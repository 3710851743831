const datas = [
  {n:"13人目の埋葬者",h:"１３にんめのまいそうしゃ",e:"The 13th Grave",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["通常"],ma:"",tp:"",at:1200,de:900,tx:"誰もいないはずの１３番目の墓から突然現れたゾンビ。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"2人3脚ゾンビ",h:"２にん３きゃくぞんび",e:"Three-Legged Zombies",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["通常"],ma:"",tp:"",at:1100,de:800,tx:"ほそっちょとでぶっちょの、仲良しガイコツ二人組。\n歩きにくそう。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"3万年の白亀",h:"３まんねんのしろかめ",e:"30,000-Year White Turtle",ra:"N",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1250,de:2100,tx:"３万年も生きつづけている巨大カメ。\n守備力が高い。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"BM－4ボムスパイダー",h:"ＢＭ－４ぼむすぱいだー",e:"BM-4 Blast Spider",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1400,de:2200,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：１ターンに１度、自分フィールドの機械族・闇属性モンスター１体と相手フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードを破壊する。\n②：自分フィールドの元々の種族・属性が機械族・闇属性のモンスターが、戦闘または自身の効果で相手フィールドのモンスターを破壊し墓地へ送った場合に発動できる。\nその破壊され墓地へ送られたモンスター１体の元々の攻撃力の半分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"KA－2 デス・シザース",h:"ＫＡ－２　です・しざーす",e:"KA-2 Des Scissors",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードが戦闘によってモンスターを破壊し墓地へ送った時、破壊したモンスターのレベル×５００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"TM－1ランチャースパイダー",h:"ＴＭ－１らんちゃーすぱいだー",e:"Launcher Spider",ra:"N",t:"monster",a:"炎",l:7,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:2200,de:2500,tx:"ロケットランチャーを乱射して、相手を爆殺する機械グモ。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"UFOタートル",h:"ＵＦＯたーとる",e:"UFO Turtle",ra:"R",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1400,de:1200,tx:"このカードが戦闘によって破壊され墓地へ送られた時、デッキから攻撃力１５００以下の炎属性モンスター１体を表側攻撃表示で特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アーカナイト・マジシャン",h:"あーかないと・まじしゃん",e:"Arcanite Magician",ra:"UR",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"魔法使い族",mo:["シンクロ","効果"],ma:"",tp:"",at:400,de:1800,tx:"チューナー＋チューナー以外の魔法使い族モンスター１体以上\n①：このカードがS召喚に成功した場合に発動する。\nこのカードに魔力カウンターを２つ置く。\n②：このカードの攻撃力は、このカードの魔力カウンターの数×１０００アップする。\n③：自分フィールドの魔力カウンターを１つ取り除き、相手フィールドのカード１枚を対象として発動できる。\nその相手のカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アーカナイト・マジシャン／バスター",h:"あーかないと・まじしゃんすらっしゅばすたー",e:"Arcanite Magician/Assault Mode",ra:"R",t:"monster",a:"光",l:9,ps:"",pe:"",tr:"魔法使い族",mo:["特殊召喚","効果"],ma:"",tp:"",at:900,de:2300,tx:"このカードは通常召喚できない。\n「バスター・モード」の効果でのみ特殊召喚できる。\nこのカードが特殊召喚に成功した時、このカードに魔力カウンターを２つ置く。\nこのカードの攻撃力は、このカードに乗っている魔力カウンターの数×１０００ポイントアップする。\nこのカードに乗っている魔力カウンターを２つ取り除く事で、相手フィールド上のカードを全て破壊する。\nまた、フィールド上のこのカードが破壊された時、自分の墓地の「アーカナイト・マジシャン」１体を選択して特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アークジェット・ライトクラフター",h:"あーくじぇっと・らいとくらふたー",e:"Arcjet Lightcraft",ra:"R",t:"monster",a:"光",l:9,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:2100,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドにモンスターが存在しない場合、このカードはリリースなしで召喚できる。\n②：このカードが召喚・特殊召喚に成功した場合、自分の墓地のレベル８以下の機械族モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターのレベルは９になり、効果は無効化される。\n③：このカードがモンスターゾーンに存在する限り、自分は機械族XモンスターしかEXデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アークブレイブドラゴン",h:"あーくぶれいぶどらごん",e:"Arkbrave Dragon",ra:"SR",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2400,de:2000,tx:"①：このカードが墓地からの特殊召喚に成功した場合に発動できる。\n相手フィールドの表側表示の魔法・罠カードを全て除外し、このカードの攻撃力・守備力は、この効果で除外したカードの数×２００アップする。\n②：このカードが墓地へ送られた次のターンのスタンバイフェイズに、「アークブレイブドラゴン」以外の自分の墓地のレベル７・８のドラゴン族モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アーケイン・ファイロ",h:"あーけいん・ふぁいろ",e:"Arcane Apprentice",ra:"N",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["チューナー","効果"],ma:"",tp:"",at:1000,de:400,tx:"このカードがシンクロモンスターのシンクロ召喚に使用され墓地へ送られた場合、自分のデッキから「バスター・モード」１枚を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"地縛霊",h:"あーす・ばうんど・すぴりっと",e:"Earthbound Spirit",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:500,de:2000,tx:"闘いに敗れた兵士たちの魂が一つになった怨霊。\nこの地に足を踏み入れた者を地中に引きずり込もうとする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アースクエイク・ジャイアント",h:"あーすくえいく・じゃいあんと",e:"Earthquake Giant",ra:"N",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1600,de:2100,tx:"このカードの表示形式が変更された時、相手フィールド上に存在するモンスター１体を選択し、表示形式を変更する事ができる。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"地母神アイリス",h:"あーすまざーあいりす",e:"Iris, the Earth Mother",ra:"N",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2400,de:1200,tx:"チェーンが３つ積まれた場合、自分のデッキからカードを１枚ドローする。\n同一チェーン上に複数回同名カードの効果が発動されている場合、この効果は発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アーティファクト－アイギス",h:"あーてぃふぁくと－あいぎす",e:"Artifact Aegis",ra:"N",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1200,de:2500,tx:"このカードは魔法カード扱いとして手札から魔法＆罠カードゾーンにセットできる。\n魔法＆罠カードゾーンにセットされたこのカードが相手ターンに破壊され墓地へ送られた時、このカードを特殊召喚する。\n相手ターン中にこのカードが特殊召喚に成功した場合、ターン終了時まで、自分フィールド上の「アーティファクト」と名のついたモンスターは相手のカードの効果の対象にならず、相手のカードの効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アーティファクト－アキレウス",h:"あーてぃふぁくと－あきれうす",e:"Artifact Achilleshield",ra:"N",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1500,de:2200,tx:"このカードは魔法カード扱いとして手札から魔法＆罠カードゾーンにセットできる。\n魔法＆罠カードゾーンにセットされたこのカードが相手ターンに破壊され墓地へ送られた時、このカードを特殊召喚する。\n相手ターン中にこのカードが特殊召喚に成功した場合、このターン相手は自分フィールド上の「アーティファクト」と名のついたモンスターを攻撃対象にできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アーティファクト－ヴァジュラ",h:"あーてぃふぁくと－う゛ぁじゅら",e:"Artifact Vajra",ra:"UR",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1800,de:1900,tx:"①：このカードは魔法カード扱いとして手札から魔法＆罠ゾーンにセットできる。\n②：魔法＆罠ゾーンにセットされたこのカードが相手ターンに破壊され墓地へ送られた場合に発動する。\nこのカードを特殊召喚する。\n③：相手ターンに、このカードが特殊召喚に成功した場合に発動する。\n自分の魔法＆罠ゾーンのカードを全て破壊する。\n④：相手モンスターの直接攻撃宣言時に発動できる。\nこのカードを手札から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アーティファクト－カドケウス",h:"あーてぃふぁくと－かどけうす",e:"Artifact Caduceus",ra:"R",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1600,de:2400,tx:"このカードは魔法カード扱いとして手札から魔法＆罠カードゾーンにセットできる。\n魔法＆罠カードゾーンにセットされたこのカードが相手ターンに破壊され墓地へ送られた時、このカードを特殊召喚する。\nまた、このカードが自分フィールド上に表側表示で存在する限り、相手ターン中に「アーティファクト」と名のついたモンスターが特殊召喚された時、デッキからカードを１枚ドローする。\n「アーティファクト－カドケウス」は自分フィールド上に１体しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アーティファクト－チャクラム",h:"あーてぃふぁくと－ちゃくらむ",e:"Artifact Chakram",ra:"SR",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1900,de:2000,tx:"①：このカードは魔法カード扱いとして手札から魔法＆罠ゾーンにセットできる。\n②：魔法＆罠ゾーンにセットされたこのカードが相手ターンに破壊され墓地へ送られた場合に発動する。\nこのカードを特殊召喚する。\n③：自分フィールドの魔法・罠カードを破壊する効果が発動した時、自分フィールドにセットされた魔法・罠カード１枚を持ち主の手札に戻して発動できる。\nこのカードを手札から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アーティファクト－フェイルノート",h:"あーてぃふぁくと－ふぇいるのーと",e:"Artifact Failnaught",ra:"N",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2000,de:1600,tx:"このカードは魔法カード扱いとして手札から魔法＆罠カードゾーンにセットできる。\n魔法＆罠カードゾーンにセットされたこのカードが相手ターンに破壊され墓地へ送られた時、このカードを特殊召喚する。\n相手ターン中にこのカードが特殊召喚に成功した場合、自分の墓地から「アーティファクト」と名のついたモンスター１体を選択して自分の魔法＆罠カードゾーンにセットできる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アーティファクト－ベガルタ",h:"あーてぃふぁくと－べがるた",e:"Artifact Beagalltach",ra:"R",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1400,de:2100,tx:"このカードは魔法カード扱いとして手札から魔法＆罠カードゾーンにセットできる。\n魔法＆罠カードゾーンにセットされたこのカードが相手ターンに破壊され墓地へ送られた時、このカードを特殊召喚する。\n相手ターン中にこのカードが特殊召喚に成功した場合、自分フィールド上にセットされたカードを２枚まで選んで破壊する。\n「アーティファクト－ベガルタ」のこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アーティファクト－ミョルニル",h:"あーてぃふぁくと－みょるにる",e:"Artifact Mjollnir",ra:"N",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1300,de:1800,tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードは魔法カード扱いとして手札から魔法＆罠ゾーンにセットできる。\n②：魔法＆罠ゾーンにセットされたこのカードが相手ターンに破壊され墓地へ送られた場合に発動する。\nこのカードを特殊召喚する。\n③：相手ターンに、このカードが特殊召喚に成功した場合、自分の墓地の「アーティファクト」モンスター１体を対象として発動する。\nそのモンスターを守備表示で特殊召喚する。\nこの効果の発動後、次のターンの終了時まで自分は「アーティファクト」モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アーティファクト－ラブリュス",h:"あーてぃふぁくと－らぶりゅす",e:"Artifact Labrys",ra:"N",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2300,de:1000,tx:"このカードは魔法カード扱いとして手札から魔法＆罠カードゾーンにセットできる。\n魔法＆罠カードゾーンにセットされたこのカードが相手ターンに破壊され墓地へ送られた時、このカードを特殊召喚する。\nまた、「アーティファクト」と名のついたカードが破壊され、自分の墓地へ送られた時に発動できる。\nこのカードを手札から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アーティファクト－ロンギヌス",h:"あーてぃふぁくと－ろんぎぬす",e:"Artifact Lancea",ra:"UR",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1700,de:2300,tx:"①：このカードは魔法カード扱いとして手札から魔法＆罠ゾーンにセットできる。\n②：魔法＆罠ゾーンにセットされたこのカードが相手ターンに破壊され墓地へ送られた場合に発動する。\nこのカードを特殊召喚する。\n③：相手ターンに、手札・フィールドのこのカードをリリースして発動できる。\nこのターン、お互いにカードを除外できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アーマー・ブレイカー",h:"あーまー・ぶれいかー",e:"Armor Breaker",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["ユニオン","効果"],ma:"",tp:"",at:800,de:800,tx:"１ターンに１度だけ自分のメインフェイズに装備カード扱いとして自分フィールド上の戦士族モンスターに装備、または装備を解除して表側攻撃表示で特殊召喚する事ができる。\nこの効果で装備カード扱いになっている場合のみ、装備モンスターが相手ライフに戦闘ダメージを与えた時、フィールド上に存在するカード１枚を破壊する。\n（１体のモンスターが装備できるユニオンは１枚まで。\n装備モンスターが戦闘によって破壊される場合は、代わりにこのカードを破壊する。\n）",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"鎧蜥蜴",h:"あーまー・りざーど",e:"Armored Lizard",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["通常"],ma:"",tp:"",at:1500,de:1200,tx:"かたい体のトカゲ。\n大きな口で噛みつかれたら、ひとたまりもないぞ。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アーマード・サイキッカー",h:"あーまーど・さいきっかー",e:"Armored Axon Kicker",ra:"UR",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:2200,de:1800,tx:"自分フィールド上にサイキック族モンスターが表側表示で存在する場合、このカードはリリースなしで召喚する事ができる。\nこのカードが戦闘によって相手モンスターを破壊した場合、自分は破壊したモンスターの攻撃力の半分のダメージを受ける。\nさらに受けたダメージの数値以下の攻撃力を持つモンスター１体を自分の墓地から特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アーマード・サイバーン",h:"あーまーど・さいばーん",e:"Armored Cybern",ra:"R",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"機械族",mo:["ユニオン","効果"],ma:"",tp:"",at:0,de:2000,tx:"①：１ターンに１度、以下の効果から１つを選択して発動できる。\n●自分フィールドの、「サイバー・ドラゴン」を素材とする融合モンスターまたは「サイバー・ドラゴン」１体を対象とし、このカードを装備カード扱いとしてそのモンスターに装備する。\n装備モンスターが戦闘・効果で破壊される場合、代わりにこのカードを破壊する。\n●装備されているこのカードを特殊召喚する。\n②：１ターンに１度、フィールドの表側表示モンスター１体を対象として発動できる。\n装備モンスターの攻撃力を１０００ダウンし、対象の表側表示モンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アーマード・スターフィッシュ",h:"あーまーど・すたーふぃっしゅ",e:"Armored Starfish",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:850,de:1400,tx:"表面がかたく守備力が比較的高い、青っぽいヒトデ。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アーマード・ビー",h:"あーまーど・びー",e:"Armored Bee",ra:"UR",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"１ターンに１度、相手フィールド上に表側表示で存在するモンスター１体を選択して発動する。\n選択した相手モンスターの攻撃力をエンドフェイズ時まで半分にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アーマード・フライ",h:"あーまーど・ふらい",e:"Arsenal Bug",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:2000,de:2000,tx:"自分フィールド上にこのカード以外の昆虫族モンスターが存在しなければ、このカードの攻撃力・守備力はそれぞれ１０００ポイントになる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アーマード・ホワイトベア",h:"あーまーど・ほわいとべあ",e:"Armored White Bear",ra:"SR",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1800,de:1400,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：フィールドにSモンスターが存在し、このカードが召喚・特殊召喚に成功した場合、自分の墓地のフィールド魔法カード１枚を対象として発動できる。\nそのカードを手札に加える。\n②：このカードが戦闘または相手の効果で破壊され墓地へ送られた場合に発動できる。\n自分のデッキ・墓地から「アーマード・ホワイトベア」以外のレベル４以下の獣族モンスター１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アーマロイドガイデンゴー",h:"あーまろいどがいでんごー",e:"Armoroid",ra:"SR",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2700,de:2000,tx:"①：「ロイド」モンスターをリリースしてこのカードがアドバンス召喚に成功した場合に発動する。\nフィールドの魔法・罠カードを全て除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アーミー・ジェネクス",h:"あーみー・じぇねくす",e:"Genex Army",ra:"R",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2300,de:1300,tx:"「ジェネクス」と名のついたモンスターをリリースしてこのカードのアドバンス召喚に成功した時、相手フィールド上のカード１枚を選択して破壊できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"軍隊竜",h:"あーみー・どらごん",e:"Troop Dragon",ra:"R",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:700,de:800,tx:"このカードが戦闘によって破壊され墓地へ送られた時、自分のデッキから「軍隊竜」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アームズ・エイド",h:"あーむず・えいど",e:"Armory Arm",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:1800,de:1200,tx:"チューナー＋チューナー以外のモンスター１体以上\n１ターンに１度、自分のメインフェイズ時に装備カード扱いとしてモンスターに装備、または装備を解除して表側攻撃表示で特殊召喚できる。\nこの効果で装備カード扱いになっている場合のみ、装備モンスターの攻撃力は１０００ポイントアップする。\nまた、装備モンスターが戦闘によってモンスターを破壊し墓地へ送った時、破壊したモンスターの元々の攻撃力分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アームズ・シーハンター",h:"あーむず・しーはんたー",e:"Armed Sea Hunter",ra:"R",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:1800,de:400,tx:"自分フィールド上にこのカード以外の水属性モンスターが存在する場合、このカードと戦闘を行った効果モンスターの効果をダメージ計算後に無効化する。\nまた、フィールド上に表側表示で存在するこのカードが破壊される場合、代わりに自分フィールド上に表側表示で存在するレベル３以下の水属性モンスター１体を破壊できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アームド・ドラゴン Lv10",h:"あーむど・どらごん　れべる１０",e:"Armed Dragon LV10",ra:"UR",t:"monster",a:"風",l:10,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3000,de:2000,tx:"このカードは通常召喚できない。\n自分フィールドの「アームド・ドラゴン LV７」１体をリリースした場合のみ特殊召喚できる。\n①：手札を１枚墓地へ送って発動できる。\n相手フィールドの表側表示モンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アームド・ドラゴン Lv3",h:"あーむど・どらごん　れべる３",e:"Armed Dragon LV3",ra:"R",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1200,de:900,tx:"①：自分スタンバイフェイズにフィールドのこのカードを墓地へ送って発動できる。\n手札・デッキから「アームド・ドラゴン LV５」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アームド・ドラゴン Lv5",h:"あーむど・どらごん　れべる５",e:"Armed Dragon LV5",ra:"SR",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2400,de:1700,tx:"①：手札からモンスター１体を墓地へ送り、そのモンスターの攻撃力以下の攻撃力を持つ、相手フィールドのモンスター１体を対象として発動できる。\nこの効果を発動するために墓地へ送ったモンスターの攻撃力以下の攻撃力を持つ、その相手モンスターを破壊する。\n②：このカードが戦闘でモンスターを破壊したターンのエンドフェイズに、フィールドのこのカードを墓地へ送って発動できる。\n手札・デッキから「アームド・ドラゴン LV７」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アームド・ドラゴン Lv7",h:"あーむど・どらごん　れべる７",e:"Armed Dragon LV7",ra:"R",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2800,de:1000,tx:"このカードは通常召喚できない。\n「アームド・ドラゴン LV５」の効果でのみ特殊召喚できる。\n①：手札からモンスター１体を墓地へ送って発動できる。\n墓地へ送ったそのモンスターの攻撃力以下の攻撃力を持つ、相手フィールドのモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アームド・ドラゴン・カタパルトキャノン",h:"あーむど・どらごん・かたぱるときゃのん",e:"Armed Dragon Catapult Cannon",ra:"UR",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:3500,de:3000,tx:"「VWXYZ－ドラゴン・カタパルトキャノン」＋「アームド・ドラゴン LV７」\n自分が上記のカード全ての特殊召喚に成功しているデュエル中に、自分のフィールド・墓地の上記のカードを除外した場合のみ、EXデッキから特殊召喚できる（「融合」は必要としない）。\n①：このカードがモンスターゾーンに存在する限り、相手は除外されている自分・相手のカードと同名カードの効果を発動できない。\n②：相手ターンに１度、デッキ・EXデッキからカード１枚を除外して発動できる。\n相手のフィールド・墓地のカードを全て除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アーメイル",h:"あーめいる",e:"Armaill",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:700,de:1300,tx:"剣状の尾を持つ変わった戦士。\n両手と尾で３連攻撃をする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"A・O・J アンノウン・クラッシャー",h:"あーりー・おぶ・じゃすてぃす　あんのうん・くらっしゃー",e:"Ally of Justice Unknown Crusher",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:800,tx:"このカードが光属性モンスターと戦闘を行った時、そのモンスターをゲームから除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"A・O・J アンリミッター",h:"あーりー・おぶ・じゃすてぃす　あんりみったー",e:"Ally of Justice Unlimiter",ra:"N",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:600,de:200,tx:"このカードをリリースして発動できる。\n自分フィールド上の「A・O・J」と名のついたモンスター１体を選択し、その攻撃力をエンドフェイズ時まで元々の攻撃力の倍にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"A・O・J エネミー・キャッチャー",h:"あーりー・おぶ・じゃすてぃす　えねみー・きゃっちゃー",e:"Ally of Justice Enemy Catcher",ra:"N",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1800,de:1800,tx:"このカードが召喚に成功した時、相手フィールド上に裏側守備表示で存在するモンスター１体を選択してエンドフェイズ時までコントロールを得る事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"A・O・J カタストル",h:"あーりー・おぶ・じゃすてぃす　かたすとる",e:"Ally of Justice Catastor",ra:"SR",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:2200,de:1200,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカードが闇属性以外のフィールド上に表側表示で存在するモンスターと戦闘を行う場合、ダメージ計算を行わずそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"A・O・J ガラドホルグ",h:"あーりー・おぶ・じゃすてぃす　がらどほるぐ",e:"Ally of Justice Garadholg",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1600,de:400,tx:"このカードは光属性モンスターと戦闘を行う場合、ダメージステップの間、攻撃力が２００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"A・O・J クラウソラス",h:"あーりー・おぶ・じゃすてぃす　くらうそらす",e:"Ally of Justice Clausolas",ra:"R",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:2300,de:1200,tx:"霞の谷に生息するモンスター、クラウソラスをモチーフに開発された対外敵用戦闘兵器。\n上空からの奇襲で敵を翻弄する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"A・O・J コアデストロイ",h:"あーりー・おぶ・じゃすてぃす　こあですとろい",e:"Ally of Justice Core Destroyer",ra:"UR",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:200,tx:"このカードが光属性モンスターと戦闘を行う場合、ダメージ計算を行わずそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"A・O・J コズミック・クローザー",h:"あーりー・おぶ・じゃすてぃす　こずみっく・くろーざー",e:"Ally of Justice Cosmic Gateway",ra:"R",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2400,de:1200,tx:"相手フィールド上に、光属性モンスターを含む２体以上のモンスターが存在する場合、このカードは手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"A・O・J サイクルリーダー",h:"あーりー・おぶ・じゃすてぃす　さいくるりーだー",e:"Ally of Justice Cycle Reader",ra:"R",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードを手札から墓地へ捨てて発動できる。\n相手の墓地の光属性モンスターを２体まで選択し、ゲームから除外する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"A・O・J サイクロン・クリエイター",h:"あーりー・おぶ・じゃすてぃす　さいくろん・くりえいたー",e:"Ally of Justice Cyclone Creator",ra:"R",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:1400,de:1200,tx:"１ターンに１度、手札を１枚捨てて発動できる。\nフィールド上のチューナーの数だけ、フィールド上の魔法・罠カードを選んで持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"A・O・J サウザンド・アームズ",h:"あーりー・おぶ・じゃすてぃす　さうざんど・あーむず",e:"Ally of Justice Thousand Arms",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1700,de:0,tx:"このカードは相手フィールド上の全ての光属性モンスターに１回ずつ攻撃できる。\nこのカードが光属性以外のモンスターと戦闘を行う場合、そのダメージ計算前にこのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"A・O・J サンダー・アーマー",h:"あーりー・おぶ・じゃすてぃす　さんだー・あーまー",e:"Ally of Justice Thunder Armor",ra:"N",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2700,de:2200,tx:"このカードは特殊召喚できない。\nこのカードがフィールド上に表側表示で存在する限り、自分フィールド上の「A・O・J」と名のついたモンスターが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"A・O・J D.D.チェッカー",h:"あーりー・おぶ・じゃすてぃす　でぃーでぃーちぇっかー",e:"Ally of Justice Quarantine",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1700,de:1200,tx:"このカードがフィールド上に表側表示で存在する限り、お互いに光属性モンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"A・O・J ディサイシブ・アームズ",h:"あーりー・おぶ・じゃすてぃす　でぃさいしぶ・あーむず",e:"Ally of Justice Decisive Armor",ra:"R",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:3300,de:3300,tx:"チューナー＋チューナー以外のモンスター２体以上\n相手フィールド上に光属性モンスターが存在する場合、１ターンに１度、以下の効果から１つを選択して発動できる。\n●相手フィールド上にセットされたカード１枚を選択して破壊する。\n●手札を１枚墓地へ送る事で、相手フィールド上の魔法・罠カードを全て破壊する。\n●自分の手札を全て墓地へ送る事で、相手の手札を確認してその中から光属性モンスターを全て墓地へ送る。\nその後、この効果で墓地へ送ったモンスターの攻撃力の合計分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"A・O・J フィールド・マーシャル",h:"あーりー・おぶ・じゃすてぃす　ふぃーるど・まーしゃる",e:"Ally of Justice Field Marshal",ra:"N",t:"monster",a:"闇",l:9,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:2900,de:2600,tx:"チューナー＋チューナー以外のモンスター２体以上\nこのカードはシンクロ召喚でしか特殊召喚できない。\nこのカードの攻撃によって裏側守備表示のモンスターを破壊し墓地へ送った時、デッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"A・O・J ブラインド・サッカー",h:"あーりー・おぶ・じゃすてぃす　ぶらいんど・さっかー",e:"Ally of Justice Nullfier",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"このカードと戦闘を行った光属性モンスターの効果をダメージ計算後に無効化する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"A・O・J ライト・ゲイザー",h:"あーりー・おぶ・じゃすてぃす　らいと・げいざー",e:"Ally of Justice Light Gazer",ra:"R",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:2400,de:1600,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカードの攻撃力は、相手の墓地の光属性モンスターの数×２００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"A・O・J リーサル・ウェポン",h:"あーりー・おぶ・じゃすてぃす　りーさる・うぇぽん",e:"Ally of Justice Omni-Weapon",ra:"N",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2200,de:800,tx:"このカードが戦闘によって光属性モンスターを破壊し墓地へ送った時、デッキからカードを１枚ドローする。\nさらに、この効果でドローしたカードがレベル４以下の闇属性モンスターだった場合、そのカードを相手に見せて特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"A・O・J リサーチャー",h:"あーりー・おぶ・じゃすてぃす　りさーちゃー",e:"Ally of Justice Searcher",ra:"R",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1400,de:100,tx:"１ターンに１度、手札を１枚捨てて発動できる。\n相手フィールド上に裏側守備表示で存在するモンスター１体を選択して表側攻撃表示にする。\nこの時、リバース効果モンスターの効果は発動しない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"A・O・J リバース・ブレイク",h:"あーりー・おぶ・じゃすてぃす　りばーす・ぶれいく",e:"Ally of Justice Reverse Break",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:1200,tx:"フィールド上に光属性モンスターが存在する場合、このカードを破壊する。\nこのカードが裏側守備表示のモンスターを攻撃した場合、ダメージ計算を行わず裏側守備表示のままそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"A・O・J ルドラ",h:"あーりー・おぶ・じゃすてぃす　るどら",e:"Ally of Justice Rudra",ra:"N",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1900,de:1200,tx:"このカードは光属性モンスターと戦闘を行う場合、ダメージステップの間、攻撃力が７００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"A・ジェネクス・アクセル",h:"あーりー・じぇねくす・あくせる",e:"Genex Ally Axel",ra:"R",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:2600,de:2000,tx:"「ジェネクス」と名のついたチューナー＋チューナー以外のモンスター１体以上\n１ターンに１度、手札を１枚捨てる事で、自分の墓地に存在するレベル４以下の機械族モンスター１体を選択して特殊召喚する。\nこの効果で特殊召喚したモンスターの攻撃力はエンドフェイズ時まで倍になり、相手プレイヤーに直接攻撃する事はできず、自分のエンドフェイズ時にゲームから除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"A・ジェネクス・クラッシャー",h:"あーりー・じぇねくす・くらっしゃー",e:"Genex Ally Crusher",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:2000,tx:"自分フィールド上のこのカードと同じ属性のモンスターが自分フィールド上に召喚された時、相手フィールド上のカード１枚を選択して破壊できる。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"A・ジェネクス・ケミストリ",h:"あーりー・じぇねくす・けみすとり",e:"Genex Ally Chemistrer",ra:"R",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:200,de:500,tx:"属性を１つ宣言し、このカードを手札から捨てて発動できる。\n自分フィールド上の「ジェネクス」と名のついたモンスター１体を選択し、その属性を宣言した属性にする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"A・ジェネクス・ソリッド",h:"あーりー・じぇねくす・そりっど",e:"Genex Ally Solid",ra:"N",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:500,de:1200,tx:"１ターンに１度、自分フィールド上に表側表示で存在する「ジェネクス」と名のついた水属性モンスター１体を墓地へ送って発動できる。\nデッキからカードを２枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"A・ジェネクス・チェンジャー",h:"あーりー・じぇねくす・ちぇんじゃー",e:"Genex Ally Changer",ra:"R",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:1800,tx:"１ターンに１度、フィールド上に表側表示で存在するモンスター１体を選択し、属性を１つ宣言して発動できる。\n選択したモンスターの属性はエンドフェイズ時まで宣言した属性になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"A・ジェネクス・ドゥルダーク",h:"あーりー・じぇねくす・どぅるだーく",e:"Genex Ally Duradark",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1800,de:200,tx:"１ターンに１度、自分のメインフェイズ時に発動する事ができる。\nこのカードと同じ属性を持つ、相手フィールド上に表側攻撃表示で存在するモンスター１体を選択して破壊する。\nこの効果を発動するターンこのカードは攻撃する事ができない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"A・ジェネクス・トライアーム",h:"あーりー・じぇねくす・とらいあーむ",e:"Genex Ally Triarm",ra:"R",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:2400,de:1600,tx:"「ジェネクス・コントローラー」＋チューナー以外のモンスター１体以上\nこのカードのシンクロ素材としたチューナー以外のモンスターの属性によって、以下の効果を１ターンに１度、手札を１枚捨てて発動できる。\n●風属性：相手の手札をランダムに１枚墓地へ送る。\n●水属性：フィールド上の魔法・罠カード１枚を選択して破壊する。\n●闇属性：フィールド上の光属性モンスター１体を選択して破壊し、デッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"A・ジェネクス・トライフォース",h:"あーりー・じぇねくす・とらいふぉーす",e:"Genex Ally Triforce",ra:"R",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:2100,tx:"「ジェネクス」と名のついたチューナー＋チューナー以外のモンスター１体以上\nこのカードのシンクロ素材としたチューナー以外のモンスターの属性によって、このカードは以下の効果を得る。\n●地属性：このカードが攻撃する場合、相手はダメージステップ終了時まで魔法・罠カードを発動できない。\n●炎属性：このカードが戦闘によってモンスターを破壊した場合、そのモンスターの元々の攻撃力分のダメージを相手ライフに与える。\n●光属性：１ターンに１度、自分の墓地の光属性モンスター１体を選択して、裏側守備表示で特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"A・ジェネクス・パワーコール",h:"あーりー・じぇねくす・ぱわーこーる",e:"Genex Ally Powercell",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1700,de:0,tx:"このカードがフィールド上に表側表示で存在する限り、このカードと同じ属性を持つ、このカード以外の自分フィールド上のモンスターの攻撃力は５００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"A・ジェネクス・ベルフレイム",h:"あーりー・じぇねくす・べるふれいむ",e:"Genex Ally Bellflame",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"自分フィールド上のモンスターが墓地へ送られる度に、このカードにジェネクスカウンターを１つ置く。\nまた、相手の墓地のカードがゲームから除外される度に、このカードにジェネクスカウンターを２つ置く。\nこのカードの攻撃力はフィールド上のジェネクスカウンターの数×１００ポイントアップする。\nこのカードが戦闘によって破壊され墓地へ送られた時、このカードに乗っていたジェネクスカウンターの数×３００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"A・ジェネクス・ボルキャノン",h:"あーりー・じぇねくす・ぼるきゃのん",e:"Genex Ally Volcannon",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1700,de:600,tx:"１ターンに１度、自分フィールド上に表側表示で存在する「ジェネクス」と名のついた炎属性モンスター１体を墓地へ送って発動できる。\n相手フィールド上に表側表示で存在するモンスター１体を選択して破壊し、破壊したモンスターのレベル×４００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"A・ジェネクス・リバイバー",h:"あーりー・じぇねくす・りばいばー",e:"Genex Ally Reliever",ra:"N",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2200,de:1000,tx:"相手がコントロールするカードの発動を無効にした場合、このカードを手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"A・ジェネクス・リモート",h:"あーりー・じぇねくす・りもーと",e:"Genex Ally Remote",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:500,de:1800,tx:"１ターンに１度、フィールド上のチューナー１体を選択して発動できる。\n選択したモンスターのカード名はエンドフェイズ時まで「ジェネクス・コントローラー」として扱う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"A・ボム",h:"あーりー・ぼむ",e:"Ally Salvo",ra:"N",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:400,de:300,tx:"このカードが光属性モンスターとの戦闘によって破壊され墓地へ送られた時、フィールド上のカード２枚を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"A・マインド",h:"あーりー・まいんど",e:"Ally Mind",ra:"R",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"機械族",mo:["チューナー","通常"],ma:"",tp:"",at:1800,de:1400,tx:"A・O・Jの思考回路を強化するために開発された高性能ユニット。\nワーム星雲より飛来した隕石から採取された物質が埋め込まれており、高いチューニング能力を誇る。\nその未知なるパワーの謎は未だ解明されていない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アイアイアン",h:"あいあいあん",e:"Aye-Iron",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1600,de:1800,tx:"１ターンに１度、自分のメインフェイズ時にこのカードの攻撃力を４００ポイントアップできる。\nこの効果を発動するターン、このカードは攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アイアン・ハート",h:"あいあん・はーと",e:"Ancient Tool",ra:"N",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1700,de:1400,tx:"古代文明の遺跡で見つかった、破壊だけを目的とした機械。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アイヴィ・ウォール",h:"あいう゛ぃ・うぉーる",e:"Wall of Ivy",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"植物族",mo:["リバース","効果"],ma:"",tp:"",at:300,de:1200,tx:"リバース：相手フィールド上に「アイヴィトークン」（植物族・地・星１・攻／守０）１体を守備表示で特殊召喚する。\n「アイヴィトークン」が破壊された時、このトークンのコントローラーは３００ポイントダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アイス・ブリザード・マスター",h:"あいす・ぶりざーど・ますたー",e:"Ice Master",ra:"R",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2500,de:2000,tx:"このカードは自分フィールドの水属性モンスター２体をリリースし、手札から特殊召喚できる。\n１ターンに１度、フィールド上に表側表示で存在するモンスター１体を選択し、アイスカウンターを１つ置く事ができる。\nまた、このカードをリリースする事で、アイスカウンターが乗ったモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アイツ",h:"あいつ",e:"Aitsu",ra:"N",t:"monster",a:"炎",l:5,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:100,de:100,tx:"非常に頼りない姿をしているが、実はとてつもない潜在能力を隠し持っているらしい。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アイルの小剣士",h:"あいるのしょうけんし",e:"The Little Swordsman of Aile",ra:"N",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:800,de:1300,tx:"自分のフィールド上に存在するこのカードを除くモンスター１体を生け贄に捧げる度に、このカードの攻撃力はターン終了時まで７００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"青き眼の乙女",h:"あおきめのおとめ",e:"Maiden with Eyes of Blue",ra:"UR",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：フィールドの表側表示のこのカードが効果の対象になった時に発動できる。\n自分の手札・デッキ・墓地から「青眼の白龍」１体を選んで特殊召喚する。\n②：このカードが攻撃対象に選択された時に発動できる。\nその攻撃を無効にし、このカードの表示形式を変更する。\nその後、自分の手札・デッキ・墓地から「青眼の白龍」１体を選んで特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"青き眼の賢士",h:"あおきめのけんし",e:"Sage with Eyes of Blue",ra:"SR",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:1500,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時に発動できる。\nデッキから「青き眼の賢士」以外の光属性・レベル１チューナー１体を手札に加える。\n②：このカードを手札から捨て、自分フィールドの効果モンスター１体を対象として発動できる。\nそのモンスターを墓地へ送り、デッキから「ブルーアイズ」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"青き眼の祭司",h:"あおきめのさいし",e:"Master with Eyes of Blue",ra:"N",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["チューナー","効果"],ma:"",tp:"",at:300,de:1200,tx:"「青き眼の祭司」の②の効果は１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時、自分の墓地の光属性・レベル１チューナー１体を対象として発動できる。\nそのモンスターを手札に加える。\n②：墓地のこのカードをデッキに戻し、自分フィールドの効果モンスター１体を対象として発動できる。\nそのモンスターを墓地へ送り、そのモンスター以外の「ブルーアイズ」モンスター１体を自分の墓地から選んで特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"青き眼の巫女",h:"あおきめのみこ",e:"Priestess with Eyes of Blue",ra:"R",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"「青き眼の巫女」の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：フィールドの表側表示のこのカードが効果の対象になった時に発動できる。\n自分フィールドの効果モンスター１体を選んで墓地へ送り、デッキから「ブルーアイズ」モンスターを２体まで手札に加える（同名カードは１枚まで）。\n②：このカードが墓地に存在する場合、自分フィールドの「ブルーアイズ」モンスター１体を対象として発動できる。\nそのモンスターを持ち主のデッキに戻し、このカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"青き眼の護人",h:"あおきめのもりと",e:"Protector with Eyes of Blue",ra:"R",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:1300,tx:"「青き眼の護人」の②の効果は１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時に発動できる。\n手札から光属性・レベル１チューナー１体を特殊召喚する。\n②：自分フィールドの効果モンスター１体を対象として発動できる。\nそのモンスターを墓地へ送り、手札から「ブルーアイズ」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"赤蟻アスカトル",h:"あかありあすかとる",e:"Fire Ant Ascator",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"昆虫族",mo:["チューナー","効果"],ma:"",tp:"",at:700,de:1300,tx:"①：このカードが戦闘で破壊され墓地へ送られた時、自分の墓地のレベル５モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化され、このターンのエンドフェイズに墓地へ送られる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"朱い靴",h:"あかいくつ",e:"Bite Shoes",ra:"N",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["リバース","効果"],ma:"",tp:"",at:500,de:300,tx:"リバース：表側表示モンスター１体の表示形式を変更する（裏側表示にはできない）。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"赤鬼",h:"あかおに",e:"Red Ogre",ra:"SR",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:2800,de:2100,tx:"このカードが召喚に成功した時、自分の手札を任意の枚数墓地に送る事で、その枚数分だけフィールド上のカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"赤き剣のライムンドス",h:"あかきけんのらいむんどす",e:"Rhaimundos of the Red Sword",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1200,de:1300,tx:"赤き炎の剣を持った戦士。\n炎の束縛で動きを封じる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"紅姫チルビメ",h:"あきちるびめ",e:"Chirubimé, Princess of Autumn Leaves",ra:"R",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1800,de:2800,tx:"このカードがフィールド上に表側表示で存在する限り、相手は他の植物族モンスターを攻撃対象に選択できない。\nまた、このカードが相手によって墓地へ送られた場合、デッキから「紅姫チルビメ」以外の植物族モンスター１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アギド",h:"あぎど",e:"Agido",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1500,de:1300,tx:"このカードが戦闘によって破壊され墓地に送られた時、サイコロを１回振る。\n自分の墓地から、サイコロの出た目と同じレベルの天使族モンスター１体を特殊召喚する事ができる。\n（６の目が出た場合は、レベル６以上のモンスターを含む）",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アクア・ドラゴン",h:"あくあ・どらごん",e:"Aqua Dragon",ra:"N",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"海竜族",mo:["融合"],ma:"",tp:"",at:2250,de:1900,tx:"「フェアリー・ドラゴン」＋「海原の女戦士」＋「ゾーン・イーター」",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アクア・マドール",h:"あくあ・まどーる",e:"Aqua Madoor",ra:"R",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:1200,de:2000,tx:"水をあやつる魔法使い。\n分厚い水の壁をつくり敵を押しつぶす。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アクアアクトレス・アロワナ",h:"あくああくとれす・あろわな",e:"Aquaactress Arowana",ra:"R",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:2000,de:2000,tx:"①：１ターンに１度、自分メインフェイズに発動できる。\nデッキから「アクアアクトレス」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アクアアクトレス・グッピー",h:"あくああくとれす・ぐっぴー",e:"Aquaactress Guppy",ra:"N",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:600,de:600,tx:"①：１ターンに１度、自分メインフェイズに発動できる。\n手札から「アクアアクトレス」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アクアアクトレス・テトラ",h:"あくああくとれす・てとら",e:"Aquaactress Tetra",ra:"R",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:300,de:300,tx:"①：１ターンに１度、自分メインフェイズに発動できる。\nデッキから「アクアリウム」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"悪の無名戦士",h:"あくのむめいせんし",e:"Unknown Warrior of Fiend",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1000,de:500,tx:"素早い動きで真空を作り出し、相手を切り刻む戦士。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"悪魔の知恵",h:"あくまのちえ",e:"Tainted Wisdom",ra:"R",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1250,de:800,tx:"このカードの表示形式が攻撃表示から表側守備表示に変わった時、自分のデッキをシャッフルする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"悪魔の偵察者",h:"あくまのていさつしゃ",e:"Hiro's Shadow Scout",ra:"R",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["リバース","効果"],ma:"",tp:"",at:650,de:500,tx:"リバース：相手はデッキからカードを３枚ドローする。\nこの効果でドローしたカードをお互いに確認し、その中から魔法カードを全て墓地へ捨てる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"悪魔竜ブラック・デーモンズ・ドラゴン",h:"あくまりゅうぶらっく・でーもんず・どらごん",e:"Archfiend Black Skull Dragon",ra:"UR",t:"monster",a:"闇",l:9,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:3200,de:2500,tx:"レベル６「デーモン」通常モンスター＋「レッドアイズ」通常モンスター\n自分は「悪魔竜ブラック・デーモンズ・ドラゴン」を１ターンに１度しか特殊召喚できない。\n①：このカードが戦闘を行う場合、相手はダメージステップ終了時まで魔法・罠・モンスターの効果を発動できない。\n②：融合召喚したこのカードが戦闘を行ったバトルフェイズ終了時、自分の墓地の「レッドアイズ」通常モンスター１体を対象として発動できる。\n墓地のそのモンスターの元々の攻撃力分のダメージを相手に与える。\nその後、そのモンスターをデッキに戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アクロバットモンキー",h:"あくろばっともんきー",e:"Acrobat Monkey",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1000,de:1800,tx:"超最先端技術により開発されたモンキータイプの自律型ロボット。\n非常にアクロバティックな動きをする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アサシン",h:"あさしん",e:"Ansatsu",ra:"N",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1700,de:1200,tx:"闇の中を音もたてず相手に忍び寄る、暗殺専門の戦士。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"A BF－神立のオニマル",h:"あさると　ぶらっくふぇざー－がんだちのおにまる",e:"Assault Blackwing - Onimaru the Divine Thunder",ra:"R",t:"monster",a:"闇",l:12,ps:"",pe:"",tr:"鳥獣族",mo:["シンクロ","効果"],ma:"",tp:"",at:3000,de:2000,tx:"チューナー＋チューナー以外のモンスター１体以上\n「A BF－神立のオニマル」の③の効果はデュエル中に１度しか使用できない。\n①：「BF」モンスターを素材としてS召喚したこのカードはチューナーとして扱う。\n②：このカードは効果では破壊されない。\n③：自分の墓地の「BF」モンスター１体を対象として発動できる。\nこのカードのレベルはそのモンスターと同じになる。\n④：Sモンスターのみを素材としてS召喚したこのカードが攻撃する場合、ダメージステップの間このカードの攻撃力は３０００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"A BF－霧雨のクナイ",h:"あさると　ぶらっくふぇざー－きりさめのくない",e:"Assault Blackwing - Kunai the Drizzle",ra:"R",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2100,de:1600,tx:"①：このカードは自分フィールドの「BF」モンスター１体をリリースして手札から特殊召喚できる。\nこの方法で特殊召喚したこのカードはチューナーとして扱う。\n②：１ターンに１度、自分フィールドのSモンスター１体を対象とし、１～８までの任意のレベルを宣言して発動できる。\nそのモンスターはターン終了時まで宣言したレベルになる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"A BF－五月雨のソハヤ",h:"あさると　ぶらっくふぇざー－さみだれのそはや",e:"Assault Blackwing - Sohaya the Rain Storm",ra:"N",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"鳥獣族",mo:["シンクロ","効果"],ma:"",tp:"",at:1500,de:2000,tx:"チューナー＋チューナー以外のモンスター１体以上\n「A BF－五月雨のソハヤ」の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：「BF」モンスターを素材としてS召喚したこのカードはチューナーとして扱う。\n②：このカードがS召喚に成功した時、自分の墓地の「A BF」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n③：このカードが墓地へ送られたターンの自分メインフェイズにこのカード以外の自分の墓地の「A BF－五月雨のソハヤ」１体を除外して発動できる。\nこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"A BF－驟雨のライキリ",h:"あさると　ぶらっくふぇざー－しゅううのらいきり",e:"Assault Blackwing - Raikiri the Rain Shower",ra:"R",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"鳥獣族",mo:["シンクロ","効果"],ma:"",tp:"",at:2600,de:2000,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：「BF」モンスターを素材としてS召喚したこのカードはチューナーとして扱う。\n②：１ターンに１度、このカード以外の自分フィールドの「BF」モンスターの数まで、相手フィールドのカードを対象として発動できる。\nそのカードを破壊する。",li:2,lk:[],ta:[],al:[],ocg:true},
  {n:"A BF－涙雨のチドリ",h:"あさると　ぶらっくふぇざー－なみだあめのちどり",e:"Assault Blackwing - Chidori the Rain Sprinkling",ra:"R",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"鳥獣族",mo:["シンクロ","効果"],ma:"",tp:"",at:2600,de:2000,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：「BF」モンスターを素材としてS召喚したこのカードはチューナーとして扱う。\n②：このカードの攻撃力は自分の墓地の「BF」モンスターの数×３００アップする。\n③：このカードが破壊され墓地へ送られた時、「A BF－涙雨のチドリ」以外の自分の墓地の鳥獣族Sモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アサルト・ガンドッグ",h:"あさると・がんどっぐ",e:"Assault Dog",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1200,de:800,tx:"①：このカードが戦闘で破壊され墓地へ送られた時に発動できる。\nデッキから「アサルト・ガンドッグ」を任意の数だけ特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アサルトワイバーン",h:"あさるとわいばーん",e:"Assault Wyvern",ra:"UR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"①：このカードが戦闘で相手モンスターを破壊した時、このカードをリリースして発動できる。\n自分の手札・墓地から「アサルトワイバーン」以外のドラゴン族モンスター１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アシッドクロウラー",h:"あしっどくろうらー",e:"Acid Crawler",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"昆虫族",mo:["通常"],ma:"",tp:"",at:900,de:700,tx:"巨大ないもむし。\n強力な酸をはき、何でも溶かしてしまう。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アステカの石像",h:"あすてかのせきぞう",e:"Stone Statue of the Aztecs",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:300,de:2000,tx:"①：このカードが攻撃された場合、その戦闘で発生する相手への戦闘ダメージは倍になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アステル・ドローン",h:"あすてる・どろーん",e:"Star Drawing",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"①：このカードをX召喚に使用する場合、このカードのレベルを５として扱う事ができる。\n②：フィールドのこのカードを素材としてX召喚したモンスターは以下の効果を得る。\n●このX召喚に成功した場合に発動する。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アストラグールズ",h:"あすとらぐーるず",e:"Astra Ghouls",ra:"N",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:0,de:2000,tx:"①：このカードが召喚に成功した時、自分の墓地のレベル４以下のモンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化され、エンドフェイズに除外される。\n②：１ターンに１度、自分メインフェイズに発動できる。\nサイコロを１回振る。\n自分フィールドの全ての表側表示モンスターのレベルはターン終了時まで出た目の数と同じになる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"阿修羅",h:"あすら",e:"Asura Priest",ra:"UR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["スピリット","効果"],ma:"",tp:"",at:1700,de:1200,tx:"このカードは特殊召喚できない。\n召喚・リバースしたターンのエンドフェイズ時に持ち主の手札に戻る。\nこのカードは相手フィールド上に存在する全てのモンスターに１回ずつ攻撃をする事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アスワンの亡霊",h:"あすわんのぼうれい",e:"Aswan Apparition",ra:"R",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:500,de:500,tx:"このカードが相手プレイヤーに戦闘ダメージを与えた場合、自分の墓地の罠カード１枚をデッキの一番上に戻す事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アタック・ゲイナー",h:"あたっく・げいなー",e:"Attack Gainer",ra:"N",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"戦士族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"このカードがシンクロ召喚の素材として墓地へ送られた場合、相手フィールド上に表側表示で存在するモンスター１体の攻撃力はエンドフェイズ時まで１０００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アチャチャアーチャー",h:"あちゃちゃあーちゃー",e:"Achacha Archer",ra:"N",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:600,tx:"このカードが召喚・反転召喚に成功した時、相手ライフに５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アチャチャチャンバラー",h:"あちゃちゃちゃんばらー",e:"Achacha Chanbara",ra:"R",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1400,de:400,tx:"ライフポイントにダメージを与える魔法・罠・効果モンスターの効果が発動した時に発動できる。\nこのカードを手札から特殊召喚し、相手ライフに４００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アックス・ドラゴニュート",h:"あっくす・どらごにゅーと",e:"Axe Dragonute",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2000,de:1200,tx:"①：このカードは攻撃した場合、ダメージステップ終了時に守備表示になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アックス・レイダー",h:"あっくす・れいだー",e:"Axe Raider",ra:"UR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1700,de:1150,tx:"オノを持つ戦士。\n片手でオノを振り回す攻撃はかなり強い。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アップル・マジシャン・ガール",h:"あっぷる・まじしゃん・がーる",e:"Apple Magician Girl",ra:"R",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1200,de:800,tx:"①：１ターンに１度、このカードが攻撃対象に選択された場合に発動できる。\n手札からレベル５以下の魔法使い族モンスター１体を特殊召喚する。\nその後、攻撃対象をそのモンスターに移し替え、攻撃モンスターの攻撃力を半分にする。\n②：このカードが戦闘・効果で破壊された場合、このカード以外の自分の墓地の「マジシャン・ガール」モンスターを３体まで対象として発動できる（同名カードは１枚まで）。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アテナ",h:"あてな",e:"Athena",ra:"SR",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2600,de:800,tx:"①：１ターンに１度、「アテナ」以外の自分フィールドの表側表示の天使族モンスター１体を墓地へ送り、「アテナ」以外の自分の墓地の天使族モンスター１体を対象として発動できる。\nその天使族モンスターを特殊召喚する。\n②：このカードが既にモンスターゾーンに存在する状態で、このカード以外の天使族モンスターが召喚・反転召喚・特殊召喚された場合に発動する。\n相手に６００ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アドバンス・ディボーター",h:"あどばんす・でぃぼーたー",e:"Majiosheldon",ra:"N",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:800,de:2000,tx:"このカードはこのカードの効果でしか特殊召喚できない。\nこのカードをリリースしてアドバンス召喚に成功した場合、次の自分のスタンバイフェイズ時に発動できる。\nこのカードを墓地から特殊召喚する。\nこの効果を発動するターン、自分はエクストラデッキからモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アトミック・スクラップ・ドラゴン",h:"あとみっく・すくらっぷ・どらごん",e:"Atomic Scrap Dragon",ra:"R",t:"monster",a:"地",l:10,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:3200,de:2400,tx:"「スクラップ」と名のついたチューナー＋チューナー以外のモンスター２体以上\n１ターンに１度、自分フィールド上に存在するカード１枚と、相手の墓地に存在するカードを３枚まで選択して発動する事ができる。\n選択した自分のカードを破壊し、選択した相手のカードをデッキに戻す。\nこのカードが相手によって破壊され墓地へ送られた時、シンクロモンスター以外の自分の墓地に存在する「スクラップ」と名のついたモンスター１体を選択して特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アトラの蟲惑魔",h:"あとらのこわくま",e:"Traptrix Atrax",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"①：このカードはモンスターゾーンに存在する限り、「ホール」通常罠カード及び「落とし穴」通常罠カードの効果を受けない。\n②：このカードがモンスターゾーンに存在する限り、自分は「ホール」通常罠カード及び「落とし穴」通常罠カードを手札から発動できる。\n③：このカードがモンスターゾーンに存在する限り、自分の通常罠カードの発動及びその発動した効果は無効化されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アトランティスの戦士",h:"あとらんてぃすのせんし",e:"Warrior of Atlantis",ra:"SR",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1900,de:1200,tx:"このカードを手札から墓地へ捨てて発動できる。\nデッキから「伝説の都 アトランティス」１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"百獣王 ベヒーモス",h:"あにまる・きんぐ　べひーもす",e:"Behemoth the King of All Animals",ra:"SR",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:2700,de:1500,tx:"このカードは生け贄１体で通常召喚する事ができる。\nその場合、このカードの元々の攻撃力は２０００になる。\n生け贄召喚に成功した時、生け贄に捧げた数だけ自分の墓地の獣族モンスターを持ち主の手札に戻す事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"暴鬼",h:"あばき",e:"Abaki",ra:"N",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1700,de:1100,tx:"このカードが戦闘によって破壊され墓地へ送られた時、お互いは５００ポイントダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"暴れ牛鬼",h:"あばれうしおに",e:"Abare Ushioni",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:1200,tx:"コイントスで裏表を当てる。\n当たった場合、相手ライフに１０００ポイントダメージを与える。\nハズレの場合、自分は１０００ポイントダメージを受ける。\nこの効果は１ターンに１度だけ自分のメインフェイズに使用する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アビス・ウォリアー",h:"あびす・うぉりあー",e:"Abyss Warrior",ra:"R",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1800,de:1300,tx:"１ターンに１度、手札から水属性モンスター１体を墓地へ捨て、自分または相手の墓地のモンスター１体を選択して発動できる。\n選択したモンスターを持ち主のデッキの一番上または一番下に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アビス・ソルジャー",h:"あびす・そるじゃー",e:"Abyss Soldier",ra:"UR",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1800,de:1300,tx:"１ターンに１度、手札から水属性モンスター１体を墓地へ捨て、フィールド上のカード１枚を選択して発動できる。\n選択したカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アブソリューター",h:"あぶそりゅーたー",e:"Fiend Reflection #1",ra:"N",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["通常"],ma:"",tp:"",at:1300,de:1400,tx:"相手を鏡の中の世界に吸い込むことができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アボイド・ドラゴン",h:"あぼいど・どらごん",e:"Dodger Dragon",ra:"SR",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1900,de:1200,tx:"このカードの召喚は無効化されない。\nこのカードが召喚に成功したターン、相手はカウンター罠カードを発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アポカテクイル",h:"あぽかてくいる",e:"Apocatequil",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:1800,de:1200,tx:"自分フィールド上にチューナーが存在する場合、フィールド上のこのカードのレベルは５として扱う。\nフィールド上のこのカードが破壊され墓地へ送られた時、自分の墓地の「太陽の神官」１体を選択して特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"悪魔ガエル",h:"あまがえる",e:"Beelze Frog",ra:"N",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1200,de:800,tx:"このカードの攻撃力は、自分の墓地に存在する「悪魂邪苦止」の枚数×３００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アマゾネス・スカウト",h:"あまぞねす・すかうと",e:"Amazoness Scouts",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:500,de:1100,tx:"このカードをリリースして発動する。\n自分フィールド上に表側表示で存在する「アマゾネス」と名のついたモンスターは、このターン効果モンスターの効果の対象にならず、魔法・罠・効果モンスターの効果では破壊されない。\nこの効果は相手ターンでも発動する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アマゾネス女王",h:"あまぞねすくぃーん",e:"Amazoness Queen",ra:"SR",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2400,de:1800,tx:"①：このカードがモンスターゾーンに存在する限り、自分の「アマゾネス」モンスターは戦闘では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アマゾネス訓練生",h:"あまぞねすくんれんせい",e:"Amazoness Trainee",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:1300,tx:"このカードが戦闘によって破壊したモンスターは墓地へ送らず持ち主のデッキの一番下に戻す。\nこのカードが戦闘によって相手モンスターを破壊した場合、このカードの攻撃力は２００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アマゾネスの格闘戦士",h:"あまぞねすのかくとうせんし",e:"Amazoness Fighter",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:1300,tx:"このカードが戦闘を行う事によって受けるコントローラーの戦闘ダメージは０になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アマゾネスの鎖使い",h:"あまぞねすのくさりつかい",e:"Amazoness Chain Master",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:1300,tx:"①：このカードが戦闘で破壊され墓地へ送られた時、１５００LPを払って発動できる。\n相手の手札を確認し、その中からモンスター１体を選んで自分の手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アマゾネスの剣士",h:"あまぞねすのけんし",e:"Amazoness Swords Woman",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:1600,tx:"①：このカードの戦闘で発生する自分への戦闘ダメージは代わりに相手が受ける。",li:2,lk:[],ta:[],al:[],ocg:true},
  {n:"アマゾネスの賢者",h:"あまぞねすのけんじゃ",e:"Amazoness Sage",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1400,de:700,tx:"このカードが攻撃した場合、そのダメージステップ終了時に相手フィールド上に存在する魔法・罠カード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アマゾネスの聖戦士",h:"あまぞねすのせいせんし",e:"Amazoness Paladin",ra:"UR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:300,tx:"自分フィールド上の「アマゾネス」という名のついたモンスターカード１枚につき、このカードの攻撃力は１００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アマゾネスの斥候",h:"あまぞねすのせっこう",e:"Amazoness Spy",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:800,de:1200,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカード以外の手札の「アマゾネス」モンスター１体を相手に見せて発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが戦闘で破壊され墓地へ送られた時、「アマゾネスの斥候」以外の自分の墓地の「アマゾネス」モンスター１体を対象として発動できる。\nそのモンスターを手札またはデッキに戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アマゾネスの吹き矢兵",h:"あまぞねすのふきやへい",e:"Amazoness Blowpiper",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:800,de:1500,tx:"自分のスタンバイフェイズ毎に相手フィールド上のモンスターを１体選択する。\n選択したモンスターの攻撃力はターン終了時まで５００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アマゾネス王女",h:"あまぞねすぷりんせす",e:"Amazoness Princess",ra:"UR",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:900,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードのカード名は、フィールド・墓地に存在する限り「アマゾネス女王」として扱う。\n②：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「アマゾネス」魔法・罠カード１枚を手札に加える。\n③：このカードの攻撃宣言時にこのカード以外の自分の手札・フィールドのカード１枚を墓地へ送って発動できる。\nデッキから「アマゾネス王女」以外の「アマゾネス」モンスター１体を守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アマゾネスペット虎",h:"あまぞねすぺっとたいがー",e:"Amazoness Tiger",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1100,de:1500,tx:"このカードは自分フィールド上に１枚しか表側表示で存在できない。\n自分フィールド上の「アマゾネス」という名のついたモンスターカード１枚につき、このカードの攻撃力は４００ポイントアップする。\n相手はこのカードを破壊しない限り、他の「アマゾネス」という名のついたモンスターを攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アマゾネスペット仔虎",h:"あまぞねすぺっとべびーたいがー",e:"Amazoness Baby Tiger",ra:"R",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:500,de:500,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードのカード名は、フィールド・墓地に存在する限り「アマゾネスペット虎」として扱う。\n②：このカードが手札・墓地に存在し、自分フィールドに「アマゾネス」モンスターが召喚・特殊召喚された場合に発動できる。\nこのカードを特殊召喚する。\n③：このカードの攻撃力は、自分の墓地の「アマゾネス」カードの数×１００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"天照大神",h:"あまてらす",e:"Amaterasu",ra:"UR",t:"monster",a:"光",l:9,ps:"",pe:"",tr:"天使族",mo:["スピリット","効果"],ma:"",tp:"",at:3000,de:3000,tx:"このカードは召喚・特殊召喚できない。\n①：裏側表示のこのモンスターを対象とする魔法・罠・モンスターの効果を相手が発動した時、このカードを表側守備表示にして発動できる。\n自分はデッキから１枚ドローする。\n②：このカードがリバースした場合に発動する。\nこのカード以外のフィールドのカードを全て除外する。\n③：このカードがリバースしたターンのエンドフェイズに発動する。\nこのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"天岩戸",h:"あまのいわと",e:"Amano-Iwato",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["スピリット","効果"],ma:"",tp:"",at:1900,de:1200,tx:"このカードは特殊召喚できない。\n①：このカードがモンスターゾーンに存在する限り、お互いにスピリットモンスター以外のモンスターの効果を発動できない。\n②：このカードが召喚・リバースしたターンのエンドフェイズに発動する。\nこのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"あまびえさん",h:"あまびえさん",e:"Amabie",ra:"SR",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"①：自分メインフェイズ１開始時に、手札のこのカードを相手に見せて発動できる。\nお互いのプレイヤーは３００LP回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アマリリース",h:"あまりりーす",e:"Amarylease",ra:"R",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:100,de:200,tx:"自分のメインフェイズ時、墓地のこのカードをゲームから除外して発動できる。\nこのターンに１度だけ、自分がモンスターを召喚する場合に必要なリリースを１体少なくする事ができる。\n「アマリリース」の効果は１ターンに１度しか発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"呪符竜",h:"あみゅれっと・どらごん",e:"Amulet Dragon",ra:"SR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:2900,de:2500,tx:"「ブラック・マジシャン」＋ドラゴン族モンスター\nこのカードは上記カードを融合素材にした融合召喚または「ティマイオスの眼」の効果でのみ特殊召喚できる。\n①：このカードが特殊召喚に成功した場合、自分・相手の墓地の魔法カードを任意の数だけ対象として発動する。\nそのカードを除外し、このカードの攻撃力はその除外したカードの数×１００アップする。\n②：このカードが破壊された場合、自分の墓地の魔法使い族モンスター１体を対象として発動できる。\nその魔法使い族モンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アメーバ",h:"あめーば",e:"Ameba",ra:"N",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:300,de:350,tx:"フィールド上に表側表示で存在するこのカードのコントロールが相手に移った時、相手は２０００ポイントダメージを受ける。\nこの効果はこのカードがフィールド上に表側表示で存在する限り１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"雨天気ラズラ",h:"あめてんきらずら",e:"The Weather Painter Rain",ra:"N",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1200,de:1400,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合に発動できる。\n手札から「天気」魔法・罠カード１枚を選んで自分の魔法＆罠ゾーンに表側表示で置く。\n②：フィールドのこのカードが「天気」カードの効果を発動するために除外された場合、次のターンのスタンバイフェイズに発動できる。\n除外されているこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"妖神－不知火",h:"あやかしがみ－しらぬい",e:"Shiranui Squiresaga",ra:"UR",t:"monster",a:"炎",l:7,ps:"",pe:"",tr:"アンデット族",mo:["シンクロ","効果"],ma:"",tp:"",at:2100,de:0,tx:"チューナー＋チューナー以外のモンスター１体以上\n自分は「妖神－不知火」を１ターンに１度しか特殊召喚できない。\n①：１ターンに１度、自分メインフェイズに発動できる。\n自分の墓地及び自分フィールドの表側表示モンスターの中から、モンスター１体を選んで除外する。\nその後、その種類によって、以下の効果をそれぞれ適用できる。\n\n●アンデット族：自分フィールドの全てのモンスターの攻撃力は３００アップする。\n\n●炎属性：フィールドの魔法・罠カード１枚を選んで破壊する。\n\n●S：フィールドのモンスター１体を選んで破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"荒ぶるアウス",h:"あらぶるあうす",e:"Avalanching Aussa",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:800,de:1500,tx:"このカードを除く自分フィールド上の地属性モンスター１体を生け贄に捧げる事で、手札から地属性モンスター１体を特殊召喚する。\nこの効果は１ターンに１度しか使用できない。\nこの効果によって特殊召喚されたモンスターは、「荒ぶるアウス」が自分フィールド上から離れた場合破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魅惑の女王 Lv3",h:"ありゅーる・くぃーん　れべる３",e:"Allure Queen LV3",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:500,de:500,tx:"１ターンに１度だけ自分のメインフェイズ時に相手フィールド上のレベル３以下のモンスター１体を選択し、装備カード扱いとしてこのカードに装備する事ができる（この効果で装備できる装備カードは１枚まで）。\nこのカードが戦闘によって破壊される場合、代わりに装備したモンスターを破壊する。\n自分ターンのスタンバイフェイズ時、この効果で装備カードを装備したこのカードを墓地に送る事で、「魅惑の女王 LV５」１体を手札またはデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魅惑の女王 Lv5",h:"ありゅーる・くぃーん　れべる５",e:"Allure Queen LV5",ra:"N",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"「魅惑の女王 LV３」の効果で特殊召喚した場合、１ターンに１度だけ自分のメインフェイズ時に相手フィールド上のレベル５以下のモンスター１体を選択し、装備カード扱いとしてこのカードに装備する事ができる（この効果で装備できる装備カードは１枚まで）。\nこのカードが戦闘によって破壊される場合、代わりに装備したモンスターを破壊する。\n自分ターンのスタンバイフェイズ時、この効果で装備カードを装備したこのカードを墓地に送る事で、「魅惑の女王 LV７」１体を手札またはデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魅惑の女王 Lv7",h:"ありゅーる・くぃーん　れべる７",e:"Allure Queen LV7",ra:"R",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1500,de:1500,tx:"「魅惑の女王 LV５」の効果で特殊召喚した場合、１ターンに１度だけ自分のメインフェイズ時に相手フィールド上のモンスター１体を選択し、装備カード扱いとしてこのカードに装備する事ができる（この効果で装備できる装備カードは１枚まで）。\nこのカードが戦闘によって破壊される場合、代わりに装備したモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アルカナ ナイトジョーカー",h:"あるかな　ないとじょーかー",e:"Arcana Knight Joker",ra:"UR",t:"monster",a:"光",l:9,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:3800,de:2500,tx:"「クィーンズ・ナイト」＋「ジャックス・ナイト」＋「キングス・ナイト」\nこのカードの融合召喚は上記のカードでしか行えない。\n①：１ターンに１度、フィールドのこのカードを対象とする、モンスターの効果・魔法・罠カードが発動した時、そのカードと同じ種類（モンスター・魔法・罠）の手札を１枚捨てて発動できる。\nその効果を無効にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アルカナフォースⅩⅧ－THE MOON",h:"あるかなふぉーすえいてぃーん－ざ・むーん",e:"Arcana Force XVIII - The Moon",ra:"SR",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2800,de:2800,tx:"このカードが召喚・反転召喚・特殊召喚に成功した時、コイントスを１回行い以下の効果を得る。\n●表：自分のスタンバイフェイズ時に自分フィールド上に「ムーントークン」（天使族・光・星１・攻／守０）を１体特殊召喚する事ができる。\n●裏：自分のエンドフェイズ時に１度だけ、自分フィールド上のモンスター１体を選択し、そのモンスターのコントロールを相手に移す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アルカナフォースEX－THE DARK RULER",h:"あるかなふぉーすえくすとら－ざ・だーく・るーらー",e:"Arcana Force EX - The Dark Ruler",ra:"SR",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"天使族",mo:["特殊召喚","効果"],ma:"",tp:"",at:4000,de:4000,tx:"このカードは通常召喚できない。\n自分フィールド上に存在するモンスター３体を墓地へ送った場合のみ特殊召喚する事ができる。\nこのカードが特殊召喚に成功した時、コイントスを１回行い以下の効果を得る。\n●表：このカードはバトルフェイズ中２回攻撃する事ができる。\nこの効果が適用された２回目の戦闘を行った場合、このカードはバトルフェイズ終了時に守備表示になる。\n次の自分のターン終了時までこのカードは表示形式を変更できない。\n●裏：このカードが破壊される場合、フィールド上のカードを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アルカナフォースEX－THE LIGHT RULER",h:"あるかなふぉーすえくすとら－ざ・らいと・るーらー",e:"Arcana Force EX - The Light Ruler",ra:"UR",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"天使族",mo:["特殊召喚","効果"],ma:"",tp:"",at:4000,de:4000,tx:"このカードは通常召喚できない。\n自分フィールド上に存在するモンスター３体を墓地へ送った場合のみ特殊召喚する事ができる。\nこのカードが特殊召喚に成功した時、コイントスを１回行い以下の効果を得る。\n●表：相手モンスターを戦闘によって破壊し墓地へ送った時、自分の墓地からカード１枚を選択して手札に加える事ができる。\n●裏：このカードを対象にする効果モンスターの効果・魔法・罠カードの発動を無効にし破壊する。\nこの効果でカードの発動を無効にする度に、このカードの攻撃力は１０００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アルカナフォースⅥ－THE LOVERS",h:"あるかなふぉーすしっくす－ざ・らばーず",e:"Arcana Force VI - The Lovers",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1600,de:1600,tx:"このカードが召喚・反転召喚・特殊召喚に成功した時、コイントスを１回行い以下の効果を得る。\n●表：「アルカナフォース」と名のついたモンスターを生け贄召喚する場合、このモンスター１体で２体分の生け贄とする事ができる。\n●裏：「アルカナフォース」と名のついたモンスターを生け贄召喚する事はできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アルカナフォースⅢ－THE EMPRESS",h:"あるかなふぉーすすりー－じ・えんぷれす",e:"Arcana Force III - The Empress",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1300,de:1300,tx:"このカードが召喚・反転召喚・特殊召喚に成功した時、コイントスを１回行い以下の効果を得る。\n●表：相手がモンスターの通常召喚に成功する度に手札から「アルカナフォース」と名のついたモンスター１体を自分フィールド上に特殊召喚する事ができる。\n●裏：相手がモンスターの通常召喚に成功する度に自分は手札のカード１枚を墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アルカナフォースⅦ－THE CHARIOT",h:"あるかなふぉーすせぶん－ざ・ちゃりおっと",e:"Arcana Force VII - The Chariot",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1700,de:1700,tx:"このカードが召喚・反転召喚・特殊召喚に成功した時、コイントスを１回行い以下の効果を得る。\n●表：このカードが戦闘によって相手モンスターを破壊した場合、そのモンスターを自分フィールド上に特殊召喚する事ができる。\n●裏：このカードのコントロールを相手に移す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アルカナフォースⅣ－THE EMPEROR",h:"あるかなふぉーすふぉー－じ・えんぺらー",e:"Arcana Force IV - The Emperor",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1400,de:1400,tx:"このカードが召喚・反転召喚・特殊召喚に成功した時、コイントスを１回行い以下の効果を得る。\n●表：自分フィールド上に表側表示で存在する「アルカナフォース」と名のついたモンスターの攻撃力は５００ポイントアップする。\n●裏：自分フィールド上に表側表示で存在する「アルカナフォース」と名のついたモンスターの攻撃力は５００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アルカナフォースⅩⅣ－TEMPERANCE",h:"あるかなふぉーすふぉーてぃーん－てんぱらんす",e:"Arcana Force XIV - Temperance",ra:"SR",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2400,de:2400,tx:"戦闘ダメージ計算時、このカードを手札から捨てて発動できる。\nその戦闘によって発生する自分への戦闘ダメージは０になる。\nこのカードが召喚・反転召喚・特殊召喚に成功した時、コイントスを１回行い、その裏表によって以下の効果を得る。\n●表：このカードがフィールド上に表側表示で存在する限り、自分が受ける戦闘ダメージは半分になる。\n●裏：このカードがフィールド上に表側表示で存在する限り、相手が受ける戦闘ダメージは半分になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アルカナフォースⅠ－THE MAGICIAN",h:"あるかなふぉーすわん－ざ・まじしゃん",e:"Arcana Force I - The Magician",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1100,de:1100,tx:"このカードが召喚・反転召喚・特殊召喚に成功した時、コイントスを１回行い以下の効果を得る。\n●表：魔法カードが発動された時、そのターンのエンドフェイズ時までこのカードの元々の攻撃力は倍になる。\n●裏：魔法カードが発動する度に相手は５００ライフポイント回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アルケミック・マジシャン",h:"あるけみっく・まじしゃん",e:"Alchemic Magician",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1500,de:1500,tx:"魔法使い族レベル４モンスター×３\nこのカードの攻撃力は自分の墓地の魔法カードの数×２００ポイントアップする。\nまた、自分のエンドフェイズ時に１度、このカードのエクシーズ素材を１つ取り除き、手札を１枚墓地へ送って発動できる。\nデッキから魔法カードを１枚選び、自分の魔法＆罠カードゾーンにセットする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アルティメット・インセクト Lv1",h:"あるてぃめっと・いんせくと　れべる１",e:"Ultimate Insect LV1",ra:"N",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"自分フィールド上に表側表示で存在するこのカードは魔法の効果を受けない。\n自分のターンのスタンバイフェイズ時、表側表示のこのカードを墓地に送る事で「アルティメット・インセクト LV３」１体を手札またはデッキから特殊召喚する（召喚・特殊召喚・リバースしたターンを除く）。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アルティメット・インセクト Lv3",h:"あるてぃめっと・いんせくと　れべる３",e:"Ultimate Insect LV3",ra:"N",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1400,de:900,tx:"「アルティメット・インセクト LV１」の効果で特殊召喚した場合、このカードがフィールド上に存在する限り、全ての相手モンスターの攻撃力は３００ポイントダウンする。\n自分のターンのスタンバイフェイズ時、表側表示のこのカードを墓地に送る事で「アルティメット・インセクト LV５」１体を手札またはデッキから特殊召喚する（召喚・特殊召喚・リバースしたターンを除く）。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アルティメット・インセクト Lv5",h:"あるてぃめっと・いんせくと　れべる５",e:"Ultimate Insect LV5",ra:"R",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:2300,de:900,tx:"「アルティメット・インセクト LV３」の効果で特殊召喚した場合、このカードがフィールド上に存在する限り、全ての相手モンスターの攻撃力は５００ポイントダウンする。\n自分のターンのスタンバイフェイズ時、表側表示のこのカードを墓地に送る事で「アルティメット・インセクト LV７」１体を手札またはデッキから特殊召喚する（召喚・特殊召喚・リバースしたターンを除く）。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アルティメット・インセクト Lv7",h:"あるてぃめっと・いんせくと　れべる７",e:"Ultimate Insect LV7",ra:"SR",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:2600,de:1200,tx:"「アルティメット・インセクト LV５」の効果で特殊召喚した場合、このカードが自分フィールド上に存在する限り、全ての相手モンスターの攻撃力・守備力は７００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アルティメットサイキッカー",h:"あるてぃめっとさいきっかー",e:"Ultimate Axon Kicker",ra:"R",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"サイキック族",mo:["融合","効果"],ma:"",tp:"",at:2900,de:1700,tx:"サイキック族シンクロモンスター＋サイキック族モンスター\nこのカードは融合召喚でのみエクストラデッキから特殊召喚する事ができる。\nこのカードはカードの効果では破壊されない。\nこのカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。\nまた、このカードが戦闘によってモンスターを破壊し墓地へ送った時、破壊したモンスターの攻撃力分だけ自分のライフポイントを回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"究極恐獣",h:"あるてぃめっとてぃらの",e:"Ultimate Tyranno",ra:"UR",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:3000,de:2200,tx:"①：自分バトルフェイズに攻撃可能な「究極恐獣」が存在する場合、「究極恐獣」以外のモンスターは攻撃できない。\n②：このカードは相手モンスター全てに１回ずつ攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アルラウネ",h:"あるらうね",e:"Arlownay",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"植物族",mo:["通常"],ma:"",tp:"",at:800,de:1000,tx:"花の中の女性が毒花粉をまき散らす。\n近づいてはいけない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アレキサンドライドラゴン",h:"あれきさんどらいどらごん",e:"Alexandrite Dragon",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:2000,de:100,tx:"アレキサンドライトのウロコを持った、非常に珍しいドラゴン。\nその美しいウロコは古の王の名を冠し、神秘の象徴とされる。\n\n――それを手にした者は大いなる幸運を既につかんでいる事に気づいていない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アロマージ－カナンガ",h:"あろまーじ－かなんが",e:"Aromage Cananga",ra:"SR",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1400,de:1000,tx:"①：自分のLPが相手より多く、このカードがモンスターゾーンに存在する限り、相手フィールドのモンスターの攻撃力・守備力は５００ダウンする。\n②：１ターンに１度、自分のLPが回復した場合、相手フィールドの魔法・罠カード１枚を対象として発動する。\nそのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アロマージ－ジャスミン",h:"あろまーじ－じゃすみん",e:"Aromage Jasmine",ra:"SR",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:100,de:1900,tx:"①：自分のLPが相手より多く、このカードがモンスターゾーンに存在する限り、自分は通常召喚に加えて１度だけ、自分メインフェイズに「アロマージ－ジャスミン」以外の植物族モンスター１体を召喚できる。\n②：１ターンに１度、自分のLPが回復した場合に発動する。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アロマージ－ベルガモット",h:"あろまーじ－べるがもっと",e:"Aromage Bergamot",ra:"R",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:2400,de:1800,tx:"①：自分のLPが相手より多く、このカードがモンスターゾーンに存在する限り、自分の植物族モンスターが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ相手に戦闘ダメージを与える。\n②：１ターンに１度、自分のLPが回復した場合に発動する。\nこのカードの攻撃力・守備力は相手ターンの終了時まで１０００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アロマージ－マジョラム",h:"あろまーじ－まじょらむ",e:"Aromage Marjoram",ra:"R",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:2000,de:1600,tx:"このカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の植物族モンスターが戦闘で破壊された時に発動できる。\nこのカードを手札から特殊召喚する。\nその後、自分は５００LP回復する。\n②：自分のLPが相手より多く、このカードがモンスターゾーンに存在する限り、自分の植物族モンスターの戦闘で発生する自分への戦闘ダメージは０になる。\n③：自分のLPが回復した場合、自分フィールドの「アロマ」モンスターの数まで相手の墓地のカードを対象として発動する。\nそのカードを除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アロマージ－ローズマリー",h:"あろまーじ－ろーずまりー",e:"Aromage Rosemary",ra:"R",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1800,de:700,tx:"①：自分のLPが相手より多く、このカードがモンスターゾーンに存在する限り、自分の植物族モンスターが攻撃する場合、ダメージステップ終了時まで相手はモンスターの効果を発動できない。\n②：１ターンに１度、自分のLPが回復した場合、フィールドの表側表示モンスター１体を対象として発動する。\nそのモンスターの表示形式を変更する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アロマージ－ローリエ",h:"あろまーじ－ろーりえ",e:"Aromage Laurel",ra:"N",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:800,de:0,tx:"このカード名の①②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分のLPが相手より多い場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：自分のLPが回復した場合、フィールドの植物族モンスター１体を対象として発動する。\nこのターン、そのモンスターをチューナーとして扱う。\n③：このカードが墓地へ送られた場合に発動できる。\n自分は５００LP回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アロマセラフィ－アンゼリカ",h:"あろませらふぃ－あんぜりか",e:"Aromaseraphy Angelica",ra:"SR",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"植物族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードを手札から捨て、自分の墓地の「アロマ」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力分だけ自分のLPを回復する。\nこの効果は相手ターンでも発動できる。\n②：このカードが墓地に存在し、自分のLPが相手より多く、自分フィールドに「アロマ」モンスターが存在する場合に発動できる。\nこのカードを墓地から特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アロマセラフィ－ローズマリー",h:"あろませらふぃ－ろーずまりー",e:"Aromaseraphy Rosemary",ra:"UR",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"植物族",mo:["シンクロ","効果"],ma:"",tp:"",at:2000,de:900,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：自分のLPが相手より多く、このカードがモンスターゾーンに存在する限り、自分フィールドの植物族モンスターの攻撃力・守備力は５００アップする。\n②：１ターンに１度、自分のLPが回復した場合、相手フィールドの表側表示のカード１枚を対象として発動する。\nそのカードの効果をターン終了時まで無効にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"暗炎星－ユウシ",h:"あんえんせい－ゆうし",e:"Brotherhood of the Fire Fist - Bear",ra:"UR",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"１ターンに１度、このカードが相手ライフに戦闘ダメージを与えた時、デッキから「炎舞」と名のついた魔法カード１枚を選んで自分フィールド上にセットできる。\nまた、１ターンに１度、自分フィールド上に表側表示で存在する「炎舞」と名のついた魔法・罠カード１枚を墓地へ送って発動できる。\nフィールド上のモンスター１体を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"屈強の釣り師",h:"あんぐらっぷらー",e:"Grappler Angler",ra:"N",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"戦士族",mo:["チューナー","効果"],ma:"",tp:"",at:100,de:100,tx:"①：このカードが直接攻撃で相手に戦闘ダメージを与えた時、自分の墓地のモンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"暗黒界の狩人 ブラウ",h:"あんこくかいのかりうど　ぶらう",e:"Broww, Huntsman of Dark World",ra:"R",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1400,de:800,tx:"このカードがカードの効果によって手札から墓地へ捨てられた場合、自分のデッキからカードを１枚ドローする。\n相手のカードの効果によって捨てられた場合、さらにもう１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"暗黒界の騎士 ズール",h:"あんこくかいのきし ずーる",e:"Zure, Knight of Dark World",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1800,de:1500,tx:"暗黒界でその名を知らぬ者はいない、誇り高き騎士。\n決して弱き者に手を下す事はない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"暗黒界の鬼神 ケルト",h:"あんこくかいのきしん　けると",e:"Lucent, Netherlord of Dark World",ra:"R",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2400,de:0,tx:"このカードがカードの効果によって手札から墓地へ捨てられた場合、このカードを墓地から特殊召喚する。\n相手のカードの効果によって捨てられた場合、さらに自分のデッキから悪魔族モンスター１体を自分または相手フィールド上に特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"暗黒界の狂王 ブロン",h:"あんこくかいのきょうおう　ぶろん",e:"Brron, Mad King of Dark World",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1800,de:400,tx:"このカードが相手ライフに戦闘ダメージを与えた時、自分は手札を１枚選択して捨てる事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"暗黒界の策士 グリン",h:"あんこくかいのさくし　ぐりん",e:"Gren, Tactician of Dark World",ra:"N",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:300,de:500,tx:"このカードがカードの効果によって手札から墓地へ捨てられた場合、フィールド上の魔法・罠カード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"暗黒界の刺客 カーキ",h:"あんこくかいのしかく　かーき",e:"Kahkki, Guerilla of Dark World",ra:"N",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:300,de:500,tx:"このカードがカードの効果によって手札から墓地へ捨てられた場合、フィールド上のモンスター１体を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"暗黒界の術師 スノウ",h:"あんこくかいのじゅつし　すのう",e:"Snoww, Unlight of Dark World",ra:"UR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1700,de:0,tx:"このカードがカードの効果によって手札から墓地へ捨てられた場合、自分のデッキから「暗黒界」と名のついたカード１枚を手札に加える。\n相手のカードの効果によって捨てられた場合、さらに相手の墓地に存在するモンスター１体を選択し、自分フィールド上に表側守備表示で特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"暗黒界の斥候 スカー",h:"あんこくかいのせっこう　すかー",e:"Scarr, Scout of Dark World",ra:"R",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:500,de:500,tx:"このカードが戦闘によって破壊され墓地へ送られた時、自分のデッキからレベル４以下の「暗黒界」と名のついたモンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"暗黒界の尖兵 ベージ",h:"あんこくかいのせんぺい　べーじ",e:"Beiige, Vanguard of Dark World",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1600,de:1300,tx:"このカードがカードの効果によって手札から墓地へ捨てられた場合、このカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"暗黒界の導師 セルリ",h:"あんこくかいのどうし　せるり",e:"Ceruli, Guru of Dark World",ra:"SR",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:100,de:300,tx:"このカードがカードの効果によって手札から墓地へ捨てられた場合、このカードを相手フィールド上に表側守備表示で特殊召喚する。\nこのカードが「暗黒界」と名のついたカードの効果によって特殊召喚に成功した時、相手は手札を１枚選択して捨てる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"暗黒界の闘神 ラチナ",h:"あんこくかいのとうしん　らちな",e:"Latinum, Exarch of Dark World",ra:"R",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1500,de:2400,tx:"このカードがカードの効果によって手札から墓地へ捨てられた場合、このカードを墓地から特殊召喚する。\n相手のカードの効果によって捨てられた場合、さらにフィールド上の悪魔族モンスター１体を選択して、攻撃力を５００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"暗黒界の番兵 レンジ",h:"あんこくかいのばんぺい れんじ",e:"Renge, Gatekeeper of Dark World",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:100,de:2100,tx:"暗黒界随一の強靱な身体を誇り、「鉄壁レンジ」として暗黒界の人々から親しまれている。\n彼の守りを破れる者は少ない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"暗黒界の武神 ゴルド",h:"あんこくかいのぶしん　ごるど",e:"Goldd, Wu-Lord of Dark World",ra:"UR",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2300,de:1400,tx:"このカードがカードの効果によって手札から墓地へ捨てられた場合、このカードを墓地から特殊召喚する。\n相手のカードの効果によって捨てられた場合、さらに相手フィールド上に存在するカードを２枚まで選択して破壊する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"暗黒界の魔神 レイン",h:"あんこくかいのましん　れいん",e:"Reign-Beaux, Overlord of Dark World",ra:"R",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2500,de:1800,tx:"このカードが相手のカードの効果によって手札から墓地へ捨てられた場合、このカードを墓地から特殊召喚する。\nこの効果で特殊召喚に成功した時、相手フィールド上の全てのモンスターまたは全ての魔法・罠カードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"暗黒界の龍神 グラファ",h:"あんこくかいのりゅうしん　ぐらふぁ",e:"Grapha, Dragon Lord of Dark World",ra:"UR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2700,de:1800,tx:"このカードは「暗黒界の龍神 グラファ」以外の自分フィールド上に表側表示で存在する「暗黒界」と名のついたモンスター１体を手札に戻し、墓地から特殊召喚する事ができる。\nこのカードがカードの効果によって手札から墓地へ捨てられた場合、相手フィールド上に存在するカード１枚を選択して破壊する。\n相手のカードの効果によって捨てられた場合、さらに相手の手札をランダムに１枚確認する。\n確認したカードがモンスターだった場合、そのモンスターを自分フィールド上に特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"暗黒火炎龍",h:"あんこくかえんりゅう",e:"Darkfire Dragon",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["融合"],ma:"",tp:"",at:1500,de:1250,tx:"「火炎草」＋「プチリュウ」",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"暗黒騎士ガイア",h:"あんこくきしがいあ",e:"Gaia The Fierce Knight",ra:"R",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:2300,de:2100,tx:"風よりも速く走る馬に乗った騎士。\n突進攻撃に注意。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"暗黒騎士ガイアオリジン",h:"あんこくきしがいあおりじん",e:"Gaia the Fierce Knight Origin",ra:"UR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"このカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札からレベル５以上のモンスター１体を墓地へ送って発動できる。\nこのカードを手札から特殊召喚する。\n②：戦士族モンスターをアドバンス召喚する場合、このカードは２体分のリリースにできる。\n③：自分・相手のバトルフェイズに、墓地のこのカードを除外し、元々の攻撃力と異なる攻撃力を持つフィールドのモンスター１体を対象として発動できる。\nそのモンスターの攻撃力は元々の数値になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"暗黒騎士ガイアソルジャー",h:"あんこくきしがいあそるじゃー",e:"Soldier Gaia the Fierce Knight",ra:"UR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2600,de:2100,tx:"このカード名の①②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドのドラゴン族の融合モンスター１体をリリースして発動できる。\nこのカードを手札から特殊召喚する。\nこの効果は相手ターンでも発動できる。\n②：このカードが特殊召喚に成功した場合、フィールドの攻撃表示モンスター１体を対象として発動できる。\nそのモンスターを守備表示にする。\n③：このカードをリリースして発動できる。\nデッキから「暗黒騎士ガイアソルジャー」以外のレベル７以上の戦士族モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"暗黒騎士ガイアロード",h:"あんこくきしがいあろーど",e:"Lord Gaia the Fierce Knight",ra:"SR",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2300,de:2100,tx:"①：相手フィールドにのみモンスターが存在する場合、このカードは手札から特殊召喚できる。\n②：１ターンに１度、このカードより攻撃力が高いモンスターが相手フィールドに特殊召喚された場合に発動できる。\nこのカードの攻撃力はターン終了時まで７００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"暗黒大要塞鯱",h:"あんこくだいようさいじゃち",e:"Orca Mega-Fortress of Darkness",ra:"SR",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:2100,de:1200,tx:"自分フィールド上の「魚雷魚」１体を生け贄に捧げる事で、フィールド上のモンスター１体を破壊する。\n自分フィールド上の「砲弾ヤリ貝」１体を生け贄に捧げる事で、フィールド上の魔法・罠カード１枚を破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"暗黒の海竜兵",h:"あんこくのかいりゅうへい",e:"Sea Serpent Warrior of Darkness",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"海竜族",mo:["通常"],ma:"",tp:"",at:1800,de:1500,tx:"暗黒海の世界を守る戦士。\n水中はもちろん、陸上でも高い戦闘能力を誇る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"暗黒の侵略者",h:"あんこくのしんりゃくしゃ",e:"Invader of Darkness",ra:"SR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2900,de:2500,tx:"このカードがフィールド上に表側表示で存在する限り、相手は速攻魔法カードを発動する事ができない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"暗黒の竜王",h:"あんこくのどらごん",e:"Blackland Fire Dragon",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:1500,de:800,tx:"暗闇の奥深くに生息するドラゴン。\n目はあまり良くない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"暗黒の眠りを誘うルシファー",h:"あんこくのねむりをさそうるしふぁー",e:"Invitation to a Dark Sleep",ra:"R",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1500,de:1800,tx:"召喚・反転召喚した時、相手のフィールド上モンスター１体を選択する。\n選択したモンスターはこのカードがフィールド上に表側表示で存在する限り、攻撃をする事ができない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"暗黒の狂犬",h:"あんこくのまっどどっぐ",e:"Mad Dog of Darkness",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:1900,de:1400,tx:"かつては公園で遊ぶ普通の犬だったが、暗黒の力により凶暴化してしまった。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"暗黒のミミック LV1",h:"あんこくのみみっく　れべる１",e:"Dark Mimic LV1",ra:"R",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["リバース","効果"],ma:"",tp:"",at:100,de:1000,tx:"リバース：デッキからカードを１枚ドローする。\nまた、自分のターンのスタンバイフェイズ時、表側表示のこのカードを墓地に送る事で「暗黒のミミック LV３」１体を手札またはデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"暗黒のミミック LV3",h:"あんこくのみみっく　れべる３",e:"Dark Mimic LV3",ra:"R",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードが戦闘によって墓地に送られた場合、このカードのコントローラーはデッキからカードを１枚ドローする。\nこのカードが「暗黒のミミック LV１」の効果によって特殊召喚されている場合はカードを２枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"暗黒魔神 ナイトメア",h:"あんこくまじん　ないとめあ",e:"Dark Titan of Terror",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1300,de:1100,tx:"夢の中に潜むと言われている悪魔。\n寝ている間に命を奪う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"暗黒魔族ギルファー・デーモン",h:"あんこくまぞくぎるふぁー・でーもん",e:"Archfiend of Gilfer",ra:"SR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2200,de:2500,tx:"①：このカードが墓地へ送られた時、フィールドの表側表示モンスター１体を対象として発動できる。\n墓地のこのカードを攻撃力５００ダウンの装備カード扱いとしてそのモンスターに装備する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アンサイクラー",h:"あんさいくらー",e:"Unicycular",ra:"N",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:100,de:100,tx:"トライクラー、ヴィークラーを兄に持つ三男坊のアンサイクラー。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アンチエイリアン",h:"あんちえいりあん",e:"Antialian",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1900,de:600,tx:"①：１ターンに１度、このカードがモンスターと戦闘を行った自分・相手のバトルフェイズに発動できる。\n手札からサイバース族モンスター１体を召喚する。\n②：表側表示のこのカードが相手の効果でフィールドから離れた場合に発動する。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"古代の歯車",h:"あんてぃーく・ぎあ",e:"Ancient Gear",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:100,de:800,tx:"自分フィールド上に「古代の歯車」が表側表示で存在する時、手札からこのカードを攻撃表示で特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"古代の機械究極巨人",h:"あんてぃーく・ぎあ・あるてぃめっと・ごーれむ",e:"Ultimate Ancient Gear Golem",ra:"UR",t:"monster",a:"地",l:10,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:4400,de:3400,tx:"「古代の機械巨人」＋「アンティーク・ギア」モンスター×２\nこのカードは融合召喚でしか特殊召喚できない。\n①：このカードが攻撃する場合、相手はダメージステップ終了時まで魔法・罠カードを発動できない。\n②：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。\n③：このカードが破壊された場合、自分の墓地の「古代の機械巨人」１体を対象として発動できる。\nそのモンスターを召喚条件を無視して特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"古代の機械熱核竜",h:"あんてぃーく・ぎあ・りあくたー・どらごん",e:"Ancient Gear Reactor Dragon",ra:"UR",t:"monster",a:"地",l:9,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:3000,de:3000,tx:"①：「アンティーク・ギア」モンスターをリリースしてアドバンス召喚したこのカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。\n②：「ガジェット」モンスターをリリースしてアドバンス召喚したこのカードは１度のバトルフェイズ中に２回攻撃できる。\n③：このカードが攻撃する場合、相手はダメージステップ終了時までモンスターの効果・魔法・罠カードを発動できない。\n④：このカードが攻撃したダメージステップ終了時に発動できる。\nフィールドの魔法・罠カード１枚を選んで破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"古代の機械工兵",h:"あんてぃーく・ぎあえんじにあ",e:"Ancient Gear Engineer",ra:"SR",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1500,de:1500,tx:"①：このカードがモンスターゾーンに存在する限り、このカードを対象とする罠カードの効果は無効化され破壊される。\n②：このカードが攻撃する場合、相手はダメージステップ終了時まで魔法・罠カードを発動できない。\n③：このカードが攻撃したダメージステップ終了時、相手フィールドの魔法・罠カード１枚を対象として発動する。\nその相手の魔法・罠カードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"古代の機械合成獣",h:"あんてぃーく・ぎあがじぇるきめら",e:"Ancient Gear Gadjiltron Chimera",ra:"N",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2300,de:1300,tx:"①：このカードの召喚のためにリリースしたモンスターによって以下の効果を得る。\n●グリーン・ガジェット：このカードの攻撃力は３００アップする。\n●レッド・ガジェット：このカードが直接攻撃で相手に戦闘ダメージを与えた場合に発動する。\n相手に５００ダメージを与える。\n●イエロー・ガジェット：このカードが戦闘で相手モンスターを破壊した場合に発動する。\n相手に７００ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"古代の機械巨竜",h:"あんてぃーく・ぎあがじぇるどらごん",e:"Ancient Gear Gadjiltron Dragon",ra:"R",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:3000,de:2000,tx:"①：このカードの召喚のためにリリースしたモンスターによって以下の効果を得る。\n●グリーン・ガジェット：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。\n●レッド・ガジェット：このカードが相手に戦闘ダメージを与えた場合に発動する。\n相手に４００ダメージを与える。\n●イエロー・ガジェット：このカードが戦闘で相手モンスターを破壊した場合に発動する。\n相手に６００ダメージを与える。\n②：このカードが攻撃する場合、相手はダメージステップ終了時まで魔法・罠カードを発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"古代の機械砲台",h:"あんてぃーく・ぎあきゃのん",e:"Ancient Gear Cannon",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:500,de:500,tx:"このカードを生け贄に捧げる。\n相手ライフに５００ポイントダメージを与え、このターンのバトルフェイズ中はお互いに罠カードを発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"古代の機械巨人",h:"あんてぃーく・ぎあごーれむ",e:"Ancient Gear Golem",ra:"UR",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:3000,de:3000,tx:"このカードは特殊召喚できない。\n①：このカードが攻撃する場合、相手はダメージステップ終了時まで魔法・罠カードを発動できない。\n②：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"古代の機械巨人－アルティメット・パウンド",h:"あんてぃーく・ぎあごーれむ－あるてぃめっと・ぱうんど",e:"Ancient Gear Golem - Ultimate Pound",ra:"UR",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:3000,de:3000,tx:"このカードは特殊召喚できない。\n①：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。\n②：このカードの攻撃でモンスターを破壊した時、手札から機械族モンスター１体を捨てて発動できる。\nこのカードは続けて攻撃できる。\nこの効果は１ターンに２度まで使用できる。\n③：フィールドのこのカードが戦闘・効果で破壊された場合に発動できる。\nデッキから「融合」１枚を手札に加え、自分の墓地からこのカード以外の「アンティーク・ギア」モンスター１体を選んで手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"古代の機械兵士",h:"あんてぃーく・ぎあそるじゃー",e:"Ancient Gear Soldier",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1300,de:1300,tx:"①：このカードが攻撃する場合、相手はダメージステップ終了時まで魔法・罠カードを発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"古代の機械騎士",h:"あんてぃーく・ぎあないと",e:"Ancient Gear Knight",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["デュアル","効果"],ma:"",tp:"",at:1800,de:500,tx:"①：このカードはフィールド・墓地に存在する限り、通常モンスターとして扱う。\n②：フィールドの通常モンスター扱いのこのカードを通常召喚としてもう１度召喚できる。\nその場合このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●このカードが攻撃する場合、相手はダメージステップ終了時まで魔法・罠カードを発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"古代の機械獣",h:"あんてぃーく・ぎあびーすと",e:"Ancient Gear Beast",ra:"R",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2000,de:2000,tx:"このカードは特殊召喚できない。\n①：このカードが攻撃する場合、相手はダメージステップ終了時まで魔法・罠カードを発動できない。\n②：このカードが戦闘で破壊した相手モンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"古代の機械合成竜",h:"あんてぃーく・ぎあひどら",e:"Ancient Gear Hydra",ra:"R",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2700,de:1700,tx:"①：「アンティーク・ギア」モンスターをリリースしてアドバンス召喚したこのカードとの戦闘で相手モンスターが破壊されなかったダメージステップ終了時に発動できる。\nその相手モンスターを除外する。\n②：「ガジェット」モンスターをリリースしてアドバンス召喚したこのカードは相手モンスター全てに１回ずつ攻撃できる。\n③：自分の「アンティーク・ギア」モンスターが攻撃する場合、相手はダメージステップ終了時までモンスターの効果・魔法・罠カードを発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"古代の機械素体",h:"あんてぃーく・ぎあふれーむ",e:"Ancient Gear Frame",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1600,de:500,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：手札を１枚捨てて発動できる。\n「古代の機械巨人」１体または「古代の機械巨人」のカード名が記された魔法・罠カード１枚をデッキから手札に加える。\n②：このカードが攻撃する場合、相手はダメージステップ終了時まで魔法・罠カードを発動できない。\n③：表側表示のこのカードが相手の効果でフィールドから離れた場合に発動できる。\n手札から「古代の機械巨人」「古代の機械巨人－アルティメット・パウンド」を合計３体まで召喚条件を無視して特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"古代の機械箱",h:"あんてぃーく・ぎあぼっくす",e:"Ancient Gear Box",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:500,de:2000,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードがドロー以外の方法でデッキ・墓地から手札に加わった場合に発動できる。\n「古代の機械箱」以外の攻撃力または守備力が５００の機械族・地属性モンスター１体をデッキから手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"古代の機械飛竜",h:"あんてぃーく・ぎあわいばーん",e:"Ancient Gear Wyvern",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1700,de:1200,tx:"「古代の機械飛竜」の①の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「古代の機械飛竜」以外の「アンティーク・ギア」カード１枚を手札に加える。\nこの効果の発動後、ターン終了時まで自分はカードをセットできない。\n②：このカードが攻撃する場合、相手はダメージステップ終了時までモンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"闇帝ディルグ",h:"あんていでぃるぐ",e:"Delg the Dark Monarch",ra:"R",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2400,de:1000,tx:"このカードが召喚・特殊召喚に成功した時、相手の墓地に存在するカードを２枚までゲームから除外する事ができる。\nさらに、除外した数だけ相手のデッキの上からカードを墓地へ送る。\nこのカードは召喚・特殊召喚したターンには攻撃する事ができない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アンデット・スカル・デーモン",h:"あんでっと・すかる・でーもん",e:"Archfiend Zombie-Skull",ra:"N",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"アンデット族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:1200,tx:"「ゾンビキャリア」＋チューナー以外のアンデット族モンスター２体以上\nこのカードがフィールド上に表側表示で存在する限り、自分フィールド上のアンデット族モンスターは、カードの効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アンドロ・スフィンクス",h:"あんどろ・すふぃんくす",e:"Andro Sphinx",ra:"R",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:3000,de:2500,tx:"フィールド上に「光のピラミッド」が存在する場合、５００ライフポイントを払う事でこのカードを手札から特殊召喚する事ができる。\nこのカードは召喚・特殊召喚したターンに攻撃をする事ができない。\nこのカードは墓地からの特殊召喚はできない。\nこのカードが戦闘によって守備表示モンスターを破壊した場合、破壊したモンスターの攻撃力の半分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アンノウン・シンクロン",h:"あんのうん・しんくろん",e:"Unknown Synchron",ra:"UR",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"「アンノウン・シンクロン」の①の方法による特殊召喚はデュエル中に１度しかできない。\n①：相手フィールドにモンスターが存在し、自分フィールドにモンスターが存在しない場合、このカードは手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アンブラル・アンフォーム",h:"あんぶらる・あんふぉーむ",e:"Umbral Horror Unform",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカードの攻撃によってこのカードが戦闘で破壊され墓地へ送られた時、デッキから「アンブラル」と名のついたモンスター２体を特殊召喚できる。\n「アンブラル・アンフォーム」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アンブラル・ウィル・オ・ザ・ウィスプ",h:"あんぶらる・うぃる・お・ざ・うぃすぷ",e:"Umbral Horror Will o' the Wisp",ra:"N",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカードが召喚・特殊召喚に成功した時、このカード以外の自分のフィールド上・墓地の「アンブラル」と名のついたモンスター１体を選択して発動できる。\nこのカードのレベルは選択したモンスターのレベルと同じになる。\nまた、フィールド上に表側攻撃表示で存在するこのカードが戦闘によって破壊され墓地へ送られた時、このカードを破壊したモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アンブラル・グール",h:"あんぶらる・ぐーる",e:"Umbral Horror Ghoul",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1800,de:0,tx:"１ターンに１度、自分のメインフェイズ時に発動できる。\nこのカードの攻撃力を０にし、手札から攻撃力０の「アンブラル」と名のついたモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アンブラル・ゴースト",h:"あんぶらる・ごーすと",e:"Umbral Horror Ghost",ra:"N",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:200,de:200,tx:"自分のメインフェイズ時に発動できる。\nこのカードとレベル４以下の悪魔族モンスター１体を手札から特殊召喚する。\nこの効果を発動するターン、自分は通常召喚できない。\n「アンブラル・ゴースト」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アンモ・ナイト",h:"あんも・ないと",e:"Arma Knight",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1000,de:1200,tx:"大昔から海を外敵から守っている、アンモナイトの戦士。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"暗躍のドルイド・ウィド",h:"あんやくのどるいど・うぃど",e:"Secret Sect Druid Wid",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:0,de:1800,tx:"このカードがフィールド上から墓地へ送られた場合、自分の墓地の永続魔法カード１枚を選択して自分フィールド上にセットできる。\nこの効果でセットしたカードはこのターン発動できない。\n「暗躍のドルイド・ウィド」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"暗躍のドルイド・ドリュース",h:"あんやくのどるいど・どりゅーす",e:"Secret Sect Druid Dru",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1800,de:0,tx:"このカードが召喚に成功した時、自分の墓地から「暗躍のドルイド・ドリュース」以外の攻撃力または守備力が０の闇属性・レベル４モンスター１体を選択して表側守備表示で特殊召喚できる。\nこの効果で特殊召喚したモンスターの効果は無効化される。\n「暗躍のドルイド・ドリュース」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"イーグル・アイ",h:"いーぐる・あい",e:"Eagle Eye",ra:"N",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1300,de:1200,tx:"このカードが召喚に成功した時、罠カードを発動する事はできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"イースター島のモアイ",h:"いーすたーとうのもあい",e:"The Statue of Easter Island",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["通常"],ma:"",tp:"",at:1100,de:1400,tx:"イースター島に存在する石像。\n口から丸いレーザーをはく。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E-HERO アダスター・ゴールド",h:"いーびるひーろー　あだすたー・ごーるど",e:"Evil HERO Adusted Gold",ra:"UR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2100,de:800,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードを手札から捨てて発動できる。\n「E-HERO アダスター・ゴールド」以外の「ダーク・フュージョン」のカード名が記されたカードまたは「ダーク・フュージョン」１枚をデッキから手札に加える。\n②：自分フィールドに融合モンスターが存在しない場合、このカードは攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E-HERO インフェルノ・ウィング",h:"いーびるひーろー　いんふぇるの・うぃんぐ",e:"Evil HERO Inferno Wing",ra:"UR",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"悪魔族",mo:["融合","効果"],ma:"",tp:"",at:2100,de:1200,tx:"「E・HERO フェザーマン」＋「E・HERO バーストレディ」\nこのカードは「ダーク・フュージョン」の効果でのみ特殊召喚できる。\n①：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。\n②：このカードが戦闘でモンスターを破壊し墓地へ送った場合に発動する。\nそのモンスターの元々の攻撃力と元々の守備力の内、高い方の数値分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E-HERO ダーク・ガイア",h:"いーびるひーろー　だーく・がいあ",e:"Evil HERO Dark Gaia",ra:"SR",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"悪魔族",mo:["融合","効果"],ma:"",tp:"",at:"?",de:0,tx:"悪魔族モンスター＋岩石族モンスター\nこのカードは「ダーク・フュージョン」の効果でのみ特殊召喚できる。\n①：このカードの元々の攻撃力は、このカードの融合素材としたモンスターの元々の攻撃力を合計した数値になる。\n②：このカードの攻撃宣言時に発動できる。\n相手フィールドの守備表示モンスターを全て表側攻撃表示にする。\nこの時、リバースモンスターの効果は発動しない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E-HERO ヘル・ゲイナー",h:"いーびるひーろー　へる・げいなー",e:"Evil HERO Infernal Gainer",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1600,de:0,tx:"①：自分メインフェイズ１にフィールドのこのカードを除外し、自分フィールドの悪魔族モンスター１体を対象として発動できる。\nその自分の悪魔族モンスターは、フィールドに表側表示で存在する限り、１度のバトルフェイズ中に２回攻撃できる。\n②：このカードが①の効果を発動するために除外された場合、２回目の自分スタンバイフェイズに発動する。\nこのカードを攻撃表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E-HERO ヘル・スナイパー",h:"いーびるひーろー　へる・すないぱー",e:"Evil HERO Infernal Sniper",ra:"SR",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"悪魔族",mo:["融合","効果"],ma:"",tp:"",at:2000,de:2500,tx:"「E・HERO クレイマン」＋「E・HERO バーストレディ」\nこのカードは「ダーク・フュージョン」の効果でのみ特殊召喚できる。\n①：このカードはモンスターゾーンに存在する限り、魔法カードの効果では破壊されない。\n②：自分スタンバイフェイズに発動する。\n相手に１０００ダメージを与える。\nこの効果はこのカードがモンスターゾーンに表側守備表示で存在する場合に発動と処理を行う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E-HERO ヘル・ブラット",h:"いーびるひーろー　へる・ぶらっと",e:"Evil HERO Infernal Prodigy",ra:"UR",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:300,de:600,tx:"①：自分フィールドにモンスターが存在しない場合、このカードは手札から攻撃表示で特殊召喚できる。\n②：このカードをリリースして「HERO」モンスターがアドバンス召喚に成功したターンのエンドフェイズに発動する。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E-HERO マリシャス・エッジ",h:"いーびるひーろー　まりしゃす・えっじ",e:"Evil HERO Malicious Edge",ra:"UR",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2600,de:1800,tx:"相手フィールドにモンスターが存在する場合、このカードはモンスター１体をリリースして表側表示でアドバンス召喚できる。\n①：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E-HERO マリシャス・デビル",h:"いーびるひーろー　まりしゃす・でびる",e:"Evil HERO Malicious Fiend",ra:"UR",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"悪魔族",mo:["融合","効果"],ma:"",tp:"",at:3500,de:2100,tx:"「E-HERO マリシャス・エッジ」＋レベル６以上の悪魔族モンスター\nこのカードは「ダーク・フュージョン」の効果でのみ特殊召喚できる。\n①：このカードがモンスターゾーンに存在する限り、相手バトルフェイズの間、相手フィールドの全てのモンスターは、表側攻撃表示になり、攻撃可能な場合はこのカードを攻撃しなければならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E-HERO ライトニング・ゴーレム",h:"いーびるひーろー　らいとにんぐ・ごーれむ",e:"Evil HERO Lightning Golem",ra:"SR",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"悪魔族",mo:["融合","効果"],ma:"",tp:"",at:2400,de:1500,tx:"「E・HERO スパークマン」＋「E・HERO クレイマン」\nこのカードは「ダーク・フュージョン」の効果でのみ特殊召喚できる。\n①：１ターンに１度、フィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E-HERO ワイルド・サイクロン",h:"いーびるひーろー　わいるど・さいくろん",e:"Evil HERO Wild Cyclone",ra:"UR",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"悪魔族",mo:["融合","効果"],ma:"",tp:"",at:1900,de:2300,tx:"「E・HERO ワイルドマン」＋「E・HERO フェザーマン」\nこのカードは「ダーク・フュージョン」の効果でのみ特殊召喚できる。\n①：このカードが攻撃する場合、相手はダメージステップ終了時まで魔法・罠カードを発動できない。\n②：このカードが相手に戦闘ダメージを与えた場合に発動する。\n相手フィールドにセットされた魔法・罠カードを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"イエロー・ガジェット",h:"いえろー・がじぇっと",e:"Yellow Gadget",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:1200,tx:"①：このカードが召喚・特殊召喚に成功した時に発動できる。\nデッキから「グリーン・ガジェット」１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"イエロー・ダストン",h:"いえろー・だすとん",e:"Yellow Duston",ra:"N",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:1000,tx:"このカードはリリースできず、融合・シンクロ・エクシーズ召喚の素材にもできない。\nフィールド上のこのカードが破壊された時、このカードのコントローラーは自分の墓地のモンスター１体を選択してデッキに戻す。\n「イエロー・ダストン」は自分フィールド上に１体しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"黄色い忍者",h:"いえろーにんじゃ",e:"Yellow Ninja",ra:"R",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1900,de:1800,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・リバースした場合に発動できる。\n手札からレベル４以下の「忍者」モンスター１体を表側攻撃表示または裏側守備表示で特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は「忍者」モンスターしかEXデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"威炎星－ヒエンシャク",h:"いえんせい－ひえんしゃく",e:"Brotherhood of the Fire Fist - Swallow",ra:"SR",t:"monster",a:"炎",l:5,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:2000,de:800,tx:"このカードは自分フィールド上に表側表示で存在する「炎舞」と名のついた魔法・罠カード３枚を墓地へ送り、手札から特殊召喚できる。\nこのカードが召喚・特殊召喚に成功した時、デッキから「炎舞」と名のついた罠カード１枚を選んで自分フィールド上にセットできる。\n「威炎星－ヒエンシャク」のこの効果は１ターンに１度しか使用できない。\nまた、このカードがフィールド上に表側表示で存在する限り、自分フィールド上の獣戦士族モンスターは相手のカードの効果の対象にならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"異界の棘紫獣",h:"いかいのきょくしじゅう",e:"Interplanetarypurplythorny Beast",ra:"N",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1100,de:2200,tx:"このカードが墓地に存在し、自分フィールド上のモンスターが戦闘によって破壊され墓地へ送られた時、このカードを墓地から特殊召喚できる。\nこの効果で特殊召喚したこのカードは、フィールド上から離れた場合ゲームから除外される。\n「異界の棘紫獣」の効果は１ターンに１度しか発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"異界の棘紫竜",h:"いかいのきょくしりゅう",e:"Interplanetarypurplythorny Dragon",ra:"UR",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2200,de:1100,tx:"自分フィールド上のモンスターが戦闘またはカードの効果によって破壊され墓地へ送られた場合、このカードを手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"異怪の妖精 エルフォビア",h:"いかいのようせい　えるふぉびあ",e:"Ghost Fairy Elfobia",ra:"R",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:900,de:500,tx:"１ターンに１度、手札の風属性モンスター１体を相手に見せて発動できる。\n次の相手のメインフェイズ１終了時まで、お互いのプレイヤーは、見せたモンスターよりレベルの高いモンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"怒りの海王",h:"いかりのかいおう",e:"The Furious Sea King",ra:"N",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:800,de:700,tx:"偉大な海の王。\n終わることのない大津波を呼び、敵をのみこむ。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"粋カエル",h:"いきかえる",e:"Ronintoadin",ra:"R",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:100,de:2000,tx:"このカードはS素材にできない。\n①：このカードはモンスターゾーンに存在する限り、カード名を「デスガエル」として扱う。\n②：このカードが墓地に存在する場合、自分の墓地から「ガエル」モンスター１体を除外して発動できる。\nこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"生き血をすするもの",h:"いきちをすするもの",e:"Drooling Lizard",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"爬虫類族",mo:["通常"],ma:"",tp:"",at:900,de:800,tx:"暗闇の中、道行く人々を襲う人型の吸血ヘビ。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"異形の従者",h:"いぎょうのじゅうしゃ",e:"Servant of Catabolism",ra:"N",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:700,de:500,tx:"このカードは相手プレイヤーに直接攻撃をする事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"戦神－不知火",h:"いくさがみ－しらぬい",e:"Shiranui Shogunsaga",ra:"R",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"アンデット族",mo:["シンクロ","効果"],ma:"",tp:"",at:3000,de:0,tx:"アンデット族チューナー＋チューナー以外のアンデット族モンスター１体以上\n自分は「戦神－不知火」を１ターンに１度しか特殊召喚できない。\n①：このカードが特殊召喚に成功した場合、自分の墓地からアンデット族モンスター１体を除外して発動できる。\nこのカードの攻撃力はターン終了時まで、除外したモンスターの元々の攻撃力分アップする。\n②：フィールドのこのカードが戦闘・効果で破壊され墓地へ送られた場合、除外されている自分の守備力０のアンデット族モンスター１体を対象として発動できる。\nそのモンスターを墓地に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"イグザリオン・ユニバース",h:"いぐざりおん・ゆにばーす",e:"Exarion Universe",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1900,tx:"①：このカードが守備表示モンスターに攻撃するバトルステップに発動できる。\nターン終了時まで、このカードの攻撃力は４００ダウンし、このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"イグナイト・アヴェンジャー",h:"いぐないと・あう゛ぇんじゃー",e:"Igknight Lancer",ra:"N",t:"monster",a:"炎",l:7,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2600,de:1800,tx:"①：自分フィールドの「イグナイト」カード３枚を対象として発動できる。\nそのカードを破壊し、このカードを手札から特殊召喚する。\n②：１ターンに１度、このカード以外の自分フィールドの「イグナイト」モンスター１体を対象として発動できる。\nそのカードを持ち主の手札に戻し、相手フィールドの魔法・罠カード１枚を選んで持ち主のデッキの一番下に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"イグナイト・イーグル",h:"いぐないと・いーぐる",e:"Igknight Crusader",ra:"N",t:"monster",a:"炎",l:3,ps:2,pe:"①：もう片方の自分のPゾーンに「イグナイト」カードが存在する場合に発動できる。\n自分のPゾーンのカードを全て破壊し、自分のデッキ・墓地から戦士族・炎属性モンスター１体を選んで手札に加える。",tr:"戦士族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:1600,de:300,tx:"とても直情的で行動派なイグナイトの戦士。\n仲間たちからは「鉄砲玉のイーグル」と呼ばれ、少し距離を置かれがちである。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"イグナイト・ウージー",h:"いぐないと・うーじー",e:"Igknight Veteran",ra:"N",t:"monster",a:"炎",l:6,ps:7,pe:"①：もう片方の自分のPゾーンに「イグナイト」カードが存在する場合に発動できる。\n自分のPゾーンのカードを全て破壊し、自分のデッキ・墓地から戦士族・炎属性モンスター１体を選んで手札に加える。",tr:"戦士族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:1300,de:2700,tx:"「デリンジャー」のお目付け役であり親衛隊長。\n無鉄砲な彼女に翻弄されてばかりで、唯一の理解者である「キャリバー」にいつもぼやいている。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"イグナイト・キャリバー",h:"いぐないと・きゃりばー",e:"Igknight Gallant",ra:"N",t:"monster",a:"炎",l:6,ps:2,pe:"①：もう片方の自分のPゾーンに「イグナイト」カードが存在する場合に発動できる。\n自分のPゾーンのカードを全て破壊し、自分のデッキ・墓地から戦士族・炎属性モンスター１体を選んで手札に加える。",tr:"戦士族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:2100,de:2200,tx:"威勢の良すぎるイグナイトたちをまとめる特攻隊長。\n血気盛んですぐ頭に点火してしまう部下たちにいつも悩まされている。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"イグナイト・スティンガー",h:"いぐないと・すてぃんがー",e:"Igknight Champion",ra:"N",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2800,de:2300,tx:"①：自分フィールドの「イグナイト」カード３枚を対象として発動できる。\nそのカードを破壊し、このカードを手札から特殊召喚する。\n②：１ターンに１度、このカード以外の自分フィールドの「イグナイト」モンスター１体を対象として発動できる。\nそのカードを持ち主の手札に戻し、相手フィールドのモンスター１体を選んで持ち主のデッキの一番下に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"イグナイト・デリンジャー",h:"いぐないと・でりんじゃー",e:"Igknight Cavalier",ra:"N",t:"monster",a:"炎",l:5,ps:2,pe:"①：もう片方の自分のPゾーンに「イグナイト」カードが存在する場合に発動できる。\n自分のPゾーンのカードを全て破壊し、自分のデッキ・墓地から戦士族・炎属性モンスター１体を選んで手札に加える。",tr:"戦士族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:2400,de:1200,tx:"仲間たちからちやほやされているイグナイトの紅一点。\n自慢の武器は敵よりも味方を射止める事の方が多いが、文句を言える者は誰もいない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"イグナイト・ドラグノフ",h:"いぐないと・どらぐのふ",e:"Igknight Templar",ra:"N",t:"monster",a:"炎",l:4,ps:7,pe:"①：もう片方の自分のPゾーンに「イグナイト」カードが存在する場合に発動できる。\n自分のPゾーンのカードを全て破壊し、自分のデッキ・墓地から戦士族・炎属性モンスター１体を選んで手札に加える。",tr:"戦士族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:1700,de:1300,tx:"一本気で曲がったことが嫌いなイグナイトのベテラン戦士。\nその性格のせいか、近頃は自分の持つ武器に疑問を抱いているようだ。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"イグナイト・マグナム",h:"いぐないと・まぐなむ",e:"Igknight Squire",ra:"N",t:"monster",a:"炎",l:3,ps:7,pe:"①：もう片方の自分のPゾーンに「イグナイト」カードが存在する場合に発動できる。\n自分のPゾーンのカードを全て破壊し、自分のデッキ・墓地から戦士族・炎属性モンスター１体を選んで手札に加える。",tr:"戦士族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:0,de:2000,tx:"剣銃を操る炎の戦士。\n冷たい鋼鉄の鎧に身を包んでいるが、その奥には激しく燃え上がるような熱い心が秘められている。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"イグナイト・マスケット",h:"いぐないと・ますけっと",e:"Igknight Paladin",ra:"N",t:"monster",a:"炎",l:4,ps:2,pe:"①：もう片方の自分のPゾーンに「イグナイト」カードが存在する場合に発動できる。\n自分のPゾーンのカードを全て破壊し、自分のデッキ・墓地から戦士族・炎属性モンスター１体を選んで手札に加える。",tr:"戦士族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:1400,de:1900,tx:"冷静沈着で理知的と評判のイグナイトの参謀。\n実は頭に血が上るのに時間がかかっているだけで、心の中ではいつもキレ気味らしい。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"イグナイト・ライオット",h:"いぐないと・らいおっと",e:"Igknight Margrave",ra:"N",t:"monster",a:"炎",l:5,ps:7,pe:"①：もう片方の自分のPゾーンに「イグナイト」カードが存在する場合に発動できる。\n自分のPゾーンのカードを全て破壊し、自分のデッキ・墓地から戦士族・炎属性モンスター１体を選んで手札に加える。",tr:"戦士族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:1500,de:2500,tx:"イグナイトの上級戦士。\nところ構わず広範囲をなぎ払う得意の二刀剣銃は、敵だけでなく味方からも怖がられてしまう。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"異国の剣士",h:"いこくのけんし",e:"Swordsman from a Distant Land",ra:"N",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:250,de:250,tx:"「異国の剣士」の攻撃を受けたモンスターは、５ターン後に破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"伊弉凪",h:"いざなぎ",e:"Izanagi",ra:"R",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2200,de:1000,tx:"このカードは手札のスピリットモンスター１体をゲームから除外し、手札から特殊召喚する事ができる。\nこのカードが自分フィールド上に表側表示で存在する限り、自分フィールド上に存在するスピリットモンスターはエンドフェイズ時に手札に戻る効果を発動しなくてもよい。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"伊弉波",h:"いざなみ",e:"Izanami",ra:"R",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"天使族",mo:["スピリット","効果"],ma:"",tp:"",at:1100,de:1800,tx:"このカードは特殊召喚できない。\n召喚・リバースしたターンのエンドフェイズ時に持ち主の手札に戻る。\nこのカードが召喚・リバースした時、手札を１枚捨てる事で自分の墓地に存在するスピリットモンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"異次元からの侵略者",h:"いじげんからのしんりゃくしゃ",e:"Invader from Another Dimension",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:950,de:1400,tx:"銀河系の外から地球にやってきた宇宙人。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"異次元トレーナー",h:"いじげんとれーなー",e:"D.D. Trainer",ra:"SR",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:100,de:2000,tx:"異次元に吸い込まれてしまった哀れなゴブリン。\nしかし、今新たな目標に向かって日々努力している。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"異次元の女戦士",h:"いじげんのおんなせんし",e:"D.D. Warrior Lady",ra:"UR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:1600,tx:"①：このカードが相手モンスターと戦闘を行ったダメージ計算後に発動できる。\nその戦闘を行ったそれぞれのモンスターを除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"異次元の狂獣",h:"いじげんのきょうじゅう",e:"D.D. Crazy Beast",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1400,de:1400,tx:"このカードが戦闘によって破壊したモンスターはゲームから除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"異次元の哨戒機",h:"いじげんのしょうかいき",e:"D.D. Patrol Plane",ra:"SR",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:800,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが除外されたターンのエンドフェイズに発動できる。\n自分の手札・フィールド・墓地のカード１枚を選んで除外し、このカードを攻撃表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"異次元の生還者",h:"いじげんのせいかんしゃ",e:"D.D. Survivor",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:200,tx:"自分フィールド上に表側表示で存在するこのカードがゲームから除外された場合、このカードはエンドフェイズ時にフィールド上に特殊召喚される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"異次元の精霊",h:"いじげんのせいれい",e:"D.D. Sprite",ra:"UR",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"天使族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:100,tx:"①：このカードは自分フィールドの表側表示モンスター１体を除外し、手札から特殊召喚できる。\n②：このカードの①の方法で特殊召喚した場合、次のスタンバイフェイズに発動する。\nこのカードを特殊召喚するために除外したモンスターをフィールドに戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"異次元の戦士",h:"いじげんのせんし",e:"D.D. Warrior",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:1000,tx:"①：このカードがモンスターと戦闘を行ったダメージ計算後に発動する。\nその戦闘を行ったそれぞれのモンスターを除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"異次元の探求者",h:"いじげんのたんきゅうしゃ",e:"D.D. Seeker",ra:"SR",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1500,de:800,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：自分フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターを次のターンのエンドフェイズまで除外する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"異次元の偵察機",h:"いじげんのていさつき",e:"D.D. Scout Plane",ra:"R",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:800,de:1200,tx:"①：このカードが除外されたターンのエンドフェイズに発動する。\n除外されているこのカードを攻撃表示で特殊召喚する（１ターンに１度のみ）。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"異次元の一角戦士",h:"いじげんのゆにこーんないと",e:"D.D. Unicorn Knight",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1800,de:900,tx:"このカードは通常召喚できず、このカードを特殊召喚するターン自分は通常召喚できない。\nこのカードは相手フィールド上にモンスターが存在し、自分フィールド上にチューナーが表側表示で存在する場合に特殊召喚する事ができる。\nこの方法で特殊召喚に成功した時、ゲームから除外されているチューナー以外のレベル３以下の自分のモンスター１体を選択して特殊召喚する事ができる。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"異次元への案内人",h:"いじげんへのあんないにん",e:"D.D. Guide",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1400,de:1000,tx:"このカードの召喚に成功した時、このカードのコントロールは相手に移る。\nエンドフェイズ毎に、相手はこのカードのコントローラーの墓地からカード１枚を選択しゲームから除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"異次元竜 トワイライトゾーンドラゴン",h:"いじげんりゅう　とわいらいとぞーんどらごん",e:"Different Dimension Dragon",ra:"R",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1200,de:1500,tx:"このカードは対象を指定しない魔法・罠カードの効果では破壊されない。\nまた、攻撃力１９００以下のモンスターとの戦闘では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"一撃必殺侍",h:"いちげきひっさつざむらい",e:"Sasuke Samurai #4",ra:"UR",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:1200,tx:"①：このカードが相手モンスターと戦闘を行うダメージステップ開始時に発動する。\nコイントスを１回行い、裏表を当てる。\n当たった場合、その相手モンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"一角獣の使い魔",h:"いっかくじゅうのつかいま",e:"Uni-Horned Familiar",ra:"N",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"獣族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:1000,tx:"表側守備表示で存在するこのカードが攻撃対象に選択された時、このカード以外の自分フィールド上に存在するモンスター１体をゲームから除外する事で、このカードをゲームから除外する事ができる。\nその時の攻撃モンスターは攻撃しなければならない。\nこの効果で除外したこのカードは、次の自分のスタンバイフェイズ時にフィールド上に戻る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"一刀両断侍",h:"いっとうりょうだんざむらい",e:"Sasuke Samurai",ra:"SR",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:500,de:800,tx:"このカードが裏側守備表示のモンスターを攻撃した場合、ダメージ計算を行わず裏側守備表示のままそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"イナゴの軍勢",h:"いなごのぐんぜい",e:"Swarm of Locusts",ra:"SR",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1000,de:500,tx:"このカードは１ターンに１度だけ裏側守備表示にする事ができる。\nこのカードが反転召喚に成功した時、相手フィールド上に存在する魔法・罠カード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"因幡之白兎",h:"いなばのしろうさぎ",e:"Inaba White Rabbit",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["スピリット","効果"],ma:"",tp:"",at:700,de:500,tx:"このカードは特殊召喚できない。\n召喚・リバースしたターンのエンドフェイズ時に持ち主の手札に戻る。\n相手フィールド上にモンスターが存在する場合、このカードの攻撃は相手プレイヤーへの直接攻撃としなければならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"稲荷火",h:"いなりび",e:"Inari Fire",ra:"SR",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:1500,de:200,tx:"①：「稲荷火」は自分フィールドに１体しか表側表示で存在できない。\n②：自分フィールドに魔法使い族モンスターが存在する場合、このカードは手札から特殊召喚できる。\n③：フィールドの表側表示のこのカードが効果で破壊され墓地へ送られた場合、次の自分スタンバイフェイズに発動する。\nこのカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"命ある花瓶",h:"いのちあるかびん",e:"Living Vase",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"植物族",mo:["通常"],ma:"",tp:"",at:900,de:1100,tx:"生けてある花から、花粉を飛ばし噛みついてくる生きている花瓶。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"命の砂時計",h:"いのちのすなどけい",e:"Hourglass of Life",ra:"N",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:700,de:600,tx:"命を司る天使。\n命を短くするかわりに力を与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"命を食する者",h:"いのちをしょくするもの",e:"That Which Feeds on Life",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1200,de:1000,tx:"あらゆる生き物の魂を喰い、己のエネルギーとする悪魔。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"棘の妖精",h:"いばらのようせい",e:"Prickle Fairy",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:300,de:2000,tx:"相手はこのカードを破壊しない限り、昆虫族モンスターを攻撃できない。\nこのカードと戦闘を行ったモンスターは、ダメージステップ終了時に守備表示になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"イビー",h:"いびー",e:"Minar",ra:"SR",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:850,de:750,tx:"このカードが相手のカードの効果によって手札から墓地へ捨てられた時、相手ライフに１０００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"イピリア",h:"いぴりあ",e:"Ipiria",ra:"SR",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:500,de:500,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・反転召喚・特殊召喚に成功した場合に発動する。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"イビリチュア・ジールギガス",h:"いびりちゅあ・じーるぎがす",e:"Gishki Zielgigas",ra:"SR",t:"monster",a:"水",l:10,ps:"",pe:"",tr:"水族",mo:["儀式","効果"],ma:"",tp:"",at:3200,de:0,tx:"「リチュア」と名のついた儀式魔法カードにより降臨。\n１ターンに１度、１０００ライフポイントを払って発動できる。\nデッキからカードを１枚ドローし、お互いに確認する。\n確認したカードが「リチュア」と名のついたモンスターだった場合、フィールド上のカード１枚を選んで持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"イビリチュア・ソウルオーガ",h:"いびりちゅあ・そうるおーが",e:"Evigishki Soul Ogre",ra:"UR",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"水族",mo:["儀式","効果"],ma:"",tp:"",at:2800,de:2800,tx:"「リチュア」と名のついた儀式魔法カードにより降臨。\n１ターンに１度、手札から「リチュア」と名のついたモンスター１体を捨てて発動できる。\n相手フィールド上に表側表示で存在するカード１枚を選択して持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"イビリチュア・テトラオーグル",h:"いびりちゅあ・てとらおーぐる",e:"Evigishki Tetrogre",ra:"R",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"水族",mo:["儀式","効果"],ma:"",tp:"",at:2600,de:2100,tx:"「リチュア」と名のついた儀式魔法カードにより降臨。\n１ターンに１度、カードの種類（モンスター・魔法・罠）を宣言して発動できる。\n相手は手札を１枚捨ててこの効果を無効にできる。\n捨てなかった場合、お互いのプレイヤーは宣言された種類のカード１枚をデッキから墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"イビリチュア・プシュケローネ",h:"いびりちゅあ・ぷしゅけろーね",e:"Gishki Psychelone",ra:"R",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"悪魔族",mo:["儀式","効果"],ma:"",tp:"",at:2150,de:1650,tx:"「リチュア」と名のついた儀式魔法カードにより降臨。\n１ターンに１度、モンスターの種族・属性を宣言して発動できる。\n相手の手札をランダムに１枚確認し、宣言した種族・属性のモンスターだった場合、そのカードを持ち主のデッキに戻す。\n違った場合は元に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"イビリチュア・マインドオーガス",h:"いびりちゅあ・まいんどおーがす",e:"Evigishki Mind Augus",ra:"SR",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"水族",mo:["儀式","効果"],ma:"",tp:"",at:2500,de:2000,tx:"「リチュア」と名のついた儀式魔法カードにより降臨。\nこのカードが儀式召喚に成功した時、お互いの墓地のカードを合計５枚まで選択して持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"イビリチュア・メロウガイスト",h:"いびりちゅあ・めろうがいすと",e:"Evigishki Merrowgeist",ra:"R",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2100,de:1600,tx:"レベル４モンスター×２\nこのカードが戦闘によって相手モンスターを破壊した場合、そのダメージ計算後にこのカードのエクシーズ素材を１つ取り除いて発動できる。\n破壊したそのモンスターを墓地へ送らず持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"イビリチュア・リヴァイアニマ",h:"いびりちゅあ・りう゛ぁいあにま",e:"Evigishki Levianima",ra:"UR",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"水族",mo:["儀式","効果"],ma:"",tp:"",at:2700,de:1500,tx:"「リチュア」と名のついた儀式魔法カードにより降臨。\nこのカードの攻撃宣言時、自分のデッキからカードを１枚ドローし、お互いに確認する。\n確認したカードが「リチュア」と名のついたモンスターだった場合、相手の手札をランダムに１枚確認する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"イビル・ラット",h:"いびる・らっと",e:"Obese Marmot of Nefariousness",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:750,de:800,tx:"どんな物にでもかじりつく、行儀の悪い野ネズミ。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"イマイルカ",h:"いまいるか",e:"Imairuka",ra:"N",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"フィールド上のこのカードが相手によって破壊され墓地へ送られた時、自分のデッキの一番上のカードを墓地へ送る。\n墓地へ送ったカードが水属性モンスターだった場合、自分のデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"イモータル・ルーラー",h:"いもーたる・るーらー",e:"Immortal Ruler",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1800,de:200,tx:"このカードは特殊召喚できない。\n①：このカードをリリースし、自分の墓地の「アンデットワールド」１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻想師・ノー・フェイス",h:"いりゅーじょにすと・のー・ふぇいす",e:"Illusionist Faceless Mage",ra:"N",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:1200,de:2200,tx:"幻影を見せ、ひらりと攻撃をかわす。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"イリュージョン・シープ",h:"いりゅーじょん・しーぷ",e:"Mystical Sheep #1",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1150,de:900,tx:"このカードを融合素材モンスター１体の代わりにする事ができる。\nその際、他の融合素材モンスターは正規のものでなければならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"イリュージョン・スナッチ",h:"いりゅーじょん・すなっち",e:"Illusory Snatcher",ra:"UR",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2400,de:1000,tx:"①：自分がモンスターのアドバンス召喚に成功した時に発動できる。\nこのカードを手札から特殊召喚する。\nこの効果で特殊召喚したこのカードの種族・属性・レベルは、アドバンス召喚したそのモンスターと同じになる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"岩の精霊 タイタン",h:"いわのせいれい　たいたん",e:"The Rock Spirit",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1700,de:1000,tx:"このカードは通常召喚できない。\n自分の墓地に存在する地属性モンスター１体をゲームから除外した場合に特殊召喚する事ができる。\nこのカードの攻撃力は相手のバトルフェイズ中のみ３００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"岩の戦士",h:"いわのせんし",e:"Minomushi Warrior",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["通常"],ma:"",tp:"",at:1300,de:1200,tx:"非常に重たい岩石の剣を振り回す、岩石の戦士。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"インヴェルズ・ガザス",h:"いんう゛ぇるず・がざす",e:"Steelswarm Caucastag",ra:"R",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2800,de:0,tx:"「インヴェルズ」と名のついたモンスター２体をリリースしてこのカードのアドバンス召喚に成功した時、以下の効果から１つを選択して発動する。\n●このカード以外のフィールド上のモンスターを全て破壊する。\n●フィールド上の魔法・罠カードを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"インヴェルズ・ギラファ",h:"いんう゛ぇるず・ぎらふぁ",e:"Steelswarm Girastag",ra:"R",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2600,de:0,tx:"このカードは「インヴェルズ」と名のついたモンスター１体をリリースして表側攻撃表示でアドバンス召喚できる。\n「インヴェルズ」と名のついたモンスターをリリースしてこのカードのアドバンス召喚に成功した時、相手フィールド上のカード１枚を選択して発動できる。\n選択した相手のカードを墓地へ送り、自分は１０００ライフポイント回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"インヴェルズ・グレズ",h:"いんう゛ぇるず・ぐれず",e:"Steelswarm Hercules",ra:"R",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:3200,de:0,tx:"このカードは特殊召喚できない。\nこのカードを通常召喚する場合、自分フィールド上の「インヴェルズ」と名のついたモンスター３体をリリースして召喚しなければならない。\n１ターンに１度、ライフポイントを半分払う事で、このカード以外のフィールド上のカードを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"インヴェルズ・ホーン",h:"いんう゛ぇるず・ほーん",e:"Steelswarm Longhorn",ra:"R",t:"monster",a:"闇",l:9,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:3000,de:0,tx:"「インヴェルズ」と名のついたモンスターをリリースしてこのカードが表側表示でアドバンス召喚に成功した場合、このカードは以下の効果を得る。\n１ターンに１度、１０００ライフポイントを払う事で、フィールド上のモンスター１体を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"インヴェルズ・マディス",h:"いんう゛ぇるず・までぃす",e:"Steelswarm Mantis",ra:"SR",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2200,de:0,tx:"「インヴェルズ」と名のついたモンスターをリリースしてこのカードのアドバンス召喚に成功した時、１０００ライフポイントを払う事で、自分の墓地の「インヴェルズ」と名のついたモンスター１体を選択して特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"インヴェルズ・モース",h:"いんう゛ぇるず・もーす",e:"Steelswarm Moth",ra:"SR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2400,de:0,tx:"「インヴェルズ」と名のついたモンスターをリリースしてこのカードのアドバンス召喚に成功した時、１０００ライフポイントを払う事で、相手フィールド上のカードを２枚まで選択して持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"インヴェルズ・ローチ",h:"いんう゛ぇるず・ろーち",e:"Steelswarm Roach",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1900,de:0,tx:"レベル４モンスター×２\nこのカードのエクシーズ素材を１つ取り除く事で、レベル５以上のモンスターの特殊召喚を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"インヴェルズの斥候",h:"いんう゛ぇるずのせっこう",e:"Steelswarm Scout",ra:"N",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:200,de:0,tx:"自分フィールド上に魔法・罠カードが存在しない場合、自分のメインフェイズ１の開始時にのみ発動できる。\nこのカードを墓地から特殊召喚する。\nこの効果を発動するターン、自分はモンスターを特殊召喚できない。\nこのカードは「インヴェルズ」と名のついたモンスターのアドバンス召喚以外のためにはリリースできず、シンクロ素材にもできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"インヴェルズの先鋭",h:"いんう゛ぇるずのせんえい",e:"Steelswarm Sting",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1850,de:0,tx:"自分フィールド上のこのカードが墓地へ送られた時、フィールド上の儀式・融合・シンクロモンスター１体を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"インヴェルズの歩哨",h:"いんう゛ぇるずのほしょう",e:"Steelswarm Sentinel",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1100,de:0,tx:"このカードがフィールド上に表側攻撃表示で存在する限り、フィールド上のレベル５以上の特殊召喚されたモンスターは効果を発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"インヴェルズの魔細胞",h:"いんう゛ぇるずのまさいぼう",e:"Steelswarm Cell",ra:"N",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"自分フィールド上にモンスターが存在しない場合、このカードは手札から特殊召喚できる。\nこのカードは「インヴェルズ」と名のついたモンスターのアドバンス召喚以外のためにはリリースできず、シンクロ素材にもできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"インヴェルズの門番",h:"いんう゛ぇるずのもんばん",e:"Steelswarm Gatekeeper",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1500,de:1900,tx:"このカードがフィールド上に表側表示で存在する限り、「インヴェルズ」と名のついたモンスターが表側表示でアドバンス召喚に成功したターン、自分は通常召喚に加えて１度だけモンスターを通常召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"インヴェルズ万能態",h:"いんう゛ぇるずばんのうたい",e:"Steelswarm Genome",ra:"N",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1000,de:0,tx:"「インヴェルズ」と名のついたモンスターをアドバンス召喚する場合、このカードは２体分のリリースとする事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"インヴェルズを呼ぶ者",h:"いんう゛ぇるずをよぶもの",e:"Steelswarm Caller",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1700,de:0,tx:"このカードをリリースして「インヴェルズ」と名のついたモンスターのアドバンス召喚に成功した時、デッキからレベル４以下の「インヴェルズ」と名のついたモンスター１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"インセクション",h:"いんせくしょん",e:"Alinsection",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"昆虫族",mo:["通常"],ma:"",tp:"",at:950,de:700,tx:"頭のノコギリの他に、腕もノコギリになっているクワガタ。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"甲虫装機 アーマイゼ",h:"いんぜくたー　あーまいぜ",e:"Inzektor Ant",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:200,de:600,tx:"１ターンに１度、自分の手札・墓地から「甲虫装機」と名のついたモンスター１体を装備カード扱いとしてこのカードに装備できる。\nこのカードが装備カード扱いとして装備されている場合、装備モンスターのレベルは３つ上がり、攻撃力・守備力はこのカードのそれぞれの数値分アップする。\n装備モンスターが破壊される場合、代わりにこのカードを破壊できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"甲虫装機 ウィーグ",h:"いんぜくたー　うぃーぐ",e:"Inzektor Earwig",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"１ターンに１度、自分の手札・墓地から「甲虫装機」と名のついたモンスター１体を装備カード扱いとしてこのカードに装備できる。\nこのカードが装備カード扱いとして装備されている場合、装備モンスターの攻撃力・守備力はこのカードのそれぞれの数値分アップする。\nまた、モンスターに装備されているこのカードが墓地へ送られた時、装備していたモンスターの攻撃力はエンドフェイズ時まで１０００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"甲虫装機 エクサスタッグ",h:"いんぜくたー　えくさすたっぐ",e:"Inzektor Exa-Stag",ra:"R",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"昆虫族",mo:["エクシーズ","効果"],ma:"",tp:"",at:800,de:800,tx:"昆虫族レベル５モンスター×２\n１ターンに１度、このカードのエクシーズ素材を１つ取り除く事で、相手のフィールド上・墓地のモンスター１体を選択して装備カード扱いとしてこのカードに装備する。\nこのカードの攻撃力・守備力は、この効果で装備したモンスターのそれぞれの半分の数値分アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"甲虫装機 エクサビートル",h:"いんぜくたー　えくさびーとる",e:"Inzektor Exa-Beetle",ra:"R",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"昆虫族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1000,de:1000,tx:"レベル６モンスター×２\nこのカードがエクシーズ召喚に成功した時、自分または相手の墓地のモンスター１体を選択し、装備カード扱いとしてこのカードに装備できる。\nこのカードの攻撃力・守備力は、この効果で装備したモンスターのそれぞれの半分の数値分アップする。\nまた、１ターンに１度、このカードのエクシーズ素材を１つ取り除く事で、自分及び相手フィールド上に表側表示で存在するカードを１枚ずつ選択して墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"甲虫装機 ギガウィービル",h:"いんぜくたー　ぎがうぃーびる",e:"Inzektor Giga-Weevil",ra:"SR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:0,de:2600,tx:"このカードは手札から装備カード扱いとして自分フィールド上の「甲虫装機」と名のついたモンスターに装備できる。\nこのカードが装備カード扱いとして装備されている場合、装備モンスターの元々の守備力は２６００になる。\nまた、モンスターに装備されているこのカードが墓地へ送られた場合、自分の墓地から「甲虫装機」と名のついたモンスター１体を選択して特殊召喚できる。\n「甲虫装機 ギガウィービル」のこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"甲虫装機 ギガグリオル",h:"いんぜくたー　ぎがぐりおる",e:"Inzektor Giga-Cricket",ra:"N",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:2000,de:1300,tx:"自分の墓地の昆虫族モンスター１体をゲームから除外する事で、墓地のこのカードを装備カード扱いとして自分フィールド上の「甲虫装機」と名のついたモンスター１体に装備する。\n「甲虫装機 ギガグリオル」のこの効果は１ターンに１度しか使用できない。\nまた、このカードが装備カード扱いとして装備されている場合、装備モンスターの元々の攻撃力は２０００になり、装備モンスターが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"甲虫装機 ギガマンティス",h:"いんぜくたー　ぎがまんてぃす",e:"Inzektor Giga-Mantis",ra:"SR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:2400,de:0,tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：自分フィールドの「甲虫装機」モンスター１体を対象として発動できる。\nこのカードを手札から装備カード扱いとしてその自分の「甲虫装機」モンスターに装備する。\n②：このカードを装備カード扱いとして装備しているモンスターの元々の攻撃力は２４００になる。\n③：モンスターに装備されているこのカードが墓地へ送られた場合、「甲虫装機 ギガマンティス」以外の自分の墓地の「甲虫装機」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"甲虫装機 グルフ",h:"いんぜくたー　ぐるふ",e:"Inzektor Ladybug",ra:"N",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:500,de:100,tx:"１ターンに１度、自分の手札・墓地から「甲虫装機」と名のついたモンスター１体を装備カード扱いとしてこのカードに装備できる。\nこのカードが装備カード扱いとして装備されている場合、装備モンスターのレベルは２つ上がり、攻撃力・守備力はこのカードのそれぞれの数値分アップする。\nまた、装備カード扱いとして装備されているこのカードを墓地へ送る事で、自分フィールド上のモンスター１体を選択し、レベルを２つまで上げる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"甲虫装機 センチピード",h:"いんぜくたー　せんちぴーど",e:"Inzektor Centipede",ra:"UR",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"①：１ターンに１度、自分メインフェイズに発動できる。\n自分の手札・墓地から「甲虫装機」モンスター１体を選び、装備カード扱いとしてこのカードに装備する。\n②：このカードが自分フィールドに存在し、このカードに装備されたカードが自分の墓地へ送られた場合に発動できる。\nデッキから「甲虫装機」カード１枚を手札に加える。\n③：このカードを装備カード扱いとして装備しているモンスターのレベルは３つ上がる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"甲虫装機 ダンセル",h:"いんぜくたー　だんせる",e:"Inzektor Dragonfly",ra:"UR",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1000,de:1800,tx:"①：１ターンに１度、自分メインフェイズに発動できる。\n自分の手札・墓地から「甲虫装機」モンスター１体を選び、装備カード扱いとしてこのカードに装備する。\n②：このカードが自分フィールドに存在し、このカードに装備されたカードが自分の墓地へ送られた場合に発動できる。\nデッキから「甲虫装機 ダンセル」以外の「甲虫装機」モンスター１体を特殊召喚する。\n③：このカードを装備カード扱いとして装備しているモンスターのレベルは３つ上がる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"甲虫装機 ホーネット",h:"いんぜくたー　ほーねっと",e:"Inzektor Hornet",ra:"UR",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:500,de:200,tx:"①：１ターンに１度、自分メインフェイズに発動できる。\n自分の手札・墓地から「甲虫装機」モンスター１体を選び、装備カード扱いとしてこのカードに装備する。\n②：このカードを装備カード扱いとして装備しているモンスターのレベルは３つ上がり、攻撃力・守備力はこのカードのそれぞれの数値分アップする。\n③：モンスターに装備されているこのカードを墓地へ送り、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"甲虫装機 ホッパー",h:"いんぜくたー　ほっぱー",e:"Inzektor Hopper",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1700,de:1400,tx:"１ターンに１度、自分の手札・墓地から「甲虫装機」と名のついたモンスター１体を装備カード扱いとしてこのカードに装備できる。\nこのカードが装備カード扱いとして装備されている場合、装備モンスターのレベルは４つ上がる。\nまた、装備カード扱いとして装備されているこのカードを墓地へ送る事で、このターン装備モンスターは相手プレイヤーに直接攻撃できる。\nこの効果を発動するターン、装備モンスター以外のモンスターは攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"甲虫装機 リュシオル",h:"いんぜくたー　りゅしおる",e:"Inzektor Firefly",ra:"UR",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"１ターンに１度、自分の手札・墓地から「甲虫装機」と名のついたモンスター１体を装備カード扱いとしてこのカードに装備できる。\nこのカードに「甲虫装機」と名のついたカードが装備された場合、相手フィールド上にセットされたカードを全て確認できる。\nまた、このカードが装備カード扱いとして装備されている場合、装備モンスターのレベルは１つ上がり、攻撃力・守備力はこのカードのそれぞれの数値分アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"インセクト・プリンセス",h:"いんせくと・ぷりんせす",e:"Insect Princess",ra:"N",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1900,de:1200,tx:"このカードがフィールド上に表側表示で存在する限り、相手フィールド上に表側表示で存在する昆虫族モンスターは全て攻撃表示になる。\nこのカードが戦闘によって昆虫族モンスターを破壊する度に、このカードの攻撃力は５００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"インセクト女王",h:"いんせくとくいーん",e:"Insect Queen",ra:"UR",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:2200,de:2400,tx:"①：このカードの攻撃力は、フィールドの昆虫族モンスターの数×２００アップする。\n②：このカードの攻撃宣言の際に、自分は自分フィールドの他のモンスター１体をリリースしなければならない。\n③：このカードが戦闘で相手モンスターを破壊したターンのエンドフェイズに発動する。\n自分フィールドに「インセクトモンスタートークン」（昆虫族・地・星１・攻／守１００）１体を攻撃表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"甲虫装甲騎士",h:"いんせくとないと",e:"Insect Knight",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["通常"],ma:"",tp:"",at:1900,de:1500,tx:"昆虫戦士の中でも、エリート中のエリートのみが所属できるという「無死虫団」の精鋭騎士。\n彼らの高い戦闘能力は無視できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"甲化鎧骨格",h:"いんぜくとろん・ぱわーど",e:"Powered Inzektron",ra:"SR",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:1600,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：このカードがS召喚に成功した場合に発動する。\nこのターン、このカードは戦闘・効果では破壊されず、自分が受ける全てのダメージは０になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"インターセプト・デーモン",h:"いんたーせぷと・でーもん",e:"Archfiend Interceptor",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1400,de:1600,tx:"このカードが自分フィールド上に表側攻撃表示で存在する限り、相手モンスターの攻撃宣言時、相手ライフに５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"インプ",h:"いんぷ",e:"Horn Imp",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1300,de:1000,tx:"闇に住む小さなオニ。\n攻撃は意外に強い。\nツノには注意。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"インフィニティ・ダーク",h:"いんふぃにてぃ・だーく",e:"Infinity Dark",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["デュアル","効果"],ma:"",tp:"",at:1500,de:1200,tx:"このカードは墓地またはフィールド上に表側表示で存在する場合、通常モンスターとして扱う。\nフィールド上に表側表示で存在するこのカードを通常召喚扱いとして再度召喚する事で、このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●このカードの攻撃宣言時、相手フィールド上に存在する表側表示モンスター１体の表示形式を変更する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"インフェルニティ・アーチャー",h:"いんふぇるにてぃ・あーちゃー",e:"Infernity Archer",ra:"SR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2000,de:1000,tx:"自分の手札が０枚の場合、このカードは相手プレイヤーに直接攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"インフェルニティ・ガーディアン",h:"いんふぇるにてぃ・がーでぃあん",e:"Infernity Guardian",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1200,de:1700,tx:"自分の手札が０枚の場合、フィールド上に表側表示で存在するこのカードは戦闘及びカードの効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"インフェルニティ・ジェネラル",h:"いんふぇるにてぃ・じぇねらる",e:"Infernity General",ra:"SR",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2700,de:1500,tx:"自分の手札が０枚の場合、自分の墓地に存在するこのカードをゲームから除外し、自分の墓地に存在するレベル３以下の「インフェルニティ」と名のついたモンスター２体を選択して発動できる。\n選択したモンスターを墓地から特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"インフェルニティ・デーモン",h:"いんふぇるにてぃ・でーもん",e:"Infernity Archfiend",ra:"UR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1800,de:1200,tx:"①：手札が０枚の場合にこのカードをドローした時、このカードを相手に見せて発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが特殊召喚に成功した時に発動できる。\nデッキから「インフェルニティ」カード１枚を手札に加える。\nこの効果は自分の手札が０枚の場合に発動と処理ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"インフェルニティ・デス・ドラゴン",h:"いんふぇるにてぃ・です・どらごん",e:"Infernity Doom Dragon",ra:"UR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:3000,de:2400,tx:"闇属性チューナー＋チューナー以外のモンスター１体以上\n自分の手札が０枚の場合、１ターンに１度、相手フィールド上のモンスター１体を選択して発動できる。\n選択した相手モンスターを破壊し、破壊したモンスターの攻撃力の半分のダメージを相手ライフに与える。\nこの効果を発動するターン、このカードは攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"インフェルニティ・デストロイヤー",h:"いんふぇるにてぃ・ですとろいやー",e:"Infernity Destroyer",ra:"UR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2300,de:1000,tx:"自分の手札が０枚の場合、このカードが戦闘によって相手モンスターを破壊し墓地へ送った時、相手ライフに１６００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"インフェルニティ・ドワーフ",h:"いんふぇるにてぃ・どわーふ",e:"Infernity Dwarf",ra:"R",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:800,de:500,tx:"自分の手札が０枚の場合、このカードが自分フィールド上に表側表示で存在する限り、自分フィールド上に存在するモンスターが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"インフェルニティ・ナイト",h:"いんふぇるにてぃ・ないと",e:"Infernity Knight",ra:"SR",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1400,de:400,tx:"フィールド上に存在するこのカードが破壊され墓地へ送られた時、手札を２枚捨てる事でこのカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"インフェルニティ・ネクロマンサー",h:"いんふぇるにてぃ・ねくろまんさー",e:"Infernity Necromancer",ra:"UR",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:2000,tx:"このカードは召喚に成功した時、守備表示になる。\nまた、自分の手札が０枚の場合、このカードは以下の効果を得る。\n１ターンに１度、自分の墓地から「インフェルニティ・ネクロマンサー」以外の「インフェルニティ」と名のついたモンスター１体を選択して特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"インフェルニティ・ビースト",h:"いんふぇるにてぃ・びーすと",e:"Infernity Beast",ra:"R",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"自分の手札が０枚の場合、このカードは以下の効果を得る。\nこのカードが攻撃する場合、相手はダメージステップ終了時まで魔法・罠カードを発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"インフェルニティ・ビートル",h:"いんふぇるにてぃ・びーとる",e:"Infernity Beetle",ra:"SR",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"昆虫族",mo:["チューナー","効果"],ma:"",tp:"",at:1200,de:0,tx:"自分の手札が０枚の場合、このカードをリリースする事で、デッキから「インフェルニティ・ビートル」を２体まで特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"インフェルニティ・ビショップ",h:"いんふぇるにてぃ・びしょっぷ",e:"Infernity Patriarch",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1000,de:2000,tx:"このカード名の①の方法による特殊召喚は１ターンに１度しかできない。\n①：自分の手札がこのカード１枚のみの場合、このカードは手札から特殊召喚できる。\n②：自分の手札が０枚である限り、自分フィールドの「インフェルニティ」モンスターが戦闘・効果で破壊される場合、代わりに墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"インフェルニティ・ミラージュ",h:"いんふぇるにてぃ・みらーじゅ",e:"Infernity Mirage",ra:"UR",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカードは墓地からの特殊召喚はできない。\n自分の手札が０枚の場合、このカードをリリースし、自分の墓地の「インフェルニティ」と名のついたモンスター２体を選択して発動できる。\n選択したモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"インフェルニティ・リベンジャー",h:"いんふぇるにてぃ・りべんじゃー",e:"Infernity Avenger",ra:"SR",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"このカードが墓地に存在し、自分の手札が０枚の場合、「インフェルニティ・リベンジャー」以外の自分フィールド上のモンスターが相手モンスターとの戦闘によって破壊され自分の墓地へ送られた時、このカードを墓地から特殊召喚できる。\nこの効果で特殊召喚したこのカードは、その戦闘によって破壊された自分のモンスターの元々のレベルと同じレベルになる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"インフェルニティ・リローダー",h:"いんふぇるにてぃ・りろーだー",e:"Infernity Randomizer",ra:"R",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:900,de:0,tx:"自分の手札が０枚の場合、１ターンに１度、自分のデッキからカードを１枚ドローする事ができる。\nこの効果でドローしたカードをお互いに確認し、モンスターカードだった場合、そのモンスターのレベル×２００ポイントダメージを相手ライフに与える。\n魔法・罠カードだった場合、自分は５００ポイントダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"インフェルノ",h:"いんふぇるの",e:"Inferno",ra:"UR",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1100,de:1900,tx:"このカードは通常召喚できない。\n自分の墓地から炎属性モンスター１体を除外した場合に特殊召喚できる。\n①：このカードが戦闘で相手モンスターを破壊し墓地へ送った場合に発動する。\n相手に１５００ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"インフェルノ・ハンマー",h:"いんふぇるの・はんまー",e:"Inferno Hammer",ra:"R",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2400,de:0,tx:"①：このカードが戦闘で相手モンスターを破壊し墓地へ送った時、相手フィールドの表側表示モンスター１体を対象として発動できる。\nその相手の表側表示モンスターを裏側守備表示にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"インフェルノクインデーモン",h:"いんふぇるのくいんでーもん",e:"Infernalqueen Archfiend",ra:"N",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:900,de:1500,tx:"このカードのコントローラーは自分のスタンバイフェイズ毎に５００ライフポイントを払う。\nこのカードが相手のコントロールするカードの効果の対象になり、その処理を行う時にサイコロを１回振る。\n２・５が出た場合、その効果を無効にし破壊する。\nこのカードがフィールド上に存在する限り、スタンバイフェイズ毎に「デーモン」という名のついたモンスターカード１体の攻撃力をエンドフェイズまで１０００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"インフォーマー・スパイダー",h:"いんふぉーまー・すぱいだー",e:"Informer Spider",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:500,de:1800,tx:"フィールド上に存在するこのカードがカードの効果によって墓地へ送られた時、相手フィールド上に守備表示で存在するモンスター１体のコントロールを得る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"インフルーエンス・ドラゴン",h:"いんふるーえんす・どらごん",e:"Influence Dragon",ra:"N",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["チューナー","効果"],ma:"",tp:"",at:300,de:900,tx:"１ターンに１度、自分フィールド上に表側表示で存在するモンスター１体を選択して発動する事ができる。\n選択したモンスターはエンドフェイズ時までドラゴン族になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴァイロン・アルファ",h:"う゛ぁいろん・あるふぁ",e:"Vylon Alpha",ra:"SR",t:"monster",a:"光",l:9,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:2200,de:1100,tx:"「ヴァイロン」と名のついたチューナー＋チューナー以外のモンスター１体以上\nこのカードがシンクロ召喚に成功した時、自分の墓地の装備魔法カード１枚を選択してこのカードに装備できる。\n装備カードを装備したこのカードは、装備カード以外の魔法・罠カードの効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴァイロン・ヴァンガード",h:"う゛ぁいろん・う゛ぁんがーど",e:"Vylon Vanguard",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1400,de:1000,tx:"このカードがカードの効果によって破壊され墓地へ送られた時、このカードに装備されていた装備カードの数だけデッキからカードをドローできる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴァイロン・エプシロン",h:"う゛ぁいろん・えぷしろん",e:"Vylon Epsilon",ra:"SR",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"天使族",mo:["シンクロ","効果"],ma:"",tp:"",at:2800,de:1200,tx:"光属性チューナー＋チューナー以外のモンスター１体以上\nこのカードに装備された装備カードは、カードの効果の対象にならない。\nまた、１ターンに１度、このカードに装備された装備カード１枚を墓地へ送って発動できる。\n相手フィールド上のモンスター１体を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴァイロン・オーム",h:"う゛ぁいろん・おーむ",e:"Vylon Ohm",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1500,de:200,tx:"このカードが召喚に成功した時、自分の墓地に存在する装備魔法カード１枚を選択し、ゲームから除外する。\n次の自分のスタンバイフェイズ時にそのカードを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴァイロン・オメガ",h:"う゛ぁいろん・おめが",e:"Vylon Omega",ra:"UR",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"天使族",mo:["シンクロ","効果"],ma:"",tp:"",at:3200,de:1900,tx:"チューナー２体＋チューナー以外の「ヴァイロン」と名のついたモンスター１体\nこのカードがシンクロ召喚に成功した時、フィールド上に表側表示で存在する通常召喚されたモンスターを全て破壊する。\n１ターンに１度、自分の墓地の「ヴァイロン」と名のついたモンスター１体を選択し、装備カード扱いとしてこのカードに装備できる。\n効果モンスターの効果が発動した時、このカードに装備された装備カード１枚を墓地へ送る事で、その発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴァイロン・キューブ",h:"う゛ぁいろん・きゅーぶ",e:"Vylon Cube",ra:"R",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:800,tx:"このカードが光属性モンスターのシンクロ召喚に使用され墓地へ送られた場合、デッキから装備魔法カード１枚を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴァイロン・シグマ",h:"う゛ぁいろん・しぐま",e:"Vylon Sigma",ra:"R",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"天使族",mo:["シンクロ","効果"],ma:"",tp:"",at:1800,de:1000,tx:"光属性チューナー＋チューナー以外の光属性モンスター１体以上\n自分フィールド上にこのカード以外のモンスターが存在しない場合、このカードの攻撃宣言時に発動できる。\nデッキから装備魔法カード１枚を選んでこのカードに装備する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴァイロン・スティグマ",h:"う゛ぁいろん・すてぃぐま",e:"Vylon Stigma",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"１ターンに１度、自分フィールド上の「ヴァイロン」と名のついた装備カード１枚を、別の正しい対象となるモンスター１体に移し替える事ができる。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴァイロン・ステラ",h:"う゛ぁいろん・すてら",e:"Vylon Stella",ra:"R",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"天使族",mo:["チューナー","効果"],ma:"",tp:"",at:1400,de:200,tx:"このカードがモンスターカードゾーン上から墓地へ送られた場合、５００ライフポイントを払う事で、このカードを装備カード扱いとして自分フィールド上のモンスター１体に装備する。\nこのカードが装備カード扱いとして装備されている場合、装備モンスターと戦闘を行った相手モンスターを、そのダメージステップ終了時に破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴァイロン・スフィア",h:"う゛ぁいろん・すふぃあ",e:"Vylon Sphere",ra:"SR",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:400,de:400,tx:"①：このカードがモンスターゾーンから墓地へ送られた場合、５００LPを払い、自分フィールドの表側表示モンスター１体を対象として発動できる。\nこのカードを装備カード扱いとしてその自分のモンスターに装備する。\n②：装備カード扱いのこのカードを墓地へ送り、このカードを装備していたモンスターが装備可能な自分の墓地の装備魔法カード１枚を対象として発動できる。\nこのカードを装備していたモンスターに対象のカードを装備する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴァイロン・ソルジャー",h:"う゛ぁいろん・そるじゃー",e:"Vylon Soldier",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"このカードの攻撃宣言時、このカードに装備された装備カードの数まで相手フィールド上のモンスターを選択し、表示形式を変更できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴァイロン・チャージャー",h:"う゛ぁいろん・ちゃーじゃー",e:"Vylon Charger",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードがフィールド上に表側表示で存在する限り、自分フィールド上の光属性モンスターの攻撃力は、このカードに装備された装備カードの数×３００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴァイロン・ディシグマ",h:"う゛ぁいろん・でぃしぐま",e:"Vylon Disigma",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2500,de:2100,tx:"レベル４モンスター×３\n１ターンに１度、このカードのエクシーズ素材を１つ取り除いて発動できる。\n相手フィールド上に表側攻撃表示で存在する効果モンスター１体を選択し、装備カード扱いとしてこのカードに装備する。\nこのカードが、この効果で装備したモンスターカードと同じ属性のモンスターと戦闘を行う場合、ダメージ計算を行わずそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴァイロン・テセラクト",h:"う゛ぁいろん・てせらくと",e:"Vylon Tesseract",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["ユニオン","効果"],ma:"",tp:"",at:800,de:600,tx:"１ターンに１度、自分のメインフェイズ時に装備カード扱いとして自分フィールド上の「ヴァイロン」と名のついたモンスターに装備、または装備を解除して表側攻撃表示で特殊召喚できる。\nこの効果で装備カード扱いになっている場合のみ、装備モンスターが戦闘によって相手モンスターを破壊した場合、自分の墓地からレベル４以下の「ヴァイロン」と名のついたモンスター１体を選択して特殊召喚できる。\n（１体のモンスターが装備できるユニオンは１枚まで。\n装備モンスターが破壊される場合、代わりにこのカードを破壊する。\n）",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴァイロン・テトラ",h:"う゛ぁいろん・てとら",e:"Vylon Tetra",ra:"SR",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:900,de:900,tx:"このカードがモンスターカードゾーン上から墓地へ送られた場合、５００ライフポイントを払う事で、このカードを装備カード扱いとして自分フィールド上のモンスター１体に装備する。\nこのカードを装備したモンスターが破壊される場合、代わりにこのカードを破壊できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴァイロン・デルタ",h:"う゛ぁいろん・でるた",e:"Vylon Delta",ra:"SR",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"天使族",mo:["シンクロ","効果"],ma:"",tp:"",at:1700,de:2800,tx:"チューナー＋チューナー以外の光属性モンスター１体以上\nこのカードが表側守備表示で存在する場合、自分のエンドフェイズ時に自分のデッキから装備魔法カード１枚を選択して手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴァイロン・ハプト",h:"う゛ぁいろん・はぷと",e:"Vylon Hept",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1800,de:800,tx:"１ターンに１度、自分フィールド上の装備カード扱いの「ヴァイロン」と名のついたモンスターカード１枚を選択し、表側守備表示で特殊召喚できる。\nこの効果で特殊召喚したモンスターは、フィールド上から離れた場合ゲームから除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴァイロン・プリズム",h:"う゛ぁいろん・ぷりずむ",e:"Vylon Prism",ra:"UR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"雷族",mo:["チューナー","効果"],ma:"",tp:"",at:1500,de:1500,tx:"このカードがモンスターカードゾーン上から墓地へ送られた場合、５００ライフポイントを払って発動できる。\nこのカードを装備カード扱いとして自分フィールド上のモンスター１体に装備する。\nこのカードが装備カード扱いとして装備されている場合、装備モンスターが戦闘を行うダメージステップの間、その攻撃力は１０００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴァイロン・ペンタクロ",h:"う゛ぁいろん・ぺんたくろ",e:"Vylon Pentachloro",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["ユニオン","効果"],ma:"",tp:"",at:500,de:400,tx:"１ターンに１度、自分のメインフェイズ時に装備カード扱いとして自分フィールド上の「ヴァイロン」と名のついたモンスターに装備、または装備を解除して表側攻撃表示で特殊召喚できる。\nこの効果で装備カード扱いになっている場合のみ、装備モンスターが戦闘によって相手モンスターを破壊した場合、相手フィールド上のカード１枚を選択して破壊できる。\n（１体のモンスターが装備できるユニオンは１枚まで。\n装備モンスターが破壊される場合、代わりにこのカードを破壊する。\n）",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴァリュアブル・アーマー",h:"う゛ぁりゅあぶる・あーまー",e:"Grasschopper",ra:"SR",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"昆虫族",mo:["デュアル","効果"],ma:"",tp:"",at:2350,de:1000,tx:"このカードは墓地またはフィールド上に表側表示で存在する場合、通常モンスターとして扱う。\nフィールド上に表側表示で存在するこのカードを通常召喚扱いとして再度召喚する事で、このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●このカードは相手フィールド上の全てのモンスターに１回ずつ攻撃をする事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴァルキュルスの影霊衣",h:"う゛ぁるきゅるすのねくろす",e:"Nekroz of Valkyrus",ra:"R",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"魔法使い族",mo:["儀式","効果"],ma:"",tp:"",at:2900,de:1700,tx:"「影霊衣」儀式魔法カードにより降臨。\nレベル８以外のモンスターのみを使用した儀式召喚でしか特殊召喚できない。\n「ヴァルキュルスの影霊衣」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手モンスターの攻撃宣言時に自分の墓地の「影霊衣」カード１枚を除外し、このカードを手札から捨てて発動できる。\nその攻撃を無効にし、その後バトルフェイズを終了する。\n②：自分メインフェイズに発動できる。\n自分の手札・フィールドのモンスターを２体までリリースし、リリースした数だけ自分はデッキからドローする。",li:1,lk:[],ta:[],al:[],ocg:true},
  {n:"ヴァルキリー",h:"う゛ぁるきりー",e:"Dark Witch",ra:"N",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:1800,de:1700,tx:"神話に出てくる闘いの天使。\n手にする槍で天罰を下す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴァンパイア・キラー",h:"う゛ぁんぱいあ・きらー",e:"Vampire Hunter",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1600,tx:"このカードが闇属性モンスターと戦闘を行う場合、ダメージ計算を行わずそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴァンパイア・グリムゾン",h:"う゛ぁんぱいあ・ぐりむぞん",e:"Vampire Grimson",ra:"N",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:2000,de:1400,tx:"①：自分フィールドのモンスターが戦闘または相手の効果で破壊される場合、代わりにその破壊されるモンスターの数×１０００LPを払う事ができる。\n②：このカードが戦闘でモンスターを破壊したバトルフェイズ終了時に発動できる。\nそのモンスターを墓地から可能な限り自分フィールドに特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴァンパイア・グレイス",h:"う゛ぁんぱいあ・ぐれいす",e:"Vampire Grace",ra:"R",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:2000,de:1200,tx:"このカードが墓地に存在し、アンデット族モンスターの効果によって自分フィールド上にレベル５以上のアンデット族モンスターが特殊召喚された時、２０００ライフポイントを払って発動できる。\nこのカードを墓地から特殊召喚する。\n「ヴァンパイア・グレイス」のこの効果は１ターンに１度しか使用できない。\nまた、１ターンに１度、カードの種類（モンスター・魔法・罠）を宣言して発動できる。\n相手は宣言された種類のカード１枚をデッキから墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴァンパイア・スカージレット",h:"う゛ぁんぱいあ・すかーじれっと",e:"Vampire Scarlet Scourge",ra:"UR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:2200,de:2200,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合、１０００LPを払い、「ヴァンパイア・スカージレット」以外の自分の墓地の「ヴァンパイア」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターはこのターン攻撃できない。\n②：このカードが戦闘でモンスターを破壊したバトルフェイズ終了時に発動できる。\nそのモンスターを墓地から可能な限り自分フィールドに特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴァンパイア・ソーサラー",h:"う゛ぁんぱいあ・そーさらー",e:"Vampire Sorcerer",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1500,de:1500,tx:"①：このカードが相手によって墓地へ送られた場合に発動できる。\nデッキから闇属性の「ヴァンパイア」モンスター１体または「ヴァンパイア」魔法・罠カード１枚を手札に加える。\n②：墓地のこのカードを除外して発動できる。\nこのターンに１度だけ、自分はレベル５以上の闇属性の「ヴァンパイア」モンスターを召喚する場合に必要なリリースをなくす事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴァンパイア・デューク",h:"う゛ぁんぱいあ・でゅーく",e:"Vampire Duke",ra:"UR",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:2000,de:0,tx:"「ヴァンパイア・デューク」の②の効果は１ターンに１度しか使用できない。\nこのカードをX召喚の素材とする場合、闇属性モンスターのX召喚にしか使用できない。\n①：このカードが召喚に成功した時、自分の墓地の闇属性の「ヴァンパイア」モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\n②：このカードが特殊召喚に成功した時、カードの種類（モンスター・魔法・罠）を宣言して発動できる。\n相手は宣言された種類のカード１枚をデッキから墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴァンパイア・ドラゴン",h:"う゛ぁんぱいあ・どらごん",e:"Vampire Dragon",ra:"N",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:2400,de:0,tx:"アドバンス召喚したこのカードがフィールド上から墓地へ送られた時、デッキからレベル４以下のモンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴァンパイア・ベビー",h:"う゛ぁんぱいあ・べびー",e:"Vampire Baby",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:700,de:1000,tx:"このカードが戦闘によってモンスターを破壊したバトルフェイズ終了時、墓地に存在するそのモンスターを自分フィールド上に特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴァンパイア・レッドバロン",h:"う゛ぁんぱいあ・れっどばろん",e:"Vampire Red Baron",ra:"R",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:2400,de:1000,tx:"①：１ターンに１度、１０００LPを払い、相手フィールドのモンスター１体とこのカード以外の自分フィールドの「ヴァンパイア」モンスター１体を対象として発動できる。\nそのモンスター２体のコントロールを入れ替える。\n②：このカードが戦闘でモンスターを破壊したバトルフェイズ終了時に発動できる。\nそのモンスターを墓地から可能な限り自分フィールドに特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴァンパイア・レディ",h:"う゛ぁんぱいあ・れでぃ",e:"Vampire Lady",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1550,de:1550,tx:"このカードが相手プレイヤーに戦闘ダメージを与える度に、カードの種類（モンスター、魔法、罠）を宣言する。\n相手はデッキからその種類のカード１枚を選択して墓地に送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴァンパイア・ロード",h:"う゛ぁんぱいあ・ろーど",e:"Vampire Lord",ra:"UR",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:2000,de:1500,tx:"このカードが相手ライフに戦闘ダメージを与えた時、カードの種類（モンスター・魔法・罠）を宣言する。\n相手は宣言された種類のカード１枚をデッキから墓地へ送る。\nまた、このカードが相手のカードの効果によって破壊され墓地へ送られた場合、次の自分のスタンバイフェイズ時にこのカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴァンパイアジェネシス",h:"う゛ぁんぱいあじぇねしす",e:"Vampire Genesis",ra:"R",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"アンデット族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3000,de:2100,tx:"このカードは通常召喚できない。\n自分フィールド上に存在する「ヴァンパイア・ロード」１体をゲームから除外した場合のみ特殊召喚する事ができる。\n１ターンに１度、手札からアンデット族モンスター１体を墓地に捨てる事で、捨てたアンデット族モンスターよりレベルの低いアンデット族モンスター１体を自分の墓地から選択して特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴァンパイアの眷属",h:"う゛ぁんぱいあのけんぞく",e:"Vampire Retainer",ra:"SR",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1200,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合、５００LPを払って発動できる。\nデッキから「ヴァンパイア」魔法・罠カード１枚を手札に加える。\n②：このカードが墓地に存在する場合、手札及び自分フィールドの表側表示のカードの中から、「ヴァンパイア」カード１枚を墓地へ送って発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴァンパイアの使い魔",h:"う゛ぁんぱいあのつかいま",e:"Vampire Familiar",ra:"SR",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:500,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合、５００LPを払って発動できる。\nデッキから「ヴァンパイアの使い魔」以外の「ヴァンパイア」モンスター１体を手札に加える。\n②：このカードが墓地に存在する場合、手札及び自分フィールドの表側表示のカードの中から、「ヴァンパイア」カード１枚を墓地へ送って発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴァンプ・オブ・ヴァンパイア",h:"う゛ぁんぷ・おぶ・う゛ぁんぱいあ",e:"Vampire Vamp",ra:"R",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:2000,de:2000,tx:"①：このカードが召喚に成功した時、または自分フィールドに「ヴァンパイア」モンスターが召喚された時に、このカードより攻撃力が高い相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターを装備カード扱いとしてこのカードに装備する。\nこの効果は１ターンに１度しか使用できない。\n②：このカードの攻撃力は、このカードの効果で装備したモンスターの元々の攻撃力分アップする。\n③：このカードの効果で装備カードを装備したこのカードが墓地へ送られた場合に発動する。\nこのカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"V－タイガー・ジェット",h:"う゛ぃ－たいがー・じぇっと",e:"V-Tiger Jet",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1600,de:1800,tx:"空中戦を得意とする、合体能力を持つモンスター。\n合体と分離を駆使して立体的な攻撃を繰り出す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴィークラー",h:"う゛ぃーくらー",e:"Bicular",ra:"R",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:200,de:200,tx:"このカードが戦闘によって破壊され墓地へ送られた時、自分の手札またはデッキから「アンサイクラー」１体を自分フィールド上に特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴィシュワ・ランディー",h:"う゛ぃしゅわ・らんでぃー",e:"Vishwar Randi",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:900,de:700,tx:"闇に仕える女戦士。\n相手を血祭りにあげることが生きがい。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"V・HERO アドレイション",h:"う゛ぃじょんひーろー　あどれいしょん",e:"Vision HERO Adoration",ra:"SR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2800,de:2100,tx:"「HERO」モンスター×２\n①：１ターンに１度、相手フィールドの表側表示モンスター１体と、このカード以外の自分フィールドの「HERO」モンスター１体を対象として発動できる。\nその相手モンスターの攻撃力・守備力はターン終了時まで、その自分のモンスターの攻撃力分ダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"V・HERO インクリース",h:"う゛ぃじょんひーろー　いんくりーす",e:"Vision HERO Increase",ra:"SR",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:900,de:1100,tx:"このカード名の①②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分が戦闘・効果でダメージを受けた場合に発動できる。\n墓地のこのカードを永続罠カード扱いで自分の魔法＆罠ゾーンに表側表示で置く。\n②：このカードが永続罠カード扱いの場合、お互いのメインフェイズに、自分フィールドの「HERO」モンスター１体をリリースして発動できる。\nこのカードを特殊召喚する。\n③：このカードが魔法＆罠ゾーンからの特殊召喚に成功した場合に発動できる。\nデッキからレベル４以下の「V・HERO」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"V・HERO ヴァイオン",h:"う゛ぃじょんひーろー　う゛ぁいおん",e:"Vision HERO Vyon",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:1200,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「HERO」モンスター１体を墓地へ送る。\n②：１ターンに１度、自分の墓地から「HERO」モンスター１体を除外して発動できる。\nデッキから「融合」１枚を手札に加える。",li:2,lk:[],ta:[],al:[],ocg:true},
  {n:"V・HERO ウィッチ・レイド",h:"う゛ぃじょんひーろー　うぃっち・れいど",e:"Vision HERO Witch Raider",ra:"UR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2700,de:1900,tx:"このカードを表側表示でアドバンス召喚する場合、モンスターの代わりに自分フィールドの罠カードをリリースできる。\n①：このカードが召喚に成功した時に発動できる。\n相手フィールドの魔法・罠カードを全て破壊する。\nこの効果を発動するターン、自分は「HERO」モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"V・HERO グラビート",h:"う゛ぃじょんひーろー　ぐらびーと",e:"Vision HERO Gravito",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:500,de:2000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合、除外されている自分の「HERO」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\n②：このカードをリリースし、自分の魔法＆罠ゾーンの「V・HERO」モンスターカード２枚を対象として発動できる。\nそのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"V・HERO トリニティー",h:"う゛ぃじょんひーろー　とりにてぃー",e:"Vision HERO Trinity",ra:"R",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2500,de:2000,tx:"「HERO」モンスター×３\n①：このカードは直接攻撃できない。\n②：このカードが融合召喚に成功したターン、このカードの攻撃力は元々の攻撃力の倍になる。\n③：融合召喚したこのカードは１度のバトルフェイズ中に３回攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"V・HERO ファリス",h:"う゛ぃじょんひーろー　ふぁりす",e:"Vision HERO Faris",ra:"UR",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札からこのカード以外の「HERO」モンスター１体を捨てて発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「V・HERO ファリス」以外の「V・HERO」モンスター１体を選び、永続罠カード扱いで自分の魔法＆罠ゾーンに表側表示で置く。\nこの効果の発動後、ターン終了時まで自分は「HERO」モンスターしかEXデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"V・HERO ポイズナー",h:"う゛ぃじょんひーろー　ぽいずなー",e:"Vision HERO Poisoner",ra:"SR",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:900,de:700,tx:"このカード名の①②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分が戦闘・効果でダメージを受けた場合に発動できる。\n墓地のこのカードを永続罠カード扱いで自分の魔法＆罠ゾーンに表側表示で置く。\n②：このカードが永続罠カード扱いの場合、お互いのメインフェイズに、自分フィールドの「HERO」モンスター１体をリリースして発動できる。\nこのカードを特殊召喚する。\n③：このカードが魔法＆罠ゾーンからの特殊召喚に成功した場合に発動できる。\nフィールドのモンスター１体を選び、その攻撃力を半分にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"V・HERO マルティプリ・ガイ",h:"う゛ぃじょんひーろー　まるてぃぷり・がい",e:"Vision HERO Multiply Guy",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:800,de:700,tx:"このカード名の①②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分が戦闘・効果でダメージを受けた場合に発動できる。\n墓地のこのカードを永続罠カード扱いで自分の魔法＆罠ゾーンに表側表示で置く。\n②：このカードが永続罠カード扱いの場合、お互いのメインフェイズに、自分フィールドの「HERO」モンスター１体をリリースして発動できる。\nこのカードを特殊召喚する。\n③：このカードが魔法＆罠ゾーンからの特殊召喚に成功した場合に発動できる。\nフィールドのモンスター１体を選び、その攻撃力を８００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"V・HERO ミニマム・レイ",h:"う゛ぃじょんひーろー　みにまむ・れい",e:"Vision HERO Minimum Ray",ra:"R",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:700,tx:"このカード名の①②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分が戦闘・効果でダメージを受けた場合に発動できる。\n墓地のこのカードを永続罠カード扱いで自分の魔法＆罠ゾーンに表側表示で置く。\n②：このカードが永続罠カード扱いの場合、お互いのメインフェイズに、自分フィールドの「HERO」モンスター１体をリリースして発動できる。\nこのカードを特殊召喚する。\n③：このカードが魔法＆罠ゾーンからの特殊召喚に成功した場合に発動できる。\n相手フィールドのレベル４以下のモンスター１体を選んで破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴィジョン・リチュア",h:"う゛ぃじょん・りちゅあ",e:"Gishki Vision",ra:"SR",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:700,de:500,tx:"水属性の儀式モンスターを特殊召喚する場合、このカード１枚で儀式召喚のためのリリースとして使用できる。\nまた、手札からこのカードを捨てて発動できる。\nデッキから「リチュア」と名のついた儀式モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"VW－タイガー・カタパルト",h:"う゛ぃだぶる－たいがー・かたぱると",e:"VW-Tiger Catapult",ra:"R",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:2000,de:2100,tx:"「V－タイガー・ジェット」＋「W－ウィング・カタパルト」\n自分フィールドの上記カードを除外した場合のみ、エクストラデッキから特殊召喚できる（「融合」は必要としない）。\n①：手札を１枚捨て、相手フィールドのモンスター１体を対象として発動できる。\nその相手モンスターの表示形式を変更する。\nこの時、リバースモンスターの効果は発動しない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"VWXYZ－ドラゴン・カタパルトキャノン",h:"う゛ぃとぅずぃ－どらごん・かたぱるときゃのん",e:"VWXYZ-Dragon Catapult Cannon",ra:"UR",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:3000,de:2800,tx:"「VW－タイガー・カタパルト」＋「XYZ－ドラゴン・キャノン」\n自分フィールドの上記カードを除外した場合のみ、EXデッキから特殊召喚できる（「融合」は必要としない）。\n①：１ターンに１度、相手フィールドのカード１枚を対象として発動できる。\nその相手のカードを除外する。\n②：このカードが相手モンスターに攻撃宣言した時、その攻撃対象モンスター１体を対象として発動できる。\nその攻撃対象モンスターの表示形式を変更する。\nこの時、リバースモンスターの効果は発動しない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴェノム・コブラ",h:"う゛ぇのむ・こぶら",e:"Venom Cobra",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["通常"],ma:"",tp:"",at:100,de:2000,tx:"堅いウロコに覆われた巨大なコブラ。\n大量の毒液を射出して攻撃するが、その巨大さ故毒液は大味である。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴェノム・サーペント",h:"う゛ぇのむ・さーぺんと",e:"Venom Serpent",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1000,de:800,tx:"１ターンに１度だけ、相手フィールド上モンスター１体にヴェノムカウンターを１つ置く事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴェノム・スネーク",h:"う゛ぇのむ・すねーく",e:"Venom Snake",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1200,de:600,tx:"１ターンに１度だけ、相手フィールド上モンスター１体にヴェノムカウンターを１つ置く事ができる。\nこの効果を使用したターンこのモンスターは攻撃宣言をする事ができない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴェノム・ボア",h:"う゛ぇのむ・ぼあ",e:"Venom Boa",ra:"N",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"１ターンに１度だけ、相手フィールド上モンスター１体にヴェノムカウンターを２つ置く事ができる。\nこの効果を使用したターンこのモンスターは攻撃宣言をする事ができない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴェルズ・アザトホース",h:"う゛ぇるず・あざとほーす",e:"Evilswarm Azzathoth",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["リバース","効果"],ma:"",tp:"",at:750,de:1950,tx:"リバース：フィールド上の特殊召喚されたモンスター１体を選択して持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴェルズ・ウロボロス",h:"う゛ぇるず・うろぼろす",e:"Evilswarm Ouroboros",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2750,de:1950,tx:"レベル４モンスター×３\n１ターンに１度、このカードのエクシーズ素材を１つ取り除き、以下の効果から１つを選択して発動できる。\n以下の効果はこのカードがフィールド上に表側表示で存在する限りそれぞれ１度しか選択できない。\n\n●相手フィールド上に存在するカード１枚を選択して持ち主の手札に戻す。\n\n●相手の手札をランダムに１枚選んで墓地へ送る。\n\n●相手の墓地に存在するカード１枚を選択してゲームから除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴェルズ・オ・ウィスプ",h:"う゛ぇるず・お・うぃすぷ",e:"Evilswarm Obliviwisp",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:450,de:2050,tx:"このカードと戦闘を行った効果モンスターの効果をダメージ計算後に無効化する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴェルズ・オランタ",h:"う゛ぇるず・おらんた",e:"Evilswarm O'lantern",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:1650,de:1250,tx:"このカードをリリースして発動できる。\n相手フィールド上に表側表示で存在するモンスター１体を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴェルズ・カイトス",h:"う゛ぇるず・かいとす",e:"Evilswarm Ketos",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1750,de:1050,tx:"このカードをリリースして発動できる。\n相手フィールド上の魔法・罠カード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴェルズ・カストル",h:"う゛ぇるず・かすとる",e:"Evilswarm Castor",ra:"UR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1750,de:550,tx:"このカードが召喚に成功したターン、自分は通常召喚に加えて１度だけ「ヴェルズ」と名のついたモンスター１体を召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴェルズ・ケルキオン",h:"う゛ぇるず・けるきおん",e:"Evilswarm Kerykeion",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1600,de:1550,tx:"このカード名の①②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の墓地の「ヴェルズ」モンスター１体を除外し、自分の墓地の「ヴェルズ」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\n②：このカードの①の効果を適用したターンのメインフェイズに発動できる。\n「ヴェルズ」モンスター１体を召喚する。\n③：このカードが墓地へ送られたターン、「ヴェルズ」モンスターを召喚する場合に必要なリリースを１体少なくできる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴェルズ・ゴーレム",h:"う゛ぇるず・ごーれむ",e:"Evilswarm Golem",ra:"SR",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:2150,de:1250,tx:"１ターンに１度、フィールド上に表側表示で存在する、闇属性以外のレベル５以上のモンスター１体を選択して破壊できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴェルズ・コッペリアル",h:"う゛ぇるず・こっぺりある",e:"Evilswarm Coppelia",ra:"UR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2450,de:2050,tx:"このカードは特殊召喚できない。\nこのカードが相手によってフィールド上から離れた時、相手フィールド上に表側表示で存在するモンスター１体を選択して次の自分のエンドフェイズ時までコントロールを得る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴェルズ・ザッハーク",h:"う゛ぇるず・ざっはーく",e:"Evilswarm Zahak",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1850,de:850,tx:"①：フィールドの表側表示のこのカードが相手によって破壊され墓地へ送られた場合、フィールドの特殊召喚されたレベル５以上のモンスター１体を対象として発動する。\nそのレベル５以上のモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴェルズ・サラマンドラ",h:"う゛ぇるず・さらまんどら",e:"Evilswarm Salamandra",ra:"UR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1850,de:950,tx:"①：自分の墓地からモンスター１体を除外して発動できる。\nこのカードの攻撃力は相手ターン終了時まで３００アップする。\nこの効果は１ターンに２度まで使用できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴェルズ・タナトス",h:"う゛ぇるず・たなとす",e:"Evilswarm Thanatos",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2350,de:1350,tx:"闇属性レベル４モンスター×２\n１ターンに１度、このカードのエクシーズ素材を１つ取り除いて発動できる。\nこのターン、このカードはこのカード以外のモンスターの効果を受けない。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴェルズ・バハムート",h:"う゛ぇるず・ばはむーと",e:"Evilswarm Bahamut",ra:"UR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2350,de:1350,tx:"「ヴェルズ」と名のついたレベル４モンスター×２\n１ターンに１度、このカードのエクシーズ素材を１つ取り除き、相手フィールド上に表側表示で存在するモンスター１体を選択して発動できる。\n手札から「ヴェルズ」と名のついたモンスター１体を捨て、選択した相手モンスターのコントロールを得る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴェルズ・フレイス",h:"う゛ぇるず・ふれいす",e:"Evilswarm Hraesvelg",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["リバース","効果"],ma:"",tp:"",at:1150,de:1850,tx:"リバース：相手フィールド上に表側表示で存在するカード１枚を選択して持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴェルズ・ヘリオロープ",h:"う゛ぇるず・へりおろーぷ",e:"Evilswarm Heliotrope",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"岩石族",mo:["通常"],ma:"",tp:"",at:1950,de:650,tx:"ルメトモ ヲンエウユシ ツメハ イカハ ンネヤジルナウコウス ノズルエヴンイ イシマタノラレワ ルナクアヤジ テシニイスンユジ",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴェルズ・マンドラゴ",h:"う゛ぇるず・まんどらご",e:"Evilswarm Mandragora",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1550,de:1450,tx:"①：相手フィールドのモンスターの数が自分フィールドのモンスターより多い場合、このカードは手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴェンデット・アニマ",h:"う゛ぇんでっと・あにま",e:"Vendread Anima",ra:"N",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：墓地のこのカードを除外し、「ヴェンデット・アニマ」以外の除外されている自分の「ヴェンデット」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果の発動後、ターン終了時まで自分はアンデット族モンスターしか特殊召喚できない。\n②：フィールドのこのカードを使用して儀式召喚した「ヴェンデット」モンスターは以下の効果を得る。\n●このカードが戦闘で破壊したモンスターは墓地へは行かず除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴェンデット・キマイラ",h:"う゛ぇんでっと・きまいら",e:"Vendread Chimera",ra:"N",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"アンデット族",mo:["儀式","効果"],ma:"",tp:"",at:2300,de:0,tx:"「ヴェンデット」儀式魔法カードにより降臨。\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：フィールドのカードを破壊する魔法・罠・モンスターの効果が発動した時、自分の墓地からアンデット族モンスター１体を除外して発動できる。\nその発動を無効にし破壊する。\n②：このカードが儀式召喚のために、リリースまたは除外された場合に発動する。\n相手フィールドの全てのモンスターの攻撃力・守備力は５００ダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴェンデット・コア",h:"う゛ぇんでっと・こあ",e:"Vendread Core",ra:"N",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:0,de:500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが墓地に存在する場合、自分の墓地からこのカード以外のアンデット族モンスター１体を除外して発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。\n②：フィールドのこのカードを使用して儀式召喚した「ヴェンデット」モンスターは以下の効果を得る。\n●このカードは相手の効果の対象にならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴェンデット・ストリゲス",h:"う゛ぇんでっと・すとりげす",e:"Vendread Striges",ra:"R",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:500,de:2000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが墓地へ送られた場合、手札の「ヴェンデット」カード１枚を相手に見せて発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。\n②：フィールドのこのカードを使用して儀式召喚した「ヴェンデット」モンスターは以下の効果を得る。\n●このカードが相手モンスターと戦闘を行ったダメージ計算後に発動できる。\n自分はデッキから１枚ドローし、その後手札を１枚選んで捨てる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴェンデット・バスタード",h:"う゛ぇんでっと・ばすたーど",e:"Vendread Battlelord",ra:"R",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"アンデット族",mo:["儀式","効果"],ma:"",tp:"",at:2700,de:0,tx:"「ヴェンデット」儀式魔法カードにより降臨。\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の墓地から「ヴェンデット」カード１枚を除外し、カードの種類（モンスター・魔法・罠）を宣言して発動できる。\nこのターン、相手は宣言した種類のカードの効果を発動できない。\n②：儀式召喚したこのカードが墓地へ送られた場合に発動できる。\nデッキから儀式モンスター１体を手札に加え、デッキから「ヴェンデット」モンスター１体を墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴェンデット・ヘルハウンド",h:"う゛ぇんでっと・へるはうんど",e:"Vendread Houndhorde",ra:"R",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:0,de:2100,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが墓地に存在する場合、手札から「ヴェンデット」カード１枚を捨てて発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。\n②：フィールドのこのカードを使用して儀式召喚した「ヴェンデット」モンスターは以下の効果を得る。\n●１ターンに１度、相手フィールドの魔法・罠カード１枚を対象として発動できる。\nそのカードを除外する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴェンデット・レヴナント",h:"う゛ぇんでっと・れう゛なんと",e:"Vendread Revenants",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1800,de:200,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが相手によって破壊され墓地へ送られた場合に発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。\n②：フィールドのこのカードを使用して儀式召喚した「ヴェンデット」モンスターは以下の効果を得る。\n●１ターンに１度、相手フィールドの特殊召喚されたモンスター１体を対象として発動できる。\nそのモンスターを除外する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴォルカニック・エッジ",h:"う゛ぉるかにっく・えっじ",e:"Volcanic Slicer",ra:"SR",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:1800,de:1200,tx:"１ターンに１度、自分のメインフェイズ時に発動できる。\n相手ライフに５００ポイントダメージを与える。\nこの効果を発動するターン、このカードは攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴォルカニック・カウンター",h:"う゛ぉるかにっく・かうんたー",e:"Volcanic Counter",ra:"UR",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:300,de:1300,tx:"このカードが墓地に存在し、自分が戦闘ダメージを受けた時に発動する。\n墓地のこのカードをゲームから除外し、自分の墓地に「ヴォルカニック・カウンター」以外の炎属性モンスターが存在する場合、自分が受けた戦闘ダメージと同じ数値のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴォルカニック・クイーン",h:"う゛ぉるかにっく・くいーん",e:"Volcanic Queen",ra:"SR",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"炎族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2500,de:1200,tx:"このカードは通常召喚できない。\n相手フィールド上のモンスター１体をリリースし、手札から相手フィールド上に特殊召喚できる。\n１ターンに１度、このカード以外の自分フィールド上のカード１枚を墓地へ送る事で、相手ライフに１０００ポイントダメージを与える。\nまた、自分のエンドフェイズ時にこのカード以外の自分フィールド上のモンスター１体をリリースするか、自分は１０００ポイントダメージを受ける。\nこのカードを特殊召喚するターン、自分は通常召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴォルカニック・デビル",h:"う゛ぉるかにっく・でびる",e:"Volcanic Doomfire",ra:"UR",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"炎族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3000,de:1800,tx:"このカードは通常召喚できない。\n自分フィールド上に表側表示で存在する「ブレイズ・キャノン－トライデント」１枚を墓地へ送った場合に特殊召喚できる。\n相手のバトルフェイズ中、相手フィールド上にモンスターが攻撃表示で存在する場合、相手はこのカードに攻撃をしなければならない。\nこのカードが戦闘によってモンスターを破壊し墓地へ送った時、相手フィールド上のモンスターを全て破壊し、破壊したモンスターの数×５００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴォルカニック・バックショット",h:"う゛ぉるかにっく・ばっくしょっと",e:"Volcanic Scattershot",ra:"SR",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:500,de:0,tx:"このカードが墓地へ送られた時、相手ライフに５００ポイントダメージを与える。\nこのカードが「ブレイズ・キャノン」と名のついたカードの効果によって墓地へ送られた場合、手札・デッキから「ヴォルカニック・バックショット」２体を墓地へ送る事で、相手フィールド上のモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴォルカニック・バレット",h:"う゛ぉるかにっく・ばれっと",e:"Volcanic Shell",ra:"R",t:"monster",a:"炎",l:1,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:100,de:0,tx:"①：このカードが墓地に存在する場合、１ターンに１度、５００LPを払って発動できる。\nこのカードが墓地に存在する場合、デッキから「ヴォルカニック・バレット」１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴォルカニック・ハンマー",h:"う゛ぉるかにっく・はんまー",e:"Volcanic Hammerer",ra:"R",t:"monster",a:"炎",l:5,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:2400,de:1500,tx:"１ターンに１度、自分のメインフェイズ時に発動できる。\n自分の墓地の「ヴォルカニック」と名のついたモンスターの数×２００ポイントダメージを相手ライフに与える。\nこの効果を発動するターン、このカードは攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴォルカニック・ラット",h:"う゛ぉるかにっく・らっと",e:"Volcanic Rat",ra:"N",t:"monster",a:"炎",l:1,ps:"",pe:"",tr:"炎族",mo:["通常"],ma:"",tp:"",at:500,de:500,tx:"灼熱の火山地帯に生息するネズミの変種。\nどんな暑さにも耐えられる体を持っている。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴォルカニック・リボルバー",h:"う゛ぉるかにっく・りぼるばー",e:"Volcanic Blaster",ra:"N",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:1200,de:600,tx:"このカードが戦闘によって破壊され墓地へ送られた時、デッキから「ヴォルカニック」と名のついたモンスター１体を選んでデッキの一番上に置く事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴォルカニック・ロケット",h:"う゛ぉるかにっく・ろけっと",e:"Volcanic Rocket",ra:"UR",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:1900,de:1400,tx:"このカードが召喚・反転召喚・特殊召喚に成功した時、自分のデッキ・墓地から「ブレイズ・キャノン」と名のついたカード１枚を選んで手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ウィード",h:"うぃーど",e:"Nettles",ra:"R",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"植物族",mo:["チューナー","効果"],ma:"",tp:"",at:1200,de:400,tx:"フィールド上に表側表示で存在するこのカードが破壊される場合、代わりにこのカード以外の自分フィールド上に表側表示で存在する植物族モンスター１体を破壊できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ウィクトーリア",h:"うぃくとーりあ",e:"Victoria",ra:"UR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1800,de:1500,tx:"１ターンに１度、相手の墓地に存在するドラゴン族モンスター１体を選択して自分フィールド上に特殊召喚する事ができる。\nこのカードがフィールド上に表側表示で存在する限り、相手は表側表示で存在する他の天使族モンスターを攻撃対象に選択する事はできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ウィッチクラフト・エーデル",h:"うぃっちくらふと・えーでる",e:"Witchcrafter Edel",ra:"UR",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2000,de:2300,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札から魔法カード１枚を捨てて発動できる。\n手札から「ウィッチクラフト・エーデル」以外の「ウィッチクラフト」モンスター１体を特殊召喚する。\nこの効果は相手ターンでも発動できる。\n②：このカードをリリースし、自分の墓地の「ウィッチクラフト・エーデル」以外の魔法使い族モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ウィッチクラフト・ジェニー",h:"うぃっちくらふと・じぇにー",e:"Witchcrafter Genni",ra:"UR",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:300,de:500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分・相手のメインフェイズに、このカードをリリースし、手札から魔法カード１枚を捨てて発動できる。\nデッキから「ウィッチクラフト・ジェニー」以外の「ウィッチクラフト」モンスター１体を特殊召喚する。\n②：自分の墓地からこのカードと「ウィッチクラフト」魔法カード１枚を除外して発動できる。\nこの効果は、その魔法カード発動時の効果と同じになる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ウィッチクラフト・シュミッタ",h:"うぃっちくらふと・しゅみった",e:"Witchcrafter Schmietta",ra:"UR",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1800,de:600,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分・相手のメインフェイズに、このカードをリリースし、手札から魔法カード１枚を捨てて発動できる。\nデッキから「ウィッチクラフト・シュミッタ」以外の「ウィッチクラフト」モンスター１体を特殊召喚する。\n②：墓地のこのカードを除外して発動できる。\nデッキから「ウィッチクラフト・シュミッタ」以外の「ウィッチクラフト」カード１枚を墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ウィッチクラフト・ピットレ",h:"うぃっちくらふと・ぴっとれ",e:"Witchcrafter Pittore",ra:"SR",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1000,de:1500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分・相手のメインフェイズに、このカードをリリースし、手札から魔法カード１枚を捨てて発動できる。\nデッキから「ウィッチクラフト・ピットレ」以外の「ウィッチクラフト」モンスター１体を特殊召喚する。\n②：墓地のこのカードを除外して発動できる。\n自分はデッキから１枚ドローし、その後手札から「ウィッチクラフト」カード１枚を選んで墓地へ送る。\n手札に「ウィッチクラフト」カードが無い場合、手札を全て除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ウィッチクラフトマスター・ヴェール",h:"うぃっちくらふとますたー・う゛ぇーる",e:"Witchcrafter Madame Verre",ra:"UR",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1000,de:2800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の魔法使い族モンスターが相手モンスターと戦闘を行うダメージ計算時に発動できる。\n手札からカード名が異なる魔法カードを任意の数だけ相手に見せ、その自分のモンスターの攻撃力・守備力はターン終了時まで、見せた数×１０００アップする。\n②：手札から魔法カード１枚を捨てて発動できる。\n相手フィールドの全ての表側表示モンスターの効果をターン終了時まで無効にする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ウィップテイル・ガーゴイル",h:"うぃっぷている・がーごいる",e:"Whiptail Crow",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1650,de:1600,tx:"ムチのように長いしっぽを使い、空中から襲いかかってくる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ウィルミー",h:"うぃるみー",e:"Wilmee",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:1000,de:1200,tx:"かなり凶暴なウサギ。\n鋭いかぎづめで、相手を血祭りにあげる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ウイング・イーグル",h:"ういんぐ・いーぐる",e:"Wing Eagle",ra:"N",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"鳥獣族",mo:["通常"],ma:"",tp:"",at:1800,de:1500,tx:"はるか上空から獲物をさがし、狙った獲物は逃さない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ウィング・エッグ・エルフ",h:"うぃんぐ・えっぐ・えるふ",e:"Wing Egg Elf",ra:"N",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:500,de:1300,tx:"たまごのカラに身を包む天使。\n大きな羽で攻撃を防ぐ。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ウィングド・ライノ",h:"うぃんぐど・らいの",e:"Winged Rhynos",ra:"R",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:500,tx:"罠カードが発動した時に発動する事ができる。\nフィールド上に表側表示で存在するこのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ウイングトータス",h:"ういんぐとーたす",e:"Wingtortoise",ra:"R",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1500,de:1400,tx:"自分フィールド上に表側表示で存在する魚族・海竜族・水族モンスターがゲームから除外された時、このカードを手札または自分の墓地から特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ウィンドファーム・ジェネクス",h:"うぃんどふぁーむ・じぇねくす",e:"Windmill Genex",ra:"R",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:2000,de:1600,tx:"「ジェネクス・コントローラー」＋チューナー以外の風属性モンスター１体以上\nこのカードの攻撃力はフィールド上にセットされた魔法・罠カードの数×３００ポイントアップする。\nまた、手札を１枚墓地へ送って発動できる。\nフィールド上にセットされた魔法・罠カード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ウィンドフレーム",h:"うぃんどふれーむ",e:"Wind Effigy",ra:"R",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1800,de:200,tx:"風属性の通常モンスターを生け贄召喚する場合、このモンスター１体で２体分の生け贄とする事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ウェザ",h:"うぇざ",e:"Wetha",ra:"N",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1000,de:900,tx:"雨を操る精霊。\n台風を呼び出し、様々なものを吹き飛ばす。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ウェザー・コントロール",h:"うぇざー・こんとろーる",e:"Weather Control",ra:"N",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:600,de:400,tx:"天気を自由にあやつれる。\n山の天気が変わりやすいのはコイツのせい。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ウェポンサモナー",h:"うぇぽんさもなー",e:"Arsenal Summoner",ra:"R",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["リバース","効果"],ma:"",tp:"",at:1600,de:1600,tx:"①：このカードがリバースした場合に発動する。\nデッキから「ガーディアン」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ウォー・アース",h:"うぉー・あーす",e:"Rock Ogre Grotto #2",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["通常"],ma:"",tp:"",at:700,de:1400,tx:"岩石が集まってできたゴーレム。\n相手を石化して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ウォーター・エレメント",h:"うぉーたー・えれめんと",e:"Water Element",ra:"N",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:900,de:700,tx:"水に住んでいる精霊。\nまわりを霧でつつみこみ視界を奪う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ウォーター・ガール",h:"うぉーたー・がーる",e:"Water Girl",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1250,de:1000,tx:"水を氷の矢のようにして攻撃してくるきれいなお姉さん。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ウォーター・スピリット",h:"うぉーたー・すぴりっと",e:"Water Spirit",ra:"N",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"水族",mo:["チューナー","通常"],ma:"",tp:"",at:400,de:1200,tx:"古代南極大陸の永久凍土にて生命が宿ったと言われる氷水の精霊。\n様々な物質に浸透する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ウォーター・ドラゴン",h:"うぉーたー・どらごん",e:"Water Dragon",ra:"UR",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"海竜族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2800,de:2600,tx:"このカードは通常召喚できない。\n「ボンディング－H２O」の効果でのみ特殊召喚できる。\n①：このカードがモンスターゾーンに存在する限り、フィールドの炎属性モンスター及び炎族モンスターの攻撃力は０になる。\n②：このカードが破壊され墓地へ送られた時、自分の墓地の「ハイドロゲドン」２体と「オキシゲドン」１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ウォーター・ドラゴン－クラスター",h:"うぉーたー・どらごん－くらすたー",e:"Water Dragon Cluster",ra:"UR",t:"monster",a:"水",l:10,ps:"",pe:"",tr:"海竜族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2800,de:2600,tx:"このカードは通常召喚できない。\n「ボンディング」魔法・罠カードの効果でのみ特殊召喚できる。\n①：このカードが特殊召喚に成功した場合に発動できる。\n相手フィールドの効果モンスターはターン終了時まで、攻撃力が０になり、効果を発動できない。\n②：このカードをリリースして発動できる。\n手札・デッキから「ウォーター・ドラゴン」２体を召喚条件を無視して守備表示で特殊召喚する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ウォーム・ワーム",h:"うぉーむ・わーむ",e:"Warm Worm",ra:"SR",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:600,de:1400,tx:"①：このカードが破壊された場合に発動する。\n相手のデッキの上からカードを３枚墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ウォール・シャドウ",h:"うぉーる・しゃどう",e:"Wall Shadow",ra:"R",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1600,de:3000,tx:"「迷宮変化」の効果でしか特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ウォールクリエイター",h:"うぉーるくりえいたー",e:"Grapple Blocker",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1200,de:2000,tx:"このカードが召喚に成功した時、相手フィールド上に存在するモンスター１体を選択する事ができる。\nこのカードがフィールド上に表側表示で存在する限り、選択されたモンスターは攻撃する事はできずリリースする事もできない。\nこのカードのコントローラーは自分のエンドフェイズ毎に５００ライフポイントを払う。\nまたは、５００ライフポイント払わずにこのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"牛鬼",h:"うしおに",e:"Ushi Oni",ra:"N",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:2150,de:1950,tx:"黒魔術で蘇ったウシの悪魔。\n壺の中から現れる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ウジャト眼を持つ男",h:"うじゃとがんをもつおとこ",e:"A Man with Wdjat",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1600,de:1600,tx:"このカードを通常召喚した時と自分のスタンバイフェイズ時、相手フィールド上にセットされているカード１枚を選択してめくり、確認した後元に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"美しき魔物使い",h:"うつくしきまものつかい",e:"Beautiful Beast Trainer",ra:"N",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1750,de:1500,tx:"珍しい女性の魔物使い。\nムチを手にすると、性格が変わる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ウッド・ジョーカー",h:"うっど・じょーかー",e:"Wood Clown",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:800,de:1200,tx:"嫌な笑みを浮かべた悪魔。\n手にするカマで、器用に攻撃をかわす。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"樹海の射手",h:"うっどらんど・あーちゃー",e:"Woodland Archer",ra:"SR",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1400,de:1300,tx:"このカードは通常召喚できない。\n自分の墓地に通常モンスターが２体以上存在する場合のみ特殊召喚する事ができる。\nこのカードをリリースする事で、自分のデッキからデュアルモンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"海神の巫女",h:"うながみのみこ",e:"Maiden of the Aqua",ra:"SR",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:700,de:2000,tx:"このカードがフィールド上に表側表示で存在する限り、フィールドは「海」になる。\nフィールド魔法カードが表側表示で存在する場合、この効果は適用されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"海原の女戦士",h:"うなばらのおんなせんし",e:"Amazon of the Seas",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["通常"],ma:"",tp:"",at:1300,de:1400,tx:"海の神に仕えるマーメイド。\n神聖な領域を守っている。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ウミノタウルス",h:"うみのたうるす",e:"Uminotaurus",ra:"R",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"このカードがフィールド上に表側表示で存在する限り、自分フィールド上の魚族・海竜族・水族モンスターが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"海を守る戦士",h:"うみをまもるせんし",e:"Sentinel of the Seas",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1300,de:1000,tx:"海を汚す奴等を徹底的に攻撃する、マーマン戦士。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"裏ガエル",h:"うらがえる",e:"Flip Flop Frog",ra:"R",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:500,de:200,tx:"このカードは１ターンに１度だけ裏側守備表示にする事ができる。\nこのカードがリバースした時、自分フィールド上の「ガエル」と名のついたモンスターの数まで、相手フィールド上のモンスターを選んで持ち主の手札に戻す事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"裏風の精霊",h:"うらかぜのせいれい",e:"Spirit of the Fall Wind",ra:"SR",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1800,de:900,tx:"①：このカードが召喚に成功した場合に発動できる。\nデッキからリバースモンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"占い魔女 アンちゃん",h:"うらないまじょ　あんちゃん",e:"Fortune Fairy Ann",ra:"N",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードをドローした時、このカードを相手に見せて発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが手札からの特殊召喚に成功した場合に発動できる。\nデッキから魔法使い族モンスター１体を除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"占い魔女 エンちゃん",h:"うらないまじょ　えんちゃん",e:"Fortune Fairy En",ra:"UR",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードをドローした時、このカードを相手に見せて発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが手札からの特殊召喚に成功した場合、相手フィールドにセットされたカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"占い魔女 スィーちゃん",h:"うらないまじょ　すぃーちゃん",e:"Fortune Fairy Swee",ra:"UR",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードをドローした時、このカードを相手に見せて発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが手札からの特殊召喚に成功した場合、このカード以外のフィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターを次の自分ターンのスタンバイフェイズまで除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"占い魔女 チーちゃん",h:"うらないまじょ　ちーちゃん",e:"Fortune Fairy Chee",ra:"R",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードをドローした時、このカードを相手に見せて発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが手札からの特殊召喚に成功した場合に発動できる。\nお互いのプレイヤーは、それぞれデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"占い魔女 ヒカリちゃん",h:"うらないまじょ　ひかりちゃん",e:"Fortune Fairy Hikari",ra:"UR",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードをドローした時、このカードを相手に見せて発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが手札からの特殊召喚に成功した場合、自分フィールドのモンスター１体を対象として発動できる。\nそのモンスターを墓地へ送り、デッキから魔法使い族・レベル１モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"占い魔女 フウちゃん",h:"うらないまじょ　ふうちゃん",e:"Fortune Fairy Hu",ra:"R",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードをドローした時、このカードを相手に見せて発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが手札からの特殊召喚に成功した場合、除外されている自分の魔法使い族モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"U.A.カストディアン",h:"うるとらあすりーとかすとでぃあん",e:"U.A. Goalkeeper",ra:"N",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:2800,tx:"「U.A.カストディアン」の①の方法による特殊召喚は１ターンに１度しかできない。\n①：このカードは「U.A.カストディアン」以外の自分フィールドの「U.A.」モンスター１体を手札に戻し、手札から特殊召喚できる。\n②：相手ターンに１度、自分フィールドの「U.A.」モンスター１体を対象として発動できる。\nそのモンスターはこのターンに１度だけ戦闘・効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"U.A.コリバルリバウンダー",h:"うるとらあすりーとこりばるりばうんだー",e:"U.A. Rival Rebounder",ra:"R",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2200,de:2300,tx:"「U.A.コリバルリバウンダー」の、①の方法による特殊召喚は１ターンに１度しかできず、②の効果は１ターンに１度しか使用できない。\n①：このカードは「U.A.コリバルリバウンダー」以外の自分フィールドの「U.A.」モンスター１体を手札に戻し、手札から特殊召喚できる。\n②：このカードが召喚または相手ターン中の特殊召喚に成功した場合に発動できる。\n自分の手札・墓地から「U.A.コリバルリバウンダー」以外の「U.A.」モンスター１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"U.A.ストロングブロッカー",h:"うるとらあすりーとすとろんぐぶろっかー",e:"U.A. Blockbacker",ra:"R",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:2700,tx:"「U.A.ストロングブロッカー」の①の方法による特殊召喚は１ターンに１度しかできない。\n①：このカードは「U.A.ストロングブロッカー」以外の自分フィールドの「U.A.」モンスター１体を手札に戻し、手札から特殊召喚できる。\n②：１ターンに１度、相手がモンスターの特殊召喚に成功した時に発動できる。\nそのモンスターの表示形式を変更し、その効果を無効にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"U.A.ドレッドノートダンカー",h:"うるとらあすりーとどれっどのーとだんかー",e:"U.A. Dreadnought Dunker",ra:"R",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2500,de:1800,tx:"「U.A.ドレッドノートダンカー」の①の方法による特殊召喚は１ターンに１度しかできない。\n①：このカードは「U.A.ドレッドノートダンカー」以外の自分フィールドの「U.A.」モンスター１体を手札に戻し、手札から特殊召喚できる。\n②：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。\n③：このカードが相手に戦闘ダメージを与えた時、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"U.A.パーフェクトエース",h:"うるとらあすりーとぱーふぇくとえーす",e:"U.A. Perfect Ace",ra:"SR",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:2500,tx:"「U.A.パーフェクトエース」の①の方法による特殊召喚は１ターンに１度しかできない。\n①：このカードは「U.A.パーフェクトエース」以外の自分フィールドの「U.A.」モンスター１体を手札に戻し、手札から特殊召喚できる。\n②：相手ターンに１度、魔法・罠・モンスターの効果が発動した時、手札を１枚捨てて発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"U.A.ファンタジスタ",h:"うるとらあすりーとふぁんたじすた",e:"U.A. Midfielder",ra:"UR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:1000,tx:"「U.A.ファンタジスタ」の、①の方法による特殊召喚は１ターンに１度しかできず、②の効果は１ターンに１度しか使用できない。\n①：このカードは「U.A.ファンタジスタ」以外の自分フィールドの「U.A.」モンスター１体を手札に戻し、手札から特殊召喚できる。\n②：このカード以外の自分フィールドの「U.A.」モンスター１体を対象として発動できる。\nその表側表示モンスターを手札に戻し、その後そのモンスターとカード名が異なる「U.A.」モンスター１体を手札から特殊召喚する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"U.A.フィールドゼネラル",h:"うるとらあすりーとふぃーるどぜねらる",e:"U.A. Playmaker",ra:"N",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2600,de:2000,tx:"「U.A.フィールドゼネラル」の①の方法による特殊召喚は１ターンに１度しかできない。\n①：このカードは「U.A.フィールドゼネラル」以外の自分フィールドの「U.A.」モンスター１体を手札に戻し、手札から特殊召喚できる。\n②：このカード以外の自分の「U.A.」モンスターの攻撃宣言時に発動できる。\nこのカードの攻撃力を８００ダウンし、自分の攻撃モンスターの攻撃力を８００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"U.A.マイティスラッガー",h:"うるとらあすりーとまいてぃすらっがー",e:"U.A. Mighty Slugger",ra:"R",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2300,de:700,tx:"「U.A.マイティスラッガー」の①の方法による特殊召喚は１ターンに１度しかできない。\n①：このカードは「U.A.マイティスラッガー」以外の自分フィールドの「U.A.」モンスター１体を手札に戻し、手札から特殊召喚できる。\n②：このカードが攻撃する場合、相手はダメージステップ終了時まで魔法・罠・モンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"麗の魔妖－妲姫",h:"うるわしのまやかし－だっき",e:"Dakki, the Graceful Mayakashi",ra:"SR",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"アンデット族",mo:["チューナー","効果"],ma:"",tp:"",at:1000,de:0,tx:"①：「麗の魔妖－妲姫」は自分フィールドに１体しか表側表示で存在できない。\n②：このカードが墓地に存在し、「魔妖」モンスターがEXデッキから自分フィールドに特殊召喚された時に発動できる。\nこのカードを特殊召喚する。\nこの効果を発動するターン、自分は「魔妖」モンスターしかEXデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"麗の魔妖－妖狐",h:"うるわしのまやかし－ようこ",e:"Yoko, the Graceful Mayakashi",ra:"UR",t:"monster",a:"炎",l:9,ps:"",pe:"",tr:"アンデット族",mo:["シンクロ","効果"],ma:"",tp:"",at:2900,de:2400,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：「麗の魔妖－妖狐」は自分フィールドに１体しか表側表示で存在できない。\n②：このカードが墓地に存在し、元々のレベルが１１の自分のSモンスターが戦闘または相手の効果で破壊された場合に発動できる。\n自分の墓地から他のアンデット族モンスター１体を除外し、このカードを特殊召喚する。\n③：このカードが墓地からの特殊召喚に成功した場合に発動できる。\n相手フィールドのモンスター１体を選んで破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ウンディーネ",h:"うんでぃーね",e:"Waterdragon Fairy",ra:"N",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1100,de:700,tx:"水の中をユラユラ漂う妖精。\n水龍を召喚できるらしい。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"運命のろうそく",h:"うんめいのろうそく",e:"Candle of Fate",ra:"N",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:600,de:600,tx:"指先の炎が消えたとき、相手の運命が決定する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エア・イーター",h:"えあ・いーたー",e:"Air Eater",ra:"N",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:2100,de:1600,tx:"周囲の空気を食べてしまい、相手を窒息させるモンスター。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エア・サーキュレーター",h:"えあ・さーきゅれーたー",e:"Vortex Trooper",ra:"SR",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:0,de:600,tx:"①：このカードが召喚に成功した時に発動できる。\n手札を２枚デッキに戻してシャッフルする。\nその後、自分はデッキから２枚ドローする。\n②：このカードが破壊された場合に発動する。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エアジャチ",h:"えあじゃち",e:"Airorca",ra:"R",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:1400,de:300,tx:"１ターンに１度、手札から魚族・海竜族・水族モンスター１体をゲームから除外する事で、相手フィールド上に表側表示で存在するカード１枚を選択して破壊する。\nその後、このカードを次の自分のスタンバイフェイズ時までゲームから除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"英炎星－ホークエイ",h:"えいえんせい－ほーくえい",e:"Brotherhood of the Fire Fist - Hawk",ra:"N",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:200,de:1500,tx:"このカードが相手によって破壊された場合、デッキから「炎舞」と名のついた魔法カード１枚を選んで自分フィールド上にセットできる。\nまた、自分フィールド上に「炎舞」と名のついた魔法・罠カードが存在する場合、自分フィールド上の全ての「炎星」と名のついたモンスターの攻撃力・守備力は５００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"衛生兵マッスラー",h:"えいせいへいまっすらー",e:"Muscle Medic",ra:"SR",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2200,de:100,tx:"①：このカードの戦闘で自分または相手が戦闘ダメージを受ける場合、代わりにその数値分だけLPを回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"英知の代行者 マーキュリー",h:"えいちのだいこうしゃ　まーきゅりー",e:"The Agent of Wisdom - Mercury",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:0,de:1700,tx:"①：相手ターン終了時に、このカードが自分のモンスターゾーンに表側表示で存在し、自分の手札が０枚の場合、次の自分スタンバイフェイズに発動する。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エヴォルダー・ウルカノドン",h:"えう゛ぉるだー・うるかのどん",e:"Evolsaur Vulcano",ra:"N",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1200,de:1000,tx:"このカードが「エヴォルド」と名のついたモンスターの効果によって特殊召喚に成功した時、自分の墓地に存在する「エヴォルダー」と名のついたモンスター１体を選択して特殊召喚する事ができる。\nこの効果で特殊召喚したモンスターは攻撃宣言をする事ができない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エヴォルダー・エリアス",h:"えう゛ぉるだー・えりあす",e:"Evolsaur Elias",ra:"R",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:100,de:2400,tx:"このカードが「エヴォルド」と名のついたモンスターの効果によって特殊召喚に成功した時、手札から恐竜族・炎属性・レベル６以下のモンスター１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エヴォルダー・ケラト",h:"えう゛ぉるだー・けらと",e:"Evolsaur Cerato",ra:"SR",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1900,de:1400,tx:"このカードが「エヴォルド」と名のついたモンスターの効果によって特殊召喚に成功した時、このカードの攻撃力は２００ポイントアップする。\nその後、このカードが戦闘によって相手モンスターを破壊した場合、自分のデッキから「エヴォルド」と名のついたモンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エヴォルダー・ダルウィノス",h:"えう゛ぉるだー・だるうぃのす",e:"Evolsaur Darwino",ra:"N",t:"monster",a:"炎",l:5,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:2200,de:700,tx:"このカードが「エヴォルド」と名のついたモンスターの効果によって特殊召喚に成功した時、フィールド上に表側表示で存在するモンスター１体を選択してレベルを２つまで上げる事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エヴォルダー・ディプロドクス",h:"えう゛ぉるだー・でぃぷろどくす",e:"Evolsaur Diplo",ra:"UR",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1600,de:800,tx:"このカードが「エヴォルド」と名のついたモンスターの効果によって特殊召喚に成功した時、相手フィールド上に存在する魔法・罠カード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エヴォルダー・テリアス",h:"えう゛ぉるだー・てりあす",e:"Evolsaur Terias",ra:"N",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:2400,de:600,tx:"このカードが「エヴォルド」と名のついたモンスターの効果によって特殊召喚に成功した時、このカードの攻撃力は５００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エヴォルダー・ペルタ",h:"えう゛ぉるだー・ぺるた",e:"Evolsaur Pelta",ra:"N",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1100,de:2000,tx:"このカードが「エヴォルド」と名のついたモンスターの効果によって特殊召喚に成功した時、このカードの守備力は５００ポイントアップする。\nその後、このカードが戦闘によって破壊された場合、デッキから「エヴォルド」と名のついたモンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エヴォルテクター シュバリエ",h:"えう゛ぉるてくたー　しゅばりえ",e:"Evocator Chevalier",ra:"SR",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"戦士族",mo:["デュアル","効果"],ma:"",tp:"",at:1900,de:900,tx:"①：このカードはフィールド・墓地に存在する限り、通常モンスターとして扱う。\n②：フィールドの通常モンスター扱いのこのカードを通常召喚としてもう１度召喚できる。\nその場合このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●自分フィールドの表側表示の装備カード１枚を墓地へ送り、相手フィールドのカード１枚を対象として発動できる。\nその相手のカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エヴォルド・ウェストロ",h:"えう゛ぉるど・うぇすとろ",e:"Evoltile Westlo",ra:"R",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"爬虫類族",mo:["リバース","効果"],ma:"",tp:"",at:700,de:1900,tx:"リバース：自分のデッキから「エヴォルダー」と名のついたモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エヴォルド・エルギネル",h:"えう゛ぉるど・えるぎねる",e:"Evoltile Elginero",ra:"SR",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"フィールド上のこのカードがリリースされ墓地へ送られた時、デッキからカードを１枚ドローする。\nその後、手札の恐竜族モンスター１体をデッキに戻し、デッキから「エヴォルド」と名のついたモンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エヴォルド・オドケリス",h:"えう゛ぉるど・おどけりす",e:"Evoltile Odonto",ra:"N",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:500,de:1200,tx:"このカードが召喚に成功した時、手札から「エヴォルダー」と名のついたモンスター１体を特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エヴォルド・カシネリア",h:"えう゛ぉるど・かしねりあ",e:"Evoltile Casinerio",ra:"N",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1600,de:400,tx:"このカードが戦闘によって相手モンスターを破壊したバトルフェイズ終了時、このカードをリリースして発動できる。\nデッキから恐竜族・炎属性・レベル６以下の同名モンスター２体を特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化され、エンドフェイズ時にゲームから除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エヴォルド・ゲフィロス",h:"えう゛ぉるど・げふぃろす",e:"Evoltile Gephyro",ra:"N",t:"monster",a:"炎",l:1,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:200,de:400,tx:"このカードが戦闘によって破壊され墓地へ送られた時、自分の墓地に存在する「エヴォルダー」と名のついたモンスター１体を選択して特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エヴォルド・ナハシュ",h:"えう゛ぉるど・なはしゅ",e:"Evoltile Najasho",ra:"SR",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:100,de:2000,tx:"フィールド上のこのカードがリリースされた場合、デッキから「エヴォルダー」と名のついたモンスター１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エヴォルド・プレウロス",h:"えう゛ぉるど・ぷれうろす",e:"Evoltile Pleuro",ra:"R",t:"monster",a:"炎",l:1,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:200,de:200,tx:"このカードが自分フィールド上で破壊され墓地へ送られた場合、手札から「エヴォルダー」と名のついたモンスター１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エヴォルド・ラゴスクス",h:"えう゛ぉるど・らごすくす",e:"Evoltile Lagosucho",ra:"SR",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1200,de:500,tx:"このカードが召喚に成功した時、デッキから「エヴォルダー」と名のついたモンスター１体を墓地へ送る事ができる。\nまた、このカードがリバースした時、デッキから「エヴォルド」と名のついたモンスター１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ADチェンジャー",h:"えーでぃーちぇんじゃー",e:"A/D Changer",ra:"R",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"自分のメインフェイズ時に、墓地のこのカードをゲームから除外し、フィールド上のモンスター１体を選択して発動できる。\n選択したモンスターの表示形式を変更する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"紅貴士－ヴァンパイア・ブラム",h:"えーでるりったー－う゛ぁんぱいあ・ぶらむ",e:"Crimson Knight Vampire Bram",ra:"R",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"アンデット族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2500,de:0,tx:"アンデット族レベル５モンスター×２\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードのX素材を１つ取り除き、相手の墓地のモンスター１体を対象として発動できる。\nそのモンスターを自分フィールドに特殊召喚する。\nこの効果で特殊召喚に成功したターン、そのモンスター以外の自分のモンスターは攻撃できない。\n②：フィールドのこのカードが相手によって破壊され墓地へ送られた次のターンのスタンバイフェイズに発動する。\nこのカードを墓地から守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エーリアン・ウォリアー",h:"えーりあん・うぉりあー",e:"Alien Warrior",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"このカードが戦闘によって破壊され墓地へ送られた時、このカードを破壊したモンスターにAカウンターを２つ置く。\nこのカードがフィールド上に表側表示で存在する限り、Aカウンターが乗ったモンスターは、「エーリアン」と名のついたモンスターと戦闘を行う場合、ダメージ計算時のみAカウンター１つにつき攻撃力・守備力が３００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エーリアン・キッズ",h:"えーりあん・きっず",e:"Alien Kid",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1600,de:700,tx:"このカードが自分フィールド上に表側表示で存在する限り、相手フィールド上にモンスターが特殊召喚される度に、その時に特殊召喚されたモンスターにAカウンターを１つ置く。\nAカウンターが乗ったモンスターは、「エーリアン」と名のついたモンスターと戦闘する場合、Aカウンター１つにつき攻撃力と守備力が３００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エーリアン・グレイ",h:"えーりあん・ぐれい",e:"Alien Grey",ra:"N",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"爬虫類族",mo:["リバース","効果"],ma:"",tp:"",at:300,de:800,tx:"リバース：相手フィールド上に表側表示で存在するモンスター１体を選択し、Aカウンターを１つ置く。\nまた、リバースしたこのカードが戦闘によって破壊され墓地へ送られた時、デッキからカードを１枚ドローする。\nこのカードがフィールド上に表側表示で存在する限り、Aカウンターが乗ったモンスターは、「エーリアン」と名のついたモンスターと戦闘を行う場合、ダメージ計算時のみAカウンター１つにつき攻撃力・守備力が３００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エーリアン・サイコ",h:"えーりあん・さいこ",e:"Alien Psychic",ra:"N",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:200,de:100,tx:"このカードは召喚・反転召喚に成功した場合守備表示になる。\nこのカードがフィールド上に表側表示で存在する限り、Aカウンターが乗ったモンスターは攻撃宣言をする事ができない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エーリアン・スカル",h:"えーりあん・すかる",e:"Alien Skull",ra:"N",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1600,de:1800,tx:"相手フィールド上のレベル３以下のモンスター１体をリリースし、このカードを手札から相手フィールド上に特殊召喚できる。\nこの方法で特殊召喚する場合、このターン自分は通常召喚できず、特殊召喚時にこのカードにAカウンターを１つ置く。\nこのカードがフィールド上に表側表示で存在する限り、Aカウンターが乗ったモンスターは、「エーリアン」と名のついたモンスターと戦闘を行う場合、ダメージ計算時のみAカウンター１つにつき攻撃力・守備力が３００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エーリアン・ソルジャー",h:"えーりあん・そるじゃー",e:"Alien Shocktrooper",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["通常"],ma:"",tp:"",at:1900,de:800,tx:"謎の生命体、エーリアンの上級戦士。\n比較的高い攻撃力を持つが、反面特殊な能力は身につけていない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エーリアン・テレパス",h:"えーりあん・てれぱす",e:"Alien Telepath",ra:"R",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"１ターンに１度、相手フィールド上のモンスターに乗っているAカウンターを１つ取り除いて発動できる。\nフィールド上の魔法・罠カード１枚を選択して破壊する。\nこのカードがフィールド上に表側表示で存在する限り、Aカウンターが乗ったモンスターは、「エーリアン」と名のついたモンスターと戦闘を行う場合、ダメージ計算時のみAカウンター１つにつき攻撃力・守備力が３００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エーリアン・ドッグ",h:"えーりあん・どっぐ",e:"Alien Dog",ra:"R",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"自分が「エーリアン」と名のついたモンスターの召喚に成功した時、このカードを手札から特殊召喚できる。\nこの効果で特殊召喚に成功した時、相手フィールド上に表側表示で存在するモンスターにAカウンターを２つ置く。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エーリアン・ハンター",h:"えーりあん・はんたー",e:"Alien Hunter",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1600,de:800,tx:"このカードが戦闘によってAカウンターが乗ったモンスターを破壊した場合、もう１度だけ続けて攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エーリアン・ヒュプノ",h:"えーりあん・ひゅぷの",e:"Alien Hypno",ra:"R",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["デュアル","効果"],ma:"",tp:"",at:1600,de:700,tx:"このカードは墓地またはフィールド上に表側表示で存在する場合、通常モンスターとして扱う。\nフィールド上に表側表示で存在するこのカードを通常召喚扱いとして再度召喚する事で、このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●相手フィールド上のAカウンターが乗ったモンスター１体を選択してコントロールを得る。\n自分のエンドフェイズ毎に、コントロールを得たモンスターのAカウンターを１つ取り除く。\nコントロールを得たモンスターのAカウンターが全て取り除かれた場合、そのモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エーリアン・ベーダー",h:"えーりあん・べーだー",e:"Alien Infiltrator",ra:"R",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:800,de:500,tx:"このカードは１ターンに１度だけ、使用していない隣のモンスターカードゾーンに移動する事ができる。\nこのカードの正面に相手のモンスター・魔法・罠カードが存在しない場合、このカードは相手プレイヤーに直接攻撃をする事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エーリアン・マーズ",h:"えーりあん・まーず",e:"Alien Mars",ra:"N",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードがフィールド上に表側表示で存在する限り、「エーリアン・マーズ」以外のフィールド上のAカウンターが乗った効果モンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エーリアン・マザー",h:"えーりあん・まざー",e:"Alien Mother",ra:"N",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:2300,de:1500,tx:"このカードが戦闘によってAカウンターが乗ったモンスターを破壊し墓地へ送った場合、そのバトルフェイズ終了時に発動する。\n破壊したそのモンスターを自分フィールド上に特殊召喚する。\nこの効果で特殊召喚したモンスターは、このカードがフィールド上から離れた場合、全て破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エーリアン・リベンジャー",h:"えーりあん・りべんじゃー",e:"Alien Overlord",ra:"R",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:2200,de:1600,tx:"このカードはフィールド上のAカウンターを２つ取り除き、手札から特殊召喚できる。\n１ターンに１度、相手フィールド上に表側表示で存在する全てのモンスターにAカウンターを１つ置く事ができる。\nこのカードがフィールド上に表側表示で存在する限り、Aカウンターが乗ったモンスターは、「エーリアン」と名のついたモンスターと戦闘を行う場合、ダメージ計算時のみAカウンター１つにつき攻撃力・守備力が３００ポイントダウンする。\n「エーリアン・リベンジャー」は自分フィールド上に１体しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エーリアンモナイト",h:"えーりあんもないと",e:"Alien Ammonite",ra:"UR",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"爬虫類族",mo:["チューナー","効果"],ma:"",tp:"",at:500,de:200,tx:"①：このカードが召喚に成功した時、自分の墓地のレベル４以下の「エーリアン」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターはエンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エキセントリック・デーモン",h:"えきせんとりっく・でーもん",e:"Archfiend Eccentrick",ra:"UR",t:"monster",a:"光",l:3,ps:7,pe:"このカード名のP効果は１ターンに１度しか使用できない。\n①：このカード以外のフィールドの魔法・罠カード１枚を対象として発動できる。\nそのカードとこのカードを破壊する。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:800,de:1000,tx:"このカード名のモンスター効果は１ターンに１度しか使用できない。\n①：このカードをリリースし、フィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エキセントリック・ボーイ",h:"えきせんとりっく・ぼーい",e:"Eccentric Boy",ra:"SR",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:200,tx:"このカードをシンクロ素材とする場合、他のシンクロ素材モンスターは手札のモンスター１体でなければならない。\nこのカードをシンクロ素材としたシンクロモンスターは効果を発動する事ができず無効化され、フィールド上から離れた場合ゲームから除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"疫病狼",h:"えきびょうおおかみ",e:"Plague Wolf",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"①：１ターンに１度、自分メインフェイズに発動できる。\nこのカードの攻撃力はターン終了時まで元々の攻撃力の倍になる。\n②：このカードの①の効果を発動した場合、エンドフェイズに発動する。\nこのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"X－レイ・ピアース",h:"えくしーず－れい・ぴあーす",e:"Xyz-Raypierce",ra:"SR",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1700,de:200,tx:"「X－レイ・ピアース」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の墓地からドラゴン族モンスターと幻竜族モンスターをそれぞれ１体ずつ除外して発動できる。\n手札・デッキから「X－レイ・ピアース」１体を特殊召喚する。\n②：このカードがフィールドから墓地へ送られた場合に発動できる。\n相手に５００ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エクシーズ・エージェント",h:"えくしーず・えーじぇんと",e:"Xyz Agent",ra:"N",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:900,de:300,tx:"このカードが墓地に存在する場合、自分のメインフェイズ時に自分フィールド上の「希望皇ホープ」と名のついたエクシーズモンスター１体を選択して発動できる。\n墓地のこのカードを選択したモンスターの下に重ねてエクシーズ素材とする。\n「エクシーズ・エージェント」の効果はデュエル中に１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エクシーズ・スライドルフィン",h:"えくしーず・すらいどるふぃん",e:"Xyz Slidolphin",ra:"SR",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:1200,de:1800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドにXモンスターが特殊召喚された場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが墓地に存在し、自分フィールドにXモンスターが特殊召喚された場合、そのXモンスター１体を対象として発動できる。\nこのカードをそのモンスターの下に重ねてX素材とする。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エクストラ・ヴェーラー",h:"えくすとら・う゛ぇーらー",e:"Extra Veiler",ra:"N",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:600,de:200,tx:"相手がモンスターを特殊召喚した時、手札からこのカードを特殊召喚する事ができる。\nこの効果で特殊召喚したターン、相手のカードの効果によって発生する自分への効果ダメージは代わりに相手が受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エクスプレスロイド",h:"えくすぷれすろいど",e:"Expressroid",ra:"UR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:400,de:1600,tx:"①：このカードが召喚・反転召喚・特殊召喚に成功した時、「エクスプレスロイド」以外の自分の墓地の「ロイド」モンスター２体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エクスプローシブ・マジシャン",h:"えくすぷろーしぶ・まじしゃん",e:"Explosive Magician",ra:"N",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:1800,tx:"チューナー＋チューナー以外の魔法使い族モンスター１体以上\n自分フィールド上の魔力カウンターを２つ取り除いて発動できる。\n相手フィールド上の魔法・罠カード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エクスプロード・ウィング・ドラゴン",h:"えくすぷろーど・うぃんぐ・どらごん",e:"Exploder Dragonwing",ra:"UR",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2400,de:1600,tx:"チューナー＋チューナー以外のドラゴン族モンスター１体以上\nこのカードの攻撃力以下の攻撃力を持つ、フィールド上に表側表示で存在するモンスターとこのカードが戦闘を行う場合、ダメージ計算を行わずそのモンスターを破壊し、その攻撃力分のダメージを相手ライフに与える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エクゾディア・ネクロス",h:"えくぞでぃあ・ねくろす",e:"Exodia Necross",ra:"UR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1800,de:0,tx:"このカードは通常召喚できない。\n「エクゾディアとの契約」の効果でのみ特殊召喚できる。\n①：このカードは戦闘及び魔法・罠カードの効果では破壊されない。\n②：自分スタンバイフェイズに発動する。\nこのカードの攻撃力は５００アップする。\n③：自分の墓地に「封印されしエクゾディア」「封印されし者の右腕」「封印されし者の左腕」「封印されし者の右足」「封印されし者の左足」のいずれかが存在しない場合にこのカードは破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エクリプス・ワイバーン",h:"えくりぷす・わいばーん",e:"Eclipse Wyvern",ra:"UR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"①：このカードが墓地へ送られた場合に発動する。\nデッキから光属性または闇属性のドラゴン族・レベル７以上のモンスター１体を除外する。\n②：墓地のこのカードが除外された場合に発動できる。\nこのカードの①の効果で除外されているモンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"霊魂鳥－巫鶴",h:"えすぷりっと・ばーど－かんなぎづる",e:"Shinobird Crane",ra:"R",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["スピリット","効果"],ma:"",tp:"",at:1500,de:1500,tx:"このカードは特殊召喚できない。\n①：１ターンに１度、このカードがモンスターゾーンに存在し、このカード以外のスピリットモンスターが召喚・特殊召喚に成功した場合に発動できる。\n自分はデッキから１枚ドローする。\n②：このカードが召喚・リバースしたターンのエンドフェイズに発動する。\nこのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"霊魂鳥－忍鴉",h:"えすぷりっと・ばーど－しのびがらす",e:"Shinobird Crow",ra:"R",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["スピリット","効果"],ma:"",tp:"",at:0,de:0,tx:"このカードは特殊召喚できない。\n①：１ターンに１度、このカードが相手モンスターと戦闘を行うダメージステップ開始時からダメージ計算前までに、手札からスピリットモンスター１体を捨てて発動できる。\nこのカードの攻撃力・守備力はバトルフェイズ終了時まで、捨てたモンスターの攻撃力・守備力のそれぞれの数値分アップする。\n②：このカードが召喚・リバースしたターンのエンドフェイズに発動する。\nこのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"霊魂鳥－伝鳩",h:"えすぷりっと・ばーど－つたえばと",e:"Shinobird Pigeon",ra:"N",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["スピリット","効果"],ma:"",tp:"",at:1000,de:1800,tx:"このカードは特殊召喚できない。\n①：１ターンに１度、このカード以外のフィールドのスピリットモンスター１体を対象として発動できる。\nそのモンスターを持ち主の手札に戻す。\n②：このカードが召喚・リバースしたターンのエンドフェイズに発動する。\nこのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"X－セイバー アクセル",h:"えっくす－せいばー　あくせる",e:"X-Saber Axel",ra:"N",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:400,de:100,tx:"このカードが自分フィールド上に表側表示で存在する限り、「セイバー」と名のついたモンスターが戦闘によって破壊され墓地へ送られた時、デッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"X－セイバー アナペレラ",h:"えっくす－せいばー　あなぺれら",e:"X-Saber Anu Piranha",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1800,de:1100,tx:"華麗な攻撃と冷静な判断で戦場を舞う、X－セイバーの女戦士。\n時に冷酷なその攻撃は敵に恐れられている。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"X－セイバー ウェイン",h:"えっくす－せいばー　うぇいん",e:"X-Saber Wayne",ra:"SR",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2100,de:400,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：このカードがS召喚に成功した時に発動できる。\n手札からレベル４以下の戦士族モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"X－セイバー ウルズ",h:"えっくす－せいばー　うるず",e:"X-Saber Uruz",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"このカードが戦闘によって相手モンスターを破壊し墓地へ送った時、このカードをリリースして発動できる。\n破壊したそのカードを持ち主のデッキの一番上に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"X－セイバー ウルベルム",h:"えっくす－せいばー　うるべるむ",e:"X-Saber Urbellum",ra:"N",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2200,de:1300,tx:"チューナー＋チューナー以外のモンスター１体以上\n相手の手札が４枚以上の場合、このカードが相手ライフに戦闘ダメージを与えた時、相手の手札をランダムに１枚選んで持ち主のデッキの一番上に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"X－セイバー エアベルン",h:"えっくす－せいばー　えあべるん",e:"X-Saber Airbellum",ra:"UR",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["チューナー","効果"],ma:"",tp:"",at:1600,de:200,tx:"①：このカードが直接攻撃で相手に戦闘ダメージを与えた場合に発動する。\n相手の手札をランダムに１枚選んで捨てる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"X－セイバー ガラハド",h:"えっくす－せいばー　がらはど",e:"X-Saber Galahad",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:800,tx:"このカードは相手モンスターに攻撃する場合、ダメージステップの間、攻撃力が３００ポイントアップする。\nこのカードは相手モンスターに攻撃される場合、ダメージステップの間、攻撃力が５００ポイントダウンする。\nまた、このカードが攻撃対象に選択された時、このカード以外の自分フィールド上の「セイバー」と名のついたモンスター１体をリリースする事で、その攻撃を無効にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"X－セイバー ソウザ",h:"えっくす－せいばー　そうざ",e:"X-Saber Souza",ra:"R",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:1600,tx:"チューナー＋チューナー以外の「X－セイバー」モンスター１体以上\n①：自分フィールドの「X－セイバー」モンスター１体をリリースし、以下の効果から１つを選択して発動できる。\nその効果をターン終了時まで得る。\n●このカードがモンスターと戦闘を行うダメージステップ開始時に発動する。\nそのモンスターを破壊する。\n●このカードは罠カードの効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"X－ヘッド・キャノン",h:"えっくす－へっど・きゃのん",e:"X-Head Cannon",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1800,de:1500,tx:"強力なキャノン砲を装備した、合体能力を持つモンスター。\n合体と分離を駆使して様々な攻撃を繰り出す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"XZ－キャタピラー・キャノン",h:"えっくすぜっと－きゃたぴらー・きゃのん",e:"XZ-Tank Cannon",ra:"R",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:2400,de:2100,tx:"「X－ヘッド・キャノン」＋「Z－メタル・キャタピラー」\n自分フィールドの上記カードを除外した場合のみ、EXデッキから特殊召喚できる。\nこのカードは墓地からの特殊召喚はできない。\n①：手札を１枚捨て、相手フィールドの裏側表示の魔法・罠カード１枚を対象として発動できる。\nその相手の裏側表示のカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"XY－ドラゴン・キャノン",h:"えっくすわい－どらごん・きゃのん",e:"XY-Dragon Cannon",ra:"R",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:2200,de:1900,tx:"「X－ヘッド・キャノン」＋「Y－ドラゴン・ヘッド」\n自分フィールドの上記カードを除外した場合のみ、EXデッキから特殊召喚できる。\nこのカードは墓地からの特殊召喚はできない。\n①：手札を１枚捨て、相手フィールドの表側表示の魔法・罠カード１枚を対象として発動できる。\nその相手の表側表示のカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"XYZ－ドラゴン・キャノン",h:"えっくすわいぜっと－どらごん・きゃのん",e:"XYZ-Dragon Cannon",ra:"UR",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:2800,de:2600,tx:"「X－ヘッド・キャノン」＋「Y－ドラゴン・ヘッド」＋「Z－メタル・キャタピラー」\n自分フィールドの上記カードを除外した場合のみ、EXデッキから特殊召喚できる。\nこのカードは墓地からの特殊召喚はできない。\n①：手札を１枚捨て、相手フィールドのカード１枚を対象として発動できる。\nその相手のカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エトワール・サイバー",h:"えとわーる・さいばー",e:"Etoile Cyber",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:1600,tx:"このカードは相手プレイヤーを直接攻撃する場合、ダメージステップの間攻撃力が５００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エナジー・ブレイブ",h:"えなじー・ぶれいぶ",e:"Energy Bravery",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:1200,tx:"このカードが自分フィールド上に表側表示で存在する限り、自分フィールド上に存在する再度召喚された状態のデュアルモンスターは、カードの効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エビルナイト・ドラゴン",h:"えびるないと・どらごん",e:"Serpent Night Dragon",ra:"SR",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:2350,de:2400,tx:"邪悪な騎士の心に宿るドラゴンが実体化したもの。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エメス・ザ・インフィニティ",h:"えめす・ざ・いんふぃにてぃ",e:"Emes the Infinity",ra:"SR",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2500,de:2000,tx:"①：このカードが戦闘で相手モンスターを破壊し墓地へ送った場合に発動する。\nこのカードの攻撃力は７００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エメラルド・ドラゴン",h:"えめらるど・どらごん",e:"Luster Dragon #2",ra:"R",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:2400,de:1400,tx:"エメラルドを喰らうドラゴン。\nその美しい姿にひかれて命を落とす者は後を絶たない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エルディーン",h:"えるでぃーん",e:"Eldeen",ra:"N",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:950,de:1000,tx:"手にする杖を使い、様々な魔法で攻撃してくる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エルフの剣士",h:"えるふのけんし",e:"Celtic Guardian",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1400,de:1200,tx:"剣術を学んだエルフ。\n素早い攻撃で敵を翻弄する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エルフの聖剣士",h:"えるふのせいけんし",e:"Celtic Guard of Noble Arms",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2100,de:700,tx:"このカードはルール上「エルフの剣士」カードとしても扱う。\n①：自分の手札がある場合、このカードは攻撃できない。\n②：１ターンに１度、自分メインフェイズに発動できる。\n手札から「エルフの剣士」モンスター１体を特殊召喚する。\n③：このカードの攻撃で相手に戦闘ダメージを与えた時に発動できる。\n自分フィールドの「エルフの剣士」モンスターの数だけ、自分はデッキからドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エレキーウィ",h:"えれきーうぃ",e:"Wattkiwi",ra:"N",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"雷族",mo:["チューナー","効果"],ma:"",tp:"",at:600,de:100,tx:"このカードがフィールド上に表側表示で存在する限り、自分フィールド上に存在する「エレキ」と名のついたモンスターが攻撃する場合、攻撃モンスターは戦闘では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エレキジ",h:"えれきじ",e:"Wattpheasant",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:1000,de:800,tx:"このカードは相手プレイヤーに直接攻撃する事ができる。\nこのカードが直接攻撃によって相手ライフに戦闘ダメージを与えた時、フィールド上に表側表示で存在するモンスター１体を選択し、このターンのエンドフェイズ時までゲームから除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エレキタリス",h:"えれきたりす",e:"Wattsquirrel",ra:"N",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:700,de:100,tx:"このカードは１度のバトルフェイズ中に２回攻撃する事ができる。\nこのカードと戦闘を行った効果モンスターの効果をダメージ計算後に無効化する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エレキック・ファイター",h:"えれきっく・ふぁいたー",e:"Wattsychic Fighter",ra:"R",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1500,de:300,tx:"「エレキック・ファイター」の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した時、相手の墓地のカード１枚を対象として発動できる。\nそのカードを相手のデッキの一番上または一番下に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エレキッズ",h:"えれきっず",e:"Wattkid",ra:"N",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"雷族",mo:["通常"],ma:"",tp:"",at:1000,de:500,tx:"雷攻撃は意外と強い。\n甘く見ると感電するぞ。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エレキツネ",h:"えれきつね",e:"Wattfox",ra:"SR",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"雷族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:100,tx:"このカードが相手によって破壊された場合、そのターン相手はモンスターの特殊召喚及び魔法・罠・効果モンスターの効果を発動する事ができない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エレキテルドラゴン",h:"えれきてるどらごん",e:"Wattaildragon",ra:"R",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:2500,de:1000,tx:"常に電気を纏い空中を浮遊するドラゴン。\n古代より存在し、その生態には未だ謎が多いものの、古のルールにより捕獲は禁止されている。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エレキトンボ",h:"えれきとんぼ",e:"Wattdragonfly",ra:"R",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:900,de:100,tx:"このカードが相手によって破壊された場合、自分のデッキから「エレキ」と名のついたモンスター１体を特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エレキリム",h:"えれきりむ",e:"Watthydra",ra:"R",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"雷族",mo:["シンクロ","効果"],ma:"",tp:"",at:1500,de:1500,tx:"「エレキ」と名のついたチューナー＋チューナー以外の雷族モンスター１体以上\nこのカードは相手プレイヤーに直接攻撃する事ができる。\nこのカードが直接攻撃によって相手ライフに戦闘ダメージを与えた時、自分のデッキからカードを１枚選択してゲームから除外する。\n発動後２回目の自分のスタンバイフェイズ時にそのカードを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エレキリン",h:"えれきりん",e:"Wattgiraffe",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:1200,de:100,tx:"このカードは相手プレイヤーに直接攻撃する事ができる。\nこのカードが直接攻撃によって相手ライフに戦闘ダメージを与えた時、相手はこのターンのエンドフェイズ時まで魔法・罠・効果モンスターの効果を発動する事ができない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エレキングコブラ",h:"えれきんぐこぶら",e:"Wattcobra",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:1000,de:500,tx:"このカードは相手プレイヤーに直接攻撃する事ができる。\nこのカードが直接攻撃によって相手ライフに戦闘ダメージを与えた時、自分のデッキから「エレキ」と名のついたモンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エレキンモグラ",h:"えれきんもぐら",e:"Wattmole",ra:"R",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:0,de:100,tx:"このカードは１度のバトルフェイズ中に２回攻撃する事ができる。\nこのカードが裏側守備表示のモンスターを攻撃した場合、ダメージ計算を行わず裏側守備表示のままそのモンスターを破壊する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エレクトリック・スネーク",h:"えれくとりっく・すねーく",e:"Electric Snake",ra:"N",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:800,de:900,tx:"このカードが相手のカードの効果によって手札から墓地へ捨てられた時、自分のデッキからカードを２枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エレクトロ軍曹",h:"えれくとろぐんそう",e:"Sergeant Electro",ra:"SR",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1600,de:1300,tx:"①：１ターンに１度、相手の魔法＆罠ゾーンにセットされたカード１枚を対象として発動できる。\nこのカードは以下の効果を得る。\n\n●このカードがモンスターゾーンに存在する限り、対象のセットされたカードは発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"電磁石の戦士α",h:"えれくとろまぐねっと・うぉりあー・あるふぁ",e:"Alpha The Electromagnet Warrior",ra:"SR",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1700,de:1100,tx:"「電磁石の戦士α」の①の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキからレベル８の「磁石の戦士」モンスター１体を手札に加える。\n②：相手ターンにこのカードをリリースして発動できる。\nデッキからレベル４の「マグネット・ウォリアー」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"電磁石の戦士γ",h:"えれくとろまぐねっと・うぉりあー・がんま",e:"Gamma The Electromagnet Warrior",ra:"SR",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:800,de:2000,tx:"「電磁石の戦士γ」の①の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\n手札から「電磁石の戦士γ」以外のレベル４以下の「マグネット・ウォリアー」モンスター１体を特殊召喚する。\n②：相手ターンにこのカードをリリースして発動できる。\nデッキからレベル４の「マグネット・ウォリアー」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"電磁石の戦士β",h:"えれくとろまぐねっと・うぉりあー・べーた",e:"Beta The Electromagnet Warrior",ra:"SR",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1500,de:1500,tx:"「電磁石の戦士β」の①の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「電磁石の戦士β」以外のレベル４以下の「マグネット・ウォリアー」モンスター１体を手札に加える。\n②：相手ターンにこのカードをリリースして発動できる。\nデッキからレベル４の「マグネット・ウォリアー」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エレファン",h:"えれふぁん",e:"Elephun",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["チューナー","効果"],ma:"",tp:"",at:500,de:300,tx:"このカードが戦闘によって破壊され墓地へ送られた時、ゲームから除外されている自分のレベル３以下の獣族・獣戦士族・鳥獣族モンスター１体を選択して手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"精霊神后 ドリアード",h:"えれめんたるぐれいす　どりあーど",e:"Elemental Grace Doriado",ra:"SR",t:"monster",a:"光",l:9,ps:"",pe:"",tr:"魔法使い族",mo:["特殊召喚","効果"],ma:"",tp:"",at:0,de:0,tx:"このカードは通常召喚できない。\n自分・相手の墓地のモンスターの属性が６種類以上の場合のみ特殊召喚できる。\n①：このカードの攻撃力・守備力は、自分・相手の墓地のモンスターの属性の種類×５００アップする。\n②：相手がモンスターを特殊召喚する際に、自分の墓地のモンスター３体を除外して発動できる。\nその特殊召喚を無効にし、そのモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E・HERO アイスエッジ",h:"えれめんたるひーろー　あいすえっじ",e:"Elemental HERO Ice Edge",ra:"R",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:800,de:900,tx:"１ターンに１度、自分のメインフェイズ１で手札を１枚捨てて発動する。\nこのターンこのカードは相手プレイヤーに直接攻撃する事ができる。\nまた、このカードが直接攻撃によって相手ライフに戦闘ダメージを与えた時、相手の魔法＆罠カードゾーンにセットされたカード１枚を破壊する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E・HERO アクア・ネオス",h:"えれめんたるひーろー　あくあ・ねおす",e:"Elemental HERO Aqua Neos",ra:"R",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2500,de:2000,tx:"「E・HERO ネオス」＋「N・アクア・ドルフィン」\n自分フィールド上の上記のカードをデッキに戻した場合のみ、エクストラデッキから特殊召喚できる（「融合」魔法カードは必要としない）。\n１ターンに１度、手札を１枚捨てる事で、相手の手札をランダムに１枚選んで破壊する。\nまた、エンドフェイズ時、このカードはエクストラデッキに戻る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E・HERO アナザー・ネオス",h:"えれめんたるひーろー　あなざー・ねおす",e:"Elemental HERO Neos Alius",ra:"UR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["デュアル","効果"],ma:"",tp:"",at:1900,de:1300,tx:"①：このカードはフィールド・墓地に存在する限り、通常モンスターとして扱う。\n②：フィールドの通常モンスター扱いのこのカードを通常召喚としてもう１度召喚できる。\nその場合このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●このカードはモンスターゾーンに存在する限り、カード名を「E・HERO ネオス」として扱う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E・HERO エアーマン",h:"えれめんたるひーろー　えあーまん",e:"Elemental HERO Stratos",ra:"UR",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:300,tx:"①：このカードが召喚・特殊召喚に成功した時、以下の効果から１つを選択して発動できる。\n●このカード以外の自分フィールドの「HERO」モンスターの数まで、フィールドの魔法・罠カードを選んで破壊する。\n●デッキから「HERO」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E・HERO エスクリダオ",h:"えれめんたるひーろー　えすくりだお",e:"Elemental HERO Escuridao",ra:"SR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2500,de:2000,tx:"「E・HERO」と名のついたモンスター＋闇属性モンスター\nこのカードは融合召喚でしか特殊召喚できない。\nこのカードの攻撃力は、自分の墓地に存在する「E・HERO」と名のついたモンスターの数×１００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E・HERO エッジマン",h:"えれめんたるひーろー　えっじまん",e:"Elemental HERO Bladedge",ra:"SR",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2600,de:1800,tx:"①：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E・HERO エリクシーラー",h:"えれめんたるひーろー　えりくしーらー",e:"Elemental HERO Electrum",ra:"UR",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2900,de:2600,tx:"「E・HERO フェザーマン」＋「E・HERO バーストレディ」＋「E・HERO クレイマン」＋「E・HERO バブルマン」\nこのモンスターは融合召喚でしか特殊召喚できない。\nこのカードの属性は「風」「水」「炎」「地」としても扱う。\nこのカードが融合召喚に成功した時、ゲームから除外された全てのカードを持ち主のデッキに戻し、デッキをシャッフルする。\n相手フィールド上に存在するこのカードと同じ属性のモンスター１体につき、このカードの攻撃力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E・HERO オーシャン",h:"えれめんたるひーろー　おーしゃん",e:"Elemental HERO Ocean",ra:"R",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:1200,tx:"①：１ターンに１度、自分スタンバイフェイズに自分のフィールド・墓地の「HERO」モンスター１体を対象として発動できる。\nその自分の「HERO」モンスターを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E・HERO ガイア",h:"えれめんたるひーろー　がいあ",e:"Elemental HERO Gaia",ra:"UR",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2200,de:2600,tx:"「E・HERO」モンスター＋地属性モンスター\nこのカードは融合召喚でしか特殊召喚できない。\n①：このカードが融合召喚に成功した場合、相手フィールドの表側表示モンスター１体を対象として発動する。\nターン終了時まで、そのモンスターの攻撃力を半分にし、このカードの攻撃力はその数値分アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E・HERO カオス・ネオス",h:"えれめんたるひーろー　かおす・ねおす",e:"Elemental HERO Chaos Neos",ra:"UR",t:"monster",a:"闇",l:9,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:3000,de:2500,tx:"「E・HERO ネオス」＋「N・ブラック・パンサー」＋「N・グロー・モス」\n自分フィールド上に存在する上記のカードをデッキに戻した場合のみ、融合デッキから特殊召喚が可能（「融合」魔法カードは必要としない）。\nエンドフェイズ時にこのカードを融合デッキに戻し、フィールド上に存在する全ての表側表示モンスターをセットした状態にする。\nコイントスを３回行い、表が出た回数によって以下の処理を行う。\nこの効果は１ターンに１度だけ自分のメインフェイズ１に使用する事ができる。\n●３回：相手フィールド上に存在する全てのモンスターを破壊する。\n●２回：このターン相手フィールド上に表側表示で存在する効果モンスターは全て効果が無効化される。\n●１回：自分フィールド上に存在する全てのモンスターを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E・HERO キャプテン・ゴールド",h:"えれめんたるひーろー　きゃぷてん・ごーるど",e:"Elemental HERO Captain Gold",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2100,de:800,tx:"このカードを手札から墓地へ捨てて発動できる。\nデッキから「摩天楼 －スカイスクレイパー－」１枚を手札に加える。\nまた、フィールド上に「摩天楼 －スカイスクレイパー－」が存在しない場合、このカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E・HERO クノスペ",h:"えれめんたるひーろー　くのすぺ",e:"Elemental HERO Knospe",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:600,de:1000,tx:"相手プレイヤーに戦闘ダメージを与える度に、このカードの攻撃力は１００ポイントアップし、守備力は１００ポイントダウンする。\nこのカードを除く「E・HERO」と名のついたモンスターが自分フィールド上に表側表示で存在する限り、相手はこのカードを攻撃対象に選択できず、このカードは相手プレイヤーに直接攻撃する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E・HERO グラン・ネオス",h:"えれめんたるひーろー　ぐらん・ねおす",e:"Elemental HERO Grand Neos",ra:"UR",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2500,de:2000,tx:"「E・HERO ネオス」＋「N・グラン・モール」\n自分フィールド上の上記のカードをデッキに戻した場合のみ、エクストラデッキから特殊召喚できる（「融合」魔法カードは必要としない）。\n１ターンに１度、相手フィールド上のモンスター１体を選択して持ち主の手札に戻す事ができる。\nまた、エンドフェイズ時、このカードはエクストラデッキに戻る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E・HERO グランドマン",h:"えれめんたるひーろー　ぐらんどまん",e:"Elemental HERO Grandmerge",ra:"UR",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:0,de:0,tx:"「HERO」通常モンスター×２\nこのカードは融合召喚でしか特殊召喚できない。\n①：このカードの攻撃力・守備力は、このカードの融合素材としたモンスターの元々のレベルの合計×３００アップする。\n②：このカードが戦闘で相手モンスターを破壊した時、このカードをリリースして発動できる。\nEXデッキから「E・HERO」融合モンスター１体を召喚条件を無視して特殊召喚する。\nこの効果で特殊召喚したモンスターは自身のレベル以下のレベルを持つモンスターを攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E・HERO Great TORNADO",h:"えれめんたるひーろー　ぐれいと　とるねーど",e:"Elemental HERO Great Tornado",ra:"UR",t:"monster",a:"風",l:8,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2800,de:2200,tx:"「E・HERO」モンスター＋風属性モンスター\nこのカードは融合召喚でしか特殊召喚できない。\n①：このカードが融合召喚に成功した場合に発動する。\n相手フィールドの全てのモンスターの攻撃力・守備力は半分になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E・HERO クレイマン",h:"えれめんたるひーろー　くれいまん",e:"Elemental HERO Clayman",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:800,de:2000,tx:"粘土でできた頑丈な体を持つE・HERO。\n体をはって、仲間のE・HEROを守り抜く。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E・HERO グロー・ネオス",h:"えれめんたるひーろー　ぐろー・ねおす",e:"Elemental HERO Glow Neos",ra:"N",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2500,de:2000,tx:"「E・HERO ネオス」＋「N・グロー・モス」\n自分フィールド上に存在する上記のカードをデッキに戻した場合のみ、融合デッキから特殊召喚が可能（「融合」魔法カードは必要としない）。\nエンドフェイズ時にこのカードは融合デッキに戻る。\n相手フィールド上に表側表示で存在するカード１枚を破壊し、そのカードの種類によりこのカードは以下の効果を得る。\nこの効果は１ターンに１度だけ自分のメインフェイズ１に使用する事ができる。\n●モンスターカード：このターン、このカードは戦闘を行えない。\n●魔法カード：このカードは相手プレイヤーに直接攻撃する事ができる。\n●罠カード：このカードは守備表示になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E・HERO Core",h:"えれめんたるひーろー　こあ",e:"Elemental HERO Core",ra:"SR",t:"monster",a:"地",l:9,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2700,de:2200,tx:"「E・HERO」モンスター×３\nこのカードは融合召喚でしか特殊召喚できない。\n①：１ターンに１度、このカードが攻撃対象になった時に発動できる。\nこのカードの攻撃力はそのダメージステップ終了時まで倍になる。\n②：このカードが戦闘を行ったバトルフェイズ終了時、フィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊する。\n③：このカードが戦闘・効果で破壊された時、自分の墓地のレベル８以下の「E・HERO」融合モンスター１体を対象として発動できる。\nそのモンスターを召喚条件を無視して特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E・HERO ゴッド・ネオス",h:"えれめんたるひーろー　ごっど・ねおす",e:"Elemental HERO Divine Neos",ra:"UR",t:"monster",a:"光",l:12,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2500,de:2500,tx:"このカードは融合召喚でしか特殊召喚できない。\n「ネオス」・「N」・「HERO」と名のついたモンスターをそれぞれ１体以上、合計５体のモンスターを融合素材として融合召喚する。\n１ターンに１度、自分の墓地に存在する「ネオス」・「N」・「HERO」と名のついたモンスター１体をゲームから除外する事で、このカードの攻撃力は５００ポイントアップする。\nさらに、エンドフェイズ時までそのモンスターと同じ効果を得る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E・HERO The シャイニング",h:"えれめんたるひーろー　ざ　しゃいにんぐ",e:"Elemental HERO The Shining",ra:"UR",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2600,de:2100,tx:"「E・HERO」と名のついたモンスター＋光属性モンスター\nこのカードは融合召喚でしか特殊召喚できない。\nこのカードの攻撃力は、ゲームから除外されている自分の「E・HERO」と名のついたモンスターの数×３００ポイントアップする。\nこのカードがフィールド上から墓地へ送られた時、ゲームから除外されている自分の「E・HERO」と名のついたモンスターを２体まで選択し、手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E・HERO ザ・ヒート",h:"えれめんたるひーろー　ざ・ひーと",e:"Elemental HERO Heat",ra:"R",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"①：このカードの攻撃力は、自分フィールドの「E・HERO」モンスターの数×２００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E・HERO サンダー・ジャイアント",h:"えれめんたるひーろー　さんだー・じゃいあんと",e:"Elemental HERO Thunder Giant",ra:"SR",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2400,de:1500,tx:"「E・HERO スパークマン」＋「E・HERO クレイマン」\nこのモンスターは融合召喚でしか特殊召喚できない。\n自分の手札を１枚捨てる事で、フィールド上に表側表示で存在する元々の攻撃力がこのカードの攻撃力よりも低いモンスター１体を選択して破壊する。\nこの効果は１ターンに１度だけ自分のメインフェイズに使用する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E・HERO ジ・アース",h:"えれめんたるひーろー　じ・あーす",e:"Elemental HERO Terra Firma",ra:"UR",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2500,de:2000,tx:"「E・HERO オーシャン」＋「E・HERO フォレストマン」\nこのカードは融合召喚でしか特殊召喚できない。\n①：このカード以外の自分フィールドの表側表示の「E・HERO」モンスター１体をリリースして発動できる。\nこのカードの攻撃力はターン終了時まで、リリースしたモンスターの攻撃力分アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E・HERO シャイニング・フレア・ウィングマン",h:"えれめんたるひーろー　しゃいにんぐ・ふれあ・うぃんぐまん",e:"Elemental HERO Shining Flare Wingman",ra:"UR",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2500,de:2100,tx:"「E・HERO フレイム・ウィングマン」＋「E・HERO スパークマン」\nこのカードは融合召喚でしか特殊召喚できない。\n①：このカードの攻撃力は、自分の墓地の「E・HERO」カードの数×３００アップする。\n②：このカードが戦闘でモンスターを破壊し墓地へ送った場合に発動する。\nそのモンスターの元々の攻撃力分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E・HERO スチーム・ヒーラー",h:"えれめんたるひーろー　すちーむ・ひーらー",e:"Elemental HERO Steam Healer",ra:"R",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:1800,de:1000,tx:"「E・HERO バーストレディ」＋「E・HERO バブルマン」\nこのカードは融合召喚でしか特殊召喚できない。\nこのカードが戦闘によってモンスターを破壊し墓地へ送った時、破壊したモンスターの元々の攻撃力分だけ自分のライフポイントを回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E・HERO ストーム・ネオス",h:"えれめんたるひーろー　すとーむ・ねおす",e:"Elemental HERO Storm Neos",ra:"SR",t:"monster",a:"風",l:9,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:3000,de:2500,tx:"「E・HERO ネオス」＋「N・エア・ハミングバード」＋「N・アクア・ドルフィン」\n自分フィールド上の上記のカードをデッキに戻した場合のみ、エクストラデッキから特殊召喚できる（「融合」魔法カードは必要としない）。\n１ターンに１度、自分のメインフェイズ時にフィールド上の魔法・罠カードを全て破壊できる。\nまた、エンドフェイズ時、このカードはエクストラデッキに戻る。\nこの効果によってこのカードがエクストラデッキに戻った時、フィールド上のカードを全て持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E・HERO スパークマン",h:"えれめんたるひーろー　すぱーくまん",e:"Elemental HERO Sparkman",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1600,de:1400,tx:"様々な武器を使いこなす、光の戦士のE・HERO。\n聖なる輝きスパークフラッシュが悪の退路を断つ。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E・HERO セイラーマン",h:"えれめんたるひーろー　せいらーまん",e:"Elemental HERO Mariner",ra:"R",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:1400,de:1000,tx:"「E・HERO バブルマン」＋「E・HERO フェザーマン」\nこのモンスターは融合召喚でしか特殊召喚できない。\n自分の魔法＆罠カードゾーンにカードがセットされている場合、このカードは相手プレイヤーに直接攻撃をする事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E・HERO ソリッドマン",h:"えれめんたるひーろー　そりっどまん",e:"Elemental HERO Solid Soldier",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1300,de:1100,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時に発動できる。\n手札からレベル４以下の「HERO」モンスター１体を特殊召喚する。\n②：このカードが魔法カードの効果でモンスターゾーンから墓地へ送られた場合、「E・HERO ソリッドマン」以外の自分の墓地の「HERO」モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E・HERO ダーク・ブライトマン",h:"えれめんたるひーろー　だーく・ぶらいとまん",e:"Elemental HERO Darkbright",ra:"R",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2000,de:1000,tx:"「E・HERO スパークマン」＋「E・HERO ネクロダークマン」\nこのカードは融合召喚でしか特殊召喚できない。\nこのカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。\nまた、このカードは攻撃した場合、ダメージステップ終了時に守備表示になる。\nこのカードが破壊された時、相手フィールド上のモンスター１体を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E・HERO テンペスター",h:"えれめんたるひーろー　てんぺすたー",e:"Elemental HERO Tempest",ra:"SR",t:"monster",a:"風",l:8,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2800,de:2800,tx:"「E・HERO フェザーマン」＋「E・HERO スパークマン」＋「E・HERO バブルマン」\nこのモンスターは融合召喚でしか特殊召喚できない。\nこのカード以外の自分フィールド上のカード１枚を墓地に送り、自分フィールド上のモンスター１体を選択する。\nこのカードがフィールド上に表側表示で存在する限り、選択したモンスターは戦闘によっては破壊されない。\n（ダメージ計算は適用する）",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E・HERO ネオス",h:"えれめんたるひーろー　ねおす",e:"Elemental HERO Neos",ra:"UR",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:2500,de:2000,tx:"ネオスペースからやってきた新たなるE・HERO。\nネオスペーシアンとコンタクト融合する事で、未知なる力を発揮する！",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E・HERO ネオス・ナイト",h:"えれめんたるひーろー ねおす・ないと",e:"Elemental HERO Neos Knight",ra:"R",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2500,de:1000,tx:"「E・HERO ネオス」＋戦士族モンスター\nこのカードは融合召喚でしか特殊召喚できない。\n①：このカードの攻撃力は、このカードの融合素材とした「E・HERO ネオス」以外のモンスターの元々の攻撃力の半分の数値分アップする。\n②：このカードは１度のバトルフェイズ中に２回攻撃できる。\n③：このカードの戦闘で発生する相手への戦闘ダメージは０になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E・HERO ネクロイド・シャーマン",h:"えれめんたるひーろー　ねくろいど・しゃーまん",e:"Elemental HERO Necroid Shaman",ra:"R",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:1900,de:1800,tx:"「E・HERO ワイルドマン」＋「E・HERO ネクロダークマン」\nこのカードは融合召喚でしか特殊召喚できない。\n①：このカードが特殊召喚に成功した場合、相手フィールドのモンスター１体を対象として発動する。\nその相手モンスターを破壊する。\nその後、相手の墓地からモンスター１体を選び、相手フィールドに特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E・HERO ネクロダークマン",h:"えれめんたるひーろー　ねくろだーくまん",e:"Elemental HERO Necroshade",ra:"R",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1800,tx:"①：このカードが墓地に存在する限り１度だけ、自分はレベル５以上の「E・HERO」モンスター１体をリリースなしで召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E・HERO ノヴァマスター",h:"えれめんたるひーろー　のう゛ぁますたー",e:"Elemental HERO Nova Master",ra:"UR",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2600,de:2100,tx:"「E・HERO」モンスター＋炎属性モンスター \nこのカードは融合召喚でしか特殊召喚できない。\n①：このカードが戦闘で相手モンスターを破壊した場合に発動する。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E・HERO バーストレディ",h:"えれめんたるひーろー　ばーすとれでぃ",e:"Elemental HERO Burstinatrix",ra:"N",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1200,de:800,tx:"炎を操るE・HEROの紅一点。\n紅蓮の炎、バーストファイヤーが悪を焼き尽くす。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E・HERO バブルマン",h:"えれめんたるひーろー　ばぶるまん",e:"Elemental HERO Bubbleman",ra:"R",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:800,de:1200,tx:"①：手札がこのカード１枚のみの場合、このカードは手札から特殊召喚できる。\n②：このカードが召喚・反転召喚・特殊召喚に成功した時に発動できる。\n自分はデッキから２枚ドローする。\nこの効果は自分の手札・フィールドに他のカードが無い場合に発動と処理ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E・HERO フェザーマン",h:"えれめんたるひーろー　ふぇざーまん",e:"Elemental HERO Avian",ra:"N",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1000,de:1000,tx:"風を操り空を舞う翼を持ったE・HERO。\n天空からの一撃、フェザーブレイクで悪を裁く。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E・HERO フォレストマン",h:"えれめんたるひーろー　ふぉれすとまん",e:"Elemental HERO Woodsman",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:2000,tx:"①：１ターンに１度、自分スタンバイフェイズに発動できる。\n自分のデッキ・墓地から「融合」１枚を選んで手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E・HERO プラズマヴァイスマン",h:"えれめんたるひーろー　ぷらずまう゛ぁいすまん",e:"Elemental HERO Plasma Vice",ra:"UR",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2600,de:2300,tx:"「E・HERO スパークマン」＋「E・HERO エッジマン」\nこのカードは融合召喚でしか特殊召喚できない。\n自分のメインフェイズ時に、手札を１枚捨てて発動できる。\n相手フィールド上に攻撃表示で存在するモンスター１体を選択して破壊する。\nまた、このカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E・HERO ブラック・ネオス",h:"えれめんたるひーろー　ぶらっく・ねおす",e:"Elemental HERO Dark Neos",ra:"R",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2500,de:2000,tx:"「E・HERO ネオス」＋「N・ブラック・パンサー」\n自分フィールド上の上記のカードをデッキに戻した場合のみ、エクストラデッキから特殊召喚できる（「融合」魔法カードは必要としない）。\nフィールド上の効果モンスター１体を選択して発動できる。\nこのカードがフィールド上に表側表示で存在する限り、選択したモンスターの効果は無効化される（この効果で選択できるモンスターは１体まで）。\nまた、エンドフェイズ時、このカードはエクストラデッキに戻る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E・HERO フラッシュ",h:"えれめんたるひーろー　ふらっしゅ",e:"Elemental HERO Flash",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1100,de:1600,tx:"このカードが戦闘によって破壊され墓地へ送られた時、自分の墓地からこのカードと「E・HERO」と名のついたモンスターカード３種類をゲームから除外する事で、自分の墓地に存在する通常魔法カード１枚を選択して手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E・HERO ブルーメ",h:"えれめんたるひーろー　ぶるーめ",e:"Elemental HERO Poison Rose",ra:"R",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"植物族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1900,de:2000,tx:"このカードは通常召喚できない。\nこのカードは「ブルーメンブラット」の効果でのみ特殊召喚する事ができる。\n相手は「E・HERO ブルーメ」しか攻撃対象に選択できない。\n相手プレイヤーに戦闘ダメージを与える度に、このカードの攻撃力は２００ポイントアップし、守備力は２００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E・HERO フレア・ネオス",h:"えれめんたるひーろー　ふれあ・ねおす",e:"Elemental HERO Flare Neos",ra:"R",t:"monster",a:"炎",l:7,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2500,de:2000,tx:"「E・HERO ネオス」＋「N・フレア・スカラベ」\n自分フィールド上の上記のカードをデッキに戻した場合のみ、エクストラデッキから特殊召喚できる（「融合」魔法カードは必要としない）。\nこのカードの攻撃力は、フィールド上の魔法・罠カードの数×４００ポイントアップする。\nまた、エンドフェイズ時、このカードはエクストラデッキに戻る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E・HERO ブレイヴ・ネオス",h:"えれめんたるひーろー ぶれいう゛・ねおす",e:"Elemental HERO Brave Neos",ra:"UR",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2500,de:2000,tx:"「E・HERO ネオス」＋レベル４以下の効果モンスター\nこのカードは融合召喚でしか特殊召喚できない。\n①：このカードの攻撃力は自分の墓地の「N」モンスター及び「HERO」モンスターの数×１００アップする。\n②：このカードが戦闘で相手モンスターを破壊した時に発動できる。\n「E・HERO ネオス」のカード名が記された魔法・罠カード１枚をデッキから手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E・HERO ブレイズマン",h:"えれめんたるひーろー　ぶれいずまん",e:"Elemental HERO Blazeman",ra:"SR",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:1800,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「融合」１枚を手札に加える。\n②：自分メインフェイズに発動できる。\nデッキから「E・HERO ブレイズマン」以外の「E・HERO」モンスター１体を墓地へ送る。\nこのカードはターン終了時まで、この効果で墓地へ送ったモンスターと同じ属性・攻撃力・守備力になる。\nこの効果の発動後、ターン終了時まで自分は融合モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E・HERO フレイム・ウィングマン",h:"えれめんたるひーろー　ふれいむ・うぃんぐまん",e:"Elemental HERO Flame Wingman",ra:"R",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2100,de:1200,tx:"「E・HERO フェザーマン」＋「E・HERO バーストレディ」\nこのカードは融合召喚でしか特殊召喚できない。\n①：このカードが戦闘でモンスターを破壊し墓地へ送った場合に発動する。\nそのモンスターの元々の攻撃力分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E・HERO フレイム・ブラスト",h:"えれめんたるひーろー　ふれいむ・ぶらすと",e:"Elemental HERO Inferno",ra:"R",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"炎族",mo:["融合","効果"],ma:"",tp:"",at:2300,de:1600,tx:"「E・HERO ザ・ヒート」＋「E・HERO レディ・オブ・ファイア」\nこのモンスターは融合召喚でしか特殊召喚できない。\n水属性モンスターと戦闘を行う場合、ダメージステップの間このカードの攻撃力は１０００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E・HERO ボルテック",h:"えれめんたるひーろー　ぼるてっく",e:"Elemental HERO Voltic",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:1000,de:1500,tx:"①：このカードが相手に戦闘ダメージを与えた時、除外されている自分の「E・HERO」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E・HERO マグマ・ネオス",h:"えれめんたるひーろー　まぐま・ねおす",e:"Elemental HERO Magma Neos",ra:"R",t:"monster",a:"炎",l:9,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:3000,de:2500,tx:"「E・HERO ネオス」＋「N・フレア・スカラベ」＋「N・グラン・モール」\n自分フィールド上の上記のカードをデッキに戻した場合のみ、エクストラデッキから特殊召喚できる（「融合」魔法カードは必要としない）。\nこのカードの攻撃力は、フィールド上のカードの数×４００ポイントアップする。\nまた、エンドフェイズ時、このカードはエクストラデッキに戻る。\nこの効果によってこのカードがエクストラデッキに戻った時、フィールド上のカードを全て持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E・HERO マッドボールマン",h:"えれめんたるひーろー　まっどぼーるまん",e:"Elemental HERO Mudballman",ra:"N",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:1900,de:3000,tx:"「E・HERO バブルマン」＋「E・HERO クレイマン」\nこのモンスターは融合召喚でしか特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E・HERO マリン・ネオス",h:"えれめんたるひーろー　まりん・ねおす",e:"Elemental HERO Marine Neos",ra:"N",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2800,de:2300,tx:"「E・HERO ネオス」＋「N・マリン・ドルフィン」\n自分フィールド上に存在する上記のカードをデッキに戻した場合のみ、融合デッキから特殊召喚が可能（「融合」魔法カードは必要としない）。\n相手の手札１枚をランダムに選択し破壊する。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E・HERO ランパートガンナー",h:"えれめんたるひーろー　らんぱーとがんなー",e:"Elemental HERO Rampart Blaster",ra:"R",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2000,de:2500,tx:"「E・HERO クレイマン」＋「E・HERO バーストレディ」\nこのモンスターは融合召喚でしか特殊召喚できない。\nこのカードが表側守備表示の場合、守備表示の状態で相手プレイヤーを直接攻撃する事ができる。\nその場合、このカードの攻撃力はダメージ計算時のみ半分になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E・HERO レディ・オブ・ファイア",h:"えれめんたるひーろー　れでぃ・おぶ・ふぁいあ",e:"Elemental HERO Lady Heat",ra:"R",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:1300,de:1000,tx:"自分のターンのエンドフェイズ時、自分フィールド上に表側表示で存在する「E・HERO」と名のついたモンスターの数×２００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E・HERO ワイルド・ウィングマン",h:"えれめんたるひーろー　わいるど・うぃんぐまん",e:"Elemental HERO Wild Wingman",ra:"R",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:1900,de:2300,tx:"「E・HERO ワイルドマン」＋「E・HERO フェザーマン」\nこのモンスターは融合召喚でしか特殊召喚できない。\n手札を１枚捨てる事で、フィールド上の魔法・罠カード１枚を破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E・HERO ワイルドジャギーマン",h:"えれめんたるひーろー　わいるどじゃぎーまん",e:"Elemental HERO Wildedge",ra:"R",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2600,de:2300,tx:"「E・HERO ワイルドマン」＋「E・HERO エッジマン」\nこのモンスターは融合召喚でしか特殊召喚できない。\n相手フィールド上の全てのモンスターに１回ずつ攻撃をする事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"E・HERO ワイルドマン",h:"えれめんたるひーろー　わいるどまん",e:"Elemental HERO Wildheart",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:1600,tx:"①：このカードはモンスターゾーンに存在する限り、罠カードの効果を受けない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"精霊術師 ドリアード",h:"えれめんたるますたー　どりあーど",e:"Elemental Mistress Doriado",ra:"R",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["儀式","効果"],ma:"",tp:"",at:1200,de:1400,tx:"「ドリアードの祈り」により降臨。\nこのカードの属性は「風」「水」「炎」「地」としても扱う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エレメント・ヴァルキリー",h:"えれめんと・う゛ぁるきりー",e:"Element Valkyrie",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1500,de:1200,tx:"このモンスターはフィールド上に特定の属性を持つモンスターが存在する場合、以下の効果を得る。\n\n●炎属性：このカードの攻撃力は５００ポイントアップする。\n\n●水属性：このカードのコントロールを変更する事ができない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エレメント・ザウルス",h:"えれめんと・ざうるす",e:"Element Saurus",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1500,de:1200,tx:"このモンスターはフィールド上に特定の属性を持つモンスターが存在する場合、以下の効果を得る。\n\n●炎属性：このカードの攻撃力は５００ポイントアップする。\n\n●地属性：このカードが戦闘によって破壊した効果モンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エレメント・ソルジャー",h:"えれめんと・そるじゃー",e:"Element Soldier",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1500,de:1200,tx:"このモンスターはフィールド上に特定の属性を持つモンスターが存在する場合、以下の効果を得る。\n\n●水属性：このカードのコントロールを変更する事ができない。\n\n●地属性：このカードが戦闘によって破壊した効果モンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エレメント・デビル",h:"えれめんと・でびる",e:"Element Doom",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1500,de:1200,tx:"このモンスターはフィールド上に特定の属性を持つモンスターが存在する場合、以下の効果を得る。\n\n●地属性：このカードが戦闘によって破壊した効果モンスターの効果は無効化される。\n\n●風属性：このカードが戦闘によって相手モンスターを破壊した場合、もう一度だけ続けて攻撃を行う事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エレメント・ドラゴン",h:"えれめんと・どらごん",e:"Element Dragon",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1500,de:1200,tx:"①：フィールドのモンスターの属性によって、このカードは以下の効果を得る。\n\n●炎属性：このカードの攻撃力は５００アップする。\n\n●風属性：このカードが戦闘で相手モンスターを破壊した時に発動できる。\nこのカードはもう１度だけ続けて攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エレメント・マジシャン",h:"えれめんと・まじしゃん",e:"Element Magician",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1500,de:1200,tx:"このモンスターはフィールド上に特定の属性を持つモンスターが存在する場合、以下の効果を得る。\n\n●水属性：このカードのコントロールを変更する事ができない。\n\n●風属性：このカードが戦闘によって相手モンスターを破壊した場合、もう一度だけ続けて攻撃を行う事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エレメントセイバー・アイナ",h:"えれめんとせいばー・あいな",e:"Elementsaber Aina",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:900,de:1800,tx:"①：１ターンに１度、手札から「エレメントセイバー」モンスター１体を墓地へ送り、自分の墓地の、「エレメントセイバー・アイナ」以外の「エレメントセイバー」モンスターまたは「霊神」モンスター１体を対象として発動できる。\nそのモンスターを召喚条件を無視して特殊召喚する。\n②：このカードが墓地に存在する場合、１ターンに１度、属性を１つ宣言して発動できる。\n墓地のこのカードはターン終了時まで宣言した属性になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エレメントセイバー・ナル",h:"えれめんとせいばー・なる",e:"Elementsaber Nalu",ra:"N",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"①：１ターンに１度、手札から「エレメントセイバー」モンスター１体を墓地へ送り、自分の墓地の、「エレメントセイバー・ナル」以外の「エレメントセイバー」モンスターまたは「霊神」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\n②：このカードが墓地に存在する場合、１ターンに１度、属性を１つ宣言して発動できる。\n墓地のこのカードはターン終了時まで宣言した属性になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エレメントセイバー・マカニ",h:"えれめんとせいばー・まかに",e:"Elementsaber Makani",ra:"N",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1300,de:1200,tx:"①：１ターンに１度、手札から「エレメントセイバー」モンスター１体を墓地へ送って発動できる。\nデッキから「エレメントセイバー・マカニ」以外の「エレメントセイバー」モンスターまたは「霊神」モンスター１体を手札に加える。\n②：このカードが墓地に存在する場合、１ターンに１度、属性を１つ宣言して発動できる。\n墓地のこのカードはターン終了時まで宣言した属性になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エレメントセイバー・マロー",h:"えれめんとせいばー・まろー",e:"Elementsaber Malo",ra:"N",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:200,tx:"①：１ターンに１度、手札から「エレメントセイバー」モンスター１体を墓地へ送って発動できる。\nデッキから「エレメントセイバー・マロー」以外の「エレメントセイバー」モンスターまたは「霊神」モンスター１体を墓地へ送る。\n②：このカードが墓地に存在する場合、１ターンに１度、属性を１つ宣言して発動できる。\n墓地のこのカードはターン終了時まで宣言した属性になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エレメントセイバー・モーレフ",h:"えれめんとせいばー・もーれふ",e:"Elementsaber Molehu",ra:"UR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1900,de:0,tx:"①：１ターンに１度、手札から「エレメントセイバー」モンスター１体を墓地へ送り、フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターを裏側守備表示にする。\nこの効果は相手ターンでも発動できる。\n②：このカードが墓地に存在する場合、１ターンに１度、属性を１つ宣言して発動できる。\n墓地のこのカードはターン終了時まで宣言した属性になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エレメントセイバー・ラパウィラ",h:"えれめんとせいばー・らぱうぃら",e:"Elementsaber Lapauila",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:400,de:2100,tx:"①：１ターンに１度、魔法・罠カードが発動した時、手札から「エレメントセイバー」モンスター１体を墓地へ送って発動できる。\nその発動を無効にし破壊する。\n②：このカードが墓地に存在する場合、１ターンに１度、属性を１つ宣言して発動できる。\n墓地のこのカードはターン終了時まで宣言した属性になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"炎王獣 ガネーシャ",h:"えんおうじゅう　がねーしゃ",e:"Fire King Avatar Arvata",ra:"SR",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:200,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがフィールドに存在し、モンスターの効果が発動した時に発動できる。\nその発動を無効にし、このカード以外の自分の手札・フィールドの炎属性モンスター１体を選んで破壊する。\n②：このカードが破壊され墓地へ送られた場合、「炎王獣 ガネーシャ」以外の自分の墓地の炎属性の獣族・獣戦士族・鳥獣族モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nその特殊召喚したモンスターの効果は無効化され、エンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"炎王獣 ガルドニクス",h:"えんおうじゅう　がるどにくす",e:"Fire King Avatar Garunix",ra:"SR",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:700,de:1700,tx:"自分フィールド上に表側表示で存在する「炎王」と名のついたモンスターがカードの効果によって破壊された場合、このカードを手札から特殊召喚できる。\nまた、このカードが相手によって破壊され墓地へ送られた場合、デッキから「炎王獣」と名のついたモンスター１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"炎王獣 キリン",h:"えんおうじゅう　きりん",e:"Fire King Avatar Kirin",ra:"N",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1000,de:200,tx:"自分フィールド上に表側表示で存在する「炎王」と名のついたモンスターがカードの効果によって破壊された場合、このカードを手札から特殊召喚できる。\nまた、このカードが破壊され墓地へ送られた場合、デッキから炎属性モンスター１体を墓地へ送る事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"炎王獣 バロン",h:"えんおうじゅう　ばろん",e:"Fire King Avatar Barong",ra:"R",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:200,tx:"自分フィールド上に表側表示で存在する「炎王」と名のついたモンスターがカードの効果によって破壊された場合、このカードを手札から特殊召喚できる。\nまた、このカードがカードの効果によって破壊され墓地へ送られた場合、次のスタンバイフェイズ時に発動する。\nデッキから「炎王獣 バロン」以外の「炎王」と名のついたカード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"炎王獣 ヤクシャ",h:"えんおうじゅう　やくしゃ",e:"Fire King Avatar Yaksha",ra:"R",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:200,tx:"自分フィールド上に表側表示で存在する「炎王」と名のついたモンスターがカードの効果によって破壊された場合、このカードを手札から特殊召喚できる。\nまた、このカードが破壊され墓地へ送られた場合、自分の手札・フィールド上のカード１枚を選んで破壊できる。\n「炎王獣 ヤクシャ」のこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"炎王神獣 ガルドニクス",h:"えんおうしんじゅう　がるどにくす",e:"Fire King High Avatar Garunix",ra:"UR",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2700,de:1700,tx:"このカードがカードの効果によって破壊され墓地へ送られた場合、次のスタンバイフェイズ時にこのカードを墓地から特殊召喚する。\nこの効果で特殊召喚に成功した時、このカード以外のフィールド上のモンスターを全て破壊する。\nまた、このカードが戦闘によって破壊され墓地へ送られた時、デッキから「炎王神獣 ガルドニクス」以外の「炎王」と名のついたモンスター１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"炎獄魔人ヘル・バーナー",h:"えんごくまじんへる・ばーなー",e:"Infernal Incinerator",ra:"N",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2800,de:1800,tx:"このカードを除く自分の手札を全て墓地に捨て、さらに自分フィールド上の攻撃力２０００以上のモンスター１体を生け贄に捧げなければ通常召喚できない。\n相手フィールド上モンスター１体につきこのカードの攻撃力は２００ポイントアップする。\nこのカード以外の自分フィールド上のモンスター１体につきこのカードの攻撃力は５００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"炎斬機マグマ",h:"えんざんきまぐま",e:"Geomathmech Magma",ra:"SR",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"サイバース族",mo:["シンクロ","チューナー","効果"],ma:"",tp:"",at:2500,de:0,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが戦闘でモンスターを破壊した時、相手フィールドのカードを２枚まで対象として発動できる。\nそのカードを破壊する。\n②：このカードが戦闘または相手の効果で破壊された場合に発動できる。\nデッキから「斬機」魔法・罠カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エンジェル・トランペッター",h:"えんじぇる・とらんぺったー",e:"Angel Trumpeter",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"植物族",mo:["チューナー","通常"],ma:"",tp:"",at:1900,de:1600,tx:"天使の様な美しい花。\n絶えず侵入者を惑わす霧を生み出し、聖なる獣たちが住まう森の最深部へ立ち入ることを許さない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エンジェル・魔女",h:"えんじぇる・まじょ",e:"Fairywitch",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:800,de:1000,tx:"天使になる運命を背負っていたが、あこがれの魔女になった天使。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エンジェルO7",h:"えんじぇるおーせぶん",e:"Angel O7",ra:"R",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2500,de:1500,tx:"このカードの生け贄召喚に成功した時、このカードは以下の効果を得る。\n●このカードがフィールド上に表側表示で存在する限り、効果モンスターは効果を発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"天空聖者メルティウス",h:"えんじぇるせいんとめるてぃうす",e:"Meltiel, Sage of the Sky",ra:"UR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"①：このカードがモンスターゾーンに存在する限り、自分または相手がカウンター罠カードを発動する度に、自分は１０００LP回復し、フィールドに「天空の聖域」が存在する場合、さらに相手フィールドのカード１枚を選んで破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"天空賢者ミネルヴァ",h:"えんじぇるせーじみねるう゛ぁ",e:"Minerva, Scholar of the Sky",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1700,de:400,tx:"①：このカードがモンスターゾーンに存在する限り、自分または相手がカウンター罠カードを発動する度に、このカードの攻撃力は５００アップし、フィールドに「天空の聖域」が存在する場合、さらにその発動したカウンター罠カードとカード名が異なるカウンター罠カード１枚を自分の墓地から選んで手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"天空騎士パーシアス",h:"えんじぇるないとぱーしあす",e:"Airknight Parshath",ra:"UR",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1900,de:1400,tx:"①：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。\n②：このカードが相手に戦闘ダメージを与えた場合に発動する。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"天空聖騎士アークパーシアス",h:"えんじぇるぱらでぃんあーくぱーしあす",e:"Sacred Arch-Airknight Parshath",ra:"SR",t:"monster",a:"光",l:9,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2800,de:2300,tx:"①：このカードが手札・墓地に存在し、自分がカウンター罠カードを発動した場合、または自分がモンスターの効果・魔法・罠カードの発動を無効にした場合、自分の手札・フィールド・墓地からこのカード以外の天使族モンスター２体を除外して発動できる。\nこのカードを特殊召喚する。\n②：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。\n③：このカードが相手に戦闘ダメージを与えた時に発動できる。\nデッキから「パーシアス」カードまたはカウンター罠カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"天空勇士ネオパーシアス",h:"えんじぇるぶれいぶねおぱーしあす",e:"Neo-Parshath, the Sky Paladin",ra:"SR",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2300,de:2000,tx:"①：このカードは自分フィールドの「天空騎士パーシアス」１体をリリースして手札から特殊召喚できる。\n②：フィールドに「天空の聖域」が存在し、自分のLPが相手より多い場合、このカードの攻撃力・守備力はその差の数値分アップする。\n③：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。\n④：このカードが相手に戦闘ダメージを与えた場合に発動する。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エンシェント・エルフ",h:"えんしぇんと・えるふ",e:"Ancient Elf",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:1450,de:1200,tx:"何千年も生きているエルフ。\n精霊をあやつり攻撃をする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エンシェント・クリムゾン・エイプ",h:"えんしぇんと・くりむぞん・えいぷ",e:"Ancient Crimson Ape",ra:"R",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:2600,de:1800,tx:"自分フィールド上に存在するモンスターが破壊され墓地へ送られた時、自分は１０００ライフポイント回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エンシェント・ゴッド・フレムベル",h:"えんしぇんと・ごっど・ふれむべる",e:"Ancient Flamvell Deity",ra:"R",t:"monster",a:"炎",l:7,ps:"",pe:"",tr:"炎族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:200,tx:"炎属性チューナー＋チューナー以外の炎族モンスター１体以上\nこのカードがシンクロ召喚に成功した時、相手の手札の枚数分まで相手の墓地のカードを選択してゲームから除外する。\nこのカードの攻撃力は、この効果で除外したカードの数×２００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エンシェント・シャーク ハイパー・メガロドン",h:"えんしぇんと・しゃーく　はいぱー・めがろどん",e:"Hyper-Ancient Shark Megalodon",ra:"N",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:2900,de:1300,tx:"このカードが相手ライフに戦闘ダメージを与えた時、相手フィールド上のモンスター１体を選択して破壊できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"超古代恐獣",h:"えんしぇんと・だいの",e:"Super-Ancient Dinobeast",ra:"R",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:2700,de:1400,tx:"このカードは恐竜族モンスター１体をリリースして表側攻撃表示でアドバンス召喚できる。\n①：このカードがモンスターゾーンに存在し、自分の墓地から恐竜族モンスターが特殊召喚された時に発動できる。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エンシェント・ドラゴン",h:"えんしぇんと・どらごん",e:"Ancient Dragon",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1400,de:1300,tx:"①：このカードが直接攻撃で相手に戦闘ダメージを与えた時に発動できる。\nこのカードのレベルを１つ上げ、攻撃力を５００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エンシェント・フェアリー・ドラゴン",h:"えんしぇんと・ふぇありー・どらごん",e:"Ancient Fairy Dragon",ra:"UR",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2100,de:3000,tx:"チューナー＋チューナー以外のモンスター１体以上\n１ターンに１度、手札からレベル４以下のモンスター１体を特殊召喚できる。\nこの効果を発動するターン、自分はバトルフェイズを行えない。\nまた、１ターンに１度、自分のメインフェイズ時に発動できる。\nフィールド上のフィールド魔法カードを全て破壊し、自分は１０００ライフポイント回復する。\nその後、デッキからフィールド魔法カード１枚を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エンシェント・ホーリー・ワイバーン",h:"えんしぇんと・ほーりー・わいばーん",e:"Ancient Sacred Wyvern",ra:"UR",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"天使族",mo:["シンクロ","効果"],ma:"",tp:"",at:2100,de:2000,tx:"光属性チューナー＋チューナー以外のモンスター１体以上\n自分のライフポイントが相手より多い場合、このカードの攻撃力はその差の数値分アップする。\n自分のライフポイントが相手より少ない場合、このカードの攻撃力はその差の数値分ダウンする。\nまた、このカードが戦闘によって破壊され墓地へ送られた時、１０００ライフポイントを払う事でこのカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"炎星皇－チョウライオ",h:"えんせいおう－ちょうらいお",e:"Brotherhood of the Fire Fist - Lion Emperor",ra:"R",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"獣戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2200,de:200,tx:"炎属性レベル３モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除き、自分の墓地の炎属性モンスター１体を対象として発動できる。\nその炎属性モンスターを手札に加える。\nこの効果の発動後、ターン終了時まで自分は対象のモンスター及びその同名モンスターを召喚・特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"炎星侯－ホウシン",h:"えんせいこう－ほうしん",e:"Brotherhood of the Fire Fist - Horse Prince",ra:"SR",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"獣戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2200,de:2200,tx:"炎属性チューナー＋チューナー以外のモンスター１体以上\n①：このカードがS召喚に成功した時に発動できる。\nデッキから炎属性・レベル３モンスター１体を特殊召喚する。\n②：このカードがS召喚に成功したターン、自分はレベル５以上のモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"炎星師－チョウテン",h:"えんせいし－ちょうてん",e:"Brotherhood of the Fire Fist - Spirit",ra:"UR",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"獣戦士族",mo:["チューナー","効果"],ma:"",tp:"",at:500,de:200,tx:"このカード名の効果は１ターンに１度しか使用できない。\nこのカードをS素材とする場合、獣戦士族モンスターのS召喚にしか使用できない。\n①：このカードが召喚に成功した時、自分の墓地の守備力２００以下の炎属性・レベル３モンスター１体を対象として発動できる。\nその炎属性モンスターを守備表示で特殊召喚する。\nこの効果で特殊召喚に成功したターン、獣戦士族以外の自分のモンスターは攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エンゼル・イヤーズ",h:"えんぜる・いやーず",e:"Ocubeam",ra:"N",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:1550,de:1650,tx:"大きな耳と目で周囲を監視する、恐ろしい風貌の天使。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"EMアメンボート",h:"えんためいとあめんぼーと",e:"Performapal Skeeter Skimmer",ra:"R",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:500,de:1600,tx:"①：攻撃表示のこのカードが攻撃対象に選択された時に発動できる。\nこのカードを表側守備表示にし、その攻撃を無効にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"EMインコーラス",h:"えんためいといんこーらす",e:"Performapal Parrotrio",ra:"R",t:"monster",a:"風",l:3,ps:2,pe:"①：１ターンに１度、もう片方の自分のPゾーンに「EMインコーラス」以外の「EM」カード、「魔術師」カード、「オッドアイズ」カードの内、いずれかが存在する場合に発動できる。\nこのカードのPスケールはターン終了時まで７になる。",tr:"鳥獣族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:500,de:500,tx:"①：このカードが戦闘で破壊された時に発動できる。\nデッキからPモンスター以外の「EM」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"EMウィップ・バイパー",h:"えんためいとうぃっぷ・ばいぱー",e:"Performapal Whip Snake",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1700,de:900,tx:"①：１ターンに１度、フィールドの表側表示のモンスター１体を対象として発動できる。\nそのモンスターの攻撃力・守備力をターン終了時まで入れ替える。\nこの効果はお互いのメインフェイズにのみ発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"EMウィム・ウィッチ",h:"えんためいとうぃむ・うぃっち",e:"Performapal Whim Witch",ra:"N",t:"monster",a:"闇",l:3,ps:4,pe:"「EMウィム・ウィッチ」のP効果は１ターンに１度しか使用できない。\n①：エクストラデッキから特殊召喚されたモンスターが相手フィールドにのみ存在する場合に発動できる。\nPゾーンのこのカードを特殊召喚する。",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:800,de:800,tx:"①：Pモンスターをアドバンス召喚する場合、このカードは２体分のリリースにできる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"EMエクストラ・シューター",h:"えんためいとえくすとら・しゅーたー",e:"Performapal Extra Slinger",ra:"N",t:"monster",a:"闇",l:3,ps:6,pe:"「EMエクストラ・シューター」のP効果は１ターンに１度しか使用できず、この効果を発動するターン、自分はP召喚できない。\n①：自分メインフェイズに発動できる。\n自分のエクストラデッキの表側表示のPモンスターの数×３００ダメージを相手に与える。",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:800,de:1100,tx:"①：１ターンに１度、自分のエクストラデッキからモンスター１体を除外し、自分または相手のPゾーンのカード１枚を対象として発動できる。\nそのカードを破壊し、相手に３００ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"EMオールカバー・ヒッポ",h:"えんためいとおーるかばー・ひっぽ",e:"Performapal Flip Hippo",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"①：このカードが召喚に成功した時に発動できる。\n手札から「EM」モンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターの効果はターン終了時まで無効化される。\n②：１ターンに１度、自分メインフェイズに発動できる。\n自分フィールドのモンスターを全て守備表示にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"EMオッドアイズ・ミノタウロス",h:"えんためいとおっどあいず・みのたうろす",e:"Performapal Odd-Eyes Minitaurus",ra:"N",t:"monster",a:"闇",l:4,ps:6,pe:"①：自分の「EM」モンスターまたは「オッドアイズ」モンスターが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ相手に戦闘ダメージを与える。",tr:"獣戦士族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1200,de:1600,tx:"①：自分のPモンスターが相手モンスターに攻撃するダメージ計算時に発動できる。\nその相手モンスターの攻撃力はそのダメージ計算時のみ、自分フィールドの「EM」カード及び「オッドアイズ」カードの数×１００ダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"EMオッドアイズ・ユニコーン",h:"えんためいとおっどあいず・ゆにこーん",e:"Performapal Odd-Eyes Unicorn",ra:"SR",t:"monster",a:"光",l:1,ps:8,pe:"①：このカードがPゾーンに存在する限り１度だけ、自分の「オッドアイズ」モンスターの攻撃宣言時、そのモンスター以外の自分フィールドの「EM」モンスター１体を対象として発動できる。\nその攻撃モンスターの攻撃力はバトルフェイズ終了時まで、対象のモンスターの元々の攻撃力分アップする。",tr:"獣族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:100,de:600,tx:"①：このカードが召喚・特殊召喚に成功した時、自分の墓地の「EM」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力分だけ自分はLPを回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"EMオッドアイズ・ライトフェニックス",h:"えんためいとおっどあいず・らいとふぇにっくす",e:"Performapal Odd-Eyes Light Phoenix",ra:"SR",t:"monster",a:"光",l:5,ps:3,pe:"①：もう片方の自分のPゾーンにカードが存在する場合、相手モンスターの直接攻撃宣言時に発動できる。\nもう片方の自分のPゾーンのカードを破壊し、このカードを特殊召喚する。",tr:"鳥獣族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2000,de:1000,tx:"①：このカードをリリースし、自分フィールドの「EM」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで１０００アップする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"EMガトリングール",h:"えんためいとがとりんぐーる",e:"Performapal Gatlinghoul",ra:"R",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"悪魔族",mo:["融合","効果"],ma:"",tp:"",at:2900,de:900,tx:"「EM」モンスター＋レベル５以上の闇属性モンスター\n「EMガトリングール」の効果は１ターンに１度しか使用できない。\n①：このカードが融合召喚に成功した場合に発動できる。\nフィールドのカードの数×２００ダメージを相手に与える。\nこのカードがPモンスターを素材として融合召喚していた場合、さらに相手フィールドのモンスター１体を選んで破壊し、そのモンスターの元々の攻撃力分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"EMカレイドスコーピオン",h:"えんためいとかれいどすこーぴおん",e:"Performapal Kaleidoscorp",ra:"R",t:"monster",a:"光",l:6,ps:4,pe:"①：自分フィールドの光属性モンスターの攻撃力は３００アップする。",tr:"昆虫族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:100,de:2300,tx:"①：１ターンに１度、自分フィールドの表側表示モンスター１体を対象として発動できる。\nこのターン、そのモンスターは相手フィールドの特殊召喚されたモンスター全てに１回ずつ攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"EMガンバッター",h:"えんためいとがんばったー",e:"Performapal Bowhopper",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"「EMガンバッター」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの「EM」モンスター１体をリリースして発動できる。\nリリースしたモンスターのレベル×１００ダメージを相手に与える。\n②：自分フィールドの「EM」モンスター１体をリリースし、リリースしたモンスター以外の自分の墓地の「EM」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"EMギッタンバッタ",h:"えんためいとぎったんばった",e:"Performapal Teeter Totter Hopper",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:100,de:1200,tx:"「EMギッタンバッタ」の③の効果は１ターンに１度しか使用できない。\n①：特殊召喚されたこのカードは１ターンに１度だけ戦闘では破壊されない。\n②：相手エンドフェイズに自分の墓地のレベル３以下の「EM」モンスター１体を対象として発動できる。\nこのカードを墓地へ送り、そのモンスターを手札に加える。\n③：このカードが墓地に存在する状態で、「EM」モンスターが手札から自分の墓地へ送られた場合に発動できる。\nこのカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"EMキングベアー",h:"えんためいときんぐべあー",e:"Performapal King Bear",ra:"R",t:"monster",a:"地",l:6,ps:7,pe:"①：このカードを発動したターンのエンドフェイズに発動できる。\nこのカードを破壊し、自分のエクストラデッキの表側表示のPモンスター及び自分の墓地のモンスターの中からレベル７以上のモンスター１体を選んで手札に加える。",tr:"獣戦士族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2200,de:1000,tx:"①：攻撃表示のこのカードは魔法・罠カードの効果では破壊されない。\n②：このカードの攻撃力は自分バトルフェイズの間、自分フィールドの「EM」カードの数×１００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"EMクレイブレイカー",h:"えんためいとくれいぶれいかー",e:"Performapal Clay Breaker",ra:"N",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2000,de:1000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：アドバンス召喚したこのカードが相手モンスターと戦闘を行うダメージ計算時に発動できる。\nその相手モンスターの攻撃力はターン終了時まで、自分のEXデッキの表側表示のPモンスターの数×５００ダウンする。\n②：このカードが墓地に存在し、自分が２体以上のモンスターを同時にP召喚した時に発動できる。\n墓地のこのカードを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"EMゴールド・ファング",h:"えんためいとごーるど・ふぁんぐ",e:"Performapal Gold Fang",ra:"R",t:"monster",a:"光",l:4,ps:3,pe:"①：１ターンに１度、自分の「EM」モンスターが戦闘で相手モンスターを破壊した場合に発動する。\n相手に１０００ダメージを与える。",tr:"獣族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1800,de:700,tx:"①：このカードが召喚・特殊召喚に成功した場合に発動する。\n自分フィールドの「EM」モンスターの攻撃力はターン終了時まで２００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"EMシルバー・クロウ",h:"えんためいとしるばー・くろう",e:"Performapal Silver Claw",ra:"SR",t:"monster",a:"闇",l:4,ps:5,pe:"①：自分フィールドの「EM」モンスターの攻撃力は３００アップする。",tr:"獣族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1800,de:700,tx:"①：このカードの攻撃宣言時に発動する。\n自分フィールドの「EM」モンスターの攻撃力はバトルフェイズ終了時まで３００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"EMジンライノ",h:"えんためいとじんらいの",e:"Performapal Thunderhino",ra:"R",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:200,de:1800,tx:"①：このカードがモンスターゾーンに存在する限り、相手は他の「EM」モンスターを攻撃対象に選択できない。\n②：このカードが墓地に存在し、「EMジンライノ」以外の自分フィールドの「EM」カードが戦闘・効果で破壊される場合、代わりに墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"EMスカイ・ピューピル",h:"えんためいとすかい・ぴゅーぴる",e:"Performapal Sky Pupil",ra:"N",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"「EMスカイ・ピューピル」の①の効果は１ターンに１度しか使用できない。\n①：自分フィールドのレベル５以上の「EM」モンスター１体を持ち主の手札に戻して発動できる。\nこのカードを手札から特殊召喚する。\nこの効果は相手ターンでも発動できる。\n②：このカードが相手モンスターと戦闘を行う場合、ダメージステップ終了時までそのモンスターの効果は無効化される。\n③：自分フィールドに他の「EM」モンスターが存在する場合、このカードが相手モンスターに攻撃するダメージ計算前に発動できる。\nその相手モンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"EMスパイク・イーグル",h:"えんためいとすぱいく・いーぐる",e:"Performapal Spikeagle",ra:"N",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:900,de:900,tx:"①：１ターンに１度、自分フィールドの表側表示モンスター１体を対象として発動できる。\nこのターン、そのモンスターが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ相手に戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"EMスプリングース",h:"えんためいとすぷりんぐーす",e:"Performapal Springoose",ra:"N",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1100,de:2400,tx:"「EMスプリングース」の効果は１ターンに１度しか使用できない。\n①：自分メインフェイズに墓地のこのカードを除外し、自分のPゾーンの「魔術師」カード、「EM」カード及び自分フィールドのPモンスターの中から２枚を対象として発動できる。\nそのカード２枚を持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"EMスライハンド・マジシャン",h:"えんためいとすらいはんど・まじしゃん",e:"Performapal Sleight Hand Magician",ra:"SR",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2500,de:2000,tx:"①：このカードはPモンスター以外の自分フィールドの「EM」モンスター１体をリリースして手札から特殊召喚できる。\n②：１ターンに１度、手札を１枚捨て、フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"EMセカンドンキー",h:"えんためいとせかんどんきー",e:"Performapal Secondonkey",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1000,de:2000,tx:"①：このカードが召喚・特殊召喚に成功した時に発動できる。\nデッキから「EMセカンドンキー」以外の「EM」モンスター１体を墓地へ送る。\n自分のPゾーンにカードが２枚存在する場合、墓地へ送らず手札に加える事もできる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"EMソード・フィッシュ",h:"えんためいとそーど・ふぃっしゅ",e:"Performapal Sword Fish",ra:"R",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:600,de:600,tx:"①：このカードが召喚・特殊召喚に成功した場合に発動する。\n相手フィールドの全てのモンスターの攻撃力・守備力は６００ダウンする。\n②：このカードがモンスターゾーンに存在し、自分がモンスターの特殊召喚に成功した場合に発動する。\n相手フィールドの全てのモンスターの攻撃力・守備力は６００ダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"EMターントルーパー",h:"えんためいとたーんとるーぱー",e:"Performapal Turn Trooper",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:1200,tx:"①：自分バトルフェイズ開始時に発動できる。\nこのカードにカウンターを１つ置く（最大２つまで）。\n②：このカードは、このカードのカウンターの数によって以下の効果を得る。\n●１つ：１ターンに１度、相手モンスターの攻撃宣言時に発動できる。\nその攻撃を無効にする。\n●２つ：このカードをリリースして発動できる。\n発動後２回目の相手エンドフェイズまで、フィールドのモンスターを全て除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"EMチアモール",h:"えんためいとちあもーる",e:"Performapal Cheermole",ra:"SR",t:"monster",a:"地",l:2,ps:5,pe:"①：自分フィールドのPモンスターの攻撃力は３００アップする。",tr:"獣族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:600,de:1000,tx:"「EMチアモール」のモンスター効果は１ターンに１度しか使用できない。\n①：自分メインフェイズに元々の攻撃力と異なる攻撃力を持つモンスター１体を対象として発動できる。\nそのモンスターの攻撃力の数値によって以下の効果を適用する。\n●そのモンスターの攻撃力が元々の攻撃力より高い場合、そのモンスターの攻撃力は１０００アップする。\n●そのモンスターの攻撃力が元々の攻撃力より低い場合、そのモンスターの攻撃力は１０００ダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"EMチェーンジラフ",h:"えんためいとちぇーんじらふ",e:"Performapal Changeraffe",ra:"UR",t:"monster",a:"地",l:1,ps:5,pe:"①：自分のモンスター１体が戦闘で破壊された時に発動できる。\nこのカードを破壊し、その戦闘で破壊されたモンスターを攻撃表示で特殊召喚する。\nこの効果で特殊召喚したモンスターは、このターン戦闘では破壊されない。",tr:"獣族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:100,de:500,tx:"①：このカードが召喚・特殊召喚に成功した時、相手フィールドの表側表示モンスター１体を対象として発動できる。\nこのモンスターが表側表示で存在する間、対象の表側表示モンスターは攻撃できず、効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"EMディスカバー・ヒッポ",h:"えんためいとでぃすかばー・ひっぽ",e:"Performapal Hip Hippo",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"①：このカードが召喚に成功したターン、自分は通常召喚に加えて１度だけ、自分メインフェイズに、レベル７以上のモンスター１体を表側攻撃表示でアドバンス召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"EM小判竜",h:"えんためいとどらご・りもーら",e:"Performapal Coin Dragon",ra:"N",t:"monster",a:"水",l:4,ps:5,pe:"①：１ターンに１度、エクストラデッキから特殊召喚された自分フィールドのドラゴン族モンスター１体を対象として発動できる。\nこのターン、その自分のモンスターが相手モンスターと戦闘を行った場合、その相手モンスターはダメージ計算後に除外される。",tr:"ドラゴン族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1700,de:1000,tx:"①：このカードがモンスターゾーンに存在する限り、このカード以外の自分フィールドのドラゴン族モンスターは攻撃力が５００アップし、効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"EMドラネコ",h:"えんためいとどらねこ",e:"Performapal Gongato",ra:"UR",t:"monster",a:"地",l:1,ps:2,pe:"①：１ターンに１度、相手モンスターの直接攻撃宣言時に発動できる。\nその戦闘で発生する自分への戦闘ダメージは０になる。",tr:"獣族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:100,de:100,tx:"①：１ターンに１度、自分のモンスターが相手モンスターと戦闘を行う攻撃宣言時に発動できる。\nその戦闘で発生する自分への戦闘ダメージは０になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"EMトランプ・ウィッチ",h:"えんためいととらんぷ・うぃっち",e:"Performapal Trump Witch",ra:"SR",t:"monster",a:"闇",l:1,ps:4,pe:"①：１ターンに１度、自分メインフェイズに発動できる。\n自分フィールドから、融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をエクストラデッキから融合召喚する。",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:100,de:100,tx:"①：このカードをリリースして発動できる。\n自分のデッキ・墓地から「融合」１枚を選んで手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"EMトランプ・ガール",h:"えんためいととらんぷ・がーる",e:"Performapal Trump Girl",ra:"R",t:"monster",a:"闇",l:2,ps:4,pe:"",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:200,de:200,tx:"①：１ターンに１度、自分メインフェイズに発動できる。\n融合モンスターカードによって決められた、このカードを含む融合素材モンスターを自分フィールドから墓地へ送り、その融合モンスター１体をエクストラデッキから融合召喚する。\n②：このカードがPゾーンで破壊された場合、自分の墓地のドラゴン族の融合モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターはエンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"EMバラード",h:"えんためいとばらーど",e:"Performapal Ballad",ra:"R",t:"monster",a:"地",l:3,ps:2,pe:"①：１ターンに１度、自分の「EM」モンスターが相手の表側表示モンスターと戦闘を行うダメージステップ開始時に発動できる。\nその相手モンスターの攻撃力は６００ダウンする。",tr:"植物族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:500,de:1100,tx:"①：自分の「EM」モンスターが攻撃したダメージ計算後に、相手フィールドの表側表示モンスター１体を対象として発動できる。\nその相手モンスターの攻撃力は、その「EM」モンスターの攻撃力分ダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"EMバラクーダ",h:"えんためいとばらくーだ",e:"Performapal Barracuda",ra:"R",t:"monster",a:"地",l:3,ps:5,pe:"①：１ターンに１度、自分の「EM」モンスターが相手モンスターと戦闘を行うダメージ計算前に発動できる。\nその相手モンスターの攻撃力は、その元々の攻撃力との差分だけダウンする。",tr:"植物族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:500,de:1100,tx:"「EMバラクーダ」のモンスター効果は１ターンに１度しか使用できない。\n①：元々の攻撃力と異なる攻撃力を持つ「EM」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで、その元々の攻撃力との差分だけアップする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"EMハンサムライガー",h:"えんためいとはんさむらいがー",e:"Performapal Handsamuraiger",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:400,tx:"「EMハンサムライガー」の効果は１ターンに１度しか使用できない。\n①：このカードが戦闘で相手モンスターを破壊し墓地へ送った時に発動できる。\nデッキからレベル５以上のPモンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"EMハンマーマンモ",h:"えんためいとはんまーまんも",e:"Performapal Elephammer",ra:"N",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:2600,de:1800,tx:"①：自分フィールドに「EM」カードが２枚以上存在する場合、このカードはリリースなしで召喚できる。\n②：自分フィールドに他の「EM」カードが存在しない場合、このカードは攻撃できない。\n③：このカードの攻撃宣言時に発動できる。\n相手フィールドの魔法・罠カードを全て持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"EMヒックリカエル",h:"えんためいとひっくりかえる",e:"Performapal Turn Toad",ra:"SR",t:"monster",a:"水",l:2,ps:3,pe:"①：１ターンに１度、フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力・守備力をターン終了時まで入れ替える。",tr:"水族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:0,de:800,tx:"①：自分バトルフェイズに１度、自分フィールドのモンスター１体を対象として発動できる。\nそのモンスターの表示形式を変更し、その攻撃力・守備力をターン終了時まで入れ替える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"EMファイア・マフライオ",h:"えんためいとふぁいあ・まふらいお",e:"Performapal Fire Mufflerlion",ra:"R",t:"monster",a:"炎",l:3,ps:5,pe:"①：自分フィールドのPモンスターが戦闘で破壊された時に発動できる。\nPゾーンのこのカードを特殊召喚する。",tr:"獣族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:800,de:800,tx:"①：１ターンに１度、自分のPモンスターが相手モンスターを戦闘で破壊したダメージ計算後に発動できる。\nその自分のモンスターは、バトルフェイズ終了時まで攻撃力が２００アップし、もう１度だけ続けて攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"EMプラスタートル",h:"えんためいとぷらすたーとる",e:"Performapal Stamp Turtle",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:100,de:1800,tx:"①：１ターンに１度、フィールドの表側表示モンスターを２体まで対象として発動できる。\nそのモンスターのレベルを１つ上げる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"EMブランコブラ",h:"えんためいとぶらんこぶら",e:"Performapal Swincobra",ra:"N",t:"monster",a:"地",l:4,ps:2,pe:"①：１ターンに１度、自分のモンスターが相手に戦闘ダメージを与えた時に発動できる。\n相手のデッキの一番上のカードを墓地へ送る。",tr:"爬虫類族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:300,de:1800,tx:"①：このカードは直接攻撃できる。\n②：このカードは攻撃した場合、バトルフェイズ終了時に守備表示になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"EMフレンドンキー",h:"えんためいとふれんどんきー",e:"Performapal Friendonkey",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1600,de:600,tx:"①：このカードが召喚に成功した時に発動できる。\n自分の手札・墓地からレベル４以下の「EM」モンスター１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"EMヘイタイガー",h:"えんためいとへいたいがー",e:"Performapal Salutiger",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:500,tx:"「EMヘイタイガー」の効果は１ターンに１度しか使用できない。\n①：このカードが戦闘で相手モンスターを破壊し墓地へ送った時に発動できる。\nデッキから「EM」Pモンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"EMボットアイズ・リザード",h:"えんためいとぼっとあいず・りざーど",e:"Performapal Bot-Eyes Lizard",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"①：このカードが召喚・特殊召喚に成功したターンの自分メインフェイズに１度だけ、デッキから「オッドアイズ」モンスター１体を墓地へ送って発動できる。\nエンドフェイズまで、このカードは墓地へ送ったモンスターと同名カードとして扱う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"EMマンモスプラッシュ",h:"えんためいとまんもすぷらっしゅ",e:"Performapal Splashmammoth",ra:"R",t:"monster",a:"水",l:6,ps:4,pe:"①：１ターンに１度、自分フィールドに融合モンスターが特殊召喚された時に発動できる。\n自分のエクストラデッキから表側表示の「オッドアイズ」Pモンスター１体を特殊召喚する。",tr:"獣族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1900,de:2300,tx:"「EMマンモスプラッシュ」のモンスター効果はデュエル中に１度しか使用できない。\n①：自分メインフェイズに発動できる。\n自分フィールドから、ドラゴン族の融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をエクストラデッキから融合召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"EMラ・パンダ",h:"えんためいとら・ぱんだ",e:"Performapal Trumpanda",ra:"N",t:"monster",a:"地",l:3,ps:3,pe:"①：１ターンに１度、自分メインフェイズに発動できる。\nこのカードのPスケールを１つ上げる（最大１２まで）。",tr:"獣族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:800,de:800,tx:"「EMラ・パンダ」のモンスター効果は１ターンに１度しか使用できない。\n①：自分のPモンスターが攻撃対象に選択された時に発動できる。\nその攻撃を無効にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"EMライフ・ソードマン",h:"えんためいとらいふ・そーどまん",e:"Performapal Life Swordsman",ra:"N",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"①：相手のLPが回復した時に発動できる。\nこのカードを手札から特殊召喚する。\nこの効果で特殊召喚したこのカードの攻撃力は、この時相手が回復した数値と同じになる。\n②：このカードをリリースし、自分フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで１０００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"EMラクダウン",h:"えんためいとらくだうん",e:"Performapal Camelump",ra:"R",t:"monster",a:"地",l:4,ps:2,pe:"①：１ターンに１度、自分フィールドの表側表示モンスター１体を対象として発動できる。\n相手フィールドの全てのモンスターの守備力はターン終了時まで８００ダウンし、このターン対象のモンスターが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ相手に戦闘ダメージを与える。",tr:"獣族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:800,de:1800,tx:"①：このカードが戦闘で破壊された場合に発動できる。\nこのカードを破壊したモンスターの攻撃力は８００ダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"EMラディッシュ・ホース",h:"えんためいとらでぃっしゅ・ほーす",e:"Performapal Radish Horse",ra:"R",t:"monster",a:"地",l:4,ps:3,pe:"①：１ターンに１度、相手フィールドの表側表示モンスター１体と、自分フィールドの「EM」モンスター１体を対象として発動できる。\nその相手モンスターの攻撃力は、その自分の「EM」モンスターの攻撃力分ダウンする。",tr:"植物族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:500,de:2000,tx:"①：特殊召喚されたモンスターが相手フィールドに存在し、相手フィールドのモンスターの数が自分フィールドのモンスターの数以上の場合、このカードは手札から特殊召喚できる。\n②：１ターンに１度、相手フィールドの表側表示モンスター１体と自分フィールドの表側表示モンスター１体を対象として発動できる。\nターン終了時まで、その相手モンスターの攻撃力はこのカードの攻撃力分ダウンし、その自分のモンスターの攻撃力はこのカードの攻撃力分アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"EMレビュー・ダンサー",h:"えんためいとれびゅー・だんさー",e:"Performapal Revue Dancer",ra:"N",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:800,de:1000,tx:"①：相手フィールドにモンスターが存在し、自分フィールドにモンスターが存在しない場合、このカードは手札から特殊召喚できる。\n②：「EM」モンスターをアドバンス召喚する場合、このカードは２体分のリリースにできる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"EMロングフォーン・ブル",h:"えんためいとろんぐふぉーん・ぶる",e:"Performapal Longphone Bull",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"「EMロングフォーン・ブル」の効果は１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合に発動できる。\nデッキからPモンスター以外の「EM」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"炎帝家臣ベルリネス",h:"えんていかしんべるりねす",e:"Berlineth the Firestorm Vassal",ra:"SR",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:800,de:1000,tx:"「炎帝家臣ベルリネス」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札を１枚捨てて発動できる。\nこのカードを手札から特殊召喚する。\nこのターン、自分はエクストラデッキからモンスターを特殊召喚できない。\n②：このカードがアドバンス召喚のためにリリースされた場合に発動できる。\n相手の手札を確認し、その中からカード１枚を選んでエンドフェイズまで除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"炎帝近衛兵",h:"えんていこのえへい",e:"Royal Firestorm Guards",ra:"SR",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:1700,de:1200,tx:"このカードが召喚に成功した時、自分の墓地に存在する炎族モンスター４体を選択して発動する。\n選択したモンスターをデッキに戻し、自分のデッキからカードを２枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"炎帝テスタロス",h:"えんていてすたろす",e:"Thestalos the Firestorm Monarch",ra:"UR",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:2400,de:1000,tx:"①：このカードがアドバンス召喚に成功した場合に発動する。\n相手の手札をランダムに１枚選んで捨てる。\n捨てたカードがモンスターカードだった場合、そのモンスターのレベル×１００ダメージを相手に与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エンド・オブ・アヌビス",h:"えんど・おぶ・あぬびす",e:"The End of Anubis",ra:"UR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2500,de:0,tx:"このカードがフィールド上に表側表示で存在する限り、墓地のカードを対象にする、または墓地で効果が発動する魔法・罠・モンスターの効果は全て無効になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"炎熱刀プロミネンス",h:"えんねつとうぷろみねんす",e:"Prominence, Molten Swordsman",ra:"N",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:600,tx:"１ターンに１度、自分の墓地の「ラヴァル」と名のついたモンスター１体をゲームから除外して発動できる。\nこのカードの攻撃力はエンドフェイズ時まで３００ポイントアップする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"円盤ムスキー",h:"えんばんむすきー",e:"Flying Saucer Muusik'i",ra:"N",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:2000,tx:"このカードがフィールド上に表側表示で存在する限り、自分のドローフェイズに通常のドローを行う代わりに、デッキから「エーリアン」と名のついたカード１枚を選択して手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エンペラー・ストゥム",h:"えんぺらー・すとぅむ",e:"Emperor Sem",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1300,de:2200,tx:"このカードが自分フィールド上に表側表示で存在する限り、自分がモンスターのアドバンス召喚に成功した時、お互いのプレイヤーはそれぞれの墓地に存在するカード１枚を選択し、デッキの一番上に戻す事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"猿魔王ゼーマン",h:"えんまおうぜーまん",e:"Zeman the Ape King",ra:"N",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"獣族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:1800,tx:"闇属性チューナー＋チューナー以外の獣族モンスター１体\nこのカードが攻撃する場合、相手はダメージステップ終了時まで魔法・罠カードを発動する事ができない。\n相手モンスターの攻撃宣言時、自分の手札またはフィールド上のモンスター１体を墓地へ送る事で、相手モンスター１体の攻撃を無効にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"琰魔竜 レッド・デーモン",h:"えんまりゅう　れっど・でーもん",e:"Hot Red Dragon Archfiend",ra:"UR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:3000,de:2000,tx:"チューナー＋チューナー以外のモンスター１体以上\n１ターンに１度、自分のメインフェイズ１でのみ発動できる。\nこのカード以外のフィールド上に表側攻撃表示で存在するモンスターを全て破壊する。\nこの効果を発動するターン、このカード以外のモンスターは攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"琰魔竜 レッド・デーモン・ベリアル",h:"えんまりゅう　れっど・でーもん・べりある",e:"Hot Red Dragon Archfiend Bane",ra:"SR",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:3500,de:3000,tx:"チューナー＋チューナー以外のドラゴン族・闇属性Sモンスター１体\n「琰魔竜 レッド・デーモン・ベリアル」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドのモンスター１体をリリースし、自分の墓地の「レッド・デーモン」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n②：このカードが相手に戦闘ダメージを与えた時に発動できる。\n自分のデッキ及び墓地からレベルが同じチューナーをそれぞれ１体ずつ選んで守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"炎妖蝶ウィルプス",h:"えんようちょううぃるぷす",e:"Blazewing Butterfly",ra:"SR",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"昆虫族",mo:["デュアル","効果"],ma:"",tp:"",at:1500,de:1500,tx:"①：このカードはフィールド・墓地に存在する限り、通常モンスターとして扱う。\n②：フィールドの通常モンスター扱いのこのカードを通常召喚としてもう１度召喚できる。\nその場合このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●このカードをリリースし、「炎妖蝶ウィルプス」以外の自分の墓地のデュアルモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターはもう１度召喚された状態として扱う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"炎霊神パイロレクス",h:"えんれいしんぱいろれくす",e:"Pyrorex the Elemental Lord",ra:"UR",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"恐竜族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2800,de:2200,tx:"このカードは通常召喚できない。\n自分の墓地の炎属性モンスターが５体の場合のみ特殊召喚できる。\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した時、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊し、お互いのプレイヤーはそのモンスターの元々の攻撃力の半分のダメージを受ける。\n②：表側表示のこのカードがフィールドから離れた場合、次の自分ターンのバトルフェイズをスキップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"オアシスの使者",h:"おあしすのししゃ",e:"Emissary of the Oasis",ra:"N",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:600,de:400,tx:"自分フィールド上にレベル３以下の通常モンスターが表側表示で存在する限り、相手はこのカードを攻撃対象に選択できない。\nこのカードが自分フィールド上に存在する限り、レベル３以下の通常モンスターの戦闘によって発生するこのカードのコントローラーへの戦闘ダメージは０になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"オイスターマイスター",h:"おいすたーまいすたー",e:"Oyster Meister",ra:"R",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1600,de:200,tx:"このカードが戦闘で破壊される以外の方法でフィールド上から墓地へ送られた時、自分フィールド上に「オイスタートークン」（魚族・水・星１・攻／守０）１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"オイルメン",h:"おいるめん",e:"Oilman",ra:"R",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"機械族",mo:["ユニオン","効果"],ma:"",tp:"",at:400,de:400,tx:"１ターンに１度、自分のメインフェイズ時に装備カード扱いとして自分フィールド上の機械族モンスターに装備、または装備を解除して表側攻撃表示で特殊召喚できる。\nこの効果で装備カード扱いになっている場合のみ、装備モンスターが戦闘によって相手モンスターを破壊した場合、デッキからカードを１枚ドローする。\n（１体のモンスターが装備できるユニオンは１枚まで。\n装備モンスターが破壊される場合、代わりにこのカードを破壊する。\n）",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"黄華の機界騎士",h:"おうかのじゃっくないつ",e:"Mekk-Knight Yellow Star",ra:"UR",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:2200,de:2800,tx:"このカード名の①の方法による特殊召喚は１ターンに１度しかできない。\n①：同じ縦列にカードが２枚以上存在する場合、このカードはその縦列の自分フィールドに特殊召喚できる。\n②：自分の墓地から「ジャックナイツ」モンスター１体を除外し、このカードと同じ縦列の魔法・罠カード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"黄血鬼",h:"おうけつき",e:"Yellow-Bellied Oni",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"自分がエクシーズ召喚に成功した時、このカードを手札から特殊召喚できる。\nまた、１ターンに１度、自分フィールド上のエクシーズ素材を１つ取り除き、フィールド上のエクシーズモンスター１体を選択して発動できる。\n選択したモンスターのランクを１つ下げ、攻撃力は３００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"王家の守護者",h:"おうけのしゅごしゃ",e:"Pharaonic Protector",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"アンデット族",mo:["通常"],ma:"",tp:"",at:900,de:0,tx:"何千年もの間王家を守り続けている兵士のミイラ。\nその魂は今も侵入者を許す事はない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"黄金のホムンクルス",h:"おうごんのほむんくるす",e:"Golden Homunculus",ra:"SR",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:1500,tx:"このカードの攻撃力・守備力は、ゲームから除外されている自分のカードの数×３００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"王座の守護者",h:"おうざのしゅごしゃ",e:"Protector of the Throne",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:800,de:1500,tx:"王が留守の間、王座を外敵から守る王妃。\n守備は高い。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"王座の侵略者",h:"おうざのしんりゃくしゃ",e:"Invader of the Throne",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["リバース","効果"],ma:"",tp:"",at:1350,de:1700,tx:"リバース：相手フィールド上に存在するモンスター１体を選択し、そのモンスターとこのカードのコントロールを入れ替える。\nこの効果はバトルフェイズに発動する事はできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"王室前のガーディアン",h:"おうしつまえのがーでぃあん",e:"Guardian of the Throne Room",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1650,de:1600,tx:"王室をガードする衛兵ロボ。\n当たるまで追い続けるミサイルを撃つ。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"応戦するG",h:"おうせんするじー",e:"Retaliating \"C\"",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1400,de:1400,tx:"①：相手がモンスターを特殊召喚する効果を含む魔法カードを発動した時に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードの①の効果で特殊召喚されたこのカードがモンスターゾーンに存在する限り、墓地へ送られるカードは墓地へは行かず除外される。\n③：このカードがフィールドから墓地へ送られた場合に発動できる。\nデッキから「応戦するG」以外の攻撃力１５００以下の昆虫族・地属性モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"王族親衛隊",h:"おうぞくしんえいたい",e:"Royal Keeper",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1600,de:1700,tx:"このカードは１ターンに１度だけ裏側守備表示にする事ができる。\nこのカードがリバースした時、このカードの攻撃力・守備力はエンドフェイズ時まで３００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"桜姫タレイア",h:"おうひたれいあ",e:"Talaya, Princess of Cherry Blossoms",ra:"SR",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:2800,de:1200,tx:"①：このカードの攻撃力は、自分フィールドの植物族モンスターの数×１００アップする。\n②：このカードがモンスターゾーンに存在する限り、このカード以外のフィールドの植物族モンスターは効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"逢魔ノ妖刀－不知火",h:"おうまのようとう－しらぬい",e:"Shiranui Spectralsword Shade",ra:"R",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"アンデット族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:0,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードをリリースし、「逢魔ノ妖刀－不知火」以外の除外されている自分のモンスターの中から「不知火」モンスターを含むアンデット族モンスター２体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。\nこの効果の発動後、ターン終了時まで自分はアンデット族モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"オオアリクイクイアリ",h:"おおありくいくいあり",e:"Anteatereatingant",ra:"SR",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"昆虫族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2000,de:500,tx:"このカードは通常召喚できない。\n自分フィールド上の魔法・罠カード２枚を墓地へ送った場合のみ特殊召喚する事ができる。\nこのカードは攻撃をするかわりに相手フィールド上の魔法・罠カード１枚を破壊する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"オオカミ",h:"おおかみ",e:"Wolf",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:1200,de:800,tx:"今ではあまり見かけないオオカミ。\nよくきく鼻で獲物をさがす。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"大食いグール",h:"おおぐいぐーる",e:"Ghoul with an Appetite",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"アンデット族",mo:["通常"],ma:"",tp:"",at:1600,de:1200,tx:"どんなに食べても、おなかがいっぱいになることは無いというモンスター。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"大くしゃみのカバザウルス",h:"おおくしゃみのかばざうるす",e:"Kabazauls",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"恐竜族",mo:["通常"],ma:"",tp:"",at:1700,de:1500,tx:"巨大な体を持つカバの化け物。\nその巨体からくり出されるクシャミは、ハリケーンに匹敵する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"オーシャンズ・オーパー",h:"おーしゃんず・おーぱー",e:"Ocean's Keeper",ra:"N",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1500,de:1200,tx:"このカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。\nこのカードが戦闘によって破壊された場合、自分のデッキから「サウザンド・アイズ・フィッシュ」または「マザー・ブレイン」１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"先史遺産アステカ・マスク・ゴーレム",h:"おーぱーつあすてか・ますく・ごーれむ",e:"Chronomaly Aztec Mask Golem",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"自分のターンに自分が「オーパーツ」と名のついた魔法カードを発動している場合、このカードは手札から特殊召喚できる。\n「先史遺産アステカ・マスク・ゴーレム」は自分フィールド上に１体しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"先史遺産ウィングス・スフィンクス",h:"おーぱーつうぃんぐす・すふぃんくす",e:"Chronomaly Winged Sphinx",ra:"R",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1600,de:1900,tx:"このカードが召喚に成功した時、自分の墓地から「先史遺産」と名のついたレベル５モンスター１体を選択して特殊召喚できる。\nこの効果を発動するターン、自分は「先史遺産」と名のついたモンスター以外のモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"先史遺産カブレラの投石機",h:"おーぱーつかぶれらのとうせきき",e:"Chronomaly Cabrera Trebuchet",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:0,de:1800,tx:"１ターンに１度、このカード以外の自分フィールド上の「先史遺産」と名のついたモンスター１体をリリースし、相手フィールド上に表側表示で存在するモンスター１体を選択して発動できる。\n選択したモンスターの攻撃力をエンドフェイズ時まで０にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"先史遺産クリスタル・スカル",h:"おーぱーつくりすたる・すかる",e:"Chronomaly Crystal Skull",ra:"UR",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:900,de:600,tx:"自分フィールド上に「先史遺産」と名のついたモンスターが存在する場合、自分のメインフェイズ時にこのカードを手札から墓地へ捨てて発動できる。\n自分のデッキ・墓地から「先史遺産クリスタル・スカル」以外の「先史遺産」と名のついたモンスター１体を選んで手札に加える。\n「先史遺産クリスタル・スカル」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"先史遺産クリスタル・ボーン",h:"おーぱーつくりすたる・ぼーん",e:"Chronomaly Crystal Bones",ra:"UR",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1300,de:400,tx:"相手フィールド上にモンスターが存在し、自分フィールド上にモンスターが存在しない場合、このカードは手札から特殊召喚できる。\nこの方法で特殊召喚に成功した時、自分の手札・墓地から「先史遺産クリスタル・ボーン」以外の「先史遺産」と名のついたモンスター１体を選んで特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"先史遺産ゴールデン・シャトル",h:"おーぱーつごーるでん・しゃとる",e:"Chronomaly Golden Jet",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1300,de:1400,tx:"１ターンに１度、自分のメインフェイズ時に発動できる。\n自分フィールド上の全ての「先史遺産」と名のついたモンスターのレベルを１つ上げる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"先史遺産ソル・モノリス",h:"おーぱーつそる・ものりす",e:"Chronomaly Sol Monolith",ra:"R",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:600,de:600,tx:"１ターンに１度、自分フィールド上の「先史遺産」と名のついたモンスター１体を選択して発動できる。\n選択したモンスターのレベルは６になる。\nこの効果を発動するターン、自分は「先史遺産」と名のついたモンスター以外のモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"先史遺産トゥーラ・ガーディアン",h:"おーぱーつとぅーら・がーでぃあん",e:"Chronomaly Tula Guardian",ra:"UR",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1800,de:900,tx:"フィールド魔法カードが表側表示で存在する場合、このカードは手札から特殊召喚できる。\n「先史遺産トゥーラ・ガーディアン」は自分フィールド上に１体しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"先史遺産ネブラ・ディスク",h:"おーぱーつねぶら・でぃすく",e:"Chronomaly Nebra Disk",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1800,de:1500,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードが召喚に成功した時に発動できる。\nデッキから「先史遺産ネブラ・ディスク」以外の「オーパーツ」カード１枚を手札に加える。\n②：このカードが墓地に存在し、自分フィールドのモンスターが「先史遺産」モンスターのみの場合に発動できる。\nこのカードを守備表示で特殊召喚する。\nこの効果を発動するターン、自分は「オーパーツ」カード以外のカードの効果を発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"先史遺産マッドゴーレム・シャコウキ",h:"おーぱーつまっどごーれむ・しゃこうき",e:"Chronomaly Mud Golem",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"このカードがフィールド上に表側表示で存在する限り、自分フィールド上の「先史遺産」と名のついたモンスターが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"先史遺産マヤン・マシーン",h:"おーぱーつまやん・ましーん",e:"Chronomaly Mayan Machine",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1500,de:700,tx:"機械族モンスターをアドバンス召喚する場合、このカードは２体分のリリースとする事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"先史遺産モアイ",h:"おーぱーつもあい",e:"Chronomaly Moai",ra:"UR",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1800,de:1600,tx:"自分フィールド上に「先史遺産」と名のついたモンスターが存在する場合、このカードは手札から表側守備表示で特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"先史遺産モアイキャリア",h:"おーぱーつもあいきゃりあ",e:"Chronomaly Moai Carrier",ra:"UR",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:900,de:1800,tx:"相手フィールド上にカードが存在し、自分フィールド上にカードが存在しない場合、このカードは手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"オーバーテクス・ゴアトルス",h:"おーばーてくす・ごあとるす",e:"Overtex Qoatlus",ra:"SR",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"恐竜族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2700,de:2100,tx:"このカードは通常召喚できない。\n除外されている自分の恐竜族モンスター５体をデッキに戻した場合のみ特殊召喚できる。\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：１ターンに１度、相手が魔法・罠カードを発動した時に発動できる。\n自分の手札・フィールドの恐竜族モンスター１体を選んで破壊し、その発動を無効にし破壊する。\n②：このカードが効果で墓地へ送られた場合に発動できる。\nデッキから「進化薬」魔法カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"オーバーレイ・イーター",h:"おーばーれい・いーたー",e:"Overlay Eater",ra:"N",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:300,de:800,tx:"自分のメインフェイズ時、墓地のこのカードをゲームから除外して発動できる。\n相手フィールド上のエクシーズ素材１つを、自分フィールド上のエクシーズモンスターの下に重ねてエクシーズ素材とする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"オーバーレイ・オウル",h:"おーばーれい・おうる",e:"Overlay Owl",ra:"N",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:800,de:1600,tx:"１ターンに１度、６００ライフポイントを払って発動できる。\nフィールド上のエクシーズ素材を１つ取り除く。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"オーバーレイ・スナイパー",h:"おーばーれい・すないぱー",e:"Overlay Sentinel",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2000,de:0,tx:"このカードは特殊召喚できない。\nこのカードは召喚に成功した時、守備表示になる。\nまた、自分フィールド上にエクシーズ素材を持ったエクシーズモンスターが存在する場合、墓地のこのカードをゲームから除外し、相手フィールド上のモンスター１体を選択して発動できる。\n選択したモンスターの攻撃力は、自分フィールド上のエクシーズ素材の数×５００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"大盤振舞侍",h:"おおばんぶるまいざむらい",e:"Sasuke Samurai #3",ra:"N",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードが相手プレイヤーに戦闘ダメージを与えた時、相手プレイヤーは手札が７枚になるようにカードをドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"オービタル ７",h:"おーびたる　せぶん",e:"Orbital 7",ra:"UR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:500,de:2000,tx:"①：このカードがリバースした場合に発動する。\nこのカードにカシコマリカウンターを１つ置く。\n②：自分メインフェイズに、このカードのカシコマリカウンターを全て取り除いて発動できる。\nこのカードの攻撃力は２０００になり、このターン直接攻撃できず、エンドフェイズに墓地へ送られる。\n③：このカードをリリースし、自分の墓地の、「フォトン」モンスターまたは「ギャラクシー」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"オーロラ・アンギラス",h:"おーろら・あんぎらす",e:"Aurora Paragon",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"①：このカードがモンスターゾーンに存在する限り、お互いにモンスターを特殊召喚できない。\n②：このカード以外のモンスターが召喚された場合に発動する。\nこのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"オーロラ・ウィング",h:"おーろら・うぃんぐ",e:"Aurora Wing",ra:"SR",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1200,de:1600,tx:"このカードが戦闘によって破壊され墓地へ送られた時、このカードを表側攻撃表示で特殊召喚できる。\n「オーロラ・ウィング」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"OKaサンダー",h:"おかさんだー",e:"Mahunder",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:1400,de:700,tx:"１ターンに１度、自分のメインフェイズ時に発動できる。\n手札から「OKaサンダー」以外の雷族・光属性・レベル４のモンスター１体を召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"オキシゲドン",h:"おきしげどん",e:"Oxygeddon",ra:"R",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1800,de:800,tx:"①：このカードが炎族モンスターとの戦闘で破壊され墓地へ送られた場合に発動する。\nお互いのプレイヤーは８００ダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"悪シノビ",h:"おしのび",e:"Vengeful Shinobi",ra:"N",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:400,de:800,tx:"フィールド上に表側攻撃表示で存在するこのカードが攻撃対象に選択された時、自分のデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"おジャマ・イエロー",h:"おじゃま・いえろー",e:"Ojama Yellow",ra:"N",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:0,de:1000,tx:"あらゆる手段を使ってジャマをすると言われているおジャマトリオの一員。\n三人揃うと何かが起こると言われている。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"おジャマ・キング",h:"おじゃま・きんぐ",e:"Ojama King",ra:"UR",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"獣族",mo:["融合","効果"],ma:"",tp:"",at:0,de:3000,tx:"「おジャマ・グリーン」＋「おジャマ・イエロー」＋「おジャマ・ブラック」\nこのカードがフィールド上に表側表示で存在する限り、相手のモンスターカードゾーンを３カ所まで使用不可能にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"おジャマ・グリーン",h:"おじゃま・ぐりーん",e:"Ojama Green",ra:"N",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:0,de:1000,tx:"あらゆる手段を使ってジャマをすると言われているおジャマトリオの一員。\n三人揃うと何かが起こると言われている。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"おジャマ・ナイト",h:"おじゃま・ないと",e:"Ojama Knight",ra:"R",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"獣族",mo:["融合","効果"],ma:"",tp:"",at:0,de:2500,tx:"「おジャマ」モンスター×２\n①：このカードがモンスターゾーンに表側表示で存在する間、使用していない相手のモンスターゾーンを２ヵ所まで指定し、そのゾーンは使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"おジャマ・ブラック",h:"おじゃま・ぶらっく",e:"Ojama Black",ra:"N",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:0,de:1000,tx:"あらゆる手段を使ってジャマをすると言われているおジャマトリオの一員。\n三人揃うと何かが起こると言われている。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"おジャマ・ブルー",h:"おじゃま・ぶるー",e:"Ojama Blue",ra:"SR",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:0,de:1000,tx:"①：このカードが戦闘で破壊され墓地へ送られた時に発動できる。\nデッキから「おジャマ」カード２枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"オシャレオン",h:"おしゃれおん",e:"Oshaleon",ra:"R",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1400,de:800,tx:"このカードが自分フィールド上に表側攻撃表示で存在する限り、相手は「オシャレオン」以外のモンスターを攻撃対象に選択する事はできない。\nこのカードが戦闘によって破壊され墓地へ送られた時、自分のデッキから攻撃力５００以下の爬虫類族モンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"オシリスの天空竜",h:"おしりすのてんくうりゅう",e:"Slifer the Sky Dragon",ra:"UR",t:"monster",a:"神",l:10,ps:"",pe:"",tr:"幻神獣族",mo:["効果"],ma:"",tp:"",at:"?",de:"?",tx:"このカードを通常召喚する場合、３体をリリースして召喚しなければならない。\n①：このカードの召喚は無効化されない。\n②：このカードの召喚成功時には、魔法・罠・モンスターの効果は発動できない。\n③：このカードの攻撃力・守備力は自分の手札の数×１０００アップする。\n④：相手モンスターが攻撃表示で召喚・特殊召喚に成功した場合に発動する。\nそのモンスターの攻撃力を２０００ダウンさせ、０になった場合そのモンスターを破壊する。\n⑤：このカードが特殊召喚されている場合、エンドフェイズに発動する。\nこのカードを墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"オシロ・ヒーロー",h:"おしろ・ひーろー",e:"Oscillo Hero",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1250,de:700,tx:"異次元の世界からやってきた、なんだかよく分からない戦士。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"遅すぎたオーク",h:"おそすぎたおーく",e:"Tardy Orc",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2200,de:0,tx:"このカードは召喚したターンには攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"悪魂邪苦止",h:"おたまじゃくし",e:"T.A.D.P.O.L.E.",ra:"N",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"自分フィールド上に存在するこのカードが戦闘によって破壊され墓地へ送られた時、自分のデッキから「悪魂邪苦止」を手札に加える事ができる。\nその後デッキをシャッフルする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"オッドアイズ・アドバンス・ドラゴン",h:"おっどあいず・あどばんす・どらごん",e:"Odd-Eyes Advance Dragon",ra:"UR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:3000,de:2500,tx:"このカードはレベル５以上のモンスター１体をリリースしてアドバンス召喚できる。\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードがアドバンス召喚に成功した場合に発動できる。\n相手フィールドのモンスター１体を選んで破壊し、そのモンスターの元々の攻撃力分のダメージを相手に与える。\n②：このカードが戦闘でモンスターを破壊した時に発動できる。\n自分の手札・墓地から「オッドアイズ・アドバンス・ドラゴン」以外のレベル５以上のモンスター１体を選んで守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"オッドアイズ・セイバー・ドラゴン",h:"おっどあいず・せいばー・どらごん",e:"Odd-Eyes Saber Dragon",ra:"SR",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2800,de:2000,tx:"①：このカードが手札にある場合、自分フィールドの光属性モンスター１体をリリースして発動できる。\n手札・デッキ及び自分フィールドの表側表示モンスターの中から、「オッドアイズ・ドラゴン」１体を選んで墓地へ送り、このカードを特殊召喚する。\n②：このカードが戦闘でモンスターを破壊し墓地へ送った時に発動できる。\n相手フィールドのモンスター１体を選んで破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"オッドアイズ・ドラゴン",h:"おっどあいず・どらごん",e:"Odd-Eyes Dragon",ra:"R",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2500,de:2000,tx:"①：このカードが戦闘で相手モンスターを破壊し墓地へ送った場合に発動する。\nそのモンスターの元々の攻撃力の半分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"オッドアイズ・ペンデュラム・ドラゴン",h:"おっどあいず・ぺんでゅらむ・どらごん",e:"Odd-Eyes Pendulum Dragon",ra:"UR",t:"monster",a:"闇",l:7,ps:4,pe:"このカード名の①②のP効果はそれぞれ１ターンに１度しか使用できない。\n①：自分のPモンスターの戦闘で発生する自分への戦闘ダメージを０にできる。\n②：自分エンドフェイズに発動できる。\nこのカードを破壊し、デッキから攻撃力１５００以下のPモンスター１体を手札に加える。",tr:"ドラゴン族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2500,de:2000,tx:"①：このカードが相手モンスターとの戦闘で相手に与える戦闘ダメージは倍になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"オッドアイズ・メテオバースト・ドラゴン",h:"おっどあいず・めておばーすと・どらごん",e:"Odd-Eyes Meteorburst Dragon",ra:"UR",t:"monster",a:"炎",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:2000,tx:"チューナー＋チューナー以外のモンスター１体以上\n「オッドアイズ・メテオバースト・ドラゴン」の①の効果は１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した時、自分のPゾーンのカード１枚を対象として発動できる。\nそのカードを特殊召喚する。\nこのターン、このカードは攻撃できない。\n②：このカードがモンスターゾーンに存在する限り、相手はバトルフェイズ中にモンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"オッドアイズ・ランサー・ドラゴン",h:"おっどあいず・らんさー・どらごん",e:"Odd-Eyes Lancer Dragon",ra:"UR",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:3000,de:2000,tx:"①：自分フィールドのPモンスターが戦闘・効果で破壊された場合、自分フィールドのモンスター１体をリリースして発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが攻撃する場合、相手はダメージステップ終了時まで魔法・罠カードを発動できない。\n③：自分フィールドの「オッドアイズ」カードが戦闘・効果で破壊される場合、代わりに自分の手札・モンスターゾーン・Pゾーンの「オッドアイズ」カード１枚を破壊できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"OToサンダー",h:"おとさんだー",e:"Pahunder",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:1300,de:600,tx:"１ターンに１度、自分のメインフェイズ時に発動できる。\n手札から「OToサンダー」以外の雷族・光属性・レベル４のモンスター１体を召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"竜宮之姫",h:"おとひめ",e:"Otohime",ra:"N",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["スピリット","効果"],ma:"",tp:"",at:0,de:100,tx:"このカードは特殊召喚できない。\n召喚・リバースしたターンのエンドフェイズ時に持ち主の手札に戻る。\nこのカードが召喚・リバースした時、相手フィールド上に表側表示で存在するモンスター１体を選択し、表示形式を変更する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"おとぼけオポッサム",h:"おとぼけおぽっさむ",e:"Playful Possum",ra:"R",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:800,de:600,tx:"自分のメインフェイズ時、このカードの攻撃力よりも高い攻撃力を持つモンスターが相手フィールド上に表側表示で存在する場合、フィールド上に存在するこのカードを破壊する事ができる。\nまた、自分のスタンバイフェイズ時、このカードの効果で破壊されたこのカードを墓地から特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"音女",h:"おとめ",e:"Sonic Maid",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1200,de:900,tx:"音を扱うのが得意なオトメ。\n音符のカマを使い攻撃する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"鬼ゴブリン",h:"おにごぶりん",e:"Coach Goblin",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1200,de:1500,tx:"このカードが自分フィールド上に表側表示で存在する限り、自分のエンドフェイズ時に手札から通常モンスターカード１枚をデッキの一番下に戻す事で、デッキからカードを１枚ドローする事ができる。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ONiサンダー",h:"おにさんだー",e:"Brohunder",ra:"UR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:900,de:400,tx:"①：このカードが召喚に成功した時に発動できる。\nデッキから「ONiサンダー」以外の雷族・光属性・レベル４モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"鬼タンクT－34",h:"おにたんくＴ－３４",e:"Oni Tank T-34",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1400,de:1700,tx:"鬼の魂が乗り移った戦車。\n意志を持ち敵をどこまでも追いつめる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ONeサンダー",h:"おねさんだー",e:"Sishunder",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:900,de:400,tx:"このカードが召喚に成功した時、「ONeサンダー」以外の自分の墓地の雷族・光属性・レベル４・攻撃力１６００以下のモンスター１体を選択してゲームから除外できる。\nこのターンのエンドフェイズ時にそのカードを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"オプション",h:"おぷしょん",e:"Gradius' Option",ra:"N",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"機械族",mo:["特殊召喚","効果"],ma:"",tp:"",at:"?",de:"?",tx:"このカードは通常召喚できない。\n自分フィールド上に「超時空戦闘機ビック・バイパー」が表側表示で存在する場合に特殊召喚する事ができる。\nこのカードを特殊召喚する場合、自分フィールド上に表側表示で存在する「超時空戦闘機ビック・バイパー」１体を選択しなければならない。\nこのカードの攻撃力・守備力は常に選択したモンスターと同じになる。\n選択したモンスターがフィールド上に表側表示で存在しなくなった時、このカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"オベリスクの巨神兵",h:"おべりすくのきょしんへい",e:"Obelisk the Tormentor",ra:"UR",t:"monster",a:"神",l:10,ps:"",pe:"",tr:"幻神獣族",mo:["効果"],ma:"",tp:"",at:4000,de:4000,tx:"このカードを通常召喚する場合、３体をリリースして召喚しなければならない。\n①：このカードの召喚は無効化されない。\n②：このカードの召喚成功時には、魔法・罠・モンスターの効果は発動できない。\n③：このカードは効果の対象にならない。\n④：自分フィールドのモンスター２体をリリースして発動できる。\n相手フィールドのモンスターを全て破壊する。\nこの効果を発動するターン、このカードは攻撃宣言できない。\n⑤：このカードが特殊召喚されている場合、エンドフェイズに発動する。\nこのカードを墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"オボミ",h:"おぼみ",e:"Lillybot",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:500,de:2000,tx:"①：このカードが召喚・リバースした時、自分の墓地の「オービタル ７」１体を対象として発動できる。\nそのモンスターを表側攻撃表示または裏側守備表示で特殊召喚する。\n②：１ターンに１度、自分フィールドの機械族モンスターを任意の数だけリリースして発動できる。\nリリースした数だけ、手札から「フォトン」モンスターまたは「ギャラクシー」モンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"尾も白い黒猫",h:"おもしろいくろねこ",e:"Dark Cat with White Tail",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["リバース","効果"],ma:"",tp:"",at:800,de:500,tx:"リバース：相手フィールド上モンスター２体と自分フィールド上モンスター１体を持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"オヤコーン",h:"おやこーん",e:"Papa-Corn",ra:"UR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1200,de:800,tx:"フィールド魔法カードが表側表示で存在する場合、このカードの攻撃力は１０００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"オリエント・ドラゴン",h:"おりえんと・どらごん",e:"Orient Dragon",ra:"SR",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2300,de:1000,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカードがシンクロ召喚に成功した時、相手フィールド上のシンクロモンスター１体を選択してゲームから除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"オルターガイスト・ドラッグウィリオン",h:"おるたーがいすと・どらっぐうぃりおん",e:"Altergeist Dragvirion",ra:"R",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["シンクロ","効果"],ma:"",tp:"",at:2200,de:1200,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：特殊召喚された相手モンスターの攻撃宣言時に、自分フィールドの「オルターガイスト」モンスター１体を持ち主の手札に戻して発動できる。\nその攻撃を無効にする。\n②：このカードがリリースされ墓地へ送られた場合に発動できる。\nこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"オルターガイスト・フィフィネラグ",h:"おるたーがいすと・ふぃふぃねらぐ",e:"Altergeist Fifinellag",ra:"N",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:1000,tx:"①：このカードがモンスターゾーンに存在する限り、相手は「オルターガイスト・フィフィネラグ」以外の自分フィールドの「オルターガイスト」モンスターを、攻撃対象に選択できず、効果の対象にもできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"オルターガイスト・マリオネッター",h:"おるたーがいすと・まりおねったー",e:"Altergeist Marionetter",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1600,de:1700,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時に発動できる。\nデッキから「オルターガイスト」罠カード１枚を選んで自分の魔法＆罠ゾーンにセットする。\n②：自分フィールドの「オルターガイスト」カード１枚と、自分の墓地の「オルターガイスト」モンスター１体を対象として発動できる。\n対象のフィールドのカードを墓地へ送り、対象の墓地のモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"オルフェゴール・カノーネ",h:"おるふぇごーる・かのーね",e:"Orcust Brass Bombard",ra:"N",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:500,de:1900,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：墓地のこのカードを除外して発動できる。\n手札から「オルフェゴール・カノーネ」以外の「オルフェゴール」モンスター１体を特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は闇属性モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"オルフェゴール・スケルツォン",h:"おるふぇごーる・すけるつぉん",e:"Orcust Cymbal Skeleton",ra:"R",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:1500,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：墓地のこのカードを除外し、「オルフェゴール・スケルツォン」以外の自分の墓地の「オルフェゴール」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は闇属性モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"オルフェゴール・ディヴェル",h:"おるふぇごーる・でぃう゛ぇる",e:"Orcust Harp Horror",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1700,de:1400,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：墓地のこのカードを除外して発動できる。\nデッキから「オルフェゴール・ディヴェル」以外の「オルフェゴール」モンスター１体を特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は闇属性モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"オレイカルコス・シュノロス",h:"おれいかるこす・しゅのろす",e:"Orichalcos Shunoros",ra:"N",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"機械族",mo:["特殊召喚","効果"],ma:"",tp:"",at:"?",de:0,tx:"このカードは通常召喚できない。\nこのカードの①の効果で特殊召喚できる。\n①：自分の通常モンスターが戦闘で破壊された場合、ダメージステップ終了時に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードの攻撃力は相手フィールドのモンスターの数×１０００アップする。\n③：このカードがモンスターゾーンに存在する限り、フィールドのレベル４通常モンスターは効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"女剣士カナン",h:"おんなけんしかなん",e:"Kanan the Swordmistress",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1400,de:1400,tx:"チョウのように舞いハチのように刺す、剣と盾を手にした女戦士。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"女忍者ヤエ",h:"おんなにんじゃやえ",e:"Lady Ninja Yae",ra:"N",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1100,de:200,tx:"手札から風属性モンスター１枚を墓地に捨てる。\n相手フィールド上に存在する魔法・罠カードを全て持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"怨念集合体",h:"おんねんしゅうごうたい",e:"Souls of the Forgotten",ra:"N",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:900,de:200,tx:"恨みを持って死んでいった人の意識が集まってできた悪霊。\n人を襲いその意識をとりこんで巨大化していく。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"怨念のキラードール",h:"おんねんのきらーどーる",e:"Malice Doll of Demise",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1600,de:1700,tx:"このカードが永続魔法の効果によってフィールド上から墓地に送られた場合、自分のターンのスタンバイフェイズ時に墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"怨念の魂 業火",h:"おんねんのたましい　ごうか",e:"Goka, the Pyre of Malice",ra:"SR",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:2200,de:1900,tx:"①：自分フィールドに炎属性モンスターが存在する場合、このカードは手札から特殊召喚できる。\n②：このカードの①の方法で特殊召喚に成功した場合、自分フィールドの炎属性モンスター１体を対象として発動する。\nその自分の炎属性モンスターを破壊する。\n③：このカード以外の自分フィールドの炎属性モンスター１体をリリースして発動できる。\nこのカードの攻撃力はターン終了時まで５００アップする。\n④：自分スタンバイフェイズに発動する。\n自分フィールドに「火の玉トークン」（炎族・炎・星１・攻／守１００）１体を守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"陰陽師 タオ",h:"おんみょうし　たお",e:"Tao the Chanter",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:1200,de:900,tx:"陰と陽の力を浸食させ、歪んだ力を生み出す魔導士。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガーゴイル",h:"がーごいる",e:"Ryu-Kishin",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1000,de:500,tx:"石像と思わせ、闇の中から攻撃をする。\n逃げ足も素早い。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガーゴイル・パワード",h:"がーごいる・ぱわーど",e:"Ryu-Kishin Powered",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1600,de:1200,tx:"闇の力を得て強化されたガーゴイル。\nかぎづめに注意！",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガーゴイルの道化師",h:"がーごいるのどうけし",e:"Ryu-Kishin Clown",ra:"N",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:800,de:500,tx:"このカードが召喚・反転召喚・特殊召喚された時、対象の表側表示モンスター１体の表示形式を変更する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カース・オブ・ヴァンパイア",h:"かーす・おぶ・う゛ぁんぱいあ",e:"Vampire's Curse",ra:"R",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:2000,de:800,tx:"このカードが戦闘によって破壊され墓地へ送られた時、５００ライフポイントを払って発動できる。\n次のターンのスタンバイフェイズ時に、このカードを墓地から特殊召喚する。\nまた、この効果によって特殊召喚に成功した時に発動する。\nこのカードの攻撃力は５００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カース・オブ・ドラゴン",h:"かーす・おぶ・どらごん",e:"Curse of Dragon",ra:"R",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:2000,de:1500,tx:"邪悪なドラゴン。\n闇の力を使った攻撃は強力だ。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カースド・フィグ",h:"かーすど・ふぃぐ",e:"Cursed Fig",ra:"SR",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:200,de:200,tx:"このカードが戦闘によって破壊され墓地へ送られた時、フィールド上にセットされた魔法・罠カードを２枚選択して発動する。\nこのカードが墓地に存在する限り、選択したカードを発動する事はできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"花札衛－猪鹿蝶－",h:"かーでぃあん－いのしかちょう－",e:"Flower Cardian Boardefly",ra:"R",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2000,de:2000,tx:"チューナー＋チューナー以外のモンスター２体\n①：このカードがモンスターゾーンに存在する限り、自分の「花札衛」モンスターが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ相手に戦闘ダメージを与える。\n②：１ターンに１度、自分の墓地の「花札衛」モンスター１体を除外して発動できる。\n次の相手ターン終了時まで、相手は墓地のカードの効果を発動できず、墓地からモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"花札衛－芒－",h:"かーでぃあん－すすき－",e:"Flower Cardian Zebra Grass",ra:"N",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"①：自分フィールドにレベル７以下の「花札衛」モンスターが存在する場合に発動できる。\nこのカードを手札から特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は「花札衛」モンスターしか召喚・特殊召喚できない。\n②：このカードが召喚・特殊召喚に成功した場合に発動できる。\n手札の「花札衛」モンスターを任意の数だけ相手に見せ、デッキに戻してシャッフルする。\nその後、自分はデッキに戻した数だけデッキからドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"花札衛－芒に月－",h:"かーでぃあん－すすきにつき－",e:"Flower Cardian Zebra Grass with Moon",ra:"N",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2000,de:2000,tx:"このカードは通常召喚できない。\n「花札衛－芒に月－」以外の自分フィールドのレベル８の「花札衛」モンスター１体をリリースした場合に特殊召喚できる。\n①：このカードが特殊召喚に成功した場合に発動する。\n自分はデッキから１枚ドローし、お互いに確認する。\nそれが「花札衛」モンスターだった場合、そのモンスターを特殊召喚できる。\n違った場合、そのカードを墓地へ送る。\n②：１ターンに１度、このカードが戦闘で相手モンスターを破壊した時に発動できる。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"花札衛－萩に猪－",h:"かーでぃあん－はぎにいのしし－",e:"Flower Cardian Clover with Boar",ra:"R",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードは通常召喚できない。\n「花札衛－萩に猪－」以外の自分フィールドの「花札衛」モンスター１体をリリースした場合に特殊召喚できる。\n①：このカードが特殊召喚に成功した場合に発動する。\n自分はデッキから１枚ドローし、お互いに確認する。\nそれが「花札衛」モンスターだった場合、相手フィールドのモンスター１体を選んで破壊できる。\n違った場合、そのカードを墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"花札衛－牡丹に蝶－",h:"かーでぃあん－ぼたんにちょう－",e:"Flower Cardian Peony with Butterfly",ra:"N",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","チューナー","効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードは通常召喚できない。\n「花札衛－牡丹に蝶－」以外の自分フィールドの「花札衛」モンスター１体をリリースした場合に特殊召喚できる。\n①：このカードが特殊召喚に成功した場合に発動する。\n自分はデッキから１枚ドローし、お互いに確認する。\nそれが「花札衛」モンスターだった場合、相手のデッキの上からカードを３枚確認し、好きな順番でデッキの上か下に戻す。\n違った場合、そのカードを墓地へ送る。\n②：フィールドのこのカードをS素材とする場合、このカードを含む全てのS素材モンスターをレベル２モンスターとして扱う事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"花札衛－松－",h:"かーでぃあん－まつ－",e:"Flower Cardian Pine",ra:"R",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"「花札衛－松－」の①の効果は１ターンに１度しか使用できない。\n①：このカードが召喚に成功した場合に発動する。\n自分はデッキから１枚ドローし、お互いに確認する。\nそれが「花札衛」モンスター以外だった場合、そのカードを墓地へ送る。\n②：このカードが戦闘または相手の効果で破壊され墓地へ送られた場合に発動できる。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"花札衛－松に鶴－",h:"かーでぃあん－まつにつる－",e:"Flower Cardian Pine with Crane",ra:"N",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2000,de:2000,tx:"このカードは通常召喚できない。\n「花札衛－松に鶴－」以外の自分フィールドのレベル１の「花札衛」モンスター１体をリリースした場合に特殊召喚できる。\n①：このカードが特殊召喚に成功した場合に発動する。\n自分はデッキから１枚ドローし、お互いに確認する。\nそれが「花札衛」モンスターだった場合、そのモンスターを特殊召喚できる。\n違った場合、そのカードを墓地へ送る。\n②：このカードが戦闘を行ったバトルフェイズ終了時に発動できる。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"花札衛－紅葉に鹿－",h:"かーでぃあん－もみじにしか－",e:"Flower Cardian Maple with Deer",ra:"R",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードは通常召喚できない。\n「花札衛－紅葉に鹿－」以外の自分フィールドの「花札衛」モンスター１体をリリースした場合に特殊召喚できる。\n①：このカードが特殊召喚に成功した場合に発動する。\n自分はデッキから１枚ドローし、お互いに確認する。\nそれが「花札衛」モンスターだった場合、相手フィールドの魔法・罠カード１枚を選んで破壊できる。\n違った場合、そのカードを墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガーディアン・エアトス",h:"がーでぃあん・えあとす",e:"Guardian Eatos",ra:"SR",t:"monster",a:"風",l:8,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2500,de:2000,tx:"①：自分の墓地にモンスターが存在しない場合、このカードは手札から特殊召喚できる。\n②：このカードに装備された自分フィールドの装備魔法カード１枚を墓地へ送り、相手の墓地のモンスターを３体まで対象として発動できる。\nそのモンスターを除外する。\nこのカードの攻撃力はターン終了時まで、この効果で除外したモンスターの数×５００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガーディアン・オブ・オーダー",h:"がーでぃあん・おぶ・おーだー",e:"Guardian of Order",ra:"SR",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2500,de:1200,tx:"自分フィールド上に光属性モンスターが２体以上存在する場合、このカードは手札から特殊召喚できる。\n「ガーディアン・オブ・オーダー」は自分フィールド上に１体しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"巨竜の守護騎士",h:"がーでぃあん・おぶ・ふぇるぐらんと",e:"Guardian of Felgrand",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:500,de:500,tx:"①：このカードが召喚・特殊召喚に成功した場合に発動できる。\n自分の手札・墓地からレベル７・８のドラゴン族モンスター１体を選んで装備カード扱いとしてこのカードに装備する。\n②：このカードの攻撃力・守備力は、このカードの効果で装備したモンスターのそれぞれの数値の半分アップする。\n③：自分フィールドのモンスター１体とこのカードをリリースし、自分の墓地のレベル７・８のドラゴン族モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガーディアン・グラール",h:"がーでぃあん・ぐらーる",e:"Guardian Grarl",ra:"R",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:2500,de:1000,tx:"「重力の斧－グラール」が自分のフィールド上に存在する時のみ、このカードは召喚・反転召喚・特殊召喚する事ができる。\n手札にこのカード１枚しかない場合、手札からこのカードを特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガーディアン・ケースト",h:"がーでぃあん・けーすと",e:"Guardian Kay'est",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:1000,de:1800,tx:"「静寂のロッド－ケースト」が自分のフィールド上に存在する時のみ、このカードは召喚・反転召喚・特殊召喚する事ができる。\nこのカードは魔法の効果を受けない。\nまた相手モンスターから攻撃対象にされない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガーディアン・シール",h:"がーでぃあん・しーる",e:"Guardian Ceal",ra:"R",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:1700,de:1400,tx:"「流星の弓－シール」が自分のフィールド上に存在する時のみ、このカードは召喚・反転召喚・特殊召喚する事ができる。\nこのカードに装備された自分の装備カード１枚を墓地へ送る事で、相手フィールド上のモンスター１体を破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガーディアン・スタチュー",h:"がーでぃあん・すたちゅー",e:"Guardian Statue",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:800,de:1400,tx:"このカードは１ターンに１度だけ裏側守備表示にする事ができる。\nこのカードが反転召喚に成功した時、相手フィールド上モンスター１体を持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガーディアン・デスサイス",h:"がーでぃあん・ですさいす",e:"Guardian Dreadscythe",ra:"N",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"悪魔族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2500,de:2000,tx:"このカードは通常召喚できず、このカードの効果でのみ特殊召喚できる。\n①：「ガーディアン・エアトス」が戦闘・効果で破壊され自分の墓地へ送られた場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが特殊召喚に成功した時に発動できる。\nデッキから「死神の大鎌－デスサイス」１枚をこのカードに装備する。\n③：このカードがモンスターゾーンに存在する限り、自分は召喚・特殊召喚できない。\n④：このカードがフィールドから墓地へ送られた場合に発動する。\n手札を１枚墓地へ送り、このカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガーディアン・トライス",h:"がーでぃあん・とらいす",e:"Guardian Tryce",ra:"N",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:1900,de:1700,tx:"「閃光の双剣－トライス」が自分のフィールド上に存在する時のみ、このカードは召喚・反転召喚・特殊召喚する事ができる。\nこのカードが破壊され墓地へ送られた時、墓地に存在するこのカードの生け贄召喚に使用したモンスターを自分のフィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガーディアン・バオウ",h:"がーでぃあん・ばおう",e:"Guardian Baou",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:800,de:400,tx:"「破邪の大剣－バオウ」が自分のフィールド上に存在する時のみ、このカードは召喚・反転召喚・特殊召喚する事ができる。\n戦闘で相手モンスターを破壊して墓地へ送る度に、このカードの攻撃力は１０００ポイントアップする。\nまた、戦闘で破壊した効果モンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"守護天使 ジャンヌ",h:"がーでぃあんえんじぇる　じゃんぬ",e:"Guardian Angel Joan",ra:"UR",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2800,de:2000,tx:"①：このカードが戦闘でモンスターを破壊し墓地へ送った場合に発動する。\nそのモンスターの元々の攻撃力分だけ自分のLPを回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"守護者スフィンクス",h:"がーでぃあんすふぃんくす",e:"Guardian Sphinx",ra:"SR",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1700,de:2400,tx:"このカードは１ターンに１度だけ裏側守備表示にする事ができる。\nこのカードが反転召喚に成功した時、相手フィールド上に存在するモンスターを全て持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガード・オブ・フレムベル",h:"がーど・おぶ・ふれむべる",e:"Flamvell Guard",ra:"SR",t:"monster",a:"炎",l:1,ps:"",pe:"",tr:"ドラゴン族",mo:["チューナー","通常"],ma:"",tp:"",at:100,de:2000,tx:"炎を自在に操る事ができる、フレムベルの護衛戦士。\n灼熱のバリアを作り出して敵の攻撃を跳ね返す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガード・ドッグ",h:"がーど・どっぐ",e:"Guard Dog",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["リバース","効果"],ma:"",tp:"",at:1500,de:500,tx:"リバース：このターン相手プレイヤーは特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カード・ブレイカー",h:"かーど・ぶれいかー",e:"Card Breaker",ra:"R",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:100,de:900,tx:"このカードは通常召喚できない。\n自分の魔法＆罠カードゾーンに存在するカード１枚を墓地へ送った場合に表側攻撃表示で特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガード・ペンギン",h:"がーど・ぺんぎん",e:"Guard Penguin",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:0,de:1200,tx:"カードの効果によって自分がダメージを受けた時に発動できる。\nこのカードを手札から特殊召喚し、受けたダメージの数値分だけ自分のライフポイントを回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カードエクスクルーダー",h:"かーどえくすくるーだー",e:"Card Ejector",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:400,de:400,tx:"相手の墓地に存在するカード１枚を選択しゲームから除外する。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カードガード",h:"かーどがーど",e:"Card Guard",ra:"UR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1600,de:500,tx:"このカードが召喚・特殊召喚に成功した時、このカードにガードカウンターを１つ置く。\nこのカードに乗っているガードカウンター１つにつき、このカードの攻撃力は３００ポイントアップする。\nまた、１ターンに１度、このカードに乗っているガードカウンターを１つ取り除き、このカード以外の自分フィールド上に表側表示で存在するカード１枚にガードカウンターを１つ置く事ができる。\n選択したカードが破壊される場合、代わりにガードカウンターを１つ取り除く。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カードガンナー",h:"かーどがんなー",e:"Card Trooper",ra:"SR",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:400,de:400,tx:"①：１ターンに１度、自分のデッキの上からカードを３枚まで墓地へ送って発動できる。\nこのカードの攻撃力はターン終了時まで、この効果を発動するために墓地へ送ったカードの数×５００アップする。\n②：自分フィールドのこのカードが破壊され墓地へ送られた場合に発動する。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カードブロッカー",h:"かーどぶろっかー",e:"Card Blocker",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:400,de:400,tx:"このカードは召喚・反転召喚・特殊召喚に成功した時、守備表示になる。\n自分フィールド上に表側表示で存在するモンスターが攻撃対象に選択された時、このカードに攻撃対象を変更する事ができる。\nこのカードが攻撃対象になった時、自分のデッキのカードを上から３枚まで墓地へ送る事ができる。\n墓地へ送ったカード１枚につき、このカードの守備力はエンドフェイズ時まで５００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カードを狩る死神",h:"かーどをかるしにがみ",e:"Reaper of the Cards",ra:"N",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"悪魔族",mo:["リバース","効果"],ma:"",tp:"",at:1380,de:1930,tx:"リバース：フィールド上に存在する罠カード１枚を選択して破壊する。\n選択したカードがセットされている場合、そのカードをめくって確認し、罠カードなら破壊する。\n魔法カードの場合は元に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガーネシア・エレファンティス",h:"がーねしあ・えれふぁんてぃす",e:"Garnecia Elefantis",ra:"R",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"獣戦士族",mo:["通常"],ma:"",tp:"",at:2400,de:2000,tx:"恐るべきパワーの持ち主。\nあまりの体重の重さに、歩くたびに地割れが起きてしまう。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カーボネドン",h:"かーぼねどん",e:"Carboneddon",ra:"SR",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:800,de:600,tx:"「カーボネドン」の②の効果は１ターンに１度しか使用できない。\n①：このカードが炎属性モンスターと戦闘を行うダメージ計算時に発動する。\nこのカードの攻撃力は、そのダメージ計算時のみ１０００アップする。\n②：自分メインフェイズに墓地のこのカードを除外して発動できる。\n手札・デッキからレベル７以下のドラゴン族の通常モンスター１体を守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガイアフレーム",h:"がいあふれーむ",e:"Earth Effigy",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:100,de:2000,tx:"地属性の通常モンスターを生け贄召喚する場合、このモンスター１体で２体分の生け贄とする事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魁炎星－シーブ",h:"かいえんせい－しーぶ",e:"Brotherhood of the Fire Fist - Ram",ra:"R",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:800,de:200,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時、手札を１枚捨て、自分フィールドの「炎舞」魔法・罠カード１枚を対象として発動できる。\nそのカードとはカード名が異なる「炎舞」魔法・罠カード１枚をデッキから選んで自分フィールドにセットする。\n②：このカードが「炎星」モンスターの効果で特殊召喚に成功した場合に発動できる。\n同名カードが自分の墓地に存在しない「炎舞」魔法・罠カード１枚をデッキから選んで自分フィールドにセットする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魁炎星王－ソウコ",h:"かいえんせいおう－そうこ",e:"Brotherhood of the Fire Fist - Tiger King",ra:"UR",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2200,de:1800,tx:"獣戦士族レベル４モンスター×２\n①：このカードがX召喚に成功した時に発動できる。\nデッキから「炎舞」魔法・罠カード１枚をセットする。\n②：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\n獣戦士族以外のフィールドの全ての効果モンスターの効果は相手ターン終了時まで無効化される。\n③：このカードがフィールドから墓地へ送られた時、自分フィールドの表側表示の「炎舞」魔法・罠カード３枚を墓地へ送って発動できる。\nレベル４以下で同じ攻撃力の獣戦士族モンスター２体をデッキから守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"海皇の重装兵",h:"かいおうのじゅうそうへい",e:"Atlantean Heavy Infantry",ra:"SR",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:0,de:1600,tx:"①：このカードがモンスターゾーンに存在する限り、自分は通常召喚に加えて１度だけ、自分メインフェイズにレベル４以下の海竜族モンスター１体を召喚できる。\n②：このカードが水属性モンスターの効果を発動するために墓地へ送られた場合、相手フィールドの表側表示のカード１枚を対象として発動する。\nその相手の表側表示のカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"海皇の狙撃兵",h:"かいおうのそげきへい",e:"Atlantean Marksman",ra:"SR",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:1400,de:0,tx:"このカードが相手ライフに戦闘ダメージを与えた時、デッキから「海皇の狙撃兵」以外のレベル４以下の「海皇」と名のついた海竜族モンスター１体を特殊召喚できる。\nまた、このカードが水属性モンスターの効果を発動するために墓地へ送られた時、相手フィールド上にセットされたカード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"海皇の突撃兵",h:"かいおうのとつげきへい",e:"Atlantean Attack Squad",ra:"R",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:1400,de:0,tx:"自分フィールド上にこのカード以外の魚族・海竜族・水族モンスターが存在する場合、このカードの攻撃力は８００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"海皇の長槍兵",h:"かいおうのながやりへい",e:"Atlantean Pikeman",ra:"N",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"海竜族",mo:["通常"],ma:"",tp:"",at:1400,de:0,tx:"海底を支配していると言われる、海皇に仕える長槍兵。\n深く暗い海の底から襲いかかる長槍の連続攻撃は、深海魚たちに恐れられている。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"海皇龍 ポセイドラ",h:"かいおうりゅう　ぽせいどら",e:"Poseidra, the Atlantean Dragon",ra:"R",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:2800,de:1600,tx:"自分フィールド上のレベル３以下の水属性モンスター３体をリリースして発動できる。\nこのカードを手札または墓地から特殊召喚する。\nこの効果で特殊召喚に成功した時、フィールド上の魔法・罠カードを全て持ち主の手札に戻す。\nこの効果でカードを３枚以上手札に戻した場合、相手フィールド上の全てのモンスターの攻撃力は手札に戻したカードの数×３００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"絵画に潜む者",h:"かいがにひそむもの",e:"The Portrait's Secret",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1200,de:1500,tx:"描いた者の呪いの込められた絵。\nこの絵を所持した者は全て不幸に陥ると言われている。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"鎧黒竜－サイバー・ダーク・ドラゴン",h:"がいこくりゅう－さいばー・だーく・どらごん",e:"Cyberdark Dragon",ra:"UR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:1000,de:1000,tx:"「サイバー・ダーク・ホーン」＋「サイバー・ダーク・エッジ」＋「サイバー・ダーク・キール」\nこのカードは融合召喚でしか特殊召喚できない。\n①：このカードが特殊召喚に成功した場合、自分の墓地のドラゴン族モンスター１体を対象として発動する。\nそのドラゴン族モンスターを装備カード扱いとしてこのカードに装備する。\n②：このカードの攻撃力は、このカードの効果で装備したモンスターの攻撃力分、及び自分の墓地のモンスターの数×１００アップする。\n③：このカードが戦闘で破壊される場合、代わりに装備したそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"鎧獄竜－サイバー・ダークネス・ドラゴン",h:"がいごくりゅう－さいばー・だーくねす・どらごん",e:"Cyberdarkness Dragon",ra:"UR",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:2000,de:2000,tx:"「サイバー・ダーク」効果モンスター×５\nこのカードは融合召喚でのみEXデッキから特殊召喚できる。\n①：このカードが特殊召喚に成功した場合に発動できる。\n自分の墓地からドラゴン族モンスターまたは機械族モンスター１体を選び、装備カード扱いとしてこのカードに装備する。\n②：このカードの攻撃力は、このカードの効果で装備したモンスターの攻撃力分アップする。\n③：相手が魔法・罠・モンスターの効果を発動した時、自分フィールドの装備カード１枚を墓地へ送って発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カイザー・グライダー",h:"かいざー・ぐらいだー",e:"Kaiser Glider",ra:"SR",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2400,de:2200,tx:"①：このカードは同じ攻撃力のモンスターとの戦闘では破壊されない。\n②：このカードが破壊され墓地へ送られた場合、フィールドのモンスター１体を対象として発動する。\nそのモンスターを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カイザー・サクリファイス",h:"かいざー・さくりふぁいす",e:"Samsara Kaiser",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:200,de:100,tx:"このカードを生け贄にした生け贄召喚に成功した時、このカードを手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カイザー・シースネーク",h:"かいざー・しーすねーく",e:"Kaiser Sea Snake",ra:"R",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:2500,de:1000,tx:"「カイザー・シースネーク」の①の方法による特殊召喚は１ターンに１度しかできない。\n①：相手フィールドにモンスターが存在し、自分フィールドにモンスターが存在しない場合、このカードは手札から特殊召喚できる。\n②：このカードがこのカードの①の方法で特殊召喚に成功した時に発動できる。\n自分の手札・墓地から海竜族・レベル８モンスター１体を選んで特殊召喚する。\nこの効果で特殊召喚したモンスターの攻撃力・守備力は０になる。\n③：特殊召喚したこのカードのレベルは４になり、元々の攻撃力は０になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カイザー・シーホース",h:"かいざー・しーほーす",e:"Kaiser Sea Horse",ra:"UR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:1700,de:1650,tx:"①：光属性モンスターをアドバンス召喚する場合、このカードは２体分のリリースにできる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カイザー・ドラゴン",h:"かいざー・どらごん",e:"Kaiser Dragon",ra:"N",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["融合"],ma:"",tp:"",at:2300,de:2000,tx:"砦を守る翼竜＋フェアリー・ドラゴン",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カイザー・ブラッド・ヴォルス",h:"かいざー・ぶらっど・う゛ぉるす",e:"Kaiser Vorse Raider",ra:"UR",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1900,de:1200,tx:"①：自分フィールドにモンスターが存在しない場合、このカードは手札から特殊召喚できる。\n②：このカードが戦闘で相手モンスターを破壊した場合に発動する。\nこのカードの攻撃力は５００アップする。\n③：このカードが戦闘で破壊された場合に発動する。\nこのカードを破壊したモンスターの攻撃力は５００ダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"灰塵王 アッシュ・ガッシュ",h:"かいじんおう　あっしゅ・がっしゅ",e:"Gash the Dust Lord",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1000,de:1200,tx:"このカードが相手ライフに戦闘ダメージを与えた時、このカードのレベルを１つ上げる（最大レベル１２まで）。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"海賊船スカルブラッド号",h:"かいぞくせんすかるぶらっどごう",e:"Skull Mariner",ra:"R",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1600,de:900,tx:"船首に赤い骸骨をかたどった海賊船。\nあらゆる海域に神出鬼没に現れ、旅客船や貨物船を襲撃する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"怪鳥グライフ",h:"かいちょうぐらいふ",e:"Glife the Phantom Bird",ra:"SR",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1500,de:1500,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードを手札から捨てて発動できる。\nデッキから「シュトロームベルクの金の城」１枚を手札に加える。\n②：このカードが召喚・特殊召喚に成功した場合、相手の魔法＆罠ゾーンのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"海底に潜む深海竜",h:"かいていにひそむしんかいりゅう",e:"The Dragon Dwelling in the Deep",ra:"R",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:1700,de:1400,tx:"お互いのスタンバイフェイズ毎にこのカードにオーシャンカウンターを１つ置く。\nこのカードがフィールド上から離れた時、このカードに乗っているオーシャンカウンター１つにつき、自分フィールド上に存在する魚族・海竜族モンスターの攻撃力はエンドフェイズ時まで２００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"開闢の騎士",h:"かいびゃくのきし",e:"Beginning Knight",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:500,de:2000,tx:"「開闢の騎士」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードを使用して儀式召喚した「カオス・ソルジャー」モンスターは以下の効果を得る。\n●１ターンに１度、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターを除外する。\n●このカードが戦闘で相手モンスターを破壊し墓地へ送った時に発動できる。\nこのカードはもう１度だけ続けて攻撃できる。\n②：墓地のこのカードが除外された場合に発動できる。\nデッキから儀式魔法カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"海竜神－ネオダイダロス",h:"かいりゅうしん－ねおだいだろす",e:"Ocean Dragon Lord - Neo-Daedalus",ra:"SR",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"海竜族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2900,de:1600,tx:"このカードは通常召喚できない。\n自分フィールド上に存在する「海竜－ダイダロス」１体をリリースした場合のみ特殊召喚する事ができる。\n自分フィールド上に存在する「海」を墓地へ送る事で、このカード以外のお互いの手札・フィールド上のカードを全て墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガエル・サンデス",h:"がえる・さんです",e:"D.3.S. Frog",ra:"R",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"水族",mo:["融合","効果"],ma:"",tp:"",at:2500,de:2000,tx:"「デスガエル」＋「デスガエル」＋「デスガエル」\nこのモンスターの融合召喚は、上記のカードでしか行えない。\nこのカードの攻撃力は、自分の墓地に存在する「黄泉ガエル」の枚数×５００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"火炎草",h:"かえんそう",e:"Firegrass",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"植物族",mo:["通常"],ma:"",tp:"",at:700,de:600,tx:"火山の近くに生息する草。\n花から火炎を吹き攻撃する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"火炎木人18",h:"かえんもくじんいんぱち",e:"Blazing Inpachi",ra:"UR",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["通常"],ma:"",tp:"",at:1850,de:0,tx:"全身が灼熱の炎に包まれた巨木の化身。\n炎の攻撃は強力だが、自身が燃えているため先は長くない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カオス・ウィザード",h:"かおす・うぃざーど",e:"Kamionwizard",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["融合"],ma:"",tp:"",at:1300,de:1100,tx:"「ホーリー・エルフ」＋「黒魔族のカーテン」",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カオス・ゴッデス－混沌の女神－",h:"かおす・ごっです－こんとんのめがみ－",e:"Chaos Goddess",ra:"SR",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"天使族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:1800,tx:"光属性チューナー＋チューナー以外の闇属性モンスター２体以上\n１ターンに１度、手札から光属性モンスター１体を墓地へ送り、自分の墓地のレベル５以上の闇属性モンスター１体を選択して発動できる。\n選択したモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターはシンクロ素材にできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カオス・ソーサラー",h:"かおす・そーさらー",e:"Chaos Sorcerer",ra:"UR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2300,de:2000,tx:"このカードは通常召喚できない。\n自分の墓地から光属性と闇属性のモンスターを１体ずつ除外した場合に特殊召喚できる。\n①：１ターンに１度、フィールドの表側表示モンスター１体を対象として発動できる。\nその表側表示モンスターを除外する。\nこの効果を発動するターン、このカードは攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カオス・ソルジャー",h:"かおす・そるじゃー",e:"Black Luster Soldier",ra:"UR",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"戦士族",mo:["儀式"],ma:"",tp:"",at:3000,de:2500,tx:"「カオスの儀式」により降臨。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カオス・ソルジャー －宵闇の使者－",h:"かおす・そるじゃー　－よいやみのししゃ－",e:"Black Luster Soldier - Envoy of the Evening Twilight",ra:"UR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3000,de:2500,tx:"このカードは通常召喚できない。\n自分の墓地の光属性と闇属性のモンスターの数が同じ場合に、その内のどちらかの属性を全て除外した場合のみ特殊召喚できる。\nこのカードの属性は「光」としても扱う。\nこのカードが特殊召喚に成功した時、特殊召喚するために除外したモンスターの属性により、以下の効果を発動できる。\nこの効果を発動するターン、自分はバトルフェイズを行えない。\n●光：フィールド上のモンスター１体を選択して除外する。\n●闇：相手の手札をランダムに１枚選び、相手のエンドフェイズ時まで裏側表示で除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カオス・ネクロマンサー",h:"かおす・ねくろまんさー",e:"Chaos Necromancer",ra:"SR",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカードの攻撃力は、自分の墓地に存在するモンスターカードの数×３００ポイントの数値になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カオス・ベトレイヤー",h:"かおす・べとれいやー",e:"Chaos Betrayer",ra:"SR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2000,de:2000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが墓地に存在する場合、自分の墓地から「カオス・ベトレイヤー」以外の光属性と闇属性のモンスターを１体ずつ除外して発動できる。\nこのカードを守備表示で特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。\n②：このカードが特殊召喚に成功した場合、相手の墓地のカード１枚を対象として発動できる。\nそのカードを除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カオス・マジシャン",h:"かおす・まじしゃん",e:"Chaos Command Magician",ra:"SR",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2400,de:1900,tx:"①：このカードがモンスターゾーンに存在する限り、このカード１枚のみを対象とするモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カオスエンドマスター",h:"かおすえんどますたー",e:"Chaos-End Master",ra:"N",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"戦士族",mo:["チューナー","効果"],ma:"",tp:"",at:1500,de:1000,tx:"このカードが戦闘によって相手モンスターを破壊し墓地へ送った時、デッキからレベル５以上で攻撃力１６００以下のモンスター１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"CNo.39 希望皇ホープレイ",h:"かおすなんばーず３９ きぼうおうほーぷれい",e:"Number C39: Utopia Ray",ra:"UR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2500,de:2000,tx:"光属性レベル４モンスター×３\nこのカードは自分フィールドの「No.39 希望皇ホープ」の上に重ねてX召喚する事もできる。\n①：このカードのX素材を１つ取り除いて発動できる。\nターン終了時までこのカードの攻撃力を５００アップし、相手フィールドのモンスター１体を選んでその攻撃力をターン終了時まで１０００ダウンする。\nこの効果は自分のLPが１０００以下の場合に発動と処理ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カオスハンター",h:"かおすはんたー",e:"Chaos Hunter",ra:"UR",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2500,de:1600,tx:"①：相手がモンスターの特殊召喚に成功した時、このカード以外の手札を１枚捨てて発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードがモンスターゾーンに存在する限り、相手はカードを除外できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カオスライダー グスタフ",h:"かおすらいだー　ぐすたふ",e:"Chaosrider Gustaph",ra:"N",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1400,de:1500,tx:"自分の墓地の魔法カードを２枚までゲームから除外する事ができる。\nこの効果によって除外したカード１枚につき、相手ターン終了時までこのカードの攻撃力は３００ポイントアップする。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガガガガール",h:"ががががーる",e:"Gagaga Girl",ra:"UR",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1000,de:800,tx:"①：自分フィールドの「ガガガマジシャン」１体を対象として発動できる。\nこのカードのレベルはそのモンスターと同じになる。\n②：このカードを含む「ガガガ」モンスターのみを素材としてX召喚したモンスターは以下の効果を得る。\n●このX召喚に成功した時、相手フィールドの特殊召喚されたモンスター１体を対象として発動できる。\nそのモンスターの攻撃力を０にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガガガカイザー",h:"がががかいざー",e:"Gagaga Caesar",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:600,tx:"このカードは自分フィールド上にこのカード以外の「ガガガ」と名のついたモンスターが存在する場合のみ攻撃できる。\nまた、１ターンに１度、自分の墓地のモンスター１体をゲームから除外して発動できる。\n自分フィールド上の全ての「ガガガ」と名のついたモンスターのレベルは、この効果を発動するためにゲームから除外したモンスターと同じレベルになる。\nこのカードはシンクロ素材にできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガガガガンマン",h:"ががががんまん",e:"Gagaga Cowboy",ra:"UR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1500,de:2400,tx:"レベル４モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\nこのカードの表示形式によって以下の効果を適用する。\n\n●攻撃表示：このターン、このカードが相手モンスターに攻撃するダメージステップの間、このカードの攻撃力は１０００アップし、その相手モンスターの攻撃力は５００ダウンする。\n\n●守備表示：相手に８００ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガガガキッド",h:"がががきっど",e:"Gagaga Child",ra:"SR",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:800,de:1200,tx:"自分フィールド上に「ガガガキッド」以外の「ガガガ」と名のついたモンスターが存在する場合、このカードは手札から特殊召喚できる。\nこの方法で特殊召喚に成功した時、自分フィールド上の「ガガガ」と名のついたモンスター１体を選択し、このカードのレベルを選択したモンスターと同じレベルにする事ができる。\nこの効果を発動するターン、自分はバトルフェイズを行えない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガガガクラーク",h:"がががくらーく",e:"Gagaga Clerk",ra:"R",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:400,de:800,tx:"①：自分フィールドに「ガガガクラーク」以外の「ガガガ」モンスターが存在する場合、このカードは手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガガガザムライ",h:"がががざむらい",e:"Gagaga Samurai",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1900,de:1600,tx:"レベル４モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除き、自分フィールドの「ガガガ」モンスター１体を対象として発動できる。\nこのターン、そのモンスターは１度のバトルフェイズ中に２回攻撃できる。\n②：このカード以外の自分のモンスターが攻撃対象に選択された時に発動できる。\nこのカードを表側守備表示にし、攻撃対象をこのカードに移し替えてダメージ計算を行う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガガガシスター",h:"がががしすたー",e:"Gagaga Sister",ra:"SR",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:200,de:800,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時に発動できる。\nデッキから「ガガガ」魔法・罠カード１枚を手札に加える。\n②：このカード以外の自分フィールドの「ガガガ」モンスター１体を対象として発動できる。\nそのモンスターとこのカードは、ターン終了時までその２体のレベルを合計したレベルになる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガガガヘッド",h:"がががへっど",e:"Gagaga Head",ra:"SR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2100,de:2000,tx:"①：相手フィールドにのみモンスターが存在する場合、このカードはリリースなしでレベル４モンスターとして召喚できる。\n②：このカードが召喚に成功した時、「ガガガヘッド」以外の自分の墓地の「ガガガ」モンスターを２体まで対象として発動できる。\nそのモンスターを特殊召喚する。\nこのターン自分は「ガガガ」モンスターのみを素材としたX召喚以外の特殊召喚ができない。\n③：フィールドのこのカードを素材としてX召喚したモンスターは以下の効果を得る。\n●このX召喚に成功した場合に発動する。\n自分はデッキから１枚ドローする。",li:2,lk:[],ta:[],al:[],ocg:true},
  {n:"ガガガマジシャン",h:"がががまじしゃん",e:"Gagaga Magician",ra:"UR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"このカードはS素材にできない。\n①：「ガガガマジシャン」は自分フィールドに１体しか表側表示で存在できない。\n②：１ターンに１度、１～８までの任意のレベルを宣言して発動できる。\nこのカードのレベルはターン終了時まで宣言したレベルになる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガガガマンサー",h:"がががまんさー",e:"Gagaga Mancer",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"①：１ターンに１度、「ガガガマンサー」以外の自分の墓地の「ガガガ」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は「ガガガ」モンスターしか特殊召喚できない。\n②：X素材のこのカードがXモンスターの効果を発動するために取り除かれ墓地へ送られた場合、自分フィールドのXモンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで５００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガガギゴ",h:"ががぎご",e:"Gagagigo",ra:"R",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["通常"],ma:"",tp:"",at:1850,de:1000,tx:"かつては邪悪な心を持っていたが、ある人物に出会う事で正義の心に目覚めた悪魔の若者。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"科学特殊兵",h:"かがくとくしゅへい",e:"Science Soldier",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:800,de:800,tx:"未知の生物に対抗するため、最新の科学兵器を装備した兵士。\n背中のコンテナにはさまざまな兵器が収納されている。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"鍵戦士キーマン",h:"かぎせんしきーまん",e:"Key Man the Key Warrior",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:500,tx:"１ターンに１度、自分のメインフェイズ時に発動する事ができる。\nこのターンのエンドフェイズ時まで、このカードのレベルは３になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"嚇灼の魔神",h:"かくしゃくのまじん",e:"Bonfire Colossus",ra:"R",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:2600,de:2200,tx:"自分フィールド上に炎属性モンスターが存在する場合、このカードは手札から特殊召喚できる。\nこのカードが特殊召喚に成功した時、自分フィールド上の炎属性モンスター２体を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"覚星師ライズベルト",h:"かくせいしらいずべると",e:"Risebell the Star Psycher",ra:"N",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1500,de:1500,tx:"１ターンに１度、フィールド上に表側表示で存在するモンスター１体を選択して発動できる。\n選択したモンスターのレベルを１つ上げる。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"覚醒戦士 クーフーリン",h:"かくせいせんし　くーふーりん",e:"CÚ Chulainn the Awakened",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["儀式","効果"],ma:"",tp:"",at:500,de:1000,tx:"「覚醒の証」により降臨。\n１ターンに１度、自分の墓地に存在する通常モンスター１体を選択して発動する事ができる。\n選択したモンスターをゲームから除外し、除外したその通常モンスターの攻撃力分だけ、次の自分のターンのスタンバイフェイズ時までこのカードの攻撃力をアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"覚醒の暗黒騎士ガイア",h:"かくせいのあんこくきしがいあ",e:"Arisen Gaia the Fierce Knight",ra:"SR",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2300,de:2100,tx:"「覚醒の暗黒騎士ガイア」の②の効果は１ターンに１度しか使用できない。\n①：相手フィールドのモンスターの数が自分フィールドのモンスターより多い場合、このカードはリリースなしで召喚できる。\n②：このカードがリリースされた場合に発動できる。\n自分の手札・墓地から「カオス・ソルジャー」モンスター１体を選んで特殊召喚する。\n③：「カオス・ソルジャー」モンスターの儀式召喚を行う場合、必要なレベル分のモンスターの内の１体として、墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"覚醒の勇士 ガガギゴ",h:"かくせいのゆうし　ががぎご",e:"Gagagigo the Risen",ra:"SR",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["エクシーズ"],ma:"",tp:"",at:2950,de:2800,tx:"レベル４モンスター×３",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カクタス",h:"かくたす",e:"Akihiron",ra:"N",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1700,de:1400,tx:"水中に潜んでいる、得体の知れない格好をしたばけもの。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"格闘戦士アルティメーター",h:"かくとうせんしあるてぃめーたー",e:"Battle Warrior",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:700,de:1000,tx:"武器をいっさい使わず、素手で戦いぬく格闘戦士。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"格闘ねずみ チュー助",h:"かくとうねずみ　ちゅーすけ",e:"Chu-Ske the Mouse Fighter",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:1200,de:0,tx:"ねずみ界最強の格闘家を目指して世界を放浪している熱血ねずみ。\nうかつに触ると火傷するぞ。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"影王デュークシェード",h:"かげおうでゅーくしぇーど",e:"Duke Shade, the Sinister Shadow Lord",ra:"UR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:500,de:2000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できず、このカードの効果を発動するターン、自分は闇属性モンスターしか特殊召喚できない。\n①：自分フィールドの闇属性モンスターを任意の数だけリリースして発動できる。\nこのカードを手札から特殊召喚する。\nこの効果で特殊召喚したこのカードの攻撃力はリリースしたモンスターの数×５００アップする。\n②：このカードが召喚・特殊召喚に成功した場合、自分の墓地のレベル５以上の闇属性モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カゲトカゲ",h:"かげとかげ",e:"Kagetokage",ra:"UR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1100,de:1500,tx:"このカードは通常召喚できず、このカードの効果で特殊召喚できる。\nこのカードはS素材にできない。\n①：自分がレベル４モンスターの召喚に成功した時に発動できる。\nこのカードを手札から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"影無茶ナイト",h:"かげむちゃないと",e:"Kagemucha Knight",ra:"SR",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"自分がレベル３モンスターの召喚に成功した時、このカードを手札から特殊召喚できる。\nこのカードはシンクロ素材にできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"影六武衆－キザル",h:"かげろくぶしゅう－きざる",e:"Secret Six Samurai - Kizaru",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1900,de:1000,tx:"①：このカードが特殊召喚に成功した時に発動できる。\n自分フィールドに存在する属性以外の「六武衆」モンスター１体をデッキから手札に加える。\n②：自分フィールドの「六武衆」モンスター１体のみが効果で破壊される場合、代わりに墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"影六武衆－ゲンバ",h:"かげろくぶしゅう－げんば",e:"Secret Six Samurai - Genba",ra:"N",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"戦士族",mo:["チューナー","効果"],ma:"",tp:"",at:500,de:2100,tx:"①：このカードが召喚に成功した時、除外されている自分の「六武衆」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\n②：自分フィールドの「六武衆」モンスター１体のみが効果で破壊される場合、代わりに墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"影六武衆－ドウジ",h:"かげろくぶしゅう－どうじ",e:"Secret Six Samurai - Doji",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:1200,tx:"①：このカードが既にモンスターゾーンに存在する状態で、自分フィールドにこのカード以外の「六武衆」モンスターが召喚・特殊召喚された時に発動できる。\nデッキから「六武衆」カード１枚を墓地へ送る。\n②：自分フィールドの「六武衆」モンスター１体のみが効果で破壊される場合、代わりに墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"影六武衆－ハツメ",h:"かげろくぶしゅう－はつめ",e:"Secret Six Samurai - Hatsume",ra:"N",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1500,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分の墓地及び自分フィールドの表側表示モンスターの中から、「六武衆」モンスター２体を除外し、「影六武衆－ハツメ」以外の自分の墓地の「六武衆」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n②：自分フィールドの「六武衆」モンスター１体のみが効果で破壊される場合、代わりに墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"影六武衆－フウマ",h:"かげろくぶしゅう－ふうま",e:"Secret Six Samurai - Fuma",ra:"R",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"戦士族",mo:["チューナー","効果"],ma:"",tp:"",at:200,de:1800,tx:"①：このカードが戦闘・効果で破壊された場合に発動できる。\nデッキから「影六武衆－フウマ」以外の「六武衆」モンスター１体を特殊召喚する。\n②：自分フィールドの「六武衆」モンスター１体のみが効果で破壊される場合、代わりに墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"影六武衆－リハン",h:"かげろくぶしゅう－りはん",e:"Secret Six Samurai - Rihan",ra:"SR",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2400,de:2400,tx:"属性が異なる「六武衆」モンスター×３\n自分フィールドの上記カードを墓地へ送った場合のみ、EXデッキから特殊召喚できる（「融合」は必要としない）。\nこのカードは融合素材にできない。\n①：１ターンに１度、手札及び自分フィールドの表側表示のカードの中から、「六武衆」カード１枚を除外し、フィールドのカード１枚を対象として発動できる。\nそのカードを除外する。\n②：自分フィールドの「六武衆」モンスターが戦闘・効果で破壊される場合、代わりに墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"化合獣オキシン・オックス",h:"かごうじゅうおきしん・おっくす",e:"Chemicritter Oxy Ox",ra:"SR",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"獣族",mo:["デュアル","効果"],ma:"",tp:"",at:0,de:2100,tx:"①：このカードはフィールド・墓地に存在する限り、通常モンスターとして扱う。\n②：フィールドの通常モンスター扱いのこのカードを通常召喚としてもう１度召喚できる。\nその場合このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●自分メインフェイズに発動できる。\n手札からデュアルモンスター１体を特殊召喚し、自分フィールドの全てのデュアルモンスターのレベルはターン終了時まで、この効果で特殊召喚したモンスターの元々のレベルと同じになる。\n「化合獣オキシン・オックス」のこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"化合獣カーボン・クラブ",h:"かごうじゅうかーぼん・くらぶ",e:"Chemicritter Carbo Crab",ra:"R",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"水族",mo:["デュアル","効果"],ma:"",tp:"",at:700,de:1400,tx:"①：このカードはフィールド・墓地に存在する限り、通常モンスターとして扱う。\n②：フィールドの通常モンスター扱いのこのカードを通常召喚としてもう１度召喚できる。\nその場合このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●自分メインフェイズに発動できる。\nデッキからデュアルモンスター１体を墓地へ送る。\nその後、デッキからデュアルモンスター１体を手札に加える。\nこのカード名のこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"化合獣ハイドロン・ホーク",h:"かごうじゅうはいどろん・ほーく",e:"Chemicritter Hydron Hawk",ra:"R",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"鳥獣族",mo:["デュアル","効果"],ma:"",tp:"",at:1400,de:700,tx:"①：このカードはフィールド・墓地に存在する限り、通常モンスターとして扱う。\n②：フィールドの通常モンスター扱いのこのカードを通常召喚としてもう１度召喚できる。\nその場合このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●手札を１枚捨て、自分の墓地のデュアルモンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\n「化合獣ハイドロン・ホーク」のこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"牙鮫帝シャーク・カイゼル",h:"がこうていしゃーく・かいぜる",e:"Shark Caesar",ra:"R",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"魚族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1800,de:2000,tx:"レベル３モンスター×３体以上（最大５体まで）\n１ターンに１度、このカードのエクシーズ素材を１つ取り除いて発動できる。\nこのカードにシャークカウンターを１つ置く。\nまた、このカードが戦闘を行うダメージステップの間、このカードの攻撃力はこのカードに乗っているシャークカウンターの数×１０００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"火口に潜む者",h:"かこうにひそむもの",e:"The Thing in the Crater",ra:"R",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:1000,de:1200,tx:"このカードが破壊されフィールド上から墓地に送られた時、手札から炎族モンスター１体を特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガジェット・アームズ",h:"がじぇっと・あーむず",e:"Gadget Arms",ra:"SR",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"機械族",mo:["リバース","効果"],ma:"",tp:"",at:200,de:400,tx:"リバース：自分の墓地に存在する「D」と名のついた魔法または罠カード１枚を選択し、手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガジェット・ソルジャー",h:"がじぇっと・そるじゃー",e:"Gadget Soldier",ra:"N",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1800,de:2000,tx:"戦うために造られた機械の戦士。\nさびない金属でできている。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガジェット・ドライバー",h:"がじぇっと・どらいばー",e:"Gadget Driver",ra:"N",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:200,de:200,tx:"このカードを手札から墓地へ送って発動する。\n自分フィールド上に表側表示で存在する「D」と名のついたモンスターを任意の数だけ選択して表示形式を変更する。\nこの効果は相手ターンでも発動する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガジェット・トレーラー",h:"がじぇっと・とれーらー",e:"Gadget Hauler",ra:"N",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1300,de:0,tx:"１ターンに１度、手札から「D」と名のついたモンスターを任意の枚数墓地へ送る事ができる。\nこの効果で墓地に送ったモンスター１体につき、このカードの攻撃力は８００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガスタ・イグル",h:"がすた・いぐる",e:"Gusto Egul",ra:"SR",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"鳥獣族",mo:["チューナー","効果"],ma:"",tp:"",at:200,de:400,tx:"このカードが戦闘によって破壊され墓地へ送られた時、デッキからチューナー以外のレベル４以下の「ガスタ」と名のついたモンスター１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガスタ・ガルド",h:"がすた・がるど",e:"Gusto Gulldo",ra:"R",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["チューナー","効果"],ma:"",tp:"",at:500,de:500,tx:"このカードがフィールド上から墓地へ送られた時、デッキからレベル２以下の「ガスタ」と名のついたモンスター１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガスタ・グリフ",h:"がすた・ぐりふ",e:"Gusto Griffin",ra:"R",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:800,de:300,tx:"このカードが手札から墓地へ送られた場合、デッキから「ガスタ」と名のついたモンスター１体を特殊召喚できる。\n「ガスタ・グリフ」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガスタ・コドル",h:"がすた・こどる",e:"Gusto Codor",ra:"N",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1000,de:400,tx:"このカードが戦闘によって相手モンスターを破壊し墓地へ送った時、自分のデッキから守備力１５００以下のサイキック族・風属性モンスター１体を特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガスタ・サンボルト",h:"がすた・さんぼると",e:"Gusto Thunbolt",ra:"R",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:1500,de:1200,tx:"このカードが戦闘によって破壊され墓地へ送られた場合、そのバトルフェイズ終了時に自分の墓地の「ガスタ」と名のついたモンスター１体をゲームから除外して発動できる。\nデッキから守備力１５００以下のサイキック族・風属性モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガスタ・スクイレル",h:"がすた・すくいれる",e:"Gusto Squirro",ra:"R",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"雷族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:1800,tx:"このカードがカードの効果によって破壊され墓地へ送られた時、デッキからレベル５以上の「ガスタ」と名のついたモンスター１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガスタ・ファルコ",h:"がすた・ふぁるこ",e:"Gusto Falco",ra:"R",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"鳥獣族",mo:["チューナー","効果"],ma:"",tp:"",at:600,de:1400,tx:"フィールド上に表側表示で存在するこのカードが戦闘以外によって墓地へ送られた時、デッキから「ガスタ」と名のついたモンスター１体を裏側守備表示で特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガスタの希望 カムイ",h:"がすたのきぼう　かむい",e:"Kamui, Hope of Gusto",ra:"R",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"サイキック族",mo:["リバース","効果"],ma:"",tp:"",at:200,de:1000,tx:"リバース：デッキから「ガスタ」と名のついたチューナー１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガスタの賢者 ウィンダール",h:"がすたのけんじゃ　うぃんだーる",e:"Windaar, Sage of Gusto",ra:"N",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:2000,de:1000,tx:"このカードが戦闘によってモンスターを破壊し墓地へ送った時、自分の墓地からレベル３以下の「ガスタ」と名のついたモンスター１体を選択し、表側守備表示で特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガスタの疾風 リーズ",h:"がすたのしっぷう　りーず",e:"Reeze, Whirlwind of Gusto",ra:"R",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1900,de:1400,tx:"１ターンに１度、手札を１枚デッキの一番下に戻し、相手フィールド上のモンスター１体と自分フィールド上の「ガスタ」と名のついたモンスター１体を選択して発動できる。\n選択したモンスターのコントロールを入れ替える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガスタの神裔 ピリカ",h:"がすたのしんえい　ぴりか",e:"Pilica, Descendant of Gusto",ra:"UR",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1000,de:1500,tx:"このカードが召喚・特殊召喚に成功した時、自分の墓地から風属性のチューナー１体を選択して表側守備表示で特殊召喚できる。\nこの効果で特殊召喚したモンスターの効果は無効化される。\nこの効果を発動したターン、自分は風属性以外のモンスターを特殊召喚できない。\n「ガスタの神裔 ピリカ」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガスタの神官 ムスト",h:"がすたのしんかん　むすと",e:"Musto, Oracle of Gusto",ra:"N",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1800,de:900,tx:"１ターンに１度、自分のメインフェイズ時に発動できる。\n自分の墓地の「ガスタ」と名のついたモンスター１体を選択してデッキに戻し、フィールド上に表側表示で存在するモンスター１体を選択してその効果をエンドフェイズ時まで無効にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガスタの静寂 カーム",h:"がすたのせいじゃく　かーむ",e:"Caam, Serenity of Gusto",ra:"N",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1700,de:1100,tx:"１ターンに１度、自分の墓地の「ガスタ」と名のついたモンスター２体をデッキに戻して発動できる。\nデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガスタの巫女 ウィンダ",h:"がすたのみこ　うぃんだ",e:"Winda, Priestess of Gusto",ra:"R",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1000,de:400,tx:"このカードが相手モンスターの攻撃によって破壊され墓地へ送られた時、デッキから「ガスタ」と名のついたチューナー１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"霞の谷の大怪鳥",h:"かすみのたにのだいかいちょう",e:"Roc from the Valley of Haze",ra:"R",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2400,de:1400,tx:"このカードが手札から墓地に送られた時、このカードをデッキに加えてシャッフルする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"風の精霊",h:"かぜのせいれい",e:"Spirit of the Winds",ra:"R",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:1700,de:1400,tx:"気ままに飛び回る風の精霊。\n機嫌が悪いと嵐になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"風の精霊 ガルーダ",h:"かぜのせいれい　がるーだ",e:"Garuda the Wind Spirit",ra:"R",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1600,de:1200,tx:"このカードは通常召喚できない。\n自分の墓地から風属性モンスター１体を除外した場合に特殊召喚できる。\n①：相手エンドフェイズに、相手フィールドの表側表示モンスター１体を対象として発動できる。\nその相手の表側表示モンスターの表示形式を変更する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"風の番人 ジン",h:"かぜのばんにん　じん",e:"Djinn the Watcher of the Wind",ra:"N",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:700,de:900,tx:"風をあやつり、竜巻や突風を起こし周囲のものを吹き飛ばす。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カタストルの影霊衣",h:"かたすとるのねくろす",e:"Nekroz of Catastor",ra:"R",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"ドラゴン族",mo:["儀式","効果"],ma:"",tp:"",at:2200,de:1200,tx:"「影霊衣」儀式魔法カードにより降臨。\n「カタストルの影霊衣」以外のモンスターのみを使用した儀式召喚でしか特殊召喚できない。\n「カタストルの影霊衣」の①の効果は１ターンに１度しか使用できない。\n①：このカードを手札から捨て、自分の墓地の「影霊衣」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n②：自分の「影霊衣」モンスターがエクストラデッキから特殊召喚されたモンスターと戦闘を行うダメージステップ開始時に発動する。\nそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"刀神－不知火",h:"かたながみ－しらぬい",e:"Shiranui Samuraisaga",ra:"N",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"アンデット族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:0,tx:"アンデット族チューナー＋チューナー以外のアンデット族モンスター１体以上\n自分は「刀神－不知火」を１ターンに１度しか特殊召喚できない。\n①：１ターンに１度、除外されている自分のアンデット族モンスター１体を対象として発動できる。\nそのモンスターをデッキに戻し、その攻撃力以下の攻撃力を持つ相手フィールドのモンスターを全て守備表示にする。\nこの効果は相手ターンでも発動できる。\n②：このカードが除外された場合、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターの攻撃力は５００ダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カタパルト・タートル",h:"かたぱると・たーとる",e:"Catapult Turtle",ra:"SR",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1000,de:2000,tx:"１ターンに１度、自分フィールド上のモンスター１体をリリースして発動できる。\nリリースしたモンスターの攻撃力の半分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガチガチガンテツ",h:"がちがちがんてつ",e:"Gachi Gachi Gantetsu",ra:"SR",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"岩石族",mo:["エクシーズ","効果"],ma:"",tp:"",at:500,de:1800,tx:"レベル２モンスター×２\nフィールド上のこのカードが破壊される場合、代わりにこのカードのエクシーズ素材を１つ取り除く事ができる。\nこのカードがフィールド上に表側表示で存在する限り、自分フィールド上のモンスターの攻撃力・守備力は、このカードのエクシーズ素材の数×２００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カチコチドラゴン",h:"かちこちどらごん",e:"Kachi Kochi Dragon",ra:"UR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2100,de:1300,tx:"レベル４モンスター×２\nこのカードが戦闘によって相手モンスターを破壊し墓地へ送った時、このカードのエクシーズ素材を１つ取り除く事で、もう１度だけ続けて攻撃する事ができる。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"霞鳥クラウソラス",h:"かちょうくらうそらす",e:"Mist Bird Clausolas",ra:"R",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["シンクロ","効果"],ma:"",tp:"",at:0,de:2300,tx:"チューナー＋チューナー以外のモンスター１体以上\n１ターンに１度、相手フィールド上に表側表示で存在するモンスター１体を選択して発動できる。\nターン終了時まで選択したモンスターの攻撃力を０にし、その効果を無効にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"合神竜ティマイオス",h:"がっしんりゅうてぃまいおす",e:"Timaeus the Knight of Destiny",ra:"R",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:"?",de:"?",tx:"「伝説の騎士 ティマイオス」＋「伝説の騎士 クリティウス」＋「伝説の騎士 ヘルモス」\n自分フィールドの上記カードを墓地へ送った場合のみ特殊召喚できる（「融合」は必要としない）。\n①：このカードは他のカードの効果を受けない。\n②：このカードが戦闘を行うダメージ計算時に発動できる。\nこのカードの攻撃力・守備力は、フィールドのモンスターの一番高い攻撃力と同じになる。\n③：このカードが戦闘で破壊された時に発動できる。\n自分の手札・デッキ・墓地の「伝説の騎士」モンスター３体を選び、召喚条件を無視して特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カッター・シャーク",h:"かったー・しゃーく",e:"Buzzsaw Shark",ra:"SR",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1600,de:500,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分フィールドの水属性モンスター１体を対象として発動できる。\nそのモンスターと同じレベルでカード名が異なる魚族モンスター１体をデッキから守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターは、このターン効果を発動できない。\nこの効果の発動後、ターン終了時まで自分はXモンスターしかEXデッキから特殊召喚できない。\n②：このカードを水属性モンスターのX召喚に使用する場合、このカードのレベルを３または５として扱う事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カッター・ロボ",h:"かったー・ろぼ",e:"Yaiba Robo",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1000,de:1300,tx:"四角い体に刃物を隠し持ち、なんでもかんでも切り刻む機械。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カトブレパスと運命の魔女",h:"かとぶれぱすとうんめいのまじょ",e:"Catoblepas and the Witch of Fate",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1800,de:0,tx:"相手がモンスターの特殊召喚に成功した時、自分の墓地に存在する攻撃力が？のモンスター１体をゲームから除外する事で、その特殊召喚したモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガトリング・ドラゴン",h:"がとりんぐ・どらごん",e:"Gatling Dragon",ra:"R",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:2600,de:1200,tx:"「リボルバー・ドラゴン」＋「ブローバック・ドラゴン」\nコイントスを３回行う。\n表が出た数だけ、フィールド上のモンスターを破壊する。\nこの効果は１ターンに１度だけ自分のメインフェイズに使用する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガトリングバギー",h:"がとりんぐばぎー",e:"Overdrive",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1600,de:1500,tx:"重機関銃装備の装甲車。\nどんな荒れ地も平気で走る事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カニカブト",h:"かにかぶと",e:"Kanikabuto",ra:"N",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:650,de:900,tx:"カニのモンスター。\n両手の大きなハサミで相手を切り刻む。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガニグモ",h:"がにぐも",e:"Ganigumo",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"昆虫族",mo:["通常"],ma:"",tp:"",at:600,de:800,tx:"はさみを持つクモ。\n相手を糸で動きを封じ、カニばさみでしとめる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カバリスト",h:"かばりすと",e:"Doctor Cranium",ra:"R",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"このカードが戦闘によって破壊され墓地へ送られた時、８００ライフポイントを払う事で自分のデッキからサイキック族モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カブキ・ドラゴン",h:"かぶき・どらごん",e:"Kabuki Dragon",ra:"R",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2300,de:2100,tx:"①：１ターンに１度、自分のモンスターが相手のモンスターに攻撃したダメージ計算前に発動できる。\nその相手のモンスターの表示形式を変更する。\n②：１ターンに１度、相手のモンスターが自分のモンスターに攻撃したダメージ計算前に発動できる。\nその自分のモンスターの表示形式を変更する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カブトロン",h:"かぶとろん",e:"Beetron",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1600,de:900,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：自分フィールドの表側表示の魔法・罠カード１枚を墓地へ送り、自分の墓地のレベル４以下の昆虫族モンスター１体を対象として発動できる。\nその昆虫族モンスターを守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガベージコレクター",h:"がべーじこれくたー",e:"Garbage Collector",ra:"N",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:100,de:1900,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカード以外の自分フィールドのサイバース族モンスター１体を対象として発動できる。\nそのモンスターを持ち主の手札に戻し、手札に戻ったモンスターと同じレベルでカード名が異なるサイバース族モンスター１体をデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"可変機獣 ガンナードラゴン",h:"かへんきじゅう　がんなーどらごん",e:"Fusilier Dragon, the Dual-Mode Beast",ra:"SR",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2800,de:2000,tx:"①：このカードはリリースなしで通常召喚できる。\n②：このカードの①の方法で通常召喚したこのカードの元々の攻撃力・守備力は半分になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カボチャの馬車",h:"かぼちゃのばしゃ",e:"Pumpkin Carriage",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:0,de:300,tx:"①：このカードがモンスターゾーンに存在する限り、自分の「シンデレラ」は直接攻撃できる。\n②：このカードがモンスターゾーンに存在する限り、自分フィールドの「シュトロームベルクの金の城」は効果では破壊されず、相手の効果の対象にならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カマキラー",h:"かまきらー",e:"Kamakiriman",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["通常"],ma:"",tp:"",at:1150,de:1400,tx:"二本のカマで相手に襲いかかる、人型のカマキリモンスター。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カミソーリトカゲ",h:"かみそーりとかげ",e:"Razor Lizard",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1500,de:300,tx:"自分フィールド上にこのカード以外の爬虫類族モンスターが存在する限り、このカードが裏側守備表示のモンスターを攻撃した場合、ダメージ計算を行わず裏側守備表示のままそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"雷ウナギ",h:"かみなりうなぎ",e:"Lightning Conger",ra:"N",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"雷族",mo:["通常"],ma:"",tp:"",at:350,de:750,tx:"電気ウナギが進化すると雷ウナギになると言い伝えられている。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"雷仙人",h:"かみなりせんにん",e:"The Immortal of Thunder",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"雷族",mo:["リバース","効果"],ma:"",tp:"",at:1500,de:1300,tx:"リバース：３０００ライフポイント回復する。\nこのカードがフィールド上から墓地に送られた時、５０００ライフポイントを失う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"瓶亀",h:"かめがめ",e:"Jar Turtle",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:200,de:2100,tx:"このカードがフィールド上に表側表示で存在する限り、「強欲な瓶」が発動する度に自分のデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"仮面呪術師カースド・ギュラ",h:"かめんじゅじゅつしかーすど・ぎゅら",e:"Grand Tiki Elder",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1500,de:800,tx:"呪いの呪文で相手を念殺する、仮面モンスター。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"仮面道化",h:"かめんどうけ",e:"Masked Clown",ra:"N",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:500,de:700,tx:"死のおどりを踊りながら、手にするカマで敵を切りきざむ戦士。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"仮面魔獣デス・ガーディウス",h:"かめんまじゅうです・がーでぃうす",e:"Masked Beast Des Gardius",ra:"SR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"悪魔族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3300,de:2500,tx:"このカードは通常召喚できない。\n「仮面呪術師カースド・ギュラ」「メルキド四面獣」の内いずれかを含む自分フィールドのモンスター２体をリリースした場合に特殊召喚できる。\n①：このカードがフィールドから墓地へ送られた場合、相手フィールドの表側表示モンスター１体を対象として発動する。\nデッキから「遺言の仮面」１枚を装備カード扱いとして対象のモンスターに装備する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"仮面魔獣マスクド・ヘルレイザー",h:"かめんまじゅうますくど・へるれいざー",e:"The Masked Beast",ra:"SR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"悪魔族",mo:["儀式"],ma:"",tp:"",at:3200,de:1800,tx:"「仮面魔獣の儀式」により降臨。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"仮面魔道士",h:"かめんまどうし",e:"Masked Sorcerer",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:900,de:1400,tx:"このカードが相手ライフに戦闘ダメージを与えた時、自分のデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カメンレオン",h:"かめんれおん",e:"Masked Chameleon",ra:"UR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["チューナー","効果"],ma:"",tp:"",at:1600,de:1100,tx:"このカードは自分フィールドにレベル５以上のモンスターが存在しない場合のみ召喚できる。\nこのカードの効果を発動するターン、自分はエクストラデッキからの特殊召喚及びこのカードの効果でしか特殊召喚できない。\n①：このカードが召喚に成功した時、自分の墓地の守備力０のモンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"寡黙なるサイコプリースト",h:"かもくなるさいこぷりーすと",e:"Hushed Psychic Cleric",ra:"SR",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:0,de:2100,tx:"このカードは召喚・反転召喚に成功した時、守備表示になる。\n１ターンに１度、手札を１枚墓地へ送る事で、自分の墓地に存在するサイキック族モンスター１体を選択してゲームから除外する。\nこのカードがフィールド上から墓地へ送られた時、このカードの効果で除外したモンスター１体を選択して特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"廃品眼の太鼓竜",h:"がらくたーあいず・ふぁっと・どらごん",e:"Googly-Eyes Drum Dragon",ra:"R",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:3000,de:2500,tx:"機械族レベル８モンスター×２\n１ターンに１度、このカードのエクシーズ素材を１つ取り除いて発動できる。\nこのカードの攻撃力は次の相手のエンドフェイズ時まで１０００ポイントアップする。\nエクシーズ素材を持っているこのカードが破壊された場合、自分の墓地の「SDロボ」と名のついたモンスター１体をゲームから除外する事で、このカードを墓地から特殊召喚する。\nその後、自分の墓地の「SDロボ」と名のついたモンスター１体を選んで、このカードの下に重ねてエクシーズ素材とする事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カラクリ蜘蛛",h:"からくりぐも",e:"Karakuri Spider",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:400,de:500,tx:"このカードの攻撃を受けたモンスターが闇属性だった場合、そのモンスターを破壊する。\nダメージ計算は適用する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カラクリ小町 弐弐四",h:"からくりこまち　ににし",e:"Karakuri Komachi mdl 224 \"Ninishi\"",ra:"SR",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:1900,tx:"このカードは攻撃可能な場合には攻撃しなければならない。\nフィールド上に表側表示で存在するこのカードが攻撃対象に選択された時、このカードの表示形式を変更する。\nこのカードがフィールド上に表側表示で存在する限り、自分のメインフェイズ時に１度だけ、自分は通常召喚に加えて「カラクリ」と名のついたモンスター１体を召喚する事ができる。",li:2,lk:[],ta:[],al:[],ocg:true},
  {n:"カラクリ参謀 弐四八",h:"からくりさんぼう　にしぱち",e:"Karakuri Strategist mdl 248 \"Nishipachi\"",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:500,de:1600,tx:"このカードは攻撃可能な場合には攻撃しなければならない。\nフィールド上に表側攻撃表示で存在するこのカードが攻撃対象に選択された時、このカードの表示形式を守備表示にする。\nこのカードが召喚・特殊召喚に成功した時、フィールド上に存在するモンスター１体の表示形式を変更する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カラクリ守衛 参壱参",h:"からくりしゅえい　さいざん",e:"Karakuri Watchdog mdl 313 \"Saizan\"",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:600,de:1800,tx:"このカードは攻撃可能な場合には攻撃しなければならない。\nフィールド上に表側表示で存在するこのカードが攻撃対象に選択された時、このカードの表示形式を変更する。\nこのカードの戦闘によって自分が戦闘ダメージを受けた時、自分フィールド上に表側表示で存在する「カラクリ」と名のついた全てのモンスターの攻撃力・守備力は、エンドフェイズ時まで８００ポイントアップする。\nまた、このカードはフィールド上に表側攻撃表示で存在する限り戦闘では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カラクリ将軍 無零",h:"からくりしょうぐん　ぶれい",e:"Karakuri Shogun mdl 00 \"Burei\"",ra:"UR",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:2600,de:1900,tx:"チューナー＋チューナー以外の機械族モンスター１体以上\nこのカードがシンクロ召喚に成功した時、自分のデッキから「カラクリ」と名のついたモンスター１体を特殊召喚する事ができる。\n１ターンに１度、フィールド上に存在するモンスター１体を選択し、表示形式を変更する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カラクリ商人 壱七七",h:"からくりしょうにん　いなしち",e:"Karakuri Merchant mdl 177 \"Inashichi\"",ra:"SR",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:500,de:1500,tx:"このカードは攻撃可能な場合には攻撃しなければならない。\nフィールド上に表側攻撃表示で存在するこのカードが攻撃対象に選択された時、このカードの表示形式を守備表示にする。\nこのカードが召喚に成功した時、自分のデッキから「カラクリ」と名のついたカード１枚を手札に加える。",li:1,lk:[],ta:[],al:[],ocg:true},
  {n:"カラクリ大将軍 無零怒",h:"からくりだいしょうぐん　ぶれいど",e:"Karakuri Steel Shogun mdl 00X \"Bureido\"",ra:"SR",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:2800,de:1700,tx:"チューナー＋チューナー以外の機械族モンスター１体以上\n①：このカードがS召喚に成功した時に発動できる。\nデッキから「カラクリ」モンスター１体を特殊召喚する。\n②：１ターンに１度、自分フィールドの表側表示の「カラクリ」モンスターの表示形式が変更された場合に発動する。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カラクリ樽 真九六",h:"からくりだる　しんくろー",e:"Karakuri Barrel mdl 96 \"Shinkuro\"",ra:"R",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:400,de:400,tx:"このカードは攻撃可能な場合には攻撃しなければならない。\nフィールド上に表側攻撃表示で存在するこのカードが攻撃対象に選択された時、このカードの表示形式を守備表示にする。\nこのカードは１ターンに１度だけ、戦闘では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カラクリ忍者 九壱九",h:"からくりにんじゃ　くいっく",e:"Karakuri Ninja mdl 919 \"Kuick\"",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1700,de:1500,tx:"このカードは攻撃可能な場合には攻撃しなければならない。\nフィールド上に表側表示で存在するこのカードが攻撃対象に選択された時、このカードの表示形式を変更する。\nこのカードが戦闘によって相手モンスターを破壊し墓地へ送った時、自分の墓地に存在するレベル４以下の「カラクリ」と名のついたモンスター１体を選択して表側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カラクリ忍者 参参九",h:"からくりにんじゃ　さざんく",e:"Karakuri Ninja mdl 339 \"Sazank\"",ra:"UR",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:1200,tx:"このカードは攻撃可能な場合には攻撃しなければならない。\nフィールド上に表側攻撃表示で存在するこのカードが攻撃対象に選択された時、このカードの表示形式を守備表示にする。\nこのカードがリバースした時、フィールド上に表側表示で存在するモンスター１体を選択して墓地へ送る。\nまた、このカードがリバースしたターン、このカードは相手プレイヤーに直接攻撃する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カラクリ忍者 七七四九",h:"からくりにんじゃ　ななしっく",e:"Karakuri Ninja mdl 7749 \"Nanashick\"",ra:"N",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2200,de:1800,tx:"このカードは攻撃可能な場合には攻撃しなければならない。\nフィールド上に表側表示で存在するこのカードが攻撃対象に選択された時、このカードの表示形式を変更する。\nこのカードが召喚に成功した時、自分フィールド上に表側守備表示で存在する「カラクリ」と名のついたモンスターの数だけ、自分のデッキからカードをドローする事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カラクリ兵 弐参六",h:"からくりへい　にさむ",e:"Karakuri Soldier mdl 236 \"Nisamu\"",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1400,de:200,tx:"このカードは攻撃可能な場合には攻撃しなければならない。\nフィールド上に表側攻撃表示で存在するこのカードが攻撃対象に選択された時、このカードの表示形式を守備表示にする。\nこのカードが戦闘によって破壊され墓地へ送られた時、自分のデッキからレベル４以下の「カラクリ」と名のついたモンスター１体を表側攻撃表示で特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カラクリ法師 九七六参",h:"からくりほうし　くなむざん",e:"Karakuri Bonze mdl 9763 \"Kunamzan\"",ra:"UR",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2000,de:1500,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが手札に存在する場合、自分フィールドの「カラクリ」モンスター１体を対象として発動できる。\nそのモンスターの表示形式を変更し、このカードをチューナー扱いとして特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は機械族・地属性モンスターしかEXデッキから特殊召喚できない。\n②：このカードは攻撃可能な場合には攻撃しなければならない。\n③：このカードが攻撃対象に選択された場合に発動する。\nこのカードの表示形式を変更する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カラクリ武者 六参壱八",h:"からくりむしゃ　むざんいちは",e:"Karakuri Bushi mdl 6318 \"Muzanichiha\"",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1800,de:600,tx:"このカードは攻撃可能な場合には攻撃しなければならない。\nフィールド上に表側攻撃表示で存在するこのカードが攻撃対象に選択された時、このカードの表示形式を守備表示にする。\nフィールド上に存在する「カラクリ」と名のついたモンスターが破壊された場合、このカードの攻撃力は４００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カラクリ無双 八壱八",h:"からくりむそう　はいぱー",e:"Karakuri Muso mdl 818 \"Haipa\"",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2100,de:1100,tx:"このカードは攻撃可能な場合には攻撃しなければならない。\nフィールド上に表側攻撃表示で存在するこのカードが攻撃対象に選択された時、このカードの表示形式を守備表示にする。\nこのカードは攻撃した場合、バトルフェイズ終了時に守備表示になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カラス天狗",h:"からすてんぐ",e:"Crow Goblin",ra:"N",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"鳥獣族",mo:["通常"],ma:"",tp:"",at:1850,de:1600,tx:"様々なことを知っている天狗。\n神通力が使えるという。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カラスの巨群",h:"からすのきょぐん",e:"Swarm of Crows",ra:"N",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1200,de:1800,tx:"このカードはデッキから特殊召喚できない。\nこのカードは１ターンに１度だけ裏側守備表示にする事ができる。\nこのカードが反転召喚に成功した時、相手の手札をランダムに１枚捨てる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カラテマン",h:"からてまん",e:"Karate Man",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"１ターンに１度、自分のメインフェイズ時に発動する事ができる。\nこのカードの攻撃力はエンドフェイズ時まで、元々の攻撃力を倍にした数値になる。\nこの効果を使用した場合、このカードはエンドフェイズ時に破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガルーザス",h:"がるーざす",e:"Garoozis",ra:"R",t:"monster",a:"炎",l:5,ps:"",pe:"",tr:"獣戦士族",mo:["通常"],ma:"",tp:"",at:1800,de:1500,tx:"竜の頭を持つ獣戦士。\nオノの攻撃はかなり強力。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガルマソード",h:"がるまそーど",e:"Garma Sword",ra:"N",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"戦士族",mo:["儀式"],ma:"",tp:"",at:2550,de:2150,tx:"「ガルマソードの誓い」により降臨。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"火霊使いヒータ",h:"かれいつかいひーた",e:"Hiita the Fire Charmer",ra:"R",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["リバース","効果"],ma:"",tp:"",at:500,de:1500,tx:"①：このカードがリバースした場合、相手フィールドの炎属性モンスター１体を対象として発動する。\nこのモンスターが表側表示で存在する間、そのモンスターのコントロールを得る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"華麗なる密偵－C",h:"かれいなるすぱい－しー",e:"Spy-C-Spy",ra:"SR",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:1300,tx:"①：このカードが召喚に成功した場合に発動する。\n相手のエクストラデッキからランダムに１枚確認する。\n攻撃力２０００以上のモンスターだった場合、このカードの攻撃力は１０００アップする。\n攻撃力２０００未満のモンスターだった場合、その攻撃力分だけ自分のLPを回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"華麗なる潜入工作員",h:"かれいなるせんにゅうこうさくいん",e:"Tactical Espionage Expert",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1300,de:1200,tx:"このカードが召喚に成功した時、罠カードを発動する事はできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"間炎星－コウカンショウ",h:"かんえんせい－こうかんしょう",e:"Brotherhood of the Fire Fist - Cardinal",ra:"R",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1800,de:2200,tx:"「炎星」と名のついたレベル４モンスター×２\nこのカードのエクシーズ素材を２つ取り除いて発動できる。\n自分のフィールド上・墓地の「炎星」または「炎舞」と名のついたカード合計２枚と、相手の墓地または相手フィールド上に表側表示で存在するカード合計２枚を選択して持ち主のデッキに戻す。\n「間炎星－コウカンショウ」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"貫ガエル",h:"かんがえる",e:"Unifrog",ra:"R",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:400,de:400,tx:"このカードは相手プレイヤーに直接攻撃できる。\nこのカードが直接攻撃によって相手ライフに戦闘ダメージを与えた時、自分フィールド上に「貫ガエル」以外の「ガエル」と名のついたモンスターが存在する場合、相手フィールド上の魔法・罠カード１枚を選択して破壊できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カンガルー・チャンプ",h:"かんがるー・ちゃんぷ",e:"Kangaroo Champ",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:800,de:700,tx:"このカードと戦闘を行ったモンスターは、ダメージ計算後に守備表示になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"岩窟魔人オーガ・ロック",h:"がんくつまじんおーが・ろっく",e:"Rock Ogre Grotto #1",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["通常"],ma:"",tp:"",at:800,de:1200,tx:"体が岩のため守備は高い。\n太い腕のひと振りに注意。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"岩石カメッター",h:"がんせきかめったー",e:"Boulder Tortoise",ra:"N",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1450,de:2200,tx:"全身が岩石でできているカメ。\n非常に高い守備が特徴。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"岩石の巨兵",h:"がんせきのきょへい",e:"Giant Soldier of Stone",ra:"SR",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["通常"],ma:"",tp:"",at:1300,de:2000,tx:"岩石の巨人兵。\n太い腕の攻撃は大地をゆるがす。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"岩石の精霊",h:"がんせきのせいれい",e:"Rock Spirit",ra:"R",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:1650,de:1900,tx:"はにわみたいだが岩石の精霊。\n攻撃・守備ともにかなり強い。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"岩石の番兵",h:"がんせきのばんぺい",e:"Sentry Soldier of Stone",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1300,de:2000,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが墓地に存在し、自分フィールドのモンスターが岩石族モンスターのみの場合に発動できる。\nこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カンツウツボ",h:"かんつうつぼ",e:"Piercing Moray",ra:"SR",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1500,de:500,tx:"１ターンに１度、自分のメインフェイズ時にこのカード以外の自分フィールド上の魚族・海竜族・水族モンスター１体をリリースして発動できる。\nこのカードの攻撃力は６００ポイントアップする。\nまた、このカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガントレット・ウォリアー",h:"がんとれっと・うぉりあー",e:"Gauntlet Warrior",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:400,de:1600,tx:"このカードをリリースして発動する。\n自分フィールド上に表側表示で存在する全ての戦士族モンスターの攻撃力・守備力は５００ポイントアップし、そのモンスターが戦闘を行ったダメージステップ終了時まで適用される。\nこの効果は相手ターンでも発動する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガンバラナイト",h:"がんばらないと",e:"Ganbara Knight",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:0,de:1800,tx:"フィールド上に表側攻撃表示で存在するこのカードが攻撃対象に選択された時、このカードの表示形式を守備表示にできる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガンバランサー",h:"がんばらんさー",e:"Ganbara Lancer",ra:"N",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:2000,tx:"このカードが召喚に成功した時、自分の手札・墓地から「ガンバランサー」１体を選んで表側守備表示で特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"管魔人メロメロメロディ",h:"かんまじんめろめろめろでぃ",e:"Melomelody the Brass Djinn",ra:"SR",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"悪魔族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1400,de:1600,tx:"レベル３モンスター×２\n１ターンに１度、このカードのエクシーズ素材を１つ取り除き、自分フィールド上の「魔人」と名のついたエクシーズモンスター１体を選択して発動できる。\nこのターン、選択したモンスターは１度のバトルフェイズ中に２回攻撃する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"冠を戴く蒼き翼",h:"かんむりをいただくあおきつばさ",e:"Blue-Winged Crown",ra:"N",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["通常"],ma:"",tp:"",at:1600,de:1200,tx:"頭の毛が冠のように見える、青白く燃えるトリ。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガンロック",h:"がんろっく",e:"Barrel Rock",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["通常"],ma:"",tp:"",at:1000,de:1300,tx:"両肩についているマシンガンを乱射しながら体当たりをする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ギアギアーセナル",h:"ぎあぎあーせなる",e:"Geargiarsenal",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1500,de:500,tx:"このカードの攻撃力は、自分フィールド上の「ギアギア」と名のついたモンスターの数×２００ポイントアップする。\nまた、このカードをリリースして発動できる。\nデッキから「ギアギアーセナル」以外の「ギアギア」と名のついたモンスター１体を表側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ギアギアーノ",h:"ぎあぎあーの",e:"Geargiano",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:500,de:1000,tx:"このカードをリリースし、自分の墓地の機械族・レベル４モンスター１体を選択して発動できる。\n選択したモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ギアギアーノ Mk－II",h:"ぎあぎあーの　Ｍｋ－Ⅱ",e:"Geargiano Mk-II",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:500,tx:"このカードが召喚・反転召喚に成功した時、自分の手札・墓地から「ギアギア」と名のついたモンスター１体を選んで表側守備表示で特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ギアギアーノ Mk－Ⅲ",h:"ぎあぎあーの　Ｍｋ－Ⅲ",e:"Geargiano Mk-III",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードが「ギアギア」と名のついたカードの効果によって特殊召喚に成功した時、自分の手札・墓地から「ギアギアーノ Mk－Ⅲ」以外の「ギアギア」と名のついたモンスター１体を選んで表側守備表示で特殊召喚できる。\nこの効果で特殊召喚したモンスターの効果は無効化される。\n「ギアギアーノ Mk－Ⅲ」の効果は１ターンに１度しか使用できず、この効果を発動するターン、自分は「ギアギア」と名のついたモンスター以外のモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ギアギアーマー",h:"ぎあぎあーまー",e:"Geargiarmor",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1100,de:1900,tx:"このカードは１ターンに１度だけ裏側守備表示にする事ができる。\nこのカードがリバースした時、デッキから「ギアギアーマー」以外の「ギアギア」と名のついたモンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ギアギアギア XG",h:"ぎあぎあぎあ　くろすぎがんと",e:"Geargiagear Gigant XG",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2500,de:1300,tx:"レベル３モンスター×３\n自分フィールド上の機械族モンスターが戦闘を行うバトルステップ時、このカードのエクシーズ素材を１つ取り除いて発動できる。\n相手フィールド上に表側表示で存在するカードの効果はそのダメージステップ終了時まで無効になり、そのダメージステップ終了時まで相手は魔法・罠・効果モンスターの効果を発動できない。\nまた、このカードがフィールド上から離れた時、自分の墓地からこのカード以外の「ギアギア」と名のついたカード１枚を選択して手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ギアギアクセル",h:"ぎあぎあくせる",e:"Geargiaccelerator",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1400,de:800,tx:"自分フィールド上に「ギアギア」と名のついたモンスターが存在する場合、このカードは手札から表側守備表示で特殊召喚できる。\nまた、このカードがフィールド上から墓地へ送られた時、自分の墓地から「ギアギアクセル」以外の「ギアギア」と名のついたモンスター１体を選択して手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ギアギアタッカー",h:"ぎあぎあたっかー",e:"Geargiattacker",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1900,de:100,tx:"このカードは１ターンに１度だけ裏側守備表示にする事ができる。\nこのカードがリバースした時、このカード以外の自分フィールド上の「ギアギア」と名のついたモンスターの数まで、フィールド上の魔法・罠カードを選んで破壊できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ギアギアンカー",h:"ぎあぎあんかー",e:"Geargianchor",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1800,de:500,tx:"①：自分メインフェイズに発動できる。\nこのカードを裏側守備表示にする（１ターンに１度のみ）。\n②：このカードがリバースした場合に発動できる。\nこのカード以外の自分フィールドの「ギアギア」モンスターの数まで、フィールドのモンスターを選んで破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ギアギアングラー",h:"ぎあぎあんぐらー",e:"Geargiauger",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:500,de:500,tx:"①：このカードが召喚に成功した時に発動できる。\nデッキから「ギアギアングラー」以外の機械族・地属性・レベル４モンスター１体を手札に加える。\nこのターン、自分は攻撃宣言できず、機械族モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ギアギガント X",h:"ぎあぎがんと　くろす",e:"Gear Gigant X",ra:"UR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2300,de:1500,tx:"機械族レベル４モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\n自分のデッキ・墓地からレベル４以下の機械族モンスター１体を選んで手札に加える。\n②：表側表示のこのカードがフィールドから離れた時、自分の墓地のレベル３以下の「ギアギア」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"キーマウス",h:"きーまうす",e:"Key Mouse",ra:"R",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"獣族",mo:["チューナー","効果"],ma:"",tp:"",at:100,de:100,tx:"このカードが戦闘によって破壊され墓地へ送られた時、デッキからレベル３以下の獣族モンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"キーメイス",h:"きーめいす",e:"Key Mace",ra:"N",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:400,de:300,tx:"とても小さな天使。\nかわいらしさに負け、誰でも心を開いてしまう。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"キウイ・マジシャン・ガール",h:"きうい・まじしゃん・がーる",e:"Kiwi Magician Girl",ra:"N",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1800,de:1200,tx:"①：このカードを手札から捨てて発動できる。\n自分フィールドの「マジシャン・ガール」モンスターの攻撃力・守備力はターン終了時まで、お互いのフィールド・墓地の「マジシャン・ガール」モンスターの種類×３００アップする。\nこの効果は相手ターンでも発動できる。\n②：このカードがモンスターゾーンに存在する限り、自分フィールドの魔法使い族モンスターは効果では破壊されず、相手の効果の対象にならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"機炎星－ゴヨウテ",h:"きえんせい－ごようて",e:"Brotherhood of the Fire Fist - Coyote",ra:"R",t:"monster",a:"炎",l:5,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:2000,de:500,tx:"自分フィールド上に「炎舞」と名のついた魔法・罠カードが存在し、自分フィールド上にモンスターが存在しない場合、このカードは手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ギガ・ガガギゴ",h:"ぎが・ががぎご",e:"Giga Gagagigo",ra:"R",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"爬虫類族",mo:["通常"],ma:"",tp:"",at:2450,de:1500,tx:"強大な悪に立ち向かうため、様々な肉体改造をほどこした結果恐るべきパワーを手に入れたが、その代償として正義の心を失ってしまった。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"機械王",h:"きかいおう",e:"Machine King",ra:"R",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2200,de:2000,tx:"フィールド上に表側表示で存在する機械族モンスター１体につき、このカードの攻撃力は１００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"機械王－プロトタイプ",h:"きかいおう－ぷろとたいぷ",e:"Machine King Prototype",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1600,de:1500,tx:"フィールド上に存在するこのカード以外の機械族モンスター１体につき、このカードの攻撃力・守備力は１００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"機械軍曹",h:"きかいぐんそう",e:"Robotic Knight",ra:"N",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1600,de:1800,tx:"機械王に仕える機械族の司令塔。\n機械の兵隊を統率する攻撃的な用兵で有名。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"機械の巨兵",h:"きかいのきょへい",e:"Giant Mech-Soldier",ra:"N",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1750,de:1900,tx:"巨大なオノの攻撃は、大地が割れるほど強力な一撃だ。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"機械の兵隊",h:"きかいのへいたい",e:"Cyber Soldier",ra:"N",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1500,de:1700,tx:"機械王を警護する兵隊。\n丸い身体でゴロゴロ転がってくる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"機械竜 パワー・ツール",h:"きかいりゅう　ぱわー・つーる",e:"Power Tool Mecha Dragon",ra:"SR",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:2300,de:2500,tx:"チューナー＋チューナー以外のモンスター１体以上\n自分のターンにこのカードが装備魔法カードを装備した時、デッキからカードを１枚ドローできる。\n「機械竜 パワー・ツール」のこの効果は１ターンに１度しか使用できない。\nまた、１ターンに１度、フィールド上に表側表示で存在する装備カード１枚を選択し、正しい対象となるこのカードに移し替える事ができる。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"機海竜プレシオン",h:"きかいりゅうぷれしおん",e:"Mecha Sea Dragon Plesion",ra:"N",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2300,de:1800,tx:"自分フィールド上に海竜族モンスターが存在する場合、このカードはリリースなしで召喚できる。\n１ターンに１度、自分フィールド上の水属性モンスター１体をリリースする事で、相手フィールド上に表側表示で存在するカード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"鬼ガエル",h:"きがえる",e:"Swap Frog",ra:"UR",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1000,de:500,tx:"①：このカードは手札からこのカード以外の水属性モンスター１体を捨てて、手札から特殊召喚できる。\n②：このカードが召喚・反転召喚・特殊召喚に成功した時に発動できる。\nデッキ及び自分フィールドの表側表示モンスターの中から、レベル２以下の水族・水属性モンスター１体を選んで墓地へ送る。\n③：１ターンに１度、自分フィールドのモンスター１体を持ち主の手札に戻して発動できる。\nこのターン、自分は通常召喚に加えて１度だけ、自分メインフェイズに「鬼ガエル」以外の「ガエル」モンスター１体を召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ギガストーン・オメガ",h:"ぎがすとーん・おめが",e:"Gigastone Omega",ra:"R",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"岩石族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1000,de:2300,tx:"このカードは通常召喚できない。\n自分の墓地に存在する地属性モンスター２体をゲームから除外した場合に特殊召喚する事ができる。\n自分フィールド上に表側表示で存在するこのカードが、カードの効果によって墓地へ送られた時、相手フィールド上に存在する魔法・罠カードを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ギガテック・ウルフ",h:"ぎがてっく・うるふ",e:"Giga-Tech Wolf",ra:"R",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1200,de:1400,tx:"全身が鋼鉄でできたオオカミ。\n鋭くとがったキバでかみついてくる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ギガプラント",h:"ぎがぷらんと",e:"Gigaplant",ra:"SR",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"植物族",mo:["デュアル","効果"],ma:"",tp:"",at:2400,de:1200,tx:"①：このカードはフィールド・墓地に存在する限り、通常モンスターとして扱う。\n②：フィールドの通常モンスター扱いのこのカードを通常召喚としてもう１度召喚できる。\nその場合このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●１ターンに１度、自分メインフェイズに発動できる。\n自分の手札・墓地から昆虫族または植物族のモンスター１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"鬼岩城",h:"きがんじょう",e:"Giganticastle",ra:"R",t:"monster",a:"地",l:9,ps:"",pe:"",tr:"岩石族",mo:["シンクロ","効果"],ma:"",tp:"",at:2900,de:2800,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカードの攻撃力・守備力は、このカードのシンクロ素材としたチューナー以外のモンスターの数×２００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ギガンテス",h:"ぎがんてす",e:"Gigantes",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1900,de:1300,tx:"このカードは通常召喚できない。\n自分の墓地から地属性モンスター１体を除外した場合に特殊召喚できる。\n①：このカードが戦闘で破壊され墓地へ送られた場合に発動する。\nフィールドの魔法・罠カードを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ギガント",h:"ぎがんと",e:"Giganto",ra:"N",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1700,de:1800,tx:"スパイクのついた大きな鉄球を振り回し襲いかかってくる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ギガント・セファロタス",h:"ぎがんと・せふぁろたす",e:"Gigantic Cephalotus",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1850,de:700,tx:"このカードがフィールド上に表側表示で存在する限り、フィールド上に存在する植物族モンスターが墓地へ送られる度に、このカードの攻撃力は２００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"機巧嘴－八咫御先",h:"きこうし－やたのみさき",e:"Gizmek Yata, the Gleaming Vanguard",ra:"UR",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2050,de:2050,tx:"このカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードは自分フィールドの通常召喚されたモンスター１体をリリースして手札から特殊召喚できる。\n②：このカードが召喚・特殊召喚に成功したターンの自分メインフェイズに発動できる。\nモンスター１体を召喚する。\n自分はこの効果で召喚したターン、そのモンスターと元々の種族が同じモンスターしか特殊召喚できない。\n③：このカードの①の方法で特殊召喚したこのカードがリリースされた場合に発動する。\n自分は２０５０LP回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"輝光子パラディオス",h:"きこうしぱらでぃおす",e:"Starliege Paladynamo",ra:"UR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2000,de:1000,tx:"光属性レベル４モンスター×２\n①：１ターンに１度、このカードのX素材を２つ取り除き、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力を０にし、その効果を無効にする。\n②：フィールドのこのカードが相手によって破壊され墓地へ送られた場合に発動する。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"輝光帝ギャラクシオン",h:"きこうていぎゃらくしおん",e:"Starliege Lord Galaxion",ra:"UR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2000,de:2100,tx:"「フォトン」と名のついたレベル４モンスター×２\n１ターンに１度、このカードのエクシーズ素材を２つまで取り除いて発動できる。\nこの効果を発動するために取り除いたエクシーズ素材の数によって以下の効果を適用する。\n●１つ：手札から「銀河眼の光子竜」１体を特殊召喚する。\n●２つ：デッキから「銀河眼の光子竜」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"機皇帝グランエル∞",h:"きこうていぐらんえるいんふぃにてぃ",e:"Meklord Emperor Granel",ra:"UR",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"機械族",mo:["特殊召喚","効果"],ma:"",tp:"",at:0,de:0,tx:"このカードは通常召喚できず、自身の効果でのみ特殊召喚できる。\n①：自分フィールドの表側表示モンスターが効果で破壊され墓地へ送られた時に発動できる。\n手札のこのカードを特殊召喚する。\n②：１ターンに１度、相手フィールドのSモンスター１体を対象として発動できる。\nその相手Sモンスターをこのカードに装備する。\n③：この攻撃力・守備力は自分LPの半分アップし、攻撃力は自身の効果で装備したモンスターの攻撃力分アップする。\n④：自身の効果で装備したモンスター１体を対象として発動できる。\nそれを守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"機皇帝スキエル∞",h:"きこうていすきえるいんふぃにてぃ",e:"Meklord Emperor Skiel",ra:"UR",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"機械族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2200,de:2200,tx:"このカードは通常召喚できず、自身の効果でのみ特殊召喚できる。\n①：自分フィールドの表側表示モンスターが効果で破壊され墓地へ送られた時に発動できる。\n手札のこのカードを特殊召喚する。\n②：１ターンに１度、相手フィールドのSモンスター１体を対象として発動できる。\nその相手Sモンスターをこのカードに装備する。\n③：この攻撃力は自身の効果で装備したモンスターの攻撃力分アップし、他の自分のモンスターは攻撃宣言できない。\n④：自身が装備している自分のモンスター１体を墓地へ送って発動できる。\nこのターンこのカードは直接攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"機皇帝ワイゼル∞",h:"きこうていわいぜるいんふぃにてぃ",e:"Meklord Emperor Wisel",ra:"SR",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"機械族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2500,de:2500,tx:"このカードは通常召喚できず、自身の効果でのみ特殊召喚できる。\n①：自分フィールドの表側表示モンスターが効果で破壊され墓地へ送られた時に発動できる。\n手札のこのカードを特殊召喚する。\n②：１ターンに１度、相手フィールドのSモンスター１体を対象として発動できる。\nその相手Sモンスターをこのカードに装備する。\n③：この攻撃力は自身の効果で装備したモンスターの攻撃力分アップし、他の自分のモンスターは攻撃宣言できない。\n④：１ターンに１度、相手が魔法カードを発動した時に発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"機皇帝ワイゼル∞－S・アブソープション",h:"きこうていわいぜるいんふぃにてぃ－しんくろ・あぶそーぷしょん",e:"Meklord Emperor Wisel - Synchro Absorption",ra:"SR",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"機械族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2500,de:2500,tx:"このカードは通常召喚できず、このカードの効果でのみ特殊召喚できる。\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：相手ターンに、自分フィールドの表側表示の「機皇」モンスター１体を墓地へ送って発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが特殊召喚に成功した場合、相手フィールドのモンスター１体を対象として発動できる。\nこのターン、そのモンスターは攻撃できない。\n③：フィールドのカードを破壊する効果が発動した時、このカードをリリースして発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"機甲忍者アース",h:"きこうにんじゃあーす",e:"Earth Armor Ninja",ra:"SR",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"相手フィールド上にモンスターが存在し、自分フィールド上にモンスターが存在しない場合、このカードは手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"機甲忍者アクア",h:"きこうにんじゃあくあ",e:"Aqua Armor Ninja",ra:"R",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:800,de:1600,tx:"相手モンスターの直接攻撃宣言時、自分の墓地にこのカード以外の「忍者」と名のついたモンスターが存在する場合、墓地のこのカードをゲームから除外して発動できる。\n攻撃モンスター１体の攻撃を無効にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"機甲忍者エアー",h:"きこうにんじゃえあー",e:"Air Armor Ninja",ra:"N",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1400,de:1400,tx:"このカードが召喚・反転召喚・特殊召喚に成功した時、自分フィールド上の「忍者」と名のついたモンスター１体を選択して発動できる。\n選択したモンスターのレベルを１つ下げる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"機甲忍者フレイム",h:"きこうにんじゃふれいむ",e:"Flame Armor Ninja",ra:"N",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"このカードが召喚・反転召喚・特殊召喚に成功した時、自分フィールド上の「忍者」と名のついたモンスター１体を選択して発動できる。\n選択したモンスターのレベルを１つ上げる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"機甲忍者ブレード・ハート",h:"きこうにんじゃぶれーど・はーと",e:"Blade Armor Ninja",ra:"UR",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2200,de:1000,tx:"戦士族レベル４モンスター×２\n１ターンに１度、このカードのエクシーズ素材を１つ取り除き、自分フィールド上の「忍者」と名のついたモンスター１体を選択して発動できる。\nこのターン、選択したモンスターは１度のバトルフェイズ中に２回攻撃する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"機皇兵グランエル・アイン",h:"きこうへいぐらんえる・あいん",e:"Meklord Army of Granel",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"このカードの攻撃力は、このカード以外のフィールド上に表側表示で存在する「機皇」と名のついたモンスターの数×１００ポイントアップする。\nこのカードが召喚に成功した時、相手フィールド上に表側表示で存在するモンスター１体を選択し、そのモンスターの攻撃力をエンドフェイズ時まで半分にする事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"機皇兵廠オブリガード",h:"きこうへいしょうおぶりがーど",e:"Meklord Army Deployer Obbligato",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:1800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\nこのカードを破壊し、デッキから「機皇兵廠オブリガード」以外の「機皇兵」モンスター２体を守備表示で特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は機械族モンスターしか特殊召喚できない。\n②：このカードが墓地へ送られた場合に発動できる。\nこのターンのエンドフェイズに、自分フィールドの「機皇」モンスターの数×１００ダメージを相手に与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"機皇兵スキエル・アイン",h:"きこうへいすきえる・あいん",e:"Meklord Army of Skiel",ra:"R",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:1000,tx:"このカードの攻撃力は、このカード以外のフィールド上に表側表示で存在する「機皇」と名のついたモンスターの数×２００ポイントアップする。\nこのカードが戦闘によって破壊され墓地へ送られた時、自分のデッキから「機皇兵」と名のついたモンスター１体を特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"機皇兵ワイゼル・アイン",h:"きこうへいわいぜる・あいん",e:"Meklord Army of Wisel",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1800,de:0,tx:"①：このカードの攻撃力は、このカード以外のフィールドの「機皇」モンスターの数×１００アップする。\n②：１ターンに１度、このカード以外の自分の「機皇」モンスターが守備表示モンスターに攻撃宣言した時に発動できる。\nその戦闘でその自分のモンスターが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ相手に戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ギゴバイト",h:"ぎごばいと",e:"Gigobyte",ra:"N",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"爬虫類族",mo:["通常"],ma:"",tp:"",at:350,de:300,tx:"今はまだおだやかな心を持っているが、邪悪な心に染まる運命を背負っている・・・。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"儀式の供物",h:"ぎしきのくもつ",e:"Ritual Raven",ra:"R",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:300,de:300,tx:"闇属性の儀式モンスターを特殊召喚する場合、このカード１枚で儀式召喚のための生け贄として使用する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"儀式魔人カースエンチャンター",h:"ぎしきまじんかーすえんちゃんたー",e:"Djinn Cursenchanter of Rituals",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"儀式召喚を行う場合、その儀式召喚に必要なレベル分のモンスター１体として、自分の墓地のこのカードをゲームから除外できる。\nまた、このカードを儀式召喚に使用した儀式モンスターがフィールド上に表側表示で存在する限り、シンクロモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"儀式魔人ディザーズ",h:"ぎしきまじんでぃざーず",e:"Djinn Disserere of Rituals",ra:"SR",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:200,de:200,tx:"儀式モンスターの儀式召喚を行う場合、その儀式召喚に必要なレベル分のモンスター１体として、墓地に存在するこのカードをゲームから除外する事ができる。\nこのカードを儀式召喚に使用した儀式モンスターは罠カードの効果を受けない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"儀式魔人デモリッシャー",h:"ぎしきまじんでもりっしゃー",e:"Djinn Demolisher of Rituals",ra:"SR",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1500,de:600,tx:"①：儀式召喚を行う場合、その儀式召喚に必要なレベル分のモンスター１体として、墓地のこのカードを除外できる。\n②：このカードを使用して儀式召喚したモンスターは相手の効果の対象にならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"儀式魔人プレコグスター",h:"ぎしきまじんぷれこぐすたー",e:"Djinn Prognosticator of Rituals",ra:"SR",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:400,de:300,tx:"儀式召喚を行う場合、その儀式召喚に必要なレベル分のモンスター１体として、自分の墓地のこのカードをゲームから除外できる。\nまた、このカードを儀式召喚に使用した儀式モンスターが相手ライフに戦闘ダメージを与えた時、相手は手札を１枚選んで捨てる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"儀式魔人プレサイダー",h:"ぎしきまじんぷれさいだー",e:"Djinn Presider of Rituals",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1800,de:1400,tx:"儀式召喚を行う場合、その儀式召喚に必要なレベル分のモンスター１体として、自分の墓地のこのカードをゲームから除外できる。\nまた、このカードを儀式召喚に使用した儀式モンスターが戦闘によってモンスターを破壊した場合、その儀式モンスターのコントローラーはデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"奇術王 ムーン・スター",h:"きじゅつおう　むーん・すたー",e:"Magical King Moonstar",ra:"SR",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:900,de:600,tx:"このカードをS素材とする場合、闇属性モンスターのS召喚にしか使用できない。\n①：自分フィールドにチューナーが存在する場合、このカードは手札から特殊召喚できる。\n②：このカードが召喚・特殊召喚に成功した場合、自分のフィールド・墓地のモンスター１体を対象として発動できる。\nこのカードのレベルはターン終了時までそのモンスターと同じになる。\nこの効果の発動後、ターン終了時まで自分はS召喚以外の特殊召喚ができない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"輝神鳥ヴェーヌ",h:"きしんちょうう゛ぇーぬ",e:"Vennu, Bright Bird of Divinity",ra:"UR",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"天使族",mo:["儀式","効果"],ma:"",tp:"",at:2800,de:2000,tx:"「原初の叫喚」により降臨。\n①：１ターンに１度、手札のモンスター１体を相手に見せ、フィールドの表側表示モンスター１体を対象として発動できる。\nこのターン、対象のモンスターのレベルは、見せたモンスターと同じになる。\n②：１ターンに１度、このカード以外の自分の手札・フィールドのモンスターがリリースされた場合、自分の墓地のモンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"寄生体ダニー",h:"きせいたいだにー",e:"Parasitic Ticky",ra:"N",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:"?",de:"?",tx:"このカードの攻撃力・守備力は、フィールド上のトークンの数×５００ポイントの数値になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"寄生虫パラサイド",h:"きせいちゅうぱらさいど",e:"Parasite Paracide",ra:"R",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"昆虫族",mo:["リバース","効果"],ma:"",tp:"",at:500,de:300,tx:"リバース：このカードを相手のデッキに表向きで混ぜてシャッフルする。\n相手がこのカードをドローした時、このカードは相手フィールド上に表側守備表示で特殊召喚され、相手ライフに１０００ポイントダメージを与える。\nその後、このカードがフィールド上に表側表示で存在する限り、相手フィールド上に表側表示で存在するモンスターは全て昆虫族となる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"奇跡のジュラシック・エッグ",h:"きせきのじゅらしっく・えっぐ",e:"Miracle Jurassic Egg",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:0,de:2000,tx:"①：フィールドの表側表示のこのカードは除外できない。\n②：このカードがモンスターゾーンに存在する限り、恐竜族モンスターが自分の墓地へ送られる度に、このカードにカウンターを２つ置く。\n③：このカードをリリースして発動できる。\nこのカードに置かれていたカウンターの数以下のレベルを持つ恐竜族モンスター１体をデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"奇跡の代行者 ジュピター",h:"きせきのだいこうしゃ　じゅぴたー",e:"The Agent of Miracles - Jupiter",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"①：１ターンに１度、自分の墓地から「代行者」モンスター１体を除外し、自分フィールドの天使族・光属性モンスター１体を対象として発動できる。\nその自分の天使族・光属性モンスターの攻撃力はターン終了時まで８００アップする。\n②：１ターンに１度、手札から天使族モンスター１体を捨て、除外されている自分の天使族・光属性モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果はフィールドに「天空の聖域」が存在する場合に発動と処理ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"きつね火",h:"きつねび",e:"Fox Fire",ra:"R",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:300,de:200,tx:"フィールド上に表側表示で存在するこのカードが戦闘によって破壊され墓地へ送られたターンのエンドフェイズ時、このカードを墓地から特殊召喚する。\nこのカードはフィールド上に表側表示で存在する限り、アドバンス召喚のためにはリリースできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"奇動装置メイルファクター",h:"きどうそうちめいるふぁくたー",e:"Unauthorized Bootup Device",ra:"UR",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"機械族",mo:["ユニオン","効果"],ma:"",tp:"",at:500,de:0,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：１ターンに１度、以下の効果から１つを選択して発動できる。\n●自分フィールドの表側表示モンスター１体を対象とし、このカードを装備カード扱いとしてそのモンスターに装備する。\n装備モンスターが戦闘・効果で破壊される場合、代わりにこのカードを破壊する。\n●装備されているこのカードを特殊召喚する。\n②：モンスターに装備されているこのカードが破壊され墓地へ送られた場合に発動できる。\nこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"機動砦のギア・ゴーレム",h:"きどうとりでのぎあ・ごーれむ",e:"Gear Golem the Moving Fortress",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:800,de:2200,tx:"メインフェイズ１でのみ発動する事ができる。\n８００ライフポイントを払う。\nこのターンこのカードは相手プレイヤーに直接攻撃をする事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"起動兵士デッドリボルバー",h:"きどうへいしでっどりぼるばー",e:"Boot-Up Soldier - Dread Dynamo",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:2000,tx:"自分フィールド上に「ガジェット」と名のついたモンスターが表側表示で存在する限り、このカードの攻撃力は２０００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"機動要犀 トリケライナー",h:"きどうようさい　とりけらいなー",e:"Trifortressops",ra:"UR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1600,de:2800,tx:"①：相手が３体以上のモンスターの召喚・反転召喚・特殊召喚に成功したターンに発動できる。\nこのカードを手札から特殊召喚する。\nこの効果で特殊召喚したこのカードは、他のカードの効果を受けず、お互いのスタンバイフェイズ毎に守備力が５００ダウンする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"きのこマン",h:"きのこまん",e:"Mushroom Man",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"植物族",mo:["通常"],ma:"",tp:"",at:800,de:600,tx:"ジメジメした所で力を発揮！かさから菌糸を振りまき攻撃！",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"起爆獣ヴァルカノン",h:"きばくじゅうう゛ぁるかのん",e:"Ignition Beast Volcannon",ra:"N",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:2300,de:1600,tx:"機械族モンスター＋炎族モンスター\nこのカードが融合召喚に成功した時、相手フィールド上に存在するモンスター１体を選択して発動する事ができる。\n選択した相手モンスターとこのカードを破壊して墓地へ送る。\nその後、墓地へ送られた相手モンスターの攻撃力分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"希望皇オノマトピア",h:"きぼうおうおのまとぴあ",e:"Utopic Onomatopoeia",ra:"UR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:1500,tx:"このカード名はルール上「ズババ」、「ガガガ」、「ゴゴゴ」、「ドドド」カードとしても扱う。\nこのカード名の効果は１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\n手札から「希望皇オノマトピア」以外の以下のモンスターをそれぞれ１体まで守備表示で特殊召喚する。\nこの効果の発動後、ターン終了時まで自分はXモンスターしかEXデッキから特殊召喚できない。\n\n●「ズババ」モンスター\n●「ガガガ」モンスター\n●「ゴゴゴ」モンスター\n●「ドドド」モンスター",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"きまぐれの女神",h:"きまぐれのめがみ",e:"Goddess of Whim",ra:"R",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:950,de:700,tx:"①：１ターンに１度、自分メインフェイズに発動できる。\nコイントスを１回行い、裏表を当てる。\n当たった場合、このカードの攻撃力はターン終了時まで倍になる。\nハズレの場合、このカードの攻撃力はターン終了時まで半分になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ギミック・パペット－ギア・チェンジャー",h:"ぎみっく・ぱぺっと－ぎあ・ちぇんじゃー",e:"Gimmick Puppet Gear Changer",ra:"SR",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"このカードはデッキから特殊召喚できない。\n１ターンに１度、このカード以外の自分フィールド上の「ギミック・パペット」と名のついたモンスター１体を選択して発動できる。\nこのカードのレベルは選択したモンスターのレベルと同じになる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ギミック・パペット－ギガンテス・ドール",h:"ぎみっく・ぱぺっと－ぎがんてす・どーる",e:"Gimmick Puppet Gigantes Doll",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:0,de:2000,tx:"レベル４「ギミック・パペット」モンスター×２\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードのX素材を２つ取り除き、相手フィールドのモンスターを２体まで対象として発動できる。\nそのモンスターのコントロールをエンドフェイズまで得る。\nこの効果を発動したターン、自分は「ギミック・パペット」モンスターしか特殊召喚できず、Xモンスターでしか攻撃宣言できない。\n②：このカードをリリースして発動できる。\n自分フィールドの全てのモンスターのレベルはターン終了時まで８になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ギミック・パペット－シザー・アーム",h:"ぎみっく・ぱぺっと－しざー・あーむ",e:"Gimmick Puppet Scissor Arms",ra:"UR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:600,tx:"このカードが召喚に成功した時、デッキから「ギミック・パペット」と名のついたモンスター１体を墓地へ送る事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ギミック・パペット－死の木馬",h:"ぎみっく・ぱぺっと－です・とろい",e:"Gimmick Puppet Des Troy",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:2000,tx:"①：このカードがフィールドに表側表示で存在する限り１度だけ、フィールドの「ギミック・パペット」モンスター１体を対象として発動できる。\nそのモンスターを破壊する。\n②：このカードがフィールドから墓地へ送られた時に発動できる。\n手札から「ギミック・パペット」モンスターを２体まで特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ギミック・パペット－テラー・ベビー",h:"ぎみっく・ぱぺっと－てらー・べびー",e:"Gimmick Puppet Terror Baby",ra:"UR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:500,de:0,tx:"①：このカードが召喚に成功した時、「ギミック・パペット－テラー・ベビー」以外の自分の墓地の「ギミック・パペット」モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\n②：墓地のこのカードを除外して発動できる。\nこのターン、自分の「ギミック・パペット」モンスターの効果の発動に対して相手は魔法・罠・モンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ギミック・パペット－ナイト・ジョーカー",h:"ぎみっく・ぱぺっと－ないと・じょーかー",e:"Gimmick Puppet Twilight Joker",ra:"UR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:800,de:1600,tx:"自分フィールド上の「ギミック・パペット」と名のついたモンスターが戦闘によって破壊され自分の墓地へ送られた時、そのモンスターをゲームから除外して発動できる。\nこのカードを手札から特殊召喚する。\n「ギミック・パペット－ナイト・ジョーカー」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ギミック・パペット－ナイトメア",h:"ぎみっく・ぱぺっと－ないとめあ",e:"Gimmick Puppet Nightmare",ra:"SR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:2000,tx:"このカードは自分フィールド上に表側表示で存在するエクシーズモンスター１体をリリースして手札から特殊召喚できる。\nこの方法による「ギミック・パペット－ナイトメア」の特殊召喚は１ターンに１度しかできない。\nこの方法で特殊召喚に成功した時、自分の手札・墓地から「ギミック・パペット－ナイトメア」１体を選んで特殊召喚できる。\nまた、このカードが特殊召喚に成功したターン、自分は「ギミック・パペット」と名のついたモンスター以外のモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ギミック・パペット－ネクロ・ドール",h:"ぎみっく・ぱぺっと－ねくろ・どーる",e:"Gimmick Puppet Dreary Doll",ra:"SR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の効果は１ターンに１度しか使用できず、このカードをX召喚の素材とする場合、「ギミック・パペット」モンスターのX召喚にしか使用できない。\n①：このカードが墓地に存在する場合、自分の墓地からこのカード以外の「ギミック・パペット」モンスター１体を除外して発動できる。\nこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ギミック・パペット－ハンプティ・ダンプティ",h:"ぎみっく・ぱぺっと－はんぷてぃ・だんぷてぃ",e:"Gimmick Puppet Humpty Dumpty",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:100,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した時に発動できる。\n手札から「ギミック・パペット」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ギミック・パペット－ビスク・ドール",h:"ぎみっく・ぱぺっと－びすく・どーる",e:"Gimmick Puppet Bisque Doll",ra:"SR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"①：このカードは手札の「ギミック・パペット」モンスター１体を捨てて、手札から特殊召喚できる。\n②：墓地のこのカードを除外して発動できる。\nこのターン、自分フィールドの「ギミック・パペット」モンスターは相手の効果の対象にならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ギミック・パペット－ボム・エッグ",h:"ぎみっく・ぱぺっと－ぼむ・えっぐ",e:"Gimmick Puppet Egg Head",ra:"UR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"自分のメインフェイズ時に手札から「ギミック・パペット」と名のついたモンスター１体を捨て、以下の効果から１つを選択して発動できる。\n「ギミック・パペット－ボム・エッグ」の効果は１ターンに１度しか使用できない。\n\n●相手ライフに８００ポイントダメージを与える。\n\n●このカードのレベルはエンドフェイズ時まで８になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ギミック・パペット－マグネ・ドール",h:"ぎみっく・ぱぺっと－まぐね・どーる",e:"Gimmick Puppet Magnet Doll",ra:"SR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"①：相手フィールドにモンスターが存在し、自分フィールドのモンスターが「ギミック・パペット」モンスターのみの場合、このカードは手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"キメラテック・オーバー・ドラゴン",h:"きめらてっく・おーばー・どらごん",e:"Chimeratech Overdragon",ra:"UR",t:"monster",a:"闇",l:9,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:"?",de:"?",tx:"「サイバー・ドラゴン」＋機械族モンスター１体以上\nこのカードは融合召喚でしか特殊召喚できない。\n①：このカードが融合召喚に成功した場合に発動する。\nこのカード以外の自分フィールドのカードを全て墓地へ送る。\n②：このカードの元々の攻撃力・守備力は、このカードの融合素材としたモンスターの数×８００になる。\n③：このカードは１度のバトルフェイズ中に、このカードの融合素材としたモンスターの数まで相手モンスターに攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"キメラテック・ランページ・ドラゴン",h:"きめらてっく・らんぺーじ・どらごん",e:"Chimeratech Rampage Dragon",ra:"UR",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:2100,de:1600,tx:"「サイバー・ドラゴン」モンスター×２体以上\nこのカードの融合召喚は上記のカードでしか行えない。\n①：このカードが融合召喚に成功した時、このカードの融合素材としたモンスターの数までフィールドの魔法・罠カードを対象として発動できる。\nそのカードを破壊する。\n②：１ターンに１度、自分メインフェイズに発動できる。\nデッキから機械族・光属性モンスターを２体まで墓地へ送る。\nこのターン、このカードは通常の攻撃に加えて、この効果で墓地へ送ったモンスターの数まで１度のバトルフェイズ中に攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"逆ギレパンダ",h:"ぎゃくぎれぱんだ",e:"Gyaku-Gire Panda",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:800,de:1600,tx:"相手フィールド上のモンスター１体につきこのカードの攻撃力は５００ポイントアップする。\n守備表示モンスターを攻撃した時にその守備力を攻撃力が越えていれば、その数値だけ相手に戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"逆転の女神",h:"ぎゃくてんのめがみ",e:"Gyakutenno Megami",ra:"N",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:1800,de:2000,tx:"聖なる力で弱き者を守り、逆転の力を与える女神。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"青虫",h:"きゃたぴらー",e:"Kattapillar",ra:"N",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"昆虫族",mo:["通常"],ma:"",tp:"",at:250,de:300,tx:"糸をはき攻撃する。\nどんなムシに成長するか分からない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"キャッツ・フェアリー",h:"きゃっつ・ふぇありー",e:"Nekogal #1",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:1100,de:900,tx:"ネコの妖精。\n愛らしい姿とはうらはらに、素早く敵をひっかく。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"キャット・レディ",h:"きゃっと・れでぃ",e:"Nekogal #2",ra:"N",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"獣戦士族",mo:["通常"],ma:"",tp:"",at:1900,de:2000,tx:"素早い動きで攻撃をかわし、鋭いかぎづめで襲いかかる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"銀河眼の光子竜",h:"ぎゃらくしーあいず・ふぉとん・どらごん",e:"Galaxy-Eyes Photon Dragon",ra:"UR",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:3000,de:2500,tx:"①：このカードは自分フィールドの攻撃力２０００以上のモンスター２体をリリースして手札から特殊召喚できる。\n②：このカードが相手モンスターと戦闘を行うバトルステップに、その相手モンスター１体を対象として発動できる。\nその相手モンスターとフィールドのこのカードを除外する。\nこの効果で除外したモンスターはバトルフェイズ終了時にフィールドに戻り、この効果でXモンスターを除外した場合、このカードの攻撃力は、そのXモンスターを除外した時のX素材の数×５００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ギャラクシーサーペント",h:"ぎゃらくしーさーぺんと",e:"Galaxy Serpent",ra:"R",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"ドラゴン族",mo:["チューナー","通常"],ma:"",tp:"",at:1000,de:0,tx:"宵闇に紛れて姿を現わすと言われるドラゴン。\n星の海を泳ぐように飛ぶ神秘的な姿からその名が付けられた。\nその姿を見た者は数えるほどしかないと伝えられるが、見た者は新たな力を得られるという。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"キューキューロイド",h:"きゅーきゅーろいど",e:"Ambulanceroid",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:300,de:1200,tx:"「ロイド」と名のついたモンスターが自分の墓地から手札に加わった時、そのモンスターを特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"究極完全態・グレート・モス",h:"きゅうきょくかんぜんたい・ぐれーと・もす",e:"Perfectly Ultimate Great Moth",ra:"SR",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"昆虫族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3500,de:3000,tx:"このカードは通常召喚できない。\n「進化の繭」を装備した状態で、自分ターンで数えて６ターン以上が経過した自分フィールドの「プチモス」１体をリリースした場合に特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"究極幻神 アルティミトル・ビシバールキン",h:"きゅうきょくげんしん　あるてぃみとる・びしばーるきん",e:"Phantasmal Lord Ultimitl Bishbaalkin",ra:"R",t:"monster",a:"闇",l:"",ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:0,de:0,tx:"ルール上、このカードのレベルは１２として扱う。\nこのカードはS召喚できず、自分フィールドのレベル８以上で同じレベルの、チューナーとチューナー以外のモンスターを１体ずつ墓地へ送った場合のみ特殊召喚できる。\n①：このカードは効果では破壊されず、攻撃力はフィールドのモンスターの数×１０００アップする。\n②：１ターンに１度、自分・相手のメインフェイズに発動できる。\nお互いのフィールドに同じ数だけ、「邪眼神トークン」（悪魔族・闇・星１・攻／守０）を可能な限り守備表示で特殊召喚する。\nこのターンこのカードは攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"究極変異態・インセクト女王",h:"きゅうきょくへんいたい・いんせくとくいーん",e:"Metamorphosed Insect Queen",ra:"UR",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"昆虫族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2800,de:2400,tx:"このカードは通常召喚できず、カードの効果でのみ特殊召喚できる。\n①：フィールドに他の昆虫族モンスターが存在する場合、自分フィールドの昆虫族モンスターは相手の効果の対象にならず、相手の効果では破壊されない。\n②：このカードが攻撃したダメージステップ終了時、自分フィールドのモンスター１体をリリースして発動できる。\nこのカードは相手モンスターに続けて攻撃できる。\n③：自分・相手のエンドフェイズに発動できる。\n自分フィールドに「インセクトモンスタートークン」（昆虫族・地・星１・攻／守１００）１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"究極宝玉神 レインボー・オーバー・ドラゴン",h:"きゅうきょくほうぎょくしん　れいんぼー・おーばー・どらごん",e:"Rainbow Overdragon",ra:"SR",t:"monster",a:"光",l:12,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:4000,de:0,tx:"「宝玉獣」モンスター×７\nこのカードは融合召喚及び以下の方法でのみ特殊召喚できる。\n●自分フィールドのレベル１０の「究極宝玉神」モンスター１体をリリースした場合にEXデッキから特殊召喚できる（「融合」は必要としない）。\n①：１ターンに１度、自分の墓地から「宝玉獣」モンスター１体を除外して発動できる。\nこのカードの攻撃力はターン終了時まで、除外したモンスターの攻撃力分アップする。\n②：融合召喚したこのカードをリリースして発動できる。\nフィールドのカードを全て持ち主のデッキに戻す。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"究極宝玉神 レインボー・ダーク・ドラゴン",h:"きゅうきょくほうぎょくしん　れいんぼー・だーく・どらごん",e:"Rainbow Dark Dragon",ra:"UR",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:4000,de:0,tx:"このカードは通常召喚できない。\n自分の墓地から闇属性モンスター７種類を１体ずつ除外した場合のみ特殊召喚できる。\n①：自分の墓地及び自分フィールドの表側表示モンスターの中から、このカード以外の闇属性モンスターを全て除外して発動できる。\nこのカードの攻撃力は除外した数×５００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"究極宝玉神 レインボー・ドラゴン",h:"きゅうきょくほうぎょくしん　れいんぼー・どらごん",e:"Rainbow Dragon",ra:"UR",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:4000,de:0,tx:"このカードは通常召喚できない。\n自分のフィールド・墓地に「宝玉獣」カードが７種類存在する場合のみ特殊召喚できる。\nこのカードが特殊召喚したターン、このカードの①②の効果は発動できない。\n①：自分フィールドの表側表示の「宝玉獣」モンスターを全て墓地へ送って発動できる。\nこのカードの攻撃力は墓地へ送ったモンスターの数×１０００アップする。\nこの効果は相手ターンでも発動できる。\n②：自分の墓地から「宝玉獣」モンスターを全て除外して発動できる。\nフィールドのカードを全て持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"吸血コアラ",h:"きゅうけつこあら",e:"Vampiric Koala",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1800,de:1500,tx:"このカードがモンスターとの戦闘によって相手ライフに戦闘ダメージを与えた時、与えた戦闘ダメージの数値分だけ自分のライフポイントを回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"吸血ノミ",h:"きゅうけつのみ",e:"Giant Flea",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["通常"],ma:"",tp:"",at:1500,de:1200,tx:"血を吸う巨大ノミ。\n攻撃はかなり強い。\nノミとあなどると危険。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"救済のレイヤード",h:"きゅうさいのれいやーど",e:"Layard the Liberator",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1400,de:1500,tx:"①：このカードがモンスターゾーンに存在する限り、自分または相手がカウンター罠カードを発動する度に、除外されている自分の天使族モンスター２体を選んで手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"吸収天児",h:"きゅうしゅうてんじ",e:"Absorbing Kid from the Sky",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1300,de:1000,tx:"このカードが戦闘によってモンスターを破壊し墓地へ送った時、自分は破壊したモンスターのレベル×３００ライフポイント回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"救世の美神ノースウェムコ",h:"きゅうせいのびしんのーすうぇむこ",e:"Divine Grace - Northwemko",ra:"UR",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"魔法使い族",mo:["儀式","効果"],ma:"",tp:"",at:2700,de:1200,tx:"「救世の儀式」により降臨。\nこのカードが儀式召喚に成功した時、このカードの儀式召喚に使用したモンスターの数まで、このカード以外のフィールド上に表側表示で存在するカードを選択して発動する。\n選択したカードがフィールド上に表側表示で存在する限り、このカードはカードの効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"救世竜 セイヴァー・ドラゴン",h:"きゅうせいりゅう　せいう゛ぁー・どらごん",e:"Majestic Dragon",ra:"SR",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"ドラゴン族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"このカードをS素材とする場合、「セイヴァー」モンスターのS召喚にしか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"九尾の狐",h:"きゅうびのきつね",e:"Nine-Tailed Fox",ra:"UR",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:2200,de:2000,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが手札・墓地に存在する場合、自分フィールドのモンスター２体をリリースして発動できる。\nこのカードを特殊召喚する。\n②：墓地から特殊召喚したこのカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。\n③：このカードが戦闘・効果で破壊され墓地へ送られた場合に発動できる。\n自分フィールドに「狐トークン」（アンデット族・炎・星２・攻／守５００）２体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"キューブン",h:"きゅーぶん",e:"Cuben",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:600,de:1900,tx:"①：１ターンに１度、自分メインフェイズに発動できる。\nサイコロを１回振る。\nこのモンスターがフィールドに表側表示で存在する限り、お互いに出た目と同じレベルのモンスターを召喚・特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"牛魔人",h:"ぎゅうまじん",e:"Battle Steer",ra:"N",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"獣戦士族",mo:["通常"],ma:"",tp:"",at:1800,de:1300,tx:"森に住む牛の魔人。\nツノを突き出し突進して攻撃。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"凶悪犯－チョップマン",h:"きょうあくはん－ちょっぷまん",e:"Chopman the Desperate Outlaw",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1100,de:500,tx:"このカードが反転召喚に成功した時、自分の墓地の装備魔法カード１枚をこのカードに装備する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"強化支援メカ・ヘビーアーマー",h:"きょうかしえんめか・へびーあーまー",e:"Heavy Mech Support Armor",ra:"UR",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"機械族",mo:["ユニオン","効果"],ma:"",tp:"",at:500,de:500,tx:"①：このカードが召喚に成功した場合、自分の墓地のユニオンモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n②：１ターンに１度、以下の効果から１つを選択して発動できる。\n●自分フィールドの機械族モンスター１体を対象とし、このカードを装備カード扱いとしてそのモンスターに装備する。\n装備モンスターが戦闘・効果で破壊される場合、代わりにこのカードを破壊する。\n●装備されているこのカードを特殊召喚する。\n③：装備モンスターは相手の効果の対象にならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"強化支援メカ・ヘビーウェポン",h:"きょうかしえんめか・へびーうぇぽん",e:"Heavy Mech Support Platform",ra:"SR",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"機械族",mo:["ユニオン","効果"],ma:"",tp:"",at:500,de:500,tx:"①：１ターンに１度、以下の効果から１つを選択して発動できる。\n●自分フィールドの機械族モンスター１体を対象とし、このカードを装備カード扱いとしてそのモンスターに装備する。\n装備モンスターが戦闘・効果で破壊される場合、代わりにこのカードを破壊する。\n●装備されているこのカードを特殊召喚する。\n②：装備モンスターの攻撃力・守備力は５００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"強化人類サイコ",h:"きょうかじんるいさいこ",e:"Reinforced Human Psychic Borg",ra:"UR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1500,de:800,tx:"自分の墓地に存在するサイキック族モンスター１体をゲームから除外して発動する。\nこのカードの攻撃力は５００ポイントアップする。\nこの効果は１ターンに２度まで使用できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"恐牙狼 ダイヤウルフ",h:"きょうがろう　だいやうるふ",e:"Diamond Dire Wolf",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2000,de:1200,tx:"レベル４モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除き、自分フィールドの獣族・獣戦士族・鳥獣族モンスター１体とフィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"凶星の魔術師",h:"きょうせいのまじゅつし",e:"Doomstar Magician",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1800,de:300,tx:"①：１ターンに１度、手札を１枚捨て、自分または相手のPゾーンのカード１枚を対象として発動できる。\nそのカードを破壊し、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"共闘するランドスターの剣士",h:"きょうとうするらんどすたーのけんし",e:"Comrade Swordsman of Landstar",ra:"SR",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["チューナー","効果"],ma:"",tp:"",at:500,de:1200,tx:"このカードがフィールド上に表側表示で存在する限り、自分フィールド上に表側表示で存在する戦士族モンスターの攻撃力は４００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"恐竜人",h:"きょうりゅうじん",e:"Anthrosaurus",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"恐竜族",mo:["通常"],ma:"",tp:"",at:1000,de:850,tx:"人型の恐竜。\n高い知能を持つが、あまり強くはない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"虚栄の大猿",h:"きょえいのおおざる",e:"Egotistical Ape",ra:"R",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"獣族",mo:["特殊召喚","チューナー","効果"],ma:"",tp:"",at:1200,de:1200,tx:"このカードは通常召喚できない。\n手札から獣族モンスター１体を墓地へ送った場合に特殊召喚する事ができる。\nこの方法で特殊召喚に成功した時、墓地へ送ったその獣族モンスターのレベルを確認し、次の効果から１つを選択して発動する事ができる。\n●そのレベルの数だけこのカードのレベルを上げる。\n●そのレベルの数だけこのカードのレベルを下げる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魚ギョ戦士",h:"ぎょぎょせんし",e:"Wow Warrior",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["通常"],ma:"",tp:"",at:1250,de:900,tx:"魚に手足が生えた魚人獣。\n鋭い歯でかみついてくる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"極神皇トール",h:"きょくしんおうとーる",e:"Thor, Lord of the Aesir",ra:"R",t:"monster",a:"地",l:10,ps:"",pe:"",tr:"獣戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:3500,de:2800,tx:"「極星獣」チューナー＋チューナー以外のモンスター２体以上\n①：１ターンに１度、自分メインフェイズに発動できる。\n相手フィールドの全ての表側表示モンスターの効果はターン終了時まで無効化される。\n②：フィールドの表側表示のこのカードが相手によって破壊され墓地へ送られたターンのエンドフェイズに、自分の墓地から「極星獣」チューナー１体を除外して発動できる。\nこのカードを墓地から特殊召喚する。\n③：このカードの②の効果で特殊召喚に成功した時に発動できる。\n相手に８００ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"極神皇ロキ",h:"きょくしんおうろき",e:"Loki, Lord of the Aesir",ra:"R",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"魔法使い族",mo:["シンクロ","効果"],ma:"",tp:"",at:3300,de:3000,tx:"「極星霊」と名のついたチューナー＋チューナー以外のモンスター２体以上\n１ターンに１度、自分のバトルフェイズ中に相手が魔法・罠カードを発動した時、その発動を無効にし破壊する事ができる。\nフィールド上に表側表示で存在するこのカードが相手によって破壊され墓地へ送られた場合、そのターンのエンドフェイズ時に自分の墓地に存在する「極星霊」と名のついたチューナー１体をゲームから除外する事で、このカードを墓地から特殊召喚する。\nこの効果で特殊召喚に成功した時、自分の墓地に存在する罠カード１枚を選択して手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"極神聖帝オーディン",h:"きょくしんせいていおーでぃん",e:"Odin, Father of the Aesir",ra:"UR",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"天使族",mo:["シンクロ","効果"],ma:"",tp:"",at:4000,de:3500,tx:"「極星天」チューナー＋チューナー以外のモンスター２体以上\n①：１ターンに１度、自分メインフェイズに発動できる。\nこのカードはターン終了時まで魔法・罠カードの効果を受けない。\n②：フィールドの表側表示のこのカードが相手によって破壊され墓地へ送られたターンのエンドフェイズに、自分の墓地から「極星天」チューナー１体を除外して発動できる。\nこのカードを墓地から特殊召喚する。\n③：このカードの②の効果で特殊召喚に成功した時に発動できる。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"極星邪龍ヨルムンガンド",h:"きょくせいじゃりゅうよるむんがんど",e:"Jormungardr the Nordic Serpent",ra:"N",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3000,de:3000,tx:"このカードは通常召喚できない。\nフィールドに「極神」モンスターが存在する場合に手札から相手フィールドに守備表示で特殊召喚できる。\n①：フィールドに「極神」モンスターが存在しない場合にこのカードは破壊される。\n②：このカードがフィールドに表側表示で存在する限り１度だけ、表側守備表示のこのカードが表側攻撃表示になった場合に発動する。\n自分は３０００ダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"極星獣ガルム",h:"きょくせいじゅうがるむ",e:"Garmr of the Nordic Beasts",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:800,de:1900,tx:"このカードがレベル４以下のモンスターと戦闘を行ったダメージ計算後、そのモンスターを手札に戻す事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"極星獣グルファクシ",h:"きょくせいじゅうぐるふぁくし",e:"Guldfaxe of the Nordic Beasts",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣族",mo:["チューナー","効果"],ma:"",tp:"",at:1600,de:1000,tx:"相手フィールド上にシンクロモンスターが表側表示で存在し、自分フィールド上にモンスターが存在しない場合、このカードは手札から特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"極星獣タングニョースト",h:"きょくせいじゅうたんぐにょーすと",e:"Tanngnjostr of the Nordic Beasts",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:800,de:1100,tx:"自分フィールド上に存在するモンスターが戦闘によって破壊され墓地へ送られた時、このカードを手札から特殊召喚する事ができる。\n１ターンに１度、フィールド上に守備表示で存在するこのカードが表側攻撃表示になった時、自分のデッキから「極星獣タングニョースト」以外の「極星獣」と名のついたモンスター１体を表側守備表示で特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"極星獣タングリスニ",h:"きょくせいじゅうたんぐりすに",e:"Tanngrisnir of the Nordic Beasts",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1200,de:800,tx:"①：このカードが戦闘で破壊され墓地へ送られた場合に発動する。\n自分フィールドに「極星獣トークン」（獣族・地・星３・攻／守０）２体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"極星將テュール",h:"きょくせいしょうてゅーる",e:"Tyr of the Nordic Champions",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2000,de:2000,tx:"フィールド上にこのカード以外の「極星」と名のついたモンスターが表側表示で存在しない場合、このカードを破壊する。\nこのカードがフィールド上に表側表示で存在する限り、相手は「極星將テュール」以外の「極星」と名のついたモンスターを攻撃対象に選択する事はできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"極星天ヴァナディース",h:"きょくせいてんう゛ぁなでぃーす",e:"Vanadis of the Nordic Ascendant",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"天使族",mo:["チューナー","効果"],ma:"",tp:"",at:1200,de:400,tx:"このカードは「極星」チューナーの代わりとしてS素材にできる。\nこのカードをS素材とする場合、他のS素材モンスターは全て「極星」モンスターでなければならない。\n①：１ターンに１度、このカードのレベルと異なるレベルを持つ「極星」モンスター１体をデッキから墓地へ送って発動できる。\nこのカードのレベルはターン終了時まで、墓地へ送ったモンスターと同じになる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"極星天ヴァルキュリア",h:"きょくせいてんう゛ぁるきゅりあ",e:"Valkyrie of the Nordic Ascendant",ra:"R",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"天使族",mo:["チューナー","効果"],ma:"",tp:"",at:400,de:800,tx:"①：このカードが召喚に成功した時、相手フィールドにモンスターが存在し、自分フィールドにこのカード以外のカードが存在しない場合、手札から「極星」モンスター２体を除外して発動できる。\n自分フィールドに「エインヘリアル・トークン」（戦士族・地・星４・攻／守１０００）２体を守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"極星天ミーミル",h:"きょくせいてんみーみる",e:"Mimir of the Nordic Ascendant",ra:"N",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:600,de:0,tx:"自分フィールド上に「極星」と名のついたモンスターが表側表示で存在する場合、自分のスタンバイフェイズ開始時に１度だけ、手札から魔法カード１枚を墓地へ送って発動する事ができる。\n墓地に存在するこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"極星霊スヴァルトアールヴ",h:"きょくせいれいすう゛ぁるとあーるう゛",e:"Mara of the Nordic Alfar",ra:"N",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["チューナー","効果"],ma:"",tp:"",at:1000,de:500,tx:"このカードをシンクロ素材とする場合、他のシンクロ素材モンスターは手札の「極星」と名のついたモンスター２体でなければならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"極星霊デックアールヴ",h:"きょくせいれいでっくあーるう゛",e:"Svartalf of the Nordic Alfar",ra:"N",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["チューナー","効果"],ma:"",tp:"",at:1400,de:1600,tx:"このカードが召喚に成功した時、自分の墓地に存在する「極星」と名のついたモンスター１体を選択して手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"極星霊ドヴェルグ",h:"きょくせいれいどう゛ぇるぐ",e:"Dverg of the Nordic Alfar",ra:"N",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:100,de:1000,tx:"このカードが召喚に成功したターン、自分は通常召喚に加えて１度だけ「極星」と名のついたモンスター１体を召喚する事ができる。\nフィールド上に表側表示で存在するこのカードが墓地へ送られた時、自分の墓地から「極星宝」と名のついたカード１枚を選択して手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"極星霊リョースアールヴ",h:"きょくせいれいりょーすあーるう゛",e:"Ljosalf of the Nordic Alfar",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1400,de:1200,tx:"このカードが召喚に成功した時、このカード以外の自分フィールド上に表側表示で存在するモンスター１体を選択して発動する事ができる。\n選択したモンスターのレベル以下の「極星」と名のついたモンスター１体を手札から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"極戦機王ヴァルバロイド",h:"きょくせんきおうう゛ぁるばろいど",e:"Barbaroid, the Ultimate Battle Machine",ra:"N",t:"monster",a:"地",l:12,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:4000,de:4000,tx:"「ロイド」と名のついた機械族モンスター×５\nこのカードは１度のバトルフェイズ中に２回攻撃する事ができる。\nこのカードが攻撃した相手の効果モンスターの効果をダメージ計算後に無効化する。\nこのカードが戦闘によって相手モンスターを破壊した場合、相手ライフに１０００ポイントダメージを与える。\nこのカードは相手プレイヤーに直接攻撃をする事はできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"極夜の騎士ガイア",h:"きょくやのきしがいあ",e:"Gaia, the Polar Knight",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"「極夜の騎士ガイア」の以下の効果はそれぞれ１ターンに１度ずつ使用できる。\n●このカード以外の自分フィールド上の闇属性モンスター１体をリリースして発動できる。\nデッキから戦士族・光属性・レベル４モンスター１体を手札に加え、その後手札を１枚墓地へ送る。\n●自分の墓地の光属性モンスター１体を除外し、自分フィールド上のモンスター１体を選択して発動できる。\n選択したモンスターの攻撃力は、相手のエンドフェイズ時まで５００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"虚構王アンフォームド・ボイド",h:"きょこうおうあんふぉーむど・ぼいど",e:"Unformed Void",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"水族",mo:["エクシーズ","効果"],ma:"",tp:"",at:0,de:0,tx:"レベル４モンスター×３\n相手のメインフェイズ時に１度、このカードのエクシーズ素材を１つ取り除いて発動できる。\nこのカードの攻撃力・守備力は、相手フィールド上のエクシーズモンスターの攻撃力を合計した数値分アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"巨人ゴーグル",h:"きょじんごーぐる",e:"Goggle Golem",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["デュアル","効果"],ma:"",tp:"",at:1500,de:500,tx:"このカードは墓地またはフィールド上に表側表示で存在する場合、通常モンスターとして扱う。\nフィールド上に表側表示で存在するこのカードを通常召喚扱いとして再度召喚する事で、このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●このカードの元々の攻撃力は２１００になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"巨星のミラ",h:"きょせいのみら",e:"Mira the Star-Bearer",ra:"SR",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1100,de:1000,tx:"レベル１モンスター×３\nフィールド上に表側表示で存在するこのカードが破壊される場合、代わりにこのカードのエクシーズ素材を１つ取り除く事ができる。\nまた、このカードがフィールド上に表側表示で存在する限り、自分フィールド上に表側表示で存在するレベル４以下のモンスターは、カードの効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"巨大戦艦 カバード・コア",h:"きょだいせんかん　かばーど・こあ",e:"B.E.S. Covered Core",ra:"R",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2500,de:800,tx:"このカードの召喚時にカウンターを２つ置く。\nこのカードは戦闘によっては破壊されない。\n戦闘を行った場合、ダメージステップ終了時にコイントスで裏表を当てる。\nハズレの場合、カードのカウンターを１つ取り除く。\nカウンターのない状態で戦闘を行った場合、ダメージステップ終了時にこのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"巨大戦艦 クリスタル・コア",h:"きょだいせんかん　くりすたる・こあ",e:"B.E.S. Crystal Core",ra:"SR",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2100,de:1000,tx:"このカードの召喚時にカウンターを３つ置く。\nこのカードは戦闘によっては破壊されない。\n戦闘を行った場合、ダメージステップ終了時にこのカードのカウンターを１つ取り除く。\nカウンターのない状態で戦闘を行った場合、ダメージステップ終了時にこのカードを破壊する。\n１ターンに１度だけ自分のメインフェイズに、相手フィールド上の表側攻撃表示モンスター１体を表側守備表示にする事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"巨大戦艦 テトラン",h:"きょだいせんかん　てとらん",e:"B.E.S. Tetran",ra:"SR",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1800,de:2300,tx:"このカードの召喚時にカウンターを３つ置く。\nこのカードは戦闘によっては破壊されない。\n戦闘を行った場合、ダメージステップ終了時にこのカードのカウンターを１つ取り除く。\nカウンターのない状態で戦闘を行った場合、ダメージステップ終了時にこのカードを破壊する。\nまた、このカードのカウンターを１つ取り除く事で、フィールド上の魔法・罠カード１枚を破壊する。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"巨大戦艦 ビッグ・コア",h:"きょだいせんかん　びっぐ・こあ",e:"B.E.S. Big Core",ra:"UR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2300,de:1100,tx:"このカードの召喚時にカウンターを３つ置く。\nこのカードは戦闘によっては破壊されない。\n戦闘を行った場合、ダメージステップ終了時にこのカードのカウンターを１つ取り除く。\nカウンターのない状態で戦闘を行った場合、ダメージステップ終了時にこのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"巨大戦艦 ビッグ・コアMk－II",h:"きょだいせんかん　びっぐ・こあＭｋ－Ⅱ",e:"B.E.S. Big Core MK-2",ra:"UR",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2400,de:1100,tx:"このカードが特殊召喚に成功した時、このカードにカウンターを３つ置く。\nこのカードは戦闘では破壊されない。\nこのカードが戦闘を行った場合、ダメージステップ終了時にこのカードに乗っているカウンターを１つ取り除く。\nこのカードがカウンターのない状態で戦闘を行った場合、ダメージステップ終了時にこのカードを破壊する。\n自分フィールド上にモンスターが存在しない場合、このカードはリリースなしで召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"巨大な怪鳥",h:"きょだいなかいちょう",e:"Monstrous Bird",ra:"N",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"鳥獣族",mo:["通常"],ma:"",tp:"",at:2000,de:1900,tx:"あまり見かけない、とても大きなトリ。\n空から急降下して襲いかかる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"巨大ネズミ",h:"きょだいねずみ",e:"Giant Rat",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1400,de:1450,tx:"①：このカードが戦闘で破壊され墓地へ送られた時に発動できる。\nデッキから攻撃力１５００以下の地属性モンスター１体を攻撃表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魚雷魚",h:"ぎょらいぎょ",e:"Torpedo Fish",ra:"R",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"「海」がフィールド上に存在する限り、このカードは魔法の効果を受けない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"キラー・ザ・クロー",h:"きらー・ざ・くろー",e:"Claw Reacher",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1000,de:800,tx:"腕を自由に伸ばし、鋭い爪で相手を串刺しにすることができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"キラー・スネーク",h:"きらー・すねーく",e:"Sinister Serpent",ra:"R",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:300,de:250,tx:"「キラー・スネーク」の効果は１ターンに１度しか使用できない。\n①：このカードが墓地に存在する場合、自分スタンバイフェイズに発動できる。\nこのカードを手札に戻す。\n次の相手エンドフェイズに自分の墓地の「キラー・スネーク」１体を選んで除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"キラー・トマト",h:"きらー・とまと",e:"Mystic Tomato",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1400,de:1100,tx:"このカードが戦闘によって破壊され墓地へ送られた時、自分のデッキから攻撃力１５００以下の闇属性モンスター１体を自分フィールド上に表側攻撃表示で特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"キラー・ビー",h:"きらー・びー",e:"Killer Needle",ra:"N",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"昆虫族",mo:["通常"],ma:"",tp:"",at:1200,de:1000,tx:"大きなハチ。\n意外に強い攻撃をする。\n群で襲われると大変。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"キラー・ブロッブ",h:"きらー・ぶろっぶ",e:"Gruesome Goo",ra:"N",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1300,de:700,tx:"スライムの親分。\n見た目は同じだが、なめてかかると痛い目にあうぞ。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"キラー・マシーン",h:"きらー・ましーん",e:"Sword Slasher",ra:"N",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1450,de:1500,tx:"大きく反った剣を振りかざし暴れ回る、殺人マシン。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"キラー・ラブカ",h:"きらー・らぶか",e:"Friller Rabca",ra:"R",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:700,de:1500,tx:"自分フィールド上の魚族・海竜族・水族モンスターが攻撃対象に選択された時、墓地のこのカードをゲームから除外し、攻撃モンスター１体を選択して発動できる。\n選択したモンスターの攻撃を無効にし、その攻撃力を次の自分のエンドフェイズ時まで５００ポイントダウンする。\n「キラー・ラブカ」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"キラーザウルス",h:"きらーざうるす",e:"Destroyersaurus",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1800,de:1100,tx:"このカードを手札から墓地へ捨てて発動できる。\nデッキから「ジュラシックワールド」１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"キラーパンダ",h:"きらーぱんだ",e:"Frenzied Panda",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:1200,de:1000,tx:"常に太い竹を一本持っており、性格は非常に凶暴である。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ギラギランサー",h:"ぎらぎらんさー",e:"Gillagillancer",ra:"SR",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2200,de:800,tx:"相手フィールド上にモンスターが存在し、自分フィールド上にモンスターが存在しない場合、このカードは手札から特殊召喚できる。\nこのカードがフィールド上に表側表示で存在する限り、自分はエンドフェイズ毎に５００ポイントダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"切り込み隊長",h:"きりこみたいちょう",e:"Marauding Captain",ra:"SR",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:400,tx:"①：このカードが召喚に成功した時に発動できる。\n手札からレベル４以下のモンスター１体を特殊召喚する。\n②：このカードがモンスターゾーンに存在する限り、相手は他の戦士族モンスターを攻撃対象に選択できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"切り盛り隊長",h:"きりもりたいちょう",e:"Marmiting Captain",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:400,tx:"①：このカードが召喚に成功した時に発動できる。\n手札を１枚デッキに戻してシャッフルする。\nその後、自分はデッキから１枚ドローする。\nそのドローしたカードがモンスターだった場合、そのモンスターを特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"騎竜",h:"きりゅう",e:"Kiryu",ra:"N",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"ドラゴン族",mo:["ユニオン","効果"],ma:"",tp:"",at:2000,de:1500,tx:"１ターンに１度だけ自分のメインフェイズに装備カード扱いとして自分の「闇魔界の戦士 ダークソード」に装備、または装備を解除して表側攻撃表示で特殊召喚する事ができる。\nこの効果で装備カード扱いになっている時のみ、装備モンスターの攻撃力・守備力は９００ポイントアップする。\n装備状態のこのカードを生け贄に捧げる事で、装備モンスターはこのターン相手プレイヤーに直接攻撃ができる。\n（１体のモンスターが装備できるユニオンは１枚まで。\n装備モンスターが戦闘によって破壊される場合は、代わりにこのカードを破壊する。\n）",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"貴竜の魔術師",h:"きりゅうのまじゅつし",e:"Nobledragon Magician",ra:"SR",t:"monster",a:"炎",l:3,ps:5,pe:"①：もう片方の自分のPゾーンに「魔術師」カードが存在しない場合にこのカードは破壊される。",tr:"魔法使い族",mo:["ペンデュラム","チューナー","効果"],ma:"",tp:"",at:700,de:1400,tx:"このカードをS素材とする場合、ドラゴン族モンスターのS召喚にしか使用できず、他のS素材に「オッドアイズ」モンスター以外のモンスターを使用した場合、このカードを持ち主のデッキの一番下に戻す。\n①：このカードが手札・墓地に存在する場合、自分フィールドのレベル７以上の「オッドアイズ」モンスター１体を対象として発動できる。\nそのモンスターのレベルを３つ下げ、このカードを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ギル・ガース",h:"ぎる・がーす",e:"Gil Garth",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1800,de:1200,tx:"鋼鉄の鎧を身にまとった殺戮マシーン。\n巨大なカタナで容赦なく攻撃をする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ギルフォード・ザ・ライトニング",h:"ぎるふぉーど・ざ・らいとにんぐ",e:"Gilford the Lightning",ra:"SR",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2800,de:1400,tx:"このカードはモンスター３体をリリースして召喚する事もできる。\n①：モンスター３体をリリースしてこのカードのアドバンス召喚に成功した場合に発動する。\n相手フィールドのモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ギルフォード・ザ・レジェンド",h:"ぎるふぉーど・ざ・れじぇんど",e:"Gilford the Legend",ra:"SR",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2600,de:2000,tx:"このカードは特殊召喚できない。\nこのカードが召喚に成功した時、自分の墓地に存在する装備魔法カードを可能な限り自分フィールド上に表側表示で存在する戦士族モンスターに装備する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"切れぎみ隊長",h:"きれぎみたいちょう",e:"Motivating Captain",ra:"UR",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:400,de:1200,tx:"①：このカードが召喚に成功した時、自分の墓地のレベル４以下のモンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ギロチン・クワガタ",h:"ぎろちん・くわがた",e:"Girochin Kuwagata",ra:"N",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"昆虫族",mo:["通常"],ma:"",tp:"",at:1700,de:1000,tx:"ハイエルフの森に生息するクワガタ。\n人の親指ほどの大きさしかないが、鋼鉄をも切り裂く強力な顎をもつ。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"金色の魔象",h:"きんいろのまぞう",e:"Great Mammoth of Goldfine",ra:"N",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"アンデット族",mo:["融合"],ma:"",tp:"",at:2200,de:1800,tx:"メデューサの亡霊＋ドラゴン・ゾンビ",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"金華猫",h:"きんかびょう",e:"Kinka-byo",ra:"SR",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"獣族",mo:["スピリット","効果"],ma:"",tp:"",at:400,de:200,tx:"このカードは特殊召喚できない。\n①：このカードが召喚・リバースした時、自分の墓地のレベル１モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこのカードがフィールドから離れた時にそのモンスターは除外される。\n②：このカードが召喚・リバースしたターンのエンドフェイズに発動する。\nこのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"キング・オブ・ビースト",h:"きんぐ・おぶ・びーすと",e:"King of the Beasts",ra:"R",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:2500,de:800,tx:"自分フィールド上に表側表示で存在する「モジャ」１体をリリースして発動する。\nこのカードを手札または墓地から特殊召喚する。\n「キング・オブ・ビースト」はフィールド上に１体しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"キング・スモーク",h:"きんぐ・すもーく",e:"King Fog",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1000,de:900,tx:"煙の中にひそむ悪魔。\nまわりを煙でおおい、見えなくしてしまう。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"キング・もけもけ",h:"きんぐ・もけもけ",e:"Mokey Mokey King",ra:"N",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"天使族",mo:["融合","効果"],ma:"",tp:"",at:300,de:100,tx:"「もけもけ」＋「もけもけ」＋「もけもけ」\nこのカードがフィールド上から離れた時、自分の墓地に存在する「もけもけ」を可能な限り特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"キングゴブリン",h:"きんぐごぶりん",e:"Goblin King",ra:"R",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"自分フィールド上にこのカード以外の悪魔族モンスターが存在する場合、このカードを攻撃する事はできない。\nこのカードの攻撃力・守備力は、フィールド上のこのカード以外の悪魔族モンスターの数×１０００ポイントになる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"キングス・ナイト",h:"きんぐす・ないと",e:"King's Knight",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1400,tx:"①：自分フィールドに「クィーンズ・ナイト」が存在し、このカードが召喚に成功した時に発動できる。\nデッキから「ジャックス・ナイト」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"霧の王",h:"きんぐみすと",e:"Fog King",ra:"SR",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカードを召喚する場合、生け贄１体または生け贄なしで召喚する事ができる。\nこのカードの攻撃力は、生け贄召喚時に生け贄に捧げたモンスターの元々の攻撃力を合計した数値になる。\nこのカードがフィールド上に表側表示で存在する限り、いかなる場合による生け贄も行う事ができなくなる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"キングレムリン",h:"きんぐれむりん",e:"King of the Feral Imps",ra:"UR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2300,de:2000,tx:"レベル４モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\nデッキから爬虫類族モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"銀嶺の巨神",h:"ぎんれいのきょしん",e:"Soul of Silvermountain",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1800,de:2200,tx:"地属性レベル３モンスター×２\n１ターンに１度、このカードのエクシーズ素材を１つ取り除き、相手の魔法＆罠カードゾーンにセットされているカード１枚を選択して発動できる。\n選択したカードは、このカードがフィールド上に表側表示で存在する限り発動できない。\nまた、エクシーズ素材を持っているこのカードが戦闘によって相手モンスターを破壊した場合、自分の墓地の地属性モンスター１体を選択し、表側守備表示で特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"クイーン・バード",h:"くいーん・ばーど",e:"Queen Bird",ra:"N",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"鳥獣族",mo:["通常"],ma:"",tp:"",at:1200,de:2000,tx:"大きなくちばしで相手をつついて攻撃する。\n守備力が高い。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"クィーンズ・ナイト",h:"くぃーんず・ないと",e:"Queen's Knight",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1500,de:1600,tx:"しなやかな動きで敵を翻弄し、相手のスキを突いて素早い攻撃を繰り出す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"くいぐるみ",h:"くいぐるみ",e:"Stuffed Animal",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1200,de:900,tx:"かわいらしいぬいぐるみと思わせ、チャックの口でガブリとかみつく。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"クイック・シンクロン",h:"くいっく・しんくろん",e:"Quickdraw Synchron",ra:"UR",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:700,de:1400,tx:"このカードは「シンクロン」チューナーの代わりとしてS素材にできる。\nこのカードをS素材とする場合、「シンクロン」チューナーを素材とするSモンスターのS召喚にしか使用できない。\n①：このカードは手札のモンスター１体を墓地へ送り、手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"クイック・スパナイト",h:"くいっく・すぱないと",e:"Quick-Span Knight",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:1000,de:800,tx:"このカードがシンクロモンスターのシンクロ召喚に使用され墓地へ送られた場合、相手フィールド上に表側表示で存在するモンスター１体の攻撃力は５００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"クインテット・マジシャン",h:"くいんてっと・まじしゃん",e:"Quintet Magician",ra:"SR",t:"monster",a:"闇",l:12,ps:"",pe:"",tr:"魔法使い族",mo:["融合","効果"],ma:"",tp:"",at:4500,de:4500,tx:"魔法使い族モンスター×５\nこのカードは融合召喚でしか特殊召喚できない。\n①：魔法使い族モンスター５種類を素材としてこのカードが融合召喚に成功した場合に発動できる。\n相手フィールドのカードを全て破壊する。\n②：このカードはモンスターゾーンに存在する限り、リリースできず、融合素材にできず、効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"空炎星－サイチョウ",h:"くうえんせい－さいちょう",e:"Brotherhood of the Fire Fist - Rhino",ra:"R",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:1400,tx:"自分フィールド上の「炎星」と名のついたモンスターが戦闘を行うダメージ計算時に１度だけ、自分フィールド上に表側表示で存在する「炎舞」と名のついた魔法・罠カード１枚と手札の「炎星」と名のついたモンスター１体を墓地へ送って発動できる。\n戦闘を行う自分のモンスターの攻撃力は、そのダメージ計算時のみ墓地へ送ったモンスターの元々の攻撃力分アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"空牙団の叡智 ウィズ",h:"くうがだんのえいち　うぃず",e:"Wiz, Sage Fur Hire",ra:"R",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1600,de:2800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合に発動できる。\n自分は「空牙団の叡智 ウィズ」以外の自分フィールドの「空牙団」モンスターの種類×５００LP回復する。\n②：相手が魔法・罠カードの効果を発動した時、手札から「空牙団」カード１枚を捨てて発動できる。\nその発動を無効にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"空牙団の撃手 ドンパ",h:"くうがだんのげきしゅ　どんぱ",e:"Donpa, Marksman Fur Hire",ra:"R",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:500,de:1000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\n手札から「空牙団の撃手 ドンパ」以外の「空牙団」モンスター１体を特殊召喚する。\n②：このカードが既にモンスターゾーンに存在する状態で、自分フィールドにこのカード以外の「空牙団」モンスターが特殊召喚された場合、フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"空牙団の剣士 ビート",h:"くうがだんのけんし　びーと",e:"Beat, Bladesman Fur Hire",ra:"SR",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\n手札から「空牙団の剣士 ビート」以外の「空牙団」モンスター１体を特殊召喚する。\n②：このカードが既にモンスターゾーンに存在する状態で、自分フィールドにこのカード以外の「空牙団」モンスターが特殊召喚された場合に発動できる。\nデッキから「空牙団の剣士 ビート」以外の「空牙団」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"空牙団の豪傑 ダイナ",h:"くうがだんのごうけつ　だいな",e:"Dyna, Hero Fur Hire",ra:"R",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:2500,de:1400,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合に発動できる。\n自分フィールドの「空牙団」モンスターの種類の数まで相手の墓地のカードを選んで除外する。\n②：このカードがモンスターゾーンに存在する限り、相手は他の「空牙団」モンスターを攻撃対象に選択できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"空牙団の参謀 シール",h:"くうがだんのさんぼう　しーる",e:"Seal, Strategist Fur Hire",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\n手札から「空牙団の参謀 シール」以外の「空牙団」モンスター１体を特殊召喚する。\n②：このカードが既にモンスターゾーンに存在する状態で、自分フィールドにこのカード以外の「空牙団」モンスターが特殊召喚された場合、自分の墓地の「空牙団」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"空牙団の舵手 ヘルマー",h:"くうがだんのだしゅ　へるまー",e:"Helmer, Helmsman Fur Hire",ra:"N",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:0,de:2000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\n手札から「空牙団の舵手 ヘルマー」以外の「空牙団」モンスター１体を特殊召喚する。\n②：このカードが既にモンスターゾーンに存在する状態で、自分フィールドにこのカード以外の「空牙団」モンスターが特殊召喚された場合、手札から「空牙団」カード１枚を捨てて発動できる。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"空牙団の伝令 フィロ",h:"くうがだんのでんれい ふぃろ",e:"Filo, Messenger Fur Hire",ra:"R",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\n手札から「空牙団の伝令 フィロ」以外の「空牙団」モンスター１体を特殊召喚する。\n②：このカードが既にモンスターゾーンに存在する状態で、自分フィールドにこのカード以外の「空牙団」モンスターが特殊召喚された場合、自分の墓地の「空牙団」モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターは、フィールドから離れた場合に持ち主のデッキの一番下に戻る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"空牙団の闘士 ブラーヴォ",h:"くうがだんのとうし　ぶらーう゛ぉ",e:"Bravo, Fighter Fur Hire",ra:"N",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1900,de:200,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\n手札から「空牙団の闘士 ブラーヴォ」以外の「空牙団」モンスター１体を特殊召喚する。\n②：このカードが既にモンスターゾーンに存在する状態で、自分フィールドにこのカード以外の「空牙団」モンスターが特殊召喚された場合に発動できる。\nフィールドの全ての「空牙団」モンスターの攻撃力・守備力はターン終了時まで５００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"空牙団の飛哨 リコン",h:"くうがだんのひしょう　りこん",e:"Recon, Scout Fur Hire",ra:"R",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1000,de:500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\n手札から「空牙団の飛哨 リコン」以外の「空牙団」モンスター１体を特殊召喚する。\n②：このカードが既にモンスターゾーンに存在する状態で、自分フィールドにこのカード以外の「空牙団」モンスターが特殊召喚された場合、フィールドにセットされたカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"久遠の魔術師ミラ",h:"くおんのまじゅつしみら",e:"Milla the Temporal Magician",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"①：このカードが召喚に成功した場合、相手フィールドの裏側表示のカード１枚を対象として発動する。\nその相手のカードを確認する。\nこの効果の発動に対して、相手は魔法・罠カードを発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"九蛇孔雀",h:"くじゃくじゃく",e:"Kujakujaku",ra:"R",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1200,de:900,tx:"フィールド上のこのカードがリリースされ墓地へ送られた場合、自分のデッキ・墓地から「九蛇孔雀」以外のレベル４以下の風属性モンスター１体を選んで手札に加える事ができる。\n「九蛇孔雀」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"クジャック",h:"くじゃっく",e:"Peacock",ra:"N",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"鳥獣族",mo:["通常"],ma:"",tp:"",at:1700,de:1500,tx:"美しい羽根を広げる大きなクジャク。\nその羽根を飛ばして攻撃！",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"くちばしヘビ",h:"くちばしへび",e:"Beaked Snake",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"爬虫類族",mo:["通常"],ma:"",tp:"",at:800,de:900,tx:"相手を長い体で締め上げ、大きなくちばしでつついて攻撃。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"朽ち果てた武将",h:"くちはてたぶしょう",e:"Decayed Commander",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1000,de:1500,tx:"このカードが召喚に成功した時、手札から「ゾンビタイガー」１体を特殊召喚する事ができる。\nこのカードが相手プレイヤーへの直接攻撃に成功した場合、相手は手札からランダムにカードを１枚捨てる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"くノ一ウォリアー",h:"くのいちうぉりあー",e:"Kunoichi",ra:"SR",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"フィールド上に表側表示で存在するこのカードのコントロールが移った時、コントローラーは手札をランダムに１枚捨てる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"首なし騎士",h:"くびなしきし",e:"Headless Knight",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1450,de:1700,tx:"反逆者に仕立て上げられ処刑された騎士の亡霊。\n失ったものを求め、出会った者に襲いかかる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"クマモール",h:"くまもーる",e:"Bearblocker",ra:"SR",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1600,de:0,tx:"①：このカードがモンスターゾーンに存在する限り、相手ターンの間、自分の魔法＆罠ゾーンにセットされたカードは効果では破壊されない。\n②：自分の魔法＆罠ゾーンにセットされたカードが相手の効果で破壊される度に発動する。\nこのカードの攻撃力はターン終了時まで８００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"蜘蛛男",h:"くもおとこ",e:"Kumootoko",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"昆虫族",mo:["通常"],ma:"",tp:"",at:700,de:1400,tx:"巨大クモが知恵をつけた姿。\n糸を吐き動きを封じ込める。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"クラインアント",h:"くらいんあんと",e:"Cliant",ra:"N",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1500,de:1500,tx:"①：通常召喚したこのカードがモンスターゾーンに存在する限り、自分フィールドのサイバース族モンスターの攻撃力・守備力は、自分ターンの間５００アップする。\n②：フィールドのこのカードが戦闘・効果で破壊される場合、代わりに自分の手札・フィールドのサイバース族モンスター１体を破壊できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"グラヴィティ・ウォリアー",h:"ぐらう゛ぃてぃ・うぉりあー",e:"Gravity Warrior",ra:"R",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2100,de:1000,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカードがシンクロ召喚に成功した時、このカードの攻撃力は相手フィールド上に表側表示で存在するモンスターの数×３００ポイントアップする。\n１ターンに１度、相手のバトルフェイズ時に相手フィールド上に守備表示で存在するモンスター１体を選択して発動できる。\n選択したモンスターを表側攻撃表示にする。\nこのターンそのモンスターが攻撃可能な場合には攻撃しなければならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"グラヴィティ・ベヒモス",h:"ぐらう゛ぃてぃ・べひもす",e:"Gravity Behemoth",ra:"SR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:2300,de:2000,tx:"フィールド上にカードが存在しない場合、このカードはリリースなしで召喚する事ができる。\nこの効果は先攻１ターン目には使用する事ができない。\nこのカードは攻撃する代わりにフィールド上に存在するフィールド魔法カードを破壊する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"雲魔物－アイ・オブ・ザ・タイフーン",h:"くらうでぃあん－あい・おぶ・ざ・たいふーん",e:"Cloudian - Eye of the Typhoon",ra:"UR",t:"monster",a:"風",l:8,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:3000,de:1000,tx:"このカードは戦闘によっては破壊されない。\nこのカードが表側守備表示でフィールド上に存在する場合、このカードを破壊する。\nこのカードの攻撃宣言時、「雲魔物」と名のついたカード以外の全ての表側表示モンスターの表示形式を変更する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"雲魔物－アシッド・クラウド",h:"くらうでぃあん－あしっど・くらうど",e:"Cloudian - Acid Cloud",ra:"R",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:500,de:0,tx:"このカードは戦闘によっては破壊されない。\nこのカードが表側守備表示でフィールド上に存在する場合、このカードを破壊する。\nこのカードの召喚に成功した時、フィールド上に存在する「雲魔物」と名のついたモンスターの数だけこのカードにフォッグカウンターを置く。\nこのカードに乗っているフォッグカウンターを２つ取り除く事で、フィールド上の魔法または罠カード１枚を破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"雲魔物－アルトス",h:"くらうでぃあん－あるとす",e:"Cloudian - Altus",ra:"R",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1300,de:0,tx:"このカードは戦闘によっては破壊されない。\nこのカードが表側守備表示でフィールド上に存在する場合、このカードを破壊する。\nこのカードの召喚に成功した時、フィールド上に存在する「雲魔物」と名のついたモンスターの数だけこのカードにフォッグカウンターを置く。\nフィールド上に存在するフォッグカウンターを３つ取り除く事で、相手の手札をランダムに１枚捨てる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"雲魔物－キロスタス",h:"くらうでぃあん－きろすたす",e:"Cloudian - Cirrostratus",ra:"R",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:900,de:0,tx:"このカードは戦闘によっては破壊されない。\nこのカードが表側守備表示でフィールド上に存在する場合、このカードを破壊する。\nこのカードの召喚に成功した時、フィールド上に存在する「雲魔物」と名のついたモンスターの数だけこのカードにフォッグカウンターを置く。\nこのカードに乗っているフォッグカウンターを２つ取り除く事で、フィールド上のモンスター１体を破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"雲魔物－ゴースト・フォッグ",h:"くらうでぃあん－ごーすと・ふぉっぐ",e:"Cloudian - Ghost Fog",ra:"N",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカードは特殊召喚できない。\nこのカードの戦闘によって発生するお互いのプレイヤーへの戦闘ダメージは０になる。\nこのカードが戦闘によって破壊された場合、このカードを破壊したモンスターのレベルの数だけフォッグカウンターをフィールド上に表側表示で存在するモンスターに置く。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"雲魔物－羊雲",h:"くらうでぃあん－しーぷくらうど",e:"Cloudian - Sheep Cloud",ra:"R",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカードが戦闘によって破壊され墓地へ送られた時、自分フィールド上に「雲魔物トークン」（天使族・水・星１・攻／守０）を２体守備表示で特殊召喚する。\nこのトークンは「雲魔物」と名のついたカード以外の生け贄召喚のための生け贄にはできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"雲魔物－ストーム・ドラゴン",h:"くらうでぃあん－すとーむ・どらごん",e:"Cloudian - Storm Dragon",ra:"R",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"天使族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1000,de:0,tx:"このカードは通常召喚できない。\n自分の墓地の「雲魔物」と名のついたモンスター１体をゲームから除外して特殊召喚する。\nこのカードは戦闘によっては破壊されない。\nこのカードが表側守備表示でフィールド上に存在する場合、このカードを破壊する。\n１ターンに１度だけ、フィールド上モンスター１体にフォッグカウンターを１つ置く事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"雲魔物－スモークボール",h:"くらうでぃあん－すもーくぼーる",e:"Cloudian - Smoke Ball",ra:"N",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:200,de:600,tx:"小さな小さな雲魔物の子供雲。\nひとりぼっちが大嫌いで、仲間達とそよ風に乗ってゆらゆらと散歩をするのが大好き。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"雲魔物－タービュランス",h:"くらうでぃあん－たーびゅらんす",e:"Cloudian - Turbulence",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:800,de:0,tx:"このカードは戦闘では破壊されない。\nこのカードがフィールド上に表側守備表示で存在する場合、このカードを破壊する。\nこのカードが召喚に成功した時、フィールド上の「雲魔物」と名のついたモンスターの数だけこのカードにフォッグカウンターを置く。\nまた、このカードに乗っているフォッグカウンターを１つ取り除く事で、自分のデッキまたはお互いの墓地から「雲魔物－スモークボール」１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"雲魔物－ニンバスマン",h:"くらうでぃあん－にんばすまん",e:"Cloudian - Nimbusman",ra:"N",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードは戦闘によっては破壊されない。\nこのカードが表側守備表示でフィールド上に存在する場合、このカードを破壊する。\nこのカードを生け贄召喚する場合、自分フィールド上の水属性モンスターを任意の数だけ生け贄にできる。\nこのカードの生け贄召喚に成功した時、生け贄に捧げた水属性モンスターの数だけフォッグカウンターをこのカードに置く。\nこのカードの攻撃力はフォッグカウンター１つにつき５００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"雲魔物－ポイズン・クラウド",h:"くらうでぃあん－ぽいずん・くらうど",e:"Cloudian - Poison Cloud",ra:"R",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:1000,tx:"フィールド上に表側表示で存在するこのカードが戦闘によって破壊され墓地へ送られた時、このカードを破壊したモンスターを破壊し相手ライフに８００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"海月－ジェリーフィッシュ－",h:"くらげ－じぇりーふぃっしゅ－",e:"Jellyfish",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1200,de:1500,tx:"海を漂うクラゲ。\n半透明の身体で姿を確認しにくい。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"クラスター・ペンデュラム",h:"くらすたー・ぺんでゅらむ",e:"Cosmic Compass",ra:"N",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:100,de:300,tx:"①：このカードが召喚に成功した時に発動できる。\n相手フィールドのモンスターの数まで、自分フィールドに「ペンデュラム・トークン」（機械族・地・星１・攻／守０）を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"グラスファントム",h:"ぐらすふぁんとむ",e:"Grass Phantom",ra:"N",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードの攻撃力は、自分の墓地に存在する「グラスファントム」の枚数×５００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"グラッジ",h:"ぐらっじ",e:"Mucus Yolk",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:0,de:100,tx:"このカードは相手プレイヤーに直接攻撃をする事ができる。\nこのカードが相手プレイヤーに戦闘ダメージを与えた場合、次の自分のスタンバイフェイズ時にこのカードの攻撃力は１０００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"クラッシュマン",h:"くらっしゅまん",e:"Togex",ra:"R",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:1600,de:1800,tx:"意外に素早く動き回り、丸まって体当たりをしてくる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"グラップラー",h:"ぐらっぷらー",e:"Grappler",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["通常"],ma:"",tp:"",at:1300,de:1200,tx:"ずるがしこいヘビ。\n太くて長い身体で締め付ける攻撃に注意！",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"剣闘獣アウグストル",h:"ぐらでぃあるびーすとあうぐすとる",e:"Gladiator Beast Augustus",ra:"N",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2600,de:1000,tx:"①：このカードが「剣闘獣」モンスターの効果で特殊召喚に成功した時に発動できる。\n手札から「剣闘獣」モンスター１体を守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターはエンドフェイズに持ち主のデッキに戻る。\n②：このカードが戦闘を行ったバトルフェイズ終了時にこのカードを持ち主のデッキに戻して発動できる。\nデッキから「剣闘獣アウグストル」以外の「剣闘獣」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"剣闘獣アトリクス",h:"ぐらでぃあるびーすとあとりくす",e:"Gladiator Beast Attorix",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:800,de:2000,tx:"①：このカードが「剣闘獣」モンスターの効果で特殊召喚に成功した場合、デッキ・EXデッキから「剣闘獣アトリクス」以外の「剣闘獣」モンスター１体を墓地へ送って発動できる。\nエンドフェイズまで、このカードは墓地へ送ったモンスターと同じレベルになり、同名カードとして扱う。\n②：このカードが戦闘を行ったバトルフェイズ終了時にこのカードを持ち主のデッキに戻して発動できる。\nデッキから「剣闘獣アトリクス」以外の「剣闘獣」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"剣闘獣アレクサンデル",h:"ぐらでぃあるびーすとあれくさんでる",e:"Gladiator Beast Alexander",ra:"R",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:2400,de:600,tx:"「剣闘獣ディカエリィ」以外の効果によって、このカードを特殊召喚する事はできない。\n特殊召喚されたこのカードは自分フィールド上に表側表示で存在する限り、魔法の効果を受けない。\nこのカードが戦闘を行ったバトルフェイズ終了時にこのカードをデッキに戻す事で、デッキから「剣闘獣アレクサンデル」以外の「剣闘獣」と名のついたモンスター１体を自分フィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"剣闘獣アンダバタエ",h:"ぐらでぃあるびーすとあんだばたえ",e:"Gladiator Beast Andabata",ra:"R",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"獣戦士族",mo:["融合","効果"],ma:"",tp:"",at:1000,de:2800,tx:"「剣闘獣アウグストル」＋「剣闘獣」モンスター×２\n自分フィールドの上記カードをデッキに戻した場合のみ、EXデッキから特殊召喚できる（「融合」は必要としない）。\n①：このカードが上記の方法で特殊召喚に成功した場合に発動できる。\nEXデッキからレベル７以下の「剣闘獣」融合モンスター１体を召喚条件を無視して特殊召喚する。\n②：このカードが戦闘を行ったバトルフェイズ終了時にこのカードを持ち主のEXデッキに戻して発動できる。\nデッキから「剣闘獣」モンスター２体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"剣闘獣アンダル",h:"ぐらでぃあるびーすとあんだる",e:"Gladiator Beast Andal",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["通常"],ma:"",tp:"",at:1900,de:1500,tx:"高い攻撃力で敵を追いつめる、隻眼の戦闘グマ。\n恐るべきスピードと重さを誇る自慢のパンチを受けて倒れぬ者はいない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"剣闘獣ウェスパシアス",h:"ぐらでぃあるびーすとうぇすぱしあす",e:"Gladiator Beast Vespasius",ra:"UR",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:2300,de:0,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分の「剣闘獣」モンスターがモンスターと戦闘を行うダメージステップ開始時に発動できる。\nこのカードを手札から特殊召喚する。\n②：「剣闘獣」モンスターの効果で特殊召喚したこのカードがモンスターゾーンに存在する限り、自分フィールドのモンスターの攻撃力は５００アップする。\n③：このカードが戦闘を行ったバトルフェイズ終了時にこのカードを持ち主のデッキに戻して発動できる。\nデッキから「剣闘獣ウェスパシアス」以外の「剣闘獣」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"剣闘獣エクイテ",h:"ぐらでぃあるびーすとえくいて",e:"Gladiator Beast Equeste",ra:"SR",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"このカードが「剣闘獣」と名のついたモンスターの効果によって特殊召喚に成功した時、自分の墓地の「剣闘獣」と名のついたカード１枚を選択して手札に加える。\nこのカードが戦闘を行ったバトルフェイズ終了時にこのカードをデッキに戻す事で、デッキから「剣闘獣エクイテ」以外の「剣闘獣」と名のついたモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"剣闘獣エセダリ",h:"ぐらでぃあるびーすとえせだり",e:"Gladiator Beast Essedarii",ra:"R",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"獣族",mo:["融合","効果"],ma:"",tp:"",at:2500,de:1400,tx:"「剣闘獣」と名のついたモンスター×２\n自分フィールド上の上記のカードをデッキに戻した場合のみ、エクストラデッキから特殊召喚できる（「融合」魔法カードは必要としない）。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"剣闘獣オクタビウス",h:"ぐらでぃあるびーすとおくたびうす",e:"Gladiator Beast Octavius",ra:"N",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2500,de:1200,tx:"このカードが「剣闘獣」と名のついたモンスターの効果によって特殊召喚に成功した時、魔法＆罠カードゾーンにセットされたカード１枚を破壊する。\nこのカードが戦闘を行った自分のバトルフェイズ終了時に、手札を１枚捨てるかこのカードをデッキに戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"剣闘獣ガイザレス",h:"ぐらでぃあるびーすとがいざれす",e:"Gladiator Beast Gyzarus",ra:"UR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"鳥獣族",mo:["融合","効果"],ma:"",tp:"",at:2400,de:1500,tx:"「剣闘獣ベストロウリィ」＋「剣闘獣」モンスター\n自分フィールドの上記カードをデッキに戻した場合のみ、EXデッキから特殊召喚できる（「融合」は必要としない）。\n①：このカードが特殊召喚に成功した時、フィールドのカードを２枚まで対象として発動できる。\nそのカードを破壊する。\n②：このカードが戦闘を行ったバトルフェイズ終了時にこのカードを持ち主のEXデッキに戻して発動できる。\nデッキから「剣闘獣ベストロウリィ」以外の「剣闘獣」モンスター２体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"剣闘獣ゲオルディアス",h:"ぐらでぃあるびーすとげおるでぃあす",e:"Gladiator Beast Gaiodiaz",ra:"R",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"恐竜族",mo:["融合","効果"],ma:"",tp:"",at:2600,de:1500,tx:"「剣闘獣スパルティクス」＋「剣闘獣」と名のついたモンスター\n自分フィールド上に存在する上記のカードをデッキに戻した場合のみ、融合デッキから特殊召喚が可能（「融合」魔法カードは必要としない）。\nこのカードが戦闘によってモンスターを破壊し墓地へ送った時、破壊したモンスターの守備力分のダメージを相手ライフに与える。\nこのカードが戦闘を行ったバトルフェイズ終了時にこのカードを融合デッキに戻す事で、デッキから「剣闘獣スパルティクス」以外の「剣闘獣」と名のついたモンスター２体を自分フィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"剣闘獣サジタリィ",h:"ぐらでぃあるびーすとさじたりぃ",e:"Gladiator Beast Sagittarii",ra:"R",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1400,de:1000,tx:"①：このカードが「剣闘獣」モンスターの効果で特殊召喚に成功した場合、手札から「剣闘獣」カード１枚を捨てて発動できる。\n自分はデッキから２枚ドローする。\n②：このカードが戦闘を行ったバトルフェイズ終了時にこのカードを持ち主のデッキに戻して発動できる。\nデッキから「剣闘獣サジタリィ」以外の「剣闘獣」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"剣闘獣サムニテ",h:"ぐらでぃあるびーすとさむにて",e:"Gladiator Beast Samnite",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"このカードが「剣闘獣」と名のついたモンスターの効果によって特殊召喚に成功した場合、このカードが戦闘によって相手モンスターを破壊し墓地へ送った時、自分のデッキから「剣闘獣」と名のついたカード１枚を手札に加える事ができる。\nこのカードが戦闘を行ったバトルフェイズ終了時にこのカードをデッキに戻す事で、デッキから「剣闘獣サムニテ」以外の「剣闘獣」と名のついたモンスター１体を自分フィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"剣闘獣スパルティクス",h:"ぐらでぃあるびーすとすぱるてぃくす",e:"Gladiator Beast Spartacus",ra:"N",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:2200,de:1600,tx:"「剣闘獣ホプロムス」以外の効果によって、このカードを特殊召喚する事はできない。\nこのカードが特殊召喚に成功した時、デッキから「闘器」と名のついた装備魔法カード１枚を手札に加える。\nこのカードが戦闘を行ったバトルフェイズ終了時にこのカードをデッキに戻す事で、デッキから「剣闘獣スパルティクス」以外の「剣闘獣」と名のついたモンスター１体を自分フィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"剣闘獣セクトル",h:"ぐらでぃあるびーすとせくとる",e:"Gladiator Beast Secutor",ra:"N",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:400,de:300,tx:"「剣闘獣」と名のついたモンスターの効果によって特殊召喚したこのカードが戦闘を行ったバトルフェイズ終了時、デッキから「剣闘獣セクトル」以外の「剣闘獣」と名のついたモンスター２体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"剣闘獣ダリウス",h:"ぐらでぃあるびーすとだりうす",e:"Gladiator Beast Darius",ra:"UR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:300,tx:"①：このカードが「剣闘獣」モンスターの効果で特殊召喚に成功した時、自分の墓地の「剣闘獣」モンスター１体を対象として発動できる。\nそのモンスターを効果を無効にして特殊召喚する。\nこのカードがフィールドから離れた時にそのモンスターは持ち主のデッキに戻る。\n②：このカードが戦闘を行ったバトルフェイズ終了時にこのカードを持ち主のデッキに戻して発動できる。\nデッキから「剣闘獣ダリウス」以外の「剣闘獣」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"剣闘獣ディカエリィ",h:"ぐらでぃあるびーすとでぃかえりぃ",e:"Gladiator Beast Dimacari",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"このカードが「剣闘獣」と名のついたモンスターの効果によって特殊召喚に成功した場合、このカードは１度のバトルフェイズ中に２回攻撃をする事ができる。\nこのカードが戦闘を行ったバトルフェイズ終了時にこのカードをデッキに戻す事で、デッキから「剣闘獣ディカエリィ」以外の「剣闘獣」と名のついたモンスター１体を自分フィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"剣闘獣ティゲル",h:"ぐらでぃあるびーすとてぃげる",e:"Gladiator Beast Tygerius",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1800,de:800,tx:"このカードは融合素材モンスターとして使用する事はできない。\nこのカードが「剣闘獣」と名のついたモンスターの効果によって特殊召喚に成功した時、手札から「剣闘獣」と名のついたカード１枚を捨てる事で、自分のデッキから「剣闘獣」と名のついたモンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"剣闘獣ドミティアノス",h:"ぐらでぃあるびーすとどみてぃあのす",e:"Gladiator Beast Domitianus",ra:"R",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"海竜族",mo:["融合","効果"],ma:"",tp:"",at:3500,de:1200,tx:"「剣闘獣ウェスパシアス」＋「剣闘獣」モンスター×２\n自分フィールドの上記カードをデッキに戻した場合のみ、EXデッキから特殊召喚できる（「融合」は必要としない）。\n①：１ターンに１度、相手がモンスターの効果を発動した時に発動できる。\nその発動を無効にし破壊する。\n②：このカードがモンスターゾーンに存在する限り、相手モンスターの攻撃対象は自分が選択する。\n③：このカードが戦闘を行ったバトルフェイズ終了時にこのカードを持ち主のEXデッキに戻して発動できる。\nデッキから「剣闘獣」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"剣闘獣トラケス",h:"ぐらでぃあるびーすととらけす",e:"Gladiator Beast Torax",ra:"N",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1400,de:400,tx:"このカードが「剣闘獣」と名のついたモンスターの効果によって特殊召喚に成功した場合、このカードが戦闘を行ったバトルフェイズ終了時にこのカードをデッキに戻す事で、自分のデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"剣闘獣ネロキウス",h:"ぐらでぃあるびーすとねろきうす",e:"Gladiator Beast Nerokius",ra:"SR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"鳥獣族",mo:["融合","効果"],ma:"",tp:"",at:2800,de:1900,tx:"「剣闘獣」モンスター×３\n自分フィールドの上記カードをデッキに戻した場合のみ、エクストラデッキから特殊召喚できる（「融合」は必要としない）。\n①：このカードは戦闘では破壊されず、このカードが戦闘を行う場合、相手はダメージステップ終了時まで魔法・罠・モンスターの効果を発動できない。\n②：このカードが戦闘を行ったバトルフェイズ終了時にこのカードをエクストラデッキに戻して発動できる。\nデッキから「剣闘獣」モンスター２体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"剣闘獣ベストロウリィ",h:"ぐらでぃあるびーすとべすとろうりぃ",e:"Gladiator Beast Bestiari",ra:"R",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1500,de:800,tx:"①：このカードが「剣闘獣」モンスターの効果で特殊召喚に成功した場合、フィールドの魔法・罠カード１枚を対象として発動する。\nそのカードを破壊する。\n②：このカードが戦闘を行ったバトルフェイズ終了時にこのカードを持ち主のデッキに戻して発動できる。\nデッキから「剣闘獣ベストロウリィ」以外の「剣闘獣」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"剣闘獣ヘラクレイノス",h:"ぐらでぃあるびーすとへらくれいのす",e:"Gladiator Beast Heraklinos",ra:"UR",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"獣戦士族",mo:["融合","効果"],ma:"",tp:"",at:3000,de:2800,tx:"「剣闘獣ラクエル」＋「剣闘獣」と名のついたモンスター×２\n自分フィールド上の上記のカードをデッキに戻した場合のみ、エクストラデッキから特殊召喚できる（「融合」魔法カードは必要としない）。\nこのカードがフィールド上に表側表示で存在する限り、手札を１枚捨てる事で、魔法・罠カードの発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"剣闘獣ホプロムス",h:"ぐらでぃあるびーすとほぷろむす",e:"Gladiator Beast Hoplomus",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:700,de:2100,tx:"このカードが「剣闘獣」と名のついたモンスターの効果によって特殊召喚に成功した場合、このカードの元々の守備力は２４００になる。\nこのカードが戦闘を行ったバトルフェイズ終了時にこのカードをデッキに戻す事で、デッキから「剣闘獣ホプロムス」以外の「剣闘獣」と名のついたモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"剣闘獣ムルミロ",h:"ぐらでぃあるびーすとむるみろ",e:"Gladiator Beast Murmillo",ra:"R",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:800,de:400,tx:"このカードが「剣闘獣」と名のついたモンスターの効果によって特殊召喚に成功した時、フィールド上に表側表示で存在するモンスター１体を選択して破壊する。\nこのカードが戦闘を行ったバトルフェイズ終了時にこのカードをデッキに戻す事で、デッキから「剣闘獣ムルミロ」以外の「剣闘獣」と名のついたモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"剣闘獣ラクエル",h:"ぐらでぃあるびーすとらくえる",e:"Gladiator Beast Laquari",ra:"SR",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:400,tx:"このカードが「剣闘獣」と名のついたモンスターの効果によって特殊召喚に成功した場合、このカードの元々の攻撃力は２１００になる。\nこのカードが戦闘を行ったバトルフェイズ終了時にこのカードをデッキに戻す事で、デッキから「剣闘獣ラクエル」以外の「剣闘獣」と名のついたモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"剣闘獣ラニスタ",h:"ぐらでぃあるびーすとらにすた",e:"Gladiator Beast Lanista",ra:"N",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1800,de:1200,tx:"このカードが「剣闘獣」と名のついたモンスターの効果によって特殊召喚に成功した時、自分の墓地に存在する「剣闘獣」と名のついたモンスター１体を選択して発動する事ができる。\n選択したモンスターをゲームから除外し、エンドフェイズ時までそのモンスターと同名カードとして扱う。\nこのカードが戦闘を行ったバトルフェイズ終了時にこのカードをデッキに戻す事で、デッキから「剣闘獣ラニスタ」以外の「剣闘獣」と名のついたモンスター１体を自分フィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"剣闘獣レティアリィ",h:"ぐらでぃあるびーすとれてぃありぃ",e:"Gladiator Beast Retiari",ra:"R",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1200,de:800,tx:"このカードが「剣闘獣」と名のついたモンスターの効果によって特殊召喚に成功した時、相手の墓地のカード１枚を選択して除外できる。\nこのカードが戦闘を行ったバトルフェイズ終了時にこのカードをデッキに戻す事で、デッキから「剣闘獣レティアリィ」以外の「剣闘獣」と名のついたモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"グラナドラ",h:"ぐらなどら",e:"Granadora",ra:"SR",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1900,de:700,tx:"このモンスターが召喚・反転召喚・特殊召喚に成功した時、自分は１０００ライフポイント回復する。\nこのカードが破壊され墓地へ送られた時、自分は２０００ポイントダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"グラビ・クラッシュドラゴン",h:"ぐらび・くらっしゅどらごん",e:"Gravi-Crush Dragon",ra:"SR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2400,de:1200,tx:"①：自分フィールドの表側表示の永続魔法カード１枚を墓地へ送り、相手フィールドのモンスター１体を対象として発動できる。\nその相手モンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"クラブ・タートル",h:"くらぶ・たーとる",e:"Crab Turtle",ra:"R",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"水族",mo:["儀式"],ma:"",tp:"",at:2550,de:2500,tx:"「亀の誓い」により降臨。\nフィールドか手札から、レベルが８以上になるようカードを生け贄に捧げなければならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"グランド・スパイダー",h:"ぐらんど・すぱいだー",e:"Ground Spider",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:0,de:1500,tx:"このカードが自分フィールド上に表側守備表示で存在する場合に相手がモンスターの召喚・特殊召喚に成功した時、そのモンスターを守備表示にする事ができる。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"グランド・ドラゴン",h:"ぐらんど・どらごん",e:"Cave Dragon",ra:"R",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2000,de:100,tx:"自分フィールド上にモンスターが存在する場合、このカードを召喚する事はできない。\nこのカードは自分フィールド上にこのカード以外のドラゴン族モンスターが表側表示で存在する場合のみ攻撃宣言する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"グランドラン",h:"ぐらんどらん",e:"Grandram",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:0,de:1400,tx:"相手フィールド上にエクシーズモンスターが存在する場合、このカードは手札から表側攻撃表示で特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"クリア・エフェクター",h:"くりあ・えふぇくたー",e:"Clear Effector",ra:"R",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:0,de:900,tx:"①：このカードがS素材として墓地へ送られた場合に発動する。\n自分はデッキから１枚ドローする。\n②：このカードをS素材としたSモンスターは効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"グリード・クエーサー",h:"ぐりーど・くえーさー",e:"Greed Quasar",ra:"N",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:"?",de:"?",tx:"①：このカードの元々の攻撃力・守備力は、このカードのレベル×３００になる。\n②：このカードがモンスターゾーンに存在する限り、このカードのレベルはこのカードが戦闘で破壊したモンスターの元々のレベル分だけ上がる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"グリーン・ガジェット",h:"ぐりーん・がじぇっと",e:"Green Gadget",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1400,de:600,tx:"①：このカードが召喚・特殊召喚に成功した時に発動できる。\nデッキから「レッド・ガジェット」１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"グリーン・ダストン",h:"ぐりーん・だすとん",e:"Green Duston",ra:"R",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:1000,tx:"このカードはリリースできず、融合・シンクロ・エクシーズ召喚の素材にもできない。\nフィールド上のこのカードが破壊された時、このカードのコントローラーは自分フィールド上の魔法・罠カード１枚を選択して持ち主の手札に戻す。\n「グリーン・ダストン」は自分フィールド上に１体しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"緑光の宣告者",h:"ぐりーん・でくれあらー",e:"Herald of Green Light",ra:"SR",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:300,de:500,tx:"①：相手が魔法カードを発動した時、手札からこのカードと天使族モンスター１体を墓地へ送って発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"クリエイト・リゾネーター",h:"くりえいと・りぞねーたー",e:"Creation Resonator",ra:"N",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:600,tx:"自分フィールド上にレベル８以上のシンクロモンスターが表側表示で存在する場合、このカードは手札から特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"クリオスフィンクス",h:"くりおすふぃんくす",e:"Criosphinx",ra:"R",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1200,de:2400,tx:"このカードが自分フィールド上に表側表示で存在する限り、フィールド上のモンスターが持ち主の手札に戻った時、そのモンスターの持ち主は手札からカードを１枚選択して墓地に送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"グリグル",h:"ぐりぐる",e:"Griggle",ra:"R",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:350,de:300,tx:"フィールド上に表側表示で存在するこのカードのコントロールが相手に移った時、自分は３０００ライフポイント回復する。\nこの効果はこのカードがフィールド上に表側表示で存在する限り１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"クリスタル・ドラゴン",h:"くりすたる・どらごん",e:"Krystal Dragon",ra:"SR",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2500,de:1000,tx:"①：１ターンに１度、このカードが戦闘を行った自分ターンのバトルステップに発動できる。\nデッキからドラゴン族・レベル８モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"クリスタル・ローズ",h:"くりすたる・ろーず",e:"Crystal Rose",ra:"SR",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:500,de:500,tx:"「クリスタル・ローズ」の②の効果は１ターンに１度しか使用できない。\n①：１ターンに１度、自分メインフェイズに発動できる。\n手札・デッキから「ジェムナイト」モンスターまたは「幻奏」モンスター１体を墓地へ送る。\nエンドフェイズまで、このカードは墓地へ送ったモンスターと同名カードとして扱う。\n②：このカードが墓地に存在する場合、自分の墓地から融合モンスター１体を除外して発動できる。\nこのカードを守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"水晶機巧－アメトリクス",h:"くりすとろん－あめとりくす",e:"Crystron Ametrix",ra:"R",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:1500,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：このカードがS召喚に成功した場合に発動できる。\n相手フィールドの特殊召喚された表側表示モンスターを全て守備表示にする。\n②：S召喚したこのカードが戦闘・効果で破壊された場合、Sモンスター以外の自分の墓地の「クリストロン」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"水晶機巧－クオン",h:"くりすとろん－くおん",e:"Crystron Quan",ra:"N",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:500,de:500,tx:"「水晶機巧－クオン」の効果は１ターンに１度しか使用できない。\n①：相手のメインフェイズ及びバトルフェイズに発動できる。\n手札からチューナー以外のモンスター１体を効果を無効にして特殊召喚し、そのモンスターとこのカードのみを素材として機械族Sモンスター１体をS召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"水晶機巧－グリオンガンド",h:"くりすとろん－ぐりおんがんど",e:"Crystron Quariongandrax",ra:"UR",t:"monster",a:"水",l:9,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:3000,de:3000,tx:"チューナー２体以上＋チューナー以外のモンスター１体\n①：このカードがS召喚に成功した場合、そのS素材としたモンスターの数まで相手のフィールド・墓地のモンスターを対象として発動できる。\nそのモンスターを除外する。\n②：S召喚したこのカードが戦闘・効果で破壊された場合、このカード以外の除外されている自分または相手のモンスター１体を対象として発動できる。\nそのモンスターを自分フィールドに特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"水晶機巧－サルファフナー",h:"くりすとろん－さるふぁふなー",e:"Crystron Sulfefnir",ra:"UR",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2000,de:1500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが手札・墓地に存在する場合、手札から「水晶機巧－サルファフナー」以外の「クリストロン」カード１枚を捨てて発動できる。\nこのカードを守備表示で特殊召喚する。\nその後、自分フィールドのカード１枚を選んで破壊する。\n②：フィールドのこのカードが戦闘・効果で破壊された場合に発動できる。\nデッキから「クリストロン」モンスター１体を守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"水晶機巧－シストバーン",h:"くりすとろん－しすとばーん",e:"Crystron Thystvern",ra:"SR",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1500,de:1500,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードを破壊し、デッキから「クリストロン」チューナー１体を特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は機械族SモンスターしかEXデッキから特殊召喚できない。\n②：墓地のこのカードを除外して発動できる。\nデッキから「水晶機巧－シストバーン」以外の「クリストロン」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"水晶機巧－シトリィ",h:"くりすとろん－しとりぃ",e:"Crystron Citree",ra:"SR",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:500,de:500,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：相手のメインフェイズ及びバトルフェイズに、チューナー以外の自分の墓地のモンスター１体を対象として発動できる。\nそのモンスターを効果を無効にして特殊召喚し、そのモンスターとこのカードのみを素材として機械族Sモンスター１体をS召喚する。\nその時のS素材モンスターは墓地へは行かず除外される。",li:2,lk:[],ta:[],al:[],ocg:true},
  {n:"水晶機巧－スモーガー",h:"くりすとろん－すもーがー",e:"Crystron Smiger",ra:"SR",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:1800,tx:"「水晶機巧－スモーガー」の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードを破壊し、デッキから「クリストロン」チューナー１体を特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は機械族Sモンスターしかエクストラデッキから特殊召喚できない。\n②：墓地のこのカードを除外して発動できる。\nデッキから「クリストロン」魔法・罠カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"水晶機巧－プラシレータ",h:"くりすとろん－ぷらしれーた",e:"Crystron Prasiortle",ra:"N",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:500,de:2000,tx:"「水晶機巧－プラシレータ」の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードを破壊し、デッキから「クリストロン」チューナー１体を特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は機械族Sモンスターしかエクストラデッキから特殊召喚できない。\n②：墓地のこのカードを除外して発動できる。\n手札から「クリストロン」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"水晶機巧－リオン",h:"くりすとろん－りおん",e:"Crystron Rion",ra:"N",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:500,de:500,tx:"「水晶機巧－リオン」の効果は１ターンに１度しか使用できない。\n①：相手のメインフェイズ及びバトルフェイズに、チューナー以外の除外されている自分のモンスター１体を対象として発動できる。\nそのモンスターを効果を無効にして特殊召喚し、そのモンスターとこのカードのみを素材として機械族Sモンスター１体をS召喚する。\nその時のS素材モンスターは墓地へは行かず持ち主のデッキに戻る。",li:2,lk:[],ta:[],al:[],ocg:true},
  {n:"水晶機巧－ローズニクス",h:"くりすとろん－ろーずにくす",e:"Crystron Rosenix",ra:"R",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"「水晶機巧－ローズニクス」の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードを破壊し、デッキから「クリストロン」チューナー１体を特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は機械族Sモンスターしかエクストラデッキから特殊召喚できない。\n②：墓地のこのカードを除外して発動できる。\n自分フィールドに「水晶機巧トークン」（機械族・水・星１・攻／守０）１体を特殊召喚する。\nこのトークンはリリースできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"グリズリーマザー",h:"ぐりずりーまざー",e:"Mother Grizzly",ra:"R",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1400,de:1000,tx:"このカードが戦闘によって破壊され墓地へ送られた時、デッキから攻撃力１５００以下の水属性モンスター１体を表側攻撃表示で特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"クリッター",h:"くりったー",e:"Sangan",ra:"UR",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1000,de:600,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードがフィールドから墓地へ送られた場合に発動する。\nデッキから攻撃力１５００以下のモンスター１体を手札に加える。\nこのターン、自分はこの効果で手札に加えたカード及びその同名カードの効果を発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"クリッチー",h:"くりっちー",e:"Sanwitch",ra:"N",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["融合"],ma:"",tp:"",at:2100,de:1800,tx:"「クリッター」＋「黒き森のウィッチ」",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"クリフォート・アセンブラ",h:"くりふぉーと・あせんぶら",e:"Qliphort Monolith",ra:"R",t:"monster",a:"地",l:5,ps:1,pe:"①：自分は「クリフォート」モンスターしか特殊召喚できない。\nこの効果は無効化されない。\n②：自分がアドバンス召喚に成功したターンのエンドフェイズに発動できる。\nこのターン自分がアドバンス召喚のためにリリースした「クリフォート」モンスターの数だけ、自分はデッキからドローする。",tr:"機械族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:2400,de:1000,tx:"qliphoth.exe の ０x１i－６６６ でハンドルされていない例外を確認。\n\n場所 ０x００－０００ に書き込み中にアクセス違反が発生しました。\n\nこのエラーを無視し、続行しますか？ ＜Y／N＞...［ ］\n＝＝＝CARNAGE＝＝＝\nたッgなnトiのoモdる知rヲu悪o善yりナnにoウよyノrりgトnひaノれsワiれワdはo人Gヨ見\nイdなoレo知lもfカるeキr生iにf久永gベn食iてrッb取もoラtか木tノn命aべw伸ヲd手nはa彼",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"グリフォール",h:"ぐりふぉーる",e:"Griffore",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:1200,de:1500,tx:"かたい体で守ることが得意。\n半端な攻撃は、はじき返す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"クリボー",h:"くりぼー",e:"Kuriboh",ra:"SR",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:300,de:200,tx:"①：相手モンスターの攻撃で自分が戦闘ダメージを受けるダメージ計算時にこのカードを手札から捨てて発動できる。\nその戦闘で発生する自分への戦闘ダメージを０にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"クリボール",h:"くりぼーる",e:"Sphere Kuriboh",ra:"UR",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:300,de:200,tx:"①：相手モンスターの攻撃宣言時、このカードを手札から墓地へ送って発動できる。\nその攻撃モンスターを守備表示にする。\n②：儀式召喚を行う場合、必要なレベル分のモンスターの内の１体として、墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"クリボルト",h:"くりぼると",e:"Kurivolt",ra:"R",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:300,de:200,tx:"自分のメインフェイズ時、エクシーズ素材を持っているエクシーズモンスター１体を選択して発動できる。\n選択したモンスターのエクシーズ素材を１つ取り除き、自分のデッキから「クリボルト」１体を特殊召喚する。\nこのカードはシンクロ素材にできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"クリムゾン・リゾネーター",h:"くりむぞん・りぞねーたー",e:"Crimson Resonator",ra:"SR",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:300,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できず、このカードの効果を発動するターン、自分はドラゴン族・闇属性SモンスターしかEXデッキから特殊召喚できない。\n①：自分フィールドにモンスターが存在しない場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカード以外の自分フィールドのモンスターがドラゴン族・闇属性Sモンスター１体のみの場合に発動できる。\n手札・デッキから「クリムゾン・リゾネーター」以外の「リゾネーター」モンスターを２体まで特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"クルーエル",h:"くるーえる",e:"Kryuel",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1000,de:1700,tx:"このカードが戦闘によって破壊され墓地へ送られた時、相手フィールド上に存在するモンスター１体を選択して発動する。\nコイントスを１回行い、裏表を当てる。\n当たった場合、そのモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"クルセイダー・オブ・エンディミオン",h:"くるせいだー・おぶ・えんでぃみおん",e:"Crusader of Endymion",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["デュアル","効果"],ma:"",tp:"",at:1900,de:1200,tx:"①：このカードはフィールド・墓地に存在する限り、通常モンスターとして扱う。\n②：フィールドの通常モンスター扱いのこのカードを通常召喚としてもう１度召喚できる。\nその場合このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●１ターンに１度、フィールドの魔力カウンターを置く事ができるカード１枚を対象として発動できる。\nそのカードに魔力カウンターを１つ置き、このカードの攻撃力はターン終了時まで６００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"グレイ・ウイング",h:"ぐれい・ういんぐ",e:"Gray Wing",ra:"R",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1300,de:700,tx:"メインフェイズ１で手札からカードを１枚捨てる。\nこのカードはそのターンのバトルフェイズ中に２回攻撃をする事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"グレイヴ・オージャ",h:"ぐれいう゛・おーじゃ",e:"Grave Ohja",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1600,de:1500,tx:"自分フィールド上に裏側守備表示モンスターが存在する限り、このカードを攻撃対象に選択する事はできない。\n自分フィールド上のモンスターが反転召喚する度に、相手ライフに３００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"グレイヴ・キーパー",h:"ぐれいう゛・きーぱー",e:"Grave Protector",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:2000,tx:"このカードがフィールド上に表側表示で存在する限り、戦闘によって破壊されたモンスターは墓地へは行かず、持ち主のデッキに戻る。\nその後デッキをシャッフルする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"クレイジー・フィッシュ",h:"くれいじー・ふぃっしゅ",e:"Crazy Fish",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["通常"],ma:"",tp:"",at:1600,de:1200,tx:"鋭くとがっている頭の先を突き出して、攻撃してくるトビウオ。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"グレイドル・スライム",h:"ぐれいどる・すらいむ",e:"Graydle Slime",ra:"N",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"水族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:2000,tx:"「グレイドル・スライム」の①の効果は１ターンに１度しか使用できない。\n①：このカードが手札・墓地に存在する場合、自分フィールドの「グレイドル」カード２枚を対象として発動できる。\nそのカードを破壊し、このカードを特殊召喚する。\n②：このカードの①の効果で特殊召喚に成功した時、自分の墓地の「グレイドル」モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"グレイドル・スライムＪｒ．",h:"ぐれいどる・すらいむじゅにあ",e:"Graydle Slime Jr.",ra:"R",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"水族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:2000,tx:"①：このカードが召喚に成功した時、自分の墓地の「グレイドル」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nその後、この効果で特殊召喚したモンスターと同じレベルの水族モンスター１体を手札から特殊召喚できる。\nこの効果の発動後、ターン終了時まで自分は水属性モンスターしか特殊召喚できない。\n②：このカードが戦闘で破壊され墓地へ送られた時に発動できる。\nデッキから「グレイドル」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"グレイドル・ドラゴン",h:"ぐれいどる・どらごん",e:"Graydle Dragon",ra:"R",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"水族",mo:["シンクロ","効果"],ma:"",tp:"",at:3000,de:2000,tx:"水族チューナー＋チューナー以外のモンスター１体以上\n「グレイドル・ドラゴン」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがS召喚に成功した時、そのS素材とした水属性モンスターの数まで相手フィールドのカードを対象として発動できる。\nそのカードを破壊する。\n②：このカードが戦闘・効果で破壊され墓地へ送られた場合、このカード以外の自分の墓地の水属性モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"グレイブ・スクワーマー",h:"ぐれいぶ・すくわーまー",e:"Grave Squirmer",ra:"UR",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"①：このカードが戦闘で破壊され墓地へ送られた場合、フィールドのカード１枚を対象として発動する。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"グレート・アンガス",h:"ぐれーと・あんがす",e:"Great Angus",ra:"R",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:1800,de:600,tx:"狂ったように暴れ続けている、非常に凶暴な獣。\nおとなしい姿を見た者はいないと言う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"グレート・スピリット",h:"ぐれーと・すぴりっと",e:"Great Spirit",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:500,de:1500,tx:"このカードは１ターンに１度だけ裏側守備表示にする事ができる。\nこのカードが反転召喚に成功した時、フィールド上の地属性モンスター１体を選択して発動できる。\n選択した地属性モンスターの元々の攻撃力と元々の守備力を、エンドフェイズ時まで入れ替える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"偉大天狗",h:"ぐれーと・てんぐ",e:"Great Long Nose",ra:"N",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"獣戦士族",mo:["スピリット","効果"],ma:"",tp:"",at:1900,de:1700,tx:"このカードは特殊召喚できない。\n召喚・リバースしたターンのエンドフェイズ時に持ち主の手札に戻る。\nこのカードが相手ライフに戦闘ダメージを与えた場合、次の相手ターンのバトルフェイズをスキップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"グレード・ビル",h:"ぐれーど・びる",e:"Great Bill",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:1250,de:1300,tx:"どんなものでも丸飲みできる大きな口を持っている。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"グレート・ホワイト",h:"ぐれーと・ほわいと",e:"Great White",ra:"R",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["通常"],ma:"",tp:"",at:1600,de:800,tx:"巨大な白いサメ。\n大きな口で噛みつかれたら逃れられない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"グレート・モス",h:"ぐれーと・もす",e:"Great Moth",ra:"SR",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"昆虫族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2600,de:2500,tx:"このカードは通常召喚できない。\n「進化の繭」が装備され、自分のターンで数えて４ターン以上が経過した「プチモス」１体をリリースした場合に特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"偉大魔獣 ガーゼット",h:"ぐれーとまじゅう　がーぜっと",e:"Great Maju Garzett",ra:"UR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカードの攻撃力は、生け贄召喚時に生け贄に捧げたモンスター１体の元々の攻撃力を倍にした数値になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"クレーンクレーン",h:"くれーんくれーん",e:"Crane Crane",ra:"UR",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:300,de:900,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時、自分の墓地のレベル３モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"クレセント・ドラゴン",h:"くれせんと・どらごん",e:"Mikazukinoyaiba",ra:"N",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:2200,de:2350,tx:"月から来たといわれている、三日月状の刀を持つドラゴン戦士。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"クレボンス",h:"くれぼんす",e:"Krebons",ra:"SR",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"サイキック族",mo:["チューナー","効果"],ma:"",tp:"",at:1200,de:400,tx:"このカードが攻撃対象に選択された時、８００ライフポイントを払って発動できる。\nその攻撃を無効にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"グレムリン",h:"ぐれむりん",e:"Feral Imp",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1300,de:1400,tx:"いたずら好きの小さな悪魔。\n暗闇から襲ってくる。\n気をつけろ！",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"グレンザウルス",h:"ぐれんざうるす",e:"Grenosaurus",ra:"R",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"恐竜族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2000,de:1900,tx:"レベル３モンスター×２\nこのカードが戦闘によって相手モンスターを破壊し墓地へ送った時、このカードのエクシーズ素材を１つ取り除いて発動できる。\n相手ライフに１０００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"紅蓮地帯を飛ぶ鷹",h:"ぐれんちたいをとぶたか",e:"Soaring Eagle Above the Searing Land",ra:"N",t:"monster",a:"炎",l:1,ps:"",pe:"",tr:"鳥獣族",mo:["チューナー","効果"],ma:"",tp:"",at:100,de:0,tx:"このカードがシンクロモンスターのシンクロ召喚に使用され墓地へ送られた場合に自分の墓地の「ラヴァル」と名のついたモンスターが３種類以上の場合、このカードを墓地から特殊召喚できる。\nこの効果で特殊召喚したこのカードは、フィールド上から離れた場合ゲームから除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"紅蓮魔獣 ダ・イーザ",h:"ぐれんまじゅう　だ・いーざ",e:"Gren Maju Da Eiza",ra:"SR",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:"?",de:"?",tx:"①：このカードの攻撃力・守備力は、除外されている自分のカードの数×４００になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"紅蓮魔闘士",h:"ぐれんまとうし",e:"Knight of the Red Lotus",ra:"UR",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2100,de:1800,tx:"①：このカードは、自分の墓地の通常モンスターが３体の場合、その内の２体を除外し、手札から特殊召喚できる。\n②：１ターンに１度、自分の墓地のレベル４以下の通常モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"黒い影の鬼王",h:"くろいかげのおーが",e:"Ogre of the Black Shadow",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["通常"],ma:"",tp:"",at:1200,de:1400,tx:"黒い影にとりつかれたオーガ。\nすごいスピードで突撃してくる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"グローアップ・バルブ",h:"ぐろーあっぷ・ばるぶ",e:"Glow-Up Bulb",ra:"UR",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"植物族",mo:["チューナー","効果"],ma:"",tp:"",at:100,de:100,tx:"このカード名の効果はデュエル中に１度しか使用できない。\n①：このカードが墓地に存在する場合に発動できる。\n自分のデッキの一番上のカードを墓地へ送り、このカードを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"グローアップ・ブルーム",h:"ぐろーあっぷ・ぶるーむ",e:"Glow-Up Bloom",ra:"UR",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"アンデット族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが墓地へ送られた場合、墓地のこのカードを除外して発動できる。\nデッキからレベル５以上のアンデット族モンスター１体を手札に加える。\nフィールドゾーンに「アンデットワールド」が存在する場合、手札に加えず特殊召喚する事もできる。\nこの効果の発動後、ターン終了時まで自分はアンデット族モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"クローラー・アクソン",h:"くろーらー・あくそん",e:"Krawler Axon",ra:"SR",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"昆虫族",mo:["リバース","効果"],ma:"",tp:"",at:500,de:1800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがリバースした場合、フィールドの魔法・罠カード１枚を対象として発動できる。\nそのカードを破壊する。\n②：表側表示のこのカードが相手の効果でフィールドから離れた場合に発動できる。\n「クローラー・アクソン」以外の「クローラー」モンスター２体をデッキから裏側守備表示で特殊召喚する（同名カードは１枚まで）。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"クローラー・グリア",h:"くろーらー・ぐりあ",e:"Krawler Glial",ra:"R",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"昆虫族",mo:["リバース","効果"],ma:"",tp:"",at:700,de:1500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがリバースした場合に発動できる。\n自分の手札・墓地から「クローラー・グリア」以外の「クローラー」モンスター１体を選んで表側攻撃表示または裏側守備表示で特殊召喚する。\n②：表側表示のこのカードが相手の効果でフィールドから離れた場合に発動できる。\n「クローラー・グリア」以外の「クローラー」モンスター２体をデッキから裏側守備表示で特殊召喚する（同名カードは１枚まで）。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"クローラー・スパイン",h:"くろーらー・すぱいん",e:"Krawler Spine",ra:"UR",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"昆虫族",mo:["リバース","効果"],ma:"",tp:"",at:300,de:2100,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがリバースした場合、フィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊する。\n②：表側表示のこのカードが相手の効果でフィールドから離れた場合に発動できる。\n「クローラー・スパイン」以外の「クローラー」モンスター２体をデッキから裏側守備表示で特殊召喚する（同名カードは１枚まで）。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"クローラー・デンドライト",h:"くろーらー・でんどらいと",e:"Krawler Dendrite",ra:"R",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"昆虫族",mo:["リバース","効果"],ma:"",tp:"",at:1300,de:600,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがリバースした場合に発動できる。\nデッキからモンスター１体を墓地へ送る。\n②：表側表示のこのカードが相手の効果でフィールドから離れた場合に発動できる。\n「クローラー・デンドライト」以外の「クローラー」モンスター２体をデッキから裏側守備表示で特殊召喚する（同名カードは１枚まで）。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"クローラー・ランヴィエ",h:"くろーらー・らんう゛ぃえ",e:"Krawler Ranvier",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"昆虫族",mo:["リバース","効果"],ma:"",tp:"",at:1100,de:900,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがリバースした場合、自分の墓地の「クローラー」モンスターを２体まで対象として発動できる。\nそのモンスターを手札に加える。\n②：表側表示のこのカードが相手の効果でフィールドから離れた場合に発動できる。\n「クローラー・ランヴィエ」以外の「クローラー」モンスター２体をデッキから裏側守備表示で特殊召喚する（同名カードは１枚まで）。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"クローラー・レセプター",h:"くろーらー・れせぷたー",e:"Krawler Receptor",ra:"R",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"昆虫族",mo:["リバース","効果"],ma:"",tp:"",at:900,de:1200,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがリバースした場合に発動できる。\nデッキから「クローラー」モンスター１体を手札に加える。\n②：表側表示のこのカードが相手の効果でフィールドから離れた場合に発動できる。\n「クローラー・レセプター」以外の「クローラー」モンスター２体をデッキから裏側守備表示で特殊召喚する（同名カードは１枚まで）。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"黒き人食い鮫",h:"くろきひとくいざめ",e:"Man-eating Black Shark",ra:"N",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"魚族",mo:["融合"],ma:"",tp:"",at:2100,de:1300,tx:"「シーカーメン」＋「キラー・ブロッブ」＋「海原の女戦士」",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"黒き森のウィッチ",h:"くろきもりのうぃっち",e:"Witch of the Black Forest",ra:"UR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1100,de:1200,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードがフィールドから墓地へ送られた場合に発動する。\nデッキから守備力１５００以下のモンスター１体を手札に加える。\nこのターン、自分はこの効果で手札に加えたカード及びその同名カードの効果を発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"クロクロークロウ",h:"くろくろーくろう",e:"Caligo Claw Crow",ra:"R",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:900,de:600,tx:"自分フィールド上に闇属性モンスターが存在する場合、このカードは手札から特殊召喚できる。\nこの方法による「クロクロークロウ」の特殊召喚は１ターンに１度しかできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"クロコダイラス",h:"くろこだいらす",e:"Krokodilus",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["通常"],ma:"",tp:"",at:1100,de:1200,tx:"知恵を持ちさらに狂暴化したワニ。\nかたいうろこで攻撃をはじく。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"黒蠍－棘のミーネ",h:"くろさそり－いばらのみーね",e:"Dark Scorpion - Meanae the Thorn",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:1800,tx:"このカードが相手プレイヤーに戦闘ダメージを与えた時、次の効果から１つを選択して発動する事ができる。\n\n●「黒蠍」という名のついたカードを自分のデッキから１枚手札に加える。\n\n●「黒蠍」という名のついたカードを自分の墓地から１枚手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"黒蠍－強力のゴーグ",h:"くろさそり－ごうりきのごーぐ",e:"Dark Scorpion - Gorg the Strong",ra:"N",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1500,tx:"このカードが相手プレイヤーに戦闘ダメージを与えた時、次の効果から１つを選択して発動する事ができる。\n\n●相手フィールド上のモンスターカード１枚を持ち主のデッキの一番上に戻す。\n\n●相手のデッキの一番上のカードを１枚墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"黒蠍－逃げ足のチック",h:"くろさそり－にげあしのちっく",e:"Dark Scorpion - Chick the Yellow",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードが相手プレイヤーに戦闘ダメージを与えた時、次の効果から１つを選択して発動する事ができる。\n\n●フィールド上のカード１枚を持ち主の手札に戻す。\n\n●相手のデッキの一番上のカードを１枚めくる（相手は確認する事はできない）。\nそのカードをデッキの一番上か一番下かを選択して戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"黒蠍－罠はずしのクリフ",h:"くろさそり－わなはずしのくりふ",e:"Dark Scorpion - Cliff the Trap Remover",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:1000,tx:"このカードが相手プレイヤーに戦闘ダメージを与えた時、次の効果から１つを選択して発動する事ができる。\n\n●フィールド上の魔法または罠カード１枚を破壊する。\n\n●相手のデッキの上から２枚を墓地に送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"黒蠍盗掘団",h:"くろさそりとうくつだん",e:"Dark Scorpion Burglars",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードが相手ライフに戦闘ダメージを与えた時、相手はデッキから魔法カード１枚を墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"グロス",h:"ぐろす",e:"Twin Long Rods #1",ra:"N",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:900,de:700,tx:"ムチのように長い腕で、少し離れたところでも攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"クロス・ポーター",h:"くろす・ぽーたー",e:"Cross Porter",ra:"N",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:400,de:400,tx:"①：自分フィールドのモンスター１体を対象として発動できる。\nその自分のモンスターを墓地へ送り、手札から「N」モンスター１体を特殊召喚する。\n②：このカードが墓地へ送られた時に発動できる。\nデッキから「N」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"クロスソード・ハンター",h:"くろすそーど・はんたー",e:"Cross-Sword Beetle",ra:"N",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1800,de:1200,tx:"自分フィールド上にこのカード以外の昆虫族モンスターが存在する場合、自分フィールド上に存在する昆虫族モンスターが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"クロック・リゾネーター",h:"くろっく・りぞねーたー",e:"Clock Resonator",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:1200,de:600,tx:"このカードがフィールド上に表側守備表示で存在する限り、このカードは１ターンに１度だけ戦闘またはカードの効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"黒魔族のカーテン",h:"くろまぞくのかーてん",e:"Curtain of the Dark Ones",ra:"N",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:600,de:500,tx:"魔術師がつくりだしたカーテン。\n魔法使いの力が上がるという。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"黒魔導師クラン",h:"くろまどうしくらん",e:"Ebon Magician Curran",ra:"N",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1200,de:0,tx:"自分のスタンバイフェイズ時、相手フィールド上に存在するモンスターの数×３００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"黒魔導戦士 ブレイカー",h:"くろまどうせんし　ぶれいかー",e:"Breaker the Dark Magical Warrior",ra:"SR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"「黒魔導戦士 ブレイカー」の④の効果は１ターンに１度しか使用できない。\n①：このカードが召喚に成功した場合に発動する。\nこのカードに魔力カウンターを２つ置く。\n②：このカードがP召喚に成功した場合に発動する。\nこのカードに魔力カウンターを３つ置く。\n③：このカードの攻撃力は、このカードの魔力カウンターの数×４００アップする。\n④：このカードの魔力カウンターを１つ取り除き、フィールドの魔法・罠カード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"黒魔力の精製者",h:"くろまりょくのせいせいしゃ",e:"Alchemist of Black Spells",ra:"R",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1200,de:1800,tx:"１ターンに１度、自分フィールド上に表側攻撃表示で存在するこのカードを表側守備表示に変更し、自分フィールド上に表側表示で存在する魔力カウンターを置く事ができるカード１枚に魔力カウンターを１つ置く事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"クワガー・ヘラクレス",h:"くわがー・へらくれす",e:"Kwagar Hercules",ra:"N",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"昆虫族",mo:["融合"],ma:"",tp:"",at:1900,de:1700,tx:"「クワガタ・アルファ」＋「ヘラクレス・ビートル」",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"クワガタ・アルファ",h:"くわがた・あるふぁ",e:"Kuwagata α",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["通常"],ma:"",tp:"",at:1250,de:1000,tx:"凶暴なクワガタ。\n相手の首を狙うギロチンカッターに注意。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"グングニールの影霊衣",h:"ぐんぐにーるのねくろす",e:"Nekroz of Gungnir",ra:"R",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"魔法使い族",mo:["儀式","効果"],ma:"",tp:"",at:2500,de:1700,tx:"「影霊衣」儀式魔法カードにより降臨。\nレベル７以外のモンスターのみを使用した儀式召喚でしか特殊召喚できない。\n「グングニールの影霊衣」の①②の効果はそれぞれ１ターンに１度しか使用できず、相手ターンでも発動できる。\n①：このカードを手札から捨て、自分フィールドの「影霊衣」モンスター１体を対象として発動できる。\nこのターンそのモンスターは戦闘・効果では破壊されない。\n②：手札の「影霊衣」カード１枚を捨て、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"軍神ガープ",h:"ぐんしんがーぷ",e:"Gaap the Divine Soldier",ra:"R",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2200,de:2000,tx:"このカードがフィールド上に表側表示で存在する限り、フィールド上に存在するモンスターは全て表側攻撃表示となり、表示形式を変更する事ができない。\nこの時、リバース効果モンスターの効果は発動しない。\nまた、１ターンに１度、手札の悪魔族モンスターを任意の枚数見せる事で、このカードの攻撃力はエンドフェイズ時まで、見せた枚数×３００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"軍隊ピラニア",h:"ぐんたいぴらにあ",e:"Piranha Army",ra:"N",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:800,de:200,tx:"このカードが相手プレイヤーへの直接攻撃で与える戦闘ダメージは倍になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"軍荼利",h:"ぐんだり",e:"Gundari",ra:"SR",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["スピリット","効果"],ma:"",tp:"",at:1000,de:200,tx:"このカードは特殊召喚できない。\n召喚・リバースしたターンのエンドフェイズ時に持ち主の手札に戻る。\nこのカードがシンクロモンスターと戦闘を行う場合、ダメージ計算を行わず、そのモンスターとこのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゲイシャドウ",h:"げいしゃどう",e:"Maiden of Macabre",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1700,de:0,tx:"このカードが戦闘によって相手モンスターを破壊した場合、このカードに魔力カウンターを１つ置く。\nこのカードの攻撃力は、このカードに乗っている魔力カウンターの数×２００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゲート・ガーディアン",h:"げーと・がーでぃあん",e:"Gate Guardian",ra:"R",t:"monster",a:"闇",l:11,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3750,de:3400,tx:"このカードは通常召喚できない。\n自分フィールドの「雷魔神－サンガ」「風魔神－ヒューガ」「水魔神－スーガ」をそれぞれ１体ずつリリースした場合に特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゲート・キーパー",h:"げーと・きーぱー",e:"Gatekeeper",ra:"N",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1500,de:1800,tx:"入り口を守るために造られた機械。\n壊すのは大変だ！",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゲート・ブロッカー",h:"げーと・ぶろっかー",e:"Gate Blocker",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:100,de:2000,tx:"①：このカードがモンスターゾーンに存在する限り、相手は他の自分フィールドのモンスターを効果の対象にできず、フィールドのカードにカウンターを置く事はできない。\nまた、相手のフィールド魔法カードの効果は無効になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"激昂のミノタウルス",h:"げきこうのみのたうるす",e:"Enraged Battle Ox",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"①：このカードがモンスターゾーンに存在する限り、自分の獣族・獣戦士族・鳥獣族モンスターが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ相手に戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"撃滅龍 ダーク・アームド",h:"げきめつりゅう　だーく・あーむど",e:"Dark Armed, the Dragon of Annihilation",ra:"UR",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2800,de:1000,tx:"レベル７モンスター×２体以上\n「撃滅龍 ダーク・アームド」は１ターンに１度、自分の墓地の闇属性モンスターが５体のみの場合、自分フィールドのレベル５以上のドラゴン族・闇属性モンスターの上に重ねてX召喚する事もできる。\n①：このカードのX素材を１つ取り除き、相手フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\nその後、自分の墓地からカード１枚を選んで除外する。\nこの効果の発動後、ターン終了時までこのカードは攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"結界術師 メイコウ",h:"けっかいじゅつし　めいこう",e:"Mei-Kou, Master of Barriers",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1700,de:600,tx:"このカードをリリースして発動できる。\nフィールド上に表側表示で存在する永続魔法・永続罠カード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"激昂のムカムカ",h:"げっこうのむかむか",e:"Enraged Muka Muka",ra:"R",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1200,de:600,tx:"自分の手札１枚につき、このカードの攻撃力・守備力はそれぞれ４００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"月風魔",h:"げつふうま",e:"Getsu Fuhma",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:1200,tx:"このカードと戦闘を行ったモンスターが悪魔族・アンデット族の場合、ダメージステップ終了時にそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"血涙のオーガ",h:"けつるいのおーが",e:"Ogre of the Scarlet Sorrow",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"相手ターンの１度のバトルフェイズ中に２回目の直接攻撃が宣言された時、このカードを手札から特殊召喚する事ができる。\nこの効果で特殊召喚に成功した時、このカードの攻撃力・守備力はこのターンに１回目の直接攻撃を行った、フィールド上に表側表示で存在するモンスターと同じ数値になる。\nこのターン、この効果で特殊召喚したこのカードがフィールド上に表側表示で存在する限り、相手はこのカード以外のモンスターを攻撃対象に選択する事はできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゲリラカイト",h:"げりらかいと",e:"Guerilla Kite",ra:"SR",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:1600,de:200,tx:"「ゲリラカイト」の効果は１ターンに１度しか使用できない。\n①：このカードがフィールドから墓地へ送られた場合に発動する。\n相手に５００ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ケルドウ",h:"けるどう",e:"Keldo",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1200,de:1600,tx:"このカードが戦闘によって破壊され墓地に送られた時、相手の墓地からカードを２枚選択して相手のデッキに加えてシャッフルする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゲルニア",h:"げるにあ",e:"Gernia",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1300,de:1200,tx:"①：フィールドの表側表示のこのカードが相手の効果で破壊され墓地へ送られた場合、次の自分スタンバイフェイズに発動する。\nこのカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ケルベク",h:"けるべく",e:"Kelbek",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1500,de:1800,tx:"このカードを攻撃したモンスターは持ち主の手札に戻る。\nダメージ計算は適用する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻影王 ハイド・ライド",h:"げんえいおう　はいど・らいど",e:"Phantom King Hydride",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:1500,de:300,tx:"自分フィールド上に表側表示で存在するこのカードをシンクロ召喚に使用する場合、このカードをチューナー以外のモンスターとして扱う事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻影コオロギ",h:"げんえいこおろぎ",e:"Phantom Cricket",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"昆虫族",mo:["リバース","効果"],ma:"",tp:"",at:300,de:1000,tx:"リバース：フィールド上に裏側表示で存在するモンスター１体を持ち主のデッキの一番上に置く。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻影の壁",h:"げんえいのかべ",e:"Wall of Illusion",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1000,de:1850,tx:"このカードを攻撃したモンスターは持ち主の手札に戻る。\nダメージ計算は適用する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻影の騎士－ミラージュ・ナイト－",h:"げんえいのきし－みらーじゅ・ないと－",e:"Mirage Knight",ra:"SR",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2800,de:2000,tx:"このカードは「黒炎の騎士－ブラック・フレア・ナイト－」の効果でのみ特殊召喚が可能。\nダメージ計算時、このカードの攻撃力に相手モンスターの元々の攻撃力を加える。\n戦闘を行ったターンのエンドフェイズ時に、このカードをゲームから除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻影のゴラ亀",h:"げんえいのごらがめ",e:"Gora Turtle of Illusion",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1200,de:1400,tx:"フィールド上に表側表示で存在するこのカードを対象とした、相手がコントロールする魔法・罠カードの効果を無効にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻影の魔術士",h:"げんえいのまじゅつし",e:"Phantom Magician",ra:"SR",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:600,de:700,tx:"このカードが戦闘によって破壊され墓地へ送られた時、自分のデッキから攻撃力１０００以下の「HERO」と名のついたモンスター１体を表側守備表示で特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻影の妖精",h:"げんえいのようせい",e:"Dreamsprite",ra:"R",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:300,de:200,tx:"このカードが相手モンスターから攻撃を受けた場合、他の自分のフィールド上モンスター１体が攻撃を受けた事にし、ダメージ計算を行う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"限界竜シュヴァルツシルト",h:"げんかいりゅうしゅう゛ぁるつしると",e:"Schwarzschild Limit Dragon",ra:"R",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2000,de:0,tx:"①：相手フィールドに攻撃力２０００以上のモンスターが存在する場合、このカードは手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻角獣フュプノコーン",h:"げんかくじゅうふゅぷのこーん",e:"Hypnocorn",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1400,de:600,tx:"相手フィールド上にモンスターが存在し、自分フィールド上にモンスターが存在しない場合にこのカードが召喚に成功した時、フィールド上にセットされた魔法・罠カード１枚を選択して破壊する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"厳格な老魔術師",h:"げんかくなろうまじゅつし",e:"The Stern Mystic",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["リバース","効果"],ma:"",tp:"",at:1500,de:1200,tx:"①：このカードがリバースした場合に発動する。\nフィールドにセットされたカードを全て確認する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻殻竜",h:"げんかくりゅう",e:"Mystery Shell Dragon",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"幻竜族",mo:["通常"],ma:"",tp:"",at:2000,de:0,tx:"別次元から突如として飛来した謎の生命体。\n高い攻撃力と奇襲能力を併せ持つ。\nその攻撃は対象物の神経を麻痺させ、強烈な幻覚症状を引き起こす。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"原始太陽ヘリオス",h:"げんしたいようへりおす",e:"Helios - The Primordial Sun",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:"?",de:"?",tx:"このカードの攻撃力・守備力は、ゲームから除外されているモンスターの数×１００ポイントになる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"原子ホタル",h:"げんしほたる",e:"Atomic Firefly",ra:"N",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:100,de:200,tx:"フィールド上に表側表示で存在するこのカードを戦闘で破壊したプレイヤーは、１０００ポイントダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"賢者ケイローン",h:"けんじゃけいろーん",e:"Chiron the Mage",ra:"UR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"１ターンに１度、手札から魔法カード１枚を捨てて発動できる。\n相手フィールド上の魔法・罠カード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻獣王ガゼル",h:"げんじゅうおうがぜる",e:"Gazelle the King of Mythical Beasts",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:1500,de:1200,tx:"走るスピードが速すぎて、姿が幻のように見える獣。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻獣機ウォーブラン",h:"げんじゅうきうぉーぶらん",e:"Mecha Phantom Beast Warbluran",ra:"N",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:300,de:300,tx:"このカードが機械族モンスターのシンクロ召喚の素材として墓地へ送られた場合、「幻獣機トークン」（機械族・風・星３・攻／守０）１体を特殊召喚する。\nこの効果を適用したターン、自分は風属性以外のモンスターを特殊召喚できない。\n自分フィールド上にトークンが存在する限り、このカードは戦闘及び効果では破壊されない。\nまた、自分フィールド上の「幻獣機」と名のついたモンスター１体をリリースして発動できる。\nこのカードのレベルを１つ上げる。\n「幻獣機ウォーブラン」の効果は１ターンに１度しか発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻獣機エアロスバード",h:"げんじゅうきえあろすばーど",e:"Mecha Phantom Beast Aerosguin",ra:"N",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1600,de:400,tx:"①：１ターンに１度、「幻獣機エアロスバード」以外の自分の墓地の「幻獣機」モンスター１体を除外して発動できる。\n自分フィールドに「幻獣機トークン」（機械族・風・星３・攻／守０）１体を特殊召喚する。\n②：このカードのレベルは自分フィールドの「幻獣機トークン」のレベルの合計分だけ上がる。\n③：自分フィールドにトークンが存在する限り、このカードは戦闘・効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻獣機オライオン",h:"げんじゅうきおらいおん",e:"Mecha Phantom Beast O-Lion",ra:"UR",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:600,de:1000,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドにトークンが存在する限り、このカードは戦闘・効果では破壊されない。\n②：このカードが墓地へ送られた場合に発動できる。\n自分フィールドに「幻獣機トークン」（機械族・風・星３・攻／守０）１体を特殊召喚する。\n③：墓地のこのカードを除外して発動できる。\n手札から「幻獣機」モンスター１体を召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻獣機グリーフィン",h:"げんじゅうきぐりーふぃん",e:"Mecha Phantom Beast Kalgriffin",ra:"N",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:2500,tx:"自分のメインフェイズ時、自分フィールド上の「幻獣機」と名のついたモンスター２体をリリースして発動できる。\nこのカードを手札から特殊召喚する。\n「幻獣機グリーフィン」のこの効果は１ターンに１度しか使用できない。\n自分フィールド上にトークンが存在する限り、このカードは戦闘及び効果では破壊されない。\nまた、１ターンに１度、手札の「幻獣機」と名のついたモンスター１体を捨てて発動できる。\n「幻獣機トークン」（機械族・風・星３・攻／守０）１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻獣機コンコルーダ",h:"げんじゅうきこんこるーだ",e:"Mecha Phantom Beast Concoruda",ra:"R",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:2400,de:1200,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカードがフィールド上に表側表示で存在する限り、自分フィールド上のトークンは戦闘及び効果では破壊されない。\nフィールド上のこのカードが相手によって破壊され墓地へ送られた場合、自分フィールド上のトークンを全てリリースして発動できる。\n自分の墓地からレベル４以下の「幻獣機」と名のついたモンスター１体を選択して特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻獣機サーバルホーク",h:"げんじゅうきさーばるほーく",e:"Mecha Phantom Beast Sabre Hawk",ra:"R",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2100,de:100,tx:"このカードは直接攻撃できない。\n自分の墓地に「幻獣機」と名のついたモンスター以外のモンスターが存在する場合、このカードは攻撃できない。\nこのカードのレベルは自分フィールド上の「幻獣機トークン」のレベルの合計分だけ上がる。\n自分フィールド上にトークンが存在する限り、このカードは戦闘及び効果では破壊されない。\nまた、１ターンに１度、トークン１体をリリースして発動できる。\n自分または相手の墓地のカード１枚を選択してゲームから除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻獣機テザーウルフ",h:"げんじゅうきてざーうるふ",e:"Mecha Phantom Beast Tetherwolf",ra:"UR",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1700,de:1200,tx:"①：このカードが召喚に成功した場合に発動する。\n自分フィールドに「幻獣機トークン」（機械族・風・星３・攻／守０）１体を特殊召喚する。\n②：このカードのレベルは自分フィールドの「幻獣機トークン」のレベルの合計分だけ上がる。\n③：自分フィールドにトークンが存在する限り、このカードは戦闘・効果では破壊されない。\n④：このカードが相手モンスターと戦闘を行うダメージステップ開始時からダメージ計算前までに１度、自分フィールドのトークン１体をリリースして発動できる。\nこのカードの攻撃力はターン終了時まで８００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻獣機ドラゴサック",h:"げんじゅうきどらごさっく",e:"Mecha Phantom Beast Dracossack",ra:"UR",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2600,de:2200,tx:"レベル７モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\n自分フィールドに「幻獣機トークン」（機械族・風・星３・攻／守０）２体を特殊召喚する。\n②：自分フィールドにトークンが存在する限り、このカードは戦闘・効果では破壊されない。\n③：１ターンに１度、自分フィールドの「幻獣機」モンスター１体をリリースし、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\nこの効果を発動するターン、このカードは攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻獣機ハムストラット",h:"げんじゅうきはむすとらっと",e:"Mecha Phantom Beast Hamstrat",ra:"UR",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1100,de:1600,tx:"このカードがリバースした時、「幻獣機トークン」（機械族・風・星３・攻／守０）２体を特殊召喚する。\nこのカードのレベルは自分フィールド上の「幻獣機トークン」のレベルの合計分だけ上がる。\n自分フィールド上にトークンが存在する限り、このカードは戦闘及び効果では破壊されない。\nまた、トークン１体をリリースして発動できる。\n自分の墓地の「幻獣機」と名のついたモンスター１体を選択して特殊召喚する。\n「幻獣機ハムストラット」のこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻獣機ハリアード",h:"げんじゅうきはりあーど",e:"Mecha Phantom Beast Harrliard",ra:"SR",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1800,de:800,tx:"１ターンに１度、このカード以外のカードの効果を発動するために自分フィールド上のモンスターがリリースされた時、「幻獣機トークン」（機械族・風・星３・攻／守０）１体を特殊召喚する。\nこのカードのレベルは自分フィールド上の「幻獣機トークン」のレベルの合計分だけ上がる。\n自分フィールド上にトークンが存在する限り、このカードは戦闘及び効果では破壊されない。\nまた、１ターンに１度、トークン１体をリリースして発動できる。\n手札から「幻獣機」と名のついたモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻獣機ブラックファルコン",h:"げんじゅうきぶらっくふぁるこん",e:"Mecha Phantom Beast Blackfalcon",ra:"SR",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:1700,tx:"このカードが攻撃宣言した時、「幻獣機トークン」（機械族・風・星３・攻／守０）１体を特殊召喚する。\nこのカードのレベルは自分フィールド上の「幻獣機トークン」のレベルの合計分だけ上がる。\n自分フィールド上にトークンが存在する限り、このカードは戦闘及び効果では破壊されない。\nまた、１ターンに１度、トークン１体をリリースして発動できる。\n相手フィールド上のモンスター１体を選択して表側守備表示にする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻獣機ブルーインパラス",h:"げんじゅうきぶるーいんぱらす",e:"Mecha Phantom Beast Blue Impala",ra:"SR",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:1400,de:1100,tx:"このカードをシンクロ素材とする場合、機械族モンスターのシンクロ召喚にしか使用できず、他のシンクロ素材モンスターは自分の手札・フィールド上の「幻獣機」と名のついたモンスターでなければならない。\n自分フィールド上にトークンが存在する限り、このカードは戦闘及び効果では破壊されない。\nまた、相手フィールド上にモンスターが存在し、自分フィールド上にモンスターが存在しない場合、墓地のこのカードをゲームから除外して発動できる。\n「幻獣機トークン」（機械族・風・星３・攻／守０）１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻獣機メガラプター",h:"げんじゅうきめがらぷたー",e:"Mecha Phantom Beast Megaraptor",ra:"SR",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1900,de:1000,tx:"自分フィールド上にトークンが特殊召喚された時、「幻獣機トークン」（機械族・風・星３・攻／守０）１体を特殊召喚する。\n「幻獣機メガラプター」のこの効果は１ターンに１度しか使用できない。\nこのカードのレベルは自分フィールド上の「幻獣機トークン」のレベルの合計分だけ上がる。\n自分フィールド上にトークンが存在する限り、このカードは戦闘及び効果では破壊されない。\nまた、１ターンに１度、トークン１体をリリースして発動できる。\nデッキから「幻獣機」と名のついたモンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻獣機ヤクルスラーン",h:"げんじゅうきやくるすらーん",e:"Mecha Phantom Beast Jaculuslan",ra:"R",t:"monster",a:"風",l:9,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:2700,de:2000,tx:"「幻獣機」チューナー＋チューナー以外の「幻獣機」モンスター１体以上\n①：このカードがS召喚に成功した時、自分フィールドの「幻獣機トークン」を任意の数だけリリースして発動できる。\nリリースした数だけ、相手の手札をランダムに選んで捨てる。\n②：このカードがモンスターゾーンに存在する限り、このカード以外の自分フィールドの「幻獣機」モンスターは戦闘・効果では破壊されない。\n③：このカードが相手によって破壊された場合に発動できる。\nデッキから速攻魔法カード１枚を選んで自分の魔法＆罠ゾーンにセットする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻獣機レイステイルス",h:"げんじゅうきれいすているす",e:"Mecha Phantom Beast Stealthray",ra:"R",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:100,de:2100,tx:"このカードが相手ライフに戦闘ダメージを与えた時、「幻獣機トークン」（機械族・風・星３・攻／守０）１体を特殊召喚する。\nこのカードのレベルは自分フィールド上の「幻獣機トークン」のレベルの合計分だけ上がる。\n自分フィールド上にトークンが存在する限り、このカードは戦闘及び効果では破壊されない。\nまた、１ターンに１度、トークン１体をリリースして発動できる。\nフィールド上の魔法・罠カード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻獣クロスウィング",h:"げんじゅうくろすうぃんぐ",e:"Phantom Beast Cross-Wing",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1300,de:1300,tx:"このカードが墓地に存在する限り、フィールド上の「幻獣」と名のついたモンスターの攻撃力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻獣サンダーぺガス",h:"げんじゅうさんだーぺがす",e:"Phantom Beast Thunder-Pegasus",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:700,de:2000,tx:"相手モンスターの攻撃宣言時、墓地のこのカードをゲームから除外し、自分フィールド上の「幻獣」と名のついたモンスター１体を選択して発動できる。\nこのターン、選択した自分のモンスターは戦闘では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻銃士",h:"げんじゅうし",e:"Phantom Skyblaster",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1100,de:800,tx:"①：このカードが召喚・反転召喚に成功した時に発動できる。\n自分フィールドのモンスターの数まで、自分フィールドに「銃士トークン」（悪魔族・闇・星４・攻／守５００）を特殊召喚する。\n②：自分スタンバイフェイズに発動できる。\n自分フィールドの「銃士」モンスターの数×３００ダメージを相手に与える。\nこの効果を発動するターン、自分の「銃士」モンスターは攻撃宣言できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻獣ロックリザード",h:"げんじゅうろっくりざーど",e:"Phantom Beast Rock-Lizard",ra:"UR",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:2200,de:2000,tx:"「幻獣」と名のついたモンスターを生け贄に捧げる場合、このカードは生け贄１体で召喚する事ができる。\nこのカードが戦闘で破壊したモンスター１体につき、相手ライフに５００ポイントダメージを与える。\n相手がコントロールするカードの効果によってこのカードが破壊され墓地へ送られた時、相手ライフに２０００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻獣ワイルドホーン",h:"げんじゅうわいるどほーん",e:"Phantom Beast Wild-Horn",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:0,tx:"このカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が越えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻水龍",h:"げんすいりゅう",e:"Mythic Water Dragon",ra:"SR",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1000,de:2000,tx:"自分フィールド上に地属性モンスターが存在する場合、このカードは手札から特殊召喚できる。\nこの方法による「幻水龍」の特殊召喚は１ターンに１度しかできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"剣聖－ネイキッド・ギア・フリード",h:"けんせい－ねいきっど・ぎあ・ふりーど",e:"Gearfried the Swordmaster",ra:"SR",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2600,de:2200,tx:"このカードは通常召喚できない。\n「拘束解除」の効果でのみ特殊召喚できる。\n①：このカードに装備カードが装備された場合、相手フィールドのモンスター１体を対象として発動する。\nその相手モンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻想召喚師",h:"げんそうしょうかんし",e:"Summoner of Illusions",ra:"UR",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["リバース","効果"],ma:"",tp:"",at:800,de:900,tx:"①：このカードがリバースした場合に発動する。\nこのカード以外の自分フィールドのモンスター１体をリリースし、エクストラデッキから融合モンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターはこのターンのエンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻奏の歌姫ソプラノ",h:"げんそうのうたひめそぷらの",e:"Soprano the Melodious Songstress",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1400,de:1400,tx:"「幻奏の歌姫ソプラノ」の①の効果は１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した時、「幻奏の歌姫ソプラノ」以外の自分の墓地の「幻奏」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\n②：１ターンに１度、自分メインフェイズに発動できる。\n「幻奏」融合モンスターカードによって決められた、このカードを含む融合素材モンスターを自分フィールドから墓地へ送り、その融合モンスター１体をエクストラデッキから融合召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻奏の歌姫ソロ",h:"げんそうのうたひめそろ",e:"Solo the Melodious Songstress",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"①：相手フィールドにモンスターが存在し、自分フィールドにモンスターが存在しない場合、このカードは手札から特殊召喚できる。\n②：このカードが戦闘で破壊され墓地へ送られた時に発動できる。\nデッキから「幻奏の歌姫ソロ」以外の「幻奏」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻奏の音姫プロディジー・モーツァルト",h:"げんそうのおとひめぷろでぃじー・もーつぁると",e:"Mozarta the Melodious Maestra",ra:"R",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2600,de:2000,tx:"このカードの効果を発動するターン、自分は光属性以外のモンスターを特殊召喚できない。\n①：１ターンに１度、自分メインフェイズに発動できる。\n手札から天使族・光属性モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻奏の音姫マイスタリン・シューベルト",h:"げんそうのおとひめまいすたりん・しゅーべると",e:"Schuberta the Melodious Maestra",ra:"UR",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"天使族",mo:["融合","効果"],ma:"",tp:"",at:2400,de:2000,tx:"「幻奏」モンスター×２\n①：このカードがフィールドに表側表示で存在する限り１度だけ、お互いの墓地のカードを合計３枚まで対象として発動できる。\nそのカードを除外する。\nこのカードの攻撃力は、この効果で除外したカードの数×２００アップする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻奏の音姫ローリイット・フランソワ",h:"げんそうのおとひめろーりいっと・ふらんそわ",e:"Shopina the Melodious Maestra",ra:"N",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2300,de:1700,tx:"このカードの効果を発動するターン、自分は光属性以外のモンスターの効果を発動できない。\n①：１ターンに１度、自分の墓地の天使族・光属性モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻奏の音女エレジー",h:"げんそうのおとめえれじー",e:"Elegy the Melodious Diva",ra:"N",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2000,de:1200,tx:"①：このカードがモンスターゾーンに存在する限り、自分フィールドの特殊召喚された「幻奏」モンスターは効果では破壊されない。\n②：特殊召喚したこのカードがモンスターゾーンに存在する限り、自分フィールドの天使族モンスターの攻撃力は３００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻奏の音女オペラ",h:"げんそうのおとめおぺら",e:"Opera the Melodious Diva",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2300,de:1000,tx:"①：このカードは召喚・リバースしたターンには攻撃できない。\n②：このカードが融合召喚の素材となって墓地へ送られた場合に発動できる。\nこのターン、自分フィールドの「幻奏」モンスターは戦闘・効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻奏の音女カノン",h:"げんそうのおとめかのん",e:"Canon the Melodious Diva",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1400,de:2000,tx:"「幻奏の音女カノン」の①の方法による特殊召喚は１ターンに１度しかできない。\n①：自分フィールドに「幻奏」モンスターが存在する場合、このカードは手札から特殊召喚できる。\n②：１ターンに１度、自分フィールドの「幻奏」モンスター１体を対象として発動できる。\nそのモンスターの表示形式を変更する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻奏の音女スコア",h:"げんそうのおとめすこあ",e:"Score the Melodious Diva",ra:"UR",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:200,de:200,tx:"①：自分の「幻奏」モンスターが相手モンスターと戦闘を行うダメージ計算時、このカードを手札から墓地へ送って発動できる。\nその相手モンスターの攻撃力・守備力はターン終了時まで０になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻奏の音女セレナ",h:"げんそうのおとめせれな",e:"Serenade the Melodious Diva",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:400,de:1900,tx:"①：天使族モンスターをアドバンス召喚する場合、このカードは２体分のリリースにできる。\n②：このカードが特殊召喚に成功したターン、自分は通常召喚に加えて１度だけ、自分メインフェイズに「幻奏」モンスター１体を召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻奏の音女ソナタ",h:"げんそうのおとめそなた",e:"Sonata the Melodious Diva",ra:"R",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1200,de:1000,tx:"①：自分フィールドに「幻奏」モンスターが存在する場合、このカードは手札から特殊召喚できる。\n②：特殊召喚したこのカードがモンスターゾーンに存在する限り、自分フィールドの天使族モンスターの攻撃力・守備力は５００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻奏の音女タムタム",h:"げんそうのおとめたむたむ",e:"Tamtam the Melodious Diva",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1000,de:2000,tx:"①：自分フィールドに「幻奏」モンスターが存在し、このカードが特殊召喚に成功した場合に発動できる。\n自分のデッキ・墓地から「融合」１枚を選んで手札に加える。\n②：このカードが融合召喚の素材となって墓地へ送られた場合、自分フィールドの「幻奏」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力を５００ダウンし、相手に５００ダメージ与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻想の黒魔導師",h:"げんそうのくろまどうし",e:"Ebon Illusion Magician",ra:"SR",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"魔法使い族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2500,de:2100,tx:"レベル７モンスター×２\nこのカードは自分フィールドのランク６の魔法使い族Xモンスターの上に重ねてX召喚する事もできる。\n「幻想の黒魔導師」の②の効果は１ターンに１度しか使用できない。\n①：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\n手札・デッキから魔法使い族の通常モンスター１体を特殊召喚する。\n②：魔法使い族の通常モンスターの攻撃宣言時、相手フィールドのカード１枚を対象として発動できる。\nそのカードを除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻層の守護者アルマデス",h:"げんそうのしゅごしゃあるまです",e:"Armades, Keeper of Boundaries",ra:"UR",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"悪魔族",mo:["シンクロ","効果"],ma:"",tp:"",at:2300,de:1500,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカードが戦闘を行う場合、相手はダメージステップ終了時まで魔法・罠・効果モンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻奏の華歌聖ブルーム・ディーヴァ",h:"げんそうのはなかせいぶるーむ・でぃーう゛ぁ",e:"Bloom Diva the Melodious Choir",ra:"UR",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"天使族",mo:["融合","効果"],ma:"",tp:"",at:1000,de:2000,tx:"「幻奏の音姫」モンスター＋「幻奏」モンスター\n①：このカードは戦闘・効果では破壊されず、このカードの戦闘で発生する自分への戦闘ダメージは０になる。\n②：このカードが特殊召喚されたモンスターと戦闘を行ったダメージ計算後に発動できる。\nその相手モンスターとこのカードの、元々の攻撃力の差分のダメージを相手に与え、その相手モンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻奏の華歌聖ブルーム・プリマ",h:"げんそうのはなかせいぶるーむ・ぷりま",e:"Bloom Prima the Melodious Choir",ra:"R",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"天使族",mo:["融合","効果"],ma:"",tp:"",at:1900,de:2000,tx:"「幻奏の音姫」モンスター＋「幻奏」モンスター１体以上\n①：このカードの攻撃力は、このカードの融合素材としたモンスターの数×３００アップする。\n②：このカードは１度のバトルフェイズ中に２回攻撃できる。\n③：融合召喚されたこのカードが墓地へ送られた場合、自分の墓地の「幻奏」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻創のミセラサウルス",h:"げんそうのみせらさうるす",e:"Miscellaneousaurus",ra:"UR",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"「幻創のミセラサウルス」の②の効果は１ターンに１度しか使用できない。\n①：自分・相手のメインフェイズにこのカードを手札から墓地へ送って発動できる。\nそのメインフェイズの間、自分フィールドの恐竜族モンスターは相手が発動した効果を受けない。\n②：自分の墓地からこのカードを含む恐竜族モンスターを任意の数だけ除外して発動できる。\n除外したモンスターの数と同じレベルの恐竜族モンスター１体をデッキから特殊召喚する。\nこの効果で特殊召喚したモンスターはエンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"犬タウルス",h:"けんたうるす",e:"Caninetaur",ra:"SR",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:200,tx:"①：１ターンに１度、このカードが相手モンスターと戦闘を行うダメージ計算時に発動できる。\n手札・デッキから獣族・獣戦士族・鳥獣族モンスター１体を墓地へ送り、このカードの攻撃力はバトルフェイズ終了時まで、墓地へ送ったそのモンスターのレベル×１００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ケンタウルミナ",h:"けんたうるみな",e:"Centaur Mina",ra:"SR",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"獣戦士族",mo:["融合","効果"],ma:"",tp:"",at:2200,de:1600,tx:"戦士族・光属性モンスター＋獣族モンスター\nこのカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\n自分の手札・墓地からレベル２以下のモンスター１体を選んで特殊召喚する。\n②：１ターンに１度、自分ターンに相手が罠カードを発動した時に発動できる。\nその発動を無効にし、そのカードをそのままセットする。\n③：このカードが戦士族・風属性SモンスターのS素材として墓地へ送られた場合に発動できる。\nフィールドの表側表示モンスター１体を選んで破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ケンタウロス",h:"けんたうろす",e:"Mystic Horseman",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:1300,de:1550,tx:"人とウマがひとつになった化け物。\n走るのが速く、誰も追いつけない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻蝶の刺客アゲハ",h:"げんちょうのしきゃくあげは",e:"Swallowtail Butterspy",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1200,tx:"このカード以外の自分フィールド上のモンスターが相手ライフに戦闘ダメージを与えた時、相手フィールド上に表側表示で存在するモンスター１体を選択して発動する。\n選択したモンスターの攻撃力は、その時に与えたダメージの数値分ダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻蝶の刺客オオルリ",h:"げんちょうのしきゃくおおるり",e:"Blue Mountain Butterspy",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:0,de:1700,tx:"このカードは通常召喚できない。\n自分が戦士族モンスターの召喚に成功した時、このカードを手札から特殊召喚できる。\nこのカードはシンクロ素材にできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻蝶の刺客モルフォ",h:"げんちょうのしきゃくもるふぉ",e:"Morpho Butterspy",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:1600,tx:"相手フィールド上のモンスターの表示形式が変更された時、そのモンスター１体を選択して発動できる。\n選択したモンスターの攻撃力・守備力を１０００ポイントダウンする。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"玄武の召喚士",h:"げんぶのしょうかんし",e:"Green Turtle Summoner",ra:"SR",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["リバース","効果"],ma:"",tp:"",at:400,de:1500,tx:"①：このカードがリバースした場合、相手フィールドのモンスター１体を対象として発動する。\nそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻木龍",h:"げんぼくりゅう",e:"Mythic Tree Dragon",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:100,de:1400,tx:"１ターンに１度、自分フィールド上のドラゴン族・水属性モンスター１体を選択して発動できる。\nこのカードのレベルは選択したモンスターのレベルと同じになる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻魔皇ラビエル",h:"げんまおうらびえる",e:"Raviel, Lord of Phantasms",ra:"UR",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"悪魔族",mo:["特殊召喚","効果"],ma:"",tp:"",at:4000,de:4000,tx:"このカードは通常召喚できない。\n自分フィールドの悪魔族モンスター３体をリリースした場合のみ特殊召喚できる。\n①：１ターンに１度、このカード以外の自分フィールドのモンスター１体をリリースして発動できる。\nこのカードの攻撃力はターン終了時まで、リリースしたモンスターの元々の攻撃力分アップする。\n②：相手がモンスターの召喚に成功した場合に発動する。\n自分フィールドに「幻魔トークン」（悪魔族・闇・星１・攻／守１０００）１体を特殊召喚する。\nこのトークンは攻撃宣言できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"鍵魔人ハミハミハミング",h:"けんまじんはみはみはみんぐ",e:"Humhumming the Key Djinn",ra:"R",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"悪魔族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1100,de:1800,tx:"レベル３モンスター×２\n①：このカードが特殊召喚に成功した時、自分の墓地の「魔人」Xモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nその後、手札を２枚までそのモンスターの下に重ねてX素材にできる。\n②：１ターンに１度、このカードのX素材を１つ取り除き、自分フィールドの「魔人」Xモンスター１体を対象として発動できる。\nこのターン、そのモンスターは相手に直接攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻妖種ミトラ",h:"げんようしゅみとら",e:"Mystic Macrocarpa Seed",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"植物族",mo:["チューナー","効果"],ma:"",tp:"",at:500,de:1000,tx:"このカードをシンクロ素材とする場合、地属性モンスターのシンクロ召喚にしか使用できない。\n自分のメインフェイズ時、フィールド上の地属性モンスター１体を選択して発動できる。\n選択したモンスターのレベルを１つ下げる。\n「幻妖種ミトラ」の効果は１ターンに２度まで使用できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻妖フルドラ",h:"げんようふるどら",e:"Mystrick Hulder",ra:"SR",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1500,de:2400,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合、手札を１枚捨てて発動できる。\n捨てたカードの種類によって以下の効果を適用する。\n\n●モンスター：自分の墓地から罠カード１枚を選んで手札に加える。\n\n●魔法：自分の墓地からモンスター１体を選んで手札に加える。\n\n●罠：自分の墓地から魔法カード１枚を選んで手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"玄翼竜 ブラック・フェザー",h:"げんよくりゅう　ぶらっく・ふぇざー",e:"Blackfeather Darkrage Dragon",ra:"SR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2800,de:1600,tx:"チューナー＋チューナー以外のモンスター１体以上\n１ターンに１度、戦闘またはカードの効果によって自分がダメージを受けた時に発動できる。\n自分のデッキの上からカードを５枚まで墓地へ送る。\nこの効果で墓地へ送ったカードの中にモンスターカードがあった場合、このカードの攻撃力は４００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コアキメイル・アイス",h:"こあきめいる・あいす",e:"Koa'ki Meiru Ice",ra:"R",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1900,de:1200,tx:"このカードのコントローラーは自分のエンドフェイズ毎に、手札から「コアキメイルの鋼核」１枚を墓地へ送るか、手札の永続魔法カード１枚を相手に見せる。\nまたは、どちらも行わずにこのカードを破壊する。\nまた、自分のメインフェイズ時に手札を１枚墓地へ送って発動できる。\nフィールド上の特殊召喚されたモンスター１体を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コアキメイル・ヴァラファール",h:"こあきめいる・う゛ぁらふぁーる",e:"Koa'ki Meiru Valafar",ra:"N",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:3000,de:2100,tx:"このカードのコントローラーは自分のエンドフェイズ毎に手札から「コアキメイルの鋼核」１枚を墓地へ送る。\nまたは、墓地へ送らずにこのカードを破壊する。\nこのカードは「コアキメイル」と名のついたモンスター１体をリリースしてアドバンス召喚する事ができる。\nこのカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。\nこのカードは罠カードの効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コアキメイル・ウォーアームズ",h:"こあきめいる・うぉーあーむず",e:"Koa'ki Meiru War Arms",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1400,de:1200,tx:"このカードのコントローラーは自分のエンドフェイズ毎に手札から「コアキメイルの鋼核」１枚を墓地へ送るか、手札の戦士族モンスター１体を相手に見せる。\nまたは、どちらも行わずにこのカードを破壊する。\n１ターンに１度、自分の墓地に存在するレベル３以下の戦士族モンスター１体を選択し、装備カード扱いとしてこのカードに装備する事ができる。\nこのカードの攻撃力は、このカードの効果で装備したモンスターの攻撃力の半分の数値分アップする。\nこのカードが戦闘によって破壊される場合、代わりに装備したモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コアキメイル・ウォール",h:"こあきめいる・うぉーる",e:"Koa'ki Meiru Wall",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1900,de:1200,tx:"このカードのコントローラーは自分のエンドフェイズ毎に手札から「コアキメイルの鋼核」１枚を墓地へ送るか、手札の岩石族モンスター１体を相手に見せる。\nまたは、どちらも行わずにこのカードを破壊する。\n相手の魔法カードが発動した時、このカードをリリースする事でその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コアキメイル・ウルナイト",h:"こあきめいる・うるないと",e:"Koa'ki Meiru Urnight",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:2000,de:1500,tx:"このカードのコントローラーは自分のエンドフェイズ毎に、手札から「コアキメイルの鋼核」１枚を墓地へ送るか、手札の獣戦士族モンスター１体を相手に見せる。\nまたは、どちらも行わずにこのカードを破壊する。\n１ターンに１度、手札の「コアキメイルの鋼核」１枚を相手に見せる事で、デッキから「コアキメイル・ウルナイト」以外のレベル４以下の「コアキメイル」と名のついたモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コアキメイル・グールズスレイブ",h:"こあきめいる・ぐーるずすれいぶ",e:"Koa'ki Meiru Ghoulungulate",ra:"N",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:2500,de:1700,tx:"このカードのコントローラーは自分のエンドフェイズ毎に手札から「コアキメイルの鋼核」１枚を墓地へ送るか、手札のアンデット族モンスター１体を相手に見せる。\nまたは、どちらも行わずにこのカードを破壊する。\n自分フィールド上に存在する「コアキメイル」と名のついたモンスター１体が戦闘またはカードの効果によって破壊される場合、代わりに自分の墓地に存在する「コアキメイル」と名のついたモンスター１体をゲームから除外する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コアキメイル・グラヴィローズ",h:"こあきめいる・ぐらう゛ぃろーず",e:"Koa'ki Meiru Gravirose",ra:"R",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1900,de:1300,tx:"このカードのコントローラーは自分のエンドフェイズ毎に手札から「コアキメイルの鋼核」１枚を墓地へ送るか、手札の植物族モンスター１体を相手に見せる。\nまたは、どちらも行わずにこのカードを破壊する。\n自分のスタンバイフェイズ時に１度だけ、自分のデッキからレベル３以下のモンスター１体を墓地へ送る事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コアキメイル・クルセイダー",h:"こあきめいる・くるせいだー",e:"Koa'ki Meiru Crusader",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1900,de:1300,tx:"このカードのコントローラーは自分のエンドフェイズ毎に、手札から「コアキメイルの鋼核」１枚を墓地へ送るか、手札の獣戦士族モンスター１体を相手に見せる。\nまたは、どちらも行わずにこのカードを破壊する。\nこのカードが戦闘によって相手モンスターを破壊した場合、自分の墓地の「コアキメイル」と名のついたカード１枚を選択して手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コアキメイル・サンドマン",h:"こあきめいる・さんどまん",e:"Koa'ki Meiru Sandman",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1900,de:1200,tx:"このカードのコントローラーは自分のエンドフェイズ毎に、手札から「コアキメイルの鋼核」１枚を墓地へ送るか、手札の岩石族モンスター１体を相手に見せる。\nまたは、どちらも行わずにこのカードを破壊する。\nまた、相手の罠カードが発動した時、このカードをリリースして発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コアキメイル・シーパンサー",h:"こあきめいる・しーぱんさー",e:"Koa'ki Meiru Sea Panther",ra:"R",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:1900,de:1200,tx:"このカードのコントローラーは自分のエンドフェイズ毎に手札から「コアキメイルの鋼核」１枚を墓地へ送るか、手札の水属性モンスター１体を相手に見せる。\nまたは、どちらも行わずにこのカードを破壊する。\n１ターンに１度、手札から「コアキメイルの鋼核」１枚を墓地へ送る事で、自分の墓地に存在する魔法カード１枚を選択しデッキの一番上に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コアキメイル・スピード",h:"こあきめいる・すぴーど",e:"Koa'ki Meiru Speeder",ra:"R",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:2200,tx:"このカードのコントローラーは自分のエンドフェイズ毎に手札から「コアキメイルの鋼核」１枚を墓地へ送るか、手札の機械族モンスター１体を相手に見せる。\nまたは、どちらも行わずにこのカードを破壊する。\nこのカードがフィールド上に表側表示で存在する限り、自分のドローフェイズ時にドローしたカードが「コアキメイルの鋼核」だった場合、そのカードを相手に見せる事で自分はカードをもう１枚ドローする事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コアキメイル・テストベッド",h:"こあきめいる・てすとべっど",e:"Koa'ki Meiru Prototype",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1800,de:1800,tx:"フィールド上に表側表示で存在する「コアキメイル」と名のついたモンスターがエンドフェイズ時に破壊される場合、代わりにこのカードを破壊する事ができる。\nまた、フィールド上に表側表示で存在する「コアキメイル」と名のついたモンスターがエンドフェイズ時に破壊された時、自分フィールド上に「コアキメイルトークン」（岩石族・地・星４・攻／守１８００）１体を特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コアキメイル・パワーハンド",h:"こあきめいる・ぱわーはんど",e:"Koa'ki Meiru Powerhand",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2100,de:1600,tx:"このカードのコントローラーは自分のエンドフェイズ毎に、手札から「コアキメイルの鋼核」１枚を墓地へ送るか、手札の通常罠カード１枚を相手に見せる。\nまたは、どちらも行わずにこのカードを破壊する。\nこのカードが光属性または闇属性のモンスターと戦闘を行う場合、バトルフェイズの間だけそのモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コアキメイル・ビートル",h:"こあきめいる・びーとる",e:"Koa'ki Meiru Beetle",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1900,de:1500,tx:"このカードのコントローラーは自分のエンドフェイズ毎に手札から「コアキメイルの鋼核」１枚を墓地へ送るか、手札の昆虫族モンスター１体を相手に見せる。\nまたは、どちらも行わずにこのカードを破壊する。\n光属性または闇属性モンスターが表側攻撃表示で特殊召喚に成功した時、そのモンスターを守備表示にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コアキメイル・フルバリア",h:"こあきめいる・ふるばりあ",e:"Koa'ki Meiru Hydro Barrier",ra:"N",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1000,de:1900,tx:"手札から「コアキメイルの鋼核」１枚をデッキの一番上に戻して発動する。\n次の自分のターンのスタンバイフェイズ時まで、「コアキメイル」と名のついたモンスター以外のフィールド上に表側表示で存在する効果モンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コアキメイル・ベルグザーク",h:"こあきめいる・べるぐざーく",e:"Koa'ki Meiru Bergzak",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2000,de:200,tx:"このカードのコントローラーは自分のエンドフェイズ毎に手札から「コアキメイルの鋼核」１枚を墓地へ送るか、手札の戦士族モンスター１体を相手に見せる。\nまたは、どちらも行わずにこのカードを破壊する。\nこのカードが戦闘によって相手モンスターを破壊した場合、もう１度だけ続けて攻撃する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コアキメイル・マキシマム",h:"こあきめいる・まきしまむ",e:"Koa'ki Meiru Maximus",ra:"R",t:"monster",a:"風",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3000,de:2500,tx:"このカードは通常召喚できない。\n自分の手札から「コアキメイルの鋼核」１枚をゲームから除外した場合に特殊召喚する事ができる。\nこのカードのコントローラーは自分のエンドフェイズ毎に手札から「コアキメイルの鋼核」１枚か「コアキメイル」と名のついたモンスター１体を墓地へ送る。\nまたは、どちらも行わずにこのカードを破壊する。\n１ターンに１度、自分のメインフェイズ時に相手フィールド上に存在するカード１枚を選択して破壊する事ができる。",li:1,lk:[],ta:[],al:[],ocg:true},
  {n:"コアキメイル・ルークロード",h:"こあきめいる・るーくろーど",e:"Koa'ki Meiru Rooklord",ra:"SR",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2800,de:2200,tx:"このカードのコントローラーは自分のエンドフェイズ毎に、手札から「コアキメイルの鋼核」１枚を墓地へ送るか、手札の戦士族モンスター１体を相手に見せる。\nまたは、どちらも行わずにこのカードを破壊する。\nこのカードは「コアキメイル」と名のついたモンスター１体をリリースしてアドバンス召喚できる。\nこのカードが召喚に成功した時、自分の墓地の「コアキメイル」と名のついたカード１枚をゲームから除外する事で、相手フィールド上のカードを２枚まで選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コアキメイル・ロック",h:"こあきめいる・ろっく",e:"Koa'ki Meiru Boulder",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1200,de:1000,tx:"このカードが戦闘によって破壊され墓地へ送られた時、デッキから「コアキメイルの鋼核」１枚またはレベル４以下の「コアキメイル」と名のついたモンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コアラッコ",h:"こあらっこ",e:"Sea Koala",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:100,de:1600,tx:"このカード以外の獣族モンスターが自分フィールド上に存在する場合、相手フィールド上のモンスター１体を選択し、エンドフェイズ時まで攻撃力を０にできる。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コアラッコアラ",h:"こあらっこあら",e:"Koalo-Koala",ra:"R",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"獣族",mo:["融合","効果"],ma:"",tp:"",at:2800,de:200,tx:"「コアラッコ」＋「ラッコアラ」\n手札から獣族モンスター１体を墓地へ送り、相手フィールド上に存在するモンスター１体を選択して発動する。\n選択したモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コイツ",h:"こいつ",e:"Koitsu",ra:"N",t:"monster",a:"水",l:10,ps:"",pe:"",tr:"天使族",mo:["ユニオン","効果"],ma:"",tp:"",at:200,de:100,tx:"１ターンに１度だけ自分のメインフェイズに装備カード扱いとして、フィールド上のこのカードを「アイツ」に装備、または装備を解除して表側攻撃表示で特殊召喚する事ができる。\n装備モンスターの攻撃力は３０００ポイントアップする。\n守備表示モンスターを攻撃した時にその守備力を攻撃力が越えていれば、その数値だけ相手ライフに戦闘ダメージを与える。\n（１体のモンスターが装備できるユニオンは１枚まで、装備モンスターが戦闘によって破壊される場合は、代わりにこのカードを破壊する。\n）",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幸運の笛吹き",h:"こううんのふえふき",e:"Lucky Pied Piper",ra:"R",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"天使族",mo:["デュアル","効果"],ma:"",tp:"",at:1500,de:500,tx:"このカードは墓地またはフィールド上に表側表示で存在する場合、通常モンスターとして扱う。\nフィールド上に表側表示で存在するこのカードを通常召喚扱いとして再度召喚する事で、このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●このカードが戦闘によって相手モンスターを破壊し墓地へ送った時、自分のデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幸運を告げるフクロウ",h:"こううんをつげるふくろう",e:"An Owl of Luck",ra:"R",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"鳥獣族",mo:["リバース","効果"],ma:"",tp:"",at:300,de:500,tx:"リバース：デッキからフィールド魔法カードを１枚選択し、デッキの一番上に置く。\n「王家の眠る谷－ネクロバレー」がフィールド上に存在する場合、選択したフィールド魔法カードを手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"巧炎星－エランセイ",h:"こうえんせい－えらんせい",e:"Brotherhood of the Fire Fist - Eland",ra:"SR",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"獣戦士族",mo:["儀式","効果"],ma:"",tp:"",at:2400,de:2000,tx:"「炎舞－「洞明」」により降臨。\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札からモンスター１体を捨てて発動できる。\n自分のデッキ・墓地から「炎舞」魔法・罠カード１枚を選んで自分フィールドにセットする。\n②：相手がモンスターの効果を発動した時、「巧炎星－エランセイ」以外の自分フィールドの表側表示の、「炎星」カードまたは「炎舞」カード１枚を墓地へ送って発動できる。\nその効果を無効にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"罡炎星－リシュンキ",h:"こうえんせい－りしゅんき",e:"Brotherhood of the Fire Fist - Kirin",ra:"R",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"獣戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2000,de:2800,tx:"炎属性チューナー＋チューナー以外の「炎星」と名のついたモンスター１体以上\nこのカードがシンクロ召喚に成功した時、デッキから「炎舞」と名のついた魔法・罠カード１枚を選んで自分フィールド上にセットできる。\nこのカードがフィールド上に表側表示で存在する限り、相手フィールド上のモンスターの攻撃力は、自分フィールド上に表側表示で存在する魔法・罠カードの数×１００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"紅炎の騎士",h:"こうえんのきし",e:"Brushfire Knight",ra:"R",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1400,de:1200,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードが既にモンスターゾーンに存在する状態で、このカード以外の炎属性モンスターが破壊され自分の墓地へ送られた場合に発動する。\nデッキから炎属性モンスター１体を墓地へ送る。\n②：このカードが破壊され墓地へ送られた場合に発動する。\nデッキから炎属性モンスター１体を墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"業火の重騎士",h:"ごうかのじゅうきし",e:"Heavy Knight of the Flame",ra:"SR",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"アンデット族",mo:["デュアル","効果"],ma:"",tp:"",at:1800,de:200,tx:"①：このカードはフィールド・墓地に存在する限り、通常モンスターとして扱う。\n②：フィールドの通常モンスター扱いのこのカードを通常召喚としてもう１度召喚できる。\nその場合このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●このカードが、特殊召喚されたモンスターを攻撃するダメージステップ開始時に発動できる。\nそのモンスターを除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"劫火の舟守 ゴースト・カロン",h:"ごうかのふなもり　ごーすと・かろん",e:"Ghost Charon, the Underworld Boatman",ra:"R",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"アンデット族",mo:["チューナー","効果"],ma:"",tp:"",at:500,de:0,tx:"「劫火の舟守 ゴースト・カロン」の効果は１ターンに１度しか使用できず、この効果を発動するターン、自分はドラゴン族モンスターしか特殊召喚できない。\n①：相手フィールドにモンスターが存在し、自分フィールドにこのカード以外のモンスターが存在しない場合、自分の墓地の融合モンスター１体を対象として発動できる。\n墓地のそのモンスターとフィールドのこのカードを除外し、その２体のレベルの合計と同じレベルを持つドラゴン族Sモンスター１体をエクストラデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"剛鬼アイアン・クロー",h:"ごうきあいあん・くろー",e:"Gouki Iron Claw",ra:"R",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の「剛鬼」モンスターが相手モンスターと戦闘を行うダメージステップ開始時からダメージ計算前までに、このカードを手札から墓地へ送って発動できる。\nその自分のモンスターはターン終了時まで、攻撃力が５００アップし、相手の効果を受けない。\n②：このカードがフィールドから墓地へ送られた場合に発動できる。\nデッキから「剛鬼アイアン・クロー」以外の「剛鬼」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"剛鬼スープレックス",h:"ごうきすーぷれっくす",e:"Gouki Suprex",ra:"UR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:0,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時に発動できる。\n手札から「剛鬼」モンスター１体を特殊召喚する。\n②：このカードがフィールドから墓地へ送られた場合に発動できる。\nデッキから「剛鬼スープレックス」以外の「剛鬼」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"剛鬼ツイストコブラ",h:"ごうきついすとこぶら",e:"Gouki Twistcobra",ra:"SR",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの「剛鬼」モンスター１体をリリースし、自分フィールドの「剛鬼」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで、リリースしたモンスターの元々の攻撃力分アップする。\nこの効果は相手ターンでも発動できる。\n②：このカードがフィールドから墓地へ送られた場合に発動できる。\nデッキから「剛鬼ツイストコブラ」以外の「剛鬼」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"剛鬼ハッグベア",h:"ごうきはっぐべあ",e:"Gouki Bearhug",ra:"R",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2400,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚または「剛鬼」カードの効果で特殊召喚に成功した場合、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで元々の攻撃力の半分になる。\n②：このカードがフィールドから墓地へ送られた場合に発動できる。\nデッキから「剛鬼ハッグベア」以外の「剛鬼」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"剛鬼ヘッドバット",h:"ごうきへっどばっと",e:"Gouki Headbatt",ra:"R",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:800,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが手札にある場合、このカード以外の「剛鬼」モンスター１体を手札から墓地へ送り、自分フィールドの「剛鬼」モンスター１体を対象として発動できる。\nこのカードを手札から守備表示で特殊召喚し、対象のモンスターの攻撃力をターン終了時まで８００アップする。\n②：このカードがフィールドから墓地へ送られた場合に発動できる。\nデッキから「剛鬼ヘッドバット」以外の「剛鬼」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"剛鬼マンジロック",h:"ごうきまんじろっく",e:"Gouki Octostretch",ra:"R",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：相手モンスターが攻撃した場合、そのダメージ計算時にこのカードを手札から捨てて発動できる。\nその戦闘で発生する自分への戦闘ダメージは半分になる。\n②：自分にダメージを与える効果を相手が発動した時、このカードを手札から捨てて発動できる（ダメージステップでも発動可能）。\nその効果で自分が受けるダメージは半分になる。\n③：このカードがフィールドから墓地へ送られた場合に発動できる。\nデッキから「剛鬼マンジロック」以外の「剛鬼」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"交響魔人マエストローク",h:"こうきょうまじんまえすとろーく",e:"Maestroke the Symphony Djinn",ra:"UR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1800,de:2300,tx:"レベル４モンスター×２\n１ターンに１度、このカードのエクシーズ素材を１つ取り除き、相手フィールド上に表側攻撃表示で存在するモンスター１体を選択して発動できる。\n選択したモンスターを裏側守備表示にする。\nまた、このカードがフィールド上に表側表示で存在する限り、自分フィールド上の「魔人」と名のついたエクシーズモンスターが破壊される場合、代わりにそのモンスターのエクシーズ素材を１つ取り除く事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"剛鬼ライジングスコーピオ",h:"ごうきらいじんぐすこーぴお",e:"Gouki Riscorpio",ra:"SR",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2300,de:0,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドのモンスターが存在しない場合、または「剛鬼」モンスターのみの場合、このカードはリリースなしで召喚できる。\n②：このカードがフィールドから墓地へ送られた場合に発動できる。\nデッキから「剛鬼ライジングスコーピオ」以外の「剛鬼」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"紅血鬼",h:"こうけつき",e:"Red-Headed Oni",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"このカードがアンデット族モンスターの効果によって墓地からの特殊召喚に成功した時、自分フィールド上のアンデット族モンスター１体を選択して発動できる。\nフィールド上のエクシーズ素材を１つ取り除き、選択したモンスターのレベルを１つ上げ、攻撃力を３００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"恍惚の人魚",h:"こうこつのまーめいど",e:"Enchanting Mermaid",ra:"N",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"魚族",mo:["通常"],ma:"",tp:"",at:1200,de:900,tx:"海を航海する者を誘惑しておぼれさせる、美しい人魚。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"光神テテュス",h:"こうしんててゅす",e:"Tethys, Goddess of Light",ra:"SR",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2400,de:1800,tx:"①：自分がカードをドローした時、そのカードが天使族モンスターだった場合、そのカードを相手に見せて発動できる。\nこのカードがフィールドに表側表示で存在する場合、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"合成魔獣 ガーゼット",h:"ごうせいまじゅう　がーぜっと",e:"Maju Garzett",ra:"R",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:"?",de:0,tx:"このカードの攻撃力は、生け贄召喚時に生け贄に捧げた２体のモンスターの元々の攻撃力を合計した数値になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"剛地帝グランマーグ",h:"ごうちていぐらんまーぐ",e:"Granmarg the Mega Monarch",ra:"UR",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:2800,de:1000,tx:"このカードはアドバンス召喚したモンスター１体をリリースしてアドバンス召喚できる。\nこのカードがアドバンス召喚に成功した時、フィールド上にセットされたカードを２枚まで選択して破壊する。\nこのカードが地属性モンスターをリリースしてアドバンス召喚に成功した場合、その時の効果に以下の効果を加える。\n\n●デッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"鋼鉄の巨神像",h:"こうてつのきょしんぞう",e:"Steel Ogre Grotto #1",ra:"N",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1400,de:1800,tx:"機械の国にまつられているという、鋼鉄の巨神像。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"荒野の女戦士",h:"こうやのおんなせんし",e:"Warrior Lady of the Wasteland",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1100,de:1200,tx:"①：このカードが戦闘で破壊され墓地へ送られた時に発動できる。\nデッキから攻撃力１５００以下の戦士族・地属性モンスター１体を攻撃表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"紅陽鳥",h:"こうようちょう",e:"Crimson Sunbird",ra:"R",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"鳥獣族",mo:["融合"],ma:"",tp:"",at:2300,de:1800,tx:"セイント・バード＋スカイ・ハンター",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"紅葉の女王",h:"こうようのじょおう",e:"Queen of Autumn Leaves",ra:"N",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"植物族",mo:["通常"],ma:"",tp:"",at:1800,de:1500,tx:"鮮やかな紅葉に囲まれて暮らす、緑樹の霊王のお妃。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"強欲ゴブリン",h:"ごうよくごぶりん",e:"Goblin of Greed",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1000,de:1800,tx:"このカードが表側表示で自分フィールド上に存在する限り、お互いは「手札のカードを捨てる事によって発動する効果」を発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"強欲な壺の精霊",h:"ごうよくなつぼのせいれい",e:"Spirit of the Pot of Greed",ra:"N",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"このカードがフィールド上に表側攻撃表示で存在する時に「強欲な壺」を発動した場合、「強欲な壺」を発動したプレイヤーはカードをもう１枚ドローする事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"降雷皇ハモン",h:"こうらいおうはもん",e:"Hamon, Lord of Striking Thunder",ra:"UR",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"雷族",mo:["特殊召喚","効果"],ma:"",tp:"",at:4000,de:4000,tx:"このカードは通常召喚できない。\n自分フィールドの表側表示の永続魔法カード３枚を墓地へ送った場合のみ特殊召喚できる。\n①：このカードがモンスターゾーンに守備表示で存在する限り、相手は他のモンスターを攻撃対象に選択できない。\n②：このカードが戦闘で相手モンスターを破壊し墓地へ送った場合に発動する。\n相手に１０００ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"黄龍の召喚士",h:"こうりゅうのしょうかんし",e:"Golden Dragon Summoner",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"「黄龍の召喚士」の効果は１ターンに１度しか使用できない。\n①：自分フィールドのモンスター１体をリリースし、フィールドのモンスター１体を対象として発動できる。\nそのモンスターを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"黄竜の忍者",h:"こうりゅうのにんじゃ",e:"Yellow Dragon Ninja",ra:"SR",t:"monster",a:"風",l:8,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:3000,de:1500,tx:"このカードは「忍者」モンスターまたは「忍法」カードの効果でしか特殊召喚できない。\n①：１ターンに１度、手札及び自分フィールドの表側表示のカードの中から、「忍者」モンスター１体と「忍法」カード１枚を墓地へ送り、フィールドの魔法・罠カードを２枚まで対象として発動できる。\nそのカードを破壊する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"光鱗のトビウオ",h:"こうりんのとびうお",e:"Golden Flying Fish",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"自分フィールド上に存在するこのカード以外の魚族モンスター１体をリリースして発動する。\nフィールド上のカード１枚を破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"光霊神フォスオラージュ",h:"こうれいしんふぉすおらーじゅ",e:"Phosphorage the Elemental Lord",ra:"SR",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"天使族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2800,de:2200,tx:"このカードは通常召喚できない。\n自分の墓地の光属性モンスターが５体の場合のみ特殊召喚できる。\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した時に発動できる。\n相手フィールドのモンスターを全て破壊する。\n②：表側表示のこのカードがフィールドから離れた場合、次の自分ターンのバトルフェイズをスキップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"光霊使いライナ",h:"こうれいつかいらいな",e:"Lyna the Light Charmer",ra:"R",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["リバース","効果"],ma:"",tp:"",at:500,de:1500,tx:"①：このカードがリバースした場合、相手フィールドの光属性モンスター１体を対象として発動する。\nこのモンスターが表側表示で存在する間、そのモンスターのコントロールを得る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"孤炎星－ロシシン",h:"こえんせい－ろししん",e:"Brotherhood of the Fire Fist - Boar",ra:"R",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["チューナー","効果"],ma:"",tp:"",at:1100,de:1400,tx:"このカードをシンクロ素材とする場合、炎属性モンスターのシンクロ召喚にしか使用できない。\nこのカードが戦闘によって破壊され墓地へ送られた時、デッキから「孤炎星－ロシシン」以外の「炎星」と名のついたレベル４モンスター１体を特殊召喚できる。\n１ターンに１度、このカードがフィールド上に存在する場合に「炎星」と名のついたモンスターが自分のエクストラデッキから特殊召喚された時、デッキから「炎舞」と名のついた魔法カード１枚を選んで自分フィールド上にセットできる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゴーゴン・エッグ",h:"ごーごん・えっぐ",e:"Gorgon Egg",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:300,de:1300,tx:"ゴーゴンが産んだ卵。\n大きな目に映ったものが産まれると言われている。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゴースト",h:"ごーすと",e:"Phantom Ghost",ra:"N",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"アンデット族",mo:["通常"],ma:"",tp:"",at:600,de:800,tx:"この世の成仏できない霊が集まってできた怨霊。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゴースト・ビーフ",h:"ごーすと・びーふ",e:"Ghost Beef",ra:"N",t:"monster",a:"闇",l:7,ps:4,pe:"①：１ターンに１度、自分メインフェイズに発動できる。\nサイコロを１回振る。\nターン終了時まで、このカードのPスケールを出た目の数だけ上げる（最大１０まで）。",tr:"獣族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:2000,de:1000,tx:"グルメな牛の幽霊。\n特に大好物のロースト・ビーフには目がなく、今日も新たな味との出会いに心を躍らせながら現世を彷徨っている。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゴースト王－パンプキング－",h:"ごーすとおう－ぱんぷきんぐ－",e:"Pumpking the King of Ghosts",ra:"UR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1800,de:2000,tx:"「闇晦ましの城」がフィールド上に表側表示で存在する限り、このカードの攻撃力と守備力は１００ポイントアップする。\nまた、自分のスタンバイフェイズ毎にさらに１００ポイントずつアップする。\nこの効果は自分の４回目のスタンバイフェイズまで続く。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゴースト姫－パンプリンセス－",h:"ごーすとひめ－ぱんぷりんせす－",e:"Pumprincess the Princess of Ghosts",ra:"SR",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:900,de:1000,tx:"このカードがモンスターカードゾーン上で破壊された場合、墓地へ送らずに永続魔法カード扱いとして自分の魔法＆罠カードゾーンに表側表示で置く事ができる。\nこの効果で永続魔法カード扱いになっている場合、お互いのスタンバイフェイズ毎にこのカードにパンプキンカウンターを１つ置く。\n相手フィールド上の全てのモンスターの攻撃力・守備力は、永続魔法カード扱いのこのカードに乗っているパンプキンカウンターの数×１００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゴーストリック・アルカード",h:"ごーすとりっく・あるかーど",e:"Ghostrick Alucard",ra:"SR",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1800,de:1600,tx:"レベル３モンスター×２\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、相手は他の「ゴーストリック」モンスター及び裏側守備表示モンスターを攻撃対象に選択できない。\n②：このカードのX素材を１つ取り除き、相手フィールドにセットされたカード１枚を対象として発動できる。\nそのセットされた相手のカードを破壊する。\n③：このカードが墓地へ送られた場合、このカード以外の自分の墓地の「ゴーストリック」カード１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゴーストリック・イエティ",h:"ごーすとりっく・いえてぃ",e:"Ghostrick Yeti",ra:"SR",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:300,de:2000,tx:"自分フィールドに「ゴーストリック」モンスターが存在する場合のみ、このカードは表側表示で召喚できる。\n①：自分メインフェイズに発動できる。\nこのカードを裏側守備表示にする（１ターンに１度のみ）。\n②：このカードがリバースした時、フィールドの「ゴーストリック」モンスター１体を対象として発動できる。\nそのモンスターはこのターン戦闘・効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゴーストリック・キョンシー",h:"ごーすとりっく・きょんしー",e:"Ghostrick Jiangshi",ra:"R",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:400,de:1800,tx:"自分フィールド上に「ゴーストリック」と名のついたモンスターが存在する場合のみ、このカードは表側表示で召喚できる。\nこのカードは１ターンに１度だけ裏側守備表示にする事ができる。\nまた、このカードがリバースした時、自分フィールド上の「ゴーストリック」と名のついたモンスターの数以下のレベルを持つ、「ゴーストリック」と名のついたモンスター１体をデッキから手札に加える事ができる。\n「ゴーストリック・キョンシー」のこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゴーストリック・グール",h:"ごーすとりっく・ぐーる",e:"Ghostrick Ghoul",ra:"R",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1100,de:1200,tx:"自分フィールドに「ゴーストリック」モンスターが存在する場合のみ、このカードは表側表示で召喚できる。\n①：自分メインフェイズに発動できる。\nこのカードを裏側守備表示にする（１ターンに１度のみ）。\n②：自分メインフェイズ１に１度、自分フィールドの「ゴーストリック」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力は次の相手ターンの終了時まで、フィールドの「ゴーストリック」モンスターの元々の攻撃力を合計した数値になり、このターン対象のモンスター以外のモンスターは攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゴーストリック・シュタイン",h:"ごーすとりっく・しゅたいん",e:"Ghostrick Stein",ra:"SR",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1600,de:0,tx:"自分フィールド上に「ゴーストリック」と名のついたモンスターが存在する場合のみ、このカードは表側表示で召喚できる。\nこのカードは１ターンに１度だけ裏側守備表示にする事ができる。\nまた、このカードが相手ライフに戦闘ダメージを与えた時、デッキから「ゴーストリック」と名のついた魔法・罠カード１枚を手札に加える事ができる。\n「ゴーストリック・シュタイン」のこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゴーストリック・スケルトン",h:"ごーすとりっく・すけるとん",e:"Ghostrick Skeleton",ra:"R",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1200,de:1100,tx:"自分フィールド上に「ゴーストリック」と名のついたモンスターが存在する場合のみ、このカードは表側表示で召喚できる。\nこのカードは１ターンに１度だけ裏側守備表示にする事ができる。\nまた、このカードがリバースした時、自分フィールド上の「ゴーストリック」と名のついたモンスターの数まで、相手のデッキの上からカードを裏側表示で除外する。\n「ゴーストリック・スケルトン」のこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゴーストリック・マミー",h:"ごーすとりっく・まみー",e:"Ghostrick Mummy",ra:"R",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1500,de:0,tx:"自分フィールド上に「ゴーストリック」と名のついたモンスターが存在する場合のみ、このカードは表側表示で召喚できる。\nこのカードは１ターンに１度だけ裏側守備表示にする事ができる。\nまた、このカードがフィールド上に表側表示で存在する限り、自分は通常召喚に加えて１度だけ「ゴーストリック」と名のついたモンスター１体を召喚できる。\nこのカードがフィールド上に表側表示で存在する限り、自分は闇属性以外のモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゴーストリック・ワーウルフ",h:"ごーすとりっく・わーうるふ",e:"Ghostrick Warwolf",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1400,de:1500,tx:"自分フィールド上に「ゴーストリック」と名のついたモンスターが存在する場合のみ、このカードは表側表示で召喚できる。\nこのカードは１ターンに１度だけ裏側守備表示にする事ができる。\nまた、このカードがリバースした時、フィールド上にセットされたカードの数×１００ポイントダメージを相手ライフに与える。\n「ゴーストリック・ワーウルフ」のこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゴーストリックの魔女",h:"ごーすとりっくのまじょ",e:"Ghostrick Witch",ra:"SR",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1200,de:200,tx:"自分フィールド上に「ゴーストリック」と名のついたモンスターが存在する場合のみ、このカードは表側表示で召喚できる。\nこのカードは１ターンに１度だけ裏側守備表示にする事ができる。\nまた、１ターンに１度、相手フィールド上に表側表示で存在するモンスター１体を選択して発動できる。\n選択したモンスターを裏側守備表示にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゴーストリックの雪女",h:"ごーすとりっくのゆきおんな",e:"Ghostrick Yuki-onna",ra:"SR",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1000,de:800,tx:"自分フィールド上に「ゴーストリック」と名のついたモンスターが存在する場合のみ、このカードは表側表示で召喚できる。\nこのカードは１ターンに１度だけ裏側守備表示にする事ができる。\nまた、このカードが戦闘によって破壊され墓地へ送られた時に発動できる。\nこのカードを破壊したモンスターは裏側守備表示になり、表示形式を変更できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"瑚之龍",h:"こーらる・どらごん",e:"Coral Dragon",ra:"UR",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","チューナー","効果"],ma:"",tp:"",at:2400,de:500,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：１ターンに１度、手札を１枚捨て、相手フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\n②：S召喚したこのカードが墓地へ送られた場合に発動できる。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"氷の女王",h:"こおりのじょおう",e:"Ice Queen",ra:"R",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2900,de:2100,tx:"このカードは墓地からの特殊召喚はできない。\n自分フィールド上に表側表示で存在するこのカードが破壊され墓地へ送られた時、自分の墓地の魔法使い族モンスターが３体以上の場合、自分の墓地から魔法カード１枚を選択して手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"氷水",h:"こおりみず",e:"Ice Water",ra:"N",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1150,de:900,tx:"攻撃的なマーメイド。\n体に生えているトゲを使って攻撃する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コーリング・ノヴァ",h:"こーりんぐ・のう゛ぁ",e:"Nova Summoner",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1400,de:800,tx:"①：このカードが戦闘で破壊され墓地へ送られた時に発動できる。\nデッキから攻撃力１５００以下の天使族・光属性モンスター１体を特殊召喚する。\nフィールドに「天空の聖域」が存在する場合、代わりに「天空騎士パーシアス」１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"黄金の天道虫",h:"ごーるでん・れでぃばぐ",e:"Golden Ladybug",ra:"R",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"１ターンに１度、自分のスタンバイフェイズ時に、手札のこのカードを相手に見せて発動できる。\n自分は５００ライフポイント回復する。\nこの効果を使用した場合、エンドフェイズ時まで手札のこのカードを公開する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コールド・エンチャンター",h:"こーるど・えんちゃんたー",e:"Cold Enchanter",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"手札を１枚捨てて発動できる。\nフィールド上に表側表示で存在するモンスター１体を選択し、アイスカウンターを１つ置く。\nこのカードがフィールド上に表側表示で存在する限り、このカードの攻撃力は、フィールド上のアイスカウンターの数×３００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゴールド・ガジェット",h:"ごーるど・がじぇっと",e:"Gold Gadget",ra:"UR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1700,de:800,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードが召喚・特殊召喚に成功した時に発動できる。\n手札から機械族・レベル４モンスター１体を特殊召喚する。\n②：このカードが戦闘・効果で破壊された場合に発動できる。\nデッキから「ゴールド・ガジェット」以外のレベル４の「ガジェット」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"成金忍者",h:"ごーるどにんじゃ",e:"Upstart Golden Ninja",ra:"UR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:500,de:1800,tx:"１ターンに１度、手札から罠カード１枚を墓地へ送って発動できる。\nデッキからレベル４以下の「忍者」と名のついたモンスター１体を表側守備表示、または裏側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゴーレム",h:"ごーれむ",e:"Brain Golem",ra:"R",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2500,de:1800,tx:"このカードがフィールド上に表側表示で存在する限り、フィールド上に表側表示で存在する光属性モンスターの効果は無効化される。\nこのカードが戦闘によって光属性モンスターを破壊した場合、もう１度だけ続けて攻撃する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゴーレム・ドラゴン",h:"ごーれむ・どらごん",e:"Golem Dragon",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:200,de:2000,tx:"このカードがフィールド上に表側表示で存在する限り、相手は表側表示で存在する他のドラゴン族モンスターを攻撃対象に選択する事はできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"子型ペンギン",h:"こがたぺんぎん",e:"Puny Penguin",ra:"R",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:400,de:200,tx:"リバースしたこのカードが墓地へ送られた時、自分の墓地から「子型ペンギン」以外の「ペンギン」と名のついたモンスター１体を選択し、表側攻撃表示または裏側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コカローチ・ナイト",h:"こかろーち・ないと",e:"Cockroach Knight",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:800,de:900,tx:"このカードが墓地へ送られた時、このカードはデッキの一番上に戻る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゴギガ・ガガギゴ",h:"ごぎが・ががぎご",e:"Gogiga Gagagigo",ra:"N",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"爬虫類族",mo:["通常"],ma:"",tp:"",at:2950,de:2800,tx:"既に精神は崩壊し、肉体は更なるパワーを求めて暴走する。\nその姿にかつての面影はない・・・。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゴキボール",h:"ごきぼーる",e:"Gokibore",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["通常"],ma:"",tp:"",at:1200,de:1400,tx:"丸いゴキブリ。\nゴロゴロ転がって攻撃。\n守備が意外と高いぞ。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゴキポン",h:"ごきぽん",e:"Gokipon",ra:"R",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"このカードが戦闘によって破壊され墓地へ送られた時、デッキから攻撃力１５００以下の昆虫族モンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"黒衣の大賢者",h:"こくいのだいけんじゃ",e:"Dark Sage",ra:"SR",t:"monster",a:"闇",l:9,ps:"",pe:"",tr:"魔法使い族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2800,de:3200,tx:"このカードは通常召喚できない。\n自分の「時の魔術師」のコイントスを当てた時、自分フィールド上に存在する「ブラック・マジシャン」１体をリリースして手札またはデッキから特殊召喚する事ができる。\nこの方法で特殊召喚に成功した時、自分のデッキから魔法カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"虚空海竜リヴァイエール",h:"こくうかいりゅうりう゛ぁいえーる",e:"Leviair the Sea Dragon",ra:"UR",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"水族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1800,de:1600,tx:"レベル３モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除き、除外されている自分または相手のレベル４以下のモンスター１体を対象として発動できる。\nそのモンスターを自分フィールドに特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"虚空の騎士",h:"こくうのきし",e:"Altitude Knight",ra:"N",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1400,de:1200,tx:"このカードがフィールド上に表側表示で存在する場合にフィールド上のモンスターが手札・デッキに戻った時、自分のデッキから風属性モンスター１体を墓地へ送る。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"C・チッキー",h:"こくーん・ちっきー",e:"Chrysalis Chicky",ra:"N",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:600,de:400,tx:"フィールド上に「ネオスペース」が存在する時、このカードを生け贄に捧げる事で手札またはデッキから「N・エア・ハミングバード」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"C・ドルフィーナ",h:"こくーん・どるふぃーな",e:"Chrysalis Dolphin",ra:"N",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:400,de:600,tx:"フィールド上に「ネオスペース」が存在する時、このカードを生け贄に捧げる事で手札またはデッキから「N・アクア・ドルフィン」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"C・パンテール",h:"こくーん・ぱんてーる",e:"Chrysalis Pantail",ra:"N",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:800,de:300,tx:"フィールド上に「ネオスペース」が存在する時、このカードを生け贄に捧げる事で手札またはデッキから「N・ブラック・パンサー」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"C・ピニー",h:"こくーん・ぴにー",e:"Chrysalis Pinny",ra:"N",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:100,de:700,tx:"フィールド上に「ネオスペース」が存在する時、このカードを生け贄に捧げる事で手札またはデッキから「N・グロー・モス」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"C・モーグ",h:"こくーん・もーぐ",e:"Chrysalis Mole",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:700,de:100,tx:"フィールド上に「ネオスペース」が存在する時、このカードを生け贄に捧げる事で手札またはデッキから「N・グラン・モール」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"C・ラーバ",h:"こくーん・らーば",e:"Chrysalis Larva",ra:"N",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:300,de:300,tx:"フィールド上に「ネオスペース」が存在する時、このカードを生け贄に捧げる事で手札またはデッキから「N・フレア・スカラベ」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"獄炎のカース・オブ・ドラゴン",h:"ごくえんのかーす・おぶ・どらごん",e:"Curse of Dragonfire",ra:"SR",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2000,de:1500,tx:"①：このカードが召喚・特殊召喚に成功した場合、フィールドのフィールド魔法カード１枚を対象として発動できる。\nそのカードを破壊する。\n②：１ターンに１度、自分メインフェイズに発動できる。\n融合モンスターカードによって決められた、このカードを含む融合素材モンスターを自分フィールドから墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"黒炎の騎士－ブラック・フレア・ナイト－",h:"こくえんのきし－ぶらっく・ふれあ・ないと－",e:"Dark Flare Knight",ra:"R",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2200,de:800,tx:"「ブラック・マジシャン」＋「炎の剣士」\n戦闘によって発生したこのカードのコントローラーへのダメージは０になる。\nこのカードが戦闘によって破壊され墓地へ送られた時、自分のデッキまたは手札から「幻影の騎士－ミラージュ・ナイト－」を１体特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"黒曜岩竜",h:"こくようがんりゅう",e:"Obsidian Dragon",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:800,de:2100,tx:"このカードがフィールド上に表側表示で存在する限り、自分フィールド上の闇属性モンスター１体を対象にする魔法・罠カードの効果を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"黒翼の魔術師",h:"こくよくのまじゅつし",e:"Night Wing Sorceress",ra:"N",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1300,de:1200,tx:"このカードがフィールド上に表側表示で存在する限り、このカードのコントローラーは「バスター・モード」をセットしたターンに発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"獄落鳥",h:"ごくらくちょう",e:"Bird of Paradise Lost",ra:"R",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"幻竜族",mo:["チューナー","効果"],ma:"",tp:"",at:2700,de:1500,tx:"①：このカードの攻撃力・守備力は、自分の墓地のチューナーの数×１００アップする。\n②：１ターンに１度、手札のチューナー１体を墓地へ送り、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターのコントロールをエンドフェイズまで得る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"黒竜の忍者",h:"こくりゅうのにんじゃ",e:"Black Dragon Ninja",ra:"R",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:2800,de:1600,tx:"このカードは「忍者」モンスターまたは「忍法」カードの効果でしか特殊召喚できない。\n①：１ターンに１度、手札及び自分フィールドの表側表示のカードの中から、「忍者」モンスター１体と「忍法」カード１枚を墓地へ送り、フィールドのモンスター１体を対象として発動できる。\nそのモンスターを除外する。\nこの効果は相手ターンでも発動できる。\n②：表側表示のこのカードがフィールドから離れた場合に発動する。\nこのカードの効果で除外されているモンスターを可能な限り元々の持ち主のフィールドに特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"黒竜の雛",h:"こくりゅうのひな",e:"Black Dragon's Chick",ra:"SR",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:800,de:500,tx:"自分フィールド上に表側表示で存在するこのカードを墓地へ送って発動できる。\n手札から「真紅眼の黒竜」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コケ",h:"こけ",e:"Niwatori",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["通常"],ma:"",tp:"",at:900,de:800,tx:"相手を丸飲みにして、自分のエネルギーとして取り込んでしまう。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"こけコッコ",h:"こけこっこ",e:"Cockadoodledoo",ra:"SR",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"鳥獣族",mo:["チューナー","効果"],ma:"",tp:"",at:1600,de:2000,tx:"①：お互いのフィールドにモンスターが存在しない場合、このカードはレベル３モンスターとして手札から特殊召喚できる。\n②：相手フィールドにモンスターが存在し、自分フィールドにカードが存在しない場合、このカードはレベル４モンスターとして手札から特殊召喚できる。\n③：表側表示のこのカードはフィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"孤高除獣",h:"ここうのけもの",e:"Aloof Lupine",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1700,de:300,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時、手札からモンスター１体を除外して発動できる。\nデッキからそのモンスターと同じ種族のモンスター１体を除外する。\n②：このカードが戦闘または相手の効果で破壊された場合、除外されている自分のモンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:2,lk:[],ta:[],al:[],ocg:true},
  {n:"ゴゴゴギガース",h:"ごごごぎがーす",e:"Gogogo Gigas",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:0,de:2200,tx:"このカードが墓地に存在し、自分が「ゴゴゴ」と名のついたモンスターの特殊召喚に成功した場合、このカードを墓地から表側守備表示で特殊召喚できる。\n「ゴゴゴギガース」の効果は１ターンに１度しか使用できず、この効果を発動するターン、自分はバトルフェイズを行えない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゴゴゴゴースト",h:"ごごごごーすと",e:"Gogogo Ghost",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1900,de:0,tx:"このカードが特殊召喚に成功した場合、自分の墓地の「ゴゴゴゴーレム」１体を選択して表側守備表示で特殊召喚できる。\nその後、このカードは守備表示になる。\n「ゴゴゴゴースト」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゴゴゴゴーレム",h:"ごごごごーれむ",e:"Gogogo Golem",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1800,de:1500,tx:"①：守備表示のこのカードは１ターンに１度だけ戦闘では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゴゴゴゴーレム－GF",h:"ごごごごーれむ－ごーるでんふぉーむ",e:"Gogogo Golem - Golden Form",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["特殊召喚","効果"],ma:"",tp:"",at:"?",de:1500,tx:"このカードは通常召喚できない。\n自分フィールド上の「ゴゴゴ」と名のついたモンスター１体をリリースした場合のみ特殊召喚できる。\nこのカードの攻撃力は、リリースしたそのモンスターの元々の攻撃力を倍にした数値になる。\nこのカードの戦闘によって発生する相手への戦闘ダメージは半分になる。\nまた、１ターンに１度、相手フィールド上で効果モンスターの効果が発動した時に発動する。\nこのカードの攻撃力を１５００ポイントダウンし、その効果を無効にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゴゴゴジャイアント",h:"ごごごじゃいあんと",e:"Gogogo Giant",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:2000,de:0,tx:"①：このカードが召喚に成功した時、自分の墓地の「ゴゴゴ」モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\nその後、このカードは守備表示になる。\n②：このカードは攻撃した場合、バトルフェイズ終了時に守備表示になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コザッキー",h:"こざっきー",e:"Kozaky",ra:"N",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:400,de:400,tx:"魔界言語の研究に全てを捧げているモーレツ悪魔。\n働きすぎで精神が崩壊している。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"古神クトグア",h:"こしんくとぐあ",e:"Old Entity Cthugua",ra:"SR",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["シンクロ","効果"],ma:"",tp:"",at:2200,de:200,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：このカードがS召喚に成功した場合に発動できる。\nフィールドのランク４のXモンスターを全て持ち主のエクストラデッキに戻す。\n②：このカードを素材とした融合召喚に成功した場合に発動する。\n自分はデッキから１枚ドローする。\n③：フィールドのこのカードを素材としてX召喚したモンスターは以下の効果を得る。\n●このX召喚に成功した場合に発動する。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"護神鳥シムルグ",h:"ごしんちょうしむるぐ",e:"Simorgh, Bird of Protection",ra:"R",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1400,de:1000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時、相手の魔法＆罠ゾーンのカード１枚を対象として発動できる。\nそのカードを持ち主の手札に戻す。\n②：このカードが墓地に存在し、相手の魔法＆罠ゾーンにカードが存在しない場合に発動できる。\nこのカードを守備表示で特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。\nこの効果の発動後、ターン終了時まで自分は鳥獣族モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"古神ハストール",h:"こしんはすとーる",e:"Old Entity Hastorr",ra:"SR",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["シンクロ","効果"],ma:"",tp:"",at:2300,de:1000,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：このカードがモンスターゾーンから墓地へ送られた場合、相手フィールドの表側表示モンスター１体を対象として発動できる。\nこのカードを装備カード扱いとしてそのモンスターに装備する。\n装備モンスターは攻撃できず、効果は無効化される。\n②：このカードの効果で相手モンスターに装備されているこのカードがフィールドから離れた場合に発動する。\nこのカードを装備していた相手モンスターのコントロールを得る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"牛頭鬼",h:"ごずき",e:"Gozuki",ra:"UR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1700,de:800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\nデッキからアンデット族モンスター１体を墓地へ送る。\n②：このカードが墓地へ送られた場合、自分の墓地から「牛頭鬼」以外のアンデット族モンスター１体を除外して発動できる。\n手札からアンデット族モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コスモクイーン",h:"こすもくいーん",e:"Cosmo Queen",ra:"N",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:2900,de:2450,tx:"宇宙に存在する、全ての星を統治しているという女王。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コスモブレイン",h:"こすもぶれいん",e:"Cosmo Brain",ra:"R",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"魔法使い族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1500,de:2450,tx:"このカードは通常召喚できない。\n手札及び自分フィールドの表側表示モンスターの中から、効果モンスター以外のモンスター１体を墓地へ送った場合に特殊召喚できる。\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードの攻撃力は、このカードを特殊召喚するために墓地へ送ったモンスターのレベル×２００アップする。\n②：自分フィールドの効果モンスター１体をリリースして発動できる。\n手札・デッキから通常モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"古代のトカゲ戦士",h:"こだいのとかげせんし",e:"Ancient Lizard Warrior",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["通常"],ma:"",tp:"",at:1400,de:1100,tx:"太古の、昔の姿そのままのトカゲの戦士。\n意外に強いぞ。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"古代魔導士",h:"こだいまどうし",e:"Ancient Sorcerer",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:1000,de:1300,tx:"数多くの杖を持ち、それぞれを使い分け多彩な攻撃をする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"子狸たんたん",h:"こだぬきたんたん",e:"Baby Raccoon Tantan",ra:"R",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["リバース","効果"],ma:"",tp:"",at:0,de:800,tx:"リバース：デッキから「子狸たんたん」以外の獣族・レベル２モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"子狸ぽんぽこ",h:"こだぬきぽんぽこ",e:"Baby Raccoon Ponpoko",ra:"R",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:800,de:0,tx:"このカードが召喚に成功した時、デッキから「子狸ぽんぽこ」以外の獣族・レベル２モンスター１体を裏側守備表示で特殊召喚できる。\nこの効果を発動するターン、自分は獣族以外のモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゴッドオーガス",h:"ごっどおーがす",e:"Orgoth the Relentless",ra:"UR",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2500,de:2450,tx:"①：１ターンに１度、自分メインフェイズに発動できる。\nサイコロを３回振る。\nこのカードの攻撃力・守備力は相手ターン終了時まで、出た目の合計×１００アップする。\nその後、出た目の２つが同じ場合、その同じ目によって以下の効果を適用する。\n出た目の全てが同じ場合、以下の効果を全て適用する。\n\n●１・２：このカードは相手ターン終了時まで戦闘・効果では破壊されない。\n\n●３・４：自分はデッキから２枚ドローする。\n\n●５・６：このターン、このカードは直接攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コドモドラゴン",h:"こどもどらごん",e:"Kidmodo Dragon",ra:"SR",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:100,de:200,tx:"このカード名の効果は１ターンに１度しか使用できず、この効果を発動するターン、自分はドラゴン族モンスターしか特殊召喚できず、バトルフェイズを行えない。\n①：このカードが墓地へ送られた場合に発動できる。\n手札からドラゴン族モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コピー・プラント",h:"こぴー・ぷらんと",e:"Copy Plant",ra:"R",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"植物族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"１ターンに１度、このカード以外のフィールド上の植物族モンスター１体を選択して発動できる。\nこのカードのレベルはエンドフェイズ時まで、選択したモンスターと同じレベルになる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コピックス",h:"こぴっくす",e:"Eyearmor",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:600,de:500,tx:"いろんな奴に変身して、相手をだましながら戦う戦士。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゴブリン穴埋め部隊",h:"ごぶりんあなうめぶたい",e:"Goblin Pothole Squad",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:400,tx:"このカードが召喚に成功した時、罠カードを発動する事はできない。\nまた、このカードがフィールド上に表側表示で存在する限り、モンスターが召喚・反転召喚・特殊召喚に成功した時、「落とし穴」と名のついた罠カードを発動する事はできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゴブリン暗殺部隊",h:"ごぶりんあんさつぶたい",e:"Goblin Black Ops",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1300,de:0,tx:"このカードは相手プレイヤーを直接攻撃する事ができる。\nこのカードは攻撃した場合、バトルフェイズ終了時に守備表示になる。\n次の自分のターン終了時までこのカードは表示形式を変更できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゴブリンエリート部隊",h:"ごぶりんえりーとぶたい",e:"Goblin Elite Attack Force",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2200,de:1500,tx:"①：このカードは攻撃した場合、バトルフェイズ終了時に守備表示になり、次の自分ターンの終了時まで表示形式を変更できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゴブリン切り込み部隊",h:"ごぶりんきりこみぶたい",e:"Goblin Marauding Squad",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1900,de:0,tx:"このカードの攻撃宣言時、相手は魔法・罠・効果モンスターの効果を発動できない。\nこのカードは攻撃した場合、バトルフェイズ終了時に守備表示になり、次の自分のターン終了時までこのカードは表示形式を変更できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゴブリンゾンビ",h:"ごぶりんぞんび",e:"Goblin Zombie",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1100,de:1050,tx:"①：このカードが相手に戦闘ダメージを与えた場合に発動する。\n相手のデッキの一番上のカードを墓地へ送る。\n②：このカードがフィールドから墓地へ送られた場合に発動する。\nデッキから守備力１２００以下のアンデット族モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゴブリン偵察部隊",h:"ごぶりんていさつぶたい",e:"Goblin Recon Squad",ra:"R",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:0,tx:"このカードが相手プレイヤーへの直接攻撃に成功した場合、相手の手札をランダムに１枚確認する事ができる。\n確認したカードが魔法カードだった場合、そのカードを墓地に送る。\nこのカードは攻撃した場合、バトルフェイズ終了時に守備表示になる。\n次の自分のターン終了時までこのカードは表示形式を変更できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゴブリン突撃部隊",h:"ごぶりんとつげきぶたい",e:"Goblin Attack Force",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2300,de:0,tx:"①：このカードは攻撃した場合、バトルフェイズ終了時に守備表示になり、次の自分ターンの終了時まで表示形式を変更できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゴブリンドバーグ",h:"ごぶりんどばーぐ",e:"Goblindbergh",ra:"UR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1400,de:0,tx:"①：このカードが召喚に成功した時に発動できる。\n手札からレベル４以下のモンスター１体を特殊召喚する。\nこの効果を発動した場合、このカードは守備表示になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゴブリン陽動部隊",h:"ごぶりんようどうぶたい",e:"Goblin Decoy Squad",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:0,tx:"このカードが相手ライフに戦闘ダメージを与えた時、自分のデッキからカードを１枚ドローする事ができる。\nこのカードは攻撃した場合、バトルフェイズ終了時に守備表示になり、次の自分のターンのエンドフェイズ時まで表示形式を変更する事ができない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コマンダー",h:"こまんだー",e:"Cyber Commander",ra:"N",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:750,de:700,tx:"ロケットランチャーとバズーカ砲を装備した実戦部隊。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コマンド・ナイト",h:"こまんど・ないと",e:"Command Knight",ra:"SR",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:1900,tx:"①：このカードがモンスターゾーンに存在する限り、自分フィールドの戦士族モンスターの攻撃力は４００アップする。\n②：自分フィールドに他のモンスターが存在する限り、相手はこのカードを攻撃対象に選択できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゴヨウ・エンペラー",h:"ごよう・えんぺらー",e:"Goyo Emperor",ra:"N",t:"monster",a:"地",l:10,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:3300,de:2500,tx:"戦士族・地属性のSモンスター×２\n①：このカードまたは元々の持ち主が相手となる自分のモンスターが戦闘で相手モンスターを破壊し墓地へ送った時に発動できる。\n破壊したそのモンスターを自分フィールドに特殊召喚する。\n②：相手がモンスターを特殊召喚した時、自分フィールドの戦士族・地属性のSモンスター１体をリリースして発動できる。\nそのモンスターのコントロールを得る。\n③：表側表示のこのカードがフィールドから離れた場合に発動する。\n自分フィールドの全てのモンスターのコントロールは、元々の持ち主に戻る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゴヨウ・ガーディアン",h:"ごよう・がーでぃあん",e:"Goyo Guardian",ra:"UR",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2800,de:2000,tx:"地属性チューナー＋チューナー以外のモンスター１体以上\n①：このカードが戦闘で相手モンスターを破壊し墓地へ送った時に発動できる。\nそのモンスターを自分フィールドに守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゴヨウ・チェイサー",h:"ごよう・ちぇいさー",e:"Goyo Chaser",ra:"UR",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:1900,de:1000,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：このカードの攻撃力は、このカード以外のフィールドの戦士族・地属性のSモンスターの数×３００アップする。\n②：このカードが戦闘で相手モンスターを破壊し墓地へ送った時に発動できる。\nそのモンスターの攻撃力を半分にして自分フィールドに特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゴヨウ・ディフェンダー",h:"ごよう・でぃふぇんだー",e:"Goyo Defender",ra:"SR",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:1000,de:1000,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：１ターンに１度、自分フィールドのモンスターが戦士族・地属性のSモンスターのみの場合に発動できる。\nエクストラデッキから「ゴヨウ・ディフェンダー」１体を特殊召喚する。\n②：このカードが攻撃対象に選択された時に発動できる。\nこのカードの攻撃力はそのダメージステップ終了時まで、このカード以外の自分フィールドの戦士族・地属性のSモンスターの数×１０００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゴラ・タートル",h:"ごら・たーとる",e:"Gora Turtle",ra:"N",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1100,de:1100,tx:"このカードがフィールド上に表側表示で存在する限り、攻撃力１９００以上のモンスターは攻撃宣言をする事ができない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゴルゴイル",h:"ごるごいる",e:"Golgoil",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:900,de:1600,tx:"異次元に通じる穴から出てくる、大きな鋼鉄の魔人。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゴルゴニック・ガーゴイル",h:"ごるごにっく・がーごいる",e:"Gorgonic Gargoyle",ra:"R",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1000,de:800,tx:"自分が岩石族モンスターの召喚に成功した時、このカードを手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゴルゴニック・ガーディアン",h:"ごるごにっく・がーでぃあん",e:"Gorgonic Guardian",ra:"SR",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"岩石族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1600,de:1200,tx:"岩石族レベル３モンスター×２\n１ターンに１度、このカードのエクシーズ素材を１つ取り除き、相手フィールド上に表側表示で存在するモンスター１体を選択して発動できる。\nターン終了時まで、選択したモンスターの攻撃力を０にし、その効果を無効にする。\nこの効果は相手ターンでも発動できる。\nまた、１ターンに１度、フィールド上の攻撃力が０のモンスター１体を選択して発動できる。\n選択したモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゴルゴニック・グール",h:"ごるごにっく・ぐーる",e:"Gorgonic Ghoul",ra:"N",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"自分フィールド上に「ゴルゴニック・グール」が存在する場合、３００ライフポイントを払って発動できる。\nこのカードを手札から特殊召喚する。\n「ゴルゴニック・グール」の効果は１ターンに２度まで使用できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゴルゴニック・ケルベロス",h:"ごるごにっく・けるべろす",e:"Gorgonic Cerberus",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1300,de:300,tx:"このカードが召喚に成功した時、自分フィールド上の全ての岩石族モンスターのレベルを３にできる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゴロゴル",h:"ごろごる",e:"Gonogo",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1350,de:1600,tx:"このカードと戦闘を行った相手モンスターは、ダメージステップ終了時に裏側守備表示になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コロボックリ",h:"ころぼっくり",e:"Acorno",ra:"N",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:200,de:400,tx:"自分のメインフェイズ時に発動する事ができる。\n手札から「マツボックル」１体を墓地へ送り、このカードを手札から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"混源龍レヴィオニア",h:"こんげんりゅうれう゛ぃおにあ",e:"Chaos Dragon Levianeer",ra:"UR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3000,de:0,tx:"このカードは通常召喚できない。\n自分の墓地から光・闇属性モンスターを合計３体除外した場合に特殊召喚できる。\nこのカード名の効果は１ターンに１度しか使用できない。\n①：この方法でこのカードが特殊召喚に成功した時に発動できる。\nその特殊召喚のために除外したモンスターの属性によって以下の効果を適用する。\nこのターン、このカードは攻撃できない。\n\n●光のみ：自分の墓地からモンスター１体を選んで守備表示で特殊召喚する。\n\n●闇のみ：相手の手札をランダムに１枚選んでデッキに戻す。\n\n●光と闇：フィールドのカードを２枚まで選んで破壊する。",li:3,lk:[],ta:[],al:[],ocg:true},
  {n:"言語道断侍",h:"ごんごどうだんざむらい",e:"Sasuke Samurai #2",ra:"R",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:200,de:300,tx:"８００ライフポイントを払う。\nエンドフェイズまで、全ての魔法・罠カードは発動する事ができない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魂食神龍ドレイン・ドラゴン",h:"こんじきしんりゅうどれいん・どらごん",e:"Soul Drain Dragon",ra:"N",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:4000,de:0,tx:"このカードは通常召喚できない。\n自分のドラゴン族エクシーズモンスターの効果でのみ特殊召喚できる。\nこのカードが特殊召喚に成功した時、自分のライフポイントが相手より少ない場合、このカードの攻撃力はその差の数値分アップし、このターン相手プレイヤーが受ける全てのダメージは０になる。\nまた、このカードは相手プレイヤーに直接攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コンデンサー・デスストーカー",h:"こんでんさー・ですすとーかー",e:"Capacitor Stalker",ra:"N",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:2000,de:1000,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時、このカード以外の自分フィールドのサイバース族モンスター１体を対象として発動できる。\nこのモンスターが表側表示で存在する間、そのモンスターの攻撃力は８００アップする。\n②：モンスターゾーンのこのカードが効果で破壊され墓地へ送られた場合に発動する。\nお互いのプレイヤーは８００ダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"C・HERO カオス",h:"こんとらすとひーろー　かおす",e:"Contrast HERO Chaos",ra:"UR",t:"monster",a:"闇",l:9,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:3000,de:2600,tx:"「M・HERO」モンスター×２\nこのカードはルール上「E・HERO」モンスターとしても扱う。\nこのカードは融合召喚でしか特殊召喚できない。\n①：このカードがモンスターゾーンに存在する限り、このカードの属性は「光」としても扱う。\n②：１ターンに１度、フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードの効果をターン終了時まで無効にする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"混沌幻魔アーミタイル",h:"こんとんげんまあーみたいる",e:"Armityle the Chaos Phantasm",ra:"UR",t:"monster",a:"闇",l:12,ps:"",pe:"",tr:"悪魔族",mo:["融合","効果"],ma:"",tp:"",at:0,de:0,tx:"「神炎皇ウリア」＋「降雷皇ハモン」＋「幻魔皇ラビエル」\n自分フィールドの上記カードを除外した場合のみ、EXデッキから特殊召喚できる（「融合」は必要としない）。\n①：このカードの攻撃力は自分ターンの間１００００アップする。\n②：このカードは戦闘では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"混沌の黒魔術師",h:"こんとんのくろまじゅつし",e:"Dark Magician of Chaos",ra:"UR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2800,de:2600,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功したターンのエンドフェイズに、自分の墓地の魔法カード１枚を対象として発動できる。\nそのカードを手札に加える。\n②：このカードが戦闘で相手モンスターを破壊したダメージ計算後に発動する。\nその相手モンスターを除外する。\n③：表側表示のこのカードはフィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"混沌の使者",h:"こんとんのししゃ",e:"Envoy of Chaos",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:0,tx:"①：自分・相手のバトルフェイズにこのカードを手札から捨て、自分フィールドの「カオス・ソルジャー」モンスターまたは「暗黒騎士ガイア」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで１５００アップし、このターンそのモンスターと戦闘を行う相手モンスターの攻撃力はダメージ計算時のみ元々の攻撃力になる。\n②：自分・相手のエンドフェイズにこのカードが墓地に存在する場合、自分の墓地からこのカード以外の光属性と闇属性のモンスターを１体ずつ除外して発動できる。\nこのカードを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"混沌の呪術師",h:"こんとんのじゅじゅつし",e:"Witch Doctor of Chaos",ra:"R",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["リバース","効果"],ma:"",tp:"",at:500,de:500,tx:"リバース：自分または相手の墓地からモンスターカードを１枚選択する。\n選択したカードをゲームから除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コンボファイター",h:"こんぼふぁいたー",e:"Combo Fighter",ra:"N",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:800,tx:"このカードがフィールド上に表側表示で存在する時に自分ターンのメインフェイズ１でチェーンが発生した場合、このターンのみこのカードは１度のバトルフェイズ中に２回攻撃する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コンボマスター",h:"こんぼますたー",e:"Combo Master",ra:"N",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2200,de:1500,tx:"このカードがフィールド上に表側表示で存在する時に自分ターンのメインフェイズ１でチェーンが発生した場合、このターンのみこのカードは１度のバトルフェイズ中に２回攻撃する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"THE トリッキー",h:"ざ　とりっきー",e:"The Tricky",ra:"UR",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2000,de:1200,tx:"①：このカードは手札を１枚捨てて、手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ザ・カリキュレーター",h:"ざ・かりきゅれーたー",e:"The Calculator",ra:"R",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:"?",de:0,tx:"①：このカードの攻撃力は、自分フィールドのモンスターのレベルの合計×３００になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ザ・キックマン",h:"ざ・きっくまん",e:"The Kick Man",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1300,de:300,tx:"このカードが特殊召喚に成功した時、自分の墓地の装備魔法カード１枚をこのカードに装備する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ザ・キャリブレーター",h:"ざ・きゃりぶれーたー",e:"The Calibrator",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:1500,de:1500,tx:"このカードの攻撃力は、相手フィールド上のエクシーズモンスターのランクの合計×３００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"The grand JUPITER",h:"ざ・ぐらんど・じゅぴたー",e:"The Grand Jupiter",ra:"R",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2500,de:2000,tx:"①：１ターンに１度、手札を２枚捨て、相手フィールドの表側表示モンスター１体を対象として発動できる。\nその表側表示モンスターを装備カード扱いとしてこのカードに装備する。\n②：このカードの攻撃力は、このカードの効果で装備したモンスターの元々の攻撃力分アップする。\n③：自分・相手のエンドフェイズに、このカードの効果で装備したモンスターカード１枚を対象として発動できる。\nそのカードを自分フィールドに特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"創世神",h:"ざ・くりえいたー",e:"The Creator",ra:"UR",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:2300,de:3000,tx:"自分の墓地からモンスターを１体選択する。\n手札を１枚墓地に送り、選択したモンスター１体を特殊召喚する。\nこの効果は１ターンに１度しか使用できない。\nこのカードは墓地からの特殊召喚はできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"The supremacy SUN",h:"ざ・すぷれましー・さん",e:"The Supremacy Sun",ra:"SR",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:3000,de:3000,tx:"このカードはこのカードの効果でしか特殊召喚できない。\nフィールド上に表側表示で存在するこのカードが破壊され墓地へ送られた場合、次のターンのスタンバイフェイズ時に手札を１枚捨てる事で、このカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"The splendid VENUS",h:"ざ・すぷれんでぃっど・う゛ぃーなす",e:"Splendid Venus",ra:"SR",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2800,de:2400,tx:"①：このカードがモンスターゾーンに存在する限り、天使族以外のフィールドのモンスターの攻撃力・守備力は５００ダウンし、自分フィールドの魔法・罠カードの効果の発動及びその発動した効果は無効化されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"The despair URANUS",h:"ざ・でぃすぺあ・うらぬす",e:"The Despair Uranus",ra:"R",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:2900,de:2300,tx:"①：自分フィールドに魔法・罠カードが存在せず、このカードがアドバンス召喚に成功した時に発動できる。\n相手はカードの種類（永続魔法・永続罠）を宣言する。\n自分はデッキから宣言した種類のカード１枚を選んで自分の魔法＆罠ゾーンにセットする。\n②：このカードの攻撃力は、自分フィールドの表側表示の魔法・罠カードの数×３００アップする。\n③：このカードがモンスターゾーンに存在する限り、自分の魔法＆罠ゾーンの表側表示のカードは効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"The tripping MERCURY",h:"ざ・とりっぴんぐ・まーきゅりー",e:"The Tripper Mercury",ra:"SR",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:2000,de:2000,tx:"①：このカードがアドバンス召喚に成功した時に発動できる。\nフィールドのモンスターを全て表側攻撃表示にする。\n②：このカードはモンスター３体をリリースして召喚する事もできる。\n③：このカードの②の方法で召喚したこのカードがモンスターゾーンに存在する限り、相手フィールドのモンスターの攻撃力はそのモンスターの元々の攻撃力分ダウンする。\n④：このカードは１度のバトルフェイズ中に２回攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"The big SATURN",h:"ざ・びっぐ・さたーん",e:"The Big Saturn",ra:"R",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2800,de:2200,tx:"このカードは手札またはデッキからの特殊召喚はできない。\n手札を１枚捨てて１０００ライフポイントを払う。\nエンドフェイズ時までこのカードの攻撃力は１０００ポイントアップする。\nこの効果は１ターンに１度だけ自分のメインフェイズに使用する事ができる。\n相手がコントロールするカードの効果によってこのカードが破壊され墓地へ送られた時、お互いにその攻撃力分のダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サーチ・ストライカー",h:"さーち・すとらいかー",e:"Search Striker",ra:"SR",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"このカードが裏側守備表示のモンスターを攻撃した場合、ダメージ計算を行わず裏側守備表示のままそのモンスターを破壊する事ができる。\nこの効果を使用した場合、このカードはバトルフェイズ終了時に守備表示になり、次の自分のターンのエンドフェイズ時まで表示形式を変更する事ができない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サーチライトメン",h:"さーちらいとめん",e:"Searchlightman",ra:"R",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"機械族",mo:["リバース","効果"],ma:"",tp:"",at:1000,de:1000,tx:"リバース：このターン相手プレイヤーはフィールド上にカードをセットする事ができない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サーマル・ジェネクス",h:"さーまる・じぇねくす",e:"Thermal Genex",ra:"R",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:2400,de:1200,tx:"「ジェネクス・コントローラー」＋チューナー以外の炎属性モンスター１体以上\nこのカードの攻撃力は自分の墓地の炎属性モンスターの数×２００ポイントアップする。\nこのカードが戦闘によって相手モンスターを破壊した場合、自分の墓地の「ジェネクス」と名のついたモンスターの数×２００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイ・ガール",h:"さい・がーる",e:"Esper Girl",ra:"R",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"サイキック族",mo:["チューナー","効果"],ma:"",tp:"",at:500,de:300,tx:"ゲームから除外されているこのカードが特殊召喚に成功した時、自分のデッキの一番上のカードを裏側表示でゲームから除外する。\nこのカードがフィールド上から墓地へ送られた時、このカードの効果で除外した自分のカードを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイガー",h:"さいがー",e:"Torike",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:1200,de:600,tx:"守備は見た目ほど高くないが、ツノによる攻撃は強力だ。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイキック・ビースト",h:"さいきっく・びーすと",e:"Psi-Beast",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:700,de:500,tx:"このカードが召喚に成功した時、自分のデッキのサイキック族モンスター１体をゲームから除外できる。\nこのカードはこの効果で除外したモンスターと同じレベルになる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイキック・リフレクター",h:"さいきっく・りふれくたー",e:"Psi-Reflector",ra:"SR",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"サイキック族",mo:["チューナー","効果"],ma:"",tp:"",at:400,de:300,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\n「サイキック・リフレクター」以外の「バスター・モード」のカード名が記されたカードまたは「バスター・モード」１枚をデッキから手札に加える。\n②：手札の「バスター・モード」１枚を相手に見せ、「サイキック・リフレクター」以外の「バスター・モード」のカード名が記された自分の墓地のモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚し、そのレベルを４つまで上げる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイクロイド",h:"さいくろいど",e:"Cycroid",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:800,de:1000,tx:"数あるビークロイドの中で、最も親しみ深く愛されるビークロイド。\n補助輪を装備する事もできるぞ！",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイクロプス",h:"さいくろぷす",e:"Hitotsu-Me Giant",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["通常"],ma:"",tp:"",at:1200,de:1000,tx:"一つ目の巨人。\n太い腕で殴りかかってくる。\n要注意。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイコ・ウォールド",h:"さいこ・うぉーるど",e:"Psychic Snail",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1900,de:1200,tx:"８００ライフポイントを払って発動する。\n自分フィールド上に表側表示で存在するサイキック族モンスター１体は、１度のバトルフェイズ中に２回攻撃する事ができる。\nこの効果を発動するターンこのカードは攻撃する事ができない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイコ・エース",h:"さいこ・えーす",e:"Psychic Ace",ra:"UR",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1000,de:0,tx:"①：モンスターゾーンのこのカードがリリースされた場合、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイコ・エンペラー",h:"さいこ・えんぺらー",e:"Psychic Emperor",ra:"R",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:2400,de:1000,tx:"このカードが召喚・特殊召喚に成功した時、自分の墓地に存在するサイキック族モンスター１体につき、自分は５００ライフポイント回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイコ・カッパー",h:"さいこ・かっぱー",e:"Psychic Kappa",ra:"N",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:400,de:1000,tx:"いろいろな超能力を使い、攻撃のダメージを防ぐカッパ。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイコ・コマンダー",h:"さいこ・こまんだー",e:"Psychic Commander",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"サイキック族",mo:["チューナー","効果"],ma:"",tp:"",at:1400,de:800,tx:"自分フィールド上のサイキック族モンスターが相手モンスターと戦闘を行うダメージステップ時に１度だけ、１００の倍数のライフポイントを払って発動できる（最大５００まで）。\nこのターンのエンドフェイズ時まで、戦闘を行う相手モンスター１体の攻撃力・守備力は払った数値分ダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイコ・デビル",h:"さいこ・でびる",e:"Psychic Nightmare",ra:"SR",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"サイキック族",mo:["シンクロ","効果"],ma:"",tp:"",at:2400,de:1800,tx:"チューナー＋チューナー以外のサイキック族モンスター１体以上 \n①：１ターンに１度、自分メインフェイズに発動できる。\n相手の手札をランダムに１枚選び、そのカードの種類（モンスター・魔法・罠）を当てる。\n当たった場合、このカードの攻撃力は次の相手ターンの終了時まで１０００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイコ・ヘルストランサー",h:"さいこ・へるすとらんさー",e:"Psychic Lifetrancer",ra:"R",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"サイキック族",mo:["シンクロ","効果"],ma:"",tp:"",at:2400,de:2000,tx:"チューナー＋チューナー以外のモンスター１体以上\n１ターンに１度、自分の墓地のサイキック族モンスター１体をゲームから除外して発動できる。\n自分は１２００ライフポイント回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイコウィールダー",h:"さいこうぃーるだー",e:"Psychic Wheeleder",ra:"UR",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"サイキック族",mo:["チューナー","効果"],ma:"",tp:"",at:600,de:0,tx:"このカード名の、①の方法による特殊召喚は１ターンに１度しかできず、②の効果は１ターンに１度しか使用できない。\n①：自分フィールドに「サイコウィールダー」以外のレベル３モンスターが存在する場合、このカードは手札から守備表示で特殊召喚できる。\n②：このカードがS素材として墓地へ送られた場合、このカードをS素材としたSモンスターより低い攻撃力を持つフィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイコトラッカー",h:"さいことらっかー",e:"Psychic Tracker",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1600,de:600,tx:"このカード名の①の方法による特殊召喚は１ターンに１度しかできない。\n①：自分フィールドに「サイコトラッカー」以外のレベル３モンスターが存在する場合、このカードは手札から守備表示で特殊召喚できる。\n②：このカードをS素材としたSモンスターは、攻撃力が６００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイコロプス",h:"さいころぷす",e:"Dicelops",ra:"R",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1800,de:200,tx:"①：１ターンに１度、自分メインフェイズに発動できる。\nサイコロを１回振り、出た目の効果を適用する。\n \n●１：相手の手札を確認し、その中からカード１枚を選んで捨てる。\n\n●２～５：自分の手札を１枚選んで捨てる。\n\n●６：自分の手札を全て捨てる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"超念導体ビヒーマス",h:"さいこんだくたーびひーます",e:"HTS Psyhemuth",ra:"SR",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"サイキック族",mo:["シンクロ","効果"],ma:"",tp:"",at:2400,de:1800,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカードが相手モンスターと戦闘を行った時、そのモンスターとこのカードをゲームから除外できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"再生ミイラ",h:"さいせいみいら",e:"Regenerating Mummy",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1800,de:1500,tx:"相手のカードの効果によって、このカードが手札から墓地へ送られた時、このカードを手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイバー・ウロボロス",h:"さいばー・うろぼろす",e:"Cyber Ouroboros",ra:"N",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:100,de:600,tx:"このカードがゲームから除外された時、手札のカード１枚を墓地に送る事で、デッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイバー・エスパー",h:"さいばー・えすぱー",e:"Cyber Esper",ra:"R",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:1800,tx:"このカードが自分フィールド上に表側攻撃表示で存在する限り、相手がドローしたカードを確認する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイバー・エンジェル－伊舎那－",h:"さいばー・えんじぇる－いざな－",e:"Cyber Angel Izana",ra:"UR",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"天使族",mo:["儀式","効果"],ma:"",tp:"",at:2500,de:2600,tx:"「機械天使の儀式」により降臨。\n①：このカードが儀式召喚に成功した場合に発動できる。\n相手は自身のフィールドの魔法・罠カード１枚を墓地へ送らなければならない。\n②：このカードの攻撃で相手モンスターを破壊し墓地へ送った時に発動できる。\nこのカードはもう１度だけ続けて相手モンスターに攻撃できる。\n③：１ターンに１度、自分フィールドの「サイバー・エンジェル」儀式モンスターを対象とする相手の効果が発動した時に発動できる。\n自分の墓地の儀式モンスター１体を選んでデッキに戻し、相手フィールドのカード１枚を選んで破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイバー・エンジェル－韋駄天－",h:"さいばー・えんじぇる－いだてん－",e:"Cyber Angel Idaten",ra:"R",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"天使族",mo:["儀式","効果"],ma:"",tp:"",at:1600,de:2000,tx:"「機械天使の儀式」により降臨。\n①：このカードが儀式召喚に成功した場合に発動できる。\n自分のデッキ・墓地から儀式魔法カード１枚を選んで手札に加える。\n②：このカードがリリースされた場合に発動できる。\n自分フィールドの全ての儀式モンスターの攻撃力・守備力は１０００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイバー・エンジェル－荼吉尼－",h:"さいばー・えんじぇる－だきに－",e:"Cyber Angel Dakini",ra:"SR",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"天使族",mo:["儀式","効果"],ma:"",tp:"",at:2700,de:2400,tx:"「機械天使の儀式」により降臨。\n①：このカードが儀式召喚に成功した場合に発動できる。\n相手は自身のフィールドのモンスター１体を墓地へ送らなければならない。\n②：このカードがモンスターゾーンに存在する限り、自分の儀式モンスターが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ相手に戦闘ダメージを与える。\n③：自分エンドフェイズに自分の墓地の、儀式モンスター１体または「機械天使の儀式」１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイバー・エンジェル－弁天－",h:"さいばー・えんじぇる－べんてん－",e:"Cyber Angel Benten",ra:"R",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"天使族",mo:["儀式","効果"],ma:"",tp:"",at:1800,de:1500,tx:"「機械天使の儀式」により降臨。\n①：このカードが戦闘でモンスターを破壊し墓地へ送った場合に発動する。\nそのモンスターの元々の守備力分のダメージを相手に与える。\n②：このカードがリリースされた場合に発動できる。\nデッキから天使族・光属性モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイバー・エンド・ドラゴン",h:"さいばー・えんど・どらごん",e:"Cyber End Dragon",ra:"UR",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:4000,de:2800,tx:"「サイバー・ドラゴン」＋「サイバー・ドラゴン」＋「サイバー・ドラゴン」 \nこのカードの融合召喚は上記のカードでしか行えない。\n①：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイバー・オーガ",h:"さいばー・おーが",e:"Cyber Ogre",ra:"R",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1900,de:1200,tx:"このカードを手札から墓地に捨てる。\n自分フィールド上に存在する「サイバー・オーガ」１体が行う戦闘を１度だけ無効にし、さらに次の戦闘終了時まで攻撃力は２０００ポイントアップする。\nこの効果は相手ターンでも発動する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイバー・オーガ・2",h:"さいばー・おーが・２",e:"Cyber Ogre 2",ra:"R",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:2600,de:1900,tx:"「サイバー・オーガ」＋「サイバー・オーガ」\nこのモンスターの融合召喚は、上記のカードでしか行えない。\nこのカードが攻撃を行う時、攻撃対象モンスターの攻撃力の半分の数値だけこのカードの攻撃力をアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイバー・ジムナティクス",h:"さいばー・じむなてぃくす",e:"Cyber Gymnast",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:800,de:1800,tx:"①：１ターンに１度、手札を１枚捨て、相手フィールドの表側攻撃表示モンスター１体を対象として発動できる。\nその相手の表側攻撃表示モンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイバー・シャーク",h:"さいばー・しゃーく",e:"Cyber Shark",ra:"N",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:2100,de:2000,tx:"自分フィールド上に水属性モンスターが表側表示で存在する場合、このカードはリリースなしで召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイバー・ジラフ",h:"さいばー・じらふ",e:"Cyber Kirin",ra:"R",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:300,de:800,tx:"このカードを生け贄に捧げる。\nこのターンのエンドフェイズまで、このカードのコントローラーへの効果によるダメージは０になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイバー・ダーク・エッジ",h:"さいばー・だーく・えっじ",e:"Cyberdark Edge",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"①：このカードが召喚に成功した場合、自分の墓地のレベル３以下のドラゴン族モンスター１体を対象として発動する。\nそのドラゴン族モンスターを装備カード扱いとしてこのカードに装備する。\n②：このカードの攻撃力は、このカードの効果で装備したモンスターの攻撃力分アップする。\n③：このカードは直接攻撃できる。\nその場合、このカードの攻撃力はそのダメージ計算時のみ半分になる。\n④：このカードが戦闘で破壊される場合、代わりにこのカードの効果で装備したモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイバー・ダーク・カノン",h:"さいばー・だーく・かのん",e:"Cyberdark Cannon",ra:"SR",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1600,de:800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードを手札から捨てて発動できる。\nデッキから機械族の「サイバー・ダーク」モンスター１体を手札に加える。\n②：このカードを装備したモンスターが戦闘を行うダメージ計算時に発動できる。\nデッキからモンスター１体を墓地へ送る。\n③：モンスターに装備されているこのカードが墓地へ送られた場合に発動できる。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイバー・ダーク・キール",h:"さいばー・だーく・きーる",e:"Cyberdark Keel",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"①：このカードが召喚に成功した場合、自分の墓地のレベル３以下のドラゴン族モンスター１体を対象として発動する。\nそのドラゴン族モンスターを装備カード扱いとしてこのカードに装備する。\n②：このカードの攻撃力は、このカードの効果で装備したモンスターの攻撃力分アップする。\n③：このカードが戦闘で相手モンスターを破壊した場合に発動する。\n相手に３００ダメージを与える。\n④：このカードが戦闘で破壊される場合、代わりにこのカードの効果で装備したモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイバー・ダーク・クロー",h:"さいばー・だーく・くろー",e:"Cyberdark Claw",ra:"SR",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1600,de:800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードを手札から捨てて発動できる。\nデッキから「サイバーダーク」魔法・罠カード１枚を手札に加える。\n②：このカードを装備したモンスターが戦闘を行うダメージ計算時に発動できる。\n自分のEXデッキからモンスター１体を墓地へ送る。\n③：モンスターに装備されているこのカードが墓地へ送られた場合、自分の墓地の「サイバー・ダーク」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイバー・ダーク・ホーン",h:"さいばー・だーく・ほーん",e:"Cyberdark Horn",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"①：このカードが召喚に成功した場合、自分の墓地のレベル３以下のドラゴン族モンスター１体を対象として発動する。\nそのドラゴン族モンスターを装備カード扱いとしてこのカードに装備する。\n②：このカードの攻撃力は、このカードの効果で装備したモンスターの攻撃力分アップする。\n③：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。\n④：このカードが戦闘で破壊される場合、代わりにこのカードの効果で装備したモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイバー・ダイナソー",h:"さいばー・だいなそー",e:"Cyber Dinosaur",ra:"R",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2500,de:1900,tx:"相手が手札からモンスターを特殊召喚した時、このカードを手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイバー・チュチュ",h:"さいばー・ちゅちゅ",e:"Cyber Tutu",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:800,tx:"相手フィールド上に存在する全てのモンスターの攻撃力がこのカードの攻撃力よりも高い場合、このカードは相手プレイヤーに直接攻撃する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイバー・チュチュボン",h:"さいばー・ちゅちゅぼん",e:"Cyber Tutubon",ra:"UR",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1600,tx:"①：このカードは自分の手札・フィールドから戦士族または天使族モンスター１体をリリースして手札から特殊召喚できる。\n②：このカードが儀式召喚のためにリリースされた場合、自分の墓地の儀式魔法カード１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイバー・ツイン・ドラゴン",h:"さいばー・ついん・どらごん",e:"Cyber Twin Dragon",ra:"UR",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:2800,de:2100,tx:"「サイバー・ドラゴン」＋「サイバー・ドラゴン」\nこのカードの融合召喚は上記のカードでしか行えない。\n①：このカードは１度のバトルフェイズ中に２回攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイバー・ドラゴン",h:"さいばー・どらごん",e:"Cyber Dragon",ra:"UR",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2100,de:1600,tx:"①：相手フィールドにのみモンスターが存在する場合、このカードは手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイバー・ドラゴン・コア",h:"さいばー・どらごん・こあ",e:"Cyber Dragon Core",ra:"SR",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:400,de:1500,tx:"このカード名の②③の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードのカード名は、フィールド・墓地に存在する限り「サイバー・ドラゴン」として扱う。\n②：このカードが召喚に成功した場合に発動する。\nデッキから「サイバー」魔法・罠カードまたは「サイバネティック」魔法・罠カード１枚を手札に加える。\n③：相手フィールドにのみモンスターが存在する場合、墓地のこのカードを除外して発動できる。\nデッキから「サイバー・ドラゴン」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイバー・ドラゴン・ツヴァイ",h:"さいばー・どらごん・つう゛ぁい",e:"Cyber Dragon Zwei",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"①：このカードのカード名は、墓地に存在する限り「サイバー・ドラゴン」として扱う。\n②：１ターンに１度、手札の魔法カード１枚を相手に見せて発動できる。\nこのカードのカード名はエンドフェイズまで「サイバー・ドラゴン」として扱う。\n③：このカードが相手モンスターに攻撃するダメージステップの間、このカードの攻撃力は３００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイバー・ドラゴン・ドライ",h:"さいばー・どらごん・どらい",e:"Cyber Dragon Drei",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1800,de:800,tx:"①：このカードのカード名は、フィールド・墓地に存在する限り「サイバー・ドラゴン」として扱う。\n②：このカードが召喚に成功した時に発動できる。\n自分フィールドの全ての「サイバー・ドラゴン」のレベルを５にする。\nこの効果を発動するターン、自分は機械族モンスターしか特殊召喚できない。\n③：このカードが除外された場合、自分フィールドの「サイバー・ドラゴン」１体を対象として発動できる。\nこのターン、そのモンスターは戦闘・効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイバー・ドラゴン・ネクステア",h:"さいばー・どらごん・ねくすてあ",e:"Cyber Dragon Nachster",ra:"SR",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:200,de:200,tx:"このカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードのカード名は、フィールド・墓地に存在する限り「サイバー・ドラゴン」として扱う。\n②：手札からこのカード以外のモンスター１体を捨てて発動できる。\nこのカードを手札から特殊召喚する。\n③：このカードが召喚・特殊召喚に成功した場合、自分の墓地の、攻撃力か守備力が２１００の機械族モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は機械族モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイバー・ドラゴン・ノヴァ",h:"さいばー・どらごん・のう゛ぁ",e:"Cyber Dragon Nova",ra:"UR",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2100,de:1600,tx:"機械族レベル５モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除き、自分の墓地の「サイバー・ドラゴン」１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n②：１ターンに１度、手札及び自分フィールドの表側表示モンスターの中から、「サイバー・ドラゴン」１体を除外して発動できる。\nこのカードの攻撃力はターン終了時まで２１００アップする。\nこの効果は相手ターンでも発動できる。\n③：このカードが相手の効果で墓地へ送られた場合に発動できる。\nEXデッキから機械族の融合モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイバー・ドラゴン・フィーア",h:"さいばー・どらごん・ふぃーあ",e:"Cyber Dragon Vier",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1100,de:1600,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードのカード名は、フィールド・墓地に存在する限り「サイバー・ドラゴン」として扱う。\n②：自分が「サイバー・ドラゴン」の召喚・特殊召喚に成功した場合に発動できる。\nこのカードを手札から守備表示で特殊召喚する。\n③：このカードがモンスターゾーンに存在する限り、自分フィールドの全ての「サイバー・ドラゴン」の攻撃力・守備力は５００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイバー・バリア・ドラゴン",h:"さいばー・ばりあ・どらごん",e:"Cyber Barrier Dragon",ra:"R",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"機械族",mo:["特殊召喚","効果"],ma:"",tp:"",at:800,de:2800,tx:"このカードは通常召喚できない。\nこのカードは「アタック・リフレクター・ユニット」の効果でのみ特殊召喚する事ができる。\nこのカードが攻撃表示の場合、１ターンに１度だけ相手モンスター１体の攻撃を無効にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイバー・ファロス",h:"さいばー・ふぁろす",e:"Cyber Pharos",ra:"SR",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:2100,tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードは自分フィールドの機械族モンスター１体をリリースして手札から特殊召喚できる。\n②：１ターンに１度、自分メインフェイズに発動できる。\n自分の手札・フィールドから、機械族の融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。\n③：自分の融合モンスターが戦闘で破壊された時、墓地のこのカードを除外して発動できる。\nデッキから「パワー・ボンド」１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイバー・フェニックス",h:"さいばー・ふぇにっくす",e:"Cyber Phoenix",ra:"R",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:1600,tx:"①：このカードがモンスターゾーンに攻撃表示で存在する限り、自分フィールドの機械族モンスター１体のみを対象とする魔法・罠カードの効果は無効化される。\n②：このカードが戦闘で破壊され墓地へ送られた時に発動できる。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイバー・プチ・エンジェル",h:"さいばー・ぷち・えんじぇる",e:"Cyber Petit Angel",ra:"R",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:300,de:200,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・反転召喚・特殊召喚に成功した場合に発動できる。\nデッキから「サイバー・エンジェル」モンスター１体または「機械天使の儀式」１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイバー・プリマ",h:"さいばー・ぷりま",e:"Cyber Prima",ra:"SR",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2300,de:1600,tx:"①：このカードがアドバンス召喚に成功した場合に発動する。\nフィールドの表側表示の魔法カードを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイバー・ブレイダー",h:"さいばー・ぶれいだー",e:"Cyber Blader",ra:"UR",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2100,de:800,tx:"「エトワール・サイバー」＋「ブレード・スケーター」\nこのカードの融合召喚は上記のカードでしか行えない。\n①：相手フィールドのモンスターの数によって、このカードは以下の効果を得る。\n●１体：このカードは戦闘では破壊されない。\n●２体：このカードの攻撃力は倍になる。\n●３体：相手が発動したカードの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイバー・レイダー",h:"さいばー・れいだー",e:"Cyber Raider",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1400,de:1000,tx:"このカードが召喚・反転召喚・特殊召喚に成功した時、次の効果から１つを選択して発動する。\n\n●フィールド上のモンスター１体に装備されている装備カードを１枚選択し破壊する。\n\n●フィールド上のモンスター１体に装備されている装備カードを１枚選択しこのカードに装備する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイバー・レーザー・ドラゴン",h:"さいばー・れーざー・どらごん",e:"Cyber Laser Dragon",ra:"SR",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"機械族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2400,de:1800,tx:"このカードは通常召喚できない。\nこのカードは「フォトン・ジェネレーター・ユニット」の効果でのみ特殊召喚する事ができる。\nこのカードの攻撃力以上の攻撃力か守備力を持つモンスター１体を破壊する事ができる。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイバース・コンバーター",h:"さいばーす・こんばーたー",e:"Cyberse Converter",ra:"N",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカード名の①の方法による特殊召喚は１ターンに１度しかできない。\n①：自分フィールドのモンスターがサイバース族モンスターのみの場合、このカードは手札から特殊召喚できる。\n②：このカードが召喚に成功した時、自分フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの種族はターン終了時までサイバース族になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイバーデーモン",h:"さいばーでーもん",e:"Cyber Archfiend",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1000,de:2000,tx:"自分のドローフェイズ開始時に自分の手札が０枚だった場合、通常のドローに加えてもう１枚ドローする。\n自分のエンドフェイズ時に自分の手札が１枚以上存在していた場合、このカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイバティック・ワイバーン",h:"さいばてぃっく・わいばーん",e:"Cyber-Tech Alligator",ra:"N",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:2500,de:1600,tx:"メカで強化された翼竜。\nドラゴンにやられ死にかけた所を、飼い主にサイボーグ化された。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイバネティック・サイクロプス",h:"さいばねてぃっく・さいくろぷす",e:"Cybernetic Cyclopean",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1400,de:200,tx:"自分の手札が０枚である限り、このカードの攻撃力は１０００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイバネティック・マジシャン",h:"さいばねてぃっく・まじしゃん",e:"Cybernetic Magician",ra:"R",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2400,de:1000,tx:"手札を１枚捨てる。\nこのターンのエンドフェイズ時まで、フィールド上に表側表示で存在するモンスター１体の攻撃力は２０００になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイファー・スカウター",h:"さいふぁー・すかうたー",e:"Cipher Soldier",ra:"UR",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1350,de:1800,tx:"このカードが戦士族モンスターと戦闘を行うダメージ計算時に発動する。\nこのカードの攻撃力・守備力は、そのダメージ計算時のみ２０００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"PSYフレーム・ドライバー",h:"さいふれーむ・どらいばー",e:"PSY-Frame Driver",ra:"N",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"サイキック族",mo:["通常"],ma:"",tp:"",at:2500,de:0,tx:"電撃を操るサイキック戦士。\n自律型増幅器「PSYフレーム」を駆り、セキュリティ・フォースに強襲を仕掛ける。\nその姿は、正に電光石火の如し。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"PSYフレーム・マルチスレッダー",h:"さいふれーむ・まるちすれっだー",e:"PSY-Frame Multi-Threader",ra:"N",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:0,de:2500,tx:"「PSYフレーム・マルチスレッダー」の③の効果は１ターンに１度しか使用できない。\n①：このカードは手札・墓地に存在する限り、「PSYフレーム・ドライバー」として扱う。\n②：自分フィールドの「PSYフレーム」カードが戦闘・効果で破壊される場合、代わりに手札のこのカードを捨てる事ができる。\n③：このカードが墓地に存在し、自分フィールドに「PSYフレーム」チューナーが特殊召喚された場合に発動できる。\nこのカードを墓地から特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"PSYフレームギア・α",h:"さいふれーむぎあ・あるふぁ",e:"PSY-Framegear Alpha",ra:"R",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"サイキック族",mo:["特殊召喚","チューナー","効果"],ma:"",tp:"",at:500,de:0,tx:"このカードは通常召喚できず、カードの効果でのみ特殊召喚できる。\n①：自分フィールドにモンスターが存在せず、相手がモンスターを召喚・特殊召喚した時に発動できる。\n手札のこのカードと自分の手札・デッキ・墓地の「PSYフレーム・ドライバー」１体を選んで特殊召喚し、デッキから「PSYフレームギア・α」以外の「PSYフレーム」カード１枚を手札に加える。\nこの効果で特殊召喚したモンスターは全てエンドフェイズに除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"PSYフレームギア・ε",h:"さいふれーむぎあ・いぷしろん",e:"PSY-Framegear Epsilon",ra:"N",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"サイキック族",mo:["特殊召喚","チューナー","効果"],ma:"",tp:"",at:1500,de:0,tx:"このカードは通常召喚できず、カードの効果でのみ特殊召喚できる。\n①：自分フィールドにモンスターが存在せず、相手の罠カードが発動した時に発動できる。\n手札のこのカードと自分の手札・デッキ・墓地の「PSYフレーム・ドライバー」１体を選んで特殊召喚し、その発動を無効にし破壊する。\nこの効果で特殊召喚したモンスターは全てエンドフェイズに除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"PSYフレームロード・Ζ",h:"さいふれーむろーど・ぜーた",e:"PSY-Framelord Zeta",ra:"R",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"サイキック族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:1800,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：１ターンに１度、相手フィールドの特殊召喚された表側攻撃表示モンスター１体を対象として発動できる。\nそのモンスターとフィールドのこのカードを次の自分スタンバイフェイズまで除外する。\nこの効果は相手ターンでも発動できる。\n②：このカードが墓地に存在する場合、このカード以外の自分の墓地の「PSYフレーム」カード１枚を対象として発動できる。\nこのカードをエクストラデッキに戻し、対象のカードを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"彩宝龍",h:"さいほうりゅう",e:"Lappis Dragon",ra:"N",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"海竜族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:2600,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードがデッキから手札に加わった場合、このカードを相手に見せて発動できる。\nこのカードを特殊召喚する。\n②：このカードが効果で自分の墓地から手札に加わった場合、このカードを相手に見せて発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイボーグ・バス",h:"さいぼーぐ・ばす",e:"Mech Bass",ra:"N",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1800,de:1500,tx:"背中に付いている砲台から、閃光のプラズマキャノンを打ち出す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイボーグドクター",h:"さいぼーぐどくたー",e:"Cyborg Doctor",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1500,de:1700,tx:"自分フィールド上に存在するチューナー１体をリリースして発動する。\nリリースしたモンスターと同じ属性・レベルのモンスター１体を自分の墓地から特殊召喚する。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイレント・アングラー",h:"さいれんと・あんぐらー",e:"Silent Angler",ra:"SR",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:800,de:1400,tx:"①：自分フィールドに水属性モンスターが存在する場合、このカードは手札から特殊召喚できる。\nこの方法で特殊召喚したターン、自分は手札からモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"静寂虫",h:"さいれんと・いんせくと",e:"Silent Insect",ra:"R",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:200,de:300,tx:"このカードは召喚・反転召喚に成功した場合守備表示になる。\nこのカードがフィールド上に表側表示で存在する限り、永続魔法・永続罠カードの効果を無効にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイレント・ソードマン Lv3",h:"さいれんと・そーどまん　れべる３",e:"Silent Swordsman LV3",ra:"N",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"①：このカードがモンスターゾーンに存在する限り、このカードを対象とする相手の魔法カードの効果は無効化される。\n②：自分スタンバイフェイズにフィールドのこのカードを墓地へ送って発動できる。\n手札・デッキから「サイレント・ソードマン LV５」１体を特殊召喚する。\nこの効果はこのカードが召喚・特殊召喚・リバースしたターンには発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイレント・ソードマン Lv5",h:"さいれんと・そーどまん　れべる５",e:"Silent Swordsman LV5",ra:"SR",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2300,de:1000,tx:"①：このカードは相手の魔法カードの効果を受けない。\n②：このカードが直接攻撃で相手に戦闘ダメージを与えた場合、次の自分ターンのスタンバイフェイズにフィールドのこのカードを墓地へ送って発動できる。\n手札・デッキから「サイレント・ソードマン LV７」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイレント・ソードマン Lv7",h:"さいれんと・そーどまん　れべる７",e:"Silent Swordsman LV7",ra:"UR",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2800,de:1000,tx:"このカードは通常召喚できない。\n「サイレント・ソードマン LV５」の効果でのみ特殊召喚できる。\n①：このカードがモンスターゾーンに存在する限り、フィールドの魔法カードの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイレント・マジシャン Lv4",h:"さいれんと・まじしゃん　れべる４",e:"Silent Magician LV4",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"①：このカードがモンスターゾーンに存在する限り、相手がカードをドローする度に、このカードに魔力カウンターを１つ置く（最大５つまで）。\n②：このカードの攻撃力は、このカードの魔力カウンターの数×５００アップする。\n③：このカードに５つ目の魔力カウンターが置かれた次の自分ターンのスタンバイフェイズに、魔力カウンターが５つ置かれているこのカードを墓地へ送って発動できる。\n手札・デッキから「サイレント・マジシャン LV８」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイレント・マジシャン Lv8",h:"さいれんと・まじしゃん　れべる８",e:"Silent Magician LV8",ra:"UR",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"魔法使い族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3500,de:1000,tx:"このカードは通常召喚できない。\n「サイレント・マジシャン LV４」の効果でのみ特殊召喚できる。\n①：このカードは相手の魔法カードの効果を受けない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイレントアビス",h:"さいれんとあびす",e:"Silent Abyss",ra:"N",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:2000,de:1500,tx:"このカードが戦闘によって破壊され墓地へ送られた時、フィールド上に表側表示で存在する水属性モンスター以外のモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サウザンド・アイズ・フィッシュ",h:"さうざんど・あいず・ふぃっしゅ",e:"Thousand-Eyes Jellyfish",ra:"N",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"魚族",mo:["特殊召喚","効果"],ma:"",tp:"",at:300,de:2100,tx:"このカードは通常召喚できない。\n自分フィールド上に存在する「オーシャンズ・オーパー」１体をリリースした場合のみ特殊召喚する事ができる。\nこのカードが自分フィールド上に表側表示で存在する限り、相手は手札を公開し続けなければならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"千年竜",h:"さうざんど・どらごん",e:"Thousand Dragon",ra:"R",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["融合"],ma:"",tp:"",at:2400,de:2000,tx:"「時の魔術師」＋「ベビードラゴン」",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"針千本",h:"さうざんど・にーどる",e:"Thousand Needles",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1000,de:1800,tx:"守備表示のこのカードが攻撃を受けた時、このカードの守備力が相手攻撃モンスターの攻撃力を越えていれば、ダメージステップ終了時にその攻撃モンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"音響戦士サイザス",h:"さうんどうぉりあーさいざす",e:"Symphonic Warrior Synthess",ra:"R",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"機械族",mo:["リバース","効果"],ma:"",tp:"",at:1200,de:1900,tx:"①：このカードがリバースした場合に発動できる。\nデッキから「音響戦士サイザス」以外の「音響戦士」モンスター１体を手札に加える。\n②：１ターンに１度、「音響戦士サイザス」以外の自分のフィールド・墓地の「音響戦士」モンスター１体を対象として発動できる。\nこのカードはエンドフェイズまで、そのモンスターと同名カードとして扱い、同じ効果を得る。\n③：墓地のこのカードを除外し、「音響戦士サイザス」以外の除外されている自分の「音響戦士」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"音響戦士ドラムス",h:"さうんどうぉりあーどらむす",e:"Symphonic Warrior Drumss",ra:"N",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:700,de:700,tx:"属性を１つ宣言し、フィールド上に表側表示で存在する「音響戦士」と名のついたモンスター１体を選択して発動する。\n選択したモンスターは宣言した属性になる。\nこの効果は１ターンに１度しか使用できない。\nまた、属性を１つ宣言し、自分の墓地に存在するこのカードをゲームから除外する事で、自分フィールド上に表側表示で存在する「音響戦士」と名のついたモンスター１体は宣言した属性になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"音響戦士ピアーノ",h:"さうんどうぉりあーぴあーの",e:"Symphonic Warrior Piaano",ra:"N",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:900,de:1300,tx:"種族を１つ宣言し、フィールド上に表側表示で存在する「音響戦士」と名のついたモンスター１体を選択して発動する。\n選択したモンスターは宣言した種族になる。\nこの効果は１ターンに１度しか使用できない。\nまた、種族を１つ宣言し、自分の墓地に存在するこのカードをゲームから除外する事で、自分フィールド上に表側表示で存在する「音響戦士」と名のついたモンスター１体は宣言した種族になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"音響戦士ベーシス",h:"さうんどうぉりあーべーしす",e:"Symphonic Warrior Basses",ra:"N",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:600,de:400,tx:"①：１ターンに１度、フィールドの「音響戦士」モンスター１体を対象として発動できる。\nターン終了時まで、そのモンスターのレベルを手札の枚数分上げる。\n②：墓地のこのカードを除外し、自分フィールドの「音響戦士」モンスター１体を対象として発動できる。\nターン終了時まで、その自分の「音響戦士」モンスターのレベルを手札の枚数分上げる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"逆巻くエリア",h:"さかまくえりあ",e:"Raging Eria",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:800,de:1500,tx:"このカードを除く自分フィールド上の水属性モンスター１体を生け贄に捧げる事で、手札から水属性モンスター１体を特殊召喚する。\nこの効果は１ターンに１度しか使用できない。\nこの効果によって特殊召喚されたモンスターは、「逆巻くエリア」が自分フィールド上から離れた場合破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"逆巻く炎の精霊",h:"さかまくほのおのせいれい",e:"Raging Flame Sprite",ra:"R",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:100,de:200,tx:"このカードは相手プレイヤーに直接攻撃できる。\nこのカードが直接攻撃によって相手ライフに戦闘ダメージを与えた時、このカードの攻撃力は１０００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サキュバス・ナイト",h:"さきゅばす・ないと",e:"Succubus Knight",ra:"N",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1650,de:1300,tx:"魔法を唱え、相手を血祭りにあげる悪魔の魔法戦士。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サクリファイス",h:"さくりふぁいす",e:"Relinquished",ra:"UR",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["儀式","効果"],ma:"",tp:"",at:0,de:0,tx:"「イリュージョンの儀式」により降臨。\n①：１ターンに１度、相手フィールドのモンスター１体を対象として発動できる。\nその相手モンスターを装備カード扱いとしてこのカードに装備する（１体のみ装備可能）。\n②：このカードの攻撃力・守備力は、このカードの効果で装備したモンスターのそれぞれの数値になり、このカードが戦闘で破壊される場合、代わりに装備したそのモンスターを破壊する。\n③：このカードの効果でモンスターを装備したこのカードの戦闘で自分が戦闘ダメージを受けた時、相手も同じ数値分の効果ダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サクリファイス・スパイダー",h:"さくりふぁいす・すぱいだー",e:"Relinquished Spider",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:300,de:500,tx:"自分の墓地に昆虫族モンスターが４体以上存在する場合、このカードをリリースして発動する。\n相手フィールド上に表側守備表示で存在するモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サクリファイス・ロータス",h:"さくりふぁいす・ろーたす",e:"Samsara Lotus",ra:"SR",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"自分のエンドフェイズ時にこのカードが墓地に存在し、自分フィールド上に魔法・罠カードが存在しない場合、自分フィールド上に表側攻撃表示で特殊召喚する事ができる。\nこのカードがフィールド上に表側表示で存在する場合、自分のスタンバイフェイズ毎にこのカードのコントローラーは１０００ポイントダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サクリボー",h:"さくりぼー",e:"Relinkuriboh",ra:"SR",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:300,de:200,tx:"①：このカードがリリースされた場合に発動する。\n自分はデッキから１枚ドローする。\n②：自分のモンスターが戦闘で破壊される場合、代わりに墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サシカエル",h:"さしかえる",e:"Tradetoad",ra:"N",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:100,de:2000,tx:"自分フィールド上に存在する水族モンスター１体をリリースし、自分の墓地に存在する「ガエル」と名のついたモンスター１体を選択して発動する。\n選択したモンスターを墓地から特殊召喚する。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"砂塵の悪霊",h:"さじんのあくりょう",e:"Dark Dust Spirit",ra:"UR",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"アンデット族",mo:["スピリット","効果"],ma:"",tp:"",at:2200,de:1800,tx:"このカードは特殊召喚できない。\n召喚・リバースしたターンのエンドフェイズ時に持ち主の手札に戻る。\nこのカードが召喚・リバースした時、このカード以外のフィールド上に表側表示で存在するモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"砂塵の騎士",h:"さじんのきし",e:"Dust Knight",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["リバース","効果"],ma:"",tp:"",at:1400,de:1200,tx:"リバース：デッキから地属性モンスター１体を墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サターナ",h:"さたーな",e:"Phontom Dewan",ra:"N",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:700,de:600,tx:"敵を呪い、動きを止めることができる魔法使い。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サタンクロース",h:"さたんくろーす",e:"Santa Claws",ra:"SR",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1200,de:2500,tx:"①：このカードは相手フィールドのモンスター１体をリリースし、手札から相手フィールドに守備表示で特殊召喚できる。\n②：このカードがこのカードの①の方法で特殊召喚に成功したターンのエンドフェイズに発動できる。\nこの効果を発動したプレイヤーはデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"殺炎星－ブルキ",h:"さつえんせい－ぶるき",e:"Brotherhood of the Fire Fist - Buffalo",ra:"R",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"このカードが墓地に存在する場合、手札または自分フィールド上に表側表示で存在する、「炎星」または「炎舞」と名のついたカードを合計２枚墓地へ送って発動できる。\nこのカードを墓地から特殊召喚する。\n「殺炎星－ブルキ」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"電磁ミノ虫",h:"ざっくとれーがー・まぐね",e:"Electromagnetic Bagworm",ra:"SR",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"昆虫族",mo:["リバース","効果"],ma:"",tp:"",at:200,de:1400,tx:"①：このカードがリバースした場合、相手フィールドの機械族モンスター１体を対象として発動する。\n次の自分エンドフェイズまで、その機械族モンスターのコントロールを得る。\n②：このカードがモンスターとの戦闘で破壊された場合に発動する。\nそのモンスターの攻撃力・守備力を５００ダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サテライト・キャノン",h:"さてらいと・きゃのん",e:"Satellite Cannon",ra:"SR",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカードはレベル７以下のモンスターとの戦闘では破壊されない。\nまた、自分のエンドフェイズ毎にこのカードの攻撃力は１０００ポイントアップする。\nこの効果でアップした攻撃力は、このカードが攻撃したダメージ計算後に０に戻る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"星因士 アルタイル",h:"さてらないと　あるたいる",e:"Satellarknight Altair",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:1300,tx:"「星因士 アルタイル」の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・反転召喚・特殊召喚に成功した場合、「星因士 アルタイル」以外の自分の墓地の「テラナイト」モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\nこの効果の発動後、ターン終了時まで「テラナイト」モンスター以外の自分フィールドのモンスターは攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"星因士 ウヌク",h:"さてらないと　うぬく",e:"Satellarknight Unukalhai",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"「星因士 ウヌク」の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・反転召喚・特殊召喚に成功した場合に発動できる。\nデッキから「星因士 ウヌク」以外の「テラナイト」カード１枚を墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"星因士 シャム",h:"さてらないと　しゃむ",e:"Satellarknight Alsahm",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1400,de:1800,tx:"「星因士 シャム」の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・反転召喚・特殊召喚に成功した場合に発動できる。\n相手に１０００ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"星因士 シリウス",h:"さてらないと　しりうす",e:"Satellarknight Sirius",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:900,tx:"「星因士 シリウス」の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・反転召喚・特殊召喚に成功した場合、自分の墓地の「テラナイト」モンスター５体を対象として発動できる。\nそのモンスター５体をデッキに戻してシャッフルする。\nその後、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"星因士 デネブ",h:"さてらないと　でねぶ",e:"Satellarknight Deneb",ra:"UR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"「星因士 デネブ」の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・反転召喚・特殊召喚に成功した場合に発動できる。\nデッキから「星因士 デネブ」以外の「テラナイト」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"星因士 プロキオン",h:"さてらないと　ぷろきおん",e:"Satellarknight Procyon",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1300,de:1200,tx:"「星因士 プロキオン」の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・反転召喚・特殊召喚に成功した場合に発動できる。\n手札の「テラナイト」モンスター１体を墓地へ送り、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"星因士 ベガ",h:"さてらないと　べが",e:"Satellarknight Vega",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:1600,tx:"「星因士 ベガ」の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・反転召喚・特殊召喚に成功した場合に発動できる。\n手札から「星因士 ベガ」以外の「テラナイト」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"星因士 ベテルギウス",h:"さてらないと　べてるぎうす",e:"Satellarknight Betelgeuse",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:700,de:1900,tx:"「星因士 ベテルギウス」の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・反転召喚・特殊召喚に成功した場合、「星因士 ベテルギウス」以外の自分の墓地の「テラナイト」カード１枚を対象として発動できる。\nこのカードを墓地へ送り、対象のカードを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"星因士 リゲル",h:"さてらないと　りげる",e:"Satellarknight Rigel",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1900,de:700,tx:"「星因士 リゲル」の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・反転召喚・特殊召喚に成功した場合、フィールドの「テラナイト」モンスター１体を対象として発動できる。\nそのモンスターは、攻撃力が５００アップし、エンドフェイズに墓地へ送られる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サニー・ピクシー",h:"さにー・ぴくしー",e:"Sunny Pixie",ra:"N",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["チューナー","効果"],ma:"",tp:"",at:300,de:400,tx:"このカードが光属性のシンクロモンスターのシンクロ召喚に使用され墓地へ送られた場合、自分は１０００ライフポイント回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"裁きの鷹",h:"さばきのたか",e:"Punished Eagle",ra:"N",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"鳥獣族",mo:["融合"],ma:"",tp:"",at:2100,de:1800,tx:"冠を戴く蒼き翼＋コケ",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"裁きを下す女帝",h:"さばきをくだすじょてい",e:"Empress Judge",ra:"R",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["融合"],ma:"",tp:"",at:2100,de:1700,tx:"女王の影武者＋響女",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"裁きを下す者－ボルテニス",h:"さばきをくだすもの－ぼるてにす",e:"Voltanis the Adjudicator",ra:"UR",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2800,de:1400,tx:"自分のカウンター罠が発動に成功した場合、自分フィールド上のモンスターを全て生け贄に捧げる事で特殊召喚する事ができる。\nこの方法で特殊召喚に成功した場合、生け贄に捧げた天使族モンスターの数まで相手フィールド上のカードを破壊する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"砂バク",h:"さばく",e:"Desertapir",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["リバース","効果"],ma:"",tp:"",at:900,de:300,tx:"①：このカードがリバースした場合、「砂バク」以外のフィールドの表側表示モンスター１体を対象として発動する。\nそのモンスターを裏側守備表示にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サファイアドラゴン",h:"さふぁいあどらごん",e:"Luster Dragon",ra:"R",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:1900,de:1600,tx:"全身がサファイアに覆われた、非常に美しい姿をしたドラゴン。\n争いは好まないが、とても高い攻撃力を備えている。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サファイヤ・リサーク",h:"さふぁいや・りさーく",e:"Lisark",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:1300,de:1300,tx:"サファイヤの眼を持つけもの。\n幻影を見せ、混乱したところを攻撃。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サブテラーの戦士",h:"さぶてらーのせんし",e:"Subterror Nemesis Warrior",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1200,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：デッキから「サブテラー」モンスター１体を墓地へ送って発動できる。\n元々のレベルの合計がその「サブテラー」モンスターのレベル以上となるように、このカードと自分フィールドのモンスター１体以上をリリースし、その「サブテラー」モンスターを表側守備表示または裏側守備表示で墓地から特殊召喚する。\nこの効果は相手ターンでも発動できる。\n②：自分フィールドの「サブテラーマリス」モンスターがリバースした場合に発動できる（ダメージステップでも発動可能）。\n墓地のこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サブテラーの刀匠",h:"さぶてらーのとうしょう",e:"Subterror Nemesis Defender",ra:"R",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:100,de:2000,tx:"①：自分フィールドの裏側表示モンスター１体のみが相手の効果の対象になった時、または相手モンスターの攻撃対象に選択された時、手札・フィールドのこのカードを墓地へ送り、その裏側表示モンスター以外の自分フィールドのモンスター１体を対象として発動できる。\nその対象を正しい対象となるそのモンスターに移し替える。\n②：自分フィールドに裏側表示モンスターが存在する限り、このカードは戦闘・効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サブテラーマリス・アクエドリア",h:"さぶてらーまりす・あくえどりあ",e:"Subterror Behemoth Stygokraken",ra:"R",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"海竜族",mo:["リバース","効果"],ma:"",tp:"",at:1400,de:2600,tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：自分フィールドの表側表示モンスターが裏側表示になった時、自分フィールドに表側表示モンスターが存在しない場合に発動できる。\nこのカードを手札から守備表示で特殊召喚する。\n②：自分メインフェイズに発動できる。\nこのカードを裏側守備表示にする（１ターンに１度のみ）。\n③：このカードがリバースした場合、自分フィールドの「サブテラーマリス」モンスターの数だけ、フィールドにセットされたカードを対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サブテラーマリス・アルラボーン",h:"さぶてらーまりす・あるらぼーん",e:"Subterror Behemoth Dragossuary",ra:"N",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"アンデット族",mo:["リバース","効果"],ma:"",tp:"",at:2400,de:1600,tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：自分フィールドの表側表示モンスターが裏側表示になった時、自分フィールドに表側表示モンスターが存在しない場合に発動できる。\nこのカードを手札から守備表示で特殊召喚する。\n②：自分メインフェイズに発動できる。\nこのカードを裏側守備表示にする（１ターンに１度のみ）。\n③：このカードがリバースした場合に発動できる。\nこのターン、自分フィールドの「サブテラー」カードは相手の効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サブテラーマリス・エルガウスト",h:"さぶてらーまりす・えるがうすと",e:"Subterror Behemoth Speleogeist",ra:"N",t:"monster",a:"地",l:11,ps:"",pe:"",tr:"悪魔族",mo:["リバース","効果"],ma:"",tp:"",at:3000,de:1400,tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：自分フィールドの表側表示モンスターが裏側表示になった時、自分フィールドに表側表示モンスターが存在しない場合に発動できる。\nこのカードを手札から守備表示で特殊召喚する。\n②：自分メインフェイズに発動できる。\nこのカードを裏側守備表示にする（１ターンに１度のみ）。\n③：このカードがリバースした場合、フィールドのモンスター１体を対象として発動できる。\nそのモンスターが守備表示の場合、表側攻撃表示にする。\nそのモンスターの攻撃力は０になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サブテラーマリス・グライオース",h:"さぶてらーまりす・ぐらいおーす",e:"Subterror Behemoth Phospheroglacier",ra:"N",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"水族",mo:["リバース","効果"],ma:"",tp:"",at:2600,de:2500,tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：自分フィールドの表側表示モンスターが裏側表示になった時、自分フィールドに表側表示モンスターが存在しない場合に発動できる。\nこのカードを手札から守備表示で特殊召喚する。\n②：自分メインフェイズに発動できる。\nこのカードを裏側守備表示にする（１ターンに１度のみ）。\n③：このカードがリバースした場合に発動できる。\nデッキからカード１枚を選んで墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サブテラーマリス・ジブラタール",h:"さぶてらーまりす・じぶらたーる",e:"Subterror Behemoth Stalagmo",ra:"N",t:"monster",a:"地",l:10,ps:"",pe:"",tr:"岩石族",mo:["リバース","効果"],ma:"",tp:"",at:2800,de:2100,tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：自分フィールドの表側表示モンスターが裏側表示になった時、自分フィールドに表側表示モンスターが存在しない場合に発動できる。\nこのカードを手札から守備表示で特殊召喚する。\n②：自分メインフェイズに発動できる。\nこのカードを裏側守備表示にする（１ターンに１度のみ）。\n③：このカードがリバースした場合に発動できる。\n手札から「サブテラー」モンスター１体を捨て、自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サブテラーマリス・バレスアッシュ",h:"さぶてらーまりす・ばれすあっしゅ",e:"Subterror Behemoth Ultramafus",ra:"R",t:"monster",a:"地",l:12,ps:"",pe:"",tr:"炎族",mo:["リバース","効果"],ma:"",tp:"",at:3000,de:1800,tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：自分フィールドの表側表示モンスターが裏側表示になった時、自分フィールドに表側表示モンスターが存在しない場合に発動できる。\nこのカードを手札から守備表示で特殊召喚する。\n②：自分メインフェイズに発動できる。\nこのカードを裏側守備表示にする（１ターンに１度のみ）。\n③：このカードがリバースした場合に発動できる。\nこのカード以外のフィールドの表側表示モンスターを全て裏側守備表示にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サブテラーマリス・リグリアード",h:"さぶてらーまりす・りぐりあーど",e:"Subterror Behemoth Umastryx",ra:"SR",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"爬虫類族",mo:["リバース","効果"],ma:"",tp:"",at:2000,de:2700,tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：自分フィールドの表側表示モンスターが裏側表示になった時、自分フィールドに表側表示モンスターが存在しない場合に発動できる。\nこのカードを手札から守備表示で特殊召喚する。\n②：自分メインフェイズに発動できる。\nこのカードを裏側守備表示にする（１ターンに１度のみ）。\n③：このカードがリバースした場合、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターを除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サブマリンロイド",h:"さぶまりんろいど",e:"Submarineroid",ra:"SR",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:800,de:1800,tx:"このカードは相手プレイヤーに直接攻撃できる。\nこの時、相手ライフに与える戦闘ダメージはこのカードの元々の攻撃力と同じ数値になる。\nまた、このカードが戦闘を行ったダメージステップ終了時、このカードの表示形式を守備表示にできる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"The blazing MARS",h:"ざ・ぶれいじんぐ・まーず",e:"The Blazing Mars",ra:"UR",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:2600,de:2200,tx:"「The blazing MARS」の②の効果は１ターンに１度しか使用できない。\n①：このカードが手札・墓地に存在する場合、このカード以外の自分の墓地のモンスター３体を除外して発動できる。\nこのカードを特殊召喚する。\nこの効果の発動後、ターン終了時まで自分はモンスターを特殊召喚できない。\n②：自分メインフェイズ１に、このカード以外の自分フィールドのモンスターを全て墓地へ送って発動できる。\n墓地へ送ったモンスターの数×５００ダメージを相手に与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サプレス・コライダー",h:"さぷれす・こらいだー",e:"Suppression Collider",ra:"N",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"サイバース族",mo:["通常"],ma:"",tp:"",at:2800,de:2600,tx:"ネットワーク世界の巨悪と戦う熟練の闘士。\nその鉄鎚に圧縮されたデータは、誰にも復元できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サボウ・ファイター",h:"さぼう・ふぁいたー",e:"Cactus Fighter",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1900,de:400,tx:"このカードが戦闘によって相手モンスターを破壊した場合、相手フィールド上に「ニードルトークン」（植物族・地・星１・攻／守５００）１体を守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"さまようミイラ",h:"さまようみいら",e:"Wandering Mummy",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1500,de:1500,tx:"このカードは１ターンに１度だけ裏側守備表示にする事ができる。\nこの効果を使用した時、自分フィールド上に裏側守備表示で存在する全てのモンスターをシャッフルし、再び裏側守備表示で並べ替える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"さまよえる亡者",h:"さまよえるもうじゃ",e:"The Wandering Doomed",ra:"N",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"アンデット族",mo:["通常"],ma:"",tp:"",at:800,de:600,tx:"成仏できず、行くあてもなくフラフラとしているモンスター。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サムライソード・バロン",h:"さむらいそーど・ばろん",e:"Samurai Sword Baron",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"１ターンに１度、相手フィールド上に守備表示で存在するモンスター１体を選択して発動する事ができる。\n選択したモンスターを表側攻撃表示にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サモン・リアクター・AI",h:"さもん・りあくたー・えーあい",e:"Summon Reactor ・SK",ra:"N",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2000,de:1400,tx:"このカードが自分フィールド上に表側表示で存在する限り、相手フィールド上にモンスターが召喚・反転召喚・特殊召喚された時、相手ライフに８００ポイントダメージを与える。\nこの効果は１ターンに１度しか使用できない。\nこの効果を使用したターンのバトルフェイズ時、相手モンスター１体の攻撃を無効にできる。\nまた、自分フィールド上に表側表示で存在する、このカードと「トラップ・リアクター・RR」「マジック・リアクター・AID」をそれぞれ１体ずつ墓地へ送る事で、自分の手札・デッキ・墓地から「ジャイアント・ボマー・エアレイド」１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サルベージ・ウォリアー",h:"さるべーじ・うぉりあー",e:"Salvage Warrior",ra:"R",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1900,de:1600,tx:"このカードがアドバンス召喚に成功した時、手札または自分の墓地からチューナー１体を特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"斬機アディオン",h:"ざんきあでぃおん",e:"Mathmech Addition",ra:"SR",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：フィールドの表側表示モンスター１体を対象として発動できる。\nこのカードを手札から特殊召喚し、対象のモンスターの攻撃力をターン終了時まで１０００アップする。\nこの効果で特殊召喚したターン、このカードは攻撃できない。\nこの効果の発動後、ターン終了時まで自分はサイバース族モンスターしかEXデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"斬機サブトラ",h:"ざんきさぶとら",e:"Mathmech Subtraction",ra:"R",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：フィールドの表側表示モンスター１体を対象として発動できる。\nこのカードを手札から特殊召喚し、対象のモンスターの攻撃力をターン終了時まで１０００ダウンする。\nこの効果で特殊召喚したターン、このカードは攻撃できない。\nこの効果の発動後、ターン終了時まで自分はサイバース族モンスターしかEXデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"斬首の美女",h:"ざんしゅのびじょ",e:"Beautiful Headhuntress",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1600,de:800,tx:"その美貌とはうらはらに、カタナで数多くの首をはねてきた女。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サンダー・キッズ",h:"さんだー・きっず",e:"Thunder Kid",ra:"N",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"雷族",mo:["通常"],ma:"",tp:"",at:700,de:600,tx:"雷をからだの中に蓄電させている。\n泣かせたときは危険。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サンダー・シーホース",h:"さんだー・しーほーす",e:"Thunder Sea Horse",ra:"UR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"「サンダー・シーホース」の効果は１ターンに１度しか使用できず、この効果を発動するターン、自分はモンスターを特殊召喚できない。\n①：このカードを手札から捨てて発動できる。\nデッキから攻撃力１６００以下の雷族・光属性・レベル４の同名モンスター２体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サンダー・ドラゴン",h:"さんだー・どらごん",e:"Thunder Dragon",ra:"SR",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:1600,de:1500,tx:"①：このカードを手札から捨てて発動できる。\nデッキから「サンダー・ドラゴン」を２体まで手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サンダー・ユニコーン",h:"さんだー・ゆにこーん",e:"Thunder Unicorn",ra:"R",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"獣族",mo:["シンクロ","効果"],ma:"",tp:"",at:2200,de:1800,tx:"獣族チューナー＋チューナー以外のモンスター１体以上\n１ターンに１度、自分のメインフェイズ時に相手フィールド上に表側表示で存在するモンスター１体を選択して発動する事ができる。\n選択したモンスターの攻撃力はエンドフェイズ時まで、自分フィールド上に存在するモンスターの数×５００ポイントダウンする。\nこの効果を発動するターン、このカード以外のモンスターは攻撃する事ができない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サンダーエンド・ドラゴン",h:"さんだーえんど・どらごん",e:"Thunder End Dragon",ra:"UR",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:3000,de:2000,tx:"レベル８通常モンスター×２\n１ターンに１度、このカードのエクシーズ素材を１つ取り除いて発動する事ができる。\nこのカード以外のフィールド上に存在するモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"三段腹ナイト",h:"さんだんばらないと",e:"Big Belly Knight",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:1600,tx:"エクシーズ素材のこのカードがエクシーズモンスターの効果を発動するために取り除かれ墓地へ送られた場合、手札からレベル３以下のモンスター１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"砂の魔女",h:"さんど・うぃっち",e:"Mystical Sand",ra:"N",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"岩石族",mo:["融合"],ma:"",tp:"",at:2100,de:1700,tx:"岩石の巨兵＋エンシェント・エルフ",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サンド・ギャンブラー",h:"さんど・ぎゃんぶらー",e:"Sand Gambler",ra:"N",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:300,de:1600,tx:"コイントスを３回行う。\n３回とも表だった場合、相手フィールド上モンスターを全て破壊する。\n３回とも裏だった場合、自分フィールド上モンスターを全て破壊する。\nこの効果は１ターンに１度だけ自分のメインフェイズに使用する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サンド・ストーン",h:"さんど・すとーん",e:"Sand Stone",ra:"N",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"岩石族",mo:["通常"],ma:"",tp:"",at:1300,de:1600,tx:"地下から突然目の前に現れ、触手で攻撃してくる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サンドモス",h:"さんどもす",e:"Sand Moth",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1000,de:2000,tx:"裏側守備表示のこのカードが戦闘以外によって破壊され墓地へ送られた時、元々の攻撃力と守備力を入れ替えて自分フィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サンライズ・ガードナー",h:"さんらいず・がーどなー",e:"Dawnbreak Gardna",ra:"R",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"戦士族",mo:["デュアル","効果"],ma:"",tp:"",at:1500,de:500,tx:"このカードは墓地またはフィールド上に表側表示で存在する場合、通常モンスターとして扱う。\nフィールド上に表側表示で存在するこのカードを通常召喚扱いとして再度召喚する事で、このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●このカードの元々の守備力は２３００になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サンライト・ユニコーン",h:"さんらいと・ゆにこーん",e:"Sunlight Unicorn",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"１ターンに１度、自分のメインフェイズ時に発動する事ができる。\n自分のデッキの一番上のカードをめくり、それが装備魔法カードだった場合手札に加える。\n違った場合、自分のデッキの一番下に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"三連星のトリオン",h:"さんれんせいのとりおん",e:"Triple Star Trion",ra:"N",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"このカードがアドバンス召喚のリリースとして墓地へ送られたターンのエンドフェイズ時、このカードを墓地から特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"The アトモスフィア",h:"じ　あともすふぃあ",e:"The Atmosphere",ra:"UR",t:"monster",a:"風",l:8,ps:"",pe:"",tr:"鳥獣族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1000,de:800,tx:"このカードは通常召喚できない。\n自分フィールド上に存在するモンスター２体と自分の墓地に存在するモンスター１体をゲームから除外した場合に特殊召喚する事ができる。\n１ターンに１度、相手フィールド上に表側表示で存在するモンスターを装備カード扱いとしてこのカードに１体のみ装備する事ができる。\nこのカードの攻撃力・守備力は、このカードの効果で装備したモンスターのそれぞれの数値分アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"終焉の精霊",h:"じ・えんど・すぴりっつ",e:"Doomsday Horror",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:"?",de:"?",tx:"このカードの攻撃力・守備力は、ゲームから除外されている闇属性モンスターの数×３００ポイントになる。\nこのカードが破壊され墓地へ送られた時、ゲームから除外されている闇属性モンスターを全て墓地に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シー・アーチャー",h:"しー・あーちゃー",e:"Mermaid Archer",ra:"N",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:1200,de:200,tx:"１ターンに１度、自分フィールド上のレベル３以下のモンスター１体を選択し、装備カード扱いとしてこのカードに１体のみ装備できる。\nこの効果でモンスターを装備している場合、このカードの攻撃力は８００ポイントアップする。\nまた、フィールド上のこのカードが破壊される場合、代わりにこのカードの効果で装備したモンスターを破壊できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シー・ランサー",h:"しー・らんさー",e:"Sea Lancer",ra:"N",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1300,de:1000,tx:"ゲームから除外されている自分の魚族・海竜族・水族モンスターを任意の数だけ選択し、装備カード扱いとしてこのカードに装備できる。\nこの効果はこのカードがフィールド上に表側表示で存在する限り１度しか使用できない。\nこの効果でモンスターを装備している場合、このカードの攻撃力は１０００ポイントアップする。\nまた、フィールド上のこのカードが破壊される場合、代わりにこのカードの効果で装備したモンスター１体を破壊できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シーカーメン",h:"しーかーめん",e:"Sea Kamen",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1100,de:1300,tx:"鋭いかぎづめで相手を切り裂く、残忍なモンスター。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シーザリオン",h:"しーざりおん",e:"Giant Red Seasnake",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1800,de:800,tx:"海中に住むヘビのモンスター。\n近づくものに噛みついてくる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"G戦隊 シャインブラック",h:"じーせんたい　しゃいんぶらっく",e:"Shiny Black \"C\" Squadder",ra:"UR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["通常"],ma:"",tp:"",at:2000,de:0,tx:"黒光りするスーツを身にまとい、戦場を駆け回る“黒の閃光”・・・。\n\n影あるところにG戦隊あり！\n隠された飛行能力を駆使して巨大なモンスターにも立ち向かうぞ！！",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シード・オブ・フレイム",h:"しーど・おぶ・ふれいむ",e:"Seed of Flame",ra:"R",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"自分フィールド上に存在するこのカードがカードの効果によって破壊され墓地へ送られた時に発動する事ができる。\n自分の墓地に存在する「シード・オブ・フレイム」以外のレベル４以下の植物族モンスター１体を自分フィールド上に特殊召喚し、相手フィールド上に「シードトークン」（植物族・地・星１・攻／守０）１体を守備表示で特殊召喚する。\nこのトークンはアドバンス召喚のためにはリリースできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"G・B・ハンター",h:"じーびー・はんたー",e:"G.B. Hunter",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:2000,tx:"このカードがフィールド上に表側表示で存在する限り、お互いのプレイヤーはフィールド上のカードをデッキに戻す事はできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シーホース",h:"しーほーす",e:"Tatsunootoshigo",ra:"N",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:1350,de:1600,tx:"ウマとサカナの体を持つモンスター。\n海中を風のように駆け抜ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シールド・ウォリアー",h:"しーるど・うぉりあー",e:"Shield Warrior",ra:"SR",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:800,de:1600,tx:"戦闘ダメージ計算時、自分の墓地に存在するこのカードをゲームから除外して発動する事ができる。\n自分フィールド上に存在するモンスターはその戦闘では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シールド・ワーム",h:"しーるど・わーむ",e:"Shield Worm",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:800,de:2000,tx:"このカードは召喚・反転召喚・特殊召喚に成功した時、守備表示になる。\nさらに、自分フィールド上に表側表示で存在する昆虫族モンスターの数だけ、相手のデッキの上からカードを墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジェイドナイト",h:"じぇいどないと",e:"Jade Knight",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:1800,tx:"このカードがフィールド上に表側攻撃表示で存在する限り、自分フィールド上の攻撃力１２００以下の機械族モンスターは罠カードの効果では破壊されない。\nフィールド上に表側表示で存在するこのカードが戦闘によって破壊され墓地へ送られた時、デッキから機械族・光属性・レベル４のモンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シェイプ・スナッチ",h:"しぇいぷ・すなっち",e:"Shapesnatch",ra:"N",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1200,de:1700,tx:"恐ろしい力を持つ蝶ネクタイ。\n何者かの身体を支配して襲いかかる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジェスター・コンフィ",h:"じぇすたー・こんふぃ",e:"Jester Confit",ra:"SR",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"①：「ジェスター・コンフィ」は自分フィールドに１体しか表側表示で存在できない。\n②：このカードは手札から攻撃表示で特殊召喚できる。\n③：このカードの②の方法で特殊召喚した場合、次の相手エンドフェイズに相手フィールドの表側表示モンスター１体を対象として発動する。\nその相手の表側表示モンスターと表側表示のこのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジェスター・ロード",h:"じぇすたー・ろーど",e:"Jester Lord",ra:"N",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"フィールド上にこのカード以外のモンスターが存在しない場合、お互いの魔法＆罠カードゾーンに存在するカード１枚につき、このカードの攻撃力は１０００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジェット・ウォリアー",h:"じぇっと・うぉりあー",e:"Jet Warrior",ra:"R",t:"monster",a:"炎",l:5,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2100,de:1200,tx:"「ジェット・シンクロン」＋チューナー以外のモンスター１体以上\n「ジェット・ウォリアー」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがS召喚に成功した場合、相手フィールドのカード１枚を対象として発動できる。\nそのカードを持ち主の手札に戻す。\n②：このカードが墓地に存在する場合、自分フィールドのレベル２以下のモンスター１体をリリースして発動できる。\nこのカードを墓地から守備表示で特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジェット・シンクロン",h:"じぇっと・しんくろん",e:"Jet Synchron",ra:"UR",t:"monster",a:"炎",l:1,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:500,de:0,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードがS素材として墓地へ送られた場合に発動できる。\nデッキから「ジャンク」モンスター１体を手札に加える。\n②：このカードが墓地に存在する場合、手札を１枚墓地へ送って発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジェット・ロイド",h:"じぇっと・ろいど",e:"Jetroid",ra:"SR",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:1800,tx:"このカードが相手モンスターの攻撃対象に選択された時、このカードのコントローラーは手札から罠カードを発動する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジェネクス・ウンディーネ",h:"じぇねくす・うんでぃーね",e:"Genex Undine",ra:"UR",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1200,de:600,tx:"①：このカードが召喚に成功した時、デッキから水属性モンスター１体を墓地へ送って発動できる。\nデッキから「ジェネクス・コントローラー」１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジェネクス・ガイア",h:"じぇねくす・がいあ",e:"Genex Gaia",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1000,de:1900,tx:"フィールド上に表側表示で存在するこのカードが破壊される場合、代わりに自分フィールド上に表側表示で存在する「ジェネクス・コントローラー」１体を破壊できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジェネクス・コントローラー",h:"じぇねくす・こんとろーらー",e:"Genex Controller",ra:"R",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"機械族",mo:["チューナー","通常"],ma:"",tp:"",at:1400,de:1200,tx:"仲間達と心を通わせる事ができる、数少ないジェネクスのひとり。\n様々なエレメントの力をコントロールできるぞ。",li:2,lk:[],ta:[],al:[],ocg:true},
  {n:"ジェネクス・サーチャー",h:"じぇねくす・さーちゃー",e:"Genex Searcher",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1600,de:400,tx:"このカードが戦闘によって破壊され墓地へ送られた時、デッキから攻撃力１５００以下の「ジェネクス」と名のついたモンスター１体を表側攻撃表示で特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジェネクス・ドクター",h:"じぇねくす・どくたー",e:"Genex Doctor",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"自分フィールド上に表側表示で存在する「ジェネクス・コントローラー」１体をリリースして発動できる。\nフィールド上のカード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジェネクス・ニュートロン",h:"じぇねくす・にゅーとろん",e:"Genex Neutron",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1800,de:1200,tx:"①：このカードを召喚したターンのエンドフェイズに発動できる。\nデッキから機械族チューナー１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジェネクス・パワー・プランナー",h:"じぇねくす・ぱわー・ぷらんなー",e:"Genex Power Planner",ra:"N",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:300,de:200,tx:"このカードが召喚に成功した時、デッキからレベル３の「ジェネクス」と名のついた効果モンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジェネクス・ヒート",h:"じぇねくす・ひーと",e:"Genex Furnace",ra:"N",t:"monster",a:"炎",l:5,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:2000,de:1300,tx:"自分フィールド上に「ジェネクス・コントローラー」が存在する場合、このカードはリリースなしで召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジェネクス・ブラスト",h:"じぇねくす・ぶらすと",e:"Genex Blastfan",ra:"N",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1600,de:1300,tx:"このカードが特殊召喚に成功した時、デッキから「ジェネクス」と名のついた闇属性モンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジェネクス・ワーカー",h:"じぇねくす・わーかー",e:"Genex Worker",ra:"N",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:1200,tx:"このカードをリリースして発動できる。\n手札から「ジェネクス」と名のついたモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"創世竜",h:"じぇねしっくどらごん",e:"Genesis Dragon",ra:"SR",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2200,de:1800,tx:"１ターンに１度、手札からドラゴン族モンスター１体を墓地へ送る事で、自分の墓地のドラゴン族モンスター１体を選択して手札に加える。\nこのカードがフィールド上から墓地へ送られた時、自分の墓地のドラゴン族モンスターを全てデッキに戻す事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジェネティック・ウーマン",h:"じぇねてぃっく・うーまん",e:"Genetic Woman",ra:"R",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1700,de:1200,tx:"１ターンに１度、１０００ライフポイントを払い、ゲームから除外されている自分のサイキック族モンスター１体を選択して発動できる。\n除外されているそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジェネティック・ワーウルフ",h:"じぇねてぃっく・わーうるふ",e:"Gene-Warped Warwolf",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["通常"],ma:"",tp:"",at:2000,de:100,tx:"遺伝子操作により強化された人狼。\n本来の優しき心は完全に破壊され、闘う事でしか生きる事ができない体になってしまった。\nその破壊力は計り知れない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"無敗将軍 フリード",h:"じぇねらる　ふりーど",e:"Freed the Matchless General",ra:"SR",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2300,de:1700,tx:"このカードがフィールド上に表側表示で存在する限り、このカードを対象にする魔法カードの効果を無効にし破壊する。\nこのカードがフィールド上に表側表示で存在する限り、自分のドローフェイズ時に通常のドローを行う代わりに、自分のデッキからレベル４以下の戦士族モンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジェネラルデーモン",h:"じぇねらるでーもん",e:"Archfiend General",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2100,de:800,tx:"このカードを手札から墓地へ捨てて発動できる。\nデッキから「万魔殿－悪魔の巣窟－」１枚を手札に加える。\nまた、フィールド上に「万魔殿－悪魔の巣窟－」が存在しない場合、このカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジェノサイドキングサーモン",h:"じぇのさいどきんぐさーもん",e:"Terrorking Salmon",ra:"N",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"魚族",mo:["通常"],ma:"",tp:"",at:2400,de:1000,tx:"暗黒海の主として恐れられている巨大なシャケ。\nその卵は暗黒界一の美味として知られている。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジェノサイドキングデーモン",h:"じぇのさいどきんぐでーもん",e:"Terrorking Archfiend",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2000,de:1500,tx:"自分フィールド上に「デーモン」という名のついたモンスターカードが存在しなければこのカードは召喚・反転召喚できない。\nこのカードのコントローラーは自分のスタンバイフェイズ毎に８００ライフポイントを払う。\nこのカードが相手のコントロールするカードの効果の対象になり、その処理を行う時にサイコロを１回振る。\n２・５が出た場合、その効果を無効にし破壊する。\nこのカードが戦闘で破壊した効果モンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジェノミックス・ファイター",h:"じぇのみっくす・ふぁいたー",e:"Genomix Fighter",ra:"R",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"サイキック族",mo:["チューナー","効果"],ma:"",tp:"",at:2200,de:1100,tx:"このカードはリリースなしで召喚できる。\nこの方法で召喚したこのカードのレベルは３になり、元々の攻撃力は半分になる。\nまた、１ターンに１度、種族を１つ宣言して発動できる。\nこのターンこのカードをシンクロ素材とする場合、このカードを含むそのシンクロ素材モンスター一組を宣言した種族として扱う。\nこの効果を発動したターン、自分は宣言した種族以外のモンスターを召喚・特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジェム・マーチャント",h:"じぇむ・まーちゃんと",e:"Gem-Merchant",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"自分フィールド上の地属性の通常モンスターが戦闘を行うダメージステップ時、このカードを手札から墓地へ送って発動できる。\nそのモンスターの攻撃力・守備力はエンドフェイズ時まで１０００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジェムエレファント",h:"じぇむえれふぁんと",e:"Gem-Elephant",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:400,de:1900,tx:"自分のメインフェイズ時に、フィールド上に表側表示で存在するこのカードを手札に戻す事ができる。\nまた、このカードが戦闘を行うダメージ計算時に１度だけ、手札から通常モンスター１体を墓地へ送って発動する事ができる。\nこのカードの守備力は、そのダメージ計算時のみ１０００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジェムタートル",h:"じぇむたーとる",e:"Gem-Turtle",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["リバース","効果"],ma:"",tp:"",at:0,de:2000,tx:"リバース：デッキから「ジェムナイト・フュージョン」１枚を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジェムナイト・アイオーラ",h:"じぇむないと・あいおーら",e:"Gem-Knight Iolite",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"水族",mo:["デュアル","効果"],ma:"",tp:"",at:1300,de:2000,tx:"このカードは墓地またはフィールド上に表側表示で存在する場合、通常モンスターとして扱う。\nフィールド上に表側表示で存在するこのカードを通常召喚扱いとして再度召喚する事で、このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●１ターンに１度、自分の墓地の「ジェム」と名のついたモンスター１体をゲームから除外して発動できる。\n自分の墓地の「ジェムナイト」と名のついたカード１枚を選択して手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジェムナイト・アクアマリナ",h:"じぇむないと・あくあまりな",e:"Gem-Knight Aquamarine",ra:"R",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"水族",mo:["融合","効果"],ma:"",tp:"",at:1400,de:2600,tx:"「ジェムナイト・サフィア」＋「ジェムナイト」モンスター\nこのカードは上記カードを融合素材にした融合召喚でのみエクストラデッキから特殊召喚できる。\n①：このカードは攻撃した場合、バトルフェイズ終了時に守備表示になる。\n②：このカードがフィールドから墓地へ送られた場合、相手フィールドのカード１枚を対象として発動する。\nその相手のカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジェムナイト・アメジス",h:"じぇむないと・あめじす",e:"Gem-Knight Amethyst",ra:"R",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"水族",mo:["融合","効果"],ma:"",tp:"",at:1950,de:2450,tx:"「ジェムナイト」モンスター＋水族モンスター\nこのカードは融合召喚でのみエクストラデッキから特殊召喚できる。\n①：このカードがフィールドから墓地へ送られた場合に発動する。\nフィールドにセットされた魔法・罠カードを全て持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジェムナイト・アレキサンド",h:"じぇむないと・あれきさんど",e:"Gem-Knight Alexandrite",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1800,de:1200,tx:"①：このカードをリリースして発動できる。\nデッキから「ジェムナイト」通常モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジェムナイト・アンバー",h:"じぇむないと・あんばー",e:"Gem-Knight Amber",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"雷族",mo:["デュアル","効果"],ma:"",tp:"",at:1600,de:1400,tx:"このカードは墓地またはフィールド上に表側表示で存在する場合、通常モンスターとして扱う。\nフィールド上に表側表示で存在するこのカードを通常召喚扱いとして再度召喚する事で、このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●１ターンに１度、手札から「ジェムナイト」と名のついたカード１枚を墓地へ送って発動できる。\nゲームから除外されている自分のモンスター１体を選択して手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジェムナイト・エメラル",h:"じぇむないと・えめらる",e:"Gem-Knight Emerald",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1800,de:800,tx:"①：自分フィールドの表側表示の通常モンスター１体とこのカードを除外し、自分の墓地の「ジェムナイト」融合モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジェムナイト・オブシディア",h:"じぇむないと・おぶしでぃあ",e:"Gem-Knight Obsidian",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1500,de:1200,tx:"①：このカードが手札から墓地へ送られた場合、自分の墓地のレベル４以下の通常モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジェムナイト・ガネット",h:"じぇむないと・がねっと",e:"Gem-Knight Garnet",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"炎族",mo:["通常"],ma:"",tp:"",at:1900,de:0,tx:"ガーネットの力を宿すジェムナイトの戦士。\n炎の鉄拳はあらゆる敵を粉砕するぞ。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジェムナイト・クリスタ",h:"じぇむないと・くりすた",e:"Gem-Knight Crystal",ra:"R",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"岩石族",mo:["通常"],ma:"",tp:"",at:2450,de:1950,tx:"クリスタルパワーを最適化し、戦闘力に変えて戦うジェムナイトの上級戦士。\nその高い攻撃力で敵を圧倒するぞ。\nしかし、その最適化には限界を感じる事も多く、仲間たちとの結束を大切にしている。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジェムナイト・サニクス",h:"じぇむないと・さにくす",e:"Gem-Knight Sardonyx",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"炎族",mo:["デュアル","効果"],ma:"",tp:"",at:1800,de:900,tx:"このカードは墓地またはフィールド上に表側表示で存在する場合、通常モンスターとして扱う。\nフィールド上に表側表示で存在するこのカードを通常召喚扱いとして再度召喚する事で、このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●このカードが戦闘によって相手モンスターを破壊し墓地へ送った時、デッキから「ジェムナイト」と名のついたカード１枚を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジェムナイト・サフィア",h:"じぇむないと・さふぃあ",e:"Gem-Knight Sapphire",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:0,de:2100,tx:"サファイアのパワーで水を自在に操り、敵からの攻撃をやさしく包み込んでしまう。\nその静かなる守りは仲間から信頼されているらしい。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジェムナイト・ジルコニア",h:"じぇむないと・じるこにあ",e:"Gem-Knight Zirconia",ra:"R",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"岩石族",mo:["融合"],ma:"",tp:"",at:2900,de:2500,tx:"「ジェムナイト」モンスター＋岩石族モンスター",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジェムナイト・セラフィ",h:"じぇむないと・せらふぃ",e:"Gem-Knight Seraphinite",ra:"SR",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"天使族",mo:["融合","効果"],ma:"",tp:"",at:2300,de:1400,tx:"「ジェムナイト」モンスター＋光属性モンスター\nこのカードは上記カードを融合素材にした融合召喚でのみEXデッキから特殊召喚できる。\n①：このカードがモンスターゾーンに存在する限り、自分は通常召喚に加えて１度だけ、自分メインフェイズにモンスター１体を通常召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジェムナイト・パーズ",h:"じぇむないと・ぱーず",e:"Gem-Knight Topaz",ra:"R",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"雷族",mo:["融合","効果"],ma:"",tp:"",at:1800,de:1800,tx:"「ジェムナイト・ルマリン」＋「ジェムナイト」モンスター\nこのカードは上記カードを融合素材にした融合召喚でのみエクストラデッキから特殊召喚できる。\n①：このカードは１度のバトルフェイズ中に２回攻撃できる。\n②：このカードが戦闘でモンスターを破壊し墓地へ送った場合に発動する。\nそのモンスターの元々の攻撃力分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジェムナイト・パール",h:"じぇむないと・ぱーる",e:"Gem-Knight Pearl",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["エクシーズ"],ma:"",tp:"",at:2600,de:1900,tx:"レベル４モンスター×２",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジェムナイト・プリズムオーラ",h:"じぇむないと・ぷりずむおーら",e:"Gem-Knight Prismaura",ra:"SR",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"雷族",mo:["融合","効果"],ma:"",tp:"",at:2450,de:1400,tx:"「ジェムナイト」モンスター＋雷族モンスター\nこのカードは融合召喚でのみエクストラデッキから特殊召喚できる。\n①：１ターンに１度、手札から「ジェムナイト」カード１枚を墓地へ送り、フィールドの表側表示のカード１枚を対象として発動できる。\nその表側表示のカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジェムナイト・マディラ",h:"じぇむないと・までぃら",e:"Gem-Knight Citrine",ra:"R",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"炎族",mo:["融合","効果"],ma:"",tp:"",at:2200,de:1950,tx:"「ジェムナイト」モンスター＋炎族モンスター\nこのカードは融合召喚でのみエクストラデッキから特殊召喚できる。\n①：このカードが戦闘を行う場合、相手はダメージステップ終了時まで魔法・罠・モンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジェムナイト・ラズリー",h:"じぇむないと・らずりー",e:"Gem-Knight Lazuli",ra:"R",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:600,de:100,tx:"①：このカードが効果で墓地へ送られた場合、自分の墓地の通常モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジェムナイト・ラピス",h:"じぇむないと・らぴす",e:"Gem-Knight Lapis",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["通常"],ma:"",tp:"",at:1200,de:100,tx:"仲間の健康を常に気づかう癒しの戦士。\nラズリーとは大の仲良しだ。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジェムナイト・ルビーズ",h:"じぇむないと・るびーず",e:"Gem-Knight Ruby",ra:"R",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"炎族",mo:["融合","効果"],ma:"",tp:"",at:2500,de:1300,tx:"「ジェムナイト・ガネット」＋「ジェムナイト」モンスター\nこのカードは上記カードを融合素材にした融合召喚でのみエクストラデッキから特殊召喚できる。\n①：１ターンに１度、このカード以外の自分フィールドの表側表示の「ジェム」モンスター１体をリリースして発動できる。\nこのカードの攻撃力はターン終了時まで、リリースしたモンスターの攻撃力分アップする。\n②：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジェムナイト・ルマリン",h:"じぇむないと・るまりん",e:"Gem-Knight Tourmaline",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"雷族",mo:["通常"],ma:"",tp:"",at:1600,de:1800,tx:"イエロートルマリンの力で不思議なエナジーを創りだし、戦力に変えて闘うぞ。\n彼の刺激的な生き方に共感するジェムは多い。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジェムナイトマスター・ダイヤ",h:"じぇむないとますたー・だいや",e:"Gem-Knight Master Diamond",ra:"UR",t:"monster",a:"地",l:9,ps:"",pe:"",tr:"岩石族",mo:["融合","効果"],ma:"",tp:"",at:2900,de:2500,tx:"「ジェムナイト」モンスター×３\nこのカードは融合召喚でのみエクストラデッキから特殊召喚できる。\n①：このカードの攻撃力は、自分の墓地の「ジェム」モンスターの数×１００アップする。\n②：１ターンに１度、自分の墓地のレベル７以下の「ジェムナイト」融合モンスター１体を除外して発動できる。\nエンドフェイズまで、このカードは除外したモンスターと同名カードとして扱い、同じ効果を得る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジェムナイトレディ・ブリリアント・ダイヤ",h:"じぇむないとれでぃ・ぶりりあんと・だいや",e:"Gem-Knight Lady Brilliant Diamond",ra:"UR",t:"monster",a:"地",l:10,ps:"",pe:"",tr:"岩石族",mo:["融合","効果"],ma:"",tp:"",at:3400,de:2000,tx:"「ジェムナイト」モンスター×３\nこのカードは上記カードを融合素材にした融合召喚でのみエクストラデッキから特殊召喚できる。\n自分は「ジェムナイトレディ・ブリリアント・ダイヤ」を１ターンに１度しか特殊召喚できない。\n①：１ターンに１度、自分メインフェイズに発動できる。\n自分フィールドの表側表示の「ジェムナイト」モンスター１体を選んで墓地へ送り、エクストラデッキから「ジェムナイト」融合モンスター１体を召喚条件を無視して特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジェムナイトレディ・ラピスラズリ",h:"じぇむないとれでぃ・らぴすらずり",e:"Gem-Knight Lady Lapis Lazuli",ra:"R",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"岩石族",mo:["融合","効果"],ma:"",tp:"",at:2400,de:1000,tx:"「ジェムナイト・ラピス」＋「ジェムナイト」モンスター\nこのカードは上記のカードを融合素材にした融合召喚でのみエクストラデッキから特殊召喚できる。\n自分は「ジェムナイトレディ・ラピスラズリ」を１ターンに１度しか特殊召喚できない。\n①：１ターンに１度、自分メインフェイズに発動できる。\nデッキ・エクストラデッキから「ジェムナイト」モンスター１体を墓地へ送り、フィールドの特殊召喚されたモンスターの数×５００ダメージを相手に与える。",li:1,lk:[],ta:[],al:[],ocg:true},
  {n:"ジェムレシス",h:"じぇむれしす",e:"Gem-Armadillo",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1700,de:500,tx:"①：このカードが召喚に成功した時に発動できる。\nデッキから「ジェムナイト」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジェリービーンズマン",h:"じぇりーびーんずまん",e:"Jerry Beans Man",ra:"SR",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"植物族",mo:["通常"],ma:"",tp:"",at:1750,de:0,tx:"ジェリーという名の豆戦士。\n自分が世界最強の戦士だと信じ込んでいるが、その実力は定かではない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"紫炎の足軽",h:"しえんのあしがる",e:"Shien's Footsoldier",ra:"R",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:700,de:300,tx:"このカードが戦闘によって破壊され墓地へ送られた時、デッキからレベル３以下の「六武衆」と名のついたモンスター１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"紫炎の荒武者",h:"しえんのあらむしゃ",e:"Shien's Daredevil",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"このカードが召喚に成功した時、このカードに武士道カウンターを１つ置く（最大１つまで）。\nこのカードに武士道カウンターが乗っている場合、このカードの攻撃力は３００ポイントアップする。\nまた、１ターンに１度、このカードに乗っている武士道カウンターを取り除き、フィールド上に表側表示で存在する武士道カウンターを置く事ができるカード１枚を選択して武士道カウンターを１つ置く事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"紫炎の影武者",h:"しえんのかげむしゃ",e:"Kagemusha of the Blue Flame",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:800,de:400,tx:"シエンに仕える影武者。\n鋭い切れ味の名刀を持つ。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"紫炎の寄子",h:"しえんのよりこ",e:"Shien's Squire",ra:"N",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"戦士族",mo:["チューナー","効果"],ma:"",tp:"",at:300,de:700,tx:"自分フィールド上に存在する「六武衆」と名のついたモンスターが戦闘を行う場合、そのダメージ計算時にこのカードを手札から墓地へ送って発動する。\nそのモンスターはこのターン戦闘では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"紫炎の老中 エニシ",h:"しえんのろうじゅう　えにし",e:"Enishi, Shien's Chancellor",ra:"SR",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2200,de:1200,tx:"このカードは通常召喚できない。\n自分の墓地の「六武衆」と名のついたモンスター２体をゲームから除外した場合のみ特殊召喚できる。\n１ターンに１度、フィールド上に表側表示で存在するモンスター１体を選択して破壊できる。\nこの効果を発動するターン、このカードは攻撃宣言できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジオ・ジェネクス",h:"じお・じぇねくす",e:"Geo Genex",ra:"R",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:1800,de:2800,tx:"「ジェネクス・コントローラー」＋チューナー以外の地属性モンスター１体以上\n１ターンに１度、自分フィールド上にレベル４以下の「ジェネクス」と名のついたモンスターが存在する限り、このカードの元々の攻撃力と元々の守備力をエンドフェイズ時まで入れ替える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"死王リッチーロード",h:"しおうりっちーろーど",e:"Lich Lord, King of the Underworld",ra:"R",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:2400,de:1200,tx:"このカードをアドバンス召喚する場合、リリースするモンスターは闇属性モンスターでなければならない。\n①：このカードが効果でリリースされ墓地へ送られた場合に発動する。\n墓地のこのカードを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"屍を貪る竜",h:"しかばねをむさぼるりゅう",e:"Crawling Dragon #2",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"恐竜族",mo:["通常"],ma:"",tp:"",at:1600,de:1200,tx:"何でも噛み砕く口を持つ恐竜。\nその攻撃は強い。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"次元要塞兵器",h:"じげんようさいへいき",e:"Dimension Fortress Weapon",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1700,de:200,tx:"このカードがフィールド上に表側表示で存在する限り、お互いにデッキからカードを墓地へ送る事はできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"地獄からの使い",h:"じごくからのつかい",e:"Emissary from Pandemonium",ra:"R",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:2600,de:1800,tx:"このカードは特殊召喚できない。\nこのカードはモンスター１体をリリースして召喚できる。\nこの方法で召喚したこのカードのレベルは５になり、元々の攻撃力・守備力は半分になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"地獄詩人ヘルポエマー",h:"じごくしじんへるぽえまー",e:"Helpoemer",ra:"SR",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2000,de:1400,tx:"このカードは墓地からの特殊召喚はできない。\n①：戦闘で破壊されたこのカードが墓地に存在する場合、相手バトルフェイズ終了時に発動する。\nこのカードが墓地に存在する場合、相手の手札をランダムに１枚選んで捨てる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"地獄の傀儡魔人",h:"じごくのくぐつまじん",e:"Perditious Puppeteer",ra:"N",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2700,de:500,tx:"１ターンに１度、手札を１枚捨てる事で、相手フィールド上に表側表示で存在する全てのレベル３以下のモンスターのコントロールをエンドフェイズ時まで得る。\nこの効果でコントロールを得たモンスターは効果を発動する事ができず、リリースやシンクロ素材とする事もできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"地獄の裁判",h:"じごくのさいばん",e:"Trial of Nightmare",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1300,de:900,tx:"敵を棺桶に閉じこめ、地獄の使いがグサリと判決を下す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"地獄の番熊",h:"じごくのばんぐま",e:"Pandemonium Watchbear",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1300,de:1800,tx:"このカードが表側表示で自分フィールド上に存在する限り、自分フィールド上の「万魔殿－悪魔の巣窟－」は、相手がコントロールするカードの効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"地獄の門番イル・ブラッド",h:"じごくのもんばんいる・ぶらっど",e:"Il Blud",ra:"SR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"アンデット族",mo:["デュアル","効果"],ma:"",tp:"",at:2100,de:800,tx:"このカードは墓地またはフィールド上に表側表示で存在する場合、通常モンスターとして扱う。\nフィールド上に表側表示で存在するこのカードを通常召喚扱いとして再度召喚する事で、このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●１ターンに１度、手札・自分または相手の墓地に存在するアンデット族モンスター１体を特殊召喚する事ができる。\nこのカードがフィールド上から離れた時、この効果で特殊召喚したアンデット族モンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジゴバイト",h:"じごばいと",e:"Jigabyte",ra:"SR",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1500,de:200,tx:"①：「ジゴバイト」は自分フィールドに１体しか表側表示で存在できない。\n②：自分フィールドに魔法使い族モンスターが存在する場合、このカードは手札から特殊召喚できる。\n③：このカードが戦闘・効果で破壊され墓地へ送られた時に発動できる。\nデッキから「ジゴバイト」以外の攻撃力１５００／守備力２００のモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"磁石の戦士マグネット・バルキリオン",h:"じしゃくのせんしまぐねっと・ばるきりおん",e:"Valkyrion the Magna Warrior",ra:"UR",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"岩石族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3500,de:3850,tx:"このカードは通常召喚できない。\n自分の手札・フィールドから、「磁石の戦士α」「磁石の戦士β」「磁石の戦士γ」を１体ずつリリースした場合に特殊召喚できる。\n①：このカードをリリースし、自分の墓地の「磁石の戦士α」「磁石の戦士β」「磁石の戦士γ」を１体ずつ対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"死者の腕",h:"ししゃのうで",e:"Fiend's Hand",ra:"N",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"アンデット族",mo:["通常"],ma:"",tp:"",at:600,de:600,tx:"混沌の沼から腕をのばし、生ける者を中へと引きずり込む。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"死神鳥シムルグ",h:"ししんちょうしむるぐ",e:"Simorgh, Bird of Calamity",ra:"R",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1500,de:200,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時に発動できる。\nデッキから「死神鳥シムルグ」以外の「シムルグ」カード１枚を墓地へ送る。\n②：このカードが墓地に存在し、相手の魔法＆罠ゾーンにカードが存在しない場合に発動できる。\nこのカードを守備表示で特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。\nこの効果の発動後、ターン終了時まで自分は鳥獣族モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"始祖神鳥シムルグ",h:"しそしんちょうしむるぐ",e:"Simorgh, Bird of Ancestry",ra:"UR",t:"monster",a:"風",l:8,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2900,de:2000,tx:"このカードは手札にある場合通常モンスターとして扱う。\nこのカードがフィールド上に表側表示で存在する限り、風属性モンスターの生け贄召喚に必要な生け贄は１体少なくなる。\n風属性モンスターのみを生け贄にしてこのカードの生け贄召喚に成功した場合、相手フィールド上のカードを２枚まで持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"始祖の守護者ティラス",h:"しそのしゅごしゃてぃらす",e:"Tiras, Keeper of Genesis",ra:"SR",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"天使族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2600,de:1700,tx:"レベル５モンスター×２\nこのカードの効果はこのカードのエクシーズ素材がなければ適用されない。\nこのカードはカードの効果では破壊されない。\nこのカードが戦闘を行ったバトルフェイズ終了時、相手フィールド上に存在するカード１枚を選択して破壊する。\n自分のエンドフェイズ毎にこのカードのエクシーズ素材を１つ取り除く。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"漆黒の戦士 ワーウルフ",h:"しっこくのせんし　わーうるふ",e:"Pitch-Black Warwolf",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:600,tx:"①：このカードがモンスターゾーンに存在する限り、相手はバトルフェイズに罠カードを発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"漆黒の闘龍",h:"しっこくのどらごん",e:"Pitch-Dark Dragon",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["ユニオン","効果"],ma:"",tp:"",at:900,de:600,tx:"１ターンに１度だけ自分のメインフェイズに装備カード扱いとして自分の「闇魔界の戦士 ダークソード」に装備、または装備を解除して表側攻撃表示で特殊召喚する事ができる。\nこの効果で装備カード扱いになっている時のみ、装備モンスターの攻撃力・守備力は４００ポイントアップする。\n守備表示モンスターを攻撃した時にその守備力を攻撃力が越えていれば、その数値だけ相手に戦闘ダメージを与える。\n（１体のモンスターが装備できるユニオンは１枚まで。\n装備モンスターが戦闘によって破壊される場合は、代わりにこのカードを破壊する。\n）",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"漆黒の豹戦士パンサーウォリアー",h:"しっこくのひょうせんしぱんさーうぉりあー",e:"Panther Warrior",ra:"UR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:2000,de:1600,tx:"①：このカードがモンスターゾーンに存在する限り、このカードの攻撃宣言の際に、自分はこのカード以外の自分フィールドのモンスター１体をリリースしなければならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"疾走の暗黒騎士ガイア",h:"しっそうのあんこくきしがいあ",e:"Charging Gaia the Fierce Knight",ra:"SR",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2300,de:2100,tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードはリリースなしで召喚できる。\n②：リリースなしで召喚したこのカードの元々の攻撃力は１９００になる。\n③：このカードがリリースされた場合に発動できる。\nデッキから「カオス・ソルジャー」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"疾風鳥人ジョー",h:"しっぷうちょうじんじょー",e:"Swift Birdman Joe",ra:"SR",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2300,de:1400,tx:"風属性モンスターを生け贄にして生け贄召喚に成功した場合、フィールド上の魔法・罠カードを全て持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"疾風の暗黒騎士ガイア",h:"しっぷうのあんこくきしがいあ",e:"Swift Gaia the Fierce Knight",ra:"SR",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2300,de:2100,tx:"①：手札がこのカード１枚のみの場合、このカードはリリースなしで召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"熾天龍 ジャッジメント",h:"してんりゅう　じゃっじめんと",e:"Judgment, the Dragon of Heaven",ra:"R",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:3000,de:2600,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカードをS召喚する場合、S素材モンスターは全て同じ属性のモンスターでなければならない。\n①：自分の墓地にチューナーが４種類以上存在し、このカードがS召喚されている場合、１ターンに１度、LPを半分払って発動できる。\nこのカード以外のフィールドのカードを全て破壊する。\nこの効果の発動後、ターン終了時まで自分はドラゴン族モンスターしか特殊召喚できない。\n②：自分エンドフェイズに発動する。\n自分のデッキの上からカードを４枚除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"使徒喰い虫",h:"しとくいむし",e:"Nobleman-Eater Bug",ra:"SR",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"昆虫族",mo:["リバース","効果"],ma:"",tp:"",at:900,de:1200,tx:"リバース：フィールド上のモンスター２体を破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"死神のドクロイゾ",h:"しにがみのどくろいぞ",e:"Dokuroizo the Grim Reaper",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["通常"],ma:"",tp:"",at:900,de:1200,tx:"地獄の一撃で魂を奪おうとする死神。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"死神ブーメラン",h:"しにがみぶーめらん",e:"Dokuroyaiba",ra:"N",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1000,de:400,tx:"ねらいを付けた標的をめがけてどこまでも飛んでゆく意志を持ったブーメラン。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"死の４つ星てんとう虫",h:"しの４つほしてんとうむし",e:"4-Starred Ladybug of Doom",ra:"SR",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"昆虫族",mo:["リバース","効果"],ma:"",tp:"",at:800,de:1200,tx:"リバース：相手フィールド上に表側表示で存在するレベル４モンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"死の代行者 ウラヌス",h:"しのだいこうしゃ　うらぬす",e:"The Agent of Entropy - Uranus",ra:"R",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"天使族",mo:["チューナー","効果"],ma:"",tp:"",at:2200,de:1200,tx:"①：フィールドに「天空の聖域」が存在する場合、このカードは手札から特殊召喚できる。\n②：１ターンに１度、自分メインフェイズに発動できる。\nデッキから「代行者」モンスター１体を墓地へ送る。\nこのカードのレベルは、そのモンスターのレベルと同じになる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"死の沈黙の天使 ドマ",h:"しのちんもくのてんし　どま",e:"Doma The Angel of Silence",ra:"N",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:1600,de:1400,tx:"死を司る天使。\nこいつに睨まれたら、死から逃れられない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"死の花－ネクロ・フルール",h:"しのはな－ねくろ・ふるーる",e:"Necro Fleur",ra:"N",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"①：このカードが効果で破壊され墓地へ送られた場合に発動できる。\nデッキから「時花の魔女－フルール・ド・ソルシエール」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"忍び寄るデビルマンタ",h:"しのびよるでびるまんた",e:"Creeping Doom Manta",ra:"N",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1300,de:1200,tx:"このカードが召喚に成功した時、罠カードを発動する事はできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"地縛神 Aslla piscu",h:"じばくしん　あすら　ぴすく",e:"Earthbound Immortal Aslla piscu",ra:"UR",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2500,de:2500,tx:"「地縛神」と名のついたモンスターはフィールド上に１体しか表側表示で存在できない。\nフィールド魔法カードが表側表示で存在しない場合このカードを破壊する。\n相手はこのカードを攻撃対象に選択できない。\nこのカードは相手プレイヤーに直接攻撃できる。\nまた、フィールド上に表側表示で存在するこのカードが、このカードの効果以外の方法でフィールド上から離れた時、相手フィールド上に表側表示で存在するモンスターを全て破壊し、破壊したモンスターの数×８００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"地縛神 Wiraqocha Rasca",h:"じばくしん　うぃらこちゃ　らすか",e:"Earthbound Immortal Wiraqocha Rasca",ra:"UR",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"①：「地縛神」モンスターはフィールドに１体しか表側表示で存在できない。\n②：このカードが召喚に成功した場合、相手の手札の数までこのカード以外の自分フィールドのカードを対象として発動する（最大３枚まで）。\nそのカードを持ち主のデッキに戻す。\nその後、戻した数だけ相手の手札をランダムに選んで捨て、このカードの攻撃力は捨てた数×１０００アップする。\n③：このカードは直接攻撃できる。\n④：相手はこのカードを攻撃対象に選択できない。\n⑤：フィールド魔法カードが表側表示で存在しない場合にこのカードは破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"地縛神 Ccapac Apu",h:"じばくしん　こかぱく　あぷ",e:"Earthbound Immortal Ccapac Apu",ra:"UR",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:3000,de:2500,tx:"「地縛神」と名のついたモンスターはフィールド上に１体しか表側表示で存在できない。\nフィールド魔法カードが表側表示で存在しない場合このカードを破壊する。\n相手はこのカードを攻撃対象に選択できない。\nこのカードは相手プレイヤーに直接攻撃できる。\nまた、このカードが戦闘によって相手モンスターを破壊した場合、破壊したモンスターの元々の攻撃力分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"地縛大神官",h:"じばくだいしんかん",e:"Earthbound Linewalker",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1200,de:1900,tx:"このカードがフィールド上に表側表示で存在する限り、「地縛神」と名のついたモンスターは自身の効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"島亀",h:"しまがめ",e:"Island Turtle",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1100,de:2000,tx:"小島ほどの大きさがある巨大ガメ。\n海中に潜ることはなく、甲羅の上には木や生物が住みついている。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シャーク・ザ・クルー",h:"しゃーく・ざ・くるー",e:"Shark Cruiser",ra:"N",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1800,de:2200,tx:"自分フィールド上に表側表示で存在するこのカードが相手のカードの効果によって破壊された場合、自分のデッキからレベル４以下の水属性モンスターを２体まで自分フィールド上に特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シャーク・サッカー",h:"しゃーく・さっかー",e:"Shark Stickers",ra:"SR",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:200,de:1000,tx:"自分フィールド上に魚族・海竜族・水族モンスターが召喚・特殊召喚された時、このカードを手札から特殊召喚する事ができる。\nこのカードはシンクロ素材とする事はできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"邪悪なるワーム・ビースト",h:"じゃあくなるわーむ・びーすと",e:"The Wicked Worm Beast",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1400,de:700,tx:"自分ターンのエンドフェイズ時、表側表示でフィールド上に存在するこのカードは持ち主の手札に戻る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シャークラーケン",h:"しゃーくらーけん",e:"Sharkraken",ra:"N",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:2400,de:2100,tx:"このカードは自分フィールド上の水属性モンスター１体をリリースし、手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジャイアント・オーク",h:"じゃいあんと・おーく",e:"Giant Orc",ra:"UR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2200,de:0,tx:"このカードは攻撃した場合、バトルフェイズ終了時に守備表示になる。\n次の自分ターン終了時までこのカードの表示形式は変更できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"G・コザッキー",h:"じゃいあんと・こざっきー",e:"Giant Kozaky",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2500,de:2400,tx:"フィールド上に「コザッキー」が表側表示で存在していない場合、このカードを破壊する。\nフィールド上に表側表示で存在するこのカードが破壊された場合、その時のコントローラーにこのカードの元々の攻撃力分のダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジャイアント・ボマー・エアレイド",h:"じゃいあんと・ぼまー・えあれいど",e:"Flying Fortress SKY FIRE",ra:"N",t:"monster",a:"風",l:8,ps:"",pe:"",tr:"機械族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3000,de:2500,tx:"このカードは通常召喚できない。\n「サモン・リアクター・AI」の効果でのみ特殊召喚できる。\n１ターンに１度、手札を１枚墓地へ送る事で、相手フィールド上のカード１枚を選択して破壊する。\nまた、相手のターンに１度、以下の効果から１つを選択して発動できる。\n●相手がモンスターの召喚・特殊召喚に成功した時に発動できる。\nそのモンスターを破壊し、相手ライフに８００ポイントダメージを与える。\n●相手がカードをセットした時に発動できる。\nそのカードを破壊し、相手ライフに８００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジャイアント・レックス",h:"じゃいあんと・れっくす",e:"Giant Rex",ra:"UR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:2000,de:1200,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードは直接攻撃できない。\n②：このカードが除外された場合に発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードの攻撃力は、除外されている自分の恐竜族モンスターの数×２００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジャイアントマミー",h:"じゃいあんとまみー",e:"Giant Axe Mummy",ra:"SR",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1700,de:2000,tx:"このカードは１ターンに１度だけ裏側守備表示にする事ができる。\n裏側守備表示のこのカードを攻撃したモンスターの攻撃力がこのカードの守備力より低い場合、攻撃モンスターは破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジャイアントワーム",h:"じゃいあんとわーむ",e:"Aztekipede, the Worm Warrior",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1900,de:400,tx:"このカードは通常召喚できない。\n自分の墓地に存在する昆虫族モンスター１体をゲームから除外した場合のみ特殊召喚する事ができる。\nこのカードが相手ライフに戦闘ダメージを与えた時、相手のデッキの上からカードを１枚墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"SZW－天聖輝狼剣",h:"しゃいにんぐ・ぜある・うぇぽん－ふぇんりる・そーど",e:"SZW - Fenrir Sword",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1800,de:1100,tx:"このカードが召喚に成功した時、自分フィールド上の装備カード扱いの「ZW」と名のついたモンスター１体を選択して表側守備表示で特殊召喚できる。\nまた、自分のメインフェイズ時、手札のこのカードを装備カード扱いとして自分フィールド上の「希望皇ホープ」と名のついたモンスターに装備できる。\n装備モンスターが戦闘によって相手モンスターを破壊し墓地へ送った時、自分の墓地の「ZW」と名のついたモンスター１体を選択して手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジャイロイド",h:"じゃいろいど",e:"Gyroid",ra:"SR",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードは１ターンに１度だけ、戦闘によっては破壊されない。\n（ダメージ計算は適用する）",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シャイン・アビス",h:"しゃいん・あびす",e:"Shining Abyss",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:1600,de:1800,tx:"光の力と闇の力を兼ね備えているモンスター。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シャインエルフ",h:"しゃいんえるふ",e:"Shining Elf",ra:"R",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1600,de:1000,tx:"レベル２モンスター×２\n相手がモンスターの召喚・特殊召喚に成功した時、このカードのエクシーズ素材を１つ取り除いて発動できる。\nそのモンスターの攻撃力は５００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シャインエンジェル",h:"しゃいんえんじぇる",e:"Shining Angel",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1400,de:800,tx:"このカードが戦闘によって破壊され墓地へ送られた時、デッキから攻撃力１５００以下の光属性モンスター１体を表側攻撃表示で特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シャインスピリッツ",h:"しゃいんすぴりっつ",e:"Radiant Spirit",ra:"N",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:2000,de:1500,tx:"このカードが戦闘によって破壊され墓地へ送られた時、フィールド上に表側表示で存在する光属性モンスター以外のモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シャインナイト",h:"しゃいんないと",e:"Shine Knight",ra:"N",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:400,de:1900,tx:"このカードがフィールド上に表側守備表示で存在する限り、このカードのレベルは４になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"邪炎の翼",h:"じゃえんのつばさ",e:"Wings of Wicked Flame",ra:"N",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"炎族",mo:["通常"],ma:"",tp:"",at:700,de:600,tx:"赤黒く燃える翼。\n全身から炎を吹き出し攻撃する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"邪王トラカレル",h:"じゃおうとらかれる",e:"Tlakalel, His Malevolent Majesty",ra:"SR",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2400,de:1000,tx:"①：このカードがアドバンス召喚に成功した場合に発動できる。\n攻撃力の合計が、このカードのアドバンス召喚のためにリリースしたモンスターの元々の攻撃力以下になるように、相手フィールドの表側表示モンスターを任意の数だけ選んで破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"邪狂神の使い",h:"じゃきょうしんのつかい",e:"Maniacal Servant",ra:"R",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:600,de:1000,tx:"相手のスタンバイフェイズ時に１度だけ、自分の墓地に存在する闇属性モンスターを任意の枚数ゲームから除外する事ができる。\nエンドフェイズ時まで、このカードの守備力はこの効果で除外したモンスターの数×５００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"灼岩魔獣",h:"しゃくがんまじゅう",e:"Burning Beast",ra:"R",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["ユニオン","効果"],ma:"",tp:"",at:1500,de:1000,tx:"１ターンに１度だけ自分のメインフェイズに装備カード扱いとして自分の「氷岩魔獣」に装備、または装備を解除して表側攻撃表示で特殊召喚する事ができる。\nこの効果で装備カード扱いになっている時のみ、装備モンスターが相手プレイヤーに戦闘ダメージを与えた場合、フィールド上で表側表示の魔法または罠カード１枚を破壊する。\n（１体のモンスターが装備できるユニオンは１枚まで。\n装備モンスターが戦闘によって破壊される場合は、代わりにこのカードを破壊する。\n）",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"寂々虫",h:"じゃくじゃくむし",e:"Silent Strider",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:300,de:700,tx:"このカードを手札から墓地へ送って発動する。\nフィールド上に存在するモンスター１体のレベルをエンドフェイズ時まで１つ下げる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シャクトパス",h:"しゃくとぱす",e:"Shocktopus",ra:"R",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1600,de:800,tx:"このカードが相手モンスターとの戦闘によって破壊され墓地へ送られた時、このカードを装備カード扱いとしてその相手モンスターに装備できる。\nこの効果によってこのカードを装備したモンスターは攻撃力が０になり、表示形式を変更できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"灼熱王パイロン",h:"しゃくねつおうぱいろん",e:"King Pyron",ra:"N",t:"monster",a:"炎",l:5,ps:"",pe:"",tr:"炎族",mo:["デュアル","効果"],ma:"",tp:"",at:1500,de:500,tx:"このカードは墓地またはフィールド上に表側表示で存在する場合、通常モンスターとして扱う。\nフィールド上に表側表示で存在するこのカードを通常召喚扱いとして再度召喚する事で、このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●１ターンに１度、自分のメインフェイズ時に発動できる。\n相手ライフに１０００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"灼熱工の巨匠カエン",h:"しゃくねつこうのきょしょうかえん",e:"Kayenn, the Master Magma Blacksmith",ra:"N",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1200,de:200,tx:"自分のメインフェイズ時、墓地のこのカードをゲームから除外して発動できる。\n自分フィールド上の全ての「ラヴァル」と名のついたモンスターの攻撃力は４００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"灼熱ゾンビ",h:"しゃくねつぞんび",e:"Molten Zombie",ra:"N",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:1600,de:400,tx:"このカードが墓地からの特殊召喚に成功した時、自分はデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジャグラー",h:"じゃぐらー",e:"Genin",ra:"R",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:600,de:900,tx:"手品のような魔法で敵を倒す。\nハトを出して攻撃もする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"邪剣男爵",h:"じゃけんだんしゃく",e:"Baron of the Fiend Sword",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1550,de:800,tx:"怨念のこもった剣をあやつる貴族。\n執念深く獲物を追いつめる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジャジメント・ザ・ハンド",h:"じゃじめんと・ざ・はんど",e:"The Judgement Hand",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1400,de:700,tx:"神が宿った手で最後の審判を下し、激しい攻撃を加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"邪神官チラム・サバク",h:"じゃしんかんちらむ・さばく",e:"Wicked Acolyte Chilam Sabak",ra:"R",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:2500,de:0,tx:"「邪神官チラム・サバク」の②の効果は１ターンに１度しか使用できない。\n①：自分の手札が５枚以上の場合、このカードはリリースなしで召喚できる。\n②：このカードが戦闘で破壊され墓地へ送られた時に発動できる。\nこのカードを墓地から守備表示で特殊召喚する。\nこの効果で特殊召喚したこのカードはチューナーとして扱う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジャスティス・ブリンガー",h:"じゃすてぃす・ぶりんがー",e:"Justice Bringer",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"相手フィールド上に存在する特殊召喚したモンスターの効果が発動した時に発動する事ができる。\nその発動を無効にする。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジャッカルの聖戦士",h:"じゃっかるのせいせんし",e:"Mystical Knight of Jackal",ra:"SR",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:2700,de:1200,tx:"このカードが相手フィールド上モンスター１体を戦闘によって破壊し相手の墓地に送った時、そのカードを相手のデッキの一番上に戻す事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジャッカルの霊騎士",h:"じゃっかるのれいきし",e:"Ghost Knight of Jackal",ra:"R",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:1600,tx:"このカードが戦闘によって破壊し墓地に送った相手モンスター１体を、自分フィールド上に表側守備表示で特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジャック・ワイバーン",h:"じゃっく・わいばーん",e:"Jack Wyvern",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1800,de:0,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：自分フィールドの機械族モンスター１体とこのカードを除外し、自分の墓地の闇属性モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジャックス・ナイト",h:"じゃっくす・ないと",e:"Jack's Knight",ra:"N",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1900,de:1000,tx:"あらゆる剣術に精通した戦士。\nとても正義感が強く、弱き者を守るために闘っている。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"機界騎士アヴラム",h:"じゃっくないつあう゛らむ",e:"Mekk-Knight Avram",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"サイキック族",mo:["通常"],ma:"",tp:"",at:2000,de:0,tx:"星の光を守護する勇者\n幻界せし闇を討つべく\n選ばれしものに力を託す。\n\n\n星の杯に継がれし意志は新たな鍵となり\n闇を絶ち切る剣とならん。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジャッジ・マン",h:"じゃっじ・まん",e:"Judge Man",ra:"SR",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:2200,de:1500,tx:"勝ち負けのない勝負が嫌いな戦士。\nこん棒の攻撃は強いぞ！",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"裁きの龍",h:"じゃっじめんと・どらぐーん",e:"Judgment Dragon",ra:"UR",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3000,de:2600,tx:"このカードは通常召喚できない。\n自分の墓地の「ライトロード」モンスターが４種類以上の場合のみ特殊召喚できる。\n①：１０００LPを払って発動できる。\nこのカード以外のフィールドのカードを全て破壊する。\n②：自分エンドフェイズに発動する。\n自分のデッキの上からカードを４枚墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"邪帝ガイウス",h:"じゃていがいうす",e:"Caius the Shadow Monarch",ra:"UR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2400,de:1000,tx:"①：このカードがアドバンス召喚に成功した場合、フィールドのカード１枚を対象として発動する。\nそのカードを除外し、除外したカードが闇属性モンスターカードだった場合、相手に１０００ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"邪帝家臣ルキウス",h:"じゃていかしんるきうす",e:"Lucius the Shadow Vassal",ra:"SR",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:800,de:1000,tx:"「邪帝家臣ルキウス」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の墓地のレベル５以上のモンスター１体を除外して発動できる。\nこのカードを手札から特殊召喚する。\nこのターン、自分はエクストラデッキからモンスターを特殊召喚できない。\n②：このカードがアドバンス召喚のためにリリースされた場合に発動できる。\n相手フィールドにセットされたカードを全て確認する。\nこの効果の発動に対して、相手は魔法・罠・モンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シャドウ・ヴァンパイア",h:"しゃどう・う゛ぁんぱいあ",e:"Shadow Vampire",ra:"R",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:2000,de:0,tx:"このカードをX召喚の素材とする場合、闇属性モンスターのX召喚にしか使用できない。\n①：このカードが召喚に成功した時に発動できる。\n手札・デッキから「シャドウ・ヴァンパイア」以外の闇属性の「ヴァンパイア」モンスター１体を特殊召喚する。\nこの効果で特殊召喚に成功したターン、そのモンスター以外の自分のモンスターは攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シャドウ・グール",h:"しゃどう・ぐーる",e:"Shadow Ghoul",ra:"N",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1600,de:1300,tx:"自分の墓地に存在するモンスター１体につき、このカードの攻撃力は１００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シャドウ・ダイバー",h:"しゃどう・だいばー",e:"Shadow Delver",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["デュアル","効果"],ma:"",tp:"",at:1500,de:500,tx:"このカードは墓地またはフィールド上に表側表示で存在する場合、通常モンスターとして扱う。\nフィールド上に表側表示で存在するこのカードを通常召喚扱いとして再度召喚する事で、このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●自分フィールド上に表側表示で存在する闇属性・レベル４以下モンスター１体を選択して発動する。\n選択したモンスターはこのターン相手プレイヤーに直接攻撃する事ができる。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シャドウ・ファイター",h:"しゃどう・ふぁいたー",e:"Kageningen",ra:"N",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:800,de:600,tx:"実体と影に分かれて襲ってくる。\n油断すると挟まれるぞ。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シャドウ・リチュア",h:"しゃどう・りちゅあ",e:"Gishki Shadow",ra:"SR",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:1200,de:1000,tx:"水属性の儀式モンスターを特殊召喚する場合、このカード１枚で儀式召喚のためのリリースとして使用できる。\nまた、手札からこのカードを捨てて発動できる。\nデッキから「リチュア」と名のついた儀式魔法カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シャドウナイトデーモン",h:"しゃどうないとでーもん",e:"Shadowknight Archfiend",ra:"R",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2000,de:1600,tx:"このカードのコントローラーは自分のスタンバイフェイズ毎に９００ライフポイントを払う。\nこのカードが相手のコントロールするカードの効果の対象になり、その処理を行う時にサイコロを１回振る。\n３が出た場合、その効果を無効にし破壊する。\nこのカードが相手プレイヤーに与えるダメージは半分になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シャブティのお守り",h:"しゃぶてぃのおまもり",e:"Charm of Shabti",ra:"N",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"手札からこのカードを捨てる。\nエンドフェイズまで、自分フィールド上の「墓守の」という名のついたモンスターカードが受ける戦闘ダメージを０にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジャベリンビートル",h:"じゃべりんびーとる",e:"Javelin Beetle",ra:"N",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"昆虫族",mo:["儀式"],ma:"",tp:"",at:2450,de:2550,tx:"「ジャベリンビートルの契約」により降臨。\n場か手札から、星の数が合計８個以上になるようカードを生け贄に捧げなければならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シャベル・クラッシャー",h:"しゃべる・くらっしゃー",e:"Shovel Crusher",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:900,de:1200,tx:"何でも破壊してしまう、両手の大きなシャベルには要注意。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"邪龍アナンタ",h:"じゃりゅうあなんた",e:"Evil Dragon Ananta",ra:"UR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"爬虫類族",mo:["特殊召喚","効果"],ma:"",tp:"",at:"?",de:"?",tx:"このカードは通常召喚できない。\n自分のフィールド上及び墓地に存在する爬虫類族モンスターを全てゲームから除外する事でのみ特殊召喚する事ができる。\nこのカードの攻撃力・守備力は、特殊召喚時にゲームから除外した爬虫類族モンスターの数×６００ポイントになる。\nこのカードが自分フィールド上に存在する限り、自分ターンのエンドフェイズ時にフィールド上のカード１枚を破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジャンク・アーチャー",h:"じゃんく・あーちゃー",e:"Junk Archer",ra:"SR",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2300,de:2000,tx:"「ジャンク・シンクロン」＋チューナー以外のモンスター１体以上\n１ターンに１度、相手フィールド上に存在するモンスター１体を選択して発動する事ができる。\n選択したモンスターをゲームから除外する。\nこの効果で除外したモンスターは、このターンのエンドフェイズ時に同じ表示形式で相手フィールド上に戻る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジャンク・アンカー",h:"じゃんく・あんかー",e:"Junk Anchor",ra:"R",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"戦士族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"このカードは「シンクロン」チューナーの代わりとしてS素材にできる。\n①：１ターンに１度、手札を１枚捨て、チューナー以外の自分の墓地の「ジャンク」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚し、そのモンスターとこのカードのみを素材として、「シンクロン」チューナーを素材とするSモンスター１体をS召喚する。\nその時のS素材モンスターは墓地へは行かず除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジャンク・ウォリアー",h:"じゃんく・うぉりあー",e:"Junk Warrior",ra:"UR",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2300,de:1300,tx:"「ジャンク・シンクロン」＋チューナー以外のモンスター１体以上\n①：このカードがS召喚に成功した場合に発動する。\nこのカードの攻撃力は、自分フィールドのレベル２以下のモンスターの攻撃力の合計分アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジャンク・ガードナー",h:"じゃんく・がーどなー",e:"Junk Gardna",ra:"UR",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:1400,de:2600,tx:"「ジャンク・シンクロン」＋チューナー以外のモンスター１体以上\n１ターンに１度、相手フィールド上に存在するモンスター１体を選択し、表示形式を変更する事ができる。\nこの効果は相手ターンでも発動する事ができる。\nまた、このカードがフィールド上から墓地へ送られた場合、フィールド上に存在するモンスター１体を選択し、表示形式を変更する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジャンク・コレクター",h:"じゃんく・これくたー",e:"Junk Collector",ra:"R",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:2200,tx:"フィールド上に表側表示で存在するこのカードと自分の墓地に存在する通常罠カード１枚をゲームから除外して発動する。\nこのカードの効果は、この効果を発動するためにゲームから除外した通常罠カードの効果と同じになる。\nこの効果は相手ターンでも発動する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジャンク・コンバーター",h:"じゃんく・こんばーたー",e:"Junk Converter",ra:"SR",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:400,de:200,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札からこのカードとチューナー１体を捨てて発動できる。\nデッキから「シンクロン」モンスター１体を手札に加える。\n②：このカードがS素材として墓地へ送られた場合、自分の墓地のチューナー１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターは、このターン効果を発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジャンク・サーバント",h:"じゃんく・さーばんと",e:"Junk Servant",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"自分フィールド上に「ジャンク」と名のついたモンスターが表側表示で存在する場合、このカードは手札から特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジャンク・ジャイアント",h:"じゃんく・じゃいあんと",e:"Junk Giant",ra:"N",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2000,de:2400,tx:"①：相手フィールドにレベル５以上のモンスターが存在する場合、このカードは手札から特殊召喚できる。\n②：このカードを素材としたS召喚は無効化されず、そのS召喚成功時に相手は魔法・罠・モンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジャンク・シンクロン",h:"じゃんく・しんくろん",e:"Junk Synchron",ra:"UR",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"戦士族",mo:["チューナー","効果"],ma:"",tp:"",at:1300,de:500,tx:"①：このカードが召喚に成功した時、自分の墓地のレベル２以下のモンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジャンク・チェンジャー",h:"じゃんく・ちぇんじゃー",e:"Junk Changer",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["チューナー","効果"],ma:"",tp:"",at:1500,de:900,tx:"「ジャンク・チェンジャー」の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合、フィールドの「ジャンク」モンスター１体を対象として以下の効果から１つを選択して発動できる。\n\n●対象のモンスターのレベルを１つ上げる。\n\n●対象のモンスターのレベルを１つ下げる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジャンク・デストロイヤー",h:"じゃんく・ですとろいやー",e:"Junk Destroyer",ra:"UR",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2600,de:2500,tx:"「ジャンク・シンクロン」＋チューナー以外のモンスター１体以上\n①：このカードがS召喚に成功した時、そのS素材としたモンスターの内、チューナー以外のモンスターの数までフィールドのカードを対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジャンク・バーサーカー",h:"じゃんく・ばーさーかー",e:"Junk Berserker",ra:"UR",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2700,de:1800,tx:"「ジャンク・シンクロン」＋チューナー以外のモンスター１体以上\n自分の墓地に存在する「ジャンク」と名のついたモンスター１体をゲームから除外し、相手フィールド上に表側表示で存在するモンスター１体を選択して発動する。\n選択した相手モンスターの攻撃力は、除外したモンスターの攻撃力分ダウンする。\nまた、このカードが守備表示のモンスターを攻撃した場合、ダメージ計算を行わずそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジャンク・フォアード",h:"じゃんく・ふぉあーど",e:"Junk Forward",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:900,de:1500,tx:"自分フィールド上にモンスターが存在しない場合、このカードは手札から特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジャンク・ブレイカー",h:"じゃんく・ぶれいかー",e:"Junk Breaker",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"①：このカードが召喚に成功したターンの自分メインフェイズに、このカードをリリースして発動できる。\nフィールドの全ての表側表示モンスターの効果をターン終了時まで無効にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジャンク・ブレーダー",h:"じゃんく・ぶれーだー",e:"Junk Blader",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"自分の墓地に存在する「ジャンク」と名のついたモンスター１体をゲームから除外する事で、このカードの攻撃力はエンドフェイズ時まで４００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジャンクリボー",h:"じゃんくりぼー",e:"Junkuriboh",ra:"N",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:300,de:200,tx:"①：自分にダメージを与える魔法・罠・モンスターの効果を相手が発動した時、自分の手札・フィールドのこのカードを墓地へ送って発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"雀姉妹",h:"じゃんしまつ",e:"Mahjong Munia Maidens",ra:"SR",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"鳥獣族",mo:["リバース","効果"],ma:"",tp:"",at:200,de:2000,tx:"①：自分メインフェイズに発動できる。\nこのカードを裏側守備表示にする（１ターンに１度のみ）。\n②：このカードがリバースした場合に発動できる。\n自分はデッキから１枚ドローし、その後手札を１枚選んで捨てる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"終焉の悪魔デミス",h:"しゅうえんのあくまでみす",e:"Demise, Agent of Armageddon",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["儀式","効果"],ma:"",tp:"",at:1800,de:1000,tx:"「エンドレス・オブ・ザ・ワールド」により降臨。\n①：このカードのカード名は、手札・フィールドに存在する限り「終焉の王デミス」として扱う。\n②：このカードが儀式召喚に成功した場合に発動する。\nフィールドの表側表示モンスター１体を選んで破壊する。\n③：このカードが墓地へ送られた場合、自分フィールドの儀式モンスター１体を対象として発動できる。\nそのモンスターが自分フィールドに表側表示で存在する限り、自分の儀式モンスターの効果の発動に対して相手はカードの効果を発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"終焉の王デミス",h:"しゅうえんのおうでみす",e:"Demise, King of Armageddon",ra:"UR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"悪魔族",mo:["儀式","効果"],ma:"",tp:"",at:2400,de:2000,tx:"「エンド・オブ・ザ・ワールド」により降臨。\n①：２０００LPを払って発動できる。\nフィールドの他のカードを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"終焉の守護者アドレウス",h:"しゅうえんのしゅごしゃあどれうす",e:"Adreus, Keeper of Armageddon",ra:"SR",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"悪魔族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2600,de:1700,tx:"レベル５モンスター×２\n１ターンに１度、このカードのエクシーズ素材を１つ取り除き、相手フィールド上に表側表示で存在するカード１枚を選択して発動できる。\n選択したカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"重機王ドボク・ザーク",h:"じゅうきおうどぼく・ざーく",e:"Digvorzhak, King of Heavy Industry",ra:"R",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:3200,de:2000,tx:"レベル５モンスター×３\n１ターンに１度、このカードのエクシーズ素材を１つ取り除いて発動できる。\n相手のデッキの上からカードを３枚墓地へ送る。\nこの効果で墓地へ送ったカードの中にモンスターカードがあった場合、その数まで相手フィールド上のカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"獣人アレス",h:"じゅうじんあれす",e:"Man Beast of Ares",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:500,de:500,tx:"このカードがフィールド上に表側表示で存在する限り、チェーンが発生する度にこのカードの攻撃力は５００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"獣神ヴァルカン",h:"じゅうしんう゛ぁるかん",e:"Vulcan the Divine",ra:"SR",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"獣戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2000,de:1600,tx:"チューナー＋チューナー以外のモンスター１体以上\n「獣神ヴァルカン」の効果は１ターンに１度しか使用できない。\n①：このカードがS召喚に成功した場合、自分及び相手フィールドの表側表示のカードを１枚ずつ対象として発動する。\nその自分及び相手の表側表示のカードを手札に戻す。\nこのターン、自分はこの効果で手札に戻したカード及びそれらの同名カードの効果を発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"獣神機王バルバロスUr",h:"じゅうしんきおうばるばろすうる",e:"Beast Machine King Barbaros Ür",ra:"UR",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:3800,de:1200,tx:"①：このカードは、自分の手札・フィールド・墓地から獣戦士族モンスターと機械族モンスターを１体ずつ除外して手札から特殊召喚できる。\n②：このカードの戦闘で相手が受ける戦闘ダメージは０になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"重装機甲 パンツァードラゴン",h:"じゅうそうきこう　ぱんつぁーどらごん",e:"Panzer Dragon",ra:"SR",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:1000,de:2600,tx:"機械族モンスター＋ドラゴン族モンスター\n①：このカードが破壊され墓地へ送られた場合、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"重装武者－ベン・ケイ",h:"じゅうそうむしゃ－べん・けい",e:"Armed Samurai - Ben Kei",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:500,de:800,tx:"このカードは通常の攻撃に加えて、このカードに装備された装備カードの数だけ、１度のバトルフェイズ中に攻撃する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シューティング・スター・ドラゴン",h:"しゅーてぃんぐ・すたー・どらごん",e:"Shooting Star Dragon",ra:"UR",t:"monster",a:"風",l:10,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:3300,de:2500,tx:"Sモンスターのチューナー＋「スターダスト・ドラゴン」\n①：１ターンに１度、発動できる。\n自分のデッキの上から５枚めくってデッキに戻す。\nこのターンこのカードはめくった中のチューナーの数まで攻撃できる。\n②：１ターンに１度、フィールドのカードを破壊する効果の発動時に発動できる。\nその効果を無効にし破壊する。\n③：１ターンに１度、相手の攻撃宣言時に攻撃モンスターを対象として発動できる。\nフィールドのこのカードを除外し、その攻撃を無効にする。\n④：この③の効果で除外されたターンのエンドフェイズに発動する。\nこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"充電池メン",h:"じゅうでんちめん",e:"Batteryman Charger",ra:"R",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:1800,de:1200,tx:"このカードが召喚に成功した時、手札・デッキから「充電池メン」以外の「電池メン」と名のついたモンスター１体を特殊召喚できる。\nこのカードの攻撃力・守備力は、自分フィールド上の雷族モンスターの数×３００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"執念深き老魔術師",h:"しゅうねんぶかきろうまじゅつし",e:"Old Vindictive Magician",ra:"R",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["リバース","効果"],ma:"",tp:"",at:450,de:600,tx:"①：このカードがリバースした場合、相手フィールドのモンスター１体を対象として発動する。\nその相手モンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"重爆撃禽 ボム・フェネクス",h:"じゅうばくげきん　ぼむ・ふぇねくす",e:"Blaze Fenix, the Burning Bombardment Bird",ra:"R",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"炎族",mo:["融合","効果"],ma:"",tp:"",at:2800,de:2300,tx:"機械族モンスター＋炎族モンスター\n自分のメインフェイズ時、フィールド上に存在するカード１枚につき３００ポイントダメージを相手ライフに与える事ができる。\nこの効果を発動するターンこのカードは攻撃する事ができない。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"呪眼の眷属 カトブレパス",h:"じゅがんのけんぞく かとぶれぱす",e:"Catoblepas, Familiar of the Evil Eye",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:600,de:1900,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの「呪眼」魔法・罠カード１枚を対象として発動できる。\n次のターンの終了時まで、そのカードは１度だけ相手の効果では破壊されない。\n②：このカードが墓地に存在し、自分フィールドに「呪眼の眷属 カトブレパス」以外の「呪眼」モンスターが存在する場合に発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"呪眼の眷属 バジリウス",h:"じゅがんのけんぞく ばじりうす",e:"Basilius, Familiar of the Evil Eye",ra:"R",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:400,de:2000,tx:"このカード名の、①の方法による特殊召喚は１ターンに１度しかできず、②の効果は１ターンに１度しか使用できない。\n①：自分フィールドに「呪眼」モンスターが存在する場合、このカードは手札から特殊召喚できる。\n②：自分メインフェイズに発動できる。\nデッキから「呪眼」魔法・罠カード１枚を墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"呪眼の死徒 メドゥサ",h:"じゅがんのしと めどぅさ",e:"Medusa, Watcher of the Evil Eye",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1400,de:1600,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時、「呪眼の死徒 メドゥサ」以外の自分の墓地の「呪眼」カード１枚を対象として発動できる。\nそのカードを手札に加える。\n②：このカードが「セレンの呪眼」を装備している場合、相手の墓地のモンスター１体を対象として発動できる。\nそのモンスターを除外する。\nこの効果は相手ターンでも発動できる。\n③：このカードの②の効果を発動した場合、次のスタンバイフェイズに発動する。\n自分の墓地のカード１枚を選んで除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"熟練の青魔道士",h:"じゅくれんのあおまどうし",e:"Skilled Blue Magician",ra:"UR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1800,de:1800,tx:"①：このカードがモンスターゾーンに存在する限り、自分または相手が魔法カードを発動する度に、このカードに魔力カウンターを１つ置く（最大３つまで）。\n②：魔力カウンターが３つ置かれているこのカードをリリースして発動できる。\n自分の手札・デッキ・墓地から「暗黒騎士ガイア」モンスター１体を選んで特殊召喚する。\n③：自分メインフェイズに墓地のこのカードを除外し、自分フィールドの魔力カウンターを置く事ができるカード１枚を対象として発動できる。\nそのカードに魔力カウンターを１つ置く。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"熟練の赤魔術士",h:"じゅくれんのあかまじゅつし",e:"Skilled Red Magician",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1600,de:1600,tx:"①：このカードがモンスターゾーンに存在する限り、自分または相手が魔法カードを発動する度に、このカードに魔力カウンターを１つ置く（最大３つまで）。\n②：魔力カウンターが３つ置かれているこのカードをリリースして発動できる。\n自分の手札・デッキ・墓地から「デーモン」モンスター１体を選んで特殊召喚する。\n③：自分メインフェイズに墓地のこのカードを除外し、自分フィールドの魔力カウンターを置く事ができるカード１枚を対象として発動できる。\nそのカードに魔力カウンターを１つ置く。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"熟練の黒魔術師",h:"じゅくれんのくろまじゅつし",e:"Skilled Dark Magician",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1900,de:1700,tx:"①：このカードがモンスターゾーンに存在する限り、自分または相手が魔法カードを発動する度に、このカードに魔力カウンターを１つ置く（最大３つまで）。\n②：魔力カウンターが３つ置かれているこのカードをリリースして発動できる。\n自分の手札・デッキ・墓地から「ブラック・マジシャン」１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"熟練の白魔導師",h:"じゅくれんのしろまどうし",e:"Skilled White Magician",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1700,de:1900,tx:"このカードがフィールド上に表側表示で存在する限り、自分または相手が魔法カードを発動する度に、このカードに魔力カウンターを１つ置く（最大３つまで）。\nまた、魔力カウンターが３つ乗っているこのカードをリリースして発動できる。\n自分の手札・デッキ・墓地から「バスター・ブレイダー」１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"守護神エクゾード",h:"しゅごしんえくぞーど",e:"Exxod, Master of The Guard",ra:"R",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"岩石族",mo:["特殊召喚","効果"],ma:"",tp:"",at:0,de:4000,tx:"このカードは通常召喚できない。\n自分フィールド上に存在する「スフィンクス」と名のついたモンスター１体をリリースした場合のみ特殊召喚する事ができる。\nこのカードがフィールド上に表側表示で存在する限り、地属性モンスターが反転召喚に成功した時、相手ライフに１０００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"守護神官マハード",h:"しゅごしんかんまはーど",e:"Palladium Oracle Mahad",ra:"UR",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2500,de:2100,tx:"①：このカードをドローした時、このカードを相手に見せて発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが闇属性モンスターと戦闘を行うダメージステップの間、このカードの攻撃力は倍になる。\n③：このカードが戦闘・効果で破壊された場合に発動できる。\n自分の手札・デッキ・墓地から「ブラック・マジシャン」１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"守護竜アンドレイク",h:"しゅごりゅうあんどれいく",e:"Guardragon Andrake",ra:"SR",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2000,de:600,tx:"このカードは通常召喚できず、カードの効果でのみ特殊召喚できる。\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが手札・デッキからの特殊召喚に成功した場合に発動できる。\nこのカードの元々の攻撃力・守備力は次のターンの終了時まで倍になる。\n②：このカードが墓地からの特殊召喚に成功した場合、または除外されているこのカードが特殊召喚に成功した場合、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"守護竜ガルミデス",h:"しゅごりゅうがるみです",e:"Guardragon Garmides",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：通常モンスターが自分の墓地へ送られた場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが墓地に存在する場合、手札からドラゴン族モンスター１体を墓地へ送って発動できる。\nこのカードを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"守護竜プロミネシス",h:"しゅごりゅうぷろみねしす",e:"Guardragon Promineses",ra:"N",t:"monster",a:"炎",l:1,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:500,de:200,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：手札・フィールドのこのカードを墓地へ送り、自分フィールドのドラゴン族モンスター１体を対象として発動できる。\nそのモンスターの攻撃力・守備力は相手ターン終了時まで５００アップする。\n②：このカードが墓地に存在し、通常モンスターが自分の墓地へ送られた場合に発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"守護竜ユスティア",h:"しゅごりゅうゆすてぃあ",e:"Guardragon Justicia",ra:"N",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"ドラゴン族",mo:["チューナー","通常"],ma:"",tp:"",at:0,de:2100,tx:"星鍵は流れぬ涙を流し、天命は果たされる。\n\n神の門は嘶き崩れ、蛇は守人の夢幻を喰らう。\n\n其の魂は始まりの地に、彼の魂は終極の地に。\n\n\n――此処に神獄たる星は闢かれん。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"守護霊アイリン",h:"しゅごれいあいりん",e:"Protective Soul Ailin",ra:"R",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"天使族",mo:["ユニオン","効果"],ma:"",tp:"",at:0,de:0,tx:"１ターンに１度だけ自分のメインフェイズに装備カード扱いとして自分の「不屈闘士レイレイ」に装備、または装備を解除して表側攻撃表示で特殊召喚する事ができる。\nこの効果で装備カード扱いになっている時のみ、装備モンスターの表示形式を１ターンに１度だけ変更する事ができる。\n（１体のモンスターが装備できるユニオンは１枚まで。\n装備モンスターが戦闘によって破壊される場合は、代わりにこのカードを破壊する。\n）",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジュッテ・ナイト",h:"じゅって・ないと",e:"Jutte Fighter",ra:"SR",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"戦士族",mo:["チューナー","効果"],ma:"",tp:"",at:700,de:900,tx:"１ターンに１度、自分のメインフェイズ時に発動できる。\n相手フィールド上に表側攻撃表示で存在するモンスター１体を選択して表側守備表示にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"酒呑童子",h:"しゅてんどうじ",e:"Shutendoji",ra:"UR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1500,de:800,tx:"①：１ターンに１度、以下の効果から１つを選択して発動できる。\n\n●自分の墓地からアンデット族モンスター２体を除外して発動できる。\n自分はデッキから１枚ドローする。\n\n●除外されている自分のアンデット族モンスター１体を対象として発動できる。\nそのモンスターをデッキの一番上に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジュラック・アウロ",h:"じゅらっく・あうろ",e:"Jurrac Aeolo",ra:"R",t:"monster",a:"炎",l:1,ps:"",pe:"",tr:"恐竜族",mo:["チューナー","効果"],ma:"",tp:"",at:200,de:200,tx:"①：このカードをリリースし、「ジュラック・アウロ」以外の自分の墓地のレベル４以下の「ジュラック」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジュラック・イグアノン",h:"じゅらっく・いぐあのん",e:"Jurrac Iguanon",ra:"N",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1700,de:700,tx:"このカードが戦闘によって相手モンスターを破壊した場合、相手フィールド上にセットされたカード１枚を選択して持ち主の手札に戻す事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジュラック・ヴェルヒプト",h:"じゅらっく・う゛ぇるひぷと",e:"Jurrac Velphito",ra:"N",t:"monster",a:"炎",l:5,ps:"",pe:"",tr:"恐竜族",mo:["シンクロ","効果"],ma:"",tp:"",at:"?",de:"?",tx:"チューナー＋チューナー以外の恐竜族モンスター１体以上\nこのカードの攻撃力・守備力は、このカードのシンクロ素材としたモンスターの元々の攻撃力を合計した数値になる。\nまた、このカードが裏側守備表示のモンスターを攻撃した場合、ダメージ計算を行わず裏側守備表示のままそのモンスターを破壊できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジュラック・ヴェロー",h:"じゅらっく・う゛ぇろー",e:"Jurrac Velo",ra:"R",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"自分フィールド上に表側攻撃表示で存在するこのカードが戦闘によって破壊され墓地へ送られた時、デッキから攻撃力１７００以下の「ジュラック」と名のついたモンスター１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジュラック・ガリム",h:"じゅらっく・がりむ",e:"Jurrac Gallim",ra:"N",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"恐竜族",mo:["チューナー","効果"],ma:"",tp:"",at:1200,de:0,tx:"このカードが戦闘によって破壊され墓地へ送られた時に発動する。\n相手は手札を１枚捨ててこのカードの効果を無効にできる。\n捨てなかった場合、このカードを破壊したモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジュラック・ギガノト",h:"じゅらっく・ぎがのと",e:"Jurrac Giganoto",ra:"R",t:"monster",a:"炎",l:7,ps:"",pe:"",tr:"恐竜族",mo:["シンクロ","効果"],ma:"",tp:"",at:2100,de:1800,tx:"チューナー＋チューナー以外の恐竜族モンスター１体以上\nこのカードがフィールド上に表側表示で存在する限り、自分フィールド上の「ジュラック」と名のついたモンスターの攻撃力は、自分の墓地の「ジュラック」と名のついたモンスターの数×２００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジュラック・グアイバ",h:"じゅらっく・ぐあいば",e:"Jurrac Guaiba",ra:"SR",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1700,de:400,tx:"このカードが戦闘によって相手モンスターを破壊した場合、デッキから攻撃力１７００以下の「ジュラック」と名のついたモンスター１体を特殊召喚できる。\nこの効果で特殊召喚したモンスターは、このターン攻撃宣言できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジュラック・スタウリコ",h:"じゅらっく・すたうりこ",e:"Jurrac Stauriko",ra:"R",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:500,de:400,tx:"このカードが戦闘によって破壊された場合、自分フィールド上に「ジュラックトークン」（恐竜族・炎・星１・攻／守０）２体を守備表示で特殊召喚する。\nこのトークンは「ジュラック」と名のついたモンスター以外のアドバンス召喚のためにはリリースできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジュラック・スピノス",h:"じゅらっく・すぴのす",e:"Jurrac Spinos",ra:"R",t:"monster",a:"炎",l:7,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:2600,de:1700,tx:"このカードが戦闘によって相手モンスターを破壊し墓地へ送った時、相手フィールド上に「スピノストークン」（恐竜族・炎・星１・攻３００／守０）１体を攻撃表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジュラック・タイタン",h:"じゅらっく・たいたん",e:"Jurrac Titano",ra:"SR",t:"monster",a:"炎",l:9,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:3000,de:2800,tx:"このカードは特殊召喚できない。\nこのカードはフィールド上に表側表示で存在する限り、罠・効果モンスターの効果の対象にならない。\nまた、１ターンに１度、自分の墓地の攻撃力１７００以下の「ジュラック」と名のついたモンスター１体をゲームから除外して発動できる。\nこのカードの攻撃力はエンドフェイズ時まで１０００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジュラック・デイノ",h:"じゅらっく・でいの",e:"Jurrac Dino",ra:"N",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"恐竜族",mo:["チューナー","効果"],ma:"",tp:"",at:1700,de:800,tx:"このカードが戦闘によって相手モンスターを破壊したターンのエンドフェイズ時に１度だけ、自分フィールド上の「ジュラック」と名のついたモンスター１体をリリースして発動できる。\nデッキからカードを２枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジュラック・ティラヌス",h:"じゅらっく・てぃらぬす",e:"Jurrac Tyrannus",ra:"SR",t:"monster",a:"炎",l:7,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:2500,de:1400,tx:"自分のメインフェイズ時に、このカード以外の自分フィールド上の恐竜族モンスター１体をリリースして発動できる。\nこのカードの攻撃力は５００ポイントアップする。\nまた、このカードが戦闘によって相手モンスターを破壊し墓地へ送った時、このカードの攻撃力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジュラック・プティラ",h:"じゅらっく・ぷてぃら",e:"Jurrac Ptera",ra:"N",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:800,de:1500,tx:"このカードが攻撃されたダメージ計算後、攻撃モンスターを持ち主の手札に戻す。\nこのカードの守備力は、この効果で手札に戻したモンスターのレベル×１００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジュラック・プロトプス",h:"じゅらっく・ぷろとぷす",e:"Jurrac Protops",ra:"R",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1700,de:1200,tx:"このカードの攻撃力は相手フィールド上のモンスターの数×１００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジュラック・ヘレラ",h:"じゅらっく・へれら",e:"Jurrac Herra",ra:"R",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:2300,de:1500,tx:"「ジュラック・ヘレラ」以外の自分フィールド上に守備表示で存在する「ジュラック」と名のついたモンスターが戦闘によって破壊され墓地へ送られた時、このカードを手札または墓地から特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジュラック・メテオ",h:"じゅらっく・めてお",e:"Jurrac Meteor",ra:"R",t:"monster",a:"炎",l:10,ps:"",pe:"",tr:"恐竜族",mo:["シンクロ","効果"],ma:"",tp:"",at:2800,de:2000,tx:"「ジュラック」と名のついたチューナー＋チューナー以外の恐竜族モンスター２体以上\nこのカードがシンクロ召喚に成功した時、フィールド上のカードを全て破壊する。\nその後、自分の墓地からチューナー１体を選んで特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジュラック・モノロフ",h:"じゅらっく・ものろふ",e:"Jurrac Monoloph",ra:"N",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"恐竜族",mo:["チューナー","効果"],ma:"",tp:"",at:1500,de:1200,tx:"このカードは相手フィールド上の全てのモンスターに１回ずつ攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シュルブの魔導騎兵",h:"しゅるぶのまどうきへい",e:"Magical Cavalry of Cxulub",ra:"R",t:"monster",a:"地",l:4,ps:3,pe:"",tr:"恐竜族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1500,de:1800,tx:"①：このカードはモンスターゾーンに存在する限り、Pモンスター以外のモンスターが発動した効果を受けない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シュレツダー",h:"しゅれつだー",e:"Shreddder",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"手札から機械族モンスター１体を墓地へ送って発動する。\n墓地へ送ったモンスターのレベル以下の、相手フィールド上に表側表示で存在するモンスター１体を破壊する。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"俊炎星－ゾウセイ",h:"しゅんえんせい－ぞうせい",e:"Brotherhood of the Fire Fist - Elephant",ra:"UR",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:1800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合、自分フィールドの表側表示の「炎舞」魔法・罠カード１枚を墓地へ送って発動できる。\n手札から「俊炎星－ゾウセイ」以外の「炎星」モンスター１体を特殊召喚する。\n②：自分の墓地の「炎舞」魔法・罠カード１枚を対象として発動できる。\nそのカードをデッキに戻す。\nその後、デッキからレベル５以上の「炎星」モンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"盾航戦車ステゴサイバー",h:"じゅんこうせんしゃすてごさいばー",e:"Stegocyber",ra:"UR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1200,de:2400,tx:"「盾航戦車ステゴサイバー」の効果は１ターンに１度しか使用できない。\n①：このカードが墓地に存在し、相手モンスターが攻撃した場合、そのダメージ計算時に１０００LPを払って発動できる。\nこのカードを墓地から特殊召喚し、その戦闘で発生する自分への戦闘ダメージは０になる。\nこの効果で特殊召喚したこのカードはフィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"俊足なカバ バリキテリウム",h:"しゅんそくなかば　ばりきてりうむ",e:"Dynatherium",ra:"UR",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1600,de:600,tx:"このカード名の①の方法による特殊召喚は１ターンに１度しかできない。\n①：このカードは手札から特殊召喚できる。\n②：このカードの①の方法で特殊召喚に成功した場合に発動する。\n相手は、自分または相手の墓地からレベル４モンスター１体を選び、自身のフィールドに特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"俊足のギラザウルス",h:"しゅんそくのぎらざうるす",e:"Gilasaurus",ra:"SR",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1400,de:400,tx:"①：このカードは手札から特殊召喚できる。\n②：このカードの①の方法で特殊召喚に成功した場合に発動する。\n相手は自身の墓地のモンスター１体を選んで特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"瞬着ボマー",h:"しゅんちゃくぼまー",e:"Adhesive Explosive",ra:"SR",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"裏側守備表示のこのカードを相手モンスターが攻撃した場合、ダメージ計算を行わずこのカードは攻撃モンスターの装備カードになる。\n次の相手ターンのスタンバイフェイズ時、その装備モンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"傷炎星－ウルブショウ",h:"しょうえんせい－うるぶしょう",e:"Brotherhood of the Fire Fist - Wolf",ra:"N",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:0,de:1900,tx:"このカードがリバースした場合、デッキから「炎舞」と名のついた罠カード１枚を選んで自分フィールド上にセットできる。\n反転召喚によってリバースした場合、さらにデッキから「炎舞」と名のついた魔法カード１枚を選んで自分フィールド上にセットできる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"捷炎星－セイヴン",h:"しょうえんせい－せいう゛ん",e:"Brotherhood of the Fire Fist - Raven",ra:"R",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:200,de:1800,tx:"このカードがフィールド上から墓地へ送られた場合、デッキから「炎舞」と名のついた魔法カード１枚を選んで自分フィールド上にセットできる。\nまた、このカードがフィールド上に表側表示で存在する限り、自分フィールド上の「炎舞」と名のついた魔法・罠カードは相手のカードの効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"召喚師アレイスター",h:"しょうかんしあれいすたー",e:"Aleister the Invoker",ra:"UR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1000,de:1800,tx:"①：このカードを手札から墓地へ送り、自分フィールドの融合モンスター１体を対象として発動できる。\nそのモンスターの攻撃力・守備力はターン終了時まで１０００アップする。\nこの効果は相手ターンでも発動できる。\n②：このカードが召喚・リバースした場合に発動できる。\nデッキから「召喚魔術」１枚を手札に加える。",li:3,lk:[],ta:[],al:[],ocg:true},
  {n:"召喚師セームベル",h:"しょうかんしせーむべる",e:"Saambell the Summoner",ra:"UR",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:600,de:400,tx:"自分のメインフェイズ時、このカードと同じレベルのモンスター１体を手札から特殊召喚する事ができる。\nこの効果はこのカードがフィールド上に表側表示で存在する限り１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"召喚獣カリギュラ",h:"しょうかんじゅうかりぎゅら",e:"Invoked Caliga",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"獣族",mo:["融合","効果"],ma:"",tp:"",at:1000,de:1800,tx:"「召喚師アレイスター」＋闇属性モンスター\n①：このカードがモンスターゾーンに存在する限り、その間はお互いに、それぞれ１ターンに１度しかモンスターの効果を発動できず、それぞれバトルフェイズにモンスター１体でしか攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"召喚獣コキュートス",h:"しょうかんじゅうこきゅーとす",e:"Invoked Cocytus",ra:"R",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:1800,de:2900,tx:"「召喚師アレイスター」＋水属性モンスター\n①：このカードは相手の効果の対象にならず、相手の効果では破壊されない。\n②：このカードは表側守備表示のままで攻撃できる。\nその場合、攻撃力の数値を適用してダメージ計算を行う。",li:0,lk:[],ta:[],al:[],ocg:true},
  {n:"召喚獣プルガトリオ",h:"しょうかんじゅうぷるがとりお",e:"Invoked Purgatrio",ra:"UR",t:"monster",a:"炎",l:7,ps:"",pe:"",tr:"悪魔族",mo:["融合","効果"],ma:"",tp:"",at:2300,de:2000,tx:"「召喚師アレイスター」＋炎属性モンスター\n①：このカードの攻撃力は、相手フィールドのカードの数×２００アップする。\n②：このカードは相手モンスター全てに１回ずつ攻撃でき、守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"召喚獣メガラニカ",h:"しょうかんじゅうめがらにか",e:"Invoked Magellanica",ra:"SR",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"岩石族",mo:["融合"],ma:"",tp:"",at:3000,de:3300,tx:"「召喚師アレイスター」＋地属性モンスター",li:2,lk:[],ta:[],al:[],ocg:true},
  {n:"召喚師ライズベルト",h:"しょうかんしらいずべると",e:"Risebell the Summoner",ra:"R",t:"monster",a:"風",l:3,ps:2,pe:"①：１ターンに１度、フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターのレベルを１つ上げる。",tr:"サイキック族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:800,de:800,tx:"妹セームベルをとても大事に想っている、心優しき兄ライズベルト。\n昼下がりの午後に妹と一緒に魔術書を読む時間は毎日の日課になっており、そんな二人の仲睦まじい様子に周囲の人々は自然と心が癒されてしまう。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"召喚神エクゾディア",h:"しょうかんしんえくぞでぃあ",e:"The Legendary Exodia Incarnate",ra:"SR",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"魔法使い族",mo:["特殊召喚","効果"],ma:"",tp:"",at:"?",de:0,tx:"このカードは通常召喚できない。\n自分フィールドの「封印されし」モンスター１体をリリースした場合のみ特殊召喚できる。\n①：このカードの攻撃力は、自分の墓地の「封印されし」モンスターの数×１０００アップする。\n②：このカードは他のカードの効果を受けない。\n③：自分エンドフェイズに発動する。\n自分の墓地から「封印されし」モンスター１体を選んで手札に加える。\n④：このカードが戦闘で破壊され墓地へ送られた時に発動できる。\n手札の「封印されし」モンスターを任意の数だけ相手に見せ、見せた数だけ自分はデッキからドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"城塞クジラ",h:"じょうさいくじら",e:"Citadel Whale",ra:"SR",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:2350,de:2150,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが手札・墓地に存在する場合、自分フィールドの水属性モンスター２体をリリースして発動できる。\nこのカードを特殊召喚する。\n②：このカードが特殊召喚に成功した場合に発動できる。\nデッキから「潜海奇襲」１枚を選んで自分フィールドにセットする。\n③：１ターンに１度、自分フィールドの水属性モンスター１体のみを対象とする魔法・罠・モンスターの効果を相手が発動した時に発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"招神鳥シムルグ",h:"しょうしんちょうしむるぐ",e:"Simorgh, Bird of Bringing",ra:"UR",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時に発動できる。\nデッキから「招神鳥シムルグ」以外の「シムルグ」カード１枚を手札に加える。\n②：このカードが墓地に存在し、相手の魔法＆罠ゾーンにカードが存在しない場合に発動できる。\nこのカードを守備表示で特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。\nこの効果の発動後、ターン終了時まで自分は鳥獣族モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"勝利の導き手フレイヤ",h:"しょうりのみちびきてふれいや",e:"Freya, Spirit of Victory",ra:"R",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"自分フィールド上に「勝利の導き手フレイヤ」以外の天使族モンスターが表側表示で存在する場合、このカードを攻撃対象に選択する事はできない。\nこのカードが自分フィールド上に表側表示で存在する限り、自分フィールド上に表側表示で存在する天使族モンスターの攻撃力・守備力は４００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"女王親衛隊",h:"じょおうしんえいたい",e:"Queen's Bodyguard",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:1200,tx:"このカードが自分フィールド上に表側表示で存在する限り、自分フィールド上に存在する「魅惑の女王」と名のついたモンスターを攻撃対象に選択できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"女王の影武者",h:"じょおうのかげむしゃ",e:"Queen's Double",ra:"N",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:350,de:300,tx:"このカードは相手プレイヤーを直接攻撃する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジョーズマン",h:"じょーずまん",e:"Jawsman",ra:"N",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:2600,de:1600,tx:"このカードは特殊召喚できない。\nこのカードをアドバンス召喚する場合、リリースするモンスターは水属性モンスターでなければならない。\nこのカードの攻撃力は、このカード以外の自分フィールド上に表側表示で存在する水属性モンスター１体につき３００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"曙光の騎士",h:"しょこうのきし",e:"Dawn Knight",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1400,de:1200,tx:"フィールド上のこのカードが墓地へ送られた場合、デッキから光属性モンスター１体を墓地へ送る事ができる。\nまた、このカードがデッキから墓地へ送られた場合、自分の墓地の光属性モンスター１体を選択してデッキの一番上に置く。\n「曙光の騎士」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"除草獣",h:"じょそうじゅう",e:"Snyffus",ra:"SR",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"獣族",mo:["チューナー","効果"],ma:"",tp:"",at:100,de:100,tx:"１ターンに１度、自分フィールド上に存在する植物族モンスター１体をリリースする事で、相手フィールド上に表側表示で存在するカード１枚を選択して破壊する。\nまた、このカードが墓地に存在し、フィールド上に存在する植物族モンスターが破壊された時、このカードを墓地から特殊召喚する事ができる。\nこの効果で特殊召喚したこのカードは、フィールド上から離れた場合ゲームから除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"女帝カマキリ",h:"じょていかまきり",e:"Empress Mantis",ra:"N",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"昆虫族",mo:["通常"],ma:"",tp:"",at:2200,de:1400,tx:"集団性の高い巨大カマキリの女王。\n狩りや移動など、カマキリの大群は全て女王の指令により行動する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"地雷蜘蛛",h:"じらいぐも",e:"Jirai Gumo",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:2200,de:100,tx:"このカードの攻撃宣言時、コイントスで裏表を当てる。\n当たりの場合はそのまま攻撃する。\nハズレの場合は自分のライフポイントを半分失い攻撃する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"地雷獣",h:"じらいじゅう",e:"Tripwire Beast",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"雷族",mo:["通常"],ma:"",tp:"",at:1200,de:1300,tx:"強力な電磁波をバリバリとまわりに放出して攻撃する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"不知火の師範",h:"しらぬいのいくさのり",e:"Shiranui Swordmaster",ra:"N",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:600,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが墓地に存在し、自分フィールドに「不知火」モンスターが２種類以上存在する場合に発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードはフィールドから離れた場合に除外される。\n②：このカードが除外された場合、自分フィールドのアンデット族モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで６００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"不知火の隠者",h:"しらぬいのかげもの",e:"Shiranui Solitaire",ra:"SR",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:500,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドのアンデット族モンスター１体をリリースして発動できる。\nデッキから守備力０のアンデット族チューナー１体を特殊召喚する。\n②：このカードが除外された場合、「不知火の隠者」以外の除外されている自分の「不知火」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果の発動時にフィールドに「不知火流 転生の陣」が存在する場合、この効果の対象を２体にできる。",li:2,lk:[],ta:[],al:[],ocg:true},
  {n:"不知火の鍛師",h:"しらぬいのかなち",e:"Shiranui Smith",ra:"N",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1000,de:0,tx:"「不知火の鍛師」の①の効果は１ターンに１度しか使用できない。\n①：フィールドのこのカードがS素材として墓地へ送られた場合に発動できる。\nデッキから「不知火の鍛師」以外の「不知火」カード１枚を手札に加える。\n②：このカードが除外された場合に発動できる。\nこのターン、自分のアンデット族モンスターは戦闘では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"不知火の宮司",h:"しらぬいのみやづかさ",e:"Shiranui Spiritmaster",ra:"R",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1500,de:0,tx:"「不知火の宮司」の②の効果は１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時に発動できる。\n自分の手札・墓地から「不知火の宮司」以外の「不知火」モンスター１体を選んで特殊召喚する。\nこの効果で特殊召喚したモンスターは、フィールドから離れた場合に除外される。\n②：このカードが除外された場合、相手フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードを破壊する。",li:2,lk:[],ta:[],al:[],ocg:true},
  {n:"不知火の武士",h:"しらぬいのもののふ",e:"Shiranui Samurai",ra:"R",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1800,de:0,tx:"「不知火の武士」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の墓地のアンデット族モンスター１体を除外して発動できる。\nこのカードの攻撃力はターン終了時まで６００アップし、このターンこのカードがモンスターと戦闘を行った場合、そのモンスターはダメージ計算後に除外される。\nこの効果は相手ターンでも発動できる。\n②：このカードが除外された場合、「不知火の武士」以外の自分の墓地の「不知火」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"不知火の武部",h:"しらぬいのもののべ",e:"Shiranui Squire",ra:"SR",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1500,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時に発動できる。\n手札・デッキから「妖刀－不知火」モンスター１体を特殊召喚する。\nこの効果の発動後、ターン終了時まで自分はアンデット族モンスターしか特殊召喚できない。\n②：このカードが除外された場合に発動できる。\n自分はデッキから１枚ドローする。\nその後、手札を１枚選んで捨てる。",li:2,lk:[],ta:[],al:[],ocg:true},
  {n:"死霊操りしパペットマスター",h:"しりょうあやつりしぱぺっとますたー",e:"Puppet Master",ra:"SR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"①：このカードがアドバンス召喚に成功した時、２０００LPを払い、自分の墓地の悪魔族モンスター２体を対象として発動できる。\nその悪魔族モンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターはこのターン攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"死霊騎士デスカリバー・ナイト",h:"しりょうきしですかりばー・ないと",e:"Doomcaliber Knight",ra:"UR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1900,de:1800,tx:"このカードは特殊召喚できない。\n①：モンスターの効果が発動した時、このカードをリリースして発動する。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"死霊伯爵",h:"しりょうはくしゃく",e:"The Earl of Demise",ra:"R",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:2000,de:700,tx:"魔界の伯爵。\n紳士を装ってはいるが、本性は邪悪そのもの。\n人間だけでなく、低級悪魔たちにも恐れられている。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シルバー・ガジェット",h:"しるばー・がじぇっと",e:"Silver Gadget",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードが召喚・特殊召喚に成功した時に発動できる。\n手札から機械族・レベル４モンスター１体を特殊召喚する。\n②：このカードが戦闘・効果で破壊された場合に発動できる。\nデッキから「シルバー・ガジェット」以外のレベル４の「ガジェット」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シルバー・フォング",h:"しるばー・ふぉんぐ",e:"Silver Fang",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:1200,de:800,tx:"白銀に輝くオオカミ。\n見た目は美しいが、性格は凶暴。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"渋い忍者",h:"しるばーにんじゃ",e:"Senior Silver Ninja",ra:"N",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2300,de:2200,tx:"このカードがリバースした時、自分の手札・墓地から「渋い忍者」以外の「忍者」と名のついたモンスターを任意の数だけ裏側守備表示で特殊召喚する事ができる。\n「渋い忍者」はフィールド上に１体しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"白き霊龍",h:"しろきれいりゅう",e:"Dragon Spirit of White",ra:"SR",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2500,de:2000,tx:"このカード名はルール上「ブルーアイズ」カードとしても扱う。\n①：このカードは手札・墓地に存在する限り、通常モンスターとして扱う。\n②：このカードが召喚・特殊召喚に成功した時、相手フィールドの魔法・罠カード１枚を対象として発動できる。\nそのカードを除外する。\n③：相手フィールドにモンスターが存在する場合、このカードをリリースして発動できる。\n手札から「青眼の白龍」１体を特殊召喚する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"白魔導士ピケル",h:"しろまどうしぴける",e:"White Magician Pikeru",ra:"N",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1200,de:0,tx:"自分のスタンバイフェイズ時、自分フィールド上に存在するモンスターの数×４００ライフポイント回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"Sin スターダスト・ドラゴン",h:"しん すたーだすと・どらごん",e:"Malefic Stardust Dragon",ra:"UR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2500,de:2000,tx:"このカードは通常召喚できない。\nEXデッキから「スターダスト・ドラゴン」１体を除外した場合のみ特殊召喚できる。\n①：「Sin」モンスターはフィールドに１体しか表側表示で存在できない。\n②：このカードがモンスターゾーンに存在する限り、フィールドゾーンの表側表示のカードは効果では破壊されない。\n③：このカードがモンスターゾーンに存在する限り、他の自分のモンスターは攻撃宣言できない。\n④：フィールド魔法カードが表側表示で存在しない場合にこのカードは破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"Sin トゥルース・ドラゴン",h:"しん とぅるーす・どらごん",e:"Malefic Truth Dragon",ra:"R",t:"monster",a:"闇",l:12,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:5000,de:5000,tx:"このカードは通常召喚できず、このカードの効果でのみ特殊召喚できる。\n①：「Sin トゥルース・ドラゴン」以外の自分フィールドの表側表示の「Sin」モンスターが戦闘・効果で破壊された場合、LPを半分払って発動できる。\nこのカードを手札・墓地から特殊召喚する。\n②：「Sin」モンスターはフィールドに１体しか表側表示で存在できない。\n③：フィールド魔法カードが表側表示で存在しない場合にこのカードは破壊される。\n④：このカードが戦闘で相手モンスターを破壊した場合に発動する。\n相手フィールドの表側表示モンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"Sin パラドクス・ドラゴン",h:"しん ぱらどくす・どらごん",e:"Malefic Paradox Dragon",ra:"R",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:4000,de:4000,tx:"「Sin パラレルギア」＋チューナー以外の「Sin」モンスター１体\n①：「Sin パラドクス・ドラゴン」はフィールドに１体しか表側表示で存在できない。\n②：このカードがS召喚に成功した時、自分または相手の墓地のSモンスター１体を対象として発動できる。\nそのモンスターを自分フィールドに特殊召喚する。\n③：フィールドに「Sin World」が存在しない場合にこのカードは破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"Sin パラドクスギア",h:"しん ぱらどくすぎあ",e:"Malefic Paradox Gear",ra:"UR",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：フィールド魔法カードが表側表示で存在する場合、このカードをリリースして発動できる。\nデッキから「Sin パラレルギア」１体を特殊召喚する。\nその後、デッキから「Sin パラドクスギア」以外の「Sin」モンスター１体を手札に加える。\n②：自分の手札の「Sin」モンスターを、自身の方法で特殊召喚するためにモンスターを除外する場合、そのモンスターの代わりにフィールド・墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"Sin パラレルギア",h:"しん ぱられるぎあ",e:"Malefic Parallel Gear",ra:"R",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"このカードをS素材とする場合、他のS素材モンスターは手札の「Sin」モンスター１体でなければならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"Sin 青眼の白龍",h:"しん ぶるーあいず・ほわいと・どらごん",e:"Malefic Blue-Eyes White Dragon",ra:"SR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3000,de:2500,tx:"このカードは通常召喚できない。\nデッキから「青眼の白龍」１体を除外した場合に特殊召喚できる。\n①：「Sin」モンスターはフィールドに１体しか表側表示で存在できない。\n②：このカードがモンスターゾーンに存在する限り、他の自分のモンスターは攻撃宣言できない。\n③：フィールド魔法カードが表側表示で存在しない場合にこのカードは破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"Sin レインボー・ドラゴン",h:"しん れいんぼー・どらごん",e:"Malefic Rainbow Dragon",ra:"SR",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:4000,de:0,tx:"このカードは通常召喚できない。\n手札・デッキから「究極宝玉神 レインボー・ドラゴン」１体を除外した場合のみ特殊召喚できる。\n①：「Sin」モンスターはフィールドに１体しか表側表示で存在できない。\n②：このカードがモンスターゾーンに存在する限り、他の自分のモンスターは攻撃宣言できない。\n③：フィールド魔法カードが表側表示で存在しない場合にこのカードは破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"Sin 真紅眼の黒竜",h:"しん れっどあいず・ぶらっくどらごん",e:"Malefic Red-Eyes Black Dragon",ra:"UR",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2400,de:2000,tx:"このカードは通常召喚できない。\nデッキから「真紅眼の黒竜」１体を除外した場合に特殊召喚できる。\n①：「Sin」モンスターはフィールドに１体しか表側表示で存在できない。\n②：このカードがモンスターゾーンに存在する限り、他の自分のモンスターは攻撃宣言できない。\n③：フィールド魔法カードが表側表示で存在しない場合にこのカードは破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"神炎皇ウリア",h:"しんえんおううりあ",e:"Uria, Lord of Searing Flames",ra:"UR",t:"monster",a:"炎",l:10,ps:"",pe:"",tr:"炎族",mo:["特殊召喚","効果"],ma:"",tp:"",at:0,de:0,tx:"このカードは通常召喚できない。\n自分フィールドの表側表示の罠カード３枚を墓地へ送った場合のみ特殊召喚できる。\n①：このカードの攻撃力は、自分の墓地の永続罠カードの数×１０００アップする。\n②：１ターンに１度、相手フィールドにセットされた魔法・罠カード１枚を対象として発動できる。\nセットされたそのカードを破壊する。\nこの効果の発動に対して魔法・罠カードは発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"深淵に咲く花",h:"しんえんにさくはな",e:"Abyss Flower",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"植物族",mo:["通常"],ma:"",tp:"",at:750,de:400,tx:"光の届かない深淵にひっそりと咲く、あまり見かけない花。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"深淵に潜む者",h:"しんえんにひそむもの",e:"Abyss Dweller",ra:"UR",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"海竜族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1700,de:1400,tx:"レベル４モンスター×２\n①：このカードが水属性モンスターをX素材としている場合、自分フィールドの水属性モンスターの攻撃力は５００アップする。\n②：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\nこのターン、相手は墓地のカードの効果を発動できない。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"深淵の冥王",h:"しんえんのめいおう",e:"Dark King of the Abyss",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1200,de:800,tx:"冥界の王。\nかつて闇を全て支配するほどの力があったという。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"真海皇 トライドン",h:"しんかいおう　とらいどん",e:"Legendary Atlantean Tridon",ra:"N",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:1600,de:800,tx:"このカードと自分フィールド上の海竜族モンスター１体をリリースして発動できる。\n手札・デッキから「海皇龍 ポセイドラ」１体を特殊召喚する。\nその後、相手フィールド上の全てのモンスターの攻撃力は３００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"深海王デビルシャーク",h:"しんかいおうでびるしゃーく",e:"Abyssal Kingshark",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1700,de:600,tx:"このカードは１ターンに１度だけ、対象を指定しないカードの効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"深海に潜むサメ",h:"しんかいにひそむさめ",e:"Deepsea Shark",ra:"N",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"魚族",mo:["融合"],ma:"",tp:"",at:1900,de:1600,tx:"神魚＋舌魚",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"深海の大ウナギ",h:"しんかいのおおうなぎ",e:"Deepsea Macrotrema",ra:"N",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:600,de:100,tx:"このカードがフィールド上から墓地へ送られた時、自分フィールド上に表側表示で存在する全ての水属性モンスターの攻撃力はエンドフェイズ時まで５００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"深海の戦士",h:"しんかいのせんし",e:"Deepsea Warrior",ra:"N",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1800,tx:"「海」がフィールド上に存在する限り、このカードは魔法の効果を受けない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"深海のディーヴァ",h:"しんかいのでぃーう゛ぁ",e:"Deep Sea Diva",ra:"UR",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"海竜族",mo:["チューナー","効果"],ma:"",tp:"",at:200,de:400,tx:"①：このカードが召喚に成功した時に発動できる。\nデッキからレベル３以下の海竜族モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"神海竜ギシルノドン",h:"しんかいりゅうぎしるのどん",e:"Sea Dragon Lord Gishilnodon",ra:"SR",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"海竜族",mo:["シンクロ","効果"],ma:"",tp:"",at:2300,de:1800,tx:"チューナー＋チューナー以外のレベル３モンスター１体\nフィールド上に表側表示で存在するレベル３以下のモンスターが墓地へ送られた時、このカードの攻撃力はエンドフェイズ時まで３０００になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"進化合獣ダイオーキシン",h:"しんかごうじゅうだいおーきしん",e:"Poly-Chemicritter Dioxogre",ra:"SR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"悪魔族",mo:["デュアル","効果"],ma:"",tp:"",at:2800,de:200,tx:"①：このカードはフィールド・墓地に存在する限り、通常モンスターとして扱う。\n②：フィールドの通常モンスター扱いのこのカードを通常召喚としてもう１度召喚できる。\nその場合このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●このカードがモンスターゾーンに存在する限り、デュアルモンスターの召喚は無効化されない。\n\n●１ターンに１度、自分の墓地のデュアルモンスター１体を除外し、相手フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"進化合獣ヒュードラゴン",h:"しんかごうじゅうひゅーどらごん",e:"Poly-Chemicritter Hydragon",ra:"R",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["デュアル","効果"],ma:"",tp:"",at:200,de:2800,tx:"①：このカードはフィールド・墓地に存在する限り、通常モンスターとして扱う。\n②：フィールドの通常モンスター扱いのこのカードを通常召喚としてもう１度召喚できる。\nその場合このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●このカード以外のデュアルモンスターが召喚に成功した時に発動できる。\nそのモンスターの攻撃力・守備力は５００アップする。\n\n●自分フィールドのデュアルモンスターが効果で破壊される場合、代わりに自分フィールドのカード１枚を破壊できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"進化の繭",h:"しんかのまゆ",e:"Cocoon of Evolution",ra:"SR",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:0,de:2000,tx:"このカードは手札から装備カード扱いとしてフィールド上に表側表示で存在する「プチモス」に装備する事ができる。\nこの効果によってこのカードを装備した「プチモス」の攻撃力・守備力は「進化の繭」の数値を適用する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"心眼の女神",h:"しんがんのめがみ",e:"Goddess with the Third Eye",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1200,de:1000,tx:"このカードを融合素材モンスター１体の代わりにする事ができる。\nその際、他の融合素材モンスターは正規のものでなければならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"神機王ウル",h:"しんきおううる",e:"Machine Lord Ür",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1600,de:1500,tx:"①：このカードは相手モンスター全てに１回ずつ攻撃できる。\n②：このカードが戦闘を行う場合、相手が受ける戦闘ダメージは０になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"神魚",h:"しんぎょ",e:"Bottom Dweller",ra:"N",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"魚族",mo:["通常"],ma:"",tp:"",at:1650,de:1700,tx:"水中を優雅に泳ぐ魚の神様。\n怒らせると危険。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"神禽王アレクトール",h:"しんきんおうあれくとーる",e:"Alector, Sovereign of Birds",ra:"UR",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2400,de:2000,tx:"相手フィールド上に同じ属性のモンスターが表側表示で２体以上存在する場合、このカードは手札から特殊召喚する事ができる。\n１ターンに１度、フィールド上に表側表示で存在するカード１枚を選択する。\n選択されたカードの効果はそのターン中無効になる。\n「神禽王アレクトール」はフィールド上に１体しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"真空イタチ",h:"しんくういたち",e:"Whirlwind Weasel",ra:"N",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"獣族",mo:["リバース","効果"],ma:"",tp:"",at:500,de:1500,tx:"リバース：このターン相手プレイヤーは魔法・罠カードを発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シンクロ・ガンナー",h:"しんくろ・がんなー",e:"Synchro Soldier",ra:"N",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"１ターンに１度、自分のメインフェイズ１でのみ発動する事ができる。\n自分フィールド上に表側表示で存在するシンクロモンスター１体をゲームから除外して相手ライフに６００ポイントダメージを与える。\nこの効果で除外したモンスターは、次の自分のスタンバイフェイズ時にフィールド上に戻る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シンクロ・フュージョニスト",h:"しんくろ・ふゅーじょにすと",e:"Synchro Fusionist",ra:"N",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:800,de:600,tx:"①：このカードがS素材として墓地へ送られた場合に発動できる。\nデッキから「融合」魔法カードまたは「フュージョン」魔法カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シンクロ・マグネーター",h:"しんくろ・まぐねーたー",e:"Synchro Magnet",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["特殊召喚","チューナー","効果"],ma:"",tp:"",at:1000,de:600,tx:"このカードは通常召喚できない。\n自分がシンクロモンスターのシンクロ召喚に成功した時、このカードを手札から特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シンクローン・リゾネーター",h:"しんくろーん・りぞねーたー",e:"Synkron Resonator",ra:"R",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:100,de:100,tx:"このカード名の①の方法による特殊召喚は１ターンに１度しかできない。\n①：フィールドにSモンスターが存在する場合、このカードは手札から特殊召喚できる。\n②：このカードがフィールドから墓地へ送られた場合、「シンクローン・リゾネーター」以外の自分の墓地の「リゾネーター」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シンクロン・エクスプローラー",h:"しんくろん・えくすぷろーらー",e:"Synchron Explorer",ra:"R",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:700,tx:"①：このカードが召喚に成功した時、自分の墓地の「シンクロン」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シンクロン・キャリアー",h:"しんくろん・きゃりあー",e:"Synchron Carrier",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:1000,tx:"「シンクロン・キャリアー」の②の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、自分は通常召喚に加えて１度だけ、自分メインフェイズに「シンクロン」モンスター１体を召喚できる。\n②：このカードがモンスターゾーンに存在し、このカード以外の「シンクロン」モンスターが戦士族または機械族SモンスターのS素材として自分の墓地へ送られた場合に発動できる。\n自分フィールドに「シンクロントークン」（機械族・地・星２・攻１０００／守０）１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"神獣王バルバロス",h:"しんじゅうおうばるばろす",e:"Beast King Barbaros",ra:"SR",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:3000,de:1200,tx:"①：このカードはリリースなしで通常召喚できる。\n②：このカードの①の方法で通常召喚したこのカードの元々の攻撃力は１９００になる。\n③：このカードはモンスター３体をリリースして召喚する事もできる。\n④：このカードがこのカードの③の方法で召喚に成功した場合に発動する。\n相手フィールドのカードを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"神聖騎士王アルトリウス",h:"しんせいきしおうあるとりうす",e:"Sacred Noble Knight of King Artorigus",ra:"UR",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2200,de:2200,tx:"レベル５「聖騎士」モンスター×２\n①：このカードがX召喚に成功した時、自分の墓地の「聖剣」装備魔法カードを３種類まで対象として発動できる。\n対象のカードをこのカードに装備する。\n②：１ターンに１度、このカードのX素材を１つ取り除き、このカード以外のフィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊する。\n③：このカードがフィールドから墓地へ送られた場合、自分の墓地のレベル４以上の「聖騎士」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"神聖騎士王コルネウス",h:"しんせいきしおうこるねうす",e:"Sacred Noble Knight of King Custennin",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1500,de:2500,tx:"レベル４「聖騎士」モンスター×２体以上\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードのX素材を任意の数だけ取り除き、その数だけ相手フィールドのカードを対象として発動できる。\nそのカードを持ち主の手札に戻す。\n②：このカードが戦闘・効果で破壊され墓地へ送られた場合に発動できる。\n「神聖騎士王コルネウス」以外の「聖騎士」Xモンスター１体をX召喚扱いとしてEXデッキから特殊召喚し、墓地のこのカードを下に重ねてX素材とする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"神聖魔導王 エンディミオン",h:"しんせいまどうおう　えんでぃみおん",e:"Endymion, the Master Magician",ra:"SR",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2700,de:1700,tx:"①：このカードは自分フィールドの「魔法都市エンディミオン」に置かれている魔力カウンターを６つ取り除き、手札・墓地から特殊召喚できる。\n②：このカードの①の方法で特殊召喚に成功した場合、自分の墓地の魔法カード１枚を対象として発動する。\nそのカードを手札に加える。\n③：１ターンに１度、手札から魔法カード１枚を捨て、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"真閃珖竜 スターダスト・クロニクル",h:"しんせんこうりゅう　すたーだすと・くろにくる",e:"Stardust Chronicle Spark Dragon",ra:"UR",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:3000,de:2500,tx:"Sモンスターのチューナー＋チューナー以外のSモンスター１体以上\nこのカードはS召喚でしか特殊召喚できない。\n①：１ターンに１度、自分の墓地のSモンスター１体を除外して発動できる。\nこのカードはターン終了時まで、他のカードの効果を受けない。\nこの効果は相手ターンでも発動できる。\n②：このカードが相手によって破壊された場合、除外されている自分のドラゴン族Sモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"人造人間－サイコ・ジャッカー",h:"じんぞうにんげん－さいこ・じゃっかー",e:"Jinzo - Jector",ra:"UR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:800,de:2000,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードのカード名は、フィールド・墓地に存在する限り「人造人間－サイコ・ショッカー」として扱う。\n②：このカードをリリースして発動できる。\nデッキから「人造人間－サイコ・ジャッカー」以外の「人造人間」モンスター１体を手札に加える。\nその後、相手の魔法＆罠ゾーンにセットされたカードがある場合、それらを全て確認する。\nその中の罠カードの数まで手札から「人造人間」モンスターを特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"人造人間－サイコ・ショッカー",h:"じんぞうにんげん－さいこ・しょっかー",e:"Jinzo",ra:"UR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2400,de:1500,tx:"①：このカードがモンスターゾーンに存在する限り、お互いにフィールドの罠カードの効果を発動できず、フィールドの罠カードの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"人造人間－サイコ・リターナー",h:"じんぞうにんげん－さいこ・りたーなー",e:"Jinzo - Returner",ra:"R",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:600,de:1400,tx:"①：このカードは直接攻撃できる。\n②：このカードが墓地へ送られた時、自分の墓地の「人造人間－サイコ・ショッカー」１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターは自分エンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"人造人間－サイコ・ロード",h:"じんぞうにんげん－さいこ・ろーど",e:"Jinzo - Lord",ra:"SR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"機械族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2600,de:1600,tx:"このカードは通常召喚できない。\n自分フィールドの表側表示の「人造人間－サイコ・ショッカー」１体を墓地へ送った場合のみ特殊召喚できる。\n①：このカードがモンスターゾーンに存在する限り、お互いにフィールドの罠カードの効果を発動できず、フィールドの罠カードの効果は無効化される。\n②：１ターンに１度、自分メインフェイズに発動できる。\nフィールドの表側表示の罠カードを全て破壊し、破壊した数×３００ダメージを相手に与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"人造人間７号",h:"じんぞうにんげん７ごう",e:"Jinzo #7",ra:"R",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:500,de:400,tx:"このカードは相手プレイヤーを直接攻撃する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"人造木人18",h:"じんぞうもくじんいんぱち",e:"Woodborg Inpachi",ra:"N",t:"monster",a:"炎",l:5,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:500,de:2500,tx:"謎の大木１８が魔界の先端技術により改造された姿。\n頑丈な装甲に重点を置きすぎた結果、機動性が犠牲になった。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"神鳥シムルグ",h:"しんちょうしむるぐ",e:"Simorgh, Bird of Divinity",ra:"R",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2700,de:1000,tx:"このカードは特殊召喚できない。\nこのカードをアドバンス召喚する場合、リリースするモンスターは風属性モンスターでなければならない。\nこのカードがフィールド上に表側表示で存在する限り、お互いのプレイヤーはお互いのエンドフェイズ毎に１０００ポイントダメージを受ける。\nこの時、それぞれのプレイヤーが受けるダメージは魔法・罠カードをコントロールしている数×５００ポイント少なくなる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シンデレラ",h:"しんでれら",e:"Prinzessin",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:300,de:600,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\n手札・デッキから「カボチャの馬車」１体を特殊召喚する。\nフィールドゾーンに「シュトロームベルクの金の城」が存在する場合、さらにデッキから「ガラスの靴」１枚をこのカードに装備できる。\n②：このカードが直接攻撃で相手に戦闘ダメージを与えた時、このカードに装備されている「ガラスの靴」１枚と、フィールドの表側表示モンスター１体を対象として発動できる。\nその「ガラスの靴」をそのモンスターに装備する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"神秘の代行者 アース",h:"しんぴのだいこうしゃ　あーす",e:"The Agent of Mystery - Earth",ra:"UR",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"天使族",mo:["チューナー","効果"],ma:"",tp:"",at:1000,de:800,tx:"①：このカードが召喚に成功した時に発動できる。\nデッキから「神秘の代行者 アース」以外の「代行者」モンスター１体を手札に加える。\nフィールドに「天空の聖域」が存在する場合、手札に加えるモンスターを「マスター・ヒュペリオン」１体にできる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"真魔獣 ガーゼット",h:"しんまじゅう　がーぜっと",e:"Legendary Maju Garzett",ra:"SR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"悪魔族",mo:["特殊召喚","効果"],ma:"",tp:"",at:0,de:0,tx:"このカードは通常召喚できない。\n自分フィールドのモンスターを全てリリースした場合のみ特殊召喚できる。\n①：このカードの攻撃力は、このカードを特殊召喚するためにリリースしたモンスターの元々の攻撃力を合計した数値になる。\n②：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"迅雷の騎士ガイアドラグーン",h:"じんらいのきしがいあどらぐーん",e:"Gaia Dragon, the Thunder Charger",ra:"SR",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2600,de:2100,tx:"レベル７モンスター×２\nこのカードは自分フィールド上のランク５・６のエクシーズモンスターの上にこのカードを重ねてエクシーズ召喚する事もできる。\nこのカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"迅雷の魔王－スカル・デーモン",h:"じんらいのまおう－すかる・でーもん",e:"Skull Archfiend of Lightning",ra:"SR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2500,de:1200,tx:"このカードのコントローラーは自分のスタンバイフェイズ毎に５００ライフポイントを払う。\nこのカードが相手のコントロールするカードの効果の対象になり、その処理を行う時にサイコロを１回振る。\n１・３・６が出た場合、その効果を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"森羅の隠蜜 スナッフ",h:"しんらのおんみつ　すなっふ",e:"Sylvan Snapdrassinagon",ra:"N",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:900,de:400,tx:"手札・フィールド上のこのカードが墓地へ送られた場合、自分のデッキの一番上のカードをめくる事ができる。\nめくったカードが植物族モンスターだった場合、そのモンスターを墓地へ送る。\n違った場合、そのカードをデッキの一番下に戻す。\nまた、デッキのこのカードがカードの効果によってめくられて墓地へ送られた場合、自分のデッキの一番上のカードをめくる事ができる。\nめくったカードが植物族モンスターだった場合、そのモンスターを墓地へ送る。\n違った場合、そのカードをデッキの一番下に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"森羅の影胞子 ストール",h:"しんらのかげぼうし　すとーる",e:"Sylvan Komushroomo",ra:"SR",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:100,de:2000,tx:"このカードがリバースした時、自分のデッキの上からカードを５枚までめくる事ができる。\nめくったカードの中に植物族モンスターがあった場合、それらのモンスターを全て墓地へ送る。\n残りのカードは好きな順番でデッキの一番下に戻す。\nまた、デッキのこのカードがカードの効果によってめくられて墓地へ送られた場合、フィールド上の魔法・罠カード１枚を選択して破壊できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"森羅の賢樹 シャーマン",h:"しんらのけんじゅ　しゃーまん",e:"Sylvan Sagequoia",ra:"R",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:2600,de:2100,tx:"「森羅」と名のついたモンスターが墓地へ送られた時、このカードを手札から特殊召喚できる。\n１ターンに１度、自分のメインフェイズ時に発動できる。\n自分のデッキの一番上のカードをめくる。\nめくったカードが植物族モンスターだった場合、そのモンスターを墓地へ送る。\n違った場合、そのカードをデッキの一番下に戻す。\nまた、デッキのこのカードがカードの効果によってめくられて墓地へ送られた場合、自分の墓地の「森羅」と名のついた魔法・罠カード１枚を選択して手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"森羅の鎮神 オレイア",h:"しんらのしずがみ　おれいあ",e:"Orea, the Sylvan High Arbiter",ra:"R",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"植物族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2800,de:2500,tx:"レベル７モンスター×２\n１ターンに１度、自分の手札・フィールド上の植物族モンスター１体を墓地へ送って発動できる。\nそのレベル分だけデッキの上からカードを確認し、好きな順番でデッキの上に戻す。\nまた、１ターンに１度、このカードのエクシーズ素材を１つ取り除いて発動できる。\n自分のデッキの上からカードを３枚までめくる。\nその中に植物族モンスターがあった場合、それらのモンスターを全て墓地へ送り、その数までこのカード以外のフィールド上のカードを選んで手札に戻す。\n残りのカードは好きな順番でデッキの下に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"森羅の神芽 スプラウト",h:"しんらのしんめ　すぷらうと",e:"Sylvan Cherubsprout",ra:"N",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"このカードが特殊召喚に成功した時、自分のデッキの上からカードを２枚までめくる事ができる。\nめくったカードの中に植物族モンスターがあった場合、それらのモンスターを全て墓地へ送る。\n残りのカードは好きな順番でデッキの一番下に戻す。\nまた、デッキのこのカードがカードの効果によってめくられて墓地へ送られた場合、デッキから植物族・レベル１モンスター１体を特殊召喚できる。\n「森羅の神芽 スプラウト」のこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"森羅の仙樹 レギア",h:"しんらのせんじゅ　れぎあ",e:"Sylvan Hermitree",ra:"R",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:2700,de:1800,tx:"１ターンに１度、自分のメインフェイズ時に発動できる。\n自分のデッキの一番上のカードをめくる。\nめくったカードが植物族モンスターだった場合、そのモンスターを墓地へ送り、デッキからカードを１枚ドローする。\n違った場合、そのカードをデッキの一番下に戻す。\nまた、デッキのこのカードがカードの効果によってめくられて墓地へ送られた場合、自分のデッキの上からカードを３枚まで確認し、好きな順番でデッキの上に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"森羅の花卉士 ナルサス",h:"しんらのはなきし　なるさす",e:"Sylvan Flowerknight",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"このカードが召喚に成功した時、自分のデッキの一番上のカードをめくる事ができる。\nめくったカードが植物族モンスターだった場合、そのモンスターを墓地へ送る。\n違った場合、そのカードをデッキの一番下に戻す。\nまた、デッキのこのカードがカードの効果によってめくられて墓地へ送られた場合、デッキから「森羅」と名のついたカード１枚を選んでデッキの一番上に置く事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"森羅の番人 オーク",h:"しんらのばんにん　おーく",e:"Sylvan Guardioak",ra:"N",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:2400,de:1500,tx:"１ターンに１度、自分のメインフェイズ時に発動できる。\n自分のデッキの上からカードを３枚までめくる。\nめくったカードの中に植物族モンスターがあった場合、それらのカードを全て墓地へ送る。\n残りのカードは好きな順番でデッキの一番下に戻す。\nまた、デッキのこのカードがカードの効果によってめくられて墓地へ送られた場合、自分の墓地からこのカード以外の植物族モンスター１体を選択してデッキの一番上に戻す事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"森羅の姫芽君 スプラウト",h:"しんらのひめぎみ　すぷらうと",e:"Sylvan Princessprout",ra:"UR",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"「森羅の姫芽君 スプラウト」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードをリリースして発動できる。\n自分のデッキの一番上のカードをめくって墓地へ送る。\nその後、自分の墓地の「スプラウト」モンスター１体を選んで自分のデッキの一番上に置く事ができる。\n②：デッキのこのカードが効果でめくられて墓地へ送られた場合、１～８までの任意のレベルを宣言して発動できる。\nこのカードを墓地から特殊召喚し、このカードのレベルは宣言したレベルになる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"森羅の姫芽宮",h:"しんらのひめみや",e:"Sylvan Princessprite",ra:"SR",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"植物族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1800,de:100,tx:"レベル１モンスター×２\n「森羅の姫芽宮」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードのX素材を１つ取り除いて発動できる。\n自分のデッキの一番上のカードをめくる。\nめくったカードが魔法・罠カードだった場合、そのカードを手札に加える。\n違った場合、そのカードを墓地へ送る。\n②：手札及びこのカード以外の自分フィールドの表側表示モンスターの中から、植物族モンスター１体を墓地へ送り、自分の墓地の「森羅」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"森羅の蜜柑子 シトラ",h:"しんらのみかんこ　しとら",e:"Sylvan Mikorange",ra:"N",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:400,de:1100,tx:"①：フィールドのこのカードが相手によって破壊され墓地へ送られた時に発動できる。\n自分のデッキの一番上のカードをめくり、そのカードが植物族モンスターだった場合、そのモンスターを墓地へ送る。\n違った場合、そのカードをデッキの一番下に戻す。\n②：デッキのこのカードが効果でめくられて墓地へ送られた場合に発動する。\n自分フィールドの全ての植物族モンスターの攻撃力・守備力は３００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"森羅の水先 リーフ",h:"しんらのみずさき　りーふ",e:"Sylvan Marshalleaf",ra:"R",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1500,de:1200,tx:"このカードが召喚に成功した時、自分のデッキの上からカードを２枚までめくる事ができる。\nめくったカードの中に植物族モンスターがあった場合、それらのモンスターを全て墓地へ送る。\n残りのカードは好きな順番でデッキの一番下に戻す。\nまた、デッキのこのカードがカードの効果によってめくられて墓地へ送られた場合、フィールド上のモンスター１体を選択して破壊できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"森羅の実張り ピース",h:"しんらのみはり　ぴーす",e:"Sylvan Peaskeeper",ra:"R",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:400,de:100,tx:"このカードが召喚・特殊召喚に成功した時、自分のデッキの一番上のカードをめくる事ができる。\nめくったカードが植物族モンスターだった場合、そのモンスターを墓地へ送る。\n違った場合、そのカードをデッキの一番下に戻す。\nまた、デッキのこのカードがカードの効果によってめくられて墓地へ送られた場合、自分の墓地からレベル４以下の植物族モンスター１体を選択して特殊召喚できる。\n「森羅の実張り ピース」のこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"森羅の葉心棒 ブレイド",h:"しんらのようじんぼう　ぶれいど",e:"Sylvan Bladefender",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1900,de:700,tx:"①：このカードが戦闘で相手モンスターを破壊し墓地へ送った時に発動できる。\n自分のデッキの一番上のカードをめくり、そのカードが植物族モンスターだった場合、そのモンスターを墓地へ送る。\n違った場合、そのカードをデッキの一番下に戻す。\n②：デッキのこのカードが効果でめくられて墓地へ送られた場合に発動できる。\n墓地のこのカードを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"森羅の渡し守 ロータス",h:"しんらのわたしもり　ろーたす",e:"Sylvan Lotuswain",ra:"N",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:2300,de:1100,tx:"１ターンに１度、相手フィールド上のカードの数だけ、自分のデッキの上からカードをめくる事ができる。\nめくったカードの中に植物族モンスターがあった場合、それらのモンスターを全て墓地へ送る。\n残りのカードは好きな順番でデッキの一番下に戻す。\nまた、デッキのこのカードがカードの効果によってめくられて墓地へ送られた場合、「森羅の渡し守 ロータス」以外の自分の墓地の「森羅」と名のついたカードを５枚まで選択し、好きな順番でデッキの下に戻す事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"侵略の炎",h:"しんりゃくのほのお",e:"Invasion of Flames",ra:"N",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:1300,de:1200,tx:"このカードが召喚に成功した時、罠カードを発動する事はできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"神竜 アポカリプス",h:"しんりゅう　あぽかりぷす",e:"Divine Dragon Apocralyph",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1000,de:1500,tx:"１ターンに１度、手札を１枚捨てて発動できる。\n自分の墓地のドラゴン族モンスター１体を選択して手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"神竜 ラグナロク",h:"しんりゅう　らぐなろく",e:"Divine Dragon Ragnarok",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:1500,de:1000,tx:"神の使いと言い伝えられている伝説の竜。\nその怒りに触れた時、世界は海に沈むと言われている。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"神竜－エクセリオン",h:"しんりゅう－えくせりおん",e:"Divine Dragon - Excelion",ra:"R",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1500,de:900,tx:"このカードの召喚時に自分の墓地に存在する「神竜－エクセリオン」１体につき、以下の効果を１つ得る。\nただし同じ効果を重複して得る事はできない。\n\n●このカードの攻撃力は１０００ポイントアップする。\n\n●このカードが戦闘によって相手モンスターを破壊した場合、もう一度だけ続けて攻撃を行う事ができる。\n\n●このカードが戦闘によってモンスターを破壊し墓地へ送った時、破壊したモンスターの攻撃力分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"神竜アクアバザル",h:"しんりゅうあくあばざる",e:"Divine Dragon Aquabizarre",ra:"SR",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:2100,de:1500,tx:"このカード以外の自分フィールド上に表側表示で存在する水属性モンスター１体をリリースし、自分の墓地に存在する永続魔法またはフィールド魔法カード１枚を選択して発動する。\n選択したカードを自分の墓地からデッキの一番上に戻す。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"神龍の聖刻印",h:"しんりゅうのせいこくいん",e:"Hieratic Seal of the Sun Dragon Overlord",ra:"N",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:0,de:0,tx:"謎の刻印が刻まれた聖なる遺物。\n神の如く力を振るった龍の力を封じた物と伝承は語る。\n黄金の太陽の下、悠久の刻を経て、それはやがて神々しさと共に太陽石と呼ばれるようになった。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"深緑の魔弓使い",h:"しんりょくのまきゅうつかい",e:"Arcane Archer of the Forest",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:900,de:1400,tx:"自分フィールド上に植物族モンスターが存在する場合、このカードを攻撃する事はできない。\n自分フィールド上の植物族モンスター１体を生け贄に捧げる度に、フィールド上に存在する魔法・罠カード１枚を破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"真六武衆－エニシ",h:"しんろくぶしゅう－えにし",e:"Legendary Six Samurai - Enishi",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:700,tx:"自分フィールド上に「真六武衆－エニシ」以外の「六武衆」と名のついたモンスターが表側表示で存在する場合、１ターンに１度、自分の墓地に存在する「六武衆」と名のついたモンスター２体をゲームから除外する事で、フィールド上に表側表示で存在するモンスター１体を選択して手札に戻す。\nこの効果は相手ターンでも発動する事ができる。\nまた、自分フィールド上に「真六武衆－エニシ」以外の「六武衆」と名のついたモンスターが表側表示で２体以上存在する場合、このカードの攻撃力・守備力は５００ポイントアップする。",li:2,lk:[],ta:[],al:[],ocg:true},
  {n:"真六武衆－カゲキ",h:"しんろくぶしゅう－かげき",e:"Legendary Six Samurai - Kageki",ra:"R",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:200,de:2000,tx:"①：このカードが召喚に成功した時に発動できる。\n手札からレベル４以下の「六武衆」モンスター１体を特殊召喚する。\n②：自分フィールドに「真六武衆－カゲキ」以外の「六武衆」モンスターが存在する場合、このカードの攻撃力は１５００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"真六武衆－キザン",h:"しんろくぶしゅう－きざん",e:"Legendary Six Samurai - Kizan",ra:"UR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:500,tx:"①：自分フィールドに「真六武衆－キザン」以外の「六武衆」モンスターが存在する場合、このカードは手札から特殊召喚できる。\n②：自分フィールドにこのカード以外の「六武衆」モンスターが２体以上存在する場合、このカードの攻撃力・守備力は３００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"真六武衆－シエン",h:"しんろくぶしゅう－しえん",e:"Legendary Six Samurai - Shi En",ra:"UR",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:1400,tx:"戦士族チューナー＋チューナー以外の「六武衆」モンスター１体以上\n①：１ターンに１度、相手が魔法・罠カードを発動した時に発動できる。\nその発動を無効にし破壊する。\n②：フィールドのこのカードが戦闘・効果で破壊される場合、代わりに自分フィールドの「六武衆」モンスター１体を破壊できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"真六武衆－シナイ",h:"しんろくぶしゅう－しない",e:"Legendary Six Samurai - Shinai",ra:"N",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:1500,tx:"自分フィールド上に「真六武衆－ミズホ」が表側表示で存在する場合、このカードは手札から特殊召喚する事ができる。\nフィールド上に存在するこのカードがリリースされた場合、自分の墓地に存在する「真六武衆－シナイ」以外の「六武衆」と名のついたモンスター１体を選択して手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"真六武衆－ミズホ",h:"しんろくぶしゅう－みずほ",e:"Legendary Six Samurai - Mizuho",ra:"R",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"自分フィールド上に「真六武衆－シナイ」が表側表示で存在する場合、このカードは手札から特殊召喚する事ができる。\nまた、１ターンに１度、このカード以外の自分フィールド上に存在する「六武衆」と名のついたモンスター１体をリリースする事で、フィールド上に存在するカード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"水晶の占い師",h:"すいしょうのうらないし",e:"Crystal Seer",ra:"R",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["リバース","効果"],ma:"",tp:"",at:100,de:100,tx:"リバース：自分のデッキの上からカードを２枚めくり、その中から１枚を選んで手札に加える。\nその後、残りのカードをデッキの一番下に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"水魔神－スーガ",h:"すいましん－すーが",e:"Suijin",ra:"R",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:2500,de:2400,tx:"このカードが相手のターンで攻撃された場合、そのダメージ計算時に発動する事ができる。\nその攻撃モンスター１体の攻撃力を０にする。\nこの効果はこのカードがフィールド上に表側表示で存在する限り１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"翠嵐の機界騎士",h:"すいらんのじゃっくないつ",e:"Mekk-Knight Green Horizon",ra:"SR",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:2100,de:1600,tx:"このカード名の①の方法による特殊召喚は１ターンに１度しかできない。\n①：同じ縦列にカードが２枚以上存在する場合、このカードはその縦列の自分フィールドに特殊召喚できる。\n②：このカードが同じ縦列の相手モンスターと戦闘を行う攻撃宣言時に、自分の墓地の「ジャックナイツ」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"水陸の帝王",h:"すいりくのていおう",e:"Emperor of the Land and Sea",ra:"N",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"爬虫類族",mo:["通常"],ma:"",tp:"",at:1800,de:1500,tx:"大きな口から四方八方に炎をはく、爬虫類のばけもの。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"水陸両用バグロス",h:"すいりくりょうようばぐろす",e:"Amphibious Bugroth",ra:"N",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"水族",mo:["融合"],ma:"",tp:"",at:1850,de:1300,tx:"「陸戦型 バグロス」＋「海を守る戦士」",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"水陸両用バグロス Mk－3",h:"すいりくりょうようばぐろす　Ｍｋ－３",e:"Amphibious Bugroth MK-3",ra:"R",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1500,de:1300,tx:"「海」がフィールド上に存在する限り、このカードは相手プレイヤーに直接攻撃する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"水霊使いエリア",h:"すいれいつかいえりあ",e:"Eria the Water Charmer",ra:"R",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["リバース","効果"],ma:"",tp:"",at:500,de:1500,tx:"①：このカードがリバースした場合、相手フィールドの水属性モンスター１体を対象として発動する。\nこのモンスターが表側表示で存在する間、そのモンスターのコントロールを得る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"雛神鳥シムルグ",h:"すうしんちょうしむるぐ",e:"Simorgh, Bird of Beginning",ra:"SR",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:0,de:1600,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時に発動できる。\nこのターン、自分は通常召喚に加えて１度だけ、自分メインフェイズに「シムルグ」モンスター１体を召喚できる。\n②：このカードが墓地に存在し、相手の魔法＆罠ゾーンにカードが存在しない場合に発動できる。\nこのカードを守備表示で特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。\nこの効果の発動後、ターン終了時まで自分は鳥獣族モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スーパー・ウォー・ライオン",h:"すーぱー・うぉー・らいおん",e:"Super War-Lion",ra:"N",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"獣族",mo:["儀式"],ma:"",tp:"",at:2300,de:2100,tx:"「ライオンの儀式」により降臨。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"超伝導恐獣",h:"すーぱーこんだくたーてぃらの",e:"Super Conductor Tyranno",ra:"SR",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:3300,de:1400,tx:"①：１ターンに１度、自分フィールドのモンスター１体をリリースして発動できる。\n相手に１０００ダメージを与える。\nこの効果を発動するターン、このカードは攻撃宣言できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スーパースター",h:"すーぱーすたー",e:"Hoshiningen",ra:"N",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:500,de:700,tx:"①：フィールドの光属性モンスターの攻撃力は５００アップし、闇属性モンスターの攻撃力は４００ダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"SDロボ・エレファン",h:"すーぱーでぃふぇんすろぼ・えれふぁん",e:"Super Defense Robot Elephan",ra:"N",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1400,de:2500,tx:"このカードが召喚に成功した時、手札から「SDロボ」と名のついたモンスターまたは「オービタル ７」１体を特殊召喚できる。\n１ターンに１度、自分フィールド上の「SDロボ」と名のついたモンスターまたは「オービタル ７」１体を選択して発動できる。\n選択したモンスターのレベルをエンドフェイズ時まで８にする。\nまた、このカードをエクシーズ召喚の素材とする場合、機械族モンスターのエクシーズ召喚にしか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"SDロボ・モンキ",h:"すーぱーでぃふぇんすろぼ・もんき",e:"Super Defense Robot Monki",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:800,de:1200,tx:"このカードが召喚に成功した時、手札から「SDロボ」と名のついたモンスターまたは「オービタル ７」１体を特殊召喚できる。\nまた、１ターンに１度、自分の墓地の機械族モンスター１体をゲームから除外して発動できる。\n自分の墓地から「SDロボ」と名のついたモンスターまたは「オービタル ７」１体を選択して手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"SDロボ・ライオ",h:"すーぱーでぃふぇんすろぼ・らいお",e:"Super Defense Robot Lio",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:1600,tx:"このカードが召喚に成功した時、手札から「SDロボ」と名のついたモンスターまたは「オービタル ７」１体を特殊召喚できる。\nまた、１ターンに１度、自分の墓地から「SDロボ」と名のついたモンスターまたは「オービタル ７」１体が自分の手札に加わった時、そのモンスターを手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スーパービークロイド－ジャンボドリル",h:"すーぱーびーくろいど－じゃんぼどりる",e:"Super Vehicroid Jumbo Drill",ra:"UR",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:3000,de:2000,tx:"「スチームロイド」＋「ドリルロイド」＋「サブマリンロイド」\nこのカードの融合召喚は上記のカードでしか行えない。\nこのカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スーパービークロイド－ステルス・ユニオン",h:"すーぱーびーくろいど－すてるす・ゆにおん",e:"Super Vehicroid - Stealth Union",ra:"R",t:"monster",a:"地",l:9,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:3600,de:3000,tx:"「トラックロイド」＋「エクスプレスロイド」＋「ドリルロイド」＋「ステルスロイド」\n１ターンに１度、自分のメインフェイズ時にフィールド上に存在する機械族以外のモンスター１体を選択し、装備カード扱いとしてこのカードに装備する事ができる。\nこの効果によってモンスターを装備している場合、相手フィールド上の全てのモンスターに１回ずつ攻撃をする事ができる。\nこのカードが攻撃をする場合、このカードの元々の攻撃力は半分になる。\nこのカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が越えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スーパイ",h:"すーぱい",e:"Supay",ra:"R",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:300,de:100,tx:"①：このカードが効果でフィールドから墓地へ送られた時に発動できる。\nデッキから「太陽の神官」１体を特殊召喚する。\nこの効果で特殊召喚したモンスターは攻撃力が倍になり、このターンのエンドフェイズに持ち主の手札に戻る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スカー・ウォリアー",h:"すかー・うぉりあー",e:"Scarred Warrior",ra:"SR",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2100,de:1000,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカードがフィールド上に表側表示で存在する限り、相手は表側表示で存在する他の戦士族モンスターを攻撃対象に選択する事はできない。\nまた、このカードは１ターンに１度だけ、戦闘では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スカーレッド・ノヴァ・ドラゴン",h:"すかーれっど・のう゛ぁ・どらごん",e:"Red Nova Dragon",ra:"UR",t:"monster",a:"闇",l:12,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:3500,de:3000,tx:"チューナー２体＋「レッド・デーモンズ・ドラゴン」\n①：このカードの攻撃力は自分の墓地のチューナーの数×５００アップする。\n②：フィールドのこのカードは相手の効果では破壊されない。\n③：相手モンスターの攻撃宣言時にその攻撃モンスター１体を対象として発動できる。\nフィールドのこのカードを除外し、その攻撃を無効にする。\n④：このカードの③の効果で除外されたターンのエンドフェイズに発動する。\n除外されているこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"天空竜",h:"すかい・どらごん",e:"Sky Dragon",ra:"N",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:1900,de:1800,tx:"４枚の羽を持つ、鳥の姿をしたドラゴン。\n刃の羽で攻撃。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スカイ・ハンター",h:"すかい・はんたー",e:"Skull Red Bird",ra:"R",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["通常"],ma:"",tp:"",at:1550,de:1200,tx:"羽に隠し持っているナイフを空から降らせて攻撃する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スカイオニヒトクイエイ",h:"すかいおにひとくいえい",e:"Skystarray",ra:"N",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:600,de:300,tx:"このカードは相手プレイヤーに直接攻撃する事ができる。\nこのカードが直接攻撃を行ったバトルフェイズ終了時、このカードを次の自分のスタンバイフェイズ時までゲームから除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スカゴブリン",h:"すかごぶりん",e:"Goblin Calligrapher",ra:"N",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:400,de:400,tx:"完璧な「スカ」の文字を極めるため、日々精進するゴブリン。\nその全てを一筆に注ぐ。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スカラベの大群",h:"すからべのたいぐん",e:"Swarm of Scarabs",ra:"R",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:500,de:1000,tx:"このカードは１ターンに１度だけ裏側守備表示にする事ができる。\nこのカードが反転召喚に成功した時、相手フィールド上に存在するモンスター１体を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スカル・クラーケン",h:"すかる・くらーけん",e:"Skull Kraken",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:600,de:1600,tx:"このカードが召喚に成功した時、相手フィールド上に表側表示で存在する魔法カード１枚を選択して破壊する事ができる。\n１ターンに１度、このカードの表示形式を変更する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スカル・コンダクター",h:"すかる・こんだくたー",e:"Skull Conductor",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:2000,de:0,tx:"①：このカードを手札から墓地へ送って発動できる。\n攻撃力の合計が２０００になるように手札のアンデット族モンスターを２体まで選び、そのモンスターを特殊召喚する。\n②：自分・相手のバトルフェイズ終了時に発動する。\nフィールドの表側表示のこのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スカル・ナイト",h:"すかる・ないと",e:"Skull Knight #2",ra:"R",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1000,de:1200,tx:"このカードを生け贄にして悪魔族モンスターを生け贄召喚した場合、デッキから「スカル・ナイト」１体を特殊召喚する。\nその後デッキをシャッフルする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スカル・フレイム",h:"すかる・ふれいむ",e:"Skull Flame",ra:"UR",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:2600,de:2000,tx:"１ターンに１度、手札から「バーニング・スカルヘッド」１体を特殊召喚する事ができる。\nこの効果を発動するターン、自分はバトルフェイズを行う事ができない。\nまた、自分のドローフェイズ時に通常のドローを行う代わりに、自分の墓地に存在する「バーニング・スカルヘッド」１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スカル・マイスター",h:"すかる・まいすたー",e:"Skull Meister",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1700,de:400,tx:"①：相手の墓地で魔法・罠・モンスターの効果が発動した時、このカードを手札から墓地へ送って発動できる。\nその効果を無効にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"骨犬マロン",h:"すかるどっぐまろん",e:"Skull Dog Marron",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:1350,de:2000,tx:"１０００年前に飼い主とはぐれてしまった迷犬。\n未だにご主人の迎えを待ち続けている。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スカルビショップ",h:"すかるびしょっぷ",e:"Skull Knight",ra:"R",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"魔法使い族",mo:["融合"],ma:"",tp:"",at:2650,de:2250,tx:"悪魔の知恵＋魔天老",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スカルライダー",h:"すかるらいだー",e:"Dokurorider",ra:"N",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"アンデット族",mo:["儀式"],ma:"",tp:"",at:1900,de:1850,tx:"「スカルライダーの復活」により降臨。\n場か手札から、星の数が合計６個以上になるようカードを生け贄に捧げなければならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スキエルG",h:"すきえるがーど",e:"Skiel Guard",ra:"SR",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:200,de:300,tx:"フィールド上に「機皇帝」と名のついたモンスターが存在しない場合、このカードは破壊される。\n１ターンに１度、自分のモンスターが攻撃対象に選択された時に発動できる。\nその攻撃を無効にする。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"スキヤナー",h:"すきやなー",e:"Scanner",ra:"N",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:"?",de:"?",tx:"ゲームから除外されている相手のモンスター１体を選択して発動する。\nこのカードはエンドフェイズ時まで選択したモンスターと同名カードとして扱い、選択したモンスターと同じ属性・レベル・攻撃力・守備力になる。\nこの効果は１ターンに１度しか使用できない。\nこの効果が適用されたこのカードはフィールド上から離れた場合、ゲームから除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スクープ・シューター",h:"すくーぷ・しゅーたー",e:"Cameraclops",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1400,de:1600,tx:"このカードが、このカードの攻撃力よりも高い守備力を持つフィールド上に表側表示で存在するモンスターに攻撃した場合、ダメージ計算を行わずそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スクラップ・オルトロス",h:"すくらっぷ・おるとろす",e:"Scrap Orthros",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["特殊召喚","チューナー","効果"],ma:"",tp:"",at:1700,de:1100,tx:"このカードは通常召喚できない。\n自分フィールドに「スクラップ」モンスターが存在する場合に特殊召喚できる。\n①：この方法でこのカードが特殊召喚に成功した場合、自分フィールドの「スクラップ」モンスター１体を対象として発動する。\nその自分の「スクラップ」モンスターを破壊する。\n②：このカードが「スクラップ」カードの効果で破壊され墓地へ送られた場合、「スクラップ・オルトロス」以外の自分の墓地の「スクラップ」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スクラップ・キマイラ",h:"すくらっぷ・きまいら",e:"Scrap Chimera",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1700,de:500,tx:"このカードをS素材とする場合、「スクラップ」モンスターのS召喚にしか使用できず、他のS素材モンスターは全て「スクラップ」モンスターでなければならない。\n①：このカードが召喚に成功した時、自分の墓地の「スクラップ」チューナー１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スクラップ・ゴーレム",h:"すくらっぷ・ごーれむ",e:"Scrap Golem",ra:"R",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:2300,de:1400,tx:"１ターンに１度、自分の墓地に存在するレベル４以下の「スクラップ」と名のついたモンスター１体を選択し、自分または相手フィールド上に特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スクラップ・ゴブリン",h:"すくらっぷ・ごぶりん",e:"Scrap Goblin",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣戦士族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:500,tx:"フィールド上に表側守備表示で存在するこのカードが攻撃対象に選択された場合、バトルフェイズ終了時にこのカードを破壊する。\nこのカードが「スクラップ」と名のついたカードの効果によって破壊され墓地へ送られた場合、「スクラップ・ゴブリン」以外の自分の墓地に存在する「スクラップ」と名のついたモンスター１体を選択して手札に加える事ができる。\nまた、このカードは戦闘では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スクラップ・コング",h:"すくらっぷ・こんぐ",e:"Scrap Kong",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:2000,de:1000,tx:"このカードが召喚に成功した時、このカードを破壊する。\nこのカードが「スクラップ」と名のついたカードの効果によって破壊され墓地へ送られた場合、「スクラップ・コング」以外の自分の墓地に存在する「スクラップ」と名のついたモンスター１体を選択して手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スクラップ・サーチャー",h:"すくらっぷ・さーちゃー",e:"Scrap Searcher",ra:"N",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:100,de:300,tx:"このカードが墓地に存在し、自分フィールド上に存在する「スクラップ・サーチャー」以外の「スクラップ」と名のついたモンスターが破壊され墓地へ送られた時、このカードを墓地から特殊召喚する事ができる。\nこのカードが特殊召喚に成功した時、「スクラップ」と名のついたモンスター以外の自分フィールド上に表側表示で存在するモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スクラップ・シャーク",h:"すくらっぷ・しゃーく",e:"Scrap Shark",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:2100,de:0,tx:"効果モンスターの効果・魔法・罠カードが発動した時、フィールド上に表側表示で存在するこのカードを破壊する。\nこのカードが「スクラップ」と名のついたカードの効果によって破壊され墓地へ送られた場合、自分のデッキから「スクラップ」と名のついたモンスター１体を墓地へ送る事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スクラップ・ソルジャー",h:"すくらっぷ・そるじゃー",e:"Scrap Soldier",ra:"N",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"戦士族",mo:["チューナー","効果"],ma:"",tp:"",at:2100,de:700,tx:"フィールド上に表側守備表示で存在するこのカードが攻撃対象に選択された場合、バトルフェイズ終了時にこのカードを破壊する。\nこのカードが「スクラップ」と名のついたカードの効果によって破壊され墓地へ送られた場合、「スクラップ・ソルジャー」以外の自分の墓地に存在する「スクラップ」と名のついたモンスター１体を選択して手札に加える事ができる。\nこのカードをシンクロ素材とする場合、「スクラップ」と名のついたモンスターのシンクロ召喚にしか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スクラップ・ツイン・ドラゴン",h:"すくらっぷ・ついん・どらごん",e:"Scrap Twin Dragon",ra:"UR",t:"monster",a:"地",l:9,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:3000,de:2200,tx:"「スクラップ」と名のついたチューナー＋チューナー以外のモンスター１体以上\n１ターンに１度、自分フィールド上に存在するカード１枚と、相手フィールド上に存在するカード２枚を選択して発動する事ができる。\n選択した自分のカードを破壊し、選択した相手のカードを手札に戻す。\nこのカードが相手によって破壊され墓地へ送られた時、シンクロモンスター以外の自分の墓地に存在する「スクラップ」と名のついたモンスター１体を選択して特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スクラップ・デスデーモン",h:"すくらっぷ・ですでーもん",e:"Scrap Archfiend",ra:"SR",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"悪魔族",mo:["シンクロ"],ma:"",tp:"",at:2700,de:1800,tx:"チューナー＋チューナー以外のモンスター１体以上",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スクラップ・ドラゴン",h:"すくらっぷ・どらごん",e:"Scrap Dragon",ra:"UR",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2800,de:2000,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：１ターンに１度、自分及び相手フィールドのカードを１枚ずつ対象として発動できる。\nそのカードを破壊する。\n②：このカードが相手によって破壊され墓地へ送られた場合、Sモンスター以外の自分の墓地の「スクラップ」モンスター１体を対象として発動する。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スクラップ・ハンター",h:"すくらっぷ・はんたー",e:"Scrap Hunter",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1600,de:400,tx:"１ターンに１度、このカード以外の自分フィールド上に表側表示で存在する「スクラップ」と名のついたモンスター１体を選択して破壊し、自分のデッキからチューナー１体を墓地へ送る事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スクラップ・ビースト",h:"すくらっぷ・びーすと",e:"Scrap Beast",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["チューナー","効果"],ma:"",tp:"",at:1600,de:1300,tx:"フィールド上に表側守備表示で存在するこのカードが攻撃対象に選択された場合、バトルフェイズ終了時にこのカードを破壊する。\nこのカードが「スクラップ」と名のついたカードの効果によって破壊され墓地へ送られた場合、「スクラップ・ビースト」以外の自分の墓地に存在する「スクラップ」と名のついたモンスター１体を選択して手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スクラップ・ブレイカー",h:"すくらっぷ・ぶれいかー",e:"Scrap Breaker",ra:"R",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2100,de:700,tx:"相手フィールド上にモンスターが存在する場合、このカードは手札から特殊召喚する事ができる。\nこの効果で特殊召喚に成功した時、自分フィールド上に表側表示で存在する「スクラップ」と名のついたモンスター１体を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スクラップ・リサイクラー",h:"すくらっぷ・りさいくらー",e:"Scrap Recycler",ra:"SR",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:900,de:1200,tx:"①：このカードが召喚・特殊召喚に成功した時に発動できる。\nデッキから機械族モンスター１体を墓地へ送る。\n②：１ターンに１度、自分の墓地の機械族・地属性・レベル４モンスター２体をデッキに戻して発動できる。\n自分はデッキから１枚ドローする。",li:2,lk:[],ta:[],al:[],ocg:true},
  {n:"スクラップ・ワーム",h:"すくらっぷ・わーむ",e:"Scrap Worm",ra:"R",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"昆虫族",mo:["チューナー","効果"],ma:"",tp:"",at:500,de:100,tx:"このカードは相手プレイヤーに直接攻撃する事ができる。\nこのカードは攻撃した場合、バトルフェイズ終了時に破壊される。\nこのカードが「スクラップ」と名のついたカードの効果によって破壊され墓地へ送られた場合、「スクラップ・ワーム」以外の自分の墓地に存在する「スクラップ」と名のついたモンスター１体を選択して手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スクリーチ",h:"すくりーち",e:"Skreech",ra:"SR",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1500,de:400,tx:"このカードが戦闘によって破壊された場合、デッキから水属性モンスター２体を墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スケイルモース",h:"すけいるもーす",e:"Scary Moth",ra:"R",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1800,de:2300,tx:"このカードがフィールド上に表側表示で存在する限り、お互いのプレイヤーは１ターンに１度しかモンスターを特殊召喚する事ができない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スケープ・ゴースト",h:"すけーぷ・ごーすと",e:"Scapeghost",ra:"R",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"アンデット族",mo:["リバース","チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"①：このカードがリバースした場合に発動できる。\n自分フィールドに「黒羊トークン」（アンデット族・闇・星１・攻／守０）を任意の数だけ特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スケルエンジェル",h:"すけるえんじぇる",e:"Skelengel",ra:"R",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"天使族",mo:["リバース","効果"],ma:"",tp:"",at:900,de:400,tx:"①：このカードがリバースした場合に発動する。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スケルゴン",h:"すけるごん",e:"Skelgon",ra:"N",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"アンデット族",mo:["融合"],ma:"",tp:"",at:1700,de:1900,tx:"メデューサの亡霊＋暗黒の竜王",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スコール",h:"すこーる",e:"Violent Rain",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1550,de:800,tx:"バケツをひっくり返したような大雨を、突然降らすモンスター。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"朱雀",h:"すざく",e:"Vermillion Sparrow",ra:"N",t:"monster",a:"炎",l:5,ps:"",pe:"",tr:"炎族",mo:["融合"],ma:"",tp:"",at:1900,de:1500,tx:"「赤き剣のライムンドス」＋「炎の魔神」",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"朱雀の召喚士",h:"すざくのしょうかんし",e:"Red Sparrow Summoner",ra:"SR",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1600,de:1300,tx:"①：このカードが相手モンスターの攻撃で破壊され墓地へ送られた時に発動できる。\nデッキから攻撃力１５００以下の戦士族モンスター１体を攻撃表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"雷帝神",h:"すさのお",e:"Susa Soldier",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"雷族",mo:["スピリット","効果"],ma:"",tp:"",at:2000,de:1600,tx:"このカードは特殊召喚できない。\n召喚・リバースしたターンのエンドフェイズ時に持ち主の手札に戻る。\nこのカードが相手ライフに与える戦闘ダメージは半分になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スター・ブライト・ドラゴン",h:"すたー・ぶらいと・どらごん",e:"Bright Star Dragon",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1900,de:1000,tx:"このカードが召喚に成功した時、このカード以外のフィールド上に表側表示で存在するモンスター１体を選択し、エンドフェイズ時までレベルを２つ上げる事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スター・ボーイ",h:"すたー・ぼーい",e:"Star Boy",ra:"N",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:550,de:500,tx:"このカードがフィールド上に表側表示で存在する限り、フィールド上に表側表示で存在する水属性モンスターの攻撃力は５００ポイントアップし、炎属性モンスターの攻撃力は４００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スターシップ・スパイ・プレーン",h:"すたーしっぷ・すぱい・ぷれーん",e:"Starship Spy Plane",ra:"SR",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1100,de:500,tx:"相手フィールド上にエクシーズモンスターが存在する場合、このカードは手札から特殊召喚できる。\nまた、このカードが手札からの特殊召喚に成功した時、相手フィールド上の魔法・罠カード１枚を選択して持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スターダスト・アサルト・ウォリアー",h:"すたーだすと・あさると・うぉりあー",e:"Stardust Assault Warrior",ra:"SR",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2100,de:1200,tx:"チューナー＋チューナー以外のモンスター１体以上\n「スターダスト・アサルト・ウォリアー」の①の効果は１ターンに１度しか使用できない。\n①：このカードがS召喚に成功した時、自分フィールドに他のモンスターが存在しない場合に自分の墓地の「ジャンク」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n②：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スターダスト・シャオロン",h:"すたーだすと・しゃおろん",e:"Stardust Xiaolong",ra:"SR",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"自分が「スターダスト・ドラゴン」のシンクロ召喚に成功した時、墓地のこのカードを表側攻撃表示で特殊召喚できる。\nこのカードは１ターンに１度だけ、戦闘では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スターダスト・チャージ・ウォリアー",h:"すたーだすと・ちゃーじ・うぉりあー",e:"Stardust Charge Warrior",ra:"SR",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2000,de:1300,tx:"チューナー＋チューナー以外のモンスター１体以上\n「スターダスト・チャージ・ウォリアー」の①の効果は１ターンに１度しか使用できない。\n①：このカードがS召喚に成功した時に発動できる。\n自分はデッキから１枚ドローする。\n②：このカードは特殊召喚された相手モンスター全てに１回ずつ攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スターダスト・ドラゴン",h:"すたーだすと・どらごん",e:"Stardust Dragon",ra:"UR",t:"monster",a:"風",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:2000,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：フィールドのカードを破壊する魔法・罠・モンスターの効果が発動した時、このカードをリリースして発動できる。\nその発動を無効にし破壊する。\n②：このカードの①の効果を適用したターンのエンドフェイズに発動できる。\nその効果を発動するためにリリースしたこのカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スターダスト・ファントム",h:"すたーだすと・ふぁんとむ",e:"Stardust Phantom",ra:"N",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"自分フィールド上に存在するこのカードが相手によって破壊され墓地へ送られた時、自分の墓地に存在する「スターダスト・ドラゴン」１体を選択して表側守備表示で特殊召喚する事ができる。\nまた、墓地に存在するこのカードをゲームから除外し、自分フィールド上に表側表示で存在するドラゴン族のシンクロモンスター１体を選択して発動する事ができる。\n選択したモンスターは１ターンに１度だけ戦闘では破壊されず、この効果を適用したダメージステップ終了時に攻撃力・守備力は８００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スターフィッシュ",h:"すたーふぃっしゅ",e:"Starfish",ra:"N",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:300,de:300,tx:"１ターンに１度、自分のメインフェイズ時に発動できる。\n自分フィールド上の全ての「スターフィッシュ」のレベルを１つ上げる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スチームジャイロイド",h:"すちーむじゃいろいど",e:"Steam Gyroid",ra:"SR",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"機械族",mo:["融合"],ma:"",tp:"",at:2200,de:1600,tx:"「ジャイロイド」＋「スチームロイド」",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スチームロイド",h:"すちーむろいど",e:"Steamroid",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1800,de:1800,tx:"このカードは相手モンスターに攻撃する場合、ダメージステップの間攻撃力が５００ポイントアップする。\nこのカードは相手モンスターに攻撃される場合、ダメージステップの間攻撃力が５００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スティング",h:"すてぃんぐ",e:"Hinotama Soul",ra:"N",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"炎族",mo:["通常"],ma:"",tp:"",at:600,de:500,tx:"ものすごく熱い炎のかたまり。\nその体で体当たりしてくる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"星輝士 デルタテロス",h:"すてらないと　でるたてろす",e:"Stellarknight Delteros",ra:"UR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2500,de:2100,tx:"レベル４モンスター×３\n①：X素材を持ったこのカードがモンスターゾーンに存在する限り、自分がモンスターの召喚・特殊召喚に成功した時には、相手は魔法・罠・モンスターの効果を発動できない。\n②：１ターンに１度、このカードのX素材を１つ取り除き、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\n③：このカードがフィールドから墓地へ送られた場合に発動できる。\n手札・デッキから「テラナイト」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ステルスバード",h:"すてるすばーど",e:"Stealth Bird",ra:"SR",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:700,de:1700,tx:"このカードは１ターンに１度だけ裏側守備表示にする事ができる。\nこのカードが反転召喚に成功した時、相手ライフに１０００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ステルスロイド",h:"すてるすろいど",e:"Stealthroid",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:0,tx:"自分フィールド上にこのカード以外の「ロイド」と名のついたモンスターが存在する場合、このカードが戦闘を行った自分ターンのバトルフェイズ終了時に、フィールド上の魔法または罠カード１枚を破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ストーム・サモナー",h:"すとーむ・さもなー",e:"Storm Caller",ra:"N",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:2300,de:2000,tx:"このカードが自分フィールド上に表側表示で存在する限り、このカード以外のサイキック族モンスターが戦闘によって破壊した相手モンスターは墓地へ送らず、相手のデッキの一番上に置く事ができる。\nこのカードがカードの効果によって破壊された時、このカードのコントローラーはこのカードの攻撃力分のダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ストーム・シューター",h:"すとーむ・しゅーたー",e:"Storm Shooter",ra:"SR",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2300,de:500,tx:"１ターンに１度だけ、次の効果から１つを選択して発動する事ができる。\n\n●使用していない隣のモンスターカードゾーンに移動する。\n\n●このカードの正面に存在する相手のモンスター・魔法・罠カード１枚を持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ストーン・アルマジラー",h:"すとーん・あるまじらー",e:"Stone Armadiller",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["通常"],ma:"",tp:"",at:800,de:1200,tx:"体が石のように堅い毛で覆われており、守りがかたい。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ストーン・ゴースト",h:"すとーん・ごーすと",e:"Stone Ghost",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["通常"],ma:"",tp:"",at:1200,de:1000,tx:"怒らせると、おつむが大噴火。\n岩がゴロゴロ降ってくるぞ。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ストーン・ドラゴン",h:"すとーん・どらごん",e:"Stone Dragon",ra:"N",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"岩石族",mo:["通常"],ma:"",tp:"",at:2000,de:2300,tx:"全身が岩でできているドラゴン。\n岩石の攻撃は強力だ。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"迷走悪魔",h:"すとれい・でびる",e:"Stray Asmodian",ra:"N",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1300,de:1700,tx:"このカードが戦闘によって破壊され墓地へ送られた時、お互いは８００ライフポイント回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ストロング・ウィンド・ドラゴン",h:"すとろんぐ・うぃんど・どらごん",e:"Strong Wind Dragon",ra:"UR",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2400,de:1000,tx:"①：ドラゴン族モンスター１体をリリースしてこのカードのアドバンス召喚に成功した場合に発動する。\nこのカードの攻撃力は、リリースしたそのモンスターの元々の攻撃力の半分だけアップする。\n②：このカードは同じ攻撃力のモンスターとの戦闘では破壊されない。\n③：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スナイプストーカー",h:"すないぷすとーかー",e:"Snipe Hunter",ra:"UR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1500,de:600,tx:"手札を１枚捨て、フィールド上のカード１枚を選択して発動できる。\nサイコロを１回振り、１・６以外が出た場合、選択したカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スナップドラゴン",h:"すなっぷどらごん",e:"Snapdragon",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:300,de:100,tx:"このカードが墓地へ送られた時、相手の手札をランダムに１枚選択し、エンドフェイズ時まで表側表示でゲームから除外する。\n「スナップドラゴン」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スネーク・パーム",h:"すねーく・ぱーむ",e:"Snakeyashi",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"植物族",mo:["通常"],ma:"",tp:"",at:1000,de:1200,tx:"多くのヘビが集まり擬態している。\n近づくとバラバラになり襲いかかる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スネークポット",h:"すねーくぽっと",e:"Cobra Jar",ra:"SR",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"爬虫類族",mo:["リバース","効果"],ma:"",tp:"",at:600,de:300,tx:"リバース：自分フィールド上に「毒蛇トークン」（爬虫類族・地・星３・攻／守１２００）を１体特殊召喚する。\n「毒蛇トークン」が戦闘によって破壊された場合、相手ライフに５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スノー・ドラゴン",h:"すのー・どらごん",e:"Snow Dragon",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1400,de:900,tx:"このカードが戦闘またはカードの効果によって破壊され墓地へ送られた時、フィールド上に表側表示で存在する全てのモンスターにアイスカウンターを１つ置く。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スノーダスト・ドラゴン",h:"すのーだすと・どらごん",e:"Snowdust Dragon",ra:"R",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2800,de:1800,tx:"このカードはフィールド上のアイスカウンターを４つ取り除き、手札から特殊召喚できる。\nこのカードがフィールド上に表側表示で存在する限り、このカード以外のアイスカウンターが乗ったモンスターは攻撃できず、表示形式の変更もできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スノーマン・クリエイター",h:"すのーまん・くりえいたー",e:"Snowman Creator",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"①：このカードが召喚・反転召喚・特殊召喚に成功した時に発動できる。\n自分フィールドの水属性モンスターの数だけ、相手フィールドの表側表示モンスターにアイスカウンターを置く。\nこの効果でアイスカウンターを３つ以上置いた場合、さらに相手フィールドのカード１枚を選んで破壊できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スノーマンイーター",h:"すのーまんいーたー",e:"Snowman Eater",ra:"SR",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:0,de:1900,tx:"このカードがリバースした時、フィールド上に表側表示で存在するモンスター１体を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スパイク・ヘッド",h:"すぱいく・へっど",e:"Spikebot",ra:"R",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1800,de:1700,tx:"地獄の魔術師が生み出した機械兵。\n両腕の鉄球で敵味方関係なしに攻撃を繰り返す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スパイクシードラ",h:"すぱいくしーどら",e:"Spike Seadra",ra:"N",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"海竜族",mo:["通常"],ma:"",tp:"",at:1600,de:1300,tx:"体のトゲを相手に突き刺し、電流を流して攻撃する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スパイダー・スパイダー",h:"すぱいだー・すぱいだー",e:"Spyder Spider",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"このカードが戦闘によって相手フィールド上に守備表示で存在するモンスターを破壊した場合、自分の墓地に存在するレベル４以下の昆虫族モンスター１体を選択して特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スパイラルドラゴン",h:"すぱいらるどらごん",e:"Spiral Serpent",ra:"N",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"海竜族",mo:["通常"],ma:"",tp:"",at:2900,de:2900,tx:"海流の渦をつくり出し人々を襲うと伝えられる海竜。\n巨大なヒレから放たれるスパイラルウェーブは全てを飲み込む。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スパウン・アリゲーター",h:"すぱうん・ありげーたー",e:"Spawn Alligator",ra:"R",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:2200,de:1000,tx:"爬虫類族モンスターをリリースしてこのカードのアドバンス召喚に成功した場合、このカードのアドバンス召喚のためにリリースしたモンスター１体を、そのターンのエンドフェイズ時に墓地から自分フィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ズババジェネラル",h:"ずばばじぇねらる",e:"Zubaba General",ra:"UR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2000,de:1000,tx:"レベル４モンスター×２\n１ターンに１度、このカードのエクシーズ素材を１つ取り除いて発動できる。\n手札から戦士族モンスター１体を装備カード扱いとしてこのカードに装備する。\nこのカードの攻撃力は、この効果で装備したモンスターの攻撃力分アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ズババナイト",h:"ずばばないと",e:"Zubaba Knight",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:900,tx:"①：このカードが表側守備表示モンスターを攻撃するダメージステップ開始時に発動する。\nそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ズバババスター",h:"ずばばばすたー",e:"Zubaba Buster",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:600,tx:"このカードが相手ライフに戦闘ダメージを与えたダメージステップ終了時、フィールド上に表側表示で存在する攻撃力が一番低いモンスター１体を破壊し、このカードの攻撃力は８００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ズバババンチョー－GC",h:"ずばばばんちょー－がががこーと",e:"Zubababancho Gagagacoat",ra:"UR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:100,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが手札に存在し、自分フィールドに「ズバババンチョー－GC」以外の、「ズババ」モンスターまたは「ガガガ」モンスターが存在する場合に発動できる。\nこのカードを特殊召喚する。\n②：自分の墓地の、「ゴゴゴ」モンスターまたは「ドドド」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果の発動後、ターン終了時まで自分はXモンスターしかEXデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"素早いアンコウ",h:"すばやいあんこう",e:"Nimble Angler",ra:"N",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:600,de:100,tx:"このカードが手札・デッキから墓地へ送られた場合、デッキから「素早いアンコウ」以外のレベル３以下の「素早い」と名のついたモンスターを２体まで特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"素早いビッグハムスター",h:"すばやいびっぐはむすたー",e:"Super-Nimble Mega Hamster",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["リバース","効果"],ma:"",tp:"",at:1100,de:1800,tx:"リバース：自分のデッキからレベル３以下の獣族モンスター１体を裏側守備表示で特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"素早いマンタ",h:"すばやいまんた",e:"Nimble Manta",ra:"N",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:800,de:100,tx:"フィールド上のこのカードがカードの効果によって墓地へ送られた時、デッキから「素早いマンタ」を任意の数だけ特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"素早いマンボウ",h:"すばやいまんぼう",e:"Nimble Sunfish",ra:"R",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1000,de:100,tx:"このカードが戦闘によって破壊され墓地へ送られた時、自分のデッキから魚族モンスター１体を墓地へ送る。\nその後、自分のデッキから「素早いマンボウ」１体を特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"素早いムササビ",h:"すばやいむささび",e:"Nimble Musasabi",ra:"R",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:800,de:100,tx:"このカードが戦闘によって破壊され墓地へ送られた時、相手ライフに５００ポイントダメージを与える。\nさらに自分のデッキから「素早いムササビ」を任意の数だけ相手フィールド上に表側攻撃表示で特殊召喚する事ができる。\nこのカードはアドバンス召喚のためにはリリースできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スパルタクァの呪術師",h:"すぱるたくぁのじゅじゅつし",e:"Witch Doctor of Sparta",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:400,de:2000,tx:"このカードがフィールド上に表側表示で存在する場合、モンスターがデッキから特殊召喚される度に、相手ライフに５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スピア・シャーク",h:"すぴあ・しゃーく",e:"Spear Shark",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1600,de:1400,tx:"このカードが召喚に成功した時、自分フィールド上の全ての魚族・レベル３モンスターのレベルを１つ上げる事ができる。\nこのカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スピア・ドラゴン",h:"すぴあ・どらごん",e:"Spear Dragon",ra:"UR",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1900,de:0,tx:"①：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。\n②：このカードは攻撃した場合、ダメージステップ終了時に守備表示になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スピアフィッシュソルジャー",h:"すぴあふぃっしゅそるじゃー",e:"Spearfish Soldier",ra:"N",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:1700,de:1100,tx:"このカードの攻撃力は、ゲームから除外されている自分の魚族・海竜族・水族モンスターの数×１００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スピード・ウォリアー",h:"すぴーど・うぉりあー",e:"Speed Warrior",ra:"R",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:900,de:400,tx:"①：このカードの召喚に成功したターンのバトルステップに発動できる。\nこのカードの攻撃力はバトルフェイズ終了時まで元々の攻撃力の倍になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スピード・キング☆スカル・フレイム",h:"すぴーど・きんぐ☆すかる・ふれいむ",e:"Supersonic Skull Flame",ra:"UR",t:"monster",a:"風",l:10,ps:"",pe:"",tr:"アンデット族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2600,de:2000,tx:"このカードは通常召喚できない。\n自分の墓地に存在する「スカル・フレイム」１体をゲームから除外した場合に特殊召喚する事ができる。\n１ターンに１度、自分の墓地に存在する「バーニング・スカルヘッド」の数×４００ポイントダメージを相手ライフに与える事ができる。\nまた、このカードがフィールド上から墓地へ送られた時、自分の墓地に存在する「スカル・フレイム」１体を特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"SR赤目のダイス",h:"すぴーどろいどあかめのだいす",e:"Speedroid Red-Eyed Dice",ra:"R",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:100,de:100,tx:"①：このカードが召喚・特殊召喚に成功した時、「SR赤目のダイス」以外の自分フィールドの「スピードロイド」モンスター１体を対象とし、１～６までの任意のレベルを宣言して発動できる。\nそのモンスターのレベルはターン終了時まで、宣言したレベルになる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"SRオハジキッド",h:"すぴーどろいどおはじきっど",e:"Speedroid Ohajikid",ra:"R",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:200,tx:"①：このカードが召喚に成功した時、自分または相手の墓地のチューナー１体を対象として発動できる。\nそのモンスターを自分フィールドに特殊召喚し、そのモンスターとこのカードのみを素材として風属性のSモンスター１体をS召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"SRシェイブー・メラン",h:"すぴーどろいどしぇいぶー・めらん",e:"Speedroid Razorang",ra:"N",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2000,de:0,tx:"①：このカードは召喚したターンには攻撃できない。\n②：１ターンに１度、フィールドの表側表示モンスター１体を対象として発動できる。\nこのカードを守備表示にし、対象のモンスターの攻撃力はターン終了時まで８００ダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"SRダブルヨーヨー",h:"すぴーどろいどだぶるよーよー",e:"Speedroid Double Yoyo",ra:"R",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1400,de:1400,tx:"①：このカードが召喚に成功した時、自分の墓地のレベル３以下の「スピードロイド」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"SR電々大公",h:"すぴーどろいどでんでんだいこう",e:"Speedroid Den-Den Daiko Duke",ra:"R",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：墓地のこのカードを除外して発動できる。\n自分の手札・墓地から「SR電々大公」以外の「スピードロイド」チューナー１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"SRパチンゴーカート",h:"すぴーどろいどぱちんごーかーと",e:"Speedroid Pachingo-Kart",ra:"N",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"①：１ターンに１度、手札から機械族モンスター１体を捨て、フィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"SRバンブー・ホース",h:"すぴーどろいどばんぶー・ほーす",e:"Speedroid Horse Stilts",ra:"N",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1100,de:1100,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時に発動できる。\n手札からレベル４以下の「スピードロイド」モンスター１体を特殊召喚する。\n②：墓地のこのカードを除外して発動できる。\nデッキから風属性モンスター１体を墓地へ送る。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"SR56プレーン",h:"すぴーどろいどふぁいぶしっくすぷれーん",e:"Speedroid Rubberband Plane",ra:"N",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1800,de:0,tx:"「SR５６プレーン」の①の効果は１ターンに１度しか使用できない。\n①：自分フィールドにモンスターが特殊召喚された場合に発動できる。\nこのカードを手札から特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は風属性モンスターしか特殊召喚できない。\n②：このカードが召喚・特殊召喚に成功した場合、フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで６００ダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スピック",h:"すぴっく",e:"Droll Bird",ra:"N",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"鳥獣族",mo:["通常"],ma:"",tp:"",at:600,de:500,tx:"くちばしがとても大きく、大声で鳴き気の弱い相手を驚かせる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スピッド・バード",h:"すぴっど・ばーど",e:"Speed Bird",ra:"SR",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1200,de:1000,tx:"このカードが戦闘によって破壊され墓地へ送られた時、自分の墓地に存在するレベル２のモンスター２体を選択して特殊召喚する事ができる。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スピリット・ドラゴン",h:"すぴりっと・どらごん",e:"Spirit Ryu",ra:"R",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードが戦闘を行う自分のバトルステップ時、手札からドラゴン族モンスター１体を墓地へ捨てて発動する事ができる。\nこのカードの攻撃力・守備力は、バトルフェイズ終了時まで１０００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"混沌球体",h:"すふぃあ・おぶ・かおす",e:"Sphere of Chaos",ra:"R",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1600,de:0,tx:"このカードはフィールド上に表側表示で存在する限り、光属性としても扱う。\nこのカードは１ターンに１度だけ、戦闘では破壊されない。\nこのカードがアドバンス召喚に成功した時、デッキからレベル３モンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スフィラスレディ",h:"すふぃらすれでぃ",e:"Spherous Lady",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["通常"],ma:"",tp:"",at:400,de:1400,tx:"美女と思い近づくと、首筋を噛まれ全身の血を吸われてしまう。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スフィンクス・アンドロジュネス",h:"すふぃんくす・あんどろじゅねす",e:"Theinen the Great Sphinx",ra:"SR",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"獣族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3500,de:3000,tx:"このカードは通常召喚できない。\n自分フィールド上の「アンドロ・スフィンクス」と「スフィンクス・テーレイア」が同時に破壊された時、５００ライフポイントを払う事でのみ手札またはデッキから特殊召喚する事ができる。\nこのカードが特殊召喚に成功した時、５００ライフポイントを払う事で、エンドフェイズ終了時までこのカードの攻撃力は３０００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スフィンクス・テーレイア",h:"すふぃんくす・てーれいあ",e:"Sphinx Teleia",ra:"R",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:2500,de:3000,tx:"フィールド上に「光のピラミッド」が存在する場合、５００ライフポイントを払う事でこのカードを手札から特殊召喚する事ができる。\nこのカードは召喚・特殊召喚したターンに攻撃をする事ができない。\nこのカードは墓地からの特殊召喚はできない。\nこのカードが戦闘によって守備表示モンスターを破壊した場合、破壊したモンスターの守備力の半分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スプリット・D・ローズ",h:"すぷりっと・でもん・ろーず",e:"Regenerating Rose",ra:"SR",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:0,de:1500,tx:"このカードが戦闘によって破壊され墓地へ送られた時、自分フィールド上に「D・ローズトークン」（植物族・闇・星３・攻／守１２００）を２体特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スプレンディッド・ローズ",h:"すぷれんでぃっど・ろーず",e:"Splendid Rose",ra:"SR",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"植物族",mo:["シンクロ","効果"],ma:"",tp:"",at:2200,de:2000,tx:"チューナー＋チューナー以外のモンスター１体以上\n１ターンに１度、自分の墓地の植物族モンスター１体をゲームから除外して発動できる。\n相手フィールド上のモンスター１体を選択し、その攻撃力をエンドフェイズ時まで半分にする。\nまた、このカードが攻撃したバトルフェイズ中に、自分の墓地の植物族モンスター１体をゲームから除外して発動できる。\nこのカードの攻撃力をエンドフェイズ時まで半分にし、そのバトルフェイズ中、このカードはもう１度だけ攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スペア・ジェネクス",h:"すぺあ・じぇねくす",e:"Genex Spare",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:1200,tx:"１ターンに１度、自分フィールド上にこのカード以外の「ジェネクス」と名のついたモンスターが存在する場合に発動できる。\nこのカードのカード名はエンドフェイズ時まで「ジェネクス・コントローラー」として扱う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スペースタイムポリス",h:"すぺーすたいむぽりす",e:"Space-Time Police",ra:"N",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:2300,de:1500,tx:"このカードが特殊召喚に成功した時、相手フィールド上に表側表示で存在するカード１枚を選択してゲームから除外する。\nこのカードがフィールド上から離れた時、このカードの効果でゲームから除外したカードを相手フィールド上にセットする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スペースマンボウ",h:"すぺーすまんぼう",e:"Space Mambo",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["通常"],ma:"",tp:"",at:1700,de:1000,tx:"広大な銀河を漂う宇宙マンボウ。\nアルファード４の超文明遺跡から発見されたという生きた化石。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スポーア",h:"すぽーあ",e:"Spore",ra:"SR",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"植物族",mo:["チューナー","効果"],ma:"",tp:"",at:400,de:800,tx:"このカード名の効果はデュエル中に１度しか使用できない。\n①：このカードが墓地に存在する場合、自分の墓地からこのカード以外の植物族モンスター１体を除外して発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードのレベルは除外したモンスターのレベル分だけ上がる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スモール・ピース・ゴーレム",h:"すもーる・ぴーす・ごーれむ",e:"Small Piece Golem",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1100,de:0,tx:"自分フィールド上に「ビッグ・ピース・ゴーレム」が表側表示で存在する場合にこのカードが召喚・反転召喚・特殊召喚に成功した時、自分のデッキから「ミッド・ピース・ゴーレム」１体を特殊召喚する事ができる。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"針三千本",h:"すりーさうざんど・にーどる",e:"Three Thousand Needles",ra:"N",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:3000,de:1800,tx:"守備表示のこのカードが攻撃を受けた場合、このカードの守備力が相手攻撃モンスターの攻撃力を超えていれば、ダメージステップ終了時にその攻撃モンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スリーピィ",h:"すりーぴぃ",e:"Mystical Sheep #2",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:800,de:1000,tx:"しっぽの長いひつじ。\nしっぽを使い催眠術をかけ、睡魔を誘う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スレイブ・エイプ",h:"すれいぶ・えいぷ",e:"Test Ape",ra:"R",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:700,de:300,tx:"このカードが戦闘によって破壊され墓地へ送られた時、デッキから「剣闘獣」と名のついたレベル４以下のモンスター１体を自分フィールド上に特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スレイブタイガー",h:"すれいぶたいがー",e:"Test Tiger",ra:"SR",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:600,de:300,tx:"①：自分フィールドに「剣闘獣」モンスターが存在する場合、このカードは手札から特殊召喚できる。\n②：このカードをリリースし、自分フィールドの「剣闘獣」モンスター１体を対象として発動できる。\nその自分の「剣闘獣」モンスターを持ち主のデッキに戻し、デッキから「剣闘獣」モンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターは、「剣闘獣」モンスターの効果で特殊召喚した扱いとなる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スロットマシーンAM-7",h:"すろっとましーんえーえむ－７",e:"Slot Machine",ra:"N",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:2000,de:2300,tx:"スロットに揃う数で能力を変化させる事ができるという機械。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ZW－阿修羅副腕",h:"ぜある・うぇぽん－あしゅら・ぶろー",e:"ZW - Asura Strike",ra:"SR",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"①：「ZW－阿修羅副腕」は自分フィールドに１枚しか表側表示で存在できない。\n②：自分フィールドの「希望皇ホープ」モンスター１体を対象として発動できる。\n自分の手札・フィールドからこのカードを攻撃力１０００アップの装備カード扱いとしてその自分の「希望皇ホープ」モンスターに装備する。\n③：このカードが装備されている場合、装備モンスターは相手フィールドの全てのモンスターに１回ずつ攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ZW－玄武絶対聖盾",h:"ぜある・うぇぽん－あるてぃめっと・しーるど",e:"ZW - Ultimate Shield",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:0,de:2000,tx:"このカードが召喚・特殊召喚に成功した時、ゲームから除外されている自分のエクシーズモンスター１体を選択して表側守備表示で特殊召喚できる。\nまた、自分のメインフェイズ時、フィールド上のこのモンスターを、守備力２０００ポイントアップの装備カード扱いとして自分フィールド上の「希望皇ホープ」と名のついたモンスターに装備できる。\n「ZW－玄武絶対聖盾」は自分フィールド上に１枚しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ZW－荒鷲激神爪",h:"ぜある・うぇぽん－いーぐる・くろー",e:"ZW - Eagle Claw",ra:"R",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2000,de:1200,tx:"①：「ZW－荒鷲激神爪」は自分フィールドに１枚しか表側表示で存在できない。\n②：自分のLPが相手より２０００以上少ない場合、このカードは手札から特殊召喚できる。\n③：自分フィールドの「希望皇ホープ」モンスター１体を対象として発動できる。\n自分フィールドのこのカードを攻撃力２０００アップの装備カード扱いとしてその自分の「希望皇ホープ」モンスターに装備する。\n④：このカードが装備されている場合、１ターンに１度、相手フィールドで発動した罠カードの効果を無効にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ZW－極星神馬聖鎧",h:"ぜある・うぇぽん－すれいぷにーる・めいる",e:"ZW - Sleipnir Mail",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"①：「ZW－極星神馬聖鎧」は自分フィールドに１枚しか表側表示で存在できない。\n②：自分フィールドの「希望皇ホープ」モンスター１体を対象として発動できる。\n自分の手札・フィールドからこのカードを攻撃力１０００アップの装備カード扱いとしてその自分の「希望皇ホープ」モンスターに装備する。\n③：装備モンスターが相手によって破壊された事でこのカードが墓地へ送られた時、自分の墓地の「希望皇ホープ」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ZW－風神雲龍剣",h:"ぜある・うぇぽん－とるねーど・ぶりんがー",e:"ZW - Tornado Bringer",ra:"R",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1300,de:1800,tx:"①：「ZW－風神雲龍剣」は自分フィールドに１枚しか表側表示で存在できない。\n②：自分フィールドの「希望皇ホープ」モンスター１体を対象として発動できる。\n自分の手札・フィールドからこのカードを攻撃力１３００アップの装備カード扱いとしてその自分の「希望皇ホープ」モンスターに装備する。\n③：このカードが装備されている場合、相手は装備モンスターを効果の対象にできない。\n④：装備モンスターが戦闘で破壊される場合、代わりにこのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ZW－不死鳥弩弓",h:"ぜある・うぇぽん－ふぇにっくす・ぼう",e:"ZW - Phoenix Bow",ra:"N",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1100,de:0,tx:"このカードは手札から装備カード扱いとして自分フィールド上の「CNo.39 希望皇ホープレイ」に装備できる。\nこの効果によってこのカードを装備したモンスターの攻撃力は１１００ポイントアップする。\nまた、装備モンスターが戦闘によって相手モンスターを破壊した時、相手ライフに１０００ポイントダメージを与える。\n「ZW－不死鳥弩弓」は自分フィールド上に１枚しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ZW－一角獣皇槍",h:"ぜある・うぇぽん－ゆにこーん・きんぐ・すぴあ",e:"ZW - Unicorn Spear",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1900,de:0,tx:"自分のメインフェイズ時、手札のこのカードを攻撃力１９００ポイントアップの装備カード扱いとして自分フィールド上の「CNo.39 希望皇ホープレイ」に装備できる。\n装備モンスターが相手モンスターと戦闘を行う場合、バトルフェイズの間だけその相手モンスターの効果は無効化される。\n「ZW－一角獣皇槍」は自分フィールド上に１枚しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ZW－獣王獅子武装",h:"ぜある・うぇぽん－らいお・あーむず",e:"ZW - Leo Arms",ra:"UR",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"獣族",mo:["エクシーズ","効果"],ma:"",tp:"",at:3000,de:1200,tx:"レベル５モンスター×２\nこのカードは直接攻撃できない。\n１ターンに１度、このカードのエクシーズ素材を１つ取り除く事で、デッキから「ZW」と名のついたモンスター１体を手札に加える。\nまた、フィールド上のこのモンスターを、攻撃力３０００ポイントアップの装備カード扱いとして自分フィールド上の「希望皇ホープ」と名のついたモンスターに装備できる。\n装備モンスターが攻撃したバトルフェイズ中に、装備されているこのカードを墓地へ送る事で、そのバトルフェイズ中、装備モンスターは相手モンスターにもう１度だけ攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ZW－雷神猛虎剣",h:"ぜある・うぇぽん－らいとにんぐ・ぶれーど",e:"ZW - Lightning Blade",ra:"R",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1200,de:2000,tx:"①：「ZW－雷神猛虎剣」は自分フィールドに１枚しか表側表示で存在できない。\n②：自分フィールドの「希望皇ホープ」モンスター１体を対象として発動できる。\n自分の手札・フィールドからこのカードを攻撃力１２００アップの装備カード扱いとしてその自分の「希望皇ホープ」モンスターに装備する。\n③：このカードが装備されている場合、自分フィールドの「ZW」カードは相手の効果では破壊されない。\n④：装備モンスターが効果で破壊される場合、代わりにこのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"星遺物－『星鎧』",h:"せいいぶつ－『せいがい』",e:"World Legacy - \"World Armor\"",ra:"UR",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2500,de:2500,tx:"このカード名の①②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：モンスターが反転召喚に成功した時に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「星遺物」カード１枚を手札に加える。\n③：通常召喚したこのカードが存在する場合、EXデッキから特殊召喚された相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターとこのカードを持ち主の手札に戻す。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"星遺物－『星杖』",h:"せいいぶつ－『せいじょう』",e:"World Legacy - \"World Wand\"",ra:"N",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:500,de:2500,tx:"このカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：通常召喚したこのカードはEXデッキから特殊召喚されたモンスターとの戦闘では破壊されない。\n②：このカードが墓地へ送られた場合に発動できる。\n手札から「星遺物」モンスター１体を特殊召喚する。\n③：墓地のこのカードを除外し、除外されている自分の「オルフェゴール」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は闇属性モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"星遺物－『星杯』",h:"せいいぶつ－『せいはい』",e:"World Legacy - \"World Chalice\"",ra:"N",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：EXデッキからモンスターが特殊召喚された場合、このカードをリリースして発動できる。\nそのモンスターを墓地へ送る。\n②：通常召喚した表側表示のこのカードがフィールドから離れた場合に発動できる。\nデッキから「星遺物－『星杯』」以外の「星杯」モンスター２体を特殊召喚する。\n③：墓地のこのカードを除外して発動できる。\nデッキから「星遺物」カード１枚を手札に加える。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"星遺物の守護竜メロダーク",h:"せいいぶつのしゅごりゅうめろだーく",e:"World Legacy Guardragon Mardark",ra:"N",t:"monster",a:"風",l:9,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2600,de:3000,tx:"このカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の手札・墓地から通常モンスター２体を除外して発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードがモンスターゾーンに存在する限り、相手フィールドのモンスターの攻撃力・守備力は、自分フィールドのドラゴン族モンスターの数×５００ダウンする。\n③：フィールドのこのカードが戦闘・効果で破壊された場合に発動できる。\nこのカードとは元々の種族・属性が異なるレベル９モンスター１体を自分の墓地から選んで手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"セイヴァー・スター・ドラゴン",h:"せいう゛ぁー・すたー・どらごん",e:"Majestic Star Dragon",ra:"UR",t:"monster",a:"風",l:10,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:3800,de:3000,tx:"「救世竜 セイヴァー・ドラゴン」＋「スターダスト・ドラゴン」＋チューナー以外のモンスター１体\n相手が魔法・罠・効果モンスターの効果を発動した時、このカードをリリースする事でその発動を無効にし、相手フィールド上のカードを全て破壊する。\n１ターンに１度、相手フィールド上に表側表示で存在するモンスター１体を選択し、その効果をエンドフェイズ時まで無効にできる。\nまた、この効果で無効にしたモンスターに記された効果を、このターンこのカードの効果として１度だけ発動できる。\nエンドフェイズ時、このカードをエクストラデッキに戻し、自分の墓地の「スターダスト・ドラゴン」１体を選択して特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"セイヴァー・デモン・ドラゴン",h:"せいう゛ぁー・でもん・どらごん",e:"Majestic Red Dragon",ra:"UR",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:4000,de:3000,tx:"「救世竜 セイヴァー・ドラゴン」＋「レッド・デーモンズ・ドラゴン」＋チューナー以外のモンスター１体\nこのカードはカードの効果では破壊されない。\nこのカードが攻撃した場合、ダメージ計算後にフィールド上に守備表示で存在するモンスターを全て破壊する。\n１ターンに１度、エンドフェイズ時まで、相手フィールド上に表側表示で存在するモンスター１体を選択してその効果を無効にし、そのモンスターの攻撃力分このカードの攻撃力をアップできる。\nエンドフェイズ時、このカードをエクストラデッキに戻し、自分の墓地の「レッド・デーモンズ・ドラゴン」１体を選択して特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"星間竜パーセク",h:"せいかんりゅうぱーせく",e:"Parsec, the Interstellar Dragon",ra:"R",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"自分フィールド上にレベル８のモンスターが存在する場合、このカードはリリースなしで召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"聖騎士アルトリウス",h:"せいきしあるとりうす",e:"Noble Knight Artorigus",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1800,de:1800,tx:"聖騎士団に所属する聡明な青年騎士。\n導かれるかの如く分け入った森の中、ついに運命にたどり着く。\nそして青年は大きな一歩を踏み出すのだ。\n\n――これは全ての始まりであり、大いなる叙事詩である。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"聖騎士イヴァン",h:"せいきしいう゛ぁん",e:"Noble Knight Iyvanne",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:1600,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが「聖剣」装備魔法カードを装備した場合に発動できる。\n自分フィールドに「聖騎士トークン」（戦士族・光・星４・攻／守１０００）１体を特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は「聖騎士」モンスターしか特殊召喚できない。\n②：このカードが「聖剣」装備魔法カードを装備している限り、このカード以外の自分フィールドの「聖騎士」モンスターの攻撃力は５００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"聖騎士エクター・ド・マリス",h:"せいきしえくたー・ど・まりす",e:"Noble Knight Eachtar",ra:"N",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:2000,tx:"「聖騎士エクター・ド・マリス」の①の効果は１ターンに１度しか使用できない。\n①：自分の墓地の「聖騎士」モンスター２体を除外して発動できる。\nこのカードを手札・墓地から特殊召喚する。\n②：このカードを素材とした「聖騎士」モンスターのS召喚・X召喚は無効化されず、その特殊召喚成功時に相手は魔法・罠・モンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"聖騎士王アルトリウス",h:"せいきしおうあるとりうす",e:"Artorigus, King of the Noble Knights",ra:"UR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2000,de:2000,tx:"レベル４「聖騎士」モンスター×２\n①：このカードがX召喚に成功した時、自分の墓地の「聖剣」装備魔法カードを３枚まで対象として発動できる（同名カードは１枚まで）。\nそのカードをこのカードに装備する。\n②：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\n自分フィールドの「聖剣」装備魔法カードの数まで、フィールドの魔法・罠カードを選んで破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"聖騎士ガウェイン",h:"せいきしがうぇいん",e:"Noble Knight Gawayn",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1900,de:500,tx:"自分フィールド上に光属性の通常モンスターが存在する場合、このカードは手札から表側守備表示で特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"聖騎士ガラハド",h:"せいきしがらはど",e:"Noble Knight Gwalchavad",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:1800,tx:"このカードはフィールド上に表側表示で存在する限り、通常モンスターとして扱う。\nこのカードが「聖剣」と名のついた装備魔法カードを装備している限り、このカードは効果モンスター扱いとなり以下の効果を得る。\n●自分の墓地の「聖騎士」と名のついたモンスター１体を選択して発動できる。\n選択したモンスターを手札に加え、自分フィールド上の「聖剣」と名のついた装備魔法カード１枚を選んで破壊する。\n「聖騎士ガラハド」のこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"聖騎士ジャンヌ",h:"せいきしじゃんぬ",e:"Noble Knight Joan",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1900,de:1300,tx:"①：このカードが攻撃するダメージステップの間、このカードの攻撃力は３００ダウンする。\n②：このカードが相手によって破壊され墓地へ送られた場合、手札を１枚墓地へ送り、自分の墓地のレベル４以下の戦士族モンスター１体を対象として発動できる。\nその戦士族モンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"聖騎士トリスタン",h:"せいきしとりすたん",e:"Noble Knight Drystan",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:800,tx:"「聖騎士トリスタン」の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドにこのカード以外の「聖騎士」モンスターが存在する限り、相手はこのカード以外の自分フィールドの攻撃力１８００未満のモンスターを攻撃対象にできず、効果の対象にもできない。\n②：このカードが「聖剣」装備魔法カードを装備した場合、フィールドの表側表示のカード１枚を対象として発動する。\nその表側表示のカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"聖騎士の三兄弟",h:"せいきしのさんきょうだい",e:"Noble Knight Brothers",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:2400,tx:"①：このカードが召喚に成功した時に発動できる。\n手札から「聖騎士」モンスターを２体まで特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は「聖騎士」モンスターしか特殊召喚できない。\n②：このカードは、自分フィールドのモンスターが「聖騎士」モンスター３体の場合にのみ攻撃できる。\n③：１ターンに１度、自分の墓地の「聖騎士」カード及び「聖剣」カードを合計３枚対象として発動できる。\nそのカード３枚をデッキに加えてシャッフルする。\nその後、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"聖騎士の盾持ち",h:"せいきしのたてもち",e:"Noble Knight's Shield-Bearer",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:800,de:1300,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合、自分の墓地から光属性モンスター１体を除外して発動できる。\n自分はデッキから１枚ドローする。\n②：手札・フィールドのこのカードを除外して発動できる。\nデッキからレベル６以下の獣族・風属性モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"聖騎士の槍持ち",h:"せいきしのやりもち",e:"Noble Knight's Spearholder",ra:"SR",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:800,de:400,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合、自分フィールドのレベル８以下のモンスター１体を対象として発動できる。\nそのモンスターはエンドフェイズまで、カード名を「フルール・シンクロン」として扱い、チューナーとして扱う。\n②：このカードをリリースして発動できる。\nデッキから装備魔法カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"聖騎士パーシヴァル",h:"せいきしぱーしう゛ぁる",e:"Noble Knight Peredur",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1900,de:300,tx:"①：このカードが「聖剣」装備魔法カードを装備している限り、このカードはレベルが１つ上がり闇属性になる。\n②：「聖剣」装備魔法カードを装備したこのカードが墓地へ送られた場合、自分の墓地の「聖剣」カード１枚を対象として発動する。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"聖騎士ベディヴィエール",h:"せいきしべでぃう゛ぃえーる",e:"Noble Knight Bedwyr",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1500,tx:"①：このカードが召喚・特殊召喚に成功した時に発動できる。\nデッキから「聖剣」装備魔法カード１枚を墓地へ送る。\n②：このカードがフィールドに表側表示で存在する限り１度だけ、フィールドの「聖剣」装備魔法カード１枚と、そのカードを装備可能なモンスター１体を対象として発動できる。\nその装備魔法カードを正しい対象となるそのモンスターに移し替える。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"聖騎士ペリノア",h:"せいきしぺりのあ",e:"Noble Knight Pellinore",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1900,de:500,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードに装備されている「聖剣」装備魔法カード１枚と相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのカードを破壊する。\nその後、自分はデッキから１枚ドローする。\nこの効果の発動後、ターン終了時までこのカードは攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"聖騎士ボールス",h:"せいきしぼーるす",e:"Noble Knight Borz",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:900,tx:"①：このカードはモンスターゾーンに存在する限り、通常モンスターとして扱う。\n②：このカードが「聖剣」装備魔法カードを装備している限り、このカードは効果モンスター扱いとなり以下の効果を得る。\n●このカードはレベルが１つ上がり闇属性になる。\n●自分メインフェイズに発動できる。\nデッキから「聖剣」カード３枚を相手に見せ、相手はその中からランダムに１枚選ぶ。\nそのカード１枚を自分の手札に加え、残りを墓地へ送る。\nこのカード名のこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"聖騎士モルドレッド",h:"せいきしもるどれっど",e:"Noble Knight Medraut",ra:"UR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"①：このカードはモンスターゾーンに存在する限り、通常モンスターとして扱う。\n②：このカードが「聖剣」装備魔法カードを装備している限り、このカードは効果モンスター扱いとなり以下の効果を得る。\n●このカードはレベルが１つ上がり闇属性になる。\n●１ターンに１度、自分フィールドにこのカード以外のモンスターが存在しない場合に発動できる。\nデッキから「聖騎士モルドレッド」以外の「聖騎士」モンスター１体を守備表示で特殊召喚し、自分フィールドの装備魔法カード１枚を選んで破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"正義の味方 カイバーマン",h:"せいぎのみかた　かいばーまん",e:"Kaibaman",ra:"UR",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:200,de:700,tx:"このカードをリリースして発動できる。\n手札から「青眼の白龍」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"セイクリッド・アクベス",h:"せいくりっど・あくべす",e:"Constellar Acubens",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:800,de:2000,tx:"このカードが召喚・特殊召喚に成功した時、自分フィールド上の全ての「セイクリッド」と名のついたモンスターの攻撃力は５００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"セイクリッド・アンタレス",h:"せいくりっど・あんたれす",e:"Constellar Antares",ra:"R",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2400,de:900,tx:"このカードが召喚・特殊召喚に成功した時、自分の墓地の「セイクリッド」と名のついたモンスター１体を選択して手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"セイクリッド・エスカ",h:"せいくりっど・えすか",e:"Constellar Zubeneschamali",ra:"SR",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2100,de:1400,tx:"このカードが召喚・特殊召喚に成功した時、デッキから「セイクリッド」と名のついたモンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"セイクリッド・オメガ",h:"せいくりっど・おめが",e:"Constellar Omega",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2400,de:500,tx:"光属性レベル４モンスター×２\n１ターンに１度、このカードのエクシーズ素材を１つ取り除いて発動できる。\n自分フィールド上の全ての「セイクリッド」と名のついたモンスターは、このターン魔法・罠カードの効果を受けない。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"セイクリッド・カウスト",h:"せいくりっど・かうすと",e:"Constellar Kaus",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:700,tx:"このカードはS素材にできない。\n①：フィールドの「セイクリッド」モンスター１体を対象として以下の効果から１つを選択して発動できる。\nこの効果は１ターンに２度まで使用できる。\n\n●対象のモンスターのレベルを１つ上げる。\n\n●対象のモンスターのレベルを１つ下げる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"セイクリッド・グレディ",h:"せいくりっど・ぐれでぃ",e:"Constellar Algiedi",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1600,de:1400,tx:"①：このカードが召喚に成功した時に発動できる。\n手札からレベル４の「セイクリッド」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"セイクリッド・シェアト",h:"せいくりっど・しぇあと",e:"Constellar Siat",ra:"N",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:100,de:1600,tx:"相手フィールド上にモンスターが存在し、自分フィールド上にモンスターが存在しない場合、このカードは手札から特殊召喚できる。\nまた、１ターンに１度、このカード以外の自分のフィールド上・墓地の「セイクリッド」と名のついたモンスター１体を選択して発動できる。\nこのカードは選択したモンスターと同じレベルになる。\nフィールド上のこのカードをエクシーズ素材とする場合、「セイクリッド」と名のついたモンスターのエクシーズ召喚にしか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"セイクリッド・シェラタン",h:"せいくりっど・しぇらたん",e:"Constellar Sheratan",ra:"SR",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:700,de:1900,tx:"①：このカードが召喚に成功した時に発動できる。\nデッキから「セイクリッド」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"セイクリッド・スピカ",h:"せいくりっど・すぴか",e:"Constellar Virgo",ra:"R",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2300,de:1600,tx:"このカードが召喚に成功した時、手札からレベル５の「セイクリッド」と名のついたモンスター１体を表側守備表示で特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"セイクリッド・ソンブレス",h:"せいくりっど・そんぶれす",e:"Constellar Sombre",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1550,de:1600,tx:"「セイクリッド・ソンブレス」の①②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の墓地の「セイクリッド」モンスター１体を除外し、自分の墓地の「セイクリッド」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\n②：このカードの①の効果を適用したターンのメインフェイズに発動できる。\n「セイクリッド」モンスター１体を召喚する。\n③：このカードが墓地へ送られたターン、「セイクリッド」モンスターを召喚する場合に必要なリリースを１体少なくできる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"セイクリッド・ダバラン",h:"せいくりっど・だばらん",e:"Constellar Aldebaran",ra:"R",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1300,de:800,tx:"このカードが召喚に成功した時、手札からレベル３の「セイクリッド」と名のついたモンスター１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"セイクリッド・トレミスM7",h:"せいくりっど・とれみすめしえせぶん",e:"Constellar Ptolemy M7",ra:"UR",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2700,de:2000,tx:"レベル６モンスター×２\nこのカードは「セイクリッド・トレミスM７」以外の自分フィールドの「セイクリッド」Xモンスターの上にこのカードを重ねてX召喚する事もできる。\nこの方法で特殊召喚した場合、このターンこのカードの効果は発動できない。\n①：１ターンに１度、このカードのX素材を１つ取り除き、自分または相手の、フィールド・墓地のモンスター１体を対象として発動できる。\nそのモンスターを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"セイクリッド・ハワー",h:"せいくりっど・はわー",e:"Constellar Rasalhague",ra:"N",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:900,de:100,tx:"このカードをリリースして発動できる。\n自分の手札・墓地から「セイクリッド・ハワー」以外の「セイクリッド」と名のついたモンスター１体を選んで表側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"セイクリッド・ヒアデス",h:"せいくりっど・ひあです",e:"Constellar Hyades",ra:"N",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"獣戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1900,de:1100,tx:"光属性レベル３モンスター×２\n１ターンに１度、このカードのエクシーズ素材を１つ取り除いて発動できる。\n相手フィールド上のモンスターを全て表側守備表示にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"セイクリッド・ビーハイブ",h:"せいくりっど・びーはいぶ",e:"Constellar Praesepe",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2400,de:800,tx:"レベル４「セイクリッド」モンスター×２\n①：１ターンに１度、自分の「セイクリッド」モンスターが戦闘を行うダメージステップ開始時からダメージ計算前までに、このカードのX素材を１つ取り除いて発動できる。\nそのモンスターの攻撃力はターン終了時まで１０００アップする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"セイクリッド・ポルクス",h:"せいくりっど・ぽるくす",e:"Constellar Pollux",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:600,tx:"①：このカードが召喚に成功したターン、自分は通常召喚に加えて１度だけ、自分メインフェイズに「セイクリッド」モンスター１体を召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"セイクリッド・レオニス",h:"せいくりっど・れおにす",e:"Constellar Leonis",ra:"N",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1000,de:1800,tx:"このカードがフィールド上に表側表示で存在する限り、自分のメインフェイズ時に１度だけ、自分は通常召喚に加えて「セイクリッド」と名のついたモンスター１体を召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"セイクリッド・レスカ",h:"せいくりっど・れすか",e:"Constellar Alrescha",ra:"N",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2200,de:1200,tx:"このカードが召喚に成功した時、手札から「セイクリッド」と名のついたモンスター１体を表側守備表示で特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"聖剣を抱く王妃ギネヴィア",h:"せいけんをいだくおうひぎねう゛ぃあ",e:"Gwenhwyfar, Queen of Noble Arms",ra:"N",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:300,de:300,tx:"「聖剣を抱く王妃ギネヴィア」の①の効果は１ターンに１度しか使用できない。\n①：自分フィールドの「聖騎士」モンスター１体を対象として発動できる。\n手札・墓地のこのカードを攻撃力３００アップの装備カード扱いとしてその自分のモンスターに装備する。\n②：装備モンスターの属性によって以下の効果を得る。\n\n●光：装備モンスターが効果で破壊される場合、代わりにこのカードを破壊できる。\n\n●闇：装備モンスターが相手モンスターと戦闘を行うダメージステップ開始時に発動できる。\nそのモンスターを破壊する。\nその後このカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"聖刻神龍－エネアード",h:"せいこくしんりゅう－えねあーど",e:"Hieratic Sun Dragon Overlord of Heliopolis",ra:"UR",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:3000,de:2400,tx:"レベル８モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\n自分の手札・フィールドのモンスターを任意の数だけリリースし、その数だけフィールドのカードを選んで破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"聖刻龍－アセトドラゴン",h:"せいこくりゅう－あせとどらごん",e:"Hieratic Dragon of Eset",ra:"R",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1900,de:1200,tx:"このカードはリリースなしで召喚できる。\nこの方法で召喚したこのカードの元々の攻撃力は１０００になる。\n１ターンに１度、フィールド上のドラゴン族の通常モンスター１体を選択して発動できる。\nフィールド上の全ての「聖刻」と名のついたモンスターのレベルはエンドフェイズ時まで選択したモンスターと同じレベルになる。\nまた、このカードがリリースされた時、自分の手札・デッキ・墓地からドラゴン族の通常モンスター１体を選び、攻撃力・守備力を０にして特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"聖刻龍－ウシルドラゴン",h:"せいこくりゅう－うしるどらごん",e:"Hieratic Dragon of Asar",ra:"R",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2600,de:700,tx:"このカードは自分の墓地のドラゴン族・光属性モンスターとドラゴン族の通常モンスターを１体ずつゲームから除外し、手札から特殊召喚できる。\nフィールド上のこのカードが破壊される場合、代わりにこのカード以外の自分フィールド上に表側表示で存在する「聖刻」と名のついたモンスター１体をリリースできる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"聖刻龍－シユウドラゴン",h:"せいこくりゅう－しゆうどらごん",e:"Hieratic Dragon of Su",ra:"R",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2200,de:1000,tx:"①：このカードは自分フィールドの「聖刻」モンスター１体をリリースして手札から特殊召喚できる。\n②：１ターンに１度、このカード以外の自分の手札・フィールドの「聖刻」モンスター１体をリリースし、相手フィールドの魔法・罠カード１枚を対象として発動できる。\nその相手のカードを破壊する。\n③：このカードがリリースされた場合に発動する。\n自分の手札・デッキ・墓地からドラゴン族の通常モンスター１体を選び、攻撃力・守備力を０にして特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"聖刻龍－セテクドラゴン",h:"せいこくりゅう－せてくどらごん",e:"Hieratic Dragon of Sutekh",ra:"R",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2800,de:2000,tx:"このカードは通常召喚できない。\n自分の墓地のドラゴン族の通常モンスター３体をゲームから除外した場合に特殊召喚できる。\n１ターンに１度、自分の墓地のドラゴン族モンスター１体をゲームから除外する事で、フィールド上のカード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"聖刻龍－トフェニドラゴン",h:"せいこくりゅう－とふぇにどらごん",e:"Hieratic Dragon of Tefnuit",ra:"SR",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2100,de:1400,tx:"①：相手フィールドにのみモンスターが存在する場合、このカードは手札から特殊召喚できる。\nこの方法で特殊召喚したターン、このカードは攻撃できない。\n②：このカードがリリースされた場合に発動する。\n自分の手札・デッキ・墓地からドラゴン族の通常モンスター１体を選び、攻撃力・守備力を０にして特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"聖刻龍－ドラゴンゲイヴ",h:"せいこくりゅう－どらごんげいう゛",e:"Hieratic Dragon of Gebeb",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1800,de:400,tx:"このカードが戦闘によって相手モンスターを破壊し墓地へ送った時、自分の手札・デッキ・墓地からドラゴン族の通常モンスター１体を選び、攻撃力・守備力を０にして特殊召喚する。\nまた、このカードがリリースされた時、自分の手札・デッキ・墓地から「聖刻」と名のついた通常モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"聖刻龍－ドラゴンヌート",h:"せいこくりゅう－どらごんぬーと",e:"Hieratic Dragon of Nuit",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1700,de:900,tx:"フィールド上に表側表示で存在するこのカードが魔法・罠・効果モンスターの効果の対象になった時に発動する。\n自分の手札・デッキ・墓地からドラゴン族の通常モンスター１体を選び、攻撃力・守備力を０にして特殊召喚する。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"聖刻龍－ネフテドラゴン",h:"せいこくりゅう－ねふてどらごん",e:"Hieratic Dragon of Nebthet",ra:"R",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2000,de:1600,tx:"このカードは自分フィールド上の「聖刻」と名のついたモンスター１体をリリースして手札から特殊召喚できる。\n１ターンに１度、このカード以外の自分の手札・フィールド上の「聖刻」と名のついたモンスター１体をリリースする事で、相手フィールド上のモンスター１体を選択して破壊する。\nまた、このカードがリリースされた時、自分の手札・デッキ・墓地からドラゴン族の通常モンスター１体を選び、攻撃力・守備力を０にして特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"聖刻龍王－アトゥムス",h:"せいこくりゅうおう－あとぅむす",e:"Hieratic Dragon King of Atum",ra:"SR",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2400,de:2100,tx:"ドラゴン族レベル６モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\nデッキからドラゴン族モンスター１体を選び、攻撃力・守備力を０にして特殊召喚する。\nこの効果を発動するターン、このカードは攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"静寂のサイコウィッチ",h:"せいじゃくのさいこうぃっち",e:"Serene Psychic Witch",ra:"SR",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1400,de:1200,tx:"フィールド上に存在するこのカードが破壊され墓地へ送られた時、自分のデッキから攻撃力２０００以下のサイキック族モンスター１体をゲームから除外する事ができる。\n次のスタンバイフェイズ時、この効果で除外したモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"静寂の聖者",h:"せいじゃくのせいじゃ",e:"Sage of Silence",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1400,de:1000,tx:"このカードが戦闘によって相手モンスターを破壊した場合、相手プレイヤーは次のターン魔法カードを発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"聖獣セルケト",h:"せいじゅうせるけと",e:"Mystical Beast of Serket",ra:"SR",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2500,de:2000,tx:"①：自分フィールドに「王家の神殿」が存在しない場合にこのカードは破壊される。\n②：このカードがモンスターゾーンに存在する限り、このカードが戦闘で破壊したモンスターは除外される。\n③：このカードが戦闘でモンスターを破壊した場合に発動する。\nこのカードの攻撃力は５００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"精神寄生体",h:"せいしんきせいたい",e:"Kiseitai",ra:"R",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:300,de:800,tx:"フィールド上に裏側守備表示で存在するこのカードが相手モンスターに攻撃された場合、そのダメージ計算前にこのカードは攻撃モンスターの装備カードになる。\n相手のスタンバイフェイズ毎に、このカードの装備モンスターの攻撃力の半分の数値分、自分のライフポイントを回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"聖戦士カオス・ソルジャー",h:"せいせんしかおす・そるじゃー",e:"Black Luster Soldier - Sacred Soldier",ra:"UR",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:3000,de:2500,tx:"「聖戦士カオス・ソルジャー」の①の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した時、除外されている自分の光属性または闇属性のモンスター１体と相手フィールドのカード１枚を対象として発動できる。\nその自分のカードを墓地に戻し、その相手のカードを除外する。\n②：このカードが戦闘で相手モンスターを破壊した時、自分の墓地のレベル７以下の戦士族モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"聖占術姫タロットレイ",h:"せいせんじゅつきたろっとれい",e:"Prediction Princess Tarotrei",ra:"R",t:"monster",a:"光",l:9,ps:"",pe:"",tr:"天使族",mo:["儀式","効果"],ma:"",tp:"",at:2700,de:1200,tx:"「聖占術の儀式」により降臨。\n「聖占術姫タロットレイ」の①②の効果は１ターンに１度、いずれか１つしか使用できず、相手ターンでも発動できる。\n①：フィールドの裏側表示モンスター１体を対象として発動できる。\nそのモンスターを表側攻撃表示にする。\n②：フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターを裏側守備表示にする。\n③：自分エンドフェイズに発動できる。\n自分の手札・墓地のリバースモンスター１体を選んで裏側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"星態龍",h:"せいたいりゅう",e:"Star Eater",ra:"SR",t:"monster",a:"光",l:11,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:3200,de:2800,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカードはS召喚でしか特殊召喚できない。\n①：このカードのS召喚は無効化されない。\n②：このカードのS召喚成功時にお互いは魔法・罠・モンスターの効果を発動できない。\n③：このカードは攻撃する場合、ダメージステップ終了時まで他のカードの効果を受けない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"聖鳥クレイン",h:"せいちょうくれいん",e:"Sacred Crane",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1600,de:400,tx:"①：このカードが特殊召喚に成功した場合に発動する。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"晴天気ベンガーラ",h:"せいてんきべんがーら",e:"The Weather Painter Sun",ra:"R",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1600,de:400,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが墓地に存在する場合、自分フィールドの表側表示の永続魔法・永続罠カード１枚を墓地へ送って発動できる。\nこのカードを守備表示で特殊召喚し、手札から「天気」魔法・罠カード１枚を選んで自分の魔法＆罠ゾーンに表側表示で置く。\n②：フィールドのこのカードが「天気」カードの効果を発動するために除外された場合、次のターンのスタンバイフェイズに発動できる。\n除外されているこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"聖なる鎖",h:"せいなるくさり",e:"Mystical Capture Chain",ra:"N",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:700,de:700,tx:"聖なる力で、動きを封じることができると言われている鎖。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"セイバー・シャーク",h:"せいばー・しゃーく",e:"Saber Shark",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"このカードはシンクロ素材にできない。\n自分のメインフェイズ時に、フィールド上の魚族モンスター１体を選択し、以下の効果から１つを選択して発動できる。\nこの効果は１ターンに２度まで使用できる。\nこの効果を発動するターン、自分は水属性以外のモンスターを特殊召喚できない。\n\n●選択したモンスターのレベルを１つ上げる。\n\n●選択したモンスターのレベルを１つ下げる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"セイバー・ビートル",h:"せいばー・びーとる",e:"Saber Beetle",ra:"R",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:2400,de:600,tx:"このカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"セイバーザウルス",h:"せいばーざうるす",e:"Sabersaurus",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"恐竜族",mo:["通常"],ma:"",tp:"",at:1900,de:500,tx:"おとなしい性格で有名な恐竜。\n大草原の小さな巣でのんびりと過ごすのが好きという。\n怒ると恐い。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"星杯に誘われし者",h:"せいはいにいざなわれしもの",e:"Beckoned by the World Chalice",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1800,de:0,tx:"機怪との戦いに明け暮れる青年。\n\n森の周辺に生息する機怪蟲が突如凶暴化した際にも、一歩も引かずに結界への侵入を防ぎ続けた。\n\n常に先陣を駆けるその雄姿は森の民を奮い立たせるが、本人はたった一人の妹を守る為だけにその槍を振るっている。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"星杯に選ばれし者",h:"せいはいにえらばれしもの",e:"Chosen by the World Chalice",ra:"N",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"サイキック族",mo:["通常"],ma:"",tp:"",at:1600,de:0,tx:"機怪の残骸で武装する、真っ直ぐな心の少年。\n\n星辰の森に古くから伝わる『星の勇者』に憧れており、妖精リースの願いを受けて、光を授かった仲間たちと共に七つの星遺物を解き放つ旅に出る。\n\n\n“星明かりの勇者 掲げし剣に光を束ね 大いなる闇を討ち祓わん”",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"星杯の妖精リース",h:"せいはいのようせいりーす",e:"Lee the World Chalice Fairy",ra:"N",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:100,de:2000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「星杯」モンスター１体を手札に加える。\n②：このカードが墓地に存在する場合、自分の手札・フィールドのモンスター１体を墓地へ送って発動できる。\n墓地のこのカードを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"星杯を戴く巫女",h:"せいはいをいただくみこ",e:"Crowned by the World Chalice",ra:"N",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:0,de:2100,tx:"星神に鎮魂の祈りを捧げる巫女。\n\n手にした杖は代々受け継がれし祭器であり、力を結界に変えて機界騎士による支配から森の民を守護している。\n\n森の守護竜が懐く程の神通力をその身に秘めているが、普段は兄と幼馴染を大切に想う、心優しい少女の顔を見せる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"青竜の召喚士",h:"せいりゅうのしょうかんし",e:"Blue Dragon Summoner",ra:"SR",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1500,de:600,tx:"①：このカードがフィールドから墓地へ送られた場合に発動できる。\nデッキからドラゴン族・戦士族・魔法使い族の通常モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"青竜の忍者",h:"せいりゅうのにんじゃ",e:"Blue Dragon Ninja",ra:"R",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:2100,de:1200,tx:"１ターンに１度、手札から「忍者」と名のついたモンスター１体と「忍法」と名のついたカード１枚を捨て、相手フィールド上に表側表示で存在するモンスター１体を選択して発動できる。\nこのターン、選択したモンスターは攻撃できず、効果は無効化される。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"精霊獣 アペライオ",h:"せいれいじゅう　あぺらいお",e:"Spiritual Beast Apelio",ra:"N",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:1800,de:200,tx:"自分は「精霊獣 アペライオ」を１ターンに１度しか特殊召喚できない。\n①：１ターンに１度、自分の墓地の「霊獣」カード１枚を除外して発動できる。\nこのターン中、以下の効果を適用する。\nこの効果は相手ターンでも発動できる。\n●自分フィールドの「霊獣」モンスターの攻撃力・守備力は５００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"精霊獣 カンナホーク",h:"せいれいじゅう　かんなほーく",e:"Spiritual Beast Cannahawk",ra:"SR",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:1400,de:600,tx:"自分は「精霊獣 カンナホーク」を１ターンに１度しか特殊召喚できない。\n①：１ターンに１度、自分メインフェイズに発動できる。\nデッキから「霊獣」カード１枚を除外する。\n発動後２回目の自分スタンバイフェイズに、この効果で除外したカードを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"精霊獣 ペトルフィン",h:"せいれいじゅう　ぺとるふぃん",e:"Spiritual Beast Pettlephin",ra:"R",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:0,de:2000,tx:"自分は「精霊獣 ペトルフィン」を１ターンに１度しか特殊召喚できない。\n①：１ターンに１度、手札の「霊獣」カード１枚を除外し、相手フィールドのカード１枚を対象として発動できる。\nそのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"精霊獣 ラムペンタ",h:"せいれいじゅう　らむぺんた",e:"Spiritual Beast Rampengu",ra:"R",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1600,de:400,tx:"自分は「精霊獣 ラムペンタ」を１ターンに１度しか特殊召喚できない。\n①：１ターンに１度、自分メインフェイズに発動できる。\nエクストラデッキから「霊獣」モンスター１体を除外し、そのモンスターと同じ種族の「霊獣」モンスター１体をデッキから墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"聖霊獣騎 アペライオ",h:"せいれいじゅうき　あぺらいお",e:"Ritual Beast Ulti-Apelio",ra:"UR",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"炎族",mo:["融合","効果"],ma:"",tp:"",at:2600,de:400,tx:"「霊獣使い」モンスター＋「精霊獣」モンスター\n自分フィールドの上記カードを除外した場合のみ特殊召喚できる（「融合」は必要としない）。\n①：このカードは攻撃する場合、ダメージステップ終了時までこのカード以外のカードの効果を受けない。\n②：このカードを持ち主のEXデッキに戻し、除外されている自分の、「霊獣使い」モンスター１体と「精霊獣」モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"聖霊獣騎 カンナホーク",h:"せいれいじゅうき　かんなほーく",e:"Ritual Beast Ulti-Cannahawk",ra:"R",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"雷族",mo:["融合","効果"],ma:"",tp:"",at:1400,de:1600,tx:"「霊獣使い」モンスター＋「精霊獣」モンスター\n自分フィールドの上記カードを除外した場合のみ特殊召喚できる（「融合」は必要としない）。\n①：１ターンに１度、除外されている自分の「霊獣」カード２枚を対象として発動できる。\nそのカードを墓地へ戻し、デッキから「霊獣」カード１枚を手札に加える。\n②：このカードを持ち主のEXデッキに戻し、除外されている自分の、「霊獣使い」モンスター１体と「精霊獣」モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"聖霊獣騎 ペトルフィン",h:"せいれいじゅうき　ぺとるふぃん",e:"Ritual Beast Ulti-Pettlephin",ra:"R",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"水族",mo:["融合","効果"],ma:"",tp:"",at:200,de:2800,tx:"「霊獣使い」モンスター＋「精霊獣」モンスター\n自分フィールドの上記カードを除外した場合のみ特殊召喚できる（「融合」は必要としない）。\n①：このカードは効果では破壊されない。\n②：このカードをエクストラデッキに戻し、除外されている自分の「霊獣使い」モンスター１体と「精霊獣」モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"精霊獣使い ウィンダ",h:"せいれいじゅうつかい　うぃんだ",e:"Spiritual Beast Tamer Winda",ra:"UR",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1600,de:1800,tx:"自分は「精霊獣使い ウィンダ」を１ターンに１度しか特殊召喚できない。\n①：このカードが相手によって破壊された場合に発動できる。\nデッキまたはエクストラデッキから「霊獣」モンスター１体を召喚条件を無視して特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"セイレーン",h:"せいれーん",e:"Ill Witch",ra:"N",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:1600,de:1500,tx:"風を操り突風をまきおこし、ありとあらゆる物を吹き飛ばす。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"聖なる解呪師",h:"せいんと・でぃすえんちゃんたー",e:"Disenchanter",ra:"R",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2000,de:2300,tx:"①：１ターンに１度、フィールドの表側表示の魔法カード１枚を対象として発動できる。\nフィールドの魔力カウンターを１つ取り除き、対象の表側表示のカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"聖光の宣告者",h:"せいんと・でくれあらー",e:"Herald of Pure Light",ra:"UR",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"天使族",mo:["エクシーズ","効果"],ma:"",tp:"",at:600,de:1000,tx:"レベル２モンスター×２\nこのカード名の効果は１ターンに１度しか使用できない。\n①：このカードのX素材を１つ取り除き、自分の墓地のモンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\nその後、手札を１枚選んでデッキに戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"セイント・バード",h:"せいんと・ばーど",e:"Faith Bird",ra:"N",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["通常"],ma:"",tp:"",at:1500,de:1100,tx:"非常に尾の長い鳥。\n全身から聖なる光を発する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"聖なる魔術師",h:"せいんと・まじしゃん",e:"Magician of Faith",ra:"UR",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["リバース","効果"],ma:"",tp:"",at:300,de:400,tx:"①：このカードがリバースした場合、自分の墓地の魔法カード１枚を対象として発動する。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"聖導騎士イシュザーク",h:"せいんとないといしゅざーく",e:"Divine Knight Ishzark",ra:"SR",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2300,de:1800,tx:"このカードが戦闘によってモンスターを破壊した時、そのモンスターをゲームから除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゼータ・レティキュラント",h:"ぜーた・れてぃきゅらんと",e:"Zeta Reticulant",ra:"R",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2400,de:2100,tx:"①：このカードが墓地に存在し、相手フィールドのモンスターが除外される度に発動する。\n自分フィールドに「イーバトークン」（悪魔族・闇・星２・攻／守５００）１体を特殊召喚する。\n②：このカードは自分フィールドの「イーバトークン」１体をリリースし、手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"セカンド・ブースター",h:"せかんど・ぶーすたー",e:"Second Booster",ra:"N",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:500,tx:"このカードをリリースし、自分フィールド上に表側攻撃表示で存在するモンスター１体を選択して発動する。\n選択したモンスターの攻撃力は、エンドフェイズ時まで１５００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"隻眼のスキル・ゲイナー",h:"せきがんのすきる・げいなー",e:"One-Eyed Skill Gainer",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2500,de:2600,tx:"レベル４モンスター×３\nこのカードのエクシーズ素材を１つ取り除き、相手フィールド上のエクシーズモンスター１体を選択して発動できる。\nこのカードは選択したモンスターと同名カードとして扱い、同じ効果を得る。\nこの効果はこのカードがフィールド上に表側表示で存在する限り１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"隻眼のホワイトタイガー",h:"せきがんのほわいとたいがー",e:"The All-Seeing White Tiger",ra:"N",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:1300,de:500,tx:"ある者には恐怖、ある者には尊敬の対象とされている、気高き密林の王者。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"赤竜の忍者",h:"せきりゅうのにんじゃ",e:"Red Dragon Ninja",ra:"R",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2400,de:1200,tx:"このカードが召喚・反転召喚・特殊召喚に成功した時、自分の墓地の「忍者」または「忍法」と名のついたカード１枚をゲームから除外し、相手フィールド上にセットされたカード１枚を選択して発動できる。\n選択したカードを確認し、持ち主のデッキの一番上または一番下に戻す。\nこの効果の発動に対して相手は選択されたカードを発動できない。\n「赤竜の忍者」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"セコンド・ゴブリン",h:"せこんど・ごぶりん",e:"Second Goblin",ra:"R",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["ユニオン","効果"],ma:"",tp:"",at:100,de:100,tx:"１ターンに１度だけ自分のメインフェイズに装備カード扱いとして自分の「ジャイアント・オーク」に装備、または装備を解除して表側攻撃表示で特殊召喚する事ができる。\nこの効果で装備カード扱いになっている時のみ、装備モンスターの表示形式を１ターンに１度だけ変更する事ができる。\n（１体のモンスターが装備できるユニオンは１枚まで。\n装備モンスターが戦闘によって破壊される場合は、代わりにこのカードを破壊する。\n）",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"絶海の騎士",h:"ぜっかいのきし",e:"Shore Knight",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1400,de:1200,tx:"フィールド上に表側表示で存在するこのカードの表示形式が変更された時、デッキから水属性モンスター１体を墓地へ送る。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"絶対服従魔人",h:"ぜったいふくじゅうまじん",e:"Ultimate Obedient Fiend",ra:"N",t:"monster",a:"炎",l:10,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:3500,de:3000,tx:"自分フィールド上にこのカードだけしかなく、手札が０枚でなければこのカードは攻撃できない。\nこのカードが破壊した効果モンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"絶対防御将軍",h:"ぜったいぼうぎょしょうぐん",e:"Total Defense Shogun",ra:"SR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1550,de:2500,tx:"このカードは召喚・反転召喚に成功した時、守備表示になる。\nフィールド上に表側守備表示で存在するこのカードは、守備表示の状態で攻撃する事ができる。\nその場合、攻撃力の数値を適用してダメージ計算を行う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"Z－メタル・キャタピラー",h:"ぜっと－めたる・きゃたぴらー",e:"Z-Metal Tank",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["ユニオン","効果"],ma:"",tp:"",at:1500,de:1300,tx:"①：１ターンに１度、以下の効果から１つを選択して発動できる。\n●自分フィールドの「X－ヘッド・キャノン」または「Y－ドラゴン・ヘッド」１体を対象とし、このカードを装備カード扱いとしてそのモンスターに装備する。\n装備モンスターが戦闘・効果で破壊される場合、代わりにこのカードを破壊する。\n●装備されているこのカードを特殊召喚する。\n②：装備モンスターの攻撃力・守備力は６００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"X・E・N・O",h:"ぜの",e:"Jowls of Dark Demise",ra:"R",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"悪魔族",mo:["リバース","効果"],ma:"",tp:"",at:200,de:100,tx:"リバース：相手フィールド上に存在するモンスター１体を選択し、エンドフェイズ時までコントロールを得る。\nこの効果でコントロールを得たモンスターは、このターン相手プレイヤーに直接攻撃する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"セブン・ソード・ウォリアー",h:"せぶん・そーど・うぉりあー",e:"Seven Swords Warrior",ra:"UR",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2300,de:1800,tx:"チューナー＋チューナー以外のモンスター１体以上\n１ターンに１度、このカードに装備カードが装備された時、相手ライフに８００ポイントダメージを与える。\nまた、１ターンに１度、このカードに装備された装備カード１枚を墓地へ送る事ができる。\nこのカードに装備された装備カードが墓地へ送られた時、相手フィールド上に表側表示で存在するモンスター１体を選択して破壊する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゼミアの神",h:"ぜみあのかみ",e:"Lord of Zemia",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1300,de:1000,tx:"相手をだまして、破滅の道へと誘うことを得意とする邪神。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゼラ",h:"ぜら",e:"Zera the Mant",ra:"R",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"悪魔族",mo:["儀式"],ma:"",tp:"",at:2800,de:2300,tx:"「ゼラの儀式」により降臨。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゼラの戦士",h:"ぜらのせんし",e:"Warrior of Zera",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1600,de:1600,tx:"大天使の力を手に入れる事ができるという聖域を探し求める戦士。\n邪悪な魔族からの誘惑から逃れるため、孤独な闘いの日々を送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゼラの天使",h:"ぜらのてんし",e:"Angel of Zera",ra:"UR",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"天使族",mo:["シンクロ","効果"],ma:"",tp:"",at:2800,de:2300,tx:"チューナー＋チューナー以外のモンスター１体以上\n「ゼラの天使」の②の効果は１ターンに１度しか使用できない。\n①：このカードの攻撃力は除外されている相手のカードの数×１００アップする。\n②：このカードが除外された場合、次のターンのスタンバイフェイズに発動する。\n除外されているこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"穿孔重機ドリルジャンボ",h:"せんこうじゅうきどりるじゃんぼ",e:"Jumbo Drill",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1800,de:100,tx:"このカードが召喚に成功した時、自分フィールド上の全ての機械族モンスターのレベルを１つ上げる事ができる。\nこのカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。\nまた、このカードは攻撃した場合、ダメージステップ終了時に守備表示になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"穿孔虫",h:"せんこうちゅう",e:"Drill Bug",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1100,de:200,tx:"このカードが相手ライフに戦闘ダメージを与えた時、自分のデッキに存在する自分の「寄生虫パラサイド」１枚をデッキの一番上に置く事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"閃光の騎士",h:"せんこうのきし",e:"Flash Knight",ra:"SR",t:"monster",a:"光",l:4,ps:7,pe:"",tr:"戦士族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:1800,de:600,tx:"神の振り子により新たな力を会得した騎士。\n今こそ覚醒し、その力を解放せよ！",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"潜航母艦エアロ・シャーク",h:"せんこうぼかんえあろ・しゃーく",e:"Submersible Carrier Aero Shark",ra:"R",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"魚族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1900,de:1000,tx:"レベル３モンスター×２\n１ターンに１度、このカードのエクシーズ素材を１つ取り除いて発動できる。\nゲームから除外されている自分のモンスターの数×１００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"閃珖竜 スターダスト",h:"せんこうりゅう　すたーだすと",e:"Stardust Spark Dragon",ra:"UR",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:2000,tx:"チューナー＋チューナー以外のモンスター１体以上\n１ターンに１度、自分フィールド上に表側表示で存在するカード１枚を選択して発動できる。\n選択したカードは、このターンに１度だけ戦闘及びカードの効果では破壊されない。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"戦士ダイ・グレファー",h:"せんしだい・ぐれふぁー",e:"Warrior Dai Grepher",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1700,de:1600,tx:"ドラゴン族を操る才能を秘めた戦士。\n過去は謎に包まれている。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"センジュ・ゴッド",h:"せんじゅ・ごっど",e:"Senju of the Thousand Hands",ra:"UR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1400,de:1000,tx:"①：このカードが召喚・反転召喚に成功した時に発動できる。\nデッキから儀式モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"占術姫アローシルフ",h:"せんじゅつきあろーしるふ",e:"Prediction Princess Arrowsylph",ra:"N",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"天使族",mo:["リバース","効果"],ma:"",tp:"",at:1000,de:1400,tx:"①：このカードがリバースした場合に発動できる。\n自分のデッキ・墓地から儀式魔法カード１枚を選んで手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"占術姫ウィジャモリガン",h:"せんじゅつきうぃじゃもりがん",e:"Prediction Princess Astromorrigan",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"天使族",mo:["リバース","効果"],ma:"",tp:"",at:1300,de:0,tx:"①：このカードがリバースした場合に発動する。\nそのターンのエンドフェイズに相手フィールドの守備表示モンスターを全て破壊し、破壊したモンスターの数×５００ダメージを相手に与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"占術姫クリスタルウンディーネ",h:"せんじゅつきくりすたるうんでぃーね",e:"Prediction Princess Crystaldine",ra:"N",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"天使族",mo:["リバース","効果"],ma:"",tp:"",at:1400,de:2200,tx:"①：このカードがリバースした場合に発動できる。\n自分のデッキ・墓地から儀式モンスター１体を選んで手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"戦場の死装束",h:"せんじょうのしにしょうぞく",e:"Warrior of Tradition",ra:"R",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["融合"],ma:"",tp:"",at:1900,de:1700,tx:"音女＋斬首の美女",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"戦士ラーズ",h:"せんしらーず",e:"Field-Commander Rahz",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"このカードが召喚・特殊召喚に成功した時、自分のデッキから「戦士ラーズ」以外のレベル４以下の戦士族モンスター１体を選択し、デッキの一番上に置く。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"千年の盾",h:"せんねんのたて",e:"Millennium Shield",ra:"SR",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:0,de:3000,tx:"古代エジプト王家より伝わるといわれている伝説の盾。\nどんなに強い攻撃でも防げるという。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"旋風のボルテクス",h:"せんぷうのぼるてくす",e:"Vortex the Whirlwind",ra:"UR",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"鳥獣族",mo:["シンクロ","効果"],ma:"",tp:"",at:2100,de:700,tx:"チューナー＋チューナー以外の鳥獣族モンスター１体以上\nこのカードが戦闘によって破壊され墓地へ送られた時、自分のデッキからレベル４以下の鳥獣族モンスター１体を特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゼンマイウォリアー",h:"ぜんまいうぉりあー",e:"Wind-Up Warrior",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:1800,tx:"自分フィールド上に表側表示で存在する「ゼンマイ」と名のついたモンスター１体を選択して発動する事ができる。\nエンドフェイズ時まで選択したモンスター１体のレベルを１つ上げ、攻撃力を６００ポイントアップする。\nこの効果はこのカードがフィールド上に表側表示で存在する限り１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"発条機甲ゼンマイスター",h:"ぜんまいきこうぜんまいすたー",e:"Wind-Up Zenmaister",ra:"UR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1900,de:1500,tx:"レベル４モンスター×２\nこのカードの攻撃力は、このカードのエクシーズ素材の数×３００ポイントアップする。\n１ターンに１度、このカードのエクシーズ素材を１つ取り除き、自分フィールド上に表側表示で存在するモンスター１体を選択して発動する事ができる。\n選択したモンスターを裏側守備表示にする。\nこのターンのエンドフェイズ時、選択したモンスターは表側攻撃表示になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"発条機雷ゼンマイン",h:"ぜんまいきらいぜんまいん",e:"Wind-Up Zenmaines",ra:"SR",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1500,de:2100,tx:"レベル３モンスター×２\nフィールド上のこのカードが破壊される場合、代わりにこのカードのエクシーズ素材を１つ取り除く事ができる。\nこの効果を適用したターンのエンドフェイズ時に１度、フィールド上のカード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"発条空母ゼンマイティ",h:"ぜんまいくうぼぜんまいてぃ",e:"Wind-Up Carrier Zenmaity",ra:"R",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1500,de:1500,tx:"レベル３モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\n手札・デッキから「ゼンマイ」モンスター１体を特殊召喚する。\n②：フィールドの表側表示の「ゼンマイ」モンスターが戦闘以外で破壊され自分の墓地へ送られた時、このカードのX素材を１つ取り除き、その「ゼンマイ」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゼンマイシャーク",h:"ぜんまいしゃーく",e:"Wind-Up Shark",ra:"UR",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1500,de:1300,tx:"①：自分フィールドに「ゼンマイ」モンスターが召喚・特殊召喚された時に発動できる。\nこのカードを手札から特殊召喚する。\n②：１ターンに１度、以下の効果から１つを選択して発動できる。\n●このカードのレベルをターン終了時まで１つ上げる。\n●このカードのレベルをターン終了時まで１つ下げる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゼンマイジャグラー",h:"ぜんまいじゃぐらー",e:"Wind-Up Juggler",ra:"SR",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"このカードが相手モンスターと戦闘を行った場合、その相手モンスターをダメージ計算後に破壊する事ができる。\nこの効果はこのカードがフィールド上に表側表示で存在する限り１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"発条装攻ゼンマイオー",h:"ぜんまいそうこうぜんまいおー",e:"Wind-Up Arsenal Zenmaioh",ra:"R",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2600,de:1900,tx:"レベル５モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除き、フィールドにセットされたカード２枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゼンマイソルジャー",h:"ぜんまいそるじゃー",e:"Wind-Up Soldier",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1200,tx:"自分のメインフェイズ時に発動する事ができる。\nエンドフェイズ時までこのカードのレベルを１つ上げ、攻撃力を４００ポイントアップする。\nこの効果はこのカードがフィールド上に表側表示で存在する限り１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゼンマイドッグ",h:"ぜんまいどっぐ",e:"Wind-Up Dog",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1200,de:900,tx:"自分のメインフェイズ時に発動する事ができる。\nエンドフェイズ時までこのカードのレベルを２つ上げ、攻撃力を６００ポイントアップする。\nこの効果はこのカードがフィールド上に表側表示で存在する限り１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゼンマイナイト",h:"ぜんまいないと",e:"Wind-Up Knight",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1200,tx:"自分フィールド上に表側表示で存在する「ゼンマイ」と名のついたモンスターが攻撃対象に選択された時、そのモンスターの攻撃を無効にする事ができる。\nこの効果はこのカードがフィールド上に表側表示で存在する限り１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゼンマイネズミ",h:"ぜんまいねずみ",e:"Wind-Up Rat",ra:"UR",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:600,de:600,tx:"自分のメインフェイズ時に発動できる。\n自分フィールド上に表側攻撃表示で存在するこのカードを表側守備表示に変更し、自分の墓地の「ゼンマイ」と名のついたモンスター１体を選択して表側守備表示で特殊召喚する。\nこの効果はこのカードがフィールド上に表側表示で存在する限り１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゼンマイバット",h:"ぜんまいばっと",e:"Wind-Up Bat",ra:"R",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:300,de:350,tx:"自分のメインフェイズ時に発動する事ができる。\n自分フィールド上に表側攻撃表示で存在するこのカードを表側守備表示に変更し、自分の墓地に存在する「ゼンマイ」と名のついたモンスター１体を選択して手札に加える。\nこの効果はこのカードがフィールド上に表側表示で存在する限り１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゼンマイハニー",h:"ぜんまいはにー",e:"Wind-Up Honeybee",ra:"SR",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:100,de:300,tx:"このカードが戦闘によって破壊され墓地へ送られた時、デッキからレベル４以下の「ゼンマイ」と名のついたモンスター１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゼンマイマイ",h:"ぜんまいまい",e:"Wind-Up Snail",ra:"R",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:100,de:2000,tx:"自分のメインフェイズ時、フィールド上にセットされたカード１枚を選択して持ち主の手札に戻す事ができる。\nこの効果はこのカードがフィールド上に表側表示で存在する限り１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゼンマイマジシャン",h:"ぜんまいまじしゃん",e:"Wind-Up Magician",ra:"SR",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:600,de:1800,tx:"「ゼンマイマジシャン」以外の「ゼンマイ」と名のついたモンスターの効果が発動した場合、自分のデッキから「ゼンマイ」と名のついたレベル４以下のモンスター１体を表側守備表示で特殊召喚する事ができる。\nこの効果はこのカードがフィールド上に表側表示で存在する限り１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゼンマイラビット",h:"ぜんまいらびっと",e:"Wind-Up Rabbit",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1400,de:600,tx:"自分フィールド上の「ゼンマイ」と名のついたモンスター１体を選択して発動できる。\n選択したモンスターを次の自分のスタンバイフェイズ時までゲームから除外する。\nこの効果は相手ターンでも発動できる。\nまた、この効果はこのカードがフィールド上に表側表示で存在する限り１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"戦慄の凶皇－ジェネシス・デーモン",h:"せんりつのきょうこう－じぇねしす・でーもん",e:"Archfiend Emperor, the First Lord of Horror",ra:"UR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:3000,de:2000,tx:"①：このカードはリリースなしで召喚できる。\n②：このカードの①の方法で召喚したこのカードの元々の攻撃力・守備力は半分になり、エンドフェイズに破壊される。\n③：このカードがモンスターゾーンに存在する限り、自分は悪魔族モンスターしか特殊召喚できない。\n④：１ターンに１度、自分の手札・墓地の「デーモン」カード１枚を除外し、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ソイツ",h:"そいつ",e:"Soitsu",ra:"N",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:0,de:0,tx:"かなり頼りない姿をしているが、実はとてつもない潜在能力を隠し持っていると思っているらしい。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"蒼炎の剣士",h:"そうえんのけんし",e:"Blue Flame Swordsman",ra:"SR",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1600,tx:"①：１ターンに１度、自分・相手のバトルフェイズに、このカード以外の自分フィールドの戦士族モンスター１体を対象として発動できる。\nこのカードの攻撃力を６００ダウンし、対象のモンスターの攻撃力を６００アップする。\n②：フィールドのこのカードが相手によって破壊され墓地へ送られた時、墓地のこのカードを除外し、自分の墓地の戦士族・炎属性モンスター１体を対象として発動できる。\nその戦士族・炎属性モンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"蒼眼の銀龍",h:"そうがんのぎんりゅう",e:"Azure-Eyes Silver Dragon",ra:"SR",t:"monster",a:"光",l:9,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:3000,tx:"チューナー＋チューナー以外の通常モンスター１体以上\n①：このカードが特殊召喚に成功した場合に発動する。\n自分フィールドのドラゴン族モンスターは次のターンの終了時まで、効果の対象にならず、効果では破壊されない。\n②：自分スタンバイフェイズ毎に自分の墓地の通常モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:1,lk:[],ta:[],al:[],ocg:true},
  {n:"蒼血鬼",h:"そうけつき",e:"Blue-Blooded Oni",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1000,de:1700,tx:"このカードは召喚・反転召喚に成功した時、守備表示になる。\n１ターンに１度、自分フィールド上に存在するエクシーズ素材を１つ取り除く事で、自分の墓地に存在するレベル４のアンデット族モンスター１体を選択して特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"総剣司令 ガトムズ",h:"そうけんしれい　がとむず",e:"Commander Gottoms, Swordmaster",ra:"R",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:2100,de:1600,tx:"このカードがフィールド上に表側表示で存在する限り、自分フィールド上の「セイバー」と名のついたモンスターの攻撃力は４００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"創世者の化身",h:"そうせいしゃのけしん",e:"The Creator Incarnate",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1500,tx:"このカードを生け贄に捧げる事で、手札の「創世神」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"創星神 sophia",h:"そうせいしん　そぴあ",e:"Sophia, Goddess of Rebirth",ra:"UR",t:"monster",a:"闇",l:11,ps:"",pe:"",tr:"天使族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3600,de:3400,tx:"このカードは通常召喚できない。\n自分・相手フィールド上に表側表示で存在する、儀式・融合・シンクロ・エクシーズモンスターをそれぞれ１体ずつゲームから除外した場合のみ特殊召喚できる。\nこのカードの特殊召喚は無効化されない。\nこのカードが特殊召喚に成功した時、このカード以外のお互いの手札・フィールド上・墓地のカードを全てゲームから除外する。\nこの効果の発動に対して魔法・罠・効果モンスターの効果は発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"創世の預言者",h:"そうせいのよげんしゃ",e:"Herald of Creation",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1800,de:600,tx:"①：１ターンに１度、手札を１枚捨て、自分の墓地のレベル７以上のモンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"創世の竜騎士",h:"そうせいのりゅうきし",e:"Dragon Knight of Creation",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1800,de:600,tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、このカードのレベルは相手ターンの間４つ上がる。\n②：このカードが戦闘で相手モンスターを破壊し墓地へ送った時に発動できる。\nデッキからレベル７・８のドラゴン族モンスター１体を墓地へ送る。\n③：手札を１枚墓地へ送り、自分の墓地のレベル７・８のドラゴン族モンスター１体を対象として発動できる。\nこのカードを墓地へ送り、対象のモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"創造の代行者 ヴィーナス",h:"そうぞうのだいこうしゃ　う゛ぃーなす",e:"The Agent of Creation - Venus",ra:"SR",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1600,de:0,tx:"①：５００LPを払って発動できる。\n手札・デッキから「神聖なる球体」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"騒々虫",h:"そうぞうむし",e:"Noisy Gnat",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:700,de:300,tx:"このカードを手札から墓地へ送って発動する。\nフィールド上に存在するモンスター１体のレベルをエンドフェイズ時まで１つ上げる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"双頭の雷龍",h:"そうとうのさんだー・どらごん",e:"Twin-Headed Thunder Dragon",ra:"SR",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"雷族",mo:["融合"],ma:"",tp:"",at:2800,de:2100,tx:"「サンダー・ドラゴン」＋「サンダー・ドラゴン」",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"増幅する悪意",h:"ぞうふくするまりす",e:"Malice Ascendant",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:700,de:1000,tx:"このカードがフィールド上に表側表示で存在する限り、相手ターンのスタンバイフェイズ時に自分の墓地に存在する「増幅する悪意」の枚数だけ、相手のデッキの上からカードを墓地に送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"霊魂の護送船",h:"そうる・こんう゛ぉい",e:"Ghost Ship",ra:"UR",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"悪魔族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1900,de:1000,tx:"このカードは通常召喚できない。\n自分の墓地に存在する光属性モンスター１体をゲームから除外した場合に特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魂虎",h:"そうる・たいがー",e:"Soul Tiger",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:0,de:2100,tx:"人の魂をむさぼると言われている恐ろしい虎の魂。\nできれば出会いたくない魂として有名。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ソウル・ハンター",h:"そうる・はんたー",e:"Soul Hunter",ra:"N",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["融合"],ma:"",tp:"",at:2200,de:1800,tx:"ランプの魔人＋異次元からの侵略者",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"剣竜",h:"そーど・どらごん",e:"Sword Arm of Dragon",ra:"N",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"恐竜族",mo:["通常"],ma:"",tp:"",at:1750,de:2030,tx:"全身にカタナのトゲがついた恐竜。\n突進攻撃をする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ソード・マスター",h:"そーど・ますたー",e:"Sword Master",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["チューナー","効果"],ma:"",tp:"",at:1200,de:0,tx:"自分フィールド上に存在する戦士族モンスターの攻撃によって相手モンスターが破壊されなかったダメージステップ終了時、このカードを手札から特殊召喚する事ができる。\nまた、このカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ソードハンター",h:"そーどはんたー",e:"Sword Hunter",ra:"N",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2450,de:1700,tx:"このカードが戦闘によってモンスターを破壊したバトルフェイズ終了時、墓地に存在するそのモンスターを攻撃力２００ポイントアップの装備カード扱いとしてこのカードに装備する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ソードブレイカー",h:"そーどぶれいかー",e:"Sword Breaker",ra:"R",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2700,de:1000,tx:"レベル６モンスター×２\n１ターンに１度、このカードのエクシーズ素材を１つ取り除き、種族を１つ宣言して発動できる。\nこのカードが、宣言した種族のモンスターと戦闘を行う場合、ダメージ計算を行わずそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"太陽風帆船",h:"そーらー・うぃんどじゃまー",e:"Solar Wind Jammer",ra:"SR",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:800,de:2400,tx:"自分フィールド上にモンスターが存在しない場合、このカードは手札から特殊召喚できる。\nこの方法で特殊召喚したこのカードの元々の攻撃力・守備力は半分になる。\nまた、自分のスタンバイフェイズ毎にこのカードのレベルを１つ上げる。\n「太陽風帆船」はフィールド上に１体しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ソーラー・ジェネクス",h:"そーらー・じぇねくす",e:"Genex Solar",ra:"R",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2500,de:1500,tx:"このカードは「ジェネクス」と名のついたモンスター１体をリリースして召喚できる。\nまた、自分フィールド上に表側表示で存在する「ジェネクス」と名のついたモンスターが墓地へ送られた時、相手ライフに５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゾーン・イーター",h:"ぞーん・いーたー",e:"Zone Eater",ra:"N",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:250,de:200,tx:"「ゾーン・イーター」の攻撃を受けたモンスターは、５ターン後に破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"速炎星－タイヒョウ",h:"そくえんせい－たいひょう",e:"Brotherhood of the Fire Fist - Leopard",ra:"SR",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:0,de:200,tx:"このカードが召喚・特殊召喚に成功したターンのメインフェイズ時に、自分フィールド上の「炎星」と名のついたモンスター１体をリリースして発動できる。\nデッキから「炎舞」と名のついた魔法・罠カード１枚を選んで自分フィールド上にセットする。\n「速炎星－タイヒョウ」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"速攻の黒い忍者",h:"そっこうのぶらっくにんじゃ",e:"Strike Ninja",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:1200,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：自分の墓地から闇属性モンスター２体を除外して発動できる。\n表側表示のこのカードをエンドフェイズまで除外する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ソニック・ウォリアー",h:"そにっく・うぉりあー",e:"Sonic Warrior",ra:"R",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:0,tx:"①：このカードが墓地へ送られた場合に発動する。\n自分フィールドの全てのレベル２以下のモンスターの攻撃力は５００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ソニック・シューター",h:"そにっく・しゅーたー",e:"Sonic Shooter",ra:"N",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1300,de:600,tx:"相手の魔法＆罠カードゾーンにカードが存在しない場合、このカードは相手プレイヤーに直接攻撃する事ができる。\nこの時、相手プレイヤーに与える戦闘ダメージはこのカードの元々の攻撃力となる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ソニックジャマー",h:"そにっくじゃまー",e:"Sonic Jammer",ra:"R",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"機械族",mo:["リバース","効果"],ma:"",tp:"",at:350,de:650,tx:"リバース：次のターンのエンドフェイズ終了時まで、相手は魔法カードを発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"音速ダック",h:"そにっくだっく",e:"Sonic Duck",ra:"R",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["通常"],ma:"",tp:"",at:1700,de:700,tx:"音速で歩く事ができるダック。\nそのすさまじいスピードに対応できず、コントロールを失う事が多い。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ソニックバード",h:"そにっくばーど",e:"Sonic Bird",ra:"UR",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1400,de:1000,tx:"①：このカードが召喚・反転召喚に成功した時に発動できる。\nデッキから儀式魔法カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"そよ風の精霊",h:"そよかぜのせいれい",e:"Spirit of the Breeze",ra:"R",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:0,de:1800,tx:"このカードが自分フィールド上に表側攻撃表示で存在する限り、自分のスタンバイフェイズ毎に自分は１０００ライフポイント回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"宇宙獣ガンギル",h:"そらけものがんぎる",e:"Cosmic Horror Gangi'el",ra:"N",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:2600,de:2000,tx:"自分フィールド上に存在する元々の持ち主が相手のモンスターを生け贄に捧げる場合、このカードは生け贄１体で召喚する事ができる。\n１ターンに１度だけ、相手フィールド上モンスター１体にAカウンターを１つ置く事ができる。\nAカウンターが乗ったモンスターは、「エーリアン」と名のついたモンスターと戦闘する場合、Aカウンター１つにつき攻撃力と守備力が３００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"宇宙砦ゴルガー",h:"そらとりでごるがー",e:"Cosmic Fortress Gol'gar",ra:"SR",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"爬虫類族",mo:["シンクロ","効果"],ma:"",tp:"",at:2600,de:1800,tx:"「エーリアンモナイト」＋チューナー以外の「エーリアン」モンスター１体以上\n①：１ターンに１度、フィールドの表側表示の魔法・罠カードを任意の数だけ対象として発動できる。\nその表側表示のカードを持ち主の手札に戻す。\nその後、手札に戻った数だけフィールドの表側表示モンスターにAカウンターを置く。\n②：１ターンに１度、フィールドのAカウンターを２つ取り除き、相手フィールドのカード１枚を対象として発動できる。\nその相手のカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"空の昆虫兵",h:"そらのこんちゅうへい",e:"Insect Soldiers of the Sky",ra:"N",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1000,de:800,tx:"風属性のモンスターを攻撃する場合、ダメージステップの間このカードの攻撃力は１０００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ソリテア・マジカル",h:"そりてあ・まじかる",e:"Solitaire Magician",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1600,de:1300,tx:"自分フィールド上に表側表示で存在する「フォーチュンレディ」と名のついたモンスター１体と、フィールド上に表側表示で存在するモンスター１体を選択して発動する。\n選択した「フォーチュンレディ」と名のついたモンスターのレベルを３つ下げ、選択したもう１体のモンスターを破壊する。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ソリテュード",h:"そりてゅーど",e:"Solitude",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣戦士族",mo:["通常"],ma:"",tp:"",at:1050,de:1000,tx:"下半身がシカで、魂を吸うという大カマを持った獣戦士。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゾルガ",h:"ぞるが",e:"Zolga",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1700,de:1200,tx:"①：このカードがアドバンス召喚のためにリリースされた場合に発動する。\n自分は２０００LP回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"兵隊竜",h:"そるじゃー・どらごん",e:"Soldier Dragons",ra:"R",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:700,de:800,tx:"①：１ターンに１度、相手が魔法・罠・モンスターの効果を発動した時に発動できる。\nデッキからレベル２以下のドラゴン族モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゾンビ・マスター",h:"ぞんび・ますたー",e:"Zombie Master",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1800,de:0,tx:"①：１ターンに１度、手札からモンスター１体を墓地へ送り、自分または相手の墓地のレベル４以下のアンデット族モンスター１体を対象として発動できる。\nそのアンデット族モンスターを自分フィールドに特殊召喚する。\nこの効果はこのカードがモンスターゾーンに表側表示で存在する場合に発動と処理ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゾンビーナ",h:"ぞんびーな",e:"Zombina",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1400,de:1500,tx:"①：このカードが相手によって破壊された場合、「ゾンビーナ」以外の自分の墓地のレベル４以下のモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゾンビーノ",h:"ぞんびーの",e:"Zombino",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"アンデット族",mo:["通常"],ma:"",tp:"",at:2000,de:0,tx:"ふたりは とってもなかよし\nしんでもいっしょ よみがえってもいっしょ \nはなれることはない\n\nだから ふたりがであうことは もうにどとない",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゾンビキャリア",h:"ぞんびきゃりあ",e:"Plaguespreader Zombie",ra:"UR",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"アンデット族",mo:["チューナー","効果"],ma:"",tp:"",at:400,de:200,tx:"①：このカードが墓地に存在する場合、手札を１枚デッキの一番上に戻して発動できる。\nこのカードを墓地から特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゾンビタイガー",h:"ぞんびたいがー",e:"Zombie Tiger",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"アンデット族",mo:["ユニオン","効果"],ma:"",tp:"",at:1400,de:1600,tx:"１ターンに１度だけ自分のメインフェイズに装備カード扱いとして自分の「朽ち果てた武将」に装備、または装備を解除して表側攻撃表示で特殊召喚する事ができる。\nこの効果で装備カード扱いになっている時のみ、装備モンスターの攻撃力・守備力は５００ポイントアップする。\n装備モンスターが戦闘によって相手モンスターを破壊する度に、相手は手札からランダムにカードを１枚捨てる。\n（１体のモンスターが装備できるユニオンは１枚まで。\n装備モンスターが戦闘によって破壊される場合は、代わりにこのカードを破壊する。\n）",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゾンビランプ",h:"ぞんびらんぷ",e:"Mech Mole Zombie",ra:"N",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"アンデット族",mo:["通常"],ma:"",tp:"",at:500,de:400,tx:"腕をロケットのように飛ばして攻撃する、アンデットモンスター。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"闇の芸術家",h:"だーく・あーてぃすと",e:"Dark Artist",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:600,de:1400,tx:"光属性モンスターの攻撃を受けた時、このカードの守備力は半分になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダーク・アイズ・イリュージョニスト",h:"だーく・あいず・いりゅーじょにすと",e:"Dark-Eyes Illusionist",ra:"R",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["リバース","効果"],ma:"",tp:"",at:0,de:1400,tx:"リバース：このカードがフィールド上に存在している間、指定した対象モンスター１体は永続的に攻撃できなくなる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダーク・アサシン",h:"だーく・あさしん",e:"Dark Hunter",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1600,de:400,tx:"自分の墓地に存在する闇属性モンスターの数によって、このカードは以下の効果を得る。\n●１枚以下：このカードの攻撃力は４００ポイントダウンする。\n●２～４枚：このカードの攻撃力は４００ポイントアップする。\n●５枚以上：このカードを墓地に送る事で、相手フィールド上に裏側表示で存在するモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダーク・ヴァージャー",h:"だーく・う゛ぁーじゃー",e:"Dark Verger",ra:"SR",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:0,de:1000,tx:"自分フィールド上に植物族のチューナーが召喚された時、このカードを墓地から攻撃表示で特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダーク・ヴァルキリア",h:"だーく・う゛ぁるきりあ",e:"Dark Valkyria",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"天使族",mo:["デュアル","効果"],ma:"",tp:"",at:1800,de:1050,tx:"このカードは墓地またはフィールド上に表側表示で存在する場合、通常モンスターとして扱う。\nフィールド上に表側表示で存在するこのカードを通常召喚扱いとして再度召喚する事で、このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●このカードが表側表示で存在する限り１度だけ、このカードに魔力カウンターを１つ置く事ができる。\nこのカードの攻撃力は、このカードに乗っている魔力カウンターの数×３００ポイントアップする。\nまた、このカードに乗っている魔力カウンターを１つ取り除く事で、フィールド上のモンスター１体を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダーク・エルフ",h:"だーく・えるふ",e:"Dark Elf",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2000,de:800,tx:"このカードは１０００ライフポイント払わなければ攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダーク・カタパルター",h:"だーく・かたぱるたー",e:"Dark Catapulter",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:1500,tx:"自分のスタンバイフェイズ時にこのカードが守備表示だった場合、このカードにカウンターを１つ置く。\nカウンターと同じ数のカードを自分の墓地から除外する事で、その枚数と同じ枚数のフィールド上の魔法・罠カードを破壊する。\nその後このカードのカウンターを全て取り除く。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダーク・キメラ",h:"だーく・きめら",e:"Dark Chimera",ra:"N",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1610,de:1460,tx:"魔界に生息するモンスター。\n闇の炎をはき攻撃する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダーク・クリエイター",h:"だーく・くりえいたー",e:"The Dark Creator",ra:"UR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"雷族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2300,de:3000,tx:"このカードは通常召喚できない。\n自分の墓地に闇属性モンスターが５体以上存在し、自分フィールド上にモンスターが存在しない場合に特殊召喚できる。\n１ターンに１度、自分の墓地の闇属性モンスター１体をゲームから除外する事で、自分の墓地の闇属性モンスター１体を選択して特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダーク・クルセイダー",h:"だーく・くるせいだー",e:"Dark Crusader",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:200,tx:"手札から闇属性モンスター１体を墓地へ送って発動できる。\nこのカードの攻撃力は４００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダーク・グレイ",h:"だーく・ぐれい",e:"Dark Gray",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:800,de:900,tx:"からだが灰色のけもの。\nあまり見かけない貴重ないきもの。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダーク・ジェネラル フリード",h:"だーく・じぇねらる　ふりーど",e:"Dark General Freed",ra:"SR",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2300,de:1700,tx:"このカードは特殊召喚できない。\n自分フィールド上に表側表示で存在する闇属性モンスターを対象にする魔法カードの効果を無効にし破壊する。\nこのカードがフィールド上に表側表示で存在する限り、自分のドローフェイズ時に通常のドローを行う代わりに、自分のデッキからレベル４の闇属性モンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダーク・スパイダー",h:"だーく・すぱいだー",e:"Dark Spider",ra:"N",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"１ターンに１度、自分フィールド上に表側表示で存在する昆虫族モンスター１体のレベルをエンドフェイズ時まで２つ上げる事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダーク・スプロケッター",h:"だーく・すぷろけったー",e:"Sinister Sprocket",ra:"SR",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:400,de:0,tx:"このカードが闇属性のシンクロモンスターのシンクロ召喚に使用され墓地へ送られた場合、フィールド上に表側表示で存在する魔法または罠カード１枚を破壊する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダーク・ダイブ・ボンバー",h:"だーく・だいぶ・ぼんばー",e:"Dark Strike Fighter",ra:"UR",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:2600,de:1800,tx:"チューナー＋チューナー以外のモンスター１体以上\n「ダーク・ダイブ・ボンバー」の効果は１ターンに１度しか使用できない。\n①：自分メインフェイズ１に自分フィールドのモンスター１体をリリースして発動できる。\nリリースしたモンスターのレベル×２００ダメージを相手に与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"D・ナポレオン",h:"だーく・なぽれおん",e:"Meda Bat",ra:"N",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:800,de:400,tx:"心の悪しき者がつくった目玉の悪魔。\nダークボムで爆破攻撃。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダーク・ネクロフィア",h:"だーく・ねくろふぃあ",e:"Dark Necrofear",ra:"UR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"悪魔族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2200,de:2800,tx:"このカードは通常召喚できない。\n自分の墓地から悪魔族モンスター３体を除外した場合に特殊召喚できる。\n①：モンスターゾーンのこのカードが相手によって破壊され墓地へ送られたターンのエンドフェイズに、相手フィールドの表側表示モンスター１体を対象として発動する。\n墓地のこのカードを装備カード扱いとしてその相手モンスターに装備する。\n②：このカードの効果でこのカードが装備されている場合、装備モンスターのコントロールを得る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダーク・ネフティス",h:"だーく・ねふてぃす",e:"Dark Nephthys",ra:"SR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2400,de:1600,tx:"自分のメインフェイズ時に自分の墓地の闇属性モンスターが３体以上の場合、その内２体をゲームから除外する事で、このカードを手札から墓地へ送る。\n次の自分のスタンバイフェイズ時、この効果で墓地へ送られたこのカードを墓地から特殊召喚する。\nまた、このカードが特殊召喚に成功した時、フィールド上の魔法・罠カード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダーク・パーシアス",h:"だーく・ぱーしあす",e:"Darknight Parshath",ra:"R",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1900,de:1400,tx:"このカードが戦闘によって相手モンスターを破壊した場合、自分の墓地に存在する闇属性モンスター１体をゲームから除外する事で、自分のデッキからカードを１枚ドローする。\nこのカードの攻撃力は、自分の墓地に存在する闇属性モンスターの数×１００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダーク・バグ",h:"だーく・ばぐ",e:"Dark Bug",ra:"UR",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"このカードが召喚に成功した時、自分の墓地のレベル３のチューナー１体を選択して特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダーク・ヒーロー ゾンバイア",h:"だーく・ひーろー　ぞんばいあ",e:"Zombyra the Dark",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2100,de:500,tx:"①：このカードは直接攻撃できない。\n②：このカードが戦闘でモンスターを破壊した場合に発動する。\nこのカードの攻撃力は２００ダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダーク・フラット・トップ",h:"だーく・ふらっと・とっぷ",e:"Dark Flattop",ra:"N",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:0,de:3000,tx:"闇属性チューナー＋チューナー以外の機械族モンスター１体以上\n１ターンに１度、自分の墓地の「リアクター」と名のついたモンスターまたは「ジャイアント・ボマー・エアレイド」１体を選択して発動できる。\n選択したモンスターを、召喚条件を無視して特殊召喚する。\nまた、このカードが破壊され墓地へ送られた場合、手札からレベル５以下の機械族モンスター１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダーク・プラント",h:"だーく・ぷらんと",e:"Dark Plant",ra:"N",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"植物族",mo:["通常"],ma:"",tp:"",at:300,de:400,tx:"汚染された土と闇の力で育てられた花。\nとても凶暴。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダーク・プリズナー",h:"だーく・ぷりずなー",e:"Dark Prisoner",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:600,de:1000,tx:"光の反射を巧みに操り、自分の姿を隠すことができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダーク・ホルス・ドラゴン",h:"だーく・ほるす・どらごん",e:"Dark Horus",ra:"UR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:3000,de:1800,tx:"このカードがフィールド上に表側表示で存在する限り、相手のメインフェイズ時に魔法カードが発動した場合、自分の墓地のレベル４の闇属性モンスター１体を選択して特殊召喚できる。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダーク・ボルテニス",h:"だーく・ぼるてにす",e:"Dark Voltanis",ra:"SR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2800,de:1400,tx:"自分がカウンター罠の発動に成功した場合、自分フィールド上に存在する闇属性モンスター１体をリリースする事で、手札からこのカードを特殊召喚する。\nこの効果で特殊召喚に成功した時、フィールド上に存在するカード１枚を破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"闇の支配者－ゾーク",h:"だーく・ますたー－ぞーく",e:"Dark Master - Zorc",ra:"UR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"悪魔族",mo:["儀式","効果"],ma:"",tp:"",at:2700,de:1500,tx:"「闇の支配者との契約」により降臨。\n①：１ターンに１度、自分メインフェイズに発動できる。\nサイコロを１回振り、出た目の効果を適用する。\n\n●１・２：相手フィールドのモンスターを全て破壊する。\n\n●３・４・５：相手フィールドのモンスター１体を選んで破壊する。\n\n●６：自分フィールドのモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダーク・ラビット",h:"だーく・らびっと",e:"Dark Rabbit",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:1100,de:1500,tx:"アメリカンコミックの世界のウサギ。\nとても素早くちょこまかと動く。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダーク・リゾネーター",h:"だーく・りぞねーたー",e:"Dark Resonator",ra:"SR",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:1300,de:300,tx:"①：このカードは１ターンに１度だけ戦闘では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダーク・リペアラー",h:"だーく・りぺあらー",e:"Dark Tinker",ra:"R",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:1000,de:1300,tx:"このカードが自分フィールド上から墓地へ送られた時、自分のデッキの一番上のカードを確認してデッキの一番上または一番下に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"漆黒の魔王 Lv4",h:"だーく・るしあす　れべる４",e:"Dark Lucius LV4",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1000,de:300,tx:"このカードが戦闘によって破壊した相手モンスターの効果は無効化される。\nこのカードがモンスターを戦闘によって破壊した次の自分ターンのスタンバイフェイズ時、このカードを墓地に送る事で「漆黒の魔王 LV６」１体を手札またはデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"漆黒の魔王 Lv6",h:"だーく・るしあす　れべる６",e:"Dark Lucius LV6",ra:"N",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1700,de:600,tx:"「漆黒の魔王 LV４」の効果で特殊召喚した場合、このカードが戦闘によって破壊した相手モンスターの効果は無効化される。\nこの効果で相手モンスターの効果を無効化した次の自分ターンのスタンバイフェイズ時、このカードを墓地に送る事で「漆黒の魔王 LV８」１体を手札またはデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"漆黒の魔王 Lv8",h:"だーく・るしあす　れべる８",e:"Dark Lucius LV8",ra:"R",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2800,de:900,tx:"「漆黒の魔王 LV６」の効果で特殊召喚した場合、このカードが戦闘によって破壊した相手モンスターの効果を無効にし、ゲームから除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダークエンド・ドラゴン",h:"だーくえんど・どらごん",e:"Dark End Dragon",ra:"SR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2600,de:2100,tx:"チューナー＋チューナー以外の闇属性モンスター１体以上\n１ターンに１度、このカードの攻撃力・守備力を５００ポイントダウンし、相手フィールド上に存在するモンスター１体を墓地へ送る事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダーク砂バク",h:"だーくさばく",e:"Dark Desertapir",ra:"R",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1100,de:300,tx:"このカードがゲームから除外された時、自分の墓地に存在するレベル４以下の獣族モンスター１体を選択して特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダークシー・フロート",h:"だーくしー・ふろーと",e:"Darksea Float",ra:"N",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:300,tx:"フィールド上に存在するこのカードがカードの効果によって破壊され墓地へ送られた時、自分のデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダークシー・レスキュー",h:"だーくしー・れすきゅー",e:"Darksea Rescue",ra:"R",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカードがシンクロモンスターのシンクロ召喚に使用され墓地へ送られた場合、自分のデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダークシェイド",h:"だーくしぇいど",e:"Dark Shade",ra:"N",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1000,de:1000,tx:"クリスタルから強烈な光を発して攻撃する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダークジェロイド",h:"だーくじぇろいど",e:"Dark Jeroid",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1200,de:1500,tx:"このカードが召喚・反転召喚・特殊召喚に成功した時、フィールド上の表側表示モンスター１体を選択する。\nそのモンスターはフィールド上に表側表示で存在する限り、攻撃力が８００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダークストーム・ドラゴン",h:"だーくすとーむ・どらごん",e:"Darkstorm Dragon",ra:"SR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["デュアル","効果"],ma:"",tp:"",at:2700,de:2500,tx:"①：このカードはフィールド・墓地に存在する限り、通常モンスターとして扱う。\n②：フィールドの通常モンスター扱いのこのカードを通常召喚としてもう１度召喚できる。\nその場合このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●１ターンに１度、自分フィールドの表側表示の魔法・罠カード１枚を墓地へ送って発動できる。\nフィールドの魔法・罠カードを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダークゼブラ",h:"だーくぜぶら",e:"Dark Zebra",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1800,de:400,tx:"自分のスタンバイフェイズ時、自分がコントロールするモンスターがこのカードのみの場合、このカードは守備表示になる。\nそのターン表示形式は変更できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"暗黒ドリケラトプス",h:"だーくどりけらとぷす",e:"Dark Driceratops",ra:"R",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:2400,de:1500,tx:"このカードが守備表示モンスターを攻撃した時、このカードの攻撃力が守備表示モンスターの守備力を越えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダークネス・シムルグ",h:"だーくねす・しむるぐ",e:"Simorgh of Darkness",ra:"UR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2900,de:2000,tx:"このカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが手札・墓地に存在し、自分が闇属性または風属性のモンスターのアドバンス召喚に成功した場合に発動できる。\nこのカードを特殊召喚する。\n②：このカードがモンスターゾーンに存在する限り、このカードの属性は「風」としても扱う。\n③：魔法・罠カードの効果が発動した時、自分フィールドの鳥獣族・風属性モンスター１体をリリースして発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダークネス・デストロイヤー",h:"だーくねす・ですとろいやー",e:"Darkness Destroyer",ra:"UR",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2300,de:1800,tx:"このカードは特殊召喚できない。\nこのカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。\nこのカードは１度のバトルフェイズ中に２回攻撃する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"黒羽を狩る者",h:"だーくねす・はんたー",e:"Hunter of Black Feathers",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"相手フィールド上にモンスターが表側表示で２体以上存在し、そのモンスターの種族が全て同じ場合、手札を１枚墓地へ送る事で、相手フィールド上に表側表示で存在するモンスター１体を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"邪神機－獄炎",h:"だーくねすぎあ－ごくえん",e:"Malevolent Mech - Goku En",ra:"UR",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:2400,de:1400,tx:"①：このカードはリリースなしで召喚できる。\n②：このカードの①の方法で召喚されている場合、エンドフェイズに発動する。\nこのカードを墓地へ送る。\nその後、自分はこのカードの元々の攻撃力分のダメージを受ける。\nこの効果はフィールドにこのカード以外のアンデット族モンスターが存在しない場合に発動と処理を行う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダークネスソウル",h:"だーくねすそうる",e:"Umbral Soul",ra:"N",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:2000,de:1500,tx:"このカードが戦闘によって破壊され墓地へ送られた時、フィールド上に表側表示で存在する闇属性モンスター以外のモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダークバット",h:"だーくばっと",e:"Dark Bat",ra:"N",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["通常"],ma:"",tp:"",at:1000,de:1000,tx:"念波で敵を探し出す闇世界のコウモリ。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダークビショップデーモン",h:"だーくびしょっぷでーもん",e:"Darkbishop Archfiend",ra:"N",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:300,de:1400,tx:"このカードのコントローラーは自分のスタンバイフェイズ毎に５００ライフポイントを払う。\n自分フィールド上に存在する「デーモン」という名のついたモンスターカードが相手のコントロールするカードの効果の対象になり、その処理を行う時にサイコロを１回振る。\n１・３・６が出た場合、その効果を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダークフレア・ドラゴン",h:"だーくふれあ・どらごん",e:"Darkflare Dragon",ra:"UR",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2400,de:1200,tx:"このカードは自分の墓地の光属性と闇属性のモンスターを１体ずつゲームから除外し、手札から特殊召喚できる。\n１ターンに１度、手札とデッキからドラゴン族モンスターを１体ずつ墓地へ送る事で、自分または相手の墓地のカード１枚を選択してゲームから除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダークブレイズドラゴン",h:"だーくぶれいずどらごん",e:"Darkblaze Dragon",ra:"R",t:"monster",a:"炎",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1200,de:1000,tx:"①：このカードが墓地からの特殊召喚に成功した場合に発動する。\nこのカードの攻撃力・守備力は元々の数値の倍になる。\n②：このカードが戦闘でモンスターを破壊し墓地へ送った場合に発動する。\nそのモンスターの元々の攻撃力分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダークフレーム",h:"だーくふれーむ",e:"Dark Effigy",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1500,de:0,tx:"闇属性の通常モンスターを生け贄召喚する場合、このモンスター１体で２体分の生け贄とする事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"闇紅の魔導師",h:"だーくれっど・えんちゃんたー",e:"Dark Red Enchanter",ra:"SR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1700,de:2200,tx:"このカードが召喚に成功した時、このカードに魔力カウンターを２つ置く。\nこのカードがフィールド上に表側表示で存在する限り、自分または相手が魔法カードを発動する度に、このカードに魔力カウンターを１つ置く。\nこのカードに乗っている魔力カウンター１つにつき、このカードの攻撃力は３００ポイントアップする。\n１ターンに１度、このカードに乗っている魔力カウンターを２つ取り除く事で、相手の手札をランダムに１枚捨てる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"闇薔薇の妖精",h:"だーくろーずふぇありー",e:"Dark Rose Fairy",ra:"N",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:800,de:1000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：チューナーが特殊召喚された場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが墓地に存在する場合、自分の手札・フィールドのカード１枚を墓地へ送って発動できる。\nこのカードをデッキの一番上または一番下に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダークロン",h:"だーくろん",e:"Darklon",ra:"N",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"このカードが召喚に成功した時に発動できる。\n自分フィールド上に表側表示で存在するモンスターはエンドフェイズ時までレベルが１つ上がり闇属性になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"タートル・狸",h:"たーとる・たぬき",e:"Turtle Raccoon",ra:"N",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:700,de:900,tx:"カメの甲羅を背負ったタヌキ。\n相手を化かして攻撃する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"タートル・バード",h:"たーとる・ばーど",e:"Turtle Bird",ra:"N",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1900,de:1700,tx:"主に水中に生息しているが、空を飛ぶこともできる珍しいカメ。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"タービン・ジェネクス",h:"たーびん・じぇねくす",e:"Genex Turbine",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1400,de:1300,tx:"このカードがフィールド上に表側表示で存在する限り、自分フィールド上の「ジェネクス」と名のついたモンスターの攻撃力は４００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ターボ・ウォリアー",h:"たーぼ・うぉりあー",e:"Turbo Warrior",ra:"R",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:1500,tx:"「ターボ・シンクロン」＋チューナー以外のモンスター１体以上\nレベル６以上のシンクロモンスターを攻撃対象としたこのカードの攻撃宣言時、攻撃対象モンスターの攻撃力をダメージステップ終了時まで半分にする。\nフィールド上のこのカードはレベル６以下の効果モンスターの効果の対象にならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ターボ・シンクロン",h:"たーぼ・しんくろん",e:"Turbo Synchron",ra:"N",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:100,de:500,tx:"①：このカードが攻撃表示モンスターに攻撃宣言した時に発動できる。\n攻撃対象モンスターを守備表示にする。\n②：このカードの攻撃で自分が戦闘ダメージを受けた時に発動できる。\n受けた戦闘ダメージの数値以下の攻撃力のモンスター１体を手札から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ターレット・ウォリアー",h:"たーれっと・うぉりあー",e:"Turret Warrior",ra:"N",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:2000,tx:"このカードは自分フィールド上の戦士族モンスター１体をリリースして手札から特殊召喚できる。\nこの方法で特殊召喚したこのカードの攻撃力は、リリースしたモンスターの元々の攻撃力分アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"大王目玉",h:"だいおうめだま",e:"Big Eye",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["リバース","効果"],ma:"",tp:"",at:1200,de:1000,tx:"リバース：自分のデッキの上からカードを５枚まで確認し、好きな順番でデッキの上に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"タイガー・アックス",h:"たいがー・あっくす",e:"Tiger Axe",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["通常"],ma:"",tp:"",at:1300,de:1100,tx:"オノを手にした獣戦士。\n素早い動きからくり出す攻撃は強い。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"タイガードラゴン",h:"たいがーどらごん",e:"Tiger Dragon",ra:"R",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2400,de:1800,tx:"ドラゴン族モンスターをリリースしてこのカードのアドバンス召喚に成功した時、相手の魔法＆罠カードゾーンにセットされたカードを２枚まで破壊する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダイガスタ・イグルス",h:"だいがすた・いぐるす",e:"Daigusto Eguls",ra:"R",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"サイキック族",mo:["シンクロ","効果"],ma:"",tp:"",at:2600,de:1800,tx:"チューナー＋チューナー以外の「ガスタ」と名のついたモンスター１体以上\n１ターンに１度、自分のエンドフェイズ時に自分の墓地の風属性モンスター１体をゲームから除外して発動できる。\n相手フィールド上にセットされたカード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダイガスタ・エメラル",h:"だいがすた・えめらる",e:"Daigusto Emeral",ra:"SR",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"岩石族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1800,de:800,tx:"レベル４モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除き、以下の効果から１つを選択して発動できる。\n●自分の墓地のモンスター３体を対象として発動できる。\nそのモンスター３体をデッキに加えてシャッフルする。\nその後、自分はデッキから１枚ドローする。\n●効果モンスター以外の自分の墓地のモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダイガスタ・ガルドス",h:"だいがすた・がるどす",e:"Daigusto Gulldos",ra:"R",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"サイキック族",mo:["シンクロ","効果"],ma:"",tp:"",at:2200,de:800,tx:"チューナー＋チューナー以外の「ガスタ」と名のついたモンスター１体以上\n１ターンに１度、自分の墓地の「ガスタ」と名のついたモンスター２体をデッキに戻して発動できる。\n相手フィールド上に表側表示で存在するモンスター１体を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダイガスタ・ファルコス",h:"だいがすた・ふぁるこす",e:"Daigusto Falcos",ra:"N",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"サイキック族",mo:["シンクロ","効果"],ma:"",tp:"",at:1400,de:1200,tx:"チューナー＋チューナー以外の「ガスタ」と名のついたモンスター１体以上\nこのカードがシンクロ召喚に成功した時、フィールド上の全ての「ガスタ」と名のついたモンスターの攻撃力は６００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"対空放花",h:"たいくうほうか",e:"Anti-Aircraft Flower",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:0,de:1600,tx:"自分フィールド上に存在する昆虫族モンスター１体を生け贄に捧げる度に、相手ライフに８００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"大皇帝ペンギン",h:"だいこうていぺんぎん",e:"The Great Emperor Penguin",ra:"N",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1800,de:1500,tx:"①：このカードをリリースして発動できる。\nデッキから「大皇帝ペンギン」以外の「ペンギン」モンスターを２体まで特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"太古の壺",h:"たいこのつぼ",e:"Ancient Jar",ra:"N",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"岩石族",mo:["通常"],ma:"",tp:"",at:400,de:200,tx:"とても壊れやすい大昔の壺。\n中に何かが潜んでいるらしい。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"太鼓魔人テンテンテンポ",h:"たいこまじんてんてんてんぽ",e:"Temtempo the Percussion Djinn",ra:"SR",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"悪魔族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1700,de:1000,tx:"レベル３モンスター×２\n１ターンに１度、このカードのエクシーズ素材を１つ取り除き、相手フィールド上のエクシーズモンスター１体を選択して発動できる。\n選択したモンスターのエクシーズ素材を１つ取り除き、自分フィールド上の全ての「魔人」と名のついたエクシーズモンスターの攻撃力は５００ポイントアップする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"対峙するG",h:"たいじするじー",e:"Confronting the \"C\"",ra:"SR",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1100,de:2500,tx:"①：相手がEXデッキからモンスターを特殊召喚した時に発動できる。\nこのカードを手札から特殊召喚する。\nこの効果で特殊召喚したこのカードは、このカードを対象とするモンスターの効果を受けない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"大邪神 レシェフ",h:"だいじゃしん　れしぇふ",e:"Reshef the Dark Being",ra:"SR",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"悪魔族",mo:["儀式","効果"],ma:"",tp:"",at:2500,de:1500,tx:"「大邪神の儀式」により降臨。\n手札の魔法カードを１枚捨てる。\n相手フィールド上モンスター１体のコントロールをエンドフェイズ時まで得る。\nこの効果は１ターンに１度だけ使用する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"大将軍 紫炎",h:"だいしょうぐん　しえん",e:"Great Shogun Shien",ra:"UR",t:"monster",a:"炎",l:7,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2500,de:2400,tx:"自分フィールド上に「六武衆」と名のついたモンスターが２体以上存在する場合、このカードは手札から特殊召喚できる。\nこのカードがフィールド上に表側表示で存在する限り、相手は１ターンに１度しか魔法・罠カードを発動できない。\nまた、フィールド上に表側表示で存在するこのカードが破壊される場合、代わりに自分フィールド上に表側表示で存在する「六武衆」と名のついたモンスター１体を破壊できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"大神官デ・ザード",h:"だいしんかんで・ざーど",e:"Great Dezard",ra:"R",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1900,de:2300,tx:"このカードが戦闘によってモンスターを破壊する度に以下の効果を得る。\n\n●１回目：フィールド上に表側表示で存在するこのカードを対象とする魔法・罠カードが発動した時、その発動を無効にし破壊する。\n\n●２回目：このカードをリリースする事で、自分の手札・デッキから「不死王リッチー」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダイス・アルマジロ",h:"だいす・あるまじろ",e:"Dice Armadillo",ra:"N",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1650,de:1800,tx:"体を丸めると、サイコロのような形になるアルマジロ。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"代打バッター",h:"だいだばったー",e:"Pinch Hopper",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1000,de:1200,tx:"①：このカードが自分フィールドから墓地へ送られた時に発動できる。\n手札から昆虫族モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"大地の騎士ガイアナイト",h:"だいちのきしがいあないと",e:"Gaia Knight, the Force of Earth",ra:"SR",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["シンクロ"],ma:"",tp:"",at:2600,de:800,tx:"チューナー＋チューナー以外のモンスター１体以上",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"大天使ゼラート",h:"だいてんしぜらーと",e:"Archlord Zerato",ra:"SR",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"天使族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2800,de:2300,tx:"このカードは通常召喚できない。\nこのカードは「天空の聖域」がフィールド上に存在し、自分フィールド上に表側表示で存在する「ゼラの戦士」１体を生け贄に捧げた場合のみ特殊召喚できる。\n光属性のモンスターカード１枚を手札から墓地に捨てる事で、相手フィールド上に存在する全てのモンスターを破壊する。\nこの効果は自分フィールド上に「天空の聖域」が存在しなければ適用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダイナミスト・アンキロス",h:"だいなみすと・あんきろす",e:"Dinomist Ankylos",ra:"N",t:"monster",a:"水",l:4,ps:6,pe:"①：このカードがPゾーンに存在する限り１度だけ、このカード以外の自分フィールドの「ダイナミスト」カードを対象として発動した効果を無効にできる。\nその後、このカードを破壊する。",tr:"機械族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1500,de:2000,tx:"①：このカードがモンスターゾーンに存在する限り、自分の「ダイナミスト」モンスターが戦闘で破壊したモンスターは除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダイナミスト・ケラトプス",h:"だいなみすと・けらとぷす",e:"Dinomist Ceratops",ra:"R",t:"monster",a:"水",l:5,ps:3,pe:"①：このカード以外の自分フィールドの「ダイナミスト」カードが戦闘または相手の効果で破壊される場合、代わりにこのカードを破壊できる。",tr:"機械族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2100,de:400,tx:"①：自分フィールドのモンスターが「ダイナミスト・ケラトプス」以外の「ダイナミスト」モンスターのみの場合、このカードは手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダイナミスト・ステゴサウラー",h:"だいなみすと・すてごさうらー",e:"Dinomist Stegosaur",ra:"N",t:"monster",a:"水",l:4,ps:3,pe:"①：このカード以外の自分フィールドの「ダイナミスト」カードが戦闘または相手の効果で破壊される場合、代わりにこのカードを破壊できる。",tr:"機械族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1600,de:1800,tx:"①：このカード以外の自分のPモンスターが相手モンスターと戦闘を行ったダメージ計算後に発動できる。\nその戦闘を行ったお互いのモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダイナミスト・スピノス",h:"だいなみすと・すぴのす",e:"Dinomist Spinos",ra:"R",t:"monster",a:"水",l:5,ps:3,pe:"①：このカード以外の自分フィールドの「ダイナミスト」カードが戦闘または相手の効果で破壊される場合、代わりにこのカードを破壊できる。",tr:"機械族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2500,de:1800,tx:"①：このカード以外の自分フィールドの「ダイナミスト」モンスター１体をリリースし、以下の効果から１つを選択して発動できる。\n\n●このターン、このカードは直接攻撃できる。\n\n●このターン、このカードは１度のバトルフェイズ中に２回攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダイナミスト・プテラン",h:"だいなみすと・ぷてらん",e:"Dinomist Pteran",ra:"R",t:"monster",a:"水",l:4,ps:3,pe:"①：このカード以外の自分フィールドの「ダイナミスト」カードが戦闘または相手の効果で破壊される場合、代わりにこのカードを破壊できる。",tr:"機械族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1800,de:1200,tx:"①：このカードが戦闘で相手モンスターを破壊した時に発動できる。\nデッキから「ダイナミスト」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダイナミスト・ブラキオン",h:"だいなみすと・ぶらきおん",e:"Dinomist Brachion",ra:"N",t:"monster",a:"水",l:5,ps:6,pe:"①：このカードがPゾーンに存在する限り１度だけ、このカード以外の自分フィールドの「ダイナミスト」カードを対象として発動した効果を無効にできる。\nその後、このカードを破壊する。",tr:"機械族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2000,de:800,tx:"①：自分のモンスターゾーンに「ダイナミスト・ブラキオン」が存在せず、フィールドの攻撃力が一番高いモンスターが相手フィールドに存在する場合、このカードは手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダイナミスト・プレシオス",h:"だいなみすと・ぷれしおす",e:"Dinomist Plesios",ra:"N",t:"monster",a:"水",l:4,ps:6,pe:"①：このカードがPゾーンに存在する限り１度だけ、このカード以外の自分フィールドの「ダイナミスト」カードを対象として発動した効果を無効にできる。\nその後、このカードを破壊する。",tr:"機械族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1700,de:1400,tx:"①：このカードがモンスターゾーンに存在する限り、相手フィールドのモンスターの攻撃力・守備力は、自分フィールドの「ダイナミスト」カードの数×１００ダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダイナミスト・レックス",h:"だいなみすと・れっくす",e:"Dinomist Rex",ra:"SR",t:"monster",a:"水",l:5,ps:6,pe:"①：このカードがPゾーンに存在する限り１度だけ、このカード以外の自分フィールドの「ダイナミスト」カードを対象として発動した効果を無効にできる。\nその後、このカードを破壊する。",tr:"機械族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2400,de:2200,tx:"①：このカードが攻撃を行ったダメージステップ終了時、このカード以外の自分フィールドの「ダイナミスト」モンスター１体をリリースし、以下の効果から１つを選択して発動できる。\n\n●このカードは相手モンスターに続けて攻撃でき、守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。\n\n●相手の手札・フィールドのカード１枚を選んで持ち主のデッキに戻す（手札から選ぶ場合はランダムに選ぶ）。\nその後、このカードの攻撃力は１００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダイナレスラー・エスクリマメンチ",h:"だいなれすらー・えすくりまめんち",e:"Dinowrestler Eskrimamenchi",ra:"N",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:2200,de:0,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドに「ダイナレスラー」モンスターが存在する場合、このカードはリリースなしで召喚できる。\n②：このカードが墓地に存在し、自分ターンに相手がモンスターの特殊召喚に成功した場合、自分の墓地のレベル４以下の「ダイナレスラー」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nその後、墓地のこのカードを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダイナレスラー・カポエラプトル",h:"だいなれすらー・かぽえらぷとる",e:"Dinowrestler Capoeiraptor",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1800,de:0,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：攻撃表示のこのカードは戦闘では破壊されず、相手モンスターに攻撃されたダメージステップ終了時にこのカードを守備表示にする。\n②：このカードがモンスターゾーンに守備表示で存在する場合、自分・相手のスタンバイフェイズに発動できる。\nデッキから「ダイナレスラー・カポエラプトル」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダイナレスラー・マーシャルアンキロ",h:"だいなれすらー・まーしゃるあんきろ",e:"Dinowrestler Martial Ankylo",ra:"R",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1000,de:0,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカード以外の自分の「ダイナレスラー」モンスターが相手モンスターと戦闘を行うダメージ計算時、手札・フィールドのこのカードを墓地へ送って発動できる。\nその自分のモンスターはその戦闘では破壊されず、その相手モンスターの攻撃力はダメージステップ終了時に半分になる。\n②：このカードがフィールドから墓地へ送られたターンのエンドフェイズに発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダイナレスラー・マーシャルアンペロ",h:"だいなれすらー・まーしゃるあんぺろ",e:"Dinowrestler Martial Ampelo",ra:"R",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:600,de:0,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分の他の「ダイナレスラー」モンスターが、その攻撃力以上の攻撃力を持つ相手モンスターと戦闘を行うダメージ計算時、手札・フィールドのこのカードを墓地へ送って発動できる。\nその自分のモンスターはその戦闘では破壊されず、その戦闘で発生する自分への戦闘ダメージは半分になる。\n②：相手モンスターの攻撃宣言時に墓地のこのカードを除外して発動できる。\nデッキから「ダイナレスラー・マーシャルアンペロ」以外の「ダイナレスラー」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダイナレスラー・ラアムブラキオ",h:"だいなれすらー・らあむぶらきお",e:"Dinowrestler Rambrachio",ra:"N",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1600,de:0,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分の「ダイナレスラー」モンスターが相手に戦闘ダメージを与えた時に発動できる。\nこのカードを手札から守備表示で特殊召喚する。\n②：このカードがモンスターゾーンに存在する限り、自分フィールドの他の「ダイナレスラー」モンスターは相手の効果の対象にならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"大砲だるま",h:"たいほうだるま",e:"Dharma Cannon",ra:"N",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:900,de:500,tx:"大砲で埋め尽くされているメカだるま。\nねらいは外さない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"タイホーン",h:"たいほーん",e:"Tyhone",ra:"N",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["通常"],ma:"",tp:"",at:1200,de:1400,tx:"口から砲弾を撃ちだし遠くを攻撃。\n山での砲撃は強い。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"タイム・エスケーパー",h:"たいむ・えすけーぱー",e:"Time Escaper",ra:"SR",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:500,de:100,tx:"このカードを手札から捨て、自分フィールド上に表側表示で存在するサイキック族モンスター１体を選択して発動する。\n選択したモンスターを次の自分のスタンバイフェイズ時までゲームから除外する。\nこの効果は相手ターンでも発動する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"タイム・ボマー",h:"たいむ・ぼまー",e:"Jigen Bakudan",ra:"R",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"炎族",mo:["リバース","効果"],ma:"",tp:"",at:200,de:1000,tx:"リバース：自分のスタンバイフェイズでこのカードを生け贄に捧げる。\n全ての自分のモンスターを破壊し、その総攻撃力の半分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"タイムマジック・ハンマー",h:"たいむまじっく・はんまー",e:"Time Magic Hammer",ra:"N",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["融合","効果"],ma:"",tp:"",at:500,de:400,tx:"このカードは「ヘルモスの爪」の効果で自分の手札・フィールドの魔法使い族モンスターを墓地へ送った場合のみ特殊召喚できる。\n①：このカードが特殊召喚に成功した場合、このカード以外のフィールドのモンスター１体を対象として発動する。\nこのカードを装備カード扱いとしてそのモンスターに装備する。\n②：このカードの効果でこのカードを装備したモンスターが相手モンスターと戦闘を行うダメージステップ開始時に発動できる。\nサイコロを１回振る。\nその相手モンスターを、出た目と同じ数のターン後のスタンバイフェイズまで除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"大木人１８",h:"だいもくじんいんぱち",e:"Inpachi",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1600,de:1900,tx:"切り倒された大木に邪悪な魂が宿った姿。\n森に迷い込んだ者に襲いかかる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"大木炭18",h:"だいもくたんいんぱち",e:"Charcoal Inpachi",ra:"N",t:"monster",a:"炎",l:1,ps:"",pe:"",tr:"炎族",mo:["通常"],ma:"",tp:"",at:100,de:2100,tx:"完全に燃え尽きてしまった巨木の化身。\nこの炭で焼く肉は絶品と言われ、重宝されている。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダイヤモンド・ドラゴン",h:"だいやもんど・どらごん",e:"Hyozanryu",ra:"R",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:2100,de:2800,tx:"全身がダイヤモンドでできたドラゴン。\nまばゆい光で敵の目をくらませる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"太陽電池メン",h:"たいようでんちめん",e:"Batteryman Solar",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:1500,de:1500,tx:"このカード名の①②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから雷族モンスター１体を墓地へ送る。\n②：このカードが既にモンスターゾーンに存在する状態で、雷族モンスターが召喚・特殊召喚された場合に発動する。\n自分フィールドに「電池メントークン」（雷族・光・星１・攻／守０）１体を特殊召喚する。\n③：自分のフィールド・墓地の「電池メン」効果モンスター１体を対象として発動できる。\nエンドフェイズまで、このカードはその同名カードとして扱う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"太陽の神官",h:"たいようのしんかん",e:"Oracle of the Sun",ra:"UR",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1000,de:2000,tx:"①：相手フィールドにのみモンスターが存在する場合、このカードは手札から特殊召喚できる。\n②：フィールドのこのカードが破壊され墓地へ送られた時に発動できる。\nデッキから「赤蟻アスカトル」または「スーパイ」１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"太陽の戦士",h:"たいようのせんし",e:"Luminous Soldier",ra:"R",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2100,de:1400,tx:"このカードが闇属性モンスターと戦闘を行う場合、ダメージステップの間このカードの攻撃力は５００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"太陽龍インティ",h:"たいようりゅういんてぃ",e:"Sun Dragon Inti",ra:"SR",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:3000,de:2800,tx:"「赤蟻アスカトル」＋チューナー以外のモンスター１体以上\n①：このカードが戦闘で破壊され墓地へ送られた場合に発動する。\nこのカードを破壊したモンスターを破壊し、その攻撃力の半分のダメージを相手に与える。\n②：フィールドのこのカードが破壊された次のターンのスタンバイフェイズに、自分の墓地の「月影龍クイラ」１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"タイラント・ドラゴン",h:"たいらんと・どらごん",e:"Tyrant Dragon",ra:"UR",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2900,de:2500,tx:"相手フィールド上にモンスターが存在する場合、このカードはバトルフェイズ中にもう１度だけ攻撃する事ができる。\nこのカードがフィールド上に表側表示で存在する限り、このカードを対象にする罠カードの効果を無効にし破壊する。\nこのカードを他のカードの効果によって墓地から特殊召喚する場合、そのプレイヤーは自分フィールド上に存在するドラゴン族モンスター１体をリリースしなければならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"タイラント・バースト・ドラゴン",h:"たいらんと・ばーすと・どらごん",e:"Tyrant Burst Dragon",ra:"R",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:2900,de:2500,tx:"このカードは「クリティウスの牙」の効果で自分の手札・フィールドの「タイラント・ウィング」を墓地へ送った場合のみ特殊召喚できる。\n①：このカードは相手モンスター全てに１回ずつ攻撃できる。\n②：自分フィールドのモンスター１体を対象として発動できる。\nこのカードを装備カード扱いとしてその自分のモンスターに装備する。\n③：このカードの効果でこのカードを装備したモンスターは、攻撃力・守備力が４００アップし、１度のバトルフェイズ中に３回攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"大凛魔天使ローザリアン",h:"だいりんまてんしろーざりあん",e:"Rosaria, the Stately Fallen Angel",ra:"UR",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"植物族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2900,de:2400,tx:"このカードは通常召喚できない。\n自分の手札と墓地からレベル７以上の植物族モンスターを１体ずつゲームから除外した場合に特殊召喚できる。\n１ターンに１度、自分のメインフェイズ時に発動できる。\nこのカード以外のフィールド上に表側表示で存在するカードの効果をエンドフェイズ時まで無効にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"タクヒ",h:"たくひ",e:"Takuhee",ra:"N",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["通常"],ma:"",tp:"",at:1450,de:1000,tx:"このトリが現れた時は、何か不吉な事が起こる前ぶれ。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"タクリミノス",h:"たくりみのす",e:"Takriminos",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"海竜族",mo:["通常"],ma:"",tp:"",at:1500,de:1200,tx:"体にヒレを持ち、水中でも自由に動ける海竜の仲間。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"タスケルトン",h:"たすけるとん",e:"Bacon Saver",ra:"R",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:700,de:600,tx:"モンスターが戦闘を行うバトルステップ時、墓地のこのカードをゲームから除外して発動できる。\nそのモンスターの攻撃を無効にする。\nこの効果は相手ターンでも発動できる。\n「タスケルトン」の効果はデュエル中に１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"黄昏の中忍－ニチリン",h:"たそがれのちゅうにん－にちりん",e:"Twilight Ninja Nichirin, the Chunin",ra:"SR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2300,de:1000,tx:"このカードはルール上「忍者」カードとしても扱う。\n①：１ターンに１度、手札から「忍者」モンスター１体を捨て、以下の効果から１つを選択して発動できる。\nこの効果は相手ターンでも発動できる。\n\n●このターン、自分フィールドの「忍者」モンスター及び「忍法」カードは戦闘・効果では破壊されない。\n\n●自分フィールドの「忍者」モンスター１体を選び、その攻撃力をターン終了時まで１０００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"黄昏の忍者将軍－ゲツガ",h:"たそがれのにんじゃしょうぐん－げつが",e:"Twilight Ninja Getsuga, the Shogun",ra:"R",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2000,de:3000,tx:"このカードは「忍者」モンスター１体をリリースしてアドバンス召喚できる。\n「黄昏の忍者将軍－ゲツガ」の効果は１ターンに１度しか使用できない。\n①：このカードがフィールドに攻撃表示で存在する場合、「黄昏の忍者将軍－ゲツガ」以外の自分の墓地の「忍者」モンスター２体を対象として発動できる。\nこのカードを守備表示にし、対象のモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"戦いの神 オリオン",h:"たたかいのかみ　おりおん",e:"Orion the Battle King",ra:"N",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:1800,de:1500,tx:"戦いの神と言われている天使。\nその戦いを見た者は誰もいない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"タタカワナイト",h:"たたかわないと",e:"Tatakawa Knight",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:0,tx:"相手のカードの効果によって自分の魔法・罠カードの発動が無効になった場合、このカードを手札から墓地へ送って発動できる。\n相手ライフに１５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダッカー",h:"だっかー",e:"DUCKER Mobile Cannon",ra:"R",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"機械族",mo:["リバース","効果"],ma:"",tp:"",at:500,de:500,tx:"①：このカードがリバースした場合、自分の墓地のレベル４モンスター１体を対象として発動する。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダックドロッパー",h:"だっくどろっぱー",e:"Duck Dummy",ra:"N",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"鳥獣族",mo:["デュアル","効果"],ma:"",tp:"",at:0,de:0,tx:"①：このカードはフィールド・墓地に存在する限り、通常モンスターとして扱う。\n②：フィールドの通常モンスター扱いのこのカードを通常召喚としてもう１度召喚できる。\nその場合このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●このカードは効果の対象にならず、効果では破壊されない。\n\n●このカードがモンスターゾーンに存在する限り、相手モンスターの攻撃は全て直接攻撃になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダックファイター",h:"だっくふぁいたー",e:"Duck Fighter",ra:"N",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1300,de:1400,tx:"自分フィールド上のトークンを、そのレベルの合計が３以上になるようにリリースして発動できる。\nこのカードを手札または墓地から特殊召喚する。\n「ダックファイター」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"タックルセイダー",h:"たっくるせいだー",e:"Tackle Crusader",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1500,de:1800,tx:"このカードが墓地へ送られた場合、以下の効果から１つを選択して発動できる。\n\n●相手フィールド上に表側表示で存在するモンスター１体を選択して裏側守備表示にする。\n\n●相手フィールド上に表側表示で存在する魔法・罠カード１枚を選択して持ち主の手札に戻す。\nこのターン、相手はこの効果で手札に戻したカード及びその同名カードを発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダッシュ・ウォリアー",h:"だっしゅ・うぉりあー",e:"Dash Warrior",ra:"SR",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:600,de:1200,tx:"このカードが攻撃する場合、ダメージステップの間このカードの攻撃力は１２００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"達人キョンシー",h:"たつじんきょんしー",e:"Master Kyonshee",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"アンデット族",mo:["通常"],ma:"",tp:"",at:1750,de:1000,tx:"強い相手を求めさまよっているキョンシー。\nかつてはあらゆる武術の達人として知られていたらしい。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"タツネクロ",h:"たつねくろ",e:"Tatsunecro",ra:"SR",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["チューナー","効果"],ma:"",tp:"",at:500,de:1700,tx:"通常召喚したこのカードを素材としてS召喚する場合、手札のモンスター１体もS素材にできる。\nその時のS素材モンスターは墓地へは行かず除外される。\n①：このカードがモンスターゾーンに存在する限り、自分はアンデット族モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"たつのこ",h:"たつのこ",e:"Tatsunoko",ra:"SR",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"幻竜族",mo:["シンクロ","チューナー","効果"],ma:"",tp:"",at:1700,de:500,tx:"チューナー＋チューナー以外のモンスター１体以上\nS召喚したこのカードを素材としてS召喚をする場合、手札のモンスター１体もS素材にできる。\n①：このカードがモンスターゾーンに存在する限り、このカードは他のモンスターの効果を受けない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"堕天使アムドゥシアス",h:"だてんしあむどぅしあす",e:"Darklord Amdusc",ra:"N",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1800,de:2800,tx:"自分は「堕天使アムドゥシアス」を１ターンに１度しか特殊召喚できず、その①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札からこのカードと「堕天使」カード１枚を捨て、自分の墓地の「堕天使」カード１枚を対象として発動できる。\nそのカードを手札に加える。\n②：１０００LPを払い、自分の墓地の「堕天使」魔法・罠カード１枚を対象として発動できる。\nその魔法・罠カードの効果を適用する。\nその後、墓地のそのカードをデッキに戻す。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"堕天使イシュタム",h:"だてんしいしゅたむ",e:"Darklord Ixchel",ra:"SR",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2500,de:2900,tx:"自分は「堕天使イシュタム」を１ターンに１度しか特殊召喚できず、その①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札からこのカードと「堕天使」カード１枚を捨てて発動できる。\n自分はデッキから２枚ドローする。\n②：１０００LPを払い、自分の墓地の「堕天使」魔法・罠カード１枚を対象として発動できる。\nその魔法・罠カードの効果を適用する。\nその後、墓地のそのカードをデッキに戻す。\nこの効果は相手ターンでも発動できる。",li:2,lk:[],ta:[],al:[],ocg:true},
  {n:"堕天使エデ・アーラエ",h:"だてんしえで・あーらえ",e:"Darklord Edeh Arae",ra:"N",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2300,de:2000,tx:"①：墓地から特殊召喚したこのカードは以下の効果を得る。\n\n●このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"堕天使スペルビア",h:"だてんしすぺるびあ",e:"Darklord Superbia",ra:"SR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2900,de:2400,tx:"①：このカードが墓地からの特殊召喚に成功した時、「堕天使スペルビア」以外の自分の墓地の天使族モンスター１体を対象として発動できる。\nその天使族モンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"堕天使ディザイア",h:"だてんしでぃざいあ",e:"Darklord Desire",ra:"SR",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:3000,de:2800,tx:"このカードは特殊召喚できない。\nこのカードは天使族モンスター１体をリリースしてアドバンス召喚する事ができる。\n１ターンに１度、自分のメインフェイズ時にこのカードの攻撃力を１０００ポイントダウンし、相手フィールド上に存在するモンスター１体を墓地へ送る事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"堕天使テスカトリポカ",h:"だてんしてすかとりぽか",e:"Darklord Tezcatlipoca",ra:"N",t:"monster",a:"闇",l:9,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2800,de:2100,tx:"自分は「堕天使テスカトリポカ」を１ターンに１度しか特殊召喚できず、その②の効果は１ターンに１度しか使用できない。\n①：自分フィールドの「堕天使」モンスターが戦闘・効果で破壊される場合、代わりに手札のこのカードを捨てる事ができる。\n②：１０００LPを払い、自分の墓地の「堕天使」魔法・罠カード１枚を対象として発動できる。\nその魔法・罠カードの効果を適用する。\nその後、墓地のそのカードをデッキに戻す。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"堕天使マスティマ",h:"だてんしますてぃま",e:"Darklord Nasten",ra:"R",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2600,de:2600,tx:"自分は「堕天使マスティマ」を１ターンに１度しか特殊召喚できず、その②の効果は１ターンに１度しか使用できない。\n①：手札からこのカード以外の「堕天使」カード２枚を捨てて発動できる。\nこのカードを手札から特殊召喚する。\n②：１０００LPを払い、自分の墓地の「堕天使」魔法・罠カード１枚を対象として発動できる。\nその魔法・罠カードの効果を適用する。\nその後、墓地のそのカードをデッキに戻す。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"堕天使マリー",h:"だてんしまりー",e:"Darklord Marie",ra:"R",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1700,de:1200,tx:"このカードが墓地に存在する場合、自分のスタンバイフェイズ時に１度だけ、自分は２００ライフポイント回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"堕天使ユコバック",h:"だてんしゆこばっく",e:"Darklord Ukoback",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:700,de:1000,tx:"「堕天使ユコバック」の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「堕天使」カード１枚を墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"堕天使ルシフェル",h:"だてんしるしふぇる",e:"Darklord Morningstar",ra:"R",t:"monster",a:"闇",l:11,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:3000,de:3000,tx:"このカードは特殊召喚できない。\n①：このカードがアドバンス召喚に成功した場合に発動できる。\n相手フィールドの効果モンスターの数まで、手札・デッキから「堕天使」モンスターを特殊召喚する。\n②：自分フィールドに他の「堕天使」モンスターが存在する限り、相手はこのカードを効果の対象にできない。\n③：１ターンに１度、自分メインフェイズに発動できる。\nフィールドの「堕天使」モンスターの数だけ、自分のデッキの上からカードを墓地へ送る。\n自分はこの効果で墓地へ送った「堕天使」カードの数×５００LP回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダニポン",h:"だにぽん",e:"Danipon",ra:"R",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:600,de:600,tx:"このカードが戦闘によって破壊され墓地へ送られた時、自分のデッキから守備力１０００以下の昆虫族モンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"W－ウィング・カタパルト",h:"だぶる－うぃんぐ・かたぱると",e:"W-Wing Catapult",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["ユニオン","効果"],ma:"",tp:"",at:1300,de:1500,tx:"①：１ターンに１度、以下の効果から１つを選択して発動できる。\n●自分フィールドの「V－タイガー・ジェット」１体を対象とし、このカードを装備カード扱いとしてそのモンスターに装備する。\n装備モンスターが戦闘・効果で破壊される場合、代わりにこのカードを破壊する。\n●装備されているこのカードを特殊召喚する。\n②：装備モンスターの攻撃力・守備力は４００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダブル・プロテクター",h:"だぶる・ぷろてくたー",e:"Twin-Shield Defender",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:700,de:1600,tx:"自分フィールド上に存在するこのカードが戦闘によって破壊され墓地へ送られた時、相手フィールド上に表側表示で存在するモンスター１体の攻撃力をエンドフェイズ時まで半分にする事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダブル・リゾネーター",h:"だぶる・りぞねーたー",e:"Double Resonator",ra:"N",t:"monster",a:"炎",l:1,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"「ダブル・リゾネーター」の②の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合、フィールドの表側表示モンスター１体を対象として発動できる。\nこのターン、その表側表示モンスターをチューナーとして扱う。\n②：墓地のこのカードを除外し、自分フィールドの悪魔族モンスター１体を対象として発動できる。\nこのターン、その悪魔族モンスターをチューナーとして扱う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"XX－セイバー エマーズブレイド",h:"だぶるえっくす－せいばー　えまーずぶれいど",e:"XX-Saber Emmersblade",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1300,de:800,tx:"①：このカードが戦闘で破壊され墓地へ送られた時に発動できる。\nデッキからレベル４以下の「X－セイバー」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"XX－セイバー ガルセム",h:"だぶるえっくす－せいばー　がるせむ",e:"XX-Saber Garsem",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1400,de:400,tx:"フィールド上に存在するこのカードがカードの効果によって破壊され墓地へ送られた時、自分のデッキから「X－セイバー」と名のついたモンスター１体を手札に加える。\nこのカードの攻撃力は、自分フィールド上に表側表示で存在する「X－セイバー」と名のついたモンスターの数×２００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"XX－セイバー ガルドストライク",h:"だぶるえっくす－せいばー　がるどすとらいく",e:"XX-Saber Gardestrike",ra:"R",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:2100,de:1400,tx:"自分の墓地に「X－セイバー」と名のついたモンスターが２体以上存在し、自分フィールド上にモンスターが存在しない場合、このカードは手札から特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"XX－セイバー ダークソウル",h:"だぶるえっくす－せいばー　だーくそうる",e:"XX-Saber Darksoul",ra:"SR",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"①：このカードが自分フィールドから墓地へ送られたターンのエンドフェイズに発動できる。\nデッキから「X－セイバー」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"XX－セイバー ヒュンレイ",h:"だぶるえっくす－せいばー　ひゅんれい",e:"XX-Saber Hyunlei",ra:"UR",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2300,de:1300,tx:"チューナー＋チューナー以外の「X－セイバー」と名のついたモンスター１体以上\nこのカードがシンクロ召喚に成功した時、フィールド上の魔法・罠カードを３枚まで選択して破壊できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"XX－セイバー フォルトロール",h:"だぶるえっくす－せいばー　ふぉるとろーる",e:"XX-Saber Faultroll",ra:"SR",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2400,de:1800,tx:"このカードは通常召喚できない。\n自分フィールドに「X－セイバー」モンスターが２体以上存在する場合のみ特殊召喚できる。\n①：１ターンに１度、自分の墓地のレベル４以下の「X－セイバー」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"XX－セイバー フラムナイト",h:"だぶるえっくす－せいばー　ふらむないと",e:"XX-Saber Fulhelmknight",ra:"UR",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["チューナー","効果"],ma:"",tp:"",at:1300,de:1000,tx:"①：このカードがフィールドに表側表示で存在する限り１度だけ、相手モンスターの攻撃宣言時にそのモンスター１体を対象として発動できる。\nその攻撃を無効にする。\n②：このカードが戦闘で相手の守備表示モンスターを破壊した場合、自分の墓地のレベル４以下の「X－セイバー」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"XX－セイバー ボガーナイト",h:"だぶるえっくす－せいばー　ぼがーないと",e:"XX-Saber Boggart Knight",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1900,de:1000,tx:"このカードをS素材とする場合、「X－セイバー」モンスターのS召喚にしか使用できない。\n①：このカードが召喚に成功した時に発動できる。\n手札からレベル４以下の「X－セイバー」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"XX－セイバー レイジグラ",h:"だぶるえっくす－せいばー　れいじぐら",e:"XX-Saber Ragigura",ra:"R",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:200,de:1000,tx:"①：このカードが召喚・特殊召喚に成功した時、自分の墓地の「X－セイバー」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダブルコストン",h:"だぶるこすとん",e:"Double Coston",ra:"UR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1700,de:1650,tx:"①：闇属性モンスターをアドバンス召喚する場合、このカードは２体分のリリースにできる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダブルフィン・シャーク",h:"だぶるふぃん・しゃーく",e:"Double Fin Shark",ra:"R",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1000,de:1200,tx:"このカードが召喚に成功した時、自分の墓地からレベル３またはレベル４の魚族・水属性モンスター１体を選択して表側守備表示で特殊召喚できる。\nこの効果で特殊召喚したモンスターの効果は無効化される。\nこの効果を発動するターン、自分は水属性以外のモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魂喰らい",h:"たまぐらい",e:"Souleater",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"魚族",mo:["通常"],ma:"",tp:"",at:1200,de:0,tx:"全てが謎に包まれている超生命体。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魂を狩る者",h:"たましいをかるもの",e:"One Who Hunts Souls",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["通常"],ma:"",tp:"",at:1100,de:1000,tx:"剣で斬りつけられた者は、魂をぬかれてしまう。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魂を喰らう者 バズー",h:"たましいをくらうもの　ばずー",e:"Bazoo the Soul-Eater",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1600,de:900,tx:"１ターンに１度、自分の墓地に存在するモンスターを３体までゲームから除外して発動する事ができる。\nこのカードの攻撃力は相手のエンドフェイズ時まで、除外したモンスター１体につき３００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魂を呼ぶ者",h:"たましいをよぶもの",e:"Spirit Caller",ra:"R",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["リバース","効果"],ma:"",tp:"",at:1000,de:1000,tx:"リバース：自分の墓地からレベル３以下の通常モンスター１体を自分フィールド上に特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダメージ・イーター",h:"だめーじ・いーたー",e:"Damage Eater",ra:"N",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:100,de:800,tx:"相手がダメージを与える魔法・罠・効果モンスターの効果を発動した時、墓地に存在するこのカードをゲームから除外して発動する事ができる。\nその効果は、ライフポイントを回復する効果になる。\nこの効果は相手ターンにのみ発動する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダメージ・メイジ",h:"だめーじ・めいじ",e:"Damage Mage",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:600,de:1200,tx:"カードの効果によって自分がダメージを受けた時に発動できる。\nこのカードを手札から特殊召喚し、受けたダメージの数値分だけ自分のライフポイントを回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"タルワール・デーモン",h:"たるわーる・でーもん",e:"Beast of Talwar",ra:"R",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:2400,de:2150,tx:"そのタルワールは、悪魔族でも剣術の達人しか持つ事を許されていない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"タン・ツイスター",h:"たん・ついすたー",e:"Tongue Twister",ra:"N",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:400,de:300,tx:"アドバンス召喚したこのカードがフィールド上から墓地へ送られた時、自分のデッキからカードを２枚ドローする。\nこの効果を発動した場合、このカードをゲームから除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"弾圧される民",h:"だんあつされるたみ",e:"Oppressed People",ra:"R",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:400,de:2000,tx:"いつの日か自由を手にする事ができると信じて日々の生活に耐えている。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"舌魚",h:"たんぎょ",e:"Tongyo",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["通常"],ma:"",tp:"",at:1350,de:800,tx:"他の魚を長い舌で捕まえ、エネルギーを吸収する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"団結するレジスタンス",h:"だんけつするれじすたんす",e:"United Resistance",ra:"N",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"雷族",mo:["通常"],ma:"",tp:"",at:1000,de:400,tx:"強大な力に立ち向かう誓いを交わすために集結した人々。\n革命の日は近い。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"誕生の天使",h:"たんじょうのてんし",e:"Winged Egg of New Life",ra:"N",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:1400,de:1700,tx:"女性のおなかに命が宿った事を知らせてくれると言われている。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダンジョン・ワーム",h:"だんじょん・わーむ",e:"Dungeon Worm",ra:"R",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"昆虫族",mo:["通常"],ma:"",tp:"",at:1800,de:1500,tx:"迷路の地下に潜み、上を通る者を大きな口で丸飲みにする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダンシング・エルフ",h:"だんしんぐ・えるふ",e:"Dancing Elf",ra:"N",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:300,de:200,tx:"音楽に合わせ天を舞うエルフ。\n体の羽は、鋭利な刃物。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダンシング・ソルジャー",h:"だんしんぐ・そるじゃー",e:"Performance of Sword",ra:"R",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["儀式"],ma:"",tp:"",at:1950,de:1850,tx:"「踊りによる誘発」により降臨。\nフィールドか手札から、レベルが６以上になるようカードを生け贄に捧げなければならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"踊る妖精",h:"だんしんぐふぇありー",e:"Dancing Fairy",ra:"SR",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"このカードが自分フィールド上に表側守備表示で存在する限り、自分のスタンバイフェイズ毎に自分は１０００ライフポイント回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"チェイス・スカッド",h:"ちぇいす・すかっど",e:"Pursuit Chaser",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1400,de:600,tx:"フィールド上に守備表示で存在するモンスターが戦闘によって破壊され墓地へ送られた時、相手ライフに５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"チェイン・スラッシャー",h:"ちぇいん・すらっしゃー",e:"Chain Thrasher",ra:"N",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:600,tx:"このカードは通常の攻撃に加えて、自分の墓地に存在する「チェイン・スラッシャー」の数だけ１度のバトルフェイズ中に攻撃する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"C・コイル",h:"ちぇーん・こいる",e:"Iron Chain Coil",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:1100,de:1600,tx:"自分フィールド上に存在する「C」と名のついたモンスター１体を選択して発動する。\nそのモンスターの攻撃力・守備力は３００ポイントアップする。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"C・シューター",h:"ちぇーん・しゅーたー",e:"Iron Chain Blaster",ra:"SR",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1100,de:0,tx:"自分フィールド上に存在する「C」と名のついたモンスター１体を墓地へ送って発動する。\n相手ライフに８００ポイントダメージを与える。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"C・スネーク",h:"ちぇーん・すねーく",e:"Iron Chain Snake",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:800,de:1200,tx:"自分のメインフェイズ時に装備カード扱いとして相手フィールド上に存在する表側表示モンスター１体に装備する事ができる。\nこのカードを装備したモンスターの攻撃力・守備力は８００ポイントダウンする。\n装備モンスターが戦闘によって破壊され墓地へ送られた時、装備モンスターのコントローラーのデッキの上からそのモンスターのレベルと同じ枚数のカードを墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"C・ドラゴン",h:"ちぇーん・どらごん",e:"Iron Chain Dragon",ra:"SR",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:1300,tx:"チューナー＋チューナー以外のモンスター１体以上\n自分のメインフェイズ時に発動できる。\n自分の墓地の「C」と名のついたモンスターを全てゲームから除外する。\nこのカードの攻撃力はエンドフェイズ時まで、この効果で除外したモンスターの数×２００ポイントアップする。\nまた、このカードが相手ライフに戦闘ダメージを与えた時、相手のデッキの上からカードを３枚墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"チェーン・リゾネーター",h:"ちぇーん・りぞねーたー",e:"Chain Resonator",ra:"R",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:100,de:100,tx:"①：フィールドにSモンスターが存在し、このカードが召喚に成功した時に発動できる。\nデッキから「チェーン・リゾネーター」以外の「リゾネーター」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"C・リペアラー",h:"ちぇーん・りぺあらー",e:"Iron Chain Repairman",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"このカードが戦闘によってモンスターを破壊し墓地へ送った時、相手ライフに３００ポイントダメージを与える。\n１ターンに１度、自分の墓地に存在する「C・リペアラー」以外の「C」と名のついたレベル４以下のモンスター１体を特殊召喚する事ができる。\nこの効果を発動するターンこのカードは攻撃する事ができない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"電動刃虫",h:"ちぇーんそー・いんせくと",e:"Chainsaw Insect",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:2400,de:0,tx:"このカードが戦闘を行った場合、ダメージステップ終了時に相手プレイヤーはカードを１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"チェーンドッグ",h:"ちぇーんどっぐ",e:"Chain Dog",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1600,de:1100,tx:"自分フィールド上に獣族モンスターが表側表示で２体存在する場合、このカードを墓地から特殊召喚する事ができる。\nこの効果で特殊召喚したこのカードはフィールド上から離れた場合、ゲームから除外される。\nこのカードをシンクロ素材とする場合、獣族モンスターのシンクロ召喚にしか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヂェミナイ・エルフ",h:"ぢぇみない・えるふ",e:"Gemini Elf",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:1900,de:900,tx:"交互に攻撃を仕掛けてくる、エルフの双子姉妹。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヂェミナイ・デビル",h:"ぢぇみない・でびる",e:"Gemini Imps",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"自分の手札を捨てる効果を含むカードを相手が発動した時、このカードを手札から墓地に送る事でその発動と効果を無効にし、そのカードを破壊した後自分のデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"チェンジ・シンクロン",h:"ちぇんじ・しんくろん",e:"Changer Synchron",ra:"N",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"このカードがシンクロモンスターのシンクロ召喚に使用され墓地へ送られた場合、相手フィールド上に存在するモンスター１体を選択して表示形式を変更する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"チェンジ・スライム",h:"ちぇんじ・すらいむ",e:"Change Slime",ra:"N",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:400,de:300,tx:"形を自由に変え、様々なものに変身するスライム。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"力の代行者 マーズ",h:"ちからのだいこうしゃ　まーず",e:"The Agent of Force - Mars",ra:"N",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"①：フィールドのこのカードは魔法カードの効果を受けない。\n②：自分フィールドに「天空の聖域」が存在し、自分のLPが相手より多い場合、その差の数値分このカードの攻撃力・守備力はアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"地球巨人 ガイア・プレート",h:"ちきゅうきょじん　がいあ・ぷれーと",e:"Gaia Plate the Earth Giant",ra:"UR",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:2800,de:1000,tx:"このカードのコントローラーは、自分スタンバイフェイズ毎に自分の墓地から岩石族モンスター１体を除外する。\nまたは除外せずにこのカードを墓地へ送る。\n①：このカードは自分の墓地の岩石族モンスター２体を除外し、手札から特殊召喚できる。\n②：このカードと戦闘を行う相手モンスターの攻撃力・守備力はダメージ計算時のみ半分になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"地帝家臣ランドローブ",h:"ちていかしんらんどろーぶ",e:"Landrobe the Rock Vassal",ra:"UR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:800,de:1000,tx:"「地帝家臣ランドローブ」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手フィールドの表側表示モンスター１体を対象として発動できる。\nこのカードを手札から特殊召喚し、対象のモンスターを裏側守備表示にする。\nこのターン、自分はエクストラデッキからモンスターを特殊召喚できない。\n②：このカードがアドバンス召喚のためにリリースされた場合、「地帝家臣ランドローブ」以外の自分の墓地の攻撃力８００／守備力１０００のモンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"地帝グランマーグ",h:"ちていぐらんまーぐ",e:"Granmarg the Rock Monarch",ra:"SR",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:2400,de:1000,tx:"①：このカードがアドバンス召喚に成功した場合、フィールドにセットされたカード１枚を対象として発動する。\nセットされたそのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"地底のアラクネー",h:"ちていのあらくねー",e:"Underground Arachnid",ra:"R",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"昆虫族",mo:["シンクロ","効果"],ma:"",tp:"",at:2400,de:1200,tx:"闇属性チューナー＋チューナー以外の昆虫族モンスター１体\nこのカードが攻撃する場合、相手はダメージステップ終了時まで魔法・罠カードを発動する事ができない。\n１ターンに１度、相手フィールド上に表側表示で存在するモンスターを装備カード扱いとしてこのカードに１体のみ装備する事ができる。\nこのカードが戦闘によって破壊される場合、代わりにこの効果で装備したモンスターを破壊する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"智天使ハーヴェスト",h:"ちてんしはーう゛ぇすと",e:"Harvest Angel of Wisdom",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"①：このカードが戦闘で破壊され墓地へ送られた時、自分の墓地のカウンター罠カード１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"地天の騎士ガイアドレイク",h:"ちてんのきしがいあどれいく",e:"Gaia Drake, the Universal Force",ra:"SR",t:"monster",a:"地",l:10,ps:"",pe:"",tr:"獣戦士族",mo:["融合","効果"],ma:"",tp:"",at:3500,de:2800,tx:"「大地の騎士ガイアナイト」＋効果モンスター以外のシンクロモンスター\nこのカードは効果モンスターの効果の対象にならず、効果モンスターの効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"チャウチャウちゃん",h:"ちゃうちゃうちゃん",e:"Chow Chow Chan",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"相手モンスターと戦闘を行う自分のモンスターの攻撃宣言時に相手が通常罠カードを発動した時、このカードを手札から捨てて発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"チャクラ",h:"ちゃくら",e:"Chakra",ra:"N",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"悪魔族",mo:["儀式"],ma:"",tp:"",at:2450,de:2000,tx:"「チャクラの復活」により降臨。\n場か手札から、星の数が合計７個以上になるようカードを生け贄に捧げなければならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"チャッチャカアーチャー",h:"ちゃっちゃかあーちゃー",e:"Chachaka Archer",ra:"N",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:1800,tx:"１ターンに１度、フィールド上の魔法・罠カード１枚を選択して発動できる。\n選択したカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"チューニング・サポーター",h:"ちゅーにんぐ・さぽーたー",e:"Tuningware",ra:"R",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:100,de:300,tx:"①：フィールドのこのカードをS素材とする場合、このカードはレベル２モンスターとして扱う事ができる。\n②：このカードがS素材として墓地へ送られた場合に発動する。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"チューニングガム",h:"ちゅーにんぐがむ",e:"Tuning Gum",ra:"N",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:400,de:1200,tx:"「チューニングガム」の①の効果は１ターンに１度しか使用できない。\n①：自分フィールドの表側表示モンスター１体を対象として発動できる。\nこのターン、その表側表示モンスターをチューナーとして扱う。\nこの効果を発動するターン、自分はSモンスターでしか攻撃できない。\n②：自分フィールドのSモンスター１体のみを対象とする魔法・罠・モンスターの効果が発動した時、墓地のこのカードを除外して発動できる。\nその発動を無効にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"チューン・ウォリアー",h:"ちゅーん・うぉりあー",e:"Tune Warrior",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["チューナー","通常"],ma:"",tp:"",at:1600,de:200,tx:"あらゆるものをチューニングしてしまう電波系戦士。\n常にアンテナを張ってはいるものの、感度はそう高くない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"チューンド・マジシャン",h:"ちゅーんど・まじしゃん",e:"Tuned Magician",ra:"R",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["デュアル","効果"],ma:"",tp:"",at:1800,de:1600,tx:"このカードは墓地またはフィールド上に表側表示で存在する場合、通常モンスターとして扱う。\nフィールド上に表側表示で存在するこのカードを通常召喚扱いとして再度召喚する事で、このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●このカードがフィールド上に表側表示で存在する限り、このカードをチューナーとして扱う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"超化合獣メタン・ハイド",h:"ちょうかごうじゅうめたん・はいど",e:"Vola-Chemicritter Methydraco",ra:"R",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"獣戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:3000,de:3000,tx:"レベル８デュアルモンスター×２\n①：このカードがX召喚に成功した時、自分の墓地のデュアルモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n②：X素材を持ったこのカードがモンスターゾーンに存在する限り、相手は自分フィールドのデュアルモンスターを攻撃対象にできず、効果の対象にもできない。\n③：デュアルモンスターが召喚に成功した時、このカードのX素材を１つ取り除いて発動できる。\n相手は自身の手札・フィールドのカード１枚を墓地へ送らなければならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"超合魔獣ラプテノス",h:"ちょうごうまじゅうらぷてのす",e:"Superalloy Beast Raptinus",ra:"SR",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:2200,de:2200,tx:"デュアルモンスター×２\n①：このカードがモンスターゾーンに存在する限り、フィールドのデュアルモンスターはもう１度召喚された状態として扱う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"超時空戦闘機ビック・バイパー",h:"ちょうじくうせんとうきびっく・ばいぱー",e:"Gradius",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1200,de:800,tx:"パワーカプセルにより、様々な能力を発揮する超高性能戦闘機。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"超重剣聖ムサ－C",h:"ちょうじゅうけんせいむさ－しー",e:"Superheavy Samurai Swordmaster Musashi",ra:"R",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:300,de:2300,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカードはルール上「超重武者」カードとしても扱う。\n①：このカードがS召喚に成功した時、自分の墓地の機械族モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\n自分の墓地に魔法・罠カードが存在する場合、このターン自分はそのモンスター及びその同名モンスターを召喚・特殊召喚できない。\n②：このカードは表側守備表示のままで攻撃できる。\nその場合、このカードは守備力を攻撃力として扱いダメージ計算を行う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"超重神鬼シュテンドウ－G",h:"ちょうじゅうしんきしゅてんどう－じー",e:"Superheavy Samurai Ogre Shutendoji",ra:"R",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:500,de:2500,tx:"機械族チューナー１体＋チューナー以外の「超重武者」モンスター１体以上\nこのカードはルール上「超重武者」カードとしても扱う。\n①：このカードは表側守備表示のままで攻撃できる。\nその場合、このカードは守備力を攻撃力として扱いダメージ計算を行う。\n②：自分の墓地に魔法・罠カードが存在せず、このカードがS召喚に成功した時に発動できる。\n相手フィールドの魔法・罠カードを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"超重忍者シノビ－A・C",h:"ちょうじゅうにんじゃしのび－あ・しー",e:"Superheavy Samurai Stealth Ninja",ra:"R",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:1200,de:2800,tx:"機械族チューナー＋チューナー以外の機械族モンスター１体以上\nこのカードはルール上「超重武者」カードとしても扱う。\n①：このカードは表側守備表示のままで攻撃できる。\nその場合、このカードは守備力を攻撃力として扱いダメージ計算を行う。\n②：自分の墓地に魔法・罠カードが存在しない場合に発動できる。\nこのカードの元々の守備力はターン終了時まで半分になり、このターンこのカードは直接攻撃できる。\n③：このカードが効果で破壊され墓地へ送られた場合、次のスタンバイフェイズに発動できる。\nこのカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"超重魔獣キュウ－B",h:"ちょうじゅうまじゅうきゅう－びー",e:"Superheavy Samurai Beast Kyubi",ra:"N",t:"monster",a:"地",l:9,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:1900,de:2500,tx:"チューナー＋チューナー以外の「超重武者」モンスター１体以上\nこのカードはルール上「超重武者」カードとしても扱う。\n①：このカードは表側守備表示のままで攻撃できる。\nその場合、このカードは守備力を攻撃力として扱いダメージ計算を行う。\n②：自分の墓地に魔法・罠カードが存在しない場合、このカードの守備力は、相手フィールドの特殊召喚されたモンスターの数×９００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"超重武者カゲボウ－C",h:"ちょうじゅうむしゃかげぼう－しー",e:"Superheavy Samurai Flutist",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:500,de:1000,tx:"「超重武者カゲボウ－C」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードをリリースして発動できる。\n手札から「超重武者」モンスター１体を特殊召喚する。\n②：自分フィールドの「超重武者」モンスターが効果の対象になった時、墓地のこのカードを除外して発動できる。\nその発動を無効にし破壊する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"超重武者カブ－10",h:"ちょうじゅうむしゃかぶ－と",e:"Superheavy Samurai Kabuto",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:2000,tx:"①：相手がモンスターの特殊召喚に成功した場合に発動できる。\n自分フィールドの攻撃表示の「超重武者」モンスターは全て守備表示になり、その守備力はターン終了時まで５００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"超重武者グロウ－V",h:"ちょうじゅうむしゃぐろう－ぶ",e:"Superheavy Samurai Gigagloves",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:100,de:1000,tx:"①：自分の墓地に魔法・罠カードが存在せず、このカードが墓地へ送られた場合に発動できる。\nデッキの上からカードを５枚確認し、好きな順番でデッキの上に戻す。\n②：相手モンスターの直接攻撃宣言時、墓地のこのカードを除外して発動できる。\n自分のデッキの一番上のカードをめくり、そのカードが「超重武者」モンスターだった場合は手札に加え、その攻撃モンスターの攻撃力を０にする。\n違った場合はめくったカードを墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"超重武者ココロガマ－A",h:"ちょうじゅうむしゃこころがま－えー",e:"Superheavy Samurai Prepped Defense",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:100,de:2100,tx:"自分の墓地に魔法・罠カードが存在する場合、このカードは召喚・反転召喚できない。\n①：自分の墓地に魔法・罠カードが存在せず、自分が戦闘ダメージを受けた時に発動できる。\nこのカードを手札から特殊召喚する。\nこの効果で特殊召喚したこのカードは、このターン戦闘・効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"超重武者コブ－C",h:"ちょうじゅうむしゃこぶ－しー",e:"Superheavy Samurai Fist",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:900,de:900,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の「超重武者」モンスターが戦闘で相手モンスターを破壊した自分バトルフェイズに発動できる。\nこのカードを含む自分フィールドのモンスターをS素材としてS召喚する。\n②：自分の墓地に魔法・罠カードが存在しない場合、自分フィールドの「超重武者」Sモンスター１体を対象として発動できる。\nそのモンスターのレベルを１つ下げ、このカードを墓地から特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は「超重武者」モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"超重武者ジシャ－Q",h:"ちょうじゅうむしゃじしゃ－くー",e:"Superheavy Samurai Magnet",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:900,de:1900,tx:"①：このカードが召喚に成功した時に発動できる。\n手札からレベル４以下の「超重武者」モンスター１体を特殊召喚する。\nその後、このカードを守備表示にする。\n②：このカードがモンスターゾーンに存在する限り、相手は他のモンスターを攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"超重武者装留イワトオシ",h:"ちょうじゅうむしゃそうるいわとおし",e:"Superheavy Samurai Soulpiercer",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:0,tx:"①：自分メインフェイズに自分フィールドの「超重武者」モンスター１体を対象として発動できる。\n自分の手札・フィールドからこのモンスターを装備カード扱いとしてその自分のモンスターに装備する。\n②：このカードの効果でこのカードを装備したモンスターが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ相手に戦闘ダメージを与える。\n③：このカードがフィールドから墓地へ送られた場合に発動できる。\nデッキから「超重武者装留イワトオシ」以外の「超重武者」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"超重武者装留グレート・ウォール",h:"ちょうじゅうむしゃそうるぐれーと・うぉーる",e:"Superheavy Samurai Soulshield Wall",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:1200,tx:"①：自分メインフェイズに自分フィールドの「超重武者」モンスター１体を対象として発動できる。\n自分の手札・フィールドからこのモンスターを守備力１２００アップの装備カード扱いとしてその自分のモンスターに装備する。\n②：このカードの効果でこのカードを装備したモンスターが攻撃対象に選択された時、装備されているこのカードを墓地へ送って発動できる。\nその攻撃を無効にし、装備モンスターの守備力を０にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"超重武者装留シャイン・クロー",h:"ちょうじゅうむしゃそうるしゃいん・くろー",e:"Superheavy Samurai Soulclaw",ra:"SR",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:500,de:500,tx:"「超重武者装留シャイン・クロー」の②の効果は１ターンに１度しか使用できない。\n①：自分メインフェイズに自分フィールドの「超重武者」モンスター１体を対象として発動できる。\n自分の手札・フィールドからこのモンスターを攻撃力・守備力５００アップの装備カード扱いとしてその自分のモンスターに装備する。\n装備モンスターは戦闘では破壊されない。\n②：このカードの効果でこのカードが装備されている場合に発動できる。\n装備されているこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"超重武者装留ダブル・ホーン",h:"ちょうじゅうむしゃそうるだぶる・ほーん",e:"Superheavy Samurai Soulhorns",ra:"N",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:300,tx:"「超重武者装留ダブル・ホーン」の②の効果は１ターンに１度しか使用できない。\n①：自分メインフェイズに自分フィールドの「超重武者」モンスター１体を対象として発動できる。\n自分の手札・フィールドからこのモンスターを装備カード扱いとしてその自分のモンスターに装備する。\n装備モンスターは、１度のバトルフェイズ中に２回攻撃できる。\n②：このカードの効果でこのカードが装備されている場合に発動できる。\n装備されているこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"超重武者装留チュウサイ",h:"ちょうじゅうむしゃそうるちゅうさい",e:"Superheavy Samurai Soulpeacemaker",ra:"SR",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"「超重武者装留チュウサイ」の③の効果は１ターンに１度しか使用できない。\n①：自分メインフェイズに自分フィールドの「超重武者」モンスター１体を対象として発動できる。\n自分の手札・フィールドからこのモンスターを装備カード扱いとしてその自分のモンスターに装備する。\n②：このカードの効果でこのカードが装備されている場合、相手は装備モンスター以外の自分のモンスターを攻撃できない。\n③：このカードの効果でこのカードを装備した自分のモンスターをリリースして発動できる。\nデッキから「超重武者」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"超重武者装留ビッグバン",h:"ちょうじゅうむしゃそうるびっぐばん",e:"Superheavy Samurai Soulbang Cannon",ra:"UR",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"①：自分メインフェイズに自分フィールドの「超重武者」モンスター１体を対象として発動できる。\n自分の手札・フィールドのこのモンスターを守備力１０００アップの装備カード扱いとしてそのモンスターに装備する。\n②：自分フィールドに守備表示の「超重武者」モンスターが存在し、相手がバトルフェイズに魔法・罠・モンスターの効果を発動した時、墓地のこのカードを除外して発動できる。\nその発動を無効にし破壊する。\nその後、フィールドのモンスターを全て破壊し、お互いのプレイヤーは１０００ダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"超重武者装留マカルガエシ",h:"ちょうじゅうむしゃそうるまかるがえし",e:"Superheavy Samurai Soulbeads",ra:"N",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"①：自分メインフェイズに自分フィールドの「超重武者」モンスター１体を対象として発動できる。\n自分の手札・フィールドからこのモンスターを装備カード扱いとしてその自分のモンスターに装備する。\n②：このカードの効果でこのカードを装備したモンスターは、１ターンに１度だけ効果では破壊されない。\n③：守備表示モンスターが戦闘で破壊され自分の墓地へ送られた時、このカードを手札から墓地へ送って発動できる。\nそのモンスターを攻撃表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"超重武者ソード－９９９",h:"ちょうじゅうむしゃそーど－きゅーきゅーきゅー",e:"Superheavy Samurai Swordsman",ra:"SR",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:1800,tx:"①：このカードが召喚・特殊召喚に成功した時に発動できる。\nこのカードの表示形式を変更する。\n②：自分フィールドの「超重武者」モンスターが相手モンスターと戦闘を行ったダメージ計算後に発動する。\nその相手モンスターの攻撃力・守備力は０になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"超重武者ダイ－８",h:"ちょうじゅうむしゃだい－はち",e:"Superheavy Samurai Wagon",ra:"UR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:1800,tx:"「超重武者ダイ－８」の③の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した時に発動できる。\nこのカードの表示形式を変更する。\n②：このカードは表側守備表示のままで攻撃できる。\nその場合、このカードは守備力を攻撃力として扱いダメージ計算を行う。\n③：自分の墓地に魔法・罠カードが存在しない場合に発動できる。\n自分フィールドの表側守備表示のこのカードを攻撃表示にし、デッキから「超重武者装留」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"超重武者ツヅ－3",h:"ちょうじゅうむしゃつづ－み",e:"Superheavy Samurai Drum",ra:"N",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:300,de:300,tx:"「超重武者ツヅ－３」の効果は１ターンに１度しか使用できない。\n①：フィールドのこのカードが破壊され墓地へ送られた場合、「超重武者ツヅ－３」以外の自分の墓地の「超重武者」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"超重武者ビッグベン－K",h:"ちょうじゅうむしゃびっぐべん－けー",e:"Superheavy Samurai Big Benkei",ra:"UR",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:3500,tx:"①：このカードが召喚・特殊召喚に成功した時に発動できる。\nこのカードの表示形式を変更する。\n②：このカードがモンスターゾーンに存在する限り、自分フィールドの「超重武者」モンスターは、表側守備表示のままで攻撃できる。\nその場合、そのモンスターは守備力を攻撃力として扱いダメージ計算を行う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"超重武者ビッグワラ－G",h:"ちょうじゅうむしゃびっぐわら－じー",e:"Superheavy Samurai Big Waraji",ra:"R",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:800,de:1800,tx:"①：自分の墓地に魔法・罠カードが存在しない場合、このカードは手札から特殊召喚できる。\nこの方法で特殊召喚に成功したターン、自分は「超重武者」モンスターしか特殊召喚できない。\n②：機械族モンスターをアドバンス召喚する場合、このカードは２体分のリリースにできる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"超重武者ホラガ－E",h:"ちょうじゅうむしゃほらが－いー",e:"Superheavy Samurai Trumpeter",ra:"R",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:300,de:600,tx:"①：自分の墓地に魔法・罠カードが存在しない場合、このカードは手札から特殊召喚できる。\nこの方法で特殊召喚に成功したターン、自分は「超重武者」モンスターしか特殊召喚できない。\n②：自分の墓地に魔法・罠カードが存在せず、このカードをリリースして「超重武者」モンスターのアドバンス召喚に成功した場合に発動できる。\nこのカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"調星師ライズベルト",h:"ちょうせいしらいずべると",e:"Risebell the Star Adjuster",ra:"SR",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"①：このカードが特殊召喚に成功した時、フィールドの表側表示モンスター１体を対象として発動できる。\nターン終了時まで、そのモンスターのレベルを３つまで上げる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"超戦士カオス・ソルジャー",h:"ちょうせんしかおす・そるじゃー",e:"Black Luster Soldier - Super Soldier",ra:"UR",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"戦士族",mo:["儀式","効果"],ma:"",tp:"",at:3000,de:2500,tx:"「超戦士の儀式」により降臨。\n自分は「超戦士カオス・ソルジャー」を１ターンに１度しか特殊召喚できない。\n①：このカードが戦闘で相手モンスターを破壊し墓地へ送った場合に発動する。\nそのモンスターの元々の攻撃力分のダメージを相手に与える。\n②：このカードが戦闘または相手の効果で破壊され墓地へ送られた場合に発動できる。\n自分の手札・デッキ・墓地から「暗黒騎士ガイア」モンスター１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"超戦士の魂",h:"ちょうせんしのたましい",e:"Super Soldier Soul",ra:"SR",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"「超戦士の魂」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札の「カオス・ソルジャー」モンスター１体を墓地へ送って発動できる。\nこのカードは次の相手エンドフェイズまで、攻撃力が３０００になり、カード名を「カオス・ソルジャー」として扱う。\n②：墓地のこのカードを除外して発動できる。\nデッキから「開闢の騎士」または「宵闇の騎士」１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"超装甲兵器ロボ ブラックアイアンG",h:"ちょうそうこうへいきろぼ　ぶらっくあいあんじー",e:"Super Armored Robot Armed Black Iron \"C\"",ra:"R",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:2400,de:2800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の墓地に昆虫族の同名モンスターが３体存在する場合、その内の任意の数だけ対象として発動できる。\nこのカードを手札から特殊召喚する。\nその後、対象のモンスターを装備カード扱いとしてこのカードに装備する。\n②：このカードの効果で装備しているモンスターカード１枚を墓地へ送って発動できる。\n墓地へ送ったそのカードの攻撃力以上の攻撃力を持つ、相手フィールドのモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"超電磁稼動ボルテック・ドラゴン",h:"ちょうでんじかどうぼるてっく・どらごん",e:"Super-Electromagnetic Voltech Dragon",ra:"R",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:2400,de:1000,tx:"以下のモンスターを生け贄にして生け贄召喚した場合、このカードはそれぞれの効果を得る。\n\n●電池メン－単一型：このカード１枚を対象にする魔法・罠カードの効果を無効にする。\n\n●電池メン－単二型：このカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が越えていれば、その数値だけ相手ライフに戦闘ダメージを与える。\n\n●電池メン－単三型：このカードの攻撃力は１０００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"超魔導騎士－ブラック・キャバルリー",h:"ちょうまどうきし－ぶらっく・きゃばるりー",e:"Dark Cavalry",ra:"UR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"魔法使い族",mo:["融合","効果"],ma:"",tp:"",at:2800,de:2300,tx:"「ブラック・マジシャン」＋戦士族モンスター\n①：このカードの攻撃力は、お互いのフィールド・墓地の魔法・罠カードの数×１００アップする。\n②：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。\n③：フィールドのカードを対象とする魔法・罠・モンスターの効果が発動した時、手札を１枚捨てて発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"超魔導剣士－ブラック・パラディン",h:"ちょうまどうけんし－ぶらっく・ぱらでぃん",e:"Dark Paladin",ra:"UR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"魔法使い族",mo:["融合","効果"],ma:"",tp:"",at:2900,de:2400,tx:"「ブラック・マジシャン」＋「バスター・ブレイダー」\nこのカードは融合召喚でしか特殊召喚できない。\n①：このカードの攻撃力は、お互いのフィールド・墓地のドラゴン族モンスターの数×５００アップする。\n②：魔法カードが発動した時、手札を１枚捨てて発動できる。\nこのカードがフィールドに表側表示で存在する場合、その発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"チョコ・マジシャン・ガール",h:"ちょこ・まじしゃん・がーる",e:"Chocolate Magician Girl",ra:"UR",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"①：１ターンに１度、手札から魔法使い族モンスター１体を捨てて発動できる。\n自分はデッキから１枚ドローする。\n②：１ターンに１度、このカードが攻撃対象に選択された場合、「チョコ・マジシャン・ガール」以外の自分の墓地の魔法使い族モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nその後、攻撃対象をそのモンスターに移し替え、攻撃モンスターの攻撃力を半分にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"地霊神グランソイル",h:"ちれいしんぐらんそいる",e:"Grandsoil the Elemental Lord",ra:"UR",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"獣戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2800,de:2200,tx:"このカードは通常召喚できない。\n自分の墓地の地属性モンスターが５体の場合のみ特殊召喚できる。\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した時、自分または相手の墓地のモンスター１体を対象として発動できる。\nそのモンスターを自分フィールドに特殊召喚する。\n②：表側表示のこのカードがフィールドから離れた場合、次の自分ターンのバトルフェイズをスキップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"地霊使いアウス",h:"ちれいつかいあうす",e:"Aussa the Earth Charmer",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["リバース","効果"],ma:"",tp:"",at:500,de:1500,tx:"①：このカードがリバースした場合、相手フィールドの地属性モンスター１体を対象として発動する。\nこのモンスターが表側表示で存在する間、そのモンスターのコントロールを得る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"地を這うドラゴン",h:"ちをはうどらごん",e:"Crawling Dragon",ra:"N",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:1600,de:1400,tx:"力が弱り、空を飛べなくなったドラゴン。\nしかしまだ攻撃は強い。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"沈黙の剣士－サイレント・ソードマン",h:"ちんもくのけんし－さいれんと・そーどまん",e:"Silent Swordsman",ra:"UR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードは通常召喚できない。\n自分フィールドの戦士族モンスター１体をリリースした場合のみ特殊召喚できる。\n①：自分・相手のスタンバイフェイズに発動する。\nこのカードの攻撃力は５００アップする。\n②：１ターンに１度、魔法カードが発動した時に発動できる。\nその発動を無効にする。\n③：フィールドのこのカードが戦闘または相手の効果で破壊された場合に発動できる。\n手札・デッキから「沈黙の剣士－サイレント・ソードマン」以外の「サイレント・ソードマン」モンスター１体を召喚条件を無視して特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"沈黙のサイコウィザード",h:"ちんもくのさいこうぃざーど",e:"Silent Psychic Wizard",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1900,de:0,tx:"このカードが召喚に成功した時、自分の墓地に存在するサイキック族モンスター１体を選択してゲームから除外する事ができる。\nこのカードがフィールド上から墓地へ送られた時、このカードの効果で除外したモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"沈黙の魔術師－サイレント・マジシャン",h:"ちんもくのまじゅつし－さいれんと・まじしゃん",e:"Silent Magician",ra:"UR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードは通常召喚できない。\n自分フィールドの魔法使い族モンスター１体をリリースした場合のみ特殊召喚できる。\n①：このカードの攻撃力は、自分の手札の数×５００アップする。\n②：１ターンに１度、魔法カードが発動した時に発動できる。\nその発動を無効にする。\n③：フィールドのこのカードが戦闘または相手の効果で破壊された場合に発動できる。\n手札・デッキから「沈黙の魔術師－サイレント・マジシャン」以外の「サイレント・マジシャン」モンスター１体を召喚条件を無視して特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"沈黙の魔導剣士－サイレント・パラディン",h:"ちんもくのまどうけんし－さいれんと・ぱらでぃん",e:"Silent Paladin",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:500,de:1500,tx:"①：このカードが召喚に成功した時に発動できる。\nデッキから「サイレント・ソードマン LV３」または「サイレント・マジシャン LV４」１体を手札に加える。\n②：このカードがフィールドに表側表示で存在する限り１度だけ、自分フィールドのモンスター１体のみを対象とする魔法カードが発動した時に発動できる。\nその発動を無効にする。\n③：フィールドのこのカードが戦闘または相手の効果で破壊された場合、自分の墓地の光属性の「LV」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ツイン・フォトン・リザード",h:"ついん・ふぉとん・りざーど",e:"Twin Photon Lizard",ra:"N",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:2400,de:1000,tx:"「フォトン」と名のついたモンスター×２\nこのカードをリリースして発動できる。\nリリースしたこのカードの融合召喚に使用した融合素材モンスター一組を自分の墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ツイン・ブレイカー",h:"ついん・ぶれいかー",e:"Twin-Sword Marauder",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"このカードが守備表示モンスターを攻撃した場合、もう１度だけ続けて攻撃する事ができる。\nこのカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ツインテール",h:"ついんてーる",e:"Twin Long Rods #2",ra:"N",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:850,de:700,tx:"ムチのようなしっぽ２本を振り回し攻撃する、水中生物。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ツインバレル・ドラゴン",h:"ついんばれる・どらごん",e:"Twin-Barrel Dragon",ra:"UR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1700,de:200,tx:"このカードが召喚・反転召喚・特殊召喚に成功した時、相手フィールド上に存在するカード１枚を選択して発動する。\nコイントスを２回行い、２回とも表だった場合、選択したカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ツインヘッド・ケルベロス",h:"ついんへっど・けるべろす",e:"Twin-Headed Wolf",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"自分フィールド上にこのカード以外の悪魔族モンスターが表側表示で存在する場合、このカードが戦闘で破壊したリバース効果モンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ツインへデッド・ビースト",h:"ついんへでっど・びーすと",e:"Twinheaded Beast",ra:"R",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1700,de:1900,tx:"①：このカードは１度のバトルフェイズ中に２回攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"針二千本",h:"つーさうざんど・にーどる",e:"Two Thousand Needles",ra:"N",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:2000,de:1800,tx:"守備表示のこのカードが攻撃を受けた時、このカードの守備力が相手攻撃モンスターの攻撃力を越えていれば、ダメージ計算後にその攻撃モンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ツーヘッド・シャーク",h:"つーへっど・しゃーく",e:"Double Shark",ra:"R",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1200,de:1600,tx:"このカードが召喚に成功した時、自分フィールド上の全ての魚族・レベル４モンスターのレベルを１つ下げる事ができる。\nこのカードは１度のバトルフェイズ中に２回攻撃する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"二つの口を持つ闇の支配者",h:"つーまうす・だーくるーらー",e:"Two-Mouth Darkruler",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"恐竜族",mo:["通常"],ma:"",tp:"",at:900,de:700,tx:"口が二つある恐竜。\nツノに蓄電し、背中の口から放電する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"月明かりの乙女",h:"つきあかりのおとめ",e:"Maiden of the Moonlight",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:1500,de:1300,tx:"月に守護されている月の魔導士。\n神秘的な魔法で幻想を見せる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"月影龍クイラ",h:"つきかげりゅうくいら",e:"Moon Dragon Quilla",ra:"SR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:2000,tx:"「スーパイ」＋チューナー以外のモンスター１体以上\n①：このカードが攻撃対象に選択された場合に発動する。\n攻撃モンスターの攻撃力の半分だけ自分のLPを回復する。\n②：フィールドのこのカードが破壊された場合、自分の墓地の「太陽龍インティ」１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"月の女戦士",h:"つきのおんなせんし",e:"Penumbral Soldier Lady",ra:"R",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2100,de:1400,tx:"光属性モンスターと戦闘をする場合、ダメージステップの間このカードの攻撃力は１０００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"月の使者",h:"つきのししゃ",e:"Moon Envoy",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1100,de:1000,tx:"月の女神に仕える戦士。\n三日月のような矛で攻撃！",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"月の女神 エルザェム",h:"つきのめがみ　えるざぇむ",e:"Lunar Queen Elzaim",ra:"R",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:750,de:1100,tx:"月を守護するきれいな女神。\n月あかりのカーテンで攻撃を防ぐ。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ツクシー",h:"つくしー",e:"Horseytail",ra:"R",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:400,de:500,tx:"このカードが戦闘によって破壊され墓地へ送られた時、相手フィールド上に「ツクシトークン」（植物族・風・星１・攻／守０）１体を守備表示で特殊召喚する。\nこのトークンが植物族モンスターとの戦闘によって破壊された場合、このトークンのコントローラーは手札を１枚墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"月読命",h:"つくよみ",e:"Tsukuyomi",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["スピリット","効果"],ma:"",tp:"",at:1100,de:1400,tx:"このカードは特殊召喚できない。\n①：このカードが召喚・リバースした場合、フィールドの表側表示モンスター１体を対象として発動する。\nそのモンスターを裏側守備表示にする。\n②：このカードが召喚・リバースしたターンのエンドフェイズに発動する。\nこのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"土地鋸",h:"つちのこ",e:"Terrene Toothed Tsuchinoko",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1600,de:500,tx:"このカードが特殊召喚に成功した時、このカード以外のフィールド上の特殊召喚されたモンスターを全て裏側守備表示にする。\n「土地鋸」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"椿姫ティタニアル",h:"つばきてぃたにある",e:"Tytannial, Princess of Camellias",ra:"SR",t:"monster",a:"風",l:8,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:2800,de:2600,tx:"①：フィールドのカードを対象とする魔法・罠・モンスターの効果が発動した時、自分フィールドの表側表示の植物族モンスター１体をリリースして発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"翼の魔妖－天狗",h:"つばさのまやかし－てんぐ",e:"Tengu, the Winged Mayakashi",ra:"R",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"アンデット族",mo:["シンクロ","効果"],ma:"",tp:"",at:2600,de:1500,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：「翼の魔妖－天狗」は自分フィールドに１体しか表側表示で存在できない。\n②：このカードが墓地に存在し、元々のレベルが９の自分のSモンスターが戦闘または相手の効果で破壊された場合に発動できる。\n自分の墓地から他のアンデット族モンスター１体を除外し、このカードを特殊召喚する。\n③：このカードが墓地からの特殊召喚に成功した場合に発動できる。\n相手フィールドの魔法・罠カード１枚を選んで破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"翼を織りなす者",h:"つばさをおりなすもの",e:"Wingweaver",ra:"R",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:2750,de:2400,tx:"６枚の翼をもつハイエンジェル。\n人々の自由と希望を司っている。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"壺魔神",h:"つぼましん",e:"Avatar of The Pot",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1200,de:1300,tx:"手札から「強欲な壺」１枚を墓地へ送る。\n自分のデッキからカードを３枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"氷の魔妖－雪娘",h:"つららのまやかし－ゆきむすめ",e:"Yuki-Musume, the Ice Mayakashi",ra:"R",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:0,de:1900,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが手札・墓地に存在し、自分フィールドに「氷の魔妖－雪娘」以外の「魔妖」カードが存在する場合に発動できる。\nこのカードを特殊召喚する。\nその後、デッキからアンデット族モンスター１体を墓地へ送る。\n②：このカードがモンスターゾーンに存在する限り、自分は「魔妖」モンスターしかEXデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"剣の女王",h:"つるぎのじょおう",e:"Princess of Tsurugi",ra:"N",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"戦士族",mo:["リバース","効果"],ma:"",tp:"",at:900,de:700,tx:"リバース：相手フィールド上に存在する魔法・罠カードの数×５００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ツルプルン",h:"つるぷるん",e:"Turu-Purun",ra:"N",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:450,de:500,tx:"一つ目の奇妙なモンスター。\n手にするもりで、相手をひと突き。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ツンドラの大蠍",h:"つんどらのおおさそり",e:"Giant Scorpion of the Tundra",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"昆虫族",mo:["通常"],ma:"",tp:"",at:1100,de:1000,tx:"砂漠ではなく、ツンドラに分布する珍しい真っ青なサソリ。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"テイ・キューピット",h:"てい・きゅーぴっと",e:"Cupid Serve",ra:"SR",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1000,de:600,tx:"このカード名の②の効果は１ターンに１度しかできない。\n①：このカードはモンスターゾーンに存在する限り、このカードのレベル以下のレベルを持つモンスター以外の全てのモンスターが発動した効果を受けない。\n②：自分の墓地のカードを３枚まで除外して発動できる。\nターン終了時まで、このカードのレベルを、除外した数だけ上げる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"D.D.アサイラント",h:"でぃー．でぃー．あさいらんと",e:"D.D. Assailant",ra:"UR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:1600,tx:"①：このカードが相手モンスターとの戦闘で破壊されたダメージ計算後に発動する。\nその戦闘を行ったそれぞれのモンスターを除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"D.D.クロウ",h:"でぃー．でぃー．くろう",e:"D.D. Crow",ra:"UR",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"①：このカードを手札から墓地へ捨て、相手の墓地のカード１枚を対象として発動できる。\nそのカードを除外する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"D.D.デストロイヤー",h:"でぃー．でぃー．ですとろいやー",e:"D.D. Destroyer",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"フィールド上に存在するこのカードがゲームから除外された時、相手フィールド上に表側表示で存在するカード１枚を選択して破壊する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"D3",h:"でぃーきゅーびっく",e:"D Cubed",ra:"R",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"「D３」の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚に成功した場合に発動する。\nこのモンスターは表側表示で存在する間、「D-HERO」モンスターとしても扱う。\n②：手札を２枚まで捨てて発動できる。\n捨てた数だけ自分の手札・デッキ・墓地から「D３」を選んで特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は「D-HERO」モンスターしか召喚・特殊召喚できない。\n③：このカードが戦闘・効果で破壊された場合に発動できる。\nデッキから「D-HERO」モンスター１体を墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"DDヴァイス・テュポーン",h:"でぃーでぃーう゛ぁいす・てゅぽーん",e:"D/D Vice Typhon",ra:"R",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2300,de:2800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時、自分フィールドの「DD」モンスター１体をリリースして発動できる。\nデッキからレベル７の「DDD」モンスター１体を特殊召喚する。\n②：このカードが墓地へ送られたターンの自分メインフェイズに発動できる。\nレベル８以上の「DDD」融合モンスターカードによって決められた、このカードを含む融合素材モンスターを自分の墓地から除外し、その融合モンスター１体をEXデッキから融合召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"DDオルトロス",h:"でぃーでぃーおるとろす",e:"D/D Orthros",ra:"SR",t:"monster",a:"闇",l:4,ps:3,pe:"①：１ターンに１度、フィールドの魔法・罠カード１枚と、このカード以外の自分フィールドの「DD」カードまたは「契約書」カード１枚を対象として発動できる。\nそのカードを破壊する。",tr:"悪魔族",mo:["ペンデュラム","チューナー","効果"],ma:"",tp:"",at:600,de:1800,tx:"①：自分が戦闘・効果でダメージを受けた時に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが特殊召喚に成功したターン、自分は悪魔族モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"DDゴースト",h:"でぃーでぃーごーすと",e:"D/D Ghost",ra:"R",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:600,de:300,tx:"①：このカードが墓地へ送られた場合、自分の墓地の、「DDゴースト」以外の「DD」モンスター１体または「契約書」カード１枚を対象として発動できる。\nその同名カード１枚をデッキから墓地へ送る。\n②：このカードが除外された場合、除外されている自分のカードの中から、「DDゴースト」以外の「DD」モンスター１体または「契約書」カード１枚を対象として発動できる。\nそのカードを墓地に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"DDスワラル・スライム",h:"でぃーでぃーすわらる・すらいむ",e:"D/D Swirl Slime",ra:"UR",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:200,de:200,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが手札に存在する場合、自分メインフェイズに発動できる。\n「DDD」融合モンスターカードによって決められた、このカードを含む融合素材モンスターを手札から墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。\n②：墓地のこのカードを除外して発動できる。\n手札から「DD」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"DDD極智王カオス・アポカリプス",h:"でぃーでぃーでぃーきょくちおうかおす・あぽかりぷす",e:"D/D/D Chaos King Apocalypse",ra:"UR",t:"monster",a:"闇",l:7,ps:4,pe:"①：自分の墓地の「DD」モンスター２体を除外して発動できる。\nPゾーンのこのカードを特殊召喚する。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2700,de:2000,tx:"「DDD極智王カオス・アポカリプス」のモンスター効果は１ターンに１度しか使用できず、相手ターンにのみ発動できる。\n①：このカードが手札・墓地に存在する場合、自分フィールドの表側表示の魔法・罠カード２枚を対象として発動できる。\nそのカードを破壊し、このカードを特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は悪魔族モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"DDD剋竜王ベオウルフ",h:"でぃーでぃーでぃーこくりゅうおうべおうるふ",e:"D/D/D Dragonbane King Beowulf",ra:"R",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"悪魔族",mo:["融合","効果"],ma:"",tp:"",at:3000,de:2500,tx:"「DDD」モンスター＋「DD」モンスター\n①：このカードがモンスターゾーンに存在する限り、自分の「DD」モンスターが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ相手に戦闘ダメージを与える。\n②：自分スタンバイフェイズに発動できる。\nお互いの魔法＆罠ゾーンのカードを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"DDD死偉王ヘル・アーマゲドン",h:"でぃーでぃーでぃーしいおうへる・あーまげどん",e:"D/D/D Doom King Armageddon",ra:"UR",t:"monster",a:"闇",l:8,ps:4,pe:"①：１ターンに１度、自分フィールドの「DD」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで８００アップする。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:3000,de:1000,tx:"①：１ターンに１度、自分フィールドのモンスターが戦闘・効果で破壊された場合、そのモンスター１体を対象として発動できる。\nこのカードの攻撃力はターン終了時まで、対象のモンスターの元々の攻撃力分アップする。\nこの効果を発動するターン、このカードは直接攻撃できない。\n②：このカードは、このカードを対象としない魔法・罠カードの効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"DDD疾風王アレクサンダー",h:"でぃーでぃーでぃーしっぷうおうあれくさんだー",e:"D/D/D Gust King Alexander",ra:"R",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"悪魔族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:2000,tx:"「DD」チューナー＋チューナー以外のモンスター１体以上\n「DDD疾風王アレクサンダー」の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在し、自分フィールドにこのカード以外の「DD」モンスターが召喚・特殊召喚された場合、自分の墓地のレベル４以下の「DD」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"DDD疾風大王エグゼクティブ・アレクサンダー",h:"でぃーでぃーでぃーしっぷうだいおうえぐぜくてぃぶ・あれくさんだー",e:"D/D/D Gust High King Alexander",ra:"SR",t:"monster",a:"風",l:10,ps:"",pe:"",tr:"悪魔族",mo:["シンクロ","効果"],ma:"",tp:"",at:3000,de:2500,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：フィールドに「DDD」モンスターが３体以上存在する場合、このカードの攻撃力は３０００アップする。\n②：このカードがモンスターゾーンに存在し、自分フィールドにこのカード以外の「DD」モンスターが召喚・特殊召喚された場合、自分の墓地の「DD」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"DDD神託王ダルク",h:"でぃーでぃーでぃーしんたくおうだるく",e:"D/D/D Oracle King d'Arc",ra:"R",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"悪魔族",mo:["融合","効果"],ma:"",tp:"",at:2800,de:2000,tx:"「DD」モンスター×２\n①：このカードがモンスターゾーンに存在する限り、自分にダメージを与える効果は、自分のLPを回復する効果になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"DDD制覇王カイゼル",h:"でぃーでぃーでぃーせいはおうかいぜる",e:"D/D/D Supreme King Kaiser",ra:"UR",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2800,de:2100,tx:"①：このカードがP召喚に成功した場合に発動する。\n相手フィールドの表側表示のカードの効果はターン終了時まで無効になる。\n②：このカードがP召喚に成功したターンのメインフェイズに１度、自分の魔法＆罠ゾーンのカードを２枚まで対象として発動できる。\nそのカードを破壊する。\nこのターン、このカードは通常の攻撃に加えて、この効果で破壊したカードの数まで１度のバトルフェイズ中に攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"DDD狙撃王テル",h:"でぃーでぃーでぃーそげきおうてる",e:"D/D/D Marksman King Tell",ra:"R",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"悪魔族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2300,de:2000,tx:"レベル５モンスター×２\nこのカードは自分フィールドのランク４の「DDD」Xモンスターの上に重ねてX召喚する事もできる。\n①：自分が効果ダメージを受けたターンに１度、このカードのX素材を１つ取り除き、フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力・守備力を１０００ダウンし、相手に１０００ダメージを与える。\nこの効果は相手ターンでも発動できる。\n②：このカードがフィールドから墓地へ送られた場合に発動できる。\nデッキから「DD」カードまたは「契約書」カード１枚を墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"DDD超視王ゼロ・マクスウェル",h:"でぃーでぃーでぃーちょうしおうぜろ・まくすうぇる",e:"D/D/D Supersight King Zero Maxwell",ra:"R",t:"monster",a:"闇",l:7,ps:4,pe:"このカード名のP効果は１ターンに１度しか使用できない。\n①：フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの守備力をターン終了時まで０にする。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2800,de:2500,tx:"①：このカードが相手の守備表示モンスターに攻撃するダメージ計算前に発動できる。\nその相手モンスターの守備力はダメージステップ終了時まで０になる。\n②：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。\n③：このカードの戦闘で発生する自分への戦闘ダメージは０になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"DDD怒濤王シーザー",h:"でぃーでぃーでぃーどとうおうしーざー",e:"D/D/D Wave King Caesar",ra:"SR",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"悪魔族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2400,de:1200,tx:"悪魔族レベル４モンスター×２\n「DDD怒濤王シーザー」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードのX素材を１つ取り除いて発動できる。\nこのターンに破壊されたモンスターをバトルフェイズ終了時に、自分の墓地から可能な限り特殊召喚する。\n次のスタンバイフェイズに自分はこの効果で特殊召喚したモンスターの数×１０００ダメージを受ける。\nこの効果は相手ターンでも発動できる。\n②：このカードがフィールドから墓地へ送られた場合に発動できる。\nデッキから「契約書」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"DDD怒濤壊薙王カエサル・ラグナロク",h:"でぃーでぃーでぃーどとうかいちおうかえさる・らぐなろく",e:"D/D/D Wave Oblivion King Caesar Ragnarok",ra:"R",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"悪魔族",mo:["融合","効果"],ma:"",tp:"",at:3200,de:3000,tx:"「DDD」モンスター×２\n①：１ターンに１度、このカードが戦闘を行う攻撃宣言時に、このカード以外の自分フィールドの「DD」カードまたは「契約書」カード１枚を対象として発動できる。\nそのカードを持ち主の手札に戻し、このカードと戦闘を行うモンスター以外の相手フィールドの表側表示モンスター１体を選んで装備カード扱いとしてこのカードに装備する。\n②：このカードの攻撃力は、このカードの効果で装備したモンスターの元々の攻撃力分アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"DDD覇龍王ペンドラゴン",h:"でぃーでぃーでぃーはりゅうおうぺんどらごん",e:"D/D/D Dragon King Pendragon",ra:"R",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2600,de:2400,tx:"①：このカードが手札にある場合、自分メインフェイズに自分の手札・フィールドからドラゴン族モンスターと悪魔族モンスターを１体ずつリリースして発動できる。\nこのカードを手札から特殊召喚する。\n②：１ターンに１度、自分メインフェイズに手札を１枚捨てて発動できる。\nこのカードの攻撃力はターン終了時まで５００アップする。\nその後、フィールドの魔法・罠カード１枚を選んで破壊できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"DDD磐石王ダリウス",h:"でぃーでぃーでぃーばんじゃくおうだりうす",e:"D/D/D Stone King Darius",ra:"UR",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"悪魔族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1900,de:1000,tx:"悪魔族レベル３モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除き、自分フィールドの「契約書」カード１枚を対象として発動できる。\nそのカードを破壊し、自分はデッキから１枚ドローする。\nこの効果は相手ターンでも発動できる。\n②：このカードが相手モンスターと戦闘を行うダメージ計算時、このカードのX素材を１つ取り除いて発動できる。\nこのカードはその戦闘では破壊されず、ダメージ計算後にその相手モンスターを破壊し、相手に５００ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"DDD烈火王テムジン",h:"でぃーでぃーでぃーれっかおうてむじん",e:"D/D/D Flame King Genghis",ra:"UR",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"悪魔族",mo:["融合","効果"],ma:"",tp:"",at:2000,de:1500,tx:"「DD」モンスター×２\n「DDD烈火王テムジン」の①の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在し、自分フィールドにこのカード以外の「DD」モンスターが特殊召喚された場合、自分の墓地の「DD」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n②：このカードが戦闘または相手の効果で破壊された場合、自分の墓地の「契約書」カード１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"DDナイト・ハウリング",h:"でぃーでぃーないと・はうりんぐ",e:"D/D Nighthowl",ra:"R",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:300,de:600,tx:"①：このカードが召喚に成功した時、自分の墓地の「DD」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターの攻撃力・守備力は０になり、そのモンスターが破壊された場合に自分は１０００ダメージを受ける。\nこの効果の発動後、ターン終了時まで自分は悪魔族モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"DDバフォメット",h:"でぃーでぃーばふぉめっと",e:"D/D Berfomet",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1400,de:1800,tx:"①：１ターンに１度、「DDバフォメット」以外の自分フィールドの「DD」モンスター１体を対象とし、１～８までの任意のレベルを宣言して発動できる。\nそのモンスターはターン終了時まで宣言したレベルになる。\nこの効果の発動後、ターン終了時まで自分は「DD」モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"DDパンドラ",h:"でぃーでぃーぱんどら",e:"D/D Pandora",ra:"N",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1700,de:2100,tx:"①：このカードが戦闘または相手の効果で破壊され墓地へ送られた時、自分フィールドにカードが存在しない場合に発動できる。\n自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"DDプラウド・オーガ",h:"でぃーでぃーぷらうど・おーが",e:"D/D Proud Ogre",ra:"UR",t:"monster",a:"闇",l:6,ps:8,pe:"①：１ターンに１度、５００LPを払い、自分フィールドの「DD」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力は５００アップする。\n②：もう片方の自分のPゾーンに「DD」カードが存在しない場合、このカードのPスケールは５になる。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2300,de:1500,tx:"①：このカードが召喚に成功した時に発動できる。\n自分のエクストラデッキから、表側表示の闇属性Pモンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化され、この効果の発動後、ターン終了時まで自分は「DD」モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"DDプラウド・シュバリエ",h:"でぃーでぃーぷらうど・しゅばりえ",e:"D/D Proud Chevalier",ra:"SR",t:"monster",a:"闇",l:5,ps:6,pe:"①：１ターンに１度、５００LPを払い、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力は５００ダウンする。\n②：もう片方の自分のPゾーンに「DD」カードが存在しない場合、このカードのPスケールは５になる。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2000,de:700,tx:"①：このカードが召喚に成功した時に発動できる。\n自分のエクストラデッキから、表側表示の闇属性Pモンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"DD魔導賢者ガリレイ",h:"でぃーでぃーまどうけんじゃがりれい",e:"D/D Savant Galilei",ra:"SR",t:"monster",a:"闇",l:10,ps:1,pe:"①：自分は「DD」モンスターしかP召喚できない。\nこの効果は無効化されない。\n②：自分スタンバイフェイズに発動する。\nこのカードのPスケールを２つ上げる（最大１０まで）。\nその後、このカードのPスケール以下のレベルを持つ、「DD」モンスター以外の自分フィールドのモンスターを全て破壊する。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:0,de:0,tx:"「DD魔導賢者ガリレイ」のモンスター効果は１ターンに１度しか使用できない。\n①：このカードを手札から捨て、自分フィールドの、「DD」カードまたは「契約書」カード１枚を対象として発動できる。\nそのカードを持ち主の手札に戻す。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"DD魔導賢者ケプラー",h:"でぃーでぃーまどうけんじゃけぷらー",e:"D/D Savant Kepler",ra:"SR",t:"monster",a:"闇",l:1,ps:10,pe:"①：自分は「DD」モンスターしかP召喚できない。\nこの効果は無効化されない。\n②：自分スタンバイフェイズに発動する。\nこのカードのPスケールを２つ下げる（最小１まで）。\nその後、このカードのPスケールの数値以上のレベルを持つ、「DD」モンスター以外の自分フィールドのモンスターを全て破壊する。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名のモンスター効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合、以下の効果から１つを選択して発動できる。\n\n●このカード以外の自分フィールドの「DD」カード１枚を対象として発動できる。\nそのカードを持ち主の手札に戻す。\n\n●デッキから「契約書」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"DD魔導賢者ニコラ",h:"でぃーでぃーまどうけんじゃにこら",e:"D/D Savant Nikola",ra:"SR",t:"monster",a:"闇",l:6,ps:8,pe:"①：自分は「DD」モンスターしかP召喚できない。\nこの効果は無効化されない。\n②：１ターンに１度、手札から「DDD」モンスター１体を捨て、自分フィールドのレベル６以下の「DD」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力・守備力はターン終了時まで２０００アップする。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2000,de:2000,tx:"「DD魔導賢者ニコラ」のモンスター効果は１ターンに１度しか使用できない。\n①：このカードがPゾーンで破壊された場合、自分フィールドの「DDD」モンスター１体を対象として発動できる。\nそのモンスターを持ち主の手札に戻し、自分のエクストラデッキから表側表示の「DD」Pモンスターを２体まで選んで自分のPゾーンに置く。\nそのカードのP効果はこのターン発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"DD魔導賢者ニュートン",h:"でぃーでぃーまどうけんじゃにゅーとん",e:"D/D Savant Newton",ra:"SR",t:"monster",a:"闇",l:7,ps:10,pe:"①：自分は「DD」モンスターしかP召喚できない。\nこの効果は無効化されない。\n②：このカードがPゾーンに存在する限り１度だけ、自分にダメージを与える罠カードの効果が発動した場合、その効果を無効にできる。\nその後、このカードを破壊する。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:0,de:0,tx:"「DD魔導賢者ニュートン」のモンスター効果は１ターンに１度しか使用できない。\n①：このカードを手札から捨て、「DD魔導賢者ニュートン」以外の自分の墓地の、「DD」カードまたは「契約書」カード１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"DDリリス",h:"でぃーでぃーりりす",e:"D/D Lilith",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:100,de:2100,tx:"「DDリリス」の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合、以下の効果から１つを選択して発動できる。\n\n●自分の墓地の「DD」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\n\n●自分のエクストラデッキから、表側表示の「DD」Pモンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ディープ・スィーパー",h:"でぃーぷ・すぃーぱー",e:"Deep Sweeper",ra:"R",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1600,de:1300,tx:"このカードをリリースして発動できる。\nフィールド上の魔法・罠カード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ディープ・スペース・クルーザー・ナイン",h:"でぃーぷ・すぺーす・くるーざー・ないん",e:"Deep-Space Cruiser IX",ra:"R",t:"monster",a:"光",l:9,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:900,de:900,tx:"このカードは手札からこのカード以外の機械族モンスター１体を墓地へ送り、手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ディープ・ダイバー",h:"でぃーぷ・だいばー",e:"Deep Diver",ra:"R",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1000,de:1100,tx:"このカードが戦闘によって破壊され墓地へ送られたバトルフェイズ終了時、デッキからモンスター１体を選んでデッキの一番上に置く。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ディープアイズ・ホワイト・ドラゴン",h:"でぃーぷあいず・ほわいと・どらごん",e:"Deep-Eyes White Dragon",ra:"UR",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"①：自分フィールドの表側表示の「ブルーアイズ」モンスターが戦闘または相手の効果で破壊された時に発動できる。\nこのカードを手札から特殊召喚し、自分の墓地のドラゴン族モンスターの種類×６００ダメージを相手に与える。\n②：このカードが召喚・特殊召喚に成功した場合、自分の墓地のドラゴン族モンスター１体を対象として発動する。\nこのカードの攻撃力はそのモンスターの攻撃力と同じになる。\n③：フィールドのこのカードが効果で破壊された場合に発動する。\n相手フィールドのモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"Dボーイズ",h:"でぃーぼーいず",e:"D-Boyz",ra:"N",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["リバース","効果"],ma:"",tp:"",at:100,de:1000,tx:"リバース：デッキから「Dボーイズ」を任意の数だけ表側攻撃表示で特殊召喚できる。\nその後、自分はこの効果で特殊召喚したモンスターの数×１０００ポイントダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ティオの蟲惑魔",h:"てぃおのこわくま",e:"Traptrix Dionaea",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1700,de:1100,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時、自分の墓地の「蟲惑魔」モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\n②：このカードが特殊召喚に成功した時、自分の墓地の「ホール」通常罠カードまたは「落とし穴」通常罠カード１枚を対象として発動できる。\nそのカードを自分フィールドにセットする。\nセットしたカードは次の自分ターンのエンドフェイズに除外される。\n③：このカードは「ホール」通常罠カード及び「落とし穴」通常罠カードの効果を受けない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ディサイシブの影霊衣",h:"でぃさいしぶのねくろす",e:"Nekroz of Decisive Armor",ra:"R",t:"monster",a:"水",l:10,ps:"",pe:"",tr:"ドラゴン族",mo:["儀式","効果"],ma:"",tp:"",at:3300,de:2300,tx:"「影霊衣」儀式魔法カードにより降臨。\nレベル１０以外のモンスターのみを使用した儀式召喚でしか特殊召喚できない。\n「ディサイシブの影霊衣」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードを手札から捨て、自分フィールドの「影霊衣」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力・守備力はターン終了時まで１０００アップする。\nこの効果は相手ターンでも発動できる。\n②：相手フィールドにセットされたカード１枚を対象として発動できる。\nそのカードを破壊し除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ディザスター・デーモン",h:"でぃざすたー・でーもん",e:"Annihilator Archfiend",ra:"SR",t:"monster",a:"闇",l:9,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2000,de:3000,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：自分及び相手フィールドのカードを１枚ずつ対象として発動できる。\nそのカードを破壊する。\nこの効果で表側表示の悪魔族モンスターを破壊した場合、このカードの攻撃力はターン終了時まで、そのモンスターの元々の攻撃力を合計した数値の半分だけアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ディスク・マジシャン",h:"でぃすく・まじしゃん",e:"Disk Magician",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1350,de:1000,tx:"自らを円盤の中に封印し、攻撃をするとき実体を出す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"円盤闘士",h:"でぃすくふぁいたー",e:"Disc Fighter",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードが守備力２０００以上の守備表示モンスターを攻撃した場合、ダメージ計算を行わずそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ディスクライダー",h:"でぃすくらいだー",e:"Diskblade Rider",ra:"SR",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1700,de:1500,tx:"１ターンに１度、自分の墓地の通常罠カード１枚を選択して発動できる。\n選択したカードをゲームから除外し、このカードの攻撃力を相手のエンドフェイズ時まで５００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ディストラクター",h:"でぃすとらくたー",e:"Destructotron",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1600,de:400,tx:"１０００ライフポイントを払って発動できる。\n相手フィールド上にセットされた魔法・罠カード１枚を選択して破壊する。\nまた、お互いのエンドフェイズ時、自分フィールド上にこのカード以外のサイキック族モンスターが存在しない場合、このカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ディッグ・ビーク",h:"でぃっぐ・びーく",e:"Dig Beak",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:500,de:800,tx:"ヘビのように長い体をまるめ、回転しながらくちばしで攻撃。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ディノインフィニティ",h:"でぃのいんふぃにてぃ",e:"Tyranno Infinity",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:"?",de:0,tx:"①：このカードの元々の攻撃力は、除外されている自分の恐竜族モンスターの数×１０００になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ディノンの鋼鉄騎兵",h:"でぃのんのこうてつきへい",e:"Steel Cavalry of Dinon",ra:"N",t:"monster",a:"地",l:4,ps:5,pe:"",tr:"恐竜族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1600,de:2600,tx:"①：このカードがPモンスターと戦闘を行うダメージステップ開始時に発動する。\nこのカードの攻撃力・守備力はダメージステップ終了時まで半分になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"D・D・M",h:"でぃふぁれんと・でぃめんしょん・ますたー",e:"D.D.M. - Different Dimension Master",ra:"SR",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1700,de:1500,tx:"手札の魔法カードを１枚捨てる。\nゲームから除外された自分が持ち主のモンスター１体を特殊召喚する。\nこの効果は１ターンに１度だけ自分のメインフェイズに発動する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ディフェクト・コンパイラー",h:"でぃふぇくと・こんぱいらー",e:"Defect Compiler",ra:"N",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"①：１ターンに１度、相手の効果で自分がダメージを受ける場合、代わりにこのカードにディフェクトカウンターを１つ置く（最大１つまで）。\n②：１ターンに１度、このカードのディフェクトカウンターを１つ取り除き、自分フィールドのサイバース族モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで８００アップする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"D・キャメラン",h:"でぃふぉーまー・きゃめらん",e:"Morphtronic Cameran",ra:"SR",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:800,de:600,tx:"このカードはこのカードの表示形式によって以下の効果を得る。\n●攻撃表示：このカードが戦闘によって破壊された時、自分の手札・墓地から「D・キャメラン」以外のレベル４以下の「D」と名のついたモンスター１体を選んで特殊召喚できる。\n●守備表示：このカードがフィールド上に表側表示で存在する限り、お互いにフィールド上の「D」と名のついたモンスターをカードの効果の対象にできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"D・クリーナン",h:"でぃふぉーまー・くりーなん",e:"Morphtronic Vacuumen",ra:"R",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカードはこのカードの表示形式によって以下の効果を得る。\n●攻撃表示：１ターンに１度、このカードに装備された装備カード１枚を墓地へ送る事で、相手ライフに５００ポイントダメージを与える。\n●守備表示：１ターンに１度、相手フィールド上に表側攻撃表示で存在するモンスター１体を選択し、装備カード扱いとしてこのカードに１体のみ装備できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"D・クロックン",h:"でぃふぉーまー・くろっくん",e:"Morphtronic Clocken",ra:"R",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:600,de:1100,tx:"このカードはこのカードの表示形式によって以下の効果を得る。\n●攻撃表示：このカードに乗っているディフォーマーカウンター１つにつき、このカードの攻撃力は５００ポイントアップする。\n●守備表示：１ターンに１度、このカードにディフォーマーカウンターを１つ置く事ができる。\nこのカードをリリースする事で、このカードに乗っているディフォーマーカウンターの数×１０００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"D・スコープン",h:"でぃふぉーまー・すこーぷん",e:"Morphtronic Scopen",ra:"SR",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:1400,tx:"このカードはこのカードの表示形式によって以下の効果を得る。\n●攻撃表示：１ターンに１度、手札から「D」と名のついたレベル４モンスター１体を特殊召喚できる。\nこの効果で特殊召喚したモンスターはエンドフェイズ時に破壊される。\n●守備表示：このカードがフィールド上に表側表示で存在する限り、このカードのレベルは４になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"D・スマホン",h:"でぃふぉーまー・すまほん",e:"Morphtronic Smartfon",ra:"UR",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"機械族",mo:["特殊召喚","チューナー","効果"],ma:"",tp:"",at:100,de:100,tx:"このカードは通常召喚できない。\n自分の墓地の「D」モンスター１体を除外した場合に特殊召喚できる。\n①：このカードは表示形式によって以下の効果を得る。\n●攻撃表示：１ターンに１度、自分メインフェイズに発動できる。\nサイコロを１回振り、出た目の数だけデッキの上からカードをめくる。\nその中から「D」カード１枚を手札に加え、残りはデッキに戻してシャッフルする。\n●守備表示：１ターンに１度、自分メインフェイズに発動できる。\nサイコロを１回振り、出た目の数だけデッキの上からカードを確認し、デッキの上か下に好きな順番で戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"D・チャッカン",h:"でぃふぉーまー・ちゃっかん",e:"Morphtronic Datatron",ra:"N",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:1200,de:600,tx:"このカードはこのカードの表示形式によって以下の効果を得る。\n●攻撃表示：自分フィールド上に存在するモンスター１体をリリースする事で相手ライフに６００ポイントダメージを与える。\nこの効果は１ターンに１度しか使用できない。\n●守備表示：１ターンに１度、相手ライフに３００ポイントダメージを与える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"D・パッチン",h:"でぃふぉーまー・ぱっちん",e:"Morphtronic Slingen",ra:"R",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:800,tx:"このカードはこのカードの表示形式によって以下の効果を得る。\n●攻撃表示：１ターンに１度、「D・パッチン」以外の「D」と名のついたモンスター１体をリリースする事でフィールド上のカード１枚を破壊する。\n●守備表示：このカードが破壊される場合、代わりにこのカード以外の「D」と名のついたモンスター１体を破壊する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"D・ビデオン",h:"でぃふぉーまー・びでおん",e:"Morphtronic Videon",ra:"UR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードはこのカードの表示形式によって以下の効果を得る。\n●攻撃表示：このカードの攻撃力はこのカードに装備された装備カードの数×８００ポイントアップする。\n●守備表示：このカードの守備力はこのカードに装備された装備カードの数×８００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"D・ボードン",h:"でぃふぉーまー・ぼーどん",e:"Morphtronic Boarden",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:500,de:1800,tx:"このカードはこのカードの表示形式によって以下の効果を得る。\n●攻撃表示：このカードがフィールド上に表側表示で存在する限り、自分フィールド上の「D」と名のついたモンスターは相手プレイヤーに直接攻撃できる。\n●守備表示：このカードがフィールド上に表側表示で存在する限り、このカード以外の自分フィールド上の「D」と名のついたモンスターは戦闘では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"D・マグネンI",h:"でぃふぉーまー・まぐねんあい",e:"Morphtronic Magnen Bar",ra:"N",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"このカードはこのカードの表示形式によって以下の効果を得る。\n●攻撃表示：自分フィールド上にこのカード以外のモンスターが表側攻撃表示で２体存在する場合、１ターンに１度、このカードの攻撃力はこのターンのエンドフェイズ時までそのモンスターの攻撃力を合計した数値分アップする事ができる。\nこの効果を発動するターン、他のモンスターは攻撃できない。\n●守備表示：このカードがフィールド上に表側表示で存在する限り、自分フィールド上に存在するモンスターは攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"D・マグネンU",h:"でぃふぉーまー・まぐねんゆー",e:"Morphtronic Magnen",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"このカードはこのカードの表示形式によって以下の効果を得る。\n●攻撃表示：相手フィールド上に表側表示のモンスターが存在する場合、このカードは攻撃力が一番高いモンスターしか攻撃対象に選択できない。\n●守備表示：このカードがフィールド上に表側表示で存在する限り、相手は他のモンスターを攻撃対象に選択できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"D・モバホン",h:"でぃふぉーまー・もばほん",e:"Morphtronic Celfon",ra:"SR",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"このカードはこのカードの表示形式によって以下の効果を得る。\n●攻撃表示：サイコロを１回振り、出た目の数だけ自分のデッキの上からカードをめくる。\nその中からレベル４以下の「D」と名のついたモンスター１体を選び、召喚条件を無視して特殊召喚し、残りのカードはデッキに戻してシャッフルする。\nこの効果は１ターンに１度しか使用できない。\n●守備表示：サイコロを１回振り、出た目の数だけ自分のデッキの上からカードを確認して元の順番でデッキの上に戻す。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"D・ライトン",h:"でぃふぉーまー・らいとん",e:"Morphtronic Lantron",ra:"SR",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:200,de:200,tx:"このカードはこのカードの表示形式によって以下の効果を得る。\n●攻撃表示：このカードがフィールド上に表側表示で存在する限り、相手のカードの効果によって発生する自分への効果ダメージは代わりに相手が受ける。\n●守備表示：このカードが戦闘によって破壊され墓地へ送られた時、このターン戦闘によって発生する自分への戦闘ダメージは０になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"D・ラジオン",h:"でぃふぉーまー・らじおん",e:"Morphtronic Radion",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:1000,de:900,tx:"このカードはこのカードの表示形式によって以下の効果を得る。\n●攻撃表示：このカードがフィールド上に表側表示で存在する限り、自分フィールド上の「D」と名のついたモンスターの攻撃力は８００ポイントアップする。\n●守備表示：このカードがフィールド上に表側表示で存在する限り、自分フィールド上の「D」と名のついたモンスターの守備力は１０００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"D・ラジカッセン",h:"でぃふぉーまー・らじかっせん",e:"Morphtronic Boomboxen",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:400,tx:"このカードはこのカードの表示形式によって以下の効果を得る。\n●攻撃表示：このカードは１度のバトルフェイズ中に２回攻撃する事ができる。\n●守備表示：１ターンに１度、自分フィールド上の「D」と名のついたモンスターが攻撃対象に選択された時、その攻撃を無効にできる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"D・リモコン",h:"でぃふぉーまー・りもこん",e:"Morphtronic Remoten",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:300,de:1200,tx:"このカードはこのカードの表示形式によって以下の効果を得る。\n●攻撃表示：１ターンに１度、自分の墓地の「D」と名のついたモンスター１体を選択してゲームから除外し、デッキからそのモンスターと同じレベルを持つ「D」と名のついたモンスター１体を手札に加える。\n●守備表示：１ターンに１度、手札の「D」と名のついたモンスター１体を墓地へ送り、自分の墓地からそのモンスターと同じレベルを持つ他の「D」と名のついたモンスター１体を選んで手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デイブレーカー",h:"でいぶれーかー",e:"Daybreaker",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:0,tx:"このカードが特殊召喚に成功した時、手札から「デイブレーカー」１体を特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"次元合成師",h:"でぃめんしょん・けみすとりー",e:"Dimensional Alchemist",ra:"UR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1300,de:200,tx:"①：１ターンに１度、自分メインフェイズに発動できる。\n自分のデッキの一番上のカードを除外し、このカードの攻撃力をターン終了時まで５００アップする。\n②：自分フィールドのこのカードが破壊され墓地へ送られた時、除外されている自分のモンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ディメンション・ポッド",h:"でぃめんしょん・ぽっど",e:"Dimension Jar",ra:"N",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"機械族",mo:["リバース","効果"],ma:"",tp:"",at:200,de:200,tx:"リバース：お互いのプレイヤーは、相手の墓地のカードを３枚までゲームから除外する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ティンダングル・アポストル",h:"てぃんだんぐる・あぽすとる",e:"Tindangle Protector",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["リバース","効果"],ma:"",tp:"",at:800,de:1600,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードがリバースした場合に発動できる。\n自分フィールドの裏側表示モンスターを３体まで選んで表側守備表示にする。\nこの効果で表側守備表示にしたモンスターが全て「ティンダングル」モンスターだった場合、そのモンスターの数までデッキから「ティンダングル」カードを手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ティンダングル・イントルーダー",h:"てぃんだんぐる・いんとるーだー",e:"Tindangle Intruder",ra:"N",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["リバース","効果"],ma:"",tp:"",at:2200,de:0,tx:"このカード名の①②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがリバースした場合に発動できる。\nデッキから「ティンダングル」カード１枚を手札に加える。\n②：このカードが召喚に成功した時に発動できる。\nデッキから「ティンダングル」カード１枚を墓地へ送る。\n③：このカードが墓地に存在し、自分フィールドにモンスターが裏側守備表示で特殊召喚された場合に発動する。\nこのカードを墓地から裏側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"機怪神エクスクローラー",h:"でうすえくすくろーらー",e:"Deus X-Krawler",ra:"UR",t:"monster",a:"地",l:9,ps:"",pe:"",tr:"昆虫族",mo:["リバース","効果"],ma:"",tp:"",at:2000,de:3000,tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：裏側表示のこのモンスターを対象とする魔法・罠・モンスターの効果を相手が発動した時、このカードを表側守備表示にして発動できる。\nその発動を無効にし破壊する。\n②：リバースしたこのカードがモンスターゾーンに存在する限り、相手フィールドのモンスターが発動した効果は無効化される。\n③：フィールドのこのカードが戦闘・効果で破壊された場合に発動できる。\nこのカードとは元々の種族・属性が異なるレベル９モンスター１体をデッキから手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"テーヴァ",h:"てーう゛ぁ",e:"Teva",ra:"R",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2000,de:1500,tx:"①：このカードがアドバンス召喚に成功した場合に発動する。\n次の相手ターン、相手は攻撃宣言できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デーモン・イーター",h:"でーもん・いーたー",e:"Nefarious Archfiend Eater of Nefariousness",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1500,de:200,tx:"①：「デーモン・イーター」は自分フィールドに１体しか表側表示で存在できない。\n②：自分フィールドに魔法使い族モンスターが存在する場合、このカードは手札から特殊召喚できる。\n③：相手エンドフェイズにこのカードが墓地に存在する場合、自分フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターを破壊し、このカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デーモン・カオス・キング",h:"でーもん・かおす・きんぐ",e:"Chaos King Archfiend",ra:"UR",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"悪魔族",mo:["シンクロ","効果"],ma:"",tp:"",at:2600,de:2600,tx:"悪魔族チューナー＋チューナー以外のモンスター１体以上\nこのカードの攻撃宣言時、相手フィールド上の全てのモンスターの攻撃力・守備力をバトルフェイズ終了時まで入れ替える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デーモン・ソルジャー",h:"でーもん・そるじゃー",e:"Archfiend Soldier",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1900,de:1500,tx:"デーモンの中でも精鋭だけを集めた部隊に所属する戦闘のエキスパート。\n与えられた任務を確実にこなす事で有名。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デーモン・テイマー",h:"でーもん・ていまー",e:"Shadow Tamer",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["リバース","効果"],ma:"",tp:"",at:800,de:700,tx:"リバース：相手フィールド上に表側表示で存在する悪魔族モンスター１体のコントロールをエンドフェイズまで得る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デーモン・ビーバー",h:"でーもん・びーばー",e:"Archfiend Marmot of Nefariousness",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:400,de:600,tx:"悪魔のツノと翼を持つビーバー。\nどんぐりを投げつけて攻撃する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"悪魔の鏡",h:"でーもんず・みらー",e:"Archfiend Mirror",ra:"N",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:700,de:600,tx:"鏡に映るものに催眠術をかけ攻撃をよける悪魔の鏡。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デーモンの騎兵",h:"でーもんのきへい",e:"Archfiend Cavalry",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1900,de:0,tx:"①：フィールドのこのカードが効果で破壊され墓地へ送られた場合、「デーモンの騎兵」以外の自分の墓地の「デーモン」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターはこのターン攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デーモンの巨神",h:"でーもんのきょしん",e:"Archfiend Giant",ra:"SR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2400,de:1600,tx:"①：モンスターゾーンのこのカードが効果で破壊される場合、代わりに５００LPを払う事ができる。\nこの効果はこのカードがフィールドに表側表示で存在する限り１度しか使用できない。\n②：このカードが効果で破壊され墓地へ送られた場合に発動できる。\n手札から「デーモン」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デーモンの顕現",h:"でーもんのけんげん",e:"Archfiend's Manifestation",ra:"UR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["融合","効果"],ma:"",tp:"",at:2500,de:1200,tx:"「デーモンの召喚」＋闇属性モンスター\n①：このカードはモンスターゾーンに存在する限り、カード名を「デーモンの召喚」として扱う。\n②：このカードがモンスターゾーンに存在する限り、自分フィールドの「デーモンの召喚」の攻撃力は５００アップする。\n③：融合召喚したこのカードが相手によって墓地へ送られた場合に発動できる。\n自分の手札・デッキ・墓地から「デーモンの召喚」１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デーモンの召喚",h:"でーもんのしょうかん",e:"Summoned Skull",ra:"SR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:2500,de:1200,tx:"闇の力を使い、人の心を惑わすデーモン。\n悪魔族ではかなり強力な力を誇る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デーモンの将星",h:"でーもんのしょうせい",e:"Archfiend Commander",ra:"UR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2500,de:1200,tx:"このカード名の①の方法による特殊召喚は１ターンに１度しかできない。\n①：自分フィールドに「デーモン」カードが存在する場合、このカードは手札から特殊召喚できる。\nこのターン、このカードは攻撃できない。\n②：このカードの①の方法で特殊召喚に成功した場合、自分フィールドの「デーモン」カード１枚を対象として発動する。\nその自分の「デーモン」カードを破壊する。\n③：このカードがアドバンス召喚に成功した時、自分の墓地のレベル６の「デーモン」モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デーモンの招来",h:"でーもんのしょうらい",e:"Archfiend's Call",ra:"SR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:1200,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：このカードはモンスターゾーンに存在する限り、カード名を「デーモンの召喚」として扱う。\n②：このカードがモンスターゾーンに存在する限り、自分フィールドの「デーモンの召喚」を相手は効果の対象にできない。\n③：S召喚したこのカードが相手によって墓地へ送られた場合に発動できる。\n自分の手札・デッキ・墓地から「デーモンの召喚」１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デーモンの超越",h:"でーもんのちょうえつ",e:"Archfiend's Ascent",ra:"SR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2500,de:1200,tx:"レベル６モンスター×２\n①：このカードはモンスターゾーンに存在する限り、カード名を「デーモンの召喚」として扱う。\n②：自分フィールドの「デーモンの召喚」が戦闘・効果で破壊される場合、代わりにこのカードのX素材を１つ取り除く事ができる。\n③：X召喚したこのカードが相手によって墓地へ送られた場合に発動できる。\n自分の手札・デッキ・墓地から「デーモンの召喚」１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デグレネード・バスター",h:"でぐれねーど・ばすたー",e:"Degrade Buster",ra:"UR",t:"monster",a:"炎",l:7,ps:"",pe:"",tr:"サイバース族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2500,de:2500,tx:"このカードは通常召喚できない。\n自分の墓地のサイバース族モンスター２体を除外した場合に特殊召喚できる。\nこのカード名の効果は１ターンに１度しか使用できない。\n①：このカードの攻撃力より高い攻撃力を持つ相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターをエンドフェイズまで除外する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デコイドラゴン",h:"でこいどらごん",e:"Decoy Dragon",ra:"SR",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:300,de:200,tx:"①：このカードが攻撃対象に選択された場合、自分の墓地のレベル７以上のドラゴン族モンスター１体を対象として発動する。\nそのモンスターを特殊召喚し、攻撃対象をそのモンスターに移し替えてダメージ計算を行う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デコイロイド",h:"でこいろいど",e:"Decoyroid",ra:"N",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:300,de:500,tx:"このカードが自分フィールド上に表側表示で存在する限り、相手は「デコイロイド」以外の表側表示のモンスターを攻撃対象に選択できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"砂漠の守護者",h:"でざーと・がーどなー",e:"Desert Protector",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:800,de:1000,tx:"このカードの守備力は、フィールド上に存在する魔法・罠カードの枚数×３００ポイントアップする。\n自分フィールド上に存在する昆虫族モンスターが破壊される場合、代わりにこのカードを破壊する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デザート・ツイスター",h:"でざーと・ついすたー",e:"Desert Twister",ra:"UR",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"悪魔族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2300,de:2000,tx:"このカードは通常召喚できない。\n自分の墓地の風属性モンスター２体と地属性モンスター１体をゲームから除外する事でのみ特殊召喚する事ができる。\n手札を１枚捨てる事でフィールド上の魔法・罠カード１枚を破壊する。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"電子光虫－ウェブソルダー",h:"でじたる・ばぐ－うぇぶそるだー",e:"Digital Bug Websolder",ra:"R",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:500,de:1500,tx:"このカードをX召喚の素材とする場合、昆虫族モンスターのX召喚にしか使用できない。\n①：１ターンに１度、自分フィールドの表側攻撃表示モンスター１体を対象として発動できる。\nそのモンスターを守備表示にし、手札から昆虫族・レベル３モンスター１体を守備表示で特殊召喚する。\n②：フィールドのこのカードを素材としてX召喚したモンスターは以下の効果を得る。\n●このX召喚に成功した場合に発動する。\n相手フィールドの全ての表側表示モンスターは、守備力が０になり、守備表示になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"電子光虫－コアベージ",h:"でじたる・ばぐ－こあべーじ",e:"Digital Bug Corebage",ra:"R",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"昆虫族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2200,de:1800,tx:"昆虫族・光属性レベル５モンスター×２体以上\nこのカードは自分フィールドのランク３・４の昆虫族XモンスターからX素材を２つ取り除き、そのXモンスターの上に重ねてX召喚する事もできる。\n①：１ターンに１度、このカードのX素材を１つ取り除き、相手フィールドの守備表示モンスター１体を対象として発動できる。\nそのモンスターを持ち主のデッキに戻す。\n②：１ターンに１度、フィールドのモンスターの表示形式が変更された場合に発動できる。\n自分の墓地の昆虫族モンスター１体を選び、このカードの下に重ねてX素材とする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"電子光虫－コクーンデンサ",h:"でじたる・ばぐ－こくーんでんさ",e:"Digital Bug Cocoondenser",ra:"N",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:0,de:2000,tx:"このカードをX召喚の素材とする場合、昆虫族モンスターのX召喚にしか使用できない。\n①：１ターンに１度、このカードがフィールドに攻撃表示で存在する場合、自分の墓地の昆虫族・レベル３モンスター１体を対象として発動できる。\nこのカードを守備表示にし、対象のモンスターを守備表示で特殊召喚する。\n②：フィールドのこのカードを素材としてX召喚したモンスターは以下の効果を得る。\n●このカードが守備表示モンスターを攻撃する場合、相手はダメージステップ終了時まで魔法・罠・モンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"電子光虫－スカラジエータ",h:"でじたる・ばぐ－すからじえーた",e:"Digital Bug Scaradiator",ra:"R",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"昆虫族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1800,de:1400,tx:"昆虫族・光属性レベル３モンスター×２体以上\n①：１ターンに１度、このカードのX素材を２つ取り除き、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターの表示形式を変更し、その効果をターン終了時まで無効にする。\nこの効果は相手ターンでも発動できる。\n②：１ターンに１度、このカードが戦闘で相手モンスターを破壊し墓地へ送った時に発動できる。\n破壊したそのモンスターをこのカードの下に重ねてX素材とする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"電子光虫－センチビット",h:"でじたる・ばぐ－せんちびっと",e:"Digital Bug Centibit",ra:"N",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1500,de:500,tx:"このカードをX召喚の素材とする場合、昆虫族モンスターのX召喚にしか使用できない。\n①：１ターンに１度、表側攻撃表示のこのカードが守備表示になった時に発動できる。\nデッキから昆虫族・レベル３モンスター１体を守備表示で特殊召喚する。\n②：フィールドのこのカードを素材としてX召喚したモンスターは以下の効果を得る。\n●このカードは相手フィールドの全ての守備表示モンスターに１回ずつ攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"電子光虫－ライノセバス",h:"でじたる・ばぐ－らいのせばす",e:"Digital Bug Rhinosebus",ra:"R",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"昆虫族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2600,de:2200,tx:"昆虫族・光属性レベル７モンスター×２体以上\nこのカードは自分フィールドのランク５・６の昆虫族XモンスターからX素材を２つ取り除き、そのXモンスターの上に重ねてX召喚する事もできる。\n①：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。\n②：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\n相手フィールドの守備力が一番高いモンスターを破壊する。\nこの効果は相手ターンでも発動できる。",li:2,lk:[],ta:[],al:[],ocg:true},
  {n:"電子光虫－LEDバグ",h:"でじたる・ばぐ－れでぃばぐ",e:"Digital Bug LEDybug",ra:"N",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:500,de:0,tx:"このカードをX召喚の素材とする場合、昆虫族モンスターのX召喚にしか使用できない。\n①：１ターンに１度、表側攻撃表示のこのカードが守備表示になった時に発動できる。\nデッキから昆虫族・レベル３モンスター１体を手札に加える。\n②：フィールドのこのカードを素材としてX召喚したモンスターは以下の効果を得る。\n●このカードが戦闘でモンスターを破壊した時に自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デジトロン",h:"でじとろん",e:"Digitron",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"サイバース族",mo:["通常"],ma:"",tp:"",at:1500,de:0,tx:"電子空間で見つけた亜種。\nその情報量は心なしか少し多い。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デス・ヴォルストガルフ",h:"です・う゛ぉるすとがるふ",e:"Des Volstgalph",ra:"N",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2200,de:1700,tx:"①：このカードが戦闘で相手モンスターを破壊し墓地へ送った場合に発動する。\n相手に５００ダメージを与える。\n②：このカードがモンスターゾーンに存在する限り、自分または相手が通常・速攻魔法カードを発動する度に、ターン終了時までこのカードの攻撃力は２００ずつアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デス・ウォンバット",h:"です・うぉんばっと",e:"Des Wombat",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1600,de:300,tx:"このカードがフィールド上に表側表示で存在する限り、コントローラーへのカードの効果によるダメージを０にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デス・ウサギ",h:"です・うさぎ",e:"Creepy Coney",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["リバース","効果"],ma:"",tp:"",at:1000,de:1900,tx:"リバース：トークン以外の自分フィールド上に表側表示で存在する通常モンスター１体につき、相手ライフに１０００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デス・カンガルー",h:"です・かんがるー",e:"Des Kangaroo",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1500,de:1700,tx:"守備表示のこのカードを攻撃したモンスターの攻撃力がこのカードの守備力より低い場合、その攻撃モンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デス・コアラ",h:"です・こあら",e:"Des Koala",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"獣族",mo:["リバース","効果"],ma:"",tp:"",at:1100,de:1800,tx:"リバース：相手の手札１枚につき４００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"堕ち武者",h:"です・さむらい",e:"Samurai Skull",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1700,de:0,tx:"①：このカードが召喚に成功した時に発動できる。\nデッキからアンデット族モンスター１体を墓地へ送る。\n②：表側表示のこのカードが相手の効果でフィールドから離れた場合に発動できる。\nデッキから「堕ち武者」以外のレベル４以下のアンデット族モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デス・ストーカー",h:"です・すとーかー",e:"Skull Stalker",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:900,de:800,tx:"素早く動き、相手をはさみで捕らえ毒針を刺すサソリ戦士。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デス・ソーサラー",h:"です・そーさらー",e:"Sorcerer of the Doomed",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:1450,de:1200,tx:"死の魔法が得意な魔法使い。\n闇と契約している。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デス・デーモン・ドラゴン",h:"です・でーもん・どらごん",e:"Fiend Skull Dragon",ra:"R",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:2000,de:1200,tx:"「グランド・ドラゴン」＋「レッサー・デーモン」\nこのカードの融合召喚は上記のカードでしか行えない。\nこのカードがフィールド上に表側表示で存在する限り、リバース効果モンスターの効果は無効化される。\nまた、このカードがフィールド上に表側表示で存在する限り、このカードを対象にする罠カードの効果を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デス・デンドル",h:"です・でんどる",e:"Des Dendle",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"植物族",mo:["ユニオン","効果"],ma:"",tp:"",at:300,de:2000,tx:"１ターンに１度だけ自分のメインフェイズに装備カード扱いとして自分の「ブラッド・オーキス」に装備、または装備を解除して表側攻撃表示で特殊召喚する事ができる。\nこの効果で装備カード扱いになっている時のみ、装備モンスターが戦闘によってモンスターを破壊する度に「魔草トークン」（植物族・地・星１・攻／守８００）を１体特殊召喚する。\n（１体のモンスターが装備できるユニオンは１枚まで。\n装備モンスターが戦闘によって破壊される場合は、代わりにこのカードを破壊する。\n）",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デス・ドーナツ",h:"です・どーなつ",e:"Doom Donuts",ra:"N",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["リバース","効果"],ma:"",tp:"",at:0,de:0,tx:"リバース：フィールド上に表側表示で存在する元々の攻撃力または元々の守備力が０のモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デス・バード",h:"です・ばーど",e:"Skullbird",ra:"N",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"鳥獣族",mo:["融合"],ma:"",tp:"",at:1900,de:1700,tx:"タクヒ＋髑髏の寺院",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デス・フット",h:"です・ふっと",e:"The Drdek",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:700,de:800,tx:"目玉に足の生えた化け物。\n高くジャンプしてかぎづめで攻撃する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デス・モスキート",h:"です・もすきーと",e:"Des Mosquito",ra:"R",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:500,de:500,tx:"このカードが召喚・特殊召喚に成功した時、このカードにカウンターを２つ置く。\nこの効果によってカードに乗ったカウンター１つにつき、このカードの攻撃力は５００ポイントアップする。\nこのカードが戦闘によって破壊される場合、代わりにこのカードのカウンターを１つ取り除く。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デス・ラクーダ",h:"です・らくーだ",e:"Des Lacooda",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:500,de:600,tx:"①：自分メインフェイズに発動できる。\nこのカードを裏側守備表示にする（１ターンに１度のみ）。\n②：このカードが反転召喚に成功した場合に発動する。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デスカイザー・ドラゴン",h:"ですかいざー・どらごん",e:"Doomkaiser Dragon",ra:"R",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"アンデット族",mo:["シンクロ","効果"],ma:"",tp:"",at:2400,de:1500,tx:"「ゾンビキャリア」＋チューナー以外のアンデット族モンスター１体以上\n①：このカードが特殊召喚に成功した時、相手の墓地のアンデット族モンスター１体を対象として発動できる。\nそのアンデット族モンスターを自分フィールドに攻撃表示で特殊召喚する。\nこのカードがフィールドから離れた時にそのモンスターは破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デスカイザー・ドラゴン／バスター",h:"ですかいざー・どらごんすらっしゅばすたー",e:"Doomkaiser Dragon/Assault Mode",ra:"R",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"アンデット族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2900,de:2000,tx:"このカードは通常召喚できない。\n「バスター・モード」の効果でのみ特殊召喚できる。\nこのカードが特殊召喚に成功した時、自分・相手の墓地からアンデット族モンスターを任意の数だけ選択して自分フィールド上に特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化され、このターンのエンドフェイズ時に破壊される。\nまた、フィールド上のこのカードが破壊された時、自分の墓地の「デスカイザー・ドラゴン」１体を選択して特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デスガエル",h:"ですがえる",e:"Des Frog",ra:"N",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1900,de:0,tx:"このカードの生け贄召喚に成功した時、自分の墓地に存在する「悪魂邪苦止」の枚数分まで、「デスガエル」を手札またはデッキから特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デスグレムリン",h:"ですぐれむりん",e:"Des Feral Imp",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["リバース","効果"],ma:"",tp:"",at:1600,de:1800,tx:"①：このカードがリバースした場合、自分の墓地のカード１枚を対象として発動する。\nそのカードをデッキに戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デスサイクロン",h:"ですさいくろん",e:"Destruction Cyclone",ra:"N",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:2000,de:1500,tx:"このカードが戦闘によって破壊され墓地へ送られた時、フィールド上に表側表示で存在する風属性モンスター以外のモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デスサイズ・キラー",h:"ですさいず・きらー",e:"Dreadscythe Harvester",ra:"N",t:"monster",a:"風",l:8,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:2300,de:1600,tx:"①：このカード以外の自分フィールドの昆虫族モンスター１体をリリースして発動できる。\nこのカードの攻撃力はターン終了時まで５００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"D-HERO ダークエンジェル",h:"ですてにーひーろー　だーくえんじぇる",e:"Destiny HERO - Dark Angel",ra:"R",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"①：自分の墓地の「D-HERO」モンスターが３体以上の場合、このカードを手札から捨て、自分の墓地の「D-HERO」モンスター１体を対象として発動できる。\nそのモンスターを相手フィールドに守備表示で特殊召喚する。\n②：このカードがモンスターゾーンに存在する限り、自分が発動した魔法カードの効果は無効化され破壊される。\n③：自分スタンバイフェイズに自分の墓地からこのカードと「D-HERO」モンスター１体を除外して発動できる。\nお互いのプレイヤーは、それぞれデッキから通常魔法カード１枚を選んでデッキの一番上に置く。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"D-HERO ダイハードガイ",h:"ですてにーひーろー　だいはーどがい",e:"Destiny HERO - Captain Tenacious",ra:"R",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"このカードが自分フィールド上に表側表示で存在する時、このカードを除く自分フィールド上の「D-HERO」と名のついたモンスターが戦闘によって破壊され墓地へ送られた場合、そのモンスター１体を次の自分のスタンバイフェイズ時に自分フィールド上に特殊召喚する事ができる。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"D-HERO ダイヤモンドガイ",h:"ですてにーひーろー　だいやもんどがい",e:"Destiny HERO - Diamond Dude",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1400,de:1600,tx:"①：１ターンに１度、自分メインフェイズに発動できる。\n自分のデッキの一番上のカードをめくり、それが通常魔法カードだった場合、そのカードを墓地へ送る。\n違った場合、そのカードをデッキの一番下に戻す。\nこの効果で通常魔法カードを墓地へ送った場合、次の自分ターンのメインフェイズに墓地のその通常魔法カードの発動時の効果を発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"D-HERO ダガーガイ",h:"ですてにーひーろー　だがーがい",e:"Destiny HERO - Blade Master",ra:"SR",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:300,de:600,tx:"相手のバトルフェイズ時、手札からこのカードを捨てて発動できる。\n自分フィールド上の全ての「D-HERO」と名のついたモンスターの攻撃力は、エンドフェイズ時まで８００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"D-HERO ダッシュガイ",h:"ですてにーひーろー　だっしゅがい",e:"Destiny HERO - Dasher",ra:"SR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2100,de:1000,tx:"１ターンに１度、自分フィールド上のモンスター１体をリリースして発動できる。\nこのカードの攻撃力はエンドフェイズ時まで１０００ポイントアップする。\nこのカードは攻撃した場合、バトルフェイズ終了時に守備表示になる。\nまた、このカードが墓地に存在する限り１度だけ、自分のドローフェイズ時にカードをドローした時、そのカードがモンスターだった場合、その１体をお互いに確認して自分フィールド上に特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"D-HERO ダブルガイ",h:"ですてにーひーろー　だぶるがい",e:"Destiny HERO - Double Dude",ra:"SR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードは特殊召喚できない。\nこのカードは１度のバトルフェイズ中に２回攻撃する事ができる。\nこのカードが破壊され墓地へ送られた場合、次の自分のターンのスタンバイフェイズ時、自分フィールド上に「ダブルガイ・トークン」（戦士族・闇・星４・攻／守１０００）２体を特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"D-HERO ディアボリックガイ",h:"ですてにーひーろー　でぃあぼりっくがい",e:"Destiny HERO - Malicious",ra:"SR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"①：墓地のこのカードを除外して発動できる。\nデッキから「D-HERO ディアボリックガイ」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"D-HERO ディシジョンガイ",h:"ですてにーひーろー　でぃしじょんがい",e:"Destiny HERO - Decider",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"「D-HERO ディシジョンガイ」の①③の効果はそれぞれデュエル中に１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nこのターンのエンドフェイズに、自分の墓地の「HERO」モンスター１体を選んで手札に加える。\n②：レベル６以上の相手モンスターはこのカードを攻撃対象に選択できない。\n③：このカードが墓地に存在し、自分にダメージを与える魔法・罠・モンスターの効果が発動した時に発動する。\nこのカードを手札に戻し、その効果で自分が受けるダメージを０にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"D-HERO ディパーテッドガイ",h:"ですてにーひーろー　でぃぱーてっどがい",e:"Destiny HERO - Departed",ra:"R",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:0,tx:"自分のスタンバイフェイズ時にこのカードが墓地に存在する場合、相手フィールド上に表側攻撃表示で特殊召喚される。\nこのカードが戦闘で破壊された場合、墓地へは行かずゲームから除外される。\nこのカードが手札またはデッキからカードの効果によって墓地へ送られる場合、墓地へは行かずゲームから除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"D-HERO ディバインガイ",h:"ですてにーひーろー　でぃばいんがい",e:"Destiny HERO - Celestial",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1400,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードの攻撃宣言時に、相手フィールドの表側表示の魔法カード１枚を対象として発動できる。\nそのカードを破壊し、相手に５００ダメージを与える。\n②：自分の手札が０枚の場合、自分の墓地からこのカードと「D-HERO」モンスター１体を除外して発動できる。\n自分はデッキから２枚ドローする。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"D-HERO ディフェンドガイ",h:"ですてにーひーろー　でぃふぇんどがい",e:"Destiny HERO - Defender",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:100,de:2700,tx:"相手のスタンバイフェイズ時、このカードがフィールド上に表側守備表示で存在する場合、相手はデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"D-HERO デッドリーガイ",h:"ですてにーひーろー　でっどりーがい",e:"Destiny HERO - Dangerous",ra:"R",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2000,de:2600,tx:"「D-HERO」モンスター＋闇属性の効果モンスター\n「D-HERO デッドリーガイ」の効果は１ターンに１度しか使用できない。\n①：手札を１枚捨てて発動できる。\n手札・デッキから「D-HERO」モンスター１体を墓地へ送り、自分フィールドの全ての「D-HERO」モンスターの攻撃力はターン終了時まで、自分の墓地の「D-HERO」モンスターの数×２００アップする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"D-HERO デビルガイ",h:"ですてにーひーろー　でびるがい",e:"Destiny HERO - Doom Lord",ra:"R",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:600,de:800,tx:"このカードが自分フィールド上に表側攻撃表示で存在する場合、１ターンに１度だけ相手モンスター１体をゲームから除外する事ができる。\nこの効果を使用したプレイヤーはこのターン戦闘を行えない。\nこの効果によって除外したモンスターは、２回目の自分のスタンバイフェイズ時に同じ表示形式で相手フィールド上に戻る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"D-HERO ドゥームガイ",h:"ですてにーひーろー　どぅーむがい",e:"Destiny HERO - Fear Monger",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードが戦闘によって破壊され墓地へ送られた場合、次の自分のスタンバイフェイズ時に発動する。\n自分の墓地から「D-HERO ドゥームガイ」以外の「D-HERO」と名のついたモンスター１体を選択して特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"D-HERO ドグマガイ",h:"ですてにーひーろー　どぐまがい",e:"Destiny HERO - Dogma",ra:"SR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3400,de:2400,tx:"このカードは通常召喚できない。\n「D-HERO」モンスターを含む自分フィールドのモンスター３体をリリースした場合のみ特殊召喚できる。\n①：この方法でこのカードが特殊召喚に成功した場合、次の相手スタンバイフェイズに発動する。\n相手のLPを半分にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"D-HERO ドリームガイ",h:"ですてにーひーろー　どりーむがい",e:"Destiny HERO - Dreamer",ra:"R",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:0,de:600,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが墓地に存在し、自分の「D-HERO」モンスターが戦闘を行うダメージ計算時に発動できる。\nこのカードを墓地から特殊召喚し、その自分のモンスターはその戦闘では破壊されず、その戦闘で発生する自分への戦闘ダメージは０になる。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"D-HERO ドリルガイ",h:"ですてにーひーろー　どりるがい",e:"Destiny HERO - Drilldark",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"「D-HERO ドリルガイ」の①の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nこのカードの攻撃力以下の攻撃力を持つ「D-HERO」モンスター１体を手札から特殊召喚する。\n②：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"D-HERO ドレッドガイ",h:"ですてにーひーろー　どれっどがい",e:"Destiny HERO - Dreadmaster",ra:"SR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:"?",de:"?",tx:"「幽獄の時計塔」の効果で特殊召喚した場合、自分フィールド上の「D-HERO」と名のついたモンスター以外の自分のモンスターを全て破壊する。\nその後、自分の墓地から「D-HERO」と名のついたモンスターを２体まで特殊召喚する事ができる。\nこのカードが特殊召喚されたターン、自分フィールド上の「D-HERO」と名のついたモンスターは破壊されず、コントローラーへの戦闘ダメージは０になる。\nこのカードの攻撃力・守備力は、自分フィールド上のこのカードを除く「D-HERO」と名のついたモンスターの元々の攻撃力を合計した数値になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"D-HERO ドレッドサーヴァント",h:"ですてにーひーろー　どれっどさーう゛ぁんと",e:"Destiny HERO - Dread Servant",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:400,de:700,tx:"このカードが召喚に成功した時、「幽獄の時計塔」に時計カウンターを１つ置く。\nこのカードが戦闘によって破壊され墓地へ送られた時、自分フィールド上の魔法・罠カード１枚を破壊する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"D-HERO Bloo-D",h:"ですてにーひーろー　ぶるーでぃー",e:"Destiny HERO - Plasma",ra:"UR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1900,de:600,tx:"このカードは通常召喚できない。\n自分フィールドのモンスター３体をリリースした場合のみ特殊召喚できる。\n①：このカードがモンスターゾーンに存在する限り、相手フィールドの表側表示モンスターの効果は無効化される。\n②：１ターンに１度、相手フィールドのモンスター１体を対象として発動できる。\nその相手モンスターを装備カード扱いとしてこのカードに装備する（１体のみ装備可能）。\n③：このカードの攻撃力は、このカードの効果で装備したモンスターの元々の攻撃力の半分アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"鬼動武者",h:"ですとろい・さむらい",e:"Samurai Destroyer",ra:"R",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:2600,de:1400,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：このカードが相手モンスターと戦闘を行う場合、相手はダメージステップ終了時まで魔法・罠・モンスターの効果を発動できず、バトルフェイズの間だけその相手モンスターの効果は無効化される。\n②：表側表示のこのカードが相手の効果でフィールドから離れた場合、自分の墓地の機械族モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デスハムスター",h:"ですはむすたー",e:"Bubonic Vermin",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["リバース","効果"],ma:"",tp:"",at:900,de:600,tx:"①：このカードがリバースした場合に発動できる。\nデッキから「デスハムスター」１体を裏側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デスペラード・リボルバー・ドラゴン",h:"ですぺらーど・りぼるばー・どらごん",e:"Desperado Barrel Dragon",ra:"UR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2800,de:2200,tx:"①：自分フィールドの機械族・闇属性モンスターが戦闘・効果で破壊された場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：１ターンに１度、自分・相手のバトルフェイズに発動できる。\nコイントスを３回行う。\n表が出た数までフィールドの表側表示モンスターを選んで破壊する。\n３回とも表だった場合、さらに自分はデッキから１枚ドローする。\nこの効果を発動するターン、このカードは攻撃できない。\n③：このカードが墓地へ送られた場合に発動できる。\nコイントスを行う効果を持つレベル７以下のモンスター１体をデッキから手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デスマニア・デビル",h:"ですまにあ・でびる",e:"Desmanian Devil",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1700,de:1400,tx:"①：このカードが戦闘で相手モンスターを破壊した時に発動できる。\nデッキからレベル４以下の獣族モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デスルークデーモン",h:"でするーくでーもん",e:"Desrook Archfiend",ra:"N",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1100,de:1800,tx:"このカードのコントローラーは自分のスタンバイフェイズ毎に５００ライフポイントを払う。\nこのカードが相手のコントロールするカードの効果の対象になり、その処理を行う時にサイコロを１回振る。\n３が出た場合、その効果を無効にし破壊する。\n自分フィールド上の「ジェノサイドキングデーモン」が破壊され墓地に送られた時、このカードを手札から墓地に送る事で、その「ジェノサイドキングデーモン」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"TG カタパルト・ドラゴン",h:"てっくじーなす　かたぱると・どらごん",e:"T.G. Catapult Dragon",ra:"R",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:900,de:1300,tx:"１ターンに１度、手札からレベル３以下の「TG」と名のついたチューナー１体を特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"TG ギア・ゾンビ",h:"てっくじーなす　ぎあ・ぞんび",e:"T.G. Gear Zombie",ra:"UR",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"アンデット族",mo:["チューナー","効果"],ma:"",tp:"",at:600,de:0,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：自分フィールドの「TG」モンスター１体を対象として発動できる。\nこのカードを手札から特殊召喚する。\nその後、対象のモンスターの攻撃力を１０００ダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"TG サイバー・マジシャン",h:"てっくじーなす　さいばー・まじしゃん",e:"T.G. Cyber Magician",ra:"R",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"自分フィールド上に表側表示で存在するこのカードを「TG」と名のついたシンクロモンスターのシンクロ素材とする場合、手札の「TG」と名のついたモンスターを他のチューナー以外のシンクロ素材とする事ができる。\nフィールド上に存在するこのカードが破壊され墓地へ送られたターンのエンドフェイズ時、自分のデッキから「TG サイバー・マジシャン」以外の「TG」と名のついたモンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"TG ジェット・ファルコン",h:"てっくじーなす　じぇっと・ふぁるこん",e:"T.G. Jet Falcon",ra:"R",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["チューナー","効果"],ma:"",tp:"",at:1400,de:1200,tx:"このカードがシンクロ召喚の素材として墓地へ送られた場合、相手ライフに５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"TG スクリュー・サーペント",h:"てっくじーなす すくりゅー・さーぺんと",e:"T.G. Screw Serpent",ra:"UR",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"海竜族",mo:["チューナー","効果"],ma:"",tp:"",at:1300,de:500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合、「TG スクリュー・サーペント」以外の自分の墓地のレベル４以下の「TG」モンスター１体を対象として発動できる。\nそのモンスターを効果を無効にして特殊召喚する。\n②：墓地のこのカードを除外し、自分フィールドの「TG」モンスター１体を対象として発動できる。\nそのモンスターのレベルをターン終了時まで１つ上げる、または下げる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"TG スター・ガーディアン",h:"てっくじーなす すたー・がーでぃあん",e:"T.G. Star Guardian",ra:"SR",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","チューナー","効果"],ma:"",tp:"",at:100,de:2200,tx:"チューナー＋チューナー以外の「TG」モンスター１体以上\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合、自分の墓地の「TG」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\n②：自分メインフェイズに発動できる。\n手札から「TG」モンスター１体を特殊召喚する。\n③：相手メインフェイズに発動できる。\nこのカードを含む自分フィールドのモンスターをS素材としてS召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"TG ストライカー",h:"てっくじーなす　すとらいかー",e:"T.G. Striker",ra:"UR",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"戦士族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:0,tx:"①：相手フィールドにのみモンスターが存在する場合、このカードは手札から特殊召喚できる。\n②：フィールドのこのカードが破壊され墓地へ送られたターンのエンドフェイズに発動できる。\nデッキから「TG ストライカー」以外の「TG」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"TG タンク・ラーヴァ",h:"てっくじーなす たんく・らーう゛ぁ",e:"T.G. Tank Grub",ra:"SR",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"昆虫族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドのこのカードを「TG」SモンスターのS素材とする場合、このカードをチューナー以外のモンスターとして扱う事ができる。\n②：このカードが「TG」SモンスターのS素材として墓地へ送られた場合に発動できる。\n自分フィールドに「TGトークン」（機械族・地・星１・攻／守０）１体を攻撃表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"TG ドリル・フィッシュ",h:"てっくじーなす　どりる・ふぃっしゅ",e:"T.G. Drill Fish",ra:"UR",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:100,de:800,tx:"このカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドのモンスターが「TG」モンスターのみの場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードは直接攻撃できる。\n③：自分の「TG」モンスターが相手に戦闘ダメージを与えた時、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"TG ハルバード・キャノン",h:"てっくじーなす はるばーど・きゃのん",e:"T.G. Halberd Cannon",ra:"UR",t:"monster",a:"地",l:12,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:4000,de:4000,tx:"Sモンスターのチューナー＋チューナー以外のSモンスター２体以上\nこのカードはS召喚でしか特殊召喚できない。\n①：１ターンに１度、自分または相手がモンスターを召喚・反転召喚・特殊召喚する際に発動できる。\nこのカードがフィールドに表側表示で存在する場合、それを無効にし、そのモンスターを破壊する。\n②：このカードがフィールドから墓地へ送られた時、自分の墓地の「TG」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"TG ハルバード・キャノン／バスター",h:"てっくじーなす はるばーど・きゃのんすらっしゅばすたー",e:"T.G. Halberd Cannon/Assault Mode",ra:"R",t:"monster",a:"地",l:12,ps:"",pe:"",tr:"機械族",mo:["特殊召喚","効果"],ma:"",tp:"",at:4500,de:4500,tx:"このカードは通常召喚できない。\n「バスター・モード」の効果でのみ特殊召喚できる。\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手がモンスターを召喚・反転召喚・特殊召喚する際に発動できる。\nそれを無効にし、そのモンスター及び相手フィールドの特殊召喚されたモンスターを全て除外する。\n②：フィールドのこのカードが破壊された時、自分の墓地の「TG ハルバード・キャノン」１体を対象として発動できる。\nそのモンスターを召喚条件を無視して特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"TG パワー・グラディエイター",h:"てっくじーなす　ぱわー・ぐらでぃえいたー",e:"T.G. Power Gladiator",ra:"UR",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2300,de:1000,tx:"チューナー＋チューナー以外の「TG」と名のついたモンスター１体以上\nこのカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。\nフィールド上に存在するこのカードが破壊された時、自分のデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"TG ブースター・ラプトル",h:"てっくじーなす ぶーすたー・らぷとる",e:"T.G. Booster Raptor",ra:"SR",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:400,de:300,tx:"このカード名の①の方法による特殊召喚は１ターンに１度しかできない。\n①：自分フィールドに「TG」モンスターが存在する場合、このカードは手札から特殊召喚できる。\n②：フィールドのこのカードが破壊され墓地へ送られたターンのエンドフェイズに発動できる。\nデッキから「TG ブースター・ラプトル」以外の「TG」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"TG ブレード・ガンナー",h:"てっくじーなす　ぶれーど・がんなー",e:"T.G. Blade Blaster",ra:"UR",t:"monster",a:"地",l:10,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:3300,de:2200,tx:"シンクロモンスターのチューナー１体＋チューナー以外のシンクロモンスター１体以上\nこのカードを対象とする相手の魔法・罠カードが発動した時、手札を１枚墓地へ送る事で、その効果を無効にする。\nまた、相手ターンに１度、自分の墓地に存在する「TG」と名のついたモンスター１体をゲームから除外する事で、フィールド上に表側表示で存在するこのカードをゲームから除外する。\n次のスタンバイフェイズ時、この効果で除外したこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"TG ラッシュ・ライノ",h:"てっくじーなす　らっしゅ・らいの",e:"T.G. Rush Rhino",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1600,de:800,tx:"このカードが攻撃する場合、ダメージステップの間このカードの攻撃力は４００ポイントアップする。\nフィールド上に存在するこのカードが破壊され墓地へ送られたターンのエンドフェイズ時、自分のデッキから「TG ラッシュ・ライノ」以外の「TG」と名のついたモンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"TG レシプロ・ドラゴン・フライ",h:"てっくじーなす　れしぷろ・どらごん・ふらい",e:"T.G. Recipro Dragonfly",ra:"R",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"昆虫族",mo:["シンクロ","効果"],ma:"",tp:"",at:300,de:300,tx:"チューナー＋チューナー以外のモンスター１体\n①：１ターンに１度、このカード以外の自分フィールドの「TG」Sモンスター１体を対象として発動できる。\nそのモンスターを墓地へ送る。\nその後、墓地へ送ったそのモンスターのS召喚に使用したS素材モンスター一組が、全てSモンスターで自分の墓地に揃っていれば、その一組を自分フィールドに特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"TG ワーウルフ",h:"てっくじーなす わーうるふ",e:"T.G. Warwolf",ra:"SR",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:0,tx:"①：レベル４以下のモンスターが特殊召喚された時に発動できる。\nこのカードを手札から特殊召喚する。\n②：フィールドのこのカードが破壊され墓地へ送られたターンのエンドフェイズに発動できる。\nデッキから「TG ワーウルフ」以外の「TG」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"TG ワンダー・マジシャン",h:"てっくじーなす　わんだー・まじしゃん",e:"T.G. Wonder Magician",ra:"UR",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["シンクロ","チューナー","効果"],ma:"",tp:"",at:1900,de:0,tx:"チューナー＋チューナー以外の「TG」モンスター１体以上\n①：このカードがS召喚に成功した場合、フィールドの魔法・罠カード１枚を対象として発動する。\nそのカードを破壊する。\n②：相手メインフェイズに発動できる。\nこのカードを含む自分フィールドのモンスターを素材としてS召喚する。\n③：フィールドのこのカードが破壊された場合に発動する。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デッド・ガードナー",h:"でっど・がーどなー",e:"Ghost Gardna",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:0,de:1900,tx:"自分フィールド上に表側表示で存在するモンスターが攻撃対象に選択された時、このカードに攻撃対象を変更する事ができる。\nこのカードが破壊され墓地へ送られた時、相手フィールド上に表側表示で存在するモンスター１体の攻撃力はエンドフェイズ時まで１０００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デッド・シャーク",h:"でっど・しゃーく",e:"Corroding Shark",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["通常"],ma:"",tp:"",at:1100,de:700,tx:"海をさまようアンデットのサメ。\n出会った者に死の呪いをかける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"鉄の騎士",h:"てつのきし",e:"Iron Knight",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:700,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドに「鉄のハンス」が存在する場合、このカードの攻撃力は１０００ダウンする。\n②：フィールドのこのカードが効果で墓地へ送られた場合、または戦闘で破壊された場合に発動できる。\nデッキから「鉄のハンス」１体を手札に加える。\nフィールドゾーンに「シュトロームベルクの金の城」が存在する場合、代わりにデッキから戦士族モンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"鉄の騎士 ギア・フリード",h:"てつのきし　ぎあ・ふりーど",e:"Gearfried the Iron Knight",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1600,tx:"①：このカードに装備カードが装備された場合に発動する。\nその装備カードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"鉄のサソリ",h:"てつのさそり",e:"Steel Scorpion",ra:"N",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:250,de:300,tx:"機械族以外のモンスターがこのカードを攻撃した場合、そのモンスターは（相手ターンで数えて）３ターン目のエンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"鉄のハンス",h:"てつのはんす",e:"Iron Hans",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:800,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・反転召喚・特殊召喚に成功した場合に発動できる。\nデッキから「鉄の騎士」１体を特殊召喚する。\nこの効果の処理時にフィールドゾーンに「シュトロームベルクの金の城」が存在しない場合、ターン終了時まで自分はEXデッキからモンスターを特殊召喚できない。\n②：フィールドゾーンに「シュトロームベルクの金の城」が存在する場合、このカードの攻撃力は自分フィールドの「鉄の騎士」の数×１０００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"鉄腕ゴーレム",h:"てつわんごーれむ",e:"Steel Ogre Grotto #2",ra:"N",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1900,de:2200,tx:"鋼鉄でできた機械人形。\n恐るべき怪力を誇る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デビル・クラーケン",h:"でびる・くらーけん",e:"Fiend Kraken",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1200,de:1400,tx:"海に潜む巨大イカ。\n海中から突然あらわれ攻撃をする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"悪魔の調理師",h:"でびる・こっく",e:"The Bistro Butcher",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"このカードが相手ライフに戦闘ダメージを与えた時、相手はデッキからカードを２枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デビル・スネーク",h:"でびる・すねーく",e:"Serpent Marauder",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"爬虫類族",mo:["通常"],ma:"",tp:"",at:700,de:600,tx:"目が一つしかないヘビ。\n冷気をはき出し、相手を氷づけにする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デビル・ドラゴン",h:"でびる・どらごん",e:"Koumori Dragon",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:1500,de:1200,tx:"凶悪なドラゴン。\n邪悪な炎をはき、心を邪悪にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デビル・フランケン",h:"でびる・ふらんけん",e:"Cyber-Stein",ra:"R",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:700,de:500,tx:"①：５０００LPを払って発動できる。\nエクストラデッキから融合モンスター１体を攻撃表示で特殊召喚する。",li:0,lk:[],ta:[],al:[],ocg:true},
  {n:"デビル・ボックス",h:"でびる・ぼっくす",e:"Bickuribox",ra:"R",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"悪魔族",mo:["融合"],ma:"",tp:"",at:2300,de:2000,tx:"マーダーサーカス＋ドリーム・ピエロ",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔族召喚師",h:"でびるず・さもなー",e:"Doom Shaman",ra:"SR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["デュアル","効果"],ma:"",tp:"",at:2400,de:2000,tx:"このカードは墓地またはフィールド上に表側表示で存在する場合、通常モンスターとして扱う。\nフィールド上に表側表示で存在するこのカードを通常召喚扱いとして再度召喚する事で、このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●１ターンに１度、自分のメインフェイズ時に発動できる。\n手札または自分・相手の墓地から悪魔族モンスター１体を選んで特殊召喚する。\nこのカードがフィールド上から離れた時、この効果で特殊召喚した悪魔族モンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デビルズ・ミラー",h:"でびるず・みらー",e:"Fiend's Mirror",ra:"N",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["儀式"],ma:"",tp:"",at:2100,de:1800,tx:"「悪魔鏡の儀式」により降臨。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デビルスコーピオン",h:"でびるすこーぴおん",e:"Fiend Scorpion",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"昆虫族",mo:["通常"],ma:"",tp:"",at:900,de:200,tx:"悪魔の魂が宿る巨大なサソリ。\n普段は力を温存しているが、潜在能力は高い。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デビルゾア",h:"でびるぞあ",e:"Zoa",ra:"N",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:2600,de:1900,tx:"真の力をメタル化によって発揮すると言われているモンスター。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デビルツムリ",h:"でびるつむり",e:"Spiked Snail",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"昆虫族",mo:["通常"],ma:"",tp:"",at:700,de:1300,tx:"闇の力で進化したカタツムリ。\n手や足があり、速く動ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デビルドーザー",h:"でびるどーざー",e:"Doom Dozer",ra:"SR",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"昆虫族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2800,de:2600,tx:"このカードは通常召喚できない。\n自分の墓地の昆虫族モンスター２体をゲームから除外した場合のみ特殊召喚する事ができる。\nこのカードが相手ライフに戦闘ダメージを与えた時、相手のデッキの上からカードを１枚墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デフコンバード",h:"でふこんばーど",e:"Defcon Bird",ra:"R",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:100,de:1700,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：手札からこのカード以外のサイバース族モンスター１体を捨てて発動できる。\nこのカードを手札から特殊召喚する。\n②：１ターンに１度、自分のサイバース族モンスターが攻撃対象に選択された時に発動できる。\nそのモンスターの攻撃力・守備力はそのダメージステップ終了時まで元々の攻撃力の倍になる。\nそのモンスターが攻撃表示の場合、さらにその表示形式を守備表示にできる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デプス・シャーク",h:"でぷす・しゃーく",e:"Depth Shark",ra:"SR",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1400,de:0,tx:"自分フィールド上にモンスターが存在しない場合、このカードはリリースなしで召喚できる。\n相手のスタンバイフェイズ時に１度、このカードの攻撃力はエンドフェイズ時まで倍になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デブリ・ドラゴン",h:"でぶり・どらごん",e:"Debris Dragon",ra:"UR",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["チューナー","効果"],ma:"",tp:"",at:1000,de:2000,tx:"このカードをS素材とする場合、ドラゴン族モンスターのS召喚にしか使用できず、他のS素材モンスターは全てレベル４以外のモンスターでなければならない。\n①：このカードが召喚に成功した時、自分の墓地の攻撃力５００以下のモンスター１体を対象として発動できる。\nそのモンスターを攻撃表示で特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"手招きする墓場",h:"てまねきするはかば",e:"Graveyard and the Hand of Invitation",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["通常"],ma:"",tp:"",at:700,de:900,tx:"死者にさらなる力をあたえ、生ける者を死へとさそう墓場。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デモニック・モーター・Ω",h:"でもにっく・もーたー・おめが",e:"Fiendish Engine Ω",ra:"UR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2800,de:2000,tx:"①：１ターンに１度、自分メインフェイズに発動できる。\nこのカードの攻撃力はターン終了時まで１０００アップする。\n②：このカードの①の効果を発動した場合、エンドフェイズに発動する。\nこのカードを破壊する。\n③：自分エンドフェイズに発動する。\n自分フィールドに「モータートークン」（機械族・地・星１・攻／守２００）１体を攻撃表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デュアル・アセンブルム",h:"でゅある・あせんぶるむ",e:"Dual Assembwurm",ra:"R",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:2800,de:1000,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが手札・墓地に存在する場合、手札及び自分フィールドの表側表示モンスターの中から、サイバース族モンスター２体を除外して発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードの攻撃力は半分になる。\n②：１ターンに１度、手札を１枚除外して発動できる。\nこのカードの攻撃力以下の攻撃力を持つフィールドのモンスター１体を選んで除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デュアル・サモナー",h:"でゅある・さもなー",e:"Gemini Summoner",ra:"SR",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1500,de:0,tx:"相手のエンドフェイズ時に１度だけ５００ライフポイントを払って発動する事ができる。\n手札または自分フィールド上に表側表示で存在するデュアルモンスター１体を通常召喚する。\nまた、このカードは１ターンに１度だけ、戦闘では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デュアル・スコーピオン",h:"でゅある・すこーぴおん",e:"Gemini Scorpion",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:400,tx:"このカードが召喚・特殊召喚に成功した時、手札からレベル４以下のデュアルモンスター１体を特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デュアル・ソルジャー",h:"でゅある・そるじゃー",e:"Gemini Soldier",ra:"R",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"戦士族",mo:["デュアル","効果"],ma:"",tp:"",at:500,de:300,tx:"このカードは墓地またはフィールド上に表側表示で存在する場合、通常モンスターとして扱う。\nフィールド上に表側表示で存在するこのカードを通常召喚扱いとして再度召喚する事で、このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●このカードは１ターンに１度だけ、戦闘では破壊されない。\nこのカードが戦闘を行う場合、そのダメージ計算後に自分のデッキから「デュアル・ソルジャー」以外のレベル４以下のデュアルモンスター１体を自分フィールド上に特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デュアル・ランサー",h:"でゅある・らんさー",e:"Gemini Lancer",ra:"R",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"海竜族",mo:["デュアル","効果"],ma:"",tp:"",at:1800,de:1400,tx:"このカードは墓地またはフィールド上に表側表示で存在する場合、通常モンスターとして扱う。\nフィールド上に表側表示で存在するこのカードを通常召喚扱いとして再度召喚する事で、このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●このカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デューテリオン",h:"でゅーてりおん",e:"Duoterion",ra:"SR",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:2000,de:1400,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズにこのカードを手札から捨てて発動できる。\nデッキから「ボンディング」魔法・罠カード１枚を手札に加える。\n②：このカードが召喚・特殊召喚に成功した場合、自分の墓地の「ハイドロゲドン」「オキシゲドン」「デューテリオン」のいずれか１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デュナミス・ヴァルキリア",h:"でゅなみす・う゛ぁるきりあ",e:"Dunames Dark Witch",ra:"UR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:1800,de:1050,tx:"勇敢なる光の天使。\nその強い正義感ゆえ、負けるとわかっている悪との戦いでも決して逃げない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"煉獄の騎士 ヴァトライムス",h:"てらないと　う゛ぁとらいむす",e:"Darktellarknight Batlamyus",ra:"UR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2600,de:550,tx:"レベル４「テラナイト」モンスター×２\n①：このカードがモンスターゾーンに存在する限り、フィールドの全ての表側表示モンスターは闇属性になる。\n②：このカードのX素材を１つ取り除き、手札を１枚捨てて発動できる。\n光属性の「テラナイト」Xモンスター１体を、自分フィールドのこのカードの上に重ねてX召喚扱いとしてエクストラデッキから特殊召喚する。\nこの効果の発動後、ターン終了時まで自分はモンスターをX召喚できない。\n自分の墓地に「テラナイト」モンスターが７種類以上存在する場合、この効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デルタトライ",h:"でるたとらい",e:"Delta Tri",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:1000,tx:"このカードが戦闘によって相手モンスターを破壊した場合、次の効果から１つを選択して発動する。\n●自分の墓地に存在する装備可能なユニオンモンスター１体を選択してこのカードに装備する。\n●自分フィールド上に表側表示で存在する機械族・光属性モンスター１体を選択してデッキに戻し、自分のデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デルタフライ",h:"でるたふらい",e:"Delta Flyer",ra:"SR",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["チューナー","効果"],ma:"",tp:"",at:1500,de:900,tx:"①：１ターンに１度、このカード以外の自分フィールドの表側表示モンスター１体を対象として発動できる。\nその自分のモンスターのレベルを１つ上げる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"テレキアタッカー",h:"てれきあたっかー",e:"Telekinetic Shocker",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1700,de:700,tx:"サイキック族モンスターが破壊される場合、５００ライフポイントを払い代わりにこのカードを破壊する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"テレポンD.D.",h:"てれぽんでぃー．でぃー．",e:"D.D. Telepon",ra:"SR",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:500,de:1800,tx:"自分フィールド上に表側表示で存在するこのカードがゲームから除外された場合、自分のデッキから攻撃力１５００以下のサイキック族モンスター１体をゲームから除外する事ができる。\n次の自分のスタンバイフェイズ時、この効果で除外したモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"天界王 シナト",h:"てんかいおう　しなと",e:"Shinato, King of a Higher Plane",ra:"UR",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"天使族",mo:["儀式","効果"],ma:"",tp:"",at:3300,de:3000,tx:"「奇跡の方舟」により降臨。\nフィールドか手札から、レベルが８以上になるようカードを生け贄に捧げなければならない。\nこのカードが相手の守備表示モンスターを戦闘によって破壊し墓地へ送った時、破壊したモンスターの元々の攻撃力分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"天下人 紫炎",h:"てんかびと　しえん",e:"Tenkabito Shien",ra:"R",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"このカードは罠の効果を受けない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"でんきトカゲ",h:"でんきとかげ",e:"Electric Lizard",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:850,de:800,tx:"アンデット族以外のモンスターがこのカードを攻撃した場合、そのモンスターは次のターン攻撃宣言をする事ができない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"天穹覇龍ドラゴアセンション",h:"てんきゅうはりゅうどらごあせんしょん",e:"Ascension Sky Dragon",ra:"R",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:"?",de:3000,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカードがシンクロ召喚に成功した時、このカードの攻撃力は自分の手札の数×８００ポイントアップする。\nフィールド上のこのカードが相手によって破壊され墓地へ送られた時、このカードのシンクロ召喚に使用したシンクロ素材モンスター一組が自分の墓地に揃っていれば、その一組を特殊召喚できる。\nこの効果で特殊召喚したモンスターの効果は無効化される。\n「天穹覇龍ドラゴアセンション」のこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"天空の使者 ゼラディアス",h:"てんくうのししゃ　ぜらでぃあす",e:"Zeradias, Herald of Heaven",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2100,de:800,tx:"①：このカードを手札から墓地へ捨てて発動できる。\nデッキから「天空の聖域」１枚を手札に加える。\n②：フィールドに「天空の聖域」が存在しない場合にこのカードは破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"電磁石の戦士マグネット・ベルセリオン",h:"でんじしゃくのせんしまぐねっと・べるせりおん",e:"Berserkion the Electromagna Warrior",ra:"UR",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"岩石族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3000,de:2800,tx:"このカードは通常召喚できない。\n自分の手札・フィールド・墓地から、「電磁石の戦士α」「電磁石の戦士β」「電磁石の戦士γ」を１体ずつ除外した場合に特殊召喚できる。\n①：自分の墓地からレベル４以下の「マグネット・ウォリアー」モンスター１体を除外し、相手フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\n②：このカードが戦闘または相手の効果で破壊された場合、除外されている自分の「電磁石の戦士α」「電磁石の戦士β」「電磁石の戦士γ」を１体ずつ対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"天翔の竜騎士ガイア",h:"てんしょうのりゅうきしがいあ",e:"Sky Galloping Gaia the Dragon Champion",ra:"SR",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:2600,de:2100,tx:"「暗黒騎士ガイア」モンスター＋ドラゴン族モンスター\n①：このカードはモンスターゾーンに存在する限り、カード名を「竜騎士ガイア」として扱う。\n②：このカードが特殊召喚に成功した場合に発動できる。\n自分のデッキ・墓地から「螺旋槍殺」１枚を選んで手札に加える。\n③：このカードが相手モンスターに攻撃宣言した時に発動できる。\nその相手モンスターの表示形式を変更する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"転職の魔鏡",h:"てんしょくのまきょう",e:"Job-Change Mirror",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:800,de:1300,tx:"悪魔の鏡。\n攻撃を受けても割れずに、ダメージを防いでくれる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"転身テンシーン",h:"てんしんてんしーん",e:"Tenshin",ra:"R",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:800,de:100,tx:"このカードの攻撃力は、自分フィールド上に表側表示で存在するレベル２モンスターの数×４００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"転生竜サンサーラ",h:"てんせいりゅうさんさーら",e:"Samsara, Dragon of Rebirth",ra:"SR",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:100,de:2600,tx:"チューナー＋チューナー以外のモンスター１体以上\n「転生竜サンサーラ」の効果は１ターンに１度しか使用できない。\n①：フィールドのこのカードが相手の効果で墓地へ送られた場合、または戦闘で破壊され墓地へ送られた場合、「転生竜サンサーラ」以外の自分または相手の墓地のモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"伝説の騎士 クリティウス",h:"でんせつのきし　くりてぃうす",e:"Legendary Knight Critias",ra:"R",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2800,de:1800,tx:"このカードは通常召喚できない。\n「レジェンド・オブ・ハート」の効果でのみ特殊召喚できる。\n①：このカードが特殊召喚に成功した時、フィールドの表側表示の魔法・罠カード１枚を対象として発動できる。\nその表側表示の魔法・罠カードを除外する。\n②：このカードが攻撃対象に選択された時、自分の墓地の罠カード１枚を対象として発動できる。\nそのカードを自分フィールドにセットする。\nこの効果でセットしたカードはセットしたターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"伝説の騎士 ティマイオス",h:"でんせつのきし　てぃまいおす",e:"Legendary Knight Timaeus",ra:"R",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2800,de:1800,tx:"このカードは通常召喚できない。\n「レジェンド・オブ・ハート」の効果でのみ特殊召喚できる。\n①：このカードが特殊召喚に成功した時、フィールドの表側表示の魔法・罠カード１枚を対象として発動できる。\nその表側表示の魔法・罠カードを除外する。\n②：このカードが攻撃対象に選択された時、自分の墓地の魔法カード１枚を対象として発動できる。\nそのカードを自分フィールドにセットする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"伝説の騎士 ヘルモス",h:"でんせつのきし　へるもす",e:"Legendary Knight Hermos",ra:"R",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2800,de:1800,tx:"このカードは通常召喚できない。\n「レジェンド・オブ・ハート」の効果でのみ特殊召喚できる。\n①：このカードが特殊召喚に成功した時、フィールドの表側表示の魔法・罠カード１枚を対象として発動できる。\nその表側表示の魔法・罠カードを除外する。\n②：１ターンに１度、このカードが攻撃対象に選択された時、自分の墓地の効果モンスター１体を対象として発動できる。\nこのカードは次の自分ターンのエンドフェイズまで、その墓地のモンスターと同名カードとして扱い、同じ効果を得る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"伝説の剣豪 MASAKI",h:"でんせつのけんごう　まさき",e:"Masaki the Legendary Swordsman",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1100,de:1100,tx:"百人斬りを成しとげたといわれる、伝説の剣豪。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"伝説のフィッシャーマン",h:"でんせつのふぃっしゃーまん",e:"The Legendary Fisherman",ra:"UR",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1850,de:1600,tx:"①：フィールドに「海」が存在する限り、フィールドのこのカードは魔法カードの効果を受けない。\n②：フィールドに「海」が存在する限り、このカードは攻撃対象にされない（この効果が適用されたモンスターしか自分フィールドに存在しない状態での相手の攻撃は自分への直接攻撃になる）。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"伝説のフィッシャーマン二世",h:"でんせつのふぃっしゃーまんにせい",e:"The Legendary Fisherman II",ra:"UR",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2200,de:1800,tx:"①：このカードのカード名は、フィールド・墓地に存在する限り「伝説のフィッシャーマン」として扱う。\n②：フィールドに「海」が存在する限り、フィールドのこのカードは他のモンスターの効果を受けない。\n③：表側表示のこのカードが相手の効果でフィールドから離れた場合、または戦闘で破壊された場合に発動できる。\nデッキから水属性・レベル７モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"伝説の爆炎使い",h:"でんせつのふれいむ・ろーど",e:"Legendary Flame Lord",ra:"SR",t:"monster",a:"炎",l:7,ps:"",pe:"",tr:"魔法使い族",mo:["儀式","効果"],ma:"",tp:"",at:2400,de:2000,tx:"「灼熱の試練」により降臨。\nフィールドか手札から、レベルが７以上になるようカードを生け贄に捧げなければならない。\n自分または相手が魔法を発動する度に、このカードに魔力カウンターを１個乗せる。\nこのカードの魔力カウンターを３個取り除く事で、このカードを除くフィールド上のモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"伝説の預言者マーリン",h:"でんせつのよげんしゃまーりん",e:"Merlin",ra:"SR",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1400,de:500,tx:"「伝説の預言者マーリン」の①②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードをリリースして発動できる。\nデッキから「聖騎士」モンスター１体を特殊召喚する。\nこの効果を発動するターン、自分は「聖騎士」モンスターしか特殊召喚できない。\n②：墓地のこのカードを除外して発動できる。\n「聖騎士」Sモンスター１体をS召喚する。\nこの効果は相手ターンでも発動できる。\n③：墓地のこのカードを除外して発動できる。\n「聖騎士」Xモンスター１体をX召喚する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"テンタクル・プラント",h:"てんたくる・ぷらんと",e:"Tentacle Plant",ra:"N",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"植物族",mo:["通常"],ma:"",tp:"",at:500,de:600,tx:"近くに動く者がいると、青いつるを伸ばして攻撃してくる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"テンダネス",h:"てんだねす",e:"Tenderness",ra:"N",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:700,de:1400,tx:"恋人たちの永遠を祝福する、かわいらしい天使。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"電池メン－角型",h:"でんちめん－かくがた",e:"Batteryman 9-Volt",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"「電池メン－角型」の①の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・反転召喚・特殊召喚に成功した時に発動できる。\nデッキから「電池メン」モンスター１体を手札に加え、このカードの攻撃力・守備力を元々の倍にする。\n②：自分エンドフェイズに発動する。\nこのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"電池メン－業務用",h:"でんちめん－ぎょうむよう",e:"Batteryman Industrial Strength",ra:"UR",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"雷族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2600,de:0,tx:"このカードは通常召喚できない。\n自分の墓地の「電池メン」と名のついたモンスター２体をゲームから除外した場合のみ特殊召喚できる。\n１ターンに１度、自分の墓地の雷族モンスター１体をゲームから除外して発動できる。\nフィールド上のモンスター１体と魔法・罠カード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"電池メン－単一型",h:"でんちめん－たんいちがた",e:"Batteryman D",ra:"R",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:0,de:1900,tx:"このカードが自分フィールド上に表側表示で存在する限り、相手は自分フィールド上に存在する「電池メン－単一型」以外の雷族モンスターを攻撃対象に選択できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"電池メン－単三型",h:"でんちめん－たんさんがた",e:"Batteryman AA",ra:"R",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"自分フィールド上の「電池メン－単三型」が全て攻撃表示だった場合、「電池メン－単三型」１体につきこのカードの攻撃力は１０００ポイントアップする。\n自分フィールド上の「電池メン－単三型」が全て守備表示だった場合、「電池メン－単三型」１体につきこのカードの守備力は１０００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"電池メン－単二型",h:"でんちめん－たんにがた",e:"Batteryman C",ra:"N",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"自分フィールド上の「電池メン－単二型」が全て攻撃表示だった場合、「電池メン－単二型」１体につき自分フィールド上の全ての機械族モンスターの攻撃力は５００ポイントアップする。\n自分フィールド上の「電池メン－単二型」が全て守備表示だった場合、「電池メン－単二型」１体につき自分フィールド上の全ての機械族モンスターの守備力は５００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"電池メン－単四型",h:"でんちめん－たんよんがた",e:"Batteryman AAA",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカードが召喚・リバースした時、自分の手札・墓地から「電池メン－単四型」１体を選んで特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"電池メン－ボタン型",h:"でんちめん－ぼたんがた",e:"Batteryman Micro-Cell",ra:"SR",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"雷族",mo:["リバース","効果"],ma:"",tp:"",at:100,de:100,tx:"リバース：デッキから「電池メン－ボタン型」以外のレベル４以下の「電池メン」と名のついたモンスター１体を特殊召喚する。\nまた、リバースしたこのカードが戦闘によって破壊され墓地へ送られた時、デッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"天魔神 インヴィシル",h:"てんましん　いんう゛ぃしる",e:"Sky Scourge Invicil",ra:"R",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2200,de:1600,tx:"このカードは特殊召喚できない。\nこのカードのアドバンス召喚のためにリリースしたモンスターの種族と属性によって、このカードは以下の効果を得る。\n\n●天使族・光属性：このカードがフィールド上に表側表示で存在する限り、フィールド上の魔法カードの効果を無効にする。\n\n●悪魔族・闇属性：このカードがフィールド上に表側表示で存在する限り、フィールド上の罠カードの効果を無効にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"天魔神 エンライズ",h:"てんましん　えんらいず",e:"Sky Scourge Enrise",ra:"UR",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"天使族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2400,de:1500,tx:"このカードは通常召喚できない。\n自分の墓地の光属性・天使族モンスター３体と闇属性・悪魔族モンスター１体をゲームから除外した場合のみ特殊召喚する事ができる。\nフィールド上に表側表示で存在するモンスター１体をゲームから除外する事ができる。\nこの効果を発動する場合、このターンこのカードは攻撃する事ができない。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"天魔大帝",h:"てんまたいてい",e:"Tenmataitei",ra:"R",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2400,de:1000,tx:"①：このカードがモンスターゾーンに存在する限り、通常召喚されたモンスターの効果の発動に対して、相手は魔法・罠・モンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"天輪の葬送士",h:"てんりんのそうそうし",e:"Guiding Light",ra:"R",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"①：このカードが召喚に成功した時、自分の墓地の光属性・レベル１モンスター１体を対象として発動できる。\nその光属性モンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"天狼王 ブルー・セイリオス",h:"てんろうおう　ぶるー・せいりおす",e:"Celestial Wolf Lord, Blue Sirius",ra:"SR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"獣戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2400,de:1500,tx:"チューナー＋チューナー以外のモンスター１体以上\nフィールド上のこのカードが破壊され墓地へ送られた時、相手フィールド上に表側表示で存在するモンスター１体を選択して発動する。\n選択したモンスターの攻撃力は２４００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トイ・マジシャン",h:"とい・まじしゃん",e:"Toy Magician",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1600,de:1500,tx:"このカードは魔法カード扱いとして手札から魔法＆罠カードゾーンにセットする事ができる。\n自分の魔法＆罠カードゾーンにセットされたこのカードが相手のカードの効果によって破壊され墓地へ送られた場合、そのターンのエンドフェイズ時にこのカードを墓地から特殊召喚する。\nまた、このカードが反転召喚に成功した時、フィールド上に表側表示で存在する「トイ・マジシャン」の数だけ、フィールド上に存在する魔法・罠カードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドイツ",h:"どいつ",e:"Doitsu",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"天使族",mo:["ユニオン","効果"],ma:"",tp:"",at:100,de:200,tx:"１ターンに１度だけ自分のメインフェイズに装備カード扱いとして自分の「ソイツ」に装備、または装備を解除して表側攻撃表示で特殊召喚する事ができる。\nこの効果で装備カード扱いになっている時のみ、装備モンスターの攻撃力は２５００ポイントアップする。\n（１体のモンスターが装備できるユニオンは１枚まで。\n装備モンスターが戦闘によって破壊される場合は、代わりにこのカードを破壊する。\n）",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トイナイト",h:"といないと",e:"Toy Knight",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:200,de:1200,tx:"このカードはデッキから特殊召喚できない。\n相手フィールド上のモンスターの数が自分フィールド上のモンスターの数より多い場合、このカードは手札から特殊召喚できる。\nこのカードが召喚・特殊召喚に成功した時、手札から「トイナイト」１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トゥーン・アリゲーター",h:"とぅーん・ありげーたー",e:"Toon Alligator",ra:"R",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["通常"],ma:"",tp:"",at:800,de:1600,tx:"アメリカンコミックの世界から現れた、ワニのモンスター。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トゥーン・アンティーク・ギアゴーレム",h:"とぅーん・あんてぃーく・ぎあごーれむ",e:"Toon Ancient Gear Golem",ra:"SR",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"機械族",mo:["トゥーン","効果"],ma:"",tp:"",at:3000,de:3000,tx:"①：このカードは召喚・反転召喚・特殊召喚したターンには攻撃できない。\n②：自分フィールドに「トゥーン・ワールド」が存在し、相手フィールドにトゥーンモンスターが存在しない場合、このカードは直接攻撃できる。\n③：このカードが攻撃する場合、相手はダメージステップ終了時まで魔法・罠カードを発動できない。\n④：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トゥーン・仮面魔道士",h:"とぅーん・かめんまどうし",e:"Toon Masked Sorcerer",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["トゥーン","効果"],ma:"",tp:"",at:900,de:1400,tx:"このカードは召喚・反転召喚・特殊召喚したターンには攻撃する事ができない。\n自分フィールド上に「トゥーン・ワールド」が存在し、相手フィールド上にトゥーンモンスターが存在しない場合、このカードは相手プレイヤーに直接攻撃する事ができる。\nフィールド上の「トゥーン・ワールド」が破壊された時、このカードを破壊する。\nこのカードが相手ライフに戦闘ダメージを与えた時、自分のデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トゥーン・ゴブリン突撃部隊",h:"とぅーん・ごぶりんとつげきぶたい",e:"Toon Goblin Attack Force",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["トゥーン","効果"],ma:"",tp:"",at:2300,de:0,tx:"このカードは召喚・反転召喚・特殊召喚したターンには攻撃する事ができない。\n自分フィールド上に「トゥーン・ワールド」が存在し、相手フィールド上にトゥーンモンスターが存在しない場合、このカードは相手プレイヤーに直接攻撃する事ができる。\nフィールド上の「トゥーン・ワールド」が破壊された時、このカードを破壊する。\nこのカードは攻撃した場合、バトルフェイズ終了時に守備表示になり、次の自分のターンのエンドフェイズ時まで表示形式を変更する事ができない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トゥーン・サイバー・ドラゴン",h:"とぅーん・さいばー・どらごん",e:"Toon Cyber Dragon",ra:"UR",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"機械族",mo:["トゥーン","効果"],ma:"",tp:"",at:2100,de:1600,tx:"①：相手フィールドにモンスターが存在し、自分フィールドにモンスターが存在しない場合、このカードは手札から特殊召喚できる。\n②：このカードは召喚・反転召喚・特殊召喚したターンには攻撃できない。\n③：自分フィールドに「トゥーン・ワールド」が存在し、相手フィールドにトゥーンモンスターが存在しない場合、このカードは直接攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トゥーン・ヂェミナイ・エルフ",h:"とぅーん・ぢぇみない・えるふ",e:"Toon Gemini Elf",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["トゥーン","効果"],ma:"",tp:"",at:1900,de:900,tx:"①：このカードは召喚・反転召喚・特殊召喚したターンには攻撃できない。\n②：自分フィールドに「トゥーン・ワールド」が存在し、相手フィールドにトゥーンモンスターが存在しない場合、このカードは直接攻撃できる。\n③：このカードが相手に戦闘ダメージを与えた時に発動できる。\n相手の手札をランダムに１枚選んで捨てる。\n④：フィールドの「トゥーン・ワールド」が破壊された時にこのカードは破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トゥーン・デーモン",h:"とぅーん・でーもん",e:"Toon Summoned Skull",ra:"SR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["特殊召喚","トゥーン","効果"],ma:"",tp:"",at:2500,de:1200,tx:"このカードは通常召喚できない。\n自分フィールド上に「トゥーン・ワールド」が存在する場合のみ特殊召喚できる（レベル５以上はリリースが必要）。\nこのカードは特殊召喚したターンには攻撃できない。\nこのカードは５００ライフポイントを払わなければ攻撃宣言できない。\n相手フィールド上にトゥーンモンスターが存在しない場合、このカードは相手プレイヤーに直接攻撃する事ができる。\n存在する場合、トゥーンモンスターを攻撃対象に選択しなければならない。\nフィールド上の「トゥーン・ワールド」が破壊された時、このカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トゥーン・ドラゴン・エッガー",h:"とぅーん・どらごん・えっがー",e:"Manga Ryu-Ran",ra:"R",t:"monster",a:"炎",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","トゥーン","効果"],ma:"",tp:"",at:2200,de:2600,tx:"このカードは通常召喚できない。\n自分フィールドに「トゥーン・ワールド」が存在し、自分フィールドのモンスター２体をリリースした場合に特殊召喚できる。\n①：このカードは特殊召喚したターンには攻撃できない。\n②：このカードの攻撃宣言の際に、自分は５００LPを払わなければならない。\n③：相手フィールドにトゥーンモンスターが存在しない場合、このカードは直接攻撃できる。\n存在する場合、トゥーンモンスターを攻撃対象にしなければならない。\n④：フィールドの「トゥーン・ワールド」が破壊された時にこのカードは破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トゥーン・バスター・ブレイダー",h:"とぅーん・ばすたー・ぶれいだー",e:"Toon Buster Blader",ra:"R",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"戦士族",mo:["トゥーン","効果"],ma:"",tp:"",at:2600,de:2300,tx:"①：このカードの攻撃力は、相手のフィールド・墓地のドラゴン族モンスターの数×５００アップする。\n②：このカードは召喚・反転召喚・特殊召喚したターンには攻撃できない。\n③：自分フィールドに「トゥーン・ワールド」が存在し、相手フィールドにトゥーンモンスターが存在しない場合、このカードは直接攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トゥーン・ブラック・マジシャン",h:"とぅーん・ぶらっく・まじしゃん",e:"Toon Dark Magician",ra:"UR",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"魔法使い族",mo:["トゥーン","効果"],ma:"",tp:"",at:2500,de:2100,tx:"①：このカードは召喚・反転召喚・特殊召喚したターンには攻撃できない。\n②：自分フィールドに「トゥーン・ワールド」が存在し、相手フィールドにトゥーンモンスターが存在しない場合、このカードは直接攻撃できる。\n③：１ターンに１度、手札から「トゥーン」カード１枚を捨て、以下の効果から１つを選択して発動できる。\n\n●デッキから「トゥーン・ブラック・マジシャン」以外のトゥーンモンスター１体を召喚条件を無視して特殊召喚する。\n\n●デッキから「トゥーン」魔法・罠カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トゥーン・ブラック・マジシャン・ガール",h:"とぅーん・ぶらっく・まじしゃん・がーる",e:"Toon Dark Magician Girl",ra:"SR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["特殊召喚","トゥーン","効果"],ma:"",tp:"",at:2000,de:1700,tx:"このカードは通常召喚できない。\n自分フィールドに「トゥーン・ワールド」が存在し、自分フィールドのモンスター１体をリリースした場合に特殊召喚できる。\n①：このカードの攻撃力は、お互いの墓地の「ブラック・マジシャン」「マジシャン・オブ・ブラックカオス」の数×３００アップする。\n②：このカードは、相手フィールドにトゥーンモンスターが存在しない場合、直接攻撃できる。\n存在する場合、トゥーンモンスターしか攻撃対象に選択できない。\n③：フィールドの「トゥーン・ワールド」が破壊された時にこのカードは破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トゥーン・マーメイド",h:"とぅーん・まーめいど",e:"Toon Mermaid",ra:"SR",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["特殊召喚","トゥーン","効果"],ma:"",tp:"",at:1400,de:1500,tx:"このカードは通常召喚できない。\n自分フィールドに「トゥーン・ワールド」が存在する場合に特殊召喚できる。\n①：このカードは特殊召喚したターンには攻撃できない。\n②：このカードは５００LPを払わなければ攻撃宣言できない。\n③：相手フィールドにトゥーンモンスターが存在しない場合、このカードは直接攻撃できる。\n存在する場合、トゥーンモンスターを攻撃対象にしなければならない。\n④：フィールドの「トゥーン・ワールド」が破壊された時にこのカードは破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トゥーン・リボルバー・ドラゴン",h:"とぅーん・りぼるばー・どらごん",e:"Toon Barrel Dragon",ra:"SR",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"機械族",mo:["トゥーン","効果"],ma:"",tp:"",at:2600,de:2200,tx:"①：このカードは召喚・反転召喚・特殊召喚したターンには攻撃できない。\n②：自分フィールドに「トゥーン・ワールド」が存在し、相手フィールドにトゥーンモンスターが存在しない場合、このカードは直接攻撃できる。\n③：１ターンに１度、フィールドのカード１枚を対象として発動できる。\nコイントスを３回行い、その内２回以上が表だった場合、そのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"洞窟に潜む竜",h:"どうくつにひそむりゅう",e:"The Dragon Dwelling in the Cave",ra:"N",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:1300,de:2000,tx:"洞窟に潜む巨大なドラゴン。\n普段はおとなしいが、怒ると恐ろしい。\n財宝を守っていると伝えられている。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"投石部隊",h:"とうせきぶたい",e:"Throwstone Unit",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:900,de:2000,tx:"自分フィールド上の戦士族モンスター１体を生け贄に捧げる。\nこのカードの攻撃力以下の守備力を持つ表側表示のモンスター１体を破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"同族感電ウィルス",h:"どうぞくかんでんうぃるす",e:"Tribe-Shocking Virus",ra:"R",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"１ターンに１度、手札のモンスター１体をゲームから除外して発動する事ができる。\nこの効果を発動するためにゲームから除外したモンスターと同じ種族の、フィールド上に表側表示で存在するモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"凍氷帝メビウス",h:"とうひょうていめびうす",e:"Mobius the Mega Monarch",ra:"R",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:2800,de:1000,tx:"このカードはアドバンス召喚したモンスター１体をリリースしてアドバンス召喚できる。\nこのカードがアドバンス召喚に成功した時、フィールド上の魔法・罠カードを３枚まで選択して破壊できる。\nこのカードが水属性モンスターをリリースしてアドバンス召喚に成功した場合、その時の効果に以下の効果を加える。\n\n●この効果の発動に対して相手は選択されたカードを発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"東方の英雄",h:"とうほうのえいゆう",e:"Hero of the East",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1100,de:1000,tx:"遥か東の国から来たと言われているサムライ。\n手にするカタナは良く切れる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トークンコレクター",h:"とーくんこれくたー",e:"Token Collector",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:2000,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが手札・墓地に存在し、トークンが特殊召喚された場合に発動できる。\nこのカードを特殊召喚する。\n②：このカードが特殊召喚に成功した場合に発動する。\nフィールドのトークンを全て破壊し、このカードの攻撃力は破壊したトークンの数×４００アップする。\n③：このカードがモンスターゾーンに存在する限り、お互いにトークンを特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"怒気土器",h:"どきどき",e:"Doki Doki",ra:"R",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:500,de:500,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：手札から岩石族モンスター１体を捨てて発動できる。\nそのモンスターと元々の属性・レベルが同じ岩石族モンスター１体を、デッキから表側攻撃表示または裏側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"時の魔術師",h:"ときのまじゅつし",e:"Time Wizard",ra:"SR",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:500,de:400,tx:"①：１ターンに１度、自分メインフェイズに発動できる。\nコイントスを１回行い、裏表を当てる。\n当たった場合、相手フィールドのモンスターを全て破壊する。\nハズレの場合、自分フィールドのモンスターを全て破壊し、自分は表側表示で破壊されたモンスターの攻撃力を合計した数値の半分のダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"時の魔人 ネクロランサ",h:"ときのまじん　ねくろらんさ",e:"Necrolancer the Time-lord",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:800,de:900,tx:"好きな所へ行けるという時空リングから出てくる、一つ目の魔人。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"時の魔導士",h:"ときのまどうし",e:"Time Wizard of Tomorrow",ra:"SR",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["融合","効果"],ma:"",tp:"",at:2000,de:1900,tx:"「時の魔術師」＋効果モンスター\n①：１ターンに１度、このカードが融合召喚されている場合に発動できる。\nコイントスを１回行い、裏表を当てる。\n当たった場合、フィールドのモンスターを全て破壊し、相手は表側表示で破壊されたモンスターの元々の攻撃力を合計した数値の半分のダメージを受ける。\nハズレの場合、フィールドのモンスターを全て破壊し、自分は表側表示で破壊されたモンスターの元々の攻撃力を合計した数値の半分のダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"時花の魔女－フルール・ド・ソルシエール",h:"ときばなのまじょ－ふるーる・ど・そるしえーる",e:"Sorciere de Fleur",ra:"R",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2900,de:0,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合、相手の墓地のモンスター１体を対象として発動する。\nそのモンスターを自分フィールドに特殊召喚する。\nこの効果で特殊召喚したモンスターは相手に直接攻撃できず、このターンのエンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"時読みの魔術師",h:"ときよみのまじゅつし",e:"Timegazer Magician",ra:"UR",t:"monster",a:"闇",l:3,ps:8,pe:"自分フィールドにモンスターが存在しない場合にこのカードを発動できる。\n①：自分のPモンスターが戦闘を行う場合、相手はダメージステップ終了時まで罠カードを発動できない。\n②：もう片方の自分のPゾーンに「魔術師」カードまたは「オッドアイズ」カードが存在しない場合、このカードのPスケールは４になる。",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1200,de:600,tx:"①：このカードがモンスターゾーンに存在する限り、１ターンに１度、自分のPゾーンのカードは相手の効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"どぐう",h:"どぐう",e:"Dogu",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1900,de:0,tx:"「どぐう」の効果は１ターンに１度しか使用できない。\n①：このカードが相手の効果で墓地へ送られたターンのエンドフェイズに発動できる。\n自分のフィールド・墓地に存在しないレベルのモンスター１体をデッキから手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"毒蛇王ヴェノミノン",h:"どくじゃおうう゛ぇのみのん",e:"Vennominon the King of Poisonous Snakes",ra:"N",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカードはこのカード以外の効果モンスターの効果では特殊召喚できない。\nこのカードの攻撃力は、自分の墓地の爬虫類族モンスターの数×５００ポイントアップする。\nこのカードはフィールド上に表側表示で存在する限り、「ヴェノム・スワンプ」の効果を受けない。\nこのカードが戦闘によって破壊され墓地へ送られた時、このカード以外の自分の墓地の爬虫類族モンスター１体をゲームから除外する事で、このカードを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"毒蛇神ヴェノミナーガ",h:"どくじゃしんう゛ぇのみなーが",e:"Vennominaga the Deity of Poisonous Snakes",ra:"R",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"爬虫類族",mo:["特殊召喚","効果"],ma:"",tp:"",at:0,de:0,tx:"このカードは通常召喚できない。\n「蛇神降臨」の効果及びこのカードの効果でのみ特殊召喚できる。\nこのカードの攻撃力は、自分の墓地の爬虫類族モンスターの数×５００ポイントアップする。\nこのカードはフィールド上に表側表示で存在する限り、このカード以外のカードの効果の対象にならず、効果も受けない。\nこのカードが戦闘によって破壊され墓地へ送られた時、このカード以外の自分の墓地の爬虫類族モンスター１体をゲームから除外する事で、このカードを特殊召喚する。\nこのカードが相手ライフに戦闘ダメージを与えた時、このカードにハイパーヴェノムカウンターを１つ置く。\nこのカードにハイパーヴェノムカウンターが３つ乗った時、このカードのコントローラーはデュエルに勝利する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"Dr.フランゲ",h:"どくたーふらんげ",e:"Dr. Frankenderp",ra:"R",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:300,de:300,tx:"「Dr.フランゲ」の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合、５００LPを払って発動できる。\n自分はデッキの一番上のカードを確認する。\nその後、確認したカードをデッキの一番下に戻すか、相手に見せて手札に加える。\n手札に加えた場合、次の自分ドローフェイズをスキップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"毒の魔妖－土蜘蛛",h:"どくのまやかし－つちぐも",e:"Tsuchigumo, the Poisonous Mayakashi",ra:"N",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"アンデット族",mo:["シンクロ","効果"],ma:"",tp:"",at:2000,de:1800,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：「毒の魔妖－土蜘蛛」は自分フィールドに１体しか表側表示で存在できない。\n②：このカードが墓地に存在し、元々のレベルが７の自分のSモンスターが戦闘または相手の効果で破壊された場合に発動できる。\n自分の墓地から他のアンデット族モンスター１体を除外し、このカードを特殊召喚する。\n③：このカードが墓地からの特殊召喚に成功した場合に発動できる。\nお互いのデッキの上からカードを３枚墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"髑髏顔 天道虫",h:"どくろがん　れでぃばぐ",e:"Skull-Mark Ladybug",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:500,de:1500,tx:"このカードが墓地に送られた時、自分は１０００ライフポイント回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"髑髏の寺院",h:"どくろのじいん",e:"Temple of Skulls",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"アンデット族",mo:["通常"],ma:"",tp:"",at:900,de:1300,tx:"ドクロと骨ばかりの、気味の悪いお寺。\n近づく者を吸い込む。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"突撃ライノス",h:"とつげきらいのす",e:"Rampaging Rhynos",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:1500,tx:"１ターンに１度、使用していない隣のモンスターカードゾーンを指定して発動できる。\n自分フィールド上のこのカードをそのモンスターカードゾーンに移動する。\nこのカードの正面の相手モンスターを攻撃する場合、ダメージステップの間このカードの攻撃力は５００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トップ・ランナー",h:"とっぷ・らんなー",e:"Top Runner",ra:"N",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:1100,de:800,tx:"このカードがフィールド上に表側表示で存在する限り、自分フィールド上に表側表示で存在する全てのシンクロモンスターの攻撃力は６００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドッペル・ウォリアー",h:"どっぺる・うぉりあー",e:"Doppelwarrior",ra:"UR",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"①：自分の墓地からモンスターが特殊召喚された時に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードがS素材として墓地へ送られた場合に発動できる。\n自分フィールドに「ドッペル・トークン」（戦士族・闇・星１・攻／守４００）２体を攻撃表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドッペルゲンガー",h:"どっぺるげんがー",e:"Greenkappa",ra:"SR",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"戦士族",mo:["リバース","効果"],ma:"",tp:"",at:650,de:900,tx:"リバース：フィールド上にセットされた魔法・罠カード２枚を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドテドテング",h:"どてどてんぐ",e:"Dotedotengu",ra:"N",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:800,tx:"このカードが相手のカードの効果によって墓地へ送られた場合、相手フィールド上のカード１枚を選択して発動できる。\n選択したカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドドドウィッチ",h:"どどどうぃっち",e:"Dododo Witch",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:1600,tx:"①：このカードが召喚・特殊召喚に成功した時に発動できる。\n手札から「ドドドウィッチ」以外の「ドドド」モンスター１体を表側攻撃表示または裏側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドドドウォリアー",h:"どどどうぉりあー",e:"Dododo Warrior",ra:"N",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2300,de:900,tx:"このカードはリリースなしで召喚できる。\nこの方法で召喚したこのカードの元々の攻撃力は１８００になる。\nまた、このカードが攻撃する場合、ダメージステップ終了時まで相手の墓地で発動する効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドドドガッサー",h:"どどどがっさー",e:"Dododo Swordsman",ra:"R",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:0,de:3000,tx:"反転召喚したこのカードがフィールド上に表側表示で存在する限り、このカードの攻撃力は３５００ポイントアップする。\nまた、このカードがリバースした時、フィールド上に表側表示で存在するモンスターを２体まで選択して発動できる。\n選択したモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドドドドライバー",h:"どどどどらいばー",e:"Dododo Driver",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:200,tx:"このカードが「ドドド」と名のついたモンスターの効果によって特殊召喚されたターン、自分フィールド上の「ドドド」と名のついたモンスター１体を選択し、以下の効果から１つを選択して発動できる。\nこの効果は１ターンに２度まで使用できる。\n\n●選択したモンスターのレベルを１つ上げる。\n\n●選択したモンスターのレベルを１つ下げる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドドドドワーフ－GG",h:"どどどどわーふ－ごごごぐろーぶ",e:"Dodododwarf Gogogoglove",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:0,de:1800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\n手札から「ズババ」モンスターまたは「ガガガ」モンスター１体を特殊召喚する。\n②：このカードが墓地に存在し、自分フィールドに「ドドドドワーフ－GG」以外の、「ゴゴゴ」モンスターまたは「ドドド」モンスターが存在する場合に発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドドドバスター",h:"どどどばすたー",e:"Dododo Buster",ra:"R",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1900,de:800,tx:"①：相手フィールドにのみモンスターが存在する場合、このカードは手札から特殊召喚できる。\nこの方法で特殊召喚したこのカードのレベルは４になる。\n②：このカードがアドバンス召喚に成功した時、自分の墓地の「ドドド」モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドドドボット",h:"どどどぼっと",e:"Dododo Bot",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1800,de:1900,tx:"このカードを通常召喚する場合、裏側守備表示でセットしなければならない。\nこのカードが攻撃する場合、このカードはダメージステップ終了時まで、このカード以外のカードの効果を受けない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"轟きの大海蛇",h:"とどろきのおおうみへび",e:"Roaring Ocean Snake",ra:"N",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"水族",mo:["融合"],ma:"",tp:"",at:2100,de:1800,tx:"「魔法のランプ」＋「ひょうすべ」",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トビペンギン",h:"とびぺんぎん",e:"Flying Penguin",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1200,de:1000,tx:"耳のようにも見える頭についた羽で空を飛ぶ、珍しいペンギン。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トマボー",h:"とまぼー",e:"Inmato",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1400,de:800,tx:"このカード以外のフィールド上の植物族モンスター１体が相手の魔法・罠カードの効果の対象になった時、このカードをリリースして発動できる。\nデッキからカードを２枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トモザウルス",h:"ともざうるす",e:"Tomozaurus",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"恐竜族",mo:["通常"],ma:"",tp:"",at:500,de:400,tx:"小さいが性格は凶暴。\n仲間同士で争いだす。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トライクラー",h:"とらいくらー",e:"Tricular",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:300,de:300,tx:"このカードが戦闘によって破壊され墓地へ送られた時、自分の手札またはデッキから「ヴィークラー」１体を自分フィールド上に特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トライゴン",h:"とらいごん",e:"Trigon",ra:"N",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["ユニオン","効果"],ma:"",tp:"",at:500,de:1700,tx:"１ターンに１度、自分のメインフェイズ時に装備カード扱いとして自分フィールド上の機械族モンスターに装備、または装備を解除して表側攻撃表示で特殊召喚する事ができる。\nこの効果で装備カード扱いになっている場合のみ、装備モンスターが戦闘によって相手モンスターを破壊した場合、自分の墓地から機械族・光属性・レベル４以下のモンスター１体を選択して特殊召喚する。\n（１体のモンスターが装備できるユニオンは１枚まで。\n装備モンスターが破壊される場合、代わりにこのカードを破壊する。\n）",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トライデント・ウォリアー",h:"とらいでんと・うぉりあー",e:"Trident Warrior",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1200,tx:"①：このカードが召喚に成功した時に発動できる。\n手札からレベル３モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トライデント・ドラギオン",h:"とらいでんと・どらぎおん",e:"Trident Dragion",ra:"R",t:"monster",a:"炎",l:10,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:3000,de:2800,tx:"ドラゴン族チューナー＋チューナー以外のドラゴン族モンスター１体以上\nこのカードはシンクロ召喚でしか特殊召喚できない。\nこのカードがシンクロ召喚に成功した時、このカード以外の自分フィールド上のカードを２枚まで選択して破壊できる。\nこのターン、このカードは通常の攻撃に加えて、この効果で破壊したカードの数まで１度のバトルフェイズ中に攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トライホーン・ドラゴン",h:"とらいほーん・どらごん",e:"Tri-Horned Dragon",ra:"R",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:2850,de:2350,tx:"頭に生えている３本のツノが特徴的な悪魔竜。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トライポッド・フィッシュ",h:"とらいぽっど・ふぃっしゅ",e:"Tripod Fish",ra:"N",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:300,de:1300,tx:"このカードが墓地からの特殊召喚に成功した時、フィールド上の魚族・海竜族・水族モンスター１体を選択して発動できる。\n選択したモンスターのレベルを１つ上げる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"龍狸燈",h:"どらくーん・らんぷ",e:"Dracoon Lamp",ra:"SR",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"幻竜族",mo:["効果"],ma:"",tp:"",at:500,de:2000,tx:"①：１ターンに１度、手札から幻竜族モンスター１体を捨てて発動できる。\nこのカードの守備力はターン終了時まで１０００アップする。\nこの効果は相手ターンでも発動できる。\n②：攻撃表示のこのカードが攻撃表示モンスターと戦闘を行うダメージ計算時に１度、発動できる。\nその戦闘ではお互いのモンスターの守備力を攻撃力として扱いダメージ計算を行う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドラグニティ－アキュリス",h:"どらぐにてぃ－あきゅりす",e:"Dragunity Aklys",ra:"R",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"ドラゴン族",mo:["チューナー","効果"],ma:"",tp:"",at:1000,de:800,tx:"①：このカードが召喚に成功した時に発動できる。\n手札から「ドラグニティ」モンスター１体を特殊召喚し、その後、自分フィールドの表側表示のこのカードを装備カード扱いとしてその特殊召喚したモンスターに装備する。\n②：モンスターに装備されているこのカードが墓地へ送られた場合、フィールドのカード１枚を対象として発動する。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドラグニティ－アングス",h:"どらぐにてぃ－あんぐす",e:"Dragunity Angusticlavii",ra:"N",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2100,de:1000,tx:"このカードが「ドラグニティ」と名のついたドラゴン族モンスターを装備している場合、このカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドラグニティ－クーゼ",h:"どらぐにてぃ－くーぜ",e:"Dragunity Couse",ra:"UR",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"ドラゴン族",mo:["チューナー","効果"],ma:"",tp:"",at:1000,de:200,tx:"このカードをS素材とする場合、「ドラグニティ」モンスターのS召喚にしか使用できない。\n①：フィールドのこのカードをS素材とする場合、このカードのレベルを４として扱う事ができる。\n②：このカードが装備カード扱いとして装備されている場合に発動できる。\n装備されているこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドラグニティ－コルセスカ",h:"どらぐにてぃ－こるせすか",e:"Dragunity Corsesca",ra:"N",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"ドラゴン族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:700,tx:"①：このカードを装備カード扱いとして装備しているモンスターが戦闘で相手モンスターを破壊した時に発動できる。\n種族・属性が装備モンスターと同じとなるレベル４以下のモンスター１体をデッキから手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドラグニティ－ジャベリン",h:"どらぐにてぃ－じゃべりん",e:"Dragunity Javelin",ra:"N",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"ドラゴン族",mo:["チューナー","効果"],ma:"",tp:"",at:1200,de:800,tx:"このカードがモンスターカードゾーン上で破壊された場合、墓地へ送らずに装備カード扱いとして自分フィールド上の「ドラグニティ」と名のついた鳥獣族モンスター１体に装備できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドラグニティ－セナート",h:"どらぐにてぃ－せなーと",e:"Dragunity Senatus",ra:"SR",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1800,de:600,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：手札から「ドラグニティ」カード１枚を捨てて発動できる。\nデッキから「ドラグニティ」チューナー１体を選び、装備カード扱いとしてこのカードに装備する。\nこの効果の発動後、ターン終了時まで自分はドラゴン族モンスターしかEXデッキから特殊召喚できない。\n②：自分フィールドの「ドラグニティ」カードが戦闘・効果で破壊される場合、代わりにこのカードに装備された「ドラグニティ」カード１枚を破壊できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドラグニティ－ドゥクス",h:"どらぐにてぃ－どぅくす",e:"Dragunity Dux",ra:"SR",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"①：このカードが召喚に成功した時、自分の墓地のドラゴン族・レベル３以下の「ドラグニティ」モンスター１体を対象として発動できる。\nそのドラゴン族モンスターを装備カード扱いとしてこのカードに装備する。\n②：このカードの攻撃力は、自分フィールドの「ドラグニティ」カードの数×２００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドラグニティ－トリブル",h:"どらぐにてぃ－とりぶる",e:"Dragunity Tribus",ra:"R",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:500,de:300,tx:"このカードが召喚・特殊召喚に成功した時、デッキからレベル３以下のドラゴン族モンスター１体を墓地へ送る事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドラグニティ－パルチザン",h:"どらぐにてぃ－ぱるちざん",e:"Dragunity Partisan",ra:"R",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"ドラゴン族",mo:["チューナー","効果"],ma:"",tp:"",at:1200,de:800,tx:"このカードが召喚に成功した時に発動できる。\n手札から「ドラグニティ」と名のついた鳥獣族モンスター１体を特殊召喚し、さらにこのカードを装備カード扱いとして装備する。\nこのカードが装備カード扱いとして装備されている場合、装備モンスターをチューナーとして扱う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドラグニティ－ピルム",h:"どらぐにてぃ－ぴるむ",e:"Dragunity Pilum",ra:"N",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["チューナー","効果"],ma:"",tp:"",at:1400,de:1000,tx:"このカードが召喚に成功した時に発動できる。\n手札から「ドラグニティ」と名のついた鳥獣族モンスター１体を特殊召喚し、さらにこのカードを装備カード扱いとして装備する。\nこのカードが装備カード扱いとして装備されている場合、装備モンスターは相手プレイヤーに直接攻撃できる。\nこの時、装備モンスターが相手ライフに与える戦闘ダメージは半分になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドラグニティ－ファランクス",h:"どらぐにてぃ－ふぁらんくす",e:"Dragunity Phalanx",ra:"SR",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"ドラゴン族",mo:["チューナー","効果"],ma:"",tp:"",at:500,de:1100,tx:"①：１ターンに１度、このカードが装備カード扱いとして装備されている場合に発動できる。\n装備されているこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドラグニティ－ブラックスピア",h:"どらぐにてぃ－ぶらっくすぴあ",e:"Dragunity Darkspear",ra:"SR",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["チューナー","効果"],ma:"",tp:"",at:1000,de:1000,tx:"①：１ターンに１度、自分フィールドのドラゴン族の「ドラグニティ」モンスター１体をリリースし、自分の墓地のレベル４以下の鳥獣族モンスター１体を対象として発動できる。\nその鳥獣族モンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドラグニティ－ブランディストック",h:"どらぐにてぃ－ぶらんでぃすとっく",e:"Dragunity Brandistock",ra:"R",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"ドラゴン族",mo:["チューナー","効果"],ma:"",tp:"",at:600,de:400,tx:"①：このカードを装備カード扱いとして装備しているモンスターは１度のバトルフェイズ中に２回攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドラグニティ－プリムス・ピルス",h:"どらぐにてぃ－ぷりむす・ぴるす",e:"Dragunity Primus Pilus",ra:"N",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2200,de:1600,tx:"①：このカードが召喚・特殊召喚に成功した時、自分フィールドの鳥獣族の「ドラグニティ」モンスター１体を対象として発動できる。\nデッキからドラゴン族・レベル３以下の「ドラグニティ」モンスター１体を選び、装備カード扱いとして対象のモンスターに装備する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドラグニティ－ミリトゥム",h:"どらぐにてぃ－みりとぅむ",e:"Dragunity Militum",ra:"N",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1700,de:1200,tx:"１ターンに１度、自分の魔法＆罠カードゾーンの「ドラグニティ」と名のついたカード１枚を選択して発動できる。\n選択したカードを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドラグニティ－レギオン",h:"どらぐにてぃ－れぎおん",e:"Dragunity Legionnaire",ra:"R",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1200,de:800,tx:"①：このカードが召喚に成功した時、自分の墓地のドラゴン族・レベル３以下の「ドラグニティ」モンスター１体を対象として発動できる。\nそのドラゴン族モンスターを装備カード扱いとしてこのカードに装備する。\n②：自分の魔法＆罠ゾーンの表側表示の「ドラグニティ」カード１枚を墓地へ送り、相手フィールドの表側表示モンスター１体を対象として発動できる。\nその相手の表側表示モンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドラグニティアームズ－ミスティル",h:"どらぐにてぃあーむず－みすてぃる",e:"Dragunity Arma Mystletainn",ra:"R",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2100,de:1500,tx:"①：このカードは自分フィールドの表側表示の「ドラグニティ」モンスター１体を墓地へ送り、手札から特殊召喚できる。\n②：このカードが手札からの召喚・特殊召喚に成功した時、自分の墓地のドラゴン族の「ドラグニティ」モンスター１体を対象として発動できる。\nそのドラゴン族モンスターを装備カード扱いとしてこのカードに装備する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドラグニティアームズ－レヴァテイン",h:"どらぐにてぃあーむず－れう゛ぁていん",e:"Dragunity Arma Leyvaten",ra:"UR",t:"monster",a:"風",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2600,de:1200,tx:"①：このカードは「ドラグニティ」カードを装備した自分フィールドのモンスター１体を除外し、手札・墓地から特殊召喚できる。\n②：このカードが召喚・特殊召喚に成功した時、「ドラグニティアームズ－レヴァテイン」以外の自分の墓地のドラゴン族モンスター１体を対象として発動できる。\nそのドラゴン族モンスターを装備カード扱いとしてこのカードに装備する。\n③：このカードが相手の効果で墓地へ送られた時、このカードに装備されていた自分・相手の墓地のモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドラグニティナイト－アスカロン",h:"どらぐにてぃないと－あすかろん",e:"Dragunity Knight - Ascalon",ra:"UR",t:"monster",a:"風",l:10,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:3300,de:3200,tx:"「ドラグニティ」チューナー＋チューナー以外のモンスター１体以上\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：自分の墓地から「ドラグニティ」モンスター１体を除外し、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターを除外する。\n②：S召喚したこのカードが相手によって破壊された場合に発動できる。\nEXデッキから攻撃力３０００以下の「ドラグニティ」Sモンスター１体をS召喚扱いで特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドラグニティナイト－ヴァジュランダ",h:"どらぐにてぃないと－う゛ぁじゅらんだ",e:"Dragunity Knight - Vajrayana",ra:"UR",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:1900,de:1200,tx:"ドラゴン族チューナー＋チューナー以外の鳥獣族モンスター１体以上 \n①：このカードがS召喚に成功した時、自分の墓地のドラゴン族・レベル３以下の「ドラグニティ」モンスター１体を対象として発動できる。\nそのドラゴン族モンスターを装備カード扱いとしてこのカードに装備する。\n②：１ターンに１度、このカードに装備された自分フィールドの装備カード１枚を墓地へ送って発動できる。\nこのカードの攻撃力はターン終了時まで倍になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドラグニティナイト－ガジャルグ",h:"どらぐにてぃないと－がじゃるぐ",e:"Dragunity Knight - Gae Dearg",ra:"SR",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2400,de:800,tx:"ドラゴン族チューナー＋チューナー以外の鳥獣族モンスター１体以上 \n①：１ターンに１度、自分メインフェイズに発動できる。\nデッキからレベル４以下の、ドラゴン族または鳥獣族のモンスター１体を手札に加える。\nその後、手札からドラゴン族または鳥獣族のモンスター１体を選んで捨てる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドラグニティナイト－ゲイボルグ",h:"どらぐにてぃないと－げいぼるぐ",e:"Dragunity Knight - Gae Bulg",ra:"R",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2000,de:1100,tx:"ドラゴン族チューナー＋チューナー以外の鳥獣族モンスター１体以上\n①：このカードが戦闘を行うダメージステップ開始時からダメージ計算前までに１度、自分の墓地から鳥獣族モンスター１体を除外して発動できる。\nこのカードの攻撃力はターン終了時まで、除外したそのモンスターの元々の攻撃力分アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドラグニティナイト－トライデント",h:"どらぐにてぃないと－とらいでんと",e:"Dragunity Knight - Trident",ra:"R",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2400,de:1700,tx:"ドラゴン族チューナー＋チューナー以外の鳥獣族モンスター１体以上\n①：１ターンに１度、自分フィールドのカードを３枚まで墓地へ送って発動できる。\n相手のEXデッキを確認し、この効果を発動するために墓地へ送った数だけカードを選んで墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドラグニティナイト－ハールーン",h:"どらぐにてぃないと－はーるーん",e:"Dragunity Knight - Luin",ra:"SR",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","チューナー","効果"],ma:"",tp:"",at:1200,de:1900,tx:"「ドラグニティ」チューナー＋チューナー以外のモンスター１体以上\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合、自分の墓地の「ドラグニティ」モンスター１体を対象として発動できる。\nそのモンスターを装備カード扱いとしてこのカードに装備する。\n②：このカードが墓地へ送られた場合、自分フィールドの「ドラグニティ」モンスター１体を対象として発動できる。\nこのカードを攻撃力・守備力１０００アップの装備カード扱いとして、対象の自分のモンスターに装備する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドラグニティナイト－バルーチャ",h:"どらぐにてぃないと－ばるーちゃ",e:"Dragunity Knight - Barcha",ra:"R",t:"monster",a:"風",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2000,de:1200,tx:"ドラゴン族チューナー＋チューナー以外の鳥獣族モンスター１体以上\n①：このカードがS召喚に成功した時、自分の墓地のドラゴン族の「ドラグニティ」モンスターを任意の数だけ対象として発動できる。\nそのドラゴン族モンスターを装備カード扱いとしてこのカードに装備する。\n②：このカードの攻撃力は、このカードに装備された「ドラグニティ」カードの数×３００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トラゴエディア",h:"とらごえでぃあ",e:"Tragoedia",ra:"UR",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:"?",de:"?",tx:"①：自分が戦闘ダメージを受けた時に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードの攻撃力・守備力は自分の手札の数×６００アップする。\n③：１ターンに１度、手札からモンスター１体を墓地へ送り、そのモンスターと同じレベルの相手フィールドの表側表示モンスター１体を対象として発動できる。\nその表側表示モンスターのコントロールを得る。\n④：１ターンに１度、自分の墓地のモンスター１体を対象として発動できる。\nこのカードのレベルはターン終了時までそのモンスターと同じになる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トラコドン",h:"とらこどん",e:"Trakodon",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"恐竜族",mo:["通常"],ma:"",tp:"",at:1300,de:800,tx:"トラ柄の恐竜。\n荒野を駆け抜けるスピードはかなり速い。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドラコニアの海竜騎兵",h:"どらこにあのかいりゅうきへい",e:"Sea Dragoons of Draconia",ra:"R",t:"monster",a:"水",l:3,ps:7,pe:"「ドラコニアの海竜騎兵」のP効果は１ターンに１度しか使用できない。\n①：自分または相手のモンスターが戦闘で破壊された時に発動できる。\n手札から通常モンスター１体を特殊召喚する。",tr:"海竜族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:200,de:2100,tx:"龍人族の国、ドラコニア帝国が有する竜騎士団の海兵部隊。\n深海から音も無く忍び寄る隠密作戦に長けている。\n対岸のディノン公国兵とは、領海を巡り小競り合いが続いている状態である。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドラコニアの獣竜騎兵",h:"どらこにあのじゅうりゅうきへい",e:"Dragoons of Draconia",ra:"R",t:"monster",a:"炎",l:4,ps:2,pe:"①：１ターンに１度、自分の通常モンスターが戦闘で相手モンスターを破壊したダメージ計算後に発動できる。\nデッキからレベル４以上の通常モンスター１体を手札に加える。",tr:"獣戦士族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:1800,de:200,tx:"龍人族の国、ドラコニア帝国が有する竜騎士団の陸兵部隊。\n鳥銃と鉄槍によるコンビネーション攻撃には隙が無く、レプティア皇国などの周辺国から恐れられている。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドラコニアの翼竜騎兵",h:"どらこにあのよくりゅうきへい",e:"Sky Dragoons of Draconia",ra:"SR",t:"monster",a:"風",l:5,ps:7,pe:"①：１ターンに１度、自分の通常モンスターが相手に戦闘ダメージを与えた時、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",tr:"鳥獣族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:2200,de:200,tx:"龍人族の国、ドラコニア帝国が有する竜騎士団の空兵部隊。\n中立国である空中都市国家シュルブへ侵攻するために結成されたとの噂があり、周辺国は警戒を強めている。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドラゴニック・ガード",h:"どらごにっく・がーど",e:"Dragonic Guard",ra:"R",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1500,de:1800,tx:"モンスターが通常召喚される度に、このカードにドラゴニックカウンターを１つ置く。\nまた、フィールド上に表側表示で存在するこのカードを墓地へ送る事で、このカードに乗っているドラゴニックカウンターの数以下のレベルを持つドラゴン族モンスター１体を自分のデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドラゴヒューマン",h:"どらごひゅーまん",e:"D. Human",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1300,de:1100,tx:"ドラゴンのキバで作った剣を振るう、ドラゴンの力を持つ戦士。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドラゴラド",h:"どらごらど",e:"Dragard",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1300,de:1900,tx:"①：このカードが召喚に成功した時、自分の墓地の攻撃力１０００以下の通常モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\n②：１ターンに１度、自分フィールドのドラゴン族モンスター１体をリリースし、自分フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターはターン終了時まで、レベルが８になり、攻撃力が８００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドラゴン・アイス",h:"どらごん・あいす",e:"Dragon Ice",ra:"SR",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1800,de:2200,tx:"相手がモンスターの特殊召喚に成功した時、自分の手札を１枚捨てる事で、このカードを手札または墓地から特殊召喚する。\n「ドラゴン・アイス」はフィールド上に１枚しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドラゴン・ウィッチ－ドラゴンの守護者－",h:"どらごん・うぃっち－どらごんのしゅごしゃ－",e:"Lady of D.",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1500,de:1100,tx:"このカードがフィールド上に表側表示で存在する限り、自分フィールド上のドラゴン族モンスターを相手は攻撃対象に選択できない。\nフィールド上のこのカードが戦闘またはカードの効果によって破壊される場合、代わりに手札からドラゴン族モンスター１体を墓地へ送る事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドラゴン・ウォリアー",h:"どらごん・うぉりあー",e:"Ryu Senshi",ra:"R",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2000,de:1200,tx:"「戦士ダイ・グレファー」＋「スピリット・ドラゴン」\nこのカードの融合召喚は上記のカードでしか行えない。\nこのカードがフィールド上に表側表示で存在する限り、通常罠カードが発動した時、１０００ライフポイントを払う事でその効果を無効にする。\nまた、このカードがフィールド上に表側表示で存在する限り、このカードを対象にする魔法カードの効果を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドラゴン・エッガー",h:"どらごん・えっがー",e:"Ryu-Ran",ra:"N",t:"monster",a:"炎",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:2200,de:2600,tx:"卵のカラをかぶっているドラゴン。\n子供と間違えると痛い目にあうぞ！",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"竜殺者",h:"どらごん・きらー",e:"Dragon Seeker",ra:"R",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2000,de:2100,tx:"このカードがフィールド上に召喚・反転召喚された時、表側表示のドラゴン族モンスター１体を破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"竜魂の石像",h:"どらごん・そうる・すたちゅー",e:"Dragon Statue",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1100,de:900,tx:"ドラゴンの魂を持つ石像の戦士。\n自慢の剣で、敵を切り裂く。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドラゴン・ゾンビ",h:"どらごん・ぞんび",e:"Dragon Zombie",ra:"R",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["通常"],ma:"",tp:"",at:1600,de:0,tx:"魔力により蘇ったドラゴン。\nはく息は触れるものを腐食させる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"竜の騎士",h:"どらごん・ないと",e:"Dragonic Knight",ra:"R",t:"monster",a:"炎",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2800,de:2300,tx:"自分フィールド上のカードを破壊する効果を相手モンスターが発動した時、対象となった自分のカードを全て墓地へ送って発動できる。\nこのカードを手札から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドラゴン・ライダー",h:"どらごん・らいだー",e:"Dragon Manipulator",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["リバース","効果"],ma:"",tp:"",at:700,de:800,tx:"リバース：相手フィールド上に表側表示で存在するドラゴン族モンスター１体のコントロールをエンドフェイズまで得る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドラゴンダウザー",h:"どらごんだうざー",e:"Dragon Dowser",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"幻竜族",mo:["効果"],ma:"",tp:"",at:1500,de:1500,tx:"「ドラゴンダウザー」の効果は１ターンに１度しか使用できない。\n①：フィールドのこのカードが戦闘または相手の効果で破壊され墓地へ送られた時に発動できる。\nデッキから地属性Pモンスター１体を守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターはエンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドラゴンに乗るワイバーン",h:"どらごんにのるわいばーん",e:"Alligator's Sword Dragon",ra:"R",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:1700,de:1500,tx:"「ベビードラゴン」＋「ワイバーンの戦士」\n①：相手フィールドの表側表示モンスターが地・水・炎属性モンスターのみの場合、このカードは直接攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドラゴンフライ",h:"どらごんふらい",e:"Flying Kamakiri #1",ra:"R",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1400,de:900,tx:"このカードが戦闘によって破壊され墓地へ送られた時、自分のデッキから攻撃力１５００以下の風属性モンスター１体を自分フィールド上に表側攻撃表示で特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トラスト・ガーディアン",h:"とらすと・がーでぃあん",e:"Trust Guardian",ra:"R",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"天使族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:800,tx:"このカードをシンクロ素材とする場合、レベル７以上のシンクロモンスターのシンクロ召喚にしか使用できない。\nこのカードをシンクロ素材としたシンクロモンスターは、１ターンに１度だけ戦闘では破壊されない。\nこの効果を適用したダメージステップ終了時、そのシンクロモンスターの攻撃力・守備力は４００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"灼銀の機竜",h:"どらっぐ・おん・う゛ぁーみりおん",e:"Vermillion Dragon Mech",ra:"UR",t:"monster",a:"炎",l:9,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:2700,de:1800,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：１ターンに１度、自分の手札・墓地及び自分フィールドの表側表示モンスターの中からチューナー１体を除外し、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\n②：S召喚したこのカードが効果で破壊され墓地へ送られた場合、除外されている自分のチューナー１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トラックロイド",h:"とらっくろいど",e:"Truckroid",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:2000,tx:"このカードが戦闘によって相手モンスターを破壊し墓地へ送った時、破壊したモンスターを装備カード扱いとしてこのカードに装備する。\nこのカードの攻撃力は、装備したモンスターカードの攻撃力分だけアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トラップ・イーター",h:"とらっぷ・いーたー",e:"Trap Eater",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["特殊召喚","チューナー","効果"],ma:"",tp:"",at:1900,de:1600,tx:"このカードは通常召喚できない。\n相手フィールド上に表側表示で存在する罠カード１枚を墓地へ送った場合のみ特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トラップ・マスター",h:"とらっぷ・ますたー",e:"Trap Master",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["リバース","効果"],ma:"",tp:"",at:500,de:1100,tx:"リバース：フィールド上の罠カードを１枚破壊する。\n（対象カードが裏の場合、表にしてそれが罠カードなら破壊する。\n違う場合は元に戻す。\nそのカードの効果は発動しない）",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トラップ・リアクター・RR",h:"とらっぷ・りあくたー・だぶるあーる",e:"Trap Reactor ・Y FI",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:800,de:1800,tx:"１ターンに１度、相手が罠カードを発動した時に発動できる。\nその罠カードを破壊し、相手ライフに８００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トラップ処理班 Ａチーム",h:"とらっぷしょりはん　Ａちーむ",e:"A-Team: Trap Disposal Unit",ra:"R",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:300,de:400,tx:"この効果は相手ターンでも使用する事ができる。\n相手が罠カードを発動した時、表側表示のこのカードを生け贄に捧げる事で罠カードの発動を無効にし、それを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"銅鑼ドラゴン",h:"どらどらごん",e:"Dragong",ra:"R",t:"monster",a:"地",l:4,ps:7,pe:"",tr:"機械族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:500,de:2100,tx:"最新鋭の技術を駆使し、飛行が可能になった戦闘用の銅鑼。\nマッハ０.７で天空を翔るその存在に驚愕せざるを得ない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トラパート",h:"とらぱーと",e:"Torapart",ra:"R",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"戦士族",mo:["チューナー","効果"],ma:"",tp:"",at:600,de:600,tx:"このカードをシンクロ素材としたシンクロモンスターが攻撃する場合、相手はダメージステップ終了時まで罠カードを発動できない。\nこのカードをシンクロ素材とする場合、戦士族モンスターのシンクロ召喚にしか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トラファスフィア",h:"とらふぁすふぃあ",e:"Troposphere",ra:"SR",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2400,de:2000,tx:"このカードをアドバンス召喚する場合、リリースするモンスターは鳥獣族モンスターでなければならない。\nこのカードはフィールド上に表側表示で存在する限り罠カードの効果を受けない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トラブル・ダイバー",h:"とらぶる・だいばー",e:"Doggy Diver",ra:"UR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"相手フィールド上にモンスターが存在し、自分フィールド上に表側表示で存在するモンスターがレベル４モンスターのみの場合、このカードは手札から特殊召喚できる。\nこの方法による「トラブル・ダイバー」の特殊召喚は１ターンに１度しかできない。\nこのカードをエクシーズ召喚の素材とする場合、戦士族モンスターのエクシーズ召喚にしか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トラミッド・スフィンクス",h:"とらみっど・すふぃんくす",e:"Triamid Sphinx",ra:"SR",t:"monster",a:"地",l:10,ps:"",pe:"",tr:"岩石族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2500,de:2500,tx:"このカードは通常召喚できず、「トラミッド」カードの効果でのみ特殊召喚できる。\n①：「トラミッド・スフィンクス」以外の自分フィールドの表側表示の「トラミッド」カードが戦闘・効果で破壊された場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：自分フィールドに他の「トラミッド」カードが存在する場合、このカードの攻撃力・守備力は自分の墓地のフィールド魔法カードの種類×５００アップし、相手モンスターは「トラミッド・スフィンクス」にしか攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トラミッド・ダンサー",h:"とらみっど・だんさー",e:"Triamid Dancer",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:600,de:1900,tx:"①：１ターンに１度、自分の墓地の「トラミッド」カード１枚を対象として発動できる。\nそのカードをデッキに戻し、自分フィールドの岩石族モンスターの攻撃力・守備力は５００アップする。\n②：相手ターンに１度、自分フィールドの「トラミッド」フィールド魔法カード１枚を対象として発動できる。\nそのカードを墓地へ送り、デッキからそのカードとカード名が異なる「トラミッド」フィールド魔法カード１枚を発動する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トラミッド・ハンター",h:"とらみっど・はんたー",e:"Triamid Hunter",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1400,de:1100,tx:"①：フィールド魔法カードが表側表示で存在する場合、自分は通常召喚に加えて１度だけ、自分メインフェイズに岩石族モンスター１体を召喚できる。\n②：相手ターンに１度、自分フィールドの「トラミッド」フィールド魔法カード１枚を対象として発動できる。\nそのカードを墓地へ送り、デッキからそのカードとカード名が異なる「トラミッド」フィールド魔法カード１枚を発動する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トラミッド・マスター",h:"とらみっど・ますたー",e:"Triamid Master",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1800,de:700,tx:"①：１ターンに１度、自分フィールドの表側表示の「トラミッド」カード１枚を墓地へ送り、フィールドにセットされたカード１枚を対象として発動できる。\nそのカードを破壊する。\n②：相手ターンに１度、自分フィールドの「トラミッド」フィールド魔法カード１枚を対象として発動できる。\nそのカードを墓地へ送り、デッキからそのカードとカード名が異なる「トラミッド」フィールド魔法カード１枚を発動する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トランシケーダ",h:"とらんしけーだ",e:"Transcicada",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"昆虫族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:1000,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合に発動できる。\n自分フィールドに「ヌケガラトークン」（昆虫族・地・星３・攻／守０）１体を特殊召喚する。\nこの効果で特殊召喚したトークンがモンスターゾーンに存在する限り、自分は昆虫族モンスターしかEXデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トランス・デーモン",h:"とらんす・でーもん",e:"Trance Archfiend",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1500,de:500,tx:"①：１ターンに１度、自分メインフェイズに発動できる。\n手札から悪魔族モンスター１体を捨て、このカードの攻撃力をターン終了時まで５００アップする。\n②：自分フィールドのこのカードが破壊され墓地へ送られた時、除外されている自分の闇属性モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トランスファミリア",h:"とらんすふぁみりあ",e:"Trancefamiliar",ra:"R",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"①：１ターンに１度、自分フィールドのモンスター１体を対象として発動できる。\nその自分のモンスターの位置を、他の自分のメインモンスターゾーンに移動する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トランスフォーム・スフィア",h:"とらんすふぉーむ・すふぃあ",e:"Transforming Sphere",ra:"N",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"１ターンに１度、相手フィールド上に表側守備表示で存在するモンスター１体を選択して発動する事ができる。\n手札を１枚捨て、選択した相手モンスターを装備カード扱いとしてこのカードに１体のみ装備する。\nこのカードの攻撃力は、このカードの効果で装備したモンスターの攻撃力分アップする。\nこのカードは攻撃した場合、バトルフェイズ終了時に守備表示になる。\nエンドフェイズ時、このカードの効果で装備したモンスターを相手フィールド上に表側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドリアード",h:"どりあーど",e:"Doriado",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:1200,de:1400,tx:"森の精霊。\n草木の力を借りて相手の動きを封じる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドリーム・ピエロ",h:"どりーむ・ぴえろ",e:"Dream Clown",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:900,tx:"フィールド上に攻撃表示で存在するこのカードが表側守備表示になった時、相手フィールド上に存在するモンスター１体を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トリックスター・ナルキッス",h:"とりっくすたー・なるきっす",e:"Trickstar Narkissus",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1000,de:1800,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：相手が効果ダメージを受けた場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードがモンスターゾーンに存在する限り、相手が手札・墓地のモンスターの効果を発動する度に、相手に２００ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トリックスター・リリーベル",h:"とりっくすたー・りりーべる",e:"Trickstar Lilybell",ra:"R",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:800,de:2000,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードがドロー以外の方法で手札に加わった場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードは直接攻撃できる。\n③：このカードが相手に戦闘ダメージを与えた時、自分の墓地の「トリックスター」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"砦を守る翼竜",h:"とりでをまもるよくりゅう",e:"Winged Dragon, Guardian of the Fortress #1",ra:"N",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:1400,de:1200,tx:"山の砦を守る竜。\n天空から急降下して敵を攻撃。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トリプル・ヴァイパー",h:"とりぷる・う゛ぁいぱー",e:"Hydra Viper",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1800,de:400,tx:"このカードは１度のバトルフェイズ中に３回攻撃する事ができる。\nこのカードは自分フィールド上に存在する水族モンスター１体をリリースしなければ攻撃宣言をする事ができない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドリラゴ",h:"どりらご",e:"Drillago",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1600,de:1100,tx:"相手フィールド上に攻撃力１６００以上の表側表示モンスターしか存在しない場合、このモンスターは相手プレイヤーに直接攻撃をする事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドリル・ウォリアー",h:"どりる・うぉりあー",e:"Drill Warrior",ra:"R",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2400,de:2000,tx:"「ドリル・シンクロン」＋チューナー以外のモンスター１体以上\n１ターンに１度、自分のメインフェイズ時に発動できる。\nこのカードの攻撃力を半分にし、このターンこのカードは相手プレイヤーに直接攻撃できる。\nまた、１ターンに１度、自分のメインフェイズ時に発動できる。\n手札を１枚捨ててこのカードをゲームから除外する。\n次の自分のスタンバイフェイズ時、この効果で除外したこのカードを自分フィールド上に特殊召喚する。\nその後、自分の墓地のモンスター１体を選んで手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドリル・シンクロン",h:"どりる・しんくろん",e:"Drill Synchron",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:300,tx:"①：このカードがモンスターゾーンに存在する限り、自分の戦士族モンスターが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ相手に戦闘ダメージを与える。\n②：１ターンに１度、このカードの①の効果を適用して相手に戦闘ダメージを与えた時に発動できる。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドリル・バーニカル",h:"どりる・ばーにかる",e:"Drill Barnacle",ra:"N",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:300,de:0,tx:"このカードは相手プレイヤーに直接攻撃する事ができる。\nこのカードが直接攻撃によって相手ライフに戦闘ダメージを与える度に、このカードの攻撃力は１０００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドリルロイド",h:"どりるろいど",e:"Drillroid",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1600,de:1600,tx:"このカードが守備表示モンスターを攻撃した場合、ダメージ計算前にそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドル・ドラ",h:"どる・どら",e:"Twin-Headed Behemoth",ra:"SR",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1500,de:1200,tx:"このカード名の効果はデュエル中に１度しか使用できない。\n①：フィールドのこのカードが破壊され墓地へ送られたターンのエンドフェイズに発動できる。\nこのカードを墓地から特殊召喚する。\nこの効果で特殊召喚したこのカードの攻撃力・守備力は１０００になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トルクチューン・ギア",h:"とるくちゅーん・ぎあ",e:"Torque Tune Gear",ra:"R",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"機械族",mo:["ユニオン","チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"①：１ターンに１度、以下の効果から１つを選択して発動できる。\n●自分フィールドの表側表示モンスター１体を対象とし、このカードを装備カード扱いとしてそのモンスターに装備する。\n装備モンスターが戦闘・効果で破壊される場合、代わりにこのカードを破壊する。\n●装備されているこのカードを特殊召喚する。\n②：装備モンスターの攻撃力・守備力は５００アップし、チューナーとして扱う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トルネード・バード",h:"とるねーど・ばーど",e:"Tornado Bird",ra:"R",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["リバース","効果"],ma:"",tp:"",at:1100,de:1000,tx:"リバース：フィールド上に存在する魔法・罠カード２枚を選択して持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドレイク",h:"どれいく",e:"Kurama",ra:"N",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["通常"],ma:"",tp:"",at:800,de:800,tx:"しっぽが長い鳥。\nそのしっぽで空中から攻撃する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トレジャー・パンダー",h:"とれじゃー・ぱんだー",e:"Treasure Panda",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1100,de:2000,tx:"①：自分の墓地から魔法・罠カードを３枚まで裏側表示で除外して発動できる。\n除外したカードの数と同じレベルの通常モンスター１体をデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドレッド・ドラゴン",h:"どれっど・どらごん",e:"Dread Dragon",ra:"SR",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"ドラゴン族",mo:["チューナー","効果"],ma:"",tp:"",at:1100,de:400,tx:"①：このカードが戦闘で破壊され墓地へ送られた時に発動できる。\nデッキからレベル３以下のドラゴン族モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トレント",h:"とれんと",e:"Trent",ra:"N",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"植物族",mo:["通常"],ma:"",tp:"",at:1500,de:1800,tx:"まだまだ成長し続けている森の大木。\n森の守り神。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トロイホース",h:"とろいほーす",e:"The Trojan Horse",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"地属性モンスターを生け贄召喚する場合、このモンスター１体で２体分の生け贄とする事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドローバ",h:"どろーば",e:"Dorover",ra:"N",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:900,de:800,tx:"ドロドロした、気持ち悪いモンスター。\n猛毒ガスを吐き、攻撃をする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドローン",h:"どろーん",e:"Doron",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:900,de:500,tx:"ドロローンと分身して、はさみ撃ち攻撃をしかけてくる。\n油断するな！",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"とろける赤き影",h:"とろけるあかきかげ",e:"The Melting Red Shadow",ra:"N",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:500,de:700,tx:"体を溶かして足もとの影にもぐり、敵の真下から攻撃する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"泥に潜み棲むもの",h:"どろにひそみすむもの",e:"The Thing That Hides in the Mud",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["通常"],ma:"",tp:"",at:1200,de:1300,tx:"足下がドロドロと溶けだしたら、こいつが現れる前兆だ。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トワイライトロード・ジェネラル ジェイン",h:"とわいらいとろーど・じぇねらる　じぇいん",e:"Jain, Twilightsworn General",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1200,tx:"①：１ターンに１度、自分の手札・墓地から「ライトロード」モンスター１体を除外し、フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力・守備力はターン終了時まで、除外したモンスターのレベル×３００ダウンする。\n②：１ターンに１度、このカード以外の自分の「ライトロード」モンスターの効果が発動した場合に発動する。\n自分のデッキの上からカードを２枚墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トワイライトロード・シャーマン ルミナス",h:"とわいらいとろーど・しゃーまん　るみなす",e:"Lumina, Twilightsworn Shaman",ra:"UR",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"①：１ターンに１度、自分の手札・墓地から「ライトロード」モンスター１体を除外し、「トワイライトロード・シャーマン ルミナス」以外の除外されている自分の「ライトロード」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n②：１ターンに１度、このカード以外の自分の「ライトロード」モンスターの効果が発動した場合に発動する。\n自分のデッキの上からカードを３枚墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トワイライトロード・ソーサラー ライラ",h:"とわいらいとろーど・そーさらー　らいら",e:"Lyla, Twilightsworn Enchantress",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1700,de:200,tx:"①：１ターンに１度、魔法・罠カードの効果が発動した時、自分の手札・墓地から「ライトロード」モンスター１体を除外し、フィールドの表側表示の魔法・罠カード１枚を対象として発動できる。\nそのカードを破壊する。\n②：１ターンに１度、このカード以外の自分の「ライトロード」モンスターの効果が発動した場合に発動する。\n自分のデッキの上からカードを３枚墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トワイライトロード・ファイター ライコウ",h:"とわいらいとろーど・ふぁいたー　らいこう",e:"Ryko, Twilightsworn Fighter",ra:"SR",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:200,de:100,tx:"①：このカードが召喚・リバースした場合、自分の手札・墓地から「ライトロード」モンスター１体を除外して発動できる。\nフィールドのカード１枚を選んで除外する。\n②：１ターンに１度、このカード以外の自分の「ライトロード」モンスターの効果が発動した場合に発動する。\n自分のデッキの上からカードを３枚墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"首領・ザルーグ",h:"どん・ざるーぐ",e:"Don Zaloog",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1400,de:1500,tx:"このカードが相手ライフに戦闘ダメージを与えた時、以下の効果から１つを選択して発動する事ができる。\n\n●相手の手札をランダムに１枚捨てる。\n\n●相手のデッキの上からカードを２枚墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"首領亀",h:"どんがめ",e:"Don Turtle",ra:"N",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1100,de:1200,tx:"このカードが召喚・反転召喚に成功した時、自分の手札から「首領亀」を任意の枚数特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドングリス",h:"どんぐりす",e:"Rodenut",ra:"N",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"相手がモンスターを特殊召喚する度に、このカードにドングリカウンターを１つ置く。\nこのカードに乗っているドングリカウンターを１つ取り除く事で、相手フィールド上に存在するモンスター１体を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドングルドングリ",h:"どんぐるどんぐり",e:"Datacorn",ra:"N",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合に発動できる。\n自分フィールドに「ドングルトークン」（サイバース族・闇・星１・攻／守０）１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"曇天気スレット",h:"どんてんきすれっと",e:"The Weather Painter Cloud",ra:"N",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが自分フィールドに存在し、このカード以外の自分フィールドの表側表示の「天気」カードが墓地へ送られた場合、自分の墓地の「天気」魔法・罠カードを２枚まで対象として発動できる。\nそのカードを自分の魔法＆罠ゾーンに表側表示で置く。\n②：フィールドのこのカードが「天気」カードの効果を発動するために除外された場合、次のターンのスタンバイフェイズに発動できる。\n除外されているこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"嵐竜の聖騎士",h:"ないと・おぶ・すとーむどらごん",e:"Paladin of Storm Dragon",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"サイバース族",mo:["儀式","効果"],ma:"",tp:"",at:1900,de:900,tx:"「サイバネット・リチューアル」により降臨。\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがモンスターに攻撃するダメージステップ開始時に発動できる。\nそのモンスターを持ち主の手札に戻す。\n②：このカードをリリースして発動できる。\n手札・デッキからレベル５以上のサイバース族モンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターは、このターン効果を発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"光子竜の聖騎士",h:"ないと・おぶ・ふぉとんどらごん",e:"Paladin of Photon Dragon",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["儀式","効果"],ma:"",tp:"",at:1900,de:800,tx:"「光子竜降臨」により降臨。\nこのカードをリリースして発動できる。\n手札・デッキから「銀河眼の光子竜」１体を特殊召喚する。\nまた、このカードが戦闘によって相手モンスターを破壊し墓地へ送った時、デッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"黒竜の聖騎士",h:"ないと・おぶ・ぶらっくどらごん",e:"Paladin of Dark Dragon",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["儀式","効果"],ma:"",tp:"",at:1900,de:1200,tx:"「黒竜降臨」により降臨。\n「黒竜の聖騎士」の②の効果は１ターンに１度しか使用できない。\n①：このカードが守備表示モンスターを攻撃したダメージステップ開始時に発動する。\nそのモンスターを破壊する。\n②：このカードをリリースして発動できる。\n手札・デッキから「レッドアイズ」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"白竜の聖騎士",h:"ないと・おぶ・ほわいとどらごん",e:"Paladin of White Dragon",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["儀式","効果"],ma:"",tp:"",at:1900,de:1200,tx:"「白竜降臨」により降臨。\n①：このカードが裏側守備表示モンスターに攻撃するダメージステップ開始時に発動する。\nその裏側守備表示モンスターを破壊する。\n②：このカードをリリースして発動できる。\n手札・デッキから「青眼の白龍」１体を特殊召喚する。\nこのターン、自分の「青眼の白龍」は攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ナイト・バタフライ・アサシン",h:"ないと・ばたふらい・あさしん",e:"Night Papilloperative",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2600,de:2000,tx:"レベル４モンスター×３\n１ターンに１度、このカードのエクシーズ素材を１つ取り除いて発動できる。\nこのカードの攻撃力はフィールド上のエクシーズ素材の数×３００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ナイトエンド・ソーサラー",h:"ないとえんど・そーさらー",e:"Night's End Sorcerer",ra:"SR",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["チューナー","効果"],ma:"",tp:"",at:1300,de:400,tx:"①：このカードが特殊召喚に成功した時、相手の墓地のカードを２枚まで対象として発動できる。\nそのカードを除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"騎士デイ・グレファー",h:"ないとでい・ぐれふぁー",e:"Knight Day Grepher",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["デュアル","効果"],ma:"",tp:"",at:1700,de:1600,tx:"①：このカードはフィールド・墓地に存在する限り、通常モンスターとして扱う。\n②：フィールドの通常モンスター扱いのこのカードを通常召喚としてもう１度召喚できる。\nその場合このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●自分エンドフェイズに、自分の墓地の装備魔法カード１枚を対象として発動できる。\nそのカードを手札に加える。\nこのカード名のこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ナイトメア・スコーピオン",h:"ないとめあ・すこーぴおん",e:"Nightmare Scorpion",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"昆虫族",mo:["通常"],ma:"",tp:"",at:900,de:800,tx:"悪夢を見せ、うなされている間に四本もある毒のしっぽを刺す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ナイトメア・ホース",h:"ないとめあ・ほーす",e:"Nightmare Horse",ra:"N",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:500,de:400,tx:"このカードは相手フィールド上にモンスターが存在しても、相手プレイヤーに直接攻撃をする事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ナイトメアテーベ",h:"ないとめあてーべ",e:"Theban Nightmare",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1500,de:500,tx:"①：自分の手札と自分の魔法＆罠ゾーンにカードが存在しない場合、このカードの攻撃力は１５００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"夜薔薇の騎士",h:"ないとろーずないと",e:"Twilight Rose Knight",ra:"SR",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"戦士族",mo:["チューナー","効果"],ma:"",tp:"",at:1000,de:1000,tx:"①：このカードが召喚に成功した時に発動できる。\n手札からレベル４以下の植物族モンスター１体を特殊召喚する。\n②：このカードがモンスターゾーンに存在する限り、相手は植物族モンスターを攻撃対象に選択できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ながれ者傭兵部隊",h:"ながれものようへいぶたい",e:"Nomadic Force",ra:"SR",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"このカードが戦闘によって相手モンスターを破壊し墓地へ送った時、このカードをリリースして発動できる。\n相手フィールド上に裏側守備表示で存在するモンスター１体を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"泣き神の石像",h:"なきがみのせきぞう",e:"Weeping Idol",ra:"R",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:0,de:500,tx:"自分の墓地に存在するチューナー１体をゲームから除外して発動する。\nこのカードを手札から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"謎の傀儡師",h:"なぞのくぐつし",e:"Mysterious Puppeteer",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:1500,tx:"モンスターが召喚・反転召喚に成功した時、自分は５００ライフポイント回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"なぞの手",h:"なぞのて",e:"Mystery Hand",ra:"N",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:500,de:500,tx:"空間をゆがませ、次元のはざまから腕をのばし攻撃をしかける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ナチュラル・ボーン・サウルス",h:"なちゅらる・ぼーん・さうるす",e:"Skelesaurus",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"アンデット族",mo:["デュアル","効果"],ma:"",tp:"",at:1700,de:1400,tx:"①：このカードはフィールド・墓地に存在する限り、通常モンスターとして扱う。\n②：フィールドの通常モンスター扱いのこのカードを通常召喚としてもう１度召喚できる。\nその場合このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●このカードの種族・属性は恐竜族・地属性になる。\n\n●このカードが戦闘で相手モンスターを破壊し墓地へ送った時に発動できる。\nそのモンスターを自分フィールドに守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターの種族はアンデット族になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ナチュル・アントジョー",h:"なちゅる・あんとじょー",e:"Naturia Antjaw",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:400,de:200,tx:"相手がモンスターの特殊召喚に成功した時、自分のデッキからレベル３以下の「ナチュル」と名のついたモンスター１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ナチュル・エッグプラント",h:"なちゅる・えっぐぷらんと",e:"Naturia Eggplant",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1000,de:700,tx:"このカードがフィールド上から墓地へ送られた時、自分の墓地に存在する「ナチュル・エッグプラント」以外の「ナチュル」と名のついたモンスター１体を選択して手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ナチュル・ガーディアン",h:"なちゅる・がーでぃあん",e:"Naturia Guardian",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1600,de:400,tx:"相手がモンスターの召喚に成功した時、このカードの攻撃力はエンドフェイズ時まで３００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ナチュル・ガイアストライオ",h:"なちゅる・がいあすとらいお",e:"Naturia Gaiastrio",ra:"N",t:"monster",a:"地",l:10,ps:"",pe:"",tr:"岩石族",mo:["融合","効果"],ma:"",tp:"",at:3200,de:2100,tx:"地属性Sモンスター×２\n①：フィールドのカード１枚のみを対象とする魔法・罠・モンスターの効果が発動した時、手札を１枚墓地へ送って発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ナチュル・ガオドレイク",h:"なちゅる・がおどれいく",e:"Naturia Leodrake",ra:"SR",t:"monster",a:"地",l:9,ps:"",pe:"",tr:"獣族",mo:["シンクロ"],ma:"",tp:"",at:3000,de:1800,tx:"地属性チューナー＋チューナー以外の地属性モンスター１体以上",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ナチュル・クリフ",h:"なちゅる・くりふ",e:"Naturia Cliff",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"このカードがフィールド上から墓地へ送られた時、デッキからレベル４以下の「ナチュル」と名のついたモンスター１体を表側攻撃表示で特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ナチュル・コスモスビート",h:"なちゅる・こすもすびーと",e:"Naturia Cosmobeet",ra:"R",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"植物族",mo:["チューナー","効果"],ma:"",tp:"",at:1000,de:700,tx:"相手がモンスターの通常召喚に成功した時、このカードを手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ナチュル・サンフラワー",h:"なちゅる・さんふらわー",e:"Naturia Sunflower",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:500,de:0,tx:"相手の効果モンスターの効果が発動した時、自分フィールド上の「ナチュル」と名のついたモンスター１体とこのカードをリリースして発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ナチュル・スタッグ",h:"なちゅる・すたっぐ",e:"Naturia Stag Beetle",ra:"N",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:2200,de:1500,tx:"このカードが攻撃を行うバトルステップ時及びダメージステップ時に相手が魔法・罠・効果モンスターの効果を発動した時、自分の墓地に存在する「ナチュル」と名のついたモンスター１体を選択して発動する事ができる。\n選択したモンスターを墓地から特殊召喚する。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ナチュル・ストロベリー",h:"なちゅる・すとろべりー",e:"Naturia Strawberry",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"相手がモンスターの召喚・特殊召喚に成功した時、そのモンスター１体を選択して発動する。\nこのカードの攻撃力はエンドフェイズ時まで、選択したモンスター１体のレベル×１００ポイントアップする。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ナチュル・スパイダーファング",h:"なちゅる・すぱいだーふぁんぐ",e:"Naturia Spiderfang",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:2100,de:400,tx:"このカードは、相手が魔法・罠・効果モンスターの効果を発動したターンでなければ攻撃宣言できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ナチュル・チェリー",h:"なちゅる・ちぇりー",e:"Naturia Cherries",ra:"R",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"植物族",mo:["チューナー","効果"],ma:"",tp:"",at:200,de:200,tx:"このカードが相手によってフィールド上から墓地へ送られた場合、自分のデッキから「ナチュル・チェリー」を２体まで裏側守備表示で特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ナチュル・トライアンフ",h:"なちゅる・とらいあんふ",e:"Naturia Tulip",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"植物族",mo:["チューナー","効果"],ma:"",tp:"",at:600,de:1500,tx:"このカードが自分フィールド上に表側表示で存在する限り、相手が魔法・罠カードを発動する度に、自分フィールド上の「ナチュル」と名のついたモンスターの攻撃力はエンドフェイズ時まで５００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ナチュル・ドラゴンフライ",h:"なちゅる・どらごんふらい",e:"Naturia Dragonfly",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1200,de:400,tx:"このカードは攻撃力２０００以上のモンスターとの戦闘では破壊されない。\nこのカードの攻撃力は自分の墓地の「ナチュル」と名のついたモンスターの数×２００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ナチュル・ナーブ",h:"なちゅる・なーぶ",e:"Naturia Vein",ra:"N",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"植物族",mo:["チューナー","効果"],ma:"",tp:"",at:200,de:300,tx:"相手が魔法・罠カードを発動した時、自分フィールド上の「ナチュル」と名のついたモンスター１体とこのカードをリリースして発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ナチュル・ハイドランジー",h:"なちゅる・はいどらんじー",e:"Naturia Hydrangea",ra:"N",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1900,de:2000,tx:"自分フィールド上の「ナチュル」と名のついたモンスターの効果が発動した自分のターン、このカードは手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ナチュル・パイナポー",h:"なちゅる・ぱいなぽー",e:"Naturia Pineapple",ra:"R",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"このカードがフィールド上に表側表示で存在する限り、自分フィールド上に表側表示で存在する全てのモンスターは植物族になる。\n自分のスタンバイフェイズ時にこのカードが墓地に存在し、自分フィールド上に魔法・罠カードが存在しない場合、このカードを墓地から特殊召喚する事ができる。\nこの効果は自分フィールド上に「ナチュル・パイナポー」が表側表示で存在する場合、または自分の墓地に植物族・獣族以外のモンスターが存在する場合には発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ナチュル・バタフライ",h:"なちゅる・ばたふらい",e:"Naturia Butterfly",ra:"SR",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"昆虫族",mo:["チューナー","効果"],ma:"",tp:"",at:500,de:1200,tx:"１ターンに１度、相手モンスターの攻撃宣言時に発動できる。\n自分のデッキの一番上のカード１枚を墓地へ送り、その攻撃を無効にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ナチュル・パルキオン",h:"なちゅる・ぱるきおん",e:"Naturia Barkion",ra:"SR",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:1800,tx:"地属性チューナー＋チューナー以外の地属性モンスター１体以上\n①：罠カードが発動した時、自分の墓地のカード２枚を除外して発動できる。\nこのカードがフィールドに表側表示で存在する場合、その発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ナチュル・パンプキン",h:"なちゅる・ぱんぷきん",e:"Naturia Pumpkin",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1400,de:800,tx:"相手フィールド上にモンスターが存在する場合にこのカードが召喚に成功した時、手札から「ナチュル」と名のついたモンスター１体を特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ナチュル・ビートル",h:"なちゅる・びーとる",e:"Naturia Beetle",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:400,de:1800,tx:"このカードがフィールド上に表側表示で存在する限り、魔法カードが発動する度に、このカードの元々の攻撃力と元々の守備力を入れ替える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ナチュル・ビーンズ",h:"なちゅる・びーんず",e:"Naturia Beans",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:100,de:1200,tx:"このカードは１ターンに１度だけ、戦闘では破壊されない。\nフィールド上に表側表示で存在するこのカードが攻撃対象に選択された時、相手ライフに５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ナチュル・ホーストニードル",h:"なちゅる・ほーすとにーどる",e:"Naturia Horneedle",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1800,de:100,tx:"相手がモンスターの特殊召喚に成功した時、このカード以外の自分フィールド上に表側表示で存在する「ナチュル」と名のついたモンスター１体をリリースして発動できる。\nそのモンスターを破壊する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ナチュル・ホワイトオーク",h:"なちゅる・ほわいとおーく",e:"Naturia White Oak",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1800,de:1400,tx:"このカードが相手のカードの効果の対象になった時、フィールド上のこのカードを墓地へ送って発動できる。\nデッキからレベル４以下の「ナチュル」と名のついたモンスター２体を特殊召喚する。\nこの効果で特殊召喚したモンスターは攻撃宣言できず、自分のエンドフェイズ時に破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ナチュル・マロン",h:"なちゅる・まろん",e:"Naturia Marron",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1200,de:700,tx:"このカードが召喚に成功した時、自分のデッキから「ナチュル」と名のついたモンスター１体を墓地へ送る事ができる。\nまた、１ターンに１度、自分の墓地に存在する「ナチュル」と名のついたモンスター２体を選択してデッキに戻し、自分のデッキからカードを１枚ドローする事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ナチュル・マンティス",h:"なちゅる・まんてぃす",e:"Naturia Mantis",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1700,de:1500,tx:"相手がモンスターの召喚に成功した時、手札から「ナチュル」と名のついたモンスター１体を墓地へ送って発動できる。\nそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ナチュル・ラグウィード",h:"なちゅる・らぐうぃーど",e:"Naturia Ragweed",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1200,de:2000,tx:"相手がドローフェイズ以外でカードをドローした時、フィールド上のこのカードを墓地へ送って発動できる。\nデッキからカードを２枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ナチュル・ランドオルス",h:"なちゅる・らんどおるす",e:"Naturia Landoise",ra:"SR",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"岩石族",mo:["シンクロ","効果"],ma:"",tp:"",at:2350,de:1600,tx:"地属性チューナー＋チューナー以外の地属性モンスター１体以上\nこのカードがフィールド上に表側表示で存在する限り、手札の魔法カード１枚を墓地へ送る事で、効果モンスターの効果の発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ナチュル・レディバグ",h:"なちゅる・れでぃばぐ",e:"Naturia Ladybug",ra:"N",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"自分が「ナチュル」と名のついたモンスターのシンクロ召喚に成功した時、このカードを墓地から特殊召喚できる。\nまた、自分のメインフェイズ時にこのカードをリリースし、自分フィールド上の「ナチュル」と名のついたモンスター１体を選択して発動できる。\n選択したモンスターの攻撃力はエンドフェイズ時まで１０００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ナチュル・ローズウィップ",h:"なちゅる・ろーずうぃっぷ",e:"Naturia Rosewhip",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"植物族",mo:["チューナー","効果"],ma:"",tp:"",at:400,de:1700,tx:"このカードがフィールド上に表側表示で存在する限り、相手は１ターンに１度しか魔法・罠カードを発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ナチュル・ロック",h:"なちゅる・ろっく",e:"Naturia Rock",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1200,de:1200,tx:"罠カードが発動した時、デッキの上からカードを１枚墓地へ送って発動できる。\nこのカードを手札から特殊召喚する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"7つの武器を持つハンター",h:"ななつのぶきをもつはんたー",e:"The Hunter with 7 Weapons",ra:"SR",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:600,tx:"このカードが召喚に成功した時、種族を１つ宣言して発動する。\nこのカードが、宣言した種族のモンスターと戦闘を行う場合、このカードの攻撃力はダメージ計算時のみ１０００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ナノブレイカー",h:"なのぶれいかー",e:"Nanobreaker",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1600,de:1800,tx:"このカードがレベル３以下の表側表示モンスターを攻撃した場合、ダメージ計算を行わずそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ならず者傭兵部隊",h:"ならずものようへいぶたい",e:"Exiled Force",ra:"UR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"①：このカードをリリースし、フィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"No.10 白輝士イルミネーター",h:"なんばーず１０ しろきしいるみねーたー",e:"Number 10: Illumiknight",ra:"UR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2400,de:2400,tx:"レベル４モンスター×３\n①：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\n手札を１枚選んで墓地へ送り、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"No.12 機甲忍者クリムゾン・シャドー",h:"なんばーず１２ きこうにんじゃくりむぞん・しゃどー",e:"Number 12: Crimson Shadow Armor Ninja",ra:"SR",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2400,de:1700,tx:"レベル５モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\nこのターン、自分フィールドの「忍者」モンスターは戦闘・効果では破壊されない。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"No.15 ギミック・パペット－ジャイアントキラー",h:"なんばーず１５ ぎみっく・ぱぺっと－じゃいあんときらー",e:"Number 15: Gimmick Puppet Giant Grinder",ra:"UR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1500,de:2500,tx:"レベル８モンスター×２\n①：自分メインフェイズ１に、このカードのX素材を１つ取り除き、相手フィールドの特殊召喚されたモンスター１体を対象として発動できる。\nそのモンスターを破壊する。\n破壊したモンスターがXモンスターだった場合、さらにそのモンスターの元々の攻撃力分のダメージを相手に与える。\nこの効果は１ターンに２度まで使用できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"No.17 リバイス・ドラゴン",h:"なんばーず１７　りばいす・どらごん",e:"Number 17: Leviathan Dragon",ra:"UR",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2000,de:0,tx:"レベル３モンスター×２\n①：X素材が無いこのカードは直接攻撃できない。\n②：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\nこのカードの攻撃力は５００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"No.18 紋章祖プレイン・コート",h:"なんばーず１８ もんしょうそぷれいん・こーと",e:"Number 18: Heraldry Patriarch",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"サイキック族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2200,de:2200,tx:"レベル４モンスター×２\nこのカードの①の効果は同一チェーン上では１度しか発動できない。\n①：フィールドに同名モンスターが２体以上存在する場合、このカードのX素材を１つ取り除いて発動できる。\nその内の１体を選び、そのモンスター以外のそのモンスターの同名モンスターを全て破壊する。\nこの効果は相手ターンでも発動できる。\n②：相手はこのカードの効果で選んだモンスターの同名モンスターを召喚・反転召喚・特殊召喚できない。\n③：このカードが墓地へ送られた場合に発動できる。\nデッキから「紋章獣」モンスター２体を墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"No.19 フリーザードン",h:"なんばーず１９ ふりーざーどん",e:"Number 19: Freezadon",ra:"R",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"恐竜族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2000,de:2500,tx:"レベル５モンスター×２\n①：１ターンに１度、自分のXモンスターがX素材を取り除いて効果を発動する場合、取り除くX素材１つをこのカードから取り除く事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"No.20 蟻岩土ブリリアント",h:"なんばーず２０ ぎがんとぶりりあんと",e:"Number 20: Giga-Brilliant",ra:"SR",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"昆虫族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1800,de:1800,tx:"レベル３モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\n自分フィールドの全てのモンスターの攻撃力は３００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"No.22 不乱健",h:"なんばーず２２ ふらんけん",e:"Number 22: Zombiestein",ra:"SR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"アンデット族",mo:["エクシーズ","効果"],ma:"",tp:"",at:4500,de:1000,tx:"闇属性レベル８モンスター×２\nこのカードはX召喚でしか特殊召喚できない。\n①：１ターンに１度、このカードがフィールドに攻撃表示で存在する場合、このカードのX素材を１つ取り除き、手札を１枚墓地へ送り、相手フィールドの表側表示のカード１枚を対象として発動できる。\nこのカードを守備表示にし、対象のカードの効果をターン終了時まで無効にする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"No.25 重装光学撮影機フォーカス・フォース",h:"なんばーず２５ ふるめたる・ふぉとぐらいどふぉーかす・ふぉーす",e:"Number 25: Force Focus",ra:"SR",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2800,de:2400,tx:"レベル６モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除き、相手フィールドのレベル５以上の効果モンスター１体を対象として発動できる。\nその相手モンスターの効果をターン終了時まで無効にする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"No.28 タイタニック・モス",h:"なんばーず２８ たいたにっく・もす",e:"Number 28: Titanic Moth",ra:"R",t:"monster",a:"炎",l:7,ps:"",pe:"",tr:"昆虫族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2400,de:2200,tx:"レベル７モンスター×２\n①：自分フィールドに他のモンスターが存在しない場合、このカードは直接攻撃できる。\nその直接攻撃で相手に与える戦闘ダメージは半分になる。\n②：このカードが相手に戦闘ダメージを与えた時、このカードのX素材を１つ取り除いて発動できる。\n相手の手札の数×５００ダメージを相手に与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"No.30 破滅のアシッド・ゴーレム",h:"なんばーず３０ はめつのあしっど・ごーれむ",e:"Number 30: Acid Golem of Destruction",ra:"SR",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"岩石族",mo:["エクシーズ","効果"],ma:"",tp:"",at:3000,de:3000,tx:"レベル３モンスター×２\n①：X素材が無いこのカードは攻撃できない。\n②：このカードがモンスターゾーンに存在する限り、自分はモンスターを特殊召喚できない。\n③：自分スタンバイフェイズに発動する。\nこのカードのX素材を１つ取り除くか、自分は２０００ダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"No.32 海咬龍シャーク・ドレイク",h:"なんばーず３２　かいこうりゅうしゃーく・どれいく",e:"Number 32: Shark Drake",ra:"UR",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"海竜族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2800,de:2100,tx:"レベル４モンスター×３\n①：１ターンに１度、このカードの攻撃で相手モンスターを破壊し墓地へ送った時、このカードのX素材を１つ取り除いて発動できる。\nそのモンスターを相手フィールドに攻撃表示で特殊召喚する。\nこの効果で特殊召喚したモンスターの攻撃力は１０００ダウンする。\nこの効果で特殊召喚した場合、このバトルフェイズ中、このカードはもう１度だけ攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"No.34 電算機獣テラ・バイト",h:"なんばーず３４　でんさんきじゅうてら・ばいと",e:"Number 34: Terror-Byte",ra:"UR",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:0,de:2900,tx:"レベル３モンスター×３\n①：１ターンに１度、このカードのX素材を１つ取り除き、相手フィールドのレベル４以下の攻撃表示モンスター１体を対象として発動できる。\nそのモンスターのコントロールをエンドフェイズまで得る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"No.36 先史遺産－超機関フォーク＝ヒューク",h:"なんばーず３６ おーぱーつ－ちょうきかんふぉーく＝ひゅーく",e:"Number 36: Chronomaly Chateau Huyuk",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2000,de:2500,tx:"レベル４「先史遺産」モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除き、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力をターン終了時まで０にする。\nこの効果は相手ターンでも発動できる。\n②：自分フィールドの「先史遺産」モンスター１体をリリースし、元々の攻撃力と異なる攻撃力を持つ相手フィールドのモンスター１体を対象として発動できる。\nその元々の攻撃力と異なる攻撃力を持つ相手モンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"No.37 希望織竜スパイダー・シャーク",h:"なんばーず３７ きぼうしきりゅうすぱいだー・しゃーく",e:"Number 37: Hope Woven Dragon Spider Shark",ra:"UR",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"海竜族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2600,de:2100,tx:"水属性レベル４モンスター×２\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分または相手のモンスターの攻撃宣言時、このカードのX素材を１つ取り除いて発動できる。\n相手フィールドの全てのモンスターの攻撃力はターン終了時まで１０００ダウンする。\n②：このカードが戦闘・効果で破壊され墓地へ送られた時、このカード以外の自分の墓地のモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"No.39 希望皇ホープ",h:"なんばーず３９　きぼうおうほーぷ",e:"Number 39: Utopia",ra:"UR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2500,de:2000,tx:"レベル４モンスター×２\n①：自分または相手のモンスターの攻撃宣言時、このカードのX素材を１つ取り除いて発動できる。\nその攻撃を無効にする。\n②：このカードがX素材の無い状態で攻撃対象に選択された場合に発動する。\nこのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"No.40 ギミック・パペット－ヘブンズ・ストリングス",h:"なんばーず４０ ぎみっく・ぱぺっと－へぶんず・すとりんぐす",e:"Number 40: Gimmick Puppet of Strings",ra:"UR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:3000,de:2000,tx:"レベル８モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\nこのカード以外のフィールドの表側表示モンスター全てにストリングカウンターを１つずつ置く。\n②：このカードの①の効果でストリングカウンターを置いた場合、次の相手エンドフェイズに発動する。\nストリングカウンターが置かれているモンスターを全て破壊し、破壊した数×５００ダメージを相手に与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"No.44 白天馬スカイ・ペガサス",h:"なんばーず４４ はくてんますかい・ぺがさす",e:"Number 44: Sky Pegasus",ra:"UR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1800,de:1600,tx:"レベル４モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除き、相手フィールドの表側表示モンスター１体を対象として発動できる。\n相手は１０００LPを払ってこの効果を無効にできる。\n払わなかった場合、対象のモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"No.45 滅亡の予言者 クランブル・ロゴス",h:"なんばーず４５ めつぼうのよげんしゃ くらんぶる・ろごす",e:"Number 45: Crumble Logos the Prophet of Demolition",ra:"R",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"アンデット族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2200,de:0,tx:"レベル２モンスター×２体以上\n①：１ターンに１度、このカードのX素材を１つ取り除き、このカード以外のフィールドの表側表示のカード１枚を対象として発動できる。\nこのモンスターが表側表示で存在する間、対象の表側表示のカードの効果は無効化される。\n②：このカードの①の効果の対象としているカードがフィールドに表側表示で存在する限り、お互いに対象のカード及びその同名カードの効果を発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"No.46 神影龍ドラッグルーオン",h:"なんばーず４６ しんえいりゅうどらっぐるーおん",e:"Number 46: Dragluon",ra:"SR",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:3000,de:3000,tx:"ドラゴン族レベル８モンスター×２\n①：１ターンに１度、自分フィールドに他のモンスターが存在しない場合、このカードのX素材を１つ取り除き、以下の効果から１つを選択して発動できる。\n●手札からドラゴン族モンスター１体を特殊召喚する。\n●相手フィールドのドラゴン族モンスター１体を対象として発動できる。\nそのドラゴン族モンスターのコントロールを得る。\n●相手ターン終了時まで、相手フィールドのドラゴン族モンスターは効果を発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"No.47 ナイトメア・シャーク",h:"なんばーず４７ ないとめあ・しゃーく",e:"Number 47: Nightmare Shark",ra:"UR",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"海竜族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2000,de:2000,tx:"レベル３モンスター×２\n①：このカードが特殊召喚に成功した時に発動できる。\n手札及び自分フィールドの表側表示モンスターの中から、水属性・レベル３モンスター１体を選び、このカードの下に重ねてX素材とする。\n②：１ターンに１度、このカードのX素材を１つ取り除き、自分フィールドの水属性モンスター１体を対象として発動できる。\nこのターン、そのモンスター以外のモンスターは攻撃できず、そのモンスターは直接攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"No.48 シャドー・リッチ",h:"なんばーず４８ しゃどー・りっち",e:"Number 48: Shadow Lich",ra:"R",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1800,de:0,tx:"レベル３モンスター×２\n①：相手ターンに１度、このカードのX素材を１つ取り除いて発動できる。\n自分フィールドに「幻影トークン」（悪魔族・闇・星１・攻／守５００）１体を特殊召喚する。\n②：このカードの攻撃力は、自分フィールドの「幻影トークン」の数×５００アップする。\n③：自分フィールドに「幻影トークン」が存在する限り、相手はこのカードを攻撃対象に選択できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"No.50 ブラック・コーン号",h:"なんばーず５０ ぶらっく・こーんごう",e:"Number 50: Blackship of Corn",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"植物族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2100,de:1500,tx:"レベル４モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除き、このカードの攻撃力以下の攻撃力を持つ相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターを墓地へ送り、相手に１０００ダメージを与える。\nこの効果を発動するターン、このカードは攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"No.52 ダイヤモンド・クラブ・キング",h:"なんばーず５２ だいやもんど・くらぶ・きんぐ",e:"Number 52: Diamond Crab King",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["エクシーズ","効果"],ma:"",tp:"",at:0,de:3000,tx:"レベル４モンスター×２\n①：「No.52 ダイヤモンド・クラブ・キング」は自分フィールドに１体しか表側表示で存在できない。\n②：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\nターン終了時まで、このカードの守備力を０にし、攻撃力を３０００にする。\n③：このカードは攻撃した場合、バトルフェイズ終了時に守備表示になる。\n④：X素材が無いこのカードは、攻撃されたダメージステップ終了時に攻撃表示になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"No.56 ゴールドラット",h:"なんばーず５６ ごーるどらっと",e:"Number 56: Gold Rat",ra:"N",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"獣族",mo:["エクシーズ","効果"],ma:"",tp:"",at:500,de:600,tx:"レベル１モンスター×３\n①：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\n自分はデッキから１枚ドローし、その後手札を１枚選んでデッキに戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"No.6 先史遺産アトランタル",h:"なんばーず６ おーぱーつあとらんたる",e:"Number 6: Chronomaly Atlandis",ra:"UR",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2600,de:3000,tx:"レベル６モンスター×２\n①：このカードがX召喚に成功した時、自分の墓地の「No.」モンスター１体を対象として発動できる。\nそのモンスターを装備カード扱いとしてこのカードに装備する。\n②：このカードの攻撃力は、このカードの効果で装備したモンスターの攻撃力の半分アップする。\n③：１ターンに１度、このカードのX素材を１つ取り除き、このカードの効果で装備している自分の魔法＆罠ゾーンのカード１枚を墓地へ送って発動できる。\n相手のLPを半分にする。\nこの効果を発動するターン、自分はバトルフェイズを行えない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"No.61 ヴォルカザウルス",h:"なんばーず６１ う゛ぉるかざうるす",e:"Number 61: Volcasaurus",ra:"UR",t:"monster",a:"炎",l:5,ps:"",pe:"",tr:"恐竜族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2500,de:1000,tx:"レベル５モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除き、相手フィールドの表側表示モンスター１体を対象として発動できる。\nその相手モンスターを破壊し、そのモンスターの元々の攻撃力分のダメージを相手に与える。\nこの効果を発動するターン、このカードは直接攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"No.64 古狸三太夫",h:"なんばーず６４ ふるだぬきさんだゆう",e:"Number 64: Ronin Raccoon Sandayu",ra:"SR",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1000,de:1000,tx:"獣族レベル２モンスター×２\n①：自分フィールドに他の獣族モンスターが存在する限り、このカードは戦闘・効果では破壊されない。\n②：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\n自分フィールドに「影武者狸トークン」（獣族・地・星１・攻？／守０）１体を特殊召喚する。\nこのトークンの攻撃力は、フィールドのモンスターの一番高い攻撃力と同じになる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"No.68 魔天牢サンダルフォン",h:"なんばーず６８ まてんろうさんだるふぉん",e:"Number 68: Sanaphond the Sky Prison",ra:"R",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"岩石族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2100,de:2700,tx:"レベル８モンスター×２\n①：このカードの攻撃力・守備力は、お互いの墓地のモンスターの数×１００アップする。\n②：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\n相手ターン終了時まで、このカードは効果では破壊されず、お互いに墓地のモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"No.69 紋章神コート・オブ・アームズ",h:"なんばーず６９ ごっど・めだりおんこーと・おぶ・あーむず",e:"Number 69: Heraldry Crest",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"サイキック族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2600,de:1400,tx:"レベル４モンスター×３\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合に発動する。\nこのカード以外のフィールドの全てのXモンスターの効果は無効化される。\n②：このカード以外のフィールドのXモンスター１体を対象として発動できる。\nエンドフェイズまで、このカードはそのモンスターの元々のカード名・効果と同じカード名・効果を得る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"No.7 ラッキー・ストライプ",h:"なんばーず７ らっきー・すとらいぷ",e:"Number 7: Lucky Straight",ra:"R",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"天使族",mo:["エクシーズ","効果"],ma:"",tp:"",at:700,de:700,tx:"レベル７モンスター×３\n①：このカードのX素材を１つ取り除いて発動できる。\nサイコロを２回振る。\nこのカードの攻撃力は、相手ターン終了時まで、大きい方の出た目×７００になる。\n出た目の合計が７だった場合、さらに以下の効果から１つを選んで適用する。\n\n●このカード以外のフィールドのカードを全て墓地へ送る。\n\n●手札または自分・相手の墓地からモンスター１体を選んで自分フィールドに特殊召喚する。\n\n●自分はデッキから３枚ドローし、その後手札を２枚選んで捨てる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"No.70 デッドリー・シン",h:"なんばーず７０ でっどりー・しん",e:"Number 70: Malevolent Sin",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"昆虫族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2400,de:1200,tx:"レベル４モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除き、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターを次の相手スタンバイフェイズまで除外する。\n②：このカードが攻撃したダメージステップ終了時に発動できる。\nこのカードの攻撃力は３００アップし、ランクは３つ上がる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"No.79 BK 新星のカイザー",h:"なんばーず７９ ばーにんぐなっくらー しんせいのかいざー",e:"Number 79: Battlin' Boxer Nova Kaiser",ra:"SR",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2300,de:1600,tx:"レベル４モンスター×２\n①：このカードの攻撃力は、このカードのX素材の数×１００アップする。\n②：１ターンに１度、自分メインフェイズに発動できる。\n自分の手札・墓地から「BK」モンスター１体を選び、このカードの下に重ねてX素材とする。\n③：X素材を持っているこのカードが相手によって破壊され墓地へ送られた時、このカードが持っていたX素材の数まで、自分の墓地のレベル４以下の「BK」モンスターを対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"No.8 紋章王ゲノム・ヘリター",h:"なんばーず８ もんしょうおうげのむ・へりたー",e:"Number 8: Heraldic King Genom-Heritage",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"サイキック族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2400,de:1800,tx:"レベル４「紋章獣」モンスター×２\n①：１ターンに１度、相手フィールドのXモンスター１体を対象として発動できる。\nこのカードは、元々の攻撃力がそのモンスターの攻撃力と同じになり、そのモンスターの元々のカード名・効果と同じカード名・効果を得る。\nその後、対象のモンスターの攻撃力は０になり、効果は無効化される。\nこの効果はエンドフェイズまで適用される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"No.83 ギャラクシー・クィーン",h:"なんばーず８３ ぎゃらくしー・くぃーん",e:"Number 83: Galaxy Queen",ra:"R",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["エクシーズ","効果"],ma:"",tp:"",at:500,de:500,tx:"レベル１モンスター×３\n①：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\n自分フィールドの全てのモンスターは相手ターン終了時まで、戦闘では破壊されず、守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ相手に戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"No.87 雪月花美神クイーン・オブ・ナイツ",h:"なんばーず８７ せつげっかびしんくいーん・おぶ・ないつ",e:"Number 87: Queen of the Night",ra:"SR",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"植物族",mo:["エクシーズ","効果"],ma:"",tp:"",at:3200,de:2800,tx:"レベル８モンスター×３\n①：１ターンに１度、このカードのX素材を１つ取り除き、以下の効果から１つを選択して発動できる。\nこの効果は相手ターンでも発動できる。\n\n●相手フィールドにセットされた魔法・罠カード１枚を対象として発動できる。\nこのカードがモンスターゾーンに存在する限り、そのカードは発動できない。\n\n●フィールドの植物族モンスター１体を対象として発動できる。\nその植物族モンスターを裏側守備表示にする。\n\n●フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力は３００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"No.88 ギミック・パペット－デステニー・レオ",h:"なんばーず８８ ぎみっく・ぱぺっと－ですてにー・れお",e:"Number 88: Gimmick Puppet of Leo",ra:"UR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:3200,de:2300,tx:"レベル８モンスター×３\nこのカードにデステニーカウンターが３つ置かれた時、自分はデュエルに勝利する。\n①：１ターンに１度、自分の魔法＆罠ゾーンにカードが存在しない場合に発動できる。\nこのカードのX素材を１つ取り除き、このカードにデステニーカウンターを１つ置く。\nこの効果を発動するターン、自分はバトルフェイズを行えない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"No.9 天蓋星ダイソン・スフィア",h:"なんばーず９ てんがいせいだいそん・すふぃあ",e:"Number 9: Dyson Sphere",ra:"UR",t:"monster",a:"光",l:9,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2800,de:3000,tx:"レベル９モンスター×２\n①：このカードより高い攻撃力を持つモンスターが相手フィールドに存在する場合、自分メインフェイズ１にこのカードのX素材を１つ取り除いて発動できる。\nこのターン、このカードは直接攻撃できる。\n②：X素材を持っているこのカードが攻撃されたバトルステップに１度、発動できる。\nその攻撃を無効にする。\n③：このカードがX素材の無い状態で攻撃対象に選択された時、自分の墓地のモンスター２体を対象として発動できる。\nそのモンスターをこのカードの下に重ねてX素材とする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"No.91 サンダー・スパーク・ドラゴン",h:"なんばーず９１ さんだー・すぱーく・どらごん",e:"Number 91: Thunder Spark Dragon",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2400,de:2000,tx:"レベル４モンスター×３\n①：１ターンに１度、このカードのX素材を以下の数だけ取り除き、その効果を発動できる。\n\n●３つ：このカード以外のフィールドの表側表示モンスターを全て破壊する。\n\n●５つ：相手フィールドのカードを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"No.98 絶望皇ホープレス",h:"なんばーず９８ ぜつぼうおうほーぷれす",e:"Number 98: Antitopian",ra:"UR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2000,de:2500,tx:"レベル４モンスター×２\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：自分または相手のモンスターの攻撃宣言時、このカードのX素材を１つ取り除いて発動できる。\nそのモンスターを守備表示にする。\n②：このカードが墓地に存在する場合、フィールドの「希望皇ホープ」モンスター１体を対象として発動できる。\nこのカードを守備表示で特殊召喚し、対象のモンスターを下に重ねてX素材とする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ニードル・ガンナー",h:"にーどる・がんなー",e:"Needle Soldier",ra:"N",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"このカードをシンクロ素材としたシンクロモンスターが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ニードル・ギルマン",h:"にーどる・ぎるまん",e:"Spined Gillman",ra:"R",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:1300,de:0,tx:"このカードがフィールド上に表側表示で存在する限り、自分フィールド上の魚族・海竜族・水族モンスターの攻撃力は４００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ニードル・ボール",h:"にーどる・ぼーる",e:"Needle Ball",ra:"N",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["リバース","効果"],ma:"",tp:"",at:750,de:700,tx:"リバース：２０００ライフポイントを払えば、相手ライフに１０００ポイントダメージを与える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ニードルバンカー",h:"にーどるばんかー",e:"Needle Burrower",ra:"N",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1700,de:1700,tx:"このカードが戦闘によってモンスターを破壊し墓地へ送った時、破壊したモンスターのレベル×５００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"二角獣レーム",h:"にかくじゅうれーむ",e:"Bicorn Re'em",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:800,de:1600,tx:"このカードがシンクロ召喚の素材として墓地へ送られた場合、相手のデッキの上からカードを２枚墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"逃げまどう民",h:"にげまどうたみ",e:"People Running About",ra:"N",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"炎族",mo:["通常"],ma:"",tp:"",at:600,de:600,tx:"いつも苦しみに耐えているが、いつか必ず革命を起こすと心に誓っている。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"二頭を持つキング・レックス",h:"にとうをもつきんぐ・れっくす",e:"Two-Headed King Rex",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"恐竜族",mo:["通常"],ma:"",tp:"",at:1600,de:1200,tx:"恐竜族の中では強力なカード。\n２つの頭で同時攻撃。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ニトロ・ウォリアー",h:"にとろ・うぉりあー",e:"Nitro Warrior",ra:"SR",t:"monster",a:"炎",l:7,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2800,de:1800,tx:"「ニトロ・シンクロン」＋チューナー以外のモンスター１体以上\nこのカードがフィールド上に表側表示で存在する限り、自分のターンに自分が魔法カードを発動した場合、このカードの攻撃力はそのターンのダメージ計算時のみ１度だけ１０００ポイントアップする。\nまた、このカードの攻撃によって相手モンスターを破壊したダメージ計算後に発動できる。\n相手フィールド上に表側守備表示で存在するモンスター１体を選択して攻撃表示にし、そのモンスターにもう１度だけ続けて攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ニトロ・シンクロン",h:"にとろ・しんくろん",e:"Nitro Synchron",ra:"N",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:300,de:100,tx:"このカードが「ニトロ」と名のついたシンクロモンスターのシンクロ召喚に使用され墓地へ送られた場合、自分のデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ニュート",h:"にゅーと",e:"Slate Warrior",ra:"UR",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"悪魔族",mo:["リバース","効果"],ma:"",tp:"",at:1900,de:400,tx:"①：このカードがリバースした場合に発動する。\nこのカードの攻撃力・守備力は５００アップする。\n②：このカードがモンスターとの戦闘で破壊された場合に発動する。\nそのモンスターの攻撃力・守備力を５００ダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ニュードリュア",h:"にゅーどりゅあ",e:"Newdoria",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1200,de:800,tx:"このカードが戦闘によって破壊され墓地へ送られた時、フィールド上に存在するモンスター１体を破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"忍犬ワンダードッグ",h:"にんけんわんだーどっぐ",e:"Nin-Ken Dog",ra:"N",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["通常"],ma:"",tp:"",at:1800,de:1000,tx:"忍術を極めた犬忍者。\n厳しい修行により、擬人化の忍術を使う事が可能となった。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"忍者義賊ゴエゴエ",h:"にんじゃぎぞくごえごえ",e:"Goe Goe the Gallant Ninja",ra:"R",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"相手の手札が５枚以上の場合、このカードが相手ライフに戦闘ダメージを与えた時、相手の手札をランダムに２枚捨てる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"忍者マスター SASUKE",h:"にんじゃますたー　さすけ",e:"Ninja Grandmaster Sasuke",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"このカードが表側守備表示のモンスターを攻撃した場合、ダメージ計算を行わずそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"忍者マスター HANZO",h:"にんじゃますたー　はんぞー",e:"Ninja Grandmaster Hanzo",ra:"UR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"①：このカードが召喚に成功した時に発動できる。\nデッキから「忍法」カード１枚を手札に加える。\n②：このカードが反転召喚・特殊召喚に成功した時に発動できる。\nデッキから「忍者マスター HANZO」以外の「忍者」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"にん人",h:"にんじん",e:"World Carrotweight Champion",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1900,de:0,tx:"「にん人」の効果は１ターンに１度しか使用できない。\n①：このカードが墓地に存在する場合、手札及び自分フィールドの表側表示モンスターの中から、「にん人」以外の植物族モンスター１体を墓地へ送って発動できる。\nこのカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヌビアガード",h:"ぬびあがーど",e:"Nubian Guard",ra:"N",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:500,de:500,tx:"このカードが相手プレイヤーに戦闘ダメージを与えた場合、自分の墓地の永続魔法カード１枚をデッキの一番上に戻す事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"沼地のドロゴン",h:"ぬまちのどろごん",e:"Mudragon of the Swamp",ra:"SR",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"幻竜族",mo:["融合","効果"],ma:"",tp:"",at:1900,de:1600,tx:"同じ属性で種族が異なるモンスター×２\n①：このカードがモンスターゾーンに存在する限り、相手はこのカード及びこのカードと同じ属性を持つフィールドのモンスターを効果の対象にできない。\n②：１ターンに１度、属性を１つ宣言して発動できる。\nこのカードはターン終了時まで宣言した属性になる。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"沼地の魔獣王",h:"ぬまちのまじゅうおう",e:"Beastking of the Swamps",ra:"R",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1000,de:1100,tx:"このカードを融合素材モンスター１体の代わりにする事ができる。\nその際、他の融合素材モンスターは正規のものでなければならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"沼地の魔神王",h:"ぬまちのましんおう",e:"King of the Swamp",ra:"UR",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:500,de:1100,tx:"①：このカードは、融合モンスターカードにカード名が記された融合素材モンスター１体の代わりにできる。\nその際、他の融合素材モンスターは正規のものでなければならない。\n②：自分メインフェイズにこのカードを手札から墓地へ捨てて発動できる。\nデッキから「融合」１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ネイビィロイド",h:"ねいびぃろいど",e:"Carrierroid",ra:"R",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:800,tx:"相手が自分フィールド上の魔法・罠カードを対象に「フィールド上の魔法・罠カードを破壊する」効果を持つカードを発動した時、手札を１枚捨てる事でそのカードの発動と効果を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"超銀河眼の光子龍",h:"ねお・ぎゃらくしーあいず・ふぉとん・どらごん",e:"Neo Galaxy-Eyes Photon Dragon",ra:"UR",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:4500,de:3000,tx:"レベル８モンスター×３\n「銀河眼の光子竜」を素材としてこのカードがエクシーズ召喚に成功した時、このカード以外のフィールド上に表側表示で存在するカードの効果を無効にする。\n１ターンに１度、このカードのエクシーズ素材を１つ取り除いて発動できる。\n相手フィールド上のエクシーズ素材を全て取り除き、このターンこのカードの攻撃力は取り除いた数×５００ポイントアップする。\nさらに、このターンこのカードは取り除いた数まで１度のバトルフェイズ中に攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"真青眼の究極竜",h:"ねお・ぶるーあいず・あるてぃめっとどらごん",e:"Neo Blue-Eyes Ultimate Dragon",ra:"UR",t:"monster",a:"光",l:12,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:4500,de:3800,tx:"「青眼の白龍」＋「青眼の白龍」＋「青眼の白龍」\nこのカード名の①の効果は１ターンに２度まで使用できる。\n①：融合召喚したこのカードが攻撃したダメージステップ終了時、自分フィールドの表側表示のカードがこのカードのみの場合、EXデッキから「ブルーアイズ」融合モンスター１体を墓地へ送って発動できる。\nこのカードは続けて攻撃できる。\n②：自分フィールドの「ブルーアイズ」モンスターを対象とする魔法・罠・モンスターの効果が発動した時、墓地のこのカードを除外して発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ネオアクア・マドール",h:"ねおあくあ・まどーる",e:"Neo Aqua Madoor",ra:"R",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:1200,de:3000,tx:"水を支配する魔法使いの真の姿。\n絶対に破る事のできないと言われる巨大な氷の壁をつくり攻撃を防ぐ。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ネオス・ワイズマン",h:"ねおす・わいずまん",e:"Neos Wiseman",ra:"UR",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"魔法使い族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3000,de:3000,tx:"このカードは通常召喚できない。\n自分のモンスターゾーンの表側表示の、「E・HERO ネオス」と「ユベル」を１体ずつ墓地へ送った場合のみ特殊召喚できる。\n①：フィールドのこのカードは効果では破壊されない。\n②：このカードが相手モンスターと戦闘を行ったダメージステップ終了時に発動する。\nその相手モンスターの攻撃力分のダメージを相手に与える。\nその相手モンスターの守備力分だけ自分のLPを回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"N・アクア・ドルフィン",h:"ねおすぺーしあん・あくあ・どるふぃん",e:"Neo-Spacian Aqua Dolphin",ra:"R",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:600,de:800,tx:"①：１ターンに１度、手札を１枚捨てて発動できる。\n相手の手札を確認し、その中からモンスター１体を選ぶ。\n選んだモンスターの攻撃力以上の攻撃力を持つモンスターが自分フィールドに存在する場合、選んだモンスターを破壊し、相手に５００ダメージを与える。\n存在しない場合、自分は５００ダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"N・エア・ハミングバード",h:"ねおすぺーしあん・えあ・はみんぐばーど",e:"Neo-Spacian Air Hummingbird",ra:"N",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:800,de:600,tx:"１ターンに１度、自分のメインフェイズ時に発動できる。\n自分は相手の手札の数×５００ライフポイント回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"N・グラン・モール",h:"ねおすぺーしあん・ぐらん・もーる",e:"Neo-Spacian Grand Mole",ra:"SR",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:900,de:300,tx:"①：このカードが相手モンスターと戦闘を行うダメージステップ開始時に発動できる。\nその相手モンスターとこのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"N・グロー・モス",h:"ねおすぺーしあん・ぐろー・もす",e:"Neo-Spacian Glow Moss",ra:"N",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:300,de:900,tx:"このカードが戦闘を行う場合、相手はカードを１枚ドローする。\nこの効果でドローしたカードをお互いに確認し、そのカードの種類によりこのカードは以下の効果を得る。\n●モンスターカード：このターンのバトルフェイズを終了させる。\n●魔法カード：このカードは相手プレイヤーに直接攻撃する事ができる。\n●罠カード：このカードは守備表示になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"N・ティンクル・モス",h:"ねおすぺーしあん・てぃんくる・もす",e:"Neo-Spacian Twinkle Moss",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"植物族",mo:["融合","効果"],ma:"",tp:"",at:500,de:1100,tx:"このカード名はルール上「N・グロー・モス」としても扱う。\nこのカードは「NEX」の効果でのみ特殊召喚できる。\nこのカードが戦闘を行う場合、自分はカードを１枚ドローする。\nこの効果でドローしたカードをお互いに確認し、そのカードの種類によりこのカードは以下の効果を得る。\n●モンスターカード：このターンのバトルフェイズを終了させる。\n●魔法カード：このカードは相手プレイヤーに直接攻撃する事ができる。\n●罠カード：このカードは守備表示になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"N・ブラック・パンサー",h:"ねおすぺーしあん・ぶらっく・ぱんさー",e:"Neo-Spacian Dark Panther",ra:"R",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1000,de:500,tx:"①：１ターンに１度、相手フィールドの表側表示モンスター１体を対象として発動できる。\nエンドフェイズまで、このカードは、そのモンスターの元々のカード名・効果と同じカード名・効果を得る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"N・フレア・スカラベ",h:"ねおすぺーしあん・ふれあ・すからべ",e:"Neo-Spacian Flare Scarab",ra:"N",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:500,de:500,tx:"このカードの攻撃力は、相手フィールド上の魔法・罠カードの数×４００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"N・マリン・ドルフィン",h:"ねおすぺーしあん・まりん・どるふぃん",e:"Neo-Spacian Marine Dolphin",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:900,de:1100,tx:"このカード名はルール上「N・アクア・ドルフィン」としても扱う。\nこのカードは「NEX」の効果でのみ特殊召喚できる。\n手札を１枚捨てる。\n相手の手札を確認してモンスターカード１枚を選択する。\n選択したモンスターの攻撃力以上のモンスターが自分フィールド上に存在する場合、選択したモンスターカードを破壊して相手ライフに５００ポイントダメージを与える。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ネオスペース・コネクター",h:"ねおすぺーす・こねくたー",e:"Neo Space Connector",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:800,de:1800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時に発動できる。\n手札・デッキから「N」モンスターまたは「E・HERO ネオス」１体を守備表示で特殊召喚する。\n②：このカードをリリースし、自分の墓地の、「N」モンスターまたは「E・HERO ネオス」１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ネオスペース・コンダクター",h:"ねおすぺーす・こんだくたー",e:"Neo Space Pathfinder",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:800,tx:"このカードを手札から墓地へ捨てて発動できる。\n自分のデッキ・墓地から「ネオスペース」１枚を選んで手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ネオバグ",h:"ねおばぐ",e:"Neo Bug",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["通常"],ma:"",tp:"",at:1800,de:1700,tx:"異星から来たと言われる巨大な昆虫タイプのモンスター。\n集団で行動してターゲットをとらえる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ネオフレムベル・オリジン",h:"ねおふれむべる・おりじん",e:"Neo Flamvell Origin",ra:"R",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"炎族",mo:["チューナー","効果"],ma:"",tp:"",at:500,de:200,tx:"自分フィールド上に「ネオフレムベル・オリジン」以外の「フレムベル」と名のついたモンスターが存在し、相手の墓地のカードが３枚以下の場合、このカードは手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ネオフレムベル・ガルーダ",h:"ねおふれむべる・がるーだ",e:"Neo Flamvell Garuda",ra:"N",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:1200,de:1800,tx:"自分のエンドフェイズ時、自分フィールド上にこのカード以外の「フレムベル」と名のついたモンスターが存在する場合、相手の墓地のカード１枚を選択してゲームから除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ネオフレムベル・サーベル",h:"ねおふれむべる・さーべる",e:"Neo Flamvell Sabre",ra:"UR",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:1500,de:200,tx:"相手の墓地のカードが４枚以下の場合、このカードの攻撃力は６００ポイントアップする。\n相手の墓地のカードが８枚以上の場合、このカードの攻撃力は３００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ネオフレムベル・シャーマン",h:"ねおふれむべる・しゃーまん",e:"Neo Flamvell Shaman",ra:"N",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:1700,de:200,tx:"自分の墓地に「フレムベル」と名のついたモンスターが３体以上存在し、このカードが戦闘によって相手モンスターを破壊した場合、相手の墓地のカード１枚を選択してゲームから除外する。\nこの効果の発動時に相手の墓地に魔法カードが存在しない場合、さらに相手ライフに５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ネオフレムベル・ヘッジホッグ",h:"ねおふれむべる・へっじほっぐ",e:"Neo Flamvell Hedgehog",ra:"N",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"炎族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:200,tx:"このカードが戦闘によって破壊された場合、相手の墓地のカード１枚を選択してゲームから除外する。\nフィールド上のこのカードがカードの効果によって破壊された場合、自分の墓地から「ネオフレムベル・ヘッジホッグ」以外の守備力２００以下の炎属性モンスター１体を選択して手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ネクロ・ディフェンダー",h:"ねくろ・でぃふぇんだー",e:"Necro Defender",ra:"N",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:800,tx:"自分のメインフェイズ時に、墓地に存在するこのカードをゲームから除外し、自分フィールド上に存在するモンスター１体を選択して発動する事ができる。\n次の相手のエンドフェイズ時まで、選択したモンスターは戦闘では破壊されず、選択したモンスターの戦闘によって発生する自分への戦闘ダメージは０になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ネクロ・リンカー",h:"ねくろ・りんかー",e:"Necro Linker",ra:"N",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:600,de:0,tx:"このカードをリリースし、自分の墓地に存在する「シンクロン」と名のついたチューナー１体を選択して発動する。\n選択したモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターは、このターンシンクロ素材とする事はできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"影霊衣の術士 シュリット",h:"ねくろすのじゅつし　しゅりっと",e:"Shurit, Strategist of the Nekroz",ra:"SR",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:300,de:1800,tx:"「影霊衣の術士 シュリット」の②の効果は１ターンに１度しか使用できない。\n①：「影霊衣」儀式モンスター１体を儀式召喚する場合、このカード１枚で儀式召喚に必要なレベル分のリリースとして使用できる。\n②：このカードが効果でリリースされた場合に発動できる。\nデッキから戦士族の「影霊衣」儀式モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"影霊衣の戦士 エグザ",h:"ねくろすのせんし　えぐざ",e:"Exa, Enforcer of the Nekroz",ra:"N",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2000,de:1000,tx:"「影霊衣の戦士 エグザ」の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードが効果でリリースされた場合に発動できる。\nデッキからドラゴン族の「影霊衣」儀式モンスター１体を手札に加える。\n②：このカードが除外された場合、このカード以外の除外されている自分の「影霊衣」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"影霊衣の大魔道士",h:"ねくろすのだいまどうし",e:"Great Sorcerer of the Nekroz",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1500,de:800,tx:"「影霊衣の大魔道士」の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードが効果でリリースされた場合に発動できる。\nデッキから魔法使い族の「影霊衣」儀式モンスター１体を手札に加える。\n②：このカードが除外された場合に発動できる。\nデッキから「影霊衣の大魔道士」以外の「影霊衣」モンスター１体を墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"影霊衣の舞姫",h:"ねくろすのまいひめ",e:"Dance Princess of the Nekroz",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1600,de:800,tx:"「影霊衣の舞姫」の②の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、「影霊衣」儀式魔法カードの発動に対して相手は魔法・罠・モンスターの効果を発動できず、自分フィールドの「影霊衣」儀式モンスターは相手の効果の対象にならない。\n②：このカードが効果でリリースされた場合、「影霊衣の舞姫」以外の除外されている自分の「影霊衣」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"影霊衣の巫女 エリアル",h:"ねくろすのみこ　えりある",e:"Ariel, Priestess of the Nekroz",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1000,de:1800,tx:"「影霊衣の巫女 エリアル」の②の効果は１ターンに１度しか使用できない。\n①：１ターンに１度、手札の「影霊衣」カードを任意の数だけ相手に見せて発動できる。\nターン終了時まで、見せたカードの数だけこのカードのレベルを上げる、または下げる。\n②：このカードが効果でリリースされた場合に発動できる。\nデッキから儀式モンスター以外の「影霊衣」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ネコマネキング",h:"ねこまねきんぐ",e:"Neko Mane King",ra:"N",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"相手ターン中にこのカードが相手の魔法・罠・モンスターの効果によって墓地に送られた時、相手ターンを終了する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ネコ耳族",h:"ねこみみぞく",e:"Cat's Ear Tribe",ra:"R",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:200,de:100,tx:"相手ターン時のみ、このカードと戦闘する相手モンスターの元々の攻撃力は、ダメージステップ時に２００になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"首狩り魔人",h:"ねっく・はんたー",e:"Neck Hunter",ra:"N",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1750,de:1900,tx:"大きなカマを振り回しクビを狩る悪魔。\n大きな目からビームも出す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"熱血獣士ウルフバーク",h:"ねっけつじゅうしうるふばーく",e:"Coach Soldier Wolfbark",ra:"UR",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"「熱血獣士ウルフバーク」の効果は１ターンに１度しか使用できない。\n①：自分の墓地の獣戦士族・炎属性・レベル４モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ネフティスの祈り手",h:"ねふてぃすのいのりて",e:"Disciple of Nephthys",ra:"R",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:0,de:2000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\n手札を１枚選んで破壊し、デッキから「ネフティスの祈り手」以外の「ネフティス」モンスター１体を手札に加える。\n②：このカードが効果で破壊され墓地へ送られた場合、次の自分スタンバイフェイズに発動できる。\nデッキから「ネフティス」魔法・罠カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ネフティスの語り手",h:"ねふてぃすのかたりて",e:"Chronicler of Nephthys",ra:"N",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:300,de:1200,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：「ネフティスの語り手」以外の自分の墓地の「ネフティス」カード１枚を対象として発動できる。\n手札を１枚選んで破壊し、対象のカードを手札に加える。\n②：このカードが効果で破壊され墓地へ送られた場合、次の自分スタンバイフェイズに発動できる。\n自分の墓地から「ネフティスの語り手」以外の「ネフティス」カード１枚を選んで手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ネフティスの悟り手",h:"ねふてぃすのさとりて",e:"Matriarch of Nephthys",ra:"N",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:600,de:600,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：「ネフティスの悟り手」以外の自分の墓地のレベル４以下の「ネフティス」モンスター１体を対象として発動できる。\n手札を１枚選んで破壊し、対象のモンスターを守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。\n②：このカードが効果で破壊され墓地へ送られた場合、次の自分スタンバイフェイズに発動できる。\nこのカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ネフティスの蒼凰神",h:"ねふてぃすのそうおうしん",e:"Cerulean Sacred Phoenix of Nephthys",ra:"UR",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"鳥獣族",mo:["儀式","効果"],ma:"",tp:"",at:3000,de:1000,tx:"「ネフティスの輪廻」により降臨。\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\n手札及び自分フィールドの表側表示のカードの中から、「ネフティス」カードを任意の数だけ選んで破壊する。\nその後、破壊した数だけ相手フィールドのモンスターを選んで破壊する。\n②：このカードが戦闘・効果で破壊され墓地へ送られた場合、次の自分スタンバイフェイズに発動できる。\nこのカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ネフティスの鳳凰神",h:"ねふてぃすのほうおうしん",e:"Sacred Phoenix of Nephthys",ra:"UR",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2400,de:1600,tx:"①：このカードが効果で破壊され墓地へ送られた場合、次の自分スタンバイフェイズに発動する。\nこのカードを墓地から特殊召喚する。\n②：このカードの①の効果で特殊召喚に成功した場合に発動する。\nフィールドの魔法・罠カードを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ネフティスの祀り手",h:"ねふてぃすのまつりて",e:"Devotee of Nephthys",ra:"SR",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["儀式","効果"],ma:"",tp:"",at:1200,de:1200,tx:"「ネフティスの輪廻」により降臨。\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが儀式召喚に成功した場合に発動できる。\nデッキから「ネフティス」モンスター１体を特殊召喚する。\nこの効果を発動したターンのエンドフェイズにこのカードは破壊される。\n②：このカードが墓地に存在する場合に発動できる。\n手札の「ネフティス」カード１枚を選んで破壊し、このカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ネフティスの護り手",h:"ねふてぃすのまもりて",e:"Defender of Nephthys",ra:"R",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1400,de:200,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\n手札を１枚選んで破壊し、手札から「ネフティスの護り手」以外のレベル４以下の「ネフティス」モンスター１体を特殊召喚する。\n②：このカードが効果で破壊され墓地へ送られた場合、次の自分スタンバイフェイズに発動できる。\nデッキから「ネフティスの護り手」以外の「ネフティス」モンスター１体を選んで破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ネフティスの導き手",h:"ねふてぃすのみちびきて",e:"Hand of Nephthys",ra:"R",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:600,de:600,tx:"①：自分フィールドのモンスター１体とこのカードをリリースして発動できる。\n手札・デッキから「ネフティスの鳳凰神」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"眠り子",h:"ねむりこ",e:"Nemuriko",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:800,de:700,tx:"子供だが睡魔をあやつり二度と覚めることのない眠りを誘う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"眠れる巨人ズシン",h:"ねむれるきょじんずしん",e:"Zushin the Sleeping Giant",ra:"N",t:"monster",a:"地",l:10,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:0,de:0,tx:"このカードは通常召喚できない。\nズシンカウンターが１０個置かれた自分のモンスター１体をリリースした場合のみ特殊召喚できる。\n①：１ターンに１度、手札のこのカードをターン終了時まで相手に見せ、自分フィールドのレベル１通常モンスター１体を対象として発動できる。\nそのモンスターにズシンカウンターを１つ置く。\n②：このカードは他のカードの効果を受けない。\n③：このカードがモンスターと戦闘を行うダメージ計算時に発動する。\nこのカードの攻撃力・守備力はダメージ計算時のみ、そのモンスターの攻撃力＋１０００の数値になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"眠れる獅子",h:"ねむれるしし",e:"Sleeping Lion",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:700,de:1700,tx:"普段眠っている猛獣。\n目をさますと手がつけられない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"燃料電池メン",h:"ねんりょうでんちめん",e:"Batteryman Fuel Cell",ra:"R",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:2100,de:0,tx:"自分フィールド上に「電池メン」と名のついたモンスターが２体以上存在する場合、このカードは手札から特殊召喚できる。\n１ターンに１度、このカード以外の自分フィールド上の「電池メン」と名のついたモンスター１体をリリースして発動できる。\n相手フィールド上のカード１枚を選択して持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ノーブル・ド・ノワール",h:"のーぶる・ど・のわーる",e:"Patrician of Darkness",ra:"SR",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:2000,de:1400,tx:"このカードがフィールド上に表側表示で存在する限り、相手モンスターの攻撃対象はこのカードのコントローラーが選択する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"不死王リッチー",h:"のすふぇらとぅりっちー",e:"Fushioh Richie",ra:"R",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"アンデット族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2600,de:2900,tx:"このカードは通常召喚できない。\n「大神官デ・ザード」の効果で特殊召喚する事ができる。\nこのカードは１ターンに１度だけ裏側守備表示にする事ができる。\nこのカードがリバースした時、自分の墓地に存在するアンデット族モンスター１体を選択して特殊召喚する事ができる。\nまた、フィールド上に表側表示で存在するこのカードを対象とする魔法・罠カードが発動した時、その発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"後に亀と呼ばれる神",h:"のちにかめとよばれるかみ",e:"Numen erat Testudo",ra:"R",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1800,de:0,tx:"このカードがフィールド上に表側表示で存在する限り、お互いに攻撃力１８００以下のモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"呪われし魔剣",h:"のろわれしまけん",e:"Fiend Sword",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1400,de:800,tx:"身につけ、呪いに打ち勝つことができた者は力を得られると言う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ハ・デスの使い魔",h:"は・ですのつかいま",e:"Winged Minion",ra:"R",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:700,de:700,tx:"このカードを生け贄に捧げる。\nフィールド上に表側表示で存在する悪魔族モンスター１体を選択する。\nそのモンスターは表側表示でフィールド上に存在する限り、攻撃力と守備力が７００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バーサーカー",h:"ばーさーかー",e:"Mystic Clown",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1500,de:1000,tx:"狂った力を使い攻撃する。\nその暴走は誰にも止められない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バーサーク・デッド・ドラゴン",h:"ばーさーく・でっど・どらごん",e:"Berserk Dragon",ra:"UR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"アンデット族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3500,de:0,tx:"このカードは「デーモンとの駆け引き」の効果でのみ特殊召喚が可能。\n相手フィールド上の全てのモンスターに１回ずつ攻撃が可能。\n自分のターンのエンドフェイズ毎にこのカードの攻撃力は５００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"怒れる類人猿",h:"ばーさーくごりら",e:"Berserk Gorilla",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:2000,de:1000,tx:"このカードが表側守備表示でフィールド上に存在する場合、このカードを破壊する。\nこのカードのコントローラーは、このカードが攻撃可能な状態であれば必ず攻撃しなければならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バード・フェイス",h:"ばーど・ふぇいす",e:"Birdface",ra:"SR",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1600,de:1600,tx:"このカードが戦闘によって破壊され墓地へ送られた時、自分のデッキから「ハーピィ・レディ」１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ハードアーマー",h:"はーどあーまー",e:"Hard Armor",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:300,de:1200,tx:"心のある鎧。\n堅い体でソルジャータックルをしかけてくる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ハードアームドラゴン",h:"はーどあーむどらごん",e:"Hardened Armed Dragon",ra:"UR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1500,de:800,tx:"①：このカードは手札のレベル８以上のモンスター１体を墓地へ送り、手札から特殊召喚できる。\n②：このカードをリリースして召喚したレベル７以上のモンスターは効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バードマン",h:"ばーどまん",e:"Sky Scout",ra:"N",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["通常"],ma:"",tp:"",at:1800,de:600,tx:"マッハ５で飛行する鳥人。\nその眼光は鷹より鋭い。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バーニング・スカルヘッド",h:"ばーにんぐ・すかるへっど",e:"Burning Skull Head",ra:"SR",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1000,de:800,tx:"このカードが手札から特殊召喚に成功した時、相手ライフに１０００ポイントダメージを与える。\nまた、自分フィールド上に表側表示で存在するこのカードをゲームから除外する事で、ゲームから除外されている「スカル・フレイム」１体を墓地に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バーニングソルジャー",h:"ばーにんぐそるじゃー",e:"Darkfire Soldier #1",ra:"R",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["通常"],ma:"",tp:"",at:1700,de:1150,tx:"熱く燃える特殊部隊工作員。\n火薬のエキスパート。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"BK カウンターブロー",h:"ばーにんぐなっくらー　かうんたーぶろー",e:"Battlin' Boxer Counterpunch",ra:"R",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:0,de:1100,tx:"自分フィールド上の「BK」と名のついたモンスターが戦闘を行うダメージステップ時に手札または墓地のこのカードをゲームから除外して発動できる。\nそのモンスターの攻撃力はエンドフェイズ時まで１０００ポイントアップする。\n「BK カウンターブロー」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"BK グラスジョー",h:"ばーにんぐなっくらー　ぐらすじょー",e:"Battlin' Boxer Glassjaw",ra:"N",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2000,de:0,tx:"このカードが攻撃対象に選択された時、このカードを破壊する。\nこのカードがカードの効果によって墓地へ送られた時、自分の墓地から「BK グラスジョー」以外の「BK」と名のついたモンスター１体を選択して手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"BK シャドー",h:"ばーにんぐなっくらー　しゃどー",e:"Battlin' Boxer Shadow",ra:"N",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1400,tx:"自分のメインフェイズ時に発動できる。\n自分フィールド上の「BK」と名のついたエクシーズモンスターのエクシーズ素材を１つ取り除き、このカードを手札から特殊召喚する。\n「BK シャドー」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"BK スイッチヒッター",h:"ばーにんぐなっくらー　すいっちひったー",e:"Battlin' Boxer Switchitter",ra:"R",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:1400,tx:"このカードが召喚に成功した時、自分の墓地の「BK」と名のついたモンスター１体を選択して特殊召喚できる。\nこの効果を発動するターン、自分は「BK」と名のついたモンスター以外のモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"BK スパー",h:"ばーにんぐなっくらー　すぱー",e:"Battlin' Boxer Sparrer",ra:"R",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:1400,tx:"自分フィールド上に「BK」と名のついたモンスターが存在する場合、このカードは手札から特殊召喚できる。\nこの方法で特殊召喚した場合、このターン自分はバトルフェイズを行えない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"BK チート・コミッショナー",h:"ばーにんぐなっくらー　ちーと・こみっしょなー",e:"Battlin' Boxer Cheat Commissioner",ra:"R",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:0,de:1300,tx:"レベル３モンスター×２\nこのカードがフィールド上に表側表示で存在する限り、攻撃可能な相手モンスターは攻撃しなければならない。\nまた、自分フィールド上にこのカード以外の「BK」と名のついたモンスターが存在する場合、相手はこのカードを攻撃対象にできない。\nこのカード以外の自分の「BK」と名のついたモンスターが戦闘を行う攻撃宣言時、このカードのエクシーズ素材を２つ取り除いて発動できる。\n相手の手札を確認し、その中から魔法カード１枚を選んで自分フィールド上にセットする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"BK ビッグバンテージ",h:"ばーにんぐなっくらー　びっぐばんてーじ",e:"Battlin' Boxer Big Bandage",ra:"N",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1100,de:1400,tx:"このカードは１ターンに１度だけ戦闘では破壊されない。\nまた、１ターンに１度、自分の墓地の「BK」と名のついたモンスター１体または除外されている自分の「BK」と名のついたモンスター１体を選択して発動できる。\n自分フィールド上の全ての「BK」と名のついたモンスターは選択したモンスターと同じレベルになる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"BK ベイル",h:"ばーにんぐなっくらー　べいる",e:"Battlin' Boxer Veil",ra:"R",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:0,de:1800,tx:"自分が戦闘ダメージを受けた時に発動できる。\nこのカードを手札から特殊召喚し、受けたダメージの数値分だけ自分のライフポイントを回復する。",li:3,lk:[],ta:[],al:[],ocg:true},
  {n:"BK ヘッドギア",h:"ばーにんぐなっくらー　へっどぎあ",e:"Battlin' Boxer Headgeared",ra:"SR",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:1800,tx:"このカードが召喚に成功した時、デッキから「BK」と名のついたモンスター１体を墓地へ送る事ができる。\nフィールド上に表側攻撃表示で存在するこのカードは、１ターンに１度だけ戦闘では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"BK ラビット・パンチャー",h:"ばーにんぐなっくらー　らびっと・ぱんちゃー",e:"Battlin' Boxer Rabbit Puncher",ra:"R",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:800,de:1000,tx:"このカードが守備表示モンスターを攻撃した場合、ダメージ計算を行わずそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"BK リベージ・ガードナー",h:"ばーにんぐなっくらー　りべーじ・がーどなー",e:"Battlin' Boxer Rib Gardna",ra:"N",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:100,de:1400,tx:"手札または墓地のこのカードをゲームから除外し、自分フィールド上の「BK」と名のついたモンスター１体を選択して発動できる。\n選択したモンスターを次の自分のスタンバイフェイズ時までゲームから除外する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"火炎鳥",h:"ばーにんぐばーど",e:"Firebird",ra:"N",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1000,de:800,tx:"このカードがフィールド上に表側表示で存在する限り、自分フィールド上の鳥獣族モンスターが破壊される度にこのカードの攻撃力は５００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バーバリアン・キング",h:"ばーばりあん・きんぐ",e:"Battleguard King",ra:"R",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:3000,de:1100,tx:"①：１ターンに１度、このカード以外の自分フィールドの戦士族モンスターを任意の数だけリリースして発動できる。\nこのターン、このカードは通常の攻撃に加えて、この効果を発動するためにリリースしたモンスターの数まで１度のバトルフェイズ中に攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バーバリアン1号",h:"ばーばりあん１ごう",e:"Lava Battleguard",ra:"N",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1550,de:1800,tx:"①：自分フィールドの「バーバリアン２号」１体につき、このカードの攻撃力は５００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バーバリアン2号",h:"ばーばりあん２ごう",e:"Swamp Battleguard",ra:"N",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1500,tx:"①：自分フィールドの「バーバリアン１号」１体につき、このカードの攻撃力は５００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ハーピィ・オラクル",h:"はーぴぃ・おらくる",e:"Harpie Oracle",ra:"UR",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1300,de:1400,tx:"このカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードのカード名は、フィールド・墓地に存在する限り「ハーピィ・レディ」として扱う。\n②：自分フィールドにレベル５以上の「ハーピィ」モンスターが存在する場合に発動できる。\nこのカードを手札から特殊召喚する。\n③：このカードが召喚・特殊召喚に成功した場合に発動できる。\nこのターンのエンドフェイズに、自分の墓地から「ハーピィ・レディ三姉妹」のカード名が記された魔法・罠カード１枚を選んで手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ハーピィ・ガール",h:"はーぴぃ・がーる",e:"Harpie Girl",ra:"N",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"鳥獣族",mo:["通常"],ma:"",tp:"",at:500,de:500,tx:"美しく華麗に舞い、鋭く攻撃する事ができるようになりたいと願っているハーピィの女の子。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ハーピィ・クイーン",h:"はーぴぃ・くぃーん",e:"Harpie Queen",ra:"SR",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1900,de:1200,tx:"①：このカードのカード名は、フィールド・墓地に存在する限り「ハーピィ・レディ」として扱う。\n②：このカードを手札から墓地へ捨てて発動できる。\nデッキから「ハーピィの狩場」１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ハーピィ・ダンサー",h:"はーぴぃ・だんさー",e:"Harpie Dancer",ra:"SR",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1200,de:1000,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードのカード名は、フィールド・墓地に存在する限り「ハーピィ・レディ」として扱う。\n②：自分フィールドの風属性モンスター１体を対象として発動できる。\nそのモンスターを持ち主の手札に戻す。\nその後、風属性モンスター１体を召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ハーピィ・チャネラー",h:"はーぴぃ・ちゃねらー",e:"Harpie Channeler",ra:"SR",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1400,de:1300,tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードのカード名は、フィールド・墓地に存在する限り「ハーピィ・レディ」として扱う。\n②：自分フィールドにドラゴン族モンスターが存在する場合、このカードのレベルは７になる。\n③：手札から「ハーピィ」カード１枚を捨てて発動できる。\nデッキから「ハーピィ・チャネラー」以外の「ハーピィ」モンスター１体を守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ハーピィ・ハーピスト",h:"はーぴぃ・はーぴすと",e:"Harpie Harpist",ra:"SR",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1700,de:600,tx:"このカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードのカード名は、フィールド・墓地に存在する限り「ハーピィ・レディ」として扱う。\n②：このカードが召喚に成功した時、このカード以外の自分フィールドの鳥獣族モンスター１体と相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターを持ち主の手札に戻す。\n③：このカードが墓地へ送られたターンのエンドフェイズに発動できる。\nデッキから攻撃力１５００以下の鳥獣族・レベル４モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ハーピィ・パフューマー",h:"はーぴぃ・ぱふゅーまー",e:"Harpie Perfumer",ra:"SR",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1400,de:1300,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードのカード名は、フィールド・墓地に存在する限り「ハーピィ・レディ」として扱う。\n②：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「ハーピィ・レディ三姉妹」のカード名が記された魔法・罠カード１枚を手札に加える。\n自分フィールドにレベル５以上の「ハーピィ」モンスターが存在する状態で発動した場合にはその枚数は２枚までになる（同名カードは１枚まで）。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ハーピィ・レディ",h:"はーぴぃ・れでぃ",e:"Harpie Lady",ra:"N",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["通常"],ma:"",tp:"",at:1300,de:1400,tx:"人に羽のはえたけもの。\n美しく華麗に舞い、鋭く攻撃する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ハーピィ・レディ・SB",h:"はーぴぃ・れでぃ・さいばー・ぼんてーじ",e:"Cyber Harpie Lady",ra:"R",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1800,de:1300,tx:"このカードのカード名はルール上「ハーピィ・レディ」として扱う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ハーピィ・レディ・SC",h:"はーぴぃ・れでぃ・すくらっち・くらっしゅ",e:"Cyber Slash Harpie Lady",ra:"UR",t:"monster",a:"風",l:8,ps:"",pe:"",tr:"鳥獣族",mo:["シンクロ","効果"],ma:"",tp:"",at:2600,de:1400,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカードをS召喚する場合、自分フィールドの「ハーピィ」モンスター１体をチューナーとして扱う事ができる。\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードのカード名は、フィールド・墓地に存在する限り「ハーピィ・レディ」として扱う。\n②：魔法・罠カードの効果が発動した時、相手フィールドのモンスター１体または自分フィールドの「ハーピィ」モンスター１体を対象として発動できる。\nそのモンスターを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ハーピィ・レディ三姉妹",h:"はーぴぃ・れでぃさんしまい",e:"Harpie Lady Sisters",ra:"UR",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"鳥獣族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1950,de:2100,tx:"このカードは通常召喚できない。\n「万華鏡－華麗なる分身－」の効果で特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ハーピィ・レディ3",h:"はーぴぃ・れでぃすりー",e:"Harpie Lady 3",ra:"R",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1300,de:1400,tx:"このカードのカード名は「ハーピィ・レディ」として扱う。\nこのカードと戦闘を行った相手モンスターは、相手ターンで数えて２ターンの間攻撃宣言ができなくなる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ハーピィ・レディ2",h:"はーぴぃ・れでぃつー",e:"Harpie Lady 2",ra:"R",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1300,de:1400,tx:"このカードのカード名は「ハーピィ・レディ」として扱う。\nこのモンスターが戦闘によって破壊したリバース効果モンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ハーピィ・レディ1",h:"はーぴぃ・れでぃわん",e:"Harpie Lady 1",ra:"SR",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1300,de:1400,tx:"このカードのカード名は「ハーピィ・レディ」として扱う。\nこのカードがフィールド上に表側表示で存在する限り、風属性モンスターの攻撃力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ハーピィズペット竜",h:"はーぴぃずぺっとどらごん",e:"Harpie's Pet Dragon",ra:"SR",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2000,de:2500,tx:"①：このカードの攻撃力・守備力は、フィールドの「ハーピィ・レディ」の数×３００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"パーフェクト機械王",h:"ぱーふぇくときかいおう",e:"Perfect Machine King",ra:"SR",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2700,de:1500,tx:"フィールド上に存在するこのカード以外の機械族モンスター１体につき、このカードの攻撃力は５００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ハープの精",h:"はーぷのせい",e:"Spirit of the Harp",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:800,de:2000,tx:"天界でハープをかなでる精霊。\nその音色はまわりの心をなごます。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"パーリィナイツ",h:"ぱーりぃないつ",e:"Parry Knights",ra:"SR",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2300,de:2100,tx:"①：相手モンスターの攻撃で自分が戦闘ダメージを受けた時に発動できる。\nこのカードを手札から特殊召喚する。\nその後、受けたダメージの数値以下の攻撃力を持つモンスター１体を手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ハイ・キューピット",h:"はい・きゅーぴっと",e:"Cupid Volley",ra:"SR",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:600,de:600,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分のデッキの上からカードを３枚まで墓地へ送って発動できる。\nこのカードのレベルをターン終了時まで、この効果を発動するために墓地へ送ったカードの数だけ上げる。\n②：フィールドのこのカードが相手によって破壊され墓地へ送られた場合に発動する。\n自分は１５００LP回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ハイ・プリーステス",h:"はい・ぷりーすてす",e:"Lady of Faith",ra:"N",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:1100,de:800,tx:"聞いたことのない呪文を唱え、あらぶる心をしずめてくれる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ハイエナ",h:"はいえな",e:"Hyena",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1000,de:300,tx:"このカードが戦闘によって墓地へ送られた時、デッキから「ハイエナ」をフィールド上に特殊召喚する事ができる。\nその後デッキをシャッフルする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"B・プラント",h:"ばいお・ぷらんと",e:"Bio Plant",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:600,de:1300,tx:"地下研究所での実験で大失敗して生まれたばけもの。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バイオ僧侶",h:"ばいおそうりょ",e:"Bio-Mage",ra:"N",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:1150,de:1000,tx:"最新のバイオテクノロジーによって生み出された僧侶。\n数々の謎を秘めている。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バイオファルコン",h:"ばいおふぁるこん",e:"Biofalcon",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1700,de:1200,tx:"このカードがフィールド上に表側表示で存在する場合に自分フィールド上の機械族モンスターが戦闘によって破壊され墓地へ送られた時、デッキから攻撃力１０００以下の機械族モンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バイオレット・ウィッチ",h:"ばいおれっと・うぃっち",e:"Violet Witch",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1100,de:1200,tx:"このカードが戦闘によって破壊され墓地へ送られた時、自分のデッキから守備力１５００以下の植物族モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"紫光の宣告者",h:"ばいおれっと・でくれあらー",e:"Herald of Purple Light",ra:"SR",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:300,de:500,tx:"①：相手が罠カードを発動した時、手札からこのカードと天使族モンスター１体を墓地へ送って発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バイサー・ショック",h:"ばいさー・しょっく",e:"Byser Shock",ra:"SR",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:800,de:600,tx:"このカードが召喚・反転召喚・特殊召喚に成功した時、フィールド上の全てのセットされたカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バイス・ドラゴン",h:"ばいす・どらごん",e:"Vice Dragon",ra:"UR",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2000,de:2400,tx:"①：相手フィールドにモンスターが存在し、自分フィールドにモンスターが存在しない場合、このカードは手札から特殊召喚できる。\nこの方法で特殊召喚したこのカードの元々の攻撃力・守備力は半分になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"HSR快刀乱破ズール",h:"はいすぴーどろいどかいとうらっぱずーる",e:"Hi-Speedroid Puzzle",ra:"R",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:1300,de:1600,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：このカードが特殊召喚されたモンスターと戦闘を行うダメージステップ開始時に発動できる。\nこのカードの攻撃力はそのダメージステップ終了時まで倍になる。\n②：S召喚したこのカードが墓地へ送られたターンのエンドフェイズに、「HSR快刀乱破ズール」以外の自分の墓地の「スピードロイド」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"HSR魔剣ダーマ",h:"はいすぴーどろいどまけんだーま",e:"Hi-Speedroid Kendama",ra:"SR",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:2200,de:1600,tx:"チューナー＋チューナー以外のモンスター１体以上\n「HSR魔剣ダーマ」の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。\n②：自分の墓地の機械族モンスター１体を除外して発動できる。\n相手に５００ダメージを与える。\n③：このカードが墓地に存在し、自分フィールドにカードが存在しない場合、自分メインフェイズに発動できる。\nこのカードを墓地から特殊召喚する。\nこの効果を発動するターン、自分は通常召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"HSRマッハゴー・イータ",h:"はいすぴーどろいどまっはごー・いーた",e:"Hi-Speedroid Hagoita",ra:"R",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:2000,de:1000,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードをリリースして発動できる。\nフィールドの全ての表側表示モンスターのレベルはターン終了時まで１つ上がる。\nこの効果は相手ターンでも発動できる。\n②：このカードが墓地に存在し、自分フィールドに「スピードロイド」チューナーが存在する場合に発動できる。\nこのカードを特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は風属性モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ハイドロ・ジェネクス",h:"はいどろ・じぇねくす",e:"Hydro Genex",ra:"R",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:2300,de:1800,tx:"「ジェネクス・コントローラー」＋チューナー以外の水属性モンスター１体以上\nこのカードが戦闘によって相手モンスターを破壊し墓地へ送った時、破壊したモンスターの攻撃力分だけ自分のライフポイントを回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ハイドロゲドン",h:"はいどろげどん",e:"Hydrogeddon",ra:"R",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"①：このカードが戦闘で相手モンスターを破壊し墓地へ送った時に発動できる。\nデッキから「ハイドロゲドン」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バイトロン",h:"ばいとろん",e:"Bitelon",ra:"R",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:2400,de:1000,tx:"このカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が越えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ハイパー・シンクロン",h:"はいぱー・しんくろん",e:"Hyper Synchron",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:1600,de:800,tx:"このカードがドラゴン族モンスターのシンクロ召喚に使用され墓地へ送られた場合、このカードをシンクロ素材としたシンクロモンスターは攻撃力が８００ポイントアップし、エンドフェイズ時にゲームから除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ハイパーサイコガンナー",h:"はいぱーさいこがんなー",e:"Hyper Psychic Blaster",ra:"SR",t:"monster",a:"地",l:9,ps:"",pe:"",tr:"サイキック族",mo:["シンクロ","効果"],ma:"",tp:"",at:3000,de:2500,tx:"チューナー＋チューナー以外のサイキック族モンスター１体以上\nこのカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。\nまた、このカードが守備表示モンスターを攻撃したダメージステップ終了時、その守備力を攻撃力が超えていれば、その数値だけ自分のライフポイントを回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ハイパーサイコガンナー／バスター",h:"はいぱーさいこがんなーすらっしゅばすたー",e:"Hyper Psychic Blaster/Assault Mode",ra:"N",t:"monster",a:"地",l:11,ps:"",pe:"",tr:"サイキック族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3500,de:3000,tx:"このカードは通常召喚できない。\n「バスター・モード」の効果でのみ特殊召喚できる。\nこのカードが相手モンスターと戦闘を行ったダメージステップ終了時、その相手モンスターの守備力分のダメージを相手ライフに与え、その攻撃力分だけ自分のライフポイントを回復する。\nまた、フィールド上のこのカードが破壊された時、自分の墓地の「ハイパーサイコガンナー」１体を選択して特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ハイパーサイコライザー",h:"はいぱーさいこらいざー",e:"Hyper Psychic Riser",ra:"SR",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"サイキック族",mo:["シンクロ","効果"],ma:"",tp:"",at:2000,de:600,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、このカードの攻撃力より低い攻撃力を持つモンスターは攻撃できず、このカードの攻撃力より高い攻撃力を持つフィールドのモンスターは効果を発動できない。\n②：このカードが相手によって破壊され墓地へ送られた場合、自分の墓地の、種族と属性が同じとなるチューナー１体とチューナー以外のモンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ハイパーハンマーヘッド",h:"はいぱーはんまーへっど",e:"Hyper Hammerhead",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1500,de:1200,tx:"このモンスターとの戦闘で破壊されなかった相手モンスターは、ダメージステップ終了時に持ち主の手札に戻る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"共鳴虫",h:"はうりんぐ・いんせくと",e:"Howling Insect",ra:"SR",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1200,de:1300,tx:"このカードが戦闘によって破壊され墓地に送られた時、デッキから攻撃力１５００以下の昆虫族モンスター１体を自分フィールド上に特殊召喚する事ができる。\nその後デッキをシャッフルする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ハウリング・ウォリアー",h:"はうりんぐ・うぉりあー",e:"Feedback Warrior",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:800,de:700,tx:"このカードが召喚・特殊召喚に成功した時、自分フィールド上に表側表示で存在するモンスター１体を選択して発動できる。\n選択したモンスターのレベルは３になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ハウンド・ドラゴン",h:"はうんど・どらごん",e:"Hunter Dragon",ra:"R",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:1700,de:100,tx:"鋭い牙で獲物を仕留めるドラゴン。\n鋭く素早い動きで攻撃を繰り出すが、守備能力は持ち合わせていない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"破壊剣－アームズバスターブレード",h:"はかいけん－あーむずばすたーぶれーど",e:"Robot Buster Destruction Sword",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"①：自分メインフェイズに自分フィールドの「バスター・ブレイダー」１体を対象として発動できる。\n自分の手札・フィールドからこのモンスターを装備カード扱いとしてその自分のモンスターに装備する。\n②：このカードが装備されている場合、相手フィールドの既に表側表示で存在している魔法・罠カードは効果を発動できない。\n③：装備されているこのカードを墓地へ送って発動できる。\nこのカードを装備していたモンスターの攻撃力はターン終了時まで１０００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"破壊剣－ウィザードバスターブレード",h:"はかいけん－うぃざーどばすたーぶれーど",e:"Wizard Buster Destruction Sword",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1200,de:900,tx:"①：自分メインフェイズに自分フィールドの「バスター・ブレイダー」１体を対象として発動できる。\n自分の手札・フィールドからこのモンスターを装備カード扱いとしてその自分のモンスターに装備する。\n②：このカードが装備されている場合、相手は墓地のモンスターの効果を発動できない。\n③：装備されているこのカードを墓地へ送り、「破壊剣－ウィザードバスターブレード」以外の自分の墓地の「破壊剣」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"破壊剣士の伴竜",h:"はかいけんしのばんりゅう",e:"Buster Whelp of the Destruction Swordsman",ra:"UR",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"ドラゴン族",mo:["チューナー","効果"],ma:"",tp:"",at:400,de:300,tx:"このカード名の②③の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードが召喚に成功した時に発動できる。\nデッキから「破壊剣士の伴竜」以外の「破壊剣」カード１枚を手札に加える。\n②：このカードをリリースして発動できる。\n自分の手札・墓地から「バスター・ブレイダー」１体を選んで特殊召喚する。\n③：このカードが墓地に存在し、自分フィールドに「バスター・ブレイダー」が存在する場合、手札から「破壊剣」カード１枚を捨てて発動できる。\nこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"破壊剣の使い手－バスター・ブレイダー",h:"はかいけんのつかいて－ばすたー・ぶれいだー",e:"Buster Blader, the Destruction Swordmaster",ra:"SR",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2600,de:2300,tx:"①：このカードのカード名は、フィールド・墓地に存在する限り「バスター・ブレイダー」として扱う。\n②：相手フィールドのモンスターが戦闘・効果で破壊され墓地へ送られた場合、破壊されたそのモンスター１体を対象として発動できる。\nそのモンスターを装備カード扱いとしてこのカードに装備する。\n③：１ターンに１度、このカードが装備しているモンスターカード１枚を墓地へ送って発動できる。\n墓地へ送ったそのモンスターカードと同じ種族の相手フィールドのモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"破壊神 ヴァサーゴ",h:"はかいしん　う゛ぁさーご",e:"Versago the Destroyer",ra:"R",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1100,de:900,tx:"このカードを融合素材モンスター１体の代わりにする事ができる。\nその際、他の融合素材モンスターは正規のものでなければならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"破戒僧 ランシン",h:"はかいそう　らんしん",e:"Anarchist Monk Ranshin",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1800,de:100,tx:"このカードが相手によってフィールド上から墓地へ送られた時、相手の墓地に存在するモンスター１体を選択してゲームから除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"破壊のゴーレム",h:"はかいのごーれむ",e:"Destroyer Golem",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["通常"],ma:"",tp:"",at:1500,de:1000,tx:"大きな右手が特徴のゴーレム。\n右手で押しつぶして攻撃する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"破壊竜ガンドラ",h:"はかいりゅうがんどら",e:"Gandora the Dragon of Destruction",ra:"UR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカードは特殊召喚できない。\n①：LPを半分払って発動できる。\nこのカード以外のフィールドのカードを全て破壊し除外する。\nこのカードの攻撃力は、この効果で破壊したカードの数×３００アップする。\n②：このカードを召喚・反転召喚したターンのエンドフェイズに発動する。\nこのカードを墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"墓守の暗殺者",h:"はかもりのあさしん",e:"Gravekeeper's Assailant",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1500,de:1500,tx:"「王家の眠る谷－ネクロバレー」がフィールド上に存在しなければ発動できない。\nこのカードの攻撃宣言時、相手表側表示モンスターの表示形式を変更する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"墓守の異端者",h:"はかもりのいたんしゃ",e:"Gravekeeper's Heretic",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1800,de:1500,tx:"①：このカードがモンスターゾーンに存在し、フィールドに「王家の眠る谷－ネクロバレー」が存在する限り、このカードはこのカード以外の効果を受けない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"墓守の異能者",h:"はかもりのいのうしゃ",e:"Gravekeeper's Supernaturalist",ra:"R",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"魔法使い族",mo:["融合","効果"],ma:"",tp:"",at:2000,de:2000,tx:"「墓守」モンスター×２\nこのカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードの攻撃力・守備力は、このカードの融合素材としたモンスターの元々のレベルの合計×１００アップする。\n②：フィールドに「王家の眠る谷－ネクロバレー」が存在する限り、このカード及び自分のフィールドゾーンのカードは効果では破壊されない。\n③：自分メインフェイズに発動できる。\nこのターンのエンドフェイズに、デッキから「墓守」モンスター１体または「ネクロバレー」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"墓守の大筒持ち",h:"はかもりのおおづつもち",e:"Gravekeeper's Cannonholder",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1400,de:1200,tx:"自分フィールド上の「墓守の」という名のついたモンスターカード１体を生け贄に捧げる度に、相手に７００ポイントダメージを与える。\nこの場合「墓守の大筒持ち」を生け贄に捧げる事はできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"墓守の長",h:"はかもりのおさ",e:"Gravekeeper's Chief",ra:"SR",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1900,de:1200,tx:"このカードは自分のフィールド上に１枚しか存在できない。\nこのカードがフィールド上に存在する限り、自分の墓地は「王家の眠る谷－ネクロバレー」の効果を受けない。\nこのカードが生け贄召喚に成功した場合、自分の墓地に存在する「墓守の」という名のついたモンスターカード１枚をフィールド上に特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"墓守の監視者",h:"はかもりのかんししゃ",e:"Gravekeeper's Watcher",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"相手プレイヤーが自らの手札を捨てる効果を含むカードを発動した時、このカードを手札から墓地に送る事で、その発動と効果を無効にしてそのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"墓守の祈祷師",h:"はかもりのきとうし",e:"Gravekeeper's Shaman",ra:"N",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1500,de:1500,tx:"このカードの守備力は、自分の墓地の「墓守」と名のついたモンスターの数×２００ポイントアップする。\nこのカードがフィールド上に表側表示で存在する限り、「墓守」と名のついたモンスター以外の、墓地で発動する効果モンスターの効果は無効化される。\nまた、「王家の眠る谷－ネクロバレー」がフィールド上に存在する場合、相手はフィールド魔法カードを発動できず、フィールド魔法カードは相手の効果によっては破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"墓守の審神者",h:"はかもりのさにわ",e:"Gravekeeper's Oracle",ra:"SR",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2000,de:1500,tx:"このカードはモンスター３体または「墓守」モンスター１体をリリースして表側表示でアドバンス召喚する事もできる。\n①：このカードがアドバンス召喚に成功した時、このカードのアドバンス召喚のためにリリースした「墓守」モンスターの数まで、以下の効果から選択して発動できる。\n●このカードの攻撃力は、このカードのアドバンス召喚のためにリリースしたモンスターのレベルの合計×１００アップする。\n●相手フィールドの裏側表示モンスターを全て破壊する。\n●相手フィールドの全てのモンスターの攻撃力・守備力は２０００ダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"墓守の使徒",h:"はかもりのしと",e:"Gravekeeper's Nobleman",ra:"R",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードが相手モンスターの攻撃によって破壊され墓地へ送られた時、デッキから「墓守の使徒」以外の「墓守」と名のついたモンスター１体を裏側守備表示で特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"墓守の従者",h:"はかもりのじゅうしゃ",e:"Gravekeeper's Vassal",ra:"R",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:700,de:500,tx:"このカードが相手プレイヤーに与える戦闘ダメージは効果によるダメージとして扱う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"墓守の呪術師",h:"はかもりのじゅじゅつし",e:"Gravekeeper's Curse",ra:"R",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"このカードが召喚・反転召喚・特殊召喚された時、相手プレイヤーに５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"墓守の召喚師",h:"はかもりのしょうかんし",e:"Gravekeeper's Recruiter",ra:"SR",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1200,de:1500,tx:"このカードが自分フィールド上から墓地へ送られた時、自分のデッキから守備力１５００以下の「墓守の」と名のついたモンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"墓守の神職",h:"はかもりのしんしょく",e:"Gravekeeper's Headman",ra:"R",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:500,de:1500,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・反転召喚・特殊召喚に成功した場合、自分の墓地のレベル４の「墓守」モンスター１体を対象として発動できる。\nそのモンスターを表側攻撃表示または裏側守備表示で特殊召喚する。\nこの効果は「王家の眠る谷－ネクロバレー」の効果を受けない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"墓守の大神官",h:"はかもりのだいしんかん",e:"Gravekeeper's Visionary",ra:"SR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2000,de:1800,tx:"このカードは「墓守の」と名のついたモンスター１体をリリースして召喚できる。\nこのカードの攻撃力は、自分の墓地の「墓守の」と名のついたモンスターの数×２００ポイントアップする。\nまた、フィールド上に表側表示で存在するこのカードが破壊される場合、代わりに手札から「墓守の」と名のついたモンスター１体を捨てる事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"墓守の偵察者",h:"はかもりのていさつしゃ",e:"Gravekeeper's Spy",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["リバース","効果"],ma:"",tp:"",at:1200,de:2000,tx:"リバース：自分のデッキから攻撃力１５００以下の「墓守の」と名のついたモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"墓守の長槍兵",h:"はかもりのながやりへい",e:"Gravekeeper's Spear Soldier",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"守備表示モンスターを攻撃した時、このカードの攻撃力が守備表示モンスターの守備力を越えていれば、その数値だけ相手ライフポイントに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"墓守の番兵",h:"はかもりのばんぺい",e:"Gravekeeper's Guard",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["リバース","効果"],ma:"",tp:"",at:1000,de:1900,tx:"リバース：フィールド上の相手モンスター１体を持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"墓守の伏兵",h:"はかもりのふくへい",e:"Gravekeeper's Ambusher",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1700,de:0,tx:"このカードがリバースした時、相手の墓地のカード１枚を選択してデッキの一番下に戻す事ができる。\nまた、リバースしたこのカードが墓地へ送られた場合、自分の墓地の「ネクロバレー」と名のついたカード１枚を選択して手札に加える事ができる。\nこのカードの効果は「王家の眠る谷－ネクロバレー」の効果では無効化されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"墓守の末裔",h:"はかもりのまつえい",e:"Gravekeeper's Descendant",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1500,de:1200,tx:"このカード以外の自分フィールド上に表側表示で存在する「墓守の」と名のついたモンスター１体をリリースして発動できる。\n相手フィールド上のカード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"墓守の巫女",h:"はかもりのみこ",e:"Gravekeeper's Priestess",ra:"SR",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1000,de:1500,tx:"このカードがフィールド上に表側表示で存在する限り、フィールドは「王家の眠る谷－ネクロバレー」になる。\nフィールド魔法カードが表側表示で存在する場合、この効果は適用されない。\nまた、このカードがフィールド上に表側表示で存在する限り、フィールド上の「墓守」と名のついたモンスターの攻撃力・守備力は２００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"墓守の霊術師",h:"はかもりのれいじゅつし",e:"Gravekeeper's Spiritualist",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1500,de:1500,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：フィールドに「王家の眠る谷－ネクロバレー」が存在する場合に発動できる。\n魔法使い族の融合モンスターカードによって決められた、フィールドのこのカードを含む融合素材モンスターを自分の手札・フィールドから墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"爆炎集合体 ガイヤ・ソウル",h:"ばくえんしゅうごうたい　がいや・そうる",e:"Gaia Soul the Combustible Collective",ra:"SR",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:2000,de:0,tx:"自分フィールド上の炎族モンスターを２体まで生け贄に捧げる事ができる。\nこの効果で生け贄を捧げた場合、このモンスターの攻撃力は生け贄の数×１０００ポイントアップする。\nこのカードが守備表示モンスターを攻撃した時、このカードの攻撃力が守備表示モンスターの守備力を越えていれば、その数値だけ相手ライフに戦闘ダメージを与える。\nエンドフェイズ時にこのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"爆炎帝テスタロス",h:"ばくえんていてすたろす",e:"Thestalos the Mega Monarch",ra:"UR",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:2800,de:1000,tx:"このカードはアドバンス召喚したモンスター１体をリリースしてアドバンス召喚できる。\nこのカードがアドバンス召喚に成功した時、相手の手札を確認して１枚捨てる。\n捨てたカードがモンスターカードだった場合、そのモンスターのレベル×２００ポイントダメージを相手ライフに与える。\nこのカードが炎属性モンスターをリリースしてアドバンス召喚に成功した場合、その時の効果に以下の効果を加える。\n\n●相手ライフに１０００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"白銀のスナイパー",h:"はくぎんのすないぱー",e:"Silver Sentinel",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:1300,tx:"このカードは魔法カード扱いとして手札から魔法＆罠カードゾーンにセットできる。\n魔法＆罠カードゾーンにセットされたこのカードが相手のカードの効果によって破壊され墓地へ送られたターンのエンドフェイズ時、このカードを墓地から特殊召喚し、相手フィールド上のカード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バク団",h:"ばくだん",e:"Explossum",ra:"N",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"自分のメインフェイズ時、手札または自分フィールド上のこのモンスターを、装備カード扱いとして相手フィールド上のエクシーズモンスター１体に装備できる。\nこの効果でこのカードを装備したモンスターにエクシーズ素材が無い場合、そのモンスターを破壊する。\nまた、このカードが装備カード扱いとして装備されている場合、相手のスタンバイフェイズ毎に装備モンスターのエクシーズ素材を１つ取り除く。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"爆弾かめ虫",h:"ばくだんかめむし",e:"Bombardment Beetle",ra:"R",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"昆虫族",mo:["リバース","効果"],ma:"",tp:"",at:400,de:900,tx:"リバース：相手フィールド上の裏側守備表示モンスターカード１枚を確認する。\n効果モンスターの場合は破壊し（リバース効果は発動しない）、違う場合は元に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"パクバグ",h:"ぱくばぐ",e:"Bite Bug",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:300,de:1000,tx:"相手フィールド上にエクシーズモンスターが存在する場合、このカードは手札から特殊召喚できる。\nこの方法で特殊召喚に成功した時、相手フィールド上のモンスター１体を選択して発動する。\n選択したモンスターの攻撃力は３００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"爆風トカゲ",h:"ばくふうとかげ",e:"Gale Lizard",ra:"UR",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["リバース","効果"],ma:"",tp:"",at:1400,de:700,tx:"①：このカードがリバースした場合、相手フィールドのモンスター１体を対象として発動する。\nその相手モンスターを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"白兵戦型お手伝いロボ",h:"はくへいせんがたおてつだいろぼ",e:"Helping Robo for Combat",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1600,de:0,tx:"このカードが戦闘によって相手モンスターを破壊する度に、自分はカードを１枚ドローした後手札からカードを１枚選択してデッキの一番下に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バグマンＸ",h:"ばぐまんえっくす",e:"Crashbug X",ra:"R",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:2000,tx:"このカードが召喚に成功した時、自分フィールド上に「バグマンY」が表側表示で存在する場合、自分のデッキから「バグマンZ」１体を特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バグマンＺ",h:"ばぐまんぜっと",e:"Crashbug Z",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:1500,tx:"このカードが召喚に成功した時、自分フィールド上に「バグマンX」が表側表示で存在する場合、自分のデッキから「バグマンY」１体を特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バグマンＹ",h:"ばぐまんわい",e:"Crashbug Y",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1400,de:1600,tx:"このカードが召喚に成功した時、自分フィールド上に「バグマンZ」が表側表示で存在する場合、自分のデッキから「バグマンX」１体を特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"白竜の忍者",h:"はくりゅうのにんじゃ",e:"White Dragon Ninja",ra:"R",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2700,de:1200,tx:"このカードを特殊召喚する場合、「忍法」と名のついたカードの効果でのみ特殊召喚できる。\nこのカードがフィールド上に表側表示で存在する限り、自分フィールド上の魔法・罠カードはカードの効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"はぐれ者傭兵部隊",h:"はぐれものようへいぶたい",e:"Missing Force",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"自分フィールド上にこのカード以外のモンスターが存在しない場合、このカードをリリースして発動できる。\n相手フィールド上のモンスター１体を選択し、エンドフェイズ時までコントロールを得る。\nこの効果を発動するターン、自分はモンスターを特殊召喚できず、バトルフェイズを行えない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バスター・ショットマン",h:"ばすたー・しょっとまん",e:"Buster Blaster",ra:"R",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"機械族",mo:["ユニオン","効果"],ma:"",tp:"",at:0,de:0,tx:"１ターンに１度、自分のメインフェイズ時に装備カード扱いとして自分フィールド上のモンスターに装備、または装備を解除して表側攻撃表示で特殊召喚する事ができる。\nこの効果で装備カード扱いになっている場合のみ、装備モンスターの攻撃力・守備力は５００ポイントダウンする。\n装備モンスターが戦闘によって相手モンスターを破壊した場合、フィールド上に表側表示で存在する、破壊したモンスターと同じ種族のモンスターを全て破壊する。\n（１体のモンスターが装備できるユニオンは１枚まで。\n装備モンスターが破壊される場合、代わりにこのカードを破壊する。\n）",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バスター・ビースト",h:"ばすたー・びーすと",e:"Assault Beast",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1900,de:1200,tx:"①：このカードを手札から墓地へ捨てて発動できる。\nデッキから「バスター・モード」１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バスター・ブレイダー",h:"ばすたー・ぶれいだー",e:"Buster Blader",ra:"R",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2600,de:2300,tx:"①：このカードの攻撃力は、相手のフィールド・墓地のドラゴン族モンスターの数×５００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バスター・マーセナリ",h:"ばすたー・まーせなり",e:"Assault Mercenary",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:300,tx:"自分の手札または墓地に存在する「バスター・モード」１枚をデッキに戻す事で、相手フィールド上に存在する魔法・罠カード１枚を破壊する。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バチバチバチ",h:"ばちばちばち",e:"Bachibachibachi",ra:"N",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"①：フィールドのこのカードを素材としてX召喚したモンスターは以下の効果を得る。\n●このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ハチビー",h:"はちびー",e:"Bee List Soldier",ra:"R",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:500,de:400,tx:"このカードと自分フィールド上に表側表示で存在する「ハチビー」以外の昆虫族モンスター１体をリリースして発動する。\n自分のデッキからカードを２枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ハック・ワーム",h:"はっく・わーむ",e:"Hack Worm",ra:"N",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:400,de:0,tx:"①：相手フィールドにモンスターが存在しない場合、このカードは手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バックアップ・ウォリアー",h:"ばっくあっぷ・うぉりあー",e:"Backup Warrior",ra:"N",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2100,de:0,tx:"このカードは通常召喚できない。\n自分フィールド上に存在するモンスターが守備表示モンスター２体のみの場合に特殊召喚する事ができる。\nこのカードを特殊召喚するターン、自分はシンクロ召喚をする事ができない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"薄幸の乙女",h:"はっこうのおとめ",e:"The Unhappy Girl",ra:"R",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:400,de:300,tx:"このカードは表側攻撃表示で存在する限り戦闘によっては破壊されない。\nこのカードが表側攻撃表示でフィールド上に存在する限り、このカードと戦闘を行ったモンスターは表示形式の変更と攻撃ができなくなる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バット",h:"ばっと",e:"Bat",ra:"N",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:300,de:350,tx:"左右のハネに搭載された爆弾を落としてくるメカコウモリ。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バッド・エンド・クイーン・ドラゴン",h:"ばっど・えんど・くいーん・どらごん",e:"Dragon Queen of Tragic Endings",ra:"SR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1900,de:2600,tx:"このカードは通常召喚できない。\n自分フィールド上の永続魔法カードが３枚以上の場合に特殊召喚できる。\nこのカードの攻撃によって相手ライフに戦闘ダメージを与えた時、相手は手札を１枚選んで墓地へ送り、自分はデッキからカードを１枚ドローする。\nまた、このカードがフィールド上から墓地へ送られていた場合、自分のスタンバイフェイズ時に、自分フィールド上に表側表示で存在する永続魔法カード１枚を墓地へ送る事で、このカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ハッピー・ラヴァー",h:"はっぴー・らう゛ぁー",e:"Happy Lover",ra:"N",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:800,de:500,tx:"頭からハートビームを出し敵をしあわせにする、小さな天使。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"派手ハネ",h:"はではね",e:"Hade-Hane",ra:"R",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"獣族",mo:["リバース","効果"],ma:"",tp:"",at:900,de:1000,tx:"リバース：フィールド上のモンスターを３体まで持ち主の手札に戻す事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"波動竜騎士 ドラゴエクィテス",h:"はどうりゅうきし　どらごえくぃてす",e:"Dragon Knight Draco-Equiste",ra:"UR",t:"monster",a:"風",l:10,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:3200,de:2000,tx:"ドラゴン族シンクロモンスター＋戦士族モンスター\nこのカードは融合召喚でのみエクストラデッキから特殊召喚する事ができる。\n１ターンに１度、墓地に存在するドラゴン族のシンクロモンスター１体をゲームから除外し、エンドフェイズ時までそのモンスターと同名カードとして扱い、同じ効果を得る事ができる。\nまた、このカードがフィールド上に表側攻撃表示で存在する限り、相手のカードの効果によって発生する自分への効果ダメージは代わりに相手が受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"波動竜フォノン・ドラゴン",h:"はどうりゅうふぉのん・どらごん",e:"Phonon Pulse Dragon",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","チューナー","効果"],ma:"",tp:"",at:1900,de:800,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカードがシンクロ召喚に成功した時、１～３までのレベルを宣言して発動できる。\nこのカードのレベルは宣言したレベルになる。\nこの効果を発動したターン、自分はこのカードをシンクロ素材としたシンクロ召喚以外の特殊召喚ができない。\n自分は「波動竜フォノン・ドラゴン」を１ターンに１度しか特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バトルフットボーラー",h:"ばとるふっとぼーらー",e:"Battle Footballer",ra:"R",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1000,de:2100,tx:"高い守備能力を誇るサイボーグ。\n元々はフットボールマシンとして開発されたという。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"パトロイド",h:"ぱとろいど",e:"Patroid",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:1200,tx:"相手フィールド上にセットされているカードを１枚めくり、確認した後元に戻す。\nこの効果は１ターンに１度だけ自分のメインフェイズに発動する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"パトロール・ロボ",h:"ぱとろーる・ろぼ",e:"Patrol Robo",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1100,de:900,tx:"このカードがフィールド上に表側表示で存在する限り、自分のスタンバイフェイズ時に相手フィールド上にセットされたカード１枚を確認する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"花騎士団の駿馬",h:"はなきしだんのしゅんめ",e:"Horse of the Floral Knights",ra:"SR",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:400,de:800,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから光属性の「聖騎士」モンスター１体を手札に加える。\n②：１ターンに１度、自分メインフェイズに発動できる。\n融合モンスターカードによって決められた、フィールドのこのカードを含む融合素材モンスターを自分の手札・フィールドから墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バニーラ",h:"ばにーら",e:"Bunilla",ra:"N",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:150,de:2050,tx:"甘いものがとっても大好きな甘党うさぎ。\n世界一甘いと言われる甘糖人参を探し求め、今日も明日もニンジンをかじりたい。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"戒めの龍",h:"ぱにっしゅめんと・どらぐーん",e:"Punishment Dragon",ra:"SR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3000,de:2600,tx:"このカードは通常召喚できない。\n除外されている自分の「ライトロード」モンスターが４種類以上の場合のみ特殊召喚できる。\n①：１ターンに１度、１０００LPを払って発動できる。\n「ライトロード」モンスター以外の、お互いの墓地のカード及び表側表示で除外されているカードを全て持ち主のデッキに戻す。\nこの効果は相手ターンでも発動できる。\n②：１ターンに１度、自分の「ライトロード」モンスターの効果が発動した場合に発動する。\n自分のデッキの上からカードを４枚墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"はにわ",h:"はにわ",e:"Haniwa",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"岩石族",mo:["通常"],ma:"",tp:"",at:500,de:500,tx:"古代王の墓の中にある宝物を守る土人形。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ハネクリボー",h:"はねくりぼー",e:"Winged Kuriboh",ra:"SR",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:300,de:200,tx:"①：フィールドのこのカードが破壊され墓地へ送られた場合に発動する。\nこのターン、自分が受ける戦闘ダメージは０になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ハネクリボー Lv9",h:"はねくりぼー　れべる９",e:"Winged Kuriboh LV9",ra:"SR",t:"monster",a:"光",l:9,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:"?",de:"?",tx:"チェーンが発生した時、自分の手札からこのカードを特殊召喚する事ができる。\nこのカードが自分フィールド上に表側表示で存在する限り、お互いに発動した魔法カードは墓地へ送られずゲームから除外する。\nこのカードの攻撃力・守備力は、相手の墓地に存在する魔法カードの数×５００ポイントになる。\n「ハネクリボー LV９」は、自分フィールド上に１枚しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ハネハネ",h:"はねはね",e:"Hane-Hane",ra:"UR",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["リバース","効果"],ma:"",tp:"",at:450,de:500,tx:"①：このカードがリバースした場合、フィールドのモンスター１体を対象として発動する。\nそのモンスターを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ハネワタ",h:"はねわた",e:"Hanewata",ra:"SR",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"天使族",mo:["チューナー","効果"],ma:"",tp:"",at:200,de:300,tx:"①：このカードを手札から捨てて発動できる。\nこのターン、自分が受ける効果ダメージは０になる。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バハムート・シャーク",h:"ばはむーと・しゃーく",e:"Bahamut Shark",ra:"UR",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"海竜族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2600,de:2100,tx:"水属性レベル４モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\nEXデッキからランク３以下の水属性Xモンスター１体を特殊召喚する。\nこのターン、このカードは攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バビロン",h:"ばびろん",e:"Meotoko",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:700,de:600,tx:"一つ目の巨大な怪物。\n目玉からビームを発射して攻撃する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バフォメット",h:"ばふぉめっと",e:"Berfomet",ra:"R",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1400,de:1800,tx:"①：このカードが召喚・反転召喚に成功した時に発動できる。\nデッキから「幻獣王ガゼル」１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"パペット・キング",h:"ぱぺっと・きんぐ",e:"Puppet King",ra:"R",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2800,de:2600,tx:"①：相手がドロー以外の方法でデッキからモンスターを手札に加えた時に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードがこのカードの①の効果で特殊召喚に成功した場合、次の自分ターンのエンドフェイズに発動する。\nこのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"覇魔導士アーカナイト・マジシャン",h:"はまどうしあーかないと・まじしゃん",e:"Supreme Arcanite Magician",ra:"N",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"魔法使い族",mo:["融合","効果"],ma:"",tp:"",at:1400,de:2800,tx:"魔法使い族Sモンスター＋魔法使い族モンスター\nこのカードは融合召喚でのみエクストラデッキから特殊召喚できる。\n①：このカードが融合召喚に成功した場合に発動する。\nこのカードに魔力カウンターを２つ置く。\n②：このカードの攻撃力は、このカードの魔力カウンターの数×１０００アップする。\n③：１ターンに１度、自分フィールドの魔力カウンターを１つ取り除き、以下の効果から１つを選択して発動できる。\n\n●フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\n\n●自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"破滅の天使ルイン",h:"はめつのてんしるいん",e:"Ruin, Angel of Oblivion",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["儀式","効果"],ma:"",tp:"",at:1700,de:1000,tx:"「エンドレス・オブ・ザ・ワールド」により降臨。\n①：このカードのカード名は、手札・フィールドに存在する限り「破滅の女神ルイン」として扱う。\n②：このカードが儀式召喚に成功した場合に発動する。\nこのターン、このカードは１度のバトルフェイズ中に２回までモンスターに攻撃できる。\n③：このカードが墓地へ送られた場合、自分フィールドの儀式モンスター１体を対象として発動できる。\nそのモンスターが自分フィールドに表側表示で存在する限り、自分の儀式モンスターの攻撃宣言時に相手はカードの効果を発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"破滅の魔王ガーランドルフ",h:"はめつのまおうがーらんどるふ",e:"Garlandolf, King of Destruction",ra:"UR",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"悪魔族",mo:["儀式","効果"],ma:"",tp:"",at:2500,de:1400,tx:"「破滅の儀式」により降臨。\nこのカードが儀式召喚に成功した時、このカードの攻撃力以下の守備力を持つ、このカード以外のフィールド上のモンスターを全て破壊し、破壊したモンスター１体につき、このカードの攻撃力は１００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"破滅の女神ルイン",h:"はめつのめがみるいん",e:"Ruin, Queen of Oblivion",ra:"UR",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"天使族",mo:["儀式","効果"],ma:"",tp:"",at:2300,de:2000,tx:"「エンド・オブ・ザ・ワールド」により降臨。\n①：このカードの攻撃で相手モンスターを破壊した時に発動できる。\nこのカードはもう１度だけ続けて攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"隼の騎士",h:"はやぶさのきし",e:"Hayabusa Knight",ra:"UR",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:700,tx:"①：このカードは１度のバトルフェイズ中に２回攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"薔薇恋人",h:"ばら・らう゛ぁー",e:"Rose Lover",ra:"SR",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：墓地のこのカードを除外して発動できる。\n手札から植物族モンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターはこのターン、相手の罠カードの効果を受けない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"パラサイト・フュージョナー",h:"ぱらさいと・ふゅーじょなー",e:"Fusion Parasite",ra:"R",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカードは、このカードの①の効果を適用する場合のみ融合素材にできる。\n①：このカードは、融合モンスターカードにカード名が記された融合素材モンスター１体の代わりにできる。\nその際、他の融合素材モンスターは正規のものでなければならない。\n②：このカードが特殊召喚に成功した場合に発動できる。\n融合モンスターカードによって決められた、このカードを含む融合素材モンスターを自分フィールドから墓地へ送り、その融合モンスター１体をエクストラデッキから融合召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"巨竜の聖騎士",h:"ぱらでぃん・おぶ・ふぇるぐらんと",e:"Paladin of Felgrand",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:300,tx:"①：このカードが召喚・特殊召喚に成功した場合に発動できる。\n自分の手札・デッキからレベル７・８のドラゴン族モンスター１体を装備カード扱いとしてこのカードに装備する。\n②：装備カードを装備したこのカードは他のモンスターの効果を受けない。\n③：自分フィールドのモンスター１体とこのカードをリリースし、自分の墓地のレベル７・８のドラゴン族モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バラに棲む悪霊",h:"ばらにすむあくりょう",e:"Rose Spectre of Dunn",ra:"N",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"植物族",mo:["融合"],ma:"",tp:"",at:2000,de:1800,tx:"グレムリン＋スネーク・パーム",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"薔薇の妖精",h:"ばらのようせい",e:"Rose Fairy",ra:"N",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:600,de:1200,tx:"このカードが魔法・罠・効果モンスターの効果によって自分のデッキから手札に加わった場合、このカードを自分フィールド上に特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ハリケル",h:"はりける",e:"Hurricail",ra:"N",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:900,de:200,tx:"荒野で荒れ狂う竜巻。\n風の刃で相手を切り刻む。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"針剣士",h:"はりけんし",e:"Stinging Swordsman",ra:"N",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:300,de:600,tx:"このカードが相手ライフに戦闘ダメージを与えた時、相手の魔法＆罠カードゾーンに表側表示で存在するカードを全て持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ハリマンボウ",h:"はりまんぼう",e:"Needle Sunfish",ra:"SR",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1500,de:100,tx:"このカードが墓地へ送られた時、相手フィールド上に表側表示で存在するモンスター１体を選択して発動する。\n選択した相手モンスターの攻撃力は５００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バルーン・リザード",h:"ばるーん・りざーど",e:"Balloon Lizard",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:500,de:1900,tx:"自分のスタンバイフェイズ毎にこのカードにカウンターを１個乗せる。\nこのカードを破壊したカードのコントローラーにカウンターの数×４００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バルキリー・ナイト",h:"ばるきりー・ないと",e:"Valkyrian Knight",ra:"SR",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1900,de:1200,tx:"①：このカードがモンスターゾーンに存在する限り、相手は「バルキリー・ナイト」以外の戦士族モンスターを攻撃対象に選択できない。\n②：このカードが戦闘で破壊され墓地へ送られた時、自分の墓地から戦士族モンスター１体とこのカードを除外し、自分の墓地のレベル５以上の戦士族モンスター１体を対象として発動できる。\nその戦士族モンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バロックス",h:"ばろっくす",e:"Barox",ra:"N",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"悪魔族",mo:["融合"],ma:"",tp:"",at:1380,de:1530,tx:"キラーパンダ＋ガーゴイル",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"パロット・ドラゴン",h:"ぱろっと・どらごん",e:"Parrot Dragon",ra:"N",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:2000,de:1300,tx:"アメリカンコミックの世界のドラゴン。\nかわいらしい風貌にだまされるな。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"パワー・インジェクター",h:"ぱわー・いんじぇくたー",e:"Power Injector",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1300,de:1400,tx:"６００ライフポイントを払って発動する。\nそのターン中フィールド上に表側表示で存在する全てのサイキック族モンスターの攻撃力は５００ポイントアップする。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"パワー・インベーダー",h:"ぱわー・いんべーだー",e:"Power Invader",ra:"R",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2200,de:0,tx:"相手フィールド上のモンスターが２体以上の場合、このカードはリリースなしで召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"パワー・サプライヤー",h:"ぱわー・さぷらいやー",e:"Power Supplier",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:400,de:400,tx:"１ターンに１度、自分フィールド上に表側表示で存在するモンスター１体を選択して発動する事ができる。\nこのカードが自分フィールド上に表側表示で存在する限り、選択したモンスターの攻撃力は４００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"パワー・ジャイアント",h:"ぱわー・じゃいあんと",e:"Power Giant",ra:"R",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:2200,de:0,tx:"このカードは手札のレベル４以下のモンスター１体を墓地へ送り、手札から特殊召喚する事ができる。\nこの方法で特殊召喚した場合、手札から墓地へ送ったモンスターのレベルの数だけこのカードのレベルを下げる。\nまた、このカードが戦闘を行う場合、そのダメージステップ終了時まで自分が受ける効果ダメージを０にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"パワー・ツール・ドラゴン",h:"ぱわー・つーる・どらごん",e:"Power Tool Dragon",ra:"UR",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:2300,de:2500,tx:"チューナー＋チューナー以外のモンスター１体以上\n１ターンに１度、自分のメインフェイズ時に発動できる。\nデッキから装備魔法カードを３枚選んで相手に見せ、相手はその中からランダムに１枚選ぶ。\n相手が選んだカード１枚を自分の手札に加え、残りのカードをデッキに戻す。\nまた、装備魔法カードを装備したこのカードが破壊される場合、代わりにこのカードに装備された装備魔法カード１枚を墓地へ送る事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"パワー・ブレイカー",h:"ぱわー・ぶれいかー",e:"Power Breaker",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1900,de:0,tx:"このカードが相手モンスターの攻撃、または相手の効果によって破壊され墓地へ送られた時、相手フィールド上に表側表示で存在する魔法・罠カード１枚を選択して破壊する。\nこのカードは攻撃した場合、ダメージステップ終了時に守備表示になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"パワード・チューナー",h:"ぱわーど・ちゅーなー",e:"Powered Tuner",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1400,de:1000,tx:"このカードの攻撃力は、フィールド上に表側表示で存在するチューナーの数×５００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"半魚獣・フィッシャービースト",h:"はんぎょじゅう・ふぃっしゃーびーすと",e:"Amphibian Beast",ra:"N",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"魚族",mo:["通常"],ma:"",tp:"",at:2400,de:2000,tx:"陸では獣のように、海では魚のように素早く攻撃する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ハングリーバーガー",h:"はんぐりーばーがー",e:"Hungry Burger",ra:"N",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"戦士族",mo:["儀式"],ma:"",tp:"",at:2000,de:1850,tx:"「ハンバーガーのレシピ」により降臨。\nフィールドか手札から、レベルが６以上になるようカードを生け贄に捧げなければならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"半月竜ラディウス",h:"はんげつりゅうらでぃうす",e:"Radius, the Half-Moon Dragon",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1400,de:1200,tx:"相手フィールド上にエクシーズモンスターが存在する場合、このカードは手札から特殊召喚できる。\nこの方法で特殊召喚したこのカードのレベルは８になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"半蛇人サクズィー",h:"はんじゃじんさくずぃー",e:"Cobraman Sakuzy",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:800,de:1400,tx:"このカードは１ターンに１度だけ裏側守備表示にする事ができる。\nこのカードがリバースした時、相手フィールド上にセットされている魔法・罠カードを全てめくり、確認した後元に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ハンター・アウル",h:"はんたー・あうる",e:"Hunter Owl",ra:"R",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1000,de:900,tx:"自分フィールド上に表側表示で存在する風属性モンスター１体につき、このカードの攻撃力は５００ポイントアップする。\nまた、自分フィールド上に他の風属性モンスターが表側表示で存在する限り、相手はこのカードを攻撃対象に選択する事はできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ハンター・スパイダー",h:"はんたー・すぱいだー",e:"Hunter Spider",ra:"N",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"昆虫族",mo:["通常"],ma:"",tp:"",at:1600,de:1400,tx:"クモの巣の罠を仕掛け狩りをする。\n罠にかかったものは食べてしまう。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"パンダボーグ",h:"ぱんだぼーぐ",e:"Pandaborg",ra:"R",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1700,de:1400,tx:"このカードが戦闘によって破壊され墓地へ送られた時、８００ライフポイントを払って発動できる。\nデッキからレベル４のサイキック族モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"パンデミック・ドラゴン",h:"ぱんでみっく・どらごん",e:"Pandemic Dragon",ra:"N",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2500,de:1000,tx:"①：１ターンに１度、１００の倍数のLPを払って発動できる。\nこのカード以外のフィールドの表側表示モンスターの攻撃力は、この効果を発動するために払ったLPの数値分ダウンする。\n②：１ターンに１度、このカードの攻撃力以下の攻撃力を持つフィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊する。\n③：このカードが戦闘・効果で破壊された場合に発動する。\nフィールドの全ての表側表示モンスターの攻撃力は１０００ダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"百年竜",h:"はんどれっど・どらごん",e:"Hundred Dragon",ra:"SR",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：１ターンに１度、自分メインフェイズに発動できる。\nターン終了時まで、自分フィールドのカードの数だけこのカードのレベルを上げ、上げた数×１００だけこのカードの攻撃力をアップする。\n②：このカードが墓地へ送られた場合、EXデッキから特殊召喚された自分フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターはフィールドに表側表示で存在する限り１度だけ、戦闘・効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ハンニバル・ネクロマンサー",h:"はんにばる・ねくろまんさー",e:"Hannibal Necromancer",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1400,de:1800,tx:"①：このカードが召喚に成功した場合に発動する。\nこのカードに魔力カウンターを１つ置く（最大１つまで）。\n②：このカードの魔力カウンターを１つ取り除き、フィールドの表側表示の罠カード１枚を対象として発動できる。\nその表側表示の罠カードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"番猫－ウォッチキャット",h:"ばんびょう－うぉっちきゃっと",e:"Watch Cat",ra:"SR",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:0,de:1800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドにモンスターが存在しない場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが特殊召喚に成功した自分ターンのエンドフェイズにフィールドのこのカードを除外して発動できる。\nデッキから永続魔法カード１枚を選んで自分の魔法＆罠ゾーンにセットする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ハンプティ・ダンディ",h:"はんぷてぃ・だんでぃ",e:"Humpty Grumpty",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1400,de:0,tx:"①：自分メインフェイズに発動できる。\nこのカードを裏側守備表示にする（１ターンに１度のみ）。\n②：このカードが反転召喚に成功した場合に発動する。\nこのカードの攻撃力はターン終了時まで８００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"番兵ゴーレム",h:"ばんぺいごーれむ",e:"Golem Sentry",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:800,de:1800,tx:"このカードは１ターンに１度だけ裏側守備表示にする事ができる。\nこのカードが反転召喚に成功した時、相手フィールド上のモンスター１体を持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ハンマー・シャーク",h:"はんまー・しゃーく",e:"Hammer Shark",ra:"SR",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1700,de:1500,tx:"１ターンに１度、自分のメインフェイズ時に発動できる。\nこのカードのレベルを１つ下げ、手札から水属性・レベル３以下のモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ハンマーラッシュ・バウンサー",h:"はんまーらっしゅ・ばうんさー",e:"Hammer Bounzer",ra:"SR",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2300,de:2000,tx:"相手フィールド上にカードが存在し、自分フィールド上にカードが存在しない場合、このカードはリリースなしで召喚できる。\n自分フィールド上に魔法・罠カードが存在せず、このカードが相手モンスターに攻撃する場合、相手はダメージステップ終了時まで魔法・罠カードを発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"B－バスター・ドレイク",h:"びー－ばすたー・どれいく",e:"B-Buster Drake",ra:"UR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["ユニオン","効果"],ma:"",tp:"",at:1500,de:1800,tx:"①：１ターンに１度、以下の効果から１つを選択して発動できる。\n●自分フィールドの機械族・光属性モンスター１体を対象とし、このカードを装備カード扱いとしてそのモンスターに装備する。\n装備モンスターが戦闘・効果で破壊される場合、代わりにこのカードを破壊する。\n●装備されているこのカードを特殊召喚する。\n②：装備モンスターは他の相手の魔法カードの効果を受けない。\n③：このカードがフィールドから墓地へ送られた場合に発動できる。\nデッキからユニオンモンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"P・M・キャプチャー",h:"ぴー・えむ・きゃぷちゃー",e:"P.M. Captor",ra:"SR",t:"monster",a:"闇",l:4,ps:5,pe:"①：自分がアンデット族モンスターのP召喚に成功した時に発動できる。\nそのモンスターはこのターン戦闘・効果では破壊されない。",tr:"アンデット族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1800,de:0,tx:"①：このカードが戦闘でモンスターを破壊した場合、自分の墓地のPモンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"B・F－降魔弓のハマ",h:"びー・ふぉーす－こうまきゅうのはま",e:"Battlewasp - Hama the Conquering Bow",ra:"UR",t:"monster",a:"風",l:8,ps:"",pe:"",tr:"昆虫族",mo:["シンクロ","効果"],ma:"",tp:"",at:2800,de:2000,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：Sモンスターを素材としてS召喚したこのカードは１度のバトルフェイズ中に２回攻撃できる。\n②：このカードが相手に戦闘ダメージを与えた時に発動できる。\n相手フィールドの全てのモンスターの攻撃力・守備力は１０００ダウンする。\n③：相手が戦闘ダメージを受けなかった自分バトルフェイズの終了時に発動できる。\n自分の墓地の「B・F」モンスターの数×３００ダメージを相手に与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"B・F－突撃のヴォウジェ",h:"びー・ふぉーす－とつげきのう゛ぉうじぇ",e:"Battlewasp - Halberd the Charge",ra:"N",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"昆虫族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:800,tx:"昆虫族チューナー＋チューナー以外のモンスター１体以上\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードがこのカードの攻撃力以上の攻撃力を持つ相手モンスターに攻撃するダメージ計算時に１度、発動できる。\nその相手モンスターの攻撃力はダメージ計算時のみ半分になる。\n②：このカードが相手に戦闘ダメージを与えた時に発動できる。\n自分フィールドの「B・F」モンスターの数×２００ダメージを相手に与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"B・F－早撃ちのアルバレスト",h:"びー・ふぉーす－はやうちのあるばれすと",e:"Battlewasp - Arbalest the Rapidfire",ra:"R",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1800,de:800,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時、自分の墓地のレベル３以下の昆虫族モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\n②：このカードが相手によって破壊された場合に発動できる。\n手札・デッキから「B・F」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"B・F－必中のピン",h:"びー・ふぉーす－ひっちゅうのぴん",e:"Battlewasp - Pin the Bullseye",ra:"N",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:200,de:300,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドに昆虫族モンスターが存在する場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：自分メインフェイズに発動できる。\n自分フィールドの「B・F－必中のピン」の数×２００ダメージを相手に与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"B・F－連撃のツインボウ",h:"びー・ふぉーす－れんげきのついんぼう",e:"Battlewasp - Twinbow the Attacker",ra:"N",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1000,de:500,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\nこのカードを手札から特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は昆虫族モンスターしかEXデッキから特殊召喚できない。\n②：このカードは１度のバトルフェイズ中に２回攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ビーストアイズ・ペンデュラム・ドラゴン",h:"びーすとあいず・ぺんでゅらむ・どらごん",e:"Beast-Eyes Pendulum Dragon",ra:"UR",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:3000,de:2000,tx:"ドラゴン族・闇属性モンスター＋獣族モンスター\nこのカードは融合召喚及び以下の方法でのみ特殊召喚できる。\n●自分フィールドの上記カードをリリースした場合にエクストラデッキから特殊召喚できる（「融合」は必要としない）。\n①：このカードが戦闘でモンスターを破壊した場合に発動する。\nこのカードの融合素材とした獣族モンスター１体の元々の攻撃力分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ビーストライカー",h:"びーすとらいかー",e:"Beast Striker",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1850,de:400,tx:"手札を１枚捨てて発動する。\n自分のデッキから「モジャ」１体を特殊召喚する。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヒール・ウェーバー",h:"ひーる・うぇーばー",e:"Healing Wave Generator",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:800,de:1600,tx:"このカード以外の自分フィールド上に表側表示で存在するモンスター１体を選択して発動する。\n選択したモンスターのレベル×１００ライフポイント回復する。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヒーロー・キッズ",h:"ひーろー・きっず",e:"Hero Kid",ra:"R",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:300,de:600,tx:"このカードが特殊召喚に成功した時、デッキから「ヒーロー・キッズ」を任意の枚数特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ビーン・ソルジャー",h:"びーん・そるじゃー",e:"Bean Soldier",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"植物族",mo:["通常"],ma:"",tp:"",at:1400,de:1300,tx:"剣やマメを使って攻撃してくる植物戦士。\n意外と強いぞ。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヒエラコスフィンクス",h:"ひえらこすふぃんくす",e:"Hieracosphinx",ra:"R",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:2400,de:1200,tx:"このカードが自分フィールド上に表側表示で存在する限り、相手は裏側守備表示モンスターを攻撃対象に選択する事ができない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"微炎星－リュウシシン",h:"びえんせい－りゅうししん",e:"Brotherhood of the Fire Fist - Dragon",ra:"SR",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:400,tx:"１ターンに１度、自分が「炎舞」と名のついた魔法・罠カードを発動した場合、デッキから「炎舞」と名のついた罠カード１枚を選んで自分フィールド上にセットできる。\nまた、１ターンに１度、自分フィールド上に表側表示で存在する「炎舞」と名のついた魔法・罠カード２枚を墓地へ送って発動できる。\n自分の墓地から「微炎星－リュウシシン」以外の「炎星」と名のついたモンスター１体を選択して特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"光の精霊 ディアーナ",h:"ひかりのせいれい でぃあーな",e:"Diana the Light Spirit",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"雷族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1700,de:1000,tx:"このカードは通常召喚できない。\n自分の墓地から光属性モンスター１体を除外した場合に特殊召喚できる。\n①：相手エンドフェイズに発動する。\n自分は１０００LP回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"光の追放者",h:"ひかりのついほうしゃ",e:"Banisher of the Light",ra:"SR",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:100,de:2000,tx:"このカードがフィールド上に表側表示で存在する限り、墓地へ送られるカードは墓地へは行かずゲームから除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"彼岸の悪鬼 ドラゴネル",h:"ひがんのあっき　どらごねる",e:"Draghig, Malebranche of the Burning Abyss",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1100,de:900,tx:"「彼岸の悪鬼 ドラゴネル」の①③の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドに魔法・罠カードが存在しない場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：自分フィールドに「彼岸」モンスター以外のモンスターが存在する場合にこのカードは破壊される。\n③：このカードが墓地へ送られた場合に発動できる。\nデッキから「彼岸」カード１枚を選んでデッキの一番上に置く。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"彼岸の悪鬼 バルバリッチャ",h:"ひがんのあっき　ばるばりっちゃ",e:"Barbar, Malebranche of the Burning Abyss",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"「彼岸の悪鬼 バルバリッチャ」の①③の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドに魔法・罠カードが存在しない場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：自分フィールドに「彼岸」モンスター以外のモンスターが存在する場合にこのカードは破壊される。\n③：このカードが墓地へ送られた場合、「彼岸の悪鬼 バルバリッチャ」以外の自分の墓地の「彼岸」カードを３枚まで対象として発動できる。\nそのカードを除外し、除外した数×３００ダメージを相手に与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"彼岸の悪鬼 ハロウハウンド",h:"ひがんのあっき　はろうはうんど",e:"Cagna, Malebranche of the Burning Abyss",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1500,de:300,tx:"「彼岸の悪鬼 ハロウハウンド」の①③の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドに魔法・罠カードが存在しない場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：自分フィールドに「彼岸」モンスター以外のモンスターが存在する場合にこのカードは破壊される。\n③：このカードが墓地へ送られた場合に発動できる。\nデッキから「彼岸」魔法・罠カード１枚を墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"彼岸の悪鬼 リビオッコ",h:"ひがんのあっき　りびおっこ",e:"Libic, Malebranche of the Burning Abyss",ra:"R",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1300,de:700,tx:"「彼岸の悪鬼 リビオッコ」の①③の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドに魔法・罠カードが存在しない場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：自分フィールドに「彼岸」モンスター以外のモンスターが存在する場合にこのカードは破壊される。\n③：このカードが墓地へ送られた場合に発動できる。\n手札から悪魔族・闇属性・レベル３モンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"彼岸の鬼神 ヘルレイカー",h:"ひがんのきしん　へるれいかー",e:"Malacoda, Netherlord of the Burning Abyss",ra:"SR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["儀式","効果"],ma:"",tp:"",at:2700,de:2200,tx:"「善悪の彼岸」により降臨。\nこのカードは儀式召喚でしか特殊召喚できない。\n①：１ターンに１度、手札から「彼岸」モンスター１体を墓地へ送り、相手フィールドの表側表示モンスター１体を対象として発動できる。\nその相手モンスターの攻撃力・守備力はターン終了時まで、この効果を発動するために墓地へ送ったモンスターのそれぞれの数値分ダウンする。\nこの効果は相手ターンでも発動できる。\n②：このカードがフィールドから墓地へ送られた場合、フィールドのカード１枚を対象として発動できる。\nそのカードを墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"引きガエル",h:"ひきがえる",e:"Poison Draw Frog",ra:"R",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"フィールド上に表側表示で存在するこのカードが墓地へ送られた時、自分のデッキからカードを１枚ドローする事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ピクシーガーディアン",h:"ぴくしーがーでぃあん",e:"Fairy Guardian",ra:"N",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"表側表示のこのカードを生け贄に捧げる。\nこのターン相手によって墓地に送られた自分の魔法カード１枚をデッキの一番下に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ピクシーナイト",h:"ぴくしーないと",e:"Pixie Knight",ra:"N",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1300,de:200,tx:"このカードが戦闘によって墓地に送られた時、自分の墓地の魔法カード１枚を相手が選択し、そのカードを自分のデッキの一番上に置く。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ビクトリー・バイパー XX03",h:"びくとりー・ばいぱー　だぶるえっくすぜろすりー",e:"Victory Viper XX03",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:1000,tx:"①：このカードが戦闘で相手モンスターを破壊した場合、以下の効果から１つを選択して発動する。\n●このカードの攻撃力は４００アップする。\n●フィールドの表側表示の魔法・罠カード１枚を対象として発動する。\nその表側表示のカードを破壊する。\n●常にこのカードと同じ種族・属性・レベル・攻撃力・守備力を持つ「オプショントークン」１体を自分フィールドに特殊召喚する。\nこのカードがフィールドに表側表示で存在しなくなった時にこのトークンは破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヒゲアンコウ",h:"ひげあんこう",e:"Unshaven Angler",ra:"R",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1500,de:1600,tx:"水属性モンスターを生け贄召喚する場合、このモンスター１体で２体分の生け贄とする事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"飛行エレファント",h:"ひこうえれふぁんと",e:"Flying Elephant",ra:"UR",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1850,de:1300,tx:"①：このカードは相手ターンに１度だけ、相手の効果では破壊されない。\n②：このカードの①の効果を適用した相手ターンのエンドフェイズに発動する。\n次の自分ターン中、以下を適用する。\n●このカードが直接攻撃で相手に戦闘ダメージを与えた時、自分はデュエルに勝利する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヒステリック天使",h:"ひすてりっくえんじぇる",e:"Hysteric Fairy",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1800,de:500,tx:"自分フィールド上に存在するモンスター２体をリリースして発動する。\n自分は１０００ライフポイント回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ビック・アント",h:"びっく・あんと",e:"Big Insect",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["通常"],ma:"",tp:"",at:1200,de:1500,tx:"密林に住む巨大アリ。\n攻撃・守備ともに意外と強い。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ビッグ・コアラ",h:"びっぐ・こあら",e:"Big Koala",ra:"R",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:2700,de:2000,tx:"とても巨大なデス・コアラの一種。\nおとなしい性格だが、非常に強力なパワーを持っているため恐れられている。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ビッグ・シールド・ガードナー",h:"びっぐ・しーるど・がーどなー",e:"Big Shield Gardna",ra:"UR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:100,de:2600,tx:"①：裏側表示のこのモンスター１体のみを対象とする魔法カードが発動した時に発動する。\nこのカードを表側守備表示にし、その発動を無効にする。\n②：このカードは攻撃された場合、ダメージステップ終了時に攻撃表示になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ビッグ・ジョーズ",h:"びっぐ・じょーず",e:"Big Jaws",ra:"R",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1800,de:300,tx:"このカードは攻撃した場合、バトルフェイズ終了時にゲームから除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ビッグ・ピース・ゴーレム",h:"びっぐ・ぴーす・ごーれむ",e:"Big Piece Golem",ra:"SR",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:2100,de:0,tx:"相手フィールド上にモンスターが存在し、自分フィールド上にモンスターが存在しない場合、このカードはリリースなしで召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ビッグ・ホエール",h:"びっぐ・ほえーる",e:"Big Whale",ra:"N",t:"monster",a:"水",l:9,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1000,de:3000,tx:"このカードがアドバンス召喚に成功した時、このカードをリリースして発動できる。\nデッキからレベル３の水属性モンスター３体を特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ビッグ・ワン・ウォリアー",h:"びっぐ・わん・うぉりあー",e:"Big One Warrior",ra:"N",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:100,de:600,tx:"自分のメインフェイズ時、このカード以外の手札のレベル１モンスター１体を墓地へ送って発動する事ができる。\nこのカードを手札から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ビッグバンガール",h:"びっぐばんがーる",e:"Fire Princess",ra:"R",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:1300,de:1500,tx:"このカードがフィールド上に表側表示で存在する限り、自分のライフポイントが回復する度に、相手ライフに５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ビッグバンドラゴン",h:"びっぐばんどらごん",e:"Twin-Headed Fire Dragon",ra:"N",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"炎族",mo:["通常"],ma:"",tp:"",at:2200,de:1700,tx:"宇宙ができた時に生まれた竜。\nその衝撃で双子の竜が合体して１つの体になってしまった。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ビッグホーン・マンモス",h:"びっぐほーん・まんもす",e:"Big-Tusked Mammoth",ra:"SR",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:2000,de:1000,tx:"このカードがフィールド上に表側表示で存在する限り、相手フィールド上モンスターは召喚・反転召喚・特殊召喚したターンには攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ビットロン",h:"びっとろん",e:"Bitron",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"サイバース族",mo:["通常"],ma:"",tp:"",at:200,de:2000,tx:"電子空間で見つけた新種。\nその情報量は少ない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"否定ペンギン",h:"ひていぺんぎん",e:"Nopenguin",ra:"N",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1600,de:100,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、「ペンギン」カードの効果でフィールドから手札に戻るカードは手札に戻らず除外される。\n②：このカードが墓地に存在し、「ペンギン」モンスターの効果が発動した時に発動する。\nこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"人喰い植物",h:"ひとくいしょくぶつ",e:"Man-Eating Plant",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"植物族",mo:["通常"],ma:"",tp:"",at:800,de:600,tx:"きれいな花と思わせ、近づく人をパクリと食べる、肉食の花。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"人喰い宝石箱",h:"ひとくいほうせきばこ",e:"Man-Eating Treasure Chest",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1600,de:1000,tx:"宝石箱の形をしたモンスター。\n冒険者をだまして襲いかかる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"人喰い虫",h:"ひとくいむし",e:"Man-Eater Bug",ra:"UR",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"昆虫族",mo:["リバース","効果"],ma:"",tp:"",at:450,de:600,tx:"①：このカードがリバースした場合、フィールドのモンスター１体を対象として発動する。\nそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヒトデンチャク",h:"ひとでんちゃく",e:"Hitodenchak",ra:"N",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:600,de:700,tx:"汚染された水で狂暴化したヒトデ。\n口から溶解液をはく。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"人投げトロール",h:"ひとなげとろーる",e:"Man-Thro' Tro'",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"自分フィールド上に存在する通常モンスター（トークンを除く）１体を生け贄に捧げる度に、相手ライフに８００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"独法師",h:"ひとりぼうし",e:"Bonze Alone",ra:"N",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"自分フィールドにモンスターが存在する場合、このカードは召喚・特殊召喚できない。\n①：このカードは手札から攻撃表示で特殊召喚できる。\n②：このカードが既にモンスターゾーンに存在する状態で、自分フィールドにこのカード以外のモンスターが召喚・反転召喚・特殊召喚された場合に発動する。\nこのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"火之迦具土",h:"ひのかぐつち",e:"Hino-Kagu-Tsuchi",ra:"SR",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"炎族",mo:["スピリット","効果"],ma:"",tp:"",at:2800,de:2900,tx:"このカードは特殊召喚できない。\n召喚・リバースしたターンのエンドフェイズ時に持ち主の手札に戻る。\nまた、このカードが相手ライフに戦闘ダメージを与えた時に発動する。\n次のターンのドローフェイズのドロー前に相手は手札を全て捨てる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"響女",h:"ひびきめ",e:"Hibikime",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1450,de:1000,tx:"耳障りな音をガンガン響かせる。\n相手を行動不能にしてしまう。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ビビット騎士",h:"びびっとないと",e:"Vivid Knight",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:800,tx:"自分フィールド上の獣戦士族・光属性モンスター１体が、相手のカードの効果の対象になった時、または相手モンスターの攻撃対象になった時に発動できる。\n対象となった自分のモンスターを次の自分のスタンバイフェイズ時までゲームから除外し、このカードを手札から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"姫葵マリーナ",h:"ひまりまりーな",e:"MariÑa, Princess of Sunflowers",ra:"SR",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:2800,de:1600,tx:"このカードがフィールド上に表側表示で存在する限り、このカード以外の自分フィールド上の植物族モンスター１体が戦闘またはカードの効果によって破壊され墓地へ送られた場合、相手フィールド上のカード１枚を選択して破壊できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"白夜の騎士ガイア",h:"びゃくやのきしがいあ",e:"Gaia, the Mid-Knight Sun",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"「白夜の騎士ガイア」の以下の効果はそれぞれ１ターンに１度ずつ使用できる。\n●このカード以外の自分フィールド上の光属性モンスター１体をリリースして発動できる。\nデッキから戦士族・闇属性・レベル４モンスター１体を手札に加え、その後手札を１枚墓地へ送る。\n●自分の墓地の闇属性モンスター１体を除外し、フィールド上のモンスター１体を選択して発動できる。\n選択したモンスターの攻撃力は、相手のエンドフェイズ時まで５００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"白虎の召喚士",h:"びゃっこのしょうかんし",e:"White Tiger Summoner",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1000,de:1700,tx:"①：このカードが召喚に成功した時に発動できる。\n手札からレベル４以下の通常モンスター１体を特殊召喚する。\n②：このカードがモンスターゾーンに存在する限り、自分フィールドのモンスターの攻撃力・守備力は１００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヒューマノイド・スライム",h:"ひゅーまのいど・すらいむ",e:"Humanoid Slime",ra:"SR",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:800,de:2000,tx:"人間の形をしたスライム。\n人間の遺伝子が組み込まれている。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヒューマノイド・ドレイク",h:"ひゅーまのいど・どれいく",e:"Humanoid Worm Drake",ra:"R",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"水族",mo:["融合"],ma:"",tp:"",at:2200,de:2000,tx:"「ワームドレイク」＋「ヒューマノイド・スライム」",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ピューマン",h:"ぴゅーまん",e:"Akz, the Pumer",ra:"R",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"獣戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1500,de:1000,tx:"このカードは通常召喚できない。\n自分の墓地に存在する獣戦士族モンスター２体をゲームから除外した場合に特殊召喚する事ができる。\n１ターンに１度、手札から獣戦士族モンスター１体を捨て、以下の効果から１つを選択して発動する事ができる。\n●このカードの攻撃力はエンドフェイズ時まで倍になる。\n●このターンこのカードは相手プレイヤーに直接攻撃する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヒュプノシスター",h:"ひゅぷのしすたー",e:"Hypnosister",ra:"N",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1300,de:1400,tx:"①：お互いのPゾーンのカードの数によって、このカードは以下の効果を得る。\n\n●１枚以上：このカードの攻撃力・守備力は８００アップする。\n\n●２枚以上：このカードがP召喚されたモンスターと戦闘を行うダメージステップ開始時に発動する。\nそのモンスターを破壊する。\n\n●３枚以上：相手フィールドのモンスターは攻撃可能な場合、このカードを攻撃しなければならない。\n\n●４枚：このカードが戦闘またはこのカードの効果でモンスターを破壊した場合に発動する。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"氷",h:"ひょう",e:"Hyo",ra:"N",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:800,de:1200,tx:"全身が氷でできている戦士。\n触れるものを何でも凍らせてしまう。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"憑依するブラッド・ソウル",h:"ひょういするぶらっど・そうる",e:"Possessed Dark Soul",ra:"R",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1200,de:800,tx:"このカードをリリースして発動する。\n相手フィールド上に表側表示で存在する全てのレベル３以下のモンスターのコントロールを得る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"憑依装着－アウス",h:"ひょういそうちゃく－あうす",e:"Familiar-Possessed - Aussa",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1850,de:1500,tx:"①：このカードは自分フィールドの表側表示の、「地霊使いアウス」１体と地属性モンスター１体を墓地へ送り、手札・デッキから特殊召喚できる。\n②：このカードの①の方法で特殊召喚したこのカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"憑依装着－ウィン",h:"ひょういそうちゃく－うぃん",e:"Familiar-Possessed - Wynn",ra:"SR",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1850,de:1500,tx:"①：このカードは自分フィールドの表側表示の、「風霊使いウィン」１体と風属性モンスター１体を墓地へ送り、手札・デッキから特殊召喚できる。\n②：このカードの①の方法で特殊召喚したこのカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"憑依装着－エリア",h:"ひょういそうちゃく－えりあ",e:"Familiar-Possessed - Eria",ra:"SR",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1850,de:1500,tx:"①：このカードは自分フィールドの表側表示の、「水霊使いエリア」１体と水属性モンスター１体を墓地へ送り、手札・デッキから特殊召喚できる。\n②：このカードの①の方法で特殊召喚したこのカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"憑依装着－ダルク",h:"ひょういそうちゃく－だるく",e:"Familiar-Possessed - Dharc",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1850,de:1500,tx:"このカードは自分フィールド上の「闇霊使いダルク」１体と闇属性モンスター１体を墓地へ送り、手札またはデッキから特殊召喚できる。\nこの方法で特殊召喚に成功した時、デッキからレベル３またはレベル４の魔法使い族・光属性モンスター１体を手札に加える事ができる。\nまた、この方法で特殊召喚したこのカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"憑依装着－ヒータ",h:"ひょういそうちゃく－ひーた",e:"Familiar-Possessed - Hiita",ra:"SR",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1850,de:1500,tx:"①：このカードは自分フィールドの表側表示の、「火霊使いヒータ」１体と炎属性モンスター１体を墓地へ送り、手札・デッキから特殊召喚できる。\n②：このカードの①の方法で特殊召喚したこのカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"憑依装着－ライナ",h:"ひょういそうちゃく－らいな",e:"Familiar-Possessed - Lyna",ra:"UR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1850,de:1500,tx:"①：このカードは自分フィールドの表側表示の、「光霊使いライナ」１体と光属性モンスター１体を墓地へ送り、手札・デッキから特殊召喚できる。\n②：このカードの①の方法で特殊召喚に成功した時に発動できる。\nデッキから「憑依装着－ライナ」以外の守備力１５００の魔法使い族モンスター１体を手札に加える。\n③：このカードの①の方法で特殊召喚したこのカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"氷岩魔獣",h:"ひょうがんまじゅう",e:"Freezing Beast",ra:"R",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["ユニオン","効果"],ma:"",tp:"",at:1500,de:1000,tx:"１ターンに１度だけ自分のメインフェイズに装備カード扱いとして自分の「灼岩魔獣」に装備、または装備を解除して表側攻撃表示で特殊召喚する事ができる。\nこの効果で装備カード扱いになっている時のみ、装備モンスターが相手プレイヤーに戦闘ダメージを与えた場合、フィールド上で裏側表示の魔法または罠カード１枚を破壊する。\n（１体のモンスターが装備できるユニオンは１枚まで。\n装備モンスターが戦闘によって破壊される場合は、代わりにこのカードを破壊する。\n）",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"氷結界に住む魔酔虫",h:"ひょうけっかいにすむますいちゅう",e:"Numbing Grub in the Ice Barrier",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1300,de:1800,tx:"このカードが召喚に成功した時、使用していないモンスターカードゾーンを１ヵ所指定して発動する。\nこのカードがフィールド上に表側表示で存在する限り、指定したモンスターカードゾーンは使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"氷結界の御庭番",h:"ひょうけっかいのおにわばん",e:"Secret Guards of the Ice Barrier",ra:"N",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:100,de:1600,tx:"このカードがフィールド上に表側表示で存在する限り、自分フィールド上の「氷結界」と名のついたモンスターは相手の効果モンスターの効果の対象にならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"氷結界の軍師",h:"ひょうけっかいのぐんし",e:"Strategist of the Ice Barrier",ra:"R",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1600,de:1600,tx:"①：１ターンに１度、手札から「氷結界」モンスター１体を墓地へ送って発動できる。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"氷結界の決起隊",h:"ひょうけっかいのけっきたい",e:"Shock Troops of the Ice Barrier",ra:"N",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1500,de:800,tx:"このカードをリリースして発動できる。\nフィールド上の水属性モンスター１体を選択して破壊し、デッキから「氷結界」と名のついたモンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"氷結界の交霊師",h:"ひょうけっかいのこうれいし",e:"Medium of the Ice Barrier",ra:"SR",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2200,de:1600,tx:"①：相手フィールドのカードの数が自分フィールドのカードより４枚以上多い場合、このカードは手札から特殊召喚できる。\n②：このカードがモンスターゾーンに存在する限り、相手は１ターンに１度しか魔法・罠カードを発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"氷結界の虎将 ガンターラ",h:"ひょうけっかいのこしょう　がんたーら",e:"General Gantala of the Ice Barrier",ra:"SR",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2700,de:2000,tx:"①：自分エンドフェイズに、「氷結界の虎将 ガンターラ」以外の自分の墓地の「氷結界」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"氷結界の虎将 グルナード",h:"ひょうけっかいのこしょう　ぐるなーど",e:"General Grunard of the Ice Barrier",ra:"N",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2800,de:1000,tx:"①：このカードがモンスターゾーンに存在する限り、自分は通常召喚に加えて１度だけ、自分メインフェイズに「氷結界」モンスター１体を召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"氷結界の虎将 ライホウ",h:"ひょうけっかいのこしょう　らいほう",e:"General Raiho of the Ice Barrier",ra:"R",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2100,de:2300,tx:"①：このカードがモンスターゾーンに存在する限り、フィールドで発動した相手モンスターの効果処理時に、相手は手札を１枚捨てる事ができる。\n捨てなかった場合、その効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"氷結界の修験者",h:"ひょうけっかいのしゅげんじゃ",e:"Pilgrim of the Ice Barrier",ra:"SR",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"このカードは攻撃力１９００以上のモンスターとの戦闘では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"氷結界の神精霊",h:"ひょうけっかいのしんしょうれい",e:"Sacred Spirit of the Ice Barrier",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"アンデット族",mo:["スピリット","効果"],ma:"",tp:"",at:1600,de:1200,tx:"このカードは特殊召喚できない。\n召喚・リバースしたターンのエンドフェイズ時に持ち主の手札に戻る。\n自分フィールド上にこのカード以外の「氷結界」と名のついたモンスターが存在する場合、エンドフェイズ時に発動するこのカードの効果は「相手フィールド上のモンスター１体を選択して持ち主の手札に戻す」となる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"氷結界の大僧正",h:"ひょうけっかいのだいそうじょう",e:"Dai-sojo of the Ice Barrier",ra:"R",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1600,de:2200,tx:"①：このカードが召喚・反転召喚に成功した場合に発動する。\nこのカードを守備表示にする。\n②：このカードがモンスターゾーンに存在する限り、自分フィールドの「氷結界」モンスターは魔法・罠カードの効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"氷結界の伝道師",h:"ひょうけっかいのでんどうし",e:"Prior of the Ice Barrier",ra:"R",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1000,de:400,tx:"①：自分フィールドに「氷結界」モンスターが存在する場合、このカードは手札から特殊召喚できる。\nこの方法で特殊召喚するターン、自分はレベル５以上のモンスターを特殊召喚できない。\n②：このカードをリリースし、「氷結界の伝道師」以外の自分の墓地の「氷結界」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"氷結界の破術師",h:"ひょうけっかいのはじゅつし",e:"Warlock of the Ice Barrier",ra:"N",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:400,de:1000,tx:"①：自分フィールドに他の「氷結界」モンスターが存在する限り、お互いに魔法カードはセットしなければ発動できず、セットしたプレイヤーから見て次の自分ターンが来るまで発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"氷結界の番人 ブリズド",h:"ひょうけっかいのばんにん　ぶりずど",e:"Blizzed, Defender of the Ice Barrier",ra:"N",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:300,de:500,tx:"①：このカードが戦闘で破壊され墓地へ送られた場合に発動する。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"氷結界の風水師",h:"ひょうけっかいのふうすいし",e:"Geomancer of the Ice Barrier",ra:"N",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:1200,tx:"手札を１枚捨て、属性を１つ宣言して発動できる。\n宣言した属性のモンスターはこのカードを攻撃対象に選択できない。\nこの効果はこのカードがフィールド上に表側表示で存在する限り１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"氷結界の封魔団",h:"ひょうけっかいのふうまだん",e:"Spellbreaker of the Ice Barrier",ra:"SR",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1200,de:2000,tx:"①：１ターンに１度、手札から「氷結界」モンスター１体を墓地へ送って発動できる。\nこのモンスターが表側表示で存在する間、次の自分ターンの終了時までお互いに魔法カードを発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"氷結界の舞姫",h:"ひょうけっかいのまいひめ",e:"Dance Princess of the Ice Barrier",ra:"SR",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1700,de:900,tx:"①：１ターンに１度、自分フィールドに他の「氷結界」モンスターが存在する場合、手札の「氷結界」モンスターを任意の数だけ相手に見せ、その数だけ相手フィールドにセットされた魔法・罠カードを対象として発動できる。\nセットされたそのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"氷結界の水影",h:"ひょうけっかいのみずかげ",e:"Dewdark of the Ice Barrier",ra:"N",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"水族",mo:["チューナー","効果"],ma:"",tp:"",at:1200,de:800,tx:"自分フィールド上に表側表示で存在するモンスターがレベル２以下のモンスターのみの場合、このカードは相手プレイヤーに直接攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"氷結界の武士",h:"ひょうけっかいのもののふ",e:"Samurai of the Ice Barrier",ra:"R",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1500,tx:"フィールド上に表側攻撃表示で存在するこのカードが表側守備表示になった時、このカードを破壊し、デッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"氷結界の輸送部隊",h:"ひょうけっかいのゆそうぶたい",e:"Caravan of the Ice Barrier",ra:"N",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:500,de:200,tx:"１ターンに１度、自分の墓地の「氷結界」と名のついたモンスター２体を選択して発動できる。\n選択したモンスター２体をデッキに戻してシャッフルする。\nその後、お互いにデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"氷結界の龍 グングニール",h:"ひょうけっかいのりゅう　ぐんぐにーる",e:"Gungnir, Dragon of the Ice Barrier",ra:"SR",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:1700,tx:"チューナー＋チューナー以外の水属性モンスター１体以上\n①：１ターンに１度、手札を２枚まで墓地へ捨て、捨てた数だけ相手フィールドのカードを対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"氷結界の龍 ブリューナク",h:"ひょうけっかいのりゅう　ぶりゅーなく",e:"Brionac, Dragon of the Ice Barrier",ra:"UR",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"海竜族",mo:["シンクロ","効果"],ma:"",tp:"",at:2300,de:1400,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の効果は１ターンに１度しか使用できない。\n①：手札を任意の枚数墓地へ捨て、捨てた数だけ相手フィールドのカードを対象として発動できる。\nそのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"氷結界のロイヤル・ナイト",h:"ひょうけっかいのろいやる・ないと",e:"Royal Knight of the Ice Barrier",ra:"N",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2000,de:2000,tx:"このカードがアドバンス召喚に成功した時、相手フィールド上に「アイスコフュントークン」（水族・水・星１・攻１０００／守０）１体を攻撃表示で特殊召喚する。\nこのトークンはアドバンス召喚のためにはリリースできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"氷結のフィッツジェラルド",h:"ひょうけつのふぃっつじぇらるど",e:"Frozen Fitzgerald",ra:"N",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"悪魔族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:2500,tx:"闇属性チューナー＋チューナー以外の獣族モンスター１体\nこのカードが攻撃する場合、相手はダメージステップ終了時まで魔法・罠カードを発動する事ができない。\nこのカードが戦闘によって破壊され墓地へ送られた時、自分フィールド上にモンスターが存在しない場合、手札を１枚捨てる事でこのカードを墓地から表側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"氷獄龍 トリシューラ",h:"ひょうごくりゅう　とりしゅーら",e:"Trishula, the Dragon of Icy Imprisonment",ra:"UR",t:"monster",a:"水",l:9,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:2700,de:2000,tx:"カード名が異なるモンスター×３\nこのカードは自分の手札・フィールドのモンスターのみを素材とした融合召喚及び以下の方法でのみEXデッキから特殊召喚できる。\n●自分フィールドの上記カードを除外した場合にEXデッキから特殊召喚できる（「融合」は必要としない）。\n①：元々の種族がドラゴン族のモンスターのみを素材としてこのカードが特殊召喚に成功した場合に発動できる。\n自分のデッキ・相手のデッキの一番上・相手のEXデッキの順に確認してそれぞれ１枚ずつ除外する。\nこのカード名のこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"秒殺の暗殺者",h:"びょうさつのあんさつしゃ",e:"Flash Assailant",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2000,de:2000,tx:"このカードの攻撃力・守備力は、自分の手札の枚数×４００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ひょうすべ",h:"ひょうすべ",e:"Hyosube",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1500,de:900,tx:"カッパの親分。\n攻撃力は意外と高い。\n守備力は低め。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"氷帝家臣エッシャー",h:"ひょうていかしんえっしゃー",e:"Escher the Frost Vassal",ra:"R",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:800,de:1000,tx:"①：相手の魔法＆罠ゾーンにカードが２枚以上存在する場合、このカードは手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"氷帝メビウス",h:"ひょうていめびうす",e:"Mobius the Frost Monarch",ra:"UR",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:2400,de:1000,tx:"①：このカードがアドバンス召喚に成功した時、フィールドの魔法・罠カードを２枚まで対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"比翼レンリン",h:"ひよくれんりん",e:"Leng Ling",ra:"SR",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["ユニオン","効果"],ma:"",tp:"",at:1500,de:0,tx:"①：１ターンに１度、以下の効果から１つを選択して発動できる。\n●自分フィールドの表側表示モンスター１体を対象とし、このカードを装備カード扱いとしてそのモンスターに装備する。\n装備モンスターが戦闘・効果で破壊される場合、代わりにこのカードを破壊する。\n●装備されているこのカードを特殊召喚する。\n②：装備モンスターの元々の攻撃力は１０００になり、１度のバトルフェイズ中に２回攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ぴよコッコ",h:"ぴよこっこ",e:"Cheepcheepcheep",ra:"SR",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"鳥獣族",mo:["リバース","効果"],ma:"",tp:"",at:300,de:200,tx:"リバース：デッキからレベル５以上のチューナー１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ピラミッド・タートル",h:"ぴらみっど・たーとる",e:"Pyramid Turtle",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1200,de:1400,tx:"①：このカードが戦闘で破壊され墓地へ送られた時に発動できる。\nデッキから守備力２０００以下のアンデット族モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"H－C エクスカリバー",h:"ひろいっく－ちゃんぴおん　えくすかりばー",e:"Heroic Champion - Excalibur",ra:"UR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2000,de:2000,tx:"戦士族レベル４モンスター×２\n１ターンに１度、このカードのエクシーズ素材を２つ取り除いて発動できる。\nこのカードの攻撃力は、次の相手のエンドフェイズ時まで元々の攻撃力の倍になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"H－C クサナギ",h:"ひろいっく－ちゃんぴおん　くさなぎ",e:"Heroic Champion - Kusanagi",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2500,de:2400,tx:"戦士族レベル４モンスター×３\n１ターンに１度、このカードのエクシーズ素材を１つ取り除いて発動できる。\n罠カードの発動を無効にし破壊する。\nその後、このカードの攻撃力は５００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"H・C アンブッシュ・ソルジャー",h:"ひろいっく・ちゃれんじゃー　あんぶっしゅ・そるじゃー",e:"Heroic Challenger - Ambush Soldier",ra:"N",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"自分のスタンバイフェイズ時、フィールド上のこのカードをリリースして発動できる。\n自分の手札・墓地から「H・C アンブッシュ・ソルジャー」以外の「H・C」と名のついたモンスターを２体まで選んで特殊召喚できる。\n「H・C アンブッシュ・ソルジャー」のこの効果は１ターンに１度しか使用できない。\nこの効果で特殊召喚に成功した時、墓地のこのカードをゲームから除外する事で、自分フィールド上の全ての「H・C」と名のついたモンスターのレベルを１にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"H・C ウォー・ハンマー",h:"ひろいっく・ちゃれんじゃー　うぉー・はんまー",e:"Heroic Challenger - War Hammer",ra:"N",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2100,de:1300,tx:"このカードが戦闘によって相手モンスターを破壊し墓地へ送った時、破壊したモンスターを装備カード扱いとしてこのカードに１体のみ装備できる。\nこのカードの攻撃力は、この効果で装備したモンスターの攻撃力分アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"H・C エクストラ・ソード",h:"ひろいっく・ちゃれんじゃー　えくすとら・そーど",e:"Heroic Challenger - Extra Sword",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードを素材としたエクシーズモンスターは以下の効果を得る。\n●このエクシーズ召喚に成功した時、このカードの攻撃力は１０００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"H・C 強襲のハルベルト",h:"ひろいっく・ちゃれんじゃー　きょうしゅうのはるべると",e:"Heroic Challenger - Assault Halberd",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:200,tx:"①：相手フィールドにモンスターが存在し、自分フィールドにモンスターが存在しない場合、このカードは手札から特殊召喚できる。\n②：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。\n③：このカードが相手に戦闘ダメージを与えた時に発動できる。\nデッキから「ヒロイック」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"H・C クラスプ・ナイフ",h:"ひろいっく・ちゃれんじゃー　くらすぷ・ないふ",e:"Heroic Challenger - Clasp Sword",ra:"N",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:300,de:100,tx:"このカードが「H・C」と名のついたモンスターの効果によって特殊召喚に成功した時、デッキから「H・C」と名のついたモンスター１体を手札に加える事ができる。\n「H・C クラスプ・ナイフ」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"H・C スパルタス",h:"ひろいっく・ちゃれんじゃー　すぱるたす",e:"Heroic Challenger - Spartan",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"１ターンに１度、相手モンスターの攻撃宣言時にこのカード以外の自分フィールド上の「ヒロイック」と名のついたモンスター１体を選択して発動できる。\nこのカードの攻撃力はバトルフェイズ終了時まで、選択したモンスターの元々の攻撃力分アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"H・C ソード・シールド",h:"ひろいっく・ちゃれんじゃー　そーど・しーるど",e:"Heroic Challenger - Swordshield",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:0,de:2000,tx:"自分フィールド上に「ヒロイック」と名のついたモンスターが存在する場合、このカードを手札から墓地へ送って発動できる。\nこのターン、戦闘によって発生する自分への戦闘ダメージは０になり、自分フィールド上の「ヒロイック」と名のついたモンスターは戦闘では破壊されない。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"H・C ダブル・ランス",h:"ひろいっく・ちゃれんじゃー　だぶる・らんす",e:"Heroic Challenger - Double Lance",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:900,tx:"このカードが召喚に成功した時、自分の手札・墓地から「H・C ダブル・ランス」１体を選んで表側守備表示で特殊召喚できる。\nこのカードはシンクロ素材にできない。\nまた、このカードをエクシーズ素材とする場合、戦士族モンスターのエクシーズ召喚にしか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"H・C 夜襲のカンテラ",h:"ひろいっく・ちゃれんじゃー　やしゅうのかんてら",e:"Heroic Challenger - Night Watchman",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:300,tx:"このカードが相手フィールド上に守備表示で存在するモンスターを攻撃した場合、ダメージ計算前にそのモンスターを破壊できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ファイターズ・エイプ",h:"ふぁいたーず・えいぷ",e:"Ape Fighter",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1900,de:1200,tx:"このカードが戦闘によって相手モンスターを破壊した場合、このカードの攻撃力は３００ポイントアップする。\nこのカードが自分のターンで攻撃を行っていない場合、この効果でアップした数値はそのターンのエンドフェイズ時に０に戻る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ファイナルサイコオーガ",h:"ふぁいなるさいこおーが",e:"Final Psychic Ogre",ra:"N",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:2200,de:1700,tx:"このカードが戦闘によって相手モンスターを破壊した場合、８００ライフポイントを払う事で自分の墓地に存在するサイキック族モンスター１体を選択して手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"F・G・D",h:"ふぁいぶ・ごっど・どらごん",e:"Five-Headed Dragon",ra:"UR",t:"monster",a:"闇",l:12,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:5000,de:5000,tx:"ドラゴン族モンスター×５\nこのカードは融合召喚でしか特殊召喚できない。\n①：このカードは闇・地・水・炎・風属性モンスターとの戦闘では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ファイヤー・アイ",h:"ふぁいやー・あい",e:"Fire Eye",ra:"N",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"炎族",mo:["通常"],ma:"",tp:"",at:800,de:600,tx:"炎につつまれた目玉。\n羽をはばたかせ、炎の風をおこす。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ファイヤー・ウイング・ペガサス",h:"ふぁいやー・ういんぐ・ぺがさす",e:"Firewing Pegasus",ra:"N",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:2250,de:1800,tx:"色鮮やかな真紅の翼をはばたかせ、天を駆け抜ける天馬。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ファイヤー・クラーケン",h:"ふぁいやー・くらーけん",e:"Fire Kraken",ra:"N",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1600,de:1500,tx:"水の中でもボウボウと燃え盛る炎に包まれたイカ。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ファイヤー・デビル",h:"ふぁいやー・でびる",e:"Fire Reaper",ra:"N",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"アンデット族",mo:["通常"],ma:"",tp:"",at:700,de:500,tx:"炎の矢を手にする死神。\nその矢にあたると火だるまになる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ファイヤー・トルーパー",h:"ふぁいやー・とるーぱー",e:"Fire Trooper",ra:"R",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードが召喚・反転召喚・特殊召喚に成功した時、このカードを墓地へ送って発動できる。\n相手ライフに１０００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ファイヤーソーサラー",h:"ふぁいやーそーさらー",e:"Fire Sorcerer",ra:"R",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["リバース","効果"],ma:"",tp:"",at:1000,de:1500,tx:"リバース：自分の手札を２枚ランダムに選択しゲームから除外する。\n相手ライフに８００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ファミリア・ナイト",h:"ふぁみりあ・ないと",e:"Familiar Knight",ra:"SR",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:1400,tx:"①：このカードが戦闘で破壊され墓地へ送られた場合に発動する。\nお互いのプレイヤーは、手札からレベル４モンスター１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ファラオの化身",h:"ふぁらおのけしん",e:"Beast of the Pharaoh",ra:"SR",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:400,de:600,tx:"①：このカードがS素材として墓地へ送られた場合、自分の墓地のレベル４以下のアンデット族モンスター１体を対象として発動できる。\nそのアンデット族モンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ファラオのしもべ",h:"ふぁらおのしもべ",e:"Pharaoh's Servant",ra:"N",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"アンデット族",mo:["通常"],ma:"",tp:"",at:900,de:0,tx:"かつてファラオに仕えたといわれている者たちの亡霊。\n揺らぐ事のない忠誠心を持っている。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ファランクス",h:"ふぁらんくす",e:"Synchar",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:800,de:900,tx:"上にも下にも頭がある、気持ち悪いヤツ。\n口からはレーザーを吐く。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ファルシオンβ",h:"ふぁるしおんべーた",e:"Falchionβ",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:800,tx:"このカードが戦闘によって相手モンスターを破壊した場合、以下の効果から１つを選択して発動する。\n\n●デッキから機械族・光属性・攻撃力１２００以下のモンスター１体を墓地へ送る。\n\n●自分の墓地から機械族・光属性・攻撃力１２００以下のモンスター１体を選択して特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ファントム・ドラゴン",h:"ふぁんとむ・どらごん",e:"Phantom Dragon",ra:"N",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2300,de:2200,tx:"相手がモンスターの特殊召喚に成功した時、手札からこのカードを特殊召喚する事ができる。\nこのカードがフィールド上に表側表示で存在する限り、自分のモンスターカードゾーンは２ヵ所が使用不可能になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ファントム・バウンサー",h:"ふぁんとむ・ばうんさー",e:"Phantom Bounzer",ra:"N",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2400,de:1200,tx:"フィールド上のこのカードが破壊され墓地へ送られた場合、デッキから「バウンサー」と名のついたカード２枚を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"不意打ち又佐",h:"ふいうちまたざ",e:"Mataza the Zapper",ra:"R",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1300,de:800,tx:"このカードは１度のバトルフェイズ中に２回攻撃する事ができる。\nこのカードは表側表示でフィールド上に存在する限り、コントロールを変更する事はできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フィッシュボーグ－アーチャー",h:"ふぃっしゅぼーぐ－あーちゃー",e:"Fishborg Archer",ra:"N",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"魚族",mo:["チューナー","効果"],ma:"",tp:"",at:300,de:300,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが墓地に存在し、自分フィールドにモンスターが存在しない場合、手札から水属性モンスター１体を捨てて発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したターンのバトルフェイズ開始時に、水属性モンスター以外の自分フィールドのモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フィッシュボーグ－ドクター",h:"ふぃっしゅぼーぐ－どくたー",e:"Fishborg Doctor",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:400,de:400,tx:"「フィッシュボーグ－ドクター」の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドに「フィッシュボーグ」モンスター以外のモンスターが存在する場合にこのカードは破壊される。\n②：このカードが墓地に存在し、自分フィールドのモンスターが「フィッシュボーグ」モンスターのみの場合、自分メインフェイズに発動できる。\nこのカードを墓地から特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フィッシュボーグ－プランター",h:"ふぃっしゅぼーぐ－ぷらんたー",e:"Fishborg Planter",ra:"R",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:200,de:200,tx:"このカードが墓地に存在する限り１度だけ発動できる。\n自分のデッキの一番上のカードを墓地へ送る。\n墓地へ送ったカードが水属性モンスターだった場合、さらにこのカードを墓地から特殊召喚する。\n「フィッシュボーグ－プランター」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フィッシュボーグ－ランチャー",h:"ふぃっしゅぼーぐ－らんちゃー",e:"Fishborg Launcher",ra:"R",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"魚族",mo:["チューナー","効果"],ma:"",tp:"",at:200,de:100,tx:"このカード名の効果は１ターンに１度しか使用できず、このカードをS素材とする場合、水属性モンスターのS召喚にしか使用できない。\n①：このカードが墓地に存在し、「フィッシュボーグ－ランチャー」以外の自分の墓地のモンスターが水属性モンスターのみの場合に発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"封印師 メイセイ",h:"ふういんし　めいせい",e:"Sealmaster Meisei",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:1100,de:900,tx:"封印の呪符を使いこなす事ができる数少ない人物。\nその経歴は未だ謎に包まれている。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"封印の鎖",h:"ふういんのくさり",e:"Binding Chain",ra:"N",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:1000,de:1100,tx:"相手をギリギリと締め上げて、封印を施す力を持つ。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブークー",h:"ぶーくー",e:"Boo Koo",ra:"N",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:650,de:500,tx:"本の姿をした魔法使い。\nありとあらゆる魔法が書かれている。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フーコーの魔砲石",h:"ふーこーのまほうせき",e:"Foucault's Cannon",ra:"SR",t:"monster",a:"闇",l:5,ps:2,pe:"①：このカードを発動したターンのエンドフェイズに、フィールドの表側表示の魔法・罠カード１枚を対象として発動できる。\nそのカードを破壊する。",tr:"魔法使い族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:2200,de:1200,tx:"夢幻の空間を彷徨う機械仕掛けの生命体、だったはずである。\n\n一番の謎は、過去の記録が殆ど残ってい・・事だ。\n\nその理由・・・なのか、・・・・・干渉・・・て拒・・・ている・・？\n・・・消去・・・",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブースト・ウォリアー",h:"ぶーすと・うぉりあー",e:"Boost Warrior",ra:"N",t:"monster",a:"炎",l:1,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:300,de:200,tx:"自分フィールド上にチューナーが表側表示で存在する場合、このカードは手札から表側守備表示で特殊召喚する事ができる。\nこのカードがフィールド上に表側表示で存在する限り、自分フィールド上に表側表示で存在する戦士族モンスターの攻撃力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"風帝家臣ガルーム",h:"ふうていかしんがるーむ",e:"Garum the Storm Vassal",ra:"N",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:800,de:1000,tx:"「風帝家臣ガルーム」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドのアドバンス召喚されたモンスター１体を持ち主の手札に戻して発動できる。\nこのカードを手札から特殊召喚する。\nこのターン、自分はエクストラデッキからモンスターを特殊召喚できない。\n②：このカードがアドバンス召喚のためにリリースされた場合に発動できる。\nデッキから「風帝家臣ガルーム」以外の攻撃力８００／守備力１０００のモンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"風帝ライザー",h:"ふうていらいざー",e:"Raiza the Storm Monarch",ra:"SR",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2400,de:1000,tx:"①：このカードがアドバンス召喚に成功した場合、フィールドのカード１枚を対象として発動する。\nそのカードを持ち主のデッキの一番上に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブーテン",h:"ぶーてん",e:"Buten",ra:"R",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"天使族",mo:["チューナー","効果"],ma:"",tp:"",at:200,de:300,tx:"自分のメインフェイズ時に、墓地のこのカードをゲームから除外し、自分フィールド上のレベル４以下の天使族・光属性モンスター１体を選択して発動できる。\n選択したモンスターはフィールド上に表側表示で存在する限りチューナーとして扱う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"風魔神－ヒューガ",h:"ふうましん－ひゅーが",e:"Kazejin",ra:"R",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2400,de:2200,tx:"このカードが相手のターンで攻撃された場合、そのダメージ計算時に発動する事ができる。\nその攻撃モンスター１体の攻撃力を０にする。\nこの効果はこのカードがフィールド上に表側表示で存在する限り１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"封魔の伝承者",h:"ふうまのでんしょうしゃ",e:"Disciple of the Forbidden Spell",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:800,tx:"このカードが召喚・反転召喚・特殊召喚に成功した時、自分の墓地に存在する「封魔の伝承者」の数だけ属性を宣言する。\nこのカードが宣言した属性のモンスターに攻撃を行う場合、ダメージ計算を行わずそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"風来王 ワイルド・ワインド",h:"ふうらいおう　わいるど・わいんど",e:"Wandering King Wildwind",ra:"UR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1700,de:1300,tx:"①：自分フィールドに攻撃力１５００以下の悪魔族チューナーが存在する場合、このカードは手札から特殊召喚できる。\nこの方法で特殊召喚したターン、自分はSモンスターしかエクストラデッキから特殊召喚できない。\n②：墓地のこのカードを除外して発動できる。\nデッキから攻撃力１５００以下の悪魔族チューナー１体を手札に加える。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"風霊神ウィンドローズ",h:"ふうれいしんうぃんどろーず",e:"Windrose the Elemental Lord",ra:"UR",t:"monster",a:"風",l:8,ps:"",pe:"",tr:"鳥獣族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2800,de:2200,tx:"このカードは通常召喚できない。\n自分の墓地の風属性モンスターが５体の場合のみ特殊召喚できる。\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合に発動する。\n相手フィールドの魔法・罠カードを全て破壊する。\n②：表側表示のこのカードがフィールドから離れた場合、次の自分ターンのバトルフェイズをスキップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"風霊使いウィン",h:"ふうれいつかいうぃん",e:"Wynn the Wind Charmer",ra:"R",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["リバース","効果"],ma:"",tp:"",at:500,de:1500,tx:"①：このカードがリバースした場合、相手フィールドの風属性モンスター１体を対象として発動する。\nこのモンスターが表側表示で存在する間、そのモンスターのコントロールを得る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フェアリー・アーチャー",h:"ふぇありー・あーちゃー",e:"Fairy Archer",ra:"N",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1400,de:600,tx:"自分のメインフェイズ時に発動できる。\n自分フィールド上の光属性モンスターの数×４００ポイントダメージを相手ライフに与える。\n「フェアリー・アーチャー」の効果は１ターンに１度しか使用できず、この効果を発動するターン、このカードは攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フェアリー・チア・ガール",h:"ふぇありー・ちあ・がーる",e:"Fairy Cheer Girl",ra:"UR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1900,de:1500,tx:"天使族レベル４モンスター×２\nこのカードのエクシーズ素材を１つ取り除いて発動できる。\nデッキからカードを１枚ドローする。\n「フェアリー・チア・ガール」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フェアリー・ドラゴン",h:"ふぇありー・どらごん",e:"Fairy Dragon",ra:"N",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:1100,de:1200,tx:"妖精の中では意外と強い、とてもきれいなドラゴンの妖精。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"妖精伝姫－シンデレラ",h:"ふぇありーている－しんでれら",e:"Fairy Tail - Rella",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1850,de:1000,tx:"①：このカードがモンスターゾーンに存在する限り、お互いにこのカード以外のフィールドのモンスターを魔法カードの効果の対象にできない。\n②：１ターンに１度、手札から魔法カード１枚を捨てて発動できる。\n自分の手札・デッキ・墓地から装備魔法カード１枚を選んでこのカードに装備する。\nその装備魔法カードはエンドフェイズに持ち主の手札に戻る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フェデライザー",h:"ふぇでらいざー",e:"Featherizer",ra:"SR",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:700,de:1100,tx:"このカードが戦闘によって破壊され墓地へ送られた時、自分のデッキからデュアルモンスター１体を墓地へ送り、自分のデッキからカードを１枚ドローする事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フェニックス・ギア・フリード",h:"ふぇにっくす・ぎあ・ふりーど",e:"Phoenix Gearfried",ra:"UR",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"戦士族",mo:["デュアル","効果"],ma:"",tp:"",at:2800,de:2200,tx:"①：このカードはフィールド・墓地に存在する限り、通常モンスターとして扱う。\n②：フィールドの通常モンスター扱いのこのカードを通常召喚としてもう１度召喚できる。\nその場合このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●相手が魔法カードを発動した場合、自分の墓地のデュアルモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n\n●フィールドのモンスターを対象とする魔法・罠カードが発動した時、自分フィールドの表側表示の装備カード１枚を墓地へ送って発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フェルグラントドラゴン",h:"ふぇるぐらんとどらごん",e:"Felgrand Dragon",ra:"SR",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2800,de:2800,tx:"このカードは墓地からしか特殊召喚できず、フィールドから墓地へ送られていなければ墓地からの特殊召喚もできない。\n①：このカードが墓地からの特殊召喚に成功した場合、自分の墓地のモンスター１体を対象として発動する。\nこのカードの攻撃力は、対象のモンスターのレベル×２００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フォース・リゾネーター",h:"ふぉーす・りぞねーたー",e:"Force Resonator",ra:"R",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:500,de:500,tx:"自分フィールド上に表側表示で存在するこのカードを墓地へ送り、自分フィールド上に表側表示で存在するモンスター１体を選択して発動する。\nこのターン、選択したモンスターが攻撃する場合、相手はダメージステップ終了時までモンスターを対象にする魔法・罠・効果モンスターの効果を発動する事ができない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"運命の戦車",h:"ふぉーちゅん・ちゃりおっと",e:"Fortune Chariot",ra:"N",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"天使族",mo:["ユニオン","効果"],ma:"",tp:"",at:1000,de:2000,tx:"このカード名はルール上「ワルキューレ」カードとしても扱う。\n①：１ターンに１度、以下の効果から１つを選択して発動できる。\n●自分フィールドの天使族モンスター１体を対象とし、このカードを装備カード扱いとしてそのモンスターに装備する。\n装備モンスターが戦闘・効果で破壊される場合、代わりにこのカードを破壊する。\n●装備されているこのカードを特殊召喚する。\n②：装備モンスターは直接攻撃できる。\nその直接攻撃で相手に与える戦闘ダメージは半分になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フォーチュンレディ・アーシー",h:"ふぉーちゅんれでぃ・あーしー",e:"Fortune Lady Earth",ra:"SR",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:"?",de:"?",tx:"このカードの攻撃力・守備力は、このカードのレベル×４００ポイントになる。\nまた、自分のスタンバイフェイズ時、このカードのレベルを１つ上げる（最大レベル１２まで）。\nこのカードのレベルが上がった時、相手ライフに４００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フォーチュンレディ・ウインディー",h:"ふぉーちゅんれでぃ・ういんでぃー",e:"Fortune Lady Wind",ra:"SR",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:"?",de:"?",tx:"このカードの攻撃力・守備力は、このカードのレベル×３００ポイントになる。\nまた、自分のスタンバイフェイズ時、このカードのレベルを１つ上げる（最大レベル１２まで）。\nこのカードが召喚に成功した時、自分フィールド上の「フォーチュンレディ」と名のついたモンスターの数だけ、相手フィールド上の魔法・罠カードを選んで破壊できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フォーチュンレディ・ウォーテリー",h:"ふぉーちゅんれでぃ・うぉーてりー",e:"Fortune Lady Water",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:"?",de:"?",tx:"①：このカードの攻撃力・守備力はこのカードのレベル×３００になる。\n②：自分スタンバイフェイズに発動する。\nこのカードのレベルを１つ上げる（最大１２まで）。\n③：自分フィールドに「フォーチュンレディ・ウォーテリー」以外の「フォーチュンレディ」モンスターが存在し、このカードが特殊召喚に成功した場合に発動する。\n自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フォーチュンレディ・エヴァリー",h:"ふぉーちゅんれでぃ・えう゛ぁりー",e:"Fortune Lady Every",ra:"SR",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"魔法使い族",mo:["シンクロ","効果"],ma:"",tp:"",at:"?",de:"?",tx:"チューナー＋チューナー以外の魔法使い族モンスター１体以上\nこのカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードの攻撃力・守備力はこのカードのレベル×４００になる。\n②：自分スタンバイフェイズに発動する。\nこのカードのレベルを１つ上げる（最大１２まで）。\nその後、相手フィールドの表側表示モンスター１体を選んで除外できる。\n③：相手エンドフェイズにこのカードが墓地に存在する場合、自分の墓地からこのカード以外の魔法使い族モンスター１体を除外して発動できる。\nこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フォーチュンレディ・ダルキー",h:"ふぉーちゅんれでぃ・だるきー",e:"Fortune Lady Dark",ra:"UR",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:"?",de:"?",tx:"このカードの攻撃力・守備力は、このカードのレベル×４００ポイントになる。\nまた、自分のスタンバイフェイズ時、このカードのレベルを１つ上げる（最大レベル１２まで）。\nこのカードが自分フィールド上に表側表示で存在する限り、自分フィールド上の「フォーチュンレディ」と名のついたモンスターが戦闘によって相手モンスターを破壊し墓地へ送った時、自分の墓地の「フォーチュンレディ」と名のついたモンスター１体を選択して特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フォーチュンレディ・パスティー",h:"ふぉーちゅんれでぃ・ぱすてぃー",e:"Fortune Lady Past",ra:"SR",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["チューナー","効果"],ma:"",tp:"",at:"?",de:"?",tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードの攻撃力・守備力はこのカードのレベル×２００になる。\n②：自分スタンバイフェイズに発動する。\nこのカードのレベルを１つ上げる（最大１２まで）。\n③：自分フィールドの「フォーチュンレディ」モンスター１体を対象として発動できる。\nそのモンスター以外の自分の手札・フィールド・墓地の魔法使い族モンスターを任意の数だけ除外し、ターン終了時まで対象のモンスターのレベルを、除外したモンスターの数だけ上げる、または下げる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フォーチュンレディ・ライティー",h:"ふぉーちゅんれでぃ・らいてぃー",e:"Fortune Lady Light",ra:"UR",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:"?",de:"?",tx:"①：このカードの攻撃力・守備力はこのカードのレベル×２００になる。\n②：自分スタンバイフェイズに発動する。\nこのカードのレベルを１つ上げる（最大１２まで）。\n③：表側表示のこのカードが効果でフィールドから離れた時に発動できる。\nデッキから「フォーチュンレディ」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フォーミュラ・シンクロン",h:"ふぉーみゅら・しんくろん",e:"Formula Synchron",ra:"UR",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"機械族",mo:["シンクロ","チューナー","効果"],ma:"",tp:"",at:200,de:1500,tx:"チューナー＋チューナー以外のモンスター１体\n①：このカードがS召喚に成功した時に発動できる。\n自分はデッキから１枚ドローする。\n②：相手メインフェイズに発動できる。\nこのカードを含む自分フィールドのモンスターをS素材としてS召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"F.A.ウィップクロッサー",h:"ふぉーみゅらあすりーとうぃっぷくろっさー",e:"F.A. Whip Crosser",ra:"N",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:1000,tx:"①：このカードの攻撃力はこのカードのレベル×３００アップし、相手は３００LPを払わなければ、このカードのレベルよりも元々のレベルまたはランクが低いモンスターの効果を発動できない。\n②：「F.A.」魔法・罠カードの効果が発動した場合に発動できる（ダメージステップでも発動可能）。\nこのカードのレベルを１つ上げる。\n③：このカードのレベルが７以上の場合、相手は手札を捨てて発動する効果及び手札を墓地へ送って発動する効果を発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"F.A.カーナビゲーター",h:"ふぉーみゅらあすりーとかーなびげーたー",e:"F.A. Auto Navigator",ra:"SR",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが手札・墓地に存在する場合、自分フィールドの元々のレベルより高いレベルを持つ「F.A.」モンスター１体を対象として発動できる。\nこのカードを特殊召喚し、対象のモンスターのレベルをその元々のレベルとの差分だけ下げる。\nこの効果で特殊召喚したこのカードのレベルはその差分の数値と同じになる。\n②：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「F.A.」フィールド魔法カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"F.A.ソニックマイスター",h:"ふぉーみゅらあすりーとそにっくまいすたー",e:"F.A. Sonic Meister",ra:"N",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:1000,tx:"①：このカードの攻撃力はこのカードのレベル×３００アップし、このカードのレベルよりも元々のレベルまたはランクが低い相手モンスターとの戦闘では破壊されない。\n②：「F.A.」魔法・罠カードの効果が発動した場合に発動できる（ダメージステップでも発動可能）。\nこのカードのレベルを１つ上げる。\n③：このカードのレベルが７以上の場合、このカードは１度のバトルフェイズ中に２回までモンスターに攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"F.A.ターボチャージャー",h:"ふぉーみゅらあすりーとたーぼちゃーじゃー",e:"F.A. Turbo Charger",ra:"N",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:1500,tx:"①：このカードの攻撃力はこのカードのレベル×３００アップし、このカードのレベルよりも元々のレベルまたはランクが低い相手モンスターは、このカードしか攻撃対象にできず、このカードしか効果の対象にできない。\n②：「F.A.」魔法・罠カードの効果が発動した場合に発動できる（ダメージステップでも発動可能）。\nこのカードのレベルを１つ上げる。\n③：このカードのレベルが７以上で、自分の「F.A.」モンスターが戦闘を行う場合、ダメージステップ終了時まで相手はモンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"F.A.ハングオンマッハ",h:"ふぉーみゅらあすりーとはんぐおんまっは",e:"F.A. Hang On Mach",ra:"SR",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:1800,tx:"①：このカードの攻撃力はこのカードのレベル×３００アップし、このカードのレベルよりも元々のレベルまたはランクが低い相手モンスターが発動した効果を受けない。\n②：「F.A.」魔法・罠カードの効果が発動した場合に発動できる（ダメージステップでも発動可能）。\nこのカードのレベルを１つ上げる。\n③：このカードのレベルが７以上の場合、相手の墓地へ送られるカードは墓地へは行かず除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"F.A.ホームトランスポーター",h:"ふぉーみゅらあすりーとほーむとらんすぽーたー",e:"F.A. Motorhome Transport",ra:"R",t:"monster",a:"光",l:9,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:0,de:3000,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：このカードの攻撃力はこのカードのレベル×３００アップする。\n②：「F.A.」魔法・罠カードの効果が発動した場合に発動できる（ダメージステップでも発動可能）。\nこのカードのレベルを１つ上げる。\n③：このカードは、このカードのレベルによって以下の効果を得る。\n●レベル１１以上：このカードは戦闘・効果では破壊されない。\n●レベル１３以上：１ターンに１度、自分の墓地の「F.A.」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フォトン・アドバンサー",h:"ふぉとん・あどばんさー",e:"Photon Advancer",ra:"UR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカード名の①の方法による特殊召喚は１ターンに１度しかできない。\n①：フィールドに「フォトン」モンスターが存在する場合、このカードは手札から特殊召喚できる。\n②：フィールドにこのカード以外の「フォトン」モンスターが存在する場合、このカードの攻撃力は１０００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フォトン・アレキサンドラ・クィーン",h:"ふぉとん・あれきさんどら・くぃーん",e:"Photon Alexandra Queen",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2400,de:1200,tx:"「幻蝶の刺客」と名のついたレベル４モンスター×２\nこのカードのエクシーズ素材を１つ取り除いて発動できる。\nフィールド上のモンスターを全て持ち主の手札に戻す。\nその後、この効果でカードが手札に加わったプレイヤーは、その数×３００ポイントダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フォトン・オービタル",h:"ふぉとん・おーびたる",e:"Photon Orbital",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:500,de:2000,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分メインフェイズに自分フィールドの「フォトン」モンスターまたは「ギャラクシー」モンスター１体を対象として発動できる。\n自分の手札・フィールドからこのモンスターを攻撃力５００アップの装備カード扱いとしてその自分のモンスターに装備する。\n装備モンスターは戦闘では破壊されない。\n②：装備されているこのカードを墓地へ送って発動できる。\nデッキから「フォトン・オービタル」以外の「フォトン」モンスターまたは「ギャラクシー」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フォトン・カイザー",h:"ふぉとん・かいざー",e:"Photon Caesar",ra:"N",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2000,de:2800,tx:"このカードが召喚・反転召喚に成功した時、自分の手札・デッキから「フォトン・カイザー」１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フォトン・クラッシャー",h:"ふぉとん・くらっしゃー",e:"Photon Crusher",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2000,de:0,tx:"①：このカードは攻撃した場合、ダメージステップ終了時に守備表示になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フォトン・ケルベロス",h:"ふぉとん・けるべろす",e:"Photon Cerberus",ra:"N",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1300,de:600,tx:"このカードが召喚に成功したターン、このカードがフィールド上に表側表示で存在する限りお互いに罠カードを発動する事はできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フォトン・サークラー",h:"ふぉとん・さーくらー",e:"Photon Circle",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードの戦闘によって発生する自分への戦闘ダメージは半分になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フォトン・サーベルタイガー",h:"ふぉとん・さーべるたいがー",e:"Photon Sabre Tiger",ra:"N",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:2000,de:300,tx:"このカードが召喚・反転召喚に成功した時、デッキから「フォトン・サーベルタイガー」１体を手札に加える事ができる。\n自分フィールド上にこのカード以外の「フォトン・サーベルタイガー」が存在しない場合、このカードの攻撃力は８００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フォトン・ストリーク・バウンサー",h:"ふぉとん・すとりーく・ばうんさー",e:"Photon Strike Bounzer",ra:"SR",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2700,de:2000,tx:"レベル６モンスター×２\n相手フィールド上で効果モンスターの効果が発動した時、このカードのエクシーズ素材を１つ取り除いて発動できる。\nその効果を無効にし、相手ライフに１０００ポイントダメージを与える。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フォトン・スラッシャー",h:"ふぉとん・すらっしゃー",e:"Photon Thrasher",ra:"UR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2100,de:0,tx:"このカードは通常召喚できない。\n自分フィールドにモンスターが存在しない場合に特殊召喚できる。\n①：自分フィールドにこのカード以外のモンスターが存在する場合、このカードは攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フォトン・スレイヤー",h:"ふぉとん・すれいやー",e:"Photon Slasher",ra:"SR",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2100,de:1000,tx:"フィールド上にエクシーズモンスターが存在する場合、このカードは手札から表側守備表示で特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フォトン・チャージマン",h:"ふぉとん・ちゃーじまん",e:"Photon Chargeman",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"１ターンに１度、自分のメインフェイズ時に発動できる。\nこのカードの攻撃力は、次の自分のスタンバイフェイズ時まで元々の攻撃力の倍になる。\nこの効果を発動するターン、このカードは攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フォトン・パイレーツ",h:"ふぉとん・ぱいれーつ",e:"Photon Pirate",ra:"N",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"自分のメインフェイズ時に自分の墓地の「フォトン」と名のついたモンスター１体をゲームから除外して発動できる。\nこのカードの攻撃力はエンドフェイズ時まで１０００ポイントアップする。\n「フォトン・パイレーツ」の効果は１ターンに２度まで使用できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フォトン・バタフライ・アサシン",h:"ふぉとん・ばたふらい・あさしん",e:"Photon Papilloperative",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2100,de:1800,tx:"レベル４モンスター×２\n１ターンに１度、このカードのエクシーズ素材を１つ取り除き、フィールド上に守備表示で存在するモンスター１体を選択して発動できる。\n選択したモンスターを表側攻撃表示にし、その攻撃力を６００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フォトン・バニッシャー",h:"ふぉとん・ばにっしゃー",e:"Photon Vanisher",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2000,de:0,tx:"このカードは通常召喚できない。\n自分フィールドに「フォトン」モンスターまたは「ギャラクシー」モンスターが存在する場合に特殊召喚できる。\n自分は「フォトン・バニッシャー」を１ターンに１度しか特殊召喚できない。\n①：このカードが特殊召喚に成功した場合に発動できる。\nデッキから「銀河眼の光子竜」１体を手札に加える。\n②：このカードは特殊召喚したターンには攻撃できない。\n③：フィールドのこのカードを素材としてX召喚したモンスターは以下の効果を得る。\n●このカードが戦闘で破壊したモンスターは墓地へは行かず除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フォトン・リザード",h:"ふぉとん・りざーど",e:"Photon Lizard",ra:"SR",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:900,de:1200,tx:"このカードをリリースして発動できる。\nデッキからレベル４以下の「フォトン」と名のついたモンスター１体を手札に加える。\n「フォトン・リザード」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フォトン・レオ",h:"ふぉとん・れお",e:"Photon Leo",ra:"N",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:2100,de:1100,tx:"このカードが召喚に成功した時に発動できる。\n相手の手札を全てデッキに加えてシャッフルする。\nその後、相手はデッキに加えた枚数分のカードをドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フォトン・ワイバーン",h:"ふぉとん・わいばーん",e:"Photon Wyvern",ra:"SR",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2500,de:2000,tx:"①：このカードが召喚・反転召喚に成功した場合に発動する。\n相手フィールドにセットされたカードを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"深き森の長老",h:"ふかきもりのちょうろう",e:"Ancient One of the Deep Forest",ra:"N",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:1800,de:1900,tx:"昔から森に住んでいる白ヤギ。\n真の姿は森の長老。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"吹き荒れるウィン",h:"ふきあれるうぃん",e:"Storming Wynn",ra:"N",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:800,de:1500,tx:"このカードを除く自分フィールド上の風属性モンスター１体を生け贄に捧げる事で、手札から風属性モンスター１体を特殊召喚する。\nこの効果は１ターンに１度しか使用できない。\nこの効果によって特殊召喚されたモンスターは、「吹き荒れるウィン」が自分フィールド上から離れた場合破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"復讐の女戦士ローズ",h:"ふくしゅうのおんなせんしろーず",e:"Rose, Warrior of Revenge",ra:"R",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"戦士族",mo:["チューナー","効果"],ma:"",tp:"",at:1600,de:600,tx:"①：このカードが相手に戦闘ダメージを与えた場合に発動する。\n相手に３００ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"復讐のソード・ストーカー",h:"ふくしゅうのそーど・すとーかー",e:"Swordstalker",ra:"N",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:2000,de:1600,tx:"やられていった味方の怨念が宿っているモンスター。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"不屈闘士レイレイ",h:"ふくつとうしれいれい",e:"Indomitable Fighter Lei Lei",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:2300,de:0,tx:"このカードは攻撃した場合、バトルフェイズ終了時に守備表示になる。\n次の自分のターン終了時までこのカードは表示形式を変更できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"覆面忍者ヱビス",h:"ふくめんにんじゃゑびす",e:"Masked Ninja Ebisu",ra:"R",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:1800,tx:"１ターンに１度、自分フィールド上に「覆面忍者ヱビス」以外の「忍者」と名のついたモンスターが存在する場合に発動できる。\n自分フィールド上の「忍者」と名のついたモンスターの数だけ、相手の魔法・罠カードを持ち主の手札に戻す。\nこの効果を適用したターン、自分フィールド上の「忍者義賊ゴエゴエ」は相手プレイヤーに直接攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"不幸を告げる黒猫",h:"ふこうをつげるくろねこ",e:"A Cat of Ill Omen",ra:"R",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"獣族",mo:["リバース","効果"],ma:"",tp:"",at:500,de:300,tx:"①：このカードがリバースした場合に発動する。\nデッキから罠カード１枚を選んでデッキの一番上に置く。\n「王家の眠る谷－ネクロバレー」がフィールドに存在する場合、その罠カードをデッキの一番上に置く代わりに、手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"不死之炎鳥",h:"ふしのとり",e:"Fushi No Tori",ra:"N",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["スピリット","効果"],ma:"",tp:"",at:1200,de:0,tx:"このカードは特殊召喚できない。\n召喚・リバースしたターンのエンドフェイズ時に持ち主の手札に戻る。\nこのカードが相手ライフに戦闘ダメージを与えた時、与えた戦闘ダメージの数値分だけ自分のライフポイントを回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"不死武士",h:"ふしぶし",e:"The Immortal Bushi",ra:"SR",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:600,tx:"自分のスタンバイフェイズ時にこのカードが墓地に存在し、自分フィールド上にモンスターが存在しない場合、このカードを墓地から特殊召喚できる。\nこの効果は自分の墓地に戦士族以外のモンスターが存在する場合には発動できない。\nこのカードは戦士族モンスターのアドバンス召喚以外のためにはリリースできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"武神－アラスダ",h:"ぶじん－あらすだ",e:"Bujin Arasuda",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1900,tx:"自分のフィールド上・墓地の「武神」と名のついたモンスターがゲームから除外された場合、このカードを手札から表側守備表示で特殊召喚できる。\nまた、このカードがフィールド上に表側表示で存在し、「武神」と名のついたカードがドロー以外の方法で自分のデッキから手札に加わった場合、そのターンのエンドフェイズ時に１度だけ発動できる。\nデッキからカードを１枚ドローし、その後手札を１枚選んで捨てる。\n「武神－アラスダ」は自分フィールド上に１体しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"武神－ヒルコ",h:"ぶじん－ひるこ",e:"Bujin Hiruko",ra:"SR",t:"monster",a:"光",l:4,ps:3,pe:"①：自分のPゾーンのこのカードを除外し、自分フィールドの「武神」Xモンスター１体を対象として発動できる。\nその自分のモンスターとカード名が異なる「武神」Xモンスター１体を、対象のモンスターの上に重ねてX召喚扱いとしてエクストラデッキから特殊召喚する。",tr:"獣戦士族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:1000,de:2000,tx:"遥か太古の昔に主神の座をかけて「武神－ヒルメ」と戦い、死闘の末に封印されてしまった悪神。\n自らの封印を解くために「ヒルメ」を操り、禍々しき「アマテラス」を生み出して世界に闇を齎したが、その野望は「ヤマト」たち若き武神の活躍によって潰えた。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"武神－ヒルメ",h:"ぶじん－ひるめ",e:"Bujin Hirume",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2000,de:1000,tx:"このカードは通常召喚できない。\n「武神－ヒルメ」以外の自分の墓地の「武神」と名のついたモンスター１体をゲームから除外した場合に特殊召喚できる。\nまた、この方法で特殊召喚したこのカードが相手によって破壊され墓地へ送られた場合に発動できる。\n自分は手札を１枚捨てる。\nその後、相手は手札を１枚選んで捨てる。\n「武神－ヒルメ」は自分フィールド上に１体しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"武神－ミカヅチ",h:"ぶじん－みかづち",e:"Bujin Mikazuchi",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1900,de:1500,tx:"自分フィールド上の「武神」と名のついた獣戦士族モンスターが戦闘またはカードの効果によって破壊され墓地へ送られた時、このカードを手札から特殊召喚できる。\nまた、このカードがフィールド上に表側表示で存在し、自分の手札から「武神」と名のついたモンスターが自分の墓地へ送られた場合、そのターンのエンドフェイズ時に１度、デッキから「武神」と名のついた魔法・罠カード１枚を手札に加える事ができる。\n「武神－ミカヅチ」は自分フィールド上に１体しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"武神－ヤマト",h:"ぶじん－やまと",e:"Bujin Yamato",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:200,tx:"①：「武神－ヤマト」は自分フィールドに１体しか表側表示で存在できない。\n②：自分エンドフェイズに発動できる。\nデッキから「武神」モンスター１体を手札に加え、その後手札を１枚選んで墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"武神姫－アマテラス",h:"ぶじんき－あまてらす",e:"Bujinki Amaterasu",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2600,de:2500,tx:"レベル４モンスター×３\n①：「武神姫－アマテラス」は自分フィールドに１体しか表側表示で存在できない。\n②：自分・相手ターンに１度、このカードのX素材を１つ取り除き、除外されている自分のレベル４以下のモンスター１体を対象として発動できる。\n発動ターンによって以下の効果を適用する。\n\n●自分ターン：対象のモンスターを特殊召喚する。\n\n●相手ターン：対象のモンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"武神器－イオツミ",h:"ぶじんぎ－いおつみ",e:"Bujingi Pavo",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1700,de:800,tx:"「武神器－イオツミ」の効果は１ターンに１度しか使用できない。\n①：自分フィールドの獣戦士族の「武神」モンスターが戦闘で破壊され墓地へ送られた時、このカードを手札から墓地へ送って発動できる。\nデッキから「武神」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"武神器－イクタ",h:"ぶじんぎ－いくた",e:"Bujingi Boar",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1100,de:1900,tx:"自分のメインフェイズ時、自分フィールド上に「武神」と名のついたモンスターが存在する場合、墓地のこのカードをゲームから除外し、相手フィールド上に表側攻撃表示で存在するモンスター１体を選択して発動できる。\n選択したモンスターを表側守備表示にし、その守備力をエンドフェイズ時まで０にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"武神器－オキツ",h:"ぶじんぎ－おきつ",e:"Bujingi Fox",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:200,de:2100,tx:"墓地のこのカードをゲームから除外し、手札から「武神」と名のついたモンスター１体を墓地へ送って発動できる。\nこのターン、自分が受ける全てのダメージを０にする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"武神器－オハバリ",h:"ぶじんぎ－おはばり",e:"Bujingi Ibis",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1600,de:300,tx:"自分のメインフェイズ１で、このカードを手札から墓地へ送り、自分フィールド上の「武神」と名のついたモンスター１体を選択して発動できる。\nこのターン、選択したモンスターが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"武神器－オロチ",h:"ぶじんぎ－おろち",e:"Bujingi Ophidian",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1700,de:500,tx:"自分のメインフェイズ１で、このカードを手札から墓地へ送り、自分フィールド上の「武神」と名のついたモンスター１体を選択して発動できる。\nこのターン、選択したモンスターは相手プレイヤーに直接攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"武神器－サグサ",h:"ぶじんぎ－さぐさ",e:"Bujingi Hare",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1700,de:500,tx:"墓地のこのカードをゲームから除外し、自分フィールド上の「武神」と名のついた獣戦士族モンスター１体を選択して発動できる。\n選択したモンスターはこのターンに１度だけ戦闘及びカードの効果では破壊されない。\nこの効果は相手ターンでも発動できる。\n「武神器－サグサ」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"武神器－タルタ",h:"ぶじんぎ－たるた",e:"Bujingi Wolf",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1100,de:2000,tx:"このカードがフィールド上に表側表示で存在する限り、このカード以外の自分フィールド上の獣族・獣戦士族・鳥獣族モンスターは戦闘では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"武神器－チカヘシ",h:"ぶじんぎ－ちかへし",e:"Bujingi Warg",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1200,de:1900,tx:"①：このカードがモンスターゾーンに守備表示で存在する限り、このカード以外の自分フィールドの「武神」モンスターは効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"武神器－ツムガリ",h:"ぶじんぎ－つむがり",e:"Bujingi Sinyou",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"「武神器－ツムガリ」の効果は１ターンに１度しか使用できない。\n①：自分の獣戦士族の「武神」モンスターが相手モンスターと戦闘を行うダメージステップ開始時からダメージ計算前までに、墓地のこのカードを除外して発動できる。\nその戦闘を行う自分のモンスターの攻撃力はダメージステップ終了時まで、戦闘を行う相手モンスターの攻撃力分アップし、その戦闘によって相手に与える戦闘ダメージは半分になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"武神器－ハチ",h:"ぶじんぎ－はち",e:"Bujingi Centipede",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1700,de:500,tx:"自分のメインフェイズ時、自分フィールド上に「武神」と名のついた獣戦士族モンスターが存在する場合、墓地のこのカードをゲームから除外して発動できる。\n相手フィールド上の魔法・罠カード１枚を選択して破壊する。\n「武神器－ハチ」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"武神器－ハバキリ",h:"ぶじんぎ－はばきり",e:"Bujingi Crane",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1600,de:300,tx:"①：自分の獣戦士族の「武神」モンスターが相手モンスターと戦闘を行うダメージ計算時にこのカードを手札から墓地へ送って発動できる。\nその戦闘を行う自分のモンスターの攻撃力は、そのダメージ計算時のみ元々の攻撃力の倍になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"武神器－ヘツカ",h:"ぶじんぎ－へつか",e:"Bujingi Turtle",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1700,de:1200,tx:"①：自分フィールドの「武神」モンスターを対象とする魔法・罠・モンスターの効果が発動した時、墓地のこのカードを除外して発動できる。\nその効果を無効にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"武神器－マフツ",h:"ぶじんぎ－まふつ",e:"Bujingi Raven",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1600,de:900,tx:"①：自分フィールドの「武神」モンスターが相手モンスターとの戦闘で破壊され自分の墓地へ送られた時、このカードを手札から墓地へ送って発動できる。\nその相手モンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"武神器－ムラクモ",h:"ぶじんぎ－むらくも",e:"Bujingi Quilin",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1600,de:900,tx:"自分のメインフェイズ時、自分フィールド上に「武神」と名のついた獣戦士族モンスターが存在する場合、墓地のこのカードをゲームから除外して発動できる。\n相手フィールド上に表側表示で存在するカード１枚を選択して破壊する。\n「武神器－ムラクモ」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"武神器－ヤタ",h:"ぶじんぎ－やた",e:"Bujingi Crow",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1700,de:800,tx:"自分フィールド上の「武神」と名のついた獣戦士族モンスターが相手モンスターの攻撃対象に選択された時、このカードを手札から墓地へ送って発動できる。\n攻撃モンスターの攻撃を無効にし、そのモンスターの攻撃力の半分のダメージを相手ライフに与える。\n「武神器－ヤタ」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"武神器－ヤツカ",h:"ぶじんぎ－やつか",e:"Bujingi Swallow",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1700,de:800,tx:"自分のメインフェイズ１で、このカードを手札から墓地へ送り、自分フィールド上の「武神」と名のついたモンスター１体を選択して発動できる。\nこのターン、選択したモンスターは１度のバトルフェイズ中に２回攻撃する事ができる。\nこの効果を発動するターン、選択したモンスター以外のモンスターは攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"武神帝－スサノヲ",h:"ぶじんてい－すさのを",e:"Bujintei Susanowo",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2400,de:1600,tx:"レベル４「武神」モンスター×２\n①：「武神帝－スサノヲ」は自分フィールドに１体しか表側表示で存在できない。\n②：このカードは相手モンスター全てに１回ずつ攻撃できる。\n③：１ターンに１度、このカードのX素材を１つ取り除いて発動できる。\nデッキから「武神」モンスター１体を選び、手札に加えるか墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"武神帝－ツクヨミ",h:"ぶじんてい－つくよみ",e:"Bujintei Tsukuyomi",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1800,de:2300,tx:"光属性レベル４モンスター×２\n１ターンに１度、このカードのエクシーズ素材を１つ取り除いて発動できる。\n手札を全て墓地へ送り、デッキからカードを２枚ドローする。\nまた、このカードが相手のカードの効果によってフィールド上から離れた時、その時にこのカードが持っていたエクシーズ素材の数まで、自分の墓地からレベル４の「武神」と名のついた獣戦士族モンスターを選択して特殊召喚できる。\n「武神帝－ツクヨミ」は自分フィールド上に１体しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"武装神竜プロテクト・ドラゴン",h:"ぶそうしんりゅうぷろてくと・どらごん",e:"Armed Protector Dragon",ra:"R",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2000,de:2800,tx:"①：このカードの攻撃力は、このカードに装備された装備カードの数×５００アップする。\n②：このカードがモンスターゾーンに存在する限り、自分フィールドの表側表示の装備カードは効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"プチテンシ",h:"ぷちてんし",e:"Petit Angel",ra:"N",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:600,de:900,tx:"ちょこまか動き攻撃がなかなか当たらない、とても小さな天使。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"プチトマボー",h:"ぷちとまぼー",e:"Cherry Inmato",ra:"R",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"植物族",mo:["チューナー","効果"],ma:"",tp:"",at:700,de:400,tx:"このカードが戦闘によって破壊され墓地へ送られた時、デッキから「トマボー」と名のついたモンスターを２体まで特殊召喚できる。\nこのターン、この効果で特殊召喚したモンスターはシンクロ素材にできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"プチモス",h:"ぷちもす",e:"Petit Moth",ra:"N",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"昆虫族",mo:["通常"],ma:"",tp:"",at:300,de:200,tx:"成長したらどんなムシになるか分からない、小さな幼虫。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"プチリュウ",h:"ぷちりゅう",e:"Petit Dragon",ra:"N",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:600,de:700,tx:"とても小さなドラゴン。\n小さなからだをいっぱいに使い攻撃する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"物資調達員",h:"ぶっしちょうたついん",e:"Supply",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["リバース","効果"],ma:"",tp:"",at:1300,de:800,tx:"リバース：「融合」によって自分の墓地へ送られた融合素材モンスター２体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"浮鵺城",h:"ふやじょう",e:"Cloudcastle",ra:"N",t:"monster",a:"風",l:9,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:0,de:3000,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：このカードがS召喚に成功した時、自分の墓地のレベル９モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n②：このカードがモンスターゾーンに存在する限り、召喚・特殊召喚されたレベル８以下のモンスターは、そのターンには攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フュージョニスト",h:"ふゅーじょにすと",e:"Fusionist",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["融合"],ma:"",tp:"",at:900,de:700,tx:"「プチテンシ」＋「スリーピィ」",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フュージョニストキラー",h:"ふゅーじょにすときらー",e:"Fusion Devourer",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1400,de:1200,tx:"このカードと戦闘を行う融合モンスターの攻撃力は、ダメージステップの間０となる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブライ・シンクロン",h:"ぶらい・しんくろん",e:"Bri Synchron",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:1500,de:1100,tx:"このカードがシンクロ召喚の素材として墓地へ送られた場合、このターンのエンドフェイズ時まで、このカードをシンクロ素材としたシンクロモンスターの攻撃力は６００ポイントアップし、効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブライニグル",h:"ぶらいにぐる",e:"Brinegir",ra:"N",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:1000,de:2600,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合、自分の墓地の水属性モンスター１体を対象として発動できる。\nこのカードの攻撃力はターン終了時まで、対象のモンスターの攻撃力分アップする。\n②：このカードが墓地へ送られた場合、自分フィールドの水属性モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで１０００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フライファング",h:"ふらいふぁんぐ",e:"Flyfang",ra:"R",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1600,de:300,tx:"このカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。\nこのカードが相手ライフに戦闘ダメージを与えたバトルフェイズ終了時、このカードを次の自分のスタンバイフェイズ時までゲームから除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フライング・フィッシュ",h:"ふらいんぐ・ふぃっしゅ",e:"Flying Fish",ra:"N",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"魚族",mo:["通常"],ma:"",tp:"",at:800,de:500,tx:"空を飛ぶ姿を見ると、３つの願い事がかなうと言われている。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フライングマンティス",h:"ふらいんぐまんてぃす",e:"Flying Kamakiri #2",ra:"R",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"昆虫族",mo:["通常"],ma:"",tp:"",at:1500,de:800,tx:"飛行能力を持ったカマキリ。\n昆虫が大好物。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブラキオレイドス",h:"ぶらきおれいどす",e:"Bracchio-raidus",ra:"R",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"恐竜族",mo:["融合"],ma:"",tp:"",at:2200,de:2000,tx:"二頭を持つキング・レックス＋屍を貪る竜",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"プラグティカル",h:"ぷらぐてぃかる",e:"Pragtical",ra:"N",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"恐竜族",mo:["融合"],ma:"",tp:"",at:1900,de:1500,tx:"トラコドン＋フレイム・ヴァイパー",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"連爆魔人",h:"ぶらすと・でびる",e:"Blast Asmodian",ra:"N",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1000,de:300,tx:"魔法・罠カードがチェーンする場合、相手ライフに５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"プラズマ・ボール",h:"ぷらずま・ぼーる",e:"Plasma Ball",ra:"N",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:900,de:900,tx:"このカードは相手プレイヤーに直接攻撃する事ができる。\nこのカードが直接攻撃によって相手ライフに戦闘ダメージを与えた時、このカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"黒魔導の執行官",h:"ぶらっく・えくすきゅーしょなー",e:"Dark Eradicator Warlock",ra:"SR",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"魔法使い族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2500,de:2100,tx:"このカードは通常召喚できない。\n自分フィールド上に存在する「ブラック・マジシャン」１体をリリースした場合のみ特殊召喚する事ができる。\nこのカードがフィールド上に表側表示で存在する限り、自分または相手が通常魔法カードを発動する度に、相手ライフに１０００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"伝説の黒石",h:"ぶらっく・おぶ・れじぇんど",e:"The Black Stone of Legend",ra:"SR",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"「伝説の黒石」の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードをリリースして発動できる。\nデッキからレベル７以下の「レッドアイズ」モンスター１体を特殊召喚する。\n②：このカードが墓地に存在する場合、自分の墓地のレベル７以下の「レッドアイズ」モンスター１体を対象として発動できる。\nそのモンスターをデッキに戻し、墓地のこのカードを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"暗黒恐獣",h:"ぶらっく・てぃらの",e:"Black Tyranno",ra:"N",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:2600,de:1800,tx:"相手フィールド上に守備表示モンスターしか存在しない場合、このカードは相手プレイヤーに直接攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブラック・デーモンズ・ドラゴン",h:"ぶらっく・でーもんず・どらごん",e:"Black Skull Dragon",ra:"SR",t:"monster",a:"闇",l:9,ps:"",pe:"",tr:"ドラゴン族",mo:["融合"],ma:"",tp:"",at:3200,de:2500,tx:"「デーモンの召喚」＋「真紅眼の黒竜」",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブラック・ブルドラゴ",h:"ぶらっく・ぶるどらご",e:"Black Brutdrago",ra:"SR",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:3000,de:2600,tx:"チューナー＋チューナー以外のデュアルモンスター１体以上\n１ターンに１度、手札からデュアルモンスター１体を墓地へ送って発動できる。\n相手フィールド上の魔法・罠カード１枚を選択して破壊する。\nまた、このカードが破壊され墓地へ送られた時、自分の墓地のデュアルモンスター１体を選択して特殊召喚できる。\nこの効果で特殊召喚したデュアルモンスターは再度召喚された状態になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブラック・ボンバー",h:"ぶらっく・ぼんばー",e:"Black Salvo",ra:"UR",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:100,de:1100,tx:"このカードが召喚に成功した時、自分の墓地から機械族・闇属性・レベル４のモンスター１体を選択して表側守備表示で特殊召喚できる。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブラック・マジシャン",h:"ぶらっく・まじしゃん",e:"Dark Magician",ra:"UR",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:2500,de:2100,tx:"魔法使いとしては、攻撃力・守備力ともに最高クラス。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブラック・マジシャン・ガール",h:"ぶらっく・まじしゃん・がーる",e:"Dark Magician Girl",ra:"UR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2000,de:1700,tx:"①：このカードの攻撃力は、お互いの墓地の「ブラック・マジシャン」「マジシャン・オブ・ブラックカオス」の数×３００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブラック・マジシャンズ・ナイト",h:"ぶらっく・まじしゃんず・ないと",e:"Dark Magician Knight",ra:"N",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2500,de:2100,tx:"このカードは通常召喚できない。\n「騎士の称号」の効果でのみ特殊召喚できる。\n①：このカードが特殊召喚に成功した場合、フィールドのカード１枚を対象として発動する。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブラック・レイ・ランサー",h:"ぶらっく・れい・らんさー",e:"Black Ray Lancer",ra:"UR",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"獣戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2100,de:600,tx:"水属性レベル３モンスター×２\n１ターンに１度、このカードのエクシーズ素材を１つ取り除き、フィールド上に表側表示で存在するモンスター１体を選択して発動できる。\n選択したモンスターの効果をエンドフェイズ時まで無効にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブラック・ローズ・ドラゴン",h:"ぶらっく・ろーず・どらごん",e:"Black Rose Dragon",ra:"UR",t:"monster",a:"炎",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2400,de:1800,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：このカードがS召喚に成功した時に発動できる。\nフィールドのカードを全て破壊する。\n②：１ターンに１度、自分の墓地から植物族モンスター１体を除外し、相手フィールドの守備表示モンスター１体を対象として発動できる。\nその相手の守備表示モンスターを表側攻撃表示にし、その攻撃力はターン終了時まで０になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"暗黒ヴェロキ",h:"ぶらっくう゛ぇろき",e:"Black Veloci",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1800,de:300,tx:"このカードは相手モンスターに攻撃する場合、ダメージステップの間攻撃力が４００ポイントアップする。\nこのカードは相手モンスターに攻撃された場合、ダメージステップの間攻撃力が４００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"暗黒ステゴ",h:"ぶらっくすてご",e:"Black Stego",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1200,de:2000,tx:"フィールド上に表側攻撃表示で存在するこのカードが攻撃対象に選択された時、このカードの表示形式を守備表示にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"闇竜の黒騎士",h:"ぶらっくないと・おぶ・だーくどらごん",e:"Paladin of the Cursed Dragon",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1900,de:1200,tx:"①：１ターンに１度、戦闘で破壊された相手の墓地のレベル４以下のアンデット族モンスター１体を対象として発動できる。\nそのアンデット族モンスターを自分フィールドに特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"BF T－漆黒のホーク・ジョー",h:"ぶらっくふぇざー　ていまー－しっこくのほーく・じょー",e:"Blackwing Tamer - Obsidian Hawk Joe",ra:"R",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2600,de:2000,tx:"「BF」チューナー＋チューナー以外の「BF」モンスター１体以上\n「BF T－漆黒のホーク・ジョー」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の墓地のレベル５以上の鳥獣族モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n②：このカードが相手の効果の対象になった時、または相手モンスターの攻撃対象になった時、このカード以外の自分フィールドの「BF」モンスター１体を対象として発動できる。\nその対象を正しい対象となるそのモンスターに移し替える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"BF－アーマード・ウィング",h:"ぶらっくふぇざー－あーまーど・うぃんぐ",e:"Blackwing Armor Master",ra:"UR",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"鳥獣族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:1500,tx:"「BF」チューナー＋チューナー以外のモンスター１体以上\n①：このカードは戦闘では破壊されず、このカードの戦闘で発生する自分への戦闘ダメージは０になる。\n②：このカードがモンスターを攻撃したダメージステップ終了時に発動できる。\nそのモンスターに楔カウンターを１つ置く（最大１つまで）。\n③：相手フィールドの楔カウンターを全て取り除いて発動できる。\n楔カウンターが置かれていた全てのモンスターの攻撃力・守備力をターン終了時まで０にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"BF－アームズ・ウィング",h:"ぶらっくふぇざー－あーむず・うぃんぐ",e:"Blackwing Armed Wing",ra:"SR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"鳥獣族",mo:["シンクロ","効果"],ma:"",tp:"",at:2300,de:1000,tx:"「BF」チューナー＋チューナー以外のモンスター１体以上\n①：このカードが守備表示モンスターを攻撃するダメージステップの間、このカードの攻撃力は５００アップする。\n②：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"BF－暁のシロッコ",h:"ぶらっくふぇざー－あかつきのしろっこ",e:"Blackwing - Sirocco the Dawn",ra:"R",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2000,de:900,tx:"①：相手フィールドにモンスターが存在し、自分フィールドにモンスターが存在しない場合、このカードはリリースなしで通常召喚できる。\n②：１ターンに１度、自分メインフェイズ１に自分フィールドの「BF」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで、そのモンスター以外のフィールドの「BF」モンスターの攻撃力の合計分アップする。\nこの効果を発動するターン、対象のモンスターしか攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"BF－追い風のアリゼ",h:"ぶらっくふぇざー－おいかぜのありぜ",e:"Blackwing - Brisote the Tailwind",ra:"R",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1200,de:1800,tx:"自分フィールド上に表側表示で存在する「BF」と名のついたモンスターが２体以上破壊されたターン、このカードは手札から特殊召喚する事ができる。\nこのカードがシンクロ召喚の素材として墓地へ送られた場合、自分は６００ライフポイント回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"BF－隠れ蓑のスチーム",h:"ぶらっくふぇざー－かくれみののすちーむ",e:"Blackwing - Steam the Cloak",ra:"R",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:1200,tx:"「BF－隠れ蓑のスチーム」の②の効果はデュエル中に１度しか使用できない。\n①：表側表示のこのカードがフィールドから離れた場合に発動する。\n自分フィールドに「スチーム・トークン」（水族・風・星１・攻／守１００）１体を特殊召喚する。\n②：このカードが墓地に存在する場合、自分フィールドのモンスター１体をリリースして発動できる。\nこのカードを墓地から特殊召喚する。\nこの効果で特殊召喚したこのカードをS素材とする場合、他のS素材モンスターは全て「BF」モンスターでなければならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"BF－陽炎のカーム",h:"ぶらっくふぇざー－かげろうのかーむ",e:"Blackwing - Calima the Haze",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:600,de:1800,tx:"相手のバトルフェイズ時、自分フィールド上にモンスターが存在しない場合、墓地に存在するこのカードをゲームから除外して発動する事ができる。\n自分の墓地に存在するシンクロモンスター１体を選択して特殊召喚する。\nこの効果で特殊召喚したモンスターはバトルフェイズ終了時にゲームから除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"BF－空風のジン",h:"ぶらっくふぇざー－からかぜのじん",e:"Blackwing - Jin the Rain Shadow",ra:"N",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"鳥獣族",mo:["チューナー","効果"],ma:"",tp:"",at:600,de:500,tx:"このカードの攻撃力以下の守備力を持つ、フィールド上に表側表示で存在するモンスターと戦闘を行う場合、ダメージ計算を行わずそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"BF－逆風のガスト",h:"ぶらっくふぇざー－ぎゃくふうのがすと",e:"Blackwing - Gust the Backblast",ra:"R",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:900,de:1400,tx:"自分フィールド上にカードが存在しない場合、このカードは手札から特殊召喚する事ができる。\nこのカードがフィールド上に表側表示で存在する限り、相手モンスターが自分フィールド上に存在する「BF」と名のついたモンスターを攻撃する場合、その攻撃モンスターはダメージステップの間攻撃力が３００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"BF－極北のブリザード",h:"ぶらっくふぇざー－きょくほくのぶりざーど",e:"Blackwing - Blizzard the Far North",ra:"R",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"鳥獣族",mo:["チューナー","効果"],ma:"",tp:"",at:1300,de:0,tx:"このカードは特殊召喚できない。\n①：このカードが召喚に成功した時、自分の墓地のレベル４以下の「BF」モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"BF－極夜のダマスカス",h:"ぶらっくふぇざー－きょくやのだますかす",e:"Blackwing - Damascus the Polar Night",ra:"SR",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1300,de:700,tx:"①：このカードを手札から捨て、自分フィールドの「BF」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで５００アップする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"BF－極光のアウロラ",h:"ぶらっくふぇざー－きょっこうのあうろら",e:"Blackwing - Aurora the Northern Lights",ra:"N",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"鳥獣族",mo:["特殊召喚","効果"],ma:"",tp:"",at:"?",de:0,tx:"このカードは通常召喚できない。\n自分フィールド上に表側表示で存在する「BF」と名のついたチューナー１体と、チューナー以外のモンスター１体をゲームから除外した場合のみ特殊召喚する事ができる。\n１ターンに１度、自分のエクストラデッキから「BF」と名のついたシンクロモンスター１体をゲームから除外し、エンドフェイズ時までそのモンスターと同名カードとして扱い、同じ攻撃力と効果を得る事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"BF－煌星のグラム",h:"ぶらっくふぇざー－きらぼしのぐらむ",e:"Blackwing - Gram the Shining Star",ra:"N",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"鳥獣族",mo:["シンクロ","効果"],ma:"",tp:"",at:2200,de:1500,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカードはシンクロ召喚でのみエクストラデッキから特殊召喚できる。\nこのカードがシンクロ召喚に成功した時、手札からチューナー以外のレベル４以下の「BF」と名のついたモンスター１体を特殊召喚できる。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"BF－銀盾のミストラル",h:"ぶらっくふぇざー－ぎんたてのみすとらる",e:"Blackwing - Mistral the Silver Shield",ra:"R",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"鳥獣族",mo:["チューナー","効果"],ma:"",tp:"",at:100,de:1800,tx:"フィールド上に存在するこのカードが破壊され墓地へ送られた場合、このターン自分が受ける戦闘ダメージを１度だけ０にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"BF－黒槍のブラスト",h:"ぶらっくふぇざー－くろやりのぶらすと",e:"Blackwing - Bora the Spear",ra:"UR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1700,de:800,tx:"①：自分フィールドに「BF－黒槍のブラスト」以外の「BF」モンスターが存在する場合、このカードは手札から特殊召喚できる。\n②：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"BF－激震のアブロオロス",h:"ぶらっくふぇざー－げきしんのあぶろおろす",e:"Blackwing - Abrolhos the Megaquake",ra:"N",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2600,de:1800,tx:"このカードは特殊召喚できない。\n１ターンに１度、このカードの攻撃力を１０００ポイントダウンし、相手の魔法＆罠カードゾーンに存在するカードを全て持ち主の手札に戻す事ができる。\nこの効果はメインフェイズ１にしか使用できない。\nこのカードと戦闘を行ったモンスターは、その戦闘で破壊されずダメージ計算後に持ち主の手札に戻る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"BF－孤高のシルバー・ウィンド",h:"ぶらっくふぇざー－ここうのしるばー・うぃんど",e:"Blackwing - Silverwind the Ascendant",ra:"R",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"鳥獣族",mo:["シンクロ","効果"],ma:"",tp:"",at:2800,de:2000,tx:"「BF」と名のついたチューナー＋チューナー以外のモンスター２体以上\nこのカードがシンクロ召喚に成功した時、このカードの攻撃力よりも低い守備力を持つ、フィールド上のモンスターを２体まで選択して破壊できる。\nこの効果を発動するターン、自分はバトルフェイズを行えない。\nまた、相手のターンに１度だけ、このカードがフィールド上に表側表示で存在する限り、自分フィールド上の「BF」と名のついたモンスターは戦闘では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"BF－逆巻のトルネード",h:"ぶらっくふぇざー－さかまきのとるねーど",e:"Blackwing - Tornado the Reverse Wind",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1000,de:1200,tx:"①：特殊召喚されたモンスターが相手フィールドに存在し、このカードが召喚に成功した時、自分の墓地の「BF」チューナー１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。\nこの効果の発動後、ターン終了時まで自分は「BF」モンスターしかエクストラデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"BF－砂塵のハルマッタン",h:"ぶらっくふぇざー－さじんのはるまったん",e:"Blackwing - Harmattan the Dust",ra:"R",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"「BF－砂塵のハルマッタン」の①の方法による特殊召喚は１ターンに１度しかできない。\n①：自分フィールドに「BF－砂塵のハルマッタン」以外の「BF」モンスターが存在する場合、このカードは手札から特殊召喚できる。\n②：このカードが召喚・特殊召喚に成功した時、このカード以外の自分フィールドの「BF」モンスター１体を対象として発動できる。\nこのカードのレベルをそのモンスターのレベル分だけ上げる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"BF－流離いのコガラシ",h:"ぶらっくふぇざー－さすらいのこがらし",e:"Blackwing - Kogarashi the Wanderer",ra:"R",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"鳥獣族",mo:["チューナー","効果"],ma:"",tp:"",at:2300,de:1600,tx:"自分フィールド上に表側表示で存在する「BF」と名のついたモンスターがカードの効果によって破壊され墓地へ送られた時、このカードを手札から特殊召喚する事ができる。\nまた、このカードをシンクロ素材としたシンクロ召喚に成功した時、相手は魔法・罠・効果モンスターの効果を発動する事ができない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"BF－残夜のクリス",h:"ぶらっくふぇざー－ざんやのくりす",e:"Blackwing - Kris the Crack of Dawn",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1900,de:300,tx:"「BF－残夜のクリス」の①の方法による特殊召喚は１ターンに１度しかできない。\n①：自分フィールドに「BF－残夜のクリス」以外の「BF」モンスターが存在する場合、このカードは手札から特殊召喚できる。\n②：このカードは１ターンに１度だけ、魔法・罠カードの効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"BF－漆黒のエルフェン",h:"ぶらっくふぇざー－しっこくのえるふぇん",e:"Blackwing - Elphin the Raven",ra:"UR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2200,de:1200,tx:"自分フィールド上に「BF」と名のついたモンスターが表側表示で存在する場合、このカードはリリースなしで召喚する事ができる。\nこのカードが召喚に成功した時、相手フィールド上に存在するモンスター１体の表示形式を変更する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"BF－疾風のゲイル",h:"ぶらっくふぇざー－しっぷうのげいる",e:"Blackwing - Gale the Whirlwind",ra:"UR",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["チューナー","効果"],ma:"",tp:"",at:1300,de:400,tx:"①：自分フィールドに「BF－疾風のゲイル」以外の「BF」モンスターが存在する場合、このカードは手札から特殊召喚できる。\n②：１ターンに１度、相手フィールドの表側表示モンスター１体を対象として発動できる。\nその相手モンスターの攻撃力・守備力を半分にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"BF－東雲のコチ",h:"ぶらっくふぇざー－しののめのこち",e:"Blackwing - Kochi the Daybreak",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["チューナー","効果"],ma:"",tp:"",at:700,de:1500,tx:"特殊召喚されたこのカードはS素材にできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"BF－上弦のピナーカ",h:"ぶらっくふぇざー－じょうげんのぴなーか",e:"Blackwing - Pinaki the Waxing Moon",ra:"UR",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["チューナー","効果"],ma:"",tp:"",at:1200,de:1000,tx:"「BF－上弦のピナーカ」の効果は１ターンに１度しか使用できない。\nこのカードをS素材とする場合、「BF」モンスターのS召喚にしか使用できない。\n①：このカードがフィールドから墓地へ送られたターンのエンドフェイズに発動できる。\nデッキから「BF－上弦のピナーカ」以外の「BF」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"BF－精鋭のゼピュロス",h:"ぶらっくふぇざー－せいえいのぜぴゅろす",e:"Blackwing - Zephyros the Elite",ra:"UR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"このカード名の効果はデュエル中に１度しか使用できない。\n①：このカードが墓地に存在する場合、自分フィールドの表側表示のカード１枚を持ち主の手札に戻して発動できる。\nこのカードを墓地から特殊召喚し、自分は４００ダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"BF－尖鋭のボーラ",h:"ぶらっくふぇざー－せんえいのぼーら",e:"Blackwing - Boreas the Sharp",ra:"R",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1300,de:900,tx:"墓地に存在するこのカードをゲームから除外し、自分フィールド上に表側表示で存在する「BF」と名のついたモンスター１体を選択して発動する。\nこのターン選択したモンスターが攻撃する場合、その攻撃によって発生する自分への戦闘ダメージは０になり、選択したモンスターは戦闘では破壊されず、戦闘を行った相手モンスターをダメージ計算後に破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"BF－蒼炎のシュラ",h:"ぶらっくふぇざー－そうえんのしゅら",e:"Blackwing - Shura the Blue Flame",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1800,de:1200,tx:"①：このカードが戦闘で相手モンスターを破壊し墓地へ送った時に発動できる。\nデッキから攻撃力１５００以下の「BF」モンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"BF－蒼天のジェット",h:"ぶらっくふぇざー－そうてんのじぇっと",e:"Blackwing - Jetstream the Blue Sky",ra:"N",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:100,de:800,tx:"戦闘ダメージ計算時、このカードを手札から墓地へ送って発動する。\n自分フィールド上に存在する「BF」と名のついたモンスターはその戦闘では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"BF－そよ風のブリーズ",h:"ぶらっくふぇざー－そよかぜのぶりーず",e:"Blackwing - Breeze the Zephyr",ra:"R",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["チューナー","効果"],ma:"",tp:"",at:1100,de:300,tx:"このカードがカードの効果によって自分のデッキから手札に加わった場合、このカードを手札から特殊召喚できる。\nこのカードをシンクロ素材とする場合、「BF」と名のついたモンスターのシンクロ召喚にしか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"BF－大旆のヴァーユ",h:"ぶらっくふぇざー－たいはいのう゛ぁーゆ",e:"Blackwing - Vayu the Emblem of Honor",ra:"R",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"鳥獣族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:0,tx:"①：このカードはモンスターゾーンに存在する限り、S素材にできない。\n②：このカードが墓地に存在する場合、チューナー以外の自分の墓地の「BF」モンスター１体を対象として発動できる。\nそのモンスターとこのカードを墓地から除外し、その２体のレベルの合計と同じレベルを持つ「BF」Sモンスター１体をエクストラデッキから特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"BF－竜巻のハリケーン",h:"ぶらっくふぇざー－たつまきのはりけーん",e:"Blackwing - Hurricane the Tornado",ra:"N",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"鳥獣族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"①：１ターンに１度、フィールドのSモンスター１体を対象として発動できる。\nこのカードの攻撃力はターン終了時まで、対象のモンスターの攻撃力と同じになる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"BF－鉄鎖のフェーン",h:"ぶらっくふぇざー－てっさのふぇーん",e:"Blackwing - Fane the Steel Chain",ra:"SR",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:500,de:800,tx:"このカードは相手プレイヤーに直接攻撃する事ができる。\nこのカードが直接攻撃によって相手ライフに戦闘ダメージを与えた時、相手フィールド上に攻撃表示で存在するモンスター１体を守備表示にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"BF－天狗風のヒレン",h:"ぶらっくふぇざー－てんぐかぜのひれん",e:"Blackwing - Hillen the Tengu-wind",ra:"N",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"鳥獣族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:2300,tx:"このカードが墓地に存在し、相手モンスターの直接攻撃によって自分が２０００ポイント以上の戦闘ダメージを受けた時、自分の墓地に存在する「BF」と名のついたレベル３以下のモンスター１体を選択して発動する。\n選択したモンスターとこのカードを墓地から特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。\n「BF－天狗風のヒレン」の効果はデュエル中に１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"BF－毒風のシムーン",h:"ぶらっくふぇざー－どくかぜのしむーん",e:"Blackwing - Simoon the Poison Wind",ra:"UR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1600,de:2000,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：自分フィールドにモンスターが存在しない場合、手札からこのカード以外の「BF」モンスター１体を除外して発動できる。\nデッキから「黒い旋風」１枚を自分の魔法＆罠ゾーンに表側表示で置く。\nその後、手札のこのカードをリリースなしで召喚するか、墓地へ送る。\nこの効果で置いた「黒い旋風」はエンドフェイズに墓地へ送られ、自分は１０００ダメージを受ける。\nこの効果の発動後、ターン終了時まで自分は闇属性モンスターしかEXデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"BF－突風のオロシ",h:"ぶらっくふぇざー－とっぷうのおろし",e:"Blackwing - Oroshi the Squall",ra:"N",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"鳥獣族",mo:["チューナー","効果"],ma:"",tp:"",at:400,de:600,tx:"このカード名の①の方法による特殊召喚は１ターンに１度しかできない。\n①：自分フィールドに「BF－突風のオロシ」以外の「BF」モンスターが存在する場合、このカードは手札から特殊召喚できる。\n②：このカードがS素材として墓地へ送られた場合、フィールドのモンスター１体を対象として発動できる。\nそのモンスターの表示形式を変更する。",li:2,lk:[],ta:[],al:[],ocg:true},
  {n:"BF－弔風のデス",h:"ぶらっくふぇざー－とむらいかぜのです",e:"Blackwing - Decay the Ill Wind",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["チューナー","効果"],ma:"",tp:"",at:1000,de:1000,tx:"「BF－弔風のデス」の①の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した時、自分フィールドの「BF」モンスター１体を対象とし、以下の効果から１つを選択して発動できる。\n\n●対象のモンスターのレベルを１つ上げる。\n\n●対象のモンスターのレベルを１つ下げる。\n\n②：このカードが墓地へ送られたターンのエンドフェイズに発動する。\n自分は１０００ダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"BF－二の太刀のエテジア",h:"ぶらっくふぇざー－にのたちのえてじあ",e:"Blackwing - Etesian of Two Swords",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:400,de:1600,tx:"自分フィールド上に存在する「BF」と名のついたモンスターが相手モンスターとの戦闘を行ったダメージステップ終了時にその相手モンスターがフィールド上に存在する場合、このカードを手札から墓地へ送って発動する事ができる。\n相手ライフに１０００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"BF－熱風のギブリ",h:"ぶらっくふぇざー－ねっぷうのぎぶり",e:"Blackwing - Ghibli the Searing Wind",ra:"SR",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:0,de:1600,tx:"①：相手モンスターの直接攻撃宣言時に発動できる。\nこのカードを手札から特殊召喚する。\n②：１ターンに１度、自分メインフェイズに発動できる。\nこのカードの元々の攻撃力・守備力をターン終了時まで入れ替える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"BF－白夜のグラディウス",h:"ぶらっくふぇざー－びゃくやのぐらでぃうす",e:"Blackwing - Gladius the Midnight Sun",ra:"R",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:800,de:1500,tx:"①：自分フィールドの表側表示モンスターが「BF－白夜のグラディウス」以外の「BF」モンスター１体のみの場合、このカードは手札から特殊召喚できる。\n②：このカードは１ターンに１度だけ戦闘では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブラックフェザー・ドラゴン",h:"ぶらっくふぇざー・どらごん",e:"Black-Winged Dragon",ra:"UR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2800,de:1600,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：自分が効果ダメージを受ける場合、代わりにこのカードに黒羽カウンターを１つ置く。\n②：このカードの攻撃力は、このカードの黒羽カウンターの数×７００ダウンする。\n③：１ターンに１度、このカードの黒羽カウンターを全て取り除き、相手フィールドの表側表示モンスター１体を対象として発動できる。\nその相手モンスターの攻撃力は取り除いた黒羽カウンターの数×７００ダウンし、ダウンした数値分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"暗黒プテラ",h:"ぶらっくぷてら",e:"Black Ptera",ra:"SR",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1000,de:500,tx:"このカードが戦闘によって破壊される以外の方法でフィールド上から墓地に送られた時、このカードは持ち主の手札に戻る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"暗黒ブラキ",h:"ぶらっくぶらき",e:"Black Brachios",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1800,de:1100,tx:"①：このカードが召喚に成功した時、フィールドのモンスター１体を対象として発動できる。\nそのモンスターを表側守備表示にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブラックポータン",h:"ぶらっくぽーたん",e:"Black Potan",ra:"R",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:200,de:100,tx:"自分フィールド上にチューナーが表側表示で存在する場合、このカードは戦闘では破壊されない。\n自分フィールド上に表側表示で存在するチューナーがフィールド上から離れた時、自分は８００ライフポイント回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"黒鋼竜",h:"ぶらっくめたるどらごん",e:"Black Metal Dragon",ra:"UR",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:600,de:600,tx:"①：自分メインフェイズに自分フィールドの「レッドアイズ」モンスター１体を対象として発動できる。\n自分の手札・フィールドからこのモンスターを攻撃力６００アップの装備カード扱いとしてその自分のモンスターに装備する。\n②：このカードがフィールドから墓地へ送られた場合に発動できる。\nデッキから「レッドアイズ」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"黒薔薇の魔女",h:"ぶらっくろーずうぃっち",e:"Witch of the Black Rose",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["チューナー","効果"],ma:"",tp:"",at:1700,de:1200,tx:"このカードは特殊召喚できない。\n①：自分フィールドに他のカードが存在せず、このカードが召喚に成功した場合に発動する。\n自分はデッキから１枚ドローし、お互いに確認する。\nそれがモンスター以外だった場合、そのカードは墓地へ送られ、このカードは破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブラッド・ヴォルス",h:"ぶらっど・う゛ぉるす",e:"Vorse Raider",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["通常"],ma:"",tp:"",at:1900,de:1200,tx:"悪行の限りを尽くし、それを喜びとしている魔獣人。\n手にした斧は常に血塗られている。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブラッド・オーキス",h:"ぶらっど・おーきす",e:"Vampiric Orchis",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"このカードが召喚に成功した時、手札から「デス・デンドル」１体を特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブラッド・サッカー",h:"ぶらっど・さっかー",e:"Blood Sucker",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1300,de:1500,tx:"このカードが相手ライフに戦闘ダメージを与えた時、相手のデッキの上からカードを１枚墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブラッド・マジシャン－煉獄の魔術師－",h:"ぶらっど・まじしゃん－れんごくのまじゅつし－",e:"Blast Magician",ra:"R",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1400,de:1700,tx:"このカードがフィールド上に表側表示で存在する限り、自分または相手が魔法カードを発動する度に、このカードに魔力カウンターを１つ置く。\nこのカードに乗っている魔力カウンターを任意の個数取り除く事で、取り除いた数×７００ポイント以下の攻撃力を持つフィールド上に表側表示で存在するモンスター１体を破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブラッド・メフィスト",h:"ぶらっど・めふぃすと",e:"Blood Mefist",ra:"UR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"悪魔族",mo:["シンクロ","効果"],ma:"",tp:"",at:2800,de:1300,tx:"チューナー＋チューナー以外のモンスター１体以上\n相手のスタンバイフェイズ時、相手フィールド上に存在するカード１枚につき相手ライフに３００ポイントダメージを与える事ができる。\nまた、相手が魔法・罠カードをセットした時、相手ライフに３００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フラッピィ",h:"ふらっぴぃ",e:"Slushy",ra:"R",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカードが召喚に成功した時、デッキから「フラッピィ」１体を墓地へ送る事ができる。\nまた、自分の墓地の「フラッピィ」及びゲームから除外されている自分の「フラッピィ」の合計が３体の場合、墓地のこのカードをゲームから除外して発動できる。\n自分の墓地の海竜族・レベル５以上のモンスター１体を選択して特殊召喚する。\n「フラッピィ」のこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"惑星探査車",h:"ぷらねっと・ぱすふぁいんだー",e:"Planet Pathfinder",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"①：このカードをリリースして発動できる。\nデッキからフィールド魔法カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フラボット",h:"ふらぼっと",e:"Flowerbot",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1500,de:800,tx:"①：このカードが墓地へ送られた場合に発動する。\n自分はデッキから１枚ドローする。\nその後、手札を１枚持ち主のデッキの一番上に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フラワー・ウルフ",h:"ふらわー・うるふ",e:"Flower Wolf",ra:"N",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"獣族",mo:["融合"],ma:"",tp:"",at:1800,de:1400,tx:"シルバー・フォング＋魔界のイバラ",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"腐乱犬",h:"ふらんけん",e:"Zombowwow",ra:"R",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードの攻撃力は、このカードが攻撃宣言する度に５００ポイントアップする。\nまた、フィールド上のこのカードが破壊され墓地へ送られた場合、デッキから攻撃力と守備力が０のレベル１モンスター１体を特殊召喚できる。\nこの効果で特殊召喚したモンスターの効果は無効化され、次の自分のエンドフェイズ時に破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"プリヴェント・ラット",h:"ぷりう゛ぇんと・らっと",e:"Prevent Rat",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:500,de:2000,tx:"毛が集まり、かたい皮のようになっている。\n守備はかなり高い。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブリキの大公",h:"ぶりきのたいこう",e:"Tin Archduke",ra:"UR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2200,de:1200,tx:"レベル４モンスター×３\n１ターンに１度、このカードのエクシーズ素材を１つ取り除き、相手フィールド上のモンスター１体を選択して発動できる。\n選択したモンスターの表示形式を変更する。\nこの時、リバース効果モンスターの効果は発動しない。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブリキンギョ",h:"ぶりきんぎょ",e:"Tin Goldfish",ra:"UR",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:800,de:2000,tx:"①：このカードが召喚に成功した時に発動できる。\n手札からレベル４モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"振り子刃の拷問機械",h:"ふりこやいばのごうもんきかい",e:"Pendulum Machine",ra:"N",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1750,de:2000,tx:"大きな振り子の刃で相手をまっぷたつ！恐ろしい拷問機械。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブリザード・ウォリアー",h:"ぶりざーど・うぉりあー",e:"Blizzard Warrior",ra:"N",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1400,de:400,tx:"このカードが戦闘によって相手モンスターを破壊した場合、相手のデッキの一番上のカードを確認してデッキの一番上または一番下に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブリザード・サンダーバード",h:"ぶりざーど・さんだーばーど",e:"Blizzard Thunderbird",ra:"R",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1600,de:1400,tx:"手札を１枚捨てて発動できる。\n「ブリザード・サンダーバード」以外の鳥獣族・水属性モンスターを自分の手札・墓地からそれぞれ１体ずつ選んで特殊召喚する。\nその後、フィールド上のこのカードを持ち主の手札に戻す。\n「ブリザード・サンダーバード」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブリザード・プリンセス",h:"ぶりざーど・ぷりんせす",e:"Blizzard Princess",ra:"SR",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2800,de:2100,tx:"このカードは魔法使い族モンスター１体をリリースして表側攻撃表示でアドバンス召喚する事ができる。\nこのカードが召喚に成功したターン、相手は魔法・罠カードを発動する事ができない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"プリズマン",h:"ぷりずまん",e:"Prisman",ra:"N",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"岩石族",mo:["通常"],ma:"",tp:"",at:800,de:1000,tx:"透き通った水晶の塊。\n光を集めてレーザーを放つ。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"プリズンクインデーモン",h:"ぷりずんくいんでーもん",e:"Imprisoned Queen Archfiend",ra:"R",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2600,de:1700,tx:"このカードのコントローラーは自分のスタンバイフェイズ毎に１０００ライフポイントを払う。\nフィールド上に「万魔殿－悪魔の巣窟－」が存在し、このカードが墓地に存在する場合、自分のスタンバイフェイズ毎にフィールド上に存在するレベル４以下の悪魔族モンスター１体の攻撃力はエンドフェイズ時まで１０００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フリック・クラウン",h:"ふりっく・くらうん",e:"Flick Clown",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1000,de:1400,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：自分フィールドにこのカード以外のサイバース族モンスターが２体以上存在し、自分の手札が０枚の場合、１０００LPを払って発動できる。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"プリベントマト",h:"ぷりべんとまと",e:"Interceptomato",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"墓地のこのカードをゲームから除外して発動できる。\nこのターン、自分が受ける効果ダメージは０になる。\nこの効果は相手ターンにのみ発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"プリミティブ・バタフライ",h:"ぷりみてぃぶ・ばたふらい",e:"Primitive Butterfly",ra:"SR",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1200,de:900,tx:"①：自分フィールドにモンスターが存在しない場合、このカードは手札から特殊召喚できる。\n②：１ターンに１度、自分メインフェイズに発動できる。\n自分フィールドの全ての昆虫族モンスターのレベルを１つ上げる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブリューナクの影霊衣",h:"ぶりゅーなくのねくろす",e:"Nekroz of Brionac",ra:"UR",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"戦士族",mo:["儀式","効果"],ma:"",tp:"",at:2300,de:1400,tx:"「影霊衣」儀式魔法カードにより降臨。\n「ブリューナクの影霊衣」以外のモンスターのみを使用した儀式召喚でしか特殊召喚できない。\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードを手札から捨てて発動できる。\nデッキから「ブリューナクの影霊衣」以外の「影霊衣」モンスター１体を手札に加える。\n②：EXデッキから特殊召喚されたフィールドのモンスターを２体まで対象として発動できる。\nそのモンスターを持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"鰤っ子姫",h:"ぶりんせす",e:"Beautunaful Princess",ra:"SR",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカードが召喚・特殊召喚に成功した時、このカードをゲームから除外して発動できる。\nデッキから「鰤っ子姫」以外のレベル４以下の魚族モンスター１体を特殊召喚する。\n「鰤っ子姫」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"プリンセス人魚",h:"ぷりんせすにんぎょ",e:"Cure Mermaid",ra:"R",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1500,de:800,tx:"このカードが自分フィールド上に表側表示で存在する限り、自分のスタンバイフェイズ毎に自分は８００ライフポイント回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フリントロック",h:"ふりんとろっく",e:"Flint Lock",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1500,de:800,tx:"フィールド上に表側表示の「フリント」が存在する場合、このカードに装備させる事ができる。\nこの効果は１ターンに１度しか使用できない。\nまた、このカードが装備している「フリント」１枚を、フィールド上に存在する表側表示モンスター１体に装備させる事ができる。\nこのカードが装備できる「フリント」は１枚まで。\nこのカードが「フリント」を装備している限り、このカードは「フリント」の効果を受けず、戦闘によっては破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"FA－ブラック・レイ・ランサー",h:"ふるあーまーど－ぶらっく・れい・らんさー",e:"Full Armored Black Ray Lancer",ra:"SR",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2100,de:600,tx:"水属性レベル４モンスター×３\nこのカードは自分フィールドのX素材の無いランク３の水属性Xモンスターの上に重ねてX召喚する事もできる。\n①：このカードの攻撃力は、このカードのX素材の数×２００アップする。\n②：このカードが戦闘で相手モンスターを破壊した時、相手フィールドの魔法・罠カード１枚を対象として発動できる。\nその相手のカードを破壊する。\n③：フィールドのこのカードが戦闘・効果で破壊される場合、代わりにこのカードのX素材を全て取り除く事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブルー・ダストン",h:"ぶるー・だすとん",e:"Blue Duston",ra:"N",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:1000,tx:"このカードはリリースできず、融合・シンクロ・エクシーズ召喚の素材にもできない。\nフィールド上のこのカードが破壊された時、このカードのコントローラーの手札をランダムに１枚選び、次のターンのスタンバイフェイズ時まで裏側表示でゲームから除外する。\n「ブルー・ダストン」は自分フィールド上に１体しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"青眼の究極竜",h:"ぶるーあいず・あるてぃめっとどらごん",e:"Blue-Eyes Ultimate Dragon",ra:"UR",t:"monster",a:"光",l:12,ps:"",pe:"",tr:"ドラゴン族",mo:["融合"],ma:"",tp:"",at:4500,de:3800,tx:"「青眼の白龍」＋「青眼の白龍」＋「青眼の白龍」",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"青眼の亜白龍",h:"ぶるーあいず・おるたなてぃぶ・ほわいと・どらごん",e:"Blue-Eyes Alternative White Dragon",ra:"UR",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3000,de:2500,tx:"このカードは通常召喚できない。\n手札の「青眼の白龍」１体を相手に見せた場合に特殊召喚できる。\nこの方法による「青眼の亜白龍」の特殊召喚は１ターンに１度しかできない。\n①：このカードのカード名は、フィールド・墓地に存在する限り「青眼の白龍」として扱う。\n②：１ターンに１度、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊する。\nこの効果を発動するターン、このカードは攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"青眼の光龍",h:"ぶるーあいず・しゃいにんぐどらごん",e:"Blue-Eyes Shining Dragon",ra:"UR",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3000,de:2500,tx:"このカードは通常召喚できない。\n自分フィールドの「青眼の究極竜」１体をリリースした場合のみ特殊召喚できる。\n①：このカードの攻撃力は、自分の墓地のドラゴン族モンスターの数×３００アップする。\n②：このカードを対象とする魔法・罠・モンスターの効果が発動した時に発動できる。\nその効果を無効にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"青眼の精霊龍",h:"ぶるーあいず・すぴりっと・どらごん",e:"Blue-Eyes Spirit Dragon",ra:"UR",t:"monster",a:"光",l:9,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:3000,tx:"チューナー＋チューナー以外の「ブルーアイズ」モンスター１体以上\n①：このカードがモンスターゾーンに存在する限り、お互いに２体以上のモンスターを同時に特殊召喚できない。\n②：１ターンに１度、墓地のカードの効果が発動した時に発動できる。\nその発動を無効にする。\n③：S召喚したこのカードをリリースして発動できる。\nエクストラデッキから「青眼の精霊龍」以外のドラゴン族・光属性のSモンスター１体を守備表示で特殊召喚する。\nそのモンスターはこのターンのエンドフェイズに破壊される。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブルーアイズ・ソリッド・ドラゴン",h:"ぶるーあいず・そりっど・どらごん",e:"Blue-Eyes Solid Dragon",ra:"UR",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2500,de:2000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの効果を無効にする。\n②：相手が魔法・罠・モンスターの効果を発動した時に発動できる。\nフィールドのこのカードを持ち主のデッキに戻し、デッキから「青眼の白龍」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"青眼の双爆裂龍",h:"ぶるーあいず・ついん・ばーすと・どらごん",e:"Blue-Eyes Twin Burst Dragon",ra:"UR",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:3000,de:2500,tx:"「青眼の白龍」＋「青眼の白龍」\nこのカードは融合召喚及び以下の方法でのみ特殊召喚できる。\n●自分のモンスターゾーンの上記カードを墓地へ送った場合にEXデッキから特殊召喚できる（「融合」は必要としない）。\n①：このカードは戦闘では破壊されない。\n②：このカードは１度のバトルフェイズ中に２回までモンスターに攻撃できる。\n③：このカードの攻撃で相手モンスターが破壊されなかったダメージステップ終了時に発動できる。\nその相手モンスターを除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブルーアイズ・トゥーン・ドラゴン",h:"ぶるーあいず・とぅーん・どらごん",e:"Blue-Eyes Toon Dragon",ra:"SR",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","トゥーン","効果"],ma:"",tp:"",at:3000,de:2500,tx:"このカードは通常召喚できない。\n自分フィールドに「トゥーン・ワールド」が存在し、自分フィールドのモンスター２体をリリースした場合に特殊召喚できる。\n①：このカードは特殊召喚したターンには攻撃できない。\n②：このカードは５００LPを払わなければ攻撃宣言できない。\n③：このカードは、相手フィールドにトゥーンモンスターが存在しない場合、直接攻撃できる。\n存在する場合、トゥーンモンスターしか攻撃対象に選択できない。\n④：フィールドの「トゥーン・ワールド」が破壊された時にこのカードは破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"青眼の白龍",h:"ぶるーあいず・ほわいと・どらごん",e:"Blue-Eyes White Dragon",ra:"UR",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:3000,de:2500,tx:"高い攻撃力を誇る伝説のドラゴン。\nどんな相手でも粉砕する、その破壊力は計り知れない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"青氷の白夜龍",h:"ぶるーあいす・ほわいとないつ・どらごん",e:"White Night Dragon",ra:"UR",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:3000,de:2500,tx:"①：このカードを対象とする魔法・罠カードが発動した時に発動する。\nその発動を無効にし破壊する。\n②：このカード以外の自分の表側表示モンスターが攻撃対象に選択された時、自分フィールドの魔法・罠カード１枚を墓地へ送って発動できる。\n攻撃対象をこのカードに移し替える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"青眼の銀ゾンビ",h:"ぶるーあいど・しるばーぞんび",e:"Blue-Eyed Silver Zombie",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["通常"],ma:"",tp:"",at:900,de:700,tx:"目から出す怪光線で、相手をゾンビに変えてしまうと言われている。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブルーサンダーT45",h:"ぶるーさんだーＴ４５",e:"Blue Thunder T-45",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"このカードが戦闘によって相手モンスターを破壊した場合、自分フィールド上に「サンダーオプショントークン」（機械族・光・星４・攻／守１５００）１体を特殊召喚する。\nこのトークンはアドバンス召喚のためにはリリースできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"青い忍者",h:"ぶるーにんじゃ",e:"Armed Ninja",ra:"R",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"戦士族",mo:["リバース","効果"],ma:"",tp:"",at:300,de:300,tx:"①：このカードがリバースした場合、フィールドの表側表示の魔法カード１枚またはフィールドにセットされた魔法・罠カード１枚を対象として発動する。\nその魔法カードを破壊する（そのカードがフィールドにセットされている場合、めくって確認する）。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フルール・シンクロン",h:"ふるーる・しんくろん",e:"Fleur Synchron",ra:"N",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:400,de:200,tx:"①：このカードがS素材として墓地へ送られた場合に発動できる。\n手札からレベル２以下のモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フルール・ド・シュヴァリエ",h:"ふるーる・ど・しゅう゛ぁりえ",e:"Chevalier de Fleur",ra:"R",t:"monster",a:"風",l:8,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2700,de:2300,tx:"「フルール・シンクロン」＋チューナー以外のモンスター１体以上\n①：１ターンに１度、自分ターンに相手が魔法・罠カードを発動した時に発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブルーローズ・ドラゴン",h:"ぶるーろーず・どらごん",e:"Blue Rose Dragon",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"①：フィールドのこのカードが破壊され墓地へ送られた時、自分の墓地の、「ブラック・ローズ・ドラゴン」または植物族モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フルエルフ",h:"ふるえるふ",e:"Flelf",ra:"N",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:800,de:1300,tx:"１ターンに１度、手札のモンスター１体を相手に見せて発動する事ができる。\nエンドフェイズ時まで、見せたモンスターのレベル分だけこのカードのレベルを上げる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブルブレーダー",h:"ぶるぶれーだー",e:"Bull Blader",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"このカードが相手モンスターと戦闘を行う攻撃宣言時に発動できる。\nその戦闘によって発生するお互いのプレイヤーへの戦闘ダメージは０になり、ダメージ計算後にその相手モンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フレア・リゾネーター",h:"ふれあ・りぞねーたー",e:"Flare Resonator",ra:"SR",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:300,de:1300,tx:"このカードをシンクロ素材としたシンクロモンスターの攻撃力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブレイドナイト",h:"ぶれいどないと",e:"Blade Knight",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"①：自分の手札が１枚以下の場合、このカードの攻撃力は４００アップする。\n②：自分フィールドにこのカード以外のモンスターが存在しない場合、このカードが戦闘で破壊したリバースモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブレイブ・シザー",h:"ぶれいぶ・しざー",e:"Brave Scizzar",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1300,de:1000,tx:"何本も持っているハサミを器用に動かし、相手を切り刻む。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フレイム・ヴァイパー",h:"ふれいむ・う゛ぁいぱー",e:"Flame Viper",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"炎族",mo:["通常"],ma:"",tp:"",at:400,de:450,tx:"シュルシュルと素早く動き、口から火炎をはくマムシ。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フレイム・オーガ",h:"ふれいむ・おーが",e:"Flame Ogre",ra:"N",t:"monster",a:"炎",l:7,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2400,de:1700,tx:"このカードは特殊召喚できない。\nこのカードの召喚に成功した時、自分のデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フレイム・ケルベロス",h:"ふれいむ・けるべろす",e:"Flame Cerebrus",ra:"N",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"炎族",mo:["通常"],ma:"",tp:"",at:2100,de:1800,tx:"全身が炎に包まれた魔獣。\n相手を地獄の炎で処刑する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フレイム・ゴースト",h:"ふれいむ・ごーすと",e:"Flame Ghost",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["融合"],ma:"",tp:"",at:1000,de:800,tx:"「ワイト」＋「マグマン」",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"焔虎",h:"ふれいむ・たいがー",e:"Flame Tiger",ra:"SR",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1800,de:0,tx:"このカードが墓地に存在し、自分フィールド上にモンスターが存在しない場合、自分のドローフェイズ時に通常のドローを行う代わりに発動できる。\nこのカードを墓地から特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールド上から離れた場合ゲームから除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"炎を支配する者",h:"ふれいむ・るーらー",e:"Flame Ruler",ra:"R",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:1500,de:1600,tx:"炎属性モンスターを生け贄召喚する場合、このモンスター１体で２体分の生け贄とする事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"炎神機－紫龍",h:"ふれいむぎあ－しりゅう",e:"Pyrotech Mech - Shiryu",ra:"SR",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:2900,de:1800,tx:"このカードはモンスター１体をリリースして召喚できる。\nこの方法で召喚している場合、自分はエンドフェイズ毎に１０００ポイントダメージを受ける。\nこのカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フレイムキラー",h:"ふれいむきらー",e:"Flame Champion",ra:"N",t:"monster",a:"炎",l:5,ps:"",pe:"",tr:"炎族",mo:["通常"],ma:"",tp:"",at:1900,de:1300,tx:"炎の盾を使う剣士。\nその盾はどんな攻撃でも無効化してしまう。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フレイムダンサー",h:"ふれいむだんさー",e:"Flame Dancer",ra:"N",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"炎族",mo:["通常"],ma:"",tp:"",at:550,de:450,tx:"燃えたぎるロープを振り回し近づいてくる。\n目を合わせたら駄目だ。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブレイン・クラッシャー",h:"ぶれいん・くらっしゃー",e:"Brain Crusher",ra:"N",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:2400,de:1500,tx:"このカードが戦闘によって相手モンスターを破壊し墓地へ送った場合、破壊したモンスター１体をそのターンのエンドフェイズ時に墓地から特殊召喚する事ができる。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブレード・スケーター",h:"ぶれーど・すけーたー",e:"Blade Skater",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1400,de:1500,tx:"氷上の舞姫は、華麗なる戦士。\n必殺アクセル・スライサーで華麗に敵モンスターを切り裂く。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブレード・バウンサー",h:"ぶれーど・ばうんさー",e:"Blade Bounzer",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1400,tx:"このカードが攻撃を行ったダメージステップ終了時、相手フィールド上にモンスターが存在する場合、手札を１枚捨てて発動できる。\nこのバトルフェイズ中、このカードは相手モンスターにもう１度だけ攻撃できる。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"プレートクラッシャー",h:"ぷれーとくらっしゃー",e:"Seismic Crasher",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1400,de:300,tx:"自分フィールド上に存在する表側表示の永続魔法または永続罠カード１枚を墓地に送る。\n相手ライフに５００ポイントダメージを与える。\nこの効果は１ターンに２度まで使用できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブレードフライ",h:"ぶれーどふらい",e:"Bladefly",ra:"N",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:600,de:700,tx:"このカードがフィールド上に表側表示で存在する限り、フィールド上に表側表示で存在する風属性モンスターの攻撃力は５００ポイントアップし、地属性モンスターの攻撃力は４００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブレードラビット",h:"ぶれーどらびっと",e:"Blade Rabbit",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:400,de:300,tx:"このカードの表示形式が攻撃表示から表側守備表示に変わった時、相手フィールド上のモンスター１体を破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"捕食植物キメラフレシア",h:"ぷれでたー・ぷらんつきめらふれしあ",e:"Predaplant Chimerafflesia",ra:"UR",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"植物族",mo:["融合","効果"],ma:"",tp:"",at:2500,de:2000,tx:"「捕食植物」モンスター＋闇属性モンスター\n①：１ターンに１度、このカードのレベル以下のレベルを持つフィールドのモンスター１体を対象として発動できる。\nそのモンスターを除外する。\n②：このカードが相手の表側表示モンスターと戦闘を行う攻撃宣言時に発動できる。\nターン終了時まで、その相手モンスターの攻撃力は１０００ダウンし、このカードの攻撃力は１０００アップする。\n③：このカードが墓地へ送られた場合、次のスタンバイフェイズに発動できる。\nデッキから「融合」魔法カードまたは「フュージョン」魔法カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"捕食植物コーディセップス",h:"ぷれでたー・ぷらんつこーでぃせっぷす",e:"Predaplant Cordyceps",ra:"N",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"①：自分スタンバイフェイズに墓地のこのカードを除外し、自分の墓地のレベル４以下の「捕食植物」モンスター２体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は通常召喚できず、融合モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"捕食植物サンデウ・キンジー",h:"ぷれでたー・ぷらんつさんでう・きんじー",e:"Predaplant Chlamydosundew",ra:"R",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:600,de:200,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、自分が融合素材とする捕食カウンターが置かれたモンスターの属性は闇属性として扱う。\n②：自分メインフェイズに発動できる。\n闇属性の融合モンスターカードによって決められた、フィールドのこのカードを含む融合素材モンスターを自分の手札・フィールド及び相手フィールドの捕食カウンターが置かれたモンスターの中から選んで墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"捕食植物スキッド・ドロセーラ",h:"ぷれでたー・ぷらんつすきっど・どろせーら",e:"Predaplant Squid Drosera",ra:"N",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:800,de:400,tx:"①：このカードを手札から墓地へ送り、自分フィールドの表側表示モンスター１体を対象として発動できる。\nこのターン、そのモンスターは捕食カウンターが置かれた相手モンスター全てに１回ずつ攻撃できる。\n②：表側表示のこのカードがフィールドから離れた場合に発動する。\n相手フィールドの特殊召喚されたモンスター全てに捕食カウンターを１つずつ置く。\n捕食カウンターが置かれたレベル２以上のモンスターのレベルは１になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"捕食植物スピノ・ディオネア",h:"ぷれでたー・ぷらんつすぴの・でぃおねあ",e:"Predaplant Spinodionaea",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1800,de:0,tx:"①：このカードが召喚・特殊召喚に成功した場合、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターに捕食カウンターを１つ置く。\n捕食カウンターが置かれたレベル２以上のモンスターのレベルは１になる。\n②：このカードがこのカードのレベル以下のレベルを持つモンスターと戦闘を行ったダメージ計算後に発動できる。\nデッキから「捕食植物スピノ・ディオネア」以外の「捕食植物」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"捕食植物へリアンフォリンクス",h:"ぷれでたー・ぷらんつへりあんふぉりんくす",e:"Predaplant Heliamphorhynchus",ra:"N",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1200,de:2400,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手フィールドのモンスターに捕食カウンターが置かれている場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが相手によって破壊された場合、「捕食植物ヘリアンフォリンクス」以外の自分の墓地のドラゴン族・植物族の闇属性モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"捕食植物モーレイ・ネペンテス",h:"ぷれでたー・ぷらんつもーれい・ねぺんてす",e:"Predaplant Moray Nepenthes",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"①：このカードの攻撃力は、フィールドの捕食カウンターの数×２００アップする。\n②：このカードが戦闘で相手モンスターを破壊した時に発動できる。\nその破壊したモンスターを装備カード扱いとしてこのカードに装備する。\n③：１ターンに１度、このカードの効果で装備しているモンスターカード１枚を対象として発動できる。\nそのカードを破壊し、その元々の攻撃力分だけ自分のLPを回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フレムベル・アーチャー",h:"ふれむべる・あーちゃー",e:"Flamvell Archer",ra:"N",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"炎族",mo:["チューナー","効果"],ma:"",tp:"",at:1000,de:200,tx:"１ターンに１度、自分フィールド上に表側表示で存在する炎族モンスター１体をリリースして発動できる。\nフィールド上の全ての「フレムベル」と名のついたモンスターの攻撃力は、エンドフェイズ時まで８００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フレムベル・ウルキサス",h:"ふれむべる・うるきさす",e:"Flamvell Uruquizas",ra:"UR",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"炎族",mo:["シンクロ","効果"],ma:"",tp:"",at:2100,de:400,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。\nまた、このカードが相手ライフに戦闘ダメージを与えた時、このカードの攻撃力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フレムベル・グルニカ",h:"ふれむべる・ぐるにか",e:"Flamvell Grunika",ra:"SR",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1700,de:200,tx:"このカードが戦闘によってモンスターを破壊し墓地へ送った時、破壊したモンスターのレベル×２００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フレムベル・デスガンナー",h:"ふれむべる・ですがんなー",e:"Flamvell Commando",ra:"N",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:2200,de:200,tx:"このカードは特殊召喚できない。\n自分フィールド上に存在する「フレムベル」と名のついたモンスター１体をリリースした場合のみ召喚する事ができる。\n１ターンに１度、自分の墓地に存在する守備力２００のモンスター１体をゲームから除外する事で、除外したモンスターの攻撃力分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フレムベル・デビル",h:"ふれむべる・でびる",e:"Flamvell Fiend",ra:"N",t:"monster",a:"炎",l:5,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2100,de:1200,tx:"このカードが相手ライフに戦闘ダメージを与えた時、自分の墓地の炎族モンスターの数×２００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フレムベル・ドラグノフ",h:"ふれむべる・どらぐのふ",e:"Flamvell Dragnov",ra:"N",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1100,de:200,tx:"このカードが戦闘によって破壊され墓地へ送られた時、相手ライフに５００ポイントダメージを与える。\nまた、自分の墓地のこのカードと自分フィールド上に表側表示で存在する炎属性モンスター１体をゲームから除外して発動できる。\nデッキから「フレムベル・ドラグノフ」１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フレムベル・パウン",h:"ふれむべる・ぱうん",e:"Flamvell Poun",ra:"R",t:"monster",a:"炎",l:1,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:200,de:200,tx:"このカードが戦闘によって破壊され墓地へ送られた時、デッキから守備力２００のモンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フレムベル・ベビー",h:"ふれむべる・べびー",e:"Flamvell Baby",ra:"N",t:"monster",a:"炎",l:1,ps:"",pe:"",tr:"炎族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:200,tx:"自分のメインフェイズ時にこのカードを手札から墓地へ送って発動できる。\n自分フィールド上の炎属性モンスター１体を選択して攻撃力を４００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フレムベル・ヘルドッグ",h:"ふれむべる・へるどっぐ",e:"Flamvell Firedog",ra:"SR",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1900,de:200,tx:"①：このカードが戦闘で相手モンスターを破壊し墓地へ送った時に発動できる。\nデッキから「フレムベル・ヘルドッグ」以外の守備力２００以下の炎属性モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フレムベル・マジカル",h:"ふれむべる・まじかる",e:"Flamvell Magician",ra:"N",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["チューナー","効果"],ma:"",tp:"",at:1400,de:200,tx:"自分フィールド上に「A・O・J」と名のついたモンスターが存在する限り、このカードの攻撃力は４００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フレンドシップ",h:"ふれんどしっぷ",e:"Shining Friendship",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:1300,de:1100,tx:"デュエル中ケンカをしても、友情を伝え仲直りをさせる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フレンドッグ",h:"ふれんどっぐ",e:"Wroughtweiler",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:800,de:1200,tx:"このカードが戦闘によって破壊され墓地へ送られた時、自分の墓地から「E・HERO」と名のついたカード１枚と「融合」魔法カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブローバック・ドラゴン",h:"ぶろーばっく・どらごん",e:"Blowback Dragon",ra:"SR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2300,de:1200,tx:"①：１ターンに１度、相手フィールドのカード１枚を対象として発動できる。\nコイントスを３回行い、その内２回以上が表だった場合、その相手のカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"氷炎の双竜",h:"ふろすとあんどふれいむ・ついんどらごん",e:"Frost and Flame Dragon",ra:"UR",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2300,de:2000,tx:"このカードは通常召喚できない。\n自分の墓地の水属性モンスター２体と炎属性モンスター１体をゲームから除外した場合のみ特殊召喚できる。\n１ターンに１度、手札を１枚捨てて発動できる。\nフィールド上のモンスター１体を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フロストザウルス",h:"ふろすとざうるす",e:"Frostosaurus",ra:"R",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"恐竜族",mo:["通常"],ma:"",tp:"",at:2600,de:1700,tx:"鈍い神経と感性のお陰で、氷づけになりつつも氷河期を乗り越える脅威の生命力を持つ。\n寒さには滅法強いぞ。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブロッカー",h:"ぶろっかー",e:"Blocker",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:850,de:1800,tx:"体のパーツがそれぞれ武器になっており、分裂して襲ってくる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブロック・ゴーレム",h:"ぶろっく・ごーれむ",e:"Block Golem",ra:"SR",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1000,de:1500,tx:"①：自分の墓地のモンスターが地属性のみの場合、このカードをリリースし、「ブロック・ゴーレム」以外の自分の墓地のレベル４以下の岩石族モンスター２体を対象として発動できる。\nその岩石族モンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターはこのターン、フィールドで発動する効果を発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブロック・スパイダー",h:"ぶろっく・すぱいだー",e:"Block Spider",ra:"N",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:0,de:100,tx:"「ブロック・スパイダー」の②の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、相手は他の昆虫族モンスターを攻撃対象にできない。\n②：このカードが特殊召喚に成功した場合に発動できる。\nデッキから「ブロック・スパイダー」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブロックドラゴン",h:"ぶろっくどらごん",e:"Block Dragon",ra:"SR",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"岩石族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2500,de:3000,tx:"このカードは通常召喚できない。\n自分の手札・墓地の地属性モンスター３体を除外した場合のみ手札・墓地から特殊召喚できる。\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、自分フィールドの岩石族モンスターは戦闘以外では破壊されない。\n②：このカードがフィールドから墓地へ送られた場合に発動できる。\nレベルの合計が８になるように、デッキから岩石族モンスターを３体まで選んで手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブロックマン",h:"ぶろっくまん",e:"Blockman",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1000,de:1500,tx:"①：このカードをリリースして発動できる。\nこのカードが自分フィールドに表側表示で存在した自分ターンの数と同じ数だけ、自分フィールドに「ブロックトークン」（岩石族・地・星４・攻１０００／守１５００）を守備表示で特殊召喚する。\nこのトークンは攻撃宣言できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"プロト・サイバー・ドラゴン",h:"ぷろと・さいばー・どらごん",e:"Proto-Cyber Dragon",ra:"R",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1100,de:600,tx:"このカードのカード名は、フィールド上に表側表示で存在する限り「サイバー・ドラゴン」として扱う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"プロトロン",h:"ぷろとろん",e:"Protron",ra:"N",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"サイバース族",mo:["通常"],ma:"",tp:"",at:100,de:100,tx:"電子空間で見つかる原種。\nその情報量は未知数。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"プロミネンス・ドラゴン",h:"ぷろみねんす・どらごん",e:"Solar Flare Dragon",ra:"SR",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"自分フィールド上にこのカード以外の炎族モンスターが存在する場合、このカードを攻撃する事はできない。\n自分のターンのエンドフェイズ時、このカードは相手ライフに５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フロント・オブザーバー",h:"ふろんと・おぶざーばー",e:"Frontline Observer",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:1500,tx:"①：このカードが召喚に成功したターンのエンドフェイズに発動できる。\nデッキから地属性Pモンスター１体を手札に加える。\n②：このカードが召喚に成功した場合、次の自分ターンのエンドフェイズに、このカードをリリースして発動できる。\nデッキから地属性モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"プロンプトホーン",h:"ぷろんぷとほーん",e:"Prompthorn",ra:"N",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:200,de:400,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：自分フィールドのレベル４以下のサイバース族モンスター１体をリリースして発動できる。\nレベルの合計がリリースしたモンスターのレベルと同じになるように、自分のデッキ・墓地からサイバース族の通常モンスターを任意の数だけ選んで特殊召喚する。\nこの効果で特殊召喚したモンスターはエンドフェイズに除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブンボーグ・ジェット",h:"ぶんぼーぐ・じぇっと",e:"Deskbot Jet",ra:"N",t:"monster",a:"地",l:10,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:500,de:500,tx:"チューナー＋チューナー以外のモンスター１体以上\n「ブンボーグ・ジェット」の②③の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードの攻撃力・守備力は、フィールドの「ブンボーグ」カードの数×５００アップする。\n②：自分フィールドの「ブンボーグ」カード１枚を対象として発動できる。\nそのカードを破壊し、デッキから「ブンボーグ」モンスター１体を特殊召喚する。\n③：自分フィールドの「ブンボーグ」カード１枚とフィールドの表側表示のカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブンボーグ003",h:"ぶんぼーぐぜろぜろすりー",e:"Deskbot 003",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:500,de:500,tx:"①：このカードが召喚に成功した時に発動できる。\nデッキから「ブンボーグ００３」以外の「ブンボーグ」モンスター１体を特殊召喚する。\n②：１ターンに１度、自分フィールドの「ブンボーグ」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力・守備力はターン終了時まで、自分フィールドの「ブンボーグ」カードの数×５００アップする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブンボーグ002",h:"ぶんぼーぐぜろぜろつー",e:"Deskbot 002",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:500,de:500,tx:"①：このカードが特殊召喚に成功した場合に発動できる。\nデッキから「ブンボーグ」カード１枚を手札に加える。\n②：このカードがモンスターゾーンに存在する限り、このカード以外の自分フィールドの機械族モンスターの攻撃力・守備力は５００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブンボーグ009",h:"ぶんぼーぐぜろぜろないん",e:"Deskbot 009",ra:"N",t:"monster",a:"地",l:9,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:500,de:500,tx:"①：１ターンに１度、自分メインフェイズ１に発動できる。\nこのカードの攻撃力は相手ターン終了時まで、「ブンボーグ００９」以外の自分フィールドの「ブンボーグ」モンスターの攻撃力の合計分アップする。\nこの効果を発動するターン、このカードしか攻撃できない。\n②：このカードが戦闘を行う場合、相手はダメージステップ終了時まで魔法・罠・モンスターの効果を発動できない。\n③：このカードが戦闘・効果で破壊される場合、代わりに自分フィールドの「ブンボーグ」カード１枚を破壊できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブンボーグ004",h:"ぶんぼーぐぜろぜろふぉー",e:"Deskbot 004",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:500,de:500,tx:"①：このカードが相手モンスターと戦闘を行うダメージ計算時に発動できる。\nデッキから「ブンボーグ００４」以外の「ブンボーグ」モンスター１体を墓地へ送り、このカードの攻撃力・守備力はそのダメージ計算時のみ、墓地へ送ったそのモンスターのレベル×５００アップする。\nこの効果の発動後、ターン終了時まで相手が受ける戦闘ダメージは０になる。\n②：このカードが戦闘で相手モンスターを破壊した場合に発動できる。\n自分の手札・墓地からレベルの異なる「ブンボーグ」モンスター２体を選んで守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブンボーグ001",h:"ぶんぼーぐぜろぜろわん",e:"Deskbot 001",ra:"N",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:500,de:500,tx:"①：このカードの攻撃力・守備力は、自分フィールドの機械族モンスターの数×５００アップする。\n②：このカードが墓地に存在し、フィールドに機械族モンスターが２体以上同時に特殊召喚された場合に発動できる。\nこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ペアサイクロイド",h:"ぺあさいくろいど",e:"Pair Cycroid",ra:"SR",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:1600,de:1200,tx:"同名の機械族モンスター×２\n①：このカードは直接攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ベイオウルフ",h:"べいおうるふ",e:"Wolf Axwielder",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["通常"],ma:"",tp:"",at:1650,de:1000,tx:"一度闘いを始めると、決着がつくまで闘うことをやめないバーサーカー。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"平穏の賢者",h:"へいおんのけんじゃ",e:"Sage of Stillness",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1400,de:1000,tx:"このカードが戦闘によって相手モンスターを破壊した場合、相手プレイヤーは次のターン罠カードを発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"陽炎獣 グリプス",h:"へいずびーすと　ぐりぷす",e:"Hazy Flame Griffin",ra:"R",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:200,de:2100,tx:"相手フィールド上にモンスターが存在し、自分のフィールド上・墓地に炎属性以外のモンスターが存在しない場合、このカードは手札から特殊召喚できる。\nこのカードがフィールド上に表側表示で存在する限り、相手はこのカードをカードの効果の対象にできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"陽炎獣 サーベラス",h:"へいずびーすと　さーべらす",e:"Hazy Flame Cerbereus",ra:"N",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:2000,de:200,tx:"このカードはリリースなしで召喚できる。\nこの方法で召喚したこのカードの元々の攻撃力は１０００になる。\nこのカードがフィールド上に表側表示で存在する限り、相手はこのカードをカードの効果の対象にできない。\nまた、このカードが破壊され墓地へ送られた時、デッキから「ヘイズ」と名のついたカード１枚を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"陽炎獣 スピンクス",h:"へいずびーすと　すぴんくす",e:"Hazy Flame Sphynx",ra:"R",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1900,de:1900,tx:"このカードがフィールド上に表側表示で存在する限り、相手はこのカードをカードの効果の対象にできない。\nまた、自分のメインフェイズ時、カードの種類（モンスター・魔法・罠）を宣言して発動できる。\n自分のデッキの一番上のカードを墓地へ送り、宣言した種類のカードだった場合、さらに自分の手札・墓地から炎属性モンスター１体を選んで特殊召喚できる。\n「陽炎獣 スピンクス」のこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"陽炎獣 バジリコック",h:"へいずびーすと　ばじりこっく",e:"Hazy Flame Basiltrice",ra:"SR",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"炎族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2500,de:1800,tx:"炎属性レベル６モンスター×２体以上（最大５体まで）\n１ターンに１度、このカードのエクシーズ素材を１つ取り除いて発動できる。\n相手のフィールド上・墓地のモンスター１体を選択してゲームから除外する。\nまた、このカードが持っているエクシーズ素材の数によって、このカードは以下の効果を得る。\n\n●３つ以上：このカードの攻撃力・守備力は、このカードのエクシーズ素材の数×２００ポイントアップする。\n\n●４つ以上：このカードは相手のカードの効果の対象にならない。\n\n●５つ：このカードはカードの効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"陽炎獣 ヒッポグリフォ",h:"へいずびーすと　ひっぽぐりふぉ",e:"Hazy Flame Hyppogrif",ra:"R",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:2100,de:200,tx:"このカードがフィールド上に表側表示で存在する限り、相手はこのカードをカードの効果の対象にできない。\nまた、１ターンに１度、このカード以外の自分フィールド上の炎属性モンスター１体をリリースして発動できる。\nこのカードの攻撃力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"陽炎獣 ヒュドラー",h:"へいずびーすと　ひゅどらー",e:"Hazy Flame Hydra",ra:"N",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:2300,de:200,tx:"このカードがフィールド上に表側表示で存在する限り、相手はこのカードをカードの効果の対象にできず、自分は炎属性以外のモンスターを特殊召喚できない。\nまた、このカードを素材としたエクシーズモンスターは以下の効果を得る。\n●このエクシーズ召喚に成功した時、自分の墓地から「陽炎獣」と名のついたモンスター１体を選択し、このカードの下に重ねてエクシーズ素材とする事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"陽炎獣 ペリュトン",h:"へいずびーすと　ぺりゅとん",e:"Hazy Flame Peryton",ra:"R",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:1600,de:1700,tx:"このカードは「陽炎獣」と名のついたモンスターの効果以外では特殊召喚できない。\nこのカードがフィールド上に表側表示で存在する限り、相手はこのカードをカードの効果の対象にできない。\nまた、手札の炎属性モンスター１体を墓地へ送り、このカードをリリースして発動できる。\nデッキから「陽炎獣」と名のついたモンスター２体を特殊召喚する。\n「陽炎獣 ペリュトン」のこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"陽炎獣 メコレオス",h:"へいずびーすと　めこれおす",e:"Hazy Flame Mantikor",ra:"N",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2200,de:300,tx:"このカードがフィールド上に表側表示で存在する限り、相手はこのカードをカードの効果の対象にできない。\nまた、１ターンに１度、手札または自分フィールド上に表側表示で存在する、炎属性モンスター１体を墓地へ送って発動できる。\nこのターン、このカードはカードの効果では破壊されない。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ペイルビースト",h:"ぺいるびーすと",e:"Pale Beast",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:1500,de:1200,tx:"青白い肌をした、気味の悪い正体不明のモンスター。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ペインペインター",h:"ぺいんぺいんたー",e:"Pain Painter",ra:"R",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"アンデット族",mo:["チューナー","効果"],ma:"",tp:"",at:400,de:200,tx:"このカードのカード名は、フィールド上に表側表示で存在する限り「ゾンビキャリア」として扱う。\nまた、１ターンに１度、このカード以外の自分フィールド上のアンデット族モンスターを２体まで選択して発動できる。\n選択したモンスターのレベルはエンドフェイズ時まで２になる。\nこの効果が適用されたモンスターをシンクロ素材とする場合、アンデット族モンスターのシンクロ召喚にしか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"昆虫人間",h:"べーしっく・いんせくと",e:"Basic Insect",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"昆虫族",mo:["通常"],ma:"",tp:"",at:500,de:700,tx:"群をなして暮らす昆虫。\n森の中は彼らの楽園だ。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヘカテリス",h:"へかてりす",e:"Hecatrice",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1500,de:1100,tx:"①：自分メインフェイズにこのカードを手札から墓地へ捨てて発動できる。\nデッキから「神の居城－ヴァルハラ」１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ベビー・ティーレックス",h:"べびー・てぃーれっくす",e:"Little D",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"恐竜族",mo:["通常"],ma:"",tp:"",at:1100,de:700,tx:"ティラノサウルスの子供。\n非常に凶暴な性格をしている。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ベビー・トラゴン",h:"べびー・とらごん",e:"Baby Tiragon",ra:"SR",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:900,de:900,tx:"レベル１モンスター×３\n自分のメインフェイズ１でこのカードのエクシーズ素材を１つ取り除き、自分フィールド上に表側表示で存在するレベル１のモンスター１体を選択して発動する事ができる。\n選択したモンスターは相手プレイヤーに直接攻撃する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ベビードラゴン",h:"べびーどらごん",e:"Baby Dragon",ra:"N",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:1200,de:700,tx:"こどもドラゴンとあなどってはいけない。\nうちに秘める力は計り知れない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ベビケラサウルス",h:"べびけらさうるす",e:"Babycerasaurus",ra:"R",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:500,de:500,tx:"①：このカードが効果で破壊され墓地へ送られた場合に発動する。\nデッキからレベル４以下の恐竜族モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ベヒゴン",h:"べひごん",e:"Behegon",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1350,de:1000,tx:"かなり変わった海ヘビ。\n大きな口と大きなキバが特徴。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヘラクレス・ビートル",h:"へらくれす・びーとる",e:"Hercules Beetle",ra:"N",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"昆虫族",mo:["通常"],ma:"",tp:"",at:1500,de:2000,tx:"巨大カブトムシ。\nつの攻撃とかたい体の守りは強力。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ベリー・マジシャン・ガール",h:"べりー・まじしゃん・がーる",e:"Berry Magician Girl",ra:"SR",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:400,de:400,tx:"①：このカードが召喚に成功した場合に発動できる。\nデッキから「マジシャン・ガール」モンスター１体を手札に加える。\n②：１ターンに１度、このカードが相手の効果の対象になった時、または相手モンスターの攻撃対象に選択された時に発動できる。\nこのカードの表示形式を変更し、デッキから「ベリー・マジシャン・ガール」以外の「マジシャン・ガール」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヘリオス・デュオ・メギストス",h:"へりおす・でゅお・めぎすとす",e:"Helios Duo Megistus",ra:"N",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:"?",de:"?",tx:"このカードは自分フィールド上の「原始太陽ヘリオス」１体を生け贄に捧げる事で特殊召喚する事ができる。\nこのカードの攻撃力と守備力は、ゲームから除外されているモンスターカードの数×２００ポイントになる。\nこのカードが戦闘によって破壊され墓地へ送られた場合、エンドフェイズ時に攻撃力・守備力を３００ポイントアップさせて特殊召喚される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヘリオス・トリス・メギストス",h:"へりおす・とりす・めぎすとす",e:"Helios Trice Megistus",ra:"N",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:"?",de:"?",tx:"このカードは自分フィールド上の「ヘリオス・デュオ・メギストス」１体を生け贄に捧げる事で特殊召喚する事ができる。\nこのカードの攻撃力と守備力は、ゲームから除外されているモンスターカードの数×３００ポイントになる。\nこのカードが戦闘によって破壊され墓地へ送られた場合、エンドフェイズ時に攻撃力・守備力を５００ポイントアップさせて特殊召喚される。\n相手フィールド上にモンスターが存在する場合、もう１度だけ続けて攻撃を行う事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヘル・エンプレス・デーモン",h:"へる・えんぷれす・でーもん",e:"Archfiend Empress",ra:"SR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2900,de:2100,tx:"このカード以外のフィールド上に表側表示で存在する悪魔族・闇属性モンスター１体が破壊される場合、代わりに自分の墓地に存在する悪魔族・闇属性モンスター１体をゲームから除外する事ができる。\nまた、フィールド上に存在するこのカードが破壊され墓地へ送られた時、「ヘル・エンプレス・デーモン」以外の自分の墓地に存在する悪魔族・闇属性・レベル６以上のモンスター１体を選択して特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヘル・セキュリティ",h:"へる・せきゅりてぃ",e:"Stygian Security",ra:"UR",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:100,de:600,tx:"①：このカードが戦闘で破壊され墓地へ送られた場合に発動する。\nデッキから悪魔族・レベル１モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"地獄大百足",h:"へる・せんちぴーど",e:"Hundred-Footed Horror",ra:"R",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:2600,de:1300,tx:"相手フィールド上にモンスターが存在し、自分フィールド上にモンスターが存在しない場合、このカードはリリースなしで召喚できる。\nこの方法で召喚したこのカードの元々の攻撃力は１３００になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヘル・ドラゴン",h:"へる・どらごん",e:"Infernal Dragon",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2000,de:0,tx:"①：このカードが攻撃したターンのエンドフェイズに発動する。\nこのカードを破壊する。\n②：フィールドのこのカードが破壊され墓地へ送られた時、自分フィールドのモンスター１体をリリースして発動できる。\nこのカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヘルゲート・ディーグ",h:"へるげーと・でぃーぐ",e:"Gate Deeg",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:700,de:800,tx:"おなかに地獄へ通じる扉があり、召還もできる不気味なモンスター。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"地獄将軍・メフィスト",h:"へるじぇねらる・めふぃすと",e:"Mefist the Infernal General",ra:"R",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1800,de:1700,tx:"このカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が越えていれば、その数値だけ相手に戦闘ダメージを与える。\n相手に戦闘ダメージを与えた時、相手の手札からカードを１枚ランダムに捨てる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"地獄戦士",h:"へるそるじゃー",e:"Chthonian Soldier",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:1400,tx:"このカードが相手モンスターの攻撃によって破壊され墓地へ送られた時、この戦闘によって自分が受けた戦闘ダメージを相手ライフにも与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヘルバウンド",h:"へるばうんど",e:"Shadow Specter",ra:"N",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"アンデット族",mo:["通常"],ma:"",tp:"",at:500,de:200,tx:"荒野に現れるけものの亡霊。\n数が集まるとやっかいなカード。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヘルフレイムエンペラー",h:"へるふれいむえんぺらー",e:"Infernal Flame Emperor",ra:"R",t:"monster",a:"炎",l:9,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:2700,de:1600,tx:"このカードは特殊召喚できない。\nこのカードがアドバンス召喚に成功した時、自分の墓地に存在する炎属性モンスターを５体までゲームから除外する事ができる。\nこの効果で除外したモンスターの数だけ、フィールド上に存在する魔法・罠カードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヘルフレイムゴースト",h:"へるふれいむごーすと",e:"Infernal Flame Vixen",ra:"R",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2200,de:2000,tx:"炎族レベル４モンスター×２\n１ターンに１度、このカードのエクシーズ素材を１つ取り除いて発動できる。\nこのカードの攻撃力は相手のエンドフェイズ時まで５００ポイントアップする。\nまた、攻撃力２５００以上のこのカードが破壊された時、お互いの墓地のモンスターを合計３体選択してゲームから除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヘルプロミネンス",h:"へるぷろみねんす",e:"Firestorm Prominence",ra:"N",t:"monster",a:"炎",l:7,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:2000,de:1500,tx:"このカードが戦闘によって破壊され墓地へ送られた時、フィールド上に表側表示で存在する炎属性モンスター以外のモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヘルポーンデーモン",h:"へるぽーんでーもん",e:"Vilepawn Archfiend",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1200,de:200,tx:"このカードのコントローラーは自分のスタンバイフェイズ毎に５００ライフポイントを払う。\nこのカードが相手のコントロールするカードの効果の対象になり、その処理を行う時にサイコロを１回振る。\n３が出た場合、その効果を無効にし破壊する。\nこのカードがフィールド上に存在する限り、相手は自分フィールド上に存在する同名カード以外の「デーモン」という名のついたモンスターカードを攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"辺境の大賢者",h:"へんきょうのだいけんじゃ",e:"Frontier Wiseman",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1600,de:800,tx:"このカードがフィールド上に表側表示で存在する限り、自分フィールド上に表側表示で存在する戦士族モンスターを対象にする魔法カードの効果を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ペンギン・ナイト",h:"ぺんぎん・ないと",e:"Penguin Knight",ra:"N",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:900,de:800,tx:"このカードが相手のカードの効果によってデッキから墓地へ送られた時、自分の墓地に存在するカードを全てデッキに戻してシャッフルする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ペンギン・ナイトメア",h:"ぺんぎん・ないとめあ",e:"Nightmare Penguin",ra:"SR",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:900,de:1800,tx:"このカードがリバースした時、相手フィールド上のカード１枚を選択して持ち主の手札に戻す。\nまた、このカードがフィールド上に表側表示で存在する限り、自分フィールド上の水属性モンスターの攻撃力は２００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ボアソルジャー",h:"ぼあそるじゃー",e:"Boar Soldier",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:2000,de:500,tx:"召喚された場合、このカードを破壊する。\n相手が１体でもモンスターをコントロールしていた場合、攻撃力は１０００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ボイコットン",h:"ぼいこっとん",e:"Boycotton",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1000,de:2500,tx:"①：このカードの戦闘で発生する相手への戦闘ダメージは代わりに自分が受ける。\n②：このカードの戦闘でこのカードが破壊されず、自分が戦闘ダメージを受けた場合に発動する。\nこのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ポイズンマミー",h:"ぽいずんまみー",e:"Poison Mummy",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"アンデット族",mo:["リバース","効果"],ma:"",tp:"",at:1000,de:1800,tx:"リバース：相手プレイヤーに５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"鳳凰",h:"ほうおう",e:"Fenghuang",ra:"SR",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"鳥獣族",mo:["スピリット","効果"],ma:"",tp:"",at:2100,de:1800,tx:"このカードは特殊召喚できない。\n召喚・リバースしたターンのエンドフェイズ時に持ち主の手札に戻る。\nこのカードが召喚・リバースした時、相手フィールド上にセットされた魔法・罠カードを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"鳳王獣ガイルーダ",h:"ほうおうじゅうがいるーだ",e:"Phoenix Beast Gairuda",ra:"R",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2500,de:1200,tx:"このカードは相手モンスターに攻撃する場合、ダメージステップの間攻撃力が３００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"方界獣ダーク・ガネックス",h:"ほうかいじゅうだーく・がねっくす",e:"Dark Garnex the Cubic Beast",ra:"SR",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["特殊召喚","効果"],ma:"",tp:"",at:0,de:0,tx:"このカードは通常召喚できない。\n自分フィールドの「方界」モンスター１体を墓地へ送った場合に特殊召喚できる。\n①：この方法で特殊召喚したこのカードの攻撃力は１０００アップする。\n②：このカードが戦闘でモンスターを破壊した時、自分の墓地の「方界胤ヴィジャム」を２体まで対象として発動できる。\nこのカードを墓地へ送り、対象のモンスターを特殊召喚する。\nその後、デッキから「方界獣ブレード・ガルーディア」１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"方界獣ブレード・ガルーディア",h:"ほうかいじゅうぶれーど・がるーでぃあ",e:"Blade Garoodia the Cubic Beast",ra:"R",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"獣族",mo:["特殊召喚","効果"],ma:"",tp:"",at:0,de:0,tx:"このカードは通常召喚できない。\n自分フィールドの「方界」モンスター２体を墓地へ送った場合に特殊召喚できる。\n①：この方法で特殊召喚したこのカードの攻撃力は２０００アップする。\n②：このカードは１度のバトルフェイズ中に２回攻撃できる。\n③：このカードが戦闘でモンスターを破壊した時、自分の墓地の「方界胤ヴィジャム」を３体まで対象として発動できる。\nこのカードを墓地へ送り、対象のモンスターを特殊召喚する。\nその後、デッキから「方界超獣バスター・ガンダイル」１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"方界超獣バスター・ガンダイル",h:"ほうかいちょうじゅうばすたー・がんだいる",e:"Buster Gundil the Cubic Behemoth",ra:"UR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣族",mo:["特殊召喚","効果"],ma:"",tp:"",at:0,de:0,tx:"このカードは通常召喚できない。\n自分フィールドの「方界」モンスター３体を墓地へ送った場合のみ特殊召喚できる。\n①：この方法で特殊召喚したこのカードの攻撃力は３０００アップする。\n②：このカードは１度のバトルフェイズ中に３回攻撃できる。\n③：このカードが相手によって墓地へ送られた場合、自分の墓地の「方界」モンスターを３体まで対象として発動できる。\nそのモンスターを特殊召喚する。\nその後、自分のデッキ・墓地から「方界」カード１枚を選んで手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"方界超帝インディオラ・デス・ボルト",h:"ほうかいちょうていいんでぃおら・です・ぼると",e:"Indiora Doom Volt the Cubic Emperor",ra:"UR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["特殊召喚","効果"],ma:"",tp:"",at:0,de:0,tx:"このカードは通常召喚できない。\n自分フィールドの「方界」モンスター３体を墓地へ送った場合のみ特殊召喚できる。\n①：この方法で特殊召喚したこのカードの攻撃力は２４００アップする。\n②：このカードが手札からの特殊召喚に成功した場合に発動する。\n相手に８００ダメージを与える。\n③：このカードが相手によって墓地へ送られた場合、自分の墓地の「方界」モンスターを３体まで対象として発動できる。\nそのモンスターを特殊召喚する。\nその後、自分のデッキ・墓地から「方界」カード１枚を選んで手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"方界帝ヴァルカン・ドラグニー",h:"ほうかいていう゛ぁるかん・どらぐにー",e:"Vulcan Dragni the Cubic King",ra:"SR",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"天使族",mo:["特殊召喚","効果"],ma:"",tp:"",at:0,de:0,tx:"このカードは通常召喚できない。\n自分フィールドの「方界」モンスター２体を墓地へ送った場合に特殊召喚できる。\n①：この方法で特殊召喚したこのカードの攻撃力は１６００アップする。\n②：このカードが手札からの特殊召喚に成功した場合に発動する。\n相手に８００ダメージを与える。\n③：このカードが戦闘したダメージステップ終了時、自分の墓地の「方界胤ヴィジャム」を３体まで対象として発動できる。\nこのカードを墓地へ送り、対象のモンスターを特殊召喚する。\nその後、デッキから「方界超帝インディオラ・デス・ボルト」１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"方界帝ゲイラ・ガイル",h:"ほうかいていげいら・がいる",e:"Geira Guile the Cubic King",ra:"R",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"天使族",mo:["特殊召喚","効果"],ma:"",tp:"",at:0,de:0,tx:"このカードは通常召喚できない。\n自分フィールドの「方界」モンスター１体を墓地へ送った場合に特殊召喚できる。\n①：この方法で特殊召喚したこのカードの攻撃力は８００アップする。\n②：このカードが手札からの特殊召喚に成功した場合に発動する。\n相手に８００ダメージを与える。\n③：このカードが戦闘したダメージステップ終了時、自分の墓地の「方界胤ヴィジャム」を２体まで対象として発動できる。\nこのカードを墓地へ送り、対象のモンスターを特殊召喚する。\nその後、デッキから「方界帝ヴァルカン・ドラグニー」１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"法眼の魔術師",h:"ほうがんのまじゅつし",e:"Dharma-Eye Magician",ra:"R",t:"monster",a:"光",l:7,ps:2,pe:"①：１ターンに１度、手札のPモンスター１体を相手に見せ、自分のPゾーンの「魔術師」カード１枚を対象として発動できる。\nその「魔術師」カードのPスケールはターン終了時まで、見せたPモンスターのPスケールと同じになる。",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2000,de:2500,tx:"①：このターンにP召喚したこのカードがモンスターゾーンに存在する限り、自分フィールドの「魔術師」Pモンスターは相手の効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"宝玉獣 アメジスト・キャット",h:"ほうぎょくじゅう　あめじすと・きゃっと",e:"Crystal Beast Amethyst Cat",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1200,de:400,tx:"このカードは相手プレイヤーに直接攻撃できる。\nこの時、このカードが相手ライフに与える戦闘ダメージは半分になる。\nこのカードがモンスターカードゾーン上で破壊された場合、墓地へ送らずに永続魔法カード扱いとして自分の魔法＆罠カードゾーンに表側表示で置く事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"宝玉獣 アンバー・マンモス",h:"ほうぎょくじゅう　あんばー・まんもす",e:"Crystal Beast Amber Mammoth",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1700,de:1600,tx:"自分フィールド上の「宝玉獣」と名のついたモンスターが攻撃対象に選択された時、このカードに攻撃対象を変更できる。\nこのカードがモンスターカードゾーン上で破壊された場合、墓地へ送らずに永続魔法カード扱いとして自分の魔法＆罠カードゾーンに表側表示で置く事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"宝玉獣 エメラルド・タートル",h:"ほうぎょくじゅう　えめらるど・たーとる",e:"Crystal Beast Emerald Tortoise",ra:"SR",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:600,de:2000,tx:"１ターンに１度、このターン攻撃を行った自分フィールド上のモンスター１体を選択して発動できる。\n選択した自分のモンスターを守備表示にする。\nこのカードがモンスターカードゾーン上で破壊された場合、墓地へ送らずに永続魔法カード扱いとして自分の魔法＆罠カードゾーンに表側表示で置く事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"宝玉獣 コバルト・イーグル",h:"ほうぎょくじゅう　こばると・いーぐる",e:"Crystal Beast Cobalt Eagle",ra:"R",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1400,de:800,tx:"１ターンに１度、自分のメインフェイズ時に発動できる。\n自分フィールド上の「宝玉獣」と名のついたカード１枚を選択して持ち主のデッキの一番上に戻す。\nこのカードがモンスターカードゾーン上で破壊された場合、墓地へ送らずに永続魔法カード扱いとして自分の魔法＆罠カードゾーンに表側表示で置く事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"宝玉獣 サファイア・ペガサス",h:"ほうぎょくじゅう　さふぁいあ・ぺがさす",e:"Crystal Beast Sapphire Pegasus",ra:"UR",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1800,de:1200,tx:"①：このカードが召喚・反転召喚・特殊召喚に成功した時に発動できる。\n自分の手札・デッキ・墓地から「宝玉獣」モンスター１体を選び、永続魔法カード扱いとして自分の魔法＆罠ゾーンに表側表示で置く。\n②：表側表示のこのカードがモンスターゾーンで破壊された場合、墓地へ送らずに永続魔法カード扱いとして自分の魔法＆罠ゾーンに表側表示で置く事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"宝玉獣 トパーズ・タイガー",h:"ほうぎょくじゅう　とぱーず・たいがー",e:"Crystal Beast Topaz Tiger",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"このカードは相手モンスターに攻撃する場合、ダメージステップの間、攻撃力が４００ポイントアップする。\nこのカードがモンスターカードゾーン上で破壊された場合、墓地へ送らずに永続魔法カード扱いとして自分の魔法＆罠カードゾーンに表側表示で置く事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"宝玉獣 ルビー・カーバンクル",h:"ほうぎょくじゅう　るびー・かーばんくる",e:"Crystal Beast Ruby Carbuncle",ra:"R",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:300,de:300,tx:"①：このカードが特殊召喚に成功した時に発動できる。\n自分の魔法＆罠ゾーンの「宝玉獣」モンスターカードを可能な限り特殊召喚する。\n②：表側表示のこのカードがモンスターゾーンで破壊された場合、墓地へ送らずに永続魔法カード扱いとして自分の魔法＆罠ゾーンに表側表示で置く事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"宝玉の守護者",h:"ほうぎょくのしゅごしゃ",e:"Crystal Keeper",ra:"UR",t:"monster",a:"炎",l:4,ps:2,pe:"①：このカードがPゾーンに存在する限り、１ターンに１度、自分フィールドの「究極宝玉神」モンスター及び「宝玉獣」カードは効果では破壊されない。",tr:"戦士族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1500,de:1800,tx:"①：自分の「宝玉獣」モンスターが相手モンスターと戦闘を行うダメージ計算時、自分の手札・フィールドのこのカードをリリースして発動できる。\nその戦闘を行う自分のモンスターは、攻撃力・守備力がダメージ計算時のみ元々の数値の倍になり、ダメージステップ終了時に破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"宝玉の先導者",h:"ほうぎょくのせんどうしゃ",e:"Crystal Master",ra:"SR",t:"monster",a:"闇",l:3,ps:5,pe:"①：このカードがPゾーンに存在する限り、自分フィールドの「究極宝玉神」モンスター及び「宝玉獣」カードは、相手の効果の対象にならない。",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1300,de:1000,tx:"①：このカードをリリースして発動できる。\nデッキから、「究極宝玉神」モンスター、「宝玉獣」モンスター、「宝玉」魔法・罠カードの内、いずれか１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"豊穣のアルテミス",h:"ほうじょうのあるてみす",e:"Bountiful Artemis",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1600,de:1700,tx:"①：このカードがモンスターゾーンに存在する限り、自分または相手がカウンター罠カードを発動する度に、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"砲弾ヤリ貝",h:"ほうだんやりがい",e:"Cannonball Spear Shellfish",ra:"R",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"「海」がフィールド上に存在する限り、このカードは魔法の効果を受けない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"茫漠の死者",h:"ぼうばくのししゃ",e:"Endless Decay",ra:"R",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:"?",de:0,tx:"①：自分のLPが２０００以下の場合、このカードは手札から特殊召喚できる。\n②：このカードが召喚・特殊召喚に成功した場合に発動する。\nこのカードの攻撃力は相手のLPの半分の数値になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"暴風小僧",h:"ぼうふうこぞう",e:"Whirlwind Prodigy",ra:"R",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1500,de:1600,tx:"風属性モンスターを生け贄召喚する場合、このモンスター１体で２体分の生け贄とする事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"暴風竜の防人",h:"ぼうふうりゅうのさきもり",e:"Rider of the Storm Winds",ra:"R",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"ドラゴン族",mo:["チューナー","効果"],ma:"",tp:"",at:500,de:200,tx:"自分のメインフェイズ時、手札または自分フィールド上のこのモンスターを、装備カード扱いとして自分フィールド上のドラゴン族の通常モンスター１体に装備できる。\nこのカードが装備カード扱いとして装備されている場合、装備モンスターが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。\nまた、このカードの装備モンスターが破壊される場合、代わりにこのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"放浪の勇者 フリード",h:"ほうろうのゆうしゃ　ふりーど",e:"Freed the Brave Wanderer",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:1200,tx:"１ターンに１度、自分の墓地の光属性モンスター２体をゲームから除外して発動できる。\nこのカードの攻撃力より高い攻撃力を持つ、フィールド上のモンスター１体を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ボーガニアン",h:"ぼーがにあん",e:"Bowganian",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1300,de:1000,tx:"自分のスタンバイフェイズ毎に相手ライフに６００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ホーリー・エルフ",h:"ほーりー・えるふ",e:"Mystical Elf",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:800,de:2000,tx:"かよわいエルフだが、聖なる力で身を守りとても守備が高い。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ホーリー・ジェラル",h:"ほーりー・じぇらる",e:"Radiant Jeral",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1000,de:2000,tx:"フィールド上に「天空の聖域」が存在する場合、このカードが戦闘によって破壊される以外の方法で墓地に送られた時、自分は１０００ライフポイント回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ホーリー・ドール",h:"ほーりー・どーる",e:"Rogue Doll",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:1600,de:1000,tx:"聖なる力を操る人形。\n闇での攻撃は強力だ。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ホーリー・パワー",h:"ほーりー・ぱわー",e:"Lucky Trinket",ra:"N",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:600,de:800,tx:"ひょろひょろとしているが、聖なる力に守られている。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"光天使ウィングス",h:"ほーりー・らいとにんぐうぃんぐす",e:"Star Seraph Scout",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1200,de:1800,tx:"このカードが召喚に成功した時、手札から「光天使」と名のついたモンスター１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"光天使スケール",h:"ほーりー・らいとにんぐすけーる",e:"Star Seraph Scale",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1500,de:900,tx:"①：このカードが特殊召喚に成功した時に発動できる。\n手札から「光天使」モンスター１体を特殊召喚する。\nその後、自分の墓地の光属性モンスター１体を選んでデッキの一番上に置く事ができる。\n②：フィールドのこのカードを含むモンスター３体以上を素材としてX召喚したモンスターは以下の効果を得る。\n\n●X素材を持っているこのカードがモンスターゾーンに存在する限り、自分または相手が手札からモンスターを特殊召喚する度に、自分はデッキから１枚ドローする。\nこの効果は１ターンに１度しか適用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"光天使スローネ",h:"ほーりー・らいとにんぐすろーね",e:"Star Seraph Sovereignty",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:800,de:2000,tx:"このカードをX召喚の素材とする場合、モンスター３体以上を素材としたX召喚にしか使用できない。\n①：自分が「光天使」モンスターの召喚・特殊召喚に成功した場合に発動できる。\nこのカードを手札から特殊召喚し、自分はデッキから１枚ドローする。\nそのドローしたカードが「光天使」モンスターだった場合、そのモンスターを特殊召喚できる。",li:2,lk:[],ta:[],al:[],ocg:true},
  {n:"光天使セプター",h:"ほーりー・らいとにんぐせぷたー",e:"Star Seraph Scepter",ra:"UR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1800,de:400,tx:"①：このカードが召喚・特殊召喚に成功した時に発動できる。\nデッキから「光天使セプター」以外の「光天使」モンスター１体を手札に加える。\n②：フィールドのこのカードを含むモンスター３体以上を素材としてX召喚したモンスターは以下の効果を得る。\n\n●このX召喚に成功した時、このカード以外のフィールドのカード１枚を対象として発動できる。\nそのカードを破壊し、自分はデッキから１枚ドローできる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"光天使ソード",h:"ほーりー・らいとにんぐそーど",e:"Star Seraph Sword",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1400,de:1000,tx:"１ターンに１度、手札の「光天使」と名のついたモンスター１体を墓地へ送って発動できる。\nこのカードの攻撃力はエンドフェイズ時まで、墓地へ送ったモンスターの元々の攻撃力分アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"光天使ブックス",h:"ほーりー・らいとにんぐぶっくす",e:"Star Seraph Sage",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1600,de:1400,tx:"１ターンに１度、手札の魔法カード１枚を墓地へ送って発動できる。\n手札から「光天使」と名のついたモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"神聖なる魂",h:"ほーりーしゃいん・そうる",e:"Soul of Purity and Light",ra:"SR",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"天使族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2000,de:1800,tx:"このカードは通常召喚できない。\n自分の墓地から光属性モンスター２体を除外した場合に特殊召喚できる。\n①：このカードがモンスターゾーンに存在する限り、相手フィールドのモンスターの攻撃力は、相手バトルフェイズの間３００ダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"神聖なる球体",h:"ほーりーしゃいん・ぼーる",e:"Mystical Shine Ball",ra:"N",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:500,de:500,tx:"聖なる輝きに包まれた天使の魂。\nその美しい姿を見た者は、願い事がかなうと言われている。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"神聖騎士パーシアス",h:"ほーりーないとぱーしあす",e:"Avenging Knight Parshath",ra:"UR",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"天使族",mo:["シンクロ","効果"],ma:"",tp:"",at:2600,de:2100,tx:"チューナー＋チューナー以外の光属性モンスター１体以上\n①：１ターンに１度、相手フィールドの表側表示モンスター１体を対象として発動できる。\nその相手の表側表示モンスターの表示形式を変更する。\n②：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ホーリーフレーム",h:"ほーりーふれーむ",e:"Light Effigy",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1500,de:0,tx:"光属性の通常モンスターを生け贄召喚する場合、このモンスター１体で２体分の生け贄とする事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ボーンクラッシャー",h:"ぼーんくらっしゃー",e:"Bone Crusher",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1600,de:200,tx:"このカードがアンデット族モンスターの効果によって自分の墓地から特殊召喚された時、相手フィールド上に存在する魔法・罠カード１枚を破壊する事ができる。\nこのカードは特殊召喚されたターンのエンドフェイズ時に破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ボーンハイマー",h:"ぼーんはいまー",e:"Boneheimer",ra:"N",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:850,de:400,tx:"海の中をさまよい、獲物を見つけたらあらゆる水分を吸い尽くす。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"BOXサー",h:"ぼくさー",e:"BOXer",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"①：このカードが戦闘で相手モンスターを破壊し墓地へ送った時に発動できる。\nこのカードにカウンターを１つ置く。\n②：カウンターが２つ以上置かれているこのカードを墓地へ送って発動できる。\nデッキから地属性モンスター１体を特殊召喚する。\n③：このカードが戦闘・効果で破壊される場合、代わりにこのカードのカウンターを１つ取り除く事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ポケ・ドラ",h:"ぽけ・どら",e:"Poki Draco",ra:"N",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:200,de:100,tx:"このカードが召喚に成功した時、自分のデッキから「ポケ・ドラ」１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"星見鳥ラリス",h:"ほしみどりらりす",e:"Rallis the Star Bird",ra:"N",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"このカードが戦闘を行う場合、ダメージステップの間このカードの攻撃力は戦闘を行う相手モンスターのレベル×２００ポイントアップする。\nまた、このカードが攻撃したダメージステップ終了時、このカードをゲームから除外し、次の自分のターンのバトルフェイズ開始時に表側攻撃表示で自分フィールド上に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"星読みの魔術師",h:"ほしよみのまじゅつし",e:"Stargazer Magician",ra:"UR",t:"monster",a:"闇",l:5,ps:1,pe:"①：自分のPモンスターが戦闘を行う場合、相手はダメージステップ終了時まで魔法カードを発動できない。\n②：もう片方の自分のPゾーンに「魔術師」カードまたは「オッドアイズ」カードが存在しない場合、このカードのPスケールは４になる。",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1200,de:2400,tx:"①：１ターンに１度、自分フィールドのPモンスター１体のみが相手の効果で自分の手札に戻った時に発動できる。\nその同名モンスター１体を手札から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ポセイドン・オオカブト",h:"ぽせいどん・おおかぶと",e:"Great Poseidon Beetle",ra:"R",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:2500,de:2300,tx:"このカードが相手フィールド上に表側攻撃表示で存在するモンスターを攻撃し、そのモンスターが戦闘によって破壊されなかった場合、同じモンスターに続けて攻撃する事ができる。\nこの効果は１ターンに２度まで使用できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ボタニカル・ライオ",h:"ぼたにかる・らいお",e:"Botanical Lion",ra:"UR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1600,de:2000,tx:"自分フィールド上に表側表示で存在する植物族モンスター１体につき、このカードの攻撃力は３００ポイントアップする。\nこのカードはフィールド上に表側表示で存在する限り、コントロールを変更する事はできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ボタニティ・ガール",h:"ぼたにてぃ・がーる",e:"Botanical Girl",ra:"SR",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1300,de:1100,tx:"このカードがフィールド上から墓地へ送られた時、デッキから守備力１０００以下の植物族モンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ポット・ザ・トリック",h:"ぽっと・ざ・とりっく",e:"Pot the Trick",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"岩石族",mo:["通常"],ma:"",tp:"",at:400,de:400,tx:"魔術師の命令通りに動く使い魔。\nあまり強くない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"骨ネズミ",h:"ほねねずみ",e:"Bone Mouse",ra:"N",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"アンデット族",mo:["通常"],ma:"",tp:"",at:400,de:300,tx:"ネコにやられた恨みをはらすため、アンデットとして蘇ったネズミ。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"炎の女暗殺者",h:"ほのおのおんなあんさつしゃ",e:"Lady Assailant of Flames",ra:"N",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["リバース","効果"],ma:"",tp:"",at:1500,de:1000,tx:"リバース：自分のデッキの上からカードを３枚ゲームから除外する。\n相手ライフに８００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"炎の騎士 キラー",h:"ほのおのきし　きらー",e:"Charubin the Fire Knight",ra:"N",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"炎族",mo:["融合"],ma:"",tp:"",at:1100,de:800,tx:"「モンスター・エッグ」＋「スティング」",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"炎の剣豪",h:"ほのおのけんごう",e:"Darkfire Soldier #2",ra:"R",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["通常"],ma:"",tp:"",at:1700,de:1100,tx:"火山に落ちて、炎を身にまとう能力を身につけた武士。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"炎の剣士",h:"ほのおのけんし",e:"Flame Swordsman",ra:"R",t:"monster",a:"炎",l:5,ps:"",pe:"",tr:"戦士族",mo:["融合"],ma:"",tp:"",at:1800,de:1600,tx:"「炎を操る者」＋「伝説の剣豪 MASAKI」",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"炎の王 ナグルファー",h:"ほのおのじぇねれいど　なぐるふぁー",e:"Naglfar, Generaider Boss of Fire",ra:"SR",t:"monster",a:"炎",l:9,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:3100,de:200,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：「炎の王 ナグルファー」は自分フィールドに１体しか表側表示で存在できない。\n②：自分フィールドのカードが戦闘・効果で破壊される場合、代わりに自分フィールドの「ジェネレイド」モンスターまたは獣戦士族モンスター１体を破壊できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"炎の精霊 イフリート",h:"ほのおのせいれい いふりーと",e:"Spirit of Flames",ra:"SR",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1700,de:1000,tx:"このカードは通常召喚できない。\n自分の墓地から炎属性モンスター１体を除外した場合に特殊召喚できる。\n①：このカードの攻撃力は自分バトルフェイズの間３００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"炎の魔神",h:"ほのおのまじん",e:"Fireyarou",ra:"N",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["通常"],ma:"",tp:"",at:1300,de:1000,tx:"炎につつまれた魔人。\nまわりの炎を自在に操り攻撃する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"炎の魔精イグニス",h:"ほのおのませいいぐにす",e:"Flame Spirit Ignis",ra:"N",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:1500,de:1200,tx:"自分フィールド上に表側表示で存在する炎属性モンスター１体をリリースして発動する。\n自分の墓地に存在する炎属性モンスターの数×１００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"炎を操る者",h:"ほのおをあやつるもの",e:"Flame Manipulator",ra:"N",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:900,de:1000,tx:"炎の海や炎の壁を自在につくり出し攻撃する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"炎を食らう大亀",h:"ほのおをくらうおおがめ",e:"Giant Turtle Who Feeds on Flames",ra:"N",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1400,de:1800,tx:"真っ赤な甲羅が特徴のカメ。\n炎を食べると言われている。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ボマー・ドラゴン",h:"ぼまー・どらごん",e:"Exploder Dragon",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1000,de:0,tx:"①：このカードの攻撃で発生するお互いの戦闘ダメージは０になる。\n②：このカードが戦闘で破壊され墓地へ送られた場合に発動する。\nこのカードを破壊したモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"炎神－不知火",h:"ほむらがみ－しらぬい",e:"Shiranui Sunsaga",ra:"R",t:"monster",a:"炎",l:10,ps:"",pe:"",tr:"アンデット族",mo:["シンクロ","効果"],ma:"",tp:"",at:3500,de:0,tx:"アンデット族チューナー＋チューナー以外のアンデット族モンスター１体以上\n自分は「炎神－不知火」を１ターンに１度しか特殊召喚できない。\n①：このカードが特殊召喚に成功した場合に発動できる。\n自分の墓地のカード及び除外されている自分のカードの中から、アンデット族Sモンスターを任意の数だけ選んでエクストラデッキに戻す。\nその後、戻した数だけ相手フィールドのカードを選んで破壊できる。\n②：自分フィールドのアンデット族モンスターが戦闘・効果で破壊される場合、代わりに自分の墓地の「不知火」モンスター１体を除外できる。",li:2,lk:[],ta:[],al:[],ocg:true},
  {n:"焔紫竜ピュラリス",h:"ほむらりゅうぴゅらりす",e:"Puralis, the Purple Pyrotile",ra:"R",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"爬虫類族",mo:["シンクロ","チューナー","効果"],ma:"",tp:"",at:800,de:1400,tx:"チューナー＋チューナー以外のモンスター１体\n①：このカードがフィールドから墓地へ送られた場合に発動する。\n相手フィールドの全てのモンスターの攻撃力は５００ダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ホルスの黒炎竜 Lv4",h:"ほるすのこくえんりゅう　れべる４",e:"Horus the Black Flame Dragon LV4",ra:"N",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"このカードは自分フィールド上に表側表示で存在する限り、コントロールを変更する事はできない。\nこのカードがモンスターを戦闘によって破壊したターンのエンドフェイズ時、このカードを墓地に送る事で「ホルスの黒炎竜 LV６」１体を手札またはデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ホルスの黒炎竜 Lv6",h:"ほるすのこくえんりゅう　れべる６",e:"Horus the Black Flame Dragon LV6",ra:"SR",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2300,de:1600,tx:"このカードは自分フィールド上に表側表示で存在する限り、魔法の効果を受けない。\nこのカードがモンスターを戦闘によって破壊したターンのエンドフェイズ時、このカードを墓地に送る事で「ホルスの黒炎竜 LV８」１体を手札またはデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ホルスの黒炎竜 Lv8",h:"ほるすのこくえんりゅう　れべる８",e:"Horus the Black Flame Dragon LV8",ra:"UR",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3000,de:1800,tx:"このカードは通常召喚できない。\n「ホルスの黒炎竜 LV６」の効果でのみ特殊召喚する事ができる。\nこのカードが自分フィールド上に表側表示で存在する限り、魔法カードの発動を無効にし破壊する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ホルスのしもべ",h:"ほるすのしもべ",e:"Horus' Servant",ra:"R",t:"monster",a:"炎",l:1,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"このカードが自分フィールド上に表側表示で存在する限り、相手は「ホルスの黒炎竜（全てのレベルを含む）」を魔法・罠・モンスターの効果の対象にする事はできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ボルテック・コング",h:"ぼるてっく・こんぐ",e:"Voltic Kong",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"このカードが相手ライフに戦闘ダメージを与えた時、自分フィールド上に表側表示で存在する光属性モンスターの数だけ相手のデッキの上からカードを墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ボルト・エスカルゴ",h:"ぼると・えすかるご",e:"Bolt Escargot",ra:"N",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"雷族",mo:["通常"],ma:"",tp:"",at:1400,de:1500,tx:"ネバネバの液をはきかけ、動けなくなったところに電撃アタック！",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ボルト・ヘッジホッグ",h:"ぼると・へっじほっぐ",e:"Quillbolt Hedgehog",ra:"SR",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"①：自分メインフェイズに発動できる。\nこのカードを墓地から特殊召喚する。\nこの効果は自分フィールドにチューナーが存在する場合に発動と処理ができる。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ボルト・ペンギン",h:"ぼると・ぺんぎん",e:"Bolt Penguin",ra:"N",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"雷族",mo:["通常"],ma:"",tp:"",at:1100,de:800,tx:"両腕の電撃ムチで相手をマヒさせ、首を絞めて攻撃する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ホログラー",h:"ほろぐらー",e:"Holograh",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1100,de:700,tx:"様々な幻想を見せ、そのスキをついて攻撃してくる機械。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"白闘気海豚",h:"ほわいと・おーら・どるふぃん",e:"White Aura Dolphin",ra:"SR",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"魚族",mo:["シンクロ","効果"],ma:"",tp:"",at:2400,de:1000,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：１ターンに１度、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで元々の攻撃力の半分になる。\n②：このカードが相手によって破壊され墓地へ送られた場合、このカード以外の自分の墓地の水属性モンスター１体を除外して発動できる。\nこのカードをチューナー扱いで特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"白闘気一角",h:"ほわいと・おーら・ものけろす",e:"White Aura Monoceros",ra:"R",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"魚族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:1500,tx:"水属性チューナー＋チューナー以外のモンスター１体以上\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードがS召喚に成功した時、自分の墓地の魚族モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターはこのターン攻撃できない。\n②：このカードが相手によって破壊され墓地へ送られた場合、このカード以外の自分の墓地の水属性モンスター１体を除外して発動できる。\nこのカードをチューナー扱いで特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"太古の白石",h:"ほわいと・おぶ・えんしぇんと",e:"The White Stone of Ancients",ra:"UR",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"ドラゴン族",mo:["チューナー","効果"],ma:"",tp:"",at:600,de:500,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが墓地へ送られたターンのエンドフェイズに発動できる。\nデッキから「ブルーアイズ」モンスター１体を特殊召喚する。\n②：墓地のこのカードを除外し、自分の墓地の「ブルーアイズ」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"伝説の白石",h:"ほわいと・おぶ・れじぇんど",e:"The White Stone of Legend",ra:"SR",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"ドラゴン族",mo:["チューナー","効果"],ma:"",tp:"",at:300,de:250,tx:"①：このカードが墓地へ送られた場合に発動する。\nデッキから「青眼の白龍」１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"白い泥棒",h:"ほわいと・しーふ",e:"White Magical Hat",ra:"SR",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1000,de:700,tx:"このカードが相手ライフに戦闘ダメージを与えた時、相手の手札をランダムに１枚捨てる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"白棘鱏",h:"ほわいと・すてぃんぐれい",e:"White Stingray",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1400,de:1000,tx:"このカード名の、①の方法による特殊召喚は１ターンに１度しかできず、②の効果は１ターンに１度しか使用できない。\n①：このカードは手札の水属性モンスター１体を捨てて、手札から特殊召喚できる。\n②：このカードが墓地からの特殊召喚に成功した場合に発動できる。\nこのターン、このカードをチューナーとして扱う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ホワイト・ダストン",h:"ほわいと・だすとん",e:"White Duston",ra:"N",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:0,de:1000,tx:"ちっちゃな悪魔、ダストンズの白いヤツ。\n自身でも驚きの白さである事をホコリに思っているらしい。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ホワイト・ドルフィン",h:"ほわいと・どるふぃん",e:"White Dolphin",ra:"N",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"魚族",mo:["通常"],ma:"",tp:"",at:500,de:400,tx:"頭にツノを持つまっしろなイルカ。\n大波をおこして攻撃する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ホワイト・ホーンズ・ドラゴン",h:"ほわいと・ほーんず・どらごん",e:"White-Horned Dragon",ra:"SR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2200,de:1400,tx:"①：このカードが召喚・特殊召喚に成功した場合、相手の墓地の魔法カードを５枚まで対象として発動する。\nそのカードを除外し、このカードの攻撃力はその除外したカードの数×３００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"白鱓",h:"ほわいと・もーれい",e:"White Moray",ra:"N",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:600,de:200,tx:"①：このカードが召喚に成功したターン、このカードは相手に直接攻撃できる。\n②：このカードが墓地からの特殊召喚に成功した場合に発動できる。\nこのターン、このカードをチューナーとして扱う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"白夜の女王",h:"ほわいとないつ・くぃーん",e:"White Night Queen",ra:"SR",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2100,de:800,tx:"このカードは特殊召喚できない。\n１ターンに１度、フィールド上にセットされたカード１枚を破壊する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"白い忍者",h:"ほわいとにんじゃ",e:"White Ninja",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["リバース","効果"],ma:"",tp:"",at:1500,de:800,tx:"リバース：フィールド上の守備表示モンスター１体を破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ホワイトポータン",h:"ほわいとぽーたん",e:"White Potan",ra:"R",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:100,de:200,tx:"自分フィールド上にチューナーが表側表示で存在する場合、このカードは戦闘では破壊されない。\n自分フィールド上に表側表示で存在するチューナーが戦闘によって破壊され墓地へ送られた時、相手ライフに５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ホワイトローズ・ドラゴン",h:"ほわいとろーず・どらごん",e:"White Rose Dragon",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1200,de:1000,tx:"このカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドにドラゴン族または植物族のチューナーが存在する場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが召喚に成功した時に発動できる。\n自分の手札・墓地から「ホワイトローズ・ドラゴン」以外の「ローズ・ドラゴン」モンスター１体を選んで特殊召喚する。\n③：このカードがS素材として墓地へ送られた場合に発動できる。\nデッキからレベル４以上の植物族モンスター１体を墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"本の精霊 ホーク・ビショップ",h:"ほんのせいれい　ほーく・びしょっぷ",e:"Spirit of the Books",ra:"N",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["通常"],ma:"",tp:"",at:1400,de:1200,tx:"本の精霊。\nとても高い知恵を持ち、多彩な攻撃をしかけてくる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"翻弄するエルフの剣士",h:"ほんろうするえるふのけんし",e:"Obnoxious Celtic Guard",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1400,de:1200,tx:"①：このカードは攻撃力１９００以上のモンスターとの戦闘では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マーダーサーカス",h:"まーだーさーかす",e:"Crass Clown",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1350,de:1400,tx:"フィールド上に守備表示で存在するこのカードが表側攻撃表示になった時、相手フィールド上に存在するモンスター１体を選択して持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マーダーサーカス・ゾンビ",h:"まーだーさーかす・ぞんび",e:"Clown Zombie",ra:"N",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"アンデット族",mo:["通常"],ma:"",tp:"",at:1350,de:0,tx:"闇の力で生き返ったピエロ。\nフラフラとした踊りで死へといざなう。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マーメイド・シャーク",h:"まーめいど・しゃーく",e:"Mermaid Shark",ra:"UR",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:100,de:300,tx:"このカードが召喚に成功した時、デッキからレベル３～５の魚族モンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マーメイド・ナイト",h:"まーめいど・ないと",e:"Mermaid Knight",ra:"SR",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1500,de:700,tx:"「海」がフィールド上に存在する限り、このカードは一度のバトルフェイズ中に２回攻撃をする事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"水精鱗－アビスグンデ",h:"まーめいる－あびすぐんで",e:"Mermail Abyssgunde",ra:"R",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1400,de:800,tx:"このカードが手札から墓地へ捨てられた場合、自分の墓地から「水精鱗－アビスグンデ」以外の「水精鱗」と名のついたモンスター１体を選択して特殊召喚できる。\n「水精鱗－アビスグンデ」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"水精鱗－アビスタージ",h:"まーめいる－あびすたーじ",e:"Mermail Abyssturge",ra:"R",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1700,de:1100,tx:"このカードが召喚・特殊召喚に成功した時、手札の水属性モンスター１体を墓地へ捨てて発動できる。\n自分の墓地からレベル３以下の水属性モンスター１体を選択して手札に加える。\n「水精鱗－アビスタージ」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"水精鱗－アビスディーネ",h:"まーめいる－あびすでぃーね",e:"Mermail Abyssdine",ra:"N",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1000,de:200,tx:"自分フィールド上に「水精鱗」と名のついたモンスターが存在する場合、このカードがカードの効果によってデッキまたは墓地から手札に加わった時、このカードを手札から特殊召喚できる。\nまた、このカードが「水精鱗」と名のついたモンスターの効果によって特殊召喚に成功した時、自分の墓地からレベル３以下の「水精鱗」と名のついたモンスター１体を選択して特殊召喚できる。\n「水精鱗－アビスディーネ」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"水精鱗－アビストリーテ",h:"まーめいる－あびすとりーて",e:"Mermail Abysstrite",ra:"R",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"海竜族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1600,de:2800,tx:"レベル３モンスター×３\n自分フィールド上の「水精鱗」と名のついたモンスター１体が相手の魔法・罠カードの効果の対象になった時、または相手モンスターの攻撃対象になった時、このカードのエクシーズ素材を１つ取り除いて発動できる。\nその対象を自分フィールド上の正しい対象となるこのカードに移し替える。\nこのカードが破壊され墓地へ送られた時、自分の墓地から「水精鱗－アビストリーテ」以外の「水精鱗」と名のついたモンスター１体を選択して特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"水精鱗－アビスノーズ",h:"まーめいる－あびすのーず",e:"Mermail Abyssnose",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1500,de:1500,tx:"このカードが戦闘によって相手モンスターを破壊し墓地へ送った時、手札の水属性モンスター１体を墓地へ捨てて発動できる。\nデッキから「水精鱗」と名のついたモンスター１体を表側守備表示で特殊召喚する。\n「水精鱗－アビスノーズ」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"水精鱗－アビスパイク",h:"まーめいる－あびすぱいく",e:"Mermail Abysspike",ra:"SR",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1600,de:800,tx:"このカードが召喚・特殊召喚に成功した時、手札の水属性モンスター１体を墓地へ捨てて発動できる。\nデッキからレベル３の水属性モンスター１体を手札に加える。\n「水精鱗－アビスパイク」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"水精鱗－アビスヒルデ",h:"まーめいる－あびすひるで",e:"Mermail Abysshilde",ra:"R",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1300,de:400,tx:"このカードが墓地へ送られた場合、手札から「水精鱗－アビスヒルデ」以外の「水精鱗」と名のついたモンスター１体を特殊召喚できる。\n「水精鱗－アビスヒルデ」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"水精鱗－アビスマンダー",h:"まーめいる－あびすまんだー",e:"Mermail Abyssmander",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:100,de:2000,tx:"墓地のこのカードをゲームから除外し、以下の効果から１つを選択して発動できる。\n●自分フィールド上の全ての「水精鱗」と名のついたモンスターのレベルを１つ上げる。\n●自分フィールド上の全ての「水精鱗」と名のついたモンスターのレベルを２つ上げる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"水精鱗－アビスラング",h:"まーめいる－あびすらんぐ",e:"Mermail Abysslung",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1200,de:1800,tx:"このカードがフィールド上に表側表示で存在する限り、相手は他の水属性モンスターを攻撃対象に選択できない。\nまた、このカードがフィールド上に表側表示で存在する限り、自分フィールド上の水属性モンスターの攻撃力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"水精鱗－アビスリンデ",h:"まーめいる－あびすりんで",e:"Mermail Abysslinde",ra:"SR",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1500,de:1200,tx:"フィールド上のこのカードが破壊され墓地へ送られた場合、デッキから「水精鱗－アビスリンデ」以外の「水精鱗」と名のついたモンスター１体を特殊召喚できる。\n「水精鱗－アビスリンデ」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"水精鱗－オーケアビス",h:"まーめいる－おーけあびす",e:"Mermail Abyssocea",ra:"N",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1100,de:1900,tx:"自分のメインフェイズ時、自分フィールド上の「水精鱗」と名のついたモンスター１体を選択して発動できる。\nレベルの合計が選択したモンスターのレベル以下となるように、デッキからレベル４以下の「水精鱗」と名のついたモンスターを任意の数だけ特殊召喚する。\nその後、選択したモンスターを墓地へ送る。\n「水精鱗－オーケアビス」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"水精鱗－サルフアビス",h:"まーめいる－さるふあびす",e:"Mermail Abyssbalaen",ra:"N",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:2500,de:2000,tx:"自分のメインフェイズ時、手札から「水精鱗」と名のついたモンスター４体を墓地へ捨てて発動できる。\nこのカードを手札から特殊召喚する。\nこの効果で特殊召喚した時、このカードの攻撃力を５００ポイントアップし、自分の墓地の「水精鱗」と名のついたモンスターの数まで、相手フィールド上のカードを選択して破壊する。\nまた、このカード以外の表側攻撃表示で存在する「水精鱗」と名のついたモンスター１体をリリースする事で、このターンこのカードが守備表示モンスターを攻撃した場合、ダメージ計算を行わずそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"水精鱗－ディニクアビス",h:"まーめいる－でぃにくあびす",e:"Mermail Abyssteus",ra:"UR",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1700,de:2400,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：手札からこのカード以外の水属性モンスター１体を墓地へ捨てて発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードの①の効果で特殊召喚に成功した時に発動できる。\nデッキからレベル４以下の「水精鱗」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"水精鱗－メガロアビス",h:"まーめいる－めがろあびす",e:"Mermail Abyssmegalo",ra:"UR",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:2400,de:1900,tx:"①：手札からこのカード以外の水属性モンスター２体を墓地へ捨てて発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードの①の効果で特殊召喚に成功した時に発動できる。\nデッキから「アビス」魔法・罠カード１枚を手札に加える。\n③：このカード以外の自分フィールドの表側攻撃表示の水属性モンスター１体をリリースして発動できる。\nこのターン、このカードは１度のバトルフェイズ中に２回攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"水精鱗－リードアビス",h:"まーめいる－りーどあびす",e:"Mermail Abyssleed",ra:"R",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:2700,de:1000,tx:"自分のメインフェイズ時、手札からこのカード以外の水属性モンスター３体を墓地へ捨てて発動できる。\nこのカードを手札から特殊召喚する。\nこの効果で特殊召喚に成功した時、自分の墓地から「アビス」と名のついた魔法・罠カード１枚を選択して手札に加える事ができる。\nまた、このカード以外の自分フィールド上に表側攻撃表示で存在する「水精鱗」と名のついたモンスター１体をリリースする事で、相手の手札をランダムに１枚墓地へ送る。\n「水精鱗－リードアビス」のこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マイティ・ウォリアー",h:"まいてぃ・うぉりあー",e:"Mighty Warrior",ra:"UR",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2200,de:2000,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカードが戦闘によって相手モンスターを破壊した場合、破壊したモンスターの攻撃力の半分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マイティガード",h:"まいてぃがーど",e:"Mighty Guard",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:500,de:1200,tx:"警備用として開発された機械の戦士。\n錆びない金属でできている。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マイルド・ターキー",h:"まいるど・たーきー",e:"Mild Turkey",ra:"N",t:"monster",a:"炎",l:4,ps:7,pe:"①：１ターンに１度、自分メインフェイズに発動できる。\nサイコロを１回振る。\nターン終了時まで、このカードのPスケールを出た目の数だけ下げる（最小１まで）。",tr:"鳥獣族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:1000,de:2000,tx:"ボウリングへの情熱に身を焦がすワイルドな七面鳥。\nストライクを取るべく鍛え上げられた体は、常に極上の香りを放つ。\nまだ見ぬターキーを目指し、日々の練習を欠かさない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マイン・ゴーレム",h:"まいん・ごーれむ",e:"Mine Golem",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1000,de:1900,tx:"このカードが戦闘によって破壊され墓地へ送られた時、相手ライフに５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マイン・モール",h:"まいん・もーる",e:"Mine Mole",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1000,de:1200,tx:"このカードは１ターンに１度だけ、戦闘では破壊されない。\nこのカードが獣族モンスターのシンクロ召喚の素材として墓地へ送られた場合、自分のデッキからカードを１枚ドローする。\nこのカードは相手のカードの効果によってフィールド上から離れた場合、ゲームから除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マインド・オン・エア",h:"まいんど・おん・えあ",e:"Mind on Air",ra:"N",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1000,de:1600,tx:"このカードがフィールド上に表側表示で存在する限り、相手は手札を公開し続けなければならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マインフィールド",h:"まいんふぃーるど",e:"Minefieldriller",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1500,de:1500,tx:"①：表側表示のこのカードが自分フィールドから離れた時、自分の墓地のフィールド魔法カード１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マウンテン・ウォーリアー",h:"まうんてん・うぉーりあー",e:"Mountain Warrior",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣戦士族",mo:["通常"],ma:"",tp:"",at:600,de:1000,tx:"足場の悪い所でもガンガン動きまわる頑丈な戦士。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔王ディアボロス",h:"まおうでぃあぼろす",e:"Diabolos, King of the Abyss",ra:"R",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2800,de:1000,tx:"このカードは特殊召喚できない。\nこのカードをアドバンス召喚する場合、リリースするモンスターは闇属性モンスターでなければならない。\n①：このカードはモンスターゾーンに存在する限り、効果ではリリースできない。\n②：相手ドローフェイズのドロー前に発動する。\n相手のデッキの一番上のカードを確認し、デッキの一番上または一番下に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔界劇団－サッシー・ルーキー",h:"まかいげきだん－さっしー・るーきー",e:"Abyss Actor - Sassy Rookie",ra:"R",t:"monster",a:"闇",l:4,ps:2,pe:"①：自分フィールドの「魔界劇団」モンスターが戦闘または相手の効果で破壊される場合、代わりにこのカードを破壊できる。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1700,de:1000,tx:"①：このカードは１ターンに１度だけ戦闘・効果では破壊されない。\n②：モンスターゾーンのこのカードが戦闘または相手の効果で破壊された場合に発動できる。\nデッキから「魔界劇団－サッシー・ルーキー」以外のレベル４以下の「魔界劇団」モンスター１体を特殊召喚する。\n③：このカードがPゾーンで破壊された場合、相手フィールドのレベル４以下のモンスター１体を対象として発動できる。\nそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔界劇団－ティンクル・リトルスター",h:"まかいげきだん－てぃんくる・りとるすたー",e:"Abyss Actor - Twinkle Little Star",ra:"R",t:"monster",a:"闇",l:4,ps:9,pe:"①：自分は「魔界劇団」モンスターしかP召喚できない。\nこの効果は無効化されない。\n②：１ターンに１度、自分フィールドの「魔界劇団」モンスター１体を対象として発動できる。\nこのターン、そのモンスターは１度のバトルフェイズ中に３回までモンスターに攻撃でき、対象のモンスター以外の自分のモンスターは攻撃できない。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1000,de:1000,tx:"①：このカードは自分ターンには戦闘では破壊されず、１度のバトルフェイズ中に３回までモンスターに攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔界劇団－デビル・ヒール",h:"まかいげきだん－でびる・ひーる",e:"Abyss Actor - Evil Heel",ra:"R",t:"monster",a:"闇",l:8,ps:1,pe:"①：１ターンに１度、自分フィールドの「魔界劇団」モンスター１体をリリースし、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで、リリースしたモンスターの元々の攻撃力分ダウンする。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:3000,de:2000,tx:"①：このカードが召喚・特殊召喚に成功した場合、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで、自分フィールドの「魔界劇団」モンスターの数×１０００ダウンする。\n②：このカードが戦闘で相手モンスターを破壊した時、自分の墓地の「魔界台本」魔法カード１枚を対象として発動できる。\nそのカードを自分フィールドにセットする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔界劇団－ビッグ・スター",h:"まかいげきだん－びっぐ・すたー",e:"Abyss Actor - Superstar",ra:"UR",t:"monster",a:"闇",l:7,ps:3,pe:"①：１ターンに１度、自分フィールドの「魔界劇団」モンスター１体をリリースし、自分の墓地の「魔界台本」魔法カード１枚を対象として発動できる。\nそのカードを手札に加える。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2500,de:1800,tx:"①：このカードの召喚・特殊召喚成功時に相手は魔法・罠カードの効果を発動できない。\n②：１ターンに１度、自分メインフェイズに発動できる。\nデッキから「魔界台本」魔法カード１枚を選んで自分フィールドにセットする。\nこの効果でセットしたカードはエンドフェイズに墓地へ送られる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔界劇団－ファンキー・コメディアン",h:"まかいげきだん－ふぁんきー・こめでぃあん",e:"Abyss Actor - Funky Comedian",ra:"SR",t:"monster",a:"闇",l:1,ps:8,pe:"①：１ターンに１度、自分フィールドの「魔界劇団」モンスター１体をリリースし、自分フィールドの「魔界劇団」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで、リリースしたモンスターの元々の攻撃力分アップする。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:300,de:200,tx:"「魔界劇団－ファンキー・コメディアン」の②のモンスター効果は１ターンに１度しか使用できず、この効果を発動するターン、このカードは攻撃できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nこのカードの攻撃力はターン終了時まで、自分フィールドの「魔界劇団」モンスターの数×３００アップする。\n②：このカード以外の自分フィールドの「魔界劇団」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで、このカードの攻撃力分アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔界劇団－プリティ・ヒロイン",h:"まかいげきだん－ぷりてぃ・ひろいん",e:"Abyss Actor - Leading Lady",ra:"R",t:"monster",a:"闇",l:4,ps:2,pe:"①：１ターンに１度、相手モンスターの攻撃で自分が戦闘ダメージを受けた時、以下の効果から１つを選択して発動できる。\n●その相手モンスターの攻撃力は、受けたダメージの数値分ダウンする。\n●自分のEXデッキから、受けたダメージの数値以下の攻撃力を持つ表側表示の「魔界劇団」Pモンスター１体を手札に加える。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1500,de:1000,tx:"①：１ターンに１度、自分または相手が戦闘ダメージを受けた時、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力は、その戦闘ダメージの数値分ダウンする。\n②：モンスターゾーンのこのカードが戦闘または相手の効果で破壊された時に発動できる。\nデッキから「魔界台本」魔法カード１枚を選んで自分フィールドにセットする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔界劇団－メロー・マドンナ",h:"まかいげきだん－めろー・まどんな",e:"Abyss Actor - Mellow Madonna",ra:"SR",t:"monster",a:"闇",l:7,ps:"",pe:"このカード名のP効果は１ターンに１度しか使用できない。\n①：１０００LPを払って発動できる。\nデッキから「魔界劇団－メロー・マドンナ」以外の「魔界劇団」Pモンスター１体を手札に加える。\nこの効果の発動後、ターン終了時まで自分は「魔界劇団」Pモンスターしか特殊召喚できない。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1800,de:2500,tx:"このカード名の①③のモンスター効果はそれぞれ１ターンに１度しか使用できない。\n①：自分のPモンスターが戦闘で破壊された時に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードの攻撃力は自分の墓地の「魔界台本」魔法カードの数×１００アップする。\n③：「魔界台本」魔法カードの効果が発動した場合に発動できる。\nデッキからレベル４以下の「魔界劇団」Pモンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターはエンドフェイズに持ち主の手札に戻る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔界劇団－ワイルド・ホープ",h:"まかいげきだん－わいるど・ほーぷ",e:"Abyss Actor - Wild Hope",ra:"SR",t:"monster",a:"闇",l:4,ps:2,pe:"①：１ターンに１度、もう片方の自分のPゾーンの「魔界劇団」カード１枚を対象として発動できる。\nそのカードのPスケールはターン終了時まで９になる。\nこの効果の発動後、ターン終了時まで自分は「魔界劇団」モンスターしか特殊召喚できない。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1600,de:1200,tx:"このカード名の②のモンスター効果は１ターンに１度しか使用できない。\n①：１ターンに１度、自分メインフェイズに発動できる。\nこのカードの攻撃力はターン終了時まで、自分フィールドの「魔界劇団」モンスターの種類×１００アップする。\n②：このカードが戦闘・効果で破壊された場合に発動できる。\nデッキから「魔界劇団－ワイルド・ホープ」以外の「魔界劇団」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔界植物",h:"まかいしょくぶつ",e:"Fungi of the Musk",ra:"N",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:400,de:300,tx:"表面から強力な酸を出して、近づく者を溶かしてしまう。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔界闘士 バルムンク",h:"まかいとうし　ばるむんく",e:"Underworld Fighter Balmung",ra:"UR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2100,de:800,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカードがカードの効果によって破壊され墓地へ送られた時、自分の墓地からこのカード以外のレベル４以下のモンスター１体を選択して特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔界のイバラ",h:"まかいのいばら",e:"Darkworld Thorns",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"植物族",mo:["通常"],ma:"",tp:"",at:1200,de:900,tx:"魔界に生息するイバラ。\n無理に通ろうとする者にからみつく。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔界の機械兵",h:"まかいのきかいへい",e:"Cyber Soldier of Darkworld",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1400,de:1200,tx:"闇の力でつくられた機械兵。\n狂ったように敵を破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔界発現世行きバス",h:"まかいはつげんせゆきばす",e:"Tour Bus From the Underworld",ra:"R",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードが墓地へ送られた時、「魔界発現世行きバス」以外の自分または相手の墓地のモンスター１体を選択し、持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔界発冥界行きバス",h:"まかいはつめいかいゆきばす",e:"Tour Bus To Forbidden Realms",ra:"SR",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["リバース","効果"],ma:"",tp:"",at:600,de:1000,tx:"①：このカードがリバースした場合に発動する。\nデッキから光・闇属性以外の悪魔族モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔貨物車両 ボコイチ",h:"まかもつしゃりょう　ぼこいち",e:"Bokoichi the Freightening Car",ra:"N",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:500,de:500,tx:"デコイチ専用の貨物車両。\nどんな物でも運ぶ事ができるが、大抵は到着時に壊れている。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マキシマム・シックス",h:"まきしまむ・しっくす",e:"Maximum Six",ra:"R",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1900,de:1600,tx:"このカードが生け贄召喚に成功した時、サイコロを１回振る。\nこのカードの攻撃力は、フィールド上に表側表示で存在する限り、出た目×２００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マキャノン",h:"まきゃのん",e:"Mabarrel",ra:"N",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1700,de:1400,tx:"大砲のような悪魔。\n目に見えない早さで目玉の弾を発射する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マグナ・スラッシュドラゴン",h:"まぐな・すらっしゅどらごん",e:"Magna-Slash Dragon",ra:"R",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2400,de:1200,tx:"自分フィールド上に表側表示で存在する永続魔法カード１枚を墓地へ送って発動できる。\n相手フィールド上の魔法・罠カード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"炎龍",h:"まぐな・どらご",e:"Magna Drago",ra:"R",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"ドラゴン族",mo:["チューナー","効果"],ma:"",tp:"",at:1400,de:600,tx:"①：このカードが相手に戦闘ダメージを与えた場合に発動する。\nこのカードの攻撃力は２００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マグナム・リリィ",h:"まぐなむ・りりぃ",e:"Barrel Lily",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"植物族",mo:["通常"],ma:"",tp:"",at:1100,de:600,tx:"いわゆるてっぽうユリ。\n花粉の弾を飛ばし相手を攻撃する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マグネッツ1号",h:"まぐねっつ１ごう",e:"M-Warrior #1",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1000,de:500,tx:"コンビプレーが得意な戦士。\n強い磁力を発し、誰にも逃げられない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マグネッツ2号",h:"まぐねっつ２ごう",e:"M-Warrior #2",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:500,de:1000,tx:"コンビプレーが得意な戦士。\n電磁コーティングされた鎧は頑丈。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"磁石の戦士α",h:"まぐねっと・うぉりあー・あるふぁ",e:"Alpha The Magnet Warrior",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["通常"],ma:"",tp:"",at:1400,de:1700,tx:"α、β、γで変形合体する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"磁石の戦士γ",h:"まぐねっと・うぉりあー・がんま",e:"Gamma The Magnet Warrior",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["通常"],ma:"",tp:"",at:1500,de:1800,tx:"α、β、γで変形合体する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"磁石の戦士δ",h:"まぐねっと・うぉりあー・でるた",e:"Delta The Magnet Warrior",ra:"UR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1600,de:1400,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキからレベル４以下の「マグネット・ウォリアー」モンスター１体を墓地へ送る。\n②：このカードが墓地へ送られた場合、自分の墓地から「磁石の戦士δ」以外のレベル４以下の「マグネット・ウォリアー」モンスター３体を除外して発動できる。\n手札・デッキから「磁石の戦士マグネット・バルキリオン」１体を召喚条件を無視して特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"磁石の戦士β",h:"まぐねっと・うぉりあー・べーた",e:"Beta The Magnet Warrior",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["通常"],ma:"",tp:"",at:1700,de:1600,tx:"α、β、γで変形合体する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"溶岩大巨人",h:"まぐま・ぎがんと",e:"Molten Behemoth",ra:"N",t:"monster",a:"炎",l:5,ps:"",pe:"",tr:"炎族",mo:["通常"],ma:"",tp:"",at:1000,de:2200,tx:"マグマの大地から生まれた巨人。\nマグマパンチで攻撃をする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マグマ・ドラゴン",h:"まぐま・どらごん",e:"Magma Dragon",ra:"R",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"幻竜族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"「マグマ・ドラゴン」の効果は１ターンに１度しか使用できず、この効果を発動するターン、自分は幻竜族モンスターしか特殊召喚できない。\n①：このカードが特殊召喚に成功した時、「マグマ・ドラゴン」以外の自分の墓地の幻竜族モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マグマン",h:"まぐまん",e:"Dissolverock",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["通常"],ma:"",tp:"",at:900,de:1000,tx:"マグマの中から生まれたモンスター。\nものすごい熱で近づくものは溶ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔犬オクトロス",h:"まけんおくとろす",e:"Doomdog Octhros",ra:"R",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"「魔犬オクトロス」の効果は１ターンに１度しか使用できない。\n①：このカードがフィールドから墓地へ送られた場合に発動する。\nデッキから悪魔族・レベル８モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔轟神アシェンヴェイル",h:"まごうしんあしぇんう゛ぇいる",e:"Fabled Ashenveil",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"①：このカードが戦闘を行うそのダメージ計算時に１度、手札を１枚墓地へ送って発動できる。\nこのカードの攻撃力は、そのダメージ計算時のみ６００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔轟神ヴァルキュルス",h:"まごうしんう゛ぁるきゅるす",e:"Fabled Valkyrus",ra:"R",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"悪魔族",mo:["シンクロ","効果"],ma:"",tp:"",at:2900,de:1700,tx:"「魔轟神」と名のついたチューナー＋チューナー以外のモンスター１体以上\n１ターンに１度、手札から悪魔族モンスター１体を捨てて発動できる。\nデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔轟神ウルストス",h:"まごうしんうるすとす",e:"Fabled Urustos",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1500,de:200,tx:"このカードがフィールド上に表側表示で存在し、自分の手札が２枚以下の場合、自分フィールド上の全ての「魔轟神」と名のついたモンスターの攻撃力は４００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔轟神オルトロ",h:"まごうしんおるとろ",e:"Fabled Oltro",ra:"N",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:500,tx:"１ターンに１度、手札を１枚墓地へ送って発動できる。\n手札からレベル３の「魔轟神」と名のついたモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔轟神ガルバス",h:"まごうしんがるばす",e:"Fabled Gallabas",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1500,de:800,tx:"手札を１枚墓地へ捨てて発動できる。\nこのカードの攻撃力以下の守備力を持つ、相手フィールド上のモンスター１体を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔轟神クシャノ",h:"まごうしんくしゃの",e:"Fabled Kushano",ra:"R",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:1100,de:800,tx:"このカードが墓地に存在する場合、手札から「魔轟神クシャノ」以外の「魔轟神」と名のついたモンスター１体を墓地へ捨てて発動できる。\n墓地のこのカードを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔轟神グリムロ",h:"まごうしんぐりむろ",e:"Fabled Grimro",ra:"UR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"①：自分フィールドに「魔轟神」モンスターが存在する場合、このカードを手札から墓地へ送って発動できる。\nデッキから「魔轟神グリムロ」以外の「魔轟神」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔轟神クルス",h:"まごうしんくるす",e:"Fabled Krus",ra:"R",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1000,de:800,tx:"このカードが手札から墓地へ捨てられた時、自分の墓地からこのカード以外のレベル４以下の「魔轟神」と名のついたモンスター１体を選択して特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔轟神獣ガナシア",h:"まごうしんじゅうがなしあ",e:"The Fabled Ganashia",ra:"R",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"このカードが手札から墓地へ捨てられた時、このカードを墓地から特殊召喚する。\nこの効果で特殊召喚したこのカードの攻撃力は２００ポイントアップし、フィールド上から離れた場合ゲームから除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔轟神獣キャシー",h:"まごうしんじゅうきゃしー",e:"The Fabled Catsith",ra:"SR",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"獣族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:600,tx:"このカードが手札から墓地へ捨てられた時、フィールド上に表側表示で存在するカード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔轟神獣クダベ",h:"まごうしんじゅうくだべ",e:"The Fabled Kudabbi",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣族",mo:["シンクロ","効果"],ma:"",tp:"",at:2200,de:1100,tx:"「魔轟神」と名のついたチューナー＋チューナー以外のモンスター１体以上\nこのカードは自分の手札の枚数によって以下の効果を得る。\n\n●０枚：このカードは戦闘及びカードの効果では破壊されない。\n\n●３枚以上：エンドフェイズ時にこのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔轟神獣ケルベラル",h:"まごうしんじゅうけるべらる",e:"The Fabled Cerburrel",ra:"UR",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"獣族",mo:["チューナー","効果"],ma:"",tp:"",at:1000,de:400,tx:"①：このカードが手札から墓地へ捨てられた場合に発動する。\nこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔轟神獣コカトル",h:"まごうしんじゅうこかとる",e:"The Fabled Kokkator",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1700,de:1500,tx:"このカードが戦闘によって相手モンスターを破壊し墓地へ送った時、手札から「魔轟神」と名のついたモンスター１体を捨てて発動できる。\nデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔轟神獣チャワ",h:"まごうしんじゅうちゃわ",e:"The Fabled Chawa",ra:"R",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"獣族",mo:["チューナー","効果"],ma:"",tp:"",at:200,de:100,tx:"自分のメインフェイズ時に発動できる。\n手札から「魔轟神」と名のついたモンスター１体を捨て、このカードを手札から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔轟神獣ノズチ",h:"まごうしんじゅうのずち",e:"The Fabled Nozoochee",ra:"N",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1200,de:800,tx:"自分のメインフェイズ時に発動できる。\n手札から「魔轟神」と名のついたモンスター１体を捨て、このカードを手札から特殊召喚する。\nこの効果で特殊召喚に成功した時、手札からレベル２以下の「魔轟神」と名のついたモンスター１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔轟神獣ペガラサス",h:"まごうしんじゅうぺがらさす",e:"The Fabled Peggulsus",ra:"N",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"獣族",mo:["チューナー","効果"],ma:"",tp:"",at:100,de:1600,tx:"このカードが手札から墓地へ捨てられた時、このカードを墓地から裏側守備表示で特殊召喚できる。\nまた、このカードがリバースした時、手札の「魔轟神」と名のついたモンスター１体を見せて発動できる。\nデッキから「魔轟神」と名のついたモンスター１体を墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔轟神獣ユニコール",h:"まごうしんじゅうゆにこーる",e:"The Fabled Unicore",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣族",mo:["シンクロ","効果"],ma:"",tp:"",at:2300,de:1000,tx:"「魔轟神」と名のついたチューナー＋チューナー以外のモンスター１体以上\nこのカードがフィールド上に表側表示で存在し、お互いの手札が同じ枚数である限り、相手が発動した魔法・罠・効果モンスターの効果は無効化され破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔轟神獣ルビィラーダ",h:"まごうしんじゅうるびぃらーだ",e:"The Fabled Rubyruda",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣族",mo:["チューナー","効果"],ma:"",tp:"",at:1100,de:800,tx:"自分フィールド上に表側表示で存在するこのカードが攻撃対象に選択された時、手札から「魔轟神」と名のついたモンスター１体を捨てる事でその攻撃を無効にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔轟神ソルキウス",h:"まごうしんそるきうす",e:"Fabled Soulkius",ra:"SR",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2200,de:2100,tx:"このカードが墓地に存在する場合、手札から「魔轟神ソルキウス」以外のカード２枚を墓地へ送って発動できる。\nこのカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔轟神ディアネイラ",h:"まごうしんでぃあねいら",e:"Fabled Dianaira",ra:"R",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2800,de:100,tx:"このカードは「魔轟神」と名のついたモンスター１体をリリースして表側攻撃表示でアドバンス召喚できる。\nこのカードが自分フィールド上に表側表示で存在する限り、相手が発動した通常魔法カードの効果は「相手は手札を１枚選んで捨てる」となる。\nこの効果は１ターンに１度しか適用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔轟神ディフ",h:"まごうしんでぃふ",e:"Fabled Dyf",ra:"N",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1400,de:1700,tx:"「魔轟神」と名のついたモンスターが手札から自分の墓地へ送られた時、フィールド上のこのカードをリリースし、そのモンスター１体を選択して発動できる。\n選択したモンスターを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔轟神トピー",h:"まごうしんとぴー",e:"Fabled Topi",ra:"N",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:500,de:200,tx:"自分の手札が相手の手札より２枚以上少ない場合、手札の「魔轟神」と名のついたモンスター１体を相手に見せ、フィールド上のこのカードをリリースして発動できる。\n相手フィールド上の魔法・罠カード２枚を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔轟神ミーズトージ",h:"まごうしんみーずとーじ",e:"Fabled Miztoji",ra:"N",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:400,de:200,tx:"このカードを手札から墓地へ送り、チューナー以外の自分フィールド上の「魔轟神」と名のついたモンスター１体を選択して発動できる。\n選択したモンスターはフィールド上に表側表示で存在する限りチューナーとして扱う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔轟神ルリー",h:"まごうしんるりー",e:"Fabled Lurrie",ra:"R",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:200,de:400,tx:"このカードが手札から墓地へ捨てられた時、このカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔轟神レイヴン",h:"まごうしんれいう゛ん",e:"Fabled Raven",ra:"SR",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:1300,de:1000,tx:"１ターンに１度、自分のメインフェイズ時に発動できる。\n自分の手札を任意の枚数捨て、その枚数分このカードのレベルをエンドフェイズ時まで上げる。\nこのカードの攻撃力はエンドフェイズ時まで、この効果によって捨てた手札の枚数×４００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔轟神レイジオン",h:"まごうしんれいじおん",e:"Fabled Ragin",ra:"SR",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"悪魔族",mo:["シンクロ","効果"],ma:"",tp:"",at:2300,de:1800,tx:"「魔轟神」チューナー＋チューナー以外のモンスター１体以上\n①：このカードがS召喚に成功した時に発動できる。\n自分は手札が２枚になるようにデッキからドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔轟神レヴュアタン",h:"まごうしんれう゛ゅあたん",e:"Fabled Leviathan",ra:"R",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"悪魔族",mo:["シンクロ","効果"],ma:"",tp:"",at:3000,de:2000,tx:"「魔轟神」と名のついたチューナー＋チューナー以外のモンスター１体以上\nフィールド上のこのカードが破壊され墓地へ送られた時、自分の墓地の「魔轟神」と名のついたモンスターを３体まで選択して発動できる。\n選択したモンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マザー・スパイダー",h:"まざー・すぱいだー",e:"Mother Spider",ra:"R",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:2300,de:1200,tx:"自分の墓地に存在するモンスターが昆虫族のみの場合、このカードは相手フィールド上に表側守備表示で存在するモンスター２体を墓地へ送り、手札から特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マザー・ブレイン",h:"まざー・ぶれいん",e:"Cranium Fish",ra:"N",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:2400,de:1000,tx:"このカードは自分フィールド上に存在する「オーシャンズ・オーパー」１体をリリースし、手札から特殊召喚する事ができる。\n手札から水属性モンスター１体を墓地に捨てる事で、フィールド上にセットされたカード１枚を破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マジカル・アンダーテイカー",h:"まじかる・あんだーていかー",e:"Magical Undertaker",ra:"R",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["リバース","効果"],ma:"",tp:"",at:400,de:400,tx:"①：このカードがリバースした場合、自分の墓地のレベル４以下の魔法使い族モンスター１体を対象として発動できる。\nその魔法使い族モンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マジカル・アンドロイド",h:"まじかる・あんどろいど",e:"Magical Android",ra:"R",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"サイキック族",mo:["シンクロ","効果"],ma:"",tp:"",at:2400,de:1700,tx:"チューナー＋チューナー以外のモンスター１体以上\n自分のエンドフェイズ時、自分フィールド上のサイキック族モンスター１体につき、自分は６００ライフポイント回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マジカル・ゴースト",h:"まじかる・ごーすと",e:"Magical Ghost",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"アンデット族",mo:["通常"],ma:"",tp:"",at:1300,de:1400,tx:"相手に魔法をかけて、恐怖と混乱におとしいれ攻撃する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マジカル・コンダクター",h:"まじかる・こんだくたー",e:"Magical Exemplar",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1700,de:1400,tx:"①：このカードがモンスターゾーンに存在する限り、自分または相手が魔法カードを発動する度に、このカードに魔力カウンターを２つ置く。\n②：１ターンに１度、このカードの魔力カウンターを任意の数だけ取り除いて発動できる。\n取り除いた数と同じレベルを持つ魔法使い族モンスター１体を、自分の手札・墓地から選んで特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マジカル・ハウンド",h:"まじかる・はうんど",e:"Magical Hound",ra:"SR",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:500,de:800,tx:"このカード名の効果はデュエル中に１度しか使用できない。\n①：このカードが手札・墓地に存在する場合、相手フィールドの表側表示の魔法・罠カード１枚を対象として発動できる。\nそのカードを持ち主の手札に戻し、このカードを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔法の操り人形",h:"まじかる・まりおねっと",e:"Magical Marionette",ra:"R",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2000,de:1000,tx:"このカードがフィールド上に表側表示で存在する限り、自分または相手が魔法カードを発動する度に、このカードに魔力カウンターを１つ置く。\nこのカードに乗っている魔力カウンター１つにつき、このカードの攻撃力は２００ポイントアップする。\nまた、このカードに乗っている魔力カウンターを２つ取り除く事で、フィールド上に存在するモンスター１体を破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マジカルフィシアリスト",h:"まじかるふぃしありすと",e:"Frequency Magician",ra:"SR",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:400,tx:"このカードが召喚に成功した時、このカードに魔力カウンターを１つ置く（最大１つまで）。\nまた、自分のメインフェイズ時にこのカードに乗っている魔力カウンターを１つ取り除く事で、自分フィールド上のモンスター１体を選択してその攻撃力をエンドフェイズ時まで５００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マジキャット",h:"まじきゃっと",e:"Magicat",ra:"SR",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:600,de:500,tx:"このカードが魔法使い族モンスターのシンクロ召喚に使用され墓地へ送られた場合、自分の墓地に存在する魔法カード１枚をデッキの一番上に戻す事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"本気ギレパンダ",h:"まじぎれぱんだ",e:"Maji-Gire Panda",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1000,de:800,tx:"このカードがフィールド上に表側表示で存在する限り、フィールド上の獣族モンスターが破壊される度にこのカードの攻撃力は５００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マジシャン・オブ・カオス",h:"まじしゃん・おぶ・かおす",e:"Magician of Chaos",ra:"UR",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"魔法使い族",mo:["儀式","効果"],ma:"",tp:"",at:2500,de:2100,tx:"「カオス・フォーム」により降臨。\n①：このカードのカード名は、フィールド・墓地に存在する限り「ブラック・マジシャン」として扱う。\n②：１ターンに１度、魔法・罠カードの効果が発動した時、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\n③：儀式召喚したこのカードが戦闘・効果で破壊された場合に発動できる。\n手札から「マジシャン・オブ・カオス」以外の「カオス」儀式モンスター１体を召喚条件を無視して特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マジシャン・オブ・ブラック・イリュージョン",h:"まじしゃん・おぶ・ぶらっく・いりゅーじょん",e:"Magician of Dark Illusion",ra:"UR",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2100,de:2500,tx:"このカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分が相手ターンに魔法・罠カードの効果を発動した場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードはモンスターゾーンに存在する限り、カード名を「ブラック・マジシャン」として扱う。\n③：このカードがフィールドに表側表示で存在する限り１度だけ、自分が魔法・罠カードの効果を発動した場合に自分の墓地の「ブラック・マジシャン」１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マジシャン・オブ・ブラックカオス",h:"まじしゃん・おぶ・ぶらっくかおす",e:"Magician of Black Chaos",ra:"R",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"魔法使い族",mo:["儀式"],ma:"",tp:"",at:2800,de:2600,tx:"「カオス－黒魔術の儀式」により降臨。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マジシャンズ・ヴァルキリア",h:"まじしゃんず・う゛ぁるきりあ",e:"Magician's Valkyria",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1600,de:1800,tx:"①：このカードがモンスターゾーンに存在する限り、相手は他の魔法使い族モンスターを攻撃対象にできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マジシャンズ・エイプ",h:"まじしゃんず・えいぷ",e:"Ape Magician",ra:"R",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:800,de:1200,tx:"このカードは特殊召喚できない。\nこのカードがフィールド上に表側攻撃表示で存在する場合、１ターンに１度、手札のモンスター１体を墓地へ送り、相手フィールド上に表側守備表示で存在するモンスター１体を選択して発動する事ができる。\nこのターンのエンドフェイズ時まで、選択したモンスターのコントロールを得る。\nこの効果でコントロールを得たモンスターは、このターン表示形式を変更する事はできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マジシャンズ・ローブ",h:"まじしゃんず・ろーぶ",e:"Magician's Robe",ra:"SR",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:700,de:2000,tx:"「マジシャンズ・ローブ」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手ターンに手札から魔法・罠カード１枚を捨てて発動できる。\nデッキから「ブラック・マジシャン」１体を特殊召喚する。\n②：このカードが墓地に存在する状態で、相手ターンに自分が魔法・罠カードの効果を発動した場合に発動できる。\nこのカードを墓地から特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マジシャンズ・ロッド",h:"まじしゃんず・ろっど",e:"Magician's Rod",ra:"SR",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1600,de:100,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時に発動できる。\n「ブラック・マジシャン」のカード名が記された魔法・罠カード１枚をデッキから手札に加える。\n②：このカードが墓地に存在する状態で、自分が相手ターンに魔法・罠カードの効果を発動した場合、自分フィールドの魔法使い族モンスター１体をリリースして発動できる。\nこのカードを手札に加える。",li:3,lk:[],ta:[],al:[],ocg:true},
  {n:"マジック・ストライカー",h:"まじっく・すとらいかー",e:"Spell Striker",ra:"UR",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:600,de:200,tx:"①：このカードは自分の墓地の魔法カード１枚を除外し、手札から特殊召喚できる。\n②：このカードは直接攻撃できる。\n③：このカードの戦闘で発生する自分への戦闘ダメージは０になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マジック・ストライダー",h:"まじっく・すとらいだー",e:"Spell Strider",ra:"UR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:600,tx:"①：このカードが手札に存在する場合、自分及び相手フィールドの表側表示の魔法カードを１枚ずつ対象として発動できる。\nそのカードを除外し、このカードを手札から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マジック・スライム",h:"まじっく・すらいむ",e:"Magical Reflect Slime",ra:"R",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["デュアル","効果"],ma:"",tp:"",at:700,de:1200,tx:"このカードは墓地またはフィールド上に表側表示で存在する場合、通常モンスターとして扱う。\nフィールド上に表側表示で存在するこのカードを通常召喚扱いとして再度召喚する事で、このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●このカードが戦闘を行う事によって受けるコントローラーへの戦闘ダメージは相手が受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"黒の魔法神官",h:"まじっく・はいえろふぁんと・おぶ・ぶらっく",e:"Sorcerer of Dark Magic",ra:"UR",t:"monster",a:"闇",l:9,ps:"",pe:"",tr:"魔法使い族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3200,de:2800,tx:"このカードは通常召喚できない。\n自分フィールドのレベル６以上の魔法使い族モンスター２体をリリースした場合のみ特殊召喚できる。\n①：罠カードが発動した時に発動できる。\nこのカードがフィールドに表側表示で存在する場合、その発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マジック・ホール・ゴーレム",h:"まじっく・ほーる・ごーれむ",e:"Magic Hole Golem",ra:"R",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:0,de:2000,tx:"１ターンに１度、自分フィールド上に表側表示で存在するモンスター１体を選択して発動する事ができる。\n選択したモンスターはエンドフェイズ時まで攻撃力が半分になり、このターン相手プレイヤーに直接攻撃する事ができる。\nこの効果を発動するターン、選択したモンスター以外のモンスターは攻撃する事ができない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マジック・ランプ",h:"まじっく・らんぷ",e:"Ancient Lamp",ra:"R",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:900,de:1400,tx:"①：自分メインフェイズに発動できる。\nこのカードがフィールドに表側表示で存在する場合、手札から「ランプの魔精・ラ・ジーン」１体を特殊召喚する。\n②：裏側守備表示のこのカードが相手モンスターに攻撃されたダメージ計算前に、攻撃モンスター以外の相手モンスター１体を対象として発動できる。\n攻撃対象をその相手モンスターに移し替えてダメージ計算を行う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マジック・リアクター・AID",h:"まじっく・りあくたー・えいど",e:"Spell Reactor ・RE",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:900,tx:"１ターンに１度、相手が魔法カードを発動した時に発動できる。\nその魔法カードを破壊し、相手ライフに８００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マジック・リサイクラー",h:"まじっく・りさいくらー",e:"Spell Recycler",ra:"N",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"相手モンスターの攻撃宣言時に墓地のこのカードをゲームから除外し、自分の墓地の魔法カード１枚を選択して発動できる。\n自分のデッキの一番上のカードを墓地へ送り、選択したカードをデッキの一番下に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マジックアブソーバー",h:"まじっくあぶそーばー",e:"Magical Something",ra:"UR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1800,de:1300,tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、自分または相手が魔法カードを発動する度に、このカードに魔力カウンターを１つ置く。\n②：このカードのレベルは、このカードの魔力カウンターの数だけ上がる。\n③：このカードの魔力カウンターを３つ取り除き、自分の墓地の速攻魔法カード１枚を対象として発動できる。\nそのカードを自分の魔法＆罠ゾーンにセットする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔導獣 ケルベロス",h:"まじっくびーすと　けるべろす",e:"Mythical Beast Cerberus",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1400,de:1400,tx:"①：このカードがモンスターゾーンに存在する限り、自分または相手が魔法カードを発動する度に、このカードに魔力カウンターを１つ置く。\n②：このカードの攻撃力は、このカードの魔力カウンターの数×５００アップする。\n③：このカードが戦闘を行ったバトルフェイズ終了時にこのカードの魔力カウンターは全て取り除かれる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マジドッグ",h:"まじどっぐ",e:"Magidog",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"このカードが魔法使い族モンスターのシンクロ召喚に使用され墓地へ送られた場合、自分の墓地に存在するフィールド魔法カード１枚を選択して手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔獣皇帝ガーゼット",h:"まじゅうかいざーがーぜっと",e:"Emperor Maju Garzett",ra:"SR",t:"monster",a:"闇",l:9,ps:"",pe:"",tr:"悪魔族",mo:["特殊召喚","効果"],ma:"",tp:"",at:0,de:0,tx:"このカードは通常召喚できない。\n自分フィールドのモンスター３体をリリースした場合のみ特殊召喚できる。\n①：このカードの攻撃力は、このカードを特殊召喚するためにリリースしたモンスターの元々の攻撃力を合計した数値になる。\n②：このカードがモンスターゾーンに存在する限り、相手はバトルフェイズ中に魔法・罠・モンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔晶龍ジルドラス",h:"ましょうりゅうじるどらす",e:"Mana Dragon Zirnitron",ra:"SR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2200,de:1200,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが手札・墓地に存在し、自分フィールドの魔法・罠カードが相手の効果でフィールドから離れ、墓地へ送られた場合または除外された場合に発動できる。\nこのカードを特殊召喚する。\nその後、自分の墓地のカード及び除外されている自分のカードの中から、魔法・罠カード１枚を選んで自分の魔法＆罠ゾーンにセットできる。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔人 ダーク・バルター",h:"まじん　だーく・ばるたー",e:"Dark Balter the Terrible",ra:"R",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"悪魔族",mo:["融合","効果"],ma:"",tp:"",at:2000,de:1200,tx:"「憑依するブラッド・ソウル」＋「辺境の大賢者」\nこのカードの融合召喚は上記のカードでしか行えない。\n通常魔法カードが発動した時、１０００ライフポイントを払う事でその効果を無効にする。\nまた、このカードが戦闘で破壊した効果モンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔人 テラ",h:"まじん　てら",e:"Terra the Terrible",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1200,de:1300,tx:"沼地に住む悪魔の手先。\n見た目ほど強くないが油断は禁物。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マシン・アタッカー",h:"ましん・あたっかー",e:"Machine Attacker",ra:"N",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1600,de:1300,tx:"特攻用に作り出した機械。\n突撃で敵をなぎ倒していく。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔神アーク・マキナ",h:"ましんあーく・まきな",e:"Mekanikal Arkfiend",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:100,de:2100,tx:"①：このカードが相手に戦闘ダメージを与えた時に発動できる。\n自分の手札・墓地から通常モンスター１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔人銃",h:"まじんがん",e:"Madjinn Gunn",ra:"N",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:600,de:800,tx:"口から弾を発射して攻撃する生物兵器。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔人デスサタン",h:"まじんですさたん",e:"Witty Phantom",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1400,de:1300,tx:"闇にとけ込む黒のタキシードに身をつつんだ、死を司る悪魔。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マシンナーズ・カノン",h:"ましんなーず・かのん",e:"Machina Cannon",ra:"R",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"機械族",mo:["特殊召喚","効果"],ma:"",tp:"",at:0,de:2200,tx:"このカードは通常召喚できない。\nこのカード以外の手札の機械族モンスターを任意の枚数墓地へ送った場合に特殊召喚できる。\n①：このカードの攻撃力は、このカードを特殊召喚するために墓地へ送ったモンスターの数×８００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マシンナーズ・ギアフレーム",h:"ましんなーず・ぎあふれーむ",e:"Machina Gearframe",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["ユニオン","効果"],ma:"",tp:"",at:1800,de:0,tx:"①：このカードが召喚に成功した時に発動できる。\nデッキから「マシンナーズ・ギアフレーム」以外の「マシンナーズ」モンスター１体を手札に加える。\n②：１ターンに１度、以下の効果から１つを選択して発動できる。\n●自分フィールドの機械族モンスター１体を対象とし、このカードを装備カード扱いとしてそのモンスターに装備する。\n装備モンスターが戦闘・効果で破壊される場合、代わりにこのカードを破壊する。\n●装備されているこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マシンナーズ・スナイパー",h:"ましんなーず・すないぱー",e:"Machina Sniper",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1800,de:800,tx:"①：このカードがモンスターゾーンに存在する限り、お互いに「マシンナーズ・スナイパー」以外の「マシンナーズ」モンスターを攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マシンナーズ・ソルジャー",h:"ましんなーず・そるじゃー",e:"Machina Soldier",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1600,de:1500,tx:"①：自分フィールドに他のモンスターが存在せず、このカードが召喚に成功した時に発動できる。\n手札から「マシンナーズ・ソルジャー」以外の「マシンナーズ」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マシンナーズ・ピースキーパー",h:"ましんなーず・ぴーすきーぱー",e:"Machina Peacekeeper",ra:"SR",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"機械族",mo:["ユニオン","効果"],ma:"",tp:"",at:500,de:400,tx:"①：１ターンに１度、以下の効果から１つを選択して発動できる。\n●自分フィールドの機械族モンスター１体を対象とし、このカードを装備カード扱いとしてそのモンスターに装備する。\n装備モンスターが戦闘・効果で破壊される場合、代わりにこのカードを破壊する。\n●装備されているこのカードを特殊召喚する。\n②：フィールドのこのカードが破壊され墓地へ送られた時に発動できる。\nデッキからユニオンモンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マシンナーズ・フォートレス",h:"ましんなーず・ふぉーとれす",e:"Machina Fortress",ra:"UR",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2500,de:1600,tx:"①：このカードはレベルの合計が８以上になるように手札の機械族モンスターを捨てて、手札・墓地から特殊召喚できる（自身を捨てた場合、墓地から特殊召喚する）。\n②：このカードがモンスターゾーンに存在する限り、このカードを対象として発動した相手モンスターの効果が適用される際に、相手の手札を確認し、その中からカード１枚を選んで捨てる。\n③：このカードが戦闘で破壊され墓地へ送られた場合、相手フィールドのカード１枚を対象として発動する。\nその相手のカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マシンナーズ・メガフォーム",h:"ましんなーず・めがふぉーむ",e:"Machina Megaform",ra:"R",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2600,de:1500,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードをリリースして発動できる。\n手札・デッキから「マシンナーズ・メガフォーム」以外の「マシンナーズ」モンスター１体を特殊召喚する。\n②：このカードが墓地に存在する状態で、自分フィールドの「マシンナーズ・フォートレス」が自分の墓地へ送られた場合、その「マシンナーズ・フォートレス」１体を墓地から除外して発動できる。\nこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マスクド・チョッパー",h:"ますくど・ちょっぱー",e:"Masked Chopper",ra:"N",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"このカードが戦闘によって相手モンスターを破壊した場合、相手ライフに２０００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"仮面竜",h:"ますくど・どらごん",e:"Masked Dragon",ra:"R",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1400,de:1100,tx:"①：このカードが戦闘で破壊され墓地へ送られた時に発動できる。\nデッキから攻撃力１５００以下のドラゴン族モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"M・HERO 闇鬼",h:"ますくどひーろー　あんき",e:"Masked HERO Anki",ra:"UR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2800,de:1200,tx:"このカードは「マスク・チェンジ」の効果でのみ特殊召喚できる。\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードは直接攻撃できる。\nその直接攻撃で相手に与える戦闘ダメージは半分になる。\n②：このカードが戦闘で相手モンスターを破壊し墓地へ送った時に発動できる。\nデッキから「チェンジ」速攻魔法カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"M・HERO ヴェイパー",h:"ますくどひーろー　う゛ぇいぱー",e:"Masked HERO Vapor",ra:"R",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2400,de:2000,tx:"このカードは「マスク・チェンジ」の効果でのみ特殊召喚できる。\nこのカードはカードの効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"M・HERO 光牙",h:"ますくどひーろー　こうが",e:"Masked HERO Koga",ra:"UR",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2500,de:1800,tx:"このカードは「マスク・チェンジ」の効果でのみ特殊召喚できる。\n①：このカードの攻撃力は相手フィールドのモンスターの数×５００アップする。\n②：１ターンに１度、自分の墓地の「HERO」モンスター１体を除外し、フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで、この効果を発動するために除外したモンスターの攻撃力分ダウンする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"M・HERO 剛火",h:"ますくどひーろー　ごうか",e:"Masked HERO Goka",ra:"R",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2200,de:1800,tx:"このカードは「マスク・チェンジ」の効果でのみ特殊召喚できる。\nこのカードの攻撃力は、自分の墓地の「HERO」と名のついたモンスターの数×１００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"M・HERO ダイアン",h:"ますくどひーろー　だいあん",e:"Masked HERO Dian",ra:"UR",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2800,de:3000,tx:"このカードは「マスク・チェンジ」の効果でのみ特殊召喚できる。\n①：このカードが戦闘で相手モンスターを破壊し墓地へ送った時に発動できる。\nデッキからレベル４以下の「HERO」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"M・HERO ブラスト",h:"ますくどひーろー　ぶらすと",e:"Masked HERO Blast",ra:"UR",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2200,de:1800,tx:"このカードは「マスク・チェンジ」の効果でのみ特殊召喚できる。\n①：このカードが特殊召喚に成功した場合、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力を半分にする。\n②：１ターンに１度、５００LPを払い、相手フィールドの魔法・罠カード１枚を対象として発動できる。\nそのカードを持ち主の手札に戻す。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マスター・アン・エキスパート",h:"ますたー・あん・えきすぱーと",e:"Master & Expert",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:1200,de:1000,tx:"けもの使いの達人と、主人に忠実なけもの。\nコンビは完璧。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マスター・オブ・OZ",h:"ますたー・おぶ・おーじー",e:"Master of Oz",ra:"UR",t:"monster",a:"地",l:9,ps:"",pe:"",tr:"獣族",mo:["融合"],ma:"",tp:"",at:4200,de:3700,tx:"「ビッグ・コアラ」＋「デス・カンガルー」",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"究極竜騎士",h:"ますたー・おぶ・どらごんないと",e:"Dragon Master Knight",ra:"UR",t:"monster",a:"光",l:12,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:5000,de:5000,tx:"「カオス・ソルジャー」＋「青眼の究極竜」 \nこのカードは融合召喚でしか特殊召喚できない。\n①：このカードの攻撃力は、このカード以外の自分フィールドのドラゴン族モンスターの数×５００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マスター・ジーグ",h:"ますたー・じーぐ",e:"Master Gig",ra:"UR",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:2600,de:1400,tx:"１０００ライフポイントを払って発動する。\n自分フィールド上に表側表示で存在するサイキック族モンスターの数だけ、相手フィールド上に存在するモンスターを破壊する。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マスター・ヒュペリオン",h:"ますたー・ひゅぺりおん",e:"Master Hyperion",ra:"UR",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2700,de:2100,tx:"①：このカードは自分の手札・フィールド・墓地の「代行者」モンスター１体を除外し、手札から特殊召喚できる。\n②：１ターンに１度、自分の墓地から天使族・光属性モンスター１体を除外し、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\nフィールドに「天空の聖域」が存在する場合、この効果は１ターンに２度まで使用できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マスターモンク",h:"ますたーもんく",e:"Master Monk",ra:"R",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"岩石族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1900,de:1000,tx:"このカードは通常召喚できない。\n自分フィールド上に存在する「モンク・ファイター」１体を生け贄に捧げた場合のみ特殊召喚する事ができる。\nこのカードは１ターンに２回攻撃をする事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔聖騎士皇ランスロット",h:"ませいきしおうらんすろっと",e:"Ignoble Knight of High Laundsallyn",ra:"R",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2100,de:900,tx:"チューナー＋チューナー以外の「聖騎士」モンスター１体以上\n①：「魔聖騎士皇ランスロット」は自分フィールドに１体しか表側表示で存在できない。\n②：このカードがS召喚に成功した時に発動できる。\nデッキから「聖剣」装備魔法カード１枚を選んでこのカードに装備する。\n③：このカードが戦闘でモンスターを破壊し墓地へ送ったバトルフェイズ終了時に発動できる。\nデッキから「聖騎士」カードまたは「聖剣」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔聖騎士ランスロット",h:"ませいきしらんすろっと",e:"Ignoble Knight of Black Laundsallyn",ra:"R",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2000,de:800,tx:"自分フィールド上に表側表示で存在する光属性の通常モンスター１体を墓地へ送って発動できる。\nこのカードを手札または墓地から特殊召喚する。\nまた、自分フィールド上の「聖騎士」と名のついたモンスター１体をリリースして発動できる。\nデッキから「聖剣」と名のついたカード１枚を手札に加える。\n「魔聖騎士ランスロット」のこの効果は１ターンに１度しか使用できない。\nまた、「魔聖騎士ランスロット」は自分フィールド上に１体しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔石術師 クルード",h:"ませきじゅつし　くるーど",e:"Spellstone Sorcerer Karood",ra:"R",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:800,de:1900,tx:"１ターンに１度、このカード以外のモンスターの効果が発動する度に、このカードに魔石カウンターを１つ置く（最大１つまで）。\nこのカードに乗っている魔石カウンター１つにつき、このカードの守備力は３００ポイントアップする。\nまた、１ターンに１度、自分フィールド上に存在する魔石カウンターを１つ取り除く事で、相手の墓地に存在するカード１枚を選択してゲームから除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔草 マンドラゴラ",h:"まそう　まんどらごら",e:"Magical Plant Mandragola",ra:"N",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["リバース","効果"],ma:"",tp:"",at:500,de:200,tx:"リバース：フィールド上に表側表示で存在する魔力カウンターを置く事ができるカード全てに魔力カウンターを１つ置く。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔装機関車 デコイチ",h:"まそうきかんしゃ　でこいち",e:"Dekoichi the Battlechanted Locomotive",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["リバース","効果"],ma:"",tp:"",at:1400,de:1000,tx:"リバース：カードを１枚ドローする。\n自分フィールド上に「魔貨物車両 ボコイチ」が表側表示で存在する場合、さらにその枚数分カードをドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔装騎士ドラゴネス",h:"まそうきしどらごねす",e:"Dragoness the Wicked Knight",ra:"N",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"戦士族",mo:["融合"],ma:"",tp:"",at:1200,de:900,tx:"「アーメイル」＋「一眼の盾竜」",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔装邪龍 イーサルウェポン",h:"まそうじゃりゅう　いーさるうぇぽん",e:"Aether, the Evil Empowering Dragon",ra:"SR",t:"monster",a:"闇",l:6,ps:4,pe:"①：１ターンに１度、自分の墓地の「魔装戦士」モンスター１体を除外し、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",tr:"ドラゴン族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2300,de:1600,tx:"①：このカードが召喚・特殊召喚に成功した時、フィールドのモンスター１体を対象として発動できる。\nそのモンスターを除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔装聖龍 イーサルウェポン",h:"まそうせいりゅう　いーさるうぇぽん",e:"Aether, the Empowering Dragon",ra:"SR",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2300,de:1600,tx:"「魔装聖龍 イーサルウェポン」の効果は１ターンに１度しか使用できない。\n①：このカードがP召喚に成功した時、フィールドのカード１枚を対象として発動できる。\nそのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔装戦士 アルニス",h:"まそうせんし　あるにす",e:"Arnis, the Empowered Warrior",ra:"R",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:1200,tx:"①：このカードが相手モンスターの攻撃で破壊され墓地へ送られた時に発動できる。\nデッキから攻撃力１５００以下の魔法使い族モンスター１体を攻撃表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔装戦士 ヴァンドラ",h:"まそうせんし　う゛ぁんどら",e:"Ventdra, the Empowered Warrior",ra:"UR",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2000,de:800,tx:"①：このカードは直接攻撃できる。\n②：このカードがフィールドから墓地へ送られた場合、自分の墓地のドラゴン族・戦士族・魔法使い族の通常モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔装戦士 テライガー",h:"まそうせんし　てらいがー",e:"Terratiger, the Empowered Warrior",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1200,tx:"①：このカードが召喚に成功した時に発動できる。\n手札からレベル４以下の通常モンスター１体を守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔装戦士 ハイドロータス",h:"まそうせんし　はいどろーたす",e:"Hydrotortoise, the Empowered Warrior",ra:"SR",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"戦士族",mo:["リバース","効果"],ma:"",tp:"",at:1400,de:900,tx:"①：このカードがリバースした場合、相手フィールドの魔法・罠カード１枚を対象として発動する。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"またたびキャット",h:"またたびきゃっと",e:"Catnipped Kitty",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:0,de:500,tx:"自分フィールド上にこのカード以外の獣族モンスターが存在する場合、このカードを攻撃する事はできない。\n相手フィールド上に存在するモンスター１体の守備力をエンドフェイズ時まで０にする。\nこの効果は１ターンに１度だけ使用する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マタンゴ",h:"またんご",e:"Mushroom Man #2",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1250,de:800,tx:"自分のスタンバイフェイズ毎に、コントローラーに３００ポイントダメージを与える。\n自分のエンドフェイズに５００ライフポイントを払えば、このカードのコントロールは相手に移る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マックス・ウォリアー",h:"まっくす・うぉりあー",e:"Max Warrior",ra:"R",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:800,tx:"このカードは相手モンスターに攻撃する場合、ダメージステップの間攻撃力が４００ポイントアップする。\nこのカードが戦闘によって相手モンスターを破壊した場合、次の自分のスタンバイフェイズ時までこのカードのレベルは２になり、元々の攻撃力・守備力は半分になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マックス・テレポーター",h:"まっくす・てれぽーたー",e:"Overdrive Teleporter",ra:"SR",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:2100,de:1200,tx:"このカードは特殊召喚できない。\n２０００ライフポイントを払う事で、自分のデッキからレベル３のサイキック族モンスター２体を自分フィールド上に特殊召喚する。\nこの効果はこのカードがフィールド上に表側表示で存在する限り１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マッシブ・ウォリアー",h:"まっしぶ・うぉりあー",e:"Fortress Warrior",ra:"SR",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:600,de:1200,tx:"このカードの戦闘によって発生する自分への戦闘ダメージは０になる。\nこのカードは１ターンに１度だけ、戦闘では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔筒覗ベイオネーター",h:"まづつしべいおねーたー",e:"Bayonater, the Baneful Barrel",ra:"R",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:500,de:0,tx:"①：１ターンに１度、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力は相手フィールドのモンスターの数×１０００ダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マッド・デーモン",h:"まっど・でーもん",e:"Mad Archfiend",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1800,de:0,tx:"①：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。\n②：攻撃表示のこのカードが攻撃対象に選択された場合に発動する。\nこのカードを守備表示にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マッド・リローダー",h:"まっど・りろーだー",e:"Mad Reloader",ra:"N",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"①：このカードが戦闘で破壊され墓地へ送られた場合に発動する。\n手札を２枚選んで墓地へ送り、自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マツボックル",h:"まつぼっくる",e:"Pinecono",ra:"N",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:400,de:200,tx:"このカードが「コロボックリ」の効果によって墓地へ送られた場合、このカードを墓地から特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔帝アングマール",h:"まていあんぐまーる",e:"Angmarl the Fiendish Monarch",ra:"UR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2400,de:1000,tx:"①：このカードがアドバンス召喚に成功した時、自分の墓地の魔法カード１枚を除外して発動できる。\n除外したその魔法カードの同名カード１枚をデッキから手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マテリアルドラゴン",h:"まてりあるどらごん",e:"Prime Material Dragon",ra:"UR",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2400,de:2000,tx:"このカードがフィールド上に表側表示で存在する限り、ライフポイントにダメージを与える効果は、ライフポイントを回復する効果になる。\nまた、「フィールド上のモンスターを破壊する効果」を持つ魔法・罠・効果モンスターの効果が発動した時、手札を１枚墓地へ送る事でその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マテリアルファルコ",h:"まてりあるふぁるこ",e:"Prime Material Falcon",ra:"R",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2200,de:800,tx:"「フィールド上の魔法・罠カードを破壊する効果」を持つ効果モンスターの効果が発動した時、手札を１枚墓地へ送る事でその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔天使ローズ・ソーサラー",h:"まてんしろーず・そーさらー",e:"Fallen Angel of Roses",ra:"R",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:2400,de:1300,tx:"このカードは「魔天使ローズ・ソーサラー」以外の自分フィールド上に表側表示で存在する植物族モンスター１体を手札に戻し、手札から特殊召喚する事ができる。\nこの方法で特殊召喚したこのカードは、フィールド上から離れた場合ゲームから除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔天老",h:"まてんろう",e:"Ancient Brain",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1000,de:700,tx:"天界から追放された堕天使。\n闇での闘いに優れている。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔導アーマー エグゼ",h:"まどうあーまー　えぐぜ",e:"Armor Exe",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:2400,de:1400,tx:"このカードは召喚・反転召喚・特殊召喚したターンには攻撃できない。\n自分と相手のスタンバイフェイズ毎に、自分フィールド上の魔力カウンターを１個取り除く。\n取り除かない場合はこのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔導ギガサイバー",h:"まどうぎがさいばー",e:"The Fiend Megacyber",ra:"UR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2200,de:1200,tx:"①：自分フィールドのモンスターの数が相手フィールドのモンスターより２体以上少ない場合、このカードは手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔導鬼士 ディアール",h:"まどうきし　でぃあーる",e:"Prophecy Destroyer",ra:"R",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2500,de:1200,tx:"このカードが墓地に存在する場合、自分の墓地の「魔導書」と名のついた魔法カード３枚をゲームから除外して発動できる。\nこのカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔導騎士 ディフェンダー",h:"まどうきし　でぃふぇんだー",e:"Defender, the Magical Knight",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1600,de:2000,tx:"①：このカードが召喚に成功した場合に発動する。\nこのカードに魔力カウンターを１つ置く（最大１つまで）。\n②：１ターンに１度、フィールドの魔法使い族モンスターが破壊される場合、代わりにその数だけ自分フィールドの魔力カウンターを取り除く事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔道騎士ガイア",h:"まどうきしがいあ",e:"Gaia the Magical Knight",ra:"SR",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2300,de:2100,tx:"このカード名はルール上「暗黒騎士ガイア」カードとしても扱う。\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドにモンスターが存在しない場合、または相手フィールドに攻撃力２３００以上のモンスターが存在する場合、このカードはリリースなしで召喚できる。\n②：このカードが召喚・特殊召喚に成功した場合に発動できる。\n自分の手札・墓地からドラゴン族・レベル５モンスター１体を選んで守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔導騎士ギルティア",h:"まどうきしぎるてぃあ",e:"Giltia the D. Knight",ra:"R",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"戦士族",mo:["融合"],ma:"",tp:"",at:1850,de:1500,tx:"「冥界の番人」＋「王座の守護者」",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔導弓士 ラムール",h:"まどうきゅうし　らむーる",e:"Amores of Prophecy",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:600,de:2000,tx:"１ターンに１度、手札の「魔導書」と名のついた魔法カード１枚を相手に見せて発動できる。\n手札からレベル４以下の魔法使い族モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔導教士 システィ",h:"まどうきょうし　しすてぃ",e:"Justice of Prophecy",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1600,de:800,tx:"自分が「魔導書」と名のついた魔法カードを発動した自分のターンのエンドフェイズ時、フィールド上のこのカードをゲームから除外して発動できる。\nデッキから光属性または闇属性の魔法使い族・レベル５以上のモンスター１体と、「魔導書」と名のついた魔法カード１枚を手札に加える。\n「魔導教士 システィ」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔導化士 マット",h:"まどうけし　まっと",e:"Fool of Prophecy",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1600,de:900,tx:"１ターンに１度、デッキから「魔導書」と名のついた魔法カード１枚を墓地へ送る事ができる。\nこの効果を発動したターンのエンドフェイズ時、自分の墓地の「魔導書」と名のついた魔法カードが５種類以上の場合、このカードをリリースする事で、デッキから魔法使い族・闇属性・レベル５以上のモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔道化リジョン",h:"まどうけりじょん",e:"Legion the Fiend Jester",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1300,de:1500,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、自分は通常召喚に加えて１度だけ、自分メインフェイズに魔法使い族モンスター１体を表側攻撃表示でアドバンス召喚できる。\n②：このカードがフィールドから墓地へ送られた場合に発動できる。\n自分のデッキ・墓地から魔法使い族の通常モンスター１体を選んで手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔導剣士 シャリオ",h:"まどうけんし　しゃりお",e:"Charioteer of Prophecy",ra:"R",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1800,de:1300,tx:"１ターンに１度、手札から「魔導書」と名のついた魔法カードを１枚捨てて発動できる。\n自分の墓地の魔法使い族モンスター１体を選択して手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔導雑貨商人",h:"まどうざっかしょうにん",e:"Magical Merchant",ra:"N",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"昆虫族",mo:["リバース","効果"],ma:"",tp:"",at:200,de:700,tx:"①：このカードがリバースした場合に発動する。\n魔法・罠カードが出るまで自分のデッキの上からカードをめくり、その魔法・罠カードを手札に加える。\n残りのめくったカードは全て墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔導獣士 ルード",h:"まどうじゅうし　るーど",e:"Wheel of Prophecy",ra:"N",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2700,de:1700,tx:"このカードが魔法使い族モンスターの効果によって特殊召喚に成功した時、ゲームから除外されている自分の「魔導書」と名のついた魔法カードを任意の数だけ選択して発動できる。\n選択したカードをデッキに戻し、残りの「魔導書」と名のついた魔法カードを墓地に戻す。\n「魔導獣士 ルード」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔導術士 ラパンデ",h:"まどうじゅつし　らぱんで",e:"Stoic of Prophecy",ra:"N",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:300,de:200,tx:"このカードが墓地へ送られた時、デッキからレベル３の「魔導」と名のついたモンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔導召喚士 テンペル",h:"まどうしょうかんし　てんぺる",e:"Temperance of Prophecy",ra:"SR",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"自分が「魔導書」と名のついた魔法カードを発動した自分のターンのメインフェイズ時、このカードをリリースして発動できる。\nデッキから光属性または闇属性の魔法使い族・レベル５以上のモンスター１体を特殊召喚する。\nこの効果を発動するターン、自分は他のレベル５以上のモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔導書士 バテル",h:"まどうしょし　ばてる",e:"Spellbook Magician of Prophecy",ra:"SR",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:500,de:400,tx:"①：このカードが召喚・リバースした場合に発動する。\nデッキから「魔導書」魔法カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔導紳士－J",h:"まどうしんし－Ｊ",e:"The Illusory Gentleman",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:1500,de:1600,tx:"奇抜な変装に身を包んで、気まぐれに悪事を働いたり人助けをしたりする。\n「美」にはうるさい。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔導戦士 フォルス",h:"まどうせんし　ふぉるす",e:"Strength of Prophecy",ra:"N",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1500,de:1400,tx:"１ターンに１度、自分の墓地の「魔導書」と名のついた魔法カード１枚をデッキに戻し、フィールド上の魔法使い族モンスター１体を選択して発動できる。\n選択したモンスターのレベルを１つ上げ、攻撃力を５００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔導戦士 ブレイカー",h:"まどうせんし　ぶれいかー",e:"Breaker the Magical Warrior",ra:"UR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"①：このカードが召喚に成功した場合に発動する。\nこのカードに魔力カウンターを１つ置く（最大１つまで）。\n②：このカードの攻撃力は、このカードの魔力カウンターの数×３００アップする。\n③：このカードの魔力カウンターを１つ取り除き、フィールドの魔法・罠カード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔導天士 トールモンド",h:"まどうてんし　とーるもんど",e:"World of Prophecy",ra:"R",t:"monster",a:"光",l:9,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2900,de:2400,tx:"このカードが魔法使い族モンスターまたは「魔導書」と名のついた魔法カードの効果によって特殊召喚に成功した時、自分の墓地の「魔導書」と名のついた魔法カード２枚を選択して手札に加える事ができる。\nこの効果を発動するターン、自分は他のモンスターを特殊召喚できない。\nこの効果でカードを手札に加えた時、手札の「魔導書」と名のついた魔法カード４種類を相手に見せて発動できる。\nこのカード以外のフィールド上のカードを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔導法士 ジュノン",h:"まどうほうし　じゅのん",e:"High Priestess of Prophecy",ra:"UR",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2500,de:2100,tx:"①：手札の「魔導書」魔法カード３枚を相手に見せて発動できる。\nこのカードを手札から特殊召喚する。\n②：１ターンに１度、自分の手札・墓地から「魔導書」魔法カード１枚を除外し、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔導冥士 ラモール",h:"まどうめいし　らもーる",e:"Reaper of Prophecy",ra:"R",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2000,de:1600,tx:"このカードが召喚・特殊召喚に成功した時に発動できる。\n自分の墓地の「魔導書」と名のついた魔法カードの種類によって以下の効果を適用する。\n「魔導冥士 ラモール」の効果は１ターンに１度しか使用できない。\n\n●３種類以上：このカードの攻撃力は６００ポイントアップする。\n\n●４種類以上：デッキから「魔導書」と名のついた魔法カード１枚を手札に加える。\n\n●５種類以上：デッキから魔法使い族・闇属性・レベル５以上のモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔導老士 エアミット",h:"まどうろうし　えあみっと",e:"Hermit of Prophecy",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1200,de:700,tx:"このカードがフィールド上に表側表示で存在する限り、「魔導書」と名のついた魔法カードが発動する度に、このカードのレベルは２つ上がり、攻撃力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔頭を持つ邪竜",h:"まとうをもつじゃりゅう",e:"Wicked Dragon with the Ersatz Head",ra:"N",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:900,de:900,tx:"もう一つの頭を持つドラゴン。\n二つの口で敵を噛み砕く。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マドルチェ・クロワンサン",h:"まどるちぇ・くろわんさん",e:"Madolche Cruffssant",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1500,de:1200,tx:"このカードが相手によって破壊され墓地へ送られた時、このカードをデッキに戻す。\nまた、１ターンに１度、このカード以外の自分フィールド上の「マドルチェ」と名のついたカード１枚を選択して発動できる。\n選択したカードを手札に戻し、このカードのレベルを１つ上げ、攻撃力を３００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マドルチェ・シューバリエ",h:"まどるちぇ・しゅーばりえ",e:"Madolche Chouxvalier",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:1300,tx:"このカードが相手によって破壊され墓地へ送られた時、このカードをデッキに戻す。\nまた、このカードがフィールド上に表側表示で存在する限り、「マドルチェ・シューバリエ」以外の「マドルチェ」と名のついたモンスターを相手は攻撃対象に選択できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マドルチェ・バトラスク",h:"まどるちぇ・ばとらすく",e:"Madolche Butlerusk",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1500,de:800,tx:"このカードが相手によって破壊され墓地へ送られた時、このカードをデッキに戻す。\nこのカードが召喚に成功した時、フィールド上にこのカード以外の「マドルチェ」と名のついたモンスターが存在する場合、デッキからフィールド魔法カード１枚を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マドルチェ・ピョコレート",h:"まどるちぇ・ぴょこれーと",e:"Madolche Chickolates",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:800,de:1500,tx:"このカードが相手によって破壊され墓地へ送られた時、このカードをデッキに戻す。\nまた、１ターンに１度、このカードがフィールド上に表側表示で存在し、自分フィールド上の「マドルチェ」と名のついたモンスターの表示形式が変更された時に発動できる。\nフィールド上のモンスター１体を選択して表側守備表示にし、そのモンスターが「マドルチェ」と名のついたモンスター以外だった場合、そのモンスターは攻撃できず、効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マドルチェ・プディンセス",h:"まどるちぇ・ぷでぃんせす",e:"Madolche Puddingcess",ra:"SR",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"①：自分の墓地にモンスターが存在しない場合、このカードの攻撃力・守備力は８００アップする。\n②：このカードが相手モンスターと戦闘を行ったダメージ計算後に、相手フィールドのカード１枚を対象として発動できる。\nその相手のカードを破壊する。\n③：このカードが相手によって破壊され墓地へ送られた場合に発動する。\nこのカードをデッキに戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マドルチェ・ホーットケーキ",h:"まどるちぇ・ほーっとけーき",e:"Madolche Hootcake",ra:"SR",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1500,de:1100,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分の墓地のモンスター１体を対象として発動できる。\nそのモンスターを除外し、デッキから「マドルチェ・ホーットケーキ」以外の「マドルチェ」モンスター１体を特殊召喚する。\n②：このカードが相手によって破壊され墓地へ送られた場合に発動する。\nこのカードをデッキに戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マドルチェ・マーマメイド",h:"まどるちぇ・まーまめいど",e:"Madolche Marmalmaide",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:800,de:2000,tx:"このカードが相手によって破壊され墓地へ送られた時、このカードをデッキに戻す。\nこのカードがリバースした時、自分の墓地の「マドルチェ」と名のついた魔法・罠カード１枚を選択して手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マドルチェ・マジョレーヌ",h:"まどるちぇ・まじょれーぬ",e:"Madolche Magileine",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1400,de:1200,tx:"このカードが相手によって破壊され墓地へ送られた時、このカードをデッキに戻す。\nこのカードが召喚・反転召喚に成功した時、デッキから「マドルチェ」と名のついたモンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マドルチェ・ミィルフィーヤ",h:"まどるちぇ・みぃるふぃーや",e:"Madolche Mewfeuille",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:500,de:300,tx:"このカードが相手によって破壊され墓地へ送られた時、このカードをデッキに戻す。\nこのカードが召喚に成功した時、手札から「マドルチェ」と名のついたモンスター１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マドルチェ・メェプル",h:"まどるちぇ・めぇぷる",e:"Madolche Baaple",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:0,de:1800,tx:"このカードが相手によって破壊され墓地へ送られた時、このカードをデッキに戻す。\n１ターンに１度、自分フィールド上に表側攻撃表示で存在する「マドルチェ」と名のついたモンスター１体と、相手フィールド上に表側攻撃表示で存在するモンスター１体を選択して発動できる。\n選択した２体のモンスターを表側守備表示にし、次の相手ターン終了時まで、選択したモンスターは表示形式を変更できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マドルチェ・メッセンジェラート",h:"まどるちぇ・めっせんじぇらーと",e:"Madolche Messengelato",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"①：このカードが特殊召喚に成功した時に発動できる。\nデッキから「マドルチェ」魔法・罠カード１枚を手札に加える。\nこの効果は自分フィールドに獣族の「マドルチェ」モンスターが存在する場合に発動と処理ができる。\n②：このカードが相手によって破壊され墓地へ送られた場合に発動する。\nこのカードをデッキに戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マハー・ヴァイロ",h:"まはー・う゛ぁいろ",e:"Maha Vailo",ra:"UR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1550,de:1400,tx:"①：このカードの攻撃力は、このカードに装備された装備カードの数×５００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"磨破羅魏",h:"まはらぎ",e:"Maharaghi",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["スピリット","効果"],ma:"",tp:"",at:1200,de:1700,tx:"このカードは特殊召喚できない。\n召喚・リバースしたターンのエンドフェイズ時に持ち主の手札に戻る。\nまた、このカードが召喚・リバースした時に発動する。\n次の自分のドローフェイズのドロー前に自分のデッキの一番上のカードを確認してデッキの一番上または一番下に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マブラス",h:"まぶらす",e:"Mavelus",ra:"N",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["融合"],ma:"",tp:"",at:1300,de:900,tx:"「タイホーン」＋「邪炎の翼」",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔法剣士トランス",h:"まほうけんしとらんす",e:"Trance the Magic Swordsman",ra:"SR",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:2600,de:200,tx:"かなりの実力を持った風変わりな魔法使い。\n異空間の旅から帰還したらしい。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔法剣士ネオ",h:"まほうけんしねお",e:"Neo the Magic Swordsman",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:1700,de:1000,tx:"武術と剣に優れた風変わりな魔法使い。\n異空間を旅している。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔法のランプ",h:"まほうのらんぷ",e:"Mystic Lamp",ra:"N",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:400,de:300,tx:"このカードは相手プレイヤーを直接攻撃する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻のグリフォン",h:"まぼろしのぐりふぉん",e:"Phantom Gryphon",ra:"SR",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["通常"],ma:"",tp:"",at:2000,de:0,tx:"山岳に隠れ棲む伝説のモンスター。\nその翼はひと羽ばたきで嵐を巻き起こすという。\nハーピィとは仲が悪く、狩場を巡って争いが絶えないらしい。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔物の狩人",h:"まもののかりうど",e:"Kojikocy",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1500,de:1200,tx:"人を狩る凶悪な狩人。\n岩をも砕く強い力を持つ。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔妖仙獣 大刃禍是",h:"まようせんじゅう　だいばかぜ",e:"Mayosenju Daibak",ra:"SR",t:"monster",a:"風",l:10,ps:7,pe:"①：自分フィールドの「妖仙獣」モンスターの攻撃宣言時に発動できる。\nその攻撃モンスターの攻撃力はバトルフェイズ終了時まで３００アップする。",tr:"獣族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:3000,de:300,tx:"このカードはP召喚でしか特殊召喚できない。\n①：このカードのP召喚は無効化されない。\n②：このカードが召喚・特殊召喚に成功した場合、フィールドのカードを２枚まで対象として発動できる。\nそのカードを持ち主の手札に戻す。\n③：このカードを特殊召喚したターンのエンドフェイズに発動する。\nこのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔妖仙獣 独眼群主",h:"まようせんじゅう　ひとつめのむらじ",e:"Mayosenju Hitot",ra:"UR",t:"monster",a:"風",l:10,ps:3,pe:"このカード名の①②のP効果はそれぞれ１ターンに１度しか使用できない。\n①：自分のPゾーンの「妖仙獣」カード１枚を対象として発動できる。\nそのPスケールはターン終了時まで１１になる。\nこのターン、自分は「妖仙獣」モンスターしか特殊召喚できない。\n②：自分エンドフェイズに発動する。\nこのカードを持ち主の手札に戻す。",tr:"獣族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2000,de:3000,tx:"このカードはP召喚でしか特殊召喚できない。\n①：このカードが召喚・特殊召喚に成功した場合、相手フィールドのカード１枚を対象として発動できる。\nそのカードを持ち主の手札に戻す。\n②：このカードがモンスターゾーンに存在し、自分のカードの効果によって、このカード以外のフィールドのカードが手札・デッキに戻る度に発動する。\n自分フィールドの全ての「妖仙獣」モンスターの攻撃力は５００アップする。\n③：このカードを特殊召喚したターンのエンドフェイズに発動する。\nこのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マリスボラス・スプーン",h:"まりすぼらす・すぷーん",e:"Malicevorous Spoon",ra:"N",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:100,de:500,tx:"このカードがフィールド上に表側表示で存在する場合に「マリスボラス・スプーン」以外の「マリスボラス」と名のついたモンスターが自分フィールド上に召喚・特殊召喚された時、自分の墓地から悪魔族・レベル２モンスター１体を選択して特殊召喚できる。\nこの効果で特殊召喚したモンスターの効果は無効化される。\n「マリスボラス・スプーン」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マリスボラス・ナイフ",h:"まりすぼらす・ないふ",e:"Malicevorous Knife",ra:"N",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:600,de:100,tx:"このカードが召喚に成功した時、自分の墓地から「マリスボラス・ナイフ」以外の「マリスボラス」と名のついたモンスター１体を選択して特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マリスボラス・フォーク",h:"まりすぼらす・ふぉーく",e:"Malicevorous Fork",ra:"N",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:400,de:400,tx:"自分のメインフェイズ時、手札からこのカード以外の悪魔族モンスター１体を墓地へ送って発動できる。\nこのカードを手札から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔力吸収球体",h:"まりょくきゅうしゅうきゅうたい",e:"Maryokutai",ra:"N",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:900,de:900,tx:"相手が魔法カードを発動した時、自分フィールド上に表側表示で存在するこのカードをリリースする事で、その発動を無効にし破壊する。\nこの効果は相手ターンにのみ発動する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マリン・ビースト",h:"まりん・びーすと",e:"Marine Beast",ra:"N",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"魚族",mo:["融合"],ma:"",tp:"",at:1700,de:1600,tx:"水の魔導師＋ベヒゴン",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マルチ・ピース・ゴーレム",h:"まるち・ぴーす・ごーれむ",e:"Multiple Piece Golem",ra:"UR",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"岩石族",mo:["融合","効果"],ma:"",tp:"",at:2600,de:1300,tx:"「ビッグ・ピース・ゴーレム」＋「ミッド・ピース・ゴーレム」\nこのカードが戦闘を行ったバトルフェイズ終了時にこのカードをエクストラデッキに戻す事ができる。\nさらに、エクストラデッキに戻したこのカードの融合召喚に使用した融合素材モンスター一組が自分の墓地に揃っていれば、この一組を自分フィールド上に特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マンイーター",h:"まんいーたー",e:"Man Eater",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"植物族",mo:["通常"],ma:"",tp:"",at:800,de:600,tx:"人喰い人面花。\n毒のある触手で攻撃してくる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マンドラゴン",h:"まんどらごん",e:"Mandragon",ra:"R",t:"monster",a:"地",l:5,ps:2,pe:"",tr:"植物族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:2500,de:1000,tx:"悲哀に満ちたドラゴンの魂を宿したマンドリン。\n呪いの音色を奏で、聞いたものは恐怖のあまり自我を失ってしまうという。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マンモ・フォッシル",h:"まんも・ふぉっしる",e:"Fossil Tusker",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1800,de:0,tx:"このカードが戦闘によって相手モンスターを破壊し墓地へ送った時、相手ライフに４００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マンモス・ゾンビ",h:"まんもす・ぞんび",e:"Zombie Mammoth",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1900,de:0,tx:"自分の墓地にアンデット族モンスターが存在しない場合、このカードを破壊する。\nフィールド上に表側表示で存在するこのカードが破壊された場合、その時のコントローラーにこのカードの元々の攻撃力分のダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マンモスの墓場",h:"まんもすのはかば",e:"Mammoth Graveyard",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"恐竜族",mo:["通常"],ma:"",tp:"",at:1200,de:800,tx:"仲間のお墓を守るマンモス。\n墓荒らしを容赦なく攻撃。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"万力魔神バイサー・デス",h:"まんりきまじんばいさー・です",e:"Viser Des",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:500,de:1200,tx:"①：このカードの召喚に成功した場合、相手フィールドのモンスター１体を対象として発動する。\n発動後、３回目の自分スタンバイフェイズに対象のモンスターを破壊する。\n②：このカードの①の効果の対象のモンスターがフィールドに存在している限り、このカードは戦闘では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"味方殺しの女騎士",h:"みかたごろしのおんなきし",e:"The Unfriendly Amazon",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2000,de:1000,tx:"自分のスタンバイフェイズ毎に、このカードを除く自分のフィールド上モンスター１体を生け贄に捧げなければ、このカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ミキサーロイド",h:"みきさーろいど",e:"Mixeroid",ra:"R",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:2200,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの機械族モンスター１体をリリースして発動できる。\nデッキから風属性以外の「ロイド」モンスター１体を特殊召喚する。\n②：LPを半分払い、自分の墓地からこのカードを含む機械族モンスターを任意の数だけ除外して発動できる。\n除外したモンスターの数と同じレベルの「ロイド」融合モンスター１体をEXデッキから召喚条件を無視して特殊召喚する。\nこの効果で特殊召喚したモンスターはエンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"未熟な悪魔",h:"みじゅくなあくま",e:"Embryonic Beast",ra:"N",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:500,de:750,tx:"完全体になれなかった醜い悪魔。\nはらの穴は何でも吸い込む。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"Ms.JUDGE",h:"みず・じゃっじ",e:"Ms. Judge",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1800,de:600,tx:"「Ms.JUDGE」の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、相手のカードの効果が発動した場合、その処理を行う時にコイントスを２回行い、２回とも表だった場合、その効果を無効にする。",li:2,lk:[],ta:[],al:[],ocg:true},
  {n:"湖の乙女ヴィヴィアン",h:"みずうみのおとめう゛ぃう゛ぃあん",e:"Lady of the Lake",ra:"N",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"水族",mo:["チューナー","効果"],ma:"",tp:"",at:200,de:1800,tx:"このカードをS素材とする場合、戦士族モンスターのS召喚にしか使用できず、S召喚に使用されたこのカードは除外される。\n①：このカードが召喚に成功した時、自分の墓地の「聖騎士」通常モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n②：このカードが墓地に存在する場合、自分フィールドのレベル５の「聖騎士」モンスター１体を対象として発動できる。\nそのモンスターのレベルを１つ下げ、このカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ミスター・ボンバー",h:"みすたー・ぼんばー",e:"Blast Juggler",ra:"N",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:800,de:900,tx:"自分のスタンバイフェイズ時にのみ発動する事ができる。\nこのカードをリリースする事で、フィールド上に表側表示で存在する攻撃力１０００以下のモンスター２体を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ミスティック・ソードマン Lv2",h:"みすてぃっく・そーどまん　れべる２",e:"Mystic Swordsman LV2",ra:"R",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:900,de:0,tx:"裏側守備表示のモンスターを攻撃した場合、ダメージ計算を行わず裏側守備表示のままそのモンスターを破壊する。\nこのカードがモンスターを戦闘によって破壊したターンのエンドフェイズ時、このカードを墓地に送る事で「ミスティック・ソードマン LV４」１体を手札またはデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ミスティック・ソードマン Lv4",h:"みすてぃっく・そーどまん　れべる４",e:"Mystic Swordsman LV4",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1900,de:1600,tx:"このカードを通常召喚する場合、裏側守備表示でしか出せない。\n裏側守備表示のモンスターを攻撃した場合、ダメージ計算を行わず裏側守備表示のままそのモンスターを破壊する。\nこのカードがモンスターを戦闘によって破壊したターンのエンドフェイズ時、このカードを墓地に送る事で「ミスティック・ソードマン LV６」１体を手札またはデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ミスティック・ソードマン Lv6",h:"みすてぃっく・そーどまん　れべる６",e:"Mystic Swordsman LV6",ra:"R",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2300,de:1700,tx:"このカードを通常召喚する場合、裏側守備表示でしか出せない。\n裏側守備表示のモンスターを攻撃した場合、ダメージ計算を行わず裏側守備表示のままそのモンスターを破壊する。\nこの効果で破壊したモンスターを墓地へ送らず、相手のデッキの一番上に置く事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ミスティック・パイパー",h:"みすてぃっく・ぱいぱー",e:"Mystic Piper",ra:"UR",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカードをリリースして発動する。\n自分のデッキからカードを１枚ドローする。\nこの効果でドローしたカードをお互いに確認し、レベル１モンスターだった場合、自分はカードをもう１枚ドローする。\n「ミスティック・パイパー」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ミスト・ウォーム",h:"みすと・うぉーむ",e:"Mist Wurm",ra:"SR",t:"monster",a:"風",l:9,ps:"",pe:"",tr:"雷族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:1500,tx:"チューナー＋チューナー以外のモンスター２体以上\n①：このカードがS召喚に成功した場合、相手フィールドのカードを３枚まで対象として発動する。\nその相手のカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ミスト・コンドル",h:"みすと・こんどる",e:"Mist Condor",ra:"N",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1400,de:400,tx:"このカードは自分フィールド上に表側表示で存在する「ミスト・バレー」と名のついたモンスター１体を持ち主の手札に戻し、手札から特殊召喚できる。\nこの方法で特殊召喚したこのカードの攻撃力は１７００になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"霞の谷の風使い",h:"みすと・ばれーのかぜつかい",e:"Mist Valley Windmaster",ra:"N",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"鳥獣族",mo:["チューナー","効果"],ma:"",tp:"",at:400,de:800,tx:"１ターンに１度、お互いの手札が５枚以上の場合に発動できる。\nお互いのプレイヤーは手札が４枚になるように手札を墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"霞の谷の祈祷師",h:"みすと・ばれーのきとうし",e:"Mist Valley Shaman",ra:"R",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"鳥獣族",mo:["チューナー","効果"],ma:"",tp:"",at:1200,de:1200,tx:"１ターンに１度、このカード以外の自分フィールド上のモンスター１体を手札に戻して発動できる。\nこのカードの攻撃力はエンドフェイズ時まで５００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"霞の谷の執行者",h:"みすと・ばれーのしっこうしゃ",e:"Mist Valley Executor",ra:"R",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:2100,de:1600,tx:"このカードが召喚に成功した時、フィールド上に表側表示で存在する魔法・罠カードを全て持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"霞の谷の戦士",h:"みすと・ばれーのせんし",e:"Mist Valley Soldier",ra:"SR",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["チューナー","効果"],ma:"",tp:"",at:1700,de:300,tx:"このカードが自分フィールド上に表側表示で存在する限り、このカードとの戦闘で破壊されなかった相手モンスターをダメージステップ終了時に持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"霞の谷のファルコン",h:"みすと・ばれーのふぁるこん",e:"Mist Valley Falcon",ra:"R",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2000,de:1200,tx:"このカードは、このカード以外の自分フィールド上のカード１枚を手札に戻さなければ攻撃宣言できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"霞の谷の見張り番",h:"みすと・ばれーのみはりばん",e:"Mist Valley Watcher",ra:"R",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:1500,de:1900,tx:"霞の谷を代々見張り続ける、見張り番一族の末裔。\n谷で起こる出来事は、どんな些細な事も見逃さない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"霞の谷の幼怪鳥",h:"みすと・ばれーのようかいちょう",e:"Mist Valley Baby Roc",ra:"N",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"鳥獣族",mo:["チューナー","効果"],ma:"",tp:"",at:400,de:600,tx:"①：このカードが手札から墓地へ送られた時に発動できる。\nこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"霞の谷の雷神鬼",h:"みすと・ばれーのらいじんき",e:"Mist Valley Thunder Lord",ra:"R",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"雷族",mo:["シンクロ","効果"],ma:"",tp:"",at:2600,de:2400,tx:"チューナー＋チューナー以外の「ミスト・バレー」と名のついたモンスター１体以上\n１ターンに１度、このカード以外の自分フィールド上のカード１枚を選択して発動できる。\n選択した自分のカードを持ち主の手札に戻し、このカードの攻撃力をエンドフェイズ時まで５００ポイントアップする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"霞の谷の雷鳥",h:"みすと・ばれーのらいちょう",e:"Mist Valley Thunderbird",ra:"N",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:1100,de:700,tx:"フィールド上に表側表示で存在するこのカードが手札に戻った時、このカードを自分フィールド上に特殊召喚する。\nこの効果で特殊召喚したターン、このカードは攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ミストデーモン",h:"みすとでーもん",e:"Mist Archfiend",ra:"SR",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2400,de:0,tx:"①：このカードはリリースなしで召喚できる。\n②：このカードの①の方法で召喚されている場合、エンドフェイズに発動する。\nこのカードを破壊し、自分は１０００ダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"水の精霊 アクエリア",h:"みずのせいれい　あくえりあ",e:"Aqua Spirit",ra:"R",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1600,de:1200,tx:"このカードは通常召喚できない。\n自分の墓地から水属性モンスター１体を除外した場合に特殊召喚できる。\n①：相手スタンバイフェイズに、相手フィールドの表側表示モンスター１体を対象として発動できる。\nその相手の表側表示モンスターの表示形式を変更する。\nこのターン、そのモンスターは表示形式を変更できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"水の魔導師",h:"みずのまどうし",e:"Water Magician",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1400,de:1000,tx:"水で相手の周りを囲んで、包むように攻撃をする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"未知ガエル",h:"みちがえる",e:"Submarine Frog",ra:"N",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1200,de:600,tx:"このカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"満ち潮のマーマン",h:"みちしおのまーまん",e:"High Tide Gyojin",ra:"R",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1650,de:1300,tx:"水の中を自在に泳ぐ、半魚人の戦士。\n攻撃は強い。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"三ツ首のギドー",h:"みつくびのぎどー",e:"Three-Headed Geedo",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1200,de:1400,tx:"三ツ首の怪物。\n夜行性でとても凶暴な性格を持つ。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"みつこぶラクーダ",h:"みつこぶらくーだ",e:"3-Hump Lacooda",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:500,de:1500,tx:"「みつこぶラクーダ」が自分フィールド上に表側表示で３体存在する場合、その内２体を生け贄に捧げる事でカードを３枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ミッド・ピース・ゴーレム",h:"みっど・ぴーす・ごーれむ",e:"Medium Piece Golem",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1600,de:0,tx:"自分フィールド上に「ビッグ・ピース・ゴーレム」が表側表示で存在する場合にこのカードが召喚・反転召喚・特殊召喚に成功した時、自分のデッキから「スモール・ピース・ゴーレム」１体を特殊召喚する事ができる。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ミッドナイト・デビル",h:"みっどないと・でびる",e:"Midnight Fiend",ra:"N",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:800,de:600,tx:"深夜に現れる鳥のばけもの。\n呼び出すにはいけにえが必要という。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"密林の黒竜王",h:"みつりんのこくりゅうおう",e:"B. Dragon Jungle King",ra:"N",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:2100,de:1800,tx:"密林に生息する、漆黒のドラゴン。\nバリバリと木を食べる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ミドル・シールド・ガードナー",h:"みどる・しーるど・がーどなー",e:"Mid Shield Gardna",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:100,de:1800,tx:"このカードは１ターンに１度だけ裏側守備表示にする事ができる。\n裏側表示のこのモンスター１体を対象とする魔法カードの発動を無効にする。\nその時、このカードは表側守備表示になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"水面のアレサ",h:"みなものあれさ",e:"Aquarian Alessa",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["デュアル","効果"],ma:"",tp:"",at:1500,de:500,tx:"このカードは墓地またはフィールド上に表側表示で存在する場合、通常モンスターとして扱う。\nフィールド上に表側表示で存在するこのカードを通常召喚扱いとして再度召喚する事で、このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●このカードが戦闘によって相手モンスターを破壊し墓地へ送った時、相手の手札をランダムに１枚捨てる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"見習い魔女",h:"みならいまじょ",e:"Witch's Apprentice",ra:"N",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:550,de:500,tx:"①：フィールドの闇属性モンスターの攻撃力は５００アップし、光属性モンスターの攻撃力は４００ダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ミノケンサテュロス",h:"みのけんさてゅろす",e:"Minoan Centaur",ra:"R",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"このカードは特殊召喚できない。\nこのカードをリリースする事で、自分のデッキから獣戦士族・レベル４の通常モンスター２体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ミノケンタウロス",h:"みのけんたうろす",e:"Rabid Horseman",ra:"R",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"獣戦士族",mo:["融合"],ma:"",tp:"",at:2000,de:1700,tx:"「ミノタウルス」＋「ケンタウロス」",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ミノタウルス",h:"みのたうるす",e:"Battle Ox",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["通常"],ma:"",tp:"",at:1700,de:1000,tx:"すごい力を持つウシの怪物。\nオノひと振りで何でもなぎ倒す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ミミミック",h:"みみみっく",e:"Mimimic",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:300,de:300,tx:"相手フィールド上にモンスターが存在し、自分フィールド上にレベル３のモンスターが存在する場合、このカードは手札から特殊召喚できる。\nこの方法による「ミミミック」の特殊召喚は１ターンに１度しかできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"音楽家の帝王",h:"みゅーじしゃん・きんぐ",e:"Musician King",ra:"N",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["融合"],ma:"",tp:"",at:1750,de:1500,tx:"「黒き森のウィッチ」＋「ハイ・プリーステス」",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ミューズの天使",h:"みゅーずのてんし",e:"Muse-A",ra:"N",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:850,de:900,tx:"芸術家の天使。\n特にハープの演奏は、右に出る者はいない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ミラー・リゾネーター",h:"みらー・りぞねーたー",e:"Mirror Resonator",ra:"R",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"「ミラー・リゾネーター」の①の効果は１ターンに１度しか使用できない。\n①：このカードが手札・墓地に存在し、エクストラデッキから特殊召喚されたモンスターが相手フィールドにのみ存在する場合に発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。\n②：１ターンに１度、相手フィールドの表側表示モンスター１体を対象として発動できる。\nこのカードはこのターンにS素材とする場合、対象のモンスターの元々のレベルと同じレベルとして扱う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ミラー・レディバグ",h:"みらー・れでぃばぐ",e:"Mirror Ladybug",ra:"N",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"自分フィールド上に表側表示でモンスターが１体以上存在し、自分の墓地にモンスターが存在しない場合、このカードは手札から特殊召喚する事ができる。\nこの効果で特殊召喚に成功した時、このカードのレベルはこのカード以外の自分フィールド上に存在するモンスターのレベルを合計したレベルになる。\nまた、フィールド上に表側表示で存在するこのカードのレベルが１２を超えた場合、このカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ミラージュ",h:"みらーじゅ",e:"Fiend Reflection #2",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["通常"],ma:"",tp:"",at:1100,de:1400,tx:"手にする鏡から仲間を呼び出すことのできる鳥のけもの。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ミラージュ・ドラゴン",h:"みらーじゅ・どらごん",e:"Mirage Dragon",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1600,de:600,tx:"①：このカードがモンスターゾーンに存在する限り、相手はバトルフェイズに罠カードを発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"未来サムライ",h:"みらいさむらい",e:"Future Samurai",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["デュアル","効果"],ma:"",tp:"",at:1600,de:1200,tx:"このカードは墓地またはフィールド上に表側表示で存在する場合、通常モンスターとして扱う。\nフィールド上に表側表示で存在するこのカードを通常召喚扱いとして再度召喚する事で、このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●自分の墓地に存在するモンスター１体をゲームから除外する事で、フィールド上に表側表示で存在するモンスター１体を破壊する。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ミラクル・フリッパー",h:"みらくる・ふりっぱー",e:"Miracle Flipper",ra:"R",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:300,de:500,tx:"「ミラクル・フリッパー」が自分フィールド上に存在する場合、このカードは召喚・反転召喚・特殊召喚できない。\nこのカードがフィールド上に表側表示で存在する限り、相手は他の表側表示のモンスターを攻撃対象に選択できない。\nこのカードが戦闘によって破壊された場合、このカードを相手フィールド上に特殊召喚する。\nこのカードが魔法・罠の効果によって破壊された場合、相手フィールド上のモンスター１体を破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ミリス・レディエント",h:"みりす・れでぃえんと",e:"Milus Radiant",ra:"N",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:300,de:250,tx:"このカードがフィールド上に表側表示で存在する限り、フィールド上に表側表示で存在する地属性モンスターの攻撃力は５００ポイントアップし、風属性モンスターの攻撃力は４００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ミレニアム・アイズ・サクリファイス",h:"みれにあむ・あいず・さくりふぁいす",e:"Millennium-Eyes Restrict",ra:"SR",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["融合","効果"],ma:"",tp:"",at:0,de:0,tx:"「サクリファイス」＋効果モンスター\n①：１ターンに１度、相手モンスターの効果が発動した時、相手のフィールド・墓地の効果モンスター１体を対象として発動できる。\nその相手の効果モンスターを装備カード扱いとしてこのカードに装備する。\n②：このカードの攻撃力・守備力は、このカードの効果で装備したモンスターのそれぞれの数値分アップする。\n③：このカードの効果で装備したモンスターと同名のモンスターは攻撃できず、その効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ミレニアム・スコーピオン",h:"みれにあむ・すこーぴおん",e:"Millennium Scorpion",ra:"SR",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:2000,de:1800,tx:"このカードが相手フィールド上モンスター１体を戦闘によって破壊し墓地へ送る度に、このカードの攻撃力は５００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魅惑の怪盗",h:"みわくのかいとう",e:"The Bewitching Phantom Thief",ra:"N",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:700,de:700,tx:"黒いマントをはおるキザな怪盗。\n杖を振って、相手を魅了する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ミンゲイドラゴン",h:"みんげいどらごん",e:"Totem Dragon",ra:"SR",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:400,de:200,tx:"ドラゴン族モンスターをアドバンス召喚する場合、このカードは２体分のリリースとする事ができる。\n自分のスタンバイフェイズ時にこのカードが墓地に存在し、自分フィールド上にモンスターが存在しない場合、このカードを表側攻撃表示で特殊召喚する事ができる。\nこの効果は自分の墓地にドラゴン族以外のモンスターが存在する場合には発動できない。\nこの効果で特殊召喚したこのカードは、フィールド上から離れた場合ゲームから除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"月光黄鼬",h:"むーんらいと・いえろー・まーてん",e:"Lunalight Yellow Marten",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:800,de:2000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが手札・墓地に存在する場合、「月光黄鼬」以外の自分フィールドの「ムーンライト」カード１枚を対象として発動できる。\nそのカードを持ち主の手札に戻し、このカードを守備表示で特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。\n②：このカードが効果で墓地へ送られた場合に発動できる。\nデッキから「ムーンライト」魔法・罠カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"月光翠鳥",h:"むーんらいと・えめらるど・ばーど",e:"Lunalight Emerald Bird",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:1000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\n手札から「ムーンライト」カード１枚を墓地へ送り、自分はデッキから１枚ドローする。\n②：このカードが効果で墓地へ送られた場合、自分の墓地のモンスター及び除外されている自分のモンスターの中から、「月光翠鳥」以外のレベル４以下の「ムーンライト」モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:3,lk:[],ta:[],al:[],ocg:true},
  {n:"月光舞猫姫",h:"むーんらいと・きゃっと・だんさー",e:"Lunalight Cat Dancer",ra:"R",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"獣戦士族",mo:["融合","効果"],ma:"",tp:"",at:2400,de:2000,tx:"「ムーンライト」モンスター×２\n①：このカードは戦闘では破壊されない。\n②：１ターンに１度、自分メインフェイズ１にこのカード以外の自分フィールドの「ムーンライト」モンスター１体をリリースして発動できる。\nこのターン、相手モンスターはそれぞれ１度だけ戦闘では破壊されず、このカードは全ての相手モンスターに２回ずつ攻撃できる。\n③：このカードの攻撃宣言時に発動する。\n相手に１００ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"月光紅狐",h:"むーんらいと・くりむぞん・ふぉっくす",e:"Lunalight Crimson Fox",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:600,tx:"①：このカードが効果で墓地へ送られた場合、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力をターン終了時まで０にする。\n②：自分フィールドの「ムーンライト」モンスターを対象とする魔法・罠・モンスターの効果が発動した時、墓地のこのカードを除外して発動できる。\nその発動を無効にし、お互いのプレイヤーは１０００LP回復する。",li:2,lk:[],ta:[],al:[],ocg:true},
  {n:"月光舞剣虎姫",h:"むーんらいと・さーべる・だんさー",e:"Lunalight Sabre Dancer",ra:"SR",t:"monster",a:"闇",l:9,ps:"",pe:"",tr:"獣戦士族",mo:["融合","効果"],ma:"",tp:"",at:3000,de:2600,tx:"「ムーンライト」モンスター×３\nこのカードは融合召喚でしか特殊召喚できない。\nこのカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードの攻撃力は、お互いの墓地の獣戦士族モンスター及び除外されている獣戦士族モンスターの数×２００アップする。\n②：このカードは相手の効果の対象にならない。\n③：墓地のこのカードを除外し、自分フィールドの融合モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで３０００アップする。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"月光紫蝶",h:"むーんらいと・ぱーぷる・ばたふらい",e:"Lunalight Purple Butterfly",ra:"R",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"「月光紫蝶」の②の効果は１ターンに１度しか使用できない。\n①：自分の手札・フィールドのこのカードを墓地へ送り、自分フィールドの「ムーンライト」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで１０００アップする。\n②：墓地のこのカードを除外して発動できる。\n手札から「ムーンライト」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"月光白兎",h:"むーんらいと・ほわいと・らびっと",e:"Lunalight White Rabbit",ra:"N",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"①：このカードが召喚に成功した時、「月光白兎」以外の自分の墓地の「ムーンライト」モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\n②：１ターンに１度、このカード以外の自分フィールドの「ムーンライト」カードの数まで、相手フィールドの魔法・罠カードを対象として発動できる。\nそのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"月光蝶",h:"むーんりっと・ぱぴよん",e:"Moonlit Papillon",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1200,de:1600,tx:"このカードがフィールド上から墓地へ送られた時、デッキからレベル４以下の「幻蝶の刺客」と名のついたモンスター１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ムカムカ",h:"むかむか",e:"Muka Muka",ra:"R",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:600,de:300,tx:"このカードの攻撃力・守備力は、自分の手札の枚数×３００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"骸の魔妖－夜叉",h:"むくろのまやかし－やしゃ",e:"Yasha, the Skeletal Mayakashi",ra:"N",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:2000,de:0,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：手札からこのカード以外の「魔妖」モンスター１体を捨てて発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードがモンスターゾーンに存在する限り、自分は「魔妖」モンスターしかEXデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ムドラ",h:"むどら",e:"Mudora",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1500,de:1800,tx:"自分の墓地に存在する天使族モンスター１体につき、このカードの攻撃力は２００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"夢魔の亡霊",h:"むまのぼうれい",e:"The Gross Ghost of Fled Dreams",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1300,de:1800,tx:"寝ている者の夢に取り憑き、生気を吸い取る悪魔。\n取り憑かれてしまった者は、決して自力で目覚めることはない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"冥王竜ヴァンダルギオン",h:"めいおうりゅうう゛ぁんだるぎおん",e:"Van'Dalgyon the Dark Dragon Lord",ra:"SR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2800,de:2500,tx:"相手がコントロールするカードの発動をカウンター罠で無効にした場合、このカードを手札から特殊召喚する事ができる。\nこの方法で特殊召喚に成功した時、無効にしたカードの種類により以下の効果を発動する。\n\n●魔法：相手ライフに１５００ポイントダメージを与える。\n\n●罠：相手フィールド上のカード１枚を選択して破壊する。\n\n●効果モンスター：自分の墓地からモンスター１体を選択して自分フィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"冥界騎士トリスタン",h:"めいかいきしとりすたん",e:"Tristan, Knight of the Underworld",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1800,de:0,tx:"①：このカードが召喚に成功した時、自分の墓地の守備力０のアンデット族モンスター１体を対象として発動できる。\nそのカードを手札に加える。\n②：自分フィールドにこのカード以外のアンデット族モンスターが存在する場合、このカードの攻撃力は３００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"冥界の使者",h:"めいかいのししゃ",e:"Emissary of the Afterlife",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1600,de:600,tx:"このカードがフィールド上から墓地に送られた時、お互いに自分のデッキからレベル３以下の通常モンスター１体を選択し、お互いに確認して手札に加える。\nその後デッキをシャッフルする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"冥界の番人",h:"めいかいのばんにん",e:"Guardian of the Labyrinth",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1000,de:1200,tx:"冥界への入り口を守る戦士。\n許可のない者は容赦なく斬る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"冥界の魔王 ハ・デス",h:"めいかいのまおう　は・です",e:"Dark Ruler Ha Des",ra:"SR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2450,de:1600,tx:"このカードは墓地からの特殊召喚はできない。\nこのカードがフィールド上に表側表示で存在する限り、自分フィールド上に存在する悪魔族モンスターが戦闘で破壊した効果モンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"冥界の麗人イゾルデ",h:"めいかいのれいじんいぞるで",e:"Isolde, Belle of the Underworld",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1000,de:0,tx:"このカードはこのカードの①の方法でしか特殊召喚できない。\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドに「冥界騎士トリスタン」が存在する場合、このカードは手札から特殊召喚できる。\n②：自分フィールドのアンデット族モンスターを２体まで対象とし、５～８までの任意のレベルを宣言して発動できる。\nそのモンスターはターン終了時まで宣言したレベルになる。\nこの効果の発動後、ターン終了時まで自分はアンデット族モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"冥界龍 ドラゴネクロ",h:"めいかいりゅう　どらごねくろ",e:"Dragonecro Nethersoul Dragon",ra:"UR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:3000,de:0,tx:"アンデット族モンスター×２\nこのカードは融合召喚でのみエクストラデッキから特殊召喚できる。\nこのカードと戦闘を行うモンスターはその戦闘では破壊されない。\nまた、このカードがモンスターと戦闘を行ったダメージステップ終了時、そのモンスターの攻撃力は０になり、そのモンスターの元々のレベル・攻撃力を持つ「ダークソウルトークン」（アンデット族・闇・星？・攻？／守０）１体を自分フィールド上に特殊召喚する。\n「冥界龍 ドラゴネクロ」は自分フィールド上に１体しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"迷宮の魔戦車",h:"めいきゅうのませんしゃ",e:"Labyrinth Tank",ra:"R",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"機械族",mo:["融合"],ma:"",tp:"",at:2400,de:2400,tx:"「ギガテック・ウルフ」＋「キャノン・ソルジャー」",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"迷宮壁－ラビリンス・ウォール－",h:"めいきゅうへき－らびりんす・うぉーる－",e:"Labyrinth Wall",ra:"SR",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"岩石族",mo:["通常"],ma:"",tp:"",at:0,de:3000,tx:"フィールドに壁を出現させ、出口のない迷宮をつくる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"迷犬マロン",h:"めいけんまろん",e:"Outstanding Dog Marron",ra:"R",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"このカードが墓地へ送られた時、このカードをデッキに加えてシャッフルする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"名工 虎鉄",h:"めいこう　こてつ",e:"Iron Blacksmith Kotetsu",ra:"R",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"獣戦士族",mo:["リバース","効果"],ma:"",tp:"",at:500,de:500,tx:"①：このカードがリバースした場合に発動する。\nデッキから装備魔法カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"名匠 ガミル",h:"めいしょう　がみる",e:"Master Craftsman Gamil",ra:"N",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:200,de:200,tx:"①：自分のモンスターが戦闘を行うダメージステップ開始時からダメージ計算前までに、このカードを手札から墓地へ送って発動できる。\nそのモンスターの攻撃力はターン終了時まで３００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"冥帝エレボス",h:"めいていえれぼす",e:"Erebus the Underworld Monarch",ra:"SR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:2800,de:1000,tx:"このカードはアドバンス召喚したモンスター１体をリリースしてアドバンス召喚できる。\n①：このカードがアドバンス召喚に成功した場合に発動できる。\n手札・デッキから「帝王」魔法・罠カード２種類を墓地へ送り、相手の手札・フィールド・墓地の中からカード１枚を選んでデッキに戻す。\n②：このカードが墓地にある場合、１ターンに１度、自分・相手のメインフェイズに手札から「帝王」魔法・罠カード１枚を捨て、自分の墓地の攻撃力２４００以上で守備力１０００のモンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"冥帝従騎エイドス",h:"めいていじゅうきえいどす",e:"Eidos the Underworld Squire",ra:"SR",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:800,de:1000,tx:"「冥帝従騎エイドス」の②の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動する。\nこのターン、自分は通常召喚に加えて１度だけ、自分メインフェイズにアドバンス召喚できる。\n②：墓地のこのカードを除外し、「冥帝従騎エイドス」以外の自分の墓地の攻撃力８００／守備力１０００のモンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\nこのターン、自分はエクストラデッキからモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"メカ・ザウルス",h:"めか・ざうるす",e:"Cyber Saurus",ra:"N",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"機械族",mo:["融合"],ma:"",tp:"",at:1800,de:1400,tx:"「ミスター・ボンバー」＋「二頭を持つキング・レックス」",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"メガ・サンダーボール",h:"めが・さんだーぼーる",e:"Mega Thunderball",ra:"N",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"雷族",mo:["通常"],ma:"",tp:"",at:750,de:600,tx:"地面を転がり回り、周囲に電撃を放つボール。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"メカ・ハンター",h:"めか・はんたー",e:"Mechanicalchaser",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1850,de:800,tx:"機械王の命令で、ターゲットを捕まえるまで追いつづけるハンター。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"メカウサー",h:"めかうさー",e:"Mecha Bunny",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:800,de:100,tx:"このカードが戦闘によって破壊され墓地へ送られた時、自分のデッキから「メカウサー」１体を自分フィールド上に裏側守備表示で特殊召喚する事ができる。\nこのカードがリバースした時、フィールド上に存在するカード１枚を選択し、そのコントローラーに５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"メガザウラー",h:"めがざうらー",e:"Megazowler",ra:"N",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"恐竜族",mo:["通常"],ma:"",tp:"",at:1800,de:2000,tx:"全身にツノの生えた恐竜。\n突撃攻撃は強烈だ！",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"メガソニック・アイ",h:"めがそにっく・あい",e:"Megasonic Eye",ra:"N",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1500,de:1800,tx:"宇宙の果てからやってきた殺人マシン。\n謎の金属でできている。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"機械犬マロン",h:"めかどっぐまろん",e:"Mecha-Dog Marron",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"フィールド上のこのカードが戦闘によって破壊され墓地へ送られた時、お互いのライフに１０００ポイントダメージを与える。\nフィールド上のこのカードが戦闘以外によって破壊され墓地へ送られた時、相手ライフに１０００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"メカニカルスネイル",h:"めかにかるすねいる",e:"Mechanical Snail",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:800,de:1000,tx:"機械に改造されたカタツムリ。\nしかし、スピードはあまり変わらない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"メカファルコン",h:"めかふぁるこん",e:"Cyber Falcon",ra:"N",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1400,de:1200,tx:"ジェットエンジンを装備した鷹。\n音の速度で飛ぶ事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"女神の聖弓－アルテミス",h:"めがみのせいきゅう－あるてみす",e:"Goddess Bow",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:1500,de:1600,tx:"このカードは「ヘルモスの爪」の効果で自分の手札・フィールドの戦士族モンスターを墓地へ送った場合のみ特殊召喚できる。\n①：このカードが特殊召喚に成功した場合、このカード以外のフィールドのモンスター１体を対象として発動する。\nこのカードを装備カード扱いとしてそのモンスターに装備する。\n②：このカードの効果でこのカードが装備されている場合、自分・相手のバトルフェイズ中に１度だけ、相手が発動した魔法・罠・モンスターの効果を無効にする。\nこの効果を適用したバトルフェイズ中、装備モンスターは２回攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"メガリス・オク",h:"めがりす・おく",e:"Megalith Och",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["儀式","効果"],ma:"",tp:"",at:1000,de:2700,tx:"「メガリス」カードにより降臨。\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが儀式召喚に成功した場合に発動できる。\n自分はデッキから１枚ドローする。\nその後、手札を１枚選んで捨てる。\n②：自分・相手のメインフェイズに発動できる。\nレベルの合計が儀式召喚するモンスターのレベル以上になるように、フィールドのこのカードを含む自分の手札・フィールドのモンスターをリリースし、手札から儀式モンスター１体を儀式召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"メガリス・オフィエル",h:"めがりす・おふぃえる",e:"Megalith Ophiel",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["儀式","効果"],ma:"",tp:"",at:1600,de:2500,tx:"「メガリス」カードにより降臨。\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが儀式召喚に成功した場合に発動できる。\nデッキから「メガリス・オフィエル」以外の「メガリス」モンスター１体を手札に加える。\n②：自分メインフェイズに発動できる。\nレベルの合計が儀式召喚するモンスターのレベル以上になるように、フィールドのこのカードを含む自分の手札・フィールドのモンスターをリリースし、手札から儀式モンスター１体を儀式召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"メガリス・ハギト",h:"めがりす・はぎと",e:"Megalith Hagith",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["儀式","効果"],ma:"",tp:"",at:1300,de:2600,tx:"「メガリス」カードにより降臨。\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが儀式召喚に成功した場合に発動できる。\nデッキから「メガリス」魔法・罠カード１枚を手札に加える。\n②：自分メインフェイズに発動できる。\nレベルの合計が儀式召喚するモンスターのレベル以上になるように、フィールドのこのカードを含む自分の手札・フィールドのモンスターをリリースし、手札から儀式モンスター１体を儀式召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"メガリス・ファレグ",h:"めがりす・ふぁれぐ",e:"Megalith Phaleg",ra:"R",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"岩石族",mo:["儀式","効果"],ma:"",tp:"",at:2500,de:1200,tx:"「メガリス」カードにより降臨。\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードを手札から捨てて発動できる。\nレベルの合計が儀式召喚するモンスターのレベル以上になるように、自分の手札・フィールドのモンスターをリリースし、手札から「メガリス」儀式モンスター１体を儀式召喚する。\n②：このカードがモンスターゾーンに存在する限り、自分フィールドのモンスターの攻撃力・守備力は、自分の墓地の儀式モンスターの数×３００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"メガリス・ベトール",h:"めがりす・べとーる",e:"Megalith Bethor",ra:"UR",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"岩石族",mo:["儀式","効果"],ma:"",tp:"",at:1500,de:2600,tx:"「メガリス」カードにより降臨。\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードを手札から捨てて発動できる。\nレベルの合計が儀式召喚するモンスターのレベル以上になるように、自分の手札・フィールドのモンスターをリリースし、手札から「メガリス」儀式モンスター１体を儀式召喚する。\n②：このカードが儀式召喚に成功した場合、自分の墓地の儀式モンスターの種類の数まで相手フィールドのカードを対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"メカレオン",h:"めかれおん",e:"Mechaleon",ra:"N",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"爬虫類族",mo:["通常"],ma:"",tp:"",at:800,de:600,tx:"身体の色を変化させ、どんな場所にでも隠れることができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"メガロスマッシャーX",h:"めがろすまっしゃーえっくす",e:"Megalosmasher X",ra:"SR",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"恐竜族",mo:["通常"],ma:"",tp:"",at:2000,de:0,tx:"太古の大海原に突如として現れた恐竜型バイオノイド。\n自慢の消音装甲で獲物の背後に忍び寄り、音もなく喰らいつくが、捕食モードになると体が発光する仕様なのでよく逃げられてしまう。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"メガロック・ドラゴン",h:"めがろっく・どらごん",e:"Megarock Dragon",ra:"SR",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"岩石族",mo:["特殊召喚","効果"],ma:"",tp:"",at:"?",de:"?",tx:"このカードは通常召喚できない。\n自分の墓地に存在する岩石族モンスターを除外する事でのみ特殊召喚できる。\nこのカードの元々の攻撃力と守備力は、特殊召喚時に除外した岩石族モンスターの数×７００ポイントの数値になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"メギストリーの儀術師",h:"めぎすとりーのぎじゅつし",e:"Megistric Maginician",ra:"N",t:"monster",a:"炎",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:0,de:2000,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、自分または相手が魔法カードを発動する度に、このカードに魔力カウンターを１つ置く。\n②：自分・相手のエンドフェイズにこのカードの魔力カウンターを３つ取り除き、除外されている自分の魔法カード１枚を対象として発動できる。\nそのカードの同名カード１枚をデッキから手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"メギラス・ライト",h:"めぎらす・らいと",e:"Megirus Light",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:900,de:600,tx:"ブキミな目から悪しき光を放ち、相手にダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"恵みの像",h:"めぐみのぞう",e:"Elephant Statue of Blessing",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1500,de:0,tx:"相手がコントロールするカードの効果によって、このカードが手札から墓地に送られた時、自分は２０００ライフポイント回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"女邪神ヌヴィア",h:"めじゃしんぬう゛ぃあ",e:"Nuvia the Wicked",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2000,de:800,tx:"召喚された場合、このカードを破壊する。\n相手が１体でもモンスターをコントロールしていた場合、攻撃力は相手フィールド上のモンスター１体につき２００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"メタファイズ・アームド・ドラゴン",h:"めたふぁいず・あーむど・どらごん",e:"Metaphys Armed Dragon",ra:"N",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"幻竜族",mo:["通常"],ma:"",tp:"",at:2800,de:1000,tx:"崇高なる存在は幻の如く。\n其の竜は頂きに佇む。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"メタファイズ・エグゼキューター",h:"めたふぁいず・えぐぜきゅーたー",e:"Metaphys Executor",ra:"N",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"幻竜族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3000,de:2500,tx:"このカードは通常召喚できない。\n自分の墓地及び自分フィールドの表側表示のカードの中から、「メタファイズ」カード５種類を１枚ずつ除外した場合のみ特殊召喚できる。\n①：フィールドのこのカードは効果では破壊されず、効果では除外できない。\n②：相手フィールドのカードの数が自分フィールドのカードより多い場合、１ターンに１度、除外されている自分の「メタファイズ」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターは次のターンのエンドフェイズに除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"メタファイズ・ダイダロス",h:"めたふぁいず・だいだろす",e:"Metaphys Daedalus",ra:"SR",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"幻竜族",mo:["効果"],ma:"",tp:"",at:2600,de:1500,tx:"①：このカードが「メタファイズ」モンスターの効果で特殊召喚に成功した場合に発動できる。\nこのカード以外のフィールドの特殊召喚された表側表示モンスターを全て除外する。\n②：このカードが除外された場合、次のターンのスタンバイフェイズに除外されているこのカードをデッキに戻して発動できる。\nデッキから「メタファイズ・ダイダロス」以外の「メタファイズ」カード１枚を除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"メタファイズ・タイラント・ドラゴン",h:"めたふぁいず・たいらんと・どらごん",e:"Metaphys Tyrant Dragon",ra:"R",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"幻竜族",mo:["効果"],ma:"",tp:"",at:2900,de:2500,tx:"①：「メタファイズ」モンスターの効果で特殊召喚したこのカードは罠カードの効果を受けず、このカードがモンスターを攻撃した場合もう１度だけ続けて攻撃できる。\n②：このカードが除外された場合、次のターンのスタンバイフェイズに除外されているこのカードをデッキに戻して発動できる。\n手札から「メタファイズ」モンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターは次のターンのエンドフェイズに除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"メタファイズ・ネフティス",h:"めたふぁいず・ねふてぃす",e:"Metaphys Nephthys",ra:"R",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"幻竜族",mo:["効果"],ma:"",tp:"",at:2400,de:1600,tx:"①：このカードが「メタファイズ」モンスターの効果で特殊召喚に成功した場合に発動できる。\nフィールドにセットされた魔法・罠カードを全て除外する。\n②：このカードが除外された場合、次のターンのスタンバイフェイズに除外されているこのカードをデッキに戻して発動できる。\nデッキから「メタファイズ・ネフティス」以外の「メタファイズ」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"メタボ・シャーク",h:"めたぼ・しゃーく",e:"Metabo-Shark",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1800,de:500,tx:"このカードが召喚に成功した時、自分の墓地に存在する魚族モンスター２体を選択してデッキに戻す事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"メタル・ガーディアン",h:"めたる・がーでぃあん",e:"Metal Guardian",ra:"N",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1150,de:2150,tx:"魔界の宝を守護する悪魔。\n暗闇での守備は相当かたい。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"メタル・シューター",h:"めたる・しゅーたー",e:"Metal Shooter",ra:"N",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"このカードが召喚に成功した時、このカードにカウンターを２つ置く。\nこの効果によってカードに乗ったカウンター１つにつき、このカードの攻撃力は８００ポイントアップする。\nこのカードが他のカードの効果によって破壊される場合、代わりにこのカードのカウンターを１つ取り除く。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"メタル・デビルゾア",h:"めたる・でびるぞあ",e:"Metalzoa",ra:"N",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"機械族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3000,de:2300,tx:"このカードは通常召喚できない。\n自分フィールドの、「メタル化・魔法反射装甲」を装備した「デビルゾア」１体をリリースした場合にデッキから特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"メタル・ドラゴン",h:"めたる・どらごん",e:"Metal Dragon",ra:"N",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"機械族",mo:["融合"],ma:"",tp:"",at:1850,de:1700,tx:"「鋼鉄の巨神像」＋「レッサー・ドラゴン」",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"メタル・フィッシュ",h:"めたる・ふぃっしゅ",e:"Metal Fish",ra:"N",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1600,de:1900,tx:"金属の魚。\n鋭いカッターになっている尾ビレで相手を切り刻む。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"鉄鋼装甲虫",h:"めたるあーまーどばぐ",e:"Metal Armored Bug",ra:"N",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"昆虫族",mo:["通常"],ma:"",tp:"",at:2800,de:1500,tx:"全身が分厚い装甲で覆われている巨大な昆虫型生物。\n行く手を妨げるものは容赦なく破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"メタル化寄生生物－ソルタイト",h:"めたるかきせいせいぶつ－そるたいと",e:"Metallizing Parasite - Soltite",ra:"N",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"水族",mo:["ユニオン","効果"],ma:"",tp:"",at:1000,de:500,tx:"１ターンに１度、自分のメインフェイズ時に装備カード扱いとして自分フィールド上のモンスターに装備、または装備を解除して表側攻撃表示で特殊召喚できる。\nこの効果で装備カード扱いになっている場合のみ、装備モンスターは相手の効果モンスターの効果の対象にならず、相手の効果モンスターの効果では破壊されない。\n（１体のモンスターが装備できるユニオンは１枚まで。\n装備モンスターが戦闘で破壊される場合、代わりにこのカードを破壊する。\n）",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"メタル化寄生生物－ルナタイト",h:"めたるかきせいせいぶつ－るなたいと",e:"Metallizing Parasite - Lunatite",ra:"N",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"水族",mo:["ユニオン","効果"],ma:"",tp:"",at:1000,de:500,tx:"１ターンに１度だけ自分のメインフェイズに装備カード扱いとして、フィールド上のこのカードを自分フィールド上表側表示のモンスターに装備、または装備を解除して表側攻撃表示で特殊召喚する事ができる。\n装備モンスターは相手がコントロールする魔法の効果を受けなくなる。\n（１体のモンスターが装備できるユニオンは１枚まで、装備モンスターが戦闘によって破壊される場合は、代わりにこのカードを破壊する。\n）",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"メテオ・ドラゴン",h:"めてお・どらごん",e:"Meteor Dragon",ra:"N",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:1800,de:2000,tx:"宇宙の果てから地球におちてきた、流星のドラゴン。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"メテオ・ブラック・ドラゴン",h:"めてお・ぶらっく・どらごん",e:"Meteor Black Dragon",ra:"UR",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["融合"],ma:"",tp:"",at:3500,de:2000,tx:"「真紅眼の黒竜」＋「メテオ・ドラゴン」",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"メデューサ・ワーム",h:"めでゅーさ・わーむ",e:"Medusa Worm",ra:"R",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:500,de:600,tx:"このカードは１ターンに１度だけ裏側守備表示にする事ができる。\nこのカードが反転召喚に成功した時、相手フィールド上モンスター１体を破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"メデューサの亡霊",h:"めでゅーさのぼうれい",e:"The Snake Hair",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"アンデット族",mo:["通常"],ma:"",tp:"",at:1500,de:1200,tx:"毒ヘビの頭を持つモンスター。\n目をあわせると、石にされてしまう。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"メトロンノーム",h:"めとろんのーむ",e:"Metrognome",ra:"SR",t:"monster",a:"地",l:4,ps:4,pe:"①：１ターンに１度、このカード以外の自分または相手のPゾーンのカード１枚を対象として発動できる。\nこのカードのPスケールはターン終了時まで、そのカードのPスケールと同じになる。",tr:"天使族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1800,de:1600,tx:"①：自分のPゾーンにカードが２枚存在し、そのPスケールが同じ場合、このカードの攻撃力・守備力はそのPスケール×１００アップし、このカードは直接攻撃できる。\n②：このカードが直接攻撃によって相手に戦闘ダメージを与えた場合に発動する。\nお互いのPゾーンのカードを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"記憶破壊者",h:"めもりー・くらっしゃー",e:"Memory Crusher",ra:"R",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1000,de:600,tx:"このカードが相手プレイヤーへの直接攻撃に成功した場合、相手の融合デッキの枚数×１００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"記憶破壊王",h:"めもりー・くらっしゅ・きんぐ",e:"Memory Crush King",ra:"R",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2000,de:0,tx:"このカードが直接攻撃によって相手ライフに戦闘ダメージを与えた時、相手の墓地に存在するシンクロモンスターを全てゲームから除外し、その数×１０００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"メルキド四面獣",h:"めるきどしめんじゅう",e:"Melchid the Four-Face Beast",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1500,de:1200,tx:"４つの仮面を切り替えながら、４種類の攻撃をしてくる化け物。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"メルフィー・フェニィ",h:"めるふぃー・ふぇにぃ",e:"Melffy Fenny",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:100,de:300,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手がモンスターの召喚・特殊召喚に成功した場合、またはこのカードが相手モンスターの攻撃対象に選択された場合に発動できる。\nこのカードを持ち主の手札に戻す。\nその後、手札から「メルフィー・フェニィ」以外の獣族モンスター１体を特殊召喚できる。\n②：自分エンドフェイズに発動できる。\nこのカードを手札から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"メルフィー・ポニィ",h:"めるふぃー・ぽにぃ",e:"Melffy Pony",ra:"R",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:400,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手がモンスターの召喚・特殊召喚に成功した場合、またはこのカードが相手モンスターの攻撃対象に選択された場合に発動できる。\nこのカードを持ち主の手札に戻す。\nその後、自分の墓地から「メルフィー・ポニィ」以外のレベル２以下の獣族モンスター１体を選んで手札に加える事ができる。\n②：自分エンドフェイズに発動できる。\nこのカードを手札から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"メルフィー・マミィ",h:"めるふぃー・まみぃ",e:"Melffy Mommy",ra:"R",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1000,de:1000,tx:"獣族レベル２モンスター×２体以上\n①：自分・相手ターンに１度、発動できる。\n自分の手札・フィールドから獣族モンスター１体を選び、このカードの下に重ねてX素材とする。\n②：このカードが持っているX素材の数によって、このカードは以下の効果を得る。\n\n●３つ以上：このカードは戦闘では破壊されない。\n\n●４つ以上：このカードの戦闘で発生する自分への戦闘ダメージは０になる。\n\n●５つ以上：このカードが攻撃表示モンスターと戦闘を行う攻撃宣言時に発動できる。\nその攻撃表示モンスターの攻撃力分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"メルフィー・ラビィ",h:"めるふぃー・らびぃ",e:"Melffy Rabby",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:0,de:2100,tx:"ラビィはこの切り株がいちばんのお気に入り。\nいつも登ってまわりをきょろきょろ。\n\n\nメルフィーの森には個性豊かでかわいい動物たちがいっぱい。\n\n切り株を見つけるとついつい座りたくなっちゃうそこのあなた！\nさぁ、ラビィといっしょにメルフィーのお友達を探しに行きましょう♪",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"メンタル・カウンセラー リリー",h:"めんたる・かうんせらー　りりー",e:"Counselor Lily",ra:"UR",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"天使族",mo:["チューナー","効果"],ma:"",tp:"",at:400,de:1500,tx:"このカードがシンクロモンスターのシンクロ召喚に使用され墓地へ送られた場合、５００ライフポイントを払う事でこのカードをシンクロ素材としたシンクロモンスターの攻撃力は、このターンのエンドフェイズ時まで１０００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"メンタルシーカー",h:"めんたるしーかー",e:"Mental Seeker",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"サイキック族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:600,tx:"ゲームから除外されているこのカードが特殊召喚に成功した時、相手のデッキの上からカードを３枚めくる。\n自分はその中から１枚を選択してゲームから除外し、残りのカードをデッキに戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"メンタルスフィア・デーモン",h:"めんたるすふぃあ・でーもん",e:"Thought Ruler Archfiend",ra:"SR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"サイキック族",mo:["シンクロ","効果"],ma:"",tp:"",at:2700,de:2300,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：このカードが戦闘でモンスターを破壊し墓地へ送った場合に発動する。\nそのモンスターの元々の攻撃力分だけ自分のLPを回復する。\n②：サイキック族モンスター１体のみを対象とする魔法・罠カードが発動した時、１０００LPを払って発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"モアイ迎撃砲",h:"もあいげいげきほう",e:"Moai Interceptor Cannons",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1100,de:2000,tx:"このカードは１ターンに１度だけ裏側守備表示にする事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"モイスチャー星人",h:"もいすちゃーせいじん",e:"Moisture Creature",ra:"N",t:"monster",a:"光",l:9,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2800,de:2900,tx:"このカードはモンスター３体をリリースして召喚する事ができる。\nこの方法で召喚に成功した時、相手フィールド上に存在する魔法・罠カードを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"猛炎星－テンレイ",h:"もうえんせい－てんれい",e:"Brotherhood of the Fire Fist - Caribou",ra:"R",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1100,de:2000,tx:"このカードがカードの効果によって破壊され墓地へ送られたターンのエンドフェイズ時、デッキから「猛炎星－テンレイ」以外の「炎星」と名のついたレベル４モンスター１体を特殊召喚できる。\nこのカードが「炎星」と名のついたシンクロモンスターのシンクロ召喚に使用され墓地へ送られた場合、デッキから「炎舞」と名のついた魔法カード１枚を選んで自分フィールド上にセットできる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"猛進する剣角獣",h:"もうしんするけんかくじゅう",e:"Mad Sword Beast",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1400,de:1200,tx:"このカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"盲信するゴブリン",h:"もうしんするごぶりん",e:"Blindly Loyal Goblin",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1500,tx:"このカードは表側表示でフィールド上に存在する限り、コントロールを変更する事はできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"燃え盛るヒータ",h:"もえさかるひーた",e:"Blazing Hiita",ra:"N",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:800,de:1500,tx:"このカードを除く自分フィールド上の炎属性モンスター１体を生け贄に捧げる事で、手札から炎属性モンスター１体を特殊召喚する。\nこの効果は１ターンに１度しか使用できない。\nこの効果によって特殊召喚されたモンスターは、「燃え盛るヒータ」が自分フィールド上から離れた場合破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"燃える藻",h:"もえるも",e:"Burning Algae",ra:"N",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:500,de:1500,tx:"このカードが墓地に送られた時、相手は１０００ライフポイント回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"モーム",h:"もーむ",e:"Mormolith",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1000,de:900,tx:"自分フィールド上に表側表示で存在する地属性モンスター１体をリリースして発動する。\nフィールド上に表側表示で存在し、リリースしたモンスターの攻撃力以下の守備力を持つモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"モグモール",h:"もぐもーる",e:"Mogmole",ra:"R",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"フィールド上のこのカードが破壊され墓地へ送られた時、このカードを墓地から表側守備表示で特殊召喚できる。\n「モグモール」の効果はデュエル中に１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"もけもけ",h:"もけもけ",e:"Mokey Mokey",ra:"N",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"天使族",mo:["通常"],ma:"",tp:"",at:300,de:100,tx:"何を考えているのかさっぱりわからない天使のはみだし者。\nたまに怒ると怖い。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"モザイク・マンティコア",h:"もざいく・まんてぃこあ",e:"Mosaic Manticore",ra:"SR",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:2800,de:2500,tx:"①：このカードがアドバンス召喚に成功した場合、次の自分ターンのスタンバイフェイズに発動する。\nこのカードのアドバンス召喚のためにリリースしたモンスターを墓地から可能な限り自分フィールドに特殊召喚する。\nこの効果で特殊召喚したモンスターは攻撃宣言できず、効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"モジャ",h:"もじゃ",e:"Moja",ra:"N",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"このカードが戦闘によって破壊され墓地へ送られた時、自分の墓地に存在するレベル４の獣族モンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"電磁蚊",h:"もすきーと・まぐね",e:"Magnetic Mosquito",ra:"R",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"昆虫族",mo:["リバース","効果"],ma:"",tp:"",at:300,de:1000,tx:"リバース：フィールド上に表側表示で存在する機械族モンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"モノ・シンクロン",h:"もの・しんくろん",e:"Mono Synchron",ra:"R",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"このカードをシンクロ素材とする場合、他のシンクロ素材モンスターはレベル４以下の戦士族・機械族モンスターでなければならず、そのレベルは１として扱う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"物陰の協力者",h:"ものかげのきょうりょくしゃ",e:"Supporter in the Shadows",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1000,de:1000,tx:"物陰からこっそりと協力してくれる、かわいらしい小人。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"モノケロース",h:"ものけろーす",e:"Monoceros",ra:"N",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"獣族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードは通常召喚できない。\n手札の魔法カード１枚をゲームから除外した場合に特殊召喚する事ができる。\nこのカードと獣族チューナーを素材としたシンクロ召喚に成功した時、そのシンクロ召喚の素材とした獣族チューナー１体を墓地から特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ものマネ幻想師",h:"ものまねげんそうし",e:"Copycat",ra:"SR",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカードが召喚・反転召喚・特殊召喚に成功した時、相手フィールド上に表側表示で存在するモンスター１体を選択して発動する。\nこのカードの攻撃力・守備力は、選択したモンスターの元々の攻撃力・守備力と同じ数値になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"森の狩人イエロー・バブーン",h:"もりのかりうどいえろー・ばぶーん",e:"Yellow Baboon, Archer of the Forest",ra:"UR",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:2600,de:1800,tx:"自分フィールド上に存在する獣族モンスターが戦闘によって破壊され墓地へ送られた時、自分の墓地に存在する獣族モンスター２体をゲームから除外する事で、このカードを手札から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"森の屍",h:"もりのしかばね",e:"Wood Remains",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["通常"],ma:"",tp:"",at:1000,de:900,tx:"森のぬしが倒れたあと、悪しき者の手により蘇った屍。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"森の住人 ウダン",h:"もりのじゅうにん　うだん",e:"Wodan the Resident of the Forest",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:900,de:1200,tx:"フィールド上の表側表示の植物族モンスター１体につき、このカードの攻撃力は１００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"森の聖獣 アルパカリブ",h:"もりのせいじゅう　あるぱかりぶ",e:"Alpacaribou, Mystical Beast of the Forest",ra:"R",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:2700,de:2100,tx:"このカードはこのカードの表示形式によって以下の効果を得る。\n●攻撃表示：自分フィールド上に表側攻撃表示で存在する鳥獣族・昆虫族・植物族モンスターは戦闘では破壊されない。\n●守備表示：自分フィールド上に表側守備表示で存在する鳥獣族・昆虫族・植物族モンスターはカードの効果の対象にならず、カードの効果では破壊されない。\n\n",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"森の聖獣 ヴァレリフォーン",h:"もりのせいじゅう　う゛ぁれりふぉーん",e:"Valerifawn, Mystical Beast of the Forest",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["チューナー","効果"],ma:"",tp:"",at:400,de:900,tx:"「森の聖獣 ヴァレリフォーン」の効果は１ターンに１度しか使用できない。\n①：手札を１枚捨て、「森の聖獣 ヴァレリフォーン」以外の自分の墓地のレベル２以下の獣族モンスター１体を対象として発動できる。\nそのモンスターを表側攻撃表示または裏側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"森の聖獣 カラントーサ",h:"もりのせいじゅう　からんとーさ",e:"Kalantosa, Mystical Beast of the Forest",ra:"R",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:200,de:1400,tx:"①：このカードが獣族モンスターの効果で特殊召喚に成功した場合、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"森の聖獣 ユニフォリア",h:"もりのせいじゅう　ゆにふぉりあ",e:"Uniflora, Mystical Beast of the Forest",ra:"SR",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:700,de:500,tx:"自分の墓地のモンスターが獣族のみの場合、このカードをリリースして発動できる。\n自分の手札・墓地から「森の聖獣 ユニフォリア」以外の獣族モンスター１体を選んで特殊召喚する。\nこの効果で特殊召喚したモンスターはこのターン攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"森の聖霊 エーコ",h:"もりのせいれい　えーこ",e:"Eco, Mystical Spirit of the Forest",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"相手のカードの効果によって自分がダメージを受けた時に発動できる。\nこのカードを手札から特殊召喚し、受けたダメージと同じダメージを相手ライフに与える。\nさらに、このターンお互いが受ける効果ダメージは０になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"森の番人グリーン・バブーン",h:"もりのばんにんぐりーん・ばぶーん",e:"Green Baboon, Defender of the Forest",ra:"UR",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:2600,de:1800,tx:"①：このカードが手札・墓地に存在し、自分フィールドの表側表示の獣族モンスターが効果で破壊され墓地へ送られた時、１０００LPを払って発動できる。\nこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"モンク・ファイター",h:"もんく・ふぁいたー",e:"Monk Fighter",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1300,de:1000,tx:"このカードが戦闘を行う事によって受けるコントローラーの戦闘ダメージは０になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"紋章獣アバコーンウェイ",h:"もんしょうじゅうあばこーんうぇい",e:"Heraldic Beast Aberconway",ra:"R",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1800,de:900,tx:"このカードが墓地に存在する場合、このカード以外の自分の墓地の「紋章獣アバコーンウェイ」１体をゲームから除外して発動できる。\n自分の墓地の「紋章獣」と名のついたモンスター１体を選択して手札に加える。\n「紋章獣アバコーンウェイ」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"紋章獣アンフィスバエナ",h:"もんしょうじゅうあんふぃすばえな",e:"Heraldic Beast Amphisbaena",ra:"N",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1700,de:1100,tx:"自分のメインフェイズ時、手札からこのカード以外の「紋章獣」と名のついたモンスター１体を捨てて発動できる。\nこのカードを手札から特殊召喚する。\nまた、１ターンに１度、手札から「紋章獣」と名のついたモンスター１体を捨てて発動できる。\nこのカードの攻撃力はエンドフェイズ時まで８００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"紋章獣エアレー",h:"もんしょうじゅうえあれー",e:"Heraldic Beast Eale",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1000,de:1800,tx:"自分フィールド上に「紋章獣」と名のついたモンスターが２体以上存在する場合、このカードは手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"紋章獣ツインヘッド・イーグル",h:"もんしょうじゅうついんへっど・いーぐる",e:"Heraldic Beast Twin-Headed Eagle",ra:"N",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1200,de:1400,tx:"墓地のこのカードをゲームから除外し、自分フィールド上のエクシーズ素材の無いエクシーズモンスター１体と、自分の墓地の「紋章獣」と名のついたモンスター２体を選択して発動できる。\n選択した墓地のモンスターを、選択したエクシーズモンスターの下に重ねてエクシーズ素材とする。\n「紋章獣ツインヘッド・イーグル」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"紋章獣バシリスク",h:"もんしょうじゅうばしりすく",e:"Heraldic Beast Basilisk",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1000,de:1400,tx:"このカードが相手モンスターと戦闘を行ったダメージ計算後、その相手モンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"紋章獣ベルナーズ・ファルコン",h:"もんしょうじゅうべるなーず・ふぁるこん",e:"Heraldic Beast Berners Falcon",ra:"N",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1000,de:1600,tx:"このカードが召喚・反転召喚・特殊召喚に成功した時に発動できる。\n自分フィールド上の全てのレベル５以上のモンスターのレベルを４にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"紋章獣ユニコーン",h:"もんしょうじゅうゆにこーん",e:"Heraldic Beast Unicorn",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1100,de:1600,tx:"墓地のこのカードをゲームから除外し、自分の墓地のサイキック族エクシーズモンスター１体を選択して発動できる。\n選択したモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。\n「紋章獣ユニコーン」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"紋章獣レオ",h:"もんしょうじゅうれお",e:"Heraldic Beast Leo",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:2000,de:1000,tx:"このカードを召喚したターンのエンドフェイズ時、このカードを破壊する。\nまた、このカードが墓地へ送られた時、デッキから「紋章獣レオ」以外の「紋章獣」と名のついたモンスター１体を手札に加える。\n「紋章獣レオ」のこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"モンスター・アイ",h:"もんすたー・あい",e:"Monster Eye",ra:"N",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:250,de:350,tx:"１０００ライフポイントを払って発動する。\n自分の墓地に存在する「融合」魔法カード１枚を手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"モンスター・エッグ",h:"もんすたー・えっぐ",e:"Monster Egg",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:600,de:900,tx:"卵のカラに身を包んだ謎の戦士。\nカラを飛ばして攻撃する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"地獄の魔物使い",h:"もんすたー・ていまー",e:"Monster Tamer",ra:"R",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1800,de:1600,tx:"モンスターを自在に操り攻撃してくる、モンスター使い。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"モンスタートル",h:"もんすたーとる",e:"Monsturtle",ra:"N",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:800,de:1000,tx:"トゲのついたこうらを身につけたカメ。\nとても凶暴で人になつかない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"モンタージュ・ドラゴン",h:"もんたーじゅ・どらごん",e:"Montage Dragon",ra:"UR",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:"?",de:0,tx:"このカードは通常召喚できない。\n手札からモンスター３体を墓地へ送った場合のみ特殊召喚できる。\nこのカードの攻撃力は、墓地へ送ったそのモンスターのレベルの合計×３００ポイントになる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヤシの木",h:"やしのき",e:"Yashinoki",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"植物族",mo:["通常"],ma:"",tp:"",at:800,de:600,tx:"意志をもつヤシの木。\n実を落として攻撃。\n実の中のミルクはおいしい。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"夜叉",h:"やしゃ",e:"Yaksha",ra:"SR",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"天使族",mo:["スピリット","効果"],ma:"",tp:"",at:1900,de:1500,tx:"このカードは特殊召喚できない。\n召喚・リバースしたターンのエンドフェイズ時に持ち主の手札に戻る。\nこのカードが召喚・リバースした時、相手フィールド上に存在する魔法・罠カード１枚を選択して持ち主の手札に戻す事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"八つ手サソリ",h:"やつでさそり",e:"8-Claws Scorpion",ra:"N",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:300,de:200,tx:"このカードは１ターンに１度だけ裏側守備表示にする事ができる。\n裏側守備表示の相手モンスターを攻撃対象にした場合、このカードの攻撃力はダメージ計算時のみ２４００ポイントになる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヤドカリュー",h:"やどかりゅー",e:"Yado Karu",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:900,de:1700,tx:"このカードの表示形式が攻撃表示から表側守備表示に変わった時、自分の手札からカードを任意の枚数デッキの一番下に戻す事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"屋根裏の物の怪",h:"やねうらのもののけ",e:"Wretched Ghost of the Attic",ra:"N",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:550,de:400,tx:"どの家の屋根裏にも潜んでいるもののけ。\n特にわるさはしない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"野望のゴーファー",h:"やぼうのごーふぁー",e:"Ambitious Gofer",ra:"SR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2400,de:100,tx:"１ターンに１度、相手フィールド上に存在するモンスターを２体まで選択して発動する事ができる。\n相手は手札のモンスター１体を見せてこのカードの効果を無効にする事ができる。\n見せなかった場合、選択したモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"八俣大蛇",h:"やまたのどらごん",e:"Yamata Dragon",ra:"SR",t:"monster",a:"炎",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["スピリット","効果"],ma:"",tp:"",at:2600,de:3100,tx:"このカードは特殊召喚できない。\n召喚・リバースしたターンのエンドフェイズ時に持ち主の手札に戻る。\nこのカードが相手ライフに戦闘ダメージを与えた時、自分の手札が５枚になるまでデッキからカードをドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヤマタノ竜絵巻",h:"やまたのどらごんえまき",e:"Yamatano Dragon Scroll",ra:"N",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:900,de:300,tx:"絵巻のドラゴンが実体化して攻撃する。\n守備はかなり低い。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"大和神",h:"やまとのかみ",e:"Yamato-no-Kami",ra:"N",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","スピリット","効果"],ma:"",tp:"",at:2200,de:1200,tx:"このカードは通常召喚する事ができない。\n自分の墓地に存在するスピリットモンスター１体をゲームから除外した場合のみ特殊召喚する事ができる。\n特殊召喚したターンのエンドフェイズ時に持ち主の手札に戻る。\nこのカードが戦闘によって相手モンスターを破壊した場合、相手フィールド上に存在する魔法または罠カード１枚を破壊する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヤマドラン",h:"やまどらん",e:"Yamadron",ra:"N",t:"monster",a:"炎",l:5,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:1600,de:1800,tx:"三つの頭でつぎつぎ炎をはき、あたり一面を炎の海にする！",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"山の精霊",h:"やまのせいれい",e:"Spirit of the Mountain",ra:"R",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:1300,de:1800,tx:"手にする笛の音を聞いた者は、力が抜けてしまうと言われている。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"闇・道化師のサギー",h:"やみ・どうけしのさぎー",e:"Saggi the Dark Clown",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:600,de:1500,tx:"どこからともなく現れる道化師。\n不思議な動きで攻撃をかわす。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"闇・道化師のペーテン",h:"やみ・どうけしのぺーてん",e:"Peten the Dark Clown",ra:"R",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:500,de:1200,tx:"①：このカードが墓地へ送られた時、墓地のこのカードを除外して発動できる。\n手札・デッキから「闇・道化師のペーテン」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"闇王プロメティス",h:"やみおうぷろめてぃす",e:"Prometheus, King of the Shadows",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1200,de:800,tx:"①：このカードが召喚に成功した場合に発動する。\n自分の墓地の闇属性モンスターを任意の数だけ選んで除外する。\nこのカードの攻撃力はターン終了時まで、除外した数×４００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"闇晦ましの城",h:"やみくらましのしろ",e:"Castle of Dark Illusions",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["リバース","効果"],ma:"",tp:"",at:920,de:1930,tx:"リバース：フィールド上に表側表示で存在する全てのアンデット族モンスターの攻撃力と守備力は２００ポイントアップする。\nまた、このカードがフィールド上に表側表示で存在する限り、自分のスタンバイフェイズ毎にさらに２００ポイントずつアップする。\nこの効果は自分の４回目のスタンバイフェイズまで続く。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"闇にしたがう者",h:"やみにしたがうもの",e:"Sectarian of Secrets",ra:"N",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:700,de:500,tx:"闇を崇拝する魔法使い。\n魔の手を呼び出し暗闇へ引きずり込む。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"闇の暗殺者",h:"やみのあんさつしゃ",e:"Dark Assailant",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"アンデット族",mo:["通常"],ma:"",tp:"",at:1200,de:1200,tx:"サイコソードと呼ばれる剣を持ち、魔界に君臨する暗殺者。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"闇の仮面",h:"やみのかめん",e:"Mask of Darkness",ra:"UR",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"悪魔族",mo:["リバース","効果"],ma:"",tp:"",at:900,de:400,tx:"リバース：自分の墓地の罠カード１枚を選択して手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"闇の侯爵ベリアル",h:"やみのこうしゃくべりある",e:"Belial - Marquis of Darkness",ra:"R",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2800,de:2400,tx:"このカードがフィールド上に表側表示で存在する限り、相手は「闇の侯爵ベリアル」以外の自分フィールド上に表側表示で存在するモンスターを攻撃対象に選択できず、魔法・罠カードの効果の対象にする事もできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"闇の住人 シャドウキラー",h:"やみのじゅうにん　しゃどうきらー",e:"Shadowslayer",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1400,de:200,tx:"相手のモンスターカードゾーンに守備表示モンスターしか存在しない場合、このカードは相手プレイヤーに直接攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"闇の精霊 ルーナ",h:"やみのせいれい　るーな",e:"Luna the Dark Spirit",ra:"UR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["特殊召喚","効果"],ma:"",tp:"",at:1600,de:1200,tx:"このカードは通常召喚できない。\n自分の墓地から闇属性モンスター１体を除外した場合に特殊召喚できる。\n①：自分スタンバイフェイズに発動する。\n相手に５００ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"闇魔界の戦士 ダークソード",h:"やみまかいのせんし だーくそーど",e:"Dark Blade",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1800,de:1500,tx:"ドラゴンを操ると言われている闇魔界の戦士。\n邪悪なパワーで斬りかかる攻撃はすさまじい。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"闇魔界の戦士長 ダークソード",h:"やみまかいのせんしちょう　だーくそーど",e:"Dark Blade the Captain of the Evil World",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1500,tx:"１ターンに１度、自分の墓地の闇属性モンスター１体をゲームから除外し、相手フィールド上の光属性・レベル４以下のモンスター１体を選択して発動できる。\n選択したモンスターを装備カード扱いとしてこのカードに１体のみ装備する。\nまた、フィールド上のこのカードが破壊される場合、代わりにこのカードの効果で装備したモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"闇魔界の覇王",h:"やみまかいのはおう",e:"King of Yamimakai",ra:"N",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:2000,de:1530,tx:"強大な闇の力を使い、まわりのものを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"闇魔界の竜騎士 ダークソード",h:"やみまかいのりゅうきし　だーくそーど",e:"Dark Blade the Dragon Knight",ra:"R",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2200,de:1500,tx:"「闇魔界の戦士 ダークソード」＋「漆黒の闘龍」\nこのカードが相手に戦闘ダメージを与える度に、相手の墓地から３枚までモンスターカードを選択し、ゲームから除外する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"闇より出でし絶望",h:"やみよりいでしぜつぼう",e:"Despair from the Dark",ra:"SR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:2800,de:3000,tx:"①：このカードが相手の効果で手札・デッキから墓地へ送られた場合に発動する。\nこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"闇よりの恐怖",h:"やみよりのきょうふ",e:"Fear from the Dark",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1700,de:1500,tx:"このカードが相手のカードの効果によって手札またはデッキから墓地に送られた時、このカードを自分フィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"闇霊神オブルミラージュ",h:"やみれいしんおぶるみらーじゅ",e:"Umbramirage the Elemental Lord",ra:"UR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"悪魔族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2800,de:2200,tx:"このカードは通常召喚できない。\n自分の墓地の闇属性モンスターが５体の場合のみ特殊召喚できる。\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合に発動する。\nデッキから攻撃力１５００以下のモンスター１体を手札に加える。\n②：表側表示のこのカードがフィールドから離れた場合、次の自分ターンのバトルフェイズをスキップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"闇霊使いダルク",h:"やみれいつかいだるく",e:"Dharc the Dark Charmer",ra:"R",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["リバース","効果"],ma:"",tp:"",at:500,de:1500,tx:"①：このカードがリバースした場合、相手フィールドの闇属性モンスター１体を対象として発動する。\nこのモンスターが表側表示で存在する間、そのモンスターのコントロールを得る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"闇を司る影",h:"やみをつかさどるかげ",e:"The Shadow Who Controls the Dark",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:800,de:700,tx:"暗闇の中にとけこむ影。\n金縛りで敵の動きを封じる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヤランゾ",h:"やらんぞ",e:"Yaranzo",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"アンデット族",mo:["通常"],ma:"",tp:"",at:1300,de:1500,tx:"宝箱のフタを開けようとする盗賊を、箱から飛び出し襲う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"雄炎星－スネイリン",h:"ゆうえんせい－すねいりん",e:"Brotherhood of the Fire Fist - Snake",ra:"R",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:600,tx:"１ターンに１度、「炎舞」と名のついた魔法・罠カードが自分の墓地へ送られた場合、デッキから「炎舞」と名のついた罠カード１枚を選んで自分フィールド上にセットできる。\nまた、自分フィールド上にこのカード以外のモンスターが存在しない場合、１ターンに１度、自分フィールド上に表側表示で存在する「炎舞」と名のついた魔法・罠カード２枚を墓地へ送って発動できる。\nデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"勇気の砂時計",h:"ゆうきのすなどけい",e:"Hourglass of Courage",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1100,de:1200,tx:"このカードが召喚・反転召喚に成功した時、このカードの元々の攻撃力・守備力は次の自分のターンのエンドフェイズ時まで半分になる。\nその後、このカードの元々の攻撃力・守備力は倍になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"融合呪印生物－地",h:"ゆうごうじゅいんせいぶつ－ち",e:"The Earth - Hex-Sealed Fusion",ra:"SR",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1000,de:1600,tx:"①：このカードは、融合モンスターカードにカード名が記された融合素材モンスター１体の代わりにできる。\nその際、他の融合素材モンスターは正規のものでなければならない。\n②：地属性の融合モンスターカードによって決められた融合素材モンスター一組（このカードをその内の１体とする）を自分フィールドからリリースして発動できる。\nそのリリースしたモンスターを融合素材とする地属性の融合モンスター１体をエクストラデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"融合呪印生物－光",h:"ゆうごうじゅいんせいぶつ－ひかり",e:"The Light - Hex-Sealed Fusion",ra:"SR",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1000,de:1600,tx:"このカードを融合素材モンスター１体の代わりにする事ができる。\nその際、他の融合素材モンスターは正規のものでなければならない。\nまた、必要となる融合素材モンスター（このカードを含む）をリリースして発動できる。\nそのリリースしたモンスターを融合素材とする光属性の融合モンスター１体をエクストラデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"融合呪印生物－闇",h:"ゆうごうじゅいんせいぶつ－やみ",e:"The Dark - Hex-Sealed Fusion",ra:"SR",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1000,de:1600,tx:"①：このカードは、融合モンスターカードにカード名が記された融合素材モンスター１体の代わりにできる。\nその際、他の融合素材モンスターは正規のものでなければならない。\n②：闇属性の融合モンスターカードによって決められた融合素材モンスター一組（このカードをその内の１体とする）を自分フィールドからリリースして発動できる。\nそのリリースしたモンスターを融合素材とする闇属性の融合モンスター１体をEXデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ユーフォロイド",h:"ゆーふぉろいど",e:"UFOroid",ra:"R",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:1200,tx:"このカードが戦闘によって破壊され墓地へ送られた時、デッキから攻撃力１５００以下の機械族モンスター１体を表側攻撃表示で特殊召喚する事ができる。\nその後デッキをシャッフルする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ユーフォロイド・ファイター",h:"ゆーふぉろいど・ふぁいたー",e:"UFOroid Fighter",ra:"SR",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:"?",de:"?",tx:"「ユーフォロイド」＋戦士族モンスター\nこのモンスターの融合召喚は、上記のカードでしか行えない。\nこのカードの元々の攻撃力・守備力は、融合素材にしたモンスター２体の元々の攻撃力を合計した数値になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"有翼賢者ファルコス",h:"ゆうよくけんじゃふぁるこす",e:"Winged Sage Falcos",ra:"UR",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:1700,de:1200,tx:"このカードが戦闘によって破壊し墓地に送った表側攻撃表示の相手モンスターを、相手のデッキの一番上に戻す事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"有翼幻獣キマイラ",h:"ゆうよくげんじゅうきまいら",e:"Chimera the Flying Mythical Beast",ra:"SR",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"獣族",mo:["融合","効果"],ma:"",tp:"",at:2100,de:1800,tx:"「幻獣王ガゼル」＋「バフォメット」\n①：このカードが破壊された時、自分の墓地の、「バフォメット」または「幻獣王ガゼル」１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"雪天気シエル",h:"ゆきてんきしえる",e:"The Weather Painter Snow",ra:"UR",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:0,de:2200,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時に発動できる。\nデッキから「天気」魔法・罠カード１枚を選んで自分の魔法＆罠ゾーンに表側表示で置く。\n②：フィールドのこのカードが「天気」カードの効果を発動するために除外された場合、次のターンのスタンバイフェイズに発動できる。\n除外されているこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ユニオン・ライダー",h:"ゆにおん・らいだー",e:"Union Rider",ra:"R",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"相手フィールド上でモンスター状態のユニオンモンスターのコントロールを得て、このカードに１枚まで装備する。\nこのカードに装備されたユニオンは自身の効果を使ってモンスター状態に戻る事はできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ユニバード",h:"ゆにばーど",e:"Unibird",ra:"N",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:100,de:600,tx:"自分フィールド上に表側表示で存在するモンスター１体とこのカードをゲームから除外し、その元々のレベルを合計した数以下のレベルを持つシンクロモンスター１体を自分の墓地から選択して発動する。\n選択したモンスターを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ユベル",h:"ゆべる",e:"Yubel",ra:"UR",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"①：このカードは戦闘では破壊されず、このカードの戦闘で自分は戦闘ダメージを受けない。\n②：攻撃表示のこのカードが攻撃対象に選択された場合、そのダメージ計算前に発動する。\nその攻撃モンスターの攻撃力分のダメージを相手に与える。\n③：自分エンドフェイズに発動する。\n自分フィールドの他のモンスター１体をリリースするか、このカードを破壊する。\n④：この③の効果以外でこのカードが破壊された時に発動できる。\n自分の手札・デッキ・墓地から「ユベル－Das Abscheulich Ritter」１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ユベル－Das Abscheulich Ritter",h:"ゆべる－だす・あぷしぇりっひ・りったー",e:"Yubel - Terror Incarnate",ra:"UR",t:"monster",a:"闇",l:11,ps:"",pe:"",tr:"悪魔族",mo:["特殊召喚","効果"],ma:"",tp:"",at:0,de:0,tx:"このカードは通常召喚できない。\n「ユベル」の効果でのみ特殊召喚できる。\nこのカードは戦闘では破壊されず、このカードの戦闘によって発生する自分への戦闘ダメージは０になる。\nフィールド上に表側攻撃表示で存在するこのカードが相手モンスターに攻撃された場合、そのダメージ計算前に攻撃モンスターの攻撃力分のダメージを相手ライフに与える。\nまた、自分のエンドフェイズ時、このカード以外のフィールド上のモンスターを全て破壊する。\nこのカードがフィールド上から離れた時、自分の手札・デッキ・墓地から「ユベル－Das Extremer Traurig Drachen」１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ユベル－Das Extremer Traurig Drachen",h:"ゆべる－だす・えくすとれーむ・とらうりひ・どらっへ",e:"Yubel - The Ultimate Nightmare",ra:"UR",t:"monster",a:"闇",l:12,ps:"",pe:"",tr:"悪魔族",mo:["特殊召喚","効果"],ma:"",tp:"",at:0,de:0,tx:"このカードは通常召喚できない。\n「ユベル－Das Abscheulich Ritter」の効果でのみ特殊召喚できる。\nこのカードは戦闘では破壊されず、このカードの戦闘によって発生する自分への戦闘ダメージは０になる。\nフィールド上に表側攻撃表示で存在するこのカードが相手モンスターと戦闘を行ったダメージステップ終了時、相手モンスターの攻撃力分のダメージを相手ライフに与え、そのモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"弓を引くマーメイド",h:"ゆみをひくまーめいど",e:"Red Archery Girl",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1400,de:1500,tx:"普段は貝殻の中に身を隠し、近づく者に矢を放つマーメイド。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"夢魔鏡の乙女－イケロス",h:"ゆめまきょうのおとめ－いけろす",e:"Ikelos, the Dream Mirror Sprite",ra:"UR",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:0,de:500,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが「夢魔鏡」モンスターの効果で特殊召喚に成功した場合に発動できる。\nデッキから「夢魔鏡の乙女－イケロス」以外の「夢魔鏡」カード１枚を手札に加える。\n②：フィールドゾーンに「闇黒の夢魔鏡」が存在する場合、自分・相手のメインフェイズ及びバトルフェイズに、このカードをリリースして発動できる。\nデッキから「夢魔鏡の夢魔－イケロス」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"夢魔鏡の黒騎士－ルペウス",h:"ゆめまきょうのくろきし－るぺうす",e:"Morpheus, the Dream Mirror Black Knight",ra:"R",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2800,de:1000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが「夢魔鏡」モンスターの効果で特殊召喚に成功した場合、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\n②：フィールドゾーンに「聖光の夢魔鏡」が存在する場合、自分・相手のメインフェイズ及びバトルフェイズに、このカードをリリースして発動できる。\nデッキから「夢魔鏡の白騎士－ルペウス」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"夢魔鏡の白騎士－ルペウス",h:"ゆめまきょうのしろきし－るぺうす",e:"Morpheus, the Dream Mirror White Knight",ra:"R",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:2800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが「夢魔鏡」モンスターの効果で特殊召喚に成功した場合に発動できる。\nこのターン、このカードは戦闘・効果では破壊されない。\n②：フィールドゾーンに「闇黒の夢魔鏡」が存在する場合、自分・相手のメインフェイズ及びバトルフェイズに、このカードをリリースして発動できる。\nデッキから「夢魔鏡の黒騎士－ルペウス」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"夢魔鏡の聖獣－パンタス",h:"ゆめまきょうのせいじゅう－ぱんたす",e:"Phantasos, the Dream Mirror Friend",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:900,de:1900,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが「夢魔鏡」モンスターの効果で特殊召喚に成功した場合、「夢魔鏡の聖獣－パンタス」以外の自分の墓地のレベル８以下の「夢魔鏡」モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\n②：フィールドゾーンに「闇黒の夢魔鏡」が存在する場合、自分・相手のメインフェイズ及びバトルフェイズに、このカードをリリースして発動できる。\nデッキから「夢魔鏡の魔獣－パンタス」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"夢魔鏡の魔獣－パンタス",h:"ゆめまきょうのまじゅう－ぱんたす",e:"Phantasos, the Dream Mirror Foe",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1900,de:900,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが「夢魔鏡」モンスターの効果で特殊召喚に成功した場合に発動できる。\nこのターン、このカードは直接攻撃できる。\n②：フィールドゾーンに「聖光の夢魔鏡」が存在する場合、自分・相手のメインフェイズ及びバトルフェイズに、このカードをリリースして発動できる。\nデッキから「夢魔鏡の聖獣－パンタス」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"夢魔鏡の夢魔－イケロス",h:"ゆめまきょうのむま－いけろす",e:"Ikelos, the Dream Mirror Mara",ra:"R",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:500,de:0,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが「夢魔鏡」モンスターの効果で特殊召喚に成功した場合に発動できる。\n手札から「夢魔鏡の夢魔－イケロス」以外の「夢魔鏡」モンスター１体を特殊召喚する。\n②：フィールドゾーンに「聖光の夢魔鏡」が存在する場合、自分・相手のメインフェイズ及びバトルフェイズに、このカードをリリースして発動できる。\nデッキから「夢魔鏡の乙女－イケロス」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"宵闇の騎士",h:"よいやみのきし",e:"Evening Twilight Knight",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:500,de:2000,tx:"「宵闇の騎士」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードを使用して儀式召喚した「カオス・ソルジャー」モンスターは以下の効果を得る。\n●１ターンに１度、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターを除外する。\n●１ターンに１度、自分メインフェイズに発動できる。\n相手の手札をランダムに１枚選び、次の相手エンドフェイズまで裏側表示で除外する。\n②：墓地のこのカードが除外された場合に発動できる。\nデッキから儀式モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"勇炎星－エンショウ",h:"ようえんせい－えんしょう",e:"Brotherhood of the Fire Fist - Gorilla",ra:"SR",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"１ターンに１度、このカードが戦闘によって相手モンスターを破壊し墓地へ送った時、デッキから「炎舞」と名のついた魔法カード１枚を選んで自分フィールド上にセットできる。\nまた、１ターンに１度、自分フィールド上に表側表示で存在する「炎舞」と名のついた魔法・罠カード１枚を墓地へ送って発動できる。\nフィールド上の魔法・罠カード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"妖海のアウトロール",h:"ようかいのあうとろーる",e:"Three Trolling Trolls",ra:"SR",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"獣戦士族",mo:["チューナー","効果"],ma:"",tp:"",at:1000,de:1300,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できず、この効果を発動するターン、自分は獣戦士族モンスターしか特殊召喚できない。\n①：このカードが召喚・特殊召喚に成功した場合、自分の墓地の獣戦士族モンスター１体を対象として発動できる。\nこのカードの属性・レベルはターン終了時まで、そのモンスターと同じになる。\n②：自分メインフェイズに発動できる。\nこのカードと同じ属性・レベルを持つ獣戦士族モンスター１体を手札から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"溶岩魔神ラヴァ・ゴーレム",h:"ようがんまじんらう゛ぁ・ごーれむ",e:"Lava Golem",ra:"UR",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"悪魔族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3000,de:2500,tx:"このカードは通常召喚できない。\n相手フィールドのモンスター２体をリリースした場合に相手フィールドに特殊召喚できる。\nこのカードを特殊召喚するターン、自分は通常召喚できない。\n①：自分スタンバイフェイズに発動する。\n自分は１０００ダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"要塞クジラ",h:"ようさいくじら",e:"Fortress Whale",ra:"N",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"魚族",mo:["儀式"],ma:"",tp:"",at:2350,de:2150,tx:"「要塞クジラの誓い」により降臨。\n場か手札から、星の数が合計７個以上になるようカードを生け贄に捧げなければならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"妖精王 アルヴェルド",h:"ようせいおう　あるう゛ぇるど",e:"Fairy King Albverdich",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"植物族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2300,de:1400,tx:"地属性レベル４モンスター×２\n１ターンに１度、このカードのエクシーズ素材を１つ取り除いて発動できる。\n地属性以外のフィールド上の全てのモンスターの攻撃力・守備力は５００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"妖精王オベロン",h:"ようせいおうおべろん",e:"Fairy King Truesdale",ra:"N",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:2200,de:1500,tx:"①：このカードがモンスターゾーンに守備表示で存在する限り、自分フィールドの植物族モンスターの攻撃力・守備力は５００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"妖精騎士イングナル",h:"ようせいきしいんぐなる",e:"Fairy Knight Ingunar",ra:"SR",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"植物族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2200,de:3000,tx:"レベル６モンスター×３\n①：１ターンに１度、このカードのX素材を２つ取り除いて発動できる。\nこのカード以外のフィールドのカードを全て持ち主の手札に戻す。\nこの効果の発動に対して相手は魔法・罠・モンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"妖精の贈りもの",h:"ようせいのおくりもの",e:"Fairy's Gift",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:1400,de:1000,tx:"誰もが幸せになれるという魔法をふりまきながら飛びまわる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"妖精竜 エンシェント",h:"ようせいりゅう　えんしぇんと",e:"Ancient Pixie Dragon",ra:"UR",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2100,de:3000,tx:"チューナー＋チューナー以外のモンスター１体以上\n自分のターンにフィールド魔法カードが発動した場合、デッキからカードを１枚ドローする。\n「妖精竜 エンシェント」のこの効果は１ターンに１度しか使用できない。\nまた、１ターンに１度、フィールド魔法カードが表側表示で存在する場合、フィールド上に表側攻撃表示で存在するモンスター１体を選択して破壊できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"妖仙獣 飯綱鞭",h:"ようせんじゅう　いずなむち",e:"Yosenju Izna",ra:"R",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:800,de:1200,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードを手札から捨てて発動できる。\nこのターン、「妖仙獣」モンスターの召喚・特殊召喚成功時に相手は魔法・罠・モンスターの効果を発動できない。\n②：自分フィールドに他の「妖仙獣」モンスターが存在する場合に発動できる。\n自分はデッキから１枚ドローする。\n③：このカードを召喚したターンのエンドフェイズに発動する。\nこのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"妖仙獣 右鎌神柱",h:"ようせんじゅう　うれんしんちゅう",e:"Yosenju Shinchu R",ra:"SR",t:"monster",a:"風",l:4,ps:5,pe:"①：１ターンに１度、もう片方の自分のPゾーンに「妖仙獣」カードが存在する場合に発動できる。\nこのカードのPスケールはターン終了時まで１１になる。\nこの効果の発動後、ターン終了時まで自分は「妖仙獣」モンスターしか特殊召喚できない。",tr:"岩石族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:0,de:2100,tx:"①：このカードが召喚に成功した場合に発動する。\nこのカードを守備表示にする。\n②：このカードがモンスターゾーンに存在する限り、相手は他の「妖仙獣」モンスターを攻撃対象にできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"妖仙獣 大幽谷響",h:"ようせんじゅう　おおやまびこ",e:"Yosenju Oyam",ra:"N",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:"?",de:"?",tx:"「妖仙獣 大幽谷響」の①の効果は１ターンに１度しか使用できない。\n①：相手モンスターの直接攻撃宣言時に手札から「妖仙獣 大幽谷響」以外の「妖仙獣」モンスター１体を墓地へ送って発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが相手モンスターと戦闘を行うダメージステップ開始時に発動できる。\nこのカードの攻撃力・守備力はターン終了時まで、戦闘を行う相手モンスターの元々の攻撃力と同じになる。\n③：このカードが戦闘で破壊され墓地へ送られた時に発動できる。\nデッキから「妖仙獣」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"妖仙獣 鎌壱太刀",h:"ようせんじゅう　かまいたち",e:"Yosenju Kama 1",ra:"SR",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:500,tx:"①：このカードが召喚に成功した場合に発動できる。\n手札から「妖仙獣 鎌壱太刀」以外の「妖仙獣」モンスター１体を召喚する。\n②：このカードがフィールドに表側表示で存在する限り１度だけ、自分フィールドにこのカード以外の「妖仙獣」モンスターが存在する場合に相手フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードを持ち主の手札に戻す。\n③：このカードを召喚したターンのエンドフェイズに発動する。\nこのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"妖仙獣 鎌弐太刀",h:"ようせんじゅう　かまにたち",e:"Yosenju Kama 2",ra:"SR",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:200,tx:"①：このカードが召喚に成功した場合に発動できる。\n手札から「妖仙獣 鎌弐太刀」以外の「妖仙獣」モンスター１体を召喚する。\n②：このカードは直接攻撃できる。\nその戦闘によって相手に与える戦闘ダメージは半分になる。\n③：このカードを召喚したターンのエンドフェイズに発動する。\nこのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"妖仙獣 鎌参太刀",h:"ようせんじゅう　かまみたち",e:"Yosenju Kama 3",ra:"SR",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:800,tx:"「妖仙獣 鎌参太刀」の②の効果は１ターンに１度しか使用できない。\n①：このカードが召喚に成功した場合に発動できる。\n手札から「妖仙獣 鎌参太刀」以外の「妖仙獣」モンスター１体を召喚する。\n②：このカード以外の自分の「妖仙獣」モンスターが相手に戦闘ダメージを与えた時に発動できる。\nデッキから「妖仙獣 鎌参太刀」以外の「妖仙獣」カード１枚を手札に加える。\n③：このカードを召喚したターンのエンドフェイズに発動する。\nこのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"妖仙獣 木魅",h:"ようせんじゅう　こだま",e:"Yosenju Kodam",ra:"N",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"「妖仙獣 木魅」の①の効果は１ターンに１度しか使用できない。\n①：このカードをリリースし、自分フィールドの「修験の妖社」１枚を対象として発動できる。\nそのカードに妖仙カウンターを３つ置く。\n②：自分メインフェイズに墓地のこのカードを除外して発動できる。\nこのターン、自分は通常召喚に加えて１度だけ、自分メインフェイズに「妖仙獣」モンスター１体を召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"妖仙獣 左鎌神柱",h:"ようせんじゅう　されんしんちゅう",e:"Yosenju Shinchu L",ra:"SR",t:"monster",a:"風",l:4,ps:3,pe:"①：自分フィールドの「妖仙獣」モンスターが戦闘・効果で破壊される場合、代わりにこのカードを破壊できる。",tr:"岩石族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:0,de:2100,tx:"①：このカードが召喚に成功した場合に発動する。\nこのカードを守備表示にする。\n②：このカードがモンスターゾーンに存在する限り、相手はこのカード以外の自分フィールドの「妖仙獣」モンスターを効果の対象にできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"妖仙獣 辻斬風",h:"ようせんじゅう　つじきりかぜ",e:"Yosenju Tsujik",ra:"R",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:0,tx:"「妖仙獣 辻斬風」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の「妖仙獣」モンスターが相手モンスターと戦闘を行うダメージステップ開始時からダメージ計算前までに、このカードを手札から捨てて発動できる。\nその自分のモンスターの攻撃力はターン終了時まで１０００アップする。\n②：フィールドの「妖仙獣」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで１０００アップする。\n③：このカードを召喚したターンのエンドフェイズに発動する。\nこのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"妖仙獣 凶旋嵐",h:"ようせんじゅう　まがつせんらん",e:"Yosenju Magat",ra:"N",t:"monster",a:"風",l:6,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:2000,de:100,tx:"①：このカードが召喚に成功した場合に発動できる。\nデッキから「妖仙獣 凶旋嵐」以外の「妖仙獣」モンスター１体を特殊召喚する。\n②：このカードを特殊召喚したターンのエンドフェイズに発動する。\nこのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"妖刀－不知火",h:"ようとう－しらぬい",e:"Shiranui Spectralsword",ra:"R",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"アンデット族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:0,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが墓地に存在する場合、チューナー以外の自分の墓地のアンデット族モンスター１体を対象として発動できる。\nそのモンスターとこのカードを墓地から除外し、その２体のレベルの合計と同じレベルを持つアンデット族Sモンスター１体をEXデッキから特殊召喚する。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:3,lk:[],ta:[],al:[],ocg:true},
  {n:"予言僧 チョウレン",h:"よげんそう　ちょうれん",e:"Chow Len the Prophet",ra:"SR",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1800,de:200,tx:"①：１ターンに１度、カードの種類（魔法・罠）を宣言し、相手の魔法＆罠ゾーンにセットされているカード１枚を対象として発動できる。\nそのセットされたカードをお互いに確認し、宣言した種類だった場合、このターンそのカードは発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"横綱犬",h:"よこちゅな",e:"Yokotuner",ra:"SR",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"獣族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:600,tx:"①：このカードが召喚に成功した時に発動できる。\n自分の手札・墓地からチューナー１体を選んで特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"蘇りし魔王 ハ・デス",h:"よみがえりしまおう　は・です",e:"Revived King Ha Des",ra:"R",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"アンデット族",mo:["シンクロ","効果"],ma:"",tp:"",at:2450,de:0,tx:"「ゾンビキャリア」＋チューナー以外のアンデット族モンスター１体以上\nこのカードがフィールド上に表側表示で存在する限り、自分フィールド上のアンデット族モンスターが戦闘で破壊した効果モンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"黄泉ガエル",h:"よみがえる",e:"Treeborn Frog",ra:"UR",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"①：このカードが墓地に存在し、自分フィールドに「黄泉ガエル」が存在しない場合、自分スタンバイフェイズに発動できる。\nこのカードを特殊召喚する。\nこの効果は自分フィールドに魔法・罠カードが存在しない場合に発動と処理ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"黄泉へ渡る船",h:"よみへわたるふね",e:"Yomi Ship",ra:"SR",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:800,de:1400,tx:"①：このカードが戦闘で破壊され墓地へ送られた場合に発動する。\nこのカードを破壊したモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヨルムンガルド",h:"よるむんがるど",e:"Yormungarde",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"爬虫類族",mo:["通常"],ma:"",tp:"",at:1200,de:900,tx:"神話の世界に出てくるヘビ。\n非常に長い。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"鎧ネズミ",h:"よろいねずみ",e:"Armored Rat",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:950,de:1100,tx:"鎧のようにかたい毛で体を守ることができるネズミ。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"鎧武者斬鬼",h:"よろいむしゃざんき",e:"Zanki",ra:"N",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:1500,de:1700,tx:"一騎打ちを好む。\n一瞬のスキをついて、居合い抜きで攻撃！",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"鎧武者ゾンビ",h:"よろいむしゃぞんび",e:"Armored Zombie",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["通常"],ma:"",tp:"",at:1500,de:0,tx:"怨念により蘇った武者。\n闇雲にふりまわすカタナに注意。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ラージマウス",h:"らーじまうす",e:"Ooguchi",ra:"N",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:300,de:250,tx:"このカードは相手プレイヤーを直接攻撃する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ラーの使徒",h:"らーのしと",e:"Ra's Disciple",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1100,de:600,tx:"①：このカードが召喚・反転召喚・特殊召喚に成功した時に発動できる。\n手札・デッキから「ラーの使徒」を２体まで特殊召喚する。\n②：このカードがモンスターゾーンに存在する限り、自分は「ラーの使徒」の効果でしかモンスターを特殊召喚できず、このカードは「オシリスの天空竜」「オベリスクの巨神兵」「ラーの翼神竜」のアドバンス召喚以外のためにはリリースできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ラーの翼神竜",h:"らーのよくしんりゅう",e:"The Winged Dragon of Ra",ra:"UR",t:"monster",a:"神",l:10,ps:"",pe:"",tr:"幻神獣族",mo:["効果"],ma:"",tp:"",at:"?",de:"?",tx:"このカードは特殊召喚できない。\nこのカードを通常召喚する場合、３体をリリースして召喚しなければならない。\n①：このカードの召喚は無効化されない。\n②：このカードの召喚成功時には、このカード以外の魔法・罠・モンスターの効果は発動できない。\n③：このカードが召喚に成功した時、１００LPになるようにLPを払って発動できる。\nこのカードの攻撃力・守備力は払った数値分アップする。\n④：１０００LPを払い、フィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ラーバス",h:"らーばす",e:"Larvas",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:800,de:1000,tx:"素早く動く鳥のばけもの。\n細く長い腕をからませ絞めあげる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ラーバモス",h:"らーばもす",e:"Larvae Moth",ra:"R",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"昆虫族",mo:["特殊召喚","効果"],ma:"",tp:"",at:500,de:400,tx:"このカードは通常召喚できない。\n「進化の繭」が装備され、自分のターンで数えて２ターン以上が経過した「プチモス」１体をリリースした場合に特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"RAI－JIN",h:"らい－じん",e:"Rai-Jin",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:"?",de:1000,tx:"このカードがフィールド上に表側表示で存在する限り、自分フィールド上の光属性モンスターの攻撃力は、自分の墓地の光属性モンスターの数×１００ポイントアップする。\nまた、自分のエンドフェイズ毎に発動する。\n自分フィールド上の光属性モンスター１体を選択して破壊する。\n「RAI－JIN」はフィールド上に１体しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"RAI－MEI",h:"らい－めい",e:"Rai-Mei",ra:"N",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:1400,de:1200,tx:"このカードが戦闘によって破壊され墓地へ送られた時、デッキからレベル２以下の光属性モンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ライオ・アリゲーター",h:"らいお・ありげーたー",e:"Lion Alligator",ra:"SR",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1900,de:200,tx:"自分フィールド上にこのカード以外の爬虫類族モンスターが存在する場合、自分フィールド上に存在する爬虫類族モンスターが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ライカン・スロープ",h:"らいかん・すろーぷ",e:"Lycanthrope",ra:"R",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"獣戦士族",mo:["儀式","効果"],ma:"",tp:"",at:2400,de:1800,tx:"「合成魔術」により降臨。\nこのカードが相手ライフに戦闘ダメージを与えた時、自分の墓地に存在する通常モンスターの数×２００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"雷源龍－サンダー・ドラゴン",h:"らいげんりゅう－さんだー・どらごん",e:"Thunder Dragonmatrix",ra:"SR",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:0,de:2000,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードを手札から捨て、自分フィールドの雷族モンスター１体を対象として発動できる。\nそのモンスターの攻撃力は５００アップする。\nこの効果は相手ターンでも発動できる。\n②：このカードが除外された場合またはフィールドから墓地へ送られた場合に発動できる。\nデッキから「雷源龍－サンダー・ドラゴン」１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"雷劫龍－サンダー・ドラゴン",h:"らいごうりゅう－さんだー・どらごん",e:"Thunder Dragonduo",ra:"SR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"雷族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2800,de:0,tx:"このカードは通常召喚できない。\n自分の墓地から光属性と闇属性のモンスターを１体ずつ除外した場合に特殊召喚できる。\n①：１ターンに１度、モンスターの効果が手札で発動した場合に発動する。\nこのカードの攻撃力はターン終了時まで３００アップする。\n②：このカードが戦闘で相手モンスターを破壊した時、自分の墓地からカード１枚を除外して発動できる。\nデッキから雷族モンスター１体を手札に加える。\n③：相手エンドフェイズに、除外されている自分のカード１枚を対象として発動できる。\nそのカードをデッキの一番上または一番下に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"雷獣龍－サンダー・ドラゴン",h:"らいじゅうりゅう－さんだー・どらごん",e:"Thunder Dragonroar",ra:"SR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:2400,de:0,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードを手札から捨てて発動できる。\n自分の墓地のカード及び除外されている自分のカードの中から、「雷獣龍－サンダー・ドラゴン」以外の「サンダー・ドラゴン」カード１枚を選んで手札に加える。\n②：このカードが除外された場合またはフィールドから墓地へ送られた場合に発動できる。\nデッキから「サンダー・ドラゴン」モンスター１体を守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターはエンドフェイズに持ち主の手札に戻る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"雷神の怒り",h:"らいじんのいかり",e:"Kaminari Attack",ra:"N",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"雷族",mo:["融合"],ma:"",tp:"",at:1900,de:1400,tx:"「エンゼル・イヤーズ」＋「メガ・サンダーボール」",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"雷鳥龍－サンダー・ドラゴン",h:"らいちょうりゅう－さんだー・どらごん",e:"Thunder Dragonhawk",ra:"UR",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:1800,de:2200,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードを手札から捨てて発動できる。\n自分の墓地のモンスター及び除外されている自分のモンスターの中から、「雷鳥龍－サンダー・ドラゴン」以外の「サンダー・ドラゴン」モンスター１体を選んで特殊召喚する。\n②：このカードが除外された場合またはフィールドから墓地へ送られた場合に発動できる。\n自分の手札を任意の数だけデッキに戻してシャッフルする。\nその後、自分はデッキに戻した数だけデッキからドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ライティ・ドライバー",h:"らいてぃ・どらいばー",e:"Righty Driver",ra:"SR",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:100,de:300,tx:"このカードは「シンクロン」チューナーの代わりとしてS素材にできる。\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが召喚に成功した場合に発動できる。\n自分の手札・デッキ・墓地から「レフティ・ドライバー」１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"雷帝家臣ミスラ",h:"らいていかしんみすら",e:"Mithra the Thunder Vassal",ra:"SR",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:800,de:1000,tx:"「雷帝家臣ミスラ」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\nこのカードを手札から特殊召喚し、相手フィールドに「家臣トークン」（雷族・光・星１・攻８００／守１０００）１体を守備表示で特殊召喚する。\nこのターン、自分はエクストラデッキからモンスターを特殊召喚できない。\n②：このカードがアドバンス召喚のためにリリースされた場合に発動できる。\nこのターン、自分は通常召喚に加えて１度だけ、自分メインフェイズにアドバンス召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"雷帝ザボルグ",h:"らいていざぼるぐ",e:"Zaborg the Thunder Monarch",ra:"SR",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:2400,de:1000,tx:"①：このカードがアドバンス召喚に成功した場合、フィールドのモンスター１体を対象として発動する。\nそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"雷天気ターメル",h:"らいてんきたーめる",e:"The Weather Painter Thunder",ra:"R",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1700,de:0,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分フィールドの表側表示の永続魔法・永続罠カード１枚を墓地へ送って発動できる。\nデッキから「天気」魔法・罠カード１枚を選んで自分の魔法＆罠ゾーンに表側表示で置く。\n②：フィールドのこのカードが「天気」カードの効果を発動するために除外された場合、次のターンのスタンバイフェイズに発動できる。\n除外されているこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"雷電娘々",h:"らいでんにゃんにゃん",e:"Thunder Nyan Nyan",ra:"UR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:1900,de:800,tx:"自分フィールド上に光属性以外の表側表示モンスターが存在する場合、表側表示のこのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"雷電龍－サンダー・ドラゴン",h:"らいでんりゅう－さんだー・どらごん",e:"Thunder Dragondark",ra:"UR",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:1600,de:1500,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードを手札から捨てて発動できる。\nデッキから「雷電龍－サンダー・ドラゴン」１体を手札に加える。\nこの効果は相手ターンでも発動できる。\n②：このカードが除外された場合またはフィールドから墓地へ送られた場合に発動できる。\nデッキから「雷電龍－サンダー・ドラゴン」以外の「サンダー・ドラゴン」カード１枚を手札に加える。",li:3,lk:[],ta:[],al:[],ocg:true},
  {n:"ライト・サーペント",h:"らいと・さーぺんと",e:"Lightserpent",ra:"N",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1200,de:800,tx:"このカードが手札から墓地へ送られた場合、このカードを墓地から特殊召喚する事ができる。\nこのカードはシンクロ素材とする事はできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ライトエンド・ドラゴン",h:"らいとえんど・どらごん",e:"Light End Dragon",ra:"N",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2600,de:2100,tx:"チューナー＋チューナー以外の光属性モンスター１体以上\nこのカードが相手モンスターと戦闘を行う攻撃宣言時に発動できる。\nこのカードの攻撃力・守備力を５００ポイントダウンし、戦闘を行う相手モンスターの攻撃力・守備力はエンドフェイズ時まで１５００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ライトニング・ウォリアー",h:"らいとにんぐ・うぉりあー",e:"Lightning Warrior",ra:"UR",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2400,de:1200,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカードが戦闘によって相手モンスターを破壊し墓地へ送った時、相手の手札の枚数×３００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"光神機－桜火",h:"らいとにんぐぎあ－おうか",e:"Majestic Mech - Ohka",ra:"R",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2400,de:1400,tx:"このカードは生け贄なしで召喚する事ができる。\nこの方法で召喚した場合、このカードはエンドフェイズ時に墓地へ送られる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"光神機－轟龍",h:"らいとにんぐぎあ－ごうりゅう",e:"Majestic Mech - Goryu",ra:"N",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2900,de:1800,tx:"このカードは生け贄１体で召喚する事ができる。\nこの方法で召喚した場合、このカードはエンドフェイズ時に墓地へ送られる。\nまた、このカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が越えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"光神機－閃空",h:"らいとにんぐぎあ－せんくう",e:"Majestic Mech - Senku",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1000,de:500,tx:"このカードが直接攻撃によって相手ライフに戦闘ダメージを与えた時、自分のデッキからカードを１枚ドローする。\nこのカードが召喚・反転召喚・特殊召喚したターンのエンドフェイズ時、このカードを墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ライトニングパニッシャー",h:"らいとにんぐぱにっしゃー",e:"Lightning Punisher",ra:"N",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:2600,de:1600,tx:"チェーンが３つ以上積まれた場合に発動する。\n相手フィールド上のカード１枚を選択して破壊する。\n同一チェーン上に複数回同名カードの効果が発動している場合、この効果は発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ライトパルサー・ドラゴン",h:"らいとぱるさー・どらごん",e:"Lightpulsar Dragon",ra:"SR",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2500,de:1500,tx:"このカードは自分の墓地の光属性と闇属性のモンスターを１体ずつゲームから除外し、手札から特殊召喚できる。\nまた、手札の光属性と闇属性のモンスターを１体ずつ墓地へ送り、このカードを自分の墓地から特殊召喚できる。\nこのカードがフィールド上から墓地へ送られた時、自分の墓地のドラゴン族・闇属性・レベル５以上のモンスター１体を選択して特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ライトレイ ギア・フリード",h:"らいとれい　ぎあ・ふりーど",e:"Lightray Gearfried",ra:"R",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2800,de:2200,tx:"このカードは通常召喚できない。\n自分の墓地の光属性モンスターが５種類以上の場合のみ特殊召喚できる。\n自分フィールド上に表側表示で存在するモンスターが戦士族のみの場合、自分の墓地の戦士族モンスター１体をゲームから除外する事で、魔法・罠カードの発動を無効にし破壊する。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ライトレイ グレファー",h:"らいとれい　ぐれふぁー",e:"Lightray Grepher",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:1600,tx:"このカードは手札からレベル５以上の光属性モンスター１体を捨てて、手札から特殊召喚できる。\n１ターンに１度、手札から光属性モンスター１体を捨てて発動できる。\nデッキから光属性モンスター１体を選び、ゲームから除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ライトレイ ソーサラー",h:"らいとれい　そーさらー",e:"Lightray Sorcerer",ra:"N",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2300,de:2000,tx:"このカードは通常召喚できない。\nゲームから除外されている自分の光属性モンスターが３体以上の場合のみ特殊召喚できる。\n１ターンに１度、ゲームから除外されている自分の光属性モンスター１体を選択してデッキに戻し、フィールド上に表側表示で存在するモンスター１体を選択してゲームから除外できる。\nこの効果を発動するターン、このカードは攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ライトレイ ディアボロス",h:"らいとれい　でぃあぼろす",e:"Lightray Diabolos",ra:"R",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2800,de:1000,tx:"このカードは通常召喚できない。\n自分の墓地の光属性モンスターが５種類以上の場合に特殊召喚できる。\n１ターンに１度、自分の墓地の光属性モンスター１体をゲームから除外して発動できる。\n相手フィールド上にセットされたカード１枚を選択して確認し、持ち主のデッキの一番上または一番下に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ライトレイ マドール",h:"らいとれい　まどーる",e:"Lightray Madoor",ra:"SR",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1200,de:3000,tx:"ゲームから除外されている自分の光属性モンスターが３体以上の場合、このカードは手札から特殊召喚できる。\nこのカードは１ターンに１度だけ、戦闘では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ライトロード・アーク ミカエル",h:"らいとろーど・あーく　みかえる",e:"Michael, the Arch-Lightsworn",ra:"UR",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2600,de:2000,tx:"チューナー＋チューナー以外の光属性モンスター１体以上\n①：１ターンに１度、１０００LPを払い、フィールドのカード１枚を対象として発動できる。\nそのカードを除外する。\n②：このカードが破壊された時、このカード以外の自分の墓地の「ライトロード」モンスターを任意の数だけ対象として発動できる。\nそのモンスターをデッキに戻し、自分は戻した数×３００LP回復する。\n③：自分エンドフェイズに発動する。\n自分のデッキの上からカードを３枚墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ライトロード・アーチャー フェリス",h:"らいとろーど・あーちゃー　ふぇりす",e:"Felis, Lightsworn Archer",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["特殊召喚","チューナー","効果"],ma:"",tp:"",at:1100,de:2000,tx:"このカードは通常召喚できず、カードの効果でのみ特殊召喚できる。\n①：このカードがモンスターの効果でデッキから墓地へ送られた場合に発動する。\nこのカードを特殊召喚する。\n②：このカードをリリースし、相手フィールドのモンスター１体を対象として発動できる。\nその相手モンスターを破壊する。\nその後、自分のデッキの上からカードを３枚墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ライトロード・アサシン ライデン",h:"らいとろーど・あさしん　らいでん",e:"Raiden, Hand of the Lightsworn",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["チューナー","効果"],ma:"",tp:"",at:1700,de:1000,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\n自分のデッキの上からカードを２枚墓地へ送る。\nこの効果で墓地へ送ったカードの中に「ライトロード」モンスターがあった場合、さらにこのカードの攻撃力は相手ターン終了時まで２００アップする。\n②：自分エンドフェイズに発動する。\n自分のデッキの上からカードを２枚墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ライトロード・ウォリアー ガロス",h:"らいとろーど・うぉりあー　がろす",e:"Garoth, Lightsworn Warrior",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1850,de:1300,tx:"「ライトロード・ウォリアー ガロス」以外の自分フィールド上の「ライトロード」と名のついたモンスターの効果によって自分のデッキからカードが墓地へ送られた場合、自分のデッキの上からカードを２枚墓地へ送る。\nその後、この効果で墓地へ送られた「ライトロード」と名のついたモンスターの数だけデッキからカードをドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ライトロード・エンジェル ケルビム",h:"らいとろーど・えんじぇる　けるびむ",e:"Celestia, Lightsworn Angel",ra:"UR",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2300,de:200,tx:"「ライトロード」と名のついたモンスターをリリースしてこのカードのアドバンス召喚に成功した時、自分のデッキの上からカードを４枚墓地へ送って発動できる。\n相手フィールド上のカードを２枚まで選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ライトロード・サモナー ルミナス",h:"らいとろーど・さもなー　るみなす",e:"Lumina, Lightsworn Summoner",ra:"R",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"①：１ターンに１度、手札を１枚捨て、自分の墓地のレベル４以下の「ライトロード」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n②：自分エンドフェイズに発動する。\n自分のデッキの上からカードを３枚墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ライトロード・シーフ ライニャン",h:"らいとろーど・しーふ　らいにゃん",e:"Rinyan, Lightsworn Rogue",ra:"N",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"獣族",mo:["リバース","効果"],ma:"",tp:"",at:100,de:100,tx:"リバース：自分の墓地に存在する「ライトロード」と名のついたモンスター１体を選択してデッキに戻し、自分のデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ライトロード・スピリット シャイア",h:"らいとろーど・すぴりっと　しゃいあ",e:"Shire, Lightsworn Spirit",ra:"N",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:400,de:1400,tx:"このカードの攻撃力は、自分の墓地に存在する「ライトロード」と名のついたモンスターの種類×３００ポイントアップする。\nこのカードが自分フィールド上に表側表示で存在する限り、自分のエンドフェイズ毎に、自分のデッキの上からカードを２枚墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ライトロード・ドラゴン グラゴニス",h:"らいとろーど・どらごん　ぐらごにす",e:"Gragonith, Lightsworn Dragon",ra:"R",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:2000,de:1600,tx:"このカードの攻撃力・守備力は、自分の墓地の「ライトロード」と名のついたモンスターの種類×３００ポイントアップする。\nこのカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。\nまた、自分のエンドフェイズ毎に発動する。\n自分のデッキの上からカードを３枚墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ライトロード・ドルイド オルクス",h:"らいとろーど・どるいど　おるくす",e:"Aurkus, Lightsworn Druid",ra:"N",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:1800,tx:"このカードがフィールド上に表側表示で存在する限り、お互いのプレイヤーは「ライトロード」と名のついたモンスターを魔法・罠・効果モンスターの効果の対象にできない。\nまた、自分のエンドフェイズ毎に発動する。\n自分のデッキの上からカードを２枚墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ライトロード・パラディン ジェイン",h:"らいとろーど・ぱらでぃん　じぇいん",e:"Jain, Lightsworn Paladin",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1200,tx:"①：このカードが相手モンスターに攻撃するダメージステップの間、このカードの攻撃力は３００アップする。\n②：自分エンドフェイズに発動する。\n自分のデッキの上からカードを２枚墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ライトロード・ハンター ライコウ",h:"らいとろーど・はんたー　らいこう",e:"Ryko, Lightsworn Hunter",ra:"SR",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"獣族",mo:["リバース","効果"],ma:"",tp:"",at:200,de:100,tx:"①：このカードがリバースした場合に発動する。\nフィールドのカード１枚を選んで破壊できる。\n自分のデッキの上からカードを３枚墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ライトロード・ビースト ウォルフ",h:"らいとろーど・びーすと　うぉるふ",e:"Wulf, Lightsworn Beast",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2100,de:300,tx:"このカードは通常召喚できず、カードの効果でのみ特殊召喚できる。\nこのカードがデッキから墓地へ送られた時に発動する。\nこのカードを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ライトロード・プリースト ジェニス",h:"らいとろーど・ぷりーすと　じぇにす",e:"Jenis, Lightsworn Mender",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:300,de:2100,tx:"「ライトロード」と名のついたカードの効果によって自分のデッキからカードが墓地へ送られたターンのエンドフェイズ時、相手ライフに５００ポイントダメージを与え、自分は５００ライフポイント回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ライトロード・マジシャン ライラ",h:"らいとろーど・まじしゃん　らいら",e:"Lyla, Lightsworn Sorceress",ra:"UR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1700,de:200,tx:"①：自分メインフェイズに相手フィールドの魔法・罠カード１枚を対象として発動できる。\n自分フィールドの表側攻撃表示のこのカードを守備表示にし、対象の相手のカードを破壊する。\nこの効果の発動後、次の自分ターンの終了時までこのカードは表示形式を変更できない。\n②：自分エンドフェイズに発動する。\n自分のデッキの上からカードを３枚墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ライトロード・メイデン ミネルバ",h:"らいとろーど・めいでん　みねるば",e:"Minerva, Lightsworn Maiden",ra:"N",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["チューナー","効果"],ma:"",tp:"",at:800,de:200,tx:"①：このカードが召喚に成功した時に発動できる。\n自分の墓地の「ライトロード」モンスターの種類の数以下のレベルを持つドラゴン族・光属性モンスター１体をデッキから手札に加える。\n②：このカードが手札・デッキから墓地へ送られた場合に発動する。\n自分のデッキの上からカードを１枚墓地へ送る。\n③：自分エンドフェイズに発動する。\n自分のデッキの上からカードを２枚墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ライトロード・モンク エイリン",h:"らいとろーど・もんく　えいりん",e:"Ehren, Lightsworn Monk",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"①：このカードが守備表示モンスターを攻撃したダメージ計算前に発動する。\nそのモンスターを持ち主のデッキに戻す。\n②：自分エンドフェイズに発動する。\n自分のデッキの上からカードを３枚墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ライドロン",h:"らいどろん",e:"Leotron",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"サイバース族",mo:["通常"],ma:"",tp:"",at:2000,de:0,tx:"高い適合能力を持った電子獣。\n縄張り意識が強い。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ライノタウルス",h:"らいのたうるす",e:"Rhinotaurus",ra:"R",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:600,tx:"１度のバトルフェイズ中に、自分フィールド上に存在するモンスターの戦闘によって相手モンスターを２体以上破壊した場合、このカードはそのバトルフェイズ中に２回攻撃する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ライフ・コーディネイター",h:"らいふ・こーでぃねいたー",e:"Lifeforce Harmonizer",ra:"R",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:800,de:400,tx:"相手が「ライフポイントにダメージを与える効果」を持つカードを発動した時、手札からこのカードを捨てる事でその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"雷魔神－サンガ",h:"らいましん－さんが",e:"Sanga of the Thunder",ra:"R",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:2600,de:2200,tx:"このカードが相手のターンで攻撃された場合、そのダメージ計算時に発動する事ができる。\nその攻撃モンスター１体の攻撃力を０にする。\nこの効果はこのカードがフィールド上に表側表示で存在する限り１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ライライダー",h:"らいらいだー",e:"Rai Rider",ra:"N",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:1200,de:1400,tx:"このカードが相手モンスターと戦闘を行った場合、そのモンスターはフィールド上に表側表示で存在する限り攻撃する事ができない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ラインモンスター Kホース",h:"らいんもんすたー　けいほーす",e:"Shogi Knight",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:800,de:400,tx:"このカードが召喚に成功した時、相手の魔法＆罠カードゾーンにセットされているカード１枚を選択して発動できる。\nセットされているそのカードを確認し、罠カードだった場合、そのカードを破壊する。\n違った場合、元に戻す。\nこの効果で罠カードを破壊した時、以下の効果を発動できる。\n●手札から地属性・レベル３モンスター１体を表側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ラインモンスター スピア・ホイール",h:"らいんもんすたー　すぴあ・ほいーる",e:"Shogi Lance",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:500,de:500,tx:"１ターンに１度、このカード以外の自分フィールド上の獣戦士族・レベル３モンスター１体を選択して発動できる。\n選択したモンスターとこのカードは、それぞれのレベルを合計したレベルになる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ラヴァ・ドラゴン",h:"らう゛ぁ・どらごん",e:"Lava Dragon",ra:"R",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"自分フィールド上に表側守備表示で存在するこのカードをリリースして発動する。\n自分の手札及び墓地からレベル３以下のドラゴン族モンスターをそれぞれ１体ずつ特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ラヴァル・ウォリアー",h:"らう゛ぁる・うぉりあー",e:"Laval Warrior",ra:"N",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:500,tx:"このカードが戦闘によって相手モンスターを破壊した場合に自分の墓地の「ラヴァル」と名のついたモンスターが４種類以上の場合、その戦闘で破壊したモンスターの元々の攻撃力分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ラヴァル・ガンナー",h:"らう゛ぁる・がんなー",e:"Laval Blaster",ra:"N",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:800,tx:"このカードが召喚に成功した時、自分の墓地に「ラヴァル・ガンナー」以外の「ラヴァル」と名のついたモンスターが存在する場合、デッキの上からカードを５枚まで墓地へ送って発動できる。\nこのカードの攻撃力は、この効果を発動するために墓地へ送った「ラヴァル」と名のついたモンスターの数×２００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ラヴァル・キャノン",h:"らう゛ぁる・きゃのん",e:"Laval Cannon",ra:"R",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:900,tx:"このカードが召喚・反転召喚に成功した時、ゲームから除外されている自分の「ラヴァル」と名のついたモンスター１体を選択して特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ラヴァル・グレイター",h:"らう゛ぁる・ぐれいたー",e:"Laval the Greater",ra:"N",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2400,de:800,tx:"チューナー＋チューナー以外の炎属性モンスター１体以上\nこのカードがシンクロ召喚に成功した時、自分は手札を１枚墓地へ送る。\nこのカードがカードの効果によって破壊される場合、代わりに自分の墓地の「ラヴァル」と名のついたモンスター１体をゲームから除外できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ラヴァル・コアトル",h:"らう゛ぁる・こあとる",e:"Laval Coatl",ra:"N",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"炎族",mo:["チューナー","効果"],ma:"",tp:"",at:1300,de:700,tx:"自分の墓地の「ラヴァル」と名のついたモンスターが３種類以上の場合、このカードは手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ラヴァル・ステライド",h:"らう゛ぁる・すてらいど",e:"Laval Stennon",ra:"N",t:"monster",a:"炎",l:7,ps:"",pe:"",tr:"炎族",mo:["シンクロ","効果"],ma:"",tp:"",at:2700,de:1800,tx:"チューナー＋チューナー以外の炎属性モンスター１体以上\nこのカードがシンクロ召喚に成功した時、自分は手札を１枚墓地へ送る。\nこのカードがカードの効果の対象になった時、自分の墓地の「ラヴァル」と名のついたモンスター１体をゲームから除外する事で、その発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ラヴァル・ツインスレイヤー",h:"らう゛ぁる・ついんすれいやー",e:"Laval Dual Slasher",ra:"R",t:"monster",a:"炎",l:5,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:2400,de:200,tx:"チューナー＋チューナー以外の炎属性モンスター１体以上\n自分の墓地に存在する「ラヴァル」と名のついたモンスターの数によって、このカードは以下の効果を得る。\n●２体以上：このカードが守備表示モンスターを攻撃した場合、もう１度だけ続けて攻撃する事ができる。\n●３体以上：このカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ラヴァル・フロギス",h:"らう゛ぁる・ふろぎす",e:"Laval Phlogis",ra:"N",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:1700,de:800,tx:"このカードが墓地へ送られた時、自分フィールド上の全ての「ラヴァル」と名のついたモンスターの攻撃力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ラヴァル・ランスロッド",h:"らう゛ぁる・らんすろっど",e:"Laval Lancelord",ra:"R",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2100,de:200,tx:"このカードはリリースなしで召喚できる。\nこの方法で召喚したこのカードは、エンドフェイズ時に墓地へ送られる。\nフィールド上のこのカードが破壊され墓地へ送られた時、ゲームから除外されている自分の炎属性モンスター１体を選択して手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ラヴァル炎火山の侍女",h:"らう゛ぁるえんかざんのじじょ",e:"Laval Volcano Handmaiden",ra:"R",t:"monster",a:"炎",l:1,ps:"",pe:"",tr:"炎族",mo:["チューナー","効果"],ma:"",tp:"",at:100,de:200,tx:"①：このカードが墓地へ送られた時、自分の墓地に「ラヴァル炎火山の侍女」以外の「ラヴァル」モンスターが存在する場合に発動できる。\nデッキから「ラヴァル」モンスター１体を墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ラヴァル炎湖畔の淑女",h:"らう゛ぁるえんこはんのしゅくじょ",e:"Laval Lakeside Lady",ra:"SR",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"炎族",mo:["チューナー","効果"],ma:"",tp:"",at:200,de:200,tx:"自分の墓地の「ラヴァル」と名のついたモンスターが３種類以上の場合、自分の墓地のこのカードと「ラヴァル」と名のついたモンスター１体をゲームから除外して発動できる。\n相手フィールド上にセットされたカード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ラヴァル炎樹海の妖女",h:"らう゛ぁるえんじゅかいのようじょ",e:"Laval Forest Sprite",ra:"N",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"炎族",mo:["チューナー","効果"],ma:"",tp:"",at:300,de:200,tx:"このカードがフィールド上から墓地へ送られた時、自分フィールド上の全ての「ラヴァル」と名のついたモンスターの攻撃力はエンドフェイズ時まで、自分の墓地の「ラヴァル」と名のついたモンスターの数×２００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ラヴァルの炎車回し",h:"らう゛ぁるのえんしゃまわし",e:"Laval Miller",ra:"N",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:300,de:400,tx:"このカードが戦闘によって破壊され墓地へ送られた時、デッキから「ラヴァル」と名のついたモンスター２体を墓地へ送る事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ラヴァルのマグマ砲兵",h:"らう゛ぁるのまぐまほうへい",e:"Laval Magma Cannoneer",ra:"SR",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:1700,de:200,tx:"手札から炎属性モンスター１体を墓地へ送って発動する。\n相手ライフに５００ポイントダメージを与える。\nこの効果は１ターンに２度まで使用できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ラヴァルバーナー",h:"らう゛ぁるばーなー",e:"Laval Burner",ra:"R",t:"monster",a:"炎",l:5,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:2100,de:1000,tx:"自分の墓地の「ラヴァル」と名のついたモンスターが３種類以上の場合、このカードは手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ラヴァルバル・イグニス",h:"らう゛ぁるばる・いぐにす",e:"Lavalval Ignis",ra:"R",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1800,de:1400,tx:"レベル３モンスター×２\nこのカードが戦闘を行うダメージステップ時に１度だけ、このカードのエクシーズ素材を１つ取り除いて発動できる。\nこのカードの攻撃力はエンドフェイズ時まで５００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ラヴァルバル・ドラグーン",h:"らう゛ぁるばる・どらぐーん",e:"Lavalval Dragun",ra:"R",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:1200,tx:"チューナー＋チューナー以外の炎属性モンスター１体以上\n①：１ターンに１度、自分メインフェイズに発動できる。\nデッキから「ラヴァル」モンスター１体を手札に加える。\nその後、手札から「ラヴァル」モンスター１体を選んで墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ラヴァルバル・ドラゴン",h:"らう゛ぁるばる・どらごん",e:"Lavalval Dragon",ra:"UR",t:"monster",a:"炎",l:5,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2000,de:1100,tx:"チューナー＋チューナー以外の炎属性モンスター１体以上\n①：自分の墓地の「ラヴァル」モンスター２体をデッキに戻し、相手フィールドのカード１枚を対象として発動できる。\nそのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ラヴァルロード・ジャッジメント",h:"らう゛ぁるろーど・じゃっじめんと",e:"Laval Judgment Lord",ra:"R",t:"monster",a:"炎",l:7,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2700,de:1800,tx:"１ターンに１度、自分の墓地の「ラヴァル」と名のついたモンスター１体をゲームから除外して発動できる。\n相手ライフに１０００ポイントダメージを与える。\nこの効果を発動するターン、「ラヴァルロード・ジャッジメント」は攻撃宣言できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ラッコアラ",h:"らっこあら",e:"Tree Otter",ra:"N",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1200,de:100,tx:"このカード以外の獣族モンスターが自分フィールド上に表側表示で存在する場合、自分フィールド上に表側表示で存在するモンスター１体の攻撃力をエンドフェイズ時まで１０００ポイントアップする事ができる。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ラッシュ・ウォリアー",h:"らっしゅ・うぉりあー",e:"Rush Warrior",ra:"SR",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:300,de:1200,tx:"「ラッシュ・ウォリアー」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の「ウォリアー」Sモンスターが相手モンスターと戦闘を行うダメージ計算時、このカードを手札から墓地へ送って発動できる。\nその戦闘を行う自分のモンスターの攻撃力は、そのダメージ計算時のみ倍になる。\n②：墓地のこのカードを除外し、自分の墓地の「シンクロン」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ラビードラゴン",h:"らびーどらごん",e:"Rabidragon",ra:"N",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:2950,de:2900,tx:"雪原に生息するドラゴンの突然変異種。\n巨大な耳は数キロ離れた物音を聴き分け、驚異的な跳躍力と相俟って狙った獲物は逃さない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ラピッド・ウォリアー",h:"らぴっど・うぉりあー",e:"Rapid Warrior",ra:"N",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1200,de:200,tx:"メインフェイズ１でのみ発動する事ができる。\nこのターンこのカードは相手プレイヤーに直接攻撃する事ができる。\nこの効果を発動するターン、このカード以外のモンスターは攻撃する事ができない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ラブラドライドラゴン",h:"らぶらどらいどらごん",e:"Labradorite Dragon",ra:"R",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["チューナー","通常"],ma:"",tp:"",at:0,de:2400,tx:"ラブラドレッセンスと呼ばれる特有の美しい輝きを放つウロコを持ったドラゴン。\n\nそのウロコから生まれる眩い輝きは、見た者の魂を導き、感情を解放させる力を持つ。\n\n――その光は前世の記憶を辿り、人々を巡り合わせると伝えられる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ラムーン",h:"らむーん",e:"LaMoon",ra:"N",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:1200,de:1700,tx:"月に住む魔法使い。\n月の持つ魔力で相手を魅了する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ララ・ライウーン",h:"らら・らいうーん",e:"LaLa Li-oon",ra:"N",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"雷族",mo:["通常"],ma:"",tp:"",at:600,de:600,tx:"電気を帯びた雲形のモンスター。\n何でも溶かす危険な雨を降らせる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ランサー・デーモン",h:"らんさー・でーもん",e:"Lancer Archfiend",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1600,de:1400,tx:"相手フィールド上に守備表示で存在するモンスターを攻撃対象とした自分のモンスターの攻撃宣言時に発動する事ができる。\nそのモンスターが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ランサー・ドラゴニュート",h:"らんさー・どらごにゅーと",e:"Lancer Dragonute",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1500,de:1800,tx:"①：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ランス・リンドブルム",h:"らんす・りんどぶるむ",e:"Lancer Lindwurm",ra:"SR",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1800,de:1200,tx:"①：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ランタン・シャーク",h:"らんたん・しゃーく",e:"Lantern Shark",ra:"UR",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:1500,de:900,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\n手札から「ランタン・シャーク」以外のレベル３～５の水属性モンスター１体を守備表示で特殊召喚する。\nこの効果の発動後、ターン終了時まで自分はXモンスターしかEXデッキから特殊召喚できない。\n②：このカードを水属性モンスターのX召喚に使用する場合、このカードのレベルを３または５として扱う事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ランチャー・コマンダー",h:"らんちゃー・こまんだー",e:"Launcher Commander",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1700,de:1200,tx:"①：このカードがモンスターゾーンに存在する限り、このカード以外の自分フィールドのサイバース族モンスターの攻撃力・守備力は３００アップする。\n②：１ターンに１度、自分フィールドのサイバース族モンスター１体をリリースし、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ランドスターの剣士",h:"らんどすたーのけんし",e:"Swordsman of Landstar",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:500,de:1200,tx:"剣の腕は未熟だが、不思議な能力を持つ妖精剣士。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ランプの魔人",h:"らんぷのまじん",e:"Lord of the Lamp",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1400,de:1200,tx:"魔法のランプから現れる魔人。\n呼び出した者に服従する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ランプの魔精・ラ・ジーン",h:"らんぷのませい・ら・じーん",e:"La Jinn the Mystical Genie of the Lamp",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1800,de:1000,tx:"呼び出した主人の言うことを、何でも聞いてくれるランプの精。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"リ・バイブル",h:"り・ばいぶる",e:"Re-Cover",ra:"N",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"サイキック族",mo:["チューナー","効果"],ma:"",tp:"",at:700,de:300,tx:"「リ・バイブル」の効果は１ターンに１度しか使用できない。\n①：このカードが墓地に存在し、自分のエクストラデッキの枚数が相手よりも５枚以上少ない場合、２０００LPを払って発動できる。\nこのカードを墓地から特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"リード・バタフライ",h:"りーど・ばたふらい",e:"Reed Butterfly",ra:"N",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:300,de:600,tx:"相手フィールド上にシンクロモンスターが表側表示で存在し、自分フィールド上にシンクロモンスターが表側表示で存在しない場合、このカードは手札から特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"リーフ・フェアリー",h:"りーふ・ふぇありー",e:"Woodland Sprite",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:900,de:400,tx:"このカードに装備された装備カード１枚を墓地へ送って発動する。\n相手ライフに５００ポイントダメージを与える。",li:1,lk:[],ta:[],al:[],ocg:true},
  {n:"リヴェンデット・エグゼクター",h:"りう゛ぇんでっと・えぐぜくたー",e:"Revendread Executor",ra:"R",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"アンデット族",mo:["儀式","効果"],ma:"",tp:"",at:3000,de:0,tx:"「ヴェンデット」儀式魔法カードにより降臨。\nこのカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードはモンスターゾーンに存在する限り、カード名を「リヴェンデット・スレイヤー」として扱う。\n②：儀式召喚したこのカードがモンスターゾーンに存在する限り、相手は他の自分フィールドのカードを効果の対象にできない。\n③：儀式召喚したこのカードが戦闘または相手の効果で破壊された場合に発動できる。\nデッキから「ヴェンデット」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"リヴェンデット・スレイヤー",h:"りう゛ぇんでっと・すれいやー",e:"Revendread Slayer",ra:"UR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"アンデット族",mo:["儀式","効果"],ma:"",tp:"",at:2400,de:0,tx:"「ヴェンデット」儀式魔法カードにより降臨。\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが相手モンスターと戦闘を行うダメージ計算時に１度、自分の墓地からアンデット族モンスター１体を除外して発動できる。\nこのカードの攻撃力は３００アップする。\n②：儀式召喚したこのカードが墓地へ送られた場合に発動できる。\nデッキから儀式魔法カード１枚を手札に加え、デッキから「ヴェンデット」モンスター１体を墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"力天使ヴァルキリア",h:"りきてんしう゛ぁるきりあ",e:"Power Angel Valkyria",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1800,de:1050,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：自分がモンスターの効果・魔法・罠カードの発動を無効にした場合に発動する。\nデッキから天使族・光属性モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"リクイド・ビースト",h:"りくいど・びーすと",e:"Liquid Beast",ra:"N",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:950,de:800,tx:"ドロドロ溶けて、水の中を自在に移動できる液体生命体。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"陸戦型 バグロス",h:"りくせんがた　ばぐろす",e:"Ground Attacker Bugroth",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1500,de:1000,tx:"陸上戦闘ロボット。\n今はダメだが、海でも使えたらしい。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"リグラス・リーパー",h:"りぐらす・りーぱー",e:"Rigorous Reaver",ra:"UR",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"植物族",mo:["リバース","効果"],ma:"",tp:"",at:1600,de:100,tx:"①：このカードがリバースした場合に発動する。\nお互いのプレイヤーは、それぞれ自分の手札を１枚選んで捨てる。\n②：このカードがモンスターとの戦闘で破壊された場合に発動する。\nそのモンスターの攻撃力・守備力を５００ダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"リザード兵",h:"りざーどへい",e:"Lizard Soldier",ra:"N",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:1100,de:800,tx:"ドラゴンから派生した獣人種。\nドラゴン族の中では小型で敏捷性に優れ、戦略性に富んでいる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"リサイクル・ジェネクス",h:"りさいくる・じぇねくす",e:"Genex Recycled",ra:"R",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:200,de:400,tx:"１ターンに１度、自分の墓地の「ジェネクス」と名のついたモンスター１体を選択して発動できる。\nエンドフェイズ時まで、このカードは選択したモンスターと同名カードとして扱う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"リターン・ゾンビ",h:"りたーん・ぞんび",e:"Return Zombie",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1000,de:1600,tx:"自分のスタンバイフェイズ時、このカードが墓地に存在し自分の手札が０枚だった場合、５００ライフポイントを払う事でこのカードを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"リチュア・アバンス",h:"りちゅあ・あばんす",e:"Gishki Avance",ra:"R",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1500,de:800,tx:"１ターンに１度、自分のメインフェイズ時に発動できる。\nデッキから「リチュア」と名のついたモンスター１体を選んでデッキの一番上に置く。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"リチュア・アビス",h:"りちゅあ・あびす",e:"Gishki Abyss",ra:"SR",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:800,de:500,tx:"このカードが召喚・反転召喚・特殊召喚に成功した時、デッキから「リチュア・アビス」以外の守備力１０００以下の「リチュア」と名のついたモンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"リチュア・ヴァニティ",h:"りちゅあ・う゛ぁにてぃ",e:"Gishki Vanity",ra:"N",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1000,de:800,tx:"自分のメインフェイズ時に、このカードを手札から捨てて発動できる。\nこのターン、相手は「リチュア」と名のついた儀式魔法カードの発動に対して魔法・罠・効果モンスターの効果を発動できず、「リチュア」と名のついた儀式モンスターが儀式召喚に成功した時、相手は魔法・罠・効果モンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"リチュア・エミリア",h:"りちゅあ・えみりあ",e:"Gishki Emilia",ra:"R",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["スピリット","効果"],ma:"",tp:"",at:1600,de:800,tx:"このカードは特殊召喚できない。\n召喚・リバースしたターンのエンドフェイズ時に持ち主の手札に戻る。\nこのカードが召喚・リバースした時、自分フィールド上にこのカード以外の「リチュア」と名のついたモンスターが存在する場合、エンドフェイズ時までフィールド上の罠カードの効果を無効にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"リチュア・エリアル",h:"りちゅあ・えりある",e:"Gishki Ariel",ra:"SR",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["リバース","効果"],ma:"",tp:"",at:1000,de:1800,tx:"リバース：デッキから「リチュア」と名のついたモンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"リチュア・キラー",h:"りちゅあ・きらー",e:"Gishki Reliever",ra:"N",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:500,de:800,tx:"このカードが召喚・反転召喚に成功した時、自分フィールド上にこのカード以外の「リチュア」と名のついたモンスターが存在する場合、このカード以外の自分フィールド上のモンスター１体を選択して持ち主の手札に戻す事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"リチュア・シェルフィッシュ",h:"りちゅあ・しぇるふぃっしゅ",e:"Gishki Mollusk",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1700,de:900,tx:"このカードがカードの効果によって墓地へ送られた時、自分のデッキの上からカードを３枚確認し、確認した３枚を好きな順番でデッキの上か下に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"リチュア・チェイン",h:"りちゅあ・ちぇいん",e:"Gishki Chain",ra:"UR",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"このカードが召喚に成功した時、デッキの上からカードを３枚確認する。\n確認したカードの中に儀式モンスターまたは儀式魔法カードがあった場合、その１枚を相手に見せて手札に加える事ができる。\nその後、確認したカードを好きな順番でデッキの上に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"リチュア・ナタリア",h:"りちゅあ・なたりあ",e:"Gishki Natalia",ra:"R",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["スピリット","効果"],ma:"",tp:"",at:1800,de:900,tx:"このカードは特殊召喚できない。\n召喚・リバースしたターンのエンドフェイズ時に持ち主の手札に戻る。\nこのカードが召喚・リバースした時、自分の墓地の「リチュア」と名のついたモンスター１体を選択してデッキの一番上に戻す事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"リチュア・ノエリア",h:"りちゅあ・のえりあ",e:"Gishki Noellia",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"このカードが召喚に成功した時、自分のデッキの上からカードを５枚めくる事ができる。\nめくったカードの中に儀式魔法カードまたは「リチュア」と名のついたモンスターカードがあった場合、それらのカードを全て墓地へ送る。\n残りのカードは好きな順番でデッキの一番下に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"リチュア・ビースト",h:"りちゅあ・びーすと",e:"Gishki Beast",ra:"R",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1500,de:1300,tx:"このカードが召喚に成功した時、自分の墓地のレベル４以下の「リチュア」と名のついたモンスター１体を選択して表側守備表示で特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"リチュア・マーカー",h:"りちゅあ・まーかー",e:"Gishki Marker",ra:"R",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"このカードが召喚・反転召喚・特殊召喚に成功した時、「リチュア」と名のついた自分の墓地の儀式モンスターまたは儀式魔法カード１枚を選択して手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"立炎星－トウケイ",h:"りつえんせい－とうけい",e:"Brotherhood of the Fire Fist - Rooster",ra:"R",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:100,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが「炎星」モンスターの効果で特殊召喚に成功した時に発動できる。\nデッキから「炎星」モンスター１体を手札に加える。\n②：１ターンに１度、自分フィールドの表側表示の「炎舞」魔法・罠カード１枚を墓地へ送って発動できる。\nデッキから「炎舞」魔法・罠カード１枚を選んで自分フィールドにセットする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"六花精エリカ",h:"りっかせいえりか",e:"Erica the Rikka Fairy",ra:"R",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:2400,de:1000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカード以外の自分の植物族モンスターが戦闘を行う攻撃宣言時に、手札・フィールドのこのカードをリリースして発動できる。\nその自分のモンスターの攻撃力・守備力はターン終了時まで１０００アップする。\n②：このカードが墓地に存在する状態で、自分フィールドの植物族モンスターがリリースされた場合に発動できる。\nこのカードを守備表示で特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"六花聖カンザシ",h:"りっかせいかんざし",e:"Kanzashi the Rikka Queen",ra:"R",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"植物族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2400,de:2400,tx:"レベル６モンスター×２\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：モンスターがリリースされた場合、このカードのX素材を１つ取り除き、自分または相手の墓地のモンスター１体を対象として発動できる。\nそのモンスターを自分フィールドに特殊召喚する。\nこの効果で特殊召喚したモンスターは効果が無効化され、植物族になる。\n②：自分フィールドの植物族モンスターが効果で破壊される場合、代わりに自分の手札・フィールドの植物族モンスター１体をリリースできる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"六花精シクラン",h:"りっかせいしくらん",e:"Cyclamen the Rikka Fairy",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1800,de:800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札・フィールドのこのカードをリリースし、自分フィールドの植物族モンスターを２体まで対象として発動できる。\nそのモンスターのレベルをターン終了時まで２つ下げる。\n②：このカードがリリースされ墓地へ送られたターンのエンドフェイズに発動できる。\nこのカードを特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"六花精スノードロップ",h:"りっかせいすのーどろっぷ",e:"Snowdrop the Rikka Fairy",ra:"UR",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1200,de:2600,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの植物族モンスター１体をリリースして発動できる。\nこのカードと植物族モンスター１体を手札から特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は植物族モンスターしか特殊召喚できない。\n②：自分フィールドの植物族モンスター１体を対象として発動できる。\n自分フィールドの全ての植物族モンスターのレベルはターン終了時まで対象のモンスターのレベルと同じになる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"六花精プリム",h:"りっかせいぷりむ",e:"Primula the Rikka Fairy",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:800,de:1800,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドのモンスターがリリースされた場合に発動できる。\nこのカードを手札から守備表示で特殊召喚する。\n②：自分フィールドの植物族モンスターを２体まで対象として発動できる。\nそのモンスターのレベルをターン終了時まで２つ上げる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"六花精ヘレボラス",h:"りっかせいへれぼらす",e:"Hellebore the Rikka Fairy",ra:"R",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:2600,de:1200,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドに「六花」モンスターが存在し、自分フィールドのモンスターを対象とするモンスターの効果を相手が発動した時、手札・フィールドのこのカードをリリースして発動できる。\nその効果を無効にする。\n②：このカードが墓地に存在する場合、自分フィールドの植物族モンスター１体をリリースして発動できる。\nこのカードを守備表示で特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"六花精ボタン",h:"りっかせいぼたん",e:"Mudan the Rikka Fairy",ra:"SR",t:"monster",a:"水",l:6,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1000,de:2400,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの植物族モンスター１体をリリースして発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが召喚または植物族モンスターの効果で特殊召喚に成功した場合に発動できる。\nデッキから「六花」魔法・罠カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"リトル・ウィンガード",h:"りとる・うぃんがーど",e:"Little-Winguard",ra:"R",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1400,de:1800,tx:"このカードは自分のエンドフェイズに１度だけ表示形式を変更する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"リトル・キメラ",h:"りとる・きめら",e:"Little Chimera",ra:"N",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:600,de:550,tx:"このカードがフィールド上に表側表示で存在する限り、フィールド上の炎属性モンスターの攻撃力は５００ポイントアップし、水属性モンスターの攻撃力は４００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"リトル・フェアリー",h:"りとる・ふぇありー",e:"Little Fairy",ra:"SR",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"自分のメインフェイズ時に手札を１枚墓地へ送って発動できる。\nこのカードのレベルを１つ上げる。\nこの効果は１ターンに２度まで使用できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"リトルトルーパー",h:"りとるとるーぱー",e:"Little Trooper",ra:"R",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:900,de:500,tx:"このカードが戦闘によって破壊された場合、デッキからレベル２以下の戦士族モンスター１体を裏側守備表示で特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"リバース・バスター",h:"りばーす・ばすたー",e:"Reverse Buster",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1500,de:0,tx:"このカードは裏側守備表示のモンスターにのみ攻撃できる。\nこのカードが攻撃する場合、相手はダメージステップ終了時まで魔法・罠カードを発動できない。\nこのカードが裏側守備表示のモンスターを攻撃した場合、ダメージ計算を行わず裏側守備表示のままそのモンスターを破壊できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"海竜神",h:"りばいあさん",e:"Kairyu-Shin",ra:"N",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"海竜族",mo:["通常"],ma:"",tp:"",at:1800,de:1500,tx:"海の主と呼ばれる海のドラゴン。\n津波をおこして全てを飲み込む。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"海竜－ダイダロス",h:"りばいあどらごん－だいだろす",e:"Levia-Dragon - Daedalus",ra:"UR",t:"monster",a:"水",l:7,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:2600,de:1500,tx:"自分フィールド上に存在する「海」を墓地に送る事で、このカード以外のフィールド上のカードを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"返り咲く薔薇の大輪",h:"りばいばる・ろーず",e:"Revival Rose",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1300,de:1300,tx:"自分フィールド上に存在するレベル５以上の植物族モンスターが破壊された場合、墓地に存在するこのカードを自分フィールド上に特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"リバイバルゴーレム",h:"りばいばるごーれむ",e:"Revival Golem",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:100,de:2100,tx:"このカードがデッキから墓地へ送られた時、以下の効果から１つを選択して発動する。\n「リバイバルゴーレム」の効果は１ターンに１度しか使用できない。\n\n●このカードを墓地から特殊召喚する。\n\n●このカードを墓地から手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"リバイバルスライム",h:"りばいばるすらいむ",e:"Revival Jam",ra:"SR",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["効果"],ma:"",tp:"",at:1500,de:500,tx:"このカードが戦闘によって墓地に送られた時１０００ライフポイントを払う事で、次の自分のスタンバイフェイズに自分フィールド上に表側守備表示で特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"リボーン・ゾンビ",h:"りぼーん・ぞんび",e:"Reborn Zombie",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1000,de:1600,tx:"自分の手札が０枚の場合、フィールド上に攻撃表示で存在するこのカードは戦闘では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"リボルバー・ドラゴン",h:"りぼるばー・どらごん",e:"Barrel Dragon",ra:"UR",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2600,de:2200,tx:"①：１ターンに１度、相手フィールドのモンスター１体を対象として発動できる。\nコイントスを３回行い、その内２回以上が表だった場合、そのモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"竜影魚レイ・ブロント",h:"りゅうえいぎょれい・ぶろんと",e:"Phantom Dragonray Bronto",ra:"SR",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["デュアル","効果"],ma:"",tp:"",at:1500,de:1000,tx:"このカードは墓地またはフィールド上に表側表示で存在する場合、通常モンスターとして扱う。\nフィールド上に表側表示で存在するこのカードを通常召喚扱いとして再度召喚する事で、このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●このカードの元々の攻撃力は２３００になる。\nこのカードは攻撃した場合、バトルフェイズ終了時に守備表示になる。\n次の自分のターン終了時までこのカードは表示形式を変更できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"龍炎剣の使い手",h:"りゅうえんけんのつかいて",e:"Master of the Flaming Dragonswords",ra:"N",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1200,tx:"自分フィールド上に「龍炎剣の使い手」以外のモンスターが召喚された時、そのモンスターのレベルを１つ上げ、このカードの攻撃力をエンドフェイズ時まで３００ポイントアップする事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"龍王の聖刻印",h:"りゅうおうのせいこくいん",e:"Hieratic Seal of the Dragon King",ra:"N",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["デュアル","効果"],ma:"",tp:"",at:0,de:0,tx:"このカードは墓地またはフィールド上に表側表示で存在する場合、通常モンスターとして扱う。\nフィールド上に表側表示で存在するこのカードを通常召喚扱いとして再度召喚する事で、このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●このカードをリリースして発動できる。\n自分の手札・デッキ・墓地から「龍王の聖刻印」以外の「聖刻」と名のついたモンスター１体を選んで表側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"龍大神",h:"りゅうおおかみ",e:"Ryu Okami",ra:"N",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"幻竜族",mo:["効果"],ma:"",tp:"",at:2900,de:1200,tx:"①：相手がモンスターの特殊召喚に成功した場合に発動する。\n相手はエクストラデッキのカード１枚を選んで墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"竜角の狩猟者",h:"りゅうかくのしゅりょうしゃ",e:"Dragon Horn Hunter",ra:"R",t:"monster",a:"闇",l:6,ps:3,pe:"①：フィールドの通常モンスターの攻撃力は２００アップし、自分の通常モンスターの戦闘で発生する自分への戦闘ダメージは０になる。",tr:"戦士族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:2300,de:1000,tx:"疫病に苦しむ故郷の村を救うため、霊薬の原料となるドラゴンの角を乱獲する女戦士。\n\nその村はすでに、棲み処を追われたドラゴンたちによって踏み荒らされ、焼き尽くされてしまった事を、彼女はまだ知らない・・・。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"竜核の呪霊者",h:"りゅうかくのじゅりょうしゃ",e:"Dragon Core Hexer",ra:"R",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["チューナー","通常"],ma:"",tp:"",at:2300,de:3000,tx:"永きに渡って狩り続けたドラゴンの返り血により、常人ならざる力を宿した女戦士。\nその魂は斃されたドラゴンの怨嗟に染まり、疫病を撒き散らす邪悪な竜核へと成り果てた。\n\n\nもはや帰る故郷もなく、本能のままに刃を血に染めたその目的は、彼女自身にも思い出せない・・・。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"竜騎士ガイア",h:"りゅうきしがいあ",e:"Gaia the Dragon Champion",ra:"SR",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["融合"],ma:"",tp:"",at:2600,de:2100,tx:"「暗黒騎士ガイア」＋「カース・オブ・ドラゴン」",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"竜騎士ブラック・マジシャン・ガール",h:"りゅうきしぶらっく・まじしゃん・がーる",e:"Dark Magician Girl the Dragon Knight",ra:"UR",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:2600,de:1700,tx:"「ブラック・マジシャン・ガール」＋ドラゴン族モンスター\nこのカードは上記カードを融合素材にした融合召喚または「ティマイオスの眼」の効果でのみ特殊召喚できる。\n①：１ターンに１度、手札を１枚墓地へ送り、フィールドの表側表示のカード１枚を対象として発動できる。\nその表側表示のカードを破壊する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"竜姫神サフィラ",h:"りゅうきしんさふぃら",e:"Saffira, Queen of Dragons",ra:"UR",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["儀式","効果"],ma:"",tp:"",at:2500,de:2400,tx:"「祝祷の聖歌」により降臨。\nこのカード名の効果は１ターンに１度しか使用できない。\n①：このカードが儀式召喚したターンのエンドフェイズ及び、このカードがモンスターゾーンに存在し、手札・デッキから光属性モンスターが墓地へ送られたターンのエンドフェイズに、以下の効果から１つを選択して発動できる。\n\n●自分はデッキから２枚ドローし、その後手札を１枚選んで捨てる。\n\n●相手の手札をランダムに１枚選んで墓地へ捨てる。\n\n●自分の墓地の光属性モンスター１体を選んで手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"竜脚獣ブラキオン",h:"りゅうきゃくじゅうぶらきおん",e:"Sauropod Brachion",ra:"SR",t:"monster",a:"地",l:8,ps:"",pe:"",tr:"恐竜族",mo:["効果"],ma:"",tp:"",at:1500,de:3000,tx:"このカードはデッキから特殊召喚できない。\nこのカードは恐竜族モンスター１体をリリースして表側表示でアドバンス召喚できる。\n①：自分メインフェイズに発動できる。\nこのカードを裏側守備表示にする（１ターンに１度のみ）。\n②：このカードが反転召喚に成功した場合に発動する。\nこのカード以外のフィールドのモンスターを全て裏側守備表示にする。\n③：このカードが攻撃された場合、その戦闘で発生する相手への戦闘ダメージは倍になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"竜宮の白タウナギ",h:"りゅうぐうのしろたうなぎ",e:"Royal Swamp Eel",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["チューナー","効果"],ma:"",tp:"",at:1700,de:1200,tx:"このカードをS素材とする場合、他のS素材モンスターは全て魚族モンスターでなければならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"竜穴の魔術師",h:"りゅうけつのまじゅつし",e:"Dragonpit Magician",ra:"UR",t:"monster",a:"水",l:7,ps:8,pe:"①：１ターンに１度、もう片方の自分のPゾーンに「魔術師」カードが存在する場合、手札のPモンスター１体を捨て、フィールドの魔法・罠カード１枚を対象として発動できる。\nそのカードを破壊する。",tr:"魔法使い族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:900,de:2700,tx:"若くして竜の魂を呼び覚ます神通力を体得した天才魔術師。\nその寡黙でストイックな魔術への姿勢から人付き合いは苦手だが、弟子の「竜脈の魔術師」にいつも振り回され、調子を狂わされている。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"龍骨鬼",h:"りゅうこつき",e:"Ryu Kokki",ra:"SR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:2400,de:2000,tx:"このカードと戦闘を行ったモンスターが戦士族・魔法使い族の場合、ダメージステップ終了時にそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"流星方界器デューザ",h:"りゅうせいほうかいきでゅーざ",e:"Duza the Meteor Cubic Vessel",ra:"UR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1600,de:1600,tx:"①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「方界」カード１枚を墓地へ送る。\n②：１ターンに１度、このカードが表側表示で存在する状態で、モンスターが自分の墓地へ送られたターンに発動できる。\nこのカードの攻撃力はターン終了時まで、自分の墓地のモンスターの種類×２００アップする。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"竜の尖兵",h:"りゅうのせんぺい",e:"Vanguard of the Dragon",ra:"UR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1700,de:1300,tx:"①：手札からドラゴン族モンスター１体を墓地へ送って発動できる。\nこのカードの攻撃力は３００アップする。\n②：フィールドのこのカードが相手の効果で墓地へ送られた時、自分または相手の墓地のドラゴン族の通常モンスター１体を対象として発動できる。\nそのドラゴン族モンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"竜破壊の剣士－バスター・ブレイダー",h:"りゅうはかいのけんし－ばすたー・ぶれいだー",e:"Buster Blader, the Dragon Destroyer Swordsman",ra:"UR",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:2800,de:2500,tx:"「バスター・ブレイダー」＋ドラゴン族モンスター\nこのカードは融合召喚でしか特殊召喚できない。\n①：このカードは直接攻撃できない。\n②：このカードの攻撃力・守備力は、相手のフィールド・墓地のドラゴン族モンスターの数×１０００アップする。\n③：このカードがモンスターゾーンに存在する限り、相手フィールドのドラゴン族モンスターは守備表示になり、相手はドラゴン族モンスターの効果を発動できない。\n④：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"竜魔人 キングドラグーン",h:"りゅうまじん　きんぐどらぐーん",e:"King Dragun",ra:"UR",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:2400,de:1100,tx:"「ロード・オブ・ドラゴン－ドラゴンの支配者－」＋「神竜 ラグナロク」\nこのカードがフィールド上に表側表示で存在する限り、相手はドラゴン族モンスターを魔法・罠・モンスターの効果の対象にする事はできない。\n１ターンに１度だけ、手札からドラゴン族モンスター１体を自分フィールド上に特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"竜魔道騎士ガイア",h:"りゅうまどうきしがいあ",e:"Gaia the Magical Knight of Dragons",ra:"UR",t:"monster",a:"風",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:2600,de:2100,tx:"「暗黒騎士ガイア」モンスター＋レベル５ドラゴン族モンスター\nこのカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードはモンスターゾーンに存在する限り、カード名を「竜騎士ガイア」として扱う。\n②：自分・相手のメインフェイズに、このカード以外のフィールドのカード１枚を対象として発動できる。\nこのカードの攻撃力を２６００ダウンし、対象のカードを破壊する。\n③：このカードが戦闘で相手モンスターを破壊した時に発動できる。\nこのカードの攻撃力は２６００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"竜魔導の守護者",h:"りゅうまどうのしゅごしゃ",e:"Keeper of Dragon Magic",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1800,de:1300,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できず、このカードの効果を発動するターン、自分は融合モンスターしかEXデッキから特殊召喚できない。\n①：このカードが召喚・特殊召喚に成功した場合、手札を１枚捨てて発動できる。\nデッキから「融合」通常魔法カードまたは「フュージョン」通常魔法カード１枚を手札に加える。\n②：EXデッキの融合モンスター１体を相手に見せて発動できる。\nそのモンスターにカード名が記された融合素材モンスター１体を自分の墓地から選んで裏側守備表示で特殊召喚する。",li:2,lk:[],ta:[],al:[],ocg:true},
  {n:"龍脈に棲む者",h:"りゅうみゃくにすむもの",e:"Dweller in the Depths",ra:"SR",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1500,de:700,tx:"①：このカードの攻撃力は、自分の魔法＆罠ゾーンの永続魔法カードの数×３００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"竜脈の魔術師",h:"りゅうみゃくのまじゅつし",e:"Dragonpulse Magician",ra:"UR",t:"monster",a:"地",l:4,ps:1,pe:"①：１ターンに１度、もう片方の自分のPゾーンに「魔術師」カードが存在する場合、手札のPモンスター１体を捨て、フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターを破壊する。",tr:"魔法使い族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:1800,de:900,tx:"元気だけが取り得の駆け出しの少年魔術師。\n実は無意識のうちに大地に眠る竜の魂を知覚する能力を有しており、まだ半人前ながらその資質の高さには師匠の「竜穴の魔術師」も一目置いている。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"燎星のプロメテオロン",h:"りょうせいのぷろめておろん",e:"Prometeor, the Burning Star",ra:"UR",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2800,de:2000,tx:"①：このカードの攻撃でメインモンスターゾーンの相手モンスターを破壊した時、手札を１枚捨てて発動できる。\nこのカードは続けて攻撃できる。\n次のターンの終了時まで、そのモンスターが存在していたゾーンは使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"緑樹の霊王",h:"りょくじゅのれいおう",e:"Green Phantom King",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"植物族",mo:["通常"],ma:"",tp:"",at:500,de:1600,tx:"青々と生い茂る木に囲まれて暮らす、森を治める若き王。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"リンク・ストリーマー",h:"りんく・すとりーまー",e:"Link Streamer",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1600,de:1800,tx:"このカード名の効果は１ターンに１度しか使用できない。\n①：このカードが既にモンスターゾーンに存在する状態で、自分フィールドにサイバース族モンスターが召喚・特殊召喚された時に発動できる。\n自分フィールドに「データトークン」（サイバース族・光・星１・攻／守０）１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"凛天使クイーン・オブ・ローズ",h:"りんてんしくいーん・おぶ・ろーず",e:"Queen Angel of Roses",ra:"R",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:2400,de:1300,tx:"このカードは植物族モンスター１体をリリースして表側攻撃表示でアドバンス召喚する事ができる。\n自分のスタンバイフェイズ時に１度だけ、フィールド上に表側表示で存在する攻撃力が一番低いモンスター１体を破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ルイーズ",h:"るいーず",e:"Beaver Warrior",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["通常"],ma:"",tp:"",at:1200,de:1500,tx:"体は小さいが、草原での守備力はかなり強い。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ルート・ウォーター",h:"るーと・うぉーたー",e:"Root Water",ra:"N",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"魚族",mo:["通常"],ma:"",tp:"",at:900,de:800,tx:"海にひそむ半魚人。\n暗黒の大津波を起こして攻撃する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ルード・カイザー",h:"るーど・かいざー",e:"Rude Kaiser",ra:"R",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"獣戦士族",mo:["通常"],ma:"",tp:"",at:1800,de:1600,tx:"両手に持つ魔人のオノの破壊力は、かなり強力だ！",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ルーレットボマー",h:"るーれっとぼまー",e:"Roulette Barrel",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1000,de:2000,tx:"自分のメインフェイズに２回サイコロを振る事ができる。\n出た目を１つ選択し、その数と同じレベルのフィールド上表側表示モンスター１体を破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ルーンアイズ・ペンデュラム・ドラゴン",h:"るーんあいず・ぺんでゅらむ・どらごん",e:"Rune-Eyes Pendulum Dragon",ra:"SR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:3000,de:2000,tx:"「オッドアイズ・ペンデュラム・ドラゴン」＋魔法使い族モンスター\n①：このカードは、「オッドアイズ・ペンデュラム・ドラゴン」以外の融合素材としたモンスターの元々のレベルによって以下の効果を得る。\n●レベル４以下：このカードは１度のバトルフェイズ中に２回までモンスターに攻撃できる。\n●レベル５以上：このカードは１度のバトルフェイズ中に３回までモンスターに攻撃できる。\n②：フィールドのP召喚されたモンスターを素材としてこのカードが融合召喚に成功したターン、このカードは相手の効果を受けない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レア・フィッシュ",h:"れあ・ふぃっしゅ",e:"Rare Fish",ra:"R",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["融合"],ma:"",tp:"",at:1500,de:1200,tx:"「フュージョニスト」＋「恍惚の人魚」",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レアメタル・ヴァルキリー",h:"れあめたる・う゛ぁるきりー",e:"Super Robolady",ra:"R",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:1200,de:500,tx:"「レアメタル・レディ」＋「レアメタル・ソルジャー」\n相手プレイヤーへの直接攻撃のダメージステップ時、攻撃力１０００アップ。\nフィールド上のこのカードと融合デッキの「レアメタル・ナイト」を交換できる。\n（このカードが特殊召喚されたターンは不可）",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レアメタル・ソルジャー",h:"れあめたる・そるじゃー",e:"Roboyarou",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:900,de:450,tx:"全身がメタルの装甲で覆われている戦士。\n「レアメタル・レディ」と融合し、パワーアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レアメタル・ドラゴン",h:"れあめたる・どらごん",e:"Rare Metal Dragon",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2400,de:1200,tx:"このカードは通常召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レアメタル・ナイト",h:"れあめたる・ないと",e:"Super Roboyarou",ra:"R",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:1200,de:500,tx:"「レアメタル・ソルジャー」＋「レアメタル・レディ」\nモンスターとの戦闘のダメージステップ時、攻撃力１０００アップ。\nフィールド上のこのカードと融合デッキの「レアメタル・ヴァルキリー」を交換できる。\n（このカードが特殊召喚されたターンは不可）",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レアメタル・レディ",h:"れあめたる・れでぃ",e:"Robolady",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:450,de:900,tx:"全身がメタルの装甲で覆われている女戦士。\n「レアメタル・ソルジャー」と融合し、パワーアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レアル・クルセイダー",h:"れある・くるせいだー",e:"Absolute Crusader",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1200,tx:"レベル５以上のモンスターが特殊召喚に成功した時、フィールド上に表側表示で存在するこのカードをリリースして発動しなければならない。\nそのレベル５以上のモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レアル・ジェネクス・アクセラレーター",h:"れある・じぇねくす・あくせられーたー",e:"R-Genex Accelerator",ra:"R",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1500,de:1900,tx:"このカードがフィールド上に表側表示で存在し、「ジェネクス」と名のついたモンスターが自分のデッキから手札に加わった時、そのモンスター１体を相手に見せて発動できる。\nそのモンスターを手札から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レアル・ジェネクス・ウルティマム",h:"れある・じぇねくす・うるてぃまむ",e:"R-Genex Ultimum",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1800,de:1400,tx:"フィールド上に表側表示で存在するこのカードが破壊され墓地へ送られた時、自分の墓地の「ジェネクス」と名のついたモンスター２体を選択してデッキに戻す事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レアル・ジェネクス・オラクル",h:"れある・じぇねくす・おらくる",e:"R-Genex Oracle",ra:"R",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"魔法使い族",mo:["チューナー","効果"],ma:"",tp:"",at:300,de:300,tx:"このカードが「ジェネクス」と名のついたモンスターの効果によって自分のデッキから手札に加わった場合、このカードを手札から特殊召喚できる。\nこのカードをシンクロ素材とする場合、「ジェネクス」と名のついたモンスターのシンクロ召喚にしか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レアル・ジェネクス・クラッシャー",h:"れある・じぇねくす・くらっしゃー",e:"R-Genex Crusher",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"このカードが召喚に成功した時、デッキからレベル４の「レアル・ジェネクス」と名のついたモンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レアル・ジェネクス・コーディネイター",h:"れある・じぇねくす・こーでぃねいたー",e:"R-Genex Overseer",ra:"SR",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["チューナー","効果"],ma:"",tp:"",at:200,de:100,tx:"このカードが召喚・特殊召喚に成功した時、手札からレベル３以下の「ジェネクス」と名のついたモンスター１体を特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レアル・ジェネクス・ターボ",h:"れある・じぇねくす・たーぼ",e:"R-Genex Turbo",ra:"R",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1500,de:1300,tx:"このカードが召喚に成功した時、デッキからレベル１の「ジェネクス」と名のついたモンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レアル・ジェネクス・マグナ",h:"れある・じぇねくす・まぐな",e:"R-Genex Magma",ra:"N",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"炎族",mo:["効果"],ma:"",tp:"",at:1000,de:200,tx:"このカードが召喚に成功した時、デッキからレベル２の「レアル・ジェネクス」と名のついたモンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"深海の怒り",h:"れいじ・おぶ・でぃーぷしー",e:"Rage of the Deep Sea",ra:"N",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"魚族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカードの攻撃力・守備力は、自分の墓地の魚族・海竜族・水族モンスターの数×５００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レイジアース",h:"れいじあーす",e:"Raging Earth",ra:"N",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:2000,de:1500,tx:"このカードが戦闘によって破壊され墓地へ送られた時、フィールド上に表側表示で存在する地属性モンスター以外のモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"霊獣使い ウェン",h:"れいじゅうつかい　うぇん",e:"Ritual Beast Tamer Wen",ra:"N",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"自分は「霊獣使い ウェン」を１ターンに１度しか特殊召喚できない。\n①：このカードが召喚に成功した場合、除外されている自分の「霊獣」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"霊獣使い レラ",h:"れいじゅうつかい　れら",e:"Ritual Beast Tamer Lara",ra:"N",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:100,de:2000,tx:"自分は「霊獣使い レラ」を１ターンに１度しか特殊召喚できない。\n①：このカードが召喚に成功した場合、自分の墓地の「霊獣」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"霊獣使いの長老",h:"れいじゅうつかいのちょうろう",e:"Ritual Beast Tamer Elder",ra:"R",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"サイキック族",mo:["効果"],ma:"",tp:"",at:200,de:1000,tx:"自分は「霊獣使いの長老」を１ターンに１度しか特殊召喚できない。\n①：このカードが召喚に成功したターン、自分は通常召喚に加えて１度だけ、自分メインフェイズに「霊獣」モンスター１体を召喚できる。",li:2,lk:[],ta:[],al:[],ocg:true},
  {n:"霊水鳥シレーヌ・オルカ",h:"れいすいちょうしれーぬ・おるか",e:"Sirenorca",ra:"UR",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:2200,de:1000,tx:"自分フィールド上に魚族及び鳥獣族モンスターが存在する場合、このカードは手札から特殊召喚できる。\nこの方法で特殊召喚に成功した時、３から５までの任意のレベルを宣言して発動できる。\n自分フィールド上の全てのモンスターのレベルは宣言したレベルになる。\nこの効果を発動したターン、水属性以外の自分のモンスターは効果を発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"霊廟の守護者",h:"れいびょうのしゅごしゃ",e:"Keeper of the Shrine",ra:"UR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:0,de:2100,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：ドラゴン族モンスターをアドバンス召喚する場合、このカードは２体分のリリースにできる。\n②：このカードが手札・墓地に存在し、「霊廟の守護者」以外のフィールドの表側表示のドラゴン族モンスターが効果で墓地へ送られた場合、または戦闘で破壊され墓地へ送られた場合に発動できる。\nこのカードを特殊召喚する。\n墓地へ送られたモンスターが通常モンスターだった場合、さらに自分の墓地のドラゴン族の通常モンスター１体を選んで手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"霊滅術師 カイクウ",h:"れいめつじゅつし　かいくう",e:"Kycoo the Ghost Destroyer",ra:"UR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1800,de:700,tx:"①：このカードがモンスターゾーンに存在する限り、相手はお互いの墓地のカードを除外できない。\n②：このカードが相手に戦闘ダメージを与えた時、相手の墓地のモンスターを２体まで対象として発動できる。\nそのモンスターを除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レイン・ボーズ",h:"れいん・ぼーず",e:"Rain Bozu",ra:"SR",t:"monster",a:"光",l:7,ps:8,pe:"①：１ターンに１度、自分フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで、お互いのEXデッキの枚数の差×１００アップする。",tr:"天使族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:0,de:0,tx:"①：このカードは、自分ターンの間は攻撃力が、相手ターンの間は守備力が、お互いのEXデッキの枚数の差×２００アップする。\n②：このカードは攻撃した場合、バトルフェイズ終了時に守備表示になる。\n③：モンスターゾーンのこのカードが破壊された場合に発動できる。\nこのカードを自分のPゾーンに置く。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レインボー・フィッシュ",h:"れいんぼー・ふぃっしゅ",e:"7 Colored Fish",ra:"R",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"魚族",mo:["通常"],ma:"",tp:"",at:1800,de:800,tx:"世にも珍しい七色の魚。\n捕まえるのはかなり難しい。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レインボー・フラワー",h:"れいんぼー・ふらわー",e:"Rainbow Flower",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:400,de:500,tx:"このカードは相手プレイヤーを直接攻撃する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レインボー・マリン・マーメイド",h:"れいんぼー・まりん・まーめいど",e:"Rainbow Marine Mermaid",ra:"N",t:"monster",a:"水",l:5,ps:"",pe:"",tr:"魚族",mo:["通常"],ma:"",tp:"",at:1550,de:1700,tx:"空に大きな虹の橋がかかると現れる、珍しいマーメイド。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レオ・ウィザード",h:"れお・うぃざーど",e:"Leo Wizard",ra:"N",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:1350,de:1200,tx:"黒いマントをはおった魔術師。\n正体は言葉を話すシシ。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レオグン",h:"れおぐん",e:"Leogun",ra:"N",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:1750,de:1550,tx:"百獣の王のような立派なたてがみを持つシシ。\n体も大きい。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レオンタウロス",h:"れおんたうろす",e:"Leotaur",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:1600,tx:"①：このカードが通常モンスター以外のモンスターと戦闘を行うダメージステップの間、このカードの攻撃力は５００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レガシーハンター",h:"れがしーはんたー",e:"Legacy Hunter",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:1500,tx:"このカードが戦闘によって裏側守備表示モンスターを破壊し墓地へ送った時、相手はランダムに手札を１枚デッキに戻す。\nその後デッキをシャッフルする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レグルス",h:"れぐるす",e:"Regulus",ra:"UR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1700,de:1000,tx:"自分の墓地に存在するフィールド魔法カード１枚を選択して発動する。\n選択したカードをデッキに戻す。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レクンガ",h:"れくんが",e:"Lekunga",ra:"R",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1700,de:500,tx:"自分の墓地の水属性モンスター２体をゲームから除外する度に、自分フィールド上に「レクンガトークン」（植物族・水・星２・攻／守７００）を１体攻撃表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レジェンド・デビル",h:"れじぇんど・でびる",e:"Legendary Fiend",ra:"SR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1500,de:1800,tx:"自分のターンのスタンバイフェイズ毎に、このカードの攻撃力は７００ポイントずつアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レスキュー・インターレーサー",h:"れすきゅー・いんたーれーさー",e:"Rescue Interlacer",ra:"R",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"サイバース族",mo:["効果"],ma:"",tp:"",at:1000,de:1200,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分のサイバース族モンスターが攻撃されたダメージ計算時にこのカードを手札から捨てて発動できる。\nその戦闘で発生する自分への戦闘ダメージを０にする。\n②：このカードがこのカードの効果を発動するために墓地へ捨てられたターンのエンドフェイズに発動する。\nこのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レスキュー・ウォリアー",h:"れすきゅー・うぉりあー",e:"Rescue Warrior",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1700,tx:"このカードの戦闘によって発生する自分への戦闘ダメージは０になる。\nこのカードが戦闘によって破壊された場合、相手フィールド上に表側表示で存在する元々の持ち主が自分となるモンスター１体を選択してコントロールを得る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レスキューキューロイド",h:"れすきゅーきゅーろいど",e:"Ambulance Rescueroid",ra:"SR",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:2300,de:1800,tx:"「レスキューロイド」＋「キューキューロイド」\nこのモンスターは融合召喚でしか特殊召喚できない。\n自分フィールド上に存在するモンスターが戦闘によって破壊され墓地へ送られた時、そのモンスターを守備表示で特殊召喚する事ができる。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レスキューラット",h:"れすきゅーらっと",e:"Rescue Hamster",ra:"UR",t:"monster",a:"地",l:4,ps:5,pe:"「レスキューラット」のP効果はデュエル中に１度しか使用できない。\n①：自分のPゾーンのこのカードを除外して発動できる。\n自分のエクストラデッキから表側表示の同名Pモンスター２体を手札に加える。",tr:"獣族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:300,de:100,tx:"①：このカードが召喚に成功したターン、自分のエクストラデッキに表側表示のレベル５以下のPモンスターが存在する場合、このカードをリリースして発動できる。\n自分のエクストラデッキから表側表示のレベル５以下のPモンスター１体を選び、デッキからその同名モンスター２体を特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化され、エンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レスキューラビット",h:"れすきゅーらびっと",e:"Rescue Rabbit",ra:"UR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:300,de:100,tx:"「レスキューラビット」の効果は１ターンに１度しか使用できない。\nこのカードはデッキから特殊召喚できない。\n①：フィールドのこのカードを除外して発動できる。\nデッキからレベル４以下の同名の通常モンスター２体を特殊召喚する。\nこの効果で特殊召喚したモンスターはエンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レスキューロイド",h:"れすきゅーろいど",e:"Rescueroid",ra:"R",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1600,de:1800,tx:"このカードが自分フィールド上に表側表示で存在する限り、自分フィールド上に存在する「ロイド」と名のついたモンスターが戦闘によって破壊され墓地に送られた時、そのモンスターを手札に戻す事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"共振虫",h:"れぞなんす・いんせくと",e:"Resonance Insect",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:1000,de:700,tx:"①：このカードがフィールドから墓地へ送られた場合に発動できる。\nデッキからレベル５以上の昆虫族モンスター１体を手札に加える。\n②：このカードが除外された場合に発動できる。\nデッキから「共振虫」以外の昆虫族モンスター１体を墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レッカーパンダ",h:"れっかーぱんだ",e:"Wrecker Panda",ra:"SR",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"①：自分・相手のスタンバイフェイズに５００LPを払って発動できる。\n自分のデッキの一番上のカードを墓地へ送り、そのカードがモンスターだった場合、このカードの攻撃力・守備力はそのモンスターのレベル×２００アップする。\n②：このカードが相手によって破壊され墓地へ送られた場合に発動できる。\n自分の墓地からレベルが一番低いモンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"烈華砲艦ナデシコ",h:"れっかほうかんなでしこ",e:"Battlecruiser Dianthus",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"植物族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2100,de:1800,tx:"レベル３モンスター×３\nこのカードのエクシーズ素材を１つ取り除いて発動できる。\n相手の手札の数×２００ポイントダメージを相手ライフに与える。\n「烈華砲艦ナデシコ」の効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レッグル",h:"れっぐる",e:"Leghul",ra:"N",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:300,de:350,tx:"このカードは相手プレイヤーを直接攻撃する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レッサー・デーモン",h:"れっさー・でーもん",e:"Lesser Fiend",ra:"N",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:2100,de:1000,tx:"このカードがフィールド上に表側表示で存在する限り、このカードが戦闘によって破壊したモンスターは墓地へは行かずゲームから除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レッサー・ドラゴン",h:"れっさー・どらごん",e:"Lesser Dragon",ra:"N",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:1200,de:1000,tx:"あまり強くなく、ブレス攻撃もやらない低級のドラゴン。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レッド・ウルフ",h:"れっど・うるふ",e:"Red Warg",ra:"R",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1400,de:2200,tx:"①：自分が「リゾネーター」モンスターの召喚に成功した時に発動できる。\nこのカードを手札から特殊召喚する。\nこの効果で特殊召喚したこのカードの攻撃力は半分になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レッド・エース",h:"れっど・えーす",e:"Akakieisu",ra:"R",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["通常"],ma:"",tp:"",at:1000,de:800,tx:"死の呪いをかけてくる魔法使い。\n呪文を聞くと、気が遠くなる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レッド・ガードナー",h:"れっど・がーどなー",e:"Red Gardna",ra:"R",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:2000,tx:"①：自分フィールドに「レッド・デーモン」モンスターが存在し、相手の魔法・罠・モンスターの効果が発動した時、このカードを手札から墓地へ送って発動できる。\nこのターン、自分フィールドのモンスターは相手の効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レッド・ガジェット",h:"れっど・がじぇっと",e:"Red Gadget",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1300,de:1500,tx:"①：このカードが召喚・特殊召喚に成功した時に発動できる。\nデッキから「イエロー・ガジェット」１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レッド・サイクロプス",h:"れっど・さいくろぷす",e:"Opticlops",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1800,de:1700,tx:"「冥界の魔王 ハ・デス」に仕える一つ目の巨人。\nツノの攻撃で敵を粉砕する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レッド・スプリンター",h:"れっど・すぷりんたー",e:"Red Sprinter",ra:"SR",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1700,de:1200,tx:"「レッド・スプリンター」の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した時、自分フィールドに他のモンスターが存在しない場合に発動できる。\n自分の手札・墓地からレベル３以下の悪魔族チューナー１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レッド・ダストン",h:"れっど・だすとん",e:"Red Duston",ra:"N",t:"monster",a:"炎",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:1000,tx:"このカードはリリースできず、融合・シンクロ・エクシーズ召喚の素材にもできない。\nフィールド上のこのカードが破壊された時、このカードのコントローラーは５００ポイントダメージを受ける。\n「レッド・ダストン」は自分フィールド上に１体しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レッド・デーモンズ・ドラゴン",h:"れっど・でーもんず・どらごん",e:"Red Dragon Archfiend",ra:"UR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:3000,de:2000,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：このカードが相手の守備表示モンスターを攻撃したダメージ計算後に発動する。\n相手フィールドの守備表示モンスターを全て破壊する。\n②：自分エンドフェイズに発動する。\nこのカードがフィールドに表側表示で存在する場合、このカード以外のこのターン攻撃宣言をしていない自分フィールドのモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レッド・デーモンズ・ドラゴン／バスター",h:"れっど・でーもんず・どらごんすらっしゅばすたー",e:"Red Dragon Archfiend/Assault Mode",ra:"UR",t:"monster",a:"闇",l:10,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3500,de:2500,tx:"このカードは通常召喚できない。\n「バスター・モード」の効果でのみ特殊召喚できる。\nこのカードが攻撃した場合、ダメージ計算後にこのカード以外のフィールド上のモンスターを全て破壊する。\nまた、フィールド上のこのカードが破壊された時、自分の墓地の「レッド・デーモンズ・ドラゴン」１体を選択して特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レッド・ドラゴン",h:"れっど・どらごん",e:"Tyhone #2",ra:"N",t:"monster",a:"炎",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:1700,de:1900,tx:"ファイヤーボールをはき辺りを火の海にする、真っ赤なドラゴン。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レッド・ノヴァ",h:"れっど・のう゛ぁ",e:"Red Nova",ra:"R",t:"monster",a:"炎",l:1,ps:"",pe:"",tr:"天使族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"「レッド・ノヴァ」の①の方法による特殊召喚は１ターンに１度しかできない。\n①：フィールドにレベル８以上のドラゴン族Sモンスターが存在する場合、このカードは手札から特殊召喚できる。\n②：このカードがチューナー２体以上を素材とするS召喚に使用され墓地へ送られた場合に発動できる。\nデッキから悪魔族・炎属性モンスター１体を守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レッド・ミラー",h:"れっど・みらー",e:"Red Mirror",ra:"R",t:"monster",a:"炎",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"「レッド・ミラー」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手モンスターの攻撃宣言時、このカードを手札から墓地へ送り、「レッド・ミラー」以外の自分の墓地の悪魔族・炎属性モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\n②：このカードが墓地に存在し、自分がS召喚に成功した場合に発動できる。\n墓地のこのカードを手札に加える。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レッド・ライジング・ドラゴン",h:"れっど・らいじんぐ・どらごん",e:"Red Rising Dragon",ra:"UR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2100,de:1600,tx:"悪魔族チューナー＋チューナー以外のモンスター１体以上\n①：このカードがS召喚に成功した時、自分の墓地の「リゾネーター」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果を発動するターン、自分はドラゴン族・闇属性Sモンスターしかエクストラデッキから特殊召喚できない。\n②：墓地のこのカードを除外し、自分の墓地のレベル１の「リゾネーター」モンスター２体を対象として発動できる。\nそのモンスター２体を特殊召喚する。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レッド・リゾネーター",h:"れっど・りぞねーたー",e:"Red Resonator",ra:"SR",t:"monster",a:"炎",l:2,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:600,de:200,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが召喚に成功した時に発動できる。\n手札からレベル４以下のモンスター１体を特殊召喚する。\n②：このカードが特殊召喚に成功した時、フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力分だけ自分はLPを回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"真紅眼の不死竜",h:"れっどあいず・あんでっとどらごん",e:"Red-Eyes Zombie Dragon",ra:"SR",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:2400,de:2000,tx:"このカードはアンデット族モンスター１体をリリースして攻撃表示でアドバンス召喚できる。\n①：このカードが戦闘でアンデット族モンスターを破壊し墓地へ送った時に発動できる。\nそのアンデット族モンスターを自分フィールドに特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"真紅眼の不屍竜",h:"れっどあいず・あんでっとねくろどらごん",e:"Red-Eyes Zombie Necro Dragon",ra:"SR",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"アンデット族",mo:["シンクロ","効果"],ma:"",tp:"",at:2400,de:2000,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードの攻撃力・守備力は、お互いのフィールド・墓地のアンデット族モンスターの数×１００アップする。\n②：このカード以外のアンデット族モンスターが戦闘で破壊された時に発動できる。\n自分または相手の墓地のアンデット族モンスター１体を選んで自分フィールドに特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"真紅眼の黒刃竜",h:"れっどあいず・すらっしゅどらごん",e:"Red-Eyes Slash Dragon",ra:"UR",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:2800,de:2400,tx:"「真紅眼の黒竜」＋戦士族モンスター\n①：「レッドアイズ」モンスターの攻撃宣言時に自分の墓地の戦士族モンスター１体を対象として発動できる。\nそのモンスターを攻撃力２００アップの装備カード扱いとしてこのカードに装備する。\n②：自分フィールドのカードを対象とするカードの効果が発動した時、自分フィールドの装備カード１枚を墓地へ送って発動できる。\nその発動を無効にし破壊する。\n③：このカードが戦闘・効果で破壊された場合に発動できる。\nこのカードに装備されていたモンスターを自分の墓地から可能な限り特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"真紅眼の闇竜",h:"れっどあいず・だーくねすどらごん",e:"Red-Eyes Darkness Dragon",ra:"UR",t:"monster",a:"闇",l:9,ps:"",pe:"",tr:"ドラゴン族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2400,de:2000,tx:"このカードは通常召喚できない。\n自分フィールド上に存在する「真紅眼の黒竜」１体をリリースした場合のみ特殊召喚する事ができる。\nこのカードの攻撃力は、自分の墓地に存在するドラゴン族モンスター１体につき３００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レッドアイズ・トゥーン・ドラゴン",h:"れっどあいず・とぅーん・どらごん",e:"Red-Eyes Toon Dragon",ra:"R",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["トゥーン","効果"],ma:"",tp:"",at:2400,de:2000,tx:"①：このカードは召喚・反転召喚・特殊召喚したターンには攻撃できない。\n②：自分フィールドに「トゥーン・ワールド」が存在し、相手フィールドにトゥーンモンスターが存在しない場合、このカードは直接攻撃できる。\n③：１ターンに１度、自分メインフェイズに発動できる。\n手札から「レッドアイズ・トゥーン・ドラゴン」以外のトゥーンモンスター１体を召喚条件を無視して特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"真紅眼の遡刻竜",h:"れっどあいず・とれーさーどらごん",e:"Red-Eyes Retro Dragon",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1700,de:1600,tx:"①：自分フィールドのレベル７以下の「レッドアイズ」モンスターが相手モンスターの攻撃または相手の効果で破壊され自分の墓地へ送られた場合に発動できる。\nこのカードを手札から守備表示で特殊召喚し、可能な限りその破壊されたモンスターを破壊された時と同じ表示形式で特殊召喚する。\n②：このカードをリリースして発動できる。\nこのターン、自分は通常召喚に加えて１度だけ、自分メインフェイズに「レッドアイズ」モンスター１体を召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"真紅眼の黒竜",h:"れっどあいず・ぶらっくどらごん",e:"Red-Eyes Black Dragon",ra:"UR",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:2400,de:2000,tx:"真紅の眼を持つ黒竜。\n怒りの黒き炎はその眼に映る者全てを焼き尽くす。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"真紅眼の黒竜剣",h:"れっどあいず・ぶらっくどらごん・そーど",e:"Red-Eyes Black Dragon Sword",ra:"N",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:2400,de:2000,tx:"このカードは「ヘルモスの爪」の効果で自分の手札・フィールドのドラゴン族モンスターを墓地へ送った場合のみ特殊召喚できる。\n①：このカードが特殊召喚に成功した場合、このカード以外のフィールドのモンスター１体を対象として発動する。\nこのカードを攻撃力１０００アップの装備カード扱いとしてそのモンスターに装備する。\n②：このカードの効果でこのカードを装備したモンスターの攻撃力・守備力は、お互いのフィールド・墓地のドラゴン族モンスターの数×５００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レッドアイズ・ブラックメタルドラゴン",h:"れっどあいず・ぶらっくめたるどらごん",e:"Red-Eyes Black Metal Dragon",ra:"R",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"機械族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2800,de:2400,tx:"このカードは通常召喚できない。\n自分フィールドの、「メタル化・魔法反射装甲」を装備した「真紅眼の黒竜」１体をリリースした場合にデッキから特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"真紅眼の鋼炎竜",h:"れっどあいず・ふれあめたるどらごん",e:"Red-Eyes Flare Metal Dragon",ra:"SR",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2800,de:2400,tx:"レベル７モンスター×２\n①：X素材を持ったこのカードは効果では破壊されない。\n②：X素材を持ったこのカードがモンスターゾーンに存在する限り、相手が魔法・罠・モンスターの効果を発動する度に相手に５００ダメージを与える。\n③：１ターンに１度、このカードのX素材を１つ取り除き、自分の墓地の「レッドアイズ」通常モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"真紅眼の幼竜",h:"れっどあいず・べびーどらごん",e:"Red-Eyes Baby Dragon",ra:"SR",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1200,de:700,tx:"①：このカードが戦闘で破壊され墓地へ送られた時に発動できる。\nデッキからレベル７以下の「レッドアイズ」モンスター１体を特殊召喚し、墓地のこのカードを攻撃力３００アップの装備カード扱いとして、そのモンスターに装備する。\n②：モンスターに装備されているこのカードが墓地へ送られた場合に発動できる。\n自分のデッキ・墓地から、ドラゴン族・レベル１モンスター１体を選んで手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"真紅眼の鉄騎士－ギア・フリード",h:"れっどあいず・めたるないと－ぎあ・ふりーど",e:"Gearfried the Red-Eyes Iron Knight",ra:"UR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1600,tx:"①：１ターンに１度、このカードに装備カードが装備された場合に発動できる。\nその装備カードを破壊する。\nその後、相手フィールドの魔法・罠カード１枚を選んで破壊できる。\n②：１ターンに１度、このカードに装備されている自分フィールドの装備カード１枚を墓地へ送り、自分の墓地のレベル７以下の「レッドアイズ」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"真紅眼の凶雷皇－エビル・デーモン",h:"れっどあいず・らいとにんぐ・ろーど－えびる・でーもん",e:"Red-Eyes Archfiend of Lightning",ra:"SR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["デュアル","効果"],ma:"",tp:"",at:2500,de:1200,tx:"①：このカードはフィールド・墓地に存在する限り、通常モンスターとして扱う。\n②：フィールドの通常モンスター扱いのこのカードを通常召喚としてもう１度召喚できる。\nその場合このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●１ターンに１度、自分メインフェイズに発動できる。\nこのカードの攻撃力より低い守備力を持つ、相手フィールドの表側表示モンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"真紅眼の飛竜",h:"れっどあいず・わいばーん",e:"Red-Eyes Wyvern",ra:"UR",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1800,de:1600,tx:"このカードの効果を発動するターン、自分は通常召喚できない。\n①：自分エンドフェイズに墓地のこのカードを除外し、自分の墓地の「レッドアイズ」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"真紅眼の凶星竜－メテオ・ドラゴン",h:"れっどあいずのきょうせいりゅう－めてお・どらごん",e:"Meteor Dragon Red-Eyes Impact",ra:"SR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"ドラゴン族",mo:["デュアル","効果"],ma:"",tp:"",at:1800,de:2000,tx:"①：このカードはフィールド・墓地に存在する限り、通常モンスターとして扱う。\n②：フィールドの通常モンスター扱いのこのカードを通常召喚としてもう１度召喚できる。\nその場合このカードは効果モンスター扱いとなり以下の効果を得る。\n\n●このカードがモンスターゾーンに存在する限り、このカード以外の自分フィールドの「レッドアイズ」モンスターは戦闘・効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"赤い忍者",h:"れっどにんじゃ",e:"Crimson Ninja",ra:"R",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"戦士族",mo:["リバース","効果"],ma:"",tp:"",at:300,de:300,tx:"①：このカードがリバースした場合、フィールドの表側表示の罠カード１枚またはフィールドにセットされた魔法・罠カード１枚を対象として発動する。\nその罠カードを破壊する（そのカードがフィールドにセットされている場合、めくって確認する）。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レフティ・ドライバー",h:"れふてぃ・どらいばー",e:"Lefty Driver",ra:"R",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:300,de:100,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合に発動できる。\nこのカードのレベルはターン終了時まで３になる。\n②：墓地のこのカードを除外して発動できる。\nデッキから「ライティ・ドライバー」１体を手札に加える。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レプティアの武者騎兵",h:"れぷてぃあのむしゃきへい",e:"Samurai Cavalry of Reptier",ra:"R",t:"monster",a:"地",l:4,ps:3,pe:"",tr:"恐竜族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1800,de:1200,tx:"①：このカードがPモンスター以外の相手の表側表示モンスターに攻撃したダメージステップ開始時に発動できる。\nそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レプティレス・ヴァースキ",h:"れぷてぃれす・う゛ぁーすき",e:"Reptilianne Vaskii",ra:"UR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"爬虫類族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2600,de:0,tx:"このカードは通常召喚できない。\n自分・相手フィールドの攻撃力０のモンスター２体をリリースした場合のみ特殊召喚できる。\n①：「レプティレス・ヴァースキ」はフィールドに１体しか表側表示で存在できない。\n②：１ターンに１度、相手フィールドの表側表示モンスター１体を対象として発動できる。\nその相手の表側表示モンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レプティレス・ガードナー",h:"れぷてぃれす・がーどなー",e:"Reptilianne Gardna",ra:"R",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:0,de:2000,tx:"自分フィールド上のこのカードが破壊され墓地へ送られた時、デッキから「レプティレス」と名のついたモンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レプティレス・ゴルゴーン",h:"れぷてぃれす・ごるごーん",e:"Reptilianne Gorgon",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1400,de:1400,tx:"このカードが攻撃を行ったダメージ計算後、このカードと戦闘を行ったモンスターは攻撃力が０になり、表示形式を変更できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レプティレス・サーヴァント",h:"れぷてぃれす・さーう゛ぁんと",e:"Reptilianne Servant",ra:"N",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"フィールド上にこのカード以外のモンスターが表側表示で存在する場合、このカードを破壊する。\nこのカードが魔法・罠カードの効果の対象になった時、このカードを破壊する。\nこのカードがフィールド上に表側表示で存在する限り、お互いにモンスターを召喚する事はできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レプティレス・スキュラ",h:"れぷてぃれす・すきゅら",e:"Reptilianne Scylla",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1800,de:1200,tx:"このカードが戦闘によって攻撃力０のモンスターを破壊した場合、そのモンスターを墓地から自分フィールド上に表側守備表示で特殊召喚できる。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レプティレス・バイパー",h:"れぷてぃれす・ばいぱー",e:"Reptilianne Viper",ra:"R",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"爬虫類族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"①：このカードが召喚に成功した時、相手フィールドの攻撃力０のモンスター１体を対象として発動できる。\nその攻撃力０のモンスターのコントロールを得る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レプティレス・ヒュドラ",h:"れぷてぃれす・ひゅどら",e:"Reptilianne Lamia",ra:"SR",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"爬虫類族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードが手札に存在し、自分フィールドのモンスターが爬虫類族モンスターのみの場合、相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力を０にし、このカードを特殊召喚する。\nその後、自分はそのモンスターの元々の攻撃力分のダメージを受ける。\n②：このカードがS素材として墓地へ送られた場合、フィールドの表側表示モンスターを２体まで対象として発動できる。\nそのモンスターの攻撃力を０にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レプティレス・メデューサ",h:"れぷてぃれす・めでゅーさ",e:"Reptilianne Medusa",ra:"R",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:2200,de:800,tx:"手札を１枚墓地へ送り、相手フィールド上に表側表示で存在するモンスター１体を選択して発動する。\n選択したモンスターは攻撃力が０になり、表示形式を変更する事ができなくなる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レプティレス・ラミア",h:"れぷてぃれす・らみあ",e:"Reptilianne Hydra",ra:"R",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"爬虫類族",mo:["シンクロ","効果"],ma:"",tp:"",at:2100,de:1500,tx:"「レプティレス」チューナー＋チューナー以外のモンスター１体以上\n①：このカードがS召喚に成功した場合に発動する。\n相手フィールドの攻撃力０のモンスターを全て破壊し、破壊した数だけ自分はデッキからドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レベル・ウォリアー",h:"れべる・うぉりあー",e:"Level Warrior",ra:"R",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:300,de:600,tx:"お互いのフィールド上にモンスターが存在しない場合、このカードはレベル２モンスターとして手札から召喚できる。\n相手フィールド上にモンスターが存在し、自分フィールド上にモンスターが存在しない場合、このカードはレベル４モンスターとして手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レモン・マジシャン・ガール",h:"れもん・まじしゃん・がーる",e:"Lemon Magician Girl",ra:"N",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:800,de:600,tx:"①：１ターンに１度、「レモン・マジシャン・ガール」以外の自分フィールドの「マジシャン・ガール」モンスター１体をリリースして発動できる。\nデッキから魔法使い族モンスター１体を手札に加える。\n②：１ターンに１度、このカードが攻撃対象に選択された場合に発動できる。\n手札から魔法使い族モンスター１体を効果を無効にして特殊召喚する。\nその後、攻撃対象をそのモンスターに移し替え、攻撃モンスターの攻撃力を半分にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"錬金生物 ホムンクルス",h:"れんきんせいぶつ　ほむんくるす",e:"Homunculus the Alchemic Being",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1800,de:1600,tx:"このモンスターの属性を変更する事ができる。\nこの効果は１ターンに１度だけ使用する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"連弾の魔術師",h:"れんだんのまじゅつし",e:"Rapid-Fire Magician",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"このカードがフィールド上に表側表示で存在する限り、自分が通常魔法を発動する度に、相手ライフに４００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ロイヤルガード",h:"ろいやるがーど",e:"Royal Guard",ra:"N",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"機械族",mo:["通常"],ma:"",tp:"",at:1900,de:2200,tx:"王族を守るために開発された、意志を持つ機械の兵隊。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ロイヤルナイツ",h:"ろいやるないつ",e:"Royal Knight",ra:"N",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1300,de:800,tx:"このカードが戦闘によってモンスターを破壊し墓地へ送った時、破壊したモンスターの守備力分だけ自分のライフポイントが回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ロー・キューピット",h:"ろー・きゅーぴっと",e:"Cupid Dunk",ra:"UR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1800,de:600,tx:"①：このカードは、このカードより高いレベルを持つモンスター以外のモンスターとの戦闘では破壊されない。\n②：自分スタンバイフェイズに発動できる。\nこのカードのレベルを１つ上げる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ローガーディアン",h:"ろーがーでぃあん",e:"Skull Guardian",ra:"N",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"戦士族",mo:["儀式"],ma:"",tp:"",at:2050,de:2500,tx:"「ローの祈り」により降臨。\n場か手札から、星の数が合計７個以上になるようカードを生け贄に捧げなければならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ローグ・オブ・エンディミオン",h:"ろーぐ・おぶ・えんでぃみおん",e:"Rogue of Endymion",ra:"N",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1500,de:200,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動する。\nこのカードに魔力カウンターを１つ置く。\n②：このカードの魔力カウンターを１つ取り除き、手札から魔法使い族モンスター１体を捨てて発動できる。\nデッキから永続魔法カード１枚を選んで自分の魔法＆罠ゾーンにセットする。\nこのターン、自分はこの効果でセットしたカード及びその同名カードの効果を発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"薔薇の聖弓手",h:"ろーず・あーちゃー",e:"Rose Archer",ra:"SR",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:100,tx:"自分フィールド上に植物族モンスターが存在し、相手が罠カードを発動した時、このカードを手札から墓地へ送って発動できる。\nその発動を無効にし破壊する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ローズ・ウィッチ",h:"ろーず・うぃっち",e:"Rose Witch",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"植物族モンスターをアドバンス召喚する場合、このカードは２体分のリリースとする事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ローズ・テンタクルス",h:"ろーず・てんたくるす",e:"Rose Tentacles",ra:"R",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:2200,de:1200,tx:"このカードは特殊召喚できない。\n自分のバトルフェイズ開始時に相手フィールド上に表側表示で植物族モンスターが存在する場合、このターンこのカードは通常の攻撃に加えて、その植物族モンスターの数だけ攻撃する事ができる。\nこのカードが戦闘によって植物族モンスターを破壊した場合、相手ライフに３００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ローズ・バード",h:"ろーず・ばーど",e:"Bird of Roses",ra:"R",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1800,de:1500,tx:"自分フィールド上に表側攻撃表示で存在するこのカードが相手モンスターの攻撃によって破壊され墓地へ送られた時、デッキから植物族チューナー２体を表側守備表示で特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"薔薇の聖騎士",h:"ろーず・ぱらでぃん",e:"Rose Paladin",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:200,tx:"「薔薇の聖騎士」の②の効果は１ターンに１度しか使用できない。\n①：このカードが戦闘で相手モンスターを破壊し墓地へ送った時、このカードをリリースして発動できる。\n手札・デッキから植物族モンスター１体を守備表示で特殊召喚する。\n②：このカードを手札から墓地へ送って発動できる。\nデッキからレベル７以上の植物族モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ロード・ウォリアー",h:"ろーど・うぉりあー",e:"Road Warrior",ra:"UR",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:3000,de:1500,tx:"「ロード・シンクロン」＋チューナー以外のモンスター２体以上\n①：１ターンに１度、自分メインフェイズに発動できる。\nデッキからレベル２以下の戦士族・機械族モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ロード・オブ・ドラゴン－ドラゴンの支配者－",h:"ろーど・おぶ・どらごん－どらごんのしはいしゃ－",e:"Lord of D.",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1200,de:1100,tx:"①：このカードがモンスターゾーンに存在する限り、お互いのプレイヤーはフィールドのドラゴン族モンスターを効果の対象にできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ロード・オブ・ドラゴン－ドラゴンの統制者－",h:"ろーど・おぶ・どらごん－どらごんのとうせいしゃ－",e:"The King of D.",ra:"UR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1200,de:1100,tx:"①：このカードはモンスターゾーンに存在する限り、カード名を「ロード・オブ・ドラゴン－ドラゴンの支配者－」として扱う。\n②：このカードが召喚に成功した時、手札から魔法・罠カード１枚を捨てて発動できる。\nデッキから「ドラゴンを呼ぶ笛」「ドラゴン・目覚めの旋律」「ドラゴン・復活の狂奏」のいずれか１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ロード・シンクロン",h:"ろーど・しんくろん",e:"Road Synchron",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:1600,de:800,tx:"①：このカードを「ロード・ウォリアー」以外のSモンスターの素材とする場合、このカードのレベルを２つ下げたレベルとして扱う。\n②：このカードが攻撃した場合、そのダメージステップ終了時に発動する。\nこのカードのレベルをターン終了時まで１つ上げる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ロードブリティッシュ",h:"ろーどぶりてぃっしゅ",e:"Lord British Space Fighter",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:800,tx:"このカードが戦闘によって相手モンスターを破壊した場合、以下の効果から１つを選択して発動する。\n\n●このカードはもう１度だけ続けて攻撃できる。\n\n●フィールド上にセットされたカード１枚を選択して破壊する。\n\n●自分フィールド上に「マルチプルトークン」（機械族・光・星４・攻／守１２００）１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ロードポイズン",h:"ろーどぽいずん",e:"Lord Poison",ra:"R",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"このカードが戦闘によって破壊され墓地に送られた時、自分の墓地に存在する「ロードポイズン」以外の植物族モンスター１体を自分フィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ロードランナー",h:"ろーどらんなー",e:"Sonic Chick",ra:"SR",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"鳥獣族",mo:["効果"],ma:"",tp:"",at:300,de:300,tx:"①：このカードは攻撃力１９００以上のモンスターとの戦闘では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"六武衆－イロウ",h:"ろくぶしゅう－いろう",e:"The Six Samurai - Irou",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1700,de:1200,tx:"自分フィールド上に「六武衆－イロウ」以外の「六武衆」と名のついたモンスターが存在し、このカードが裏側守備表示のモンスターを攻撃した場合、ダメージ計算を行わず裏側守備表示のままそのモンスターを破壊する。\nまた、フィールド上に表側表示で存在するこのカードが破壊される場合、代わりにこのカード以外の自分フィールド上に表側表示で存在する「六武衆」と名のついたモンスター１体を破壊できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"六武衆－カモン",h:"ろくぶしゅう－かもん",e:"The Six Samurai - Kamon",ra:"N",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:1000,tx:"自分フィールド上に「六武衆－カモン」以外の「六武衆」と名のついたモンスターが存在する場合、１ターンに１度、フィールド上に表側表示で存在する魔法・罠カード１枚を選択して破壊できる。\nこの効果を発動するターン、このカードは攻撃宣言できない。\nまた、フィールド上に表側表示で存在するこのカードが破壊される場合、代わりにこのカード以外の自分フィールド上に表側表示で存在する「六武衆」と名のついたモンスター１体を破壊できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"六武衆－ザンジ",h:"ろくぶしゅう－ざんじ",e:"The Six Samurai - Zanji",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1800,de:1300,tx:"自分フィールド上に「六武衆－ザンジ」以外の「六武衆」と名のついたモンスターが存在する場合、このカードが攻撃したモンスターをダメージステップ終了時に破壊する。\nまた、フィールド上に表側表示で存在するこのカードが破壊される場合、代わりにこのカード以外の自分フィールド上に表側表示で存在する「六武衆」と名のついたモンスター１体を破壊できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"六武衆－ニサシ",h:"ろくぶしゅう－にさし",e:"The Six Samurai - Nisashi",ra:"N",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1400,de:700,tx:"自分フィールド上に「六武衆－ニサシ」以外の「六武衆」と名のついたモンスターが存在する場合、このカードは１度のバトルフェイズ中に２回攻撃する事ができる。\nまた、フィールド上に表側表示で存在するこのカードが破壊される場合、代わりにこのカード以外の自分フィールド上に表側表示で存在する「六武衆」と名のついたモンスター１体を破壊できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"六武衆－ヤイチ",h:"ろくぶしゅう－やいち",e:"The Six Samurai - Yaichi",ra:"R",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1300,de:800,tx:"自分フィールド上に「六武衆－ヤイチ」以外の「六武衆」と名のついたモンスターが存在する場合、１ターンに１度、フィールド上にセットされた魔法・罠カード１枚を選択して破壊できる。\nこの効果を発動するターン、このカードは攻撃宣言できない。\nまた、フィールド上に表側表示で存在するこのカードが破壊される場合、代わりにこのカード以外の自分フィールド上に表側表示で存在する「六武衆」と名のついたモンスター１体を破壊できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"六武衆－ヤリザ",h:"ろくぶしゅう－やりざ",e:"The Six Samurai - Yariza",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1000,de:500,tx:"自分フィールド上に「六武衆－ヤリザ」以外の「六武衆」と名のついたモンスターが存在する場合、このカードは相手プレイヤーに直接攻撃できる。\nまた、フィールド上に表側表示で存在するこのカードが破壊される場合、代わりにこのカード以外の自分フィールド上に表側表示で存在する「六武衆」と名のついたモンスター１体を破壊できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"六武衆の影武者",h:"ろくぶしゅうのかげむしゃ",e:"Kagemusha of the Six Samurai",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"戦士族",mo:["チューナー","効果"],ma:"",tp:"",at:400,de:1800,tx:"自分フィールド上に表側表示で存在する「六武衆」と名のついたモンスター１体が魔法・罠・効果モンスターの効果の対象になった時、その効果の対象をフィールド上に表側表示で存在するこのカードに移し替える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"六武衆のご隠居",h:"ろくぶしゅうのごいんきょ",e:"Elder of the Six Samurai",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:400,de:0,tx:"相手フィールド上にモンスターが存在し、自分フィールド上にモンスターが存在しない場合、このカードは手札から特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"六武衆の侍従",h:"ろくぶしゅうのじじゅう",e:"Chamberlain of the Six Samurai",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["通常"],ma:"",tp:"",at:200,de:2000,tx:"六武衆を陰で支える謎多き人物。\n今はもう闘う事はないが、体に刻まれた無数の傷跡が何かを語る。\nその過去を知る者はいない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"六武衆の師範",h:"ろくぶしゅうのしはん",e:"Grandmaster of the Six Samurai",ra:"UR",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2100,de:800,tx:"①：「六武衆の師範」は自分フィールドに１体しか表側表示で存在できない。\n②：自分フィールドに「六武衆」モンスターが存在する場合、このカードは手札から特殊召喚できる。\n③：このカードが相手の効果で破壊された場合、自分の墓地の「六武衆」モンスター１体を対象として発動する。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"六武衆の真影",h:"ろくぶしゅうのしんえい",e:"Legendary Secret of the Six Samurai",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:500,de:2000,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分が「影六武衆」モンスターの召喚・特殊召喚に成功した時に発動できる。\nこのカードを手札から特殊召喚する。\n②：１ターンに１度、自分の墓地からレベル４以下の「六武衆」モンスター１体を除外して発動できる。\nターン終了時まで、このカードの属性・レベル・攻撃力・守備力は、除外したそのモンスターと同じになる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"六武衆の露払い",h:"ろくぶしゅうのつゆはらい",e:"Hand of the Six Samurai",ra:"R",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1600,de:1000,tx:"自分フィールド上にこのカード以外の「六武衆」と名のついたモンスターが表側表示で存在する場合に発動する事ができる。\n自分フィールド上に存在する「六武衆」と名のついたモンスター１体をリリースする事で、フィールド上に存在するモンスター１体を破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"六武衆の御霊代",h:"ろくぶしゅうのみたましろ",e:"Spirit of the Six Samurai",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["ユニオン","効果"],ma:"",tp:"",at:500,de:500,tx:"１ターンに１度、自分のメインフェイズ時に装備カード扱いとして自分フィールド上の「六武衆」と名のついたモンスターに装備、または装備を解除して表側攻撃表示で特殊召喚できる。\nこの効果で装備カード扱いになっている場合のみ、装備モンスターの攻撃力・守備力は５００ポイントアップする。\n装備モンスターが戦闘によって相手モンスターを破壊した場合、自分はデッキからカードを１枚ドローする。\n（１体のモンスターが装備できるユニオンは１枚まで。\n装備モンスターが破壊される場合、代わりにこのカードを破壊する。\n）",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ロケット・ジャンパー",h:"ろけっと・じゃんぱー",e:"Rocket Jumper",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1000,de:800,tx:"相手フィールド上に守備表示モンスターしか存在しない場合、このカードは相手プレイヤーに直接攻撃をする事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ロケット・ヘルモス・キャノン",h:"ろけっと・へるもす・きゃのん",e:"Rocket Hermos Cannon",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["融合","効果"],ma:"",tp:"",at:1500,de:1300,tx:"このカードは「ヘルモスの爪」の効果で自分の手札・フィールドの戦士族モンスターを墓地へ送った場合のみ特殊召喚できる。\n①：このカードが特殊召喚に成功した場合、このカード以外のフィールドのモンスター１体を対象として発動する。\nこのカードを装備カード扱いとしてそのモンスターに装備する。\n②：このカードの効果でこのカードを装備したモンスターは、１度のバトルフェイズ中に２回攻撃でき、守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ相手に戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ロケット戦士",h:"ろけっとせんし",e:"Rocket Warrior",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:1300,tx:"①：自分バトルフェイズ中、このカードは戦闘では破壊されず、このカードの戦闘で発生する自分への戦闘ダメージは０になる。\n②：このカードがモンスターに攻撃したダメージ計算後に発動する。\n攻撃対象モンスターの攻撃力はターン終了時まで５００ダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ロスト・ブルー・ブレイカー",h:"ろすと・ぶるー・ぶれいかー",e:"Lost Blue Breaker",ra:"R",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"海竜族",mo:["効果"],ma:"",tp:"",at:1400,de:0,tx:"フィールド上にこのカード以外の魚族・海竜族・水族モンスターが存在する場合にこのカードをリリースして発動できる。\nフィールド上の魔法・罠カード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ロストガーディアン",h:"ろすとがーでぃあん",e:"Lost Guardian",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:100,de:"?",tx:"このカードの元々の守備力は、自分が除外している岩石族モンスターの数×７００ポイントの数値になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ロックキャット",h:"ろっくきゃっと",e:"Lock Cat",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1200,de:1000,tx:"このカードが召喚に成功した時、自分の墓地に存在するレベル１の獣族モンスター１体を選択して表側守備表示で特殊召喚する事ができる。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ロックストーン・ウォリアー",h:"ろっくすとーん・うぉりあー",e:"Rockstone Warrior",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1800,de:1600,tx:"このカードの戦闘によって発生する自分への戦闘ダメージは０になる。\nこのカードの攻撃によってこのカードが戦闘で破壊され墓地へ送られた時、自分フィールド上に「ロックストーン・トークン」（岩石族・地・星１・攻／守０）２体を特殊召喚する。\nこのトークンはアドバンス召喚のためにはリリースできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ロックメイス",h:"ろっくめいす",e:"Key Mace #2",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["通常"],ma:"",tp:"",at:1050,de:1200,tx:"心の良い部分を封印して、相手を悪魔の手先にしてしまう。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ワーム・アグリィ",h:"わーむ・あぐりぃ",e:"Worm Ugly",ra:"N",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"このカードをリリースして「ワーム」と名のついた爬虫類族モンスターのアドバンス召喚に成功した時、自分の墓地のこのカードを相手フィールド上に表側攻撃表示で特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ワーム・アポカリプス",h:"わーむ・あぽかりぷす",e:"Worm Apocalypse",ra:"R",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"爬虫類族",mo:["リバース","効果"],ma:"",tp:"",at:300,de:200,tx:"リバース：フィールド上の魔法・罠カード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ワーム・イーロキン",h:"わーむ・いーろきん",e:"Worm Erokin",ra:"N",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:2400,de:1200,tx:"このカードは特殊召喚できない。\n１ターンに１度、フィールド上の「ワーム」と名のついた爬虫類族モンスター１体を選択して裏側守備表示にする事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ワーム・イリダン",h:"わーむ・いりだん",e:"Worm Illidan",ra:"N",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:2000,de:1800,tx:"自分フィールド上にカードがセットされる度に、このカードにワームカウンターを１つ置く。\nこのカードに乗っているワームカウンターを２つ取り除く事で、相手フィールド上のカード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ワーム・ヴィクトリー",h:"わーむ・う゛ぃくとりー",e:"Worm Victory",ra:"R",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"爬虫類族",mo:["リバース","効果"],ma:"",tp:"",at:0,de:2500,tx:"リバース：「ワーム」と名のついた爬虫類族モンスター以外の、フィールド上に表側表示で存在するモンスターを全て破壊する。\nまた、このカードがフィールド上に表側表示で存在する限り、このカードの攻撃力は、自分の墓地の「ワーム」と名のついた爬虫類族モンスターの数×５００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ワーム・ウォーロード",h:"わーむ・うぉーろーど",e:"Worm Warlord",ra:"R",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:2350,de:1800,tx:"このカードは特殊召喚できない。\nこのカードが戦闘で破壊した効果モンスターの効果は無効化される。\nこのカードが戦闘によって相手モンスターを破壊した場合、もう１度だけ続けて攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ワーム・オペラ",h:"わーむ・おぺら",e:"Worm Opera",ra:"N",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"爬虫類族",mo:["リバース","効果"],ma:"",tp:"",at:400,de:800,tx:"リバース：「ワーム」と名のついた爬虫類族モンスター以外の、フィールド上の全てのモンスターの攻撃力は５００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ワーム・カルタロス",h:"わーむ・かるたろす",e:"Worm Cartaros",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["リバース","効果"],ma:"",tp:"",at:1200,de:500,tx:"リバース：デッキからレベル４以下の「ワーム」と名のついた爬虫類族モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ワーム・キング",h:"わーむ・きんぐ",e:"Worm King",ra:"R",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:2700,de:1100,tx:"このカードは「ワーム」と名のついた爬虫類族モンスター１体をリリースして表側攻撃表示でアドバンス召喚できる。\nまた、自分フィールド上の「ワーム」と名のついた爬虫類族モンスター１体をリリースする事で、相手フィールド上のカード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ワーム・クィーン",h:"わーむ・くぃーん",e:"Worm Queen",ra:"R",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:2700,de:1100,tx:"このカードは「ワーム」と名のついた爬虫類族モンスター１体をリリースして表側攻撃表示でアドバンス召喚できる。\nまた、１ターンに１度、自分フィールド上の「ワーム」と名のついた爬虫類族モンスター１体をリリースする事で、リリースしたモンスターのレベル以下の「ワーム」と名のついた爬虫類族モンスター１体をデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ワーム・グルス",h:"わーむ・ぐるす",e:"Worm Gulse",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1500,de:300,tx:"フィールド上に裏側守備表示で存在するモンスターがリバースする度に、このカードにワームカウンターを１つ置く。\nこのカードの攻撃力は、このカードに乗っているワームカウンターの数×３００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ワーム・ジェートリクプス",h:"わーむ・じぇーとりくぷす",e:"Worm Jetelikpse",ra:"R",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"爬虫類族",mo:["リバース","効果"],ma:"",tp:"",at:1200,de:0,tx:"リバース：このターン、このカードが破壊され墓地へ送られた時、このカードを墓地から表側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ワーム・ゼクス",h:"わーむ・ぜくす",e:"Worm Xex",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"このカードが召喚に成功した時、デッキから「ワーム」と名のついた爬虫類族モンスター１体を墓地へ送る事ができる。\n自分フィールド上に「ワーム・ヤガン」が存在する場合、このカードは戦闘では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ワーム・ゼロ",h:"わーむ・ぜろ",e:"Worm Zero",ra:"R",t:"monster",a:"光",l:10,ps:"",pe:"",tr:"爬虫類族",mo:["融合","効果"],ma:"",tp:"",at:"?",de:0,tx:"「ワーム」と名のついた爬虫類族モンスター×２体以上\nこのカードの攻撃力は、このカードの融合素材としたモンスターの種類×５００ポイントになる。\nまた、このカードは融合素材としたモンスターの種類によって以下の効果を得る。\n●２種類以上：１ターンに１度、自分の墓地の爬虫類族モンスター１体を選択し、裏側守備表示で特殊召喚できる。\n●４種類以上：自分の墓地の爬虫類族モンスター１体をゲームから除外して発動できる。\nフィールド上のモンスター１体を選択して墓地へ送る。\n●６種類以上：１ターンに１度、デッキからカードを１枚ドローできる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ワーム・ソリッド",h:"わーむ・そりっど",e:"Worm Solid",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1000,de:1600,tx:"このカードの守備力は、自分の墓地の「ワーム」と名のついた爬虫類族モンスターの数×１００ポイントアップする。\nまた、このカードが攻撃され、相手プレイヤーが戦闘ダメージを受けた場合、そのダメージステップ終了時に相手フィールド上の魔法・罠カード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ワーム・ディミクレス",h:"わーむ・でぃみくれす",e:"Worm Dimikles",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["リバース","効果"],ma:"",tp:"",at:1700,de:1400,tx:"リバース：このカードの攻撃力・守備力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ワーム・テンタクルス",h:"わーむ・てんたくるす",e:"Worm Tentacles",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1700,de:700,tx:"１ターンに１度、自分の墓地の「ワーム」と名のついた爬虫類族モンスター１体をゲームから除外して発動できる。\nこのターン、このカードは１度のバトルフェイズ中に２回攻撃する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ワーム・ノーブル",h:"わーむ・のーぶる",e:"Worm Noble",ra:"N",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"爬虫類族",mo:["リバース","効果"],ma:"",tp:"",at:1500,de:2400,tx:"リバース：このカードが相手モンスターの攻撃によってリバースした場合、その相手モンスターの攻撃力の半分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ワーム・バルサス",h:"わーむ・ばるさす",e:"Worm Barses",ra:"N",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1400,de:1500,tx:"このカードが召喚に成功した時、フィールド上に守備表示で存在するモンスター１体を選択して表側攻撃表示にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ワーム・ファルコ",h:"わーむ・ふぁるこ",e:"Worm Falco",ra:"N",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"爬虫類族",mo:["リバース","効果"],ma:"",tp:"",at:500,de:800,tx:"リバース：このカード以外の自分フィールド上の「ワーム」と名のついた爬虫類族モンスターを全て裏側守備表示にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ワーム・プリンス",h:"わーむ・ぷりんす",e:"Worm Prince",ra:"N",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:2200,de:400,tx:"このカードが戦闘によって相手モンスターを破壊した場合、デッキから「ワーム」と名のついた爬虫類族モンスター１体を手札に加える事ができる。\nエンドフェイズ時、自分フィールド上にこのカード以外の「ワーム」と名のついた爬虫類族モンスターが存在しない場合、このカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ワーム・ホープ",h:"わーむ・ほーぷ",e:"Worm Hope",ra:"N",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"爬虫類族",mo:["リバース","効果"],ma:"",tp:"",at:800,de:1500,tx:"リバース：このカードが相手モンスターの攻撃によってリバースした場合、デッキからカードを１枚ドローする。\nまた、このカードがフィールド上から墓地へ送られた時、自分は手札を１枚選んで墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ワーム・ミリディス",h:"わーむ・みりでぃす",e:"Worm Millidith",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["リバース","効果"],ma:"",tp:"",at:400,de:1600,tx:"リバース：このカードを装備カード扱いとして相手モンスター１体に装備できる。\nお互いのスタンバイフェイズ毎に、装備モンスターのコントローラーに４００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ワーム・ヤガン",h:"わーむ・やがん",e:"Worm Yagan",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:1000,de:1800,tx:"自分フィールド上のモンスターが「ワーム・ゼクス」１体のみの場合、このカードを墓地から裏側守備表示で特殊召喚できる。\nこの効果で特殊召喚したこのカードは、フィールド上から離れた場合ゲームから除外される。\nこのカードがリバースした時、相手フィールド上に表側表示で存在するモンスター１体を選択して持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ワーム・リンクス",h:"わーむ・りんくす",e:"Worm Linx",ra:"R",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"爬虫類族",mo:["リバース","効果"],ma:"",tp:"",at:300,de:1000,tx:"リバース：このカードがフィールド上に表側表示で存在する場合、お互いのエンドフェイズ毎に自分はデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ワーム・ルクイエ",h:"わーむ・るくいえ",e:"Worm Rakuyeh",ra:"N",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["効果"],ma:"",tp:"",at:2100,de:1200,tx:"このカードは、このカードがリバースしたターンでなければ攻撃宣言できない。\nこのカードは攻撃した場合、バトルフェイズ終了時に裏側守備表示になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ワームドレイク",h:"わーむどれいく",e:"Worm Drake",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"爬虫類族",mo:["通常"],ma:"",tp:"",at:1400,de:1500,tx:"その長い体に巻き込まれたら最後、二度と逃げる事はできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"Y－ドラゴン・ヘッド",h:"わい－どらごん・へっど",e:"Y-Dragon Head",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["ユニオン","効果"],ma:"",tp:"",at:1500,de:1600,tx:"①：１ターンに１度、以下の効果から１つを選択して発動できる。\n●自分フィールドの「X－ヘッド・キャノン」１体を対象とし、このカードを装備カード扱いとしてそのモンスターに装備する。\n装備モンスターが戦闘・効果で破壊される場合、代わりにこのカードを破壊する。\n●装備されているこのカードを特殊召喚する。\n②：装備モンスターの攻撃力・守備力は４００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"矮星竜 プラネター",h:"わいせいりゅう　ぷらねたー",e:"Dwarf Star Dragon Planeter",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1700,de:1200,tx:"①：このカードを召喚したターンのエンドフェイズに発動できる。\nデッキから光属性または闇属性のレベル７モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"YZ－キャタピラー・ドラゴン",h:"わいぜっと－きゃたぴらー・どらごん",e:"YZ-Tank Dragon",ra:"R",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:2100,de:2200,tx:"「Y－ドラゴン・ヘッド」＋「Z－メタル・キャタピラー」\n自分フィールドの上記カードを除外した場合のみ、EXデッキから特殊召喚できる。\nこのカードは墓地からの特殊召喚はできない。\n①：手札を１枚捨て、相手フィールドの裏側表示モンスター１体を対象として発動できる。\nその相手の裏側表示モンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ワイト",h:"わいと",e:"Skull Servant",ra:"N",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"アンデット族",mo:["通常"],ma:"",tp:"",at:300,de:200,tx:"どこにでも出てくるガイコツのおばけ。\n攻撃は弱いが集まると大変。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ワイトキング",h:"わいときんぐ",e:"King of the Skull Servants",ra:"SR",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:"?",de:0,tx:"このカードの元々の攻撃力は、自分の墓地に存在する「ワイトキング」「ワイト」の数×１０００ポイントの数値になる。\nこのカードが戦闘によって破壊され墓地へ送られた時、自分の墓地の「ワイトキング」または「ワイト」１体をゲームから除外する事で、このカードを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ワイト夫人",h:"わいとふじん",e:"The Lady in Wight",ra:"SR",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:0,de:2200,tx:"このカードのカード名は、墓地に存在する限り「ワイト」として扱う。\nまた、このカードがフィールド上に表側表示で存在する限り、「ワイト夫人」以外のフィールド上のレベル３以下のアンデット族モンスターは戦闘では破壊されず、魔法・罠カードの効果も受けない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ワイバーン",h:"わいばーん",e:"Winged Dragon, Guardian of the Fortress #2",ra:"N",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"鳥獣族",mo:["通常"],ma:"",tp:"",at:1200,de:1000,tx:"羽をはばたかせて強力な竜巻をおこす。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ワイバーンの戦士",h:"わいばーんのせんし",e:"Alligator's Sword",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["通常"],ma:"",tp:"",at:1500,de:1200,tx:"剣技にすぐれたトカゲ人間。\n音の速さで剣をふるう。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ワイルド・ラプター",h:"わいるど・らぷたー",e:"Uraby",ra:"N",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"恐竜族",mo:["通常"],ma:"",tp:"",at:1500,de:800,tx:"走ることが得意な恐竜。\n鋭いかぎづめで攻撃する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"業神－不知火",h:"わざがみ－しらぬい",e:"Shiranui Swordsaga",ra:"R",t:"monster",a:"炎",l:5,ps:"",pe:"",tr:"アンデット族",mo:["シンクロ","効果"],ma:"",tp:"",at:1900,de:0,tx:"チューナー＋チューナー以外のモンスター１体以上\n自分は「業神－不知火」を１ターンに１度しか特殊召喚できず、その②の効果は１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合、フィールドのモンスター１体を対象として発動できる。\nそのモンスターの表示形式を変更する。\n②：このカードが除外された場合に発動できる。\n自分フィールドに「不知火トークン」（アンデット族・炎・星１・攻／守０）１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"災いの像",h:"わざわいのぞう",e:"Elephant Statue of Disaster",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:1500,de:0,tx:"相手がコントロールするカードの効果によって、このカードが手札から墓地に送られた時、相手ライフに２０００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"轍の魔妖－朧車",h:"わだちのまやかし－おぼろぐるま",e:"Oboro-Guruma, the Wheeled Mayakashi",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"アンデット族",mo:["シンクロ","効果"],ma:"",tp:"",at:800,de:2100,tx:"チューナー＋チューナー以外のモンスター１体以上\nこのカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：「轍の魔妖－朧車」は自分フィールドに１体しか表側表示で存在できない。\n②：このカードが墓地に存在し、元々のレベルが５の自分のSモンスターが戦闘または相手の効果で破壊された場合に発動できる。\n自分の墓地から他のアンデット族モンスター１体を除外し、このカードを特殊召喚する。\n③：このカードが墓地からの特殊召喚に成功した場合に発動できる。\nこのターン、自分のモンスターは戦闘では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"轍の魔妖－俥夫",h:"わだちのまやかし－しゃふ",e:"Shafu, the Wheeled Mayakashi",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"アンデット族",mo:["効果"],ma:"",tp:"",at:1200,de:400,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合、「轍の魔妖－俥夫」以外の自分の墓地の「魔妖」モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。\n②：このカードがモンスターゾーンに存在する限り、自分は「魔妖」モンスターしかEXデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ワタポン",h:"わたぽん",e:"Watapon",ra:"N",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:200,de:300,tx:"このカードがカードの効果によって自分のデッキから手札に加わった場合、このカードを手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"手錠龍",h:"わっぱー・どらごん",e:"Handcuffs Dragon",ra:"R",t:"monster",a:"風",l:5,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1800,de:1800,tx:"このカードが相手モンスターの攻撃によって破壊され墓地へ送られた時、このカードを装備カード扱いとしてそのモンスターに装備する事ができる。\n装備モンスターの攻撃力は１８００ポイントダウンする。\n装備モンスターが破壊される事によってこのカードが墓地へ送られた時、このカードを自分フィールド上に特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"笑う花",h:"わらうはな",e:"Laughing Flower",ra:"N",t:"monster",a:"地",l:2,ps:"",pe:"",tr:"植物族",mo:["通常"],ma:"",tp:"",at:900,de:500,tx:"いつも笑っている花。\n笑い声を聞きつづけると、頭が混乱する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ワルキューレ・ヴリュンヒルデ",h:"わるきゅーれ・う゛りゅんひるで",e:"Valkyrie Brunhilde",ra:"UR",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1800,de:2000,tx:"①：このカードの攻撃力は、相手フィールドのモンスターの数×５００アップする。\n②：このカードは相手の魔法カードの効果を受けない。\n③：相手モンスターの攻撃宣言時に発動できる。\nこのカードの守備力を１０００ダウンし、このターン、自分の「ワルキューレ」モンスターは戦闘では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ワルキューレ・エルダ",h:"わるきゅーれ・えるだ",e:"Valkyrie Erda",ra:"R",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2000,de:2200,tx:"①：「ワルキューレ・エルダ」は自分フィールドに１体しか表側表示で存在できない。\n②：「ワルキューレ」カードの効果で特殊召喚したこのカードがモンスターゾーンに存在する限り、相手フィールドのモンスターの攻撃力は１０００ダウンする。\n③：このカードがモンスターゾーンに存在する限り、戦闘・効果で破壊され相手の墓地へ送られるカードは墓地へは行かず除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ワルキューレ・シグルーン",h:"わるきゅーれ・しぐるーん",e:"Valkyrie Sigrun",ra:"SR",t:"monster",a:"光",l:9,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2200,de:2400,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが手札に存在する場合、自分フィールドの表側表示の魔法・罠カード１枚を対象として発動できる。\nそのカードを墓地へ送り、このカードを特殊召喚する。\n②：このカードが召喚・特殊召喚に成功した場合に発動できる。\n自分の手札・墓地からレベル８以下の「ワルキューレ」モンスター１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ワルキューレ・セクスト",h:"わるきゅーれ・せくすと",e:"Valkyrie Sechste",ra:"SR",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:0,de:2000,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが特殊召喚に成功した場合に発動できる。\nデッキから「ワルキューレ・セクスト」以外の「ワルキューレ」モンスター１体を特殊召喚する。\n②：自分メインフェイズに発動できる。\n相手のデッキの上からカードを２枚墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"戦乙女の戦車",h:"わるきゅーれ・ちゃりおっと",e:"Valkyrie Chariot",ra:"N",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"天使族",mo:["ユニオン","効果"],ma:"",tp:"",at:500,de:1000,tx:"①：１ターンに１度、以下の効果から１つを選択して発動できる。\n●自分フィールドの天使族モンスター１体を対象とし、このカードを装備カード扱いとしてそのモンスターに装備する。\n装備モンスターが戦闘・効果で破壊される場合、代わりにこのカードを破壊する。\n●装備されているこのカードを特殊召喚する。\n②：装備モンスターの攻撃宣言時に発動できる。\n装備モンスターの攻撃力は５００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ワルキューレ・ツヴァイト",h:"わるきゅーれ・つう゛ぁいと",e:"Valkyrie Zweite",ra:"R",t:"monster",a:"光",l:5,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1600,de:1600,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードが召喚・反転召喚・特殊召喚に成功した場合、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊する。\n②：このカードが戦闘を行ったダメージ計算後に、自分の墓地の永続魔法カード１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ワルキューレ・ドリット",h:"わるきゅーれ・どりっと",e:"Valkyrie Dritte",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1000,de:1600,tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「ワルキューレ・ドリット」以外の「ワルキューレ」カード１枚を手札に加える。\n②：このカードの攻撃力は、除外されている相手モンスターの数×２００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ワルキューレ・フィアット",h:"わるきゅーれ・ふぃあっと",e:"Valkyrie Vierte",ra:"R",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:1400,de:1400,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\nこのカード以外の自分フィールドの「ワルキューレ」モンスターの数だけ自分のデッキの上からカードをめくる。\nその中に通常魔法・通常罠カードがあった場合、その内の１枚を選んで手札に加え、残りのカードは全て墓地へ送る。\n無かった場合、めくったカードを全てデッキに戻す。\n②：このカードが戦闘で破壊され墓地へ送られた時に発動できる。\nデッキから「ワルキューレ」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ワルキューレ・フュンフト",h:"わるきゅーれ・ふゅんふと",e:"Valkyrie Funfte",ra:"N",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:800,de:1200,tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドの「ワルキューレ」モンスターの攻撃力は、除外されている相手モンスターの数×２００アップする。\n②：自分フィールドに「ワルキューレ・フュンフト」以外の「ワルキューレ」モンスターが存在する場合に発動できる。\nデッキから魔法・罠カード１枚を墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"一眼の盾竜",h:"わんあいど・しーるどどらごん",e:"One-Eyed Shield Dragon",ra:"N",t:"monster",a:"風",l:3,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:700,de:1300,tx:"身につけた盾は身を守るだけでなく、突撃にも使える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ワンショット・キャノン",h:"わんしょっと・きゃのん",e:"Turbo Cannon",ra:"R",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"機械族",mo:["シンクロ","効果"],ma:"",tp:"",at:0,de:0,tx:"「ワンショット・ロケット」＋チューナー以外のモンスター１体\n１ターンに１度、自分のメインフェイズ時に発動できる。\nフィールド上に表側表示で存在するモンスター１体を選択して破壊し、そのコントローラーに破壊したモンスターの攻撃力の半分のダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ワンショット・ロケット",h:"わんしょっと・ろけっと",e:"Turbo Rocket",ra:"N",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"このカードが攻撃する場合、このカードはその戦闘では破壊されない。\nまた、このカードが攻撃を行ったダメージ計算後、攻撃対象モンスターの攻撃力の半分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ワンハンドレッド・アイ・ドラゴン",h:"わんはんどれっど・あい・どらごん",e:"Hundred Eyes Dragon",ra:"UR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:3000,de:2500,tx:"闇属性チューナー＋チューナー以外の悪魔族モンスター１体以上\n①：１ターンに１度、自分の墓地からレベル６以下の闇属性の効果モンスター１体を除外して発動できる。\nエンドフェイズまで、このカードはそのモンスターと同じ、元々のカード名・効果を得る。\n②：このカードが破壊され墓地へ送られた場合に発動する。\nデッキから「地縛神」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アルカナフォースⅫ－THE HANGED MAN",h:"あるかなふぉーすとぅえるぶ－ざ・はんぐどまん",e:"Arcana Force XII - The Hangman",ra:"UR",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"天使族",mo:["効果"],ma:"",tp:"",at:2200,de:2200,tx:"このカードが召喚・反転召喚・特殊召喚に成功した時、コイントスを１回行い以下の効果を得る。\n●表：自分のエンドフェイズ時、自分フィールド上の表側表示モンスター１体を選択して破壊し、自分はその攻撃力分のダメージを受ける。\n●裏：自分のエンドフェイズ時、相手フィールド上の表側表示モンスター１体を選択して破壊し、その攻撃力分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"プラズマ戦士エイトム",h:"ぷらずませんしえいとむ",e:"Plasma Warrior Eitom",ra:"UR",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"雷族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3000,de:2500,tx:"このカードは通常召喚できない。\n自分フィールド上のレベル７以上のモンスター１体をリリースした場合のみ特殊召喚できる。\nこのカードは相手プレイヤーに直接攻撃できる。\nその直接攻撃で相手ライフに与える戦闘ダメージは半分になる。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"恋する乙女",h:"こいするおとめ",e:"Maiden in Love",ra:"UR",t:"monster",a:"光",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:400,de:300,tx:"攻撃表示のこのカードは戦闘では破壊されない。\nこのカードが攻撃されたダメージ計算後、攻撃したモンスターに乙女カウンターを１つ置く。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"ワクチンゲール",h:"わくちんげーる",e:"Antidote Nurse",ra:"SR",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"機械族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1800,de:1200,tx:"レベル３モンスターｘ３\n１ターンに１度、自分のモンスターが戦闘を行うダメージステップ時に、このカードのエクシーズ素材を１つ取り除いて発動できる。\nそのモンスターの攻撃力はターン終了時まで１０００ポイントアップする。\nこのカードが相手モンスターと戦闘を行うダメージ計算時に発動する。\nその相手モンスターの攻撃力は元々の数値になる。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"サテライト・レーザー X06S バルサム",h:"さてらいと・れーざー　Ｘ－０６Ｓ　ばるさむ",e:"Satellite Laser Balsam",ra:"UR",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"機械族",mo:["融合","効果"],ma:"",tp:"",at:0,de:2000,tx:"「サテライト・キャノン」×３\nこのモンスターは融合召喚でしか特殊召喚できない。\n自分のエンドフェイズ毎に、このカードの攻撃力は３０００ポイントアップする。\nこの効果でアップした攻撃力は、このカードが攻撃したダメージ計算後に０に戻る。\nこのカードが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"アサルト・コア",h:"あさると・こあ",e:"Armored Core",ra:"UR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1300,de:2000,tx:"このカードのカード名はルール上「巨大戦艦」と名のついたカードとしても扱う。\n相手フィールド上にモンスターが存在し、自分フィールド上にモンスターが存在しない場合、このカードは手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"キャット・ガール・マジシャン",h:"きゃっと・がーる・まじしゃん",e:"Cat Girl Magician",ra:"SR",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2000,de:800,tx:"レベル４モンスター×２\n１ターンに１度、自分のバトルフェイズ中に、このカードのエクシーズ素材を１つ取り除いて発動できる。\nこのカードの攻撃力はターン終了時まで半分になり、このターンこのカードは相手プレイヤーに直接攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"機皇神マシニクル∞",h:"きこうしんましにくるいんふぃにてぃ",e:"Meklord Astro Mekanikle",ra:"UR",t:"monster",a:"光",l:12,ps:"",pe:"",tr:"機械族",mo:["特殊召喚","効果"],ma:"",tp:"",at:4000,de:4000,tx:"このカードは通常召喚できない。\n手札から「機皇」モンスター３体を墓地へ送った場合のみ特殊召喚できる。\n①：１ターンに１度、相手フィールドのSモンスター１体を対象として発動できる。\nその相手Sモンスターをこのカードに装備する。\n②：この攻撃力は自身の効果で装備したモンスターの攻撃力分アップする。\n③：自分スタンバイフェイズに、自身の効果で装備している自分のモンスター１体を墓地へ送って発動できる。\nそのモンスターの攻撃力分のダメージを相手に与える。\nこの効果を発動するターン、自分はバトルフェイズを行えない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダーク・アーキタイプ",h:"だーく・あーきたいぷ",e:"Dark Archetype",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:1400,de:400,tx:"このカードが戦闘によって破壊され墓地へ送られた時に発動できる。\nその戦闘で自分が受けた戦闘ダメージの数値以下の攻撃力を持つモンスター１体をデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"ジェスター・クィーン",h:"じぇすたー・くぃーん",e:"Jester Queen",ra:"SR",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"このカードが召喚・特殊召喚に成功した時、自分の魔法＆罠ゾーンのカードを全て破壊する。\n自分フィールド上に他のモンスターが存在しない場合、このカードは通常の攻撃に加えて、自分の魔法＆罠ゾーンのカードの数だけ、１度のバトルフェイズ中に攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"ワイゼルA",h:"わいぜるあたっく",e:"Wisel Attack",ra:"N",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1200,de:0,tx:"フィールド上に「機皇帝」と名のついたモンスターが存在しない場合、このカードは破壊される。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"ワイゼルA3",h:"わいぜるあたっくすりー",e:"Wisel Attack 3",ra:"SR",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1600,de:0,tx:"このカードは自分フィールド上の「ワイゼルA」１体をリリースし、手札から特殊召喚できる。\nフィールド上に「機皇帝」と名のついたモンスターが存在しない場合、このカードは破壊される。\n自分の「機皇帝」と名のついたモンスターが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"ワイゼルC",h:"わいぜるきゃりあ",e:"Wisel Carrier",ra:"R",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:800,de:600,tx:"フィールド上に「機皇帝」と名のついたモンスターが存在しない場合、このカードは破壊される。\nこのカードは相手のカードの効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"ワイゼルG",h:"わいぜるがーど",e:"Wisel Guard",ra:"R",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:1200,tx:"フィールド上に「機皇帝」と名のついたモンスターが存在しない場合、このカードは破壊される。\n自分のモンスターを攻撃対象とした相手モンスターの攻撃宣言時に発動できる。\n攻撃対象をこのカードに移し替える。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"ワイゼルG3",h:"わいぜるがーどすりー",e:"Wisel Guard 3",ra:"SR",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:0,de:2000,tx:"このカードは自分フィールド上の「ワイゼルG」１体をリリースし、手札から特殊召喚できる。\nフィールド上に「機皇帝」と名のついたモンスターが存在しない場合、このカードは破壊される。\n自分のモンスターを攻撃対象とした相手モンスターの攻撃宣言時に発動できる。\n攻撃対象をこのカードに移し替える。\nこのカードは１ターンに１度だけ、戦闘では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"ワイゼルT",h:"わいぜるとっぷ",e:"Wisel Top",ra:"N",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:500,de:0,tx:"フィールド上に「機皇帝」と名のついたモンスターが存在しない場合、このカードは破壊される。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"インフェルニティ・デス・ガンマン",h:"いんふぇるにてぃ・です・がんまん",e:"Infernity Doom Slinger",ra:"R",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:100,de:100,tx:"自分の手札が０枚の場合、自分にダメージを与える魔法・罠・モンスターの効果を相手が発動した時、墓地のこのカードをゲームから除外して発動できる。\nその効果で自分が受けるダメージを０にする。\nその後、以下の効果を適用するかどうかを相手が選ぶ。\n●自分のデッキの一番上のカードをめくり、それがモンスターだった場合、相手ライフに２０００ポイントダメージを与える。\n違った場合、自分は２０００ポイントダメージを受ける。\nめくったカードはデッキに戻してシャッフルする。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"宇宙花",h:"こすもす",e:"Cosmos",ra:"SR",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:600,de:900,tx:"自分フィールド上に植物族モンスターが２体以上存在する場合、このカードは手札から特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"ヒドゥン・ナイト－フック－",h:"ひどぅん・ないと－ふっく－",e:"Hook the Hidden Knight",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1600,de:0,tx:"攻撃表示のこのカードが攻撃対象に選択された場合、攻撃モンスター１体を選択して発動する。\nこのカードを守備表示にし、攻撃モンスターを守備表示にする。\nこのカードが攻撃表示から表側守備表示になった場合に発動する。\n相手に４００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"化け猫",h:"ばけねこ",e:"Monster Cat",ra:"R",t:"monster",a:"闇",l:1,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカードが「捨て猫」の効果によって特殊召喚に成功した場合に発動する。\n相手フィールド上のレベル４以下のモンスターを全て破壊し、破壊したモンスターの数×４００ポイントダメージを相手ライフに与える。\nまた、１ターンに１度、自分のメインフェイズ時に発動できる。\n自分の墓地から「捨て猫」または「捨て猫娘」１体を選択して手札に加える。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"ブリザード・リザード",h:"ぶりざーど・りざーど",e:"Blizzard Lizard",ra:"SR",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:600,de:1800,tx:"このカードが戦闘によって破壊された場合に発動する。\n相手に４００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"水晶ドクロ",h:"すいしょうどくろ",e:"Crystal Skull",ra:"R",t:"monster",a:"水",l:1,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカードが召喚・反転召喚・特殊召喚に成功した時、自分は５００ポイントのダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"火銃花",h:"ひがんばな",e:"Fire Lily",ra:"R",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:800,de:800,tx:"このカードが戦闘を行う攻撃宣言時、相手ライフに４００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"砲戦花",h:"ほうせんか",e:"Ferocious Flora",ra:"SR",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1000,de:500,tx:"１ターンに１度、自分の植物族モンスターが相手モンスターと戦闘を行うダメージ計算時に発動できる。\nその相手モンスターの攻撃力をターン終了時まで８００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"トイ・ソルジャー",h:"とい・そるじゃー",e:"Toy Soldier",ra:"SR",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:800,de:300,tx:"自分のスタンバイフェイズ時に発動できる。\nデッキから「トイ・ソルジャー」１体を攻撃表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"トイ・エンペラー",h:"とい・えんぺらー",e:"Toy Emperor",ra:"SR",t:"monster",a:"地",l:6,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2300,de:800,tx:"このカードが戦闘によって相手モンスターを破壊した時に発動できる。\nデッキから罠カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"ファンタジスタ・ディエチ",h:"ふぁんたじすた・でぃえち",e:"Playmaker",ra:"SR",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2600,de:2500,tx:"レベル５モンスター×３\nこのカードが特殊召喚に成功した時、フィールド上の裏側守備表示モンスターを全て表側攻撃表示にする。\nこの時、リバースモンスターの効果は発動しない。\nまた、このカードがモンスターを攻撃したダメージステップ終了時、このカードのエクシーズ素材を１つ取り除き、相手フィールド上の攻撃力が一番高いモンスター１体を選択して発動できる。\nこのカードは選択したモンスターに続けて攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"マスクド・ナイト Lv3",h:"ますくど・ないと　れべる３",e:"Masked Knight Lv3",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:800,tx:"自分のスタンバイフェイズ時、フィールド上のこのカードを墓地へ送って発動できる。\n手札・デッキから「マスクド・ナイト Lv5」１体を特殊召喚する。\n１ターンに１度、自分のメインフェイズ時に発動できる。\n相手に２００ポイントダメージを与える。\nこの効果を発動するターン、このカードは攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"マスクド・ナイト Lv5",h:"ますくど・ないと　れべる５",e:"Masked Knight Lv5",ra:"R",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:2300,de:1300,tx:"自分のスタンバイフェイズ時、フィールド上のこのカードを墓地へ送って発動できる。\n手札・デッキから「マスクド・ナイト Lv7」１体を特殊召喚する。\nこの効果はこのカードが召喚・特殊召喚・リバースしたターンには発動できない。\n１ターンに１度、自分のメインフェイズ時に発動できる。\n相手に５００ポイントダメージを与える。\nこの効果を発動するターン、このカードは攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"マスクド・ナイト Lv7",h:"ますくど・ないと　れべる７",e:"Masked Knight Lv7",ra:"SR",t:"monster",a:"地",l:7,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:2900,de:1800,tx:"このカードは通常召喚できない。「マスクド・ナイト Lv5」の効果でのみ特殊召喚できる。\n１ターンに１度、自分のメインフェイズ時に発動できる。\n相手に７５０ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"トマト王ドレッドキング",h:"とまとおうどれっどきんぐ",e:"Tomato King",ra:"UR",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"植物族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1600,de:800,tx:"植物族レベル３モンスター×２\n１ターンに１度、このカードのエクシーズ素材を１つ取り除いて発動できる。\nこのカードの攻撃力は相手ターン終了時まで元々の攻撃力の倍になる。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"トマトマトリョーシカ",h:"とまとまとりょーしか",e:"Tomato in Tomato",ra:"SR",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"植物族",mo:["効果"],ma:"",tp:"",at:1000,de:1400,tx:"このカードが召喚に成功した時、手札・デッキから「トマトマトリョーシカ」１体を特殊召喚できる。\nこのカードをエクシーズ召喚の素材とする場合、植物族モンスターのエクシーズ召喚にしか使用できない。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"キャット・ガール",h:"きゃっと・がーる",e:"Cat Girl",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1200,de:1600,tx:"このカードが召喚に成功した時に発動できる。\n自分の手札・墓地から「捨て猫娘」１体を選んで特殊召喚する。\nこの効果を発動したターン、自分は攻撃力が２０００より高いモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"捨て猫娘",h:"すてねこむすめ",e:"Stray Cat Girl",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:600,de:1500,tx:"相手モンスターの攻撃宣言時、自分フィールド上のレベル１の獣族モンスター１体をリリースして発動できる。\nこのカードを手札から特殊召喚する。\nまた、攻撃表示のこのカードは戦闘では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"捨て猫",h:"すてねこ",e:"Stray Cat",ra:"SR",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:100,de:300,tx:"このカードが召喚に成功した時に発動できる。\n手札から「捨て猫」１体を特殊召喚する。\nまた、このカードがフィールド上に表側攻撃表示で存在する限り、相手は他のモンスターを攻撃対象に選択できない。\nこのカードが戦闘によって破壊され墓地へ送られた時に発動できる。\n自分の手札・デッキ・墓地から「化け猫」１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"カブレラストーン",h:"かぶれらすとーん",e:"Cabrera Stone",ra:"R",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:0,de:0,tx:"このカードが表側守備表示でフィールド上に存在する場合、このカードを破壊する。\nこのカードが破壊された場合、自分は１０００ポイントダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"アショカ・ピラー",h:"あしょか・ぴらー",e:"Ashoka Pillar",ra:"R",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"岩石族",mo:["効果"],ma:"",tp:"",at:0,de:2200,tx:"このカードが破壊された場合、自分は１０００ポイントダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"グランエルA",h:"ぐらんえるあたっく",e:"Granel Attack",ra:"SR",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1300,de:0,tx:"フィールド上に「機皇帝」と名のついたモンスターが存在しない場合、このカードは破壊される。\n自分の「機皇帝」と名のついたモンスターが守備表示モンスターを攻撃した場合、そのモンスターはもう１度だけ続けて攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"グランエルC",h:"ぐらんえるきゃりあ",e:"Granel Carrier",ra:"SR",t:"monster",a:"地",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:700,de:700,tx:"フィールド上に「機皇帝」と名のついたモンスターが存在しない場合、このカードは破壊される。\n１ターンに１度だけ自分のモンスターは戦闘では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"マッハ・シンクロン",h:"まっは・しんくろん",e:"Mach Synchron",ra:"UR",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"機械族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"このカードがシンクロ召喚の素材として墓地へ送られたターンのエンドフェイズ時、そのシンクロ召喚の素材とした他のモンスター１体を自分の墓地から選択して発動できる。\n選択したモンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"カイトロイド",h:"かいとろいど",e:"Kiteroid",ra:"SR",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:200,de:400,tx:"相手モンスターが直接攻撃した場合、そのダメージ計算時に、このカードを手札から捨てて発動できる。\nその戦闘によって発生する自分への戦闘ダメージを０にする。\nまた、相手モンスターが直接攻撃した場合、そのダメージ計算時に、墓地のこのカードを除外して発動できる。\nその戦闘によって発生する自分への戦闘ダメージを０にする。",li:2,lk:[],ta:[],al:[],ocg:false},
  {n:"アブサード・スティーラー",h:"あぶさーど・すてぃーらー",e:"Absurd Stealer",ra:"SR",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:100,de:0,tx:"このカードが召喚に成功した時、相手フィールド上のレベル４以下の攻撃表示モンスター１体を選択して発動する。\n選択したモンスターの攻撃力を半分にし、ターン終了時までこのカードの攻撃力は選択したモンスターの守備力と同じ数値になる。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"スフィア・ボム 球体時限爆弾",h:"すふぃあ・ぼむ　きゅうたいじげんばくだん",e:"Blast Sphere",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1400,de:1400,tx:"①：裏側守備表示のこのカードが相手モンスターに攻撃されたダメージ計算前に発動する。\nこのカードを装備カード扱いとしてその攻撃モンスターに装備する。\n②：このカードの効果でこのカードを装備した次の相手スタンバイフェイズに発動する。\n装備モンスターを破壊し、その攻撃力の半分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"バイス・バーサーカー",h:"ばいす・ばーさーかー",e:"Vice Berserker",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1000,de:1000,tx:"このカードがシンクロ召喚の素材として墓地へ送られた場合、そのプレイヤーに１０００ポイントダメージを与える。\nまた、このカードをシンクロ素材としたシンクロモンスターの攻撃力は、ターン終了時まで２０００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"勇気機関車ブレイブポッポ",h:"ゆうききかんしゃぶれいぶぽっぽ",e:"Lionhearted Locomotive",ra:"SR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:2400,de:2100,tx:"①：このカードの攻撃宣言時に発動する。\nこのカードの攻撃力はダメージステップ終了時まで元々の攻撃力の半分になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"豪腕特急トロッコロッコ",h:"ごうわんとっきゅうとろっころっこ",e:"Express Train Trolley Olley",ra:"UR",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"機械族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"①：フィールドのこのカードを素材としてX召喚したモンスターは以下の効果を得る。\n●このX召喚に成功した場合に発動する。\nこのカードの攻撃力は８００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"風竜星－ホロウ",h:"ふうりゅうせい－ほろう",e:"Pulao, Wind of the Yang Zing",ra:"N",t:"monster",a:"風",l:1,ps:"",pe:"",tr:"幻竜族",mo:["効果"],ma:"",tp:"",at:0,de:1800,tx:"「風竜星－ホロウ」の①の効果は１ターンに１度しか使用できない。\n①：自分フィールドのこのカードが戦闘・効果で破壊され墓地へ送られた時に発動できる。\nデッキから「風竜星－ホロウ」以外の「竜星」モンスター１体を攻撃表示で特殊召喚する。\n②：１ターンに１度、相手のメインフェイズ及びバトルフェイズに発動できる。\n自分フィールドの「竜星」モンスターのみをS素材としてS召喚する。\n③：このカードをS素材としたSモンスターは、魔法カードの効果を受けない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"黄昏の忍者－カゲン",h:"たそがれのにんじゃ－かげん",e:"Twilight Ninja Kagen",ra:"N",t:"monster",a:"闇",l:1,ps:10,pe:"①：自分は「忍者」モンスターしかP召喚できない。\nこの効果は無効化されない。\n②：１ターンに１度、自分の「忍者」モンスターの攻撃宣言時に発動できる。\nそのモンスターの攻撃力はダメージステップ終了時まで１０００アップする。",tr:"戦士族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:0,de:2000,tx:"①：このカードをリリースし、自分フィールドの「忍者」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで８００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"水竜星－ビシキ",h:"すいりゅうせい－びしき",e:"Bixi, Water of the Yang Zing",ra:"N",t:"monster",a:"水",l:2,ps:"",pe:"",tr:"幻竜族",mo:["効果"],ma:"",tp:"",at:0,de:2000,tx:"「水竜星－ビシキ」の①の効果は１ターンに１度しか使用できない。\n①：自分フィールドのこのカードが戦闘・効果で破壊され墓地へ送られた時に発動できる。\nデッキから「水竜星－ビシキ」以外の「竜星」モンスター１体を攻撃表示で特殊召喚する。\n②：１ターンに１度、相手のメインフェイズ及びバトルフェイズに発動できる。\n自分フィールドの「竜星」モンスターのみをS素材としてS召喚する。\n③：このカードをS素材としたSモンスターは、罠カードの効果を受けない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"彼岸の悪鬼 ラビキャント",h:"ひがんのあっき　らびきゃんと",e:"Rubic, Malebranche of the Burning Abyss",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["チューナー","効果"],ma:"",tp:"",at:100,de:2100,tx:"「彼岸の悪鬼 ラビキャント」の①の効果は１ターンに１度しか使用できない。\nこのカードをS素材とする場合、「彼岸」モンスターのS召喚にしか使用できない。\n①：自分フィールドに魔法・罠カードが存在しない場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：自分フィールドに「彼岸」モンスター以外のモンスターが存在する場合にこのカードは破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"彼岸の悪鬼 アリキーノ",h:"ひがんのあっき　ありきーの",e:"Alich, Malebranche of the Burning Abyss",ra:"N",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1200,de:0,tx:"「彼岸の悪鬼 アリキーノ」の①③の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドに魔法・罠カードが存在しない場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：自分フィールドに「彼岸」モンスター以外のモンスターが存在する場合にこのカードは破壊される。\n③：このカードが墓地へ送られた場合、フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの効果をターン終了時まで無効にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ナチュル・フライトフライ",h:"なちゅる・ふらいとふらい",e:"Naturia Fruitfly",ra:"N",t:"monster",a:"地",l:3,ps:"",pe:"",tr:"昆虫族",mo:["効果"],ma:"",tp:"",at:800,de:1500,tx:"このカードがフィールド上に表側表示で存在する限り、相手フィールド上のモンスターの攻撃力・守備力は、自分フィールド上の「ナチュル」と名のついたモンスターの数×３００ポイントダウンする。\nまた、１ターンに１度、相手フィールド上の守備力が０のモンスター１体を選択し、エンドフェイズ時までコントロールを得る事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アクア・スネーク",h:"あくあ・すねーく",e:"Aqua Snake",ra:"N",t:"monster",a:"水",l:3,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1050,de:900,tx:"尾の先についている玉で催眠術をかけ、相手をおぼれさせる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"羅刹",h:"らせつ",e:"Rasetsu",ra:"N",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"天使族",mo:["スピリット","効果"],ma:"",tp:"",at:1500,de:1900,tx:"このカードは特殊召喚できない。\n召喚・リバースしたターンのエンドフェイズ時に持ち主の手札に戻る。\nこのカードが召喚・リバースした時、「羅刹」以外の手札のスピリットモンスター１体を相手に見せて発動できる。\n相手フィールド上に表側攻撃表示で存在するモンスター１体を選択して持ち主の手札に戻す。\nこの効果を発動するターン、自分はモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マジカル・アブダクター",h:"まじかる・あぶだくたー",e:"Magical Abductor",ra:"N",t:"monster",a:"地",l:4,ps:3,pe:"①：このカードがPゾーンに存在する限り、自分または相手が魔法カードを発動する度に、このカードに魔力カウンターを１つ置く。\n②：１ターンに１度、このカードの魔力カウンターを３つ取り除いて発動できる。\nデッキからPモンスター１体を手札に加える。",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1700,de:1400,tx:"①：このカードがモンスターゾーンに存在する限り、自分または相手が魔法カードを発動する度に、このカードに魔力カウンターを１つ置く。\n②：このカードの攻撃力は、このカードの魔力カウンターの数×１００アップする。\n③：１ターンに１度、このカードの魔力カウンターを３つ取り除いて発動できる。\nデッキから魔法使い族・レベル１モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マジェスペクター・フォックス",h:"まじぇすぺくたー・ふぉっくす",e:"Majespecter Fox - Kyubi",ra:"N",t:"monster",a:"風",l:4,ps:2,pe:"",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1500,de:1000,tx:"「マジェスペクター・フォックス」の①のモンスター効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した時に発動できる。\nデッキから「マジェスペクター」罠カード１枚を手札に加える。\n②：このカードはモンスターゾーンに存在する限り、相手の効果の対象にならず、相手の効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"黄昏の忍者－シンゲツ",h:"たそがれのにんじゃ－しんげつ",e:"Twilight Ninja Shingetsu",ra:"N",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"戦士族",mo:["効果"],ma:"",tp:"",at:1500,de:100,tx:"「黄昏の忍者－シンゲツ」の②の効果は１ターンに１度しか使用できない。\n①：このカードがモンスターゾーンに存在する限り、相手は他の「忍者」モンスターを攻撃対象にできず、効果の対象にもできない。\n②：このカードが戦闘または相手の効果で破壊され墓地へ送られた場合に発動できる。\nデッキから「黄昏の忍者－シンゲツ」以外の「忍者」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"タートル・タイガー",h:"たーとる・たいがー",e:"Turtle Tiger",ra:"N",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"水族",mo:["通常"],ma:"",tp:"",at:1000,de:1500,tx:"甲羅を持ったトラ。\n堅い甲羅で身を守り、鋭いキバで攻撃する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ストーンジャイアント",h:"すとーんじゃいあんと",e:"Stone Ogre Grotto",ra:"N",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"岩石族",mo:["通常"],ma:"",tp:"",at:1600,de:1500,tx:"巨大な岩が積み重なってできている、岩石の巨人。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ランスフォリンクス",h:"らんすふぉりんくす",e:"Lancephorhynchus",ra:"N",t:"monster",a:"風",l:6,ps:7,pe:"①：自分の通常モンスターが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ相手に戦闘ダメージを与える。",tr:"恐竜族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:2500,de:800,tx:"太古の絶滅を生き延びた幻の翼竜。\nその姿はより攻撃的に進化し、クチバシは全てを貫く槍と化した。\n・・・それでも主食は魚らしい。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"黄昏の忍者－ジョウゲン",h:"たそがれのにんじゃ－じょうげん",e:"Twilight Ninja Jogen",ra:"N",t:"monster",a:"闇",l:7,ps:1,pe:"①：自分の「忍者」モンスターが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ相手に戦闘ダメージを与える。",tr:"戦士族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2000,de:1000,tx:"①：手札の「忍法」カード１枚を相手に見せて発動できる。\nこのカードを手札から特殊召喚する。\n②：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"光竜星－リフン",h:"こうりゅうせい－りふん",e:"Chiwen, Light of the Yang Zing",ra:"R",t:"monster",a:"光",l:1,ps:"",pe:"",tr:"幻竜族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:0,tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドのこのカードが戦闘・効果で破壊され墓地へ送られた時に発動できる。\nデッキから「光竜星－リフン」以外の「竜星」モンスター１体を特殊召喚する。\n②：このカードが墓地に存在し、自分フィールドの「竜星」モンスターが戦闘・効果で破壊され墓地へ送られた時に発動できる。\nこのカードを墓地から特殊召喚する。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マジェスペクター・ラクーン",h:"まじぇすぺくたー・らくーん",e:"Majespecter Raccoon - Bunbuku",ra:"R",t:"monster",a:"風",l:3,ps:5,pe:"",tr:"魔法使い族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1200,de:900,tx:"「マジェスペクター・ラクーン」の①のモンスター効果は１ターンに１度しか使用できない。\n①：このカードが召喚・特殊召喚に成功した時に発動できる。\nデッキから「マジェスペクター」モンスター１体を手札に加える。\n②：このカードはモンスターゾーンに存在する限り、相手の効果の対象にならず、相手の効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"彼岸の悪鬼 ファーファレル",h:"ひがんのあっき　ふぁーふぁれる",e:"Farfa, Malebranche of the Burning Abyss",ra:"R",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1000,de:1900,tx:"このカード名の①③の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドに魔法・罠カードが存在しない場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：自分フィールドに「彼岸」モンスター以外のモンスターが存在する場合にこのカードは破壊される。\n③：このカードが墓地へ送られた場合、フィールドのモンスター１体を対象として発動できる。\nそのモンスターをエンドフェイズまで除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"破面竜",h:"ねいきっど・どらごん",e:"Unmasked Dragon",ra:"R",t:"monster",a:"炎",l:3,ps:"",pe:"",tr:"幻竜族",mo:["効果"],ma:"",tp:"",at:1400,de:1100,tx:"①：このカードが戦闘で破壊され墓地へ送られた時に発動できる。\nデッキから守備力１５００以下の幻竜族モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ライブラの魔法秤",h:"らいぶらのまほうびん",e:"Magicalibra",ra:"R",t:"monster",a:"水",l:4,ps:5,pe:"このカード名のP効果は１ターンに１度しか使用できない。\n①：１～６までの任意のレベルを宣言し、自分フィールドの表側表示モンスター２体を対象として発動できる。\nターン終了時まで、対象のモンスター１体のレベルを宣言したレベル分だけ下げ、もう１体のモンスターのレベルを宣言したレベル分だけ上げる。",tr:"魔法使い族",mo:["ペンデュラム","チューナー","通常"],ma:"",tp:"",at:1000,de:1000,tx:"意思を持った天秤。\n世の中の均衡を保っているが、しばしば間違った方に錘星を乗せてしまう。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブリザード・ドラゴン",h:"ぶりざーど・どらごん",e:"Blizzard Dragon",ra:"R",t:"monster",a:"水",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["効果"],ma:"",tp:"",at:1800,de:1000,tx:"相手フィールド上に存在するモンスター１体を選択して発動する。\n選択したモンスターは次の相手のエンドフェイズ時まで攻撃宣言をする事ができず、表示形式を変更する事もできない。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"和魂",h:"にぎたま",e:"Nikitama",ra:"R",t:"monster",a:"光",l:4,ps:"",pe:"",tr:"天使族",mo:["スピリット","効果"],ma:"",tp:"",at:800,de:1800,tx:"このカードは特殊召喚できない。\n①：このカードが召喚・リバースしたターン、自分は通常召喚に加えて１度だけ、自分メインフェイズにスピリットモンスター１体を召喚できる。\n②：このカードが召喚・リバースしたターンのエンドフェイズに発動する。\nこのカードを持ち主の手札に戻す。\n③：このカードが墓地へ送られた場合に発動する。\n自分はデッキから１枚ドローする。\nこの効果は自分フィールドにスピリットモンスターが存在する場合に発動と処理を行う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"炎竜星－シュンゲイ",h:"えんりゅうせい－しゅんげい",e:"Suanni, Fire of the Yang Zing",ra:"R",t:"monster",a:"炎",l:4,ps:"",pe:"",tr:"幻竜族",mo:["効果"],ma:"",tp:"",at:1900,de:0,tx:"「炎竜星－シュンゲイ」の①の効果は１ターンに１度しか使用できない。\n①：自分フィールドのこのカードが戦闘・効果で破壊され墓地へ送られた時に発動できる。\nデッキから「炎竜星－シュンゲイ」以外の「竜星」モンスター１体を守備表示で特殊召喚する。\n②：１ターンに１度、相手のメインフェイズ及びバトルフェイズに発動できる。\n自分フィールドの「竜星」モンスターのみをS素材としてS召喚する。\n③：このカードをS素材としたSモンスターは、攻撃力・守備力が５００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴェルズ・サンダーバード",h:"う゛ぇるず・さんだーばーど",e:"Evilswarm Thunderbird",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"雷族",mo:["効果"],ma:"",tp:"",at:1650,de:1050,tx:"魔法・罠・効果モンスターの効果が発動した時、自分フィールド上のこのカードをゲームから除外できる。\nこの効果は相手ターンでも発動できる。\nこの効果で除外したこのカードは次のスタンバイフェイズ時にフィールド上に戻り、攻撃力は３００ポイントアップする。\n「ヴェルズ・サンダーバード」の効果は１ターンに１度しか発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"荒魂",h:"あらたま",e:"Aratama",ra:"R",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["スピリット","効果"],ma:"",tp:"",at:800,de:1800,tx:"このカードは特殊召喚できない。\n①：このカードが召喚・リバースした時に発動できる。\nデッキから「荒魂」以外のスピリットモンスター１体を手札に加える。\n②：このカードが召喚・リバースしたターンのエンドフェイズに発動する。\nこのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔竜星－トウテツ",h:"まりゅうせい－とうてつ",e:"Taotie, Shadow of the Yang Zing",ra:"R",t:"monster",a:"闇",l:5,ps:"",pe:"",tr:"幻竜族",mo:["効果"],ma:"",tp:"",at:2200,de:0,tx:"「魔竜星－トウテツ」の①の効果は１ターンに１度しか使用できない。\n①：自分フィールドのこのカードが戦闘・効果で破壊され墓地へ送られた時に発動できる。\nデッキから「魔竜星－トウテツ」以外の「竜星」モンスター１体を守備表示で特殊召喚する。\n②：１ターンに１度、相手のメインフェイズ及びバトルフェイズに発動できる。\n自分フィールドの「竜星」モンスターのみをS素材としてS召喚する。\n③：このカードをS素材としたSモンスターは、コントロールを変更できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴェルズ・ナイトメア",h:"う゛ぇるず・ないとめあ",e:"Evilswarm Nightmare",ra:"SR",t:"monster",a:"闇",l:4,ps:"",pe:"",tr:"悪魔族",mo:["エクシーズ","効果"],ma:"",tp:"",at:950,de:1950,tx:"闇属性レベル４モンスター×２\n相手がモンスターの特殊召喚に成功した時、このカードのエクシーズ素材を１つ取り除いて発動できる。\nその特殊召喚したモンスターを裏側守備表示にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"闇竜星－ジョクト",h:"あんりゅうせい－じょくと",e:"Jiaotu, Darkness of the Yang Zing",ra:"SR",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"幻竜族",mo:["チューナー","効果"],ma:"",tp:"",at:0,de:2000,tx:"「闇竜星－ジョクト」の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドのこのカードが戦闘・効果で破壊され墓地へ送られた時に発動できる。\nデッキから「闇竜星－ジョクト」以外の「竜星」モンスター１体を攻撃表示で特殊召喚する。\n②：自分フィールドにこのカード以外のモンスターが存在しない場合、手札の「竜星」カード２枚を墓地へ送って発動できる。\nデッキから攻撃力０と守備力０の「竜星」モンスターを１体ずつ特殊召喚する。\nこの効果で特殊召喚したモンスターはエンドフェイズに除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"彼岸の悪鬼 ガトルホッグ",h:"ひがんのあっき　がとるほっぐ",e:"Cir, Malebranche of the Burning Abyss",ra:"SR",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:1600,de:1200,tx:"「彼岸の悪鬼 ガトルホッグ」の①③の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドに魔法・罠カードが存在しない場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：自分フィールドに「彼岸」モンスター以外のモンスターが存在する場合にこのカードは破壊される。\n③：このカードが墓地へ送られた場合、「彼岸の悪鬼 ガトルホッグ」以外の自分の墓地の「彼岸」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"彼岸の詩人 ウェルギリウス",h:"ひがんのしじん　うぇるぎりうす",e:"Virgil, Rock Star of the Burning Abyss",ra:"SR",t:"monster",a:"光",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["シンクロ","効果"],ma:"",tp:"",at:2500,de:1000,tx:"チューナー＋チューナー以外のモンスター１体以上\n「彼岸の詩人 ウェルギリウス」の③の効果は１ターンに１度しか使用できない。\n①：「彼岸の詩人 ウェルギリウス」は自分フィールドに１体しか表側表示で存在できない。\n②：１ターンに１度、手札から「彼岸」カード１枚を捨て、相手のフィールド・墓地のカード１枚を対象として発動できる。\nそのカードを持ち主のデッキに戻す。\n③：フィールドのこのカードが戦闘・効果で破壊され墓地へ送られた場合に発動できる。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"破戒蛮竜－バスター・ドラゴン",h:"はかいばんりゅう－ばすたー・どらごん",e:"Buster Dragon",ra:"SR",t:"monster",a:"闇",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:1200,de:2800,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：相手フィールドのモンスターは、このカードが表側表示で存在する限りドラゴン族になる。\n②：自分フィールドに「バスター・ブレイダー」モンスターが存在しない場合、１ターンに１度、自分の墓地の「バスター・ブレイダー」１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n③：相手ターンに１度、自分フィールドの「バスター・ブレイダー」モンスター１体を対象として発動できる。\n自分の墓地の「破壊剣」モンスター１体を装備カード扱いとして対象のモンスターに装備する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"剛竜剣士ダイナスターP",h:"ごうりゅうけんしだいなすたーぱわふる",e:"Dinoster Power, the Mighty Dracoslayer",ra:"SR",t:"monster",a:"水",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["融合","効果"],ma:"",tp:"",at:2000,de:2950,tx:"「竜剣士」Pモンスター＋Pモンスター\n自分フィールドの上記カードをリリースした場合のみ、エクストラデッキから特殊召喚できる（「融合」は必要としない）。\n①：このカードがモンスターゾーンに存在する限り、自分のモンスターゾーン・PゾーンのPモンスターカードは戦闘及び相手の効果では破壊されない。\n②：１ターンに１度、自分メインフェイズに発動できる。\n自分の手札・墓地から「竜剣士」Pモンスター１体を選んで特殊召喚する。\nこの効果で特殊召喚したモンスターは融合素材にできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"彼岸の旅人 ダンテ",h:"ひがんのたびびと　だんて",e:"Dante, Traveler of the Burning Abyss",ra:"UR",t:"monster",a:"光",l:3,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1000,de:2500,tx:"レベル３モンスター×２\n①：１ターンに１度、このカードのX素材を１つ取り除き、自分のデッキの上からカードを３枚まで墓地へ送って発動できる。\nこのカードの攻撃力はターン終了時まで、この効果を発動するために墓地へ送ったカードの数×５００アップする。\n②：このカードは攻撃した場合、バトルフェイズ終了時に守備表示になる。\n③：このカードが墓地へ送られた場合、このカード以外の自分の墓地の「彼岸」カード１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"彼岸の悪鬼 スカラマリオン",h:"ひがんのあっき　すからまりおん",e:"Scarm, Malebranche of the Burning Abyss",ra:"UR",t:"monster",a:"闇",l:3,ps:"",pe:"",tr:"悪魔族",mo:["効果"],ma:"",tp:"",at:800,de:2000,tx:"このカード名の①③の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドに魔法・罠カードが存在しない場合に発動できる。\nこのカードを手札から特殊召喚する。\n②：自分フィールドに「彼岸」モンスター以外のモンスターが存在する場合にこのカードは破壊される。\n③：このカードが墓地へ送られたターンのエンドフェイズに発動できる。\nデッキから「彼岸の悪鬼 スカラマリオン」以外の悪魔族・闇属性・レベル３モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"竜剣士マスターP",h:"りゅうけんしますたーぺんでゅらむ",e:"Master Pendulum, the Dracoslayer",ra:"UR",t:"monster",a:"光",l:4,ps:3,pe:"①：このカードがPゾーンに存在する限り１度だけ、自分または相手のPゾーンのカード１枚を対象として発動できる。\nそのカードを破壊する。",tr:"ドラゴン族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:1950,de:0,tx:"同志たちの力を得て成長した「竜剣士ラスターP」の姿。\n謎の呪いをかけられて竜魔族に似た竜の力を発現しているが、それ以前の記憶が全て失われており、真相は定かではない。\n“竜化の秘法”がその呪いと記憶を紐解く鍵だと信じて、今日も悪の魔王を討つべく旅を続けている。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"オッドアイズ・アークペンデュラム・ドラゴン",h:"おっどあいず・あーくぺんでゅらむ・どらごん",e:"Odd-Eyes Arc Pendulum Dragon",ra:"UR",t:"monster",a:"闇",l:7,ps:8,pe:"このカード名のP効果は１ターンに１度しか使用できない。\n①：自分フィールドの表側表示の「オッドアイズ」カードが戦闘・効果で破壊された場合に発動できる。\n自分の手札・デッキ・墓地から「オッドアイズ」モンスター１体を選んで特殊召喚する。",tr:"ドラゴン族",mo:["ペンデュラム","通常"],ma:"",tp:"",at:2700,de:2000,tx:"雄々しくも美しき、神秘の眼を持つ奇跡の龍。\n\nその二色に輝く眼は、天空に描かれし軌跡を映す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"輝竜星－ショウフク",h:"きりゅうせい－しょうふく",e:"Baxia, Brightness of the Yang Zing",ra:"UR",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"幻竜族",mo:["シンクロ","効果"],ma:"",tp:"",at:2300,de:2600,tx:"チューナー＋チューナー以外の幻竜族モンスター１体以上\n①：このカードがS召喚に成功した時、このカードのS素材とした幻竜族モンスターの元々の属性の種類の数まで、フィールドのカードを対象として発動できる。\nそのカードを持ち主のデッキに戻す。\n②：１ターンに１度、自分フィールドのカード１枚と自分の墓地のレベル４以下のモンスター１体を対象として発動できる。\nそのフィールドのカードを破壊し、その墓地のモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トリブルドッグ",h:"とりぶるどっぐ",e:"Tribulldog",ra:"R",t:"monster",a:"地",l:4,ps:"",pe:"",tr:"獣族",mo:["効果"],ma:"",tp:"",at:1200,de:1100,tx:"１ターンに１度、自分のメインフェイズ時に発動できる。手札から「トリブルドッグ」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"カタパルト・ウォリアー",h:"かたぱると・うぉりあー",e:"Catapult Warrior",ra:"SR",t:"monster",a:"地",l:5,ps:"",pe:"",tr:"戦士族",mo:["シンクロ","効果"],ma:"",tp:"",at:1000,de:1500,tx:"チューナー＋チューナー以外のモンスター１体以上\n１ターン１度、自分フィールド上の「ジャンク」と名のついたモンスター１体をリリースして発動できる。\nリリースしたモンスターの元々の攻撃力の半分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"DDケルベロス",h:"でぃーでぃーけるべろす",e:"D/D Cerberus",ra:"SR",t:"monster",a:"闇",l:4,ps:6,pe:"①：１ターンに１度、自分フィールドの「DD」モンスター１体を対象として発動できる。\nそのモンスターのレベルを４にし、攻撃力・守備力を４００アップする。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:1800,de:600,tx:"①：このカードが手札からのペンデュラム召喚に成功した時、「DDケルベロス」以外の「DD」モンスターが自分フィールドに存在する場合に自分の墓地の永続魔法カード１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"DDD反骨王レオニダス",h:"でぃーでぃーでぃーはんこつおうれおにだす",e:"D/D/D Rebel King Leonidas",ra:"UR",t:"monster",a:"闇",l:7,ps:3,pe:"①：自分が効果ダメージを受けた時にこの効果を発動できる。\nこのカードを破壊し、さらにこのターン、LPにダメージを与える効果は、LPを回復する効果になる。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:2600,de:1200,tx:"①：自分が効果ダメージを受けた時に発動できる。\nこのカードを手札から特殊召喚し、受けたダメージの数値分だけ自分のLPを回復する。\n②：このカードがモンスターゾーンに存在する限り、自分が受ける効果ダメージは０になる。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"ホーリー・ナイト・ドラゴン",h:"ほーりー・ないと・どらごん",e:"Seiyaryu",ra:"UR",t:"monster",a:"光",l:7,ps:"",pe:"",tr:"ドラゴン族",mo:["通常"],ma:"",tp:"",at:2500,de:2300,tx:"聖なる炎で悪しき者を焼きはらう、神聖な力を持つドラゴン。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"見習い魔術師",h:"みならいまじゅつし",e:"Apprentice Magician",ra:"R",t:"monster",a:"闇",l:2,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:400,de:800,tx:"①：このカードが召喚・反転召喚・特殊召喚に成功した場合、フィールドの魔力カウンターを置く事ができるカード１枚を対象として発動する。\nそのカードに魔力カウンターを１つ置く。\n②：このカードが戦闘で破壊された時に発動できる。\nデッキからレベル２以下の魔法使い族モンスター１体を裏側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"ダイガスタ・フェニクス",h:"だいがすた・ふぇにくす",e:"Daigusto Phoenix",ra:"SR",t:"monster",a:"風",l:2,ps:"",pe:"",tr:"炎族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1500,de:1100,tx:"レベル２モンスター×２\n１ターンに１度、このカードのエクシーズ素材を１つ取り除き、自分フィールド上の風属性モンスター１体を選択して発動できる。\nこのターン、選択したモンスターは１度のバトルフェイズ中に２回攻撃する事ができる。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"昇竜剣士マジェスターP",h:"しょうりゅうけんしまじぇすたーぱらでぃん",e:"Majester Paladin, the Ascending Dracoslayer",ra:"SR",t:"monster",a:"風",l:4,ps:"",pe:"",tr:"ドラゴン族",mo:["エクシーズ","効果"],ma:"",tp:"",at:1850,de:2000,tx:"レベル４のペンデュラムモンスター×２\n①：このカードがエクシーズ召喚に成功した時に発動できる。\nこのターンのエンドフェイズに、デッキからペンデュラムモンスター１体を手札に加える。\n②：１ターンに１度、このカードのエクシーズ素材を１つ取り除いて発動できる。\n自分のエクストラデッキから表側表示の「竜剣士」ペンデュラムモンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターはエクシーズ召喚の素材にできない。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"虚空の黒魔導師",h:"こくうのくろまどうし",e:"Ebon High Magician",ra:"SR",t:"monster",a:"闇",l:7,ps:"",pe:"",tr:"魔法使い族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2300,de:2800,tx:"魔法使い族レベル７モンスター×２\n①：エクシーズ素材を持ったこのカードがモンスターゾーンに存在する限り、自分は相手ターンに速攻魔法カード及び罠カードを手札から発動できる。\nその発動の際にこのカードのエクシーズ素材を１つ取り除く。\n②：エクシーズ召喚したこのカードが、相手の効果で墓地へ送られた場合、または戦闘で破壊され墓地へ送られた場合に発動できる。\n手札・デッキから魔法使い族・闇属性モンスター１体を特殊召喚する。\nその後、フィールドのカード１枚を選んで破壊できる。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"熱血指導王ジャイアントレーナー",h:"ねっけつしどうおうじゃいあんとれーなー",e:"Coach King Giantrainer",ra:"SR",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"戦士族",mo:["エクシーズ","効果"],ma:"",tp:"",at:2800,de:2000,tx:"レベル８モンスター×３\nこのカードのエクシーズ素材を１つ取り除いて発動できる。\nデッキからカードを１枚ドローし、お互いに確認する。\n確認したカードがモンスターだった場合、さらに相手ライフに４００ポイントダメージを与える。\nこの効果を発動するターン、自分はバトルフェイズを行えない。\n「熱血指導王ジャイアントレーナー」の効果は１ターンに３度まで使用できる。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"インフェルニティ・ヘル・デーモン",h:"いんふぇるにてぃ・へる・でーもん",e:"Infernity Doom Archfiend",ra:"SR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"悪魔族",mo:["シンクロ","効果"],ma:"",tp:"",at:2200,de:1600,tx:"チューナー＋チューナー以外のモンスター１体以上\n①：１ターンに１度、フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードの効果をターン終了時まで無効にする。\n自分の手札が０枚の場合、さらにそのカードを破壊できる。\n②：自分の手札が０枚である限り、このカードが相手モンスターとの戦闘で相手に与える戦闘ダメージは倍になる。\n③：このカードをシンクロ素材とした闇属性シンクロモンスターは１度のバトルフェイズ中に２回までモンスターに攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"爆竜剣士イグニスターP",h:"ばくりゅうけんしいぐにすたーぷろみねんす",e:"Ignister Prominence, the Blasting Dracoslayer",ra:"SR",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"ドラゴン族",mo:["シンクロ","効果"],ma:"",tp:"",at:2850,de:0,tx:"チューナー＋チューナー以外のペンデュラムモンスター１体以上\n①：１ターンに１度、フィールドのペンデュラムモンスター１体またはペンデュラムゾーンのカード１枚を対象として発動できる。\nそのカードを破壊し、フィールドのカード１枚を選んで持ち主のデッキに戻す。\n②：１ターンに１度、自分メインフェイズに発動できる。\nデッキから「竜剣士」モンスター１体を守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターはシンクロ召喚の素材にできない。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"熱血獣王ベアーマン",h:"ねっけつじゅうおうべあーまん",e:"Coach Captain Bearman",ra:"SR",t:"monster",a:"炎",l:8,ps:"",pe:"",tr:"獣戦士族",mo:["効果"],ma:"",tp:"",at:2600,de:2700,tx:"このカードはリリースなしで召喚できる。\nこの方法で召喚したこのカードの元々の攻撃力は１３００になる。\nまた、１ターンに１度、自分のメインフェイズ時に発動できる。\n自分フィールド上の全ての獣戦士族・レベル４モンスターのレベルをターン終了時まで８にする。\nこのカードをエクシーズ召喚の素材とする場合、戦士族・炎属性モンスターのエクシーズ召喚にしか使用できない。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"DDD運命王ゼロ・ラプラス",h:"でぃーでぃーでぃーうんめいおうぜろ・らぷらす",e:"D/D/D Destiny King Zero Laplace",ra:"SR",t:"monster",a:"闇",l:10,ps:1,pe:"このカード名のペンデュラム効果は１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\n自分のEXデッキから「DDD運命王ゼロ・ラプラス」以外の表側表示の「DDD」ペンデュラムモンスター１体を選んで手札に加える。",tr:"悪魔族",mo:["ペンデュラム","効果"],ma:"",tp:"",at:"?",de:0,tx:"①：このカードは自分フィールドの「DDD」モンスター１体をリリースして手札から特殊召喚できる。\n②：このカードが相手モンスターと戦闘を行うダメージ計算前に発動できる。\nこのカードの攻撃力はダメージステップ終了時まで、その相手モンスターの元々の攻撃力の倍になる。\n③：このカードが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ戦闘ダメージを与える。\n④：このカードは１ターンに１度だけ戦闘では破壊されない。\nその際、自分が受ける戦闘ダメージは０になる。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"幻想の見習い魔導師",h:"げんそうのみならいまどうし",e:"Apprentice Illusion Magician",ra:"UR",t:"monster",a:"闇",l:6,ps:"",pe:"",tr:"魔法使い族",mo:["効果"],ma:"",tp:"",at:2000,de:1700,tx:"①：このカードは手札を１枚捨てて、手札から特殊召喚できる。\n②：このカードが召喚・特殊召喚に成功した場合に発動できる。\nデッキから「ブラック・マジシャン」１体を手札に加える。\n③：このカード以外の自分の魔法使い族・闇属性モンスターが相手モンスターと戦闘を行うダメージ計算時、手札・フィールドのこのカードを墓地へ送って発動できる。\nその自分のモンスターの攻撃力・守備力はそのダメージ計算時のみ２０００アップする。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"カオス・ソルジャー －開闢の使者－",h:"かおす・そるじゃー　－かいびゃくのししゃ－",e:"Black Luster Soldier - Envoy of the Beginning",ra:"UR",t:"monster",a:"光",l:8,ps:"",pe:"",tr:"戦士族",mo:["特殊召喚","効果"],ma:"",tp:"",at:3000,de:2500,tx:"このカードは通常召喚できない。\n自分の墓地から光属性と闇属性のモンスターを１体ずつ除外した場合に特殊召喚できる。\nこのカードの①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：フィールドのモンスター１体を対象として発動できる。\nそのモンスターを除外する。\nこの効果を発動するターン、このカードは攻撃できない。\n②：このカードの攻撃で相手モンスターを破壊した時に発動できる。\nこのカードはもう１度だけ続けて攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"真竜皇アグニマズドV",h:"しんりゅうおうあぐにまずどばにっしゃー",e:"True King Agnimazud, the Vanisher",ra:"UR",t:"monster",a:"炎",l:9,ps:"",pe:"",tr:"幻竜族",mo:["効果"],ma:"",tp:"",at:2900,de:1900,tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\nこのカード以外の手札及び自分フィールドの表側表示モンスターの中から、炎属性モンスターを含むモンスター２体を選んで破壊し、このカードを手札から特殊召喚し、炎属性モンスター２体を破壊した場合、相手のフィールド・墓地からモンスター１体を選んで除外できる。\n②：このカードが効果で破壊された場合に発動できる。\n自分の墓地から炎属性以外の幻竜族モンスター１体を選んで手札に加える。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"「A」細胞組み換え装置",h:"「えー」さいぼうくみかえそうち",e:"A Cell Recombination Device",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：フィールドの表側表示モンスター１体を対象として発動できる。\nデッキから「エーリアン」モンスター１体を墓地へ送り、墓地へ送ったモンスターのレベルの数だけ、対象のモンスターにAカウンターを置く。\n②：自分メインフェイズに墓地のこのカードを除外して発動できる。\nデッキから「エーリアン」モンスター１体を手札に加える。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"「A」細胞散布爆弾",h:"「えー」さいぼうさんぷばくだん",e:"A Cell Scatter Burst",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上の「エーリアン」と名のついたモンスター１体を選択して発動できる。\n選択した表側表示モンスターを破壊し、そのモンスターのレベルの数だけAカウンターを相手フィールド上に表側表示で存在するモンスターに置く。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"「A」細胞増殖装置",h:"「えー」さいぼうぞうしょくそうち",e:"A Cell Breeding Device",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分のスタンバイフェイズ時、相手フィールド上に表側表示で存在するモンスター１体を選択し、Aカウンターを１つ置く。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"「A」細胞培養装置",h:"「えー」さいぼうばいようそうち",e:"A Cell Incubator",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"フィールド上のAカウンターが取り除かれる度に、このカードにAカウンターを１つ置く。\nフィールド上のこのカードが破壊された時、このカードに乗っていた全てのAカウンターを、フィールド上に表側表示で存在するモンスターに置く。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"『攻撃』封じ",h:"『こうげき』ふうじ",e:"Block Attack",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"相手フィールド上に表側攻撃表示で存在するモンスター１体を選択して表側守備表示にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"『守備』封じ",h:"『しゅび』ふうじ",e:"Stop Defense",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：相手フィールドの守備表示モンスター１体を対象として発動できる。\nその相手モンスターを表側攻撃表示にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"TGX1－HL",h:"ＴＧＸ１－ＨＬ",e:"TGX1-HL",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「TG」と名のついたモンスター１体を選択して発動する。\n選択したモンスターの攻撃力・守備力を半分にし、フィールド上に存在する魔法・罠カード１枚を破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"TGX３００",h:"ＴＧＸ３００",e:"TGX300",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「TG」と名のついたモンスター１体につき、自分フィールド上に表側表示で存在するモンスターの攻撃力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アースクエイク",h:"あーすくえいく",e:"Earthquake",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在するモンスターを全て守備表示にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アーマーブラスト",h:"あーまーぶらすと",e:"Armor Blast",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上の「甲虫装機」と名のついたカード１枚と相手フィールド上に表側表示で存在するカード２枚を選択して発動する。\n選択したカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アームド・チェンジャー",h:"あーむど・ちぇんじゃー",e:"Armed Changer",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"自分の手札から装備魔法カード１枚を墓地に送って発動する。\n装備モンスターが戦闘によってモンスターを破壊した場合、装備カードのコントローラーは自分の墓地から装備モンスターの攻撃力以下のモンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"R－ライトジャスティス",h:"あーる－らいとじゃすてぃす",e:"R - Righteous Justice",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの「E・HERO」カードの数だけ、フィールドの魔法・罠カードを選んで破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アイアンコール",h:"あいあんこーる",e:"Iron Call",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドに機械族モンスターが存在する場合、自分の墓地のレベル４以下の機械族モンスター１体を対象として発動できる。\nその機械族モンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化され、エンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アイス・ミラー",h:"あいす・みらー",e:"Ice Mirror",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドのレベル３以下の水属性モンスター１体を対象として発動できる。\nその同名モンスター１体をデッキから特殊召喚する。\nこの効果で特殊召喚したモンスターが自分フィールドに表側表示で存在する限り、自分はEXデッキからモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"相乗り",h:"あいのり",e:"Shared Ride",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：このターン、相手がドロー以外の方法でデッキ・墓地からカードを手札に加える度に、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"青き眼の威光",h:"あおきめのいこう",e:"Majesty with Eyes of Blue",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：手札・デッキから「ブルーアイズ」モンスター１体を墓地へ送り、フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターはフィールドに表側表示で存在する限り、攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アクア・ジェット",h:"あくあ・じぇっと",e:"Aqua Jet",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上の魚族・海竜族・水族モンスター１体を選択して発動できる。\n選択したモンスターの攻撃力は１０００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"水舞台装置",h:"あくありうむ・せっと",e:"Aquarium Set",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの水属性モンスターの攻撃力・守備力は３００アップする。\n②：自分フィールドの「アクアアクトレス」モンスターの攻撃力・守備力は３００アップする。\n③：このカードがフィールドから墓地へ送られた場合、自分の墓地の水族モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は水族モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"水照明",h:"あくありうむ・らいてぃんぐ",e:"Aquarium Lighting",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：「水照明」は自分フィールドに１枚しか表側表示で存在できない。\n②：自分の「アクアアクトレス」モンスターが相手モンスターと戦闘を行うダメージ計算時に発動する。\nその自分のモンスターの攻撃力・守備力はダメージ計算時のみ倍になる。\n③：このカードがフィールドから墓地へ送られた場合、自分の墓地の水族モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は水族モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アクションマジック－ダブル・バンキング",h:"あくしょんまじっく－だぶる・ばんきんぐ",e:"Action Magic - Double Banking",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：手札を１枚捨てて発動できる。\n自分フィールドのモンスターは、このターン戦闘で相手モンスターを破壊した場合、もう１度だけ続けて攻撃できる。\n②：このカードが墓地に存在する場合、自分メインフェイズに手札から魔法カード１枚を捨てて発動できる。\nこのカードを自分の魔法＆罠ゾーンにセットする。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"悪魔鏡の儀式",h:"あくまかがみのぎしき",e:"Beastly Mirror Ritual",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「デビルズ・ミラー」の降臨に必要。\n場か手札から、星の数が合計６個以上になるようカードを生け贄に捧げなければならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"悪魔のくちづけ",h:"あくまのくちづけ",e:"Malevolent Nuzzler",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"①：装備モンスターの攻撃力は７００アップする。\n②：このカードがフィールドから墓地へ送られた時、５００LPを払って発動できる。\nこのカードをデッキの一番上に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"悪魔払い",h:"あくまばらい",e:"Exile of the Wicked",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"フィールド上の悪魔族モンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"悪魔への貢物",h:"あくまへのみつぎもの",e:"Faustian Bargain",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"フィールド上の特殊召喚されたモンスター１体を選択して墓地へ送り、手札からレベル４以下の通常モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"悪夢再び",h:"あくむふたたび",e:"Recurring Nightmare",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の墓地の守備力０の闇属性モンスター２体を対象として発動できる。\nその闇属性モンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アゲインスト・ウィンド",h:"あげいんすと・うぃんど",e:"Against the Wind",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地に存在する「BF」と名のついたモンスター１体を選択して発動する。\n自分はそのモンスターの攻撃力分のダメージを受け、そのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"浅すぎた墓穴",h:"あさすぎたはかあな",e:"The Shallow Grave",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"お互いのプレイヤーはそれぞれの墓地のモンスター１体を選択し、それぞれのフィールド上に裏側守備表示でセットする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アサルト・アーマー",h:"あさると・あーまー",e:"Assault Armor",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"自分フィールドのモンスターが戦士族モンスター１体のみの場合、そのモンスターに装備できる。\n①：装備モンスターの攻撃力は３００アップする。\n②：装備されているこのカードを墓地へ送って発動できる。\nこのターン、このカードを装備していたモンスターは１度のバトルフェイズ中に２回攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アシッドレイン",h:"あしっどれいん",e:"Acidic Downpour",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"全ての地属性モンスターの攻撃力は５００ポイントダウンし、守備力は４００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アシンメタファイズ",h:"あしんめたふぁいず",e:"Asymmetaphys",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：１ターンに１度、自分メインフェイズに発動できる。\n手札から「メタファイズ」カード１枚を除外し、自分はデッキから１枚ドローする。\n②：１ターンに１度、自分の「メタファイズ」カードが除外された場合に発動する。\nそのターンによって以下の効果を適用する。\n●自分ターン：「メタファイズ」モンスター以外のフィールドの全てのモンスターの攻撃力・守備力は５００ダウンする。\n●相手ターン：「メタファイズ」モンスター以外のフィールドの全てのモンスターの表示形式を変更する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アタック・フェロモン",h:"あたっく・ふぇろもん",e:"Attack Pheromones",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分フィールド上に存在する爬虫類族モンスターが守備表示モンスターを攻撃した場合、そのモンスターをダメージステップ終了時に表側攻撃表示にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アドバンス・ゾーン",h:"あどばんす・ぞーん",e:"Advance Zone",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"１ターンに１度、自分がモンスターのアドバンス召喚に成功したターンのエンドフェイズ時に発動できる。\nこのターン自分がアドバンス召喚のためにリリースしたモンスターの数によって以下の効果を適用する。\n\n●１体以上：相手フィールド上にセットされたカード１枚を選んで破壊する。\n\n●２体以上：デッキからカードを１枚ドローする。\n\n●３体以上：自分の墓地のモンスター１体を選んで手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アドバンス・フォース",h:"あどばんす・ふぉーす",e:"Advance Force",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードがフィールド上に存在する限り、レベル７以上のモンスターはレベル５以上のモンスター１体をリリースしてアドバンス召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アドバンスド・ダーク",h:"あどばんすど・だーく",e:"Advanced Dark",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：フィールドの「宝玉獣」モンスター及び墓地の「宝玉獣」モンスターは全て闇属性になる。\n②：このカードがフィールドゾーンに存在する限り、「究極宝玉神」モンスターの攻撃対象になったモンスターの効果はそのバトルフェイズの間だけ無効化される。\n③：自分の「宝玉獣」モンスターが戦闘を行うダメージ計算時に、デッキから「宝玉獣」モンスター１体を墓地へ送って発動できる。\nその戦闘で発生する自分への戦闘ダメージを０にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アドバンスドロー",h:"あどばんすどろー",e:"Advance Draw",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在するレベル８以上のモンスター１体をリリースして発動できる。\nデッキからカードを２枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アビスケイル－クラーケン",h:"あびすけいる－くらーけん",e:"Abyss-scale of the Kraken",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「水精鱗」と名のついたモンスターにのみ装備可能。\n装備モンスターの攻撃力は４００ポイントアップする。\nこのカードがフィールド上に存在する限り、相手フィールド上で発動した効果モンスターの効果を無効にする。\nその後、このカードを墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アビスケイル－ケートス",h:"あびすけいる－けーとす",e:"Abyss-scale of Cetus",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「水精鱗」と名のついたモンスターにのみ装備可能。\n装備モンスターの攻撃力は８００ポイントアップする。\nこのカードがフィールド上に存在する限り、相手フィールド上で発動した罠カードの効果を無効にする。\nその後、このカードを墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アビスケイル－ミヅチ",h:"あびすけいる－みづち",e:"Abyss-scale of the Mizuchi",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「水精鱗」と名のついたモンスターにのみ装備可能。\n装備モンスターの攻撃力は８００ポイントアップする。\nこのカードがフィールド上に存在する限り、相手フィールド上で発動した魔法カードの効果を無効にする。\nその後、このカードを墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アフター・グロー",h:"あふたー・ぐろー",e:"Afterglow",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードはデュエル中に１枚しか発動できない。\n①：自分の手札・デッキ・墓地及び自分フィールドの表側表示のカードの中から、このカードを含む「アフター・グロー」を全て除外する。\nその後、除外されている自分のカードの中から「アフター・グロー」１枚を選んでデッキに加える。\n次の自分ドローフェイズに、通常のドローをしたカードをお互いに確認する。\nそれが「アフター・グロー」だった場合、相手に４０００ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アポート",h:"あぽーと",e:"Teleport",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：１ターンに１度、相手フィールドにのみモンスターが存在する場合、８００LPを払って発動できる。\n手札からサイキック族モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"天架ける星因士",h:"あまかけるさてらないと",e:"Satellarknight Skybridge",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"「天架ける星因士」は１ターンに１枚しか発動できない。\n①：自分フィールドの「テラナイト」モンスター１体を対象として発動できる。\nそのモンスターとカード名が異なる「テラナイト」モンスター１体をデッキから特殊召喚し、対象のモンスターを持ち主のデッキに戻す。\nこの効果で特殊召喚したモンスターが表側表示で存在する限り、自分は「テラナイト」モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アマゾネスの呪詛師",h:"あまぞねすのじゅそし",e:"Amazoness Spellcaster",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"ターン終了時まで、自分フィールド上の「アマゾネス」という名のついた表側表示モンスター１体と、相手フィールド上表側表示モンスター１体の元々の攻撃力を入れ替える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アマゾネスの闘志",h:"あまぞねすのとうし",e:"Amazoness Fighting Spirit",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"「アマゾネス」と名のついたモンスターが、その攻撃力よりも高い攻撃力を持つモンスターを攻撃する場合、ダメージ計算時のみ攻撃モンスターの攻撃力は１０００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アマゾネスの秘宝",h:"あまぞねすのひほう",e:"Amazoness Heirloom",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「アマゾネス」と名のついたモンスターにのみ装備可能。\n装備モンスターは１ターンに１度だけ、戦闘では破壊されない。\n装備モンスターが攻撃した場合、その攻撃を受けたモンスターをダメージ計算後に破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"嵐",h:"あらし",e:"Storm",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上の魔法・罠カードを全て破壊する。\nその後、破壊したカードの数だけ相手フィールド上の魔法・罠カードを破壊する。",li:1,lk:[],ta:[],al:[],ocg:true},
  {n:"アリの増殖",h:"ありのぞうしょく",e:"Multiplication of Ants",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上の昆虫族モンスター１体を生け贄に捧げて発動する。\n自分フィールド上に「兵隊アリトークン」（昆虫族・地・星４・攻５００／守１２００）を２体特殊召喚する。\n（生け贄召喚のための生け贄にはできない）",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アルティメット・バースト",h:"あるてぃめっと・ばーすと",e:"Neutron Blast",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの融合召喚された「青眼の究極竜」１体を対象として発動できる。\nこのターン、そのモンスターは１度のバトルフェイズ中に３回攻撃でき、そのモンスターが攻撃する場合、相手はダメージステップ終了時まで魔法・罠・モンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アルマの魔導書",h:"あるまのまどうしょ",e:"Spellbook of Eternity",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「アルマの魔導書」以外のゲームから除外されている自分の「魔導書」と名のついた魔法カード１枚を選択して手札に加える。\n「アルマの魔導書」は１ターンに１枚しか発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アロマガーデニング",h:"あろまがーでにんぐ",e:"Aroma Gardening",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分が「アロマ」モンスターの召喚・特殊召喚に成功した場合に発動できる。\n自分は１０００LP回復する。\n②：自分のLPが相手より少ない場合、相手モンスターの攻撃宣言時に発動できる。\nデッキから「アロマ」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アロマガーデン",h:"あろまがーでん",e:"Aroma Garden",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：１ターンに１度、自分フィールドに「アロマ」モンスターが存在する場合に発動できる。\n自分は５００LP回復する。\nこの効果の発動後、次の相手ターン終了時まで自分フィールドのモンスターの攻撃力・守備力は５００アップする。\n②：自分フィールドの「アロマ」モンスターが戦闘・効果で破壊され墓地へ送られた場合に発動する。\n自分は１０００LP回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"暗黒界に続く結界通路",h:"あんこくかいにつづくけっかいつうろ",e:"Gateway to Dark World",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分の墓地に存在する「暗黒界」と名のついたモンスター１体を選択して特殊召喚する。\nこのカードを発動するターン、自分は召喚・反転召喚・特殊召喚する事はできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"暗黒界の雷",h:"あんこくかいのいかずち",e:"Dark World Lightning",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"フィールド上に裏側表示で存在するカード１枚を選択して破壊する。\nその後、自分の手札を１枚選択して捨てる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"暗黒界の援軍",h:"あんこくかいのえんぐん",e:"Charge Into a Dark World",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地のレベル４以下の悪魔族モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nその後、手札から悪魔族モンスター１体を選んで捨てる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"暗黒界の書物",h:"あんこくかいのしょもつ",e:"Dark World Grimoire",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分のエンドフェイズ時に手札枚数制限によって手札を墓地に捨てた場合、その中にモンスターカードが含まれていれば１体のみ自分フィールド上に特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"暗黒界の取引",h:"あんこくかいのとりひき",e:"Dark World Dealings",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：お互いのプレイヤーはデッキから１枚ドローし、その後手札を１枚選んで捨てる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"暗黒界の門",h:"あんこくかいのもん",e:"The Gates of Dark World",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在する悪魔族モンスターの攻撃力・守備力は３００ポイントアップする。\n１ターンに１度、自分の墓地に存在する悪魔族モンスター１体をゲームから除外する事で、手札から悪魔族モンスター１体を選択して捨てる。\nその後、自分のデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"闇黒の夢魔鏡",h:"あんこくのゆめまきょう",e:"Dream Mirror of Terror",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドに闇属性の「夢魔鏡」モンスターが存在する限り、相手がモンスターの特殊召喚に成功する度に、相手に３００ダメージを与える。\n②：自分・相手のエンドフェイズに、自分のフィールドゾーンのこのカードを除外して発動できる。\n手札・デッキから「聖光の夢魔鏡」１枚を発動する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"古代の整備場",h:"あんてぃーく・ぎあがれーじ",e:"Ancient Gear Workshop",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の墓地の「アンティーク・ギア」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"古代の機械城",h:"あんてぃーく・ぎあきゃっする",e:"Ancient Gear Castle",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：フィールドの「アンティーク・ギア」モンスターの攻撃力は３００アップする。\n②：このカードが魔法＆罠ゾーンに存在する限り、モンスターが通常召喚される度に、このカードにカウンターを１つ置く。\n③：自分が「アンティーク・ギア」モンスターを表側表示でアドバンス召喚する場合、必要なリリースの数以上のカウンターが置かれたこのカードを代わりにリリースしてアドバンス召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"古代の機械戦車",h:"あんてぃーく・ぎあたんく",e:"Ancient Gear Tank",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「アンティーク・ギア」と名のついたモンスターにのみ装備可能。\n装備モンスターの攻撃力は６００ポイントアップする。\nこのカードが破壊され墓地へ送られた時、相手ライフに６００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"古代の採掘機",h:"あんてぃーく・ぎあどりる",e:"Ancient Gear Drill",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドに「アンティーク・ギア」モンスターが存在する場合、手札を１枚捨てて発動できる。\nデッキから魔法カード１枚を選んで自分フィールドにセットする。\nこの効果でセットしたカードはこのターン発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"古代の機械掌",h:"あんてぃーく・ぎあはんど",e:"Ancient Gear Fist",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「アンティーク・ギア」と名のついたモンスターにのみ装備可能。\n装備モンスターと戦闘を行ったモンスターを、そのダメージステップ終了時に破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"古代の機械工場",h:"あんてぃーく・ぎあふぁくとりー",e:"Ancient Gear Factory",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"手札から「アンティーク・ギア」と名のついたモンスターカード１枚を選択する。\n選択したカードの倍のレベルになるように墓地の「アンティーク・ギア」と名のつくカードをゲームから除外する。\n選択したカードはこのターンに限り召喚時に生け贄は必要なくなる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"古代の機械要塞",h:"あんてぃーく・ぎあふぉーとれす",e:"Ancient Gear Fortress",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの「アンティーク・ギア」モンスターは召喚・特殊召喚されたターンには相手の効果の対象にならず、相手の効果では破壊されない。\n②：「アンティーク・ギア」カードの効果の発動に対して、相手は魔法・罠・モンスターの効果を発動できない。\n③：魔法＆罠ゾーンのこのカードが破壊された場合に発動できる。\n自分の手札・墓地から「アンティーク・ギア」モンスター１体を選んで特殊召喚する。\nこの効果の発動後、ターン終了時まで自分は「アンティーク・ギア」モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"古代の機械爆弾",h:"あんてぃーく・ぎあぼむ",e:"Ancient Gear Explosive",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「アンティーク・ギア」と名のついたモンスター１体を対象に発動する事ができる。\n対象のモンスターを破壊し、そのモンスターの元々の攻撃力の半分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アンティ勝負",h:"あんてぃしょうぶ",e:"Ante",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"それぞれ手札からカードを１枚選択し、お互いにレベルを確認する。\nレベルの高いモンスターを選択したプレイヤーのカードは手札に戻り、レベルの低いモンスターを選択したプレイヤーは１０００ポイントダメージを受け、そのカードを墓地へ送る。\nモンスター以外のカードを選択した場合はレベル０とする。\n同レベルの場合はお互いにカードを手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アンデットワールド",h:"あんでっとわーるど",e:"Zombie World",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：フィールドの表側表示モンスター及び墓地のモンスターは全てアンデット族になる。\n②：お互いはアンデット族モンスターしかアドバンス召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アンプリファイヤー",h:"あんぷりふぁいやー",e:"Symph Amplifire",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：このカードがフィールドゾーンに存在する限り、「音響戦士」カードの効果が発動する度にこのカードに音響カウンターを１つ置く。\n②：フィールドの「音響戦士」モンスターの攻撃力は、このカードの音響カウンターの数×１００アップする。\n③：１ターンに１度、自分フィールドの音響カウンターを５つまたは７つ取り除いて以下の効果を発動できる。\n\n●５つ：フィールドの「音響戦士」カードの数×３００ダメージを相手に与える。\n\n●７つ：フィールドの「音響戦士」カードの数まで、相手のフィールド・墓地のカードを選んで除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"イージーチューニング",h:"いーじーちゅーにんぐ",e:"Battle Tuned",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分の墓地からチューナー１体を除外し、自分フィールドの表側表示モンスター１体を対象として発動できる。\nその自分のモンスターの攻撃力は、このカードを発動するために除外したチューナーの攻撃力分アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"異界空間－Aゾーン",h:"いかいくうかん－えーぞーん",e:"Otherworld - The \"A\" Zone",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"相手モンスターが自分フィールド上に存在する「エーリアン」と名のついたモンスターと戦闘する場合、相手モンスターの攻撃力と守備力はダメージ計算時のみ３００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"怒れるもけもけ",h:"いかれるもけもけ",e:"Mokey Mokey Smackdown",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"「もけもけ」が自分フィールド上に表側表示で存在している時、自分フィールド上の天使族モンスターが破壊された場合、このターンのエンドフェイズまで自分フィールド上の「もけもけ」の攻撃力は３０００になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"イグナイト・ユナイト",h:"いぐないと・ゆないと",e:"Igknights Unite",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"「イグナイト・ユナイト」は１ターンに１枚しか発動できない。\n①：自分フィールドの「イグナイト」カード１枚を対象として発動できる。\nそのカードを破壊し、デッキから「イグナイト」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"イグニッションP",h:"いぐにっしょんふぇにっくす",e:"Ignition Phoenix",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：フィールドの「イグナイト」モンスターの攻撃力・守備力は３００アップする。\n②：１ターンに１度、自分フィールドの「イグナイト」カード１枚を対象として発動できる。\nそのカードを破壊し、デッキから「イグナイト」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"生け贄人形",h:"いけにえどーる",e:"Tribute Doll",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上モンスター１体を生け贄に捧げて発動する。\n手札から通常召喚可能なレベル７のモンスター１体を特殊召喚する。\nそのモンスターはこのターン攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"異次元海溝",h:"いじげんかいこう",e:"Different Dimension Deepsea Trench",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードの発動時に、自分の手札・フィールド上・墓地の水属性モンスター１体を選んでゲームから除外する。\nその後、フィールド上に表側表示で存在するこのカードが破壊された時、このカードの効果で除外したモンスターを自分フィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"異次元隔離マシーン",h:"いじげんかくりましーん",e:"Different Dimension Gate",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分と相手のフィールド上からモンスターを１体ずつ選択し、ゲームから除外する。\nこのカードが破壊され墓地へ送られた時、除外したモンスターを同じ表示形式で元のフィールド上に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"異次元からの埋葬",h:"いじげんからのまいそう",e:"Burial from a Different Dimension",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：除外されている自分及び相手のモンスターの中から合計３体まで対象として発動できる。\nそのモンスターを墓地に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"泉の精霊",h:"いずみのせいれい",e:"Fairy of the Spring",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地から装備魔法カード１枚を手札に加える。\nその装備魔法カードはこのターン発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"異層空間",h:"いそうくうかん",e:"Celestia",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：フィールドの幻竜族モンスターの攻撃力・守備力は３００アップする。\n②：１ターンに１度、自分の墓地の幻竜族モンスター３体を除外し、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"痛み分け",h:"いたみわけ",e:"Share the Pain",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に存在するモンスター１体をリリースして発動する。\n相手はモンスター１体をリリースしなければならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"一陣の風",h:"いちじんのかぜ",e:"Mystical Wind Typhoon",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"チェーン３以降に発動する事ができる。\nフィールド上の魔法または罠カード１枚を破壊する。\n同一チェーン上に複数回同名カードの効果が発動されている場合、このカードは発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"一角獣のホーン",h:"いっかくじゅうのほーん",e:"Horn of the Unicorn",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"①：装備モンスターの攻撃力・守備力は７００アップする。\n②：このカードがフィールドから墓地へ送られた場合に発動する。\nこのカードをデッキの一番上に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"一騎加勢",h:"いっきかせい",e:"Back-Up Rider",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで１５００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"一斉蜂起",h:"いっせいほうき",e:"Summoning Swarm",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できず、このカードを発動するターン、自分は昆虫族モンスターしかEXデッキから特殊召喚できない。\n①：相手フィールドのモンスターの数まで、自分の墓地のレベル４以下の「B・F」モンスターを対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"一点買い",h:"いってんがい",e:"Single Purchase",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード以外の手札が３枚以上存在し、その中にモンスターカードが存在しない場合に、手札を全て表側表示でゲームから除外して発動できる。\nデッキからモンスター１体を手札に加える。\nこのターン、自分は手札に加えた同名モンスター以外のモンスターを召喚・特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"偽りの種",h:"いつわりのたね",e:"Seed of Deception",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"手札からレベル２以下の植物族モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"稲妻の剣",h:"いなずまのけん",e:"Lightning Blade",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"戦士族モンスターにのみ装備可能。\n装備モンスターの攻撃力は８００ポイントアップし、フィールド上に表側表示で存在する全ての水属性モンスターの攻撃力は５００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"古のルール",h:"いにしえのるーる",e:"Ancient Rules",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"手札からレベル５以上の通常モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"イリュージョン・バルーン",h:"いりゅーじょん・ばるーん",e:"Illusion Balloons",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドのモンスターが破壊されたターンに発動できる。\n自分のデッキの上からカードを５枚めくる。\nその中から「EM」モンスター１体を選んで特殊召喚できる。\n残りのカードはデッキに戻してシャッフルする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"イリュージョン・マジック",h:"いりゅーじょん・まじっく",e:"Illusion Magic",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの魔法使い族モンスター１体をリリースして発動できる。\n自分のデッキ・墓地から「ブラック・マジシャン」を２体まで選んで手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"イリュージョンの儀式",h:"いりゅーじょんのぎしき",e:"Black Illusion Ritual",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「サクリファイス」の降臨に必要。\n①：レベルの合計が１以上になるように、自分の手札・フィールドのモンスターをリリースし、手札から「サクリファイス」１体を儀式召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"岩投げエリア",h:"いわなげえりあ",e:"Catapult Zone",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：このカードがフィールドゾーンに存在する限り、自分のモンスターが戦闘で破壊される場合、代わりに自分のデッキから岩石族モンスター１体を墓地へ送る事ができる。\nこの効果は１ターンに１度しか適用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"インスタント・ネオスペース",h:"いんすたんと・ねおすぺーす",e:"Instant Neo Space",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「E・HERO ネオス」を融合素材とする融合モンスターにのみ装備可能。\n装備モンスターはエンドフェイズ時にエクストラデッキに戻る効果を発動しなくてもよい。\n装備モンスターがフィールド上から離れた場合、自分の手札・デッキ・墓地から「E・HERO ネオス」１体を選んで特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"甲虫装機の魔弓 ゼクトアロー",h:"いんぜくたーのまきゅう　ぜくとあろー",e:"Inzektor Crossbow - Zektarrow",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「甲虫装機」と名のついたモンスターにのみ装備可能。\n装備モンスターの攻撃力は５００ポイントアップする。\n装備モンスターの効果の発動に対して相手は魔法・罠・効果モンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"甲虫装機の魔剣 ゼクトキャリバー",h:"いんぜくたーのまけん　ぜくときゃりばー",e:"Inzektor Sword - Zektkaliber",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「甲虫装機」と名のついたモンスターにのみ装備可能。\n装備モンスターの攻撃力・守備力は８００ポイントアップする。\nフィールド上に表側表示で存在するこのカードが墓地へ送られた時、自分の墓地の「甲虫装機」と名のついたモンスター１体を選択して手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"甲虫装機の魔斧 ゼクトホーク",h:"いんぜくたーのまふ　ぜくとほーく",e:"Inzektor Axe - Zektahawk",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「甲虫装機」と名のついたモンスターにのみ装備可能。\n装備モンスターの攻撃力は１０００ポイントアップする。\n装備モンスターの攻撃宣言時、相手は魔法・罠カードを発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"インフェルニティガン",h:"いんふぇるにてぃがん",e:"Infernity Launcher",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：１ターンに１度、自分メインフェイズに発動できる。\n手札から「インフェルニティ」モンスター１体を墓地へ送る。\n②：魔法＆罠ゾーンの表側表示のこのカードを墓地へ送り、自分の墓地の「インフェルニティ」モンスターを２体まで対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果は自分の手札が０枚の場合に発動と処理ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"垂直着陸",h:"う゛ぁーてぃかる・らんでぃんぐ",e:"Vertical Landing",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"トークン以外の自分フィールド上の風属性モンスターを任意の数だけリリースして発動できる。\nリリースしたモンスターの数だけ、自分フィールド上に「幻獣機トークン」（機械族・風・星３・攻／守０）を特殊召喚する。\n「垂直着陸」は１ターンに１枚しか発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴァイパー・リボーン",h:"う゛ぁいぱー・りぼーん",e:"Viper's Rebirth",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の墓地のモンスターが爬虫類族モンスターのみの場合、チューナー以外の自分の墓地のモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターはこのターンのエンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴァイロン・エレメント",h:"う゛ぁいろん・えれめんと",e:"Vylon Element",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「ヴァイロン」と名のついた装備カードが破壊された時、破壊された数までデッキから「ヴァイロン」と名のついたチューナーを特殊召喚できる。\nこの効果で特殊召喚したモンスターをシンクロ素材とする場合、「ヴァイロン」と名のついたモンスターのシンクロ召喚にしか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴァイロン・コンポーネント",h:"う゛ぁいろん・こんぽーねんと",e:"Vylon Component",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「ヴァイロン」と名のついたモンスターにのみ装備可能。\n装備モンスターが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。\nフィールド上に表側表示で存在するこのカードが墓地へ送られた場合、デッキから「ヴァイロン」と名のついた魔法カード１枚を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴァイロン・セグメント",h:"う゛ぁいろん・せぐめんと",e:"Vylon Segment",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「ヴァイロン」と名のついたモンスターにのみ装備可能。\n装備モンスターは相手の罠・効果モンスターの効果の対象にならない。\nフィールド上に表側表示で存在するこのカードが墓地へ送られた場合、デッキから「ヴァイロン」と名のついた魔法カード１枚を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴァイロン・フィラメント",h:"う゛ぁいろん・ふぃらめんと",e:"Vylon Filament",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「ヴァイロン」と名のついたモンスターにのみ装備可能。\n装備モンスターが攻撃する場合、相手はダメージステップ終了時まで魔法・罠カードを発動する事ができない。\nフィールド上に表側表示で存在するこのカードが墓地へ送られた場合、自分のデッキから「ヴァイロン」と名のついた魔法カード１枚を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴァイロン・ポリトープ",h:"う゛ぁいろん・ぽりとーぷ",e:"Vylon Polytope",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上の装備カード扱いの「ヴァイロン」と名のついたモンスターを任意の枚数選択して発動できる。\n選択したカードを表側守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターは、フィールド上から離れた場合ゲームから除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴァイロン・マター",h:"う゛ぁいろん・またー",e:"Vylon Matter",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地に存在する装備魔法カード３枚を選択して発動する。\n選択したカードをデッキに加えてシャッフルし、以下の効果から１つを選択して適用する。\n●自分のデッキからカードを１枚ドローする。\n●相手フィールド上に存在するカード１枚を破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴァイロン・マテリアル",h:"う゛ぁいろん・まてりある",e:"Vylon Material",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「ヴァイロン」と名のついたモンスターにのみ装備可能。\n装備モンスターの攻撃力は６００ポイントアップする。\nフィールド上に表側表示で存在するこのカードが墓地へ送られた場合、デッキから「ヴァイロン」と名のついた魔法カード１枚を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴァンパイア・デザイア",h:"う゛ぁんぱいあ・でざいあ",e:"Vampire's Desire",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：以下の効果から１つを選択して発動できる。\n\n●自分フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターのレベルと異なるレベルを持つ「ヴァンパイア」モンスター１体をデッキから墓地へ送る。\n対象のモンスターのレベルは、ターン終了時まで墓地へ送ったモンスターと同じになる。\n\n●自分の墓地の「ヴァンパイア」モンスター１体を対象として発動できる。\n自分フィールドのモンスター１体を選んで墓地へ送り、対象のモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴァンパイア帝国",h:"う゛ぁんぱいあえんぱいあ",e:"Vampire Kingdom",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"フィールド上のアンデット族モンスターの攻撃力はダメージ計算時のみ５００ポイントアップする。\nまた、１ターンに１度、相手のデッキからカードが墓地へ送られた時、自分の手札・デッキから「ヴァンパイア」と名のついた闇属性モンスター１体を墓地へ送り、フィールド上のカード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴァンパイアの領域",h:"う゛ぁんぱいあのりょういき",e:"Vampire's Domain",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：１ターンに１度、５００LPを払って発動できる。\nこのターン自分は通常召喚に加えて１度だけ、自分メインフェイズに「ヴァンパイア」モンスター１体を召喚できる。\n②：このカードが魔法＆罠ゾーンに存在する限り、自分の「ヴァンパイア」モンスターが相手に戦闘ダメージを与えた場合に発動する。\nその数値分だけ自分のLPを回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴィシャス・クロー",h:"う゛ぃしゃす・くろー",e:"Vicious Claw",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"装備モンスターの攻撃力は３００ポイントアップする。\n装備モンスターが戦闘によって破壊される場合は、代わりにこのカードを手札に戻す。\nさらに戦闘を行った相手モンスター以外のモンスター１体を破壊し、相手ライフに６００ポイントダメージを与える。\nその後、相手フィールド上に「イービル・トークン」（悪魔族・闇・星７・攻／守２５００）を１体特殊召喚する。\nこのカードが手札に戻ったターンは「ヴィシャス・クロー」を手札から使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻影解放",h:"う゛ぃじょんかいほう",e:"Vision Release",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の魔法＆罠ゾーンの「V・HERO」モンスターカード１枚を対象として発動できる。\nそのカードを特殊召喚する。\n②：墓地のこのカードを除外し、自分の墓地の「V・HERO」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻影融合",h:"う゛ぃじょんゆうごう",e:"Vision Fusion",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の手札・フィールドから、「HERO」融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。\n自分の魔法＆罠ゾーンに融合素材モンスターが永続罠カード扱いで存在する場合、そのモンスターカードを除外して融合素材とする事もできる（最大２枚まで）。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴェノム・ショット",h:"う゛ぇのむ・しょっと",e:"Venom Shot",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に「毒蛇王ヴェノミノン」、「毒蛇神ヴェノミナーガ」または「ヴェノム」と名のついたモンスターが表側表示で存在する時に発動する事ができる。\n自分のデッキから爬虫類族モンスター１体を墓地に送り、相手フィールド上に表側表示で存在するモンスター１体にヴェノムカウンターを２つ置く。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴェノム・スワンプ",h:"う゛ぇのむ・すわんぷ",e:"Venom Swamp",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"お互いのターンのエンドフェイズ毎に、フィールド上に表側表示で存在する「ヴェノム」と名のついたモンスター以外の表側表示で存在する全てのモンスターにヴェノムカウンターを１つ置く。\nヴェノムカウンター１つにつき、攻撃力は５００ポイントダウンする。\nこの効果で攻撃力が０になったモンスターは破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴェンデット・ナイト",h:"う゛ぇんでっと・ないと",e:"Vendread Nights",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分メインフェイズに手札を１枚捨てて発動できる。\nデッキから「ヴェンデット」モンスター１体を手札に加える。\n②：自分の「ヴェンデット」モンスターの攻撃で相手モンスターを破壊した時、自分の墓地から「ヴェンデット」モンスター１体を除外して発動できる。\nそのモンスターは相手モンスターに続けて攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴェンデット・ナイトメア",h:"う゛ぇんでっと・ないとめあ",e:"Vendread Nightmare",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：１ターンに１度、自分の手札・フィールドから「ヴェンデット」モンスターを任意の数だけリリースし、自分フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターのレベルはターン終了時までリリースしたモンスターの数だけ上がる。\n②：自分の「ヴェンデット」儀式モンスターの攻撃で相手モンスターを破壊した時に発動できる。\nその自分のモンスターの攻撃力は１０００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ウィッチクラフト・コラボレーション",h:"うぃっちくらふと・こらぼれーしょん",e:"Witchcrafter Collaboration",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドの「ウィッチクラフト」モンスター１体を対象として発動できる。\nこのターン、そのモンスターは１度のバトルフェイズ中に２回攻撃でき、そのモンスターが攻撃する場合、相手はダメージステップ終了時まで魔法・罠カードを発動できない。\n②：このカードが墓地に存在し、自分フィールドに「ウィッチクラフト」モンスターが存在する場合、自分エンドフェイズに発動できる。\nこのカードを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ウィッチクラフト・サボタージュ",h:"うぃっちくらふと・さぼたーじゅ",e:"Witchcrafter Holiday",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分の墓地の「ウィッチクラフト」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n②：このカードが墓地に存在し、自分フィールドに「ウィッチクラフト」モンスターが存在する場合、自分エンドフェイズに発動できる。\nこのカードを手札に加える。",li:2,lk:[],ta:[],al:[],ocg:true},
  {n:"ウィッチクラフト・デモンストレーション",h:"うぃっちくらふと・でもんすとれーしょん",e:"Witchcrafter Unveiling",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：手札から「ウィッチクラフト」モンスター１体を特殊召喚する。\nこの効果で特殊召喚したターン、自分の魔法使い族モンスターの効果の発動に対して相手は魔法・罠・モンスターの効果を発動できない。\n②：このカードが墓地に存在し、自分フィールドに「ウィッチクラフト」モンスターが存在する場合、自分エンドフェイズに発動できる。\nこのカードを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ウェポンチェンジ",h:"うぇぽんちぇんじ",e:"Weapon Change",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"１ターンに１度だけ自分のスタンバイフェイズに７００ライフポイントを払って発動する事ができる。\n自分フィールド上の戦士族か機械族モンスター１体の攻撃力と守備力を次の相手のエンドフェイズ終了時まで入れ替える。\nこのカードが破壊された時、その効果は無効になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ウォーターハザード",h:"うぉーたーはざーど",e:"Water Hazard",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分フィールド上にモンスターが存在しない場合、手札からレベル４以下の水属性モンスター１体を特殊召喚できる。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ウォーターワールド",h:"うぉーたーわーるど",e:"Umiiruka",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在する水属性モンスターの攻撃力は５００ポイントアップし、守備力は４００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"受け入れがたい結果",h:"うけいれがたいけっか",e:"Unacceptable Result",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドに魔法使い族モンスターが存在する場合に発動できる。\n手札から「占い魔女」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"受け継がれる力",h:"うけつがれるちから",e:"Gift of the Martyr",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上のモンスター１体を墓地に送る。\n自分フィールド上のモンスター１体を選択する。\n選択したモンスター１体の攻撃力は、発動ターンのエンドフェイズまで墓地に送ったモンスターカードの攻撃力分アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"烏合の行進",h:"うごうのこうしん",e:"The Big Cattle Drive",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に獣族・獣戦士族・鳥獣族のいずれかのモンスターが存在する場合、その種族１種類につき１枚デッキからカードをドローする。\nこのカードを発動するターン、自分は他の魔法・罠カードの効果を発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"烏合無象",h:"うごうむぞう",e:"Cattle Call",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドから元々の種族が獣族・獣戦士族・鳥獣族の表側表示モンスター１体を墓地へ送って発動できる。\n元々の種族が墓地へ送ったそのモンスターと同じモンスター１体をEXデッキから特殊召喚する。\nこの効果で特殊召喚したモンスターは攻撃できず、効果は無効化され、エンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"うごめく影",h:"うごめくかげ",e:"Shifting Shadows",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"３００ライフポイントを払う事で、自分フィールド上の裏側守備表示モンスターをシャッフルして、再び裏側守備表示で並べ替える。\nこの効果は１ターンに１度だけ使用する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"打ち出の小槌",h:"うちでのこづち",e:"Magical Mallet",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の手札を任意の数だけデッキに戻してシャッフルする。\nその後、自分はデッキに戻した数だけドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"移り気な仕立屋",h:"うつりぎなしたてや",e:"Tailor of the Fickle",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"モンスターに装備された装備カード１枚を、別の正しい対象となるモンスター１体に移し替える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"馬の骨の対価",h:"うまのほねのたいか",e:"White Elephant's Gift",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：効果モンスター以外の自分フィールドの表側表示モンスター１体を墓地へ送って発動できる。\n自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"海",h:"うみ",e:"Umi",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在する魚族・海竜族・雷族・水族モンスターの攻撃力・守備力は２００ポイントアップする。\nフィールド上に表側表示で存在する機械族・炎族モンスターの攻撃力・守備力は２００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"U.A.スタジアム",h:"うるとらあすりーとすたじあむ",e:"U.A. Stadium",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：自分フィールドに「U.A.」モンスターが召喚された場合に発動できる。\nデッキから「U.A.」モンスター１体を手札に加える。\n②：１ターンに１度、自分フィールドに「U.A.」モンスターが特殊召喚された場合に発動する。\n自分フィールドのモンスターの攻撃力は５００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"U.A.ターンオーバー・タクティクス",h:"うるとらあすりーとたーんおーばー・たくてぃくす",e:"U.A. Turnover Tactics",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"「U.A.ターンオーバー・タクティクス」は１ターンに１枚しか発動できない。\n①：自分フィールドに「U.A.」モンスターが２種類以上存在する場合に発動できる。\nフィールドのモンスターを全て持ち主のデッキに戻す。\nその後、自分はこの効果で自分のデッキに戻ったカードの数まで、デッキから「U.A.」モンスターを特殊召喚する（同名カードは１枚まで）。\nこの効果で自分が特殊召喚したモンスターはこのターン攻撃できない。\nその後、相手はこの効果で相手のデッキに戻ったカードの数まで、デッキからモンスターを特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"U.A.パワードギプス",h:"うるとらあすりーとぱわーどぎぷす",e:"U.A. Powered Jersey",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「U.A.」モンスターにのみ装備可能。\n①：装備モンスターの攻撃力・守備力は１０００アップし、装備モンスターが相手モンスターと戦闘を行う場合、相手に与える戦闘ダメージは倍になる。\n②：装備モンスターの攻撃でモンスターを破壊した場合に発動できる。\nこのバトルフェイズ中、装備モンスターはもう１度だけ攻撃できる。\n③：自分スタンバイフェイズに発動する。\n装備モンスターを除外する。\n④：装備モンスターが手札に戻る事でこのカードが墓地へ送られた場合に発動できる。\nこのカードを手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"U.A.フラッグシップ・ディール",h:"うるとらあすりーとふらっぐしっぷ・でぃーる",e:"U.A. Signing Deal",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：デッキから「U.A.」モンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターはS・X召喚の素材にできず、効果は無効化される。\nその後、自分はそのモンスターのレベル×３００LPを失う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エアークラック・ストーム",h:"えあーくらっく・すとーむ",e:"Air Cracking Storm",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"機械族モンスターにのみ装備可能。\n①：装備モンスターの攻撃で相手モンスターを破壊した時に発動できる。\nこのバトルフェイズ中、装備モンスターはもう１度だけ攻撃できる。\nこの効果を発動するターン、装備モンスター以外の自分のモンスターは攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"永遠の渇水",h:"えいえんのかっすい",e:"Eternal Drought",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"フィールド上の魚族モンスターは、すべて破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"栄光の聖騎士団",h:"えいこうのせいきしだん",e:"Glory of the Noble Knights",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの「聖騎士」モンスター１体を対象として発動できる。\nその自分のモンスターが装備可能な装備魔法カード１枚をデッキから選び、そのモンスターに装備する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"H－ヒートハート",h:"えいち－ひーとはーと",e:"H - Heated Heart",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで５００アップし、このターンそのモンスターが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ相手に戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エヴォリューション・バースト",h:"えう゛ぉりゅーしょん・ばーすと",e:"Evolution Burst",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に「サイバー・ドラゴン」が存在する場合に発動できる。\n相手フィールド上のカード１枚を選択して破壊する。\nこのカードを発動するターン、「サイバー・ドラゴン」は攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"疫病ウィルス ブラックダスト",h:"えきびょううぃるす　ぶらっくだすと",e:"Ekibyo Drakmord",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"このカードの装備モンスターは攻撃をする事ができない。\nその装備モンスターのコントローラーの２回目のエンドフェイズ時、その装備モンスターを破壊する。\nまた、この効果で装備モンスターを破壊した場合、このカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エクシーズ・オーバーライド",h:"えくしーず・おーばーらいど",e:"Xyz Override",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"フィールド上のエクシーズモンスターがそのエクシーズ素材を取り除いて効果を発動する場合、取り除くエクシーズ素材１つの代わりに手札１枚を裏側表示でゲームから除外できる。\nこの効果はお互いに１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エクシーズ・ギフト",h:"えくしーず・ぎふと",e:"Xyz Gift",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドにXモンスターが２体以上存在する場合に発動できる。\n自分フィールドのX素材を２つ取り除き、自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エクシーズ・ダブル・バック",h:"えくしーず・だぶる・ばっく",e:"Xyz Double Back",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上のエクシーズモンスターが破壊されたターン、自分フィールド上にモンスターが存在しない場合に発動できる。\n自分の墓地から、そのターンに破壊されたエクシーズモンスター１体と、そのモンスターの攻撃力以下のモンスター１体を選択して特殊召喚する。\nこの効果で特殊召喚したモンスターはエンドフェイズ時に破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エクシーズ・テリトリー",h:"えくしーず・てりとりー",e:"Xyz Territory",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"エクシーズモンスターがモンスターと戦闘を行うダメージ計算時のみ、そのエクシーズモンスターの攻撃力・守備力はそのランク×２００ポイントアップする。\nフィールド上のこのカードがカードの効果によって破壊される場合、代わりに自分フィールド上のエクシーズ素材を１つ取り除く事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エクシーズ・ユニット",h:"えくしーず・ゆにっと",e:"Xyz Unit",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"エクシーズモンスターにのみ装備可能。\n装備モンスターの攻撃力は、装備モンスターのランク×２００ポイントアップする。\nまた、自分フィールド上の装備モンスターがエクシーズ素材を取り除いて効果を発動する場合、このカードは取り除くエクシーズ素材の１つとして扱う事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エクシーズ・レセプション",h:"えくしーず・れせぷしょん",e:"Xyz Reception",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターと同じレベルのモンスター１体を手札から特殊召喚する。\nこの効果で特殊召喚したモンスターの攻撃力・守備力は０になり、効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エクスチェンジ",h:"えくすちぇんじ",e:"Exchange",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"お互いのプレイヤーは手札を公開し、それぞれ相手のカード１枚を選んで自分の手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エクストラゲート",h:"えくすとらげーと",e:"Extra Gate",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"１から１２までの任意のレベルを宣言して発動する。\n相手はエクストラデッキに存在する宣言されたレベルを持つモンスター１体をゲームから除外する。\n宣言したレベルを持つモンスターが相手のエクストラデッキに無かった場合、自分の手札を１枚選択して捨てる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エクゾディアとの契約",h:"えくぞでぃあとのけいやく",e:"Contract with Exodia",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の墓地に「封印されしエクゾディア」「封印されし者の右腕」「封印されし者の左腕」「封印されし者の右足」「封印されし者の左足」が存在する場合に発動できる。\n手札から「エクゾディア・ネクロス」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"霊魂の拠所",h:"えすぷりっと・ぱわーすぽっと",e:"Shinobird Power Spot",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"「霊魂の拠所」の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドのスピリットモンスターの攻撃力・守備力は５００アップする。\n②：自分フィールドの表側表示の風属性モンスターが自分の手札に戻った場合に発動できる。\nデッキからスピリットモンスター１体または儀式魔法カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エネミーコントローラー",h:"えねみーこんとろーらー",e:"Enemy Controller",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：以下の効果から１つを選択して発動できる。\n\n●相手フィールドの表側表示モンスター１体を対象として発動できる。\nその相手の表側表示モンスターの表示形式を変更する。\n\n●自分フィールドのモンスター１体をリリースし、相手フィールドの表側表示モンスター１体を対象として発動できる。\nその表側表示モンスターのコントロールをエンドフェイズまで得る。",li:2,lk:[],ta:[],al:[],ocg:true},
  {n:"選ばれし者",h:"えらばれしもの",e:"Chosen One",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の手札から、モンスターカード１枚とモンスター以外のカード２枚を選択し、相手はその中からランダムに１枚を選択する。\n相手が選択したカードがモンスターだった場合、そのモンスターを自分フィールド上に特殊召喚し、残りのカードは墓地へ送る。\n違った場合、選択した３枚を墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エルフの光",h:"えるふのひかり",e:"Elf's Light",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"光属性モンスターにのみ装備可能。\n装備モンスターの攻撃力は４００ポイントアップし、守備力は２００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エレキー",h:"えれきー",e:"Wattkey",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「エレキ」と名のついたモンスターは、このターン相手プレイヤーに直接攻撃する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エレキャッシュ",h:"えれきゃっしゅ",e:"Wattjustment",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"レベル３以下の雷族モンスターにのみ装備可能。\n装備モンスターの攻撃力は８００ポイントアップし、効果は無効化される。\n装備モンスターが相手ライフに戦闘ダメージを与えた時、自分のデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エレキャッスル",h:"えれきゃっする",e:"Wattcastle",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカードがフィールド上に存在する限り、「エレキ」と名のついたモンスターを攻撃したモンスターの攻撃力は、そのダメージ計算後に１０００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エレキュア",h:"えれきゅあ",e:"Wattcine",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分フィールド上に存在する雷族モンスターが相手ライフに戦闘ダメージを与えた時、与えた戦闘ダメージの数値分だけ自分のライフポイントを回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エレキューブ",h:"えれきゅーぶ",e:"Wattcube",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"雷族モンスターにのみ装備可能。\n装備モンスターの攻撃力は、自分の墓地に存在する雷族モンスターの数×１００ポイントアップする。\nまた、フィールド上に表側表示で存在するこのカードを墓地へ送る事で、自分フィールド上に表側表示で存在する雷族モンスター１体の攻撃力は１０００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エレメントの泉",h:"えれめんとのいずみ",e:"Spring of Rebirth",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"フィールド上に存在するモンスターが持ち主の手札に戻った時、自分は５００ライフポイント回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"炎王炎環",h:"えんおうえんかん",e:"Circle of the Fire Kings",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの炎属性モンスター１体と自分の墓地の炎属性モンスター１体を対象として発動できる。\n対象の自分フィールドのモンスターを破壊し、対象の墓地のモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"炎王の急襲",h:"えんおうのきゅうしゅう",e:"Onslaught of the Fire Kings",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"相手フィールド上にモンスターが存在し、自分フィールド上にモンスターが存在しない場合に発動できる。\nデッキから炎属性の獣族・獣戦士族・鳥獣族モンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化され、エンドフェイズ時に破壊される。\n「炎王の急襲」は１ターンに１枚しか発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"炎王の孤島",h:"えんおうのことう",e:"Fire King Island",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"「炎王の孤島」の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分メインフェイズにこの効果を発動できる。\n自分の手札・フィールドのモンスター１体を選んで破壊し、デッキから「炎王」モンスター１体を手札に加える。\n②：自分フィールドにモンスターが存在しない場合にこの効果を発動できる。\n手札の鳥獣族・炎属性モンスター１体を特殊召喚する。\n③：フィールドゾーンの表側表示のこのカードが、墓地へ送られた場合または除外された場合に発動する。\n自分フィールドのモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エンシェント・リーフ",h:"えんしぇんと・りーふ",e:"Ancient Leaf",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分のLPが９０００以上の場合、２０００LPを払って発動できる。\n自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"遠心分離フィールド",h:"えんしんぶんりふぃーるど",e:"Centrifugal Field",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"融合モンスターがカードの効果で破壊され墓地へ送られた時、その融合モンスターに記されている融合素材モンスター１体を自分の墓地から選択し、自分フィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"円卓の聖騎士",h:"えんたくのせいきし",e:"Noble Knights of the Round Table",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：自分エンドフェイズに、自分のフィールド・墓地の「聖騎士」カードの種類によって以下の効果をそれぞれ１度ずつ発動できる。\n\n●３種類以上：デッキから「聖騎士」カード１枚を墓地へ送る。\n\n●６種類以上：手札から「聖騎士」モンスター１体を特殊召喚する。\nその後、手札から「聖剣」装備魔法カード１枚をそのモンスターに装備できる。\n\n●９種類以上：自分の墓地の「聖騎士」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\n\n●１２種類：自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"EMキャスト・チェンジ",h:"えんためいときゃすと・ちぇんじ",e:"Performapal Recasting",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「EMキャスト・チェンジ」は１ターンに１枚しか発動できない。\n①：手札の「EM」モンスターを任意の数だけ相手に見せ、デッキに戻してシャッフルする。\nその後、自分はデッキに戻した数＋１枚をデッキからドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エンド・オブ・ザ・ワールド",h:"えんど・おぶ・ざ・わーるど",e:"End of the World",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「破滅の女神ルイン」「終焉の王デミス」の降臨に使用する事ができる。\nフィールドか手札から、儀式召喚するモンスターと同じレベルになるように生け贄を捧げなければならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エンドレス・オブ・ザ・ワールド",h:"えんどれす・おぶ・ざ・わーるど",e:"Cycle of the World",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「破滅の女神ルイン」「終焉の王デミス」の降臨に必要。\n①：レベルの合計が儀式召喚するモンスターのレベル以上になるように、自分フィールドのモンスターをリリースし、手札から「破滅の女神ルイン」または「終焉の王デミス」を儀式召喚する。\n②：墓地のこのカードをデッキに戻して発動できる。\nデッキから「エンド・オブ・ザ・ワールド」１枚を手札に加える。\nその後、自分の墓地から「破滅の女神ルイン」または「終焉の王デミス」１体を選んで手札に加える事ができる。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"炎熱伝導場",h:"えんねつでんどうば",e:"Molten Conduction Field",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：デッキから「ラヴァル」モンスター２体を墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"炎舞－「玉衝」",h:"えんぶ－「ぎょっこう」",e:"Fire Formation - Gyokkou",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードの発動時に、相手フィールド上にセットされた魔法・罠カード１枚を選択する。\nこのカードの発動に対して相手は選択されたカードを発動できない。\nこのカードがフィールド上に存在する限り、選択されたカードは発動できない。\nまた、このカードがフィールド上に存在する限り、自分フィールド上の獣戦士族モンスターの攻撃力は１００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"炎舞－「天璣」",h:"えんぶ－「てんき」",e:"Fire Formation - Tenki",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：このカードの発動時の効果処理として、デッキからレベル４以下の獣戦士族モンスター１体を手札に加える事ができる。\n②：このカードが魔法＆罠ゾーンに存在する限り、自分フィールドの獣戦士族モンスターの攻撃力は１００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"炎舞－「天枢」",h:"えんぶ－「てんすう」",e:"Fire Formation - Tensu",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在する限り、自分は通常召喚に加えて１度だけ、自分メインフェイズに獣戦士族モンスター１体を召喚できる。\n②：このカードが魔法＆罠ゾーンに存在する限り、自分フィールドの獣戦士族モンスターの攻撃力は１００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"炎舞－「洞明」",h:"えんぶ－「どうめい」",e:"Fire Formation - Domei",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できず、このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードの発動時の効果処理として、以下の効果を適用できる。\n●レベルの合計が儀式召喚するモンスターのレベル以上になるように、自分の手札・フィールドのモンスターをリリースし、手札から獣戦士族の儀式モンスター１体を儀式召喚する。\n②：魔法＆罠ゾーンの表側表示のこのカードが墓地へ送られた場合、自分の墓地の「炎星」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"炎舞－「揺光」",h:"えんぶ－「ようこう」",e:"Fire Formation - Yoko",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードの発動時に相手フィールド上に表側表示で存在するカード１枚を選択する事ができる。\nその場合、自分の手札から獣戦士族モンスター１体を捨て、選択したカードを破壊する。\nまた、このカードがフィールド上に存在する限り、自分フィールド上の獣戦士族モンスターの攻撃力は１００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"逢華妖麗譚－不知火語",h:"おうかようれいたん－しらぬいがたり",e:"Ghost Meets Girl - A Shiranui's Story",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：相手フィールドにモンスターが存在する場合、手札からアンデット族モンスター１体を捨てて発動できる。\n捨てたモンスターとカード名が異なる「不知火」モンスター１体を自分のデッキ・墓地から選んで特殊召喚する。\nこのカードの発動後、ターン終了時まで自分はアンデット族モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"王家の神殿",h:"おうけのしんでん",e:"Temple of the Kings",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分は罠カード１枚をセットしたターンに発動できる。\n②：自分フィールドの表側表示の「聖獣セルケト」１体とこのカードを墓地へ送って発動できる。\n手札・デッキのモンスター１体またはEXデッキの融合モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"王家の眠る谷－ネクロバレー",h:"おうけのねむるたに－ねくろばれー",e:"Necrovalley",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：フィールドの「墓守」モンスターの攻撃力・守備力は５００アップする。\n②：このカードがフィールドゾーンに存在する限り、お互いに墓地のカードを除外できず、墓地のカードへ及ぶ効果は無効化され、適用されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"黄金の歯車装置箱",h:"おうごんのはぐるまそうちばこ",e:"Golden Gearbox",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在する「カラクリ」と名のついたモンスター１体を選択して発動する。\nエンドフェイズ時まで、選択したモンスターの攻撃力は５００ポイントアップし、守備力は１５００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"O－オーバーソウル",h:"おー－おーばーそうる",e:"O - Oversoul",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の墓地の「E・HERO」通常モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"昇華する紋章",h:"おーぐめんと・めだりおん",e:"Augmented Heraldry",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカードがフィールド上に存在する限り、フィールド上のサイキック族エクシーズモンスターは魔法・罠カードの効果の対象にならない。\nまた、１ターンに１度、自分のメインフェイズ時に手札から「紋章獣」と名のついたモンスター１体を捨てて発動できる。\nデッキから「昇華する紋章」以外の「メダリオン」と名のついた魔法・罠カード１枚を手札に加える。\nこの効果を発動するターン、自分はサイキック族エクシーズモンスター及び「紋章獣」と名のついたモンスターしか召喚・特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"大波小波",h:"おおなみこなみ",e:"Big Wave Small Wave",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する水属性モンスターを全て破壊する。\nその後、破壊した数と同じ数まで手札から水属性モンスターを特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"オーバー・コアリミット",h:"おーばー・こありみっと",e:"Core Overclock",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードがフィールド上に存在する限り、自分フィールド上に表側表示で存在する「コアキメイル」と名のついた全てのモンスターの攻撃力は５００ポイントアップする。\nまた、１ターンに１度、自分のメインフェイズ時に手札から「コアキメイルの鋼核」１枚を捨てる事で、自分フィールド上に表側表示で存在する「コアキメイル」と名のついた全てのモンスターの攻撃力は、エンドフェイズ時まで１０００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"オーバー・ザ・レインボー",h:"おーばー・ざ・れいんぼー",e:"Rainbow Refraction",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの、元々のカード名が「究極宝玉神 レインボー・ドラゴン」または「究極宝玉神 レインボー・ダーク・ドラゴン」となるモンスターが効果を発動したターンに発動できる。\nデッキから「宝玉獣」モンスターを任意の数だけ特殊召喚する（同名カードは１枚まで）。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"オーバー・デステニー",h:"おーばー・ですてにー",e:"Over Destiny",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の墓地の「D-HERO」モンスター１体を対象として発動できる。\nそのモンスターのレベルの半分以下のレベルを持つ「D-HERO」モンスター１体をデッキから特殊召喚する。\nこの効果で特殊召喚したモンスターはこのターンのエンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"先史遺産－ピラミッド・アイ・タブレット",h:"おーぱーつ－ぴらみっど・あい・たぶれっと",e:"Chronomaly Pyramid Eye Tablet",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分フィールド上の「先史遺産」と名のついたモンスターの攻撃力は８００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"先史遺産都市バビロン",h:"おーぱーつしてぃばびろん",e:"Chronomaly City Babylon",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"１ターンに１度、自分の墓地の「先史遺産」と名のついたモンスター１体をゲームから除外し、除外したモンスターと同じレベルを持つ「先史遺産」と名のついたモンスター１体を自分の墓地から選択して発動できる。\n選択したモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"先史遺産技術",h:"おーぱーつてくのろじー",e:"Chronomaly Technology",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地の「先史遺産」と名のついたモンスター１体を選択して発動できる。\n選択したモンスターをゲームから除外する。\nその後、自分のデッキの上からカードを２枚確認し、その中から１枚を選んで手札に加え、残りのカードを墓地へ送る。\n「先史遺産技術」は１ターンに１枚しか発動できず、このカードを発動するターン自分は「先史遺産」と名のついたモンスター以外のモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"オーバーレイ・キャプチャー",h:"おーばーれい・きゃぷちゃー",e:"Overlay Capture",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"相手フィールド上のエクシーズ素材を持ったエクシーズモンスター１体と、自分フィールド上のエクシーズモンスター１体を選択して発動できる。\n選択した相手モンスターのエクシーズ素材を全て取り除き、このカードを選択した自分のモンスターの下に重ねてエクシーズ素材とする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"オーバーレイ・リジェネレート",h:"おーばーれい・りじぇねれーと",e:"Overlay Regen",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"フィールド上に存在するエクシーズモンスター１体を選択して発動できる。\nこのカードを選択したモンスターの下に重ねてエクシーズ素材とする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"オーバーロード・フュージョン",h:"おーばーろーど・ふゅーじょん",e:"Overload Fusion",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分のフィールド・墓地から、機械族・闇属性の融合モンスターカードによって決められた融合素材モンスターを除外し、その融合モンスター１体をEXデッキから融合召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"オーロラ・ドロー",h:"おーろら・どろー",e:"Aurora Draw",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に「機皇帝」と名のついたモンスターが存在し、このカード以外のカードが手札にない場合に発動できる。\n自分のデッキからカードを２枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"おくびょうかぜ",h:"おくびょうかぜ",e:"Timidity",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"次の相手ターンのエンドフェイズまで、フィールド上にセットされた魔法・罠カードを破壊する事はできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"おジャマ・カントリー",h:"おじゃま・かんとりー",e:"Ojama Country",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"１ターンに１度、手札から「おジャマ」と名のついたカード１枚を墓地へ送る事で、自分の墓地に存在する「おジャマ」と名のついたモンスター１体を特殊召喚する。\n自分フィールド上に「おジャマ」と名のついたモンスターが表側表示で存在する限り、フィールド上に表側表示で存在する全てのモンスターの元々の攻撃力・守備力を入れ替える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"おジャマ・デルタハリケーン！！",h:"おじゃま・でるたはりけーん！！",e:"Ojama Delta Hurricane!!",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に「おジャマ・グリーン」「おジャマ・イエロー」「おジャマ・ブラック」が表側表示で存在する場合に発動する事ができる。\n相手フィールド上に存在するカードを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"おジャマ改造",h:"おじゃまかいぞう",e:"Ojamassimilation",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：EXデッキの機械族・光属性の融合モンスター１体を相手に見せ、自分の手札・フィールド・墓地の「おジャマ」モンスターを任意の数だけ除外して発動できる。\n見せたモンスターにカード名が記されている融合素材モンスターを、除外したモンスターの数だけ自分の手札・デッキ・墓地から選んで特殊召喚する（同名カードは１枚まで）。\n②：墓地のこのカードを除外し、除外されている自分の「おジャマ」モンスター３体を対象として発動できる。\nそのモンスターをデッキに加えてシャッフルする。\nその後、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"おジャマッスル",h:"おじゃまっする",e:"Ojamuscle",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在する「おジャマ・キング」１体を選択する。\n選択した「おジャマ・キング」以外の「おジャマ」と名のついたモンスターを全て破壊する。\n破壊したモンスター１体につき、選択した「おジャマ・キング」１体の攻撃力は１０００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"おジャマッチング",h:"おじゃまっちんぐ",e:"Ojamatch",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：手札及び自分フィールドの表側表示のカードの中から、「おジャマ」カード１枚を墓地へ送って発動できる。\nそのカードとカード名が異なる「おジャマ」モンスター１体と「アームド・ドラゴン」モンスター１体を自分のデッキ・墓地から選んで手札に加える。\nその後、この効果で手札に加えたモンスター１体を召喚できる。\n②：自分メインフェイズに墓地のこのカードを除外し、除外されている自分の「おジャマ」モンスター３体を対象として発動できる。\nそのモンスターをデッキに加えてシャッフルする。\nその後、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"オスティナート",h:"おすてぃなーと",e:"Ostinato",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドにモンスターが存在しない場合に発動できる。\n自分の手札・デッキから、「幻奏」融合モンスターカードによって決められた融合素材モンスター２体を墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。\nこのターンのエンドフェイズに、この効果で融合召喚したモンスターを破壊し、その融合素材モンスター一組が自分の墓地に揃っていれば、その一組を特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"踊りによる誘発",h:"おどりによるゆうはつ",e:"Commencement Dance",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「ダンシング・ソルジャー」の降臨に必要。\nフィールドか手札から、レベルが６以上になるようカードを生け贄に捧げなければ、「ダンシング・ソルジャー」は降臨できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"Ωメガネ",h:"おめがめがね",e:"Omega Goggles",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"このカードは自分フィールド上のモンスターのみ装備可能。\n１ターンに１度、相手の手札をランダムに１枚選択して確認する。\nこの効果を発動するターン、装備モンスターは攻撃する事ができない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"思い出のブランコ",h:"おもいでのぶらんこ",e:"Swing of Memories",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の墓地の通常モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターはこのターンのエンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"オルフェゴール・アインザッツ",h:"おるふぇごーる・あいんざっつ",e:"Orcustrated Einsatz",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：相手がモンスターの召喚・特殊召喚に成功した場合に発動できる。\n手札・デッキから「オルフェゴール」モンスターまたは「星遺物」モンスター１体を選び、墓地へ送るか除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"オルフェゴール・プライム",h:"おるふぇごーる・ぷらいむ",e:"Orcustrated Return",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：手札及び自分フィールドの表側表示モンスターの中から、「オルフェゴール」モンスターまたは「星遺物」モンスター１体を墓地へ送って発動できる。\n自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"折れ竹光",h:"おれたけみつ",e:"Broken Bamboo Sword",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"装備モンスターの攻撃力は０ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"おろかな転生",h:"おろかなてんせい",e:"Foolish Return",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"相手の墓地に存在するカード１枚を選択して発動する。\n選択したカードをデッキに戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"終わりの始まり",h:"おわりのはじまり",e:"The Beginning of the End",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の墓地に闇属性モンスターが７体以上存在する場合、その内の５体を除外して発動できる。\n自分はデッキから３枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"隠密忍法帖",h:"おんみつにんぽうちょう",e:"Ninjitsu Art Notebook",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：１ターンに１度、手札から「忍者」モンスター１体を墓地へ送って発動できる。\nデッキから「隠密忍法帖」以外の「忍法」魔法・罠カード１枚を選んで自分フィールドにセットする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガーディアンの力",h:"がーでぃあんのちから",e:"Power of the Guardians",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"①：装備モンスターが戦闘を行う攻撃宣言時に発動する。\nこのカードに魔力カウンターを１つ置く。\n②：装備モンスターの攻撃力・守備力は、このカードの魔力カウンターの数×５００アップする。\n③：装備モンスターが戦闘・効果で破壊される場合、代わりに自分フィールドの魔力カウンターを１つ取り除く事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カード・アドバンス",h:"かーど・あどばんす",e:"Card Advance",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分のデッキの上からカードを５枚まで確認し、好きな順番でデッキの上に戻す。\nこのターン自分は通常召喚に加えて１度だけモンスター１体をアドバンス召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カード・フリッパー",h:"かーど・ふりっぱー",e:"Card Rotator",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"手札を１枚墓地へ送って発動する。\n相手フィールド上に存在する全てのモンスターの表示形式を変更する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カードトレーダー",h:"かーどとれーだー",e:"Card Trader",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分のスタンバイフェイズ時に手札を１枚デッキに戻す事で、デッキからカードを１枚ドローする。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガードペナルティ",h:"がーどぺなるてぃ",e:"Guard Penalty",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"フィールド上のモンスター１体を選択する。\nこのターン選択したモンスターが守備表示になった場合、自分のデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カーム・マジック",h:"かーむ・まじっく",e:"Calming Magic",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"メインフェイズ１の開始時に発動できる。\nお互いのプレイヤーは、このターンのメインフェイズ時及びバトルフェイズ時にモンスターを通常召喚・反転召喚・特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガイアパワー",h:"がいあぱわー",e:"Gaia Power",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在する地属性モンスターの攻撃力は５００ポイントアップし、守備力は４００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"開運ミラクルストーン",h:"かいうんみらくるすとーん",e:"Miracle Stone",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：「開運ミラクルストーン」は自分フィールドに１枚しか表側表示で存在できない。\n②：自分フィールドの魔法使い族モンスターの攻撃力・守備力は、自分フィールドの「占い魔女」モンスターの種類×５００アップする。\n③：１ターンに１度、自分の「占い魔女」モンスターが戦闘を行う攻撃宣言時に発動できる。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"海皇の咆哮",h:"かいおうのほうこう",e:"Call of the Atlanteans",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分の墓地のレベル３以下の海竜族モンスター３体を選択して発動できる。\n選択したモンスター３体を墓地から特殊召喚する。\nこのカードを発動するターン、自分はモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カウンタークリーナー",h:"かうんたーくりーなー",e:"Counter Cleaner",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"５００ライフポイントを払う。\nフィールド上に存在する全てのカウンターを取り除く。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カウンターマシンガンパンチ",h:"かうんたーましんがんぱんち",e:"Continuous Destruction Punch",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"攻撃された守備表示モンスターの守備力が相手攻撃モンスターの攻撃力を越えていた場合、その攻撃モンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"火炎地獄",h:"かえんじごく",e:"Tremendous Fire",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"相手ライフに１０００ポイントダメージを与え、自分は５００ポイントダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カオス－黒魔術の儀式",h:"かおす－くろまじゅつのぎしき",e:"Black Magic Ritual",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「マジシャン・オブ・ブラックカオス」の降臨に必要。\n自分の手札・フィールド上から、レベルの合計が８以上になるようにモンスターをリリースしなければならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カオス・エンド",h:"かおす・えんど",e:"Chaos End",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分のカードが７枚以上ゲームから除外されている場合に発動する事ができる。\nフィールド上に存在する全てのモンスターカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カオス・グリード",h:"かおす・ぐりーど",e:"Chaos Greed",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分のカードが４枚以上ゲームから除外されており、自分の墓地にカードが存在しない場合に発動する事ができる。\n自分のデッキからカードを２枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カオス・シールド",h:"かおす・しーるど",e:"Yellow Luster Shield",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分フィールド上に存在する全てのモンスターの守備力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"混沌空間",h:"かおす・ぞーん",e:"Chaos Zone",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：モンスターが表側表示で除外される度に、１体につき１つこのカードにカオスカウンターを置く。\n②：１ターンに１度、自分フィールドのカオスカウンターを４つ以上取り除き、取り除いた数と同じレベルを持つ、除外されている自分または相手のモンスター１体を対象として発動できる。\nそのモンスターを自分フィールドに特殊召喚する。\n③：フィールドのこのカードが相手の効果で墓地へ送られた時に発動できる。\nこのカードに置かれていたカオスカウンターの数以下のレベルを持つ、光・闇属性のモンスター１体をデッキから手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"混沌の場",h:"かおす・ふぃーるど",e:"Gateway to Chaos",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：このカードの発動時の効果処理として、デッキから「カオス・ソルジャー」儀式モンスターまたは「暗黒騎士ガイア」モンスター１体を手札に加える。\n②：このカードがフィールドゾーンに存在する限り、お互いの手札・フィールドからモンスターが墓地へ送られる度に、１体につき１つこのカードに魔力カウンターを置く（最大６つまで）。\n③：１ターンに１度、このカードの魔力カウンターを３つ取り除いて発動できる。\n自分はデッキから儀式魔法カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カオス・フォーム",h:"かおす・ふぉーむ",e:"Chaos Form",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「カオス」儀式モンスターの降臨に必要。\n①：レベルの合計が儀式召喚するモンスターと同じになるように、自分の手札・フィールドのモンスターをリリース、またはリリースの代わりに自分の墓地から「青眼の白龍」または「ブラック・マジシャン」を除外し、手札から「カオス」儀式モンスター１体を儀式召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カオス・ブルーム",h:"かおす・ぶるーむ",e:"Chaos Bloom",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地の「カオス・ブルーム」の数によって以下の効果を適用する。\n●０枚：フィールド上の攻撃力１０００以下のモンスター１体を選んで破壊する。\n●１枚：魔法＆罠ゾーンのカード１枚を選んで破壊する。\n●２枚以上：フィールド上のカード１枚を選んで破壊する。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"カオスの儀式",h:"かおすのぎしき",e:"Black Luster Ritual",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「カオス・ソルジャー」の降臨に必要。\n①：自分の手札・フィールドから、レベルの合計が８以上になるようにモンスターをリリースし、手札から「カオス・ソルジャー」を儀式召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガオドレイクのタテガミ",h:"がおどれいくのたてがみ",e:"Leodrake's Mane",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「ナチュル」と名のついたモンスター１体を選択して発動する。\n選択したモンスターの攻撃力はエンドフェイズ時まで３０００になり、効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガガガウィンド",h:"がががうぃんど",e:"Gagagawind",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"手札から「ガガガ」と名のついたモンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターのレベルは４になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガガガ学園の緊急連絡網",h:"ががががくえんのきんきゅうれんらくもう",e:"Gagaga Academy Emergency Network",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できず、このカードを発動するターン、自分はX召喚以外の特殊召喚ができない。\n①：相手フィールドにのみモンスターが存在する場合に発動できる。\nデッキから「ガガガ」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガガガタッグ",h:"がががたっぐ",e:"Gagagatag",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上の全ての「ガガガ」と名のついたモンスターの攻撃力は、次の自分のスタンバイフェイズ時まで、自分フィールド上の「ガガガ」と名のついたモンスターの数×５００ポイントアップする。\n「ガガガタッグ」は１ターンに１枚しか発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガガガドロー",h:"がががどろー",e:"Gagagadraw",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地の「ガガガ」と名のついたモンスター３体をゲームから除外して発動できる。\nデッキからカードを２枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガガガバック",h:"がががばっく",e:"Gagagaback",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上の「ガガガ」と名のついたモンスターが戦闘で破壊され墓地へ送られたターンに発動できる。\nこのターン戦闘で破壊されたモンスターを可能な限り自分の墓地から表側守備表示で特殊召喚する。\nその後、この効果で特殊召喚したモンスター１体につき、自分は６００ポイントのダメージを受ける。\n「ガガガバック」は１ターンに１枚しか発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガガガボルト",h:"がががぼると",e:"Gagagabolt",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に「ガガガ」と名のついたモンスターが存在する場合に発動できる。\nフィールド上のカード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"火器付機甲鎧",h:"かきつきいんせくとあーまー",e:"Insect Armor with Laser Cannon",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"昆虫族モンスターにのみ装備可能。\n①：装備モンスターの攻撃力は７００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"拡散する波動",h:"かくさんするはどう",e:"Diffusion Wave-Motion",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：相手フィールドにモンスターが存在する場合、１０００LPを払い、自分フィールドのレベル７以上の魔法使い族モンスター１体を対象として発動できる。\nこのターン、そのモンスター以外のモンスターは攻撃できず、対象のモンスターは可能な限り相手モンスター全てに１回ずつ攻撃しなければならない。\nその攻撃で破壊されたモンスターの効果は発動できず、無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"覚醒",h:"かくせい",e:"Invigoration",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"地属性モンスター１体の攻撃力４００ポイントアップ！守備力２００ポイントダウン！",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"覚醒の証",h:"かくせいのあかし",e:"Emblem of the Awakening",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「覚醒戦士 クーフーリン」の降臨に必要。\n手札・自分フィールド上から、レベルの合計が４以上になるようにモンスターをリリースしなければならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"革命",h:"かくめい",e:"Restructer Revolution",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"相手の手札の枚数×２００ポイントダメージを相手ライフに与える。",li:1,lk:[],ta:[],al:[],ocg:true},
  {n:"隠れ里－忍法修練の地",h:"かくれざと－にんぽうしゅうれんのち",e:"Hidden Village of Ninjitsu Arts",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドに「忍者」モンスターが召喚・反転召喚・特殊召喚された場合、自分の墓地の、「忍者」モンスター１体または「忍法」カード１枚を対象として発動できる。\nそのカードを手札に加える。\nこのターン、自分はこの効果で手札に加えたカード及びその同名カードの効果の発動ができない。\n②：自分フィールドの、「忍者」モンスターまたは「忍法」カードが戦闘または相手の効果で破壊される場合、代わりに自分の墓地の「忍者」モンスター１体を除外できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"下降潮流",h:"かこうちょうりゅう",e:"Falling Current",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在するモンスター１体を選択し、１から３までの任意のレベルを宣言して発動できる。\n選択したモンスターのレベルは宣言したレベルとなる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガスタの交信",h:"がすたのこうしん",e:"Contact with Gusto",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地の「ガスタ」と名のついたモンスター２体と相手フィールド上のカード１枚を選択して発動できる。\n選択した墓地のモンスター２体をデッキに加えてシャッフルする。\nその後、選択した相手のカード１枚を破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"化石調査",h:"かせきちょうさ",e:"Fossil Dig",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：デッキからレベル６以下の恐竜族モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カップ・オブ・エース",h:"かっぷ・おぶ・えーす",e:"Cup of Ace",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"コイントスを１回行う。\n表が出た場合、自分はデッキからカードを２枚ドローする。\n裏が出た場合、相手はデッキからカードを２枚ドローする。",li:2,lk:[],ta:[],al:[],ocg:true},
  {n:"ガトムズの非常召集",h:"がとむずのひじょうしょうしゅう",e:"Gottoms' Second Call",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「ガトムズの非常召集」は１ターンに１枚しか発動できず、このカードを発動するターン、自分はバトルフェイズを行えない。\n①：自分フィールドに「X－セイバー」Sモンスターが存在する場合に自分の墓地の「X－セイバー」モンスター２体を対象として発動できる。\nそのモンスター２体を召喚条件を無視して特殊召喚する。\nこの効果で特殊召喚したモンスターは攻撃力が０になり、このターンのエンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"神々の黄昏",h:"かみがみのたそがれ",e:"Gotterdammerung",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「極神」と名のついたモンスター１体を選択して発動する。\n選択したモンスターのコントロールを相手に移す。\n次の相手のエンドフェイズ時、選択したモンスターを破壊し、相手フィールド上に存在するカードを全てゲームから除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"神の息吹",h:"かみのいぶき",e:"Breath of Light",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在する岩石族モンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"神の居城－ヴァルハラ",h:"かみのきょじょう－う゛ぁるはら",e:"Valhalla, Hall of the Fallen",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：１ターンに１度、自分メインフェイズに発動できる。\n手札から天使族モンスター１体を特殊召喚する。\nこの効果は自分フィールドにモンスターが存在しない場合に発動と処理ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"亀の誓い",h:"かめのちかい",e:"Turtle Oath",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「クラブ・タートル」の降臨に必要。\nフィールドか手札から、レベルが８以上になるようカードを生け贄に捧げなければ、「クラブ・タートル」は降臨できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"仮面魔獣の儀式",h:"かめんまじゅうのぎしき",e:"Curse of the Masked Beast",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「仮面魔獣マスクド・ヘルレイザー」の降臨に必要。\n①：自分の手札・フィールドから、レベルの合計が８以上になるようにモンスターをリリースし、手札から「仮面魔獣マスクド・ヘルレイザー」を儀式召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カラクリ解体新書",h:"からくりかいたいしんしょ",e:"Karakuri Anatomy",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"「カラクリ」と名のついたモンスターの表示形式が変更される度に、このカードにカラクリカウンターを１つ置く（最大２つまで）。\nまた、フィールド上に存在するこのカードを墓地へ送る事で、このカードに乗っているカラクリカウンターの数だけ自分のデッキからカードをドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カラクリ蝦蟇油",h:"からくりがまあぶら",e:"Karakuri Gama Oil",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地の「カラクリ」モンスター１体を対象としてこのカードを発動できる。\nそのモンスターを特殊召喚し、このカードを装備する。\n②：１ターンに１度、自分フィールドの表側表示の「カラクリ」モンスターの表示形式が変更された場合に発動する。\n装備モンスターの攻撃力・守備力は５００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カラクリ粉",h:"からくりこ",e:"Karakuri Gold Dust",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"フィールド上に表側攻撃表示で存在する「カラクリ」と名のついたモンスター２体を選択して発動する。\n選択したモンスター１体を守備表示にし、もう１体のモンスターの攻撃力はエンドフェイズ時まで、守備表示にしたモンスターの攻撃力分だけアップする。\nこの効果はバトルフェイズ時にのみ発動する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カラクリ大暴走",h:"からくりだいぼうそう",e:"Runaway Karakuri",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在する「カラクリ」と名のついたモンスター１体を選択して発動する。\nエンドフェイズ時まで、選択したモンスターの攻撃力は１０００ポイントアップし、効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガラスの靴",h:"がらすのくつ",e:"Glass Slippers",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：装備モンスターの種族が天使族の場合、装備モンスターの攻撃力は１０００アップする。\n天使族以外の場合、装備モンスターは攻撃できず、攻撃力は１０００ダウンする。\n②：装備モンスターが破壊された事でこのカードが墓地へ送られた場合、自分フィールドの「シンデレラ」１体を対象として発動できる。\nその自分の「シンデレラ」にこのカードを装備する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"借カラクリ蔵",h:"かりからくりくら",e:"Karakuri Cash Cache",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「カラクリ」と名のついたモンスター１体を選択して発動する。\n自分のデッキからレベル４以下の「カラクリ」と名のついたモンスター１体を手札に加え、選択したモンスターの表示形式を変更する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"火竜の火炎弾",h:"かりゅうのかえんだん",e:"Dragon's Gunfire",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上にドラゴン族モンスターが表側表示で存在する場合、次の効果から１つを選択して発動する。\n\n●相手ライフに８００ポイントダメージを与える。\n\n●フィールド上に表側表示で存在する守備力８００以下のモンスター１体を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"牙竜咆哮",h:"がりょうほうこう",e:"Dragoroar",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分の墓地の地・水・炎・風属性モンスターをそれぞれ１体ずつ除外して発動できる。\nフィールドのカード１枚を選んで持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガルドスの羽根ペン",h:"がるどすのはねぺん",e:"Quill Pen of Gulldos",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地に存在する風属性モンスター２体を選択してデッキに戻し、フィールド上に存在するカード１枚を選択して持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガルマソードの誓い",h:"がるまそーどのちかい",e:"Garma Sword Oath",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「ガルマソード」の降臨に必要。\n①：自分の手札・フィールドから、レベルの合計が７以上になるようにモンスターをリリースし、手札から「ガルマソード」１体を儀式召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"喚忌の呪眼",h:"かんきのじゅがん",e:"Evil Eye Awakening",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の手札・墓地から「呪眼」モンスター１体を選んで特殊召喚する。\n自分の魔法＆罠ゾーンに「セレンの呪眼」が存在する場合、代わりにデッキから「呪眼」モンスター１体を特殊召喚する事もできる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"歯車街",h:"ぎあ・たうん",e:"Geartown",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：このカードがフィールドゾーンに存在する限り、お互いのプレイヤーは「アンティーク・ギア」モンスターを召喚する場合に必要なリリースを１体少なくできる。\n②：このカードが破壊され墓地へ送られた時に発動できる。\n自分の手札・デッキ・墓地から「アンティーク・ギア」モンスター１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ギアギアチェンジ",h:"ぎあぎあちぇんじ",e:"Geargia Change",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"「ギアギアチェンジ」は１ターンに１枚しか発動できない。\n①：自分の墓地の「ギアギアーノ」モンスター２体以上を対象として発動できる（同名カードは１枚まで）。\nそのモンスターを特殊召喚し、そのモンスターのみを素材としてXモンスター１体をX召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"記憶抹消",h:"きおくまっしょう",e:"Mind Wipe",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"相手の手札が３枚以下の場合に発動する事ができる。\n相手は手札をデッキに加えてシャッフルする。\nその後、相手はデッキに加えた枚数分のカードをドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"機械改造工場",h:"きかいかいぞうこうじょう",e:"Machine Conversion Factory",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"機械族のみ装備可能。\n装備モンスター１体の攻撃力と守備力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"機械天使の儀式",h:"きかいてんしのぎしき",e:"Machine Angel Ritual",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「サイバー・エンジェル」儀式モンスターの降臨に必要。\n①：レベルの合計が儀式召喚するモンスターのレベル以上になるように、自分の手札・フィールドのモンスターをリリースし、手札から「サイバー・エンジェル」儀式モンスター１体を儀式召喚する。\n②：自分フィールドの光属性モンスターが戦闘・効果で破壊される場合、代わりに墓地のこのカードを除外できる。",li:2,lk:[],ta:[],al:[],ocg:true},
  {n:"機械天使の絶対儀式",h:"きかいてんしのぜったいぎしき",e:"Machine Angel Absolute Ritual",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「サイバー・エンジェル」儀式モンスターの降臨に必要。\n①：レベルの合計が儀式召喚するモンスターと同じになるように、自分の手札・フィールドのモンスターをリリース、またはリリースの代わりに自分の墓地から天使族または戦士族のモンスターをデッキに戻し、手札から「サイバー・エンジェル」儀式モンスター１体を儀式召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"機械複製術",h:"きかいふくせいじゅつ",e:"Machine Duplication",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの攻撃力５００以下の機械族モンスター１体を対象として発動できる。\nデッキからその表側表示モンスターの同名モンスターを２体まで特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"機皇城",h:"きこうじょう",e:"Meklord Fortress",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの「機皇帝」モンスターはSモンスターの効果の対象にならない。\n②：フィールドのこのカードが破壊され墓地へ送られた時に発動できる。\nデッキから「機皇帝」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"機皇創出",h:"きこうそうしゅつ",e:"Meklord Assembly",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できず、このカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードの発動時の効果処理として、デッキから「機皇」モンスター１体を手札に加える事ができる。\n②：手札を１枚捨て、自分フィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊する。\n③：自分フィールドの表側表示の「機皇」モンスターが戦闘・効果で破壊された場合、このカード以外のフィールドの表側表示の魔法・罠カード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"機皇帝の賜与",h:"きこうていのしよ",e:"Boon of the Meklord Emperor",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在するモンスターが「機皇」と名のついたモンスター２体のみの場合に発動する事ができる。\n自分のデッキからカードを２枚ドローする。\nこのカードを発動するターン、自分はバトルフェイズを行う事ができない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"機皇統制",h:"きこうとうせい",e:"Meklord Deflection",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの「機皇」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力は、自分フィールドの「機皇」モンスターの元々の攻撃力を合計した数値になり、ターン終了時までそのモンスターの戦闘で発生する相手への戦闘ダメージは０になる。\n②：自分フィールドの「機皇」モンスターが戦闘・効果で破壊される場合、代わりに墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"機甲忍法ゴールド・コンバージョン",h:"きこうにんぽうごーるど・こんばーじょん",e:"Armor Ninjitsu Art of Alchemy",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に「忍法」と名のついたカードが存在する場合に発動できる。\n自分フィールド上の「忍法」と名のついたカードを全て破壊する。\nその後、デッキからカードを２枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"旗鼓堂々",h:"きこどうどう",e:"Swords at Dawn",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分の墓地の装備魔法カード１枚と、その正しい対象となるフィールド上のモンスター１体を選択して発動できる。\n選択した装備魔法カードを選択したモンスターに装備する。\nこの効果で装備した装備魔法カードはエンドフェイズ時に破壊される。\nこのカードを発動したターン、自分はモンスターを特殊召喚できない。\n「旗鼓堂々」は１ターンに１枚しか発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"儀式の檻",h:"ぎしきのおり",e:"Ritual Cage",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在する限り、自分の儀式モンスターの戦闘で発生する自分への戦闘ダメージは０になる。\n②：このカードが魔法＆罠ゾーンに存在する限り、自分フィールドの儀式モンスターはモンスターの効果の対象にならず、モンスターの効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"擬似空間",h:"ぎじくうかん",e:"Pseudo Space",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：１ターンに１度、自分の墓地からフィールド魔法カード１枚を除外して発動できる。\nエンドフェイズまで、このカードは除外したカードと同名カードとして扱い、同じ効果を得る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"騎士道精神",h:"きしどうせいしん",e:"Kishido Spirit",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分フィールド上モンスターは、攻撃力の同じモンスターとの戦闘では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"騎士の称号",h:"きしのしょうごう",e:"Knight's Title",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの表側表示の「ブラック・マジシャン」１体をリリースして発動できる。\n自分の手札・デッキ・墓地から「ブラック・マジシャンズ・ナイト」１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"鬼神の連撃",h:"きしんのれんげき",e:"Oni-Gami Combo",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在するエクシーズモンスター１体を選択し、そのエクシーズ素材を全て取り除いて発動する。\nこのターン、選択したモンスターは１度のバトルフェイズ中に２回攻撃する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"儀水鏡との交信",h:"ぎすいきょうとのこうしん",e:"Contact with the Aquamirror",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に水属性モンスターが表側表示で存在する場合、以下の効果から１つを選択して発動する。\n自分フィールド上に水属性の儀式モンスターが表側表示で存在する場合、両方を選択して発動する事ができる。\n\n●相手の魔法＆罠カードゾーンにセットされているカードを全て確認して元に戻す。\n\n●自分または相手のデッキの上からカードを２枚確認し、好きな順番でデッキの上に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"奇跡の方舟",h:"きせきのはこぶね",e:"Shinato's Ark",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「天界王 シナト」の降臨に必要。\nフィールドか手札から、レベルが８以上になるようカードを生け贄に捧げなければならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"奇跡の発掘",h:"きせきのはっくつ",e:"Miracle Dig",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：除外されている自分のモンスターが５体以上存在する場合、その内３体を対象として発動できる。\nそのモンスターを墓地に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"奇跡のピラミッド",h:"きせきのぴらみっど",e:"Pyramid of Wonders",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在するアンデット族モンスターの攻撃力は、相手フィールド上に存在するモンスターの数×２００ポイントアップする。\n自分フィールド上に表側表示で存在するアンデット族モンスター１体が破壊される場合、代わりにこのカードを墓地へ送る事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"機動要塞フォルテシモ",h:"きどうようさいふぉるてしも",e:"Fortissimo the Mobile Fortress",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"１ターンに１度、自分の手札から「機皇兵」と名のついたモンスター１体を自分フィールド上に特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"希望郷－オノマトピア－",h:"きぼうきょう－おのまとぴあ－",e:"Onomatopia",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：このカードがフィールドゾーンに存在する限り、自分フィールドに「希望皇ホープ」モンスターが特殊召喚される度に、このカードにかっとビングカウンターを１つ置く。\n②：自分フィールドのモンスターの攻撃力・守備力は、このカードのかっとビングカウンターの数×２００アップする。\n③：１ターンに１度、このカードのかっとビングカウンターを２つ取り除いて発動できる。\nデッキから「ズババ」、「ガガガ」、「ゴゴゴ」、「ドドド」モンスターの内いずれか１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ギャラクシー・ウェーブ",h:"ぎゃらくしー・うぇーぶ",e:"Galaxy Wave",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分がエクシーズ召喚に成功する度に、相手ライフに５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ギャラクシー・クィーンズ・ライト",h:"ぎゃらくしー・くぃーんず・らいと",e:"Galaxy Queen's Light",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドのレベル７以上のモンスター１体を対象として発動できる。\n自分フィールドの全てのモンスターのレベルはターン終了時まで対象のモンスターと同じレベルになる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ギャラクシー・サイクロン",h:"ぎゃらくしー・さいくろん",e:"Galaxy Cyclone",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：フィールドにセットされた魔法・罠カード１枚を対象として発動できる。\nそのカードを破壊する。\n②：墓地のこのカードを除外し、フィールドの表側表示の魔法・罠カード１枚を対象として発動できる。\nそのカードを破壊する。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ギャラクシー・ストーム",h:"ぎゃらくしー・すとーむ",e:"Galaxy Storm",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"フィールド上に存在する、エクシーズ素材が無いエクシーズモンスター１体を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"救援光",h:"きゅうえんこう",e:"Light of Redemption",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：８００LPを払い、除外されている自分の光属性モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"究極進化薬",h:"きゅうきょくしんかやく",e:"Double Evolution Pill",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の手札・墓地から、恐竜族モンスターと恐竜族以外のモンスターを１体ずつ除外して発動できる。\n手札・デッキからレベル７以上の恐竜族モンスター１体を召喚条件を無視して特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"旧神の印",h:"きゅうしんのいん",e:"Seal of the Ancients",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：１０００LP払って発動できる。\n相手フィールドにセットされたカードを全て確認する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"救世の儀式",h:"きゅうせいのぎしき",e:"Ritual of Grace",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「救世の美神ノースウェムコ」の降臨に必要。\n自分の手札・フィールド上から、レベルの合計が７以上になるようにモンスターをリリースしなければならない。\nまた、自分のメインフェイズ時に墓地のこのカードをゲームから除外し、自分フィールド上の儀式モンスター１体を選択して発動できる。\nこのターン選択した自分の儀式モンスターはカードの効果の対象にならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"休息する剣闘獣",h:"きゅうそくするぐらでぃあるびーすと",e:"Gladiator Beast's Respite",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の手札から「剣闘獣」と名のついたカード２枚をデッキに戻す。\nその後、自分のデッキからカードを３枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"急転直下",h:"きゅうてんちょっか",e:"Out of the Blue",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"相手が墓地に存在するカードを対象とする魔法・罠・効果モンスターの効果を発動した場合このカードを破壊する。\nこのカードの効果によってこのカードが破壊され墓地へ送られた時、相手は墓地に存在するカードを全てデッキに加えてシャッフルする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"救魔の標",h:"きゅうまのしるべ",e:"Dwimmered Path",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地の魔法使い族の効果モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"狂植物の氾濫",h:"きょうしょくぶつのはんらん",e:"Raging Mad Plants",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上の全ての植物族モンスターの攻撃力は、エンドフェイズ時まで自分の墓地の植物族モンスターの数×３００ポイントアップする。\nこのターンのエンドフェイズ時、自分フィールド上の植物族モンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"共振装置",h:"きょうしんそうち",e:"Resonance Device",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する同じ種族・属性のモンスター２体を選択して発動する。\n選択したモンスター１体のレベルはエンドフェイズ時まで、もう１体のモンスターのレベルと同じになる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"強制進化",h:"きょうせいしんか",e:"Evo-Force",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上の「エヴォルド」と名のついたモンスター１体をリリースして発動する。\nデッキから「エヴォルダー」と名のついたモンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターは、「エヴォルド」と名のついたモンスターの効果で特殊召喚した扱いとなる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"驚天動地",h:"きょうてんどうち",e:"Earthshattering Event",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：自分または相手のデッキからカードが墓地へ送られた場合、自分または相手の墓地のカード１枚を対象としてこの効果を発動する。\nそのカードを持ち主のデッキに戻す。\nこの効果の発動後、ターン終了時までお互いにデッキからカードを墓地へ送る事はできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"凶暴化の仮面",h:"きょうぼうかのかめん",e:"Mask of Brutality",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"装備モンスターの攻撃力は１０００ポイントアップし、守備力は１０００ポイントダウンする。\nこのカードのコントローラーは自分のスタンバイフェイズ毎に１０００ライフポイントを払う。\nまたは、１０００ライフポイント払わずにこのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"共鳴する振動",h:"きょうめいするしんどう",e:"Harmonic Oscillation",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：相手のPゾーンにカードが２枚存在する場合、そのカード２枚を対象として発動できる。\nそのカード２枚が相手のPゾーンに存在し、このターン自分がP召喚する場合、相手のPスケール一組でP召喚できる。\nその場合、エクストラデッキからしかモンスターをP召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"鏡鳴する武神",h:"きょうめいするぶじん",e:"Bujin Regalia - The Mirror",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に「武神」と名のついた獣戦士族モンスターが存在し、相手フィールド上のモンスターの数が自分フィールド上のモンスターの数より多い場合、メインフェイズ１の開始時に発動できる。\n次の相手ターン終了時まで、お互いに魔法・罠カードの効果を発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"共鳴破",h:"きょうめいは",e:"Resonant Destruction",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードがフィールド上に存在する限り、「リゾネーター」と名のついたモンスターがシンクロ素材として墓地へ送られる度に、相手フィールド上に存在するカード１枚を選択して破壊する。\nこのカードは発動後２回目の自分のエンドフェイズ時に破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"虚栄巨影",h:"きょえいきょえい",e:"Ego Boost",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：モンスターの攻撃宣言時、フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力は、そのバトルフェイズ終了時まで１０００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"局所的ハリケーン",h:"きょくしょてきはりけーん",e:"Hey, Trunade!",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：フィールドにセットされている魔法・罠カードを全て持ち主の手札に戻す。",li:0,lk:[],ta:[],al:[],ocg:true},
  {n:"極星宝ドラウプニル",h:"きょくせいほうどらうぷにる",e:"Nordic Relic Draupnir",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「極神」または「極星」と名のついたモンスターにのみ装備可能。\n装備モンスターの攻撃力は８００ポイントアップする。\nフィールド上に表側表示で存在するこのカードがカードの効果によって破壊された場合、自分のデッキから「極星宝」と名のついたカード１枚を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"巨神竜の遺跡",h:"きょしんりゅうのいせき",e:"Ruins of the Divine Dragon Lords",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：自分フィールドにレベル７・８のドラゴン族モンスターが存在し、墓地以外からモンスターが特殊召喚された場合に発動する。\nそのモンスターの効果はターン終了時まで無効化される。\n②：１ターンに１度、このカード以外の自分フィールドの表側表示のカード１枚を墓地へ送って発動できる。\n自分フィールドに「巨竜トークン」（ドラゴン族・光・星１・攻／守０）１体を特殊召喚する。\n③：このカードが墓地に存在する場合、自分の手札・フィールドのレベル７・８のドラゴン族モンスター１体を墓地へ送って発動できる。\nこのカードを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"虚無の波動",h:"きょむのはどう",e:"Wave-Motion Inferno",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分の手札が０枚の場合、自分フィールド上に表側表示で存在する「インフェルニティ」と名のついた全てのモンスターの攻撃力・守備力は４００ポイントアップする。\nまた、フィールド上に表側表示で存在するこのカードを墓地へ送る事で、自分の手札を全て墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"巨竜の羽ばたき",h:"きょりゅうのはばたき",e:"A Wingbeat of Giant Dragon",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドのレベル５以上のドラゴン族モンスター１体を選んで持ち主の手札に戻し、フィールドの魔法・罠カードを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"機雷化",h:"きらいか",e:"Detonate",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上の「クリボー」及び「クリボートークン」を全て破壊する。\nその後、破壊した数と同じ数まで相手フィールド上のカードを選んで破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"緊急鋼核処分",h:"きんきゅうこうかくしょぶん",e:"Iron Core Immediate Disposal",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分のデッキから「コアキメイルの鋼核」１枚を選択して墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"緊急合成",h:"きんきゅうごうせい",e:"Urgent Synthesis",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地に存在する「コアキメイルの鋼核」１枚をデッキに戻して発動する。\n自分の手札・墓地からレベル４以下の「コアキメイル」と名のついたモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"禁じられた聖衣",h:"きんじられたせいい",e:"Forbidden Dress",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：フィールドの表側表示モンスター１体を対象として発動できる。\nターン終了時までそのモンスターは、攻撃力が６００ダウンし、効果の対象にならず、効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"禁じられた聖槍",h:"きんじられたせいそう",e:"Forbidden Lance",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターはターン終了時まで、攻撃力が８００ダウンし、このカード以外の魔法・罠カードの効果を受けない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"禁じられた聖典",h:"きんじられたせいてん",e:"Forbidden Scripture",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：お互いのモンスターが戦闘を行うダメージ計算時に発動できる。\nダメージステップ終了時まで、このカード以外のフィールドのカードの効果は無効化され、その戦闘のダメージ計算は元々の攻撃力・守備力で行う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"禁じられた聖杯",h:"きんじられたせいはい",e:"Forbidden Chalice",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターはターン終了時まで、攻撃力が４００アップし、効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"銀の弓矢",h:"ぎんのゆみや",e:"Silver Bow and Arrow",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"天使族モンスターにのみ装備可能。\n装備モンスターの攻撃力・守備力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"銀龍の轟咆",h:"ぎんりゅうのごうほう",e:"Silver's Cry",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地のドラゴン族の通常モンスター１体を対象として発動できる。\nそのドラゴン族モンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"速攻魔力増幅器",h:"くいっく・ぶーすたー",e:"Quick Booster",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分の墓地から「速攻魔力増幅器」以外の速攻魔法カード１枚を選択してデッキに戻す。\nまた、フィールド上のこのカードが相手によって破壊され墓地へ送られた場合、デッキから「速攻魔力増幅器」以外の速攻魔法カード１枚を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"急速充電器",h:"くぃっくちゃーじゃー",e:"Quick Charger",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分の墓地のレベル４以下の「電池メン」と名のついたモンスター２体を選択して発動できる。\n選択したモンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"傀儡儀式－パペット・リチューアル",h:"くぐつぎしき－ぱぺっと・りちゅーある",e:"Puppet Ritual",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分のライフポイントが相手より２０００ポイント以上少ない場合に発動できる。\n自分の墓地から「ギミック・パペット」と名のついたレベル８モンスター２体を選択して特殊召喚する。\n「傀儡儀式－パペット・リチューアル」は１ターンに１枚しか発動できず、このカードを発動するターン、自分はバトルフェイズを行えない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"草薙剣",h:"くさなぎのつるぎ",e:"Sword of Kusanagi",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"スピリットモンスターにのみ装備可能。\n装備モンスターが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。\n装備モンスターが自分フィールド上から手札に戻る事によってこのカードが墓地へ送られた時、このカードを手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"苦渋の決断",h:"くじゅうのけつだん",e:"Painful Decision",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：デッキからレベル４以下の通常モンスター１体を墓地へ送り、その同名モンスター１体をデッキから手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"苦渋の転生",h:"くじゅうのてんせい",e:"Painful Return",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地から、相手はモンスター１体を選ぶ。\nこのターンのエンドフェイズ時、そのモンスターを墓地から自分の手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"愚鈍の斧",h:"ぐどんのおの",e:"Axe of Fools",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"①：装備モンスターの攻撃力は１０００アップし、効果は無効化される。\n②：自分スタンバイフェイズに発動する。\n装備モンスターのコントローラーに５００ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"曇りの天気模様",h:"くもりのてんきもよう",e:"The Weather Cloudy Canvas",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：「曇りの天気模様」は自分フィールドに１枚しか表側表示で存在できない。\n②：このカードと同じ縦列の自分のメインモンスターゾーン及びその両隣の自分のメインモンスターゾーンに存在する「天気」効果モンスターは以下の効果を得る。\n●このカードを除外し、フィールドの表側表示モンスター１体を対象として発動できる。\nこのターン、そのモンスターの攻撃力は半分になり、直接攻撃できる。\nこの効果はダメージステップでは発動できず、相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"重力砲",h:"ぐらう゛ぃてぃ・ぶらすたー",e:"Gravity Blaster",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"機械族モンスターにのみ装備可能。\n１ターンに１度、装備モンスターの攻撃力を４００ポイントアップできる。\nまた、装備モンスターが相手モンスターと戦闘を行う場合、バトルフェイズの間だけその相手モンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"雲魔物のスコール",h:"くらうでぃあん・すこーる",e:"Cloudian Squall",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分のスタンバイフェイズ毎にフィールド上に表側表示で存在する全てのモンスターに、フォッグカウンターを１つ置く。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"クラッキング",h:"くらっきんぐ",e:"Cracking",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"フィールド上に存在するモンスターがカードの効果によって墓地へ送られた時、そのモンスターの元々の持ち主に８００ポイントダメージを与える。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"グラディアル・リターン",h:"ぐらでぃある・りたーん",e:"Gladiator's Return",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"墓地に存在する「剣闘獣」と名のついたカード３枚をデッキに戻す。\nその後、自分のデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"剣闘調教",h:"ぐらでぃあるていむ",e:"Gladiator Taming",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"フィールド上に「剣闘獣」と名のついたモンスターが存在する場合、以下の効果から１つを選択して発動できる。\n\n●相手フィールド上に表側表示で存在するモンスター１体を選択し、表示形式を変更する。\n\n●相手フィールド上の「剣闘獣」と名のついたモンスター１体を選択し、エンドフェイズ時までコントロールを得る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"剣闘獣の檻－コロッセウム",h:"ぐらでぃあるびーすとのおり－ころっせうむ",e:"Colosseum - Cage of the Gladiator Beasts",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"モンスターがデッキからフィールド上に特殊召喚される度に、このカードにカウンターを１つ置く。\nフィールド上に表側表示で存在する「剣闘獣」と名のついたモンスターは、このカードに乗っているカウンター１つにつき、攻撃力と守備力が１００ポイントアップする。\nこのカードがカードの効果によって破壊される時、手札から「剣闘獣の檻－コロッセウム」１枚を捨てる事でこのカードは破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"剣闘獣の底力",h:"ぐらでぃあるびーすとのそこぢから",e:"Indomitable Gladiator Beast",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上の「剣闘獣」と名のついたモンスター１体を選択し、その攻撃力をエンドフェイズ時まで５００ポイントアップする。\nまた、このカードが墓地に存在する場合、自分のメインフェイズ時にこのカード以外の自分の墓地の「剣闘獣」と名のついたカード２枚をデッキに戻す事で、墓地のこのカードを手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"剣闘獣の闘器グラディウス",h:"ぐらでぃあるびーすとのとうきぐらでぃうす",e:"Gladiator Beast's Battle Gladius",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「剣闘獣」と名のついたモンスターにのみ装備可能。\n装備モンスターの攻撃力は３００ポイントアップする。\n装備モンスターが自分フィールド上からデッキに戻る事によってこのカードが墓地へ送られた時、このカードを手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"剣闘獣の闘器デーモンズシールド",h:"ぐらでぃあるびーすとのとうきでーもんずしーるど",e:"Gladiator Beast's Battle Archfiend Shield",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「剣闘獣」と名のついたモンスターにのみ装備可能。\n装備モンスターが破壊される場合、代わりにこのカードを破壊する。\n装備モンスターが自分フィールド上からデッキに戻る事によってこのカードが墓地へ送られた時、このカードを手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"剣闘獣の闘器ハルバード",h:"ぐらでぃあるびーすとのとうきはるばーど",e:"Gladiator Beast's Battle Halberd",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「剣闘獣」と名のついたモンスターにのみ装備可能。\n装備モンスターが攻撃を行ったダメージステップ終了時、フィールド上の魔法または罠カード１枚を破壊する。\n装備モンスターが自分フィールド上からデッキに戻る事によってこのカードが墓地へ送られた時、このカードを手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"剣闘排斥波",h:"ぐらでぃあるりじぇくしょん",e:"Gladiator Rejection",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドの「剣闘獣」モンスターは、バトルフェイズ以外では相手の効果の対象にならない。\n②：自分のデッキから「剣闘獣」モンスターが特殊召喚された場合に発動できる。\n同じ種族のモンスターが自分フィールドに存在しない「剣闘獣」モンスター１体をデッキから守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"グリード・グラード",h:"ぐりーど・ぐらーど",e:"Greed Grado",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分が相手フィールド上に表側表示で存在するシンクロモンスターを戦闘またはカードの効果によって破壊したターンに発動する事ができる。\n自分のデッキからカードを２枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"クリスタルP",h:"くりすたるぽてんしゃる",e:"Crystolic Potential",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの「クリストロン」モンスターの攻撃力・守備力は３００アップする。\n②：自分・相手のエンドフェイズに発動できる。\nこのターン自分がS召喚した「クリストロン」Sモンスターの数だけ、自分はデッキからドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"クリティウスの牙",h:"くりてぃうすのきば",e:"The Fang of Critias",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカードのカード名はルール上「伝説の竜 クリティウス」としても扱う。\n「クリティウスの牙」は１ターンに１枚しか発動できない。\n①：「クリティウスの牙」の効果でのみ特殊召喚できる融合モンスターカードに記された罠カード１枚を自分の手札・フィールドから墓地へ送る（そのカードがフィールドにセットされている場合、めくって確認する）。\nその後、その融合モンスター１体をエクストラデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"グリフォンの羽根帚",h:"ぐりふぉんのはねぼうき",e:"Gryphon's Feather Duster",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に存在する全ての魔法・罠カードを破壊する。\n自分は破壊したカードの数×５００ライフポイント回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"クリボーを呼ぶ笛",h:"くりぼーをよぶふえ",e:"The Flute of Summoning Kuriboh",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分のデッキから「クリボー」または「ハネクリボー」１体を選択し、手札に加えるか自分フィールド上に特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"クリムゾン・ヘル・セキュア",h:"くりむぞん・へる・せきゅあ",e:"Scarlet Security",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に「レッド・デーモンズ・ドラゴン」が表側表示で存在する場合のみ発動する事ができる。\n相手フィールド上に存在する魔法・罠カードを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"グリモの魔導書",h:"ぐりものまどうしょ",e:"Spellbook of Secrets",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：デッキから「グリモの魔導書」以外の「魔導書」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"グレイドル・インパクト",h:"ぐれいどる・いんぱくと",e:"Graydle Impact",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"「グレイドル・インパクト」の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカード以外の自分フィールドの「グレイドル」カード１枚と相手フィールドのカード１枚を対象としてこの効果を発動できる。\nそのカードを破壊する。\n②：自分エンドフェイズにこの効果を発動できる。\nデッキから「グレイドル」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"紅蓮の炎壁",h:"ぐれんのえんぺき",e:"Searing Fire Wall",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分の墓地の「ラヴァル」と名のついたモンスターを任意の枚数ゲームから除外して発動できる。\nこのカードを発動するために除外したモンスターの数だけ自分フィールド上に「ラヴァルトークン」（炎族・炎・星１・攻／守０）を守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"紅蓮魔竜の壺",h:"ぐれんまりゅうのつぼ",e:"Red Dragon Vase",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に「レッド・デーモンズ・ドラゴン」が表側表示で存在する場合のみ発動する事ができる。\n自分のデッキからカードを２枚ドローする。\nこのカードを発動する場合、次の相手ターン終了時まで自分はモンスターを召喚・特殊召喚する事はできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"黒い旋風",h:"くろいせんぷう",e:"Black Whirlwind",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：自分フィールドに「BF」モンスターが召喚された時にこの効果を発動できる。\nそのモンスターより低い攻撃力を持つ「BF」モンスター１体をデッキから手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"クローザー・フォレスト",h:"くろーざー・ふぉれすと",e:"Closed Forest",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"自分の墓地に存在するモンスター１体につき、自分フィールド上に表側表示で存在する獣族モンスターの攻撃力は１００ポイントアップする。\nこのカードがフィールド上に存在する限り、フィールド魔法カードを発動する事はできない。\nまた、このカードが破壊されたターン、フィールド魔法カードを発動する事はできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"黒蠍団召集",h:"くろさそりだんしょうしゅう",e:"Mustering of the Dark Scorpions",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に「首領・ザルーグ」が表側表示で存在する時に発動する事ができる。\n自分の手札から「黒蠍」という名のついたモンスターカードを全て特殊召喚する事ができる。\n（同名カードは１枚のみ）",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"クロス・アタック",h:"くろす・あたっく",e:"Cross Attack",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側攻撃表示で存在する、同じ攻撃力を持つモンスター２体を選択して発動する。\nこのターン、選択したモンスター１体は相手プレイヤーに直接攻撃する事ができる。\nもう１体のモンスターは攻撃する事ができない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"クロス・ソウル",h:"くろす・そうる",e:"Soul Exchange",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカードを発動するターン、自分はバトルフェイズを行えない。\n①：相手フィールドのモンスター１体を対象として発動できる。\nこのターン自分がモンスターをリリースする場合、自分のモンスター１体の代わりに対象の相手モンスターをリリースしなければならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"クロス・ブリード",h:"くろす・ぶりーど",e:"Cross Breed",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：手札及び自分フィールドの表側表示モンスターの中から、元々の種族・属性が同じで、カード名が異なるモンスター２体を除外して発動できる。\nそれらのモンスターと元々の種族・属性が同じで、カード名が異なるモンスター１体をデッキから手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"黒の魔導陣",h:"くろのまどうじん",e:"Dark Magical Circle",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードの発動時の効果処理として、自分のデッキの上からカードを３枚確認する。\nその中に、「ブラック・マジシャン」のカード名が記された魔法・罠カードまたは「ブラック・マジシャン」があった場合、その内の１枚を相手に見せて手札に加える事ができる。\n残りのカードは好きな順番でデッキの上に戻す。\n②：自分フィールドに「ブラック・マジシャン」が召喚・特殊召喚された場合、相手フィールドのカード１枚を対象として発動できる。\nそのカードを除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"黒羽の宝札",h:"くろはねのほうさつ",e:"Cards for Black Feathers",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"手札の「BF」と名のついたモンスター１体をゲームから除外して発動できる。\nデッキからカードを２枚ドローする。\n「黒羽の宝札」は１ターンに１枚しか発動できず、このカードを発動するターン、自分はモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"黒魔術のヴェール",h:"くろまじゅつのう゛ぇーる",e:"Dark Magic Veil",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：１０００LPを払って発動できる。\n自分の手札・墓地から魔法使い族・闇属性モンスター１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"黒魔術のカーテン",h:"くろまじゅつのかーてん",e:"Dark Magic Curtain",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカードを発動するターン、自分はこのカードの効果以外ではモンスターを召喚・反転召喚・特殊召喚できない。\n①：LPを半分払って発動できる。\nデッキから「ブラック・マジシャン」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"黒魔術の継承",h:"くろまじゅつのけいしょう",e:"Dark Magic Inheritance",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地から魔法カード２枚を除外して発動できる。\n「黒魔術の継承」以外の、「ブラック・マジシャン」のカード名または「ブラック・マジシャン・ガール」のカード名が記された魔法・罠カード１枚をデッキから手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"契約の履行",h:"けいやくのりこう",e:"Fulfillment of the Contract",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"８００ライフポイントを払う。\n自分の墓地から儀式モンスター１体を選択して自分フィールド上に特殊召喚し、このカードを装備する。\nこのカードが破壊された時、装備モンスターをゲームから除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"軽量化",h:"けいりょうか",e:"Lighten the Load",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"手札からレベル７以上のモンスター１体をデッキに加えてシャッフルした後、カードを１枚ドローする。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゲーテの魔導書",h:"げーてのまどうしょ",e:"Spellbook of Fate",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上に魔法使い族モンスターが存在する場合、自分の墓地の「魔導書」と名のついた魔法カードを３枚までゲームから除外して発動できる。\nこのカードを発動するために除外した魔法カードの数によって以下の効果を適用する。\n「ゲーテの魔導書」は１ターンに１枚しか発動できない。\n\n●１枚：フィールド上にセットされた魔法・罠カード１枚を選んで持ち主の手札に戻す。\n\n●２枚：フィールド上のモンスター１体を選んで裏側守備表示または表側攻撃表示にする。\n\n●３枚：相手フィールド上のカード１枚を選んでゲームから除外する。",li:3,lk:[],ta:[],al:[],ocg:true},
  {n:"下克上の首飾り",h:"げこくじょうのくびかざり",e:"Amulet of Ambition",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"通常モンスターにのみ装備可能。\n装備モンスターよりレベルの高いモンスターと戦闘を行う場合、装備モンスターの攻撃力はダメージ計算時のみレベルの差×５００ポイントアップする。\nこのカードが墓地へ送られた時、このカードをデッキの一番上に戻す事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"結束 UNITY",h:"けっそく　ＵＮＩＴＹ",e:"Unity",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの守備力はターン終了時まで、自分フィールドの全ての表側表示モンスターの元々の守備力を合計した数値になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"検疫",h:"けんえき",e:"Quarantine",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：自分・相手のエンドフェイズに相手フィールドにセットされた魔法・罠カード１枚を対象としてこの効果を発動できる。\nそのカードを確認し、元に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"検閲",h:"けんえつ",e:"Inspection",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"相手のスタンバイフェイズ毎に５００ライフポイントを払う事で、ランダムに１枚相手の手札を見る事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"謙虚な壺",h:"けんきょなつぼ",e:"Pot of Generosity",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の手札からカードを２枚デッキに戻す。\nその後デッキをシャッフルする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"謙虚な番兵",h:"けんきょなばんぺい",e:"The Humble Sentry",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の手札を公開し、その中からカードを１枚選んでデッキに戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"眷現の呪眼",h:"けんげんのじゅがん",e:"Evil Eye Reemergence",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドに「呪眼の眷属トークン」（悪魔族・闇・星１・攻／守４００）１体を特殊召喚する。\n自分の魔法＆罠ゾーンに「セレンの呪眼」が存在する場合、この効果で特殊召喚する数を２体にできる。\nこのカードの発動後、ターン終了時まで自分は悪魔族モンスターしか特殊召喚できない。\n②：墓地のこのカードを除外して発動できる。\nこのターン、自分フィールドの「呪眼」魔法・罠カードは相手の効果の対象にならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"賢者の石－サバティエル",h:"けんじゃのいし－さばてぃえる",e:"Sabatiel - The Philosopher's Stone",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の墓地に「ハネクリボー」モンスターが存在する場合、LPを半分払って発動できる。\nデッキから「融合」魔法カードまたは「フュージョン」魔法カード１枚を手札に加える。\n②：このカードが墓地に存在する場合、自分の墓地の「賢者の石－サバティエル」３枚を除外し、フィールドのモンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで、フィールドの攻撃力が一番高いモンスターの攻撃力分アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"賢者の聖杯",h:"けんじゃのせいはい",e:"Wiseman's Chalice",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上にモンスターが存在しない場合、相手の墓地に存在するモンスター１体を選択して発動する事ができる。\n選択したモンスターを自分フィールド上に特殊召喚する。\nこのターンのエンドフェイズ時、この効果で特殊召喚したモンスターのコントロールを相手に移す。\nまた、この効果で特殊召喚したモンスターはリリースできず、シンクロ素材とする事もできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"賢者の宝石",h:"けんじゃのほうせき",e:"Sage's Stone",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドに「ブラック・マジシャン・ガール」が存在する場合に発動できる。\n手札・デッキから「ブラック・マジシャン」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"原初の叫喚",h:"げんしょのきょうかん",e:"Primal Cry",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「輝神鳥ヴェーヌ」の降臨に必要。\n①：自分の手札・フィールドから、レベルの合計が８以上になるようにモンスターをリリースし、手札から「輝神鳥ヴェーヌ」を儀式召喚する。\n②：自分エンドフェイズに墓地のこのカードを除外し、このターンにフィールドから墓地へ送られた、自分の墓地の儀式モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"原初のスープ",h:"げんしょのすーぷ",e:"Primordial Soup",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"１ターンに１度、自分のメインフェイズ時に発動できる。\n手札の「エヴォルダー」と名のついたモンスターを２体までデッキに戻してシャッフルし、戻した数だけデッキからカードをドローする。\n「原初のスープ」は自分フィールド上に１枚しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"限定解除",h:"げんていかいじょ",e:"Ritual Foregone",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"１０００ライフポイントを払って発動できる。\n手札から儀式モンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターは攻撃できず、エンドフェイズ時に破壊される。\n「限定解除」は１ターンに１枚しか発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻惑の巻物",h:"げんわくのまきもの",e:"Scroll of Bewitchment",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"属性を１つ宣言して発動する。\n装備モンスターの属性は宣言した属性になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"鋼核初期化",h:"こあきめいる・いにしゃらいず",e:"Koa'ki Meiru Initialize!",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上に存在する「コアキメイル」と名のついたモンスター１体をリリースして発動する。\n自分のデッキ・墓地から「コアキメイルの鋼核」１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コアキメイルの鋼核",h:"こあきめいるのこうかく",e:"Iron Core of Koa'ki Meiru",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分のドローフェイズ時に通常のドローを行う代わりに、墓地のこのカードを手札に加える事ができる。\nまた、自分のドローフェイズ時に手札から「コアキメイル」と名のついたモンスター１体を墓地へ送る事で、墓地のこのカードを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コアキメイルの金剛核",h:"こあきめいるのこんごうかく",e:"Diamond Core of Koa'ki Meiru",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"デッキから「コアキメイルの金剛核」以外の「コアキメイル」と名のついたカード１枚を手札に加える。\nまた、自分のメインフェイズ時に墓地のこのカードをゲームから除外して発動できる。\nこのターン、自分フィールド上の「コアキメイル」と名のついたモンスターは破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コア転送ユニット",h:"こあてんそうゆにっと",e:"Core Transport Unit",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"１ターンに１度、手札を１枚捨てる事で、自分のデッキから「コアキメイルの鋼核」１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コア濃度圧縮",h:"こあのうどあっしゅく",e:"Core Compression",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"手札の「コアキメイルの鋼核」１枚を相手に見せ、手札から「コアキメイル」と名のついたモンスター１体を捨てて発動する。\n自分のデッキからカードを２枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コアバスター",h:"こあばすたー",e:"Core Blaster",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「コアキメイル」と名のついたモンスターにのみ装備可能。\n装備モンスターが光属性または闇属性モンスターと戦闘を行う場合、ダメージ計算を行わずそのモンスターを破壊する。\n装備モンスターがフィールド上から離れる事によってこのカードが墓地へ送られた時、このカードを手札に戻す事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ご隠居の猛毒薬",h:"ごいんきょのもうどくやく",e:"Poison of the Old Man",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：以下の効果から１つを選択して発動できる。\n\n●自分は１２００LP回復する。\n\n●相手に８００ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幸運の鉄斧",h:"こううんのてつおの",e:"Lucky Iron Axe",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"①：装備モンスターの攻撃力は５００アップする。\n②：フィールドに表側表示で存在するこのカードが相手の効果で破壊され墓地へ送られた場合に発動する。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幸運の前借り",h:"こううんのまえがり",e:"Lucky Loan",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの「占い魔女」モンスター１体を対象として発動できる。\nそのモンスターより元々のレベルが１つ低い魔法使い族モンスター１体を手札・デッキから特殊召喚する。\nこのカードの発動後、次の自分ターン中、自分は魔法使い族モンスターしか召喚・特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"鋼核合成獣研究所",h:"こうかくごうせいじゅうけんきゅうじょ",e:"Iron Core Specimen Lab",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカードのコントローラーは自分のエンドフェイズ毎に、手札から「コアキメイルの鋼核」１枚を相手に見せる。\nまたは、見せずにこのカードを破壊する。\nこのカードがフィールド上に存在する限り、フィールド上の「コアキメイル」と名のついたモンスターがエンドフェイズ時に破壊される度に、そのモンスターの元々の持ち主はデッキから「コアキメイル」と名のついたモンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"鋼核収納",h:"こうかくしゅうのう",e:"Iron Core Armor",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「コアキメイル」と名のついたモンスターにのみ装備可能。\n装備モンスターと戦闘を行う相手モンスターの攻撃力は、そのダメージ計算時のみ装備モンスターのレベル×１００ポイントダウンする。\n装備モンスターがエンドフェイズ時に破壊される場合、代わりにこのカードを墓地へ送る事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"降格処分",h:"こうかくしょぶん",e:"Demotion",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"装備モンスターのレベルを２つ下げる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"光学迷彩アーマー",h:"こうがくめいさいあーまー",e:"Opti-Camouflage Armor",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"レベル１のモンスターのみ装備可能。\n装備モンスターは相手プレイヤーに直接攻撃をする事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"剛鬼死闘",h:"ごうきですまっち",e:"Gouki Cage Match",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：このカードの発動時の効果処理として、このカードにカウンターを３つ置く。\n②：自分の「剛鬼」モンスターが戦闘で相手モンスターを破壊した場合に発動する。\nこのカードのカウンターを１つ取り除く。\n③：このカードの効果でこのカードに置かれているカウンターが全て取り除かれたバトルフェイズ終了時に、自分はこの効果を発動できる。\n手札・デッキから「剛鬼」モンスターを可能な限り特殊召喚する（同名カードは１枚まで）。\nその後、このカードにカウンターを３つ置く。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"剛鬼フェイスターン",h:"ごうきふぇいすたーん",e:"Gouki Face Turn",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの「剛鬼」カード１枚と自分の墓地の「剛鬼」モンスター１体を対象として発動できる。\n対象のフィールドのカードを破壊し、対象の墓地のモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"紅玉の宝札",h:"こうぎょくのほうさつ",e:"Cards of the Red Stone",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「紅玉の宝札」は１ターンに１枚しか発動できない。\n①：手札からレベル７の「レッドアイズ」モンスター１体を墓地へ送って発動できる。\n自分はデッキから２枚ドローする。\nその後、デッキからレベル７の「レッドアイズ」モンスター１体を墓地へ送る事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"光子竜降臨",h:"こうしりゅうこうりん",e:"Luminous Dragon Ritual",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「光子竜の聖騎士」の降臨に必要。\n自分の手札・フィールド上から、レベルの合計が４になるようにモンスターをリリースしなければならない。\nまた、自分のメインフェイズ時に墓地のこのカードをゲームから除外して発動できる。\nレベルの合計が４になるように自分の墓地のモンスターをゲームから除外し、手札から「光子竜の聖騎士」１体を儀式召喚扱いとして特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"光神化",h:"こうしんか",e:"Celestial Transformation",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：手札から天使族モンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターの攻撃力は半分になり、エンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"合成魔術",h:"ごうせいまじゅつ",e:"Synthesis Spell",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「ライカン・スロープ」の降臨に必要。\nフィールドか手札から、レベルが６以上になるようカードを生け贄に捧げなければならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"拘束解除",h:"こうそくかいじょ",e:"Release Restraint",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの「鉄の騎士 ギア・フリード」１体をリリースして発動できる。\n手札・デッキから「剣聖－ネイキッド・ギア・フリード」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"拘束解放波",h:"こうそくかいほうは",e:"Release Restraint Wave",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する装備魔法カード１枚を選択して発動できる。\n選択した装備魔法カードと相手フィールド上にセットされた魔法・罠カードを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"高等儀式術",h:"こうとうぎしきじゅつ",e:"Advanced Ritual Art",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"儀式モンスターの降臨に必要。\n①：レベルの合計が儀式召喚するモンスターと同じになるように、デッキから通常モンスターを墓地へ送り、手札から儀式モンスター１体を儀式召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"荒野",h:"こうや",e:"Wasteland",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"全ての恐竜・アンデット・岩石族モンスターの攻撃力と守備力は、２００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"強欲なウツボ",h:"ごうよくなうつぼ",e:"Moray of Greed",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：手札の水属性モンスター２体をデッキに戻してシャッフルする。\nその後、自分はデッキから３枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"強欲なカケラ",h:"ごうよくなかけら",e:"Shard of Greed",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在する限り、自分ドローフェイズに通常のドローをする度に、このカードに強欲カウンターを１つ置く。\n②：強欲カウンターが２つ以上置かれているこのカードを墓地へ送って発動できる。\n自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"強欲な壺",h:"ごうよくなつぼ",e:"Pot of Greed",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コート・オブ・ジャスティス",h:"こーと・おぶ・じゃすてぃす",e:"Court of Justice",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\n手札から天使族モンスター１体を特殊召喚する。\nこの効果は自分フィールドにレベル１の天使族モンスターが存在する場合に発動と処理ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コーリング・マジック",h:"こーりんぐ・まじっく",e:"Spell Calling",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"相手がコントロールする魔法・罠カードの効果によってセットされたこのカードが破壊され墓地へ送られた時、デッキから速攻魔法カード１枚を選択してお互いに確認し自分フィールド上にセットする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コール・リゾネーター",h:"こーる・りぞねーたー",e:"Resonator Call",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：デッキから「リゾネーター」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"黄金色の竹光",h:"こがねいろのたけみつ",e:"Golden Bamboo Sword",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドに「竹光」装備魔法カードが存在する場合に発動できる。\n自分はデッキから２枚ドローする。",li:1,lk:[],ta:[],al:[],ocg:true},
  {n:"コクーン・パーティ",h:"こくーん・ぱーてぃ",e:"Cocoon Party",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地に存在する「N」と名のついたモンスター１種類につき、「C」と名のついたモンスター１体を自分のデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コクーン・リボーン",h:"こくーん・りぼーん",e:"Cocoon Rebirth",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「C」と名のついたモンスター１体を生け贄に捧げる事で、そのカードに記されている「N」と名のついたモンスター１体を墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"黒白の波動",h:"こくびゃくのはどう",e:"Black and White Wave",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"シンクロモンスターをエクシーズ素材としているエクシーズモンスターがフィールド上に存在する場合に発動できる。\nフィールド上のカード１枚を選択してゲームから除外し、自分のデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"黒竜降臨",h:"こくりゅうこうりん",e:"Dark Dragon Ritual",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「黒竜の聖騎士」の降臨に必要。\n①：自分の手札・フィールドから、レベルの合計が４以上になるようにモンスターをリリースし、手札から「黒竜の聖騎士」を儀式召喚する。\n②：自分メインフェイズに墓地のこのカードを除外して発動できる。\nデッキから「レッドアイズ」魔法・罠カード１枚を手札に加える。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゴゴゴ護符",h:"ごごごごふ",e:"Gogogo Talisman",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分フィールド上に「ゴゴゴ」と名のついたモンスターが２体以上存在する場合、自分が受ける効果ダメージは０になる。\nまた、１ターンに１度、自分フィールド上の「ゴゴゴ」と名のついたモンスターが戦闘を行う攻撃宣言時に発動できる。\nその自分のモンスターはその戦闘では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コストダウン",h:"こすとだうん",e:"Cost Down",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：手札を１枚捨てて発動できる。\nこのターン、自分の手札のモンスターのレベルを２つ下げる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コズミック・サイクロン",h:"こずみっく・さいくろん",e:"Cosmic Cyclone",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：１０００LPを払い、フィールドの魔法・罠カード１枚を対象として発動できる。\nそのカードを除外する。",li:3,lk:[],ta:[],al:[],ocg:true},
  {n:"古代遺跡コードA",h:"こだいいせきこーどえー",e:"Code A Ancient Ruins",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在する「エーリアン」と名のついたモンスターが破壊される度に、このカードにAカウンターを１つ置く。\n１ターンに１度、フィールド上に存在するAカウンターを２つ取り除く事で、自分の墓地に存在する「エーリアン」と名のついたモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"古代の遠眼鏡",h:"こだいのとおめがね",e:"Ancient Telescope",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"相手のデッキのカードを、上から５枚まで見ることができる。\nその後カードを元通りにする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゴッドハンド・スマッシュ",h:"ごっどはんど・すまっしゅ",e:"Kaminote Blow",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に「格闘ねずみ チュー助」「モンク・ファイター」「マスターモンク」の内１体以上が存在している場合のみ発動する事ができる。\nこのターンこれらのモンスターと戦闘を行ったモンスターをダメージステップ終了時に破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コピーキャット",h:"こぴーきゃっと",e:"Mimicat",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「コピーキャット」は１ターンに１枚しか発動できない。\n①：自分フィールドに「トゥーン・ワールド」及びトゥーンモンスターが存在する場合、相手の墓地のカード１枚を対象として発動できる。\nそのカードがモンスターだった場合、そのモンスターを自分フィールドに特殊召喚する。\nそのカードが魔法・罠カードだった場合、そのカードを自分フィールドにセットする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゴブリンの秘薬",h:"ごぶりんのひやく",e:"Goblin's Secret Remedy",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分は６００LP回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コモンメンタルワールド",h:"こもんめんたるわーるど",e:"Synchronized Realm",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分がシンクロモンスターのシンクロ召喚に成功する度に、相手ライフに５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゴルゴネイオの呪眼",h:"ごるごねいおのじゅがん",e:"Evil Eye of Gorgoneio",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「呪眼」モンスターにのみ装備可能。\nこのカード名のカードは１ターンに１枚しか発動できず、このカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードは魔法＆罠ゾーンに存在する限り、カード名を「セレンの呪眼」として扱う。\n②：自分のLPが相手より少ない場合、装備モンスターの攻撃力はLPの差の数値分アップする。\n③：墓地のこのカードを除外し、手札から「呪眼」カード１枚を捨てて発動できる。\nデッキから「ゴルゴネイオの呪眼」以外の「呪眼」魔法・罠カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"金剛真力",h:"こんごうしんりき",e:"Herculean Power",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"相手フィールド上にモンスターが存在し、自分フィールド上にモンスターが存在しない場合、手札からレベル４以下のデュアルモンスター１体を特殊召喚する事ができる。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"渾身の一撃",h:"こんしんのいちげき",e:"Full-Force Strike",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在するモンスター１体を選択して発動する。\nこのターン、選択したモンスターは戦闘では破壊されず、その攻撃によって発生するお互いへの戦闘ダメージは０になる。\nまた、このターン、選択したモンスターが相手モンスターを攻撃した場合、ダメージ計算後にその相手モンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コンセントレイト",h:"こんせんとれいと",e:"Concentrating Current",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時までその守備力分アップする。\nこのカードを発動するターン、対象のモンスター以外の自分のモンスターは攻撃できない。",li:2,lk:[],ta:[],al:[],ocg:true},
  {n:"コンタクト",h:"こんたくと",e:"Contact",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上の「C」と名のついたモンスター全てを墓地に送り、そのカードに記されているモンスター１体を手札またはデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コンタクト・アウト",h:"こんたくと・あうと",e:"Contact Out",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「ネオス」と名のついた融合モンスター１体を融合デッキに戻す。\nさらに、融合デッキに戻したモンスターに記された融合素材モンスター一組が自分のデッキに揃っていれば、この一組を自分フィールド上に特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コンドーレンス・パペット",h:"こんどーれんす・ぱぺっと",e:"Condolence Puppet",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：EXデッキから特殊召喚された相手フィールドのモンスターの数＋１枚まで、デッキから「ギミック・パペット」モンスターを墓地へ送る（同名カードは１枚まで）。\n②：墓地のこのカードを除外し、自分フィールドの機械族Xモンスター１体を対象として発動できる。\nそのモンスターはフィールドに表側表示で存在する限り、相手の効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"混沌の種",h:"こんとんのたね",e:"Chaos Seed",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に、光属性及び闇属性モンスターが存在する場合、除外されている自分の光属性または闇属性の戦士族モンスター１体を選択して発動できる。\n選択したモンスターを手札に加える。\n「混沌の種」は１ターンに１枚しか発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コンバート・コンタクト",h:"こんばーと・こんたくと",e:"Convert Contact",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドにモンスターが存在しない場合に発動できる。\n手札及びデッキから「N」カードを１枚ずつ墓地へ送る。\nその後、自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コンビネーション・アタック",h:"こんびねーしょん・あたっく",e:"Combination Attack",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"ユニオンモンスターを装備したモンスターが戦闘を行ったバトルフェイズ時にのみ発動する事ができる。\nユニオンモンスターを装備して戦闘を行ったモンスター１体を選択し、装備されたユニオンを解除する。\n選択したモンスターはこのターンもう１度攻撃する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイキック・インパルス",h:"さいきっく・いんぱるす",e:"Psi-Impulse",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に存在するサイキック族モンスター１体をリリースして発動する。\n相手の手札を全てデッキに加えてシャッフルする。\nその後、相手はデッキからカードを３枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"超能力増幅器",h:"さいきっく・ぶーすたー",e:"ESP Amplifier",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカードの発動時に、自分フィールド上に表側表示で存在する全てのサイキック族モンスターの攻撃力は、ゲームから除外されている自分のサイキック族モンスターの数×３００ポイントアップする。\nこの効果を受けたモンスターはエンドフェイズ時にゲームから除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイキックブレイク",h:"さいきっくぶれいく",e:"Psi-Station",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"サイキック族モンスターが召喚に成功した時、５００ライフポイントを払う事でそのモンスター１体のレベルを１つ上げ、攻撃力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"再機動",h:"さいきどう",e:"Reboot",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の手札から「機皇」と名のついたモンスター１体をデッキに戻して発動する。\n自分の墓地に存在する「機皇」と名のついたカード１枚を選択して手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"細菌感染",h:"さいきんかんせん",e:"Germ Infection",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"機械族以外のモンスターのみ装備可能。\n装備モンスターの攻撃力は自分のスタンバイフェイズ毎に３００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイクロン",h:"さいくろん",e:"Mystical Space Typhoon",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：フィールドの魔法・罠カード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイクロン・ブーメラン",h:"さいくろん・ぶーめらん",e:"Cyclone Boomerang",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「E・HERO ワイルドマン」にのみ装備可能。\n装備モンスターの攻撃力は５００ポイントアップする。\n装備モンスターが他のカードの効果によって破壊され墓地へ送られた時、フィールド上の魔法・罠カードを全て破壊する。\n破壊した魔法・罠カードの枚数×１００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"旋風剣",h:"さいくろんぶれーど",e:"Cyclone Blade",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「D-HERO」と名のついたモンスターにのみ装備可能。\n装備モンスターが攻撃を行ったダメージステップ終了時、フィールド上の魔法・罠カード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイクロンレーザー",h:"さいくろんれーざー",e:"Cyclon Laser",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「超時空戦闘機ビック・バイパー」のみ装備可能。\n攻撃力は３００ポイントアップする。\nさらにこのカードが守備表示モンスターを攻撃した時にその守備力を攻撃力が越えていれば、その数値だけ相手に戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイコ・フィール・ゾーン",h:"さいこ・ふぃーる・ぞーん",e:"Psychic Feel Zone",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"ゲームから除外されている自分のサイキック族のチューナー１体とチューナー以外のサイキック族モンスター１体を墓地に戻し、そのレベルの合計と同じレベルのサイキック族のシンクロモンスター１体をエクストラデッキから表側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"最古式念導",h:"さいこしきねんどう",e:"Psychokinesis",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上にサイキック族モンスターが存在する場合、フィールド上のカード１枚を選択して発動できる。\n選択したカードを破壊し、自分は１０００ポイントダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"最後の進軍",h:"さいごのしんぐん",e:"March Towards Ragnarok",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「極神」と名のついたモンスター１体を選択して発動する。\nこのターンのエンドフェイズ時まで、選択したモンスターの効果は無効化され、このカード以外の魔法・罠カードの効果を受けない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"最期の同調",h:"さいごのどうちょう",e:"Final Gesture",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上のレベル３モンスター１体を選択して発動できる。\n選択したモンスターと同名のモンスター１体を自分の手札・墓地から選んで特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。\nこのターンのエンドフェイズ時、選択したモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイコパス",h:"さいこぱす",e:"Psychic Path",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：８００LPを払い、除外されている自分のサイキック族モンスターを２体まで対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイコロン",h:"さいころん",e:"Dicephoon",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：サイコロを１回振り、出た目の効果を適用する。\n\n●２・３・４：フィールドの魔法・罠カード１枚を選んで破壊する。\n\n●５：フィールドの魔法・罠カード２枚を選んで破壊する。\n\n●１・６：自分は１０００ダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"最終戦争",h:"さいしゅうせんそう",e:"Final Destiny",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"手札を５枚捨てて発動する。\nフィールド上に存在するカードを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"賽挑戦",h:"さいちょうせん",e:"Diced Dice",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：サイコロを１回振り、１・６が出た場合、サイコロを振る効果を持つカード１枚をデッキから手札に加える。\n１・６以外が出た場合、サイコロをもう１回振り、出た目によって以下の効果を適用する。\n\n●１・６：フィールドのこのカードを持ち主の手札に戻す。\n\n●２～５：フィールドのこのカードを持ち主のデッキの一番上に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイバー・ボンテージ",h:"さいばー・ぼんてーじ",e:"Cyber Shield",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「ハーピィ・レディ」または「ハーピィ・レディ三姉妹」にのみ装備可能。\n①：装備モンスターの攻撃力は５００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイバー・リペア・プラント",h:"さいばー・りぺあ・ぷらんと",e:"Cyber Repair Plant",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地に「サイバー・ドラゴン」が存在する場合、以下の効果から１つを選択して発動できる。\nこのカードの発動時に自分の墓地に「サイバー・ドラゴン」が３体以上存在する場合、両方を選択できる。\n\n●デッキから機械族・光属性モンスター１体を手札に加える。\n\n●自分の墓地の機械族・光属性モンスター１体を対象として発動できる。\nその機械族・光属性モンスターをデッキに戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイバー・レヴシステム",h:"さいばー・れう゛しすてむ",e:"Cyber Revsystem",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の手札・墓地から「サイバー・ドラゴン」１体を選んで特殊召喚する。\nこの効果で特殊召喚したモンスターは効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイバーダーク・インパクト！",h:"さいばーだーく・いんぱくと！",e:"Cyberdark Impact!",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の手札・フィールド・墓地から、「サイバー・ダーク・ホーン」「サイバー・ダーク・エッジ」「サイバー・ダーク・キール」を１枚ずつ持ち主のデッキに戻し、「鎧黒竜－サイバー・ダーク・ドラゴン」１体をEXデッキから融合召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイバーロード・フュージョン",h:"さいばーろーど・ふゅーじょん",e:"Cyberload Fusion",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールド及び除外されている自分のモンスターの中から、融合モンスターカードによって決められた融合素材モンスターを持ち主のデッキに戻し、「サイバー・ドラゴン」モンスターを融合素材とするその融合モンスター１体をEXデッキから融合召喚する。\nこのターン、この効果で特殊召喚したモンスター以外の自分のモンスターは攻撃できない。",li:2,lk:[],ta:[],al:[],ocg:true},
  {n:"サイバネット・リチューアル",h:"さいばねっと・りちゅーある",e:"Cynet Ritual",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"サイバース族の儀式モンスターの降臨に必要。\n①：レベルの合計が儀式召喚するモンスターのレベル以上になるように、自分の手札・フィールドのモンスターをリリースし、手札からサイバース族の儀式モンスター１体を儀式召喚する。\n②：自分フィールドにモンスターが存在しない場合、自分の墓地からこのカードと儀式モンスター１体を除外して発動できる。\n自分フィールドに「サイバネット・トークン」（サイバース族・光・星４・攻／守０）２体を特殊召喚する。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイバネティック・ゾーン",h:"さいばねてぃっく・ぞーん",e:"Cybernetic Zone",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する機械族の融合モンスター１体を選択し、発動ターンのエンドフェイズ時までゲームから除外する。\nゲームから除外されたモンスターがフィールド上に戻った時、そのモンスターの攻撃力は倍になる。\n次の自分のターンのスタンバイフェイズ時に、このカードの対象となった機械族の融合モンスター１体を破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイバネティック・フュージョン・サポート",h:"さいばねてぃっく・ふゅーじょん・さぽーと",e:"Cybernetic Fusion Support",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"ライフポイントを半分払って発動できる。\nこのターン、自分が機械族の融合モンスターを融合召喚する場合に１度だけ、その融合モンスターカードによって決められた融合素材モンスターを自分の手札・フィールド上・墓地から選んでゲームから除外し、これらを融合素材にできる。\n「サイバネティック・フュージョン・サポート」は１ターンに１枚しか発動できない。",li:2,lk:[],ta:[],al:[],ocg:true},
  {n:"PSYフレーム・サーキット",h:"さいふれーむ・さーきっと",e:"PSY-Frame Circuit",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：自分フィールドに「PSYフレーム」モンスターが特殊召喚された場合に発動できる。\n自分フィールドの「PSYフレーム」モンスターのみをS素材としてS召喚する。\n②：自分の「PSYフレーム」モンスターが相手モンスターと戦闘を行うダメージステップ開始時に、手札の「PSYフレーム」モンスター１体を捨てて発動できる。\nその戦闘を行う自分のモンスターの攻撃力はターン終了時まで、この効果を発動するために捨てたモンスターの攻撃力分アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"財宝への隠し通路",h:"ざいほうへのかくしつうろ",e:"Secret Pass to the Treasures",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"表側表示で自分フィールド上に存在する攻撃力１０００以下のモンスター１体を選択する。\nこのターン、選択したモンスターは相手プレイヤーを直接攻撃する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"催眠術",h:"さいみんじゅつ",e:"Mesmeric Control",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：次の相手ターン、相手はモンスターの表示形式を変更できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"再融合",h:"さいゆうごう",e:"Re-Fusion",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"①：８００LPを払い、自分の墓地の融合モンスター１体を対象としてこのカードを発動できる。\nそのモンスターを特殊召喚し、このカードを装備する。\nこのカードが破壊された時にそのモンスターは除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"再臨の帝王",h:"さいりんのていおう",e:"Restoration of the Monarchs",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地の、攻撃力２４００／守備力１０００のモンスターまたは攻撃力２８００／守備力１０００のモンスター１体を対象としてこのカードを発動できる。\nそのモンスターを効果を無効にして守備表示で特殊召喚し、このカードを装備する。\nこのカードがフィールドから離れた時にそのモンスターは破壊される。\n②：モンスターをアドバンス召喚する場合、装備モンスターは２体分のリリースにできる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"完全防音壁",h:"さいれんと・うぉーる",e:"Soundproofed",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上にシンクロモンスターが表側表示で存在しない場合、メインフェイズ１の開始時に発動する事ができる。\n次の相手のエンドフェイズ時まで、お互いにシンクロモンスターを特殊召喚する事ができない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイレント・バーニング",h:"さいれんと・ばーにんぐ",e:"Silent Burning",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドに「サイレント・マジシャン」モンスターが存在し、自分の手札が相手より多い場合、自分・相手のバトルフェイズに発動できる。\nお互いのプレイヤーは、それぞれ手札が６枚になるようにデッキからドローする。\nこのカードの発動と効果は無効化されない。\n②：自分メインフェイズに墓地のこのカードを除外して発動できる。\nデッキから「サイレント・マジシャン」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サウザンドエナジー",h:"さうざんどえなじー",e:"Thousand Energy",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する、全てのレベル２通常モンスター（トークンを除く）の元々の攻撃力と守備力は１０００ポイントアップする。\nエンドフェイズ時に自分フィールド上に存在するレベル２通常モンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"千本ナイフ",h:"さうざんどないふ",e:"Thousand Knives",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドに「ブラック・マジシャン」が存在する場合、相手フィールドのモンスター１体を対象として発動できる。\nその相手モンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サクリファイス・ソード",h:"さくりふぁいす・そーど",e:"Sword of Dark Rites",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"闇属性モンスターのみ装備可能。\n装備モンスターの攻撃力は４００ポイントアップする。\n装備モンスターが生け贄に捧げられる事によってこのカードが墓地に送られた場合、このカードを手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"砂塵の結界",h:"さじんのけっかい",e:"Dust Barrier",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在する通常モンスターは相手の魔法カードの効果を受けない。\nこのカードは発動後、２回目の自分のスタンバイフェイズ時に破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サブテラーの激闘",h:"さぶてらーのげきとう",e:"Subterror Cave Clash",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの「サブテラー」モンスターの攻撃力・守備力は、フィールドの裏側表示モンスターの数×５００アップする。\n②：１ターンに１度、自分の「サブテラー」モンスターが相手に戦闘ダメージを与えた時、「サブテラーの激闘」以外の自分の墓地の「サブテラー」カード１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サベージ・コロシアム",h:"さべーじ・ころしあむ",e:"Savage Colosseum",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"フィールド上に存在するモンスターが攻撃を行った場合、そのモンスターのコントローラーはダメージステップ終了時に３００ライフポイント回復する。\nこのカードがフィールド上に存在する限り、攻撃可能なモンスターは攻撃しなければならない。\nエンドフェイズ時、ターンプレイヤーのフィールド上に表側攻撃表示で存在する攻撃宣言をしていないモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サモンオーバー",h:"さもんおーばー",e:"Summon Over",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：モンスターが特殊召喚される度にこのカードにサモンカウンターを１つ置く（最大６つまで）。\n②：サモンカウンターが６つ置かれているこのカードは効果では破壊されない。\n③：このカードにサモンカウンターが６つ置かれている場合に、お互いのプレイヤーは、自分メインフェイズ１開始時に発動できる。\nこのカードを墓地へ送り、相手フィールドの特殊召喚されたモンスターを全て墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"召喚雲",h:"さもんくらうど",e:"Summon Cloud",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分フィールド上にモンスターが存在しない場合、自分の手札または墓地からレベル４以下の「雲魔物」と名のついたモンスター１体を特殊召喚する事ができる。\nこの効果は１ターンに１度だけ自分のメインフェイズに使用する事ができる。\n墓地から特殊召喚した場合はこのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サモンチェーン",h:"さもんちぇーん",e:"Chain Summoning",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：同一チェーン上で複数回同名カードの効果が発動していない場合、そのチェーン３以降に発動できる。\nこのターン自分は通常召喚を３回まで行う事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サモンブレーカー",h:"さもんぶれーかー",e:"Summon Breaker",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカードがフィールド上に存在する限り、ターンプレイヤーがそのターン３回目の召喚・反転召喚・特殊召喚に成功した時、そのターンのエンドフェイズになる。\nこの効果はメインフェイズ１でのみ発動する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サラマンドラ",h:"さらまんどら",e:"Salamandra",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"炎属性モンスターにのみ装備可能。\n①：装備モンスターの攻撃力は７００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サルベージ",h:"さるべーじ",e:"Salvage",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の墓地の攻撃力１５００以下の水属性モンスター２体を対象として発動できる。\nその水属性モンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"惨禍の呪眼",h:"さんかのじゅがん",e:"Evil Eye Confrontation",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドに「呪眼」モンスターが存在する場合、相手フィールドの魔法・罠カード１枚を対象として発動できる。\nそのカードを破壊する。\n自分の魔法＆罠ゾーンに「セレンの呪眼」が存在する場合、この効果で破壊したカードは墓地へは行かず除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"斬機刀ナユタ",h:"ざんきとうなゆた",e:"Mathmech Billionblade Nayuta",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"サイバース族モンスターにのみ装備可能。\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：装備モンスターが相手モンスターと戦闘を行うダメージ計算時に、デッキから「斬機」モンスター１体を墓地へ送って発動できる。\n装備モンスターの攻撃力はターン終了時まで、墓地へ送ったモンスターの攻撃力分アップする。\n②：このカードが魔法＆罠ゾーンから墓地へ送られた場合、「斬機刀ナユタ」以外の自分の墓地の「斬機」カード１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"斬機方程式",h:"ざんきほうていしき",e:"Mathmech Equation",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地の「斬機」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターの攻撃力はターン終了時まで１０００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サンダー・クラッシュ",h:"さんだー・くらっしゅ",e:"Thunder Crash",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に存在する全てのモンスターを破壊する。\n相手ライフに破壊したモンスターの数×３００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サンダー・ショート",h:"さんだー・しょーと",e:"Thunder Short",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"相手フィールド上に存在するモンスター１体につき、相手ライフに４００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"酸の嵐",h:"さんのあらし",e:"Acid Rain",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在する機械族モンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シールドクラッシュ",h:"しーるどくらっしゅ",e:"Shield Crush",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：フィールドの守備表示モンスター１体を対象として発動できる。\nその守備表示モンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シールドバッシュ",h:"しーるどばっしゅ",e:"Bashing Shield",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"通常召喚されたモンスターにのみ装備可能。\n①：装備モンスターの攻撃力は１０００アップする。\n②：装備モンスターの戦闘で発生する自分への戦闘ダメージは０になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジェネレーション・ネクスト",h:"じぇねれーしょん・ねくすと",e:"Generation Next",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分のLPが相手より少ない場合に発動できる。\nお互いのLPの差の数値以下の攻撃力を持つ、「E・HERO」モンスター、「クリボー」モンスター、「N」モンスターの内、いずれか１体を自分のデッキ・墓地から選び、手札に加えるか特殊召喚する。\nこのターン、自分はそのカード及びその同名カードの効果の発動ができない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジェノサイド・ウォー",h:"じぇのさいど・うぉー",e:"After the Struggle",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"メインフェイズ１でのみ発動する事ができる。\nこのターン戦闘によってダメージ計算をした自分・相手モンスターは、エンドステップ時に全て破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジェムナイト・フュージョン",h:"じぇむないと・ふゅーじょん",e:"Gem-Knight Fusion",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の手札・フィールドから、「ジェムナイト」融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。\n②：このカードが墓地に存在する場合、自分の墓地の「ジェムナイト」モンスター１体を除外して発動できる。\n墓地のこのカードを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"紫炎の霞城",h:"しえんのかすみじょう",e:"Shien's Castle of Mist",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"「六武衆」と名のついたモンスターを攻撃する時、攻撃モンスターの攻撃力は５００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シエンの間者",h:"しえんのかんじゃ",e:"Shien's Spy",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在するモンスター１体を選択して発動する。\nこのターンのエンドフェイズ時まで、選択したカードのコントロールを相手に移す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"紫炎の道場",h:"しえんのどうじょう",e:"Shien's Dojo",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：「六武衆」モンスターが召喚・特殊召喚される度に、このカードに武士道カウンターを１つ置く。\n②：武士道カウンターが置かれているこのカードを墓地へ送って発動できる。\nこのカードに置かれていた武士道カウンターの数以下のレベルを持つ、「六武衆」効果モンスターまたは「紫炎」効果モンスター１体をデッキから特殊召喚する。",li:2,lk:[],ta:[],al:[],ocg:true},
  {n:"死角からの一撃",h:"しかくからのいちげき",e:"Blind Spot Strike",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"相手フィールド上に表側守備表示で存在するモンスター１体と、自分フィールド上に表側攻撃表示で存在するモンスター１体を選択して発動する。\n選択した自分のモンスターの攻撃力はエンドフェイズ時まで、選択した相手モンスターの守備力の数値分アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"士気高揚",h:"しきこうよう",e:"Morale Boost",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"装備魔法カードをモンスターに装備させる度に、装備魔法カードのコントローラーは１０００ライフポイント回復する。\nまた、装備魔法カードがフィールド上から離れる度に、装備魔法カードのコントローラーは１０００ポイントダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"時空超越",h:"じくうちょうえつ",e:"Spacetime Transcendence",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の墓地から恐竜族モンスターを２体以上除外して発動できる。\n除外したモンスターのレベルの合計と同じレベルを持つ恐竜族モンスター１体を自分の手札・墓地から選んで特殊召喚する。\nこの効果で特殊召喚したモンスターはこのターン攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"地砕き",h:"じくだき",e:"Smashing Ground",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：相手フィールドの守備力が一番高いモンスター１体を破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"次元の歪み",h:"じげんのひずみ",e:"Dimension Distortion",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地にカードが存在しない場合に発動する事ができる。\n除外された自分のモンスター１体を選択し、自分フィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"次元誘爆",h:"じげんゆうばく",e:"Dimension Explosion",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する融合モンスター１体を融合デッキに戻す事で発動する事ができる。\nお互いにゲームから除外されているモンスターを２体まで選択し、それぞれのフィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"死皇帝の陵墓",h:"しこうていのりょうぼ",e:"Mausoleum of the Emperor",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：お互いのプレイヤーは、自分メインフェイズに以下の効果から１つを選択して発動できる。\n\n●１０００LPを払って発動できる。\n１体のリリースを必要とする手札のモンスター１体の通常召喚を、リリースなしで行う。\n\n●２０００LPを払って発動できる。\n２体のリリースを必要とする手札のモンスター１体の通常召喚を、リリースなしで行う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"地獄門の契約書",h:"じごくもんのけいやくしょ",e:"Dark Contract with the Gate",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\nデッキから「DD」モンスター１体を手札に加える。\n②：自分スタンバイフェイズに発動する。\n自分は１０００ダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"死者転生",h:"ししゃてんせい",e:"Monster Reincarnation",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：手札を１枚捨て、自分の墓地のモンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"死者の生還",h:"ししゃのせいかん",e:"Return of the Doomed",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の手札からモンスターカード１枚を墓地に捨てる。\nこのターン戦闘によって破壊され自分の墓地へ送られたモンスター１体を、ターン終了時に手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"死者への供物",h:"ししゃへのくもつ",e:"Offerings to the Doomed",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：フィールドの表側表示モンスター１体を対象として発動できる。\nその表側表示モンスターを破壊する。\n次の自分ドローフェイズをスキップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"死者への手向け",h:"ししゃへのたむけ",e:"Tribute to The Doomed",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：手札を１枚捨て、フィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"システム・ダウン",h:"しすてむ・だうん",e:"System Down",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"１０００ライフポイントを払う。\n相手フィールド上と相手の墓地の機械族モンスターを全てゲームから除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"漆黒のトバリ",h:"しっこくのとばり",e:"Veil of Darkness",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分のドローフェイズにドローしたカードが闇属性モンスターだった場合、そのカードを相手に見せる事で、そのカードを墓地へ送る。\nその後、自分のデッキからカードを１枚ドローする事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"漆黒の名馬",h:"しっこくのめいば",e:"Legendary Ebon Steed",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「六武衆」と名のついたモンスターにのみ装備可能。\n装備モンスターの攻撃力と守備力は２００ポイントアップする。\n装備モンスターが破壊される場合、代わりにこのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"湿地草原",h:"しっちそうげん",e:"Wetlands",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"フィールド上の水族・水属性・レベル２以下のモンスターの攻撃力は１２００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"死神の大鎌－デスサイス",h:"しにがみのおおがま－ですさいす",e:"Reaper Scythe - Dreadscythe",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「ガーディアン・デスサイス」にのみ装備可能。\n①：装備モンスターの攻撃力は、お互いの墓地のモンスターの数×５００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"忍び寄る闇",h:"しのびよるやみ",e:"Creeping Darkness",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地の闇属性モンスター２体をゲームから除外して発動する。\nデッキから闇属性・レベル４モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"死のマジック・ボックス",h:"しのまじっく・ぼっくす",e:"Mystic Box",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分及び相手フィールドのモンスターを１体ずつ対象として発動できる。\nその相手のモンスターを破壊する。\nその後、その自分のモンスターのコントロールを相手に移す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"地縛神の復活",h:"じばくしんのふっかつ",e:"Earthbound Immortal Revival",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"手札を１枚捨てて発動する。\n自分の墓地に存在する「地縛神」と名のついたモンスター１体とフィールド魔法カード１枚を選択して手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"地縛旋風",h:"じばくせんぷう",e:"Earthbound Whirlwind",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に「地縛神」と名のついたモンスターが存在する場合に発動できる。\n相手フィールド上の魔法・罠カードを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"慈悲深き機械天使",h:"じひぶかききかいてんし",e:"Merciful Machine Angel",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の手札・フィールドの「サイバー・エンジェル」儀式モンスター１体をリリースして発動できる。\n自分はデッキから２枚ドローし、その後手札を１枚選んでデッキの一番下に戻す。\nこのカードの発動後、ターン終了時まで自分は儀式モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"しびれ薬",h:"しびれぐすり",e:"Paralyzing Potion",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"機械族以外のモンスターのみ装備可能。\n装備モンスターは攻撃宣言をする事ができない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"神鳥の来寇",h:"しむるぐのらいこう",e:"Simorgh Onslaught",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：手札から鳥獣族モンスター１体を捨てて発動できる。\nデッキから「シムルグ」モンスター２体を手札に加える（同じ属性は１体まで）。\n②：墓地のこのカードを除外して発動できる。\n手札の鳥獣族モンスター１体を相手に見せる。\nこのターン、そのモンスター及び自分の手札の同名モンスターのレベルを１つ下げる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シャイニング・アブソーブ",h:"しゃいにんぐ・あぶそーぶ",e:"Leeching the Light",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"相手フィールド上に表側表示で存在する光属性モンスター１体を選択して発動する。\n自分フィールド上に表側攻撃表示で存在する全てのモンスターの攻撃力はエンドフェイズ時まで、選択したモンスターの攻撃力分アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シャイン・キャッスル",h:"しゃいん・きゃっする",e:"Shine Palace",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"光属性モンスターにのみ装備可能。\n①：装備モンスターの攻撃力は７００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シャインスパーク",h:"しゃいんすぱーく",e:"Luminous Spark",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在する光属性モンスターの攻撃力は５００ポイントアップし、守備力は４００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"邪気退散",h:"じゃきたいさん",e:"Malice Dispersion",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"手札を１枚捨てる。\nフィールド上に表側表示で存在する永続罠カードを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"弱者の意地",h:"じゃくしゃのいじ",e:"Pride of the Weak",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分の手札が０枚の場合、自分フィールド上に存在するレベル２以下のモンスターが戦闘によって相手モンスターを破壊し墓地へ送った時、自分のデッキからカードを２枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"灼熱の試練",h:"しゃくねつのしれん",e:"Incandescent Ordeal",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「伝説の爆炎使い」の降臨に必要。\nフィールドか手札から、レベルが７以上になるようカードを生け贄に捧げなければならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"灼熱の槍",h:"しゃくねつのやり",e:"Burning Spear",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"炎属性モンスターにのみ装備可能。\n装備モンスターの攻撃力は４００ポイントアップし、守備力は２００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジャスティス・ワールド",h:"じゃすてぃす・わーるど",e:"Realm of Light",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"自分のデッキからカードが墓地へ送られる度に、このカードにシャインカウンターを１つ置く。\nフィールド上の「ライトロード」と名のついたモンスターの攻撃力は、このカードに乗っているシャインカウンターの数×１００ポイントアップする。\nまた、このカードがカードの効果によって破壊される場合、代わりにこのカードに乗っているシャインカウンターを２つ取り除く。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シャッフル・リボーン",h:"しゃっふる・りぼーん",e:"Shuffle Reborn",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドにモンスターが存在しない場合、自分の墓地のモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化され、エンドフェイズに除外される。\n②：墓地のこのカードを除外し、自分フィールドのカード１枚を対象として発動できる。\nそのカードを持ち主のデッキに戻してシャッフルし、その後自分はデッキから１枚ドローする。\nこのターンのエンドフェイズに、自分の手札を１枚除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジャベリンビートルの契約",h:"じゃべりんびーとるのけいやく",e:"Javelin Beetle Pact",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「ジャベリンビートル」の降臨に必要。\n場か手札から、星の数が合計８個以上になるようカードを生け贄に捧げなければならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジャンク・アタック",h:"じゃんく・あたっく",e:"Junk Barrage",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"装備モンスターが戦闘によってモンスターを破壊し墓地へ送った時、破壊したモンスターの攻撃力の半分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジャンク・パペット",h:"じゃんく・ぱぺっと",e:"Junk Puppet",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地の「ギミック・パペット」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジャンクBOX",h:"じゃんくぼっくす",e:"Junk Box",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地からレベル４以下の「D」と名のついたモンスター１体を選択して特殊召喚する。\nこの効果で特殊召喚したモンスターはこのターンのエンドフェイズ時に破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"終焉の地",h:"しゅうえんのち",e:"Demise of the Land",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：相手がモンスターの特殊召喚に成功した時に発動できる。\nデッキからフィールド魔法カード１枚を選んで発動する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"終焉の焔",h:"しゅうえんのほのお",e:"Fires of Doomsday",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカードを発動するターン、自分はこのカードの効果以外ではモンスターを召喚・反転召喚・特殊召喚できない。\n①：自分フィールドに「黒焔トークン」（悪魔族・闇・星１・攻／守０）２体を守備表示で特殊召喚する。\nこのトークンは闇属性以外のモンスターのアドバンス召喚のためにはリリースできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"収縮",h:"しゅうしゅく",e:"Shrink",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの元々の攻撃力はターン終了時まで半分になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シューティング・ソニック",h:"しゅーてぃんぐ・そにっく",e:"Cosmic Flare",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの「スターダスト」Sモンスター１体を対象として発動できる。\nこのターン、その自分のSモンスターが相手モンスターと戦闘を行う場合、ダメージステップ開始時にその相手モンスターを持ち主のデッキに戻す。\n②：自分フィールドの「スターダスト」Sモンスターが効果を発動するために自身をリリースする場合、代わりに墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"執念の剣",h:"しゅうねんのつるぎ",e:"Sword of Deep-Seated",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"装備モンスターの攻撃力・守備力は５００ポイントアップする。\nこのカードが墓地へ送られた時、このカードをデッキの一番上に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"終幕の光",h:"しゅうまくのひかり",e:"Final Light",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：１０００の倍数のLPを払い、払ったLP１０００につき１体、自分の墓地の「ワルキューレ」モンスターを対象として発動できる（同名カードは１枚まで）。\nそのモンスターを特殊召喚する。\nその後、この効果で特殊召喚したモンスターの数まで、相手は自身の墓地から攻撃力２０００以下のモンスターを選んで特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"重力の斧－グラール",h:"じゅうりょくのおの－ぐらーる",e:"Gravity Axe - Grarl",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"装備モンスターの攻撃力は５００ポイントアップする。\nこのカードがフィールド上に存在する限り、相手フィールド上モンスターは表示形式を変更する事ができない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"呪眼領閾－パレイドリア－",h:"じゅがんりょういき－ぱれいどりあ－",e:"Evil Eye Domain - Pareidolia",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：このカードの発動時の効果処理として、デッキから「呪眼」モンスター１体を手札に加える事ができる。\n②：１ターンに１度、自分の魔法＆罠ゾーンに「セレンの呪眼」が存在し、自分の「呪眼」モンスターが攻撃されたダメージ計算時に発動できる。\nその戦闘で発生する自分への戦闘ダメージは相手も受ける。\n③：フィールドゾーンのこのカードが効果で破壊された場合、自分の墓地の「呪眼」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"縮退回路",h:"しゅくたいかいろ",e:"Degenerate Circuit",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードがフィールド上に存在する限り、フィールド上から手札に戻るモンスターカードは手札に戻らずゲームから除外される。\nこのカードのコントローラーは自分のスタンバイフェイズ毎に５００ライフポイントを払う。\nこの時に５００ライフポイント払えない場合はこのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"祝祷の聖歌",h:"しゅくとうのせいか",e:"Hymn of Light",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「竜姫神サフィラ」の降臨に必要。\n①：自分の手札・フィールドから、レベルの合計が６以上になるようにモンスターをリリースし、手札から「竜姫神サフィラ」を儀式召喚する。\n②：自分フィールドの儀式モンスターが戦闘・効果で破壊される場合、代わりに墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"修験の妖社",h:"しゅげんのようしゃ",e:"Yosen Training Grounds",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"「修験の妖社」の②の効果は１ターンに１度しか使用できない。\n①：このカードが魔法＆罠ゾーンに存在する限り、「妖仙獣」モンスターが召喚・特殊召喚される度に、このカードに妖仙カウンターを１つ置く。\n②：このカードの妖仙カウンターを任意の個数取り除いて発動できる。\n取り除いた数によって以下の効果を適用する。\n●１つ：自分フィールドの「妖仙獣」モンスターの攻撃力はターン終了時まで３００アップする。\n●３つ：自分のデッキ・墓地から「妖仙獣」カード１枚を選んで手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"守護神の宝札",h:"しゅごしんのほうさつ",e:"Guarded Treasure",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：手札を５枚捨ててこのカードを発動できる。\n自分はデッキから２枚ドローする。\n②：このカードが魔法＆罠ゾーンに存在する限り、自分ドローフェイズの通常のドローは２枚になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"守護神の矛",h:"しゅごしんのほこ",e:"Phalanx Pike",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"装備モンスターの攻撃力は、お互いの墓地に存在する装備モンスターと同名のカードの数×９００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"呪魂の仮面",h:"じゅこんのかめん",e:"Mask of the Accursed",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"装備モンスターは攻撃する事ができない。\nまた、自分のスタンバイフェイズ毎に、装備モンスターのコントローラーに５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"種子弾丸",h:"しゅしだんがん",e:"Seed Cannon",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"植物族モンスターが召喚・反転召喚・特殊召喚される度に、このカードにプラントカウンターを１つ置く（最大５つまで）。\nフィールド上に存在するこのカードを墓地へ送る事で、このカードに乗っているプラントカウンターの数×５００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"取捨蘇生",h:"しゅしゃそせい",e:"Monster Reborn Reborn",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地のモンスター３体を対象として発動できる。\n相手は対象のモンスターの中から１体を選ぶ。\nそのモンスター１体を自分フィールドに特殊召喚し、残りのモンスターは全て除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シュトロームベルクの金の城",h:"しゅとろーむべるくのきんのしろ",e:"Golden Castle of Stromberg",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカードのコントローラーは自分スタンバイフェイズ毎にデッキの上からカード１０枚を裏側表示で除外する。\n除外できない場合このカードを破壊する。\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\n「シュトロームベルクの金の城」のカード名が記されたモンスター１体をデッキから特殊召喚する。\nこの効果を発動するターン、自分は通常召喚できない。\n②：相手モンスターの攻撃宣言時に発動する。\nその攻撃モンスターを破壊し、その攻撃力の半分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジュラシックワールド",h:"じゅらしっくわーるど",e:"Jurassic World",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在する恐竜族モンスターは攻撃力と守備力が３００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"瞬間融合",h:"しゅんかんゆうごう",e:"Flash Fusion",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドから融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をエクストラデッキから融合召喚する。\nこの効果で融合召喚したモンスターはエンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"昇華する魂",h:"しょうかするたましい",e:"Ascending Soul",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"儀式モンスターが儀式召喚に成功した時、その儀式召喚でリリースした自分の墓地に存在するモンスター１体を選択して手札に加える事ができる。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"召喚制限－エクストラネット",h:"しょうかんせいげん－えくすとらねっと",e:"Extra Net",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：自分または相手がエクストラデッキからモンスターを特殊召喚した場合にこの効果を発動する。\nそのモンスターを特殊召喚したプレイヤーから見て相手は以下の効果を適用できる。\n\n●デッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"召喚の呪詛",h:"しょうかんのじゅそ",e:"Summoning Curse",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードのコントローラーは自分のエンドフェイズ毎に５００ライフポイントを払う。\nまたは、５００ライフポイント払わずにこのカードを破壊する。\nこのカードがフィールド上に存在する場合にモンスターが特殊召喚に成功した時、そのモンスターのコントローラーは手札を１枚選択してゲームから除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"召喚魔術",h:"しょうかんまじゅつ",e:"Invocation",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：融合モンスターカードによって決められた融合素材モンスターを手札から墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。\n「召喚獣」融合モンスターを融合召喚する場合、自分フィールド及び自分・相手の墓地のモンスターを除外して融合素材とする事もできる。\n②：このカードが墓地に存在する場合、除外されている自分の「召喚師アレイスター」１体を対象として発動できる。\n墓地のこのカードをデッキに戻し、対象のモンスターを手札に加える。",li:3,lk:[],ta:[],al:[],ocg:true},
  {n:"召集の聖刻印",h:"しょうしゅうのせいこくいん",e:"Hieratic Seal of Convocation",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：デッキから「聖刻」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"成仏",h:"じょうぶつ",e:"Eternal Rest",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"装備カードを装備したモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"城壁壊しの大槍",h:"じょうへきこわしのおおやり",e:"Ballista of Rampart Smashing",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"装備モンスターが裏側守備表示モンスターを攻撃する場合、装備モンスターの攻撃力は１５００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"招来の対価",h:"しょうらいのたいか",e:"Trial and Tribulation",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカードを発動したターンのエンドフェイズ時、このターン自分が手札・フィールド上からリリースしたトークン以外のモンスターの数によって以下の効果を適用する。\n「招来の対価」は１ターンに１枚しか発動できない。\n\n●１体：デッキからカードを１枚ドローする。\n\n●２体：自分の墓地のモンスター２体を選んで手札に加える。\n\n●３体以上：フィールド上に表側表示で存在するカードを３枚まで選んで破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ショット・ガン・シャッフル",h:"しょっと・がん・しゃっふる",e:"Card Shuffle",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"３００ライフポイントを払う。\n自分、または相手のデッキをシャッフルする。\nこの効果は１ターンに１度しか使えない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"所有者の刻印",h:"しょゆうしゃのこくいん",e:"Owner's Seal",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：フィールドの全てのモンスターのコントロールは元々の持ち主に戻る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"不知火流 伝承の陣",h:"しらぬいりゅう でんしょうのじん",e:"Shiranui Style Solemnity",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：このカードは魔法＆罠ゾーンに存在する限り、カード名を「不知火流 転生の陣」として扱う。\n②：１ターンに１度、以下の効果から１つを選択して発動できる。\n\n●自分の墓地からアンデット族モンスター１体を除外して発動できる。\nこのターン、自分のアンデット族モンスターの召喚・特殊召喚は無効化されない。\n\n●自分フィールドのアンデット族モンスター１体を対象として発動できる。\nそのモンスターを除外する。\nその後、デッキから守備力０のアンデット族モンスター１体を墓地へ送る事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"不知火流 転生の陣",h:"しらぬいりゅう　てんせいのじん",e:"Shiranui Style Synthesis",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"「不知火流 転生の陣」は１ターンに１枚しか発動できない。\n①：１ターンに１度、自分フィールドにモンスターが存在しない場合、手札を１枚墓地へ送り、以下の効果から１つを選択して発動できる。\n\n●自分の墓地の守備力０のアンデット族モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n\n●除外されている自分の守備力０のアンデット族モンスター１体を対象として発動できる。\nそのモンスターを墓地に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"自律行動ユニット",h:"じりつこうどうゆにっと",e:"Autonomous Action Unit",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"①：１５００LPを払い、相手の墓地のモンスター１体を対象としてこのカードを発動できる。\nそのモンスターを自分フィールドに攻撃表示で特殊召喚し、このカードを装備する。\nこのカードがフィールドから離れた時にそのモンスターは破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"磁力の指輪",h:"じりょくのゆびわ",e:"Ring of Magnetism",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"自分フィールド上に存在するモンスターにのみ装備可能。\n装備モンスターの攻撃力・守備力は５００ポイントダウンする。\n相手はこのカードの装備モンスターしか攻撃する事ができない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"白のヴェール",h:"しろのう゛ぇーる",e:"White Veil",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"①：装備モンスターが戦闘を行う場合、相手はダメージステップ終了時まで魔法・罠カードを発動できない。\n②：装備モンスターが戦闘を行う攻撃宣言時に発動する。\n相手フィールドの表側表示の魔法・罠カードの効果はダメージステップ終了時まで無効化される。\n③：装備モンスターが戦闘で相手モンスターを破壊した時に発動できる。\n相手フィールドの魔法・罠カードを全て破壊する。\n④：魔法＆罠ゾーンの表側表示のこのカードがフィールドから離れた時に自分は３０００ダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"地割れ",h:"じわれ",e:"Fissure",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：相手フィールドの攻撃力が一番低いモンスター１体を破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"Sin Cross",h:"しん くろす",e:"Malefic Divide",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分の墓地の「Sin」モンスター１体を対象として発動できる。\nそのモンスターを召喚条件を無視して特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化され、エンドフェイズに除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"Sin Selector",h:"しん せれくたー",e:"Malefic Selector",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地から「Sin」カード２枚を除外して発動できる。\n除外したカードとカード名が異なる「Sin Selector」以外の「Sin」カード２枚をデッキから手札に加える（同名カードは１枚まで）。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"Sin Territory",h:"しん てりとりー",e:"Malefic Territory",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：このカードの発動時の効果処理として、デッキから「Sin World」１枚を発動できる。\nこの効果で発動したカードがフィールドゾーンに存在する限り、お互いにフィールドゾーンのカードを効果の対象にできない。\n②：「Sin」モンスターの持つ、「「Sin」モンスターはフィールドに１体しか表側表示で存在できない」効果は、「「Sin」モンスターは１種類につきフィールドに１体しか表側表示で存在できない」として適用される。\n③：バトルフェイズの間だけフィールドの「Sin」モンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"Sin World",h:"しん わーるど",e:"Malefic World",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：自分ドローフェイズに通常のドローを行う代わりに発動できる。\nデッキから「Sin」カード３枚を相手に見せ、相手はその中からランダムに１枚選ぶ。\nそのカード１枚を自分の手札に加え、残りのカードはデッキに戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"深淵の指名者",h:"しんえんのしめいしゃ",e:"Abyssal Designator",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"１０００ライフポイントを払う。\n種族と属性を１つずつ宣言する。\n相手は宣言された種族・属性を両方とも満たすモンスターを手札またはデッキから１枚墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"深淵の宣告者",h:"しんえんのせんこくしゃ",e:"Herald of the Abyss",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：１５００LPを払い、種族と属性を１つずつ宣言して発動できる。\n宣言した種族・属性のモンスターが相手フィールドに存在する場合、相手はそのモンスター１体を墓地へ送らなければならない。\nこのターン、相手はそのモンスター及びその同名モンスターのモンスター効果を発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"真炎の爆発",h:"しんえんのばくはつ",e:"Rekindling",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の墓地から守備力２００の炎属性モンスターを可能な限り特殊召喚する。\nこの効果で特殊召喚したモンスターは、このターンのエンドフェイズに除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"進化する人類",h:"しんかするじんるい",e:"Unstable Evolution",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"自分のライフポイントが相手より少ない場合、装備モンスターの元々の攻撃力は２４００になる。\n自分のライフポイントが相手より多い場合、装備モンスターの元々の攻撃力は１０００になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"進化の奇跡",h:"しんかのきせき",e:"Evo-Miracle",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"「エヴォルド」と名のついたモンスターの効果によって特殊召喚されたモンスター１体を選択して発動する。\nこのターン、選択したモンスターは戦闘及びカードの効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"進化の宿命",h:"しんかのしゅくめい",e:"Evo-Karma",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分の「エヴォルド」と名のついたモンスターの効果によってモンスターが特殊召喚に成功した時、相手は魔法・罠・効果モンスターの効果を発動する事ができない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"進化の代償",h:"しんかのだいしょう",e:"Evo-Price",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"「エヴォルド」と名のついたモンスターの効果によってモンスターが特殊召喚された場合、フィールド上のカード１枚を選択して破壊できる。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"心眼の祭殿",h:"しんがんのさいでん",e:"Temple of the Mind's Eye",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：このカードがフィールドゾーンに存在する限り、自分または相手が戦闘ダメージを受ける場合、その数値は１０００になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"心眼の鉾",h:"しんがんのほこ",e:"Rod of the Mind's Eye",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"装備モンスターが相手プレイヤーに戦闘ダメージを与える場合、そのダメージ数値は１０００ポイントになる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シンクロ・ギフト",h:"しんくろ・ぎふと",e:"Synchro Gift",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在するシンクロモンスター１体とシンクロモンスター以外のモンスター１体を選択して発動する。\nこのターンのエンドフェイズ時まで、選択したシンクロモンスターの攻撃力を０にし、その元々の攻撃力分もう１体のモンスターの攻撃力をアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シンクロ・チェイス",h:"しんくろ・ちぇいす",e:"Synchro Chase",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分が「ウォリアー」、「シンクロン」、「スターダスト」SモンスターのS召喚に成功した場合、そのS召喚の素材とした自分の墓地のモンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\n②：このカードが魔法＆罠ゾーンに存在する限り、元々のカード名に「ウォリアー」、「シンクロン」、「スターダスト」の内、いずれかを含む自分のSモンスターの効果の発動に対して相手は魔法・罠・モンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シンクロ・チェンジ",h:"しんくろ・ちぇんじ",e:"Synchro Change",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在するシンクロモンスター１体をゲームから除外して発動する。\nそのモンスターと同じレベルのシンクロモンスター１体をエクストラデッキから特殊召喚する。\nこの効果で特殊召喚した効果モンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シンクロ・ヒーロー",h:"しんくろ・ひーろー",e:"Synchro Boost",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"①：装備モンスターのレベルは１つ上がり、攻撃力は５００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シンクロキャンセル",h:"しんくろきゃんせる",e:"De-Synchro",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：フィールドのSモンスター１体を対象として発動できる。\nそのSモンスターを持ち主のEXデッキに戻す。\nその後、EXデッキに戻したそのモンスターのS召喚に使用したS素材モンスター一組が自分の墓地に揃っていれば、その一組を自分フィールドに特殊召喚できる。",li:1,lk:[],ta:[],al:[],ocg:true},
  {n:"進撃の帝王",h:"しんげきのていおう",e:"March of the Monarchs",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在する限り、自分フィールドのアドバンス召喚したモンスターは効果の対象にならず、効果では破壊されない。\n②：このカードが魔法＆罠ゾーンに存在する限り、自分はエクストラデッキからモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"神剣－フェニックスブレード",h:"しんけん－ふぇにっくすぶれーど",e:"Divine Sword - Phoenix Blade",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"戦士族モンスターにのみ装備可能。\n装備モンスターの攻撃力は３００ポイントアップする。\n自分のメインフェイズ時、自分の墓地に存在する戦士族モンスター２体をゲームから除外する事で、このカードを自分の墓地から手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"侵食細胞「A」",h:"しんしょくさいぼう「えー」",e:"Corruption Cell \"A\"",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"相手フィールド上に表側表示で存在するモンスター１体を選択して発動できる。\n選択した相手モンスターにAカウンターを１つ置く。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"神秘の中華なべ",h:"しんぴのちゅうかなべ",e:"Mystik Wok",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上のモンスター１体を生け贄に捧げる。\n生け贄に捧げたモンスターの攻撃力か守備力を選択し、その数値だけ自分のライフポイントを回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"森羅の霊峰",h:"しんらのれいほう",e:"Mount Sylvania",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"自分のメインフェイズ時に手札または自分フィールド上に表側表示で存在する、植物族モンスター１体を墓地へ送って発動できる。\nデッキから「森羅」と名のついたカード１枚を選んでデッキの一番上に置く。\n「森羅の霊峰」のこの効果は１ターンに１度しか使用できない。\nまた、相手のエンドフェイズ時に１度だけ、自分のデッキの一番上のカードをめくる事ができる。\nめくったカードが植物族モンスターだった場合、そのカードを墓地へ送る。\n違った場合、そのカードをデッキの一番上または一番下に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"侵略の一手",h:"しんりゃくのいって",e:"First Step Towards Infestation",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上のアドバンス召喚した「インヴェルズ」と名のついたモンスター１体を持ち主の手札に戻して発動できる。\nデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"侵略の汎発感染",h:"しんりゃくのはんぱつかんせん",e:"Infestation Pandemic",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの全ての「ヴェルズ」モンスターは、ターン終了時までこのカード以外の魔法・罠カードの効果を受けない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"水神の護符",h:"すいじんのごふ",e:"Sea Lord's Amulet",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードがフィールド上に存在する限り、自分フィールド上の水属性モンスターは相手のカードの効果では破壊されない。\n発動後３回目の相手のエンドフェイズ時にこのカードは墓地へ送られる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スーペルヴィス",h:"すーぺるう゛ぃす",e:"Supervise",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"デュアルモンスターにのみ装備可能。\n①：装備モンスターはもう１度召喚された状態として扱う。\n②：表側表示のこのカードがフィールドから墓地へ送られた場合、自分の墓地の通常モンスター１体を対象として発動する。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スカルライダーの復活",h:"すかるらいだーのふっかつ",e:"Revival of Dokurorider",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「スカルライダー」の降臨に必要。\n場か手札から、星の数が合計６個以上になるようカードを生け贄に捧げなければならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スクラップ・エリア",h:"すくらっぷ・えりあ",e:"Scrapyard",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分のデッキから「スクラップ」と名のついたチューナー１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スクラップ・オイルゾーン",h:"すくらっぷ・おいるぞーん",e:"Scrap Lube",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分の墓地に存在する「スクラップ」と名のついたモンスター１体を選択して発動する。\n選択したモンスターを墓地から特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。\nこのカードがフィールド上に存在しなくなった時、そのモンスターを破壊する。\nそのモンスターがフィールド上から離れた時このカードを破壊する。\nこのカードを発動するターン、自分はバトルフェイズを行う事ができない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スクラップ・スコール",h:"すくらっぷ・すこーる",e:"Scrapstorm",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「スクラップ」と名のついたモンスター１体を選択して発動する。\n自分のデッキから「スクラップ」と名のついたモンスター１体を墓地へ送り、カードを１枚ドローする。\nその後、選択したモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スクラップ・ファクトリー",h:"すくらっぷ・ふぁくとりー",e:"Scrap Factory",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：フィールドの「スクラップ」モンスターの攻撃力・守備力は２００アップする。\n②：フィールドの表側表示の「スクラップ」モンスターが効果で破壊され墓地へ送られた時に発動できる。\n自分はデッキから「スクラップ」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スクラップ・フィスト",h:"すくらっぷ・ふぃすと",e:"Scrap Fist",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの「ジャンク・ウォリアー」１体を対象として発動できる。\nこのターン、その自分のモンスターが相手モンスターと戦闘を行う場合、以下の効果を適用する。\n\n●相手はダメージステップ終了時まで魔法・罠・モンスターの効果を発動できない。\n\n●対象のモンスターが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ相手に戦闘ダメージを与える。\n\n●相手が受ける戦闘ダメージは倍になる。\n\n●対象のモンスターは戦闘では破壊されない。\n\n●戦闘を行った相手モンスターはダメージステップ終了時に破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スクラップ・ポリッシュ",h:"すくらっぷ・ぽりっしゅ",e:"Scrap Sheen",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「スクラップ」と名のついたモンスター１体を選択して破壊する。\n自分フィールド上に表側表示で存在する全ての「スクラップ」と名のついたモンスターの攻撃力は、このターンのエンドフェイズ時まで１０００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"緊急発進",h:"すくらんぶる",e:"Scramble!! Scramble!!",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"相手フィールド上のモンスターの数が、トークン以外の自分フィールド上のモンスターの数より多い場合、自分フィールド上の「幻獣機トークン」を任意の数だけリリースして発動できる。\n「幻獣機」と名のついたモンスターを、リリースしたトークンの数だけデッキから特殊召喚する。\nこの効果で特殊召喚したモンスターはエンドフェイズ時に持ち主のデッキに戻る。\n「緊急発進」は１ターンに１枚しか発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スター・ブラスト",h:"すたー・ぶらすと",e:"Star Blast",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"５００の倍数のライフポイントを払って発動できる。\n自分の手札または自分フィールド上に表側表示で存在するモンスター１体を選び、そのモンスターのレベルをエンドフェイズ時まで、払ったライフポイント５００ポイントにつき１つ下げる。",li:1,lk:[],ta:[],al:[],ocg:true},
  {n:"スタンド・イン",h:"すたんど・いん",e:"Stand In",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドのモンスター１体をリリースし、そのモンスターと元々の種族・属性が同じとなる相手の墓地のモンスター１体を対象として発動できる。\nそのモンスターを自分フィールドに特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スタンピング・クラッシュ",h:"すたんぴんぐ・くらっしゅ",e:"Stamping Destruction",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上にドラゴン族モンスターが表側表示で存在する場合のみ発動する事ができる。\nフィールド上に存在する魔法・罠カード１枚を選択して破壊し、そのコントローラーに５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ストイック・チャレンジ",h:"すといっく・ちゃれんじ",e:"Stoic Challenge",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"エクシーズ素材を持っているエクシーズモンスターにのみ装備可能。\n装備モンスターの攻撃力は自分フィールド上のエクシーズ素材の数×６００ポイントアップし、相手モンスターとの戦闘によって相手ライフに与える戦闘ダメージは倍になる。\nまた、装備モンスターは効果を発動できない。\nこのカードは相手のエンドフェイズ時に墓地へ送られる。\nこのカードがフィールド上から離れた時、装備モンスターを破壊する。\n「ストイック・チャレンジ」は自分フィールド上に１枚しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スネーク・レイン",h:"すねーく・れいん",e:"Snake Rain",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：手札を１枚捨てて発動できる。\nデッキから爬虫類族モンスター４体を墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"化合電界",h:"すぱーく・ふぃーるど",e:"Catalyst Field",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：このカードがフィールドゾーンに存在する限り、自分はレベル５以上のデュアルモンスターを召喚する場合に必要なリリースをなくす事ができる。\nこの効果は１ターンに１度しか適用できない。\n②：このカードがフィールドゾーンに存在する限り、自分は通常召喚に加えて１度だけ、自分メインフェイズにデュアルモンスター１体を召喚できる。\n③：１ターンに１度、相手フィールドのカード１枚を対象として発動できる。\n自分フィールドのもう１度召喚された状態のデュアルモンスター１体を相手エンドフェイズまで除外し、対象のカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スパークガン",h:"すぱーくがん",e:"Spark Blaster",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「E・HERO スパークマン」にのみ装備可能。\n自分のターンのメインフェイズ時に表側表示モンスター１体の表示形式を変更する事ができる。\nこの効果を３回使用した後、このカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"螺旋槍殺",h:"すぱいらる・しぇいばー",e:"Spiral Spear Strike",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：自分の「暗黒騎士ガイア」「疾風の暗黒騎士ガイア」「竜騎士ガイア」が守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ相手に戦闘ダメージを与える。\n②：このカードの①の効果の適用によって「竜騎士ガイア」が相手に戦闘ダメージを与えた場合に発動する。\n自分はデッキから２枚ドローし、その後手札を１枚選んで捨てる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スピードリバース",h:"すぴーどりばーす",e:"Speed Recovery",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の墓地の「スピードロイド」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\n②：墓地のこのカードを除外し、自分の墓地の「スピードロイド」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"神聖なる森",h:"すぴりちゅある・ふぉれすと",e:"Spiritual Forest",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分フィールド上の植物族・獣族・獣戦士族モンスターは、１ターンに１度だけ戦闘では破壊されない。\nこの効果は１ターンに１度しか適用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スピリット変換装置",h:"すぴりっとへんかんそうち",e:"Spirit Converter",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"１ターンに１度、自分フィールド上のエクシーズモンスター１体を選択して発動できる。\n自分フィールド上の光属性・レベル４モンスター１体を、選択したエクシーズモンスターの下に重ねてエクシーズ素材とする。\nまた、このカードがフィールド上から墓地へ送られた時、自分の墓地の雷族・光属性・レベル４の同名モンスター２体を選択して手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スプール・コード",h:"すぷーる・こーど",e:"Spool Code",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分の墓地のサイバース族モンスターが３体以上の場合、相手モンスターの直接攻撃宣言時に発動できる。\nその攻撃を無効にする。\nその後、自分フィールドに「スプールトークン」（サイバース族・光・星１・攻／守０）を３体まで守備表示で特殊召喚できる。\nこのトークンはアドバンス召喚のためにはリリースできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"至高の木の実",h:"すぷれましー・べりー",e:"Supremacy Berry",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカードの発動時に、自分のライフポイントが相手より下の場合、自分は２０００ライフポイント回復する。\n自分のライフポイントが相手より上の場合、自分は１０００ポイントダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スペーシア・ギフト",h:"すぺーしあ・ぎふと",e:"Space Gift",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「N」と名のついたモンスター１種類につき、自分のデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スペース・サイクロン",h:"すぺーす・さいくろん",e:"Space Cyclone",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"フィールド上に存在するエクシーズ素材を１つ取り除く。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スペシャル・デュアル・サモン",h:"すぺしゃる・でゅある・さもん",e:"Super Double Summon",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在するデュアルモンスター１体を選択し、再度召喚した状態にする。\nこのターンのエンドフェイズ時、選択したデュアルモンスターを手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スペシャルハリケーン",h:"すぺしゃるはりけーん",e:"Special Hurricane",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の手札を１枚捨てる。\nフィールド上に存在する、特殊召喚されたモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スマイル・ワールド",h:"すまいる・わーるど",e:"Smile World",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：フィールドの全てのモンスターの攻撃力はターン終了時まで、フィールドのモンスターの数×１００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スライム増殖炉",h:"すらいむぞうしょくろ",e:"Jam Breeding Machine",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分のスタンバイフェイズ毎に、自分フィールド上に「スライムモンスタートークン」（水族・水・星１・攻／守５００）１体を攻撃表示で特殊召喚する。\nこのカードがフィールド上に存在する限り、自分は「スライムモンスタートークン」以外のモンスターを召喚・反転召喚・特殊召喚する事はできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スワローズ・ネスト",h:"すわろーず・ねすと",e:"Swallow's Nest",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの表側表示の鳥獣族モンスター１体をリリースして発動できる。\nリリースしたモンスターと同じレベルの鳥獣族モンスター１体をデッキから特殊召喚する。",li:2,lk:[],ta:[],al:[],ocg:true},
  {n:"聖域の歌声",h:"せいいきのうたごえ",e:"Chorus of Sanctuary",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"フィールド上に表側守備表示で存在する全てのモンスターの守備力は５００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"星遺物との邂逅",h:"せいいぶつとのかいこう",e:"World Legacy Discovery",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：フィールドの「星杯」モンスターの攻撃力・守備力は３００アップする。\n②：１ターンに１度、自分フィールドの表側表示の「星杯」モンスターが相手の効果でフィールドから離れた場合、または戦闘で破壊された場合、自分の墓地の「星杯」モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"星遺物を巡る戦い",h:"せいいぶつをめぐるたたかい",e:"World Legacy Clash",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの表側表示モンスター１体をエンドフェイズまで除外し、相手フィールドの表側表示モンスター１体を対象として発動できる。\nその相手モンスターの攻撃力・守備力は、このカードを発動するために除外したモンスターのそれぞれの元々の数値分ダウンする。",li:2,lk:[],ta:[],al:[],ocg:true},
  {n:"静冠の呪眼",h:"せいかんのじゅがん",e:"Evil Eye Repose",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：このカードは自分の墓地から「呪眼」カード１枚を除外して発動する事もできる。\nその場合、自分はデッキから１枚ドローする。\n②：１ターンに１度、「セレンの呪眼」を装備した自分の「呪眼」モンスターが相手モンスターを攻撃したダメージ計算後に発動できる。\nその相手モンスターを除外する。\n③：魔法＆罠ゾーンのこのカードが効果で破壊された場合、除外されている自分の「呪眼」カードを３枚まで対象として発動できる。\nそのカードを墓地に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"聖騎士伝説の終幕",h:"せいきしでんせつのしゅうまく",e:"Last Chapter of the Noble Knights",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「聖騎士伝説の終幕」は１ターンに１枚しか発動できない。\n①：相手フィールドにモンスターが存在し、自分フィールドにモンスターが存在しない場合、自分の墓地の、「聖騎士」モンスター１体とそのモンスターが装備可能な「聖剣」装備魔法カード１枚を対象として発動できる。\nそのモンスターを特殊召喚し、その装備魔法カードを正しい対象となるそのモンスターに装備する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"セイクリッド・テンペスト",h:"せいくりっど・てんぺすと",e:"Constellar Tempest",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"「セイクリッド・テンペスト」の①の効果はデュエル中に１度しか使用できない。\n①：自分エンドフェイズに自分フィールドのX素材を持った「セイクリッド」Xモンスター２体を対象として発動できる。\nそのXモンスター２体のX素材を全て取り除き、相手LPを半分にする。\n②：自分スタンバイフェイズに自分フィールドの「セイクリッド」Xモンスター１体と自分の墓地の「セイクリッド」モンスター１体を対象として発動できる。\nその墓地のモンスターを、そのXモンスターの下に重ねてX素材とする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"神星なる領域",h:"せいくりっど・べると",e:"Constellar Belt",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードがフィールド上に存在する限り、光属性モンスターの効果の発動は無効化されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"セイクリッドの星痕",h:"せいくりっどのせいこん",e:"Constellar Star Chart",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分フィールド上に「セイクリッド」と名のついたエクシーズモンスターが特殊召喚された時、デッキからカードを１枚ドローできる。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"セイクリッドの超新生",h:"せいくりっどのちょうしんせい",e:"Constellar Star Cradle",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地の「セイクリッド」と名のついたモンスター２体を選択して手札に加える。\nこのカードを発動するターン、自分はバトルフェイズを行えない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"聖剣アロンダイト",h:"せいけんあろんだいと",e:"Noble Arms - Arfeudutyr",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"戦士族モンスターにのみ装備可能。\nこのカード名の③の効果は１ターンに１度しか使用できない。\n①：「聖剣アロンダイト」は自分フィールドに１枚しか表側表示で存在できない。\n②：１ターンに１度、相手フィールドにセットされたカード１枚を対象として発動できる。\n装備モンスターの攻撃力を５００ダウンし、そのセットされた相手のカードを破壊する。\n③：フィールドの表側表示のこのカードが破壊され墓地へ送られた場合、自分フィールドの戦士族の「聖騎士」モンスター１体を対象として発動できる。\nその自分のモンスターにこのカードを装備する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"聖剣ガラティーン",h:"せいけんがらてぃーん",e:"Noble Arms - Gallatin",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"戦士族モンスターにのみ装備可能。\nこのカード名の③の効果は１ターンに１度しか使用できない。\n①：「聖剣ガラティーン」は自分フィールドに１枚しか表側表示で存在できない。\n②：装備モンスターの攻撃力は１０００アップし、自分スタンバイフェイズ毎に２００ダウンする。\n③：フィールドの表側表示のこのカードが破壊され墓地へ送られた場合、自分フィールドの戦士族の「聖騎士」モンスター１体を対象として発動できる。\nその自分の戦士族の「聖騎士」モンスターにこのカードを装備する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"聖剣カリバーン",h:"せいけんかりばーん",e:"Noble Arms - Caliburn",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"戦士族モンスターにのみ装備可能。\n装備モンスターの攻撃力は５００ポイントアップする。\nまた、１ターンに１度、自分は５００ライフポイント回復できる。\nフィールド上に表側表示で存在するこのカードが破壊され墓地へ送られた場合、自分フィールド上の「聖騎士」と名のついた戦士族モンスター１体を選択してこのカードを装備できる。\n「聖剣カリバーン」のこの効果は１ターンに１度しか使用できない。\nまた、「聖剣カリバーン」は自分フィールド上に１枚しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"聖光の夢魔鏡",h:"せいこうのゆめまきょう",e:"Dream Mirror of Joy",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドに光属性の「夢魔鏡」モンスターが存在する限り、自分フィールドの「夢魔鏡」モンスターの内、レベルが一番高いモンスター以外の「夢魔鏡」モンスターは、攻撃対象にならず、相手の効果の対象にもならない。\n②：自分・相手のエンドフェイズに、自分のフィールドゾーンのこのカードを除外して発動できる。\n手札・デッキから「闇黒の夢魔鏡」１枚を発動する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"静寂のロッド－ケースト",h:"せいじゃくのろっど－けーすと",e:"Rod of Silence - Kay'est",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"装備モンスターの守備力は５００ポイントアップする。\n装備モンスターを対象にするこのカード以外の魔法カードの効果を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"生者の書－禁断の呪術－",h:"せいじゃのしょ－きんだんのじゅじゅつ－",e:"Book of Life",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の墓地のアンデット族モンスター１体と相手の墓地のモンスター１体を対象として発動できる。\nその自分のアンデット族モンスターを特殊召喚する。\nその相手のモンスターを除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"精神統一",h:"せいしんとういつ",e:"Gather Your Mind",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"デッキから「精神統一」を１枚手札に加える。\nこのカードは１ターンに１枚しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"精神同調波",h:"せいしんどうちょうは",e:"Synchro Blast Wave",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上にシンクロモンスターが表側表示で存在する場合のみ発動する事ができる。\n相手フィールド上に存在するモンスター１体を破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"聖占術の儀式",h:"せいせんじゅつのぎしき",e:"Prediction Ritual",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「聖占術姫タロットレイ」の降臨に必要。\n①：自分の手札・フィールドから、レベルの合計が９以上になるようにモンスターをリリースし、手札から「聖占術姫タロットレイ」を儀式召喚する。\n②：自分メインフェイズに墓地のこのカードを除外して発動できる。\nデッキから「占術姫」モンスター１体を手札に加える。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"セイバー・ヴォールト",h:"せいばー・う゛ぉーると",e:"Saber Vault",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在する「X－セイバー」と名のついたモンスターは、攻撃力がそのレベル×１００ポイントアップし、守備力がそのレベル×１００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"セイバー・スラッシュ",h:"せいばー・すらっしゅ",e:"Saber Slash",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側攻撃表示で存在する「X－セイバー」と名のついたモンスターの数だけ、フィールド上に表側表示で存在するカードを選んで破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"精霊の祝福",h:"せいれいのしゅくふく",e:"Sprite's Blessing",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"光属性の儀式モンスターの降臨に必要。\n①：レベルの合計が儀式召喚するモンスターと同じになるように、自分の手札・フィールドのモンスターをリリースし、手札から光属性の儀式モンスター１体を儀式召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"世界樹",h:"せかいじゅ",e:"The World Tree",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"フィールド上の植物族モンスターが破壊される度に、このカードにフラワーカウンターを１つ置く。\nまた、このカードに乗っているフラワーカウンターを任意の個数取り除いて以下の効果を発動できる。\n●１つ：フィールド上の植物族モンスター１体を選択し、その攻撃力・守備力をエンドフェイズ時まで４００ポイントアップする。\n●２つ：フィールド上のカード１枚を選択して破壊する。\n●３つ：自分の墓地の植物族モンスター１体を選択して特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"セカンド・チャンス",h:"せかんど・ちゃんす",e:"Second Coin Toss",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか適用できない。\n①：自分がコイントスを行う効果を適用する際に、コイントスを最初からやり直す事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"絶対魔法禁止区域",h:"ぜったいまほうきんしくいき",e:"Non-Spellcasting Area",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在する全ての効果モンスター以外のモンスターは魔法の効果を受けない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"Z－ONE",h:"ぜっと・わん",e:"Z-ONE",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：セットされたこのカードが破壊され墓地へ送られた場合、自分の墓地の永続魔法カードまたはフィールド魔法カード１枚を対象として発動する。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"セフェルの魔導書",h:"せふぇるのまどうしょ",e:"Spellbook of the Master",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドに魔法使い族モンスターが存在する場合、このカード以外の手札の「魔導書」カード１枚を相手に見せ、「セフェルの魔導書」以外の自分の墓地の「魔導書」通常魔法カード１枚を対象として発動できる。\nこのカードの効果は、その通常魔法カード発動時の効果と同じになる。",li:2,lk:[],ta:[],al:[],ocg:true},
  {n:"7",h:"せぶん",e:"7",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"「７」が自分フィールド上に表側表示で３枚揃った時、自分はデッキからカードを３枚ドローする。\nその後全ての「７」を破壊する。\nこのカードがフィールドから墓地へ送られた時、自分は７００ライフポイント回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"7カード",h:"せぶんかーど",e:"7 Completed",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"機械族モンスターのみ装備可能。\n装備モンスターの攻撃力または守備力は７００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"セベクの祝福",h:"せべくのしゅくふく",e:"Sebek's Blessing",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分のモンスターが直接攻撃によって相手に戦闘ダメージを与えた時に発動できる。\nその数値分だけ自分のLPを回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゼラの儀式",h:"ぜらのぎしき",e:"Zera Ritual",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「ゼラ」の降臨に必要。\n場か手札から、星の数が合計８個以上になるようカードを生け贄に捧げなければならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"セレンの呪眼",h:"せれんのじゅがん",e:"Evil Eye of Selene",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「呪眼」モンスターにのみ装備可能。\nこのカード名の③の効果は１ターンに１度しか使用できない。\n①：装備モンスターは戦闘及び相手の効果では破壊されず、相手の効果の対象にならない。\n②：自分が装備モンスターの効果またはこのカード以外の「呪眼」魔法・罠カードを発動する度に発動する。\n装備モンスターの攻撃力は５００アップし、自分は５００LPを失う。\n③：このカードが墓地に存在する場合、１０００LPを払い、自分の墓地から「セレンの呪眼」以外の「呪眼」魔法・罠カード１枚を除外して発動できる。\nこのカードを自分フィールドにセットする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"善悪の彼岸",h:"ぜんあくのひがん",e:"Good & Evil in the Burning Abyss",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「彼岸の鬼神 ヘルレイカー」の降臨に必要。\n「善悪の彼岸」の②の効果は１ターンに１度しか使用できない。\n①：自分の手札・フィールドから、レベルの合計が６以上になるようにモンスターをリリースし、手札から「彼岸の鬼神 ヘルレイカー」を儀式召喚する。\n②：自分メインフェイズに墓地のこのカードを除外し、手札から「彼岸」モンスター１体を墓地へ送って発動できる。\nデッキから「彼岸」カード１枚を手札に加える。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"戦火の残滓",h:"せんかのざんし",e:"After the Storm",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"お互いのフィールド上に、他のカードが存在しない場合に発動できる。\n自分の墓地から水属性または風属性のモンスター１体を選択して手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"閃光の双剣－トライス",h:"せんこうのそうけん－とらいす",e:"Twin Swords of Flashing Light - Tryce",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"手札のカード１枚を墓地に送って装備する。\n装備モンスターの攻撃力は５００ポイントダウンする。\n装備モンスターはバトルフェイズ中に２回攻撃をする事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"戦士の生還",h:"せんしのせいかん",e:"The Warrior Returning Alive",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の墓地の戦士族モンスター１体を対象として発動できる。\nその戦士族モンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"戦士抹殺",h:"せんしまっさつ",e:"Warrior Elimination",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在する戦士族モンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"前線基地",h:"ぜんせんきち",e:"Frontline Base",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"１ターンに１度、自分のメインフェイズ時に手札からレベル４以下のユニオンモンスター１体を特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"戦線復活の代償",h:"せんせんふっかつのだいしょう",e:"Symbols of Duty",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"自分フィールド上の通常モンスター１体を墓地へ送り、自分または相手の墓地のモンスター１体を選択して発動できる。\n選択したモンスターを自分フィールド上に特殊召喚し、このカードを装備する。\nこのカードがフィールド上から離れた時、装備モンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"閃刀機－イーグルブースター",h:"せんとうき－いーぐるぶーすたー",e:"Sky Striker Mecha - Eagle Booster",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分のメインモンスターゾーンにモンスターが存在しない場合、フィールドの表側表示モンスター１体を対象として発動できる。\nこのターン、その表側表示モンスターは自身以外のカードの効果を受けない。\n自分の墓地に魔法カードが３枚以上存在する場合、さらにこのターン、そのモンスターは戦闘では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"閃刀術式－ジャミングウェーブ",h:"せんとうじゅつしき－じゃみんぐうぇーぶ",e:"Sky Striker Maneuver - Jamming Waves!",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分のメインモンスターゾーンにモンスターが存在しない場合、フィールドにセットされた魔法・罠カード１枚を対象として発動できる。\nそのカードを破壊する。\nその後、自分の墓地に魔法カードが３枚以上存在する場合、フィールドのモンスター１体を選んで破壊できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"洗脳－ブレインコントロール",h:"せんのう－ぶれいんこんとろーる",e:"Brain Control",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：８００LPを払い、相手フィールドの通常召喚可能な表側表示モンスター１体を対象として発動できる。\nその表側表示モンスターのコントロールをエンドフェイズまで得る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゼンマイマニュファクチャ",h:"ぜんまいまにゅふぁくちゃ",e:"Wind-Up Factory",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"「ゼンマイ」と名のついたモンスターの効果が発動した場合、自分のデッキからレベル４以下の「ゼンマイ」と名のついたモンスター１体を手札に加える事ができる。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"千里眼",h:"せんりがん",e:"Senri Eye",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"１ターンに１度だけ自分のスタンバイフェイズ時に１００ライフポイントを払う事で、相手デッキの一番上のカードを確認して元に戻す事ができる。\n相手はそのカードを確認できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"憎悪の棘",h:"ぞうおのとげ",e:"Thorn of Malice",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「ブラック・ローズ・ドラゴン」または植物族モンスターにのみ装備可能。\n装備モンスターの攻撃力は６００ポイントアップする。\n装備モンスターが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。\n装備モンスターがモンスターを攻撃した場合、ダメージ計算後に攻撃対象モンスターの攻撃力・守備力は６００ポイントダウンする。\n装備モンスターと戦闘を行った相手モンスターは、その戦闘では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"草原",h:"そうげん",e:"Sogen",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"全ての戦士・獣戦士族モンスターの攻撃力と守備力は、２００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"荘厳なる機械天使",h:"そうごんなるきかいてんし",e:"Magnificent Machine Angel",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の手札・フィールドの「サイバー・エンジェル」儀式モンスター１体をリリースし、自分フィールドの天使族・光属性モンスター１体を対象として発動できる。\n対象のモンスターの攻撃力・守備力はターン終了時まで、リリースしたモンスターのレベル×２００アップする。\nこのターン、対象のモンスターが、EXデッキから特殊召喚された相手モンスターと戦闘を行う場合、バトルフェイズの間だけその相手モンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"増殖",h:"ぞうしょく",e:"Multiply",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「クリボー」１体をリリースして発動できる。\n自分フィールド上に「クリボートークン」（悪魔族・闇・星１・攻３００／守２００）を可能な限り守備表示で特殊召喚する。\nこのトークンはアドバンス召喚のためにはリリースできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"増草剤",h:"ぞうそうざい",e:"Miracle Fertilizer",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：１ターンに１度、自分の墓地の植物族モンスター１体を対象として発動できる。\nその植物族モンスターを特殊召喚する。\nこの効果でモンスターを特殊召喚するターン、自分は通常召喚できない。\nこの効果で特殊召喚したモンスターがフィールドから離れた時にこのカードは破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"走破するガイア",h:"そうはするがいあ",e:"Galloping Gaia",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分のモンスターゾーンに「竜騎士ガイア」が存在する限り、相手はバトルフェイズ中に効果を発動できない。\n②：以下の効果から１つを選択して発動できる。\n\n●手札の「暗黒騎士ガイア」モンスター１体を相手に見せて発動できる。\nデッキからドラゴン族・レベル５モンスター１体を手札に加える。\n\n●手札のドラゴン族・レベル５モンスター１体を相手に見せて発動できる。\nデッキから「暗黒騎士ガイア」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ソウルテイカー",h:"そうるていかー",e:"Soul Taker",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：相手フィールドの表側表示モンスター１体を対象として発動できる。\nその相手の表側表示モンスターを破壊する。\nその後、相手は１０００LP回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ソーラー・エクスチェンジ",h:"そーらー・えくすちぇんじ",e:"Solar Recharge",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：手札から「ライトロード」モンスター１体を捨てて発動できる。\n自分はデッキから２枚ドローする。\nその後、自分のデッキの上からカードを２枚墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"即神仏",h:"そくしんぶつ",e:"Self-Mummification",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上に存在するモンスター１体を選択して墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダーク・コーリング",h:"だーく・こーりんぐ",e:"Dark Calling",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の手札・墓地から、融合モンスターカードによって決められた融合素材モンスターを除外し、「ダーク・フュージョン」の効果でのみ特殊召喚できるその融合モンスター１体を「ダーク・フュージョン」による融合召喚扱いとしてEXデッキから融合召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダーク・ジェノサイド・カッター",h:"だーく・じぇのさいど・かったー",e:"Dark Mambele",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に闇属性モンスターが３体以上存在する場合に発動できる。\nフィールド上に表側表示で存在するカード１枚を選択してゲームから除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダーク・シティ",h:"だーく・してぃ",e:"Dark City",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：「D-HERO」モンスターがその攻撃力より高い攻撃力を持つモンスターを攻撃した場合、攻撃モンスターの攻撃力はダメージ計算時のみ１０００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダーク・フュージョン",h:"だーく・ふゅーじょん",e:"Dark Fusion",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の手札・フィールドから、悪魔族の融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。\nこの効果で特殊召喚したモンスターは、このターン相手の効果の対象にならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダークゾーン",h:"だーくぞーん",e:"Mystic Plasma Zone",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在する闇属性モンスターの攻撃力は５００ポイントアップし、守備力は４００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"体温の上昇",h:"たいおんのじょうしょう",e:"Raise Body Heat",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"恐竜族のみ装備可能。\n装備モンスター１体の攻撃力と守備力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"大寒気",h:"だいかんき",e:"Cold Feet",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このターン、自分は魔法・罠カードの効果の使用及び発動・セットはできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"大邪神の儀式",h:"だいじゃしんのぎしき",e:"Final Ritual of the Ancients",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「大邪神 レシェフ」の降臨に必要。\nフィールドか手札から、レベルが８以上になるようカードを生け贄に捧げなければならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"大樹海",h:"だいじゅかい",e:"Verdant Sanctuary",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在する昆虫族モンスターが戦闘またはカードの効果によって破壊され墓地へ送られた時、そのモンスターのコントローラーは破壊されたモンスターと同じレベルの昆虫族モンスター１体をデッキから手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"大進化薬",h:"だいしんかやく",e:"Big Evolution Pill",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールドの恐竜族モンスター１体をリリースしてこのカードを発動できる。\nこのカードは発動後、フィールドに残り続け、相手ターンで数えて３ターン目の相手エンドフェイズに破壊される。\n①：このカードが魔法＆罠ゾーンに存在する限り、自分はレベル５以上の恐竜族モンスターを召喚する場合に必要なリリースをなくす事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"大胆無敵",h:"だいたんむてき",e:"Child's Play",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：相手がモンスターを召喚・反転召喚・特殊召喚する度に、自分は３００LP回復する。\n②：自分のLPが１００００以上の場合、自分のモンスターは戦闘では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"大地讃頌",h:"だいちさんしょう",e:"Earth Chant",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"地属性の儀式モンスターの降臨に使用する事ができる。\nフィールドか手札から、儀式召喚する地属性モンスターと同じレベルになるように生け贄を捧げなければならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダイナミスト・チャージ",h:"だいなみすと・ちゃーじ",e:"Dinomist Charge",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"「ダイナミスト・チャージ」は１ターンに１枚しか発動できない。\n①：このカードの発動時の効果処理として、デッキから「ダイナミスト」モンスター１体を手札に加える。\n②：１ターンに１度、「ダイナミスト」カードがフィールドから自分のエクストラデッキに表側表示で加わった場合に発動する。\nそのカード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダイナミックP",h:"だいなみっくぱわーろーど",e:"Dinomic Powerload",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：フィールドの「ダイナミスト」モンスターの攻撃力・守備力は３００アップする。\n②：自分の「ダイナミスト」モンスターが戦闘を行う場合、相手はダメージステップ終了時まで魔法・罠・モンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"大熱波",h:"だいねっぱ",e:"Heat Wave",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分メインフェイズ１開始時に発動できる。\n次の自分ドローフェイズまで、お互いに効果モンスターを召喚・特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"タイムカプセル",h:"たいむかぷせる",e:"Different Dimension Capsule",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分のデッキからカードを１枚選択し、裏側表示でゲームから除外する。\n発動後２回目の自分のスタンバイフェイズ時にこのカードを破壊し、そのカードを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"タイムパッセージ",h:"たいむぱっせーじ",e:"Time Passage",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上の「フォーチュンレディ」と名のついたモンスター１体を選択し、そのレベルをエンドフェイズ時まで３つ上げる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダイヤモンドダスト・サイクロン",h:"だいやもんどだすと・さいくろん",e:"Diamond-Dust Cyclone",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"フォッグカウンターが４つ以上乗っているモンスター１体を選択して発動する。\n選択したモンスターを破壊し、破壊したモンスターに乗っているフォッグカウンター４つにつき、自分のデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"太陽の祭壇",h:"たいようのさいだん",e:"Temple of the Sun",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードがフィールド上に存在する限り、自分フィールド上に表側表示で存在する墓地から特殊召喚されたモンスターの攻撃力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"太陽の書",h:"たいようのしょ",e:"Book of Taiyou",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"フィールド上に裏側表示で存在するモンスター１体を選択し、表側攻撃表示にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"大欲な壺",h:"たいよくなつぼ",e:"Pot of Acquisitiveness",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：除外されている自分及び相手のモンスターの中から合計３体を対象として発動できる。\nそのモンスター３体を持ち主のデッキに加えてシャッフルする。\nその後、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダウンビート",h:"だうんびーと",e:"Downbeat",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの表側表示モンスター１体をリリースして発動できる。\nリリースしたモンスターと元々の種族・属性が同じで元々のレベルが１つ低いモンスター１体をデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダグラの剣",h:"だぐらのつるぎ",e:"Cestus of Dagla",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"天使族のみ装備可能。\n装備モンスター１体の攻撃力は５００ポイントアップする。\n装備モンスターが戦闘によって相手プレイヤーにダメージを与えた時、その数値分、自分のライフポイントを回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"堕天使の戒壇",h:"だてんしのかいだん",e:"Darklord Contact",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「堕天使の戒壇」は１ターンに１枚しか発動できない。\n①：自分の墓地の「堕天使」モンスター１体を選んで守備表示で特殊召喚する。",li:2,lk:[],ta:[],al:[],ocg:true},
  {n:"堕天使の追放",h:"だてんしのついほう",e:"Banishment of the Darklords",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：デッキから「堕天使の追放」以外の「堕天使」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"頼もしき守護者",h:"たのもしきしゅごしゃ",e:"The Reliable Guardian",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在するモンスター１体の守備力はエンドフェイズ時まで７００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダブル・アップ・チャンス",h:"だぶる・あっぷ・ちゃんす",e:"Double or Nothing!",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：モンスターの攻撃が無効になった時、そのモンスター１体を対象として発動できる。\nこのバトルフェイズ中、そのモンスターはもう１度だけ攻撃できる。\nこの効果でそのモンスターが攻撃するダメージステップの間、そのモンスターの攻撃力は倍になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダブル・サイクロン",h:"だぶる・さいくろん",e:"Double Cyclone",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの魔法・罠カード１枚と、相手フィールドの魔法・罠カード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダブル・ディフェンダー",h:"だぶる・でぃふぇんだー",e:"Double Defender",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側守備表示でモンスターが２体以上存在する場合、相手モンスターの攻撃宣言時に発動できる。\nその相手モンスター１体の攻撃を無効にする。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダブルアタック",h:"だぶるあたっく",e:"Double Attack",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の手札からモンスターカード１枚を墓地に捨てる。\n捨てたモンスターよりもレベルが低いモンスター１体を自分フィールド上から選択する。\n選択したモンスター１体はこのターン２回攻撃をする事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダブルツールD＆C",h:"だぶるつーるでぃーあんどしー",e:"Double Tool C&D",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"自分フィールド上の「パワー・ツール・ドラゴン」または「D」と名のついたレベル４以上の機械族モンスターにのみ装備可能。\nそれぞれのターンで以下の効果を適用する。\n●自分のターン：装備モンスターの攻撃力は１０００ポイントアップする。\nまた、装備モンスターが攻撃する場合、バトルフェイズの間だけ攻撃対象モンスターの効果は無効化される。\n●相手のターン：相手は装備モンスター以外のモンスターを攻撃対象に選択できない。\nまた、装備モンスターが相手モンスターと戦闘を行ったダメージステップ終了時、その相手モンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダブルトラップ",h:"だぶるとらっぷ",e:"Double Snare",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在する、「罠の効果を無効にする効果」を持つカード１枚を破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダブルヒーローアタック",h:"だぶるひーろーあたっく",e:"Double Hero Attack",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドに「E・HERO ネオス」を融合素材とする融合モンスターが存在する場合、自分の墓地の「HERO」融合モンスター１体を対象として発動できる。\nそのモンスターを召喚条件を無視して特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魂喰らいの魔刀",h:"たまぐらいのまとう",e:"Sword of the Soul-Eater",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"自分フィールド上に存在するレベル３以下の通常モンスターに装備する事ができる。\nこのカードの発動時、装備モンスター以外の自分フィールド上に存在する通常モンスター（トークンを除く）を全て生け贄に捧げる。\n生け贄に捧げた通常モンスター１体につき、装備モンスターの攻撃力は１０００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魂吸収",h:"たましいきゅうしゅう",e:"Soul Absorption",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードのコントローラーはカードがゲームから除外される度に、１枚につき５００ライフポイント回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魂のカード",h:"たましいのかーど",e:"Card of the Soul",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「魂のカード」は１ターンに１枚しか発動できない。\n①：自分のデッキを確認する。\nその中から攻撃力・守備力の合計が自分のLPと同じ数値となるモンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魂の解放",h:"たましいのかいほう",e:"Soul Release",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"お互いの墓地のカードを合計５枚まで選択し、そのカードをゲームから除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魂の共有－コモンソウル",h:"たましいのきょうゆう－こもんそうる",e:"Common Soul",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在するモンスター１体を選択して発動する。\n自分の手札の「N」と名のついたモンスター１体を、選択したモンスターのコントローラーのフィールド上へ特殊召喚する。\n選択したモンスターの攻撃力は、この効果で特殊召喚した「N」と名のついたモンスターの攻撃力分アップする。\nこのカードがフィールド上から離れた時、このカードの効果で特殊召喚した「N」と名のついたモンスター１体を手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"多様進化",h:"たようしんか",e:"Evo-Diversity",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"デッキから「エヴォルド」または「エヴォルダー」と名のついたモンスター１体を手札に加える。\n「多様進化」は１ターンに１枚しか発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"団結の力",h:"だんけつのちから",e:"United We Stand",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"①：装備モンスターの攻撃力・守備力は、自分フィールドの表側表示モンスターの数×８００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"断層地帯",h:"だんそうちたい",e:"Canyon",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"守備表示の岩石族モンスターが攻撃され、攻撃モンスターのコントローラーが戦闘ダメージを受ける場合、その戦闘ダメージは倍になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"タンホイザーゲート",h:"たんほいざーげーと",e:"Tannhauser Gate",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上の攻撃力１０００以下で同じ種族のモンスター２体を選択して発動できる。\n選択した２体のモンスターは、その２体のレベルを合計したレベルになる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"置換融合",h:"ちかんゆうごう",e:"Fusion Substitute",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカードのカード名はルール上「融合」として扱う。\n①：自分フィールドから、融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。\n②：墓地のこのカードを除外し、自分の墓地の融合モンスター１体を対象として発動できる。\nそのモンスターをEXデッキに戻す。\nその後、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"顕現する紋章",h:"ちゃーじ・めだりおん",e:"Charged-Up Heraldry",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドのモンスター１体をリリースして発動できる。\nデッキから「紋章獣」モンスター２体を守備表示で特殊召喚する。\nこのカードの発動後、ターン終了時まで自分はサイキック族モンスター及び機械族モンスターしかEXデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"チャクラの復活",h:"ちゃくらのふっかつ",e:"Resurrection of Chakra",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「チャクラ」の降臨に必要。\n場か手札から、星の数が合計７個以上になるようカードを生け贄に捧げなければならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"チューナーズ・ハイ",h:"ちゅーなーず・はい",e:"Tuner's High",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：手札からモンスター１体を捨てて発動できる。\nそのモンスターと同じ種族・属性でレベルが１つ高いチューナー１体をデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"昼夜の大火事",h:"ちゅうやのおおかじ",e:"Ookazi",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"相手ライフに８００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"超栄養太陽",h:"ちょうえいようたいよう",e:"Super Solar Nutrient",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：自分フィールドのレベル２以下の植物族モンスター１体をリリースしてこのカードを発動できる。\nリリースしたモンスターのレベル＋３以下のレベルを持つ植物族モンスター１体を、手札・デッキから特殊召喚する。\nこのカードがフィールドから離れた時にそのモンスターは破壊される。\nそのモンスターがフィールドから離れた時にこのカードは破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"超進化の繭",h:"ちょうしんかのまゆ",e:"Cocoon of Ultra Evolution",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：装備カードを装備した自分・相手フィールドの昆虫族モンスター１体をリリースし、デッキから昆虫族モンスター１体を召喚条件を無視して特殊召喚する。\n②：自分メインフェイズに墓地のこのカードを除外し、自分の墓地の昆虫族モンスター１体を対象として発動できる。\nそのモンスターをデッキに加えてシャッフルする。\nその後、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"超進化薬",h:"ちょうしんかやく",e:"Ultra Evolution Pill",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上の爬虫類族モンスター１体を生け贄に捧げる。\n手札から恐竜族モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"超戦士の儀式",h:"ちょうせんしのぎしき",e:"Super Soldier Ritual",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「カオス・ソルジャー」儀式モンスターの降臨に必要。\n「超戦士の儀式」の②の効果は１ターンに１度しか使用できない。\n①：自分の手札・フィールドから、レベルの合計が８になるようにモンスターをリリースし、手札から「カオス・ソルジャー」儀式モンスター１体を儀式召喚する。\n②：自分の墓地からこのカード及び光属性モンスター１体と闇属性モンスター１体を除外して発動できる。\n手札から「カオス・ソルジャー」儀式モンスター１体を召喚条件を無視して特殊召喚する。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"超戦士の萌芽",h:"ちょうせんしのほうが",e:"Super Soldier Synthesis",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「カオス・ソルジャー」儀式モンスターの降臨に必要。\n「超戦士の萌芽」は１ターンに１枚しか発動できない。\n①：レベルの合計が８になるように、以下のどちらかの組み合わせのモンスターを墓地へ送り、自分の手札・墓地から「カオス・ソルジャー」儀式モンスター１体を儀式召喚する。\n\n●手札の光属性モンスター１体とデッキの闇属性モンスター１体\n●手札の闇属性モンスター１体とデッキの光属性モンスター１体",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"調律",h:"ちょうりつ",e:"Tuning",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：デッキから「シンクロン」チューナー１体を手札に加えてデッキをシャッフルする。\nその後、自分のデッキの一番上のカードを墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"超力の聖刻印",h:"ちょうりょくのせいこくいん",e:"Hieratic Seal of Supremacy",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"手札から「聖刻」と名のついたモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"調和の宝札",h:"ちょうわのほうさつ",e:"Cards of Consonance",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：手札から攻撃力１０００以下のドラゴン族チューナー１体を捨てて発動できる。\n自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"猪突猛進",h:"ちょとつもうしん",e:"Super Rush Headlong",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：属性を１つ宣言し、自分フィールドの表側表示モンスター１体を対象として発動できる。\nこのターン、その自分の表側表示モンスターが、宣言した属性を持つ相手モンスターと戦闘を行う場合、そのダメージステップ開始時にその相手モンスターは破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"治療の神 ディアン・ケト",h:"ちりょうのかみ　でぃあん・けと",e:"Dian Keto the Cure Master",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分は１０００LP回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"沈黙の剣",h:"ちんもくのつるぎ",e:"Silent Sword Slash",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの「サイレント・ソードマン」モンスター１体を対象として発動できる。\nその自分のモンスターの攻撃力・守備力は１５００アップし、ターン終了時まで相手の効果を受けない。\nこのカードの発動と効果は無効化されない。\n②：自分メインフェイズに墓地のこのカードを除外して発動できる。\nデッキから「サイレント・ソードマン」モンスター１体を手札に加える。",li:2,lk:[],ta:[],al:[],ocg:true},
  {n:"ツイスター",h:"ついすたー",e:"Twister",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"５００ライフポイントを払って発動できる。\nフィールド上に表側表示で存在する魔法・罠カード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"痛魂の呪術",h:"つうこんのじゅじゅつ",e:"Spell of Pain",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分にダメージを与える効果を相手が発動した時に発動できる。\nその効果で発生する自分への効果ダメージは代わりに相手が受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ツーマンセルバトル",h:"つーまんせるばとる",e:"Two-Man Cell Battle",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"各プレイヤーは自分のターンのエンドフェイズ時に１度だけ、レベル４通常モンスター１体を手札から特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"使い捨て学習装置",h:"つかいすてらーにんぐましん",e:"Disposable Learner Device",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：装備モンスターの攻撃力は、自分の墓地のモンスターの数×２００アップする。\n②：このカードがフィールドから墓地へ送られたターンのエンドフェイズに発動できる。\nこのカードを自分フィールドにセットする。\nこの効果でセットしたこのカードはフィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"月の書",h:"つきのしょ",e:"Book of Moon",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターを裏側守備表示にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"壺盗み",h:"つぼぬすみ",e:"Jar Robber",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"「強欲な壺」発動時に発動する事ができる。\n「強欲な壺」の効果を無効にし、自分はデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"剣の煌き",h:"つるぎのきらめき",e:"Sword of Sparkles",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「X－セイバー」と名のついたモンスターにのみ装備可能。\n装備モンスターが戦闘によって相手モンスターを破壊した場合、相手フィールド上に存在するカード１枚を破壊する事ができる。\nまた、自分フィールド上に存在するモンスター１体をリリースする事で、自分の墓地に存在するこのカードをデッキの一番上に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"D-スピリッツ",h:"でぃー－すぴりっつ",e:"D - Spirit",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に「D-HERO」と名のついたモンスターが表側表示で存在しない場合、手札からレベル４以下の「D-HERO」と名のついたモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"D-フォーメーション",h:"でぃー－ふぉーめーしょん",e:"D - Formation",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「D-HERO」と名のついたモンスターが破壊される度に、１体につき１つDカウンターをこのカードに置く。\n自分のメインフェイズ時にモンスターの召喚・特殊召喚に成功した時、Dカウンターが２つ以上乗ったこのカードを墓地に送る事で、召喚・特殊召喚したモンスターと同名カードを２枚まで自分のデッキ・墓地から手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"帝王の轟毅",h:"ていおうのごうき",e:"Strike of the Monarchs",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドのレベル５以上の通常召喚された表側表示モンスター１体をリリースし、フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードの効果をターン終了時まで無効にする。\nその後、自分はデッキから１枚ドローする。\n②：自分メインフェイズに墓地のこのカードを除外し、属性を１つ宣言して発動できる。\nフィールドの全ての表側表示モンスターは、ターン終了時まで宣言した属性になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"帝王の凍気",h:"ていおうのとうき",e:"Frost Blast of the Monarchs",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドに攻撃力２４００以上で守備力１０００のモンスターが存在する場合、フィールドにセットされたカード１枚を対象として発動できる。\nそのカードを破壊する。\n②：自分の墓地からこのカードと「帝王」魔法・罠カード１枚を除外し、フィールドにセットされたカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ディスカバード・アタック",h:"でぃすかばーど・あたっく",e:"Checkmate",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に存在する「デーモン」という名のついたモンスター１体を生け贄に捧げる。\nこのターン自分フィールド上の「ジェノサイドキングデーモン」１体は相手プレイヤーに直接攻撃をする事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"D・D・R",h:"でぃふぁれんと・でぃめんしょん・りばいばる",e:"D.D.R. - Different Dimension Reincarnation",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"①：手札を１枚捨て、除外されている自分のモンスター１体を対象としてこのカードを発動できる。\nそのモンスターを攻撃表示で特殊召喚し、このカードを装備する。\nこのカードがフィールドから離れた時にそのモンスターは破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"D・コード",h:"でぃふぉーまー・こーど",e:"Morphtronic Cord",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「D」と名のついたモンスターにのみ装備可能。\n装備モンスターの表示形式が変更される度に、フィールド上に存在する魔法または罠カード１枚を破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"D・スピードユニット",h:"でぃふぉーまー・すぴーどゆにっと",e:"Morphtronic Accelerator",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"手札の「D」と名のついたモンスター１体をデッキに戻してシャッフルする。\nその後、フィールド上のカード１枚を選択して破壊し、デッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"D・パワーユニット",h:"でぃふぉーまー・ぱわーゆにっと",e:"Morphtronic Engine",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「D」と名のついたレベル３のモンスターにのみ装備可能。\n装備モンスターの元々の攻撃力を倍にする。\n発動後２回目の自分のスタンバイフェイズ時にこのカードを破壊し、自分は装備モンスターの元々の攻撃力分のダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"D・フィールド",h:"でぃふぉーまー・ふぃーるど",e:"Morphtronic Map",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"フィールド上のモンスターの表示形式が変更される度に、このカードにディフォーマーカウンターを１つ置く。\nフィールド上の「D」と名のついたモンスターの攻撃力は、このカードに乗っているディフォーマーカウンターの数×３００ポイントアップする。\nフィールド上のこのカードが破壊され墓地へ送られた時、自分の墓地の「D」と名のついたモンスター１体を選択して特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"D・リペアユニット",h:"でぃふぉーまー・りぺあゆにっと",e:"Morphtronic Repair Unit",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"手札から「D」と名のついたモンスター１体を墓地へ送り、自分の墓地に存在する「D」と名のついたモンスター１体を選択して発動する。\n選択したモンスターを特殊召喚し、このカードを装備する。\n装備モンスターは表示形式を変更する事ができない。\nこのカードがフィールド上に存在しなくなった時、装備モンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"D・レトロエンジン",h:"でぃふぉーまー・れとろえんじん",e:"Morphtronic Rusty Engine",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「D」と名のついたモンスターにのみ装備可能。\n装備モンスターが破壊された場合、お互いにそのモンスターの元々の攻撃力分のダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ティマイオスの眼",h:"てぃまいおすのまなこ",e:"The Eye of Timaeus",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名はルール上「伝説の竜 ティマイオス」としても扱う。\nこのカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの「ブラック・マジシャン」モンスター１体を対象として発動できる。\nそのモンスターを融合素材として墓地へ送り、そのカード名が融合素材として記されている融合モンスター１体をEXデッキから融合召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ティンクル・セイクリッド",h:"てぃんくる・せいくりっど",e:"Constellar Twinkle",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「ティンクル・セイクリッド」の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドの「セイクリッド」モンスター１体を対象として発動できる。\nそのモンスターのレベルを１つまたは２つ上げる。\n②：このカードが墓地に存在する場合、自分の墓地の「セイクリッド」モンスター１体を除外して発動できる。\n墓地のこのカードを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デーモンとの駆け引き",h:"でーもんとのかけひき",e:"A Deal with Dark Ruler",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"レベル８以上の自分フィールド上のモンスターが墓地へ送られたターンに発動する事ができる。\n自分の手札またはデッキから「バーサーク・デッド・ドラゴン」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デーモンの斧",h:"でーもんのおの",e:"Axe of Despair",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"①：装備モンスターの攻撃力は１０００アップする。\n②：このカードがフィールドから墓地へ送られた時、自分フィールドのモンスター１体をリリースして発動できる。\nこのカードをデッキの一番上に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"伏魔殿－悪魔の迷宮－",h:"でーもんぱれす－あくまのめいきゅう－",e:"Archfiend Palabyrinth",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカードがフィールド上に存在する限り、自分フィールド上の悪魔族モンスターの攻撃力は５００ポイントアップする。\nまた、自分フィールド上の「デーモン」と名のついたモンスター１体を選択して発動できる。\n選択したモンスター以外の自分フィールド上の悪魔族モンスター１体を選んでゲームから除外し、自分の手札・デッキ・墓地から選択したモンスターと同じレベルの「デーモン」と名のついたモンスター１体を選んで特殊召喚する。\n「伏魔殿－悪魔の迷宮－」のこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デザートストーム",h:"でざーとすとーむ",e:"Rising Air Current",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在する風属性モンスターの攻撃力は５００ポイントアップし、守備力は４００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デス・メテオ",h:"です・めてお",e:"Meteor of Destruction",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"相手ライフに１０００ポイントダメージを与える。\n相手ライフが３０００ポイント以下の場合このカードは発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"死の合唱",h:"ですこーらす",e:"Des Croaking",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に「デスガエル」３体が表側表示で存在する時に発動する事ができる。\n相手フィールド上に存在する全てのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デステニー・ドロー",h:"ですてにー・どろー",e:"Destiny Draw",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：手札から「D-HERO」カード１枚を捨てて発動できる。\n自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"鉄の檻",h:"てつのおり",e:"Iron Cage",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：このカードの発動時の効果処理として、自分フィールドのモンスター１体を墓地へ送る。\nフィールドゾーンに「シュトロームベルクの金の城」が存在する場合、代わりに相手フィールドのモンスターを墓地へ送る事もできる。\n②：自分スタンバイフェイズに、このカードの①の効果で自分または相手の墓地へ送られたモンスター１体を対象として発動できる。\nこのカードを破壊し、そのモンスターを自分フィールドに特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"鉄壁の機皇兵",h:"てっぺきのきこうへい",e:"The Resolute Meklord Army",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードがフィールド上に存在する限り、自分フィールド上に表側攻撃表示で存在する「機皇兵」と名のついたモンスターの効果は無効化され、戦闘では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"鉄壁の布陣",h:"てっぺきのふじん",e:"Impenetrable Formation",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分フィールド上のモンスター１体を選択して発動する。\n自分フィールド上にモンスターが２体以上存在し全て守備表示の場合、選択したモンスターの守備力は７００ポイントアップする。\n選択したモンスターがフィールド上から離れた時、このカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"二重召喚",h:"でゅあるさもん",e:"Double Summon",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：このターン自分は通常召喚を２回まで行う事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デュアルスパーク",h:"でゅあるすぱーく",e:"Gemini Spark",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの表側表示のレベル４のデュアルモンスター１体をリリースし、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊し、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デルタ・アタッカー",h:"でるた・あたっかー",e:"Delta Attacker",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に同名通常モンスター（トークンを除く）が３体存在する時に発動する事ができる。\n発動ターンのみ、３体の同名通常モンスターは相手プレイヤーに直接攻撃をする事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"天空の泉",h:"てんくうのいずみ",e:"The Fountain in the Sky",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"光属性モンスターが戦闘によって破壊され自分の墓地へ送られた時、そのモンスター１体をゲームから除外する事で、そのモンスターの攻撃力分だけ自分のライフポイントを回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"天空の虹彩",h:"てんくうのこうさい",e:"Sky Iris",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"「天空の虹彩」の②の効果は１ターンに１度しか使用できない。\n①：このカードがフィールドゾーンに存在する限り、自分のPゾーンの、「魔術師」カード、「EM」カード、「オッドアイズ」カードは相手の効果の対象にならない。\n②：このカード以外の自分フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードを破壊し、デッキから「オッドアイズ」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"天空の聖域",h:"てんくうのせいいき",e:"The Sanctuary in the Sky",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：このカードがフィールドゾーンに存在する限り、天使族モンスターの戦闘で発生するそのコントローラーへの戦闘ダメージは０になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"天空の宝札",h:"てんくうのほうさつ",e:"Cards from the Sky",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカードを発動するターン、自分はモンスターを特殊召喚できず、バトルフェイズを行えない。\n①：手札から天使族・光属性モンスター１体を除外し、自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"電撃鞭",h:"でんげきむち",e:"Electro-Whip",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"雷族のみ装備可能。\n装備モンスター１体の攻撃力と守備力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"天使の生き血",h:"てんしのいきち",e:"Soul of the Pure",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分は８００ライフポイント回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"天使のサイコロ",h:"てんしのさいころ",e:"Graceful Dice",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：サイコロを１回振る。\n自分フィールドのモンスターの攻撃力・守備力は、ターン終了時まで出た目の数×１００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デンジャラスマシン TYPE－6",h:"でんじゃらすましん　たいぷ－しっくす",e:"Dangerous Machine Type-6",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分のスタンバイフェイズ毎にサイコロを１回振る。\n出た目の効果を適用する。\n１・自分は手札を１枚捨てる。\n２・相手は手札を１枚捨てる。\n３・自分はカードを１枚ドローする。\n４・相手はカードを１枚ドローする。\n５・相手フィールド上モンスター１体を破壊する。\n６・このカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"伝説の黒帯",h:"でんせつのくろおび",e:"Legendary Black Belt",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"自分フィールド上の「格闘ねずみ チュー助」「モンク・ファイター」「マスターモンク」のみ装備可能。\n装備モンスターが戦闘によって相手モンスターを破壊し墓地へ送った時、破壊したモンスターの守備力分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"伝説の剣",h:"でんせつのけん",e:"Legendary Sword",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"戦士族のみ装備可能。\n装備モンスター１体の攻撃力と守備力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"伝説のゼンマイ",h:"でんせつのぜんまい",e:"Legendary Wind-Up Key",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「ゼンマイ」と名のついたモンスターを全て裏側守備表示にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"伝説の都 アトランティス",h:"でんせつのみやこ　あとらんてぃす",e:"A Legendary Ocean",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名はルール上「海」として扱う。\n①：フィールドの水属性モンスターの攻撃力・守備力は２００アップする。\n②：このカードがフィールドゾーンに存在する限り、お互いの手札・フィールドの水属性モンスターのレベルは１つ下がる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"電脳増幅器",h:"でんのうぞうふくき",e:"Amplifier",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「人造人間－サイコ・ショッカー」にのみ装備可能。\nこのカードの発動と効果は無効化されない。\n①：装備モンスターの持つ、「お互いにフィールドの罠カードの効果を発動できず、フィールドの罠カードの効果は無効化される」効果は、「相手はフィールドの罠カードの効果を発動できず、相手フィールドの罠カードの効果は無効化される」として適用される。\n②：このカードがフィールドから離れた時に装備モンスターは破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"天馬の翼",h:"てんまのつばさ",e:"Pegasus Wing",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の墓地にユニオンモンスターが存在する場合、自分フィールドの「ワルキューレ」モンスターを任意の数だけ対象として発動できる。\nこのターン、そのモンスターは直接攻撃できる。\nその直接攻撃で相手に与える戦闘ダメージは半分になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"天命の聖剣",h:"てんめいのせいけん",e:"Noble Arms of Destiny",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"戦士族モンスターにのみ装備可能。\nこのカード名の③の効果は１ターンに１度しか使用できない。\n①：「天命の聖剣」は自分フィールドに１枚しか表側表示で存在できない。\n②：装備モンスターは１ターンに１度だけ戦闘・効果では破壊されない。\n③：フィールドの表側表示のこのカードが破壊され墓地へ送られた場合、自分フィールドの戦士族の「聖騎士」モンスター１体を対象として発動できる。\nその自分の戦士族の「聖騎士」モンスターにこのカードを装備する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"天よりの宝札",h:"てんよりのほうさつ",e:"Card of Sanctity",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の手札・フィールドのカードを全て除外して発動できる。\n自分は手札が２枚になるようにデッキからドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トゥーン・キングダム",h:"とぅーん・きんぐだむ",e:"Toon Kingdom",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：このカードの発動時の効果処理として、自分のデッキの上からカード３枚を裏側表示で除外する。\n②：このカードはフィールドゾーンに存在する限り、カード名を「トゥーン・ワールド」として扱う。\n③：このカードがフィールドゾーンに存在する限り、自分フィールドのトゥーンモンスターは相手の効果の対象にならない。\n④：自分フィールドのトゥーンモンスターが戦闘・効果で破壊される場合、代わりに破壊されるモンスター１体につき１枚、自分のデッキの上からカードを裏側表示で除外できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トゥーン・ロールバック",h:"とぅーん・ろーるばっく",e:"Toon Rollback",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドのトゥーンモンスター１体を対象として発動できる。\nこのターン、そのモンスターは１度のバトルフェイズ中に２回攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トゥーン・ワールド",h:"とぅーん・わーるど",e:"Toon World",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"１０００LPを払ってこのカードを発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トゥーンのもくじ",h:"とぅーんのもくじ",e:"Toon Table of Contents",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：デッキから「トゥーン」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"盗賊の極意",h:"とうぞくのごくい",e:"The Secret of the Bandit",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"メインフェイズ１でのみ発動する事ができる。\nフィールド上に表側表示で存在するモンスターを１体選択する。\nこのターン、選択したモンスターが相手プレイヤーに戦闘ダメージを与える度に、相手はランダムにカードを１枚捨てる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"同胞の絆",h:"どうほうのきずな",e:"Ties of the Brethren",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカードを発動するターン、自分はバトルフェイズを行えない。\n①：２０００LPを払い、自分フィールドのレベル４以下のモンスター１体を対象として発動できる。\nそのモンスターと同じ種族・属性・レベルでカード名が異なるモンスター２体をデッキから特殊召喚する（同名カードは１枚まで）。\nこのカードの発動後、ターン終了時まで自分はモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トークン収穫祭",h:"とーくんしゅうかくさい",e:"Token Thanksgiving",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"フィールド上のトークンを全て破壊する。\n破壊したトークンの数×８００ライフポイントを回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トークン復活祭",h:"とーくんふっかつさい",e:"Token Sundae",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に存在するトークンを全て破壊する。\nこの効果で破壊したトークンの数まで、フィールド上に存在するカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドーピング",h:"どーぴんぐ",e:"Stim-Pack",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"装備モンスターの攻撃力は７００ポイントアップする。\nまた、自分のスタンバイフェイズ毎に、装備モンスターの攻撃力は２００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トーラの魔導書",h:"とーらのまどうしょ",e:"Spellbook of Wisdom",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：フィールドの魔法使い族モンスター１体を対象として、以下の効果から１つを選択して発動できる。\n\n●このターン、その表側表示モンスターはこのカード以外の魔法カードの効果を受けない。\n\n●このターン、その表側表示モンスターは罠カードの効果を受けない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"独奏の第1楽章",h:"どくそうのだい１がくしょう",e:"1st Movement Solo",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「独奏の第１楽章」は１ターンに１枚しか発動できず、このカードを発動するターン、自分は「幻奏」モンスターしか特殊召喚できない。\n①：自分フィールドにモンスターが存在しない場合に発動できる。\n手札・デッキからレベル４以下の「幻奏」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トゲトゲ神の殺虫剤",h:"とげとげしんのさっちゅうざい",e:"Eradicating Aerosol",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"フィールド上の昆虫族モンスターは、すべて破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"突撃指令",h:"とつげきしれい",e:"Order to Charge",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：トークン以外の自分フィールドの通常モンスター１体を対象として発動できる。\nその通常モンスターをリリースし、相手フィールドのモンスター１体を選んで破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"突進",h:"とっしん",e:"Rush Recklessly",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで７００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"突風の扇",h:"とっぷうのおうぎ",e:"Gust Fan",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"風属性モンスターのみ装備可能。\n装備モンスター１体の攻撃力は４００ポイントアップする。\n守備力は２００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドドドドロー",h:"どどどどろー",e:"Dodododraw",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"手札または自分フィールド上に表側表示で存在する、「ドドド」と名のついたモンスター１体を墓地へ送って発動できる。\nデッキからカードを２枚ドローする。\n「ドドドドロー」は１ターンに１枚しか発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"隣の芝刈り",h:"となりのしばかり",e:"That Grass Looks Greener",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分のデッキの枚数が相手よりも多い場合に発動できる。\nデッキの枚数が相手と同じになるように、自分のデッキの上からカードを墓地へ送る。",li:0,lk:[],ta:[],al:[],ocg:true},
  {n:"ドミノ",h:"どみの",e:"Domino Effect",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"相手フィールド上に存在するモンスターが戦闘によって破壊され墓地へ送られた時、自分フィールド上に存在するモンスター１体を墓地へ送る事で、相手フィールド上に存在するモンスター１体を破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トライアングル・X・スパーク",h:"とらいあんぐる・えくすたしー・すぱーく",e:"Triangle Ecstasy Spark",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：フィールドの全ての「ハーピィ・レディ三姉妹」の攻撃力はターン終了時まで２７００になる。\nこのターン、相手は罠カードを発動できず、相手フィールドの罠カードの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トライアングル・エリア",h:"とらいあんぐる・えりあ",e:"Mysterious Triangle",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"フィールド上のAカウンターが乗ったモンスター１体を選択して破壊する。\nその後、自分のデッキから「エーリアン」と名のついたレベル４モンスター１体を特殊召喚できる。\nこの効果で特殊召喚したモンスターはエンドフェイズ時に破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トライアングルパワー",h:"とらいあんぐるぱわー",e:"Triangle Power",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する、全てのレベル１通常モンスター（トークンを除く）の元々の攻撃力と守備力は２０００ポイントアップする。\nエンドフェイズ時に自分フィールド上に存在するレベル１通常モンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トライワイトゾーン",h:"とらいわいとぞーん",e:"Tri-Wight",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の墓地のレベル２以下の通常モンスター３体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドラグニティの神槍",h:"どらぐにてぃのしんそう",e:"Dragunity Divine Lance",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「ドラグニティ」モンスターにのみ装備可能。\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：装備モンスターは、攻撃力が装備モンスターのレベル×１００アップし、罠カードの効果を受けない。\n②：自分メインフェイズに発動できる。\nデッキからドラゴン族の「ドラグニティ」チューナー１体を選び、このカードの装備モンスターに装備カード扱いとして装備する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドラゴニック・タクティクス",h:"どらごにっく・たくてぃくす",e:"Dragonic Tactics",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に存在するドラゴン族モンスター２体をリリースして発動する。\n自分のデッキからレベル８のドラゴン族モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドラゴン・復活の狂奏",h:"どらごん・ふっかつのきょうそう",e:"Dragon Revival Rhapsody",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドに魔法使い族モンスターが存在する場合、ドラゴン族の通常モンスターを含む、自分の墓地のドラゴン族モンスターを２体まで対象として発動できる。\nそのモンスターを特殊召喚する。\nこのカードの発動後、ターン終了時まで相手が受ける全てのダメージは０になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドラゴン・目覚めの旋律",h:"どらごん・めざめのせんりつ",e:"The Melody of Awakening Dragon",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：手札を１枚捨てて発動できる。\n攻撃力３０００以上で守備力２５００以下のドラゴン族モンスターを２体までデッキから手札に加える。",li:1,lk:[],ta:[],al:[],ocg:true},
  {n:"龍の鏡",h:"どらごんず・みらー",e:"Dragon's Mirror",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分のフィールド・墓地から、ドラゴン族の融合モンスターカードによって決められた融合素材モンスターを除外し、その融合モンスター１体をEXデッキから融合召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドラゴンの秘宝",h:"どらごんのひほう",e:"Dragon Treasure",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"ドラゴン族のみ装備可能。\n装備モンスター１体の攻撃力と守備力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドラゴンレーザー",h:"どらごんれーざー",e:"Dragon Laser",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「デルタトライ」に装備された「トライゴン」１体を墓地へ送り、相手フィールド上に存在するモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドラゴンを呼ぶ笛",h:"どらごんをよぶふえ",e:"The Flute of Summoning Dragon",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：手札からドラゴン族モンスターを２体まで特殊召喚する。\nこの効果はフィールドに「ロード・オブ・ドラゴン－ドラゴンの支配者－」が存在する場合に発動と処理ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トラスト・マインド",h:"とらすと・まいんど",e:"Mind Trust",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に存在するレベル２以上のモンスター１体をリリースして発動する。\n自分の墓地から、リリースしたモンスターの半分以下のレベルを持つチューナー１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トラミッド・キングゴレム",h:"とらみっど・きんぐごれむ",e:"Triamid Kingolem",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"「トラミッド・キングゴレム」の③の効果は１ターンに１度しか使用できない。\n①：フィールドの岩石族モンスターの攻撃力は５００アップする。\n②：自分の「トラミッド」モンスターが戦闘を行う場合、ダメージステップ終了時まで相手は魔法・罠・モンスターの効果を発動できない。\n③：フィールドゾーンの表側表示のこのカードが墓地へ送られた場合に発動できる。\n手札から「トラミッド」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トラミッド・クルーザー",h:"とらみっど・くるーざー",e:"Triamid Cruiser",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"「トラミッド・クルーザー」の③の効果は１ターンに１度しか使用できない。\n①：このカードがフィールドゾーンに存在する限り、岩石族モンスターが召喚される度に、自分は５００LP回復する。\n②：フィールドに「トラミッド」モンスターが召喚された場合に発動できる。\n自分はデッキから１枚ドローし、その後手札を１枚選んで捨てる。\n③：フィールドゾーンの表側表示のこのカードが墓地へ送られた場合に発動できる。\nデッキから「トラミッド」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トラミッド・フォートレス",h:"とらみっど・ふぉーとれす",e:"Triamid Fortress",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"「トラミッド・フォートレス」の③の効果は１ターンに１度しか使用できない。\n①：フィールドの岩石族モンスターの守備力は５００アップする。\n②：フィールドの「トラミッド」モンスターは効果では破壊されない。\n③：フィールドゾーンの表側表示のこのカードが墓地へ送られた場合、自分の墓地の「トラミッド」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トランスターン",h:"とらんすたーん",e:"Transmodify",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの表側表示モンスター１体を墓地へ送って発動できる。\n種族・属性が墓地のそのモンスターと同じでレベルが１つ高いモンスター１体をデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドリアードの祈り",h:"どりあーどのいのり",e:"Doriado's Blessing",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「精霊術師 ドリアード」の降臨に必要。\nフィールドか手札から、レベルが３以上になるようカードを生け贄に捧げなければならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"捕違い",h:"とりちがい",e:"Mistaken Arrest",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：このカードの発動後、次の自分ターンの終了時まで、お互いにドロー以外の方法でデッキからカードを手札に加える事はできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トリッキーズ・マジック4",h:"とりっきーず・まじっくふぉー",e:"Tricky Spell 4",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの表側表示の「THE トリッキー」１体を墓地へ送って発動できる。\n相手フィールドのモンスターの数だけ、自分フィールドに「トリッキートークン」（魔法使い族・風・星５・攻２０００／守１２００）を守備表示で特殊召喚する。\nこのトークンは攻撃宣言できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トリックスター・ブーケ",h:"とりっくすたー・ぶーけ",e:"Trickstar Bouquet",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの「トリックスター」モンスター１体とフィールドの表側表示モンスター１体を対象として発動できる。\nその「トリックスター」モンスターを持ち主の手札に戻し、もう１体の対象のモンスターの攻撃力はターン終了時まで、手札に戻したモンスターの元々の攻撃力分アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トリックスター・マジカローラ",h:"とりっくすたー・まじかろーら",e:"Trickstar Magical Laurel",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地の「トリックスター」モンスター１体を対象としてこのカードを発動できる。\nそのモンスターを特殊召喚し、このカードを装備する。\nこのカードがフィールドから離れた時にそのモンスターは破壊される。\n②：１ターンに１度、装備モンスターが戦闘・効果で相手にダメージを与えた場合に発動できる。\n手札から「トリックスター」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トルネード",h:"とるねーど",e:"Tornado",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"相手の魔法＆罠カードゾーンにカードが３枚以上存在する場合に発動できる。\n相手の魔法＆罠カードゾーンのカード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"鈍重",h:"どんじゅう",e:"Gravity Lash",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで、そのモンスターの守備力分ダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"貪欲で無欲な壺",h:"どんよくでむよくなつぼ",e:"Pot of Dichotomy",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"メインフェイズ１の開始時に自分の墓地から異なる種族のモンスター３体を選択して発動できる。\n選択したモンスター３体をデッキに加えてシャッフルする。\nその後、デッキからカードを２枚ドローする。\nこのカードを発動するターン、自分はバトルフェイズを行えない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ナイト・ショット",h:"ないと・しょっと",e:"Night Beam",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：相手フィールドにセットされた魔法・罠カード１枚を対象として発動できる。\nセットされたそのカードを破壊する。\nこのカードの発動に対して相手は対象のカードを発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ナイルの恵み",h:"ないるのめぐみ",e:"Blessings of the Nile",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"相手がコントロールするカードの効果によって、カードが自分の手札から墓地へ捨てられる度に、自分は１０００ライフポイント回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ナチュラル・チューン",h:"なちゅらる・ちゅーん",e:"Natural Tune",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上のレベル４以下の通常モンスター１体を選択して発動できる。\n選択したモンスターはフィールド上に表側表示で存在する限りチューナーとして扱う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ナチュルの森",h:"なちゅるのもり",e:"Naturia Forest",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"相手がコントロールするカードの発動を無効にした場合、自分のデッキからレベル３以下の「ナチュル」と名のついたモンスター１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"奈落との契約",h:"ならくとのけいやく",e:"Contract with the Abyss",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"闇属性の儀式モンスターの降臨に必要。\n①：レベルの合計が儀式召喚するモンスターと同じになるように、自分の手札・フィールドのモンスターをリリースし、手札から闇属性の儀式モンスター１体を儀式召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"虹の架け橋",h:"にじのかけはし",e:"Rainbow Bridge",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：デッキから「宝玉」魔法・罠カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ニトロユニット",h:"にとろゆにっと",e:"Nitro Unit",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"相手フィールド上モンスターにのみ装備可能。\n装備モンスターを戦闘によって破壊し墓地へ送った時、装備モンスターの攻撃力分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"盗人ゴブリン",h:"ぬすっとごぶりん",e:"Goblin Thief",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：相手に５００ダメージを与える。\n自分は５００LP回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"盗人の煙玉",h:"ぬすっとのけむりだま",e:"Smoke Grenade of the Thief",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"モンスターに装備されているこのカードがカードの効果によって破壊された時、相手の手札を確認して１枚捨てる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"盗み見ゴブリン",h:"ぬすみみごぶりん",e:"Peeking Goblin",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"相手はデッキの上からカードを３枚めくる。\n自分はその中から１枚を選択して相手のデッキの一番下に戻し、残りのカードを好きな順番で相手のデッキの上に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"濡れ衣",h:"ぬれぎぬ",e:"Mistaken Accusation",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"「濡れ衣」は１ターンに１枚しか発動できない。\n①：相手の手札・フィールドのカードを合計した数が自分の手札・フィールドのカードを合計した数より多い場合、フィールドの表側表示のカード１枚を対象として発動できる。\nお互いのプレイヤーはこのデュエル中、その表側表示のカード以外の、対象のカードと同名カードの効果を発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ネオス・フォース",h:"ねおす・ふぉーす",e:"Neos Force",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「E・HERO ネオス」にのみ装備可能。\n装備モンスターの攻撃力は８００ポイントアップする。\n装備モンスターが戦闘によってモンスターを破壊し墓地へ送った時、破壊したモンスターの元々の攻撃力分のダメージを相手ライフに与える。\nまた、エンドフェイズ時、フィールド上のこのカードはデッキに戻る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ネオス・フュージョン",h:"ねおす・ふゅーじょん",e:"Neos Fusion",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の手札・デッキ・フィールドから、融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、「E・HERO ネオス」を含むモンスター２体のみを素材とするその融合モンスター１体を召喚条件を無視してEXデッキから特殊召喚する。\nこのカードの発動後、ターン終了時まで自分はモンスターを特殊召喚できない。\n②：「E・HERO ネオス」を融合素材とする自分フィールドの融合モンスターが戦闘・効果で破壊される場合、または自身の効果でEXデッキに戻る場合、代わりに墓地のこのカードを除外できる。",li:2,lk:[],ta:[],al:[],ocg:true},
  {n:"NEX",h:"ねおすぺーしあんえくすてんと",e:"NEX",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「N」と名のついたモンスター１体を墓地へ送り、墓地へ送ったカードと同名カード扱いのレベル４モンスター１体を融合デッキから特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ネオスペース",h:"ねおすぺーす",e:"Neo Space",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカードがフィールド上に存在する限り、「E・HERO ネオス」及び「E・HERO ネオス」を融合素材とする融合モンスターの攻撃力は５００ポイントアップする。\nまた、「E・HERO ネオス」を融合素材とする融合モンスターは、エンドフェイズ時にエクストラデッキに戻る効果を発動しなくてもよい。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ネクロイド・シンクロ",h:"ねくろいど・しんくろ",e:"Necroid Synchro",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：チューナー１体とチューナー以外のモンスター２体までを自分の墓地から選んで除外し、除外したモンスターのレベルの合計と同じレベルを持つ「スターダスト」Sモンスター１体をエクストラデッキからS召喚扱いで特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"影霊衣の降魔鏡",h:"ねくろすのこうまきょう",e:"Nekroz Mirror",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「影霊衣」儀式モンスターの降臨に必要。\n「影霊衣の降魔鏡」の①の効果は１ターンに１度しか使用できない。\n①：レベルの合計が儀式召喚するモンスターと同じになるように、自分の手札・フィールドのモンスターをリリース、またはリリースの代わりに自分の墓地の「影霊衣」モンスターを除外し、手札から「影霊衣」儀式モンスター１体を儀式召喚する。\n②：自分フィールドにモンスターが存在しない場合、自分の墓地からこのカードと「影霊衣」モンスター１体を除外して発動できる。\nデッキから「影霊衣」魔法カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"影霊衣の反魂術",h:"ねくろすのはんごんじゅつ",e:"Nekroz Cycle",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「影霊衣」儀式モンスターの降臨に必要。\n「影霊衣の反魂術」の①の効果は１ターンに１度しか使用できない。\n①：レベルの合計が儀式召喚するモンスターと同じになるように、自分の手札・フィールドのモンスターをリリースし、自分の手札・墓地から「影霊衣」儀式モンスター１体を儀式召喚する。\n②：自分フィールドにモンスターが存在しない場合、自分の墓地からこのカードと「影霊衣」モンスター１体を除外して発動できる。\nデッキから「影霊衣」魔法カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"影霊衣の万華鏡",h:"ねくろすのまんげきょう",e:"Nekroz Kaleidoscope",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「影霊衣」儀式モンスターの降臨に必要。\nこのカード名の①の効果は１ターンに１度しか使用できない。\n①：レベルの合計が儀式召喚するモンスターと同じになるように、自分の手札・フィールドのモンスター１体をリリース、またはリリースの代わりにEXデッキのモンスター１体を墓地へ送り、手札から「影霊衣」儀式モンスターを任意の数だけ儀式召喚する。\n②：自分フィールドにモンスターが存在しない場合、自分の墓地からこのカードと「影霊衣」モンスター１体を除外して発動できる。\nデッキから「影霊衣」魔法カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ネクロの魔導書",h:"ねくろのまどうしょ",e:"Spellbook of Life",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"自分の墓地の魔法使い族モンスター１体をゲームから除外し、このカード以外の手札の「魔導書」と名のついた魔法カード１枚を相手に見せて発動できる。\n自分の墓地の魔法使い族モンスター１体を選択して表側攻撃表示で特殊召喚し、このカードを装備する。\nまた、装備モンスターのレベルは、このカードを発動するために除外した魔法使い族モンスターのレベル分だけ上がる。\n「ネクロの魔導書」は１ターンに１枚しか発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ネクロバレーの玉座",h:"ねくろばれーのぎょくざ",e:"Necrovalley Throne",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：以下の効果から１つを選択して発動できる。\n\n●デッキから「墓守」モンスター１体を手札に加える。\n\n●手札から「墓守」モンスター１体を召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ネクロバレーの祭殿",h:"ねくろばれーのさいでん",e:"Hidden Temples of Necrovalley",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"フィールド上に「墓守」と名のついたモンスター及び「王家の眠る谷－ネクロバレー」が存在する場合に発動できる。\nこのカードがフィールド上に存在する限り、お互いに「墓守」と名のついたモンスター以外のモンスターを特殊召喚できない。\n「墓守」と名のついたモンスター及び「王家の眠る谷－ネクロバレー」がフィールド上に存在しない場合、このカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ネフティスの希望",h:"ねふてぃすのきぼう",e:"Last Hope of Nephthys",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの「ネフティス」カード１枚と相手フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ネフティスの輪廻",h:"ねふてぃすのりんね",e:"Rebirth of Nephthys",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「ネフティス」儀式モンスターの降臨に必要。\n①：レベルの合計が儀式召喚するモンスターのレベル以上になるように、自分の手札・フィールドのモンスターをリリースし、手札から「ネフティス」儀式モンスター１体を儀式召喚する。\n「ネフティスの祀り手」または「ネフティスの蒼凰神」をリリースして儀式召喚した場合には、さらにフィールドのカード１枚を選んで破壊できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"念動増幅装置",h:"ねんどうぞうふくそうち",e:"Telekinetic Charging Cell",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"サイキック族モンスターにのみ装備可能。\n装備モンスターの効果を発動するために払うライフポイントが必要なくなる。\n装備モンスターが破壊される事によってこのカードが墓地へ送られた時、１０００ライフポイントを払う事でこのカードを手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"脳開発研究所",h:"のうかいはつけんきゅうじょ",e:"Brain Research Lab",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：このカードがフィールドゾーンに存在する限り、お互いのプレイヤーは通常召喚に加えて１度だけ、自身のメインフェイズにサイキック族モンスター１体を召喚できる。\nこの方法でサイキック族モンスターが召喚される度にこのカードにサイコカウンターを１つ置く。\n②：自分フィールドのサイキック族モンスターが効果を発動するためにLPを払う場合、代わりにこのカードにサイコカウンターを１つ置く事ができる。\n③：このカードがフィールドから離れた時に自分はこのカードに置かれていたサイコカウンターの数×１０００ダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"呪いのお札",h:"のろいのおふだ",e:"Cursed Bill",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"装備モンスターが破壊される事によってこのカードが墓地へ送られた時、墓地へ送られた装備モンスターの元々の守備力分のダメージを装備モンスターのコントローラーのライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バーサーカークラッシュ",h:"ばーさーかーくらっしゅ",e:"Berserker Crush",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分の墓地に存在するモンスター１体をゲームから除外して発動する。\nこのターンのエンドフェイズ時まで、自分フィールド上に表側表示で存在する「ハネクリボー」１体の攻撃力・守備力は、除外したモンスターと同じ数値になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"蛮勇鱗粉",h:"ばーさーく・すけーるす",e:"Berserk Scales",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在するモンスター１体を選択して発動できる。\n選択したモンスターの攻撃力は１０００ポイントアップし、このターン相手プレイヤーに直接攻撃できない。\nこのターンのエンドフェイズ時、選択したモンスターの攻撃力は２０００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"パーシアスの神域",h:"ぱーしあすのしんいき",e:"The Sanctum of Parshath",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：このカードのカード名は、フィールド・墓地に存在する限り「天空の聖域」として扱う。\n②：このカードが魔法＆罠ゾーンに存在する限り、フィールドの天使族モンスターの攻撃力・守備力は３００アップし、フィールドにセットされた魔法・罠カードは効果の対象にならず、効果では破壊されない。\n③：１ターンに１度、自分の墓地の天使族モンスター及びカウンター罠カードの中から、合計３枚を対象として発動できる（同名カードは１枚まで）。\nそのカードを好きな順番でデッキの上に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バースト・リターン",h:"ばーすと・りたーん",e:"Burst Return",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「E・HERO バーストレディ」が自分フィールド上に表側表示で存在する時のみ発動する事ができる。\nフィールド上の「E・HERO バーストレディ」以外の「E・HERO」と名のついたモンスターを全て持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"パーティカル・フュージョン",h:"ぱーてぃかる・ふゅーじょん",e:"Particle Fusion",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上から、融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、「ジェムナイト」と名のついたその融合モンスター１体を融合召喚扱いとしてエクストラデッキから特殊召喚する。\nこの効果で融合召喚に成功した時、墓地のこのカードをゲームから除外し、その融合召喚に使用した「ジェムナイト」と名のついた融合素材モンスター１体を選択して発動する。\nその融合モンスターの攻撃力はエンドフェイズ時まで、選択したモンスターの攻撃力分アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バーニングナックル・スピリッツ",h:"ばーにんぐなっくる・すぴりっつ",e:"Battlin' Boxing Spirits",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"デッキの一番上のカードを墓地へ送って発動できる。\n自分の墓地の「BK」と名のついたモンスター１体を選択して表側守備表示で特殊召喚する。\n「バーニングナックル・スピリッツ」は１ターンに１枚しか発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バーニングブラッド",h:"ばーにんぐぶらっど",e:"Molten Destruction",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在する炎属性モンスターの攻撃力は５００ポイントアップし、守備力は４００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ハーピィ・レディ －鳳凰の陣－",h:"はーぴぃ・れでぃ　－ほうおうのじん－",e:"Harpie Lady Phoenix Formation",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカードを発動するターン、自分はデッキ・EXデッキからモンスターを特殊召喚できず、バトルフェイズを行えない。\n①：自分フィールドに「ハーピィ・レディ」「ハーピィ・レディ三姉妹」が合計３体以上存在する場合、その数まで可能な限り相手フィールドのモンスターを対象として発動できる。\n対象のモンスターを破壊し、破壊したモンスターの内、元々の攻撃力が一番高いモンスターのその数値分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ハーピィの狩場",h:"はーぴぃのかりば",e:"Harpies' Hunting Ground",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：フィールドの鳥獣族モンスターの攻撃力・守備力は２００アップする。\n②：自分または相手が、「ハーピィ・レディ」または「ハーピィ・レディ三姉妹」の召喚・特殊召喚に成功した場合、そのプレイヤーはフィールドの魔法・罠カード１枚を対象として発動する。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ハーピィの羽根休め",h:"はーぴぃのはねやすめ",e:"Harpie's Feather Rest",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地の「ハーピィ・レディ」「ハーピィ・レディ三姉妹」の中から合計３体を対象として発動できる。\nそのカードをデッキに加えてシャッフルする。\nその後、自分はデッキから１枚ドローする。\n自分フィールドにレベル５以上の「ハーピィ」モンスターが存在する状態で発動した場合にはドローする枚数は２枚になる。\nこのカードの発動後、ターン終了時まで自分は風属性モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ハーフ・シャット",h:"はーふ・しゃっと",e:"Half Shut",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターはターン終了時まで、攻撃力が半分になり、戦闘では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ハイ・スピード・リレベル",h:"はい・すぴーど・りれべる",e:"Hi-Speed Re-Level",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の墓地の「スピードロイド」モンスター１体を除外し、自分フィールドのSモンスター１体を対象として発動できる。\nそのモンスターはターン終了時まで、除外したモンスターと同じレベルになり、攻撃力は除外したモンスターのレベル×５００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"高等紋章術",h:"はい・めだりおん・あーつ",e:"Advanced Heraldry Art",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地の「紋章獣」と名のついたモンスター２体を選択して発動できる。\n選択したモンスター２体を特殊召喚し、その２体のみを素材としてエクシーズモンスター１体をエクシーズ召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ハイドロプレッシャーカノン",h:"はいどろぷれっしゃーかのん",e:"Hydro Pressure Cannon",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"レベル３以下の水属性モンスターにのみ装備可能。\n装備モンスターが戦闘によって相手モンスターを破壊した場合、相手の手札をランダムに１枚墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"覇王城",h:"はおうじょう",e:"Supreme King's Castle",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：このカードがフィールドゾーンに存在する限り、自分は「ダーク・フュージョン」の効果でのみ特殊召喚できる融合モンスターを「ダーク・フュージョン」の効果以外でも融合召喚できる。\n②：１ターンに１度、自分の悪魔族モンスターが相手モンスターと戦闘を行うダメージ計算時に、デッキ・EXデッキから「E-HERO」モンスター１体を墓地へ送って発動できる。\nその自分のモンスターの攻撃力はターン終了時まで、墓地へ送ったモンスターのレベル×２００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"墓穴の道連れ",h:"はかあなのみちづれ",e:"Dragged Down into the Grave",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：お互いのプレイヤーは、それぞれ相手の手札を確認し、その中からカードを１枚選んで捨てる。\nその後、お互いのプレイヤーは、それぞれデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"破壊剣士の宿命",h:"はかいけんしのしゅくめい",e:"Karma of the Destruction Swordsman",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"「破壊剣士の宿命」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手の墓地の同じ種族のモンスターを３体まで対象として発動できる。\nそのモンスターを除外し、自分フィールドの「バスター・ブレイダー」モンスターまたは「破壊剣」モンスター１体を選び、ターン終了時までその攻撃力・守備力を除外したモンスターの数×５００アップする。\n②：このカードが墓地に存在する場合、手札から「破壊剣」カード１枚を捨てて発動できる。\n墓地のこのカードを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"破壊剣士融合",h:"はかいけんしゆうごう",e:"Destruction Swordsman Fusion",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"「破壊剣士融合」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の手札及び自分・相手フィールドから、融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、「バスター・ブレイダー」を融合素材とするその融合モンスター１体をエクストラデッキから融合召喚する。\n②：このカードが墓地に存在する場合、手札を１枚墓地へ送って発動できる。\n墓地のこのカードを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"はがねの甲羅",h:"はがねのこうら",e:"Steel Shell",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"水属性モンスターにのみ装備可能。\n装備モンスターの攻撃力は４００ポイントアップし、守備力は２００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"墓掘りグール",h:"はかほりぐーる",e:"Gravedigger Ghoul",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"相手の墓地から１枚または２枚のモンスターカードを選択する。\n選択したカードをゲームから除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"墓守の石版",h:"はかもりのせきばん",e:"Gravekeeper's Stele",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地の「墓守の」と名のついたモンスター２体を選択して手札に加える。\nこの効果は「王家の眠る谷－ネクロバレー」の効果では無効化されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"光虫信号",h:"ばぐ・しぐなる",e:"Bug Signal",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"「光虫信号」は１ターンに１枚しか発動できない。\n①：自分フィールドの昆虫族Xモンスター１体を対象として発動できる。\nその自分のモンスターよりランクが２つ高い、またはランクが２つ低い昆虫族Xモンスター１体を、対象のモンスターの上に重ねてX召喚扱いとしてエクストラデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"光虫基盤",h:"ばぐ・まとりっくす",e:"Bug Matrix",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"「光虫基盤」の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドの昆虫族モンスターの攻撃力・守備力は３００アップする。\n②：自分フィールドの昆虫族Xモンスター１体を対象として発動できる。\n手札の昆虫族モンスター１体をそのモンスターの下に重ねてX素材とする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バグ・ロード",h:"ばぐ・ろーど",e:"Crashbug Road",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"お互いに自分フィールド上に表側表示で存在するレベル４以下のモンスター１体と同じレベルのモンスター１体を手札から特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"白銀の翼",h:"はくぎんのつばさ",e:"Silver Wing",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"レベル８以上のドラゴン族シンクロモンスターにのみ装備可能。\n装備モンスターは１ターンに２度まで、戦闘では破壊されない。\n装備モンスターがカードの効果によって破壊される場合、代わりにこのカードを破壊する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"白竜降臨",h:"はくりゅうこうりん",e:"White Dragon Ritual",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「白竜の聖騎士」の降臨に必要。\n①：レベルの合計が４以上になるように、自分の手札・フィールドのモンスターをリリースし、手札から「白竜の聖騎士」を儀式召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"破邪の大剣－バオウ",h:"はじゃのたいけん－ばおう",e:"Wicked-Breaking Flamberge - Baou",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"手札のカード１枚を墓地に送って装備する。\n装備モンスターの攻撃力は５００ポイントアップする。\nこのカードを装備したモンスターが戦闘で相手モンスターを破壊した場合、そのモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"破邪の魔法壁",h:"はじゃのまほうへき",e:"Sorcerous Spell Wall",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：自分フィールドのモンスターは、自分ターンの間は攻撃力が３００アップし、相手ターンの間は守備力が３００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バスター・テレポート",h:"ばすたー・てれぽーと",e:"Assault Teleport",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"手札から「／バスター」と名のついたモンスター１体をデッキに戻して発動できる。\nデッキからカードを２枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バスター・モード・ゼロ",h:"ばすたー・もーど・ぜろ",e:"Assault Mode Zero",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドのSモンスター１体をリリースして発動できる。\nそのモンスターのカード名が含まれる「／バスター」モンスター１体を、「バスター・モード」による特殊召喚扱いとして手札から特殊召喚する。\n②：自分メインフェイズに墓地のこのカードを除外して発動できる。\n手札・デッキから「バスター・モード」１枚を選んで自分の魔法＆罠ゾーンにセットする。\nこの効果でセットしたカードはセットしたターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バスターランチャー",h:"ばすたーらんちゃー",e:"Buster Rancher",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"攻撃力１０００以下のモンスターのみ装備可能。\nダメージ計算時、相手モンスターが攻撃表示なら攻撃力、守備表示なら守備力が２５００ポイント以上の場合、このカードを装備したモンスターの攻撃力は２５００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"充電器",h:"ばってりーちゃーじゃー",e:"Battery Charger",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"５００ライフポイントを払う。\n自分の墓地から「電池メン」という名のついたモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バッテリーリサイクル",h:"ばってりーりさいくる",e:"Recycling Batteries",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地の攻撃力１５００以下の雷族モンスター２体を選択して手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"破天荒な風",h:"はてんこうなかぜ",e:"Blustering Winds",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在するモンスター１体を選択して発動できる。\n選択したモンスターの攻撃力・守備力は、次の自分のスタンバイフェイズ時まで１０００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"波動キャノン",h:"はどうきゃのん",e:"Wave-Motion Cannon",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分のメインフェイズ時、フィールド上に表側表示で存在するこのカードを墓地へ送る事で、このカードの発動後に経過した自分のスタンバイフェイズの数×１０００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"波動共鳴",h:"はどうきょうめい",e:"Harmonic Waves",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在するモンスター１体を選択して発動する。\n選択したモンスターのレベルはエンドフェイズ時まで４になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"武闘円舞",h:"ばとるわるつ",e:"Battle Waltz",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在するシンクロモンスター１体を選択して発動する。\nそのモンスターと同じ種族・属性・レベル・攻撃力・守備力を持つ「ワルツトークン」１体を自分フィールド上に特殊召喚する。\nこのトークンの戦闘によって発生するお互いのプレイヤーへの戦闘ダメージは０になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"花積み",h:"はなづみ",e:"Flower Stacking",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「花積み」の②の効果は１ターンに１度しか使用できない。\n①：デッキから「花札衛」モンスター３種類を選び、好きな順番でデッキの上に戻す。\n②：墓地のこのカードを除外し、自分の墓地の「花札衛」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"パフォーム・パペット",h:"ぱふぉーむ・ぱぺっと",e:"Perform Puppet",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の墓地から「ギミック・パペット」モンスター１体を除外して発動できる。\n自分フィールドの全てのモンスターのレベルはターン終了時まで、除外したモンスターと同じレベルになる。\n②：自分フィールドの表側表示の「ギミック・パペット」モンスターが戦闘または相手の効果で破壊され墓地へ送られた場合、除外されている自分の「ギミック・パペット」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バブル・シャッフル",h:"ばぶる・しゃっふる",e:"Bubble Shuffle",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"「E・HERO バブルマン」がフィールド上に表側表示で存在する時のみ発動する事ができる。\n自分フィールド上に表側攻撃表示で存在する「E・HERO バブルマン」１体と相手フィールド上に表側攻撃表示で存在するモンスター１体を守備表示にする。\n守備表示にした「E・HERO バブルマン」１体を生け贄に捧げ、「E・HERO」と名のつくモンスター１体を手札から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バブル・ショット",h:"ばぶる・しょっと",e:"Bubble Blaster",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「E・HERO バブルマン」にのみ装備可能。\n装備モンスターの攻撃力は８００ポイントアップする。\n装備モンスターが戦闘で破壊される場合、代わりにこのカードを破壊し、装備モンスターのコントローラーへの戦闘ダメージを０にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バブルイリュージョン",h:"ばぶるいりゅーじょん",e:"Bubble Illusion",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"「E・HERO バブルマン」が自分フィールド上に表側表示で存在する時のみ発動する事ができる。\nこのターン、自分は手札から罠カード１枚を発動する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"破滅の儀式",h:"はめつのぎしき",e:"Ritual of Destruction",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「破滅の魔王ガーランドルフ」の降臨に必要。\n自分の手札・フィールド上から、レベルの合計が７以上になるようにモンスターをリリースしなければならない。\nまた、自分のメインフェイズ時に墓地のこのカードをゲームから除外する事で、このターン自分フィールド上の儀式モンスターが戦闘によって破壊したモンスターは墓地へ送らず持ち主のデッキの一番上に戻る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"破滅のフォトン・ストリーム",h:"はめつのふぉとん・すとりーむ",e:"Photon Stream of Destruction",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上に「ギャラクシーアイズ」と名のついたモンスターが存在する場合に発動できる。\nフィールド上のカード１枚を選択してゲームから除外する。\n自分フィールド上に「銀河眼の光子竜」が存在しない場合、このカードは自分のターンにしか発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バラエティ・アウト",h:"ばらえてぃ・あうと",e:"Variety Comes Out",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在するシンクロモンスター１体をエクストラデッキに戻して発動する。\nレベルの合計がそのシンクロモンスターのレベルと同じになるように、自分の墓地に存在するチューナーを選択して自分フィールド上に特殊召喚する。\nこのカードを発動するターン、自分はシンクロ召喚をする事ができない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"パラライズ・チェーン",h:"ぱららいず・ちぇーん",e:"Paralyzing Chain",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"カードの効果によって相手のデッキからカードが墓地へ送られる度に、相手ライフに３００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"パラレル・ツイスター",h:"ぱられる・ついすたー",e:"Parallel Twister",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：このカード以外の自分フィールドの魔法・罠カード１枚を墓地へ送り、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"平行世界融合",h:"ぱられる・わーるど・ふゅーじょん",e:"Parallel World Fusion",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカードを発動するターン、自分はこのカードの効果以外ではモンスターを特殊召喚できない。\n①：除外されている、「E・HERO」融合モンスターカードによって決められた自分の融合素材モンスターをデッキに戻し、その融合モンスター１体をエクストラデッキから融合召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"パルキオンのうろこ",h:"ぱるきおんのうろこ",e:"Barkion's Bark",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に「ナチュル」と名のついたモンスターが表側表示で存在する場合のみ発動する事ができる。\nこのターン、相手は罠カードを発動する事ができない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"晴れの天気模様",h:"はれのてんきもよう",e:"The Weather Sunny Canvas",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：「晴れの天気模様」は自分フィールドに１枚しか表側表示で存在できない。\n②：このカードと同じ縦列の自分のメインモンスターゾーン及びその両隣の自分のメインモンスターゾーンに存在する「天気」効果モンスターは以下の効果を得る。\n●このカードを除外し、自分フィールドのモンスター１体を対象として発動できる。\nその自分のモンスターをリリースし、そのモンスターとカード名が異なる「天気」モンスター１体を、自分の手札・墓地から選んで特殊召喚する。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"能力調整",h:"ぱわー・ちゅーん",e:"Level Tuning",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する全てのモンスターのレベルをエンドフェイズ時まで１つ下げる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"パワー・ピカクス",h:"ぱわー・ぴかくす",e:"Power Pickaxe",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"１ターンに１度、装備モンスターのレベル以下のレベルを持つ、相手の墓地に存在するモンスター１体を選択してゲームから除外し、エンドフェイズ時まで装備モンスターの攻撃力を５００ポイントアップする事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"パワーカプセル",h:"ぱわーかぷせる",e:"Power Capsule",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「ビクトリー・バイパー XX０３」１体を選択して発動する。\n「ビクトリー・バイパー XX０３」の効果から１つを選択し、このカードの効果として適用する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"繁華の花笑み",h:"はんかのはなえみ",e:"Flourishing Frolic",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地の「繁華の花笑み」の数＋３枚を自分のデッキの上からめくる。\nその中に３種類（モンスター・魔法・罠）のカードがあった場合、その内の１枚を選んで手札に加え、残りのカードを墓地へ送る。\n無かった場合、めくったカードを全てデッキに戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"蛮族の狂宴LV5",h:"ばんぞくのきょうえんれべる５",e:"Feast of the Wild LV5",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の手札・墓地から戦士族・レベル５モンスターを２体まで選んで特殊召喚する。\nこの効果で特殊召喚したモンスターは、効果が無効化され、このターン攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"万魔殿－悪魔の巣窟－",h:"ぱんでぃもにうむ－あくまのそうくつ－",e:"Pandemonium",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"「デーモン」という名のついたモンスターはスタンバイフェイズにライフを払わなくてよい。\n戦闘以外で「デーモン」という名のついたモンスターカードが破壊されて墓地に送られた時、そのカードのレベル未満の「デーモン」という名のついたモンスターカードをデッキから１枚選択して手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ハンバーガーのレシピ",h:"はんばーがーのれしぴ",e:"Hamburger Recipe",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「ハングリーバーガー」の降臨に必要。\nフィールドか手札から、レベルが６以上になるようカードを生け贄に捧げなければ、「ハングリーバーガー」は降臨できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ハンマーシュート",h:"はんまーしゅーと",e:"Hammer Shot",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：フィールドの攻撃表示モンスターの内、攻撃力が一番高いモンスター１体を破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"反目の従者",h:"はんもくのじゅうしゃ",e:"Vengeful Servant",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"装備モンスターのコントロールが移った時、装備モンスターのコントローラーに装備モンスターの元々の攻撃力分のダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"火あぶりの刑",h:"ひあぶりのけい",e:"Final Flame",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"相手ライフに６００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ビークロイド・コネクション・ゾーン",h:"びーくろいど・こねくしょん・ぞーん",e:"Vehicroid Connection Zone",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の手札・フィールドから、「ビークロイド」融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。\nこの効果で特殊召喚したモンスターは効果では破壊されず、そのモンスターの効果は無効化されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ビーストレイジ",h:"びーすとれいじ",e:"Beast Rage",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に存在する全てのモンスターの攻撃力は、ゲームから除外されている自分の獣族及び鳥獣族モンスターの数×２００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヒーロー・マスク",h:"ひーろー・ますく",e:"Hero Mask",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの表側表示モンスター１体を対象として発動できる。\nデッキから「E・HERO」モンスター１体を墓地へ送り、対象の自分の表側表示モンスターはエンドフェイズまで、この効果で墓地へ送ったモンスターと同名カードとして扱う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"HERO’S ボンド",h:"ひーろーず　ぼんど",e:"HERO's Bond",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"フィールド上に「HERO」と名のついたモンスターが存在している時に発動する事ができる。\n手札からレベル４以下の「E・HERO」と名のついたモンスター２体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"HEROの遺産",h:"ひーろーのいさん",e:"Legacy of a HERO",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「HEROの遺産」は１ターンに１枚しか発動できない。\n①：「HERO」モンスターを融合素材とする融合モンスター２体を自分の墓地からエクストラデッキに戻して発動できる。\n自分はデッキから３枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヒーローハート",h:"ひーろーはーと",e:"Hero Heart",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「E・HERO」と名のついたモンスター１体を選択して発動する。\nこのターン選択したモンスターの攻撃力は半分になり、１度のバトルフェイズ中に２回攻撃する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヒエログリフの石版",h:"ひえろぐりふのせきばん",e:"Hieroglyph Lithograph",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"１０００ライフポイントを払う。\nそのデュエル中、自分の手札制限枚数が７枚になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"光と闇の洗礼",h:"ひかりとやみのせんれい",e:"Dedication through Light and Darkness",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの「ブラック・マジシャン」１体をリリースして発動できる。\n自分の手札・デッキ・墓地から「混沌の黒魔術師」１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"光の援軍",h:"ひかりのえんぐん",e:"Charge of the Light Brigade",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分のデッキの上からカードを３枚墓地へ送って発動できる。\nデッキからレベル４以下の「ライトロード」モンスター１体を手札に加える。",li:2,lk:[],ta:[],al:[],ocg:true},
  {n:"光の結界",h:"ひかりのけっかい",e:"Light Barrier",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：自分スタンバイフェイズに発動する。\nコイントスを１回行い、裏が出た場合、このカードの②③の効果を次の自分スタンバイフェイズまで無効にする。\n②：自分の「アルカナフォース」モンスターの召喚・反転召喚・特殊召喚時に発動する効果は、コイントスを行わず裏表のどちらかを選んで適用する。\n③：自分の「アルカナフォース」モンスターが戦闘で相手モンスターを破壊した場合に発動する。\nその破壊されたモンスターの元々の攻撃力分だけ自分のLPを回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"光の護封陣",h:"ひかりのごふうじん",e:"Array of Revealing Light",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"種族を１つ宣言して発動する。\n宣言した種族のモンスターは召喚・反転召喚・特殊召喚されたターンに攻撃宣言をする事ができない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"光の角",h:"ひかりのつの",e:"Horn of Light",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"装備したモンスターの守備力は８００ポイントアップする。\nこのカードがフィールドから墓地に送られた時、５００ライフポイント払えばデッキの一番上に戻る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"光の導き",h:"ひかりのみちびき",e:"Beacon of White",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"①：自分フィールドに他の「光の導き」が存在せず、自分の墓地に「ブルーアイズ」モンスターが３体以上存在する場合、その内の１体を対象としてこのカードを発動できる。\nそのモンスターを効果を無効にして特殊召喚し、このカードを装備する。\nこのカードがフィールドから離れた時にそのモンスターは除外される。\n②：装備モンスター以外の自分のモンスターは攻撃できず、自分の墓地に「ブルーアイズ」モンスターが存在する場合、装備モンスターはその数まで１度のバトルフェイズ中に攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"光の霊堂",h:"ひかりのれいどう",e:"Mausoleum of White",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：このカードがフィールドゾーンに存在する限り、自分は通常召喚に加えて１度だけ、自分メインフェイズに光属性・レベル１チューナー１体を召喚できる。\n②：１ターンに１度、自分フィールドの表側表示モンスター１体を対象として発動できる。\n手札・デッキから通常モンスター１体を墓地へ送る。\n対象のモンスターの攻撃力・守備力はターン終了時まで、墓地へ送ったモンスターのレベル×１００アップする。\n③：墓地のこのカードを除外して発動できる。\nデッキから「滅びの爆裂疾風弾」１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"秘術の書",h:"ひじゅつのしょ",e:"Book of Secret Arts",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"魔法使い族モンスターにのみ装備可能。\n装備モンスターの攻撃力・守備力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"非常食",h:"ひじょうしょく",e:"Emergency Provisions",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：このカード以外の自分フィールドの魔法・罠カードを任意の数だけ墓地へ送って発動できる。\n自分はこのカードを発動するために墓地へ送ったカードの数×１０００LP回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"翡翠の蟲笛",h:"ひすいのむしぶえ",e:"Jade Insect Whistle",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"相手プレイヤーはデッキから昆虫族モンスター１体を選択し、デッキをシャッフルした後そのカードをデッキの一番上に置く。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヒステリック・サイン",h:"ひすてりっく・さいん",e:"Hysteric Sign",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：このカードの発動時の効果処理として、自分のデッキ・墓地から「万華鏡－華麗なる分身－」１枚を選んで手札に加える。\n②：このカードが手札・フィールドから墓地へ送られたターンのエンドフェイズに発動する。\nデッキから「ハーピィ」カードを３枚まで手札に加える（同名カードは１枚まで）。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ビッグバン・シュート",h:"びっぐばん・しゅーと",e:"Big Bang Shot",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"装備モンスターの攻撃力は４００ポイントアップする。\n装備モンスターが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。\nこのカードがフィールド上から離れた時、装備モンスターをゲームから除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"火の粉",h:"ひのこ",e:"Sparks",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"相手ライフに２００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"百獣大行進",h:"ひゃくじゅうだいこうしん",e:"The Big March of Animals",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する獣族モンスターの攻撃力は、エンドフェイズ時まで自分フィールド上の獣族モンスターの数×２００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"百機夜工",h:"ひゃっきやこう",e:"Factory of 100 Machines",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分の墓地に存在する「D」と名のついたモンスターを全てゲームから除外する。\n自分フィールド上に表側表示で存在する機械族モンスター１体の攻撃力はこのターンのエンドフェイズ時まで、この効果で除外したモンスター１体につき２００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヒュグロの魔導書",h:"ひゅぐろのまどうしょ",e:"Spellbook of Power",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの魔法使い族モンスター１体を対象として発動できる。\nこのターン、そのモンスターの攻撃力は１０００アップし、以下の効果を適用する。\n\n●そのモンスターが戦闘で相手モンスターを破壊した時に発動できる。\nデッキから「魔導書」魔法カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"氷結界の鏡",h:"ひょうけっかいのかがみ",e:"Mirror of the Ice Barrier",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：このターン相手が発動したモンスターの効果で、自分の手札・フィールド・墓地のカードが除外される度に、以下の効果を適用する。\n\n●手札：相手の手札をランダムに２枚まで選んで除外する。\n\n●フィールド：相手フィールドのカードを２枚まで選んで除外する。\n\n●墓地：相手の墓地のカードを２枚まで選んで除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"氷結界の三方陣",h:"ひょうけっかいのさんほうじん",e:"Magic Triangle of the Ice Barrier",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：カード名が異なる手札の「氷結界」モンスター３体を相手に見せ、相手フィールドのカード１枚を対象として発動できる。\nその相手のカードを破壊し、手札から「氷結界」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ピラミッドパワー",h:"ぴらみっどぱわー",e:"Pyramid Energy",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"次の効果から１つを選択して発動する事ができる。\n\n●自分フィールド上に表側表示で存在する全てのモンスターの攻撃力は、エンドフェイズ時まで２００ポイントアップする。\n\n●自分フィールド上に表側表示で存在する全てのモンスターの守備力は、エンドフェイズ時まで５００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヒロイック・チャンス",h:"ひろいっく・ちゃんす",e:"Heroic Chance",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上の「ヒロイック」と名のついたモンスター１体を選択して発動できる。\nこのターン、選択したモンスターは攻撃力が倍になり、相手プレイヤーに直接攻撃できない。\n「ヒロイック・チャンス」は１ターンに１枚しか発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ビンゴマシーンGO！GO！",h:"びんごましーんごー！ごー！",e:"Bingo Machine, Go!!!",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：デッキから以下のカードを合計３枚選んで相手に見せ、相手はその中からランダムに１枚選ぶ。\nそのカード１枚を自分の手札に加え、残りのカードはデッキに戻す。\n\n●「ブルーアイズ」モンスター\n●「ビンゴマシーンGO！GO！」以外の、「青眼の白龍」または「青眼の究極竜」のカード名が記された魔法・罠カード",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ファイティング・スピリッツ",h:"ふぁいてぃんぐ・すぴりっつ",e:"Fighting Spirit",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"装備モンスターの攻撃力は相手フィールド上に存在するモンスター１体につき３００ポイントアップする。\n装備モンスターが戦闘によって破壊される場合、代わりにこのカードを破壊する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ファイナル・インゼクション",h:"ふぁいなる・いんぜくしょん",e:"Final Inzektion",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「甲虫装機」と名のついたカード５枚を墓地へ送って発動できる。\n相手フィールド上のカードを全て破壊する。\n相手はこのターンのバトルフェイズ中に手札・墓地で発動する効果モンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ファイヤー・ソウル",h:"ふぁいやー・そうる",e:"Soul of Fire",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"相手はデッキからカードを１枚ドローする。\n自分のデッキから炎族モンスター１体を選んでゲームから除外し、除外したモンスターの攻撃力の半分のダメージを相手ライフに与える。\nこのカードを発動するターン、自分は攻撃宣言できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ファイヤー・ボール",h:"ふぁいやー・ぼーる",e:"Hinotama",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：相手に５００ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フィールドバリア",h:"ふぃーるどばりあ",e:"Field Barrier",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードがフィールド上に存在する限り、お互いにフィールド魔法カードを破壊できず、フィールド魔法カードの発動もできない。\n「フィールドバリア」は自分フィールド上に１枚しか表側表示で存在できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フィッシュアンドキックス",h:"ふぃっしゅあんどきっくす",e:"Fish and Kicks",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"ゲームから除外されている自分の魚族・海竜族・水族モンスターが３体以上の場合、フィールド上に存在するカード１枚を選択して発動する。\n選択したカードをゲームから除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フィッシュアンドバックス",h:"ふぃっしゅあんどばっくす",e:"Fish and Swaps",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"手札を１枚捨て、ゲームから除外されている自分の魚族・海竜族・水族モンスター２体を選択して発動できる。\n選択したモンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"封印の黄金櫃",h:"ふういんのおうごんひつ",e:"Gold Sarcophagus",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：デッキからカード１枚を選んで除外する。\nこのカードの発動後２回目の自分スタンバイフェイズに、この効果で除外したカードを手札に加える。",li:2,lk:[],ta:[],al:[],ocg:true},
  {n:"風雲カラクリ城",h:"ふううんからくりじょう",e:"Karakuri Showdown Castle",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「カラクリ」と名のついたモンスターが、相手フィールド上に表側表示で存在するモンスターを攻撃対象に選択した時、その相手モンスター１体の表示形式を変更する事ができる。\nまた、フィールド上に表側表示で存在するこのカードが破壊され墓地へ送られた時、自分の墓地に存在するレベル４以上の「カラクリ」と名のついたモンスター１体を選択して特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブーギートラップ",h:"ぶーぎーとらっぷ",e:"Boogie Trap",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：手札を２枚捨て、自分の墓地の罠カード１枚を対象として発動できる。\nそのカードを自分フィールドにセットする。\nこの効果でセットしたカードはセットしたターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"封魔一閃",h:"ふうまいっせん",e:"Flash of the Forbidden Spell",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"相手フィールド上のモンスターカードゾーン全てにモンスターが存在している時に発動する事ができる。\n相手フィールド上に存在する全てのモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"封魔の矢",h:"ふうまのや",e:"Anti-Magic Arrows",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカードの発動に対して魔法・罠・モンスターの効果は発動できない。\n①：自分または相手のバトルフェイズ開始時に発動できる。\nこのカードの発動後、ターン終了時までお互いに魔法・罠カードの効果を発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フェイク・ヒーロー",h:"ふぇいく・ひーろー",e:"Fake Hero",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"手札から「E・HERO」と名のついたモンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターは攻撃できず、このターンのエンドフェイズ時に持ち主の手札に戻る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フェイバリット・ヒーロー",h:"ふぇいばりっと・ひーろー",e:"Favorite Hero",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"レベル５以上の「HERO」モンスターにのみ装備可能。\nこのカード名の②の効果は１ターンに１度しか使用できない。\n①：自分のフィールドゾーンにカードが存在する場合、装備モンスターは、攻撃力が元々の守備力分アップし、相手の効果の対象にならない。\n②：自分・相手のバトルフェイズ開始時に発動できる。\n自分の手札・デッキからフィールド魔法カード１枚を選んで発動する。\n③：装備モンスターの攻撃で相手モンスターを破壊した時、このカードを墓地へ送って発動できる。\nその攻撃モンスターはもう１度だけ続けて攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フェザー・ウィンド・アタック",h:"ふぇざー・うぃんど・あたっく",e:"Raptor Wing Strike",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「BF」と名のついたモンスター１体をデッキに戻して発動する。\n自分のデッキから「BF」と名のついたモンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フェザー・ショット",h:"ふぇざー・しょっと",e:"Feather Shot",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「E・HERO フェザーマン」１体を選択して発動する。\nこのターン、選択したカードは自分フィールド上のモンスターの数だけ攻撃する事ができる。\nその場合、相手プレイヤーに直接攻撃をする事はできず、他の自分のモンスターは攻撃する事ができない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フォース",h:"ふぉーす",e:"Riryoku",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：フィールドの表側表示モンスター２体を対象として発動できる。\nターン終了時まで、対象のモンスター１体の攻撃力を半分にし、その数値分もう１体のモンスターの攻撃力をアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フォース・リリース",h:"ふぉーす・りりーす",e:"Unleash Your Power!",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカードの発動時に自分フィールド上に表側表示で存在する全てのデュアルモンスターは再度召喚した状態になる。\nこの効果を適用したモンスターはエンドフェイズ時に裏側守備表示になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フォーチュン・ヴィジョン",h:"ふぉーちゅん・う゛ぃじょん",e:"Fortune Vision",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：このカードの発動時の効果処理として、デッキから「フォーチュンレディ」カード１枚を手札に加える事ができる。\n②：１ターンに１度、自分フィールドのカードが効果で除外された場合に発動できる。\nこのターン、自分フィールドのモンスターは効果では破壊されない。\n③：１ターンに１度、相手フィールドのカードが効果で除外された場合に発動できる。\nこのターン、自分が受ける戦闘ダメージは１度だけ０になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フォーチュンフューチャー",h:"ふぉーちゅんふゅーちゃー",e:"Fortune's Future",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：除外されている自分の「フォーチュンレディ」モンスター１体を対象として発動できる。\nそのモンスターを墓地に戻す。\nその後、自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フォーチュンレディ・コーリング",h:"ふぉーちゅんれでぃ・こーりんぐ",e:"Fortune Lady Calling",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドに「フォーチュンレディ」モンスターが存在する場合に発動できる。\n同名カードが自分フィールドに存在しない「フォーチュンレディ」モンスター１体をデッキから特殊召喚する。\nこのカードの発動後、ターン終了時まで自分はSモンスターしかEXデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"F.A.オフロードGP",h:"ふぉーみゅらあすりーとおふろーどぐらんぷり",e:"F.A. Off-Road Grand Prix",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名の②③の効果はそれぞれ１ターンに１度しか使用できない。\n①：このカードがフィールドゾーンに存在する限り、フィールドの「F.A.」モンスターのレベルはメインフェイズの間だけ２つ上がる。\n②：自分の「F.A.」モンスターが戦闘で破壊された時に発動できる。\n相手の手札をランダムに１枚選んで捨てる。\n③：フィールドの表側表示のこのカードが効果で破壊された場合に発動できる。\nデッキから「F.A.オフロードGP」以外の「F.A.」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"F.A.サーキットGP",h:"ふぉーみゅらあすりーとさーきっとぐらんぷり",e:"F.A. Circuit Grand Prix",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名の③の効果は１ターンに１度しか使用できない。\n①：このカードがフィールドゾーンに存在する限り、フィールドの「F.A.」モンスターのレベルはバトルフェイズの間だけ２つ上がる。\n②：１ターンに１度、自分の「F.A.」モンスターが戦闘で相手モンスターを破壊した時に発動できる。\n自分はデッキから１枚ドローする。\n③：フィールドの表側表示のこのカードが効果で破壊された場合に発動できる。\nデッキから「F.A.サーキットGP」以外の「F.A.」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"F.A.ダウンフォース",h:"ふぉーみゅらあすりーとだうんふぉーす",e:"F.A. Downforce",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの「F.A.」モンスター１体を対象として発動できる。\nそのモンスターのレベルをターン終了時まで２つ上げる。\n②：自分メインフェイズに墓地のこのカードを除外し、自分フィールドの「F.A.」モンスター１体を対象として発動できる。\nそのモンスターのレベルをターン終了時まで２つ上げる。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"F.A.ピットストップ",h:"ふぉーみゅらあすりーとぴっとすとっぷ",e:"F.A. Pit Stop",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの「F.A.」モンスター１体を対象として発動できる。\nそのモンスターのレベルを２つ下げ、自分は自分の墓地の「F.A.ピットストップ」の数＋１枚をデッキからドローする。\n②：自分メインフェイズに墓地のこのカードを除外し、自分の墓地の「F.A.」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フォーム・チェンジ",h:"ふぉーむ・ちぇんじ",e:"Form Change",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの「HERO」融合モンスター１体を対象として発動できる。\nそのモンスターをエクストラデッキへ戻し、そのモンスターの元々のレベルと同じレベルでカード名が異なる「M・HERO」モンスター１体を、「マスク・チェンジ」による特殊召喚扱いとしてエクストラデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フォッグ・コントロール",h:"ふぉっぐ・こんとろーる",e:"Fog Control",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「雲魔物」と名のついたモンスター１体を生け贄に捧げる事で、フィールド上に表側表示で存在するモンスター１体にフォッグカウンターを３つ置く。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フォトン・ジェネレーター・ユニット",h:"ふぉとん・じぇねれーたー・ゆにっと",e:"Photon Generator Unit",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上の「サイバー・ドラゴン」２体を生け贄に捧げて発動する。\n自分の手札・デッキ・墓地から「サイバー・レーザー・ドラゴン」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フォトン・トライデント",h:"ふぉとん・とらいでんと",e:"Photon Trident",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上の「フォトン」と名のついたモンスター１体を選択して発動できる。\nエンドフェイズ時まで、選択したモンスターの攻撃力は７００ポイントアップし、守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。\nまた、選択したモンスターが相手ライフに戦闘ダメージを与えた時、フィールド上の魔法・罠カード１枚を選択して破壊できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フォトン・ブースター",h:"ふぉとん・ぶーすたー",e:"Photon Booster",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"トークン以外のフィールド上に表側表示で存在するレベル４以下の光属性モンスター１体を選択して発動する。\n選択したモンスター及びフィールド上に表側表示で存在する同名のモンスターの攻撃力は、エンドフェイズ時まで２０００になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フォトン・ベール",h:"ふぉとん・べーる",e:"Photon Veil",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の手札から光属性モンスター３体をデッキに戻し、自分のデッキから光属性・レベル４以下のモンスターを３体まで手札に加える事ができる。\n２体以上手札に加える場合は、全て同名モンスターでなければならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フォトン・リード",h:"ふぉとん・りーど",e:"Photon Lead",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"手札からレベル４以下の光属性モンスター１体を表側攻撃表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フォルテッシモ",h:"ふぉるてっしも",e:"Fortissimo",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：１ターンに１度、自分フィールドの「幻奏」モンスター１体を対象としてこの効果を発動できる。\nそのモンスターの攻撃力は次の自分スタンバイフェイズまで８００アップする。\n②：魔法＆罠ゾーンの表側表示のこのカードを墓地へ送って発動できる。\n「幻奏」融合モンスターカードによって決められた融合素材モンスターを自分フィールドから墓地へ送り、その融合モンスター１体をエクストラデッキから融合召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フォロー・ウィンド",h:"ふぉろー・うぃんど",e:"Follow Wind",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"鳥獣族のみ装備可能。\n装備モンスター１体の攻撃力と守備力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"孵化",h:"ふか",e:"Insect Imitation",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドのモンスター１体をリリースして発動できる。\nリリースしたモンスターよりレベルが１つ高い昆虫族モンスター１体をデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"不死式冥界砲",h:"ふししきめいかいほう",e:"Everliving Underworld Cannon",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分フィールド上にアンデット族モンスターが特殊召喚された時、相手ライフに８００ポイントダメージを与える。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"浮上",h:"ふじょう",e:"Surface",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の墓地のレベル３以下の魚族・海竜族・水族モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"武神決戦",h:"ぶじんけっせん",e:"Bujintervention",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"フィールド上の「武神」と名のついたモンスター１体を選択して発動できる。\nこのターン選択したモンスターが、そのモンスターの元々の攻撃力以上の攻撃力を持つモンスターを戦闘によって破壊した場合、破壊したそのモンスターを除外し、さらにその同名モンスターを相手の手札・デッキ・エクストラデッキ・墓地から全て除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"武神降臨",h:"ぶじんこうりん",e:"Bujincarnation",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"相手フィールド上にモンスターが存在し、自分フィールド上にモンスターが存在しない場合、自分の墓地の「武神」と名のついたモンスター１体と、ゲームから除外されている自分の「武神」と名のついたモンスター１体を選択して発動できる。\n選択したモンスター２体を特殊召喚する。\nこの効果で特殊召喚したモンスターをエクシーズ召喚の素材とする場合、獣族・獣戦士族・鳥獣族モンスターのエクシーズ召喚にしか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"武神集結",h:"ぶじんしゅうけつ",e:"Bujinunity",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上にこのカード以外のカードが存在しない場合に発動できる。\n自分の墓地の「武神」と名のついた獣戦士族モンスターを全てデッキに戻し、自分の手札を全て墓地へ送る。\nその後、デッキからカード名の異なる「武神」と名のついた獣戦士族モンスターを３体まで手札に加える事ができる。\n「武神集結」は１ターンに１枚しか発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"札再生",h:"ふださいせい",e:"Recardination",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の墓地の「花札衛」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\nその後、手札から「花札衛」モンスター１体を召喚条件を無視して特殊召喚できる。\n②：このカードが「花札衛」モンスターの効果で墓地へ送られた場合に発動できる。\n自分のデッキの上からカードを５枚めくる。\nその中から魔法・罠カード１枚を選んで手札に加える事ができる。\n残りのカードは好きな順番でデッキの上に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フュージョン・ウェポン",h:"ふゅーじょん・うぇぽん",e:"Fusion Weapon",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"レベル６以下の融合モンスターのみ装備可能。\n装備モンスターの攻撃力と守備力は１５００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フュージョン・ゲート",h:"ふゅーじょん・げーと",e:"Fusion Gate",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：お互いのプレイヤーは、自分メインフェイズに発動できる。\n自分の手札・フィールドから、融合モンスターカードによって決められた融合素材モンスターを除外し、その融合モンスター１体をEXデッキから融合召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"融合識別",h:"ふゅーじょん・たぐ",e:"Fusion Tag",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドのモンスター１体を対象として発動できる。\nEXデッキの融合モンスター１体を相手に見せる。\nこのターン、対象のモンスターを融合素材とする場合、その見せたモンスターの同名カードとして融合素材にできる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"融合回収",h:"ふゅーじょん・りかばりー",e:"Fusion Recovery",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の墓地の、「融合」１枚と融合召喚に使用した融合素材モンスター１体を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フューチャー・グロウ",h:"ふゅーちゃー・ぐろう",e:"Future Glow",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分の墓地に存在するサイキック族モンスター１体をゲームから除外して発動する。\nこのカードがフィールド上に存在する限り、自分フィールド上に表側表示で存在する全てのサイキック族モンスターの攻撃力は、このカードを発動するために除外したモンスターのレベル×２００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブラック・コア",h:"ぶらっく・こあ",e:"Dark Core",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の手札を１枚捨てる。\nフィールド上の表側表示のモンスター１体をゲームから除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"黒・魔・導・爆・裂・破",h:"ぶらっく・ばーにんぐ",e:"Dark Burning Attack",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドに「ブラック・マジシャン・ガール」モンスターが存在する場合に発動できる。\n相手フィールドの表側表示モンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"黒いペンダント",h:"ぶらっく・ぺんだんと",e:"Black Pendant",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"①：装備モンスターの攻撃力は５００アップする。\n②：このカードがフィールドから墓地へ送られた場合に発動する。\n相手に５００ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"黒・魔・導",h:"ぶらっく・まじっく",e:"Dark Magic Attack",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドに「ブラック・マジシャン」が存在する場合に発動できる。\n相手フィールドの魔法・罠カードを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"黒・魔・導・連・弾",h:"ぶらっくついんばーすと",e:"Dark Magic Twin Burst",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの「ブラック・マジシャン」１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで、お互いのフィールド・墓地の「ブラック・マジシャン・ガール」の攻撃力の合計分アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブラックフェザー・シュート",h:"ぶらっくふぇざー・しゅーと",e:"Black-Winged Strafe",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"手札から「BF」と名のついたモンスター１体を墓地へ送り、相手フィールド上に守備表示で存在するモンスター１体を選択して発動する。\n選択したモンスターを墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブランチ",h:"ぶらんち",e:"Branch!",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"融合モンスターがフィールド上で破壊され墓地に送られた時、自分の墓地に存在するその融合モンスターの融合に使用した融合素材モンスター１体を特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブリザード",h:"ぶりざーど",e:"Blizzard",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：相手フィールドの表側表示の魔法カード１枚を対象として発動できる。\nこのターン、そのカード及びそのカードと元々のカード名が同じ魔法カードがフィールドで発動する効果は無効化される。\nこのターン中に対象のカードが相手の墓地へ送られる場合、墓地へは行かず相手の手札に戻る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"プリベント・スター",h:"ぷりべんと・すたー",e:"Prevention Star",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側攻撃表示で存在するモンスターの表示形式が表側守備表示に変更されたターン、そのモンスターにのみ装備可能。\n相手フィールド上に存在するモンスター１体を選択する。\nそのモンスターは攻撃と表示形式の変更ができない。\n装備モンスターが破壊される事によってこのカードが墓地へ送られた時、選択した相手モンスターをゲームから除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フリント",h:"ふりんと",e:"Flint",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"このカードの装備モンスターは、表示形式の変更と攻撃宣言ができなくなり、攻撃力が３００ポイントダウンする。\n装備モンスターが破壊された場合、フィールド上のモンスターを１体選択し、そのモンスターにこのカードを装備する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブルー・ポーション",h:"ぶるー・ぽーしょん",e:"Blue Medicine",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分は４００LP回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブルーメンブラット",h:"ぶるーめんぶらっと",e:"Rose Bud",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上の「E・HERO クノスペ」１体を生け贄に捧げて発動する。\n自分の手札またはデッキから「E・HERO ブルーメ」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブレイク・オブ・ザ・ワールド",h:"ぶれいく・おぶ・ざ・わーるど",e:"Breaking of the World",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：１ターンに１度、自分フィールドの儀式モンスター１体を対象として発動できる。\n手札の儀式モンスター１体をターン終了時まで公開する。\nその公開した儀式モンスターのレベルはターン終了時まで、対象のモンスターのレベルと同じになる。\n②：１ターンに１度、自分フィールドに「破滅の女神ルイン」または「終焉の王デミス」が儀式召喚された場合、以下の効果から１つを選択して発動できる。\n\n●自分はデッキから１枚ドローする。\n\n●フィールドのカード１枚を選んで破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブレイク・ドロー",h:"ぶれいく・どろー",e:"Break! Draw!",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"機械族モンスターにのみ装備可能。\n装備モンスターが戦闘によって相手モンスターを破壊し墓地へ送った時、自分のデッキからカードを１枚ドローする。\nこのカードは発動後３回目の自分のエンドフェイズ時に破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブレイズ・キャノン",h:"ぶれいず・きゃのん",e:"Blaze Accelerator",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分のメインフェイズ時に相手フィールド上のモンスター１体を選択して発動できる。\n手札から攻撃力５００以下の炎族モンスター１体を墓地へ送り、選択した相手モンスターを破壊する。\nこの効果を発動するターン、自分のモンスターは攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブレイズ・キャノン－トライデント",h:"ぶれいず・きゃのん－とらいでんと",e:"Tri-Blaze Accelerator",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードは自分フィールド上に表側表示で存在する「ブレイズ・キャノン」１枚を墓地へ送って発動できる。\nまた、自分のメインフェイズ時、相手フィールド上のモンスター１体を選択して発動できる。\n手札から炎族モンスター１体を墓地へ送り、選択した相手モンスターを破壊し相手ライフに５００ポイントダメージを与える。\nこの効果を発動するターン、自分のモンスターは攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フレグランス・ストーム",h:"ふれぐらんす・すとーむ",e:"Fragrance Storm",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：フィールドの植物族モンスター１体を対象として発動できる。\nその植物族モンスターを破壊し、自分はデッキから１枚ドローする。\nそのドローしたカードが植物族モンスターだった場合、さらにそのカードをお互いに確認して自分はデッキから１枚ドローできる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"プレゼント交換",h:"ぷれぜんとこうかん",e:"Gift Exchange",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：お互いのプレイヤーは、それぞれ自分のデッキからカード１枚を選んで裏側表示で除外する。\nこのターンのエンドフェイズに、お互いはそれぞれ相手となるプレイヤーが除外したそのカードを自分の手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"捕食接ぎ木",h:"ぷれでたー・ぐらふと",e:"Predapruning",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"①：自分の墓地の「捕食植物」モンスター１体を対象としてこのカードを発動できる。\nそのモンスターを特殊召喚し、このカードを装備する。\nこのカードがフィールドから離れた時にそのモンスターは破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"捕食活動",h:"ぷれでたー・ぷらくてぃす",e:"Predapractice",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：手札から「捕食植物」モンスター１体を特殊召喚する。\nその後、デッキから「捕食活動」以外の「プレデター」カード１枚を手札に加える。\nこのカードの発動後、ターン終了時まで自分は融合モンスターしかEXデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"プレデター・プランター",h:"ぷれでたー・ぷらんたー",e:"Predaponics",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードのコントローラーは、自分スタンバイフェイズ毎に８００LPを払う。\nまたはLPを払わずにこのカードを破壊する。\n①：１ターンに１度、自分メインフェイズに発動できる。\n自分の手札・墓地からレベル４以下の「捕食植物」モンスター１体を選んで特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"陽炎柱",h:"へいずぴらー",e:"Hazy Pillar",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードがフィールド上に存在する限り、自分は「陽炎獣」と名のついたモンスターを召喚する場合に必要なリリースを１体少なくする事ができる。\nまた、１ターンに１度、自分フィールド上のエクシーズモンスター１体を選択して発動できる。\n自分の手札・フィールド上の「陽炎獣」と名のついたモンスター１体を、選択したエクシーズモンスターの下に重ねてエクシーズ素材とする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヘル・アライアンス",h:"へる・あらいあんす",e:"Chthonian Alliance",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在する装備モンスターと同名のモンスター１体につき、装備モンスターの攻撃力は８００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヘル・テンペスト",h:"へる・てんぺすと",e:"Inferno Tempest",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"３０００ポイント以上の戦闘ダメージを受けた時に発動する事ができる。\nお互いのデッキと墓地のモンスターを全てゲームから除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヘルモスの爪",h:"へるもすのつめ",e:"The Claw of Hermos",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカードのカード名はルール上「伝説の竜 ヘルモス」としても扱う。\n「ヘルモスの爪」は１ターンに１枚しか発動できない。\n①：「ヘルモスの爪」の効果でのみ特殊召喚できる融合モンスターカードに記された種族のモンスター１体を自分の手札・フィールドから墓地へ送る（そのカードがフィールドにセットされている場合、めくって確認する）。\nその後、その融合モンスター１体をエクストラデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ペンデュラム・パラドックス",h:"ぺんでゅらむ・ぱらどっくす",e:"Pendulum Paradox",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分のEXデッキの表側表示のPモンスターの中から、Pスケールが同じでカード名が異なるモンスター２体を選んで手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ポイズン・チェーン",h:"ぽいずん・ちぇーん",e:"Poison Chain",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分のターンに戦闘を行っていない場合、エンドフェイズ時に自分フィールド上に表側表示で存在する「C」と名のついたモンスターの数だけ、相手のデッキの上からカードを墓地へ送る事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ポイズン・ファング",h:"ぽいずん・ふぁんぐ",e:"Poison Fangs",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"獣族モンスターが相手プレイヤーに戦闘ダメージを与える度に、相手ライフに５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"鳳凰神の羽根",h:"ほうおうしんのはね",e:"A Feather of the Phoenix",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"手札を１枚捨て、自分の墓地のカード１枚を選択して発動できる。\n選択したカードをデッキの一番上に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"方界業",h:"ほうかいかるま",e:"Cubic Karma",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：このカードの発動時に、「方界胤ヴィジャム」以外の自分フィールドの「方界」モンスター１体を対象にできる。\nその場合、手札・デッキから「方界胤ヴィジャム」を任意の枚数墓地へ送る。\nその後、対象のモンスターの攻撃力は、この効果で墓地へ送ったモンスターの数×８００アップする。\n②：相手ターンに「方界」モンスターの効果で「方界胤ヴィジャム」が特殊召喚された場合に発動する。\nこのカードを墓地へ送り、相手のLPを半分にする。\n③：墓地のこのカードを除外して発動できる。\nデッキから「方界」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"方界法",h:"ほうかいだーま",e:"Cubic Dharma",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①③の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\n手札から「方界」カード１枚を墓地へ送り、自分はデッキから１枚ドローする。\n②：このカードが魔法＆罠ゾーンに存在する限り、自分の「方界」モンスターの戦闘で発生する自分への戦闘ダメージは０になる。\n③：墓地のこのカードを除外し、自分の墓地の「方界」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"忘却の都 レミューリア",h:"ぼうきゃくのみやこ　れみゅーりあ",e:"Lemuria, the Forgotten City",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカードのカード名は「海」として扱う。\nこのカードがフィールド上に存在する限り、フィールド上の水属性モンスターの攻撃力・守備力は２００ポイントアップする。\nまた、１ターンに１度、自分のメインフェイズ時に発動できる。\nこのカードがフィールド上に存在する限り、自分フィールド上の水属性モンスターの数と同じ数だけ、自分フィールド上の水属性モンスターのレベルをエンドフェイズ時まで上げる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"宝玉の解放",h:"ほうぎょくのかいほう",e:"Crystal Release",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「宝玉獣」モンスターにのみ装備可能。\n①：装備モンスターの攻撃力は８００アップする。\n②：このカードがフィールドから墓地へ送られた時に発動できる。\nデッキから「宝玉獣」モンスター１体を永続魔法カード扱いとして自分の魔法＆罠ゾーンに表側表示で置く。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"宝玉の樹",h:"ほうぎょくのき",e:"Crystal Tree",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在する限り、「宝玉獣」モンスターが魔法＆罠ゾーンに置かれる度に、このカードにジェムカウンターを１つ置く。\n②：ジェムカウンターが置かれているこのカードを墓地へ送って発動できる。\nこのカードに置かれていたジェムカウンターの数だけ、デッキから「宝玉獣」モンスターを永続魔法カード扱いとして自分の魔法＆罠ゾーンに表側表示で置く。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"宝玉の絆",h:"ほうぎょくのきずな",e:"Crystal Bond",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：デッキから「宝玉獣」モンスター１体を手札に加え、そのモンスターとカード名が異なる「宝玉獣」モンスター１体をデッキから選び、永続魔法カード扱いとして自分の魔法＆罠ゾーンに表側表示で置く。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"宝玉の契約",h:"ほうぎょくのけいやく",e:"Crystal Promise",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の魔法＆罠カードゾーンの「宝玉獣」と名のついたカード１枚を選択して特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"宝玉の導き",h:"ほうぎょくのみちびき",e:"Crystal Beacon",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の魔法＆罠カードゾーンに「宝玉獣」と名のついたカードが２枚以上存在する場合、デッキから「宝玉獣」と名のついたモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"宝玉の恵み",h:"ほうぎょくのめぐみ",e:"Crystal Blessing",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地の「宝玉獣」と名のついたモンスターを２体まで選択して発動できる。\n選択したモンスターを永続魔法カード扱いとして自分の魔法＆罠カードゾーンに表側表示で置く。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"防御輪",h:"ぼうぎょりん",e:"Ring of Defense",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"罠カードの効果によるダメージを０にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"封神鏡",h:"ほうしんきょう",e:"Fengsheng Mirror",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"相手の手札を見る。\nスピリットモンスターが手札に存在する場合、スピリットモンスター１体を選択して墓地に捨てる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"暴走する魔力",h:"ぼうそうするまりょく",e:"That Wacky Magic!",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地の魔法カードを全てゲームから除外して発動できる。\n除外した魔法カードの数×３００ポイント以下の守備力を持つ、相手フィールド上に表側表示で存在するモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ホープ・オブ・フィフス",h:"ほーぷ・おぶ・ふぃふす",e:"Fifth Hope",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地の「E・HERO」と名のついたカード５枚を選択し、デッキに加えてシャッフルする。\nその後、デッキからカードを２枚ドローする。\nこのカードの発動時に自分の手札・フィールド上に他のカードが存在しない場合はカードを３枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ホープ剣スラッシュ",h:"ほーぷけんすらっしゅ",e:"Rising Sun Slash",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「希望皇ホープ」モンスターにのみ装備可能。\n①：装備モンスターは効果では破壊されない。\n②：このカードが魔法＆罠ゾーンに存在する限り、モンスターの攻撃が無効になる度に、このカードにホープ剣カウンターを１つ置く。\n装備モンスターの攻撃力は、このカードのホープ剣カウンターの数×５００アップする。\n③：自分フィールドの装備モンスターがX素材を取り除いて効果を発動する場合、このカードは取り除くX素材の１つとして扱う事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"撲滅の使徒",h:"ぼくめつのしと",e:"Nobleman of Extermination",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"フィールド上にセットされた魔法・罠カード１枚を選択して破壊し、ゲームから除外する。\nそれが罠カードだった場合、お互いのデッキを確認し、同名カードを全てゲームから除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"星屑のきらめき",h:"ほしくずのきらめき",e:"Stardust Shimmer",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地に存在するドラゴン族のシンクロモンスター１体を選択して発動する。\nそのモンスターのレベルと同じレベルになるように、選択したモンスター以外の自分の墓地に存在するモンスターをゲームから除外し、選択したモンスターを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ポジションチェンジ",h:"ぽじしょんちぇんじ",e:"Senet Switch",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分フィールド上モンスター１体の位置を、使用していない隣のモンスターカードゾーンに移動する事ができる。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"星に願いを",h:"ほしにねがいを",e:"Star Light, Star Bright",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在するモンスター１体を選択して発動する。\n選択したモンスターと同じ攻撃力または同じ守備力を持つ自分フィールド上のモンスターのレベルは、エンドフェイズ時まで選択したモンスターと同じになる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"補充部隊",h:"ほじゅうぶたい",e:"Backup Squad",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：相手モンスターの攻撃または相手の効果で自分が１０００以上のダメージを受ける度に発動する。\nそのダメージ１０００につき１枚、自分はデッキからドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ボスラッシュ",h:"ぼすらっしゅ",e:"Boss Rush",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分が通常召喚していないターンにこのカードを発動できる。\n①：このカードが魔法＆罠ゾーンに存在する限り、自分は通常召喚できない。\n②：自分フィールドの表側表示の「巨大戦艦」モンスターが破壊され墓地へ送られた場合、そのターンのエンドフェイズに発動できる。\nデッキから「巨大戦艦」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ポセイドンの力",h:"ぽせいどんのちから",e:"Power of Kaishin",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"水族のみ装備可能。\n装備モンスター１体の攻撃力と守備力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"墓地封印",h:"ぼちふういん",e:"Silent Graveyard",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：手札を１枚捨てて発動できる。\nこのターン、墓地で発動するカードの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ポップルアップ",h:"ぽっぷるあっぷ",e:"Pop-Up",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"「ポップルアップ」は１ターンに１枚しか発動できない。\n①：相手のフィールドゾーンにカードが存在し、自分のフィールドゾーンにカードが存在しない場合に発動できる。\nデッキからフィールド魔法カード１枚を発動する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ポルターガイスト",h:"ぽるたーがいすと",e:"Spiritualism",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：相手フィールドの魔法・罠カード１枚を対象として発動できる。\nその相手のカードを持ち主の手札に戻す。\nこのカードの発動と効果は無効化されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"滅びの爆裂疾風弾",h:"ほろびのばーすとすとりーむ",e:"Burst Stream of Destruction",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカードを発動するターン、自分の「青眼の白龍」は攻撃できない。\n①：自分フィールドに「青眼の白龍」が存在する場合に発動できる。\n相手フィールドのモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"白の救済",h:"ほわいと・さるべーじ",e:"Whitefish Salvage",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分の墓地の魚族モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\n②：このカードが相手の効果で破壊され墓地へ送られた場合に発動できる。\nデッキから魚族モンスター１体を手札に加えるか特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"凡骨の意地",h:"ぼんこつのいじ",e:"Heart of the Underdog",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"ドローフェイズにドローしたカードが通常モンスターだった場合、そのカードを相手に見せる事で、自分はカードをもう１枚ドローする事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ポンコツの意地",h:"ぽんこつのいじ",e:"Guts of Steel",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地に存在する「スクラップ」と名のついたモンスター３体を選択して発動する。\n相手はその中から１体を選択する。\nその後、相手が選択したモンスターを自分または相手フィールド上に特殊召喚し、残りのカードをゲームから除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ボンディング－H2O",h:"ぼんでぃんぐ－えいちつーおー",e:"Bonding - H2O",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に存在する「ハイドロゲドン」２体と「オキシゲドン」１体を生け贄に捧げる。\n自分の手札・デッキ・墓地から「ウォーター・ドラゴン」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ボンディング－D2O",h:"ぼんでぃんぐ－でぃーつーおー",e:"Bonding - D2O",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分の手札・フィールドの「デューテリオン」２体と「オキシゲドン」１体をリリースして発動できる。\n自分の手札・デッキ・墓地から「ウォーター・ドラゴン」または「ウォーター・ドラゴン－クラスター」１体を選んで「ボンディング－H２O」の効果扱いとして特殊召喚する。\n②：このカードが墓地に存在し、「ウォーター・ドラゴン」または「ウォーター・ドラゴン－クラスター」がフィールドから自分の墓地へ送られた場合に発動する。\n墓地のこのカードを手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"埋葬されし生け贄",h:"まいそうされしいけにえ",e:"Tribute Burial",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：このターン、自分がモンスター２体のリリースを必要とするアドバンス召喚をする場合に１度だけ、モンスター２体をリリースせずに自分・相手の墓地からモンスターを１体ずつ除外してアドバンス召喚できる。\nこの効果の発動後、ターン終了時まで自分はモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔界大道具「ニゲ馬車」",h:"まかいおおどうぐ「にげばしゃ」",e:"Abyss Prop - Wild Wagon",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在する限り、自分の「魔界劇団」モンスターはそれぞれ１ターンに１度だけ戦闘では破壊されない。\n②：１ターンに１度、自分フィールドの「魔界劇団」モンスター１体を対象として発動できる。\n相手ターン終了時まで、相手はそのモンスターを効果の対象にできない。\n③：自分のエクストラデッキに表側表示の「魔界劇団」Pモンスターが存在し、セットされたこのカードが相手の効果で破壊された場合に発動できる。\n相手フィールドのカードを全て持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔界台本「オープニング・セレモニー」",h:"まかいだいほん「おーぷにんぐ・せれもにー」",e:"Abyss Script - Opening Ceremony",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分は自分フィールドの「魔界劇団」モンスターの数×５００LP回復する。\n②：自分のEXデッキに表側表示の「魔界劇団」Pモンスターが存在し、セットされたこのカードが相手の効果で破壊された場合に発動できる。\n自分は手札が５枚になるようにデッキからドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔界台本「火竜の住処」",h:"まかいだいほん「かりゅうのすみか」",e:"Abyss Script - Fire Dragon's Lair",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「魔界台本「火竜の住処」」の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドの「魔界劇団」モンスター１体を対象として発動できる。\nこのターン、そのモンスターが戦闘で相手モンスターを破壊した場合、相手はエクストラデッキからモンスター３体を選んで除外する。\n②：自分のエクストラデッキに表側表示の「魔界劇団」Pモンスターが存在し、セットされたこのカードが相手の効果で破壊された場合に発動できる。\n相手のエクストラデッキを確認し、その内の１枚を選んで除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔界台本「ファンタジー・マジック」",h:"まかいだいほん「ふぁんたじー・まじっく」",e:"Abyss Script - Fantasy Magic",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの「魔界劇団」モンスター１体を対象として発動できる。\nこのターン、そのモンスターとの戦闘で破壊されなかったモンスターは、ダメージステップ終了時に持ち主の手札に戻る。\n②：自分のEXデッキに表側表示の「魔界劇団」Pモンスターが存在し、セットされたこのカードが相手の効果で破壊された場合、相手フィールドのカード１枚を対象として発動できる。\nそのカードを持ち主のデッキの一番上に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔界台本「魔王の降臨」",h:"まかいだいほん「まおうのこうりん」",e:"Abyss Script - Rise of the Abyss King",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの攻撃表示の「魔界劇団」モンスターの種類の数まで、フィールドの表側表示のカードを対象として発動できる。\nそのカードを破壊する。\n自分フィールドにレベル７以上の「魔界劇団」モンスターが存在する場合、このカードの発動に対して相手は効果を発動できない。\n②：自分のEXデッキに表側表示の「魔界劇団」Pモンスターが存在し、セットされたこのカードが相手の効果で破壊された場合に発動できる。\nデッキから「魔界劇団」カードまたは「魔界台本」魔法カードを合計２枚まで手札に加える（同名カードは１枚まで）。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔界台本「魔界の宴咜女」",h:"まかいだいほん「まかいのえんため」",e:"Abyss Script - Abysstainment",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①の効果は１ターンに２度まで使用できる。\n①：自分フィールドの「魔界劇団」モンスター１体をリリースし、自分の墓地の「魔界台本」魔法カード１枚を対象として発動できる。\nそのカードを自分フィールドにセットする。\n②：自分のEXデッキに表側表示の「魔界劇団」Pモンスターが存在し、セットされたこのカードが相手の効果で破壊された場合に発動できる。\nデッキから「魔界劇団」Pモンスターを任意の数だけ特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔界台本「ロマンティック・テラー」",h:"まかいだいほん「ろまんてぃっく・てらー」",e:"Abyss Script - Romantic Terror",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの「魔界劇団」Pモンスター１体を選んで持ち主の手札に戻し、手札に戻ったモンスターと元々のカード名が異なる表側表示の「魔界劇団」Pモンスター１体を自分のEXデッキから守備表示で特殊召喚する。\n②：自分のEXデッキに表側表示の「魔界劇団」Pモンスターが存在し、セットされたこのカードが相手の効果で破壊された場合に発動できる。\nデッキから「魔界台本」魔法カードを任意の数だけ選んで自分の魔法＆罠ゾーンにセットする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔界の足枷",h:"まかいのあしかせ",e:"Darkworld Shackles",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"装備モンスターは攻撃する事ができず、攻撃力・守備力は１００になる。\nまた、自分のスタンバイフェイズ毎に、装備モンスターのコントローラーに５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔霧雨",h:"まきう",e:"Makiu, the Magical Mist",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカードを発動するターン、自分はバトルフェイズを行えない。\n①：自分のモンスターゾーンの、「デーモンの召喚」または雷族モンスター１体を対象として発動できる。\nそのモンスターの攻撃力以下の守備力を持つ、相手フィールドのモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"巻きすぎた発条",h:"まきすぎたぜんまい",e:"Zenmailfunction",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地に存在する「ゼンマイ」と名のついたモンスター１体を選択して発動する。\n選択したモンスターを表側守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化され、リリースできず、シンクロ素材とする事もできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔菌",h:"まきん",e:"Vile Germs",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"植物族のみ装備可能。\n装備モンスター１体の攻撃力と守備力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マグネット・フィールド",h:"まぐねっと・ふぃーるど",e:"Magnetic Field",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"「マグネット・フィールド」の①の効果は１ターンに１度しか使用できない。\n①：自分フィールドにレベル４以下の岩石族・地属性モンスターが存在する場合、自分の墓地のレベル４以下の「マグネット・ウォリアー」モンスター１体を対象としてこの効果を発動できる。\nそのモンスターを特殊召喚する。\n②：１ターンに１度、自分の岩石族・地属性モンスターとの戦闘で相手モンスターが破壊されなかったダメージステップ終了時に発動できる。\nその相手モンスターを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マグネット・リバース",h:"まぐねっと・りばーす",e:"Magnet Reverse",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分の墓地のモンスター及び除外されている自分のモンスターの中から、機械族・岩石族の通常召喚できないモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"磁力の召喚円 LV2",h:"まぐねっとさーくる　れべる２",e:"Magnet Circle LV2",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"手札からレベル２以下の機械族モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"円融魔術",h:"まじからいず・ふゅーじょん",e:"Magicalized Fusion",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分のフィールド・墓地から、魔法使い族の融合モンスターカードによって決められた融合素材モンスターを除外し、その融合モンスター１体をEXデッキから融合召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マジカル・スター・イリュージョン",h:"まじかる・すたー・いりゅーじょん",e:"Magical Star Illusion",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドのモンスターの数が相手フィールドのモンスターの数以下の場合に発動できる。\n自分及び相手フィールドのモンスターの攻撃力は、ターン終了時までそのモンスターのコントローラーのフィールドのモンスターのレベルの合計×１００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マジシャンズ・クロス",h:"まじしゃんず・くろす",e:"Magicians Unite",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドに攻撃表示の魔法使い族モンスターが２体以上存在する場合、その内の１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで３０００になる。\nこのカードの発動後、ターン終了時までそのモンスター以外の魔法使い族モンスターは攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔力隔壁",h:"まじっく・うぉーる",e:"Spell Wall",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：以下の効果から１つを選択して発動できる。\nこのカードの発動後、ターン終了時まで相手が受ける全てのダメージは０になる。\n\n●このターン、魔法使い族モンスターの召喚・反転召喚・特殊召喚は無効化されず、魔法使い族モンスターの召喚・反転召喚・特殊召喚成功時に相手は魔法・罠・モンスターの効果を発動できない。\n\n●自分フィールドの魔法使い族モンスター１体を対象として発動できる。\nこのターンそのモンスターが攻撃する場合、相手はダメージステップ終了時まで魔法・罠・モンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"黒魔導強化",h:"まじっく・えくすぱんど",e:"Dark Magic Expanded",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：お互いのフィールド・墓地の「ブラック・マジシャン」「ブラック・マジシャン・ガール」の数によって以下の効果を適用する。\n●１体以上：フィールドの魔法使い族・闇属性モンスター１体を選び、その攻撃力をターン終了時まで１０００アップする。\n●２体以上：このターン、自分の魔法・罠カードの効果の発動に対して、相手は魔法・罠・モンスターの効果を発動できず、自分フィールドの魔法・罠カードは相手の効果では破壊されない。\n●３体以上：自分フィールドの魔法使い族・闇属性モンスターはターン終了時まで相手の効果を受けない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マジック・ガードナー",h:"まじっく・がーどなー",e:"Magic Reflector",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する魔法カード１枚を選択して発動する。\n選択したカードにカウンターを１つ置く。\n選択したカードが破壊される場合、代わりにそのカウンターを１つ取り除く。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔法の歯車",h:"まじっく・ぎあ",e:"Spell Gear",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの表側表示の「アンティーク・ギア」カード３枚を墓地へ送って発動できる。\n手札及びデッキからそれぞれ１体まで、「古代の機械巨人」を召喚条件を無視して特殊召喚する。\nその後、自分フィールドに「古代の機械巨人」以外のモンスターが存在する場合、そのモンスターを全て破壊する。\nこの効果の発動後、自分のターンで数えて２ターンの間、自分は通常召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マジック・クロニクル",h:"まじっく・くろにくる",e:"Spell Chronicle",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：手札を全て墓地へ送ってこのカードを発動できる。\nデッキから魔法・罠カード５枚を除外する。\n②：相手が魔法カードを発動する度に、このカードにクロニクルカウンターを１つ置く。\n③：このカードのクロニクルカウンターを２つ取り除いて発動できる。\n相手はこのカードの効果で除外したカードの中から１枚を選ぶ。\n自分は相手が選んだカードを手札に加える。\n④：魔法＆罠ゾーンの表側表示のこのカードがフィールドから離れた時、自分はこのカードの効果で除外されているカードの数×５００ダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔導加速",h:"まじっく・ぶーすと",e:"Mythical Bestiary",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分のデッキの上からカードを２枚墓地へ送り、フィールドの魔力カウンターを置く事ができるカード１枚を対象として発動できる。\nそのカードに魔力カウンターを２つまで置く。\n②：このカードが相手の効果で破壊された場合に発動できる。\n魔力カウンターを置く事ができるモンスター１体をデッキから特殊召喚し、そのモンスターに魔力カウンターを２つまで置く。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マジック・プランター",h:"まじっく・ぷらんたー",e:"Magic Planter",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの表側表示の永続罠カード１枚を墓地へ送って発動できる。\n自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マジックブラスト",h:"まじっくぶらすと",e:"Magical Blast",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの魔法使い族モンスターの数×２００ダメージを相手に与える。\n②：このカードが墓地に存在する場合、自分ドローフェイズのドロー前に発動できる。\nこのターン通常のドローを行う代わりに、このカードを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔獣の懐柔",h:"まじゅうのかいじゅう",e:"Obedience Schooled",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドにモンスターが存在しない場合に発動できる。\nカード名が異なるレベル２以下の獣族の効果モンスター３体をデッキから特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化され、エンドフェイズに破壊される。\nこのカードの発動後、ターン終了時まで自分は獣族モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔術の呪文書",h:"まじゅつのじゅもんしょ",e:"Magic Formula",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「ブラック・マジシャン」「ブラック・マジシャン・ガール」にのみ装備可能。\n装備モンスターの攻撃力は７００ポイントアップする。\nこのカードがフィールド上から墓地へ送られた時、自分は１０００ライフポイント回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔性の月",h:"ましょうのつき",e:"Mystical Moon",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"獣戦士族モンスターにのみ装備可能。\n装備モンスターの攻撃力・守備力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔女狩り",h:"まじょがり",e:"Last Day of Witch",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在する魔法使い族モンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マシン・デベロッパー",h:"ましん・でべろっぱー",e:"Machine Assembly Line",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードがフィールド上に存在する限り、フィールド上の機械族モンスターの攻撃力は２００ポイントアップする。\nフィールド上の機械族モンスターが戦闘またはカードの効果によって破壊される度に、このカードにジャンクカウンターを２つ置く。\nまた、ジャンクカウンターが乗っているこのカードを墓地へ送って発動できる。\nこのカードに乗っていたジャンクカウンターの数以下のレベルを持つ機械族モンスター１体を自分の墓地から選択して特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔神王の禁断契約書",h:"ましんおうのきんだんけいやくしょ",e:"Forbidden Dark Contract with the Swamp King",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：１ターンに１度、自分メインフェイズに発動できる。\n手札から「DDD」モンスター１体を効果を無効にして守備表示で特殊召喚する。\n②：１ターンに１度、自分メインフェイズに発動できる。\n悪魔族の融合モンスターカードによって決められた、このカードの①の効果で特殊召喚したモンスターを含む融合素材モンスターを自分の手札・フィールドから墓地へ送り、その融合モンスター１体をエクストラデッキから融合召喚する。\n③：自分スタンバイフェイズに発動する。\n自分は２０００ダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔神王の契約書",h:"ましんおうのけいやくしょ",e:"Dark Contract with the Swamp King",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"「魔神王の契約書」の①の効果は１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\n自分の手札・フィールドから、悪魔族の融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をエクストラデッキから融合召喚する。\n「DD」融合モンスターを融合召喚する場合、自分の墓地のモンスターを除外して融合素材とする事もできる。\n②：自分スタンバイフェイズに発動する。\n自分は１０００ダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"機甲部隊の最前線",h:"ましんなーず・ふろんとらいん",e:"Machina Armored Unit",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：１ターンに１度、機械族モンスターが戦闘で破壊され自分の墓地へ送られた時に発動できる。\n墓地のそのモンスターより攻撃力が低い、同じ属性の機械族モンスター１体をデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マスク・チェンジ",h:"ますく・ちぇんじ",e:"Mask Change",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの「HERO」モンスター１体を対象として発動できる。\nそのモンスターを墓地へ送り、そのモンスターと同じ属性の「M・HERO」モンスター１体をエクストラデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マスク・チャージ",h:"ますく・ちゃーじ",e:"Mask Charge",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の墓地の、「HERO」モンスター１体と「チェンジ」速攻魔法カード１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"抹殺の使徒",h:"まっさつのしと",e:"Nobleman of Crossout",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"フィールド上に裏側表示で存在するモンスター１体を選択して破壊し、ゲームから除外する。\nそれがリバース効果モンスターだった場合、お互いのデッキを確認し、同名カードを全てゲームから除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"摩天楼 －スカイスクレイパー－",h:"まてんろう　－すかいすくれいぱー－",e:"Skyscraper",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：「E・HERO」モンスターの攻撃力は、その攻撃力より高い攻撃力を持つモンスターに攻撃するダメージ計算時のみ１０００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"摩天楼2－ヒーローシティ",h:"まてんろう２－ひーろーしてぃ",e:"Skyscraper 2 - Hero City",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"１ターンに１度、自分のメインフェイズ時に、戦闘によって破壊され自分の墓地へ送られた「E・HERO」と名のついたモンスター１体を選択して発動できる。\n選択したモンスターを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔導師の力",h:"まどうしのちから",e:"Mage Power",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"①：装備モンスターの攻撃力・守備力は、自分フィールドの魔法・罠カードの数×５００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔導書院ラメイソン",h:"まどうしょいんらめいそん",e:"The Grand Spellbook Tower",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：自分フィールドまたは自分の墓地に魔法使い族モンスターが存在する場合、自分スタンバイフェイズに発動できる。\n自分の墓地から「魔導書院ラメイソン」以外の「魔導書」魔法カード１枚を選んでデッキの一番下に戻し、自分はデッキから１枚ドローする。\n②：このカードが相手によって破壊され墓地へ送られた時に発動できる。\n自分の墓地の「魔導書」魔法カードの数以下のレベルを持つ魔法使い族モンスター１体を手札・デッキから特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔導書庫クレッセン",h:"まどうしょこくれっせん",e:"Spellbook Library of the Crescent",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地に「魔導書」と名のついた魔法カードが存在しない場合に発動できる。\nデッキから「魔導書」と名のついた魔法カードを３種類選んで相手に見せ、相手はその中からランダムに１枚選ぶ。\n相手が選んだカード１枚を自分の手札に加え、残りのカードをデッキに戻す。\n「魔導書庫クレッセン」は１ターンに１枚しか発動できず、このカードを発動するターン、自分は「魔導書」と名のついたカード以外の魔法カードを発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔導書庫ソレイン",h:"まどうしょこそれいん",e:"Spellbook Library of the Heliosphere",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地の「魔導書」と名のついた魔法カードが５枚以上の場合に発動できる。\n自分のデッキの上からカードを２枚めくる。\nその中の「魔導書」と名のついた魔法カードを全て手札に加え、残りのカードをデッキに戻す。\n「魔導書庫ソレイン」は１ターンに１枚しか発動できず、このカードを発動するターン、自分は「魔導書」と名のついたカード以外の魔法カードを発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔導書整理",h:"まどうしょせいり",e:"Spellbook Organization",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分のデッキの上から３枚カードをめくり好きな順番でデッキの上に戻す。\n相手はそのカードを確認できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔導書廊エトワール",h:"まどうしょろうえとわーる",e:"Spellbook Star Hall",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードがフィールド上に存在する限り、自分または相手が「魔導書」と名のついた魔法カードを発動する度に、このカードに魔力カウンターを１つ置く。\n自分フィールド上の魔法使い族モンスターの攻撃力は、このカードに乗っている魔力カウンターの数×１００ポイントアップする。\nまた、魔力カウンターが乗っているこのカードが破壊され墓地へ送られた時、このカードに乗っていた魔力カウンターの数以下のレベルを持つ魔法使い族モンスター１体をデッキから手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マドルチェ・シャトー",h:"まどるちぇ・しゃとー",e:"Madolche Chateau",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカードの発動時に、自分の墓地に「マドルチェ」と名のついたモンスターが存在する場合、そのモンスターを全てデッキに戻す。\nこのカードがフィールド上に存在する限り、フィールド上の「マドルチェ」と名のついたモンスターの攻撃力・守備力は５００ポイントアップする。\nまた、「マドルチェ」と名のついたモンスターの効果によって、自分の墓地のモンスターがデッキに戻る場合、デッキに戻さず手札に戻す事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マドルチェ・チケット",h:"まどるちぇ・ちけっと",e:"Madolche Ticket",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：このカード以外の自分のフィールド・墓地の「マドルチェ」カードが効果で自分の手札・デッキに戻った場合に発動する。\nデッキから「マドルチェ」モンスター１体を手札に加える。\n自分フィールドに天使族の「マドルチェ」モンスターが存在する場合、手札に加えず攻撃表示で特殊召喚する事もできる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔の試着部屋",h:"まのしちゃくべや",e:"Enchanting Fitting Room",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"８００ライフポイントを払う。\n自分のデッキの上からカードを４枚めくり、その中のレベル３以下の通常モンスターを自分フィールド上に特殊召喚する。\nそれ以外のカードはデッキに戻してシャッフルする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔法吸収",h:"まほうきゅうしゅう",e:"Spell Absorption",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"魔法カードが発動する度に、このカードのコントローラーは５００ライフポイント回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔法再生",h:"まほうさいせい",e:"Spell Reproduction",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"手札の魔法カードを２枚墓地に送る。\n自分の墓地から魔法カードを１枚選択し、手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔法除去",h:"まほうじょきょ",e:"De-Spell",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：フィールドの表側表示の魔法カード１枚またはフィールドにセットされた魔法・罠カード１枚を対象として発動できる。\nその魔法カードを破壊する（そのカードがフィールドにセットされている場合、めくって確認する）。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔法石の採掘",h:"まほうせきのさいくつ",e:"Magical Stone Excavation",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：手札を２枚捨て、自分の墓地の魔法カード１枚を対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔法族の結界",h:"まほうぞくのけっかい",e:"Arcane Barrier",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：フィールドの表側表示の魔法使い族モンスターが破壊される度にこのカードに魔力カウンターを１つ置く（最大４つまで）。\n②：魔力カウンターが置かれているこのカードと自分フィールドの表側表示の魔法使い族モンスター１体を墓地へ送って発動できる。\nこのカードに置かれていた魔力カウンターの数だけ、自分はデッキからドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔法都市エンディミオン",h:"まほうとしえんでぃみおん",e:"Magical Citadel of Endymion",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：このカードがフィールドゾーンに存在する限り、自分または相手が魔法カードを発動する度に、このカードに魔力カウンターを１つ置く。\n②：魔力カウンターが置かれているカードが破壊された場合にそのカードに置かれていた魔力カウンターの数だけ、このカードに魔力カウンターを置く。\n③：１ターンに１度、自分がカードの効果を発動するために自分フィールドの魔力カウンターを取り除く場合、代わりにこのカードから取り除く事ができる。\n④：このカードが破壊される場合、代わりにこのカードの魔力カウンターを１つ取り除く事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔妖壊劫",h:"まやかしかいごう",e:"Mayakashi Winter",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の②③の効果は１ターンに１度、いずれか１つしか使用できない。\n①：相手フィールドのモンスターの攻撃力・守備力は、自分の墓地の「魔妖」モンスターの種類×１００ダウンする。\n②：自分フィールドの表側表示の「魔妖」モンスター１体とこのカードを墓地へ送って発動できる。\n自分はデッキから１枚ドローする。\n③：自分の墓地からこのカードとアンデット族モンスター１体を除外し、自分の墓地の「魔妖」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔妖廻天",h:"まやかしかいてん",e:"Mayakashi Return",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：デッキから「魔妖廻天」以外の「魔妖」カード１枚を選び、手札に加えるか墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"迷える仔羊",h:"まよえるこひつじ",e:"Stray Lambs",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカードを発動するターン、自分はこのカードの効果以外ではモンスターを召喚・反転召喚・特殊召喚できない。\n①：自分フィールドに「仔羊トークン」（獣族・地・星１・攻／守０）２体を守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔力倹約術",h:"まりょくけんやくじゅつ",e:"Spell Economics",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"魔法カードを発動するために払うライフポイントが必要なくなる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔力掌握",h:"まりょくしょうあく",e:"Spell Power Grasp",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：フィールドの魔力カウンターを置く事ができるカード１枚を対象として発動できる。\nそのカードに魔力カウンターを１つ置く。\nその後、デッキから「魔力掌握」１枚を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔力の泉",h:"まりょくのいずみ",e:"Magical Spring",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"「魔力の泉」は１ターンに１枚しか発動できない。\n①：相手フィールドの表側表示の魔法・罠カードの数だけ自分はデッキからドローする。\nその後、自分フィールドの表側表示の魔法・罠カードの数だけ自分の手札からカードを選んで捨てる。\nこのカードの発動後、次の相手ターンの終了時まで、相手フィールドの魔法・罠カードは破壊されず、発動と効果を無効化されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔力無力化の仮面",h:"まりょくむりょくかのかめん",e:"Mask of Dispel",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在する魔法カード１枚を選択して発動する。\n自分のスタンバイフェイズ毎に、選択した魔法カードのコントローラーに５００ポイントダメージを与える。\n選択したカードがフィールド上に存在しなくなった時、このカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"万華鏡－華麗なる分身－",h:"まんげきょう－かれいなるぶんしん－",e:"Elegant Egotist",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：フィールドに「ハーピィ・レディ」が存在する場合に発動できる。\n手札・デッキから「ハーピィ・レディ」または「ハーピィ・レディ三姉妹」１体を特殊召喚する。",li:2,lk:[],ta:[],al:[],ocg:true},
  {n:"ミイラの呼び声",h:"みいらのよびごえ",e:"Call of the Mummy",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：１ターンに１度、自分メインフェイズに発動できる。\n手札からアンデット族モンスター１体を特殊召喚する。\nこの効果は自分フィールドにモンスターが存在しない場合に発動と処理ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"右手に盾を左手に剣を",h:"みぎてにたてをひだりてにけんを",e:"Shield & Sword",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカードの発動時にフィールド上に表側表示で存在する全てのモンスターの元々の攻撃力と元々の守備力を、エンドフェイズ時まで入れ替える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"未熟な密偵",h:"みじゅくなみってい",e:"The Inexperienced Spy",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"相手の手札を１枚指定して、見ることができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"霞の谷の祭壇",h:"みすと・ばれーのさいだん",e:"Shrine of Mist Valley",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"風属性モンスターがカードの効果によって破壊され自分の墓地へ送られた時、自分の手札・デッキから風属性・レベル３以下のモンスター１体を特殊召喚できる。\nこの効果で特殊召喚したモンスターの効果は無効化される。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"霞の谷の神風",h:"みすと・ばれーのしんぷう",e:"Divine Wind of Mist Valley",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する風属性モンスターが手札に戻った場合、自分のデッキからレベル４以下の風属性モンスター１体を特殊召喚する事ができる。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ミスフォーチュン",h:"みすふぉーちゅん",e:"Misfortune",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"相手フィールド上に表側表示で存在するモンスター１体を選択して発動する。\n選択したモンスターの元々の攻撃力の半分のダメージを相手ライフに与える。\nこのターン自分のモンスターは攻撃する事ができない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"見世物ゴブリン",h:"みせものごぶりん",e:"Goblin Circus",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分のエンドフェイズ毎に、自分のデッキの一番下のカードをお互いに確認し、そのカードを自分のデッキの一番上に置くか、裏側表示でゲームから除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ミニマム・ガッツ",h:"みにまむ・がっつ",e:"Mini-Guts",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上のモンスター１体をリリースし、相手フィールド上に表側表示で存在するモンスター１体を選択して発動できる。\n選択したモンスターの攻撃力はエンドフェイズ時まで０になる。\nこのターン、選択したモンスターが戦闘によって破壊され相手の墓地へ送られた時、そのモンスターの元々の攻撃力分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"蜃気楼の筒",h:"みらーじゅしりんだー",e:"Mirage Tube",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカードは手札から発動する事はできない。\n自分フィールド上に表側表示で存在するモンスターが攻撃対象に選択された時に発動する事ができる。\n相手ライフに１０００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"未来への思い",h:"みらいへのおもい",e:"Message in a Bottle",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地のレベルが異なるモンスター３体を選択して発動できる。\n選択したモンスター３体を特殊召喚する。\nこの効果で特殊召喚したモンスターの攻撃力は０になり、効果は無効化される。\nその後、自分がエクシーズ召喚を行っていない場合、このターンのエンドフェイズ時に自分は４０００ライフポイントを失う。\nまた、このカードを発動するターン、自分はエクシーズ召喚以外の特殊召喚ができない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"未来融合－フューチャー・フュージョン",h:"みらいゆうごう－ふゅーちゃー・ふゅーじょん",e:"Future Fusion",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：このカードの発動後１回目の自分スタンバイフェイズに発動する。\n自分のEXデッキの融合モンスター１体をお互いに確認し、そのモンスターによって決められた融合素材モンスターを自分のデッキから墓地へ送る。\n②：このカードの発動後２回目の自分スタンバイフェイズに発動する。\nこのカードの①の効果で確認したモンスターと同名の融合モンスター１体をEXデッキから融合召喚する。\nこのカードがフィールドから離れた時にそのモンスターは破壊される。\nそのモンスターが破壊された時にこのカードは破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ミラクル・コンタクト",h:"みらくる・こんたくと",e:"Miracle Contact",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の手札・フィールド・墓地から、融合モンスターカードによって決められた融合素材モンスターを持ち主のデッキに戻し、「E・HERO ネオス」を融合素材とするその「E・HERO」融合モンスター１体を召喚条件を無視してEXデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ミラクル・フュージョン",h:"みらくる・ふゅーじょん",e:"Miracle Fusion",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分のフィールド・墓地から、「E・HERO」融合モンスターカードによって決められた融合素材モンスターを除外し、その融合モンスター１体をEXデッキから融合召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"奇跡の蘇生",h:"みらくる・りぼーん",e:"Miraculous Rebirth",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"チェーン４以降に発動できる。\n自分または相手の墓地のモンスター１体を選択し、自分フィールド上に特殊召喚する。\n同一チェーン上に複数回同名カードの効果が発動している場合、このカードは発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ミラクルシンクロフュージョン",h:"みらくるしんくろふゅーじょん",e:"Miracle Synchro Fusion",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分のフィールド・墓地から、融合モンスターカードによって決められた融合素材モンスターを除外し、Sモンスターを融合素材とするその融合モンスター１体をエクストラデッキから融合召喚する。\n②：セットされたこのカードが相手の効果で破壊され墓地へ送られた場合に発動する。\n自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ムーン・スクレイパー",h:"むーん・すくれいぱー",e:"Attack the Moon!",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分フィールド上の岩石族モンスターの表示形式が変更された時、相手フィールド上の魔法・罠カード１枚を選択して破壊できる。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ムーンバリア",h:"むーんばりあ",e:"Light Wing Shield",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：モンスターの攻撃が無効になった時、以下の効果から１つを選択して発動できる。\n\n●このターンのエンドフェイズになる。\n\n●自分フィールドの「希望皇ホープ」Xモンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで元々の攻撃力の倍になる。\n\n②：自分フィールドの「希望皇ホープ」XモンスターがX素材を１つ取り除いて効果を発動する場合、取り除くX素材の代わりに墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"月光融合",h:"むーんらいと・ふゅーじょん",e:"Lunalight Fusion",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の手札・フィールドから、「ムーンライト」融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。\n相手フィールドに、EXデッキから特殊召喚されたモンスターが存在する場合には自分のデッキ・EXデッキの「ムーンライト」モンスターも１体まで融合素材とする事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"無限の手札",h:"むげんのてふだ",e:"Infinite Cards",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードがフィールド上に存在する限り、お互いのプレイヤーは手札枚数制限が無くなる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"無情の抹殺",h:"むじょうのまっさつ",e:"Ruthless Denial",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に存在するモンスター１体を選択して発動する。\n選択した自分のモンスターを墓地へ送り、相手の手札をランダムに１枚墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"無念の手札",h:"むねんのてふだ",e:"Finite Cards",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在する限り、お互いの手札制限枚数は３枚になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"無の煉獄",h:"むのれんごく",e:"Into the Void",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の手札が３枚以上の場合に発動できる。\n自分のデッキからカードを１枚ドローし、このターンのエンドフェイズ時に自分の手札を全て捨てる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"無欲な壺",h:"むよくなつぼ",e:"Pot of Benevolence",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分または相手の墓地に存在するカードを合計２枚選択し、持ち主のデッキに戻す。\nこのカードは発動後、墓地へ送らずにゲームから除外する。\n「無欲な壺」は１ターンに１枚しか発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"紫水晶",h:"むらさきすいしょう",e:"Violet Crystal",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"アンデット族のみ装備可能。\n装備モンスター１体の攻撃力と守備力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"冥王結界波",h:"めいおうけっかいは",e:"Dark Ruler No More",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカードの発動に対してモンスターの効果は発動できない。\n①：相手フィールドの全ての表側表示モンスターの効果をターン終了時まで無効にする。\nこのカードの発動後、ターン終了時まで相手が受ける全てのダメージは０になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"冥界の宝札",h:"めいかいのほうさつ",e:"Precious Cards from Beyond",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：自分がモンスター２体以上をリリースしたアドバンス召喚に成功した場合に発動する。\n自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"冥界流傀儡術",h:"めいかいりゅうくぐつじゅつ",e:"The Puppet Magic of Dark Ruler",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地に存在する悪魔族モンスター１体を選択して発動する。\nレベルの合計が選択したモンスターのレベルと同じになるように、自分フィールド上に存在するモンスターをゲームから除外する。\nその後、選択したモンスターを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"迷宮変化",h:"めいきゅうへんげ",e:"Magical Labyrinth",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「迷宮壁－ラビリンス・ウォール－」に装備する。\n「迷宮壁－ラビリンス・ウォール－」と装備したこのカードを生け贄に捧げる事で、デッキから「ウォール・シャドウ」を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"メガトン魔導キャノン",h:"めがとんまどうきゃのん",e:"Mega Ton Magical Cannon",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に存在する魔力カウンターを１０個取り除いて発動する。\n相手フィールド上に存在するカードを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"女神の聖剣－エアトス",h:"めがみのせいけん－えあとす",e:"Celestial Sword - Eatos",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"①：装備モンスターの攻撃力は５００アップする。\n②：このカードがフィールドから墓地へ送られた時、自分フィールドの「ガーディアン・エアトス」１体を対象として発動できる。\nそのモンスターの攻撃力は、除外されているモンスターの数×５００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"メガリス・ポータル",h:"めがりす・ぽーたる",e:"Megalith Portal",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：儀式召喚したモンスターはそれぞれ１ターンに１度だけ戦闘では破壊されない。\n②：「メガリス」モンスターが特殊召喚された場合、自分の墓地の儀式モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"メガロイド都市",h:"めがろいどしてぃ",e:"Megaroid City",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できず、この効果を発動するターン、自分は融合モンスターしかEXデッキから特殊召喚できない。\n①：このカード以外の自分フィールドのカード１枚を対象として発動できる。\nそのカードを破壊し、デッキから「ロイド」カード１枚を手札に加える。\n②：自分の「ロイド」モンスターが戦闘を行うダメージ計算時に、デッキから「ロイド」モンスター１体を墓地へ送って発動できる。\nその戦闘を行う自分のモンスターはそのダメージ計算時のみ、元々の攻撃力と元々の守備力が入れ替わる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"恵みの雨",h:"めぐみのあめ",e:"Rain of Mercy",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"お互いのプレイヤーは１０００ライフポイント回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"メタファイズ・ファクター",h:"めたふぁいず・ふぁくたー",e:"Metaphys Factor",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：このカードがフィールドゾーンに存在する限り、自分はレベル５以上の「メタファイズ」モンスターを召喚する場合に必要なリリースをなくす事ができる。\nこの効果は１ターンに１度しか適用できない。\nこの効果を適用して召喚したモンスターは次のターンのエンドフェイズに除外される。\n②：このカードがフィールドゾーンに存在する限り、自分の「メタファイズ」モンスターの効果の発動に対して、相手は魔法・罠・モンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"メテオ・ストライク",h:"めてお・すとらいく",e:"Fairy Meteor Crush",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"装備モンスターが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"メルフィーのおいかけっこ",h:"めるふぃーのおいかけっこ",e:"Melffy Tag",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地の「メルフィー」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"猛獣の歯",h:"もうじゅうのは",e:"Beast Fangs",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"獣族のみ装備可能。\n装備モンスター１体の攻撃力と守備力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"猛毒の風",h:"もうどくのかぜ",e:"Poisonous Winds",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードがフィールド上に存在する限り、お互いに風属性モンスターを特殊召喚する事はできない。\nまた、フィールド上に表側表示で存在する全ての風属性モンスターの攻撃力は５００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"モウヤンのカレー",h:"もうやんのかれー",e:"Mooyan Curry",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"ライフポイントを２００ポイント回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"燃えさかる大地",h:"もえさかるだいち",e:"Burning Land",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードの発動時に、フィールド上のフィールド魔法カードを全て破壊する。\nまた、お互いのスタンバイフェイズ時、ターンプレイヤーは５００ポイントダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"黙する死者",h:"もくするししゃ",e:"Silent Doom",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の墓地の通常モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターは攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"モノマネンド",h:"ものまねんど",e:"Mimiclay",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「モノマネンド」は１ターンに１枚しか発動できない。\n①：相手フィールドにモンスターが存在する場合、自分フィールドのレベル２以下の表側守備表示モンスター１体を対象として発動できる。\nその同名モンスター１体をデッキから守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"模倣－イミテーションー",h:"もほう－いみてーしょん－",e:"Imitation",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"モンスターゾーン上のトークン１体を選択して発動できる。\n選択したトークンを破壊し、同じ攻撃力・守備力を持つ「マスクトークン」（魔法使い族・闇・星１・攻／守？）１体を相手フィールド上に特殊召喚する。\nその後、特殊召喚したトークンの攻撃力の半分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"森",h:"もり",e:"Forest",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"全ての昆虫・獣・植物・獣戦士族モンスターの攻撃力と守備力は、２００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"森のざわめき",h:"もりのざわめき",e:"Murmur of the Forest",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"相手フィールド上に表側表示で存在するモンスター１体を選択して裏側守備表示にする。\nその後、フィールド上に存在するフィールド魔法カードを持ち主の手札に戻す事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"モルティング・エスケープ",h:"もるてぃんぐ・えすけーぷ",e:"Molting Escape",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"爬虫類族モンスターにのみ装備可能。\n装備モンスターは１ターンに１度だけ、戦闘では破壊されない。\nこの効果を適用したダメージステップ終了時、装備モンスターの攻撃力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"モルトシュラーク",h:"もるとしゅらーく",e:"Mordschlag",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"自分フィールドの通常召喚されたモンスターにのみ装備可能。\n①：装備モンスターは特殊召喚された相手フィールドのモンスターが発動した効果を受けない。\n②：装備モンスターが特殊召喚された相手モンスターと戦闘を行うダメージ計算時に発動する。\nその相手モンスターの攻撃力・守備力はそのダメージ計算時のみ、装備モンスターの元々の攻撃力分ダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"モンスター回収",h:"もんすたーかいしゅう",e:"Monster Recovery",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"元々の持ち主が自分となる、自分フィールド上のモンスター１体を選択して発動できる。\n選択したモンスターと自分の手札を全てデッキに加えてシャッフルする。\nその後、元の手札の枚数分だけデッキからカードをドローする。\n元々の持ち主が相手となるカードが自分の手札にある場合、このカードは発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"モンスターゲート",h:"もんすたーげーと",e:"Monster Gate",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドのモンスター１体をリリースして発動できる。\n通常召喚可能なモンスターが出るまで自分のデッキの上からカードをめくり、そのモンスターを特殊召喚する。\n残りのめくったカードは全て墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"八尺勾玉",h:"やさかのまがたま",e:"Orb of Yasaka",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"スピリットモンスターにのみ装備可能。\n装備モンスターが戦闘によって相手モンスターを破壊し墓地へ送った時、破壊したモンスターの元々の攻撃力分だけ自分のライフポイントを回復する。\n装備モンスターが自分フィールド上から手札に戻る事によってこのカードが墓地へ送られた時、このカードを手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"野性解放",h:"やせいかいほう",e:"Wild Nature's Release",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"フィールド上の獣族・獣戦士族モンスター１体を選択して発動できる。\n選択した獣族・獣戦士族モンスターの攻撃力は、そのモンスターの守備力分アップする。\nこの効果を受けたモンスターはエンドフェイズ時に破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"八汰鏡",h:"やたのかがみ",e:"Mirror of Yata",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"スピリットモンスターにのみ装備可能。\n装備モンスターはエンドフェイズ時に手札に戻る効果を発動しなくてもよい。\n装備モンスターが戦闘によって破壊される場合、代わりにこのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"山",h:"やま",e:"Mountain",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在するドラゴン族・鳥獣族・雷族モンスターの攻撃力・守備力は２００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"闇",h:"やみ",e:"Yami",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在する悪魔族・魔法使い族モンスターの攻撃力・守備力は２００ポイントアップする。\nフィールド上に表側表示で存在する天使族モンスターの攻撃力・守備力は２００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"闇・エネルギー",h:"やみ・えねるぎー",e:"Dark Energy",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"悪魔族のみ装備可能。\n装備モンスター１体の攻撃力と守備力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"闇の訪れ",h:"やみのおとずれ",e:"Darkness Approaches",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"手札を２枚捨てる。\n表側表示のモンスター１体を選択し、表示形式はそのままで裏側表示にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"闇の支配者との契約",h:"やみのしはいしゃとのけいやく",e:"Contract with the Dark Master",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「闇の支配者－ゾーク」の降臨に必要。\n①：自分の手札・フィールドから、レベルの合計が８以上になるようにモンスターをリリースし、手札から「闇の支配者－ゾーク」を儀式召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"闇の進軍",h:"やみのしんぐん",e:"March of the Dark Brigade",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地の「ライトロード」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\nその後、手札に加えたそのモンスターの元々のレベルの数だけ、自分のデッキの上からカードを除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"闇の破神剣",h:"やみのはしんけん",e:"Sword of Dark Destruction",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"闇属性モンスター１体の攻撃力４００ポイントアップ！守備力２００ポイントダウン！",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"闇の量産工場",h:"やみのりょうさんこうじょう",e:"Dark Factory of Mass Production",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の墓地の通常モンスター２体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"闇魔界の契約書",h:"やみまかいのけいやくしょ",e:"Dark Contract with the Yamimakai",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"「闇魔界の契約書」の①の効果は１ターンに１度しか使用できない。\n①：以下の効果から１つを選択してこの効果を発動できる。\n\n●自分の墓地の「DD」Pモンスター１体を対象として発動できる。\nそのモンスターを自分のPゾーンに置く。\n\n●自分のエクストラデッキから、表側表示の「DD」Pモンスター１体を自分のPゾーンに置く。\n\n②：自分スタンバイフェイズに発動する。\n自分は１０００ダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"闇をかき消す光",h:"やみをかきけすひかり",e:"Dark-Piercing Light",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"相手フィールド上に裏側表示で存在するモンスターを全て表側表示にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"やりすぎた埋葬",h:"やりすぎたまいそう",e:"Overdone Burial",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：手札からモンスター１体を捨て、捨てたモンスターより元々のレベルが低い自分の墓地のモンスター１体を対象としてこのカードを発動できる。\nそのモンスターを特殊召喚し、このカードを装備する。\n②：装備モンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"遺言の仮面",h:"ゆいごんのかめん",e:"The Mask of Remnants",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：このカードをデッキに戻してシャッフルする。\n②：このカードが「仮面魔獣デス・ガーディウス」の効果で装備されている場合、装備モンスターのコントロールを得る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"勇気の旗印",h:"ゆうきのはたじるし",e:"Banner of Courage",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在する限り、自分フィールドのモンスターの攻撃力は、自分バトルフェイズの間２００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"融合",h:"ゆうごう",e:"Polymerization",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の手札・フィールドから、融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をEXデッキから融合召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"融合解除",h:"ゆうごうかいじょ",e:"De-Fusion",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：フィールドの融合モンスター１体を対象として発動できる。\nその融合モンスターを持ち主のEXデッキに戻す。\nその後、EXデッキに戻したそのモンスターの融合召喚に使用した融合素材モンスター一組が自分の墓地に揃っていれば、その一組を自分フィールドに特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"融合賢者",h:"ゆうごうけんじゃ",e:"Fusion Sage",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分のデッキから「融合」魔法カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"融合再生機構",h:"ゆうごうさいせいきこう",e:"Fusion Recycling Plant",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：１ターンに１度、手札を１枚捨てて発動できる。\n自分のデッキ・墓地から「融合」１枚を選んで手札に加える。\n②：自分・相手のエンドフェイズに、このターン融合召喚に使用した自分の墓地の融合素材モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"融合破棄",h:"ゆうごうはき",e:"Revoke Fusion",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「融合」魔法カード１枚を手札から墓地へ捨てて発動する。\n融合デッキに存在する融合モンスター１体を墓地に送り、その融合モンスターに記されている融合素材モンスター１体を手札から特殊召喚する。\nこの効果で特殊召喚したモンスターは、このターンのエンドフェイズ時に墓地へ送られる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"融合武器ムラサメブレード",h:"ゆうごうぶきむらさめぶれーど",e:"Fusion Sword Murasame Blade",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"戦士族モンスターにのみ装備可能。\n①：装備モンスターの攻撃力は８００アップする。\n②：モンスターに装備されているこのカードは効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幽獄の時計塔",h:"ゆうごくのとけいとう",e:"Clock Tower Prison",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"相手ターンのスタンバイフェイズ時に、このカードに時計カウンターを１個乗せる。\n時計カウンターの合計が４個以上になった場合、このカードのコントローラーは戦闘ダメージを受けない。\n時計カウンターが４個以上乗ったこのカードが破壊され墓地へ送られた時、手札またはデッキから「D-HERO ドレッドガイ」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"友情 YU－JYO",h:"ゆうじょう　ＹＵ－ＪＹＯ",e:"Yu-Jo Friendship",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：相手プレイヤーに握手を申し込む。\n相手が握手に応じた場合、お互いのLPは、お互いのLPを合計した数値の半分になる。\n自分の手札に「結束 UNITY」がある場合、そのカードを相手に見せる事ができる。\nその場合、お互いは握手をして、このカードの効果を適用する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"雪の天気模様",h:"ゆきのてんきもよう",e:"The Weather Snowy Canvas",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：「雪の天気模様」は自分フィールドに１枚しか表側表示で存在できない。\n②：このカードと同じ縦列の自分のメインモンスターゾーン及びその両隣の自分のメインモンスターゾーンに存在する「天気」効果モンスターは以下の効果を得る。\n●このカードを除外して発動できる。\nデッキから「天気」カード１枚を手札に加える。\nこの効果の発動後、ターン終了時まで自分はドロー以外の方法でデッキからカードを手札に加える事はできない。\nこの効果は相手ターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ユニオン・アタック",h:"ゆにおん・あたっく",e:"Union Attack",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの表側表示モンスター１体を対象として発動できる。\nこのターンのバトルフェイズ開始時、そのモンスターの攻撃力は、他の自分フィールドの攻撃表示モンスターの攻撃力の合計分、バトルフェイズ終了時までアップする。\nこのターン、対象のモンスターが相手に与える戦闘ダメージは０になり、他の自分のモンスターは攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ユニコーンの導き",h:"ゆにこーんのみちびき",e:"Unicorn Beacon",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"ゲームから除外されているレベル５以下の獣族または鳥獣族モンスター１体を選択して発動する。\n手札を１枚ゲームから除外し、選択したモンスターを攻撃表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ユニゾン・チューン",h:"ゆにぞん・ちゅーん",e:"Uni-Song Tuning",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分または相手の墓地のチューナー１体と、フィールドの表側表示モンスター１体を対象として発動できる。\n対象の墓地のモンスターを除外する。\nその後、対象のフィールドのモンスターはターン終了時まで、除外したモンスターと同じレベルになり、チューナーとして扱う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"揺れる発条秤",h:"ゆれるぜんまいばかり",e:"Weights & Zenmaisures",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する、レベルが異なる「ゼンマイ」と名のついたモンスター２体を選択して発動する。\nその内１体を相手が選び、もう１体のモンスターのレベルはエンドフェイズ時まで、相手が選んだモンスターと同じになる。\n相手がレベルの低いモンスターを選んだ場合、その後自分はデッキからカードを１枚ドローできる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"陽気な葬儀屋",h:"ようきなそうぎや",e:"The Cheerful Coffin",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の手札から３枚までのモンスターカードを墓地へ捨てる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"要塞クジラの誓い",h:"ようさいくじらのちかい",e:"Fortress Whale's Oath",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「要塞クジラ」の降臨に必要。\n場か手札から、星の数が合計７個以上になるようカードを生け贄に捧げなければならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"妖仙獣の風祀り",h:"ようせんじゅうのかざまつり",e:"Yosenju Wind Worship",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドに「妖仙獣」モンスターカードが３種類以上存在する場合に発動できる。\n自分フィールドの「妖仙獣」モンスターカードを全て持ち主の手札に戻す。\nその後、自分は手札が５枚になるようにデッキからドローできる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"妖仙大旋風",h:"ようせんだいせんぷう",e:"Yosen Whirlwind",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"８００LPを払ってこのカードを発動できる。\n①：１ターンに１度、自分フィールドの表側表示の「妖仙獣」モンスターが手札に戻った場合、相手フィールドのカード１枚を対象として発動できる。\nそのカードを持ち主の手札に戻す。\n②：自分ターンにこのカードの①の効果を適用していない場合、そのターンのエンドフェイズにこのカードは破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"妖刀竹光",h:"ようとうたけみつ",e:"Cursed Bamboo Sword",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：装備モンスターの攻撃力は０アップする。\n②：このカード以外の自分フィールドの「竹光」カード１枚を対象として発動できる。\nそのカードを持ち主の手札に戻し、装備モンスターはこのターン相手に直接攻撃できる。\n③：このカードが墓地へ送られた場合に発動できる。\nデッキから「妖刀竹光」以外の「竹光」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"予見通帳",h:"よけんつうちょう",e:"Ledger of Legerdemain",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分のデッキの上からカード３枚を裏側表示で除外する。\nこのカードの発動後３回目の自分スタンバイフェイズに、この効果で除外したカード３枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"四次元の墓",h:"よじげんのはか",e:"The Graveyard in the Fourth Dimension",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地に存在する「LV」を持つモンスター２体を、自分のデッキに加えてシャッフルする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"予想GUY",h:"よそうがい",e:"Unexpected Dai",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドにモンスターが存在しない場合に発動できる。\nデッキからレベル４以下の通常モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"与奪の首飾り",h:"よだつのくびかざり",e:"Necklace of Command",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"自分フィールド上の装備モンスターが戦闘によって破壊されこのカードが墓地に送られた時、次の効果から１つを選択して発動する。\n\n●デッキからカードを１枚ドローする。\n\n●相手の手札をランダムに１枚墓地に捨てる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ライオンの儀式",h:"らいおんのぎしき",e:"War-Lion Ritual",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「スーパー・ウォー・ライオン」の降臨に必要。\n手札・自分フィールド上から、レベルの合計が７以上になるようにモンスターをリリースしなければならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ライトイレイザー",h:"らいといれいざー",e:"Light Laser",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"光属性・戦士族モンスターにのみ装備可能。\n装備モンスターと戦闘を行ったモンスターを、そのダメージステップ終了時にゲームから除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ライトニング・チューン",h:"らいとにんぐ・ちゅーん",e:"Lightwave Tuning",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在するレベル４の光属性モンスター１体を選択して発動する。\n選択したモンスターはフィールド上に表側表示で存在する限りチューナーとして扱う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ライトニング・ボルテックス",h:"らいとにんぐ・ぼるてっくす",e:"Lightning Vortex",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：手札を１枚捨てて発動できる。\n相手フィールドの表側表示モンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ライトロード・レイピア",h:"らいとろーど・れいぴあ",e:"Lightsworn Sabre",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「ライトロード」と名のついたモンスターにのみ装備可能。\n装備モンスターの攻撃力は７００ポイントアップする。\nこのカードがデッキから墓地に送られた時、このカードを自分フィールド上に存在する「ライトロード」と名のついたモンスター１体に装備する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ライトロードの神域",h:"らいとろーどのしんいき",e:"Lightsworn Sanctuary",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：１ターンに１度、手札の「ライトロード」モンスター１体を墓地へ送り、そのモンスター以外の自分の墓地の「ライトロード」モンスター１体を対象として発動できる。\n対象のモンスターを手札に加える。\n②：このカードが魔法＆罠ゾーンに存在する限り、自分のデッキからカードが墓地へ送られる度に、このカードにシャインカウンターを１つ置く。\n③：自分フィールドの「ライトロード」カードが効果で破壊される場合、代わりに自分フィールドのシャインカウンターを、破壊される「ライトロード」カード１枚につき２つ取り除く事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ライバル・アライバル",h:"らいばる・あらいばる",e:"Arrivalrivals",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分・相手のバトルフェイズに発動できる。\nモンスター１体を召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"雷鳴",h:"らいめい",e:"Raimei",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"相手ライフに３００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ラス・オブ・ネオス",h:"らす・おぶ・ねおす",e:"Wrath of Neos",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分のモンスターゾーンの「E・HERO ネオス」１体を対象として発動できる。\nその「E・HERO ネオス」を持ち主のデッキに戻し、フィールドのカードを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"宝札雲",h:"らっきーくらうど",e:"Lucky Cloud",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"発動ターン中に「雲魔物」と名のついた同名モンスターを２体以上召喚・反転召喚・特殊召喚した場合、エンドフェイズ時に自分のデッキからカードを２枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ランドオルスのヒカリゴケ",h:"らんどおるすのひかりごけ",e:"Landoise's Luminous Moss",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に「ナチュル」と名のついたモンスターが表側表示で存在する場合のみ発動する事ができる。\nこのターン、相手の効果モンスターは効果を発動する事ができない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"リヴェンデット・バース",h:"りう゛ぇんでっと・ばーす",e:"Revendread Evolution",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「ヴェンデット」儀式モンスターの降臨に必要。\nこのカード名のカードは１ターンに１枚しか発動できない。\n①：レベルの合計が儀式召喚するモンスターと同じになるように、自分の手札・フィールドのモンスターをリリース、またはリリースの代わりにデッキから「ヴェンデット」モンスター（１体まで）を墓地へ送り、自分の手札・墓地から「ヴェンデット」儀式モンスター１体を儀式召喚する。\nこの効果で儀式召喚したモンスターは、次のターンのエンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"リヴェンデット・ボーン",h:"りう゛ぇんでっと・ぼーん",e:"Revendread Origin",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「ヴェンデット」儀式モンスターの降臨に必要。\n①：レベルの合計が儀式召喚するモンスターのレベル以上になるように、自分の手札・フィールドのモンスターをリリース、またはリリースの代わりに自分の墓地のアンデット族モンスターを除外し、自分の手札・墓地から「ヴェンデット」儀式モンスター１体を儀式召喚する。\n②：自分フィールドの「リヴェンデット・スレイヤー」が戦闘・効果で破壊される場合、代わりに墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"リサイクル",h:"りさいくる",e:"Recycle",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分ターンのスタンバイフェイズ時に３００ライフポイントを払う事で、自分の墓地に存在するモンスター以外のカードを１枚選択してデッキの一番下に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"リゾネーター・エンジン",h:"りぞねーたー・えんじん",e:"Resonator Engine",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地に存在する「リゾネーター」と名のついたモンスター２体を選択して発動する。\n自分のデッキからレベル４モンスター１体を手札に加え、選択した墓地のモンスターをデッキに戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"リチュアに伝わりし禁断の秘術",h:"りちゅあにつたわりしきんだんのひじゅつ",e:"Forbidden Arts of the Gishki",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「リチュア」と名のついた儀式モンスターの降臨に必要。\n自分フィールド上及び相手フィールド上から、儀式召喚するモンスターと同じレベルになるように表側表示で存在するモンスターをリリースしなければならない。\nこの効果で儀式召喚したモンスターの攻撃力は半分になる。\nこのカードを発動するターン、自分はバトルフェイズを行えない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"リチュアの儀水鏡",h:"りちゅあのぎすいきょう",e:"Gishki Aquamirror",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「リチュア」と名のついた儀式モンスターの降臨に必要。\n自分の手札・フィールド上から、儀式召喚するモンスターと同じレベルになるようにモンスターをリリースしなければならない。\nまた、自分のメインフェイズ時に墓地のこのカードをデッキに戻す事で、自分の墓地の「リチュア」と名のついた儀式モンスター１体を選択して手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"リチュアの写魂鏡",h:"りちゅあのしゃこんきょう",e:"Gishki Photomirror",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「リチュア」と名のついた儀式モンスターの降臨に必要。\n儀式召喚するモンスターのレベル×５００ライフポイントを払わなければならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"リチュアル・ウェポン",h:"りちゅある・うぇぽん",e:"Ritual Weapon",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"レベル６以下の儀式モンスターのみ装備可能。\n装備モンスターの攻撃力と守備力は１５００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"六花絢爛",h:"りっかけんらん",e:"Rikka Glamour",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\nこのカードは自分フィールドの植物族モンスター１体をリリースして発動する事もできる。\n①：デッキから「六花」モンスター１体を手札に加える。\nモンスターをリリースしてこのカードを発動した場合、さらに手札に加えたモンスターとはカード名が異なり、元々のレベルが同じ植物族モンスター１体をデッキから手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"六花の風花",h:"りっかのかざはな",e:"Rikka Flurries",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分フィールドの「六花」モンスターがリリースされた場合に発動できる。\n相手は自身のフィールドのモンスター１体をリリースしなければならない。\n②：相手エンドフェイズに、植物族モンスター以外の表側表示モンスターが自分フィールドに存在する場合に発動する。\nこのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"リニアキャノン",h:"りにあきゃのん",e:"Linear Accelerator Cannon",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に存在するモンスター１体を生け贄に捧げ、そのモンスターの元々の攻撃力の半分のダメージを相手ライフに与える。\nこのカードを発動する場合、このターン中に他の魔法カードを発動する事はできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"リバース・オブ・ネオス",h:"りばーす・おぶ・ねおす",e:"Reverse of Neos",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「ネオス」と名のついた融合モンスターが破壊された時に発動する事ができる。\n自分のデッキから「E・HERO ネオス」１体を攻撃表示で特殊召喚する。\nこの効果で特殊召喚された「E・HERO ネオス」の攻撃力はフィールド上に表側表示で存在する限り１０００ポイントアップし、このターンのエンドフェイズ時に破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"リバース・ブレイカー",h:"りばーす・ぶれいかー",e:"Reverse Breaker",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「希望皇ホープ」と名のついたモンスターにのみ装備可能。\n装備モンスターが攻撃宣言した時、相手フィールド上の魔法・罠カード１枚を選択して破壊する。\nこの効果の発動に対して相手は魔法・罠カードを発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"リバースソウル",h:"りばーすそうる",e:"Soul Reversal",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分の墓地に存在するリバース効果モンスター１体をデッキの一番上に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"Re－BUSTER",h:"りばすたー",e:"Assault Revival",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分の墓地に存在する「バスター・モード」１枚をゲームから除外して発動する。\n自分フィールド上に存在するモンスターを全て破壊し、自分の墓地に存在する「／バスター」と名のついたモンスター１体を召喚条件を無視して特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化され、リリースする事もできず、フィールド上から離れた場合ゲームから除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"リビング・フォッシル",h:"りびんぐ・ふぉっしる",e:"Living Fossil",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地のレベル４以下のモンスター１体を対象としてこのカードを発動できる。\nそのモンスターを特殊召喚し、このカードを装備する。\nこの効果で特殊召喚したモンスターは、フィールドから離れた場合に除外される。\nこのカードがフィールドから離れた時にそのモンスターは除外される。\n②：装備モンスターの攻撃力・守備力は１０００ダウンし、効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"蘇生紋章",h:"りぼーん・めだりおん",e:"Heraldry Reborn",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地の「紋章獣」と名のついたモンスター１体を選択して発動できる。\n選択したモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"リボーンリボン",h:"りぼーんりぼん",e:"Ribbon of Rebirth",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"装備モンスターが戦闘によって破壊され墓地へ送られた場合、そのターンのエンドフェイズ時にそのモンスターを墓地から自分フィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"竜殺しの剣",h:"りゅうごろしのけん",e:"Sword of Dragon's Soul",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"戦士族モンスターにのみ装備可能。\n①：装備モンスターの攻撃力は７００アップする。\n②：装備モンスターがドラゴン族モンスターと戦闘を行ったダメージ計算後に発動する。\n装備モンスターと戦闘を行ったそのモンスターはそのバトルフェイズ終了時に破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"流星の弓－シール",h:"りゅうせいのゆみ－しーる",e:"Shooting Star Bow - Ceal",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"装備モンスターの攻撃力は１０００ポイントダウンする。\n装備モンスターは相手プレイヤーに直接攻撃をする事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"竜操術",h:"りゅうそうじゅつ",e:"Dragon Mastery",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：「ドラグニティ」モンスターカードを装備した、自分フィールドのモンスターの攻撃力は５００アップする。\n②：１ターンに１度、自分フィールドの表側表示モンスター１体を対象として発動できる。\n手札からドラゴン族の「ドラグニティ」モンスター１体を装備カード扱いとして対象の自分のモンスターに装備する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"竜の交感",h:"りゅうのこうかん",e:"Draconnection",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"手札のドラゴン族モンスター１体を相手に見せ、見せたモンスターと同じレベルのドラゴン族モンスター１体をデッキから手札に加える。\nその後、見せたモンスターをデッキに戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"竜の闘志",h:"りゅうのとうし",e:"Dragon's Fighting Spirit",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：このターンに特殊召喚された自分フィールドのドラゴン族モンスター１体を対象として発動できる。\nこのターン、そのモンスターは通常の攻撃に加えて、相手フィールドのこのターンに特殊召喚されたモンスターの数まで、１度のバトルフェイズ中に攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"竜破壊の証",h:"りゅうはかいのあかし",e:"Emblem of Dragon Destroyer",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分のデッキ・墓地から「バスター・ブレイダー」１体を選んで手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"リロード",h:"りろーど",e:"Reload",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分の手札を全てデッキに加えてシャッフルする。\nその後、デッキに加えた枚数分のカードをドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"輪廻転生",h:"りんねてんしょう",e:"Samsara",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"儀式召喚の生け贄にしたモンスターカードは墓地へは行かず、持ち主のデッキに戻る。\nその後デッキをシャッフルする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レア・ヴァリュー",h:"れあ・う゛ぁりゅー",e:"Rare Value",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分の魔法＆罠ゾーンの「宝玉獣」カードが２枚以上存在する場合に発動できる。\n自分の魔法＆罠ゾーンの「宝玉獣」カード１枚を相手が選ぶ。\n相手が選んだカードを墓地へ送り、自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"霊子エネルギー固定装置",h:"れいしえねるぎーこていそうち",e:"Spiritual Energy Settle Machine",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカードがフィールド上に存在する限り、スピリットモンスターはフィールド上に残り続ける。\n自分のエンドフェイズ時に手札を１枚捨てる。\n捨てなければ、このカードを破壊する。\nまた、このカードがフィールド上から離れた時、フィールド上に存在する表側表示のスピリットモンスターは全て持ち主の手札に戻る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"霊獣の相絆",h:"れいじゅうのしょうばん",e:"Ritual Beast's Bond",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの表側表示の「霊獣」モンスター２体を除外して発動できる。\nエクストラデッキから「霊獣」モンスター１体を召喚条件を無視して特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"霊獣の誓還",h:"れいじゅうのせいかん",e:"Ritual Beast Return",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：手札から「霊獣」モンスター１体を除外し、自分の墓地のモンスターまたは除外されている自分のモンスターの中から、「霊獣」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"霊神の聖殿",h:"れいしんのせいでん",e:"Palace of the Elemental Lords",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"①：自分フィールドのモンスターの攻撃力・守備力は、自分の墓地のモンスターの属性の種類×２００アップする。\n②：１ターンに１度、自分メインフェイズに発動できる。\nデッキから「エレメントセイバー」モンスター１体を手札に加える。\nその後、次の自分ターンのバトルフェイズをスキップする。\n③：１ターンに１度、自分の手札・フィールドの「エレメントセイバー」モンスターが効果を発動するために手札を墓地へ送る場合、代わりにデッキの「エレメントセイバー」モンスターを墓地へ送る事ができる。",li:3,lk:[],ta:[],al:[],ocg:true},
  {n:"霊滅独鈷杵",h:"れいめつどっこしょ",e:"Tokkosho of Ghost Destroying",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"装備モンスターが相手ライフに戦闘ダメージを与えた時、相手の墓地に存在するモンスターを２体まで選択してゲームから除外する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レインボー・ヴェール",h:"れいんぼー・う゛ぇーる",e:"Rainbow Veil",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"装備モンスターが相手モンスターと戦闘を行う場合、バトルフェイズの間だけその相手モンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レーザー砲機甲鎧",h:"れーざーきゃのんあーまー",e:"Laser Cannon Armor",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"昆虫族モンスターにのみ装備可能。\n装備モンスターの攻撃力・守備力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レジェンド・オブ・ハート",h:"れじぇんど・おぶ・はーと",e:"Legend of Heart",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「レジェンド・オブ・ハート」は１ターンに１枚しか発動できない。\n①：２０００LPを払い、自分フィールドの戦士族モンスター１体をリリースして発動できる。\n自分の手札・墓地の「伝説の竜」魔法カードを３種類まで除外し、除外した種類の数だけ、「伝説の騎士」モンスターを自分の手札・デッキ・墓地から選んで特殊召喚する（同名カードは１枚まで）。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レッド・ポーション",h:"れっど・ぽーしょん",e:"Red Medicine",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分は５００ライフポイント回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レッドアイズ・インサイト",h:"れっどあいず・いんさいと",e:"Red-Eyes Insight",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「レッドアイズ・インサイト」は１ターンに１枚しか発動できない。\n①：手札・デッキから「レッドアイズ」モンスター１体を墓地へ送って発動できる。\nデッキから「レッドアイズ・インサイト」以外の「レッドアイズ」魔法・罠カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"真紅眼融合",h:"れっどあいず・ふゅーじょん",e:"Red-Eyes Fusion",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できず、このカードを発動するターン、自分はこのカードの効果以外ではモンスターを召喚・特殊召喚できない。\n①：自分の手札・デッキ・フィールドから、融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、「レッドアイズ」モンスターを融合素材とするその融合モンスター１体をEXデッキから融合召喚する。\nこの効果で特殊召喚したモンスターのカード名は「真紅眼の黒竜」として扱う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"烈風の空牙団",h:"れっぷうのくうがだん",e:"Mayhem Fur Hire",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地の「空牙団」モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レプティレス・アンガー",h:"れぷてぃれす・あんがー",e:"Reptilianne Rage",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"装備モンスターは爬虫類族になり、攻撃力は８００ポイントアップする。\nまた、このカードが破壊され墓地へ送られた時、相手フィールド上に表側表示で存在するモンスター１体を選択する。\nそのモンスターの攻撃力は８００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レプティレス・スポーン",h:"れぷてぃれす・すぽーん",e:"Reptilianne Spawn",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分の墓地に存在する「レプティレス」と名のついたモンスター１体をゲームから除外して発動する。\n自分フィールド上に「レプティレストークン」（爬虫類族・地・星１・攻／守０）２体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レプティレス・ポイズン",h:"れぷてぃれす・ぽいずん",e:"Reptilianne Poison",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に「レプティレス」と名のついたモンスターが表側表示で存在する場合のみ発動する事ができる。\n相手フィールド上に守備表示で存在するモンスター１体を表側攻撃表示に変更し、そのモンスターの攻撃力を０にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レベル・マイスター",h:"れべる・まいすたー",e:"Level Lifter",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"手札のモンスター１体を墓地へ送り、自分フィールド上に表側表示で存在するモンスターを２体まで選択して発動できる。\n選択したモンスターのレベルはエンドフェイズ時まで、このカードを発動するために墓地へ送ったモンスターの元々のレベルと同じになる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レベルアップ！",h:"れべるあっぷ！",e:"Level Up!",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"フィールド上に表側表示で存在する「LV」を持つモンスター１体を墓地へ送り発動する。\nそのカードに記されているモンスターを、召喚条件を無視して手札またはデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レベルダウン！？",h:"れべるだうん！？",e:"Level Down!?",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"フィールド上の「LV」と名のついたモンスター１体を選択して発動できる。\n選択したモンスターを持ち主のデッキに戻し、持ち主の墓地からそのモンスターのカード名が含まれる「LV」の低いモンスター１体を選び、特殊召喚条件を無視して持ち主のフィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レベル調整",h:"れべるちょうせい",e:"Level Modulation",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"相手はカードを２枚ドローする。\n自分の墓地に存在する「LV」を持つモンスター１体を、召喚条件を無視して特殊召喚する。\nこの効果で特殊召喚したモンスターは、このターン攻撃できず効果を発動及び適用する事もできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"連合軍",h:"れんごうぐん",e:"The A. Forces",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分フィールド上の戦士族モンスターの攻撃力は、自分フィールド上の戦士族・魔法使い族モンスターの数×２００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ローの祈り",h:"ろーのいのり",e:"Novox's Prayer",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"儀式魔法",tp:"",at:"",de:"",tx:"「ローガーディアン」の降臨に必要。\n場か手札から、星の数が合計７個以上になるようカードを生け贄に捧げなければならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"鹵獲装置",h:"ろかくそうち",e:"Creature Seizure",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"お互いが自分フィールド上モンスターを１体ずつ選択し、そのモンスターのコントロールを入れ替える。\nただしこのカードのコントローラーは自分フィールド上に表側表示で存在する通常モンスターを選択しなければならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"六武院",h:"ろくぶいん",e:"Temple of the Six",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"「六武衆」と名のついたモンスターが召喚・特殊召喚される度に、このカードに武士道カウンターを１つ置く。\n相手フィールド上に表側表示で存在するモンスターの攻撃力は、このカードに乗っている武士道カウンターの数×１００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"六武式三段衝",h:"ろくぶしきさんだんしょう",e:"Six Strike - Triple Impact",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に「六武衆」と名のついたモンスターが表側表示で３体以上存在する場合、以下の効果から１つを選択して発動する事ができる。\n\n●相手フィールド上に表側表示で存在するモンスターを全て破壊する。\n\n●相手フィールド上に表側表示で存在する魔法・罠カードを全て破壊する。\n\n●相手フィールド上にセットされた魔法・罠カードを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"六武衆の荒行",h:"ろくぶしゅうのあらぎょう",e:"Asceticism of the Six Samurai",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの「六武衆」モンスター１体を対象として発動できる。\n対象のモンスターとはカード名が異なり、同じ攻撃力を持つ「六武衆」モンスター１体をデッキから特殊召喚する。\nこのターンのエンドフェイズに対象のモンスターは破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"六武衆の影忍術",h:"ろくぶしゅうのかげにんじゅつ",e:"Secret Skills of the Six Samurai",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドのモンスター１体を墓地へ送り、除外されている自分の「六武衆」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"六武衆の結束",h:"ろくぶしゅうのけっそく",e:"Six Samurai United",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：「六武衆」モンスターが召喚・特殊召喚される度にこのカードに武士道カウンターを１つ置く（最大２つまで）。\n②：武士道カウンターが置かれているこのカードを墓地へ送って発動できる。\nこのカードに置かれていた武士道カウンターの数だけ、自分はデッキからドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"六武衆の理",h:"ろくぶしゅうのことわり",e:"Cunning of the Six Samurai",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「六武衆」と名のついたモンスター１体を墓地へ送って発動できる。\n自分または相手の墓地の「六武衆」と名のついたモンスター１体を選択して自分フィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"六武ノ書",h:"ろくぶのしょ",e:"Six Scrolls of the Samurai",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「六武衆」と名のついたモンスター２体をリリースして発動する。\n自分のデッキから「大将軍 紫炎」１体を自分フィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ロケット・パイルダー",h:"ろけっと・ぱいるだー",e:"Rocket Pilder",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"装備モンスターが攻撃する場合、装備モンスターは戦闘では破壊されない。\n装備モンスターが攻撃を行ったダメージステップ終了時、装備モンスターの攻撃を受けたモンスターの攻撃力は、エンドフェイズ時まで装備モンスターの攻撃力分ダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ワーム・コール",h:"わーむ・こーる",e:"Worm Call",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"相手フィールド上にモンスターが存在し、自分フィールド上にモンスターが存在しない場合、手札から「ワーム」と名のついた爬虫類族モンスター１体を裏側守備表示で特殊召喚する事ができる。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ワーム・ベイト",h:"わーむ・べいと",e:"Worm Bait",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカードを発動するターン、自分はレベル３・４のモンスターを召喚・特殊召喚できない。\n①：自分フィールドに昆虫族モンスターが存在する場合に発動できる。\n自分フィールドに「ワームトークン」（昆虫族・地・星１・攻／守０）２体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ワーム・ホール",h:"わーむ・ほーる",e:"Dimensionhole",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に存在するモンスター１体を選択して発動する。\n選択した自分のモンスターを次の自分のスタンバイフェイズ時までゲームから除外する。\nゲームから除外している間、そのモンスターカードゾーンは使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"我が身を盾に",h:"わがみをたてに",e:"My Body as a Shield",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"１５００ライフポイントを払って発動する。\n相手が発動した「フィールド上のモンスターを破壊する効果」を持つカードの発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"災いの装備品",h:"わざわいのそうびひん",e:"Cursed Armaments",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"装備モンスターの攻撃力は、自分フィールド上に存在するモンスターの数×６００ポイントダウンする。\nこのカードがフィールド上から墓地へ送られた時、相手フィールド上に表側表示で存在するモンスター１体を選択してこのカードを装備する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"罠はずし",h:"わなはずし",e:"Remove Trap",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"表側表示でフィールド上に存在する罠カードを１枚選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"罠封印の呪符",h:"わなふういんのじゅふ",e:"Talisman of Trap Sealing",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"「封印師 メイセイ」が自分フィールド上に存在している時のみ発動する事ができる。\nこのカードがフィールド上に存在する限り罠カードは発動できず、全てのフィールド上罠カードの効果は無効になる。\n「封印師 メイセイ」が自分フィールド上に存在しなくなった時、このカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ワルキューレの抱擁",h:"わるきゅーれのほうよう",e:"Valkyrie's Embrace",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できず、このカードを発動するターン、自分は「ワルキューレ」モンスターしか特殊召喚できない。\n①：自分フィールドのモンスターが「ワルキューレ」モンスターのみの場合、自分フィールドの攻撃表示の「ワルキューレ」モンスター１体と相手フィールドの表側表示モンスター１体を対象として発動できる。\nその自分のモンスターを守備表示にし、その相手モンスターを除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ワンショット・ワンド",h:"わんしょっと・わんど",e:"One-Shot Wand",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"魔法使い族モンスターにのみ装備可能。\n装備モンスターの攻撃力は８００ポイントアップする。\nまた、装備モンスターが戦闘を行ったダメージ計算後、このカードを破壊してデッキからカードを１枚ドローできる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ワンダー・クローバー",h:"わんだー・くろーばー",e:"Wonder Clover",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に表側表示で存在するモンスター１体を選択し、手札からレベル４の植物族モンスター１体を墓地へ送って発動する。\n選択したモンスターは１度のバトルフェイズ中に２回攻撃する事ができる。\nこのカードを発動するターン、自分フィールド上に存在する他のモンスターは攻撃宣言をする事ができない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ワンダー・バルーン",h:"わんだー・ばるーん",e:"Wonder Balloons",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：１ターンに１度、手札を任意の枚数墓地へ送って発動できる。\n墓地へ送ったその枚数分だけ、このカードにバルーンカウンターを置く。\n②：このカードが魔法＆罠ゾーンに存在する限り、相手フィールドのモンスターの攻撃力は、このカードのバルーンカウンターの数×３００ダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ワンダー・ワンド",h:"わんだー・わんど",e:"Wonder Wand",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"魔法使い族モンスターにのみ装備可能。\n①：装備モンスターの攻撃力は５００アップする。\n②：装備モンスターとこのカードを自分フィールドから墓地へ送って発動できる。\n自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ワンチャン！？",h:"わんちゃん！？",e:"Where Arf Thou?",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドにレベル１モンスターが存在する場合に発動できる。\nデッキからレベル１モンスター１体を手札に加える。\n発動後、このターン中に自分がこの効果で手札に加えたモンスターまたはその同名カードの召喚に成功しなかった場合、エンドフェイズに自分は２０００ダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"おジャマンダラ",h:"おじゃまんだら",e:"Ojamandala",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"１０００ライフポイント払い、自分の墓地の「おジャマ・グリーン」「おジャマ・イエロー」「おジャマ・ブラック」をそれぞれ１体ずつ選択して発動できる。\n選択したモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"ザ・ヘブンズ・ロード",h:"ざ・へぶんず・ろーど",e:"The Sky Load",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分の墓地にレベル７以上の「アルカナフォース」と名のついたモンスターが存在する場合にこのカードを発動できる。\n自分フィールド上から、表側表示のこのカードを「ザ・マテリアル・ロード」「ザ・スピリチアル・ロード」を１枚ずつ墓地へ送って発動できる。\n自分の手札・デッキ・墓地から「アルカナフォースEX－THE LIGHT RULER」１体を選び、召喚条件を無視して特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"ディストレイン・カード",h:"でぃすとれいん・かーど",e:"Card of Distrain",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"相手の魔法＆罠ゾーンにセットされたカードが２枚以上存在する場合、その内の１枚を選択して発動できる。\nこのターン、選択したカードは発動できず、相手が魔法＆罠ゾーンにセットされたカードを発動する度に相手ライフに８００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"集中防御盾",h:"せんとらるしーるど",e:"Central Shield",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"自分フィールド上のモンスターにのみ装備可能。\n装備モンスター以外の自分のモンスターの戦闘によって発生する自分への戦闘ダメージは０になる。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"キューピッド・キス",h:"きゅーぴっど・きす",e:"Cupid's Kiss",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"乙女カウンターが置かれている相手モンスターを自分の装備モンスターが攻撃し、自分が戦闘ダメージを受けた場合、ダメージステップ終了時にその相手モンスターのコントロールを得る。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"ジェスターズ・パニック",h:"じぇすたーず・ぱにっく",e:"Jester's Panic",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"相手の魔法＆罠ゾーンにセットされたカード１枚を選択して確認する。\n自分の手札から同じ種類（魔法・罠）のカードを相手に見せて、選択したカードを破壊できる。\nこの効果でカードを破壊した場合、自分フィールド上のレベル２以下の魔法使い族モンスターは、このターン相手プレイヤーに直接攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"ハッピー・マリッジ",h:"はっぴー・まりっじ",e:"Happy Marriage",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"装備モンスターの攻撃力は、元々の持ち主が相手となる自分フィールド上のモンスターの元々の攻撃力の合計分アップする。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"シンクロ・モニュメント",h:"しんくろ・もにゅめんと",e:"Synchro Monument",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカードがフィールド上に存在する限り、シンクロ召喚は無効化されず、シンクロ召喚成功時には、お互いに魔法・罠カードを発動できない。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"選択されなかった者",h:"せんたくされなかったもの",e:"The Unchosen One",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"相手フィールド上にモンスターが２体以上存在する場合に発動できる。\n相手フィールド上のモンスター１体を相手が選び、相手フィールド上の他のモンスター１体を自分が選んで破壊する。\nその後、この効果で破壊され墓地へ送られたモンスターを自分フィールド上に攻撃表示で特殊召喚する。\nこのカードを発動するターン、自分はバトルフェイズを行えない。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"二重波紋",h:"にじゅうはもん",e:"Double Ripple",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"レベルの合計が７になるように自分フィールド上の表側表示の、チューナー１体とチューナー以外のモンスター１体を選んで墓地へ送る。\nその後、エクストラデッキから「パワー・ツール・ドラゴン」と「エンシェント・フェアリー・ドラゴン」を１体ずつ守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"皇帝の戴冠式",h:"こうていのたいかんしき",e:"Crowning of the Emperor",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上の「トイ・ソルジャー」１体をリリースして発動できる。\n手札・デッキから「トイ・エンペラー」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"D-マインド",h:"でぃー－まいんど",e:"D - Mind",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分のフィールド上に「D-HERO」と名のついたモンスターが存在しない場合に発動できる。\nデッキからレベル３以下の「D-HERO」と名のついたモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"Pain to Power",h:"ぺいんとぅぱわー",e:"Pain to Power",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"装備モンスター以外の自分のモンスターの攻撃によって自分が戦闘ダメージを受けた時に発動する。\n装備モンスターの攻撃力はターン終了時まで、そのダメージの数値分アップする。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"錬金釜 カオス・ディスティル",h:"れんきんがま　かおす・でぃすてぃる",e:"Chaos Distill",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分の墓地へ送られるカードは墓地へは行かずゲームから除外される。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"アマゾネスの死闘場",h:"あまぞねすのしとうじょう",e:"Amazoness Arena",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"このカードの発動時の効果処理として、お互いは６００ライフポイント回復する。\n戦闘を行ったダメージステップ終了時、攻撃したプレイヤーは１００ライフポイントを払ってこの効果を発動できる。\n相手ライフに１００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"隠し通路",h:"かくしつうろ",e:"Hidden Passage",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"相手フィールド上のモンスターの一番低い攻撃力よりも低い攻撃力を持つ、自分フィールド上のレベル２以下の魔法使い族モンスターは相手プレイヤーに直接攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"パワー・コンバーター",h:"ぱわー・こんばーたー",e:"Power Converter",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"自分フィールド上のモンスターにのみ装備可能。\n１ターンに１度、自分のメインフェイズ１に発動できる。\n装備モンスターの攻撃力の半分だけ自分のライフポイントを回復し、そのモンスターの攻撃力をターン終了時まで０にする。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"聖地の守護結界",h:"せいちのしゅごけっかい",e:"Sacred Defense Barrier",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"岩石族モンスターが召喚・反転召喚される度に発動する。\nそのモンスターを守備表示にし、そのモンスターに守護カウンターを１つ置く。\nこのカードがフィールド上に存在する限り、守護カウンターが置かれたモンスターが破壊される場合、代わりにそのモンスターの守護カウンターを１つ取り除く。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"フレイム・ウォール",h:"ふれいむ・うぉーる",e:"Flame Wall",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"自分の墓地に炎族モンスターが存在する限り、相手のカードの効果によって自分が受ける効果ダメージは０になる。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"アトリビュート・ボム",h:"あとりびゅーと・ぼむ",e:"Attribute Bomb",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"属性を１つ宣言してこのカードを発動できる。\n装備モンスターが宣言した属性のモンスターを戦闘によって破壊した場合、相手に５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"ヴォルカニック・ウォール",h:"う゛ぉるかにっく・うぉーる",e:"Volcanic Wall",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"１ターンに１度、自分のメインフェイズ時に発動できる。\n自分のデッキの上からカードを３枚墓地へ送り、この効果で墓地へ送られた炎族モンスターの数×２５０ポイントダメージを相手ライフに与える。\nこの効果を発動するターン、自分のモンスターは攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"シールドリカバリー",h:"しーるどりかばりー",e:"Shield Recovery",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"フィールド上の「巨大戦艦」と名のついたモンスター１体を選択し、自身の効果で使用するカウンターを３つ置く。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"黒蠍 愛の悲劇",h:"くろさそり　あいのひげき",e:"Dark Scorpion - Tragedy of Love",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールドに「首領・ザルーグ」が存在する場合、自分フィールド上の表側表示の「黒蠍－棘のミーネ」１体を墓地へ送って発動できる。\n相手フィールド上のモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"猫招き",h:"ねこまねき",e:"Inviting Cat",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"デッキから「キャット・ガール」または「捨て猫」と名のついたモンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"トライアングル－O",h:"とらいあんぐる－おー",e:"Triangle O",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"自分フィールド上に「水晶ドクロ」「アショカ・ピラー」「カブレラストーン」が存在する場合に発動できる。フィールド上のカードを全て破壊する。\nこのターン自分が受ける効果ダメージは代わりに相手が受ける。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"デーモンの杖",h:"でーもんのつえ",e:"Archfiend's Staff of Despair",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"自分フィールドのモンスターにのみ装備可能。\nこのカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分メインフェイズに発動できる。\n相手フィールドの全てのモンスターの攻撃力はターン終了時まで、装備モンスターの攻撃力の半分ダウンする。\n②：モンスターに装備されているこのカードが墓地へ送られた場合、１０００LPを払って発動できる。\nこのカードを手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"竜星の気脈",h:"りゅうせいのきみゃく",e:"Yang Zing Prana",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：自分の墓地の「竜星」モンスターの属性の種類の数によって以下の効果を得る。\n\n●２種類以上：自分フィールドの「竜星」モンスターの攻撃力は５００アップする。\n\n●３種類以上：自分フィールドの「竜星」モンスターが戦闘・効果で破壊される場合、代わりにこのカードを墓地へ送る事ができる。\n\n●４種類以上：相手はモンスターをセットできず、相手フィールドの表側表示モンスターは全て攻撃表示になる。\n\n●５種類以上：このカードを墓地へ送って発動できる。\nフィールドのカードを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マジェスペクター・ソニック",h:"まじぇすぺくたー・そにっく",e:"Majespecter Sonics",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの「マジェスペクター」モンスター１体を対象として発動できる。\nそのモンスターはターン終了時まで、攻撃力・守備力が倍になり、相手に与える戦闘ダメージは半分になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ペンデュラム・ターン",h:"ぺんでゅらむ・たーん",e:"Pendulum Shift",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分または相手のPゾーンのカード１枚を対象とし、１～１０までのPスケールを宣言して発動できる。\nこのターン、そのカードは宣言したPスケールになる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"風魔手裏剣",h:"ふうましゅりけん",e:"Fuhma Shuriken",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"「忍者」という名のついたモンスターのみ装備可能。\n装備モンスターは攻撃力が７００ポイントアップする。\nこのカードがフィールド上から墓地に送られた時、相手ライフに７００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スピリット・バーナー",h:"すぴりっと・ばーなー",e:"Spirit Burner",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"装備魔法",tp:"",at:"",de:"",tx:"１ターンに１度、装備モンスターを守備表示にする事ができる。\n装備モンスターがフィールド上から手札に戻る事によってこのカードが墓地へ送られた時、相手ライフに６００ポイントダメージを与える。\nこのカードが墓地に存在する場合、自分のドローフェイズ時に通常のドローを行う代わりに、このカードを手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻界突破",h:"げんかいとっぱ",e:"Dracocension",ra:"N",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"①：１ターンに１度、自分フィールドのドラゴン族モンスター１体をリリースして発動できる。\nリリースしたモンスターの元々のレベルと同じレベルの幻竜族モンスター１体をデッキから特殊召喚する。\nこの効果で特殊召喚したモンスターが戦闘で破壊したモンスターは墓地へ送らず持ち主のデッキに戻る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マジェスペクター・ストーム",h:"まじぇすぺくたー・すとーむ",e:"Majespecter Storm",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの魔法使い族・風属性モンスター１体をリリースし、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターを持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マジェスペクター・サイクロン",h:"まじぇすぺくたー・さいくろん",e:"Majespecter Cyclone",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：自分フィールドの魔法使い族・風属性モンスター１体をリリースし、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マジェスティックP",h:"まじぇすてぃっくぺがさす",e:"Majesty's Pegasus",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"フィールド魔法",tp:"",at:"",de:"",tx:"「マジェスティックP」の②の効果は１ターンに１度しか使用できない。\n①：フィールドの「マジェスペクター」モンスターの攻撃力・守備力は３００アップする。\n②：自分フィールドの魔法使い族・風属性モンスター１体をリリースして発動できる。\nデッキからレベル４以下の「マジェスペクター」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ペンデュラム・モラトリアム",h:"ぺんでゅらむ・もらとりあむ",e:"Pendulum Impenetrable",ra:"R",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"速攻魔法",tp:"",at:"",de:"",tx:"①：このターン、自分及び相手のPゾーンのカードは相手の効果では破壊されず、Pゾーンのカードを対象として相手が発動した効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ペンデュラム・コール",h:"ぺんでゅらむ・こーる",e:"Pendulum Call",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「ペンデュラム・コール」は１ターンに１枚しか発動できず、「魔術師」PモンスターのP効果を発動したターンには発動できない。\n①：手札を１枚捨てて発動できる。\nカード名が異なる「魔術師」Pモンスター２体をデッキから手札に加える。\nこのカードの発動後、次の相手ターン終了時まで自分のPゾーンの「魔術師」カードは効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"オッドアイズ・フュージョン",h:"おっどあいず・ふゅーじょん",e:"Odd-Eyes Fusion",ra:"SR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"「オッドアイズ・フュージョン」は１ターンに１枚しか発動できない。\n①：自分の手札・フィールドから、ドラゴン族の融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をエクストラデッキから融合召喚する。\n相手フィールドにモンスターが２体以上存在し、自分フィールドにモンスターが存在しない場合、自分のエクストラデッキの「オッドアイズ」モンスターも２体まで融合素材とする事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"螺旋のストライクバースト",h:"らせんのすとらいくばーすと",e:"Spiral Flame Strike",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：以下の効果から１つを選択して発動できる。\n\n●自分フィールドに「オッドアイズ」カードが存在する場合、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\n\n●デッキのモンスター及び自分のEXデッキの表側表示のPモンスターの中から、レベル７の「オッドアイズ」モンスター１体を選んで手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"束ねられし力",h:"たばねられしちから",e:"Strength in Unity",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"永続魔法",tp:"",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分が「青眼の白龍」または「ブラック・マジシャン」を使用した儀式・融合召喚に成功した場合、相手のフィールド・墓地のカード１枚を対象として発動できる。\nそのカードを除外する。\n②：魔法＆罠ゾーンの表側表示のこのカードを墓地へ送り、自分の墓地のレベル７以上の通常モンスター１体を対象として発動できる。\nそのモンスターを手札に加えるかデッキに戻す。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"死者蘇生",h:"ししゃそせい",e:"Monster Reborn",ra:"UR",t:"magic",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"通常魔法",tp:"",at:"",de:"",tx:"①：自分または相手の墓地のモンスター１体を対象として発動できる。\nそのモンスターを自分フィールドに特殊召喚する。",li:1,lk:[],ta:[],al:[],ocg:false},
  {n:"DNA移植手術",h:"ＤＮＡいしょくしゅじゅつ",e:"DNA Transplant",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"発動時に１種類の属性を宣言する。\nこのカードがフィールド上に存在する限り、フィールド上の全ての表側表示モンスターは自分が宣言した属性になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"DNA改造手術",h:"ＤＮＡかいぞうしゅじゅつ",e:"DNA Surgery",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"種族を１つ宣言して発動する。\nこのカードがフィールド上に存在する限り、フィールド上に表側表示で存在する全てのモンスターは宣言した種族になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"DNA定期健診",h:"ＤＮＡていきけんしん",e:"DNA Checkup",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に裏側表示で存在するモンスター１体を選択して発動する。\n相手はモンスターの属性を２つ宣言する。\n選択したモンスターをめくって確認し宣言された属性だった場合、相手はデッキからカードを２枚ドローする。\n違った場合、自分はデッキからカードを２枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アース・グラビティ",h:"あーす・ぐらびてぃ",e:"Terra Firma Gravity",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手のバトルフェイズに自分のモンスターゾーン上に「E・HERO ジ・アース」が存在する場合に発動できる。\nこのターン、攻撃可能な相手のレベル４以下のモンスターは「E・HERO ジ・アース」を攻撃しなければならない。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"アーマーブレイク",h:"あーまーぶれいく",e:"Armor Break",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"装備魔法カードが発動した時に発動できる。\n装備魔法カードの発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"アイヴィ・シャックル",h:"あいう゛ぃ・しゃっくる",e:"Ivy Shackles",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分のターン中、相手フィールド上の全てのモンスターは植物族になる。\nフィールド上の表側表示のこのカードが相手の効果によって破壊され墓地へ送られた時、自分のデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"アクアの合唱",h:"あくあのがっしょう",e:"Aqua Chorus",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：フィールドに同名モンスターが存在する、フィールドのモンスターの攻撃力・守備力は５００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"亜空間ジャンプ装置",h:"あくうかんじゃんぷそうち",e:"Interdimensional Warp",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上モンスター１体と、相手フィールド上のAカウンターが乗ったモンスター１体のコントロールを入れ替える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"亜空間物質転送装置",h:"あくうかんぶっしつてんそうそうち",e:"Interdimensional Matter Transporter",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの表側表示モンスター１体を対象として発動できる。\nその自分の表側表示モンスターをエンドフェイズまで除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"悪魔のサイコロ",h:"あくまのさいころ",e:"Skull Dice",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：サイコロを１回振る。\n相手フィールドのモンスターの攻撃力・守備力は、ターン終了時まで出た目の数×１００ダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"悪魔の手鏡",h:"あくまのてかがみ",e:"Fiend's Hand Mirror",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"フィールド上の魔法・罠カード１枚を対象に発動した相手の魔法を、別の正しい対象に移し替える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"悪魔の嘆き",h:"あくまのなげき",e:"Fiend Griefing",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手の墓地のモンスター１体を対象として発動できる。\nそのモンスターを相手のデッキに戻す。\nその後、自分のデッキから悪魔族モンスター１体を墓地へ送る事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"悪魔の憑代",h:"あくまのよりしろ",e:"Sinister Yorishiro",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在する限り、自分はレベル５以上の悪魔族モンスターを召喚する場合に必要なリリースをなくす事ができる。\nこの効果は１ターンに１度しか適用できない。\n②：通常召喚したレベル５以上の悪魔族モンスター１体のみが破壊される場合、代わりにこのカードを墓地へ送る事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"悪夢の迷宮",h:"あくむのめいきゅう",e:"Labyrinth of Nightmare",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"各ターンのエンドフェイズ時、ターンプレイヤーのフィールド上に表側表示で存在する全てのモンスターの表示形式を変更する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アグレッシブ・クラウディアン",h:"あぐれっしぶ・くらうでぃあん",e:"Raging Cloudian",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在する「雲魔物」と名のついたモンスターが自身の効果により破壊され墓地に送られた時に発動する事ができる。\n自分の墓地からそのモンスター１体を攻撃表示で特殊召喚し、そのモンスターにフォッグカウンターを１つ置く。\nこの効果で特殊召喚されたモンスターはカードの効果によっては守備表示にならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"明と宵の逆転",h:"あけとよいのぎゃくてん",e:"Exchange of Night and Day",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"以下の効果から１つを選択して発動できる。\n「明と宵の逆転」の効果は１ターンに１度しか使用できない。\n\n●手札から戦士族・光属性モンスター１体を墓地へ送る。\nその後、そのモンスターと同じレベルの戦士族・闇属性モンスター１体をデッキから手札に加える。\n\n●手札から戦士族・闇属性モンスター１体を墓地へ送る。\nその後、そのモンスターと同じレベルの戦士族・光属性モンスター１体をデッキから手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アサルト・スピリッツ",h:"あさると・すぴりっつ",e:"Assault Spirits",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"発動後このカードは装備カードとなり、自分フィールド上に存在するモンスター１体に装備する。\n装備モンスターが攻撃する場合、そのダメージステップ時に手札から攻撃力１０００以下のモンスター１体を墓地へ送る事で、装備モンスターの攻撃力はエンドフェイズ時まで、墓地へ送ったモンスターの攻撃力分アップする。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アストラルバリア",h:"あすとらるばりあ",e:"Astral Barrier",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"相手モンスターが自分フィールド上モンスターを攻撃する場合、その攻撃を自分ライフへの直接攻撃にする事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アタック・リフレクター・ユニット",h:"あたっく・りふれくたー・ゆにっと",e:"Attack Reflector Unit",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の「サイバー・ドラゴン」１体を生け贄に捧げて発動する。\n自分の手札・デッキから「サイバー・バリア・ドラゴン」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"輝石融合",h:"あっせんぶる・ふゅーじょん",e:"Pyroxene Fusion",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の手札・フィールド上から、融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、「ジェムナイト」と名のついたその融合モンスター１体を融合召喚扱いとしてエクストラデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アヌビスの裁き",h:"あぬびすのさばき",e:"Judgment of Anubis",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"手札を１枚捨てる。\n相手がコントロールする「フィールド上の魔法・罠カードを破壊する」効果を持つ魔法カードの発動と効果を無効にし破壊する。\nその後、相手フィールド上の表側表示モンスター１体を破壊し、そのモンスターの攻撃力分のダメージを相手プレイヤーに与える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アヌビスの呪い",h:"あぬびすののろい",e:"Curse of Anubis",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"フィールド上に表側表示で存在する効果モンスターは全て守備表示になる。\n発動ターン、それらの効果モンスターの元々の守備力は０になり、表示形式の変更ができない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アビスコール",h:"あびすこーる",e:"Abyss-squall",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の墓地の「水精鱗」と名のついたモンスター３体を選択して表側守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化され、攻撃宣言できず、エンドフェイズ時に破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アビスコーン",h:"あびすこーん",e:"Abyss-scorn",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の「水精鱗」と名のついたモンスター１体を選択して発動できる。\n選択したモンスターの攻撃力はエンドフェイズ時まで１０００ポイントアップする。\nまた、セットされたこのカードが墓地へ送られた時、相手フィールド上のモンスター１体を選択して墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アビスフィアー",h:"あびすふぃあー",e:"Abyss-sphere",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"デッキから「水精鱗」と名のついたモンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。\nまた、このカードがフィールド上に存在する限り、自分は魔法カードを発動できない。\nこのカードがフィールド上から離れた時、そのモンスターを破壊する。\nこのカードは発動後、次の相手のエンドフェイズ時に破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アポピスの化神",h:"あぽぴすのけしん",e:"Embodiment of Apophis",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：自分・相手のメインフェイズに発動できる。\nこのカードは発動後、通常モンスター（爬虫類族・地・星４・攻１６００／守１８００）となり、モンスターゾーンに特殊召喚する。\nこのカードは罠カードとしても扱う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アマゾネス転生術",h:"あまぞねすてんせいじゅつ",e:"Amazoness Shamanism",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「アマゾネス」と名のついたモンスターを全て破壊する。\nその後、破壊した数と同じ数まで自分の墓地に存在するレベル４以下の「アマゾネス」と名のついたモンスターを表側守備表示で特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アマゾネスの意地",h:"あまぞねすのいじ",e:"Amazoness Willpower",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分の墓地から「アマゾネス」と名のついたモンスター１体を選択し、攻撃表示で特殊召喚する。\nこの効果で特殊召喚したモンスターは表示形式を変更する事ができず、攻撃可能な場合には攻撃しなければならない。\nこのカードがフィールド上に存在しなくなった時、そのモンスターを破壊する。\nそのモンスターが破壊された時このカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アマゾネスの急襲",h:"あまぞねすのきゅうしゅう",e:"Amazoness Onslaught",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：１ターンに１度、自分・相手のバトルフェイズに発動できる。\n手札から「アマゾネス」モンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターの攻撃力はターン終了時まで５００アップする。\n②：自分の「アマゾネス」モンスターが相手モンスターと戦闘を行ったダメージ計算後に発動できる。\nその相手モンスターを除外する。\n③：フィールドのこのカードが破壊され墓地へ送られた場合、自分の墓地の「アマゾネス」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アマゾネスの弩弓隊",h:"あまぞねすのどきゅうたい",e:"Amazoness Archers",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手の攻撃宣言時に、自分フィールド上に「アマゾネス」という名のついたモンスターが存在する場合のみ発動する事ができる。\n相手フィールド上の全てのモンスターは表側攻撃表示になり（リバース効果は発動しない）、攻撃力は５００ポイントダウンする。\n相手は全てのモンスターで攻撃しなければならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"あまのじゃくの呪い",h:"あまのじゃくののろい",e:"Reverse Trap",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"発動ターンのエンドフェイズ時まで、攻撃力・守備力のアップ・ダウンの効果は逆になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アルカナコール",h:"あるかなこーる",e:"Arcana Call",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「アルカナフォース」と名のついたモンスター１体を選択して発動する。\n墓地に存在する「アルカナフォース」と名のついたモンスター１体をゲームから除外する。\nエンドフェイズ時まで、選択したモンスターがコイントスによって得た効果は、ゲームから除外したモンスターがコイントスによって得る効果と同じ効果になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"アルケミー・サイクル",h:"あるけみー・さいくる",e:"Alchemy Cycle",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"発動ターンのエンドフェイズ時まで、自分フィールド上に表側表示で存在するモンスター全ての元々の攻撃力を０にする。\nこの効果によって元々の攻撃力が０になっているモンスターが戦闘によって破壊され墓地へ送られる度に、自分のデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"暗黒界の洗脳",h:"あんこくかいのせんのう",e:"Dark World Brainwashing",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：自分の手札が３枚以上存在し、相手モンスターの効果が発動した時、フィールドの「暗黒界」モンスター１体を対象としてこの効果を発動できる。\n対象のモンスターを持ち主の手札に戻し、その相手の効果は「相手の手札をランダムに１枚選んで捨てる」となる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"暗黒の呪縛",h:"あんこくのじゅばく",e:"Curse of Darkness",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在する限り、自分または相手が魔法カードを発動する度にその発動したプレイヤーに１０００ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"暗黒の瘴気",h:"あんこくのしょうき",e:"Dark Smog",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"１ターンに１度、相手の墓地のモンスター１体を選択して発動できる。\n手札から悪魔族モンスター１体を捨て、選択したモンスターをゲームから除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"暗黒よりの軍勢",h:"あんこくよりのぐんぜい",e:"The Forces of Darkness",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の墓地に存在する「暗黒界」と名のついたモンスター２体を選択して手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"古代の機械蘇生",h:"あんてぃーく・ぎありぼーん",e:"Ancient Gear Reborn",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：「古代の機械蘇生」は自分フィールドに１枚しか表側表示で存在できない。\n②：１ターンに１度、自分フィールドにモンスターが存在しない場合、自分の墓地の「アンティーク・ギア」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターの攻撃力は２００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"イービル・ブラスト",h:"いーびる・ぶらすと",e:"Evil Blast",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手フィールド上にモンスターが特殊召喚された時に発動する事ができる。\n発動後このカードは攻撃力５００ポイントアップの装備カードとなり、そのモンスターに装備する。\n相手のスタンバイフェイズ毎に５００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"イカサマ御法度",h:"いかさまごはっと",e:"Fraud Freeze",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：１ターンに１度、相手が手札からモンスターを特殊召喚した時に発動できる。\n手札から特殊召喚された相手フィールドのモンスターを全て持ち主の手札に戻す。\n②：フィールドに「花札衛」Sモンスターが存在しない場合にこのカードは墓地へ送られる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"雷の裁き",h:"いかずちのさばき",e:"Judgment of Thunder",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドに雷族モンスターが召喚・反転召喚・特殊召喚された時、相手フィールドのカード１枚を対象として発動できる。\nその相手のカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"イクイップ・シュート",h:"いくいっぷ・しゅーと",e:"Equip Shot",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"バトルフェイズ中のみ発動する事ができる。\n自分フィールド上に表側攻撃表示で存在するモンスターに装備された装備カード１枚と相手フィールド上に存在する表側攻撃表示のモンスター１体を選択し、選択した装備カードを選択した相手モンスターに装備する。\nその後、選択した装備カードを装備していた自分のモンスターと、選択した相手モンスターで戦闘を行いダメージ計算を行う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"イグナイト・バースト",h:"いぐないと・ばーすと",e:"Igknight Burst",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：１ターンに１度、自分メインフェイズにこの効果を発動できる。\nこのカード以外の自分フィールドの「イグナイト」カードを３枚まで選んで破壊する。\nその後、破壊した数だけ相手フィールドのカードを選んで持ち主の手札に戻す。\n②：このカードが墓地へ送られた場合に発動できる。\n自分のエクストラデッキの表側表示の「イグナイト」Pモンスター１体を選んで手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"生贄の祭壇",h:"いけにえのさいだん",e:"Altar for Tribute",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上のモンスターを１体選択して墓地に送る。\nこのモンスターの元々の攻撃力分のライフポイントを回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"生贄の抱く爆弾",h:"いけにえのだくばくだん",e:"Blast Held by a Tribute",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：アドバンス召喚された相手モンスターの攻撃宣言時に発動できる。\n相手フィールドの表側攻撃表示モンスターを全て破壊し、相手に１０００ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"生贄封じの仮面",h:"いけにえふうじのかめん",e:"Mask of Restrict",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在する限り、お互いにカードをリリースできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"異次元グランド",h:"いじげんぐらんど",e:"Different Dimension Ground",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：このターン、墓地へ送られるモンスターは墓地へは行かず除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"異次元の落とし穴",h:"いじげんのおとしあな",e:"D.D. Trap Hole",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手がモンスター１体を守備表示でセットした時に発動する事ができる。\nセットしたそのモンスター１体と自分フィールド上のモンスター１体を破壊してゲームから除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"異次元の邂逅",h:"いじげんのかいこう",e:"Different Dimension Encounter",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：お互いに、除外されている自分のモンスターが１体以上存在する場合に発動できる。\nお互いのプレイヤーは、それぞれ除外されている自分のモンスター１体を選んで裏側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"異次元への隙間",h:"いじげんへのすきま",e:"Crevice Into the Different Dimension",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"属性を１つ宣言し、お互いの墓地に存在する宣言した属性のモンスターを合計２体選択して発動する。\n選択したモンスターをゲームから除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"異種闘争",h:"いしゅとうそう",e:"Battle of the Elements",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"お互いのフィールド上のモンスターが全て表側表示の場合に発動できる。\nお互いのプレイヤーはそれぞれ属性が１種類になるように、フィールド上の自分のモンスターを墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"イタクァの暴風",h:"いたくぁのぼうふう",e:"Windstorm of Etaqua",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手フィールドの全ての表側表示モンスターの表示形式を変更する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"一族の掟",h:"いちぞくのおきて",e:"The Regulation of Tribe",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"発動時に１種類の種族を宣言する。\nその種族のモンスターは攻撃宣言ができない。\n自分のスタンバイフェイズ毎にモンスター１体を生け贄に捧げなければこのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"一族の結集",h:"いちぞくのけっしゅう",e:"Super Team Buddy Force Unite!",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターと元々のカード名が異なり、元々の種族が同じモンスター１体を自分の手札・墓地から選んで特殊召喚する。\n②：魔法＆罠ゾーンのこのカードが相手の効果で破壊された場合に発動できる。\nデッキから「一族の結集」１枚を選んで自分の魔法＆罠ゾーンにセットする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"一色即発",h:"いっしょくそくはつ",e:"The Revenge of the Normal",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：相手フィールドのモンスターの数まで、手札からレベル４以下のモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"命の綱",h:"いのちのつな",e:"Rope of Life",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分モンスターが戦闘によって墓地に送られた時に、手札を全て捨てて発動する。\nそのモンスターの攻撃力を８００ポイントアップさせて、フィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"威風堂々",h:"いふうどうどう",e:"Chivalry",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"バトルフェイズ中のみ発動する事ができる。\n相手が発動した効果モンスターの効果を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"岩投げアタック",h:"いわなげあたっく",e:"Rock Bombardment",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分のデッキから岩石族モンスター１体を選択して墓地へ送る。\n相手ライフに５００ポイントダメージを与える。\nその後デッキをシャッフルする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"因果切断",h:"いんがせつだん",e:"Karma Cut",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"手札を１枚捨てて発動できる。\n相手フィールド上に表側表示で存在するモンスター１体を選択してゲームから除外する。\nこの効果によって除外したモンスターと同名のカードが相手の墓地に存在する場合、さらにその同名カードを全てゲームから除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"甲虫装機の宝珠",h:"いんぜくたーのおーぶ",e:"Inzektor Orb",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"発動後このカードは攻撃力・守備力５００ポイントアップの装備カードとなり、自分フィールド上の「甲虫装機」と名のついたモンスター１体に装備する。\n自分フィールド上の「甲虫装機」と名のついたモンスター１体がカードの効果の対象になった時、装備カードとなったこのカードを墓地へ送る事で、その効果を無効にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"甲虫装機の手甲",h:"いんぜくたーのがんとれっと",e:"Inzektor Gauntlet",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"発動後このカードは守備力１０００ポイントアップの装備カードとなり、自分フィールド上の「甲虫装機」と名のついたモンスター１体に装備する。\n装備モンスターは相手のカードの効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"インターセプト",h:"いんたーせぷと",e:"Intercept",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"モンスター１体のリリースを必要とするモンスターの召喚に成功した時に発動する事ができる。\nそのモンスター１体のコントロールを得る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"インフェルニティ・インフェルノ",h:"いんふぇるにてぃ・いんふぇるの",e:"Infernity Inferno",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の手札を２枚まで捨てる。\nその後、この効果で捨てた枚数分だけデッキから「インフェルニティ」と名のついたカードを墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"インフェルニティ・バリア",h:"いんふぇるにてぃ・ばりあ",e:"Infernity Barrier",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"自分フィールド上に「インフェルニティ」と名のついたモンスターが表側攻撃表示で存在し、自分の手札が０枚の場合に発動する事ができる。\n相手が発動した効果モンスターの効果・魔法・罠カードの発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"インフェルニティ・フォース",h:"いんふぇるにてぃ・ふぉーす",e:"Infernity Force",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の手札が０枚の場合、「インフェルニティ」と名のついたモンスターが攻撃対象に選択された時に発動する事ができる。\n攻撃モンスター１体を破壊し、自分の墓地に存在する「インフェルニティ」と名のついたモンスター１体を選択して特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"インフェルニティ・ブレイク",h:"いんふぇるにてぃ・ぶれいく",e:"Infernity Break",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の手札が０枚の場合に発動できる。\n自分の墓地の「インフェルニティ」と名のついたカード１枚を選択してゲームから除外し、相手フィールド上のカード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"インフェルニティ・リフレクター",h:"いんふぇるにてぃ・りふれくたー",e:"Infernity Reflector",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在する「インフェルニティ」と名のついたモンスターが戦闘によって破壊され墓地へ送られた時、手札を全て捨てて発動する事ができる。\nそのモンスター１体を自分の墓地から特殊召喚し、相手ライフに１０００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"陰謀の盾",h:"いんぼうのたて",e:"Intrigue Shield",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"発動後このカードは装備カードとなり、自分フィールド上のモンスター１体に装備する。\n装備モンスターは表側攻撃表示で存在する限り、１ターンに１度だけ戦闘では破壊されない。\nまた、装備モンスターの戦闘によって発生する自分への戦闘ダメージは０になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"虚無を呼ぶ呪文",h:"う゛ぁにてぃー・こーる",e:"Vanity's Call",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"チェーン４以降にライフポイントを半分払って発動できる。\nこのカードの発動時に積まれていたチェーン上の全てのカードの発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴァリュアブル・フォーム",h:"う゛ぁりゅあぶる・ふぉーむ",e:"Variable Form",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"１ターンに１度、以下の効果から１つを選択して発動できる。\n●自分フィールド上の「甲虫装機」と名のついたモンスター２体を選択し、選択したモンスター１体をもう１体のモンスターに装備する。\n●自分フィールド上の装備カード扱いの「甲虫装機」と名のついたモンスター１体を選択して自分フィールド上に表側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"戦乙女の契約書",h:"う゛ぁるきりーのけいやくしょ",e:"Dark Contract with the Witch",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"「戦乙女の契約書」の①の効果は１ターンに１度しか使用できない。\n①：手札から「DD」カードまたは「契約書」カードを１枚墓地へ送り、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\n②：このカードが魔法＆罠ゾーンに存在する限り、自分フィールドの悪魔族モンスターの攻撃力は、相手ターンの間１０００アップする。\n③：自分スタンバイフェイズに発動する。\n自分は１０００ダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴァンパイア・アウェイク",h:"う゛ぁんぱいあ・あうぇいく",e:"Vampire Awakening",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：デッキから「ヴァンパイア」モンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターはこのターンのエンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴァンパイア・シフト",h:"う゛ぁんぱいあ・しふと",e:"Vampire Takeover",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分のフィールドカードゾーンにカードが存在せず、自分フィールド上に表側表示で存在するモンスターがアンデット族モンスターのみの場合に発動できる。\nデッキから「ヴァンパイア帝国」１枚を選んで発動する。\nその後、自分の墓地から「ヴァンパイア」と名のついた闇属性モンスター１体を選んで表側守備表示で特殊召喚できる。\n「ヴァンパイア・シフト」は１ターンに１枚しか発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴィクティム・カウンター",h:"う゛ぃくてぃむ・かうんたー",e:"Gemini Counter",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在するデュアルモンスター１体を裏側守備表示にし、相手の魔法カードの発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴェノム・スプラッシュ",h:"う゛ぇのむ・すぷらっしゅ",e:"Venom Burn",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"ヴェノムカウンターが乗ったモンスター１体を選択して発動する。\nそのカードのヴェノムカウンターを取り除き、取り除いたヴェノムカウンターの数×７００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴェンデット・リバース",h:"う゛ぇんでっと・りばーす",e:"Vendread Revolution",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：手札を１枚捨て、自分の墓地の、「ヴェンデット」モンスター１体と儀式魔法カード１枚を対象として発動できる。\nそのモンスターを守備表示で特殊召喚し、その儀式魔法カードを手札に加える。\n②：墓地のこのカードを除外し、除外されている自分のアンデット族モンスター５体を対象として発動できる。\nそのモンスターをデッキに加えてシャッフルする。\nその後、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴェンデット・リユニオン",h:"う゛ぇんでっと・りゆにおん",e:"Vendread Reunion",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：儀式召喚する手札の「ヴェンデット」儀式モンスター１体を相手に見せる。\nレベルの合計が見せた儀式モンスターのレベルと同じになるように、除外されている自分の「ヴェンデット」モンスターを任意の数だけ選び、裏側守備表示で特殊召喚する（同名カードは１枚まで）。\nその後、その裏側守備表示モンスターを全てリリースして手札からその儀式モンスターを儀式召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヴォルカニック・チャージ",h:"う゛ぉるかにっく・ちゃーじ",e:"Volcanic Recharge",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の墓地に存在する「ヴォルカニック」と名のついたモンスターカードを３枚までデッキに戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ウィキッド・リボーン",h:"うぃきっど・りぼーん",e:"Wicked Rebirth",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"８００ライフポイントを払い、自分の墓地に存在するシンクロモンスター１体を選択して発動する。\n選択したモンスターを表側攻撃表示で特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化され、このターン攻撃宣言をする事ができない。\nこのカードがフィールド上に存在しなくなった時、そのモンスターを破壊する。\nそのモンスターが破壊された時このカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ウィッチクラフト・パトローナス",h:"うぃっちくらふと・ぱとろーなす",e:"Witchcrafter Patronus",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の墓地のモンスター及び除外されている自分のモンスターの中から、魔法使い族モンスター１体を対象として発動できる。\nそのモンスターをデッキに戻し、デッキから「ウィッチクラフト」魔法カード１枚を手札に加える。\n②：墓地のこのカードを除外し、除外されている自分の「ウィッチクラフト」魔法カードを任意の数だけ対象として発動できる（同名カードは１枚まで）。\nそのカードを手札に加える。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ウィッチクラフト・マスターピース",h:"うぃっちくらふと・ますたーぴーす",e:"Witchcrafter Masterpiece",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドに「ウィッチクラフト」モンスターが存在する場合、自分または相手の墓地の魔法カード１枚を対象として発動できる。\nそのカードの同名カード１枚を自分のデッキから手札に加える。\n②：自分の墓地から、このカードと魔法カードを任意の数だけ除外して発動できる。\n除外した魔法カードの数と同じレベルの「ウィッチクラフト」モンスター１体をデッキから特殊召喚する。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"宇宙の収縮",h:"うちゅうのしゅうしゅく",e:"Spatial Collapse",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"それぞれのフィールド上に存在しているカードが５枚以下の場合に発動する事ができる。\nお互いにフィールド上に出せるカードの合計枚数は５枚までになる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"潤いの風",h:"うるおいのかぜ",e:"Humid Winds",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：１０００LPを払って発動できる。\nデッキから「アロマ」モンスター１体を手札に加える。\n②：自分のLPが相手より少ない場合に発動できる。\n自分は５００LP回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"U.A.ペナルティ",h:"うるとらあすりーとぺなるてぃ",e:"U.A. Penalty Box",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"「U.A.ペナルティ」の①の効果は１ターンに１度しか使用できない。\n①：自分の「U.A.」モンスターが相手モンスターと戦闘を行うダメージステップ開始時にこの効果を発動できる。\nその相手モンスターを、発動後２回目の相手エンドフェイズまで除外する。\n②：墓地のこのカードを除外して発動できる。\nデッキから「U.A.」魔法カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"運命の扉",h:"うんめいのとびら",e:"The Door of Destiny",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：相手モンスターの直接攻撃宣言時にこのカードを発動できる。\nその攻撃を無効にする。\nその後、このカードは効果モンスター（悪魔族・光・星１・攻／守０）となり、モンスターゾーンに特殊召喚する。\nこのカードは罠カードとしても扱う。\n②：このカードの効果でこのカードが特殊召喚されている場合、自分スタンバイフェイズに自分の墓地から「希望皇ホープ」モンスターを任意の数だけ除外して発動できる（同名カードは１枚まで）。\n除外した数×５００ダメージを相手に与え、そのダメージの数値分だけこのカードの攻撃力をアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"運命のドラ",h:"うんめいのどら",e:"Dora of Fate",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手ターンのみ発動する事ができる。\n相手フィールド上に表側表示で存在するモンスター１体を選択する。\n次の自分ターン、選択したモンスターより１つレベルが低いモンスターの召喚に成功した時、相手ライフに選択したモンスターのレベル×５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"運命の発掘",h:"うんめいのはっくつ",e:"Dig of Destiny",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分が戦闘ダメージを受けた時に発動できる。\n自分はデッキから１枚ドローする。\n②：フィールドのこのカードが相手の効果で破壊された場合に発動できる。\n自分の墓地の「運命の発掘」の枚数分だけ、自分はデッキからドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"運命の火時計",h:"うんめいのひどけい",e:"Pyro Clock of Destiny",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"カード１枚のターンカウントを１ターン進める。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"運命湾曲",h:"うんめいわんきょく",e:"Bending Destiny",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在するモンスターが「フォーチュンレディ」と名のついたモンスターのみの場合に発動できる。\n魔法・罠カードの発動、モンスターの召喚のどれか１つを無効にし、そのカードをゲームから除外する。\nこのターンのエンドフェイズ時に、この効果でゲームから除外したカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"空中補給",h:"えありある・ちゃーじ",e:"Aerial Recharge",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードのコントローラーは、お互いのエンドフェイズ毎に自分フィールドのトークンまたは「幻獣機」モンスター１体をリリースする。\nまたは、どちらもリリースせずにこのカードを墓地へ送る。\n①：１ターンに１度、発動できる。\n自分フィールドに「幻獣機トークン」（機械族・風・星３・攻／守０）１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"栄誉の贄",h:"えいよのにえ",e:"Offering to the Immortals",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分のライフポイントが３０００以下の場合、相手が直接攻撃を宣言した時に発動する事ができる。\nそのモンスターの攻撃を無効にし、自分フィールド上に「贄の石碑トークン」（岩石族・地・星１・攻／守０）２体を特殊召喚し、自分のデッキから「地縛神」と名のついたカード１枚を手札に加える。\n「贄の石碑トークン」は、「地縛神」と名のついたモンスターのアドバンス召喚以外のためにはリリースできず、シンクロ素材とする事もできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エクシーズ・ヴェール",h:"えくしーず・う゛ぇーる",e:"Xyz Veil",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードがフィールド上に存在する限り、お互いのプレイヤーはフィールド上に表側表示で存在する、エクシーズ素材を持ったエクシーズモンスターをカードの効果の対象にできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エクシーズ・ソウル",h:"えくしーず・そうる",e:"Xyz Soul",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分または相手の墓地のエクシーズモンスター１体を選択して発動できる。\n自分フィールド上に存在する全てのモンスターの攻撃力は、エンドフェイズ時まで選択したモンスターのランク×２００ポイントアップする。\nその後、選択したモンスターをエクストラデッキに戻す事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エクシーズ・トライバル",h:"えくしーず・とらいばる",e:"Xyz Tribalrivals",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードがフィールド上に存在する限り、フィールド上のエクシーズ素材を２つ以上持ったエクシーズモンスターは、カードの効果では破壊されない。\nまた、自分フィールド上のエクシーズ素材を２つ以上持ったエクシーズモンスターと戦闘を行った相手モンスターをダメージ計算後に破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エクシーズ・パニッシュ",h:"えくしーず・ぱにっしゅ",e:"Xyz Wrath",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"１ターンに１度、自分フィールド上にエクシーズモンスターが存在し、レベル５以上の効果モンスターの効果が発動した時、手札を１枚捨てる事で、その効果を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エクシーズ・ブロック",h:"えくしーず・ぶろっく",e:"Xyz Block",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"自分フィールド上のエクシーズ素材を１つ取り除いて発動できる。\n相手が発動した効果モンスターの効果の発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エクシーズ・リボーン",h:"えくしーず・りぼーん",e:"Xyz Reborn",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分の墓地のXモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚し、このカードを下に重ねてX素材とする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エクシーズエフェクト",h:"えくしーずえふぇくと",e:"Xyz Effect",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分がエクシーズ召喚に成功した時、フィールド上のカード１枚を選択して発動できる。\n選択したカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エクシーズ熱戦！！",h:"えくしーずねっせん！！",e:"Xyz Xtreme !!",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上のエクシーズモンスターが戦闘によって破壊された時、１０００ライフポイントを払って発動できる。\nお互いのプレイヤーは、破壊されたモンスターのランク以下のランクを持つエクシーズモンスター１体をそれぞれのエクストラデッキから選んで相手に見せる。\n攻撃力の低いモンスターを見せたプレイヤーは、相手が見せたモンスターの攻撃力と、自分が見せたモンスターの攻撃力の差分のダメージを受ける。\n相手がモンスターを見せなかった場合、自分が見せたモンスターの攻撃力分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"臨時収入",h:"えくすとらばっく",e:"Extra Buck",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在する限り、自分のEXデッキにカードが加わる度に、このカードに魔力カウンターを１つ置く（最大３つまで）。\n②：魔力カウンターが３つ置かれているこのカードを墓地へ送って発動できる。\n自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エクストリオの牙",h:"えくすとりおのきば",e:"Exterio's Fang",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"自分フィールド上に「ナチュル」と名のついたモンスターが表側表示で存在する場合に発動する事ができる。\n相手が発動した魔法・罠カードの発動を無効にし破壊する。\nその後、自分の手札を１枚墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"永遠なる銀河",h:"えたーなる・ぎゃらくしー",e:"Eternal Galaxy",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドに「フォトン」モンスターまたは「ギャラクシー」モンスターが存在する場合、自分フィールドのXモンスター１体を対象として発動できる。\nその自分のモンスターよりランクが４つ高い、「フォトン」Xモンスターまたは「ギャラクシー」Xモンスター１体を、対象のモンスターの上に重ねてX召喚扱いとしてEXデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エターナル・ドレッド",h:"えたーなる・どれっど",e:"Eternal Dread",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"「幽獄の時計塔」に時計カウンターを２個乗せる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エッジ・ハンマー",h:"えっじ・はんまー",e:"Edge Hammer",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在する「E・HERO エッジマン」１体を生け贄に捧げる。\n相手フィールド上に存在するモンスター１体を破壊し、そのモンスターの元々の攻撃力分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エナジー・ドレイン",h:"えなじー・どれいん",e:"Energy Drain",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在するモンスター１体を選択する。\nそのモンスターの攻撃力・守備力は、エンドフェイズ時まで相手の手札の数×２００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エネルギー吸収板",h:"えねるぎーきゅうしゅうばん",e:"Energy-Absorbing Monolith",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分にダメージを与える魔法・罠・効果モンスターの効果を相手が発動した時に発動できる。\n自分はダメージを受ける代わりに、その数値分だけライフポイントを回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エレキーパー",h:"えれきーぱー",e:"Wattkeeper",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の墓地に存在するレベル４以下の「エレキ」と名のついたモンスター１体を選択して発動する。\n選択したモンスターを墓地から特殊召喚する。\nこの効果で特殊召喚したモンスターは、このターンのエンドフェイズ時に破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エレキャノン",h:"えれきゃのん",e:"Wattcannon",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"フィールド上にレベル４以下の雷族モンスターが召喚・特殊召喚された時、相手ライフに６００ポイントダメージを与える。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エレメンタル・チャージ",h:"えれめんたる・ちゃーじ",e:"Elemental Recharge",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「E・HERO」と名のついたモンスター１体につき、自分は１０００ライフポイント回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"霊神統一",h:"えれめんたる・とれーにんぐ",e:"Elemental Training",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在する限り、自分フィールドの「霊神の聖殿」は効果では破壊されず、相手の効果の対象にならない。\n②：１ターンに１度、自分フィールドのモンスター１体をリリースして発動できる。\nリリースしたモンスターと元々の属性が異なる「エレメントセイバー」モンスター１体をデッキから特殊召喚する。\n③：魔法＆罠ゾーンの表側表示のこのカードを墓地へ送って発動できる。\n手札を全て捨てる。\nその後、この効果で捨てたカードの数だけ、自分の墓地から「霊神」モンスターを選んで手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"炎渦の胎動",h:"えんかのたいどう",e:"Burgeoning Whirlflame",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"手札から「ラヴァル」と名のついたモンスター１体を墓地へ送って発動する。\n罠カードの発動を無効にし破壊する。\nまた、このカードが墓地に存在する場合、自分の墓地に存在する炎属性モンスター２体をゲームから除外する事で、このカードを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"援軍",h:"えんぐん",e:"Reinforcements",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで５００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"援護射撃",h:"えんごしゃげき",e:"Covering Fire",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手モンスターが自分フィールド上モンスターを攻撃する場合、ダメージステップ時に発動する事ができる。\n攻撃を受けた自分モンスターの攻撃力は、自分フィールド上に表側表示で存在する他のモンスター１体の攻撃力分アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"炎虎梁山爆",h:"えんこりょうざんばく",e:"Five Brothers Explosion",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードの発動時に、自分は自分フィールド上の永続魔法・永続罠カードの数×５００ライフポイント回復する。\nまた、フィールド上に表側表示で存在するこのカードが相手の効果によって墓地へ送られた場合、自分の墓地の永続魔法・永続罠カードの数×５００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エンジェル・リフト",h:"えんじぇる・りふと",e:"Graceful Revival",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分の墓地のレベル２以下のモンスター１体を選択し、表側攻撃表示で特殊召喚する。\nこのカードがフィールド上から離れた時、そのモンスターを破壊する。\nそのモンスターがフィールド上から離れた時、このカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"炎塵爆発",h:"えんじんばくはつ",e:"Dustflame Blast",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の墓地の「ラヴァル」と名のついたモンスターを全てゲームから除外して発動できる。\nこのカードを発動するために除外したモンスターの数まで、フィールド上のカードを選んで破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"EMコール",h:"えんためいとこーる",e:"Performapal Call",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手モンスターの直接攻撃宣言時に、その攻撃モンスター１体を対象として発動できる。\nその攻撃を無効にし、守備力の合計が対象のモンスターの攻撃力以下となるように、デッキから「EM」モンスターを２体まで手札に加える。\nこのカードの発動後、次の自分ターンの終了時まで自分はエクストラデッキからモンスターを特殊召喚できない。",li:1,lk:[],ta:[],al:[],ocg:true},
  {n:"EMリバイバル",h:"えんためいとりばいばる",e:"Performapal Revival",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドのモンスターが戦闘・効果で破壊された場合に発動できる。\n自分の手札・墓地から「EM」モンスター１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"炎熱旋風壁",h:"えんねつせんぷうへき",e:"Molten Whirlwind Wall",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上の「ラヴァル」と名のついたモンスターの攻撃力は、自分の墓地の「ラヴァル」と名のついたモンスターの数×１００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"炎舞－「開陽」",h:"えんぶ－「かいよう」",e:"Fire Formation - Kaiyo",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードを発動したターン、自分フィールド上の獣戦士族モンスターが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。\nまた、このカードがフィールド上に存在する限り、自分フィールド上の獣戦士族モンスターの攻撃力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"炎舞－「天権」",h:"えんぶ－「てんけん」",e:"Fire Formation - Tenken",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードはメインフェイズ１でのみ発動できる。\nこのカードの発動時に、自分フィールド上の獣戦士族モンスター１体を選択する。\nこのカードを発動したメインフェイズ１の間だけ、選択したモンスターの効果は無効になり、このカード以外のカードの効果を受けない。\nまた、このカードがフィールド上に存在する限り、自分フィールド上の獣戦士族モンスターの攻撃力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"炎舞－「天璇」",h:"えんぶ－「てんせん」",e:"Fire Formation - Tensen",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードの発動時に、自分フィールド上の獣戦士族モンスター１体を選択する。\n選択したモンスターの攻撃力はエンドフェイズ時まで７００ポイントアップする。\nまた、このカードがフィールド上に存在する限り、自分フィールド上の獣戦士族モンスターの攻撃力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エンペラー・オーダー",h:"えんぺらー・おーだー",e:"By Order of the Emperor",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：モンスターが召喚に成功した時に発動するモンスターの効果が発動した時、この効果を発動できる。\nその発動を無効にする。\nその後、発動を無効にされたプレイヤーはデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"お家おとりつぶし",h:"おいえおとりつぶし",e:"Eliminating the League",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：手札から魔法カード１枚を捨て、相手フィールドの表側表示モンスター１体を対象として発動できる。\nその相手の表側表示モンスターを破壊する。\nその後、相手の手札がある場合、相手の手札を確認し、破壊したカードの同名カードを全て墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"追い剥ぎゾンビ",h:"おいはぎぞんび",e:"Robbin' Zombie",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上のモンスターが相手プレイヤーに戦闘ダメージを与える度に、相手はデッキの一番上のカードを１枚墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"王宮の陥落",h:"おうきゅうのかんらく",e:"Royal Surrender",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"相手が永続罠カードを発動した時に発動する事ができる。\nそのカードの発動と効果を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"王宮の号令",h:"おうきゅうのごうれい",e:"Royal Command",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードがフィールド上に存在する限り、全てのリバース効果モンスターの発動及び効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"王宮の重税",h:"おうきゅうのじゅうぜい",e:"Royal Writ of Taxation",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手のスタンバイフェイズ時に発動できる。\n相手の手札をランダムに１枚確認する。\n確認したカードまたは同名カードが次の自分のエンドフェイズ終了時までに召喚または発動されなかった場合、相手ライフに１０００ポイントダメージを与える。\n召喚または発動された場合、自分は１０００ポイントダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"王家の呪い",h:"おうけののろい",e:"Curse of Royal",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：フィールドの魔法・罠カード１枚のみを対象として、そのカードを破壊する魔法・罠カードが発動した時に発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"王魂調和",h:"おうごんちょうわ",e:"King's Consonance",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手モンスターの直接攻撃宣言時に発動できる。\nその攻撃を無効にする。\nその後、以下の効果を適用できる。\n●レベルの合計が８以下になるように、自分の墓地からチューナー１体とチューナー以外のモンスターを任意の数だけ選んで除外し、除外したモンスターのレベルの合計と同じレベルを持つSモンスター１体を、エクストラデッキからS召喚扱いで特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"黄金の邪神像",h:"おうごんのじゃしんぞう",e:"Statue of the Wicked",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"セットされたこのカードが破壊され墓地へ送られた時、自分フィールド上に「邪神トークン」（悪魔族・闇・星４・攻／守１０００）１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"王者の看破",h:"おうじゃのかんぱ",e:"Champion's Vigilance",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：自分フィールドにレベル７以上の通常モンスターが存在する場合、以下の効果を発動できる。\n\n●魔法・罠カードが発動した時に発動できる。\nその発動を無効にし破壊する。\n\n●自分または相手がモンスターを召喚・反転召喚・特殊召喚する際に発動できる。\nそれを無効にし、そのモンスターを破壊する。",li:1,lk:[],ta:[],al:[],ocg:true},
  {n:"皇の波動",h:"おうのはどう",e:"Utopian Aura",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上のエクシーズ素材を１つ取り除いて発動できる。\n自分フィールド上の全てのエクシーズモンスターは、エンドフェイズ時までカードの効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"王墓の罠",h:"おうぼのわな",e:"Trap of the Imperial Tomb",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手の墓地から自分フィールドにアンデット族モンスターが特殊召喚された時、フィールドのカード２枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"大落とし穴",h:"おおおとしあな",e:"Giant Trap Hole",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"同時に２体以上のモンスターが特殊召喚に成功した時に発動できる。\nフィールド上のモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"大騒動",h:"おおそうどう",e:"Major Riot",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手のコントロールするカードの効果によって、自分のモンスターがフィールドから手札に戻された時に発動する事ができる。\nフィールド上の全モンスターを持ち主の手札に戻す。\nその後、お互いに戻された数のモンスターを裏側守備表示で手札から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"オーディンの眼",h:"おーでぃんのまなこ",e:"Odin's Eye",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"１ターンに１度、お互いのスタンバイフェイズ時に自分フィールド上に表側表示で存在する「極神」と名のついたモンスター１体を選択して発動する事ができる。\n選択したモンスターの効果をエンドフェイズ時まで無効にし、相手の手札及び相手フィールド上にセットされたカードを全て確認する。\nこの効果の発動に対して、相手は魔法・罠・効果モンスターの効果を発動する事はできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"オーバー・デッド・ライン",h:"おーばー・でっど・らいん",e:"Overdoom Line",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードがフィールド上に存在する限り、墓地から自分フィールド上に特殊召喚した植物族モンスターの攻撃力は１０００ポイントアップする。\nこのカードは発動後２回目の自分のエンドフェイズ時に破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"オーバー・レンチ",h:"おーばー・れんち",e:"Overwind",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「ゼンマイ」と名のついたモンスター１体を選択して発動する。\n選択したモンスターの攻撃力・守備力は倍になり、このターンのエンドフェイズ時に手札に戻る。\n「オーバー・レンチ」は１ターンに１枚しか発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"オーバーウェルム",h:"おーばーうぇるむ",e:"Overwhelm",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"自分フィールド上にレベル７以上のアドバンス召喚したモンスターが表側表示で存在する場合に発動する事ができる。\n罠カードまたは効果モンスターの効果の発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"オーバーリミット",h:"おーばーりみっと",e:"Over Limit",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"５００ライフポイントを払う。\nこのターン戦闘で破壊された攻撃力１０００以下の通常モンスターを、可能な限り自分フィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"大番狂わせ",h:"おおばんくるわせ",e:"A Major Upset",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側攻撃表示で存在するレベル２以下のモンスター１体をリリースして発動する。\nフィールド上に表側表示で存在するレベル７以上の特殊召喚したモンスターを全て持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"オーロラの天気模様",h:"おーろらのてんきもよう",e:"The Weather Auroral Canvas",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：「オーロラの天気模様」は自分フィールドに１枚しか表側表示で存在できない。\n②：このカードと同じ縦列の自分のメインモンスターゾーン及びその両隣の自分のメインモンスターゾーンに存在する「天気」効果モンスターは以下の効果を得る。\n●カード１枚のみが自分または相手の手札に加わった時、このカードを除外して発動できる。\n手札に加わったそのカードを除外し、そのプレイヤーはデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"押し売りゴブリン",h:"おしうりごぶりん",e:"Hard-sellin' Goblin",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上のモンスターが相手ライフに戦闘ダメージを与えた時、相手の魔法＆罠カードゾーンのカード１枚を選択して持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"押し売りゾンビ",h:"おしうりぞんび",e:"Hard-sellin' Zombie",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上のモンスターが相手ライフに戦闘ダメージを与えた時、相手の墓地のカード１枚を選択して持ち主のデッキの一番下に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"落とし穴",h:"おとしあな",e:"Trap Hole",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手が攻撃力１０００以上のモンスターの召喚・反転召喚に成功した時、そのモンスター１体を対象として発動できる。\nその攻撃力１０００以上のモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"落とし大穴",h:"おとしおおあな",e:"Darkfall",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手が表側表示で２体以上のモンスターの特殊召喚に成功した時に発動する事ができる。\nそのモンスターを全て墓地へ送る。\nさらにそのモンスターと同名モンスターを相手の手札・デッキから墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"鬼くじ",h:"おにくじ",e:"Onikuji",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：自分スタンバイフェイズにこの効果を発動する。\n相手はカードの種類（モンスター・魔法・罠）を宣言する。\n自分のデッキの一番上のカードをめくり、めくったカードが宣言した種類のカードだった場合、相手はデッキから１枚ドローする。\n違った場合、相手の手札をランダムに１枚選んで捨てる。\nめくったカードはデッキの一番下に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"オプションハンター",h:"おぷしょんはんたー",e:"Option Hunter",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上のモンスターが戦闘によって破壊され墓地へ送られた時に発動する事ができる。\n自分は破壊されたモンスターの元々の攻撃力分のライフポイントを回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"オルターガイスト・エミュレルフ",h:"おるたーがいすと・えみゅれるふ",e:"Altergeist Emulatelf",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードは発動後、効果モンスター（魔法使い族・光・星４・攻１４００／守１８００）となり、モンスターゾーンに特殊召喚する。\nこのカードは罠カードとしても扱う。\n②：このカードの効果で特殊召喚したこのカードがモンスターゾーンに存在する限り、このカード以外の自分フィールドの「オルターガイスト」罠カードは効果の対象にならず、効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"オルターガイスト・カモフラージュ",h:"おるたーがいすと・かもふらーじゅ",e:"Altergeist Camouflage",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの「オルターガイスト」モンスター１体を対象としてこのカードを発動できる。\nこのカードを装備カード扱いとしてそのモンスターに装備する。\n装備モンスターは相手モンスターの攻撃対象にされない。\n（この効果が適用されたモンスターしか自分フィールドに存在しない状態での相手の攻撃は自分への直接攻撃になる。\n）②：装備モンスターを対象として発動した相手モンスターの効果は無効化される。\n③：自分フィールドの「オルターガイスト」カードが戦闘・効果で破壊される場合、代わりに墓地のこのカードを除外できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"オルフェゴール・アタック",h:"おるふぇごーる・あたっく",e:"Orcustrated Attack",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分または相手のモンスターの攻撃宣言時に、自分フィールドの、「オルフェゴール」モンスターまたは「星遺物」モンスター１体をリリースし、相手フィールドのモンスター１体を対象として発動できる。\nそのモンスターを除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"オルフェゴール・コア",h:"おるふぇごーる・こあ",e:"Orcustrated Core",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：１ターンに１度、自分のフィールド・墓地からモンスター１体を除外し、「オルフェゴール・コア」以外の自分フィールドの、「オルフェゴール」カードまたは「星遺物」カード１枚を対象として発動できる。\nこのターン、そのカードは効果の対象にならない。\n②：このカード以外の自分フィールドの、「オルフェゴール」カードまたは「星遺物」カードが戦闘・効果で破壊される場合、代わりにこのカードを墓地へ送る事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カース・オブ・スタチュー",h:"かーす・おぶ・すたちゅー",e:"Tiki Curse",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードは発動後モンスターカード（岩石族・闇・星４・攻１８００／守１０００）となり、自分のモンスターカードゾーンに特殊召喚する。\nこのカードがフィールド上にモンスター扱いとして存在し、このカード以外のモンスター扱いとした罠カードが相手モンスターと戦闘を行った場合、その相手モンスターをダメージ計算後に破壊する。\nこのカードは罠カードとしても扱う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガードゴー！",h:"がーどごー！",e:"Guard Go!",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの「ガガガ」、「ドドド」、「ゴゴゴ」モンスターのいずれかが戦闘・効果で破壊され墓地へ送られた場合、そのモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nその後、手札から「ガガガ」、「ドドド」、「ゴゴゴ」モンスターを合計２体まで守備表示で特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガード・ブロック",h:"がーど・ぶろっく",e:"Defense Draw",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手ターンの戦闘ダメージ計算時に発動する事ができる。\nその戦闘によって発生する自分への戦闘ダメージは０になり、自分のデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"海竜神の加護",h:"かいりゅうしんのかご",e:"Aegis of the Ocean Dragon Lord",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このターンのエンドフェイズ時まで、自分フィールド上の全てのレベル３以下の水属性モンスターは、戦闘及びカードの効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カウンター・カウンター",h:"かうんたー・かうんたー",e:"Counter Counter",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"カウンター罠カードの発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カウンター・ゲート",h:"かうんたー・げーと",e:"Counter Gate",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手モンスターの直接攻撃宣言時に発動できる。\nその攻撃を無効にし、自分はデッキから１枚ドローする。\nそのドローしたカードがモンスターだった場合、そのモンスターを表側攻撃表示で通常召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カウンター・ジェム",h:"かうんたー・じぇむ",e:"Counter Gem",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の魔法＆罠カードゾーンに存在するカードを全て墓地に送る。\n自分の墓地に存在する「宝玉獣」と名のついたモンスターを可能な限り永続魔法カード扱いとして自分の魔法＆罠カードゾーンに表側表示で置く。\nこのターンのエンドフェイズ時に自分フィールド上に表側表示で存在する「宝玉獣」と名のついたカードを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カウンターパンチ",h:"かうんたーぱんち",e:"Destruction Punch",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"フィールド上に守備表示で存在するモンスターが相手モンスターに攻撃され、その守備力が攻撃モンスターの攻撃力を超えていた場合、ダメージ計算時に発動する事ができる。\nその攻撃モンスターをダメージステップ終了時に破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カオス・インフィニティ",h:"かおす・いんふぃにてぃ",e:"Chaos Infinity",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：フィールドの守備表示モンスターを全て表側攻撃表示にする。\nその後、自分のデッキ・墓地から「機皇」モンスター１体を選んで特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化され、エンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カオス・バースト",h:"かおす・ばーすと",e:"Chaos Burst",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手モンスターの攻撃宣言時に自分フィールドのモンスター１体をリリースし、その攻撃モンスター１体を対象として発動できる。\nその攻撃モンスターを破壊する。\nその後、相手に１０００ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガガガシールド",h:"がががしーるど",e:"Gagagashield",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの魔法使い族モンスター１体を対象としてこのカードを発動できる。\nこのカードを装備カード扱いとしてその自分の魔法使い族モンスターに装備する。\n装備モンスターは１ターンに２度まで、戦闘・効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"隠された魔導書",h:"かくされたまどうしょ",e:"Hidden Spellbook",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカードは自分のターンのみ発動する事ができる。\n自分の墓地に存在する魔法カード２枚を選択し、デッキに加えてシャッフルする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"攪乱作戦",h:"かくらんさくせん",e:"Disturbance Strategy",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手は手札をデッキに加えてシャッフルした後、元の手札の数だけデッキからカードをドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"隠れ兵",h:"かくれへい",e:"Hidden Soldiers",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手がモンスターを召喚・反転召喚した時に発動できる。\n手札からレベル４以下の闇属性モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガスタのつむじ風",h:"がすたのつむじかぜ",e:"Whirlwind of Gusto",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上にモンスターが存在しない場合に発動できる。\n自分の墓地の「ガスタ」と名のついたモンスター２体を選択してデッキに戻す。\nその後、デッキから守備力１０００以下の「ガスタ」と名のついたモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガスタの風塵",h:"がすたのふうじん",e:"Dust Storm of Gusto",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカードを発動したターン、自分フィールド上の「ガスタ」と名のついたモンスターの攻撃宣言時に相手は魔法・罠・効果モンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガスタへの祈り",h:"がすたへのいのり",e:"Blessings for Gusto",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の墓地の「ガスタ」と名のついたモンスター２体を選択し、その２体をデッキに加えてシャッフルする。\nその後、自分の墓地の「ガスタ」と名のついたモンスター１体を選択して特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"化石岩の解放",h:"かせきがんのかいほう",e:"Release from Stone",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"ゲームから除外されている自分の岩石族モンスター１体を選択して特殊召喚する。\nこのカードがフィールド上から離れた時、そのモンスターを破壊する。\nそのモンスターが破壊された時、このカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"化石発掘",h:"かせきはっくつ",e:"Fossil Excavation",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：手札を１枚捨て、自分の墓地の恐竜族モンスター１体を対象としてこのカードを発動できる。\nその恐竜族モンスターを特殊召喚する。\n②：このカードの①の効果で特殊召喚されたモンスターは、このカードが魔法＆罠ゾーンに存在する限り効果が無効化され、このカードがフィールドから離れた時に破壊される。\nそのモンスターが破壊された時にこのカードは破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"かっとビング・チャレンジ",h:"かっとびんぐ・ちゃれんじ",e:"Hi-Five the Sky",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分バトルフェイズに、このターン攻撃を行ったXモンスター１体を対象として発動できる。\nこのバトルフェイズ中、そのモンスターはもう１度だけ攻撃できる。\nこの効果でそのモンスターが攻撃する場合、ダメージステップ終了時まで相手は魔法・罠・モンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガトムズの緊急指令",h:"がとむずのきんきゅうしれい",e:"Gottoms' Emergency Call",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：フィールドに「X－セイバー」モンスターが存在する場合、自分・相手の墓地の「X－セイバー」モンスターを合計２体対象として発動できる。\nそのモンスター２体を自分フィールドに特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"火遁封印式",h:"かとんふういんしき",e:"Sealing Ceremony of Katon",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"１ターンに１度、自分の墓地の炎属性モンスター１体をゲームから除外する事で、相手の墓地のカード１枚を選択してゲームから除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"雷の天気模様",h:"かみなりのてんきもよう",e:"The Weather Thundery Canvas",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：「雷の天気模様」は自分フィールドに１枚しか表側表示で存在できない。\n②：このカードと同じ縦列の自分のメインモンスターゾーン及びその両隣の自分のメインモンスターゾーンに存在する「天気」効果モンスターは以下の効果を得る。\n●このカードが相手モンスターと戦闘を行うダメージステップ開始時に、このカードを除外して発動できる。\nその相手モンスターを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"神の威光",h:"かみのいこう",e:"Solemn Authority",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「極神」と名のついたモンスター１体を選択して発動する。\nこのカードがフィールド上に存在する限り、お互いのプレイヤーは選択したモンスターをカードの効果の対象にする事はできない。\n発動後２回目の自分のスタンバイフェイズ時にこのカードを墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"神の桎梏グレイプニル",h:"かみのしっこくぐれいぷにる",e:"Gleipnir, the Fetters of Fenrir",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分のデッキから「極星」と名のついたモンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"神の摂理",h:"かみのせつり",e:"Ultimate Providence",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：モンスターの効果・魔法・罠カードが発動した時、そのカードと同じ種類（モンスター・魔法・罠）の手札を１枚捨てて発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"神の忠告",h:"かみのちゅうこく",e:"Solemn Scolding",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：自分の魔法＆罠ゾーンにセットされているカードがこのカードのみの場合、３０００LPを払って発動できる。\n\n●モンスターの効果・魔法・罠カードが発動した時に発動できる。\nその発動を無効にし破壊する。\n\n●自分または相手がモンスターを召喚・反転召喚・特殊召喚する際に発動できる。\nそれを無効にし、そのモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"神の恵み",h:"かみのめぐみ",e:"Solemn Wishes",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在する限り、自分がカードをドローする度に、自分は５００LP回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガムシャラ",h:"がむしゃら",e:"Gamushara",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に守備表示で存在するモンスターが攻撃対象になった時に発動できる。\nその守備表示モンスターの表示形式を表側攻撃表示に変更する。\nさらに、その戦闘によって攻撃モンスターを破壊し墓地へ送った時、そのモンスターの元々の攻撃力分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"カラクリ屋敷",h:"からくりやしき",e:"Karakuri Trick House",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「カラクリ」と名のついたモンスターの表示形式が変更された時に発動する事ができる。\nフィールド上に存在するカード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ガラスの鎧",h:"がらすのよろい",e:"Armored Glass",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"装備カードがモンスターに装備された時に発動する事ができる。\nターン終了時までフィールド上の全ての装備カードの効果を無効にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"借カラクリ整備蔵",h:"かりからくりせいびくら",e:"Karakuri Cash Shed",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"自分フィールド上に「カラクリ」と名のついたモンスターが表側守備表示で存在する場合に発動する事ができる。\n相手が発動した魔法・罠カードの発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"借カラクリ旅籠蔵",h:"かりからくりはたごくら",e:"Karakuri Cash Inn",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの「カラクリ」モンスター１体と相手フィールドの効果モンスター１体を対象として発動できる。\nその自分のモンスターの表示形式を変更し、その相手モンスターの効果をターン終了時まで無効にする。\n②：自分フィールドに「カラクリ」モンスターが存在する場合、墓地のこのカードを除外し、フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの表示形式を変更する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"牙竜転生",h:"がりょうてんせい",e:"Dragoncarnation",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"ゲームから除外されている自分のドラゴン族モンスター１体を選択して手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"華麗なるハーピィ・レディ",h:"かれいなるはーぴぃ・れでぃ",e:"Harpie Lady Elegance",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの「ハーピィ・レディ三姉妹」１体を選んで持ち主のデッキに戻す。\nその後、元々のカード名が異なる「ハーピィ」モンスター３体を、自分の手札・デッキ・墓地からそれぞれ１体ずつ選んで特殊召喚できる。\nこのカードの発動後、ターン終了時まで自分は風属性モンスターしか特殊召喚できない。\n②：フィールドのこのカードが相手の効果または自分の「ハーピィ」カードの効果で破壊された場合に発動する。\nデッキから「ハーピィ」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"渇きの風",h:"かわきのかぜ",e:"Dried Winds",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"「渇きの風」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分のLPが回復した場合、相手フィールドの表側表示モンスター１体を対象としてこの効果を発動する。\nそのモンスターを破壊する。\n②：自分フィールドに「アロマ」モンスターが存在し、自分のLPが相手より３０００以上多い場合、その差分のLPを払ってこの効果を発動できる。\n攻撃力の合計が、この効果を発動するために払ったLPの数値以下になるように、相手フィールドの表側表示モンスターを選んで破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"銃砲撃",h:"がん・きゃのん・しょっと",e:"Proton Blast",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：１ターンに１度、コイントスを行う効果が発動した場合、その効果で表が出た数によって以下の効果を適用する。\n\n●１回以上：相手に５００ダメージを与える。\n\n●２回以上：相手フィールドのカード１枚を選んで破壊する。\n\n●３回以上：相手の手札を確認し、その中からカード１枚を選んで捨てる。\n\n②：コイントスを２回以上行う効果が発動した時、墓地のこのカードを除外して発動できる。\nそのコイントスの結果を全て表が出たものとして扱う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"棺桶売り",h:"かんおけうり",e:"Coffin Seller",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"モンスターカードが相手の墓地に送られる度に、相手ライフに３００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"岩盤爆破",h:"がんばんばくは",e:"Minefield Eruption",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「マイン・ゴーレム」１体につき、相手ライフに１０００ポイントダメージを与える。\nその後自分フィールド上に表側表示で存在する「マイン・ゴーレム」を全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ギアギアギア",h:"ぎあぎあぎあ",e:"Geargiagear",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"デッキから「ギアギアーノ」と名のついたモンスター２体を特殊召喚する。\nこの効果で特殊召喚したモンスターのレベルは１つ上がる。\n「ギアギアギア」は１ターンに１枚しか発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"機械王－B.C.3000",h:"きかいおう－Ｂ．Ｃ．３０００",e:"Machine King - 3000 B.C.",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードは発動後モンスターカード（機械族・地・星４・攻／守１０００）となり、自分のモンスターカードゾーンに特殊召喚する。\nこの効果で特殊召喚されている場合のみ、１ターンに１度、自分フィールド上に存在する機械族モンスター１体をリリースする事で、このカードの攻撃力はエンドフェイズ時までリリースしたモンスターの攻撃力分アップする。\nこのカードを発動するターン、自分はモンスターを召喚・特殊召喚する事はできない。\n（このカードは罠カードとしても扱う）",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"機皇廠",h:"きこうしょう",e:"Meklord Factory",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"フィールド上に表側表示で存在する「機皇」と名のついたモンスターが攻撃対象に選択された時に発動する事ができる。\n自分の墓地に存在する「機皇兵」と名のついたモンスター１体を選択して手札に加える。\nその後、攻撃対象となったモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"機甲忍法ラスト・ミスト",h:"きこうにんぽうらすと・みすと",e:"Armor Ninjitsu Art of Rust Mist",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上に「忍者」と名のついたモンスターが存在し、相手フィールド上にモンスターが特殊召喚された時、その特殊召喚したモンスターの攻撃力を半分にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"奇策",h:"きさく",e:"Nitwit Outwit",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：手札からモンスター１体を捨て、フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力は、この効果を発動するために捨てたモンスターの攻撃力分ダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"儀式降臨封印の書",h:"ぎしきこうりんふういんのしょ",e:"Ritual Sealing",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"フィールド上に表側表示で存在する儀式モンスター１体を破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"儀水鏡の幻影術",h:"ぎすいきょうのげんえいじゅつ",e:"Aquamirror Illusion",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"手札から「リチュア」と名のついた儀式モンスター１体を特殊召喚する。\nこの効果で特殊召喚した儀式モンスターは攻撃できず、エンドフェイズ時に持ち主の手札に戻る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"儀水鏡の反魂術",h:"ぎすいきょうのはんごんじゅつ",e:"Aquamirror Cycle",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の水属性モンスター１体を選択してデッキに戻し、自分の墓地に存在する水属性モンスター２体を選択して手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"儀水鏡の瞑想術",h:"ぎすいきょうのめいそうじゅつ",e:"Aquamirror Meditation",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"手札の儀式魔法カード１枚を相手に見せ、自分の墓地の「リチュア」と名のついたモンスター２体を選択して発動できる。\n選択した墓地のモンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"奇跡の光臨",h:"きせきのこうりん",e:"Miraculous Descent",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：除外されている自分の天使族モンスター１体を対象としてこのカードを発動できる。\nそのモンスターを特殊召喚する。\nこのカードがフィールドから離れた時にそのモンスターは破壊される。\nそのモンスターが破壊された時にこのカードは破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"奇跡の残照",h:"きせきのざんしょう",e:"Miracle's Wake",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：このターン戦闘で破壊され自分の墓地へ送られたモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"奇跡の復活",h:"きせきのふっかつ",e:"Miracle Restoring",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の魔力カウンターを２つ取り除いて発動できる。\n自分の墓地から「ブラック・マジシャン」または「バスター・ブレイダー」１体を選択して特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"義賊の極意書",h:"ぎぞくのごくいしょ",e:"Secrets of the Gallant",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の通常モンスター１体を選択して発動できる。\nこのターン選択したモンスターが相手ライフに戦闘ダメージを与えた時、相手の手札をランダムに２枚捨てる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"キックファイア",h:"きっくふぁいあ",e:"Kickfire",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードがフィールド上に存在する限り、自分フィールド上に表側表示で存在する炎属性モンスターがカードの効果によって破壊される度に、その破壊されたモンスターの数だけこのカードにカウンターを置く。\nこの効果は１ターンに１度しか適用できない。\nまた、自分または相手のスタンバイフェイズ時にこのカードを墓地へ送って発動できる。\nこのカードの効果によってこのカードに乗っていたカウンターの数×１０００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"キッズ・ガード",h:"きっず・がーど",e:"Kid Guard",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在する「ヒーロー・キッズ」１体を生け贄に捧げる。\n相手モンスターの攻撃を無効にし、自分のデッキから「E・HERO」と名のついたモンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ギブ＆テイク",h:"ぎぶあんどていく",e:"Give and Take",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分の墓地のモンスター１体と、自分フィールドの表側表示モンスター１体を対象として発動できる。\n対象の墓地のモンスターを相手フィールドに守備表示で特殊召喚し、対象のフィールドのモンスターのレベルはターン終了時まで、その特殊召喚したモンスターのレベル分だけ上がる。",li:1,lk:[],ta:[],al:[],ocg:true},
  {n:"希望の光",h:"きぼうのひかり",e:"Ray of Hope",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の墓地の光属性モンスターカード２枚を自分のデッキに加えてシャッフルする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ギャクタン",h:"ぎゃくたん",e:"Wiretap",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：罠カードが発動した時に発動できる。\nその発動を無効にし、そのカードを持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"逆転する運命",h:"ぎゃくてんするうんめい",e:"Reversal of Fate",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「アルカナフォース」と名のついたモンスター１体を選択して発動する。\n選択したモンスターがコイントスの裏表によって得た効果は逆になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"キャッシュバック",h:"きゃっしゅばっく",e:"Cash Back",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"相手がライフポイントを払って発動した効果モンスターの効果・魔法・罠カードの発動を無効にし、そのカードを持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"キャトルミューティレーション",h:"きゃとるみゅーてぃれーしょん",e:"Beast Soul Swap",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在する獣族モンスター１体を手札に戻し、手札から戻したモンスターと同じレベルの獣族モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ギャンブル",h:"ぎゃんぶる",e:"Gamble",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手の手札が６枚以上、自分の手札が２枚以下の場合に発動する事ができる。\nコイントスを１回行い裏表を当てる。\n当たった場合、自分の手札が５枚になるようにデッキからカードをドローする。\nハズレの場合、次の自分のターンをスキップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"究極・背水の陣",h:"きゅうきょく・はいすいのじん",e:"Backs to the Wall",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"ライフポイントを１００ポイントになるように払って発動できる。\n自分の墓地の「六武衆」と名のついたモンスターを可能な限り特殊召喚する。\n（同名カードは１枚まで。\nただし、フィールド上に存在する同名カードは特殊召喚できない。\n）",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"救護部隊",h:"きゅうごぶたい",e:"First-Aid Squad",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分の墓地の通常モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\n②：このカードが墓地に存在し、通常モンスターが戦闘で破壊された時に発動できる。\nこのカードは通常モンスター（戦士族・地・星３・攻１２００／守４００）となり、モンスターゾーンに守備表示で特殊召喚する（罠カードとしては扱わない）。\nこの効果で特殊召喚したこのカードは、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"救出劇",h:"きゅうしゅつげき",e:"Dramatic Rescue",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"フィールド上の「アマゾネス」という名のついたモンスターを対象にしたカードが発動した時に発動する事ができる。\n対象になったモンスターカードを手札に戻し、別のモンスター１体を手札から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"窮鼠の進撃",h:"きゅうそのしんげき",e:"Attack of the Cornered Rat",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上に存在するレベル３以下の通常モンスターが戦闘を行う場合、そのダメージステップ時に１００の倍数のライフポイントを払って発動する事ができる。\nこのターンのエンドフェイズ時まで、戦闘を行う相手モンスター１体の攻撃力は払った数値分ダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"救魔の奇石",h:"きゅうまのきせき",e:"Dwimmered Glimmer",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの表側表示モンスター１体または自分の墓地のモンスター１体を除外してこのカードを発動できる。\nこのカードは発動後、除外したモンスターの元々のレベルと同じレベルを持つ通常モンスター（魔法使い族・光・攻／守０）となり、モンスターゾーンに特殊召喚する。\nこのカードは罠カードとしても扱う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"強化空間",h:"きょうかくうかん",e:"Reinforced Space",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在する全てのエクシーズモンスターの攻撃力は、エンドフェイズ時までそのモンスターのエクシーズ素材１つにつき３００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"強化蘇生",h:"きょうかそせい",e:"Powerful Rebirth",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：自分の墓地のレベル４以下のモンスター１体を対象としてこのカードを発動できる。\nそのモンスターを特殊召喚する。\nそのモンスターのレベルは１つ上がり、攻撃力・守備力は１００アップする。\nそのモンスターが破壊された時にこのカードは破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"恐撃",h:"きょうげき",e:"Ghosts From the Past",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分の墓地のモンスター２体を除外し、フィールドの表側攻撃表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで０になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"強制退化",h:"きょうせいたいか",e:"Degen-Force",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上のレベル４以上の恐竜族モンスター１体をリリースして発動する。\n自分の手札・墓地からレベル３以下の爬虫類族モンスター２体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"鏡像のスワンプマン",h:"きょうぞうのすわんぷまん",e:"Swamp Mirrorer",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：種族と属性を１つずつ宣言して発動できる。\nこのカードは発動後、宣言した種族・属性を持つ通常モンスター（星４・攻１８００／守１０００）となり、モンスターゾーンに特殊召喚する。\nこのカードは罠カードとしても扱う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"共闘",h:"きょうとう",e:"Unified Front",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカードを発動するターン、自分のモンスターは直接攻撃できない。\n①：手札からモンスター１体を捨て、フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力・守備力はターン終了時まで、このカードを発動するために捨てたモンスターのそれぞれの数値と同じになる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"共同戦線",h:"きょうどうせんせん",e:"United Front",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"自分フィールド上に同じレベルのモンスターが表側表示で２体以上存在する場合に発動する事ができる。\n罠カードの発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"極限への衝動",h:"きょくげんへのしょうどう",e:"Limit Impulse",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"手札を２枚墓地へ送って発動する。\n自分フィールド上に「ソウルトークン」（悪魔族・闇・星１・攻／守０）２体を特殊召喚する。\nこのトークンはアドバンス召喚以外のためにはリリースできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"玉砕指令",h:"ぎょくさいしれい",e:"Order to Smash",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在するレベル２以下の通常モンスター（トークンを除く）１体を選択して発動する。\n発動後、選択した通常モンスターを生け贄に捧げ、相手フィールド上の魔法・罠カードを２枚まで破壊する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"極星宝グングニル",h:"きょくせいほうぐんぐにる",e:"Nordic Relic Gungnir",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「極神」または「極星」と名のついたモンスター１体をゲームから除外し、フィールド上に存在するカード１枚を選択して発動する。\n選択したカードを破壊する。\n発動後２回目の自分のエンドフェイズ時に、この効果を発動するためにゲームから除外したモンスターを表側攻撃表示でフィールド上に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"極星宝ブリージンガ・メン",h:"きょくせいほうぶりーじんが・めん",e:"Nordic Relic Brisingamen",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分及び相手フィールド上に表側表示で存在するモンスターを１体ずつ選択して発動する。\n選択した自分のモンスターの攻撃力はエンドフェイズ時まで、選択した相手モンスターの元々の攻撃力と同じ攻撃力になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"極星宝メギンギョルズ",h:"きょくせいほうめぎんぎょるず",e:"Nordic Relic Megingjord",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「極神」または「極星」と名のついたモンスター１体を選択して発動する。\nエンドフェイズ時まで、選択したモンスターの攻撃力・守備力は元々の数値の倍になる。\nこのターン、選択したモンスターは相手プレイヤーに直接攻撃する事はできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"極星宝レーヴァテイン",h:"きょくせいほうれーう゛ぁていん",e:"Nordic Relic Laevateinn",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このターン戦闘によってモンスターを破壊した、フィールド上に表側表示で存在するモンスター１体を選択して発動する事ができる。\n選択したモンスターを破壊する。\nこのカードの発動に対して、魔法・罠・効果モンスターの効果を発動する事はできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"局地的大ハリケーン",h:"きょくちてきだいはりけーん",e:"Localized Tornado",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の手札・墓地に存在するカードを全て持ち主のデッキに戻してシャッフルする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ギョッ！",h:"ぎょっ！",e:"Oh F!sh!",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"ゲームから除外されている自分の魚族・海竜族・水族モンスター１体をデッキに戻して発動する。\n効果モンスターの効果の発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"緊急儀式術",h:"きんきゅうぎしきじゅつ",e:"Urgent Ritual Art",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドに儀式モンスターが存在しない場合、自分の手札・墓地から儀式魔法カード１枚を除外して発動できる。\nこのカードの効果は、その儀式魔法カード発動時の儀式召喚する効果と同じになる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"緊急同調",h:"きんきゅうどうちょう",e:"Urgent Tuning",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分・相手のバトルフェイズに発動できる。\nSモンスター１体をS召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"キング・スカーレット",h:"きんぐ・すかーれっと",e:"King Scarlet",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：自分の「レッド・デーモン」モンスターが戦闘を行うダメージ計算時にこのカードを発動できる。\nその自分のモンスターはその戦闘では破壊されず、このカードは通常モンスター（悪魔族・チューナー・炎・星１・攻／守０）となり、モンスターゾーンに特殊召喚する。\nこのカードは罠カードとしても扱う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"王者の調和",h:"きんぐす・しんくろ",e:"King's Synchro",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手モンスターが自分のSモンスターに攻撃宣言した時に発動できる。\nその攻撃を無効にする。\nその後、以下の効果を適用できる。\n●その自分のSモンスターと自分の墓地のチューナー１体を除外し、除外したモンスターのレベルの合計と同じレベルを持つSモンスター１体を、エクストラデッキからS召喚扱いで特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"金属探知器",h:"きんぞくたんちき",e:"Metal Detector",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"永続罠カードが発動された時に発動する事ができる。\nターン終了時までフィールド上の全ての永続罠カードの効果を無効にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"銀幕の鏡壁",h:"ぎんまくのみらーうぉーる",e:"Mirror Wall",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードのコントローラーは自分スタンバイフェイズ毎に２０００LPを払う。\nまたはLPを払わずにこのカードを破壊する。\n①：このカードが魔法＆罠ゾーンに存在する限り、相手の攻撃モンスターの攻撃力は半分になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"空牙団の修練",h:"くうがだんのしゅうれん",e:"Training Fur Hire, Fur All Your Training Needs",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分フィールドの「空牙団」モンスターが、戦闘または相手の効果で破壊された場合、そのモンスター１体を対象として発動できる。\nデッキからそのモンスターの元々のレベルより低いレベルを持つ「空牙団」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"鎖付き尖盾",h:"くさりつきすぱいくしーるど",e:"Spikeshield with Chain",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"発動後このカードは攻撃力５００ポイントアップの装備カードとなり、フィールド上のモンスター１体に装備する。\n装備モンスターが守備表示で戦闘を行う場合、装備モンスターの守備力はダメージ計算時のみ、その攻撃力分アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"鎖付き爆弾",h:"くさりつきだいなまいと",e:"Blast with Chain",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの表側表示モンスター１体を対象としてこのカードを発動できる。\nこのカードを攻撃力５００アップの装備カード扱いとして、その自分のモンスターに装備する。\n②：装備カード扱いのこのカードが効果で破壊された場合、フィールドのカード１枚を対象として発動する。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"鎖付きブーメラン",h:"くさりつきぶーめらん",e:"Kunai with Chain",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：以下の効果から１つ、または両方を選択してこのカードを発動できる。\n\n●相手モンスターの攻撃宣言時に、その攻撃モンスター１体を対象として発動できる。\nその攻撃モンスターを守備表示にする。\n\n●自分フィールドの表側表示モンスター１体を対象として発動できる。\nこのカードを攻撃力５００アップの装備カード扱いとして、その自分のモンスターに装備する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"鎖付き真紅眼牙",h:"くさりつきれっどあいずふぁんぐ",e:"Red-Eyes Fang with Chain",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの「レッドアイズ」モンスター１体を対象としてこのカードを発動できる。\nこのカードを装備カード扱いとして、そのモンスターに装備する。\n装備モンスターは１度のバトルフェイズ中に２回までモンスターに攻撃できる。\n②：装備されているこのカードを墓地へ送り、フィールドの効果モンスター１体を対象として発動できる。\nその効果モンスターを装備カード扱いとして、このカードを装備していたモンスターに装備する。\nこの効果でモンスターを装備している限り、装備モンスターはそのモンスターと同じ攻撃力・守備力になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"串刺しの落とし穴",h:"くしざしのおとしあな",e:"Trap Hole of Spikes",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：このターンに召喚・特殊召喚された相手モンスターの攻撃宣言時に発動できる。\nその攻撃モンスターを破壊し、そのモンスターの元々の攻撃力の半分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"苦渋の黙札",h:"くじゅうのもくさつ",e:"Painful Escape",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドのモンスター１体をリリースして発動できる。\nリリースしたモンスターと元々のカード名が異なり、元々の種族・属性・レベルが同じモンスター１体を自分のデッキ・墓地から選んで手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"くず鉄の像",h:"くずてつのぞう",e:"Scrap-Iron Statue",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"「くず鉄の像」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手フィールドの既に表側表示で存在している魔法・罠カードがその効果を発動した時に発動できる。\nそのカードを破壊する。\n発動後このカードは墓地へ送らず、そのままセットする。\n②：このカードが墓地へ送られた場合、自分の墓地の「ジャンク」モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"苦紋様の土像",h:"くもんようのどぞう",e:"Statue of Anguish Pattern",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードは発動後、効果モンスター（岩石族・地・星７・攻０／守２５００）となり、モンスターゾーンに特殊召喚する。\nこのカードは罠カードとしても扱う。\n②：このカード以外のモンスター扱いの罠カードがモンスターゾーンに存在する限り、このカードの効果で特殊召喚されたこのカードは相手の効果の対象にならない。\n③：このカードの効果で特殊召喚されたこのカードが存在し、自分の魔法＆罠ゾーンのカードがモンスターゾーンに特殊召喚された場合、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"グラディアル・チェンジ",h:"ぐらでぃある・ちぇんじ",e:"Gladiator Lash",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に「剣闘獣」と名のついたモンスターが特殊召喚された時に発動する事ができる。\n相手は手札を１枚選択して捨てる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"剣闘海戦",h:"ぐらでぃあるなうまきあ",e:"Gladiator Naumachia",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在し、自分フィールドに「剣闘獣」モンスターが存在する限り、攻撃可能な相手モンスターは攻撃しなければならない。\n②：１ターンに１度、自分の手札・墓地から「剣闘獣」モンスター１体をデッキに戻し、自分フィールドの「剣闘獣」モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで元々の守備力分アップする。\n③：魔法＆罠ゾーンのこのカードが効果で破壊された場合に発動できる。\nこのターン、自分の「剣闘獣」モンスターは戦闘では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"剣闘獣の戦車",h:"ぐらでぃあるびーすと・ちゃりおっと",e:"Gladiator Beast War Chariot",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：自分フィールドに「剣闘獣」モンスターが存在し、モンスターの効果が発動した時に発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"暗闇を吸い込むマジック・ミラー",h:"くらやみをすいこむまじっく・みらー",e:"Shadow-Imprisoning Mirror",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードがフィールド上に存在する限り、フィールド上・墓地で発動する闇属性モンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"地縛波",h:"ぐらんど・うぇーぶ",e:"Earthbound Wave",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"自分フィールド上に「地縛神」と名のついたモンスターが存在する場合に発動できる。\n相手が発動した魔法・罠カードの発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"グリード",h:"ぐりーど",e:"Greed",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"カードの効果でドローを行ったプレイヤーは、そのターンのエンドフェイズ終了時にカードの効果でドローしたカードの枚数×５００ポイントダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"クリスタル・アバター",h:"くりすたる・あばたー",e:"Krystal Avatar",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：相手モンスターの直接攻撃宣言時、そのモンスターの攻撃力が自分のLP以上の場合に発動できる。\nこのカードは発動後、自分のLPの数値と同じ攻撃力の効果モンスター（戦士族・光・星４・攻？／守０）となり、モンスターゾーンに攻撃表示で特殊召喚する。\nその後、攻撃対象をこのカードに移し替える。\nこのカードは罠カードとしても扱う。\n②：このカードの効果で特殊召喚したこのカードが戦闘で破壊されたダメージ計算後に発動する。\nこのカードの攻撃力分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"クリストロン・インパクト",h:"くりすとろん・いんぱくと",e:"Crystron Impact",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：除外されている自分の「クリストロン」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚し、相手フィールドに表側表示モンスターが存在する場合、その相手モンスターの守備力は０になる。\n②：自分フィールドの「クリストロン」モンスターを対象とした魔法・罠・モンスターの効果が発動した時、墓地のこのカードを除外して発動できる。\nその効果を無効にする。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:2,lk:[],ta:[],al:[],ocg:true},
  {n:"クリストロン・エントリー",h:"くりすとろん・えんとりー",e:"Crystron Entry",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"「クリストロン・エントリー」の②の効果は１ターンに１度しか使用できない。\n①：自分の手札・墓地から「クリストロン」チューナーをそれぞれ１体ずつ選んで特殊召喚する。\n②：墓地のこのカードを除外し、自分フィールドの「クリストロン」モンスター１体を対象として発動できる。\nそのモンスターのレベルと異なるレベルを持つ「クリストロン」モンスター１体をデッキから墓地へ送る。\n対象のモンスターのレベルは、墓地へ送ったモンスターのレベルと同じになる。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"グリフォンの翼",h:"ぐりふぉんのつばさ",e:"Gryphon Wing",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手が「ハーピィの羽根帚」を発動した時に発動できる。\nその効果を無効にし、相手フィールドの魔法・罠カードを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"クリムゾン・ヘルフレア",h:"くりむぞん・へるふれあ",e:"Crimson Fire",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"自分フィールド上に「レッド・デーモンズ・ドラゴン」が存在し、自分にダメージを与える魔法・罠カードを相手が発動した時に発動できる。\n自分が受けるその効果ダメージの代わりに、相手はその数値の倍のダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"クレイ・チャージ",h:"くれい・ちゃーじ",e:"Clay Charge",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の「E・HERO クレイマン」が攻撃対象に選択された時に発動する事ができる（選択されたカードがセットされている場合、そのカードをめくって確認する）。\n攻撃モンスターと選択された「E・HERO クレイマン」を破壊し、相手ライフに８００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"グレイドル・コンバット",h:"ぐれいどる・こんばっと",e:"Graydle Combat",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：自分フィールドの「グレイドル」モンスター１体のみを対象とするモンスターの効果・魔法・罠カードが発動した時、以下の効果から１つを選択して発動できる。\n\n●その効果は「対象のモンスター１体を破壊する」となる。\n\n●その発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"グレイドル・スプリット",h:"ぐれいどる・すぷりっと",e:"Graydle Split",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"「グレイドル・スプリット」の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドのモンスター１体を対象としてこのカードを発動できる。\nこのカードを攻撃力５００アップの装備カード扱いとして、そのモンスターに装備する。\n②：自分メインフェイズに、このカードの効果で装備されているこのカードを墓地へ送って発動できる。\nこのカードを装備していたモンスターを破壊し、デッキから「グレイドル」モンスター２体を特殊召喚する（同名カードは１枚まで）。\nこの効果で特殊召喚したモンスターはエンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"グレイモヤ不発弾",h:"ぐれいもやふはつだん",e:"Widespread Dud",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"フィールド上に表側攻撃表示で存在するモンスター２体を選択して発動できる。\n選択したモンスターがフィールド上から離れた時、このカードを破壊する。\nこのカードが破壊された時、選択したモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"紅蓮の指名者",h:"ぐれんのしめいしゃ",e:"Appointer of the Red Lotus",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：２０００LPを払い、手札を全て相手に見せて発動できる。\n相手の手札を確認し、その中から１枚を選んで、次の相手エンドフェイズまで除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"クローン複製",h:"くろーんふくせい",e:"Cloning",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手がモンスターの召喚・反転召喚に成功した時、そのモンスター１体を対象として発動できる。\nその表側表示モンスターの元々の種族・属性・レベル・攻撃力・守備力を持つ「クローントークン」１体を自分フィールドに特殊召喚する。\nその表側表示モンスターが破壊され墓地へ送られた時にこのトークンは破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"クロスカウンター",h:"くろすかうんたー",e:"Cross Counter",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"攻撃された守備表示モンスターの守備力が、相手攻撃モンスターの攻撃力を越えていた場合、相手に与える戦闘ダメージは倍になる。\nダメージ計算後にその攻撃モンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"黒猫の睨み",h:"くろねこのにらみ",e:"Black Cat-astrophe",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドに裏側守備表示モンスターが２体以上存在する場合、相手バトルフェイズに発動できる。\nそのバトルフェイズを終了する。\n②：墓地のこのカードを除外し、「占術姫」モンスターを含むフィールドの表側表示モンスター２体を対象として発動できる。\nそのモンスターを裏側守備表示にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"黒羽の導き",h:"くろはねのみちびき",e:"Black Feather Beacon",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"カードの効果によって自分がダメージを受けた時に発動する事ができる。\n手札からレベル４以下の「BF」と名のついたモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"迎撃準備",h:"げいげきじゅんび",e:"Ready for Intercepting",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"フィールド上に表側表示で存在する戦士族か魔法使い族モンスター１体を裏側守備表示にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"携帯型バッテリー",h:"けいたいがたばってりー",e:"Portable Battery Pack",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分の墓地の「電池メン」と名のついたモンスター２体を選択し、表側攻撃表示で特殊召喚する。\nこのカードがフィールド上から離れた時、そのモンスターを全て破壊する。\nそのモンスターが全てフィールド上から離れた時、このカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"決戦の火蓋",h:"けっせんのひぶた",e:"Cry Havoc!",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分の墓地のモンスターカード１枚をゲームから除外する事で、手札から通常モンスター１体を通常召喚する事ができる。\nこの効果は自分ターンのメインフェイズ時にのみ発動する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゲットライド！",h:"げっとらいど！",e:"Roll Out!",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の墓地のユニオンモンスター１体を選択し、自分フィールド上の装備可能なモンスターに装備する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"謙虚な瓶",h:"けんきょなかめ",e:"Jar of Generosity",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分は手札を１枚選んでデッキの一番上または一番下に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"剣現する武神",h:"けんげんするぶじん",e:"Bujin Regalia - The Sword",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"以下の効果から１つを選択して発動できる。\n\n●自分の墓地の「武神」と名のついたモンスター１体を選択して手札に加える。\n\n●ゲームから除外されている自分の「武神」と名のついたモンスター１体を選択して墓地に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻獣の角",h:"げんじゅうのつの",e:"Horn of the Phantom Beast",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"発動後このカードは攻撃力８００ポイントアップの装備カードとなり、自分フィールド上の獣族・獣戦士族モンスター１体に装備する。\n装備モンスターが戦闘によって相手モンスターを破壊し墓地へ送った時、自分のデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"現世と冥界の逆転",h:"げんせとめいかいのぎゃくてん",e:"Exchange of the Spirit",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードはデュエル中に１枚しか発動できない。\n①：お互いの墓地のカードがそれぞれ１５枚以上の場合に１０００LPを払って発動できる。\nお互いのプレイヤーは、それぞれ自分のデッキと墓地のカードを全て入れ替え、その後デッキをシャッフルする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"幻奏のイリュージョン",h:"げんそうのいりゅーじょん",e:"Melodious Illusion",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの「幻奏」モンスター１体を対象として発動できる。\nこのターン、その自分のモンスターは相手の魔法・罠カードの効果を受けず、１度のバトルフェイズ中に２回攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コア・ブラスト",h:"こあ・ぶらすと",e:"Core Blast",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分のスタンバイフェイズ時に１度だけ、相手フィールド上に存在するモンスターの数が自分より多い場合、自分フィールド上に存在するモンスターの数と同じ数になるように相手フィールド上に存在するカードを破壊する事ができる。\nこの効果は自分フィールド上に「コアキメイル」と名のついたモンスターが表側表示で存在する場合のみ発動する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コアキメイルの障壁",h:"こあきめいるのしょうへき",e:"Koa'ki Meiru Shield",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の墓地に「コアキメイルの鋼核」が２枚以上存在する場合、相手モンスターの攻撃宣言時に発動する事ができる。\n相手フィールド上に表側攻撃表示で存在するモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コアの再練成",h:"こあのさいれんせい",e:"Core Reinforcement",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分の墓地に存在する「コアキメイル」と名のついたモンスター１体を選択し、攻撃表示で特殊召喚する。\n自分のエンドフェイズ時にそのモンスターが破壊された時、このカードのコントローラーはそのモンスターの攻撃力分のダメージを受ける。\nこのカードがフィールド上に存在しなくなった時、そのモンスターを破壊する。\nそのモンスターが破壊された時このカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"強引な安全協定",h:"ごういんなあんぜんきょうてい",e:"Forced Ceasefire",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"手札を１枚捨てる。\nこのターンのエンドフェイズ時まで、罠カードを発動する事はできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"鋼核の輝き",h:"こうかくのかがやき",e:"Iron Core Luster",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"手札にある「コアキメイルの鋼核」１枚を相手に見せて発動する。\n相手の魔法・罠カードの発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"狡猾な落とし穴",h:"こうかつなおとしあな",e:"Treacherous Trap Hole",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分の墓地に罠カードが存在しない場合、フィールドのモンスター２体を対象として発動できる。\nそのモンスターを破壊する。",li:2,lk:[],ta:[],al:[],ocg:true},
  {n:"攻撃の無敵化",h:"こうげきのむてきか",e:"Impenetrable Attack",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"バトルフェイズ時にのみ、以下の効果から１つを選択して発動できる。\n\n●フィールド上のモンスター１体を選択して発動できる。\n選択したモンスターはこのバトルフェイズ中、戦闘及びカードの効果では破壊されない。\n\n●このバトルフェイズ中、自分への戦闘ダメージは０になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"荒野の大竜巻",h:"こうやのおおたつまき",e:"Wild Tornado",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：魔法＆罠ゾーンの表側表示のカード１枚を対象として発動できる。\nその表側表示のカードを破壊する。\nその後、破壊されたカードのコントローラーは手札から魔法・罠カード１枚をセットできる。\n②：セットされたこのカードが破壊され墓地へ送られた場合、フィールドの表側表示のカード１枚を対象として発動する。\nその表側表示のカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"強欲な贈り物",h:"ごうよくなおくりもの",e:"The Gift of Greed",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手はデッキからカードを２枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"強欲な瓶",h:"ごうよくなかめ",e:"Jar of Greed",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"光霊術－「聖」",h:"こうれいじゅつ－「ひじり」",e:"Spiritual Light Art - Hijiri",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの光属性モンスター１体をリリースし、除外されている自分または相手のモンスター１体を対象として発動できる。\n相手は手札から罠カード１枚を見せてこの効果を無効にできる。\n見せなかった場合、対象のモンスターを自分フィールドに特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"降霊の儀式",h:"こうれいのぎしき",e:"Rite of Spirit",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"指定した自分の墓地の「墓守の」という名のついたモンスターカード１枚を特殊召喚する。\nこのカードの発動は「王家の眠る谷－ネクロバレー」によっては制限されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゴーゴンの眼",h:"ごーごんのまなこ",e:"Gorgon's Eye",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"エンドフェイズ終了時まで、フィールド上に存在する守備表示モンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゴーストリック・アウト",h:"ごーすとりっく・あうと",e:"Ghostrick Vanish",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"手札の「ゴーストリック」と名のついたモンスター１体を相手に見せて発動できる。\nこのターン、自分フィールド上の「ゴーストリック」と名のついたカード及び裏側守備表示で存在するモンスターはカードの効果の対象にならず、カードの効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゴーストリック・パニック",h:"ごーすとりっく・ぱにっく",e:"Ghostrick Scare",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に裏側守備表示で存在するモンスターを任意の数だけ選択して発動できる。\n選択したモンスターを表側守備表示にし、その中の「ゴーストリック」と名のついたモンスターの数まで、相手フィールド上に表側表示で存在するモンスターを選んで裏側守備表示にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゴーストリック・ブレイク",h:"ごーすとりっく・ぶれいく",e:"Ghostrick Break",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの「ゴーストリック」モンスター１体が戦闘または相手の効果で破壊され自分の墓地へ送られた時、破壊されたそのモンスターとカード名が異なる、自分の墓地の「ゴーストリック」モンスター２体を対象として発動できる。\nそのモンスターを裏側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コクーン・ヴェール",h:"こくーん・う゛ぇーる",e:"Cocoon Veil",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「C」と名のついたモンスター１体を生け贄に捧げて発動する事ができる。\nこのターン効果によるプレイヤーへのダメージは０になる。\nその後、生け贄に捧げた「C」と名のついたモンスターに記されている「N」と名のついたモンスター１体を手札・デッキ・墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"極炎舞－「辰斗」",h:"ごくえんぶ－「しんと」",e:"Ultimate Fire Formation - Sinto",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドに「炎星」モンスター及び「炎舞」魔法・罠カードが存在し、魔法・罠カードが発動した時に発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"極炎舞－「星斗」",h:"ごくえんぶ－「せいと」",e:"Ultimate Fire Formation - Seito",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の墓地の「炎舞」と名のついた魔法・罠カード７枚をゲームから除外して発動できる。\n自分の墓地から「炎星」と名のついたモンスターを可能な限り特殊召喚する。\nその後、この効果で特殊召喚したモンスターの数まで、デッキから「極炎舞－「星斗」」以外の「炎舞」と名のついた魔法・罠カードを選んで自分フィールド上にセットできる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"黒板消しの罠",h:"こくばんけしのわな",e:"Trap of Board Eraser",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"ダメージを与える効果が発動した時に発動する事ができる。\n自分が受けるその効果ダメージを無効にし、相手は手札を１枚選択して捨てる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コザッキーの研究成果",h:"こざっきーのけんきゅうせいか",e:"Fruits of Kozaky's Studies",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分のデッキのカードを上から３枚確認し、そのカードを好きな順番に入れ替えてデッキの上に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コザッキーの自爆装置",h:"こざっきーのじばくそうち",e:"Kozaky's Self-Destruct Button",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"セットされたこのカードを破壊したプレイヤーに１０００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"姑息な落とし穴",h:"こそくなおとしあな",e:"Double Trap Hole",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手がモンスターを守備表示で特殊召喚した時に発動できる。\nその守備表示モンスターを除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"誤爆",h:"ごばく",e:"Oops!",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"コピー・ナイト",h:"こぴー・ないと",e:"Copy Knight",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上にレベル４以下の戦士族モンスターが召喚された時に発動できる。\nこのカードは発動後、その召喚されたモンスターと同じレベルの同名モンスターカード（戦士族・光・攻／守０）となり、モンスターカードゾーンに特殊召喚する。\nこのカードは罠カードとしても扱う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"鼓舞",h:"こぶ",e:"Inspiration",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで７００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"誤封の契約書",h:"ごふうのけいやくしょ",e:"Dark Contract with Errors",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：１ターンに１度、自分フィールドに「DD」モンスターが存在する場合にこの効果を発動できる。\nターン終了時まで、このカード以外のフィールドの罠カードの効果は無効化される。\n②：自分スタンバイフェイズに発動する。\n自分は１０００ダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゴブリンの小役人",h:"ごぶりんのこやくにん",e:"Minor Goblin Official",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"相手のライフポイントが３０００ポイント以下の場合に発動する事ができる。\n相手のスタンバイフェイズ毎に相手ライフに５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゴブリンのその場しのぎ",h:"ごぶりんのそのばしのぎ",e:"Goblin Out of the Frying Pan",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"５００ライフポイントを払う。\n魔法カードの発動を無効にし、そのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゴブリンのやりくり上手",h:"ごぶりんのやりくりじょうず",e:"Good Goblin Housekeeping",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の墓地に存在する「ゴブリンのやりくり上手」の枚数＋１枚を自分のデッキからドローし、自分の手札を１枚選択してデッキの一番下に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"五稜星の呪縛",h:"ごりょうせいのじゅばく",e:"Curse of the Circle",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手フィールド上に存在するモンスター１体を選択して発動する。\n相手は選択したモンスターをリリースできず、シンクロ素材とする事もできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"蟲惑の落とし穴",h:"こわくのおとしあな",e:"Traptrix Trap Hole Nightmare",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：このターンに特殊召喚された相手フィールドのモンスターが効果を発動した時に発動できる。\nその効果を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイコ・ショックウェーブ",h:"さいこ・しょっくうぇーぶ",e:"Psychic Shockwave",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手が罠カードを発動した時、手札から魔法・罠カード１枚を捨てて発動できる。\nデッキから機械族・闇属性・レベル６モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイコ・ソウル",h:"さいこ・そうる",e:"Psychic Soul",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在するサイキック族モンスター１体をリリースして発動する。\n自分はリリースしたモンスターのレベル×３００ライフポイント回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイコ・チャージ",h:"さいこ・ちゃーじ",e:"Psychic Overload",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の墓地に存在するサイキック族モンスター３体を選択し、デッキに加えてシャッフルする。\nその後、自分のデッキからカードを２枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイコ・トリガー",h:"さいこ・とりがー",e:"Psychic Trigger",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分のライフポイントが相手より下の場合に発動する事ができる。\n自分の墓地に存在するサイキック族モンスター２体をゲームから除外し、自分のデッキからカードを２枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイコ・ヒーリング",h:"さいこ・ひーりんぐ",e:"Psychic Rejuvenation",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在するサイキック族モンスター１体につき、自分は１０００ライフポイント回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイコ・リアクター",h:"さいこ・りあくたー",e:"Psychic Reactor",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上にサイキック族モンスターが表側表示で存在する場合に発動する事ができる。\n自分フィールド上に表側表示で存在するサイキック族モンスターがこのターン相手モンスターと戦闘を行った時、そのサイキック族モンスターと相手モンスターをゲームから除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"再転",h:"さいころ",e:"Diceversity",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：１ターンに１度、フィールドの元々のレベルと異なるレベルを持つモンスター１体を対象としてこの効果を発動できる。\nサイコロを１回振る。\n対象のモンスターのレベルは出た目と同じになる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイバー・サモン・ブラスター",h:"さいばー・さもん・ぶらすたー",e:"Cyber Summon Blaster",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードが既に魔法＆罠ゾーンに存在する状態で、機械族モンスターが特殊召喚に成功する度に発動する。\n相手に３００ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイバー・シャドー・ガードナー",h:"さいばー・しゃどー・がーどなー",e:"Cyber Shadow Gardna",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"相手のメインフェイズ時に発動できる。\nこのカードは発動後モンスターカード（機械族・地・星４・攻／守？）となり、自分のモンスターカードゾーンに特殊召喚する。\nこのカードを攻撃対象とした相手モンスターの攻撃宣言時、このカードの攻撃力・守備力は相手攻撃モンスターと同じ数値になる。\nこのカードは相手のエンドフェイズ時に魔法＆罠カードゾーンにセットされる。\nこのカードは罠カードとしても扱う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイバー・ネットワーク",h:"さいばー・ねっとわーく",e:"Cyber Network",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードは発動後、３回目の自分スタンバイフェイズに破壊される。\n①：１ターンに１度、フィールドに「サイバー・ドラゴン」が存在する場合に発動できる。\nデッキから機械族・光属性モンスター１体を除外する。\n②：このカードがフィールドから墓地へ送られた場合に発動する。\n除外されている自分の機械族・光属性モンスターを可能な限り特殊召喚し、自分フィールドの魔法・罠カードを全て破壊する。\nこの効果で特殊召喚したモンスターは効果を発動できない。\nこの効果を発動するターン、自分はバトルフェイズを行えない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイバース・ビーコン",h:"さいばーす・びーこん",e:"Cyberse Beacon",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：戦闘または相手の効果で自分がダメージを受けたターンに発動できる。\nデッキからレベル４以下のサイバース族モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サイバネティック・オーバーフロー",h:"さいばねてぃっく・おーばーふろー",e:"Cybernetic Overflow",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分の手札・墓地及び自分フィールドの表側表示モンスターの中から、「サイバー・ドラゴン」を任意の数だけ選んで除外する（同じレベルは１体まで）。\nその後、除外した数だけ相手フィールドのカードを選んで破壊する。\n②：フィールドのこのカードが効果で破壊された場合に発動できる。\nデッキから「サイバー」魔法・罠カードまたは「サイバネティック」魔法・罠カード１枚を手札に加える。",li:2,lk:[],ta:[],al:[],ocg:true},
  {n:"PSYフレーム・アクセラレーター",h:"さいふれーむ・あくせられーたー",e:"PSY-Frame Accelerator",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：１ターンに１度、５００LPを払い、自分フィールドの「PSYフレーム」モンスター１体を対象として発動できる。\nそのモンスターを次の自分スタンバイフェイズまで除外する。\n②：１ターンに１度、このカード以外の自分フィールドの表側表示の「PSYフレーム」カードが戦闘以外でフィールドから離れた場合に発動できる。\n手札から「PSYフレーム」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"逆さ眼鏡",h:"さかさめがね",e:"Reverse Glasses",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"フィールド上に表側表示で存在する全てのモンスターの攻撃力をエンドフェイズ時まで半分にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"差し戻し",h:"さしもどし",e:"Return",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手が墓地のカードを手札に加えた時に発動できる。\n相手は手札に加えたそのカード１枚をデッキに戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"砂塵の大竜巻",h:"さじんのおおたつまき",e:"Dust Tornado",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手フィールドの魔法・罠カード１枚を対象として発動できる。\nその相手のカードを破壊する。\nその後、手札から魔法・罠カード１枚をセットできる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サディスティック・ポーション",h:"さでぃすてぃっく・ぽーしょん",e:"Brutal Potion",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"発動後このカードは装備カードとなり、自分フィールド上に存在するモンスター１体に装備する。\nこのカードのコントローラーがカードの効果によって相手プレイヤーにダメージを与えた場合、そのターンのエンドフェイズ時まで装備モンスターの攻撃力は１０００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"裁きの光",h:"さばきのひかり",e:"Light of Judgment",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：フィールドに「天空の聖域」が存在する場合に手札から光属性モンスター１体を墓地へ捨てて発動できる。\n以下の効果から１つを選んで適用する。\n\n●相手の手札を確認し、その中からカード１枚を選んで墓地へ送る。\n\n●相手フィールドのカード１枚を選んで墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"砂漠の光",h:"さばくのひかり",e:"Desert Sunlight",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在するモンスターを全て表側守備表示にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"砂漠の裁き",h:"さばくのさばき",e:"Judgment of the Desert",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"表側表示になったモンスターの表示形式は変更できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サブテラーの決戦",h:"さぶてらーのけっせん",e:"Subterror Final Battle",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：以下の効果から１つを選択して発動できる。\n発動後このカードは墓地へ送らず、そのままセットする。\n\n●自分フィールドの裏側表示の「サブテラー」モンスター１体を選んで表側攻撃表示または表側守備表示にする。\n\n●フィールドの表側表示の「サブテラー」モンスター１体を選んで裏側守備表示にする。\n\n●フィールドの表側表示の「サブテラー」モンスター１体を選び、そのモンスターの攻撃力・守備力はターン終了時まで、その元々の攻撃力と守備力を合計した数値になる。\n\n●このターン、「サブテラー」カードの発動する効果は無効化されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サブテラーマリスの潜伏",h:"さぶてらーまりすのせんぷく",e:"Subterror Behemoth Burrowing",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分の墓地から「サブテラー」モンスター１体を除外して発動できる。\nターン終了時まで、自分フィールドの裏側表示モンスターは効果では破壊されず、相手の効果の対象にならない。\n②：フィールドのこのカードが効果で破壊された場合に発動できる。\nデッキから「サブテラー」モンスター１体を手札に加える。\n③：墓地のこのカードを除外し、自分フィールドの「サブテラー」モンスター１体を対象として発動できる。\nそのモンスターを裏側守備表示にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"醒めない悪夢",h:"さめないあくむ",e:"Unending Nightmare",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の効果は同一チェーン上では１度しか発動できない。\n①：１０００LPを払い、フィールドの表側表示の魔法・罠カード１枚を対象としてこの効果を発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サモン・ゲート",h:"さもん・げーと",e:"Summon Gate",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在する限り、お互いはそれぞれ１ターンに合計３体までしかエクストラデッキからモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"残骸爆破",h:"ざんがいばくは",e:"Blasting the Ruins",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の墓地のカードが３０枚以上存在する場合に発動する事ができる。\n相手ライフに３０００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"斬機帰納法",h:"ざんききのうほう",e:"Mathmech Induction",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドのサイバース族モンスターの攻撃力は５００アップする。\n②：自分フィールドに「斬機」モンスターが存在する場合、魔法＆罠ゾーンの表側表示のこのカードを墓地へ送り、相手フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"雷龍放電",h:"さんだー・どらごん・すとりーま",e:"Thunder Dragon Discharge",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在する限り、自分の雷族モンスターの効果の発動は無効化されない。\n②：１ターンに１度、自分フィールドに「サンダー・ドラゴン」モンスターが召喚・特殊召喚された場合、フィールドの魔法・罠カード１枚を対象として発動できる。\nデッキから雷族モンスター１体を除外し、対象のカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サンダー・ブレイク",h:"さんだー・ぶれいく",e:"Raigeki Break",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：手札を１枚捨て、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"サンダー・ボトル",h:"さんだー・ぼとる",e:"Raigeki Bottle",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上に存在するモンスターが攻撃宣言をする度に、このカードに雷カウンターを１つ置く。\n雷カウンターが４つ以上乗っているこのカードを墓地へ送る事で、相手フィールド上に存在するモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"潜海奇襲",h:"しー・すてるす・あたっく",e:"Sea Stealth Attack",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードの発動時の効果処理として、自分の手札・墓地から「海」１枚を選んで発動できる。\n②：フィールドに「海」が存在する場合、表側表示のこのカードは以下の効果を得る。\n\n●１ターンに１度、自分フィールドの表側表示の水属性モンスター１体をエンドフェイズまで除外して発動できる。\nこのターン、自分フィールドの表側表示の魔法・罠カードは相手の効果では破壊されない。\n\n●元々のレベルが５以上の自分の水属性モンスターが相手モンスターと戦闘を行うダメージステップ開始時に発動する。\nその相手モンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シールドスピア",h:"しーるどすぴあ",e:"Shield Spear",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力・守備力はターン終了時まで４００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シェイプシスター",h:"しぇいぷしすたー",e:"Shapesister",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：このカードは発動後、通常モンスター（悪魔族・チューナー・地・星２・攻／守０）となり、モンスターゾーンに特殊召喚する。\nこのカードは罠カードとしても扱う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジェネレーション・チェンジ",h:"じぇねれーしょん・ちぇんじ",e:"Generation Shift",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在するモンスター１体を選択して破壊する。\nその後、デッキから破壊したカードと同名のカード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジェム・エンハンス",h:"じぇむ・えんはんす",e:"Gem-Enhancement",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の「ジェムナイト」と名のついたモンスター１体をリリースし、自分の墓地の「ジェムナイト」と名のついたモンスター１体を選択して発動できる。\n選択したモンスターを墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジェムフラッシュエナジー",h:"じぇむふらっしゅえなじー",e:"Gem Flash Energy",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分のスタンバイフェイズ毎にフィールド上に表側表示で存在する永続魔法カードの枚数×３００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"紫炎の計略",h:"しえんのけいりゃく",e:"Shien's Scheme",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在する「六武衆」と名のついたモンスターが戦闘によって破壊された場合に発動する事ができる。\n手札から「六武衆」と名のついたモンスターを２体まで特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"時限カラクリ爆弾",h:"じげんからくりばくだん",e:"Karakuri Klock",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"表側守備表示で存在する「カラクリ」と名のついたモンスターが攻撃対象に選択された時に発動する事ができる。\n相手フィールド上に表側表示で存在するモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"始源の帝王",h:"しげんのていおう",e:"The First Monarch",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードは発動後、効果モンスター（悪魔族・闇・星６・攻１０００／守２４００）となり、モンスターゾーンに特殊召喚する。\nこのカードは罠カードとしても扱う。\n②：このカードの効果でこのカードが特殊召喚した場合、手札を１枚捨て、属性を１つ宣言して発動できる。\nこのカードは宣言した属性として扱い、このカードと同じ属性のモンスターをアドバンス召喚する場合、２体分のリリースにできる。\n③：このカードの効果で特殊召喚されたこのカードが存在する限り、自分はこのカードと同じ属性のモンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"次元幽閉",h:"じげんゆうへい",e:"Dimensional Prison",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手モンスターの攻撃宣言時、攻撃モンスター１体を選択して発動できる。\n選択した攻撃モンスターをゲームから除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"地獄の扉越し銃",h:"じごくのとびらごしじゅう",e:"Barrel Behind the Door",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"ダメージを与える効果が発動した時に発動する事ができる。\n自分が受けるその効果ダメージを相手に与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"仕込み爆弾",h:"しこみばくだん",e:"Secret Blast",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手フィールドのカードの数×３００ダメージを相手に与える。\n②：フィールドのこのカードが相手によって破壊され墓地へ送られた場合に発動する。\n相手に１０００ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"仕込みマシンガン",h:"しこみましんがん",e:"Secret Barrel",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手の手札・フィールドのカードの数×２００ダメージを相手に与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"漆黒のパワーストーン",h:"しっこくのぱわーすとーん",e:"Pitch-Black Power Stone",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードを発動する場合、このカードに魔力カウンターを３つ置いて発動する。\n①：自分ターンに１度、このカード以外のフィールドの魔力カウンターを置く事ができるカード１枚を対象として発動できる。\nこのカードの魔力カウンターを１つ取り除き、そのカードに魔力カウンターを１つ置く。\n②：このカードの魔力カウンターが全て取り除かれた場合にこのカードは破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"疾風！凶殺陣",h:"しっぷう！きょうさつじん",e:"Swift Samurai Storm!",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上の「六武衆」と名のついたモンスターが戦闘を行った場合、このターンのエンドフェイズ時まで自分フィールド上の「六武衆」と名のついたモンスターの攻撃力は３００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"しっぺ返し",h:"しっぺがえし",e:"Retort",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"自分の墓地に存在する魔法・罠カードと同名のカードを相手が発動した時に発動できる。\nその発動を無効にし破壊する。\nその後、この効果で発動を無効にしたカードと同名のカードを自分の墓地から１枚選んで手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"死神の呼び声",h:"しにがみのよびごえ",e:"Call of the Reaper",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の墓地からモンスターが特殊召喚された時に発動できる。\n自分の墓地に存在する「スーパイ」または「赤蟻アスカトル」１体を選択して特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"地縛神の咆哮",h:"じばくしんのほうこう",e:"Roar of the Earthbound Immortal",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"相手モンスターの攻撃宣言時、攻撃モンスターの攻撃力が自分フィールド上に表側表示で存在する「地縛神」と名のついたモンスターの攻撃力より低い場合、その攻撃モンスター１体を破壊し、破壊したモンスターの攻撃力の半分のダメージを相手ライフに与える。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"地縛霊の誘い",h:"じばくれいのいざない",e:"Call of the Earthbound",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手モンスターの攻撃宣言時に発動できる。\nその攻撃対象を自分が選択し直す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シフトチェンジ",h:"しふとちぇんじ",e:"Shift",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上のモンスター１体が相手の魔法・罠カードの効果の対象になった時、または相手モンスターの攻撃対象になった時に発動できる。\nその対象を、自分フィールド上の正しい対象となる他のモンスター１体に移し替える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シモッチによる副作用",h:"しもっちによるふくさよう",e:"Bad Reaction to Simochi",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードがフィールド上に存在する限り、相手ライフを回復する効果は、相手ライフにダメージを与える効果になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"邪悪なるバリア －ダーク・フォース－",h:"じゃあくなるばりあ　－だーく・ふぉーす－",e:"Dark Mirror Force",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手モンスターの攻撃宣言時に発動する事ができる。\n相手フィールド上に守備表示で存在するモンスターを全てゲームから除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"弱体化の仮面",h:"じゃくたいかのかめん",e:"Mask of Weakness",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：攻撃モンスター１体を対象として発動できる。\nその攻撃モンスターの攻撃力はターン終了時まで７００ダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"蛇神降臨",h:"じゃしんこうりん",e:"Rise of the Snake Deity",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「毒蛇王ヴェノミノン」が戦闘以外で破壊された時に発動できる。\n手札・デッキから「毒蛇神ヴェノミナーガ」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"蛇神の勅命",h:"じゃしんのちょくめい",e:"Snake Deity's Command",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"手札の「ヴェノム」と名のついたモンスターカード１枚を相手に見せて発動する。\n相手の魔法カードの発動と効果を無効にし、それを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジャスティブレイク",h:"じゃすてぃぶれいく",e:"Justi-Break",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の通常モンスターを攻撃対象とした相手モンスターの攻撃宣言時に発動できる。\n表側攻撃表示で存在する通常モンスター以外のフィールド上のモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シャドー・インパルス",h:"しゃどー・いんぱるす",e:"Shadow Impulse",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"「シャドー・インパルス」は１ターンに１枚しか発動できない。\n①：自分フィールドのSモンスターが戦闘・効果で破壊され墓地へ送られた時、そのモンスター１体を対象として発動できる。\nそのモンスターと同じレベル・種族でカード名が異なるSモンスター１体をエクストラデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"砂利ケーン",h:"じゃりけーん",e:"Gravelstorm",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分及び相手フィールド上に存在する魔法・罠カードを１枚ずつ選択して発動する。\n選択したカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"終焉の指名者",h:"しゅうえんのしめいしゃ",e:"Armageddon Designator",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"手札を１枚ゲームから除外して発動できる。\nお互いのプレイヤーはこのデュエル中、このカードを発動するために除外したカード及びその同名カードの効果を発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"自由解放",h:"じゆうかいほう",e:"Liberty at Last!",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分のモンスターが戦闘で破壊され墓地へ送られた時、フィールドの表側表示モンスター２体を対象として発動できる。\nその表側表示モンスターを持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シューティング・スター",h:"しゅーてぃんぐ・すたー",e:"Shooting Star",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：フィールドに「スターダスト」モンスターが存在する場合、フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"重量オーバー",h:"じゅうりょうおーばー",e:"Over Capacity",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手がモンスターの特殊召喚に成功した時に発動できる。\nフィールド上のレベル２以下のモンスターを全てゲームから除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"守護霊のお守り",h:"しゅごれいのおまもり",e:"Deal of Phantom",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"フィールド上に表側表示で存在するモンスター１体を選択する。\n選択したモンスター１体の攻撃力は、ターン終了時まで自分の墓地に存在するモンスター１体につき攻撃力が１００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"呪言の鏡",h:"じゅごんのかがみ",e:"Mirror of Oaths",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手がデッキからモンスターを特殊召喚した時に発動できる。\nそのモンスターを破壊し、自分はデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"呪術抹消",h:"じゅじゅつまっしょう",e:"Spell Vanishing",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"手札からカードを２枚捨てる。\n魔法カードの発動を無効にし、それを破壊する。\nさらに相手の手札とデッキを確認し、破壊した魔法カードと同名のカードがあった場合全て墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"出幻",h:"しゅつげん",e:"Apparition",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの表側表示の「HERO」モンスターが戦闘・効果で破壊された場合に発動できる。\nデッキからレベル４以下の「V・HERO」モンスター１体を特殊召喚する。\nその後、相手フィールドのモンスター１体を選び、その元々の攻撃力・守備力を半分にできる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"狩猟本能",h:"しゅりょうほんのう",e:"Hunting Instinct",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手フィールド上にモンスターが特殊召喚された時に発動する事ができる。\n手札から恐竜族モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"召喚制限－猛突するモンスター",h:"しょうかんせいげん－もうとつするもんすたー",e:"All-Out Attacks",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードがフィールド上に存在する場合にモンスターが特殊召喚に成功した時、そのモンスターを表側攻撃表示にする。\nそのターンそのモンスターが攻撃可能な場合には攻撃しなければならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"上昇気流",h:"じょうしょうきりゅう",e:"Updraft",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"フィールド上に存在するフォッグカウンターを全て取り除いて発動する。\n取り除いたフォッグカウンターの数×３００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"城壁",h:"じょうへき",e:"Castle Walls",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"フィールド上に表側表示で存在するモンスター１体を選択して発動する。\n選択したモンスターの守備力はエンドフェイズ時まで５００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"女王の選択",h:"じょおうのせんたく",e:"Queen's Pawn",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在する「アマゾネス」と名のついたモンスターが戦闘によって相手モンスターを破壊し墓地へ送った時に発動する事ができる。\n自分のデッキからレベル４以下の「アマゾネス」と名のついたモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"植物連鎖",h:"しょくぶつれんさ",e:"Plant Food Chain",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"発動後このカードは攻撃力５００ポイントアップの装備カードとなり、自分フィールド上の植物族モンスター１体に装備する。\n装備カードとなったこのカードがカードの効果によって破壊された場合、自分の墓地の植物族モンスター１体を選択して特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ジョルト・カウンター",h:"じょると・かうんたー",e:"Jolt Counter",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"自分フィールド上に「BK」と名のついたモンスターが存在する場合に発動できる。\nバトルフェイズ中に発動した効果モンスターの効果・魔法・罠カードの発動を無効にして破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"璽律する武神",h:"じりつするぶじん",e:"Bujin Regalia - The Jewel",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"発動後このカードは装備カードとなり、自分フィールド上のランク４のエクシーズモンスター１体に装備する。\n装備モンスターの攻撃力は、そのモンスターのエクシーズ素材の数×３００ポイントアップする。\nまた、この効果によってこのカードが装備されている場合、１ターンに１度、手札の「武神」と名のついたモンスター１体を装備モンスターの下に重ねてエクシーズ素材とする事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"死霊の誘い",h:"しりょうのいざない",e:"Skull Invitation",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"カードが墓地へ送られる度に、そのカードの持ち主は１枚につき３００ポイントダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"死霊の巣",h:"しりょうのす",e:"Skull Lair",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分の墓地に存在するモンスターを任意の枚数ゲームから除外する事で、その枚数と同じレベルを持つフィールド上に表側表示で存在するモンスター１体を破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"死力のタッグ・チェンジ",h:"しりょくのたっぐ・ちぇんじ",e:"Desperate Tag",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上に表側攻撃表示で存在するモンスターが戦闘によって破壊されるダメージ計算時、その戦闘によって発生する自分への戦闘ダメージを０にし、そのダメージステップ終了時に手札からレベル４以下の戦士族モンスター１体を特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"Sin Claw Stream",h:"しん くろう すとりーむ",e:"Malefic Claw Stream",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドに「Sin」モンスターが存在する場合、相手フィールドのモンスター１体を対象として発動できる。\nその相手モンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"Sin Tune",h:"しん ちゅーん",e:"Malefic Tune",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドの表側表示の「Sin」モンスターが戦闘または相手の効果で破壊された場合に発動できる。\n自分はデッキから２枚ドローする。\n②：このカードが墓地に存在する状態で、自分フィールドの表側表示の「Sin」モンスターが戦闘以外で破壊された場合、墓地のこのカードを除外して発動できる。\nデッキから「Sin」モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"心鎮壷",h:"しん・つぇん・ふー",e:"Xing Zhen Hu",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"フィールド上にセットされた魔法・罠カードを２枚選択して発動する。\nこのカードがフィールド上に存在する限り、選択された魔法・罠カードは発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"心鎮壷のレプリカ",h:"しん・つぇん・ふーのれぷりか",e:"Xing Zhen Hu Replica",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"フィールド上にセットされた魔法・罠カード１枚を選択して発動する。\nこのカードがフィールド上に存在する限り、選択したカードは発動する事ができない。\nこのカードの発動に対して、魔法・罠・効果モンスターの効果を発動する事はできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"人海戦術",h:"じんかいせんじゅつ",e:"Human-Wave Tactics",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"各ターンのエンドフェイズ時、そのターンで戦闘によって破壊された自分のレベル２以下の通常モンスターの数だけ、デッキからレベル２以下の通常モンスターを選択して自分フィールド上に特殊召喚する。\nその後デッキをシャッフルする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"進化の分岐点",h:"しんかのぶんきてん",e:"Evo-Branch",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の爬虫類族モンスター１体を選択して発動する。\n選択したモンスターを破壊し、デッキから「エヴォルド」と名のついたモンスター１体を裏側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"進化への懸け橋",h:"しんかへのかけはし",e:"Evolutionary Bridge",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在するモンスターが攻撃対象に選択された時に発動する事ができる。\n自分の墓地に存在する「エヴォルド」と名のついたモンスター１体を選択して特殊召喚し、そのモンスターに攻撃対象を移し替えてダメージ計算を行う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シンクロ・イジェクション",h:"しんくろ・いじぇくしょん",e:"Synchro Ejection",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手フィールド上に表側表示で存在するシンクロモンスター１体を選択してゲームから除外し、相手はデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シンクロ・ストライク",h:"しんくろ・すとらいく",e:"Synchro Strike",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"シンクロ召喚したモンスター１体の攻撃力はエンドフェイズ時まで、シンクロ素材にしたモンスターの数×５００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シンクロ・バリアー",h:"しんくろ・ばりあー",e:"Synchro Barrier",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在するシンクロモンスター１体をリリースして発動する。\n次のターンのエンドフェイズ時まで、自分が受ける全てのダメージを０にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シンクロ・マテリアル",h:"しんくろ・まてりある",e:"Synchro Material",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手フィールド上に表側表示で存在するモンスター１体を選択して発動する。\nこのターン自分がシンクロ召喚をする場合、選択したモンスターをシンクロ素材にする事ができる。\nこのカードを発動するターン、自分はバトルフェイズを行う事ができない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シンクロコール",h:"しんくろこーる",e:"Synchro Call",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分の墓地のモンスター１体を対象として発動できる。\nそのモンスターを効果を無効にして特殊召喚し、そのモンスターを含む自分フィールドのモンスターのみを素材としてドラゴン族・悪魔族の闇属性Sモンスター１体をS召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"シンクロン・リフレクト",h:"しんくろん・りふれくと",e:"Synchro Deflector",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在するシンクロモンスターが攻撃対象になった時に発動する事ができる。\nその攻撃を無効にし、相手フィールド上に存在するモンスター１体を破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"身剣一体",h:"しんけんいったい",e:"At One With the Sword",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上のモンスターが、「X－セイバー」と名のついたモンスター１体のみの場合に発動できる。\n発動後このカードは攻撃力８００ポイントアップの装備カードとなり、そのモンスター１体に装備する。\n装備モンスターが戦闘によって相手モンスターを破壊した場合、デッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"真剣勝負",h:"しんけんしょうぶ",e:"Face-Off",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：ダメージステップにモンスターの効果・魔法・罠カードが発動した時に発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"真源の帝王",h:"しんげんのていおう",e:"The Prime Monarch",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"「真源の帝王」の②の効果は１ターンに１度しか使用できない。\n①：１ターンに１度、自分の墓地の「帝王」魔法・罠カード２枚を対象として発動できる。\nそのカードをデッキに加えてシャッフルする。\nその後、自分はデッキから１枚ドローする。\n②：このカードが墓地に存在する場合、このカード以外の自分の墓地の「帝王」魔法・罠カード１枚を除外して発動できる。\nこのカードは通常モンスター（天使族・光・星５・攻１０００／守２４００）となり、モンスターゾーンに守備表示で特殊召喚する（罠カードとしては扱わない）。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"真実の眼",h:"しんじつのめ",e:"The Eye of Truth",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードがフィールド上に存在する限り、相手は手札を公開し続ける。\n相手のスタンバイフェイズ時に相手の手札に魔法カードがある場合、相手は１０００ライフポイント回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"神星なる因子",h:"しんせいなるいんし",e:"Stellarnova Alpha",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：モンスターの効果・魔法・罠カードが発動した時、自分フィールドの表側表示の「テラナイト」モンスター１体を墓地へ送って発動できる。\nその発動を無効にし破壊する。\nその後、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"神星なる波動",h:"しんせいなるはどう",e:"Stellarnova Wave",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：１ターンに１度、自分メインフェイズ及び相手バトルフェイズにのみ、この効果を発動できる。\n手札から「テラナイト」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"人造天使",h:"しんせてぃっく・えんじぇる",e:"Synthetic Seraphim",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在する限り、カウンター罠カードが発動する度に、自分フィールドに「人造天使トークン」（天使族・光・星１・攻／守３００）１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"神速の具足",h:"しんそくのぐそく",e:"Swiftstrike Armor",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分のドローフェイズにドローしたカードが「六武衆」と名のついたモンスターカードだった場合、そのカードを相手に見せる事で自分フィールド上に特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"神属の堕天使",h:"しんぞくのだてんし",e:"The Sanctified Darklord",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：手札及び自分フィールドの表側表示モンスターの中から、「堕天使」モンスター１体を墓地へ送って発動できる。\nフィールドの効果モンスター１体を選び、その効果をターン終了時まで無効にし、そのモンスターの攻撃力分だけ自分のLPを回復する。",li:2,lk:[],ta:[],al:[],ocg:true},
  {n:"神罰",h:"しんばつ",e:"Divine Punishment",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：フィールドに「天空の聖域」が存在し、モンスターの効果・魔法・罠カードが発動した時に発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"森羅の滝滑り",h:"しんらのたきすべり",e:"Sylvan Waterslide",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"相手モンスターが直接攻撃宣言する度に、このカードの効果を発動できる。\n自分のデッキの一番上のカードをめくる。\nめくったカードが植物族モンスターだった場合、そのモンスターを墓地へ送る。\n違った場合、そのカードをデッキの一番下に戻す。\nまた、このカードがフィールド上に存在する限り、自分のドローフェイズ時に通常のドローを行う代わりに、自分のデッキの一番上のカードをめくる。\nめくったカードが植物族モンスターだった場合、そのモンスターを墓地へ送る。\n違った場合、そのカードを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"侵略の手段",h:"しんりゃくのしゅだん",e:"Infestation Tool",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"デッキから「インヴェルズ」と名のついたモンスター１体を墓地へ送り、自分フィールド上の「インヴェルズ」と名のついたモンスター１体を選択して発動できる。\n選択したモンスターの攻撃力はエンドフェイズ時まで８００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"侵略の侵喰感染",h:"しんりゃくのしんしょくかんせん",e:"Infestation Infection",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"１ターンに１度、自分の手札または自分フィールド上に表側表示で存在する、「ヴェルズ」と名のついたモンスター１体をデッキに戻して発動できる。\nデッキから「ヴェルズ」と名のついたモンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"侵略の侵喰崩壊",h:"しんりゃくのしんしょくほうかい",e:"Infestation Terminus",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「ヴェルズ」と名のついたモンスター１体を選択してゲームから除外し、相手フィールド上のカード２枚を選択して持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"侵略の波動",h:"しんりゃくのはどう",e:"Infestation Wave",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上のアドバンス召喚した「インヴェルズ」と名のついたモンスター１体を持ち主の手札に戻して発動できる。\n相手フィールド上のカード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"侵略の波紋",h:"しんりゃくのはもん",e:"Infestation Ripples",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"５００ライフポイントを払い、自分の墓地のレベル４以下の「インヴェルズ」と名のついたモンスター１体を選択して発動できる。\n選択したモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"衰弱の霧",h:"すいじゃくのきり",e:"Enervating Mist",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"相手の手札制限枚数は５枚までになる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"水遁封印式",h:"すいとんふういんしき",e:"Sealing Ceremony of Suiton",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"１ターンに１度、手札の水属性モンスター１体を墓地へ送る事で、相手の墓地に存在するカード１枚を選択してゲームから除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スーパージュニア対決！",h:"すーぱーじゅにあたいけつ！",e:"Super Junior Confrontation",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手モンスターの攻撃宣言時に発動する事ができる。\nその戦闘を無効にし、相手フィールド上の一番攻撃力の低い表側攻撃表示モンスター１体と、自分フィールド上の一番守備力の低い表側守備表示モンスター１体で戦闘を行う。\nその戦闘終了後、バトルフェイズを終了する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スーパーチャージ",h:"すーぱーちゃーじ",e:"Supercharge",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドのモンスターが機械族の「ロイド」モンスターのみの場合、相手モンスターの攻撃宣言時に発動できる。\n自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"超能力治療",h:"すーぱーなちゅらるひーりんぐ",e:"Supernatural Regeneration",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカードはエンドフェイズ時にのみ発動する事ができる。\n自分はこのターン墓地へ送られたサイキック族モンスターの数×１０００ライフポイント回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スカーレッド・コクーン",h:"すかーれっど・こくーん",e:"Red Cocoon",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドのドラゴン族Sモンスター１体を対象としてこのカードを発動できる。\nこのカードを装備カード扱いとしてそのモンスターに装備する。\n②：このカードの効果でこのカードを装備したモンスターが相手モンスターと戦闘を行う場合、そのダメージステップ終了時まで相手フィールドの全ての表側表示モンスターの効果は無効化される。\n③：このカードが墓地へ送られたターンのエンドフェイズに、自分の墓地の「レッド・デーモンズ・ドラゴン」１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スキル・サクセサー",h:"すきる・さくせさー",e:"Skill Successor",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上のモンスター１体を選択して発動できる。\n選択したモンスターの攻撃力はエンドフェイズ時まで４００ポイントアップする。\nまた、墓地のこのカードをゲームから除外し、自分フィールド上のモンスター１体を選択して発動できる。\n選択した自分のモンスターの攻撃力はエンドフェイズ時まで８００ポイントアップする。\nこの効果はこのカードが墓地へ送られたターンには発動できず、自分のターンにのみ発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スキル・プリズナー",h:"すきる・ぷりずなー",e:"Skill Prisoner",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上のカード１枚を選択して発動できる。\nこのターン、選択したカードを対象として発動したモンスター効果を無効にする。\nまた、墓地のこのカードをゲームから除外し、自分フィールド上のカード１枚を選択して発動できる。\nこのターン、選択したカードを対象として発動したモンスター効果を無効にする。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スクラップ・カウンター",h:"すくらっぷ・かうんたー",e:"Scrap Rage",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"フィールド上に守備表示で存在する「スクラップ」と名のついたモンスターが攻撃された場合、そのダメージ計算時に発動する事ができる。\n攻撃された「スクラップ」と名のついたモンスターの守備力は２０００ポイントアップし、バトルフェイズ終了時に破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スクラップ・クラッシュ",h:"すくらっぷ・くらっしゅ",e:"Scrap Crash",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在する「スクラップ」と名のついたモンスターが破壊され墓地へ送られた時に発動する事ができる。\nフィールド上に表側表示で存在する魔法・罠カードを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スクランブル・エッグ",h:"すくらんぶる・えっぐ",e:"Scramble Egg",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドのモンスターが戦闘・効果で破壊され墓地へ送られた場合に発動できる。\n自分の手札・デッキ・墓地から「ロードランナー」１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スクランブル・ユニオン",h:"すくらんぶる・ゆにおん",e:"Union Scramble",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"「スクランブル・ユニオン」は１ターンに１枚しか発動できない。\n①：除外されている自分の機械族・光属性の、通常モンスターまたはユニオンモンスターを３体まで対象として発動できる。\nそのモンスターを特殊召喚する。\n②：墓地のこのカードを除外し、除外されている自分の機械族・光属性の、通常モンスターまたはユニオンモンスター１体を対象として発動できる。\nそのモンスターを手札に戻す。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スターゲート",h:"すたーげーと",e:"Spacegate",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"相手モンスターが戦闘を行った場合、そのダメージステップ終了時にこのカードにゲートカウンターを１つ置く。\n自分のターンのメインフェイズ時にこのカードを墓地に送る事で、このカードに乗っているゲートカウンターの数以下のレベルのモンスター１体を手札から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"星墜つる地に立つ閃珖",h:"すたーだすと・り・すぱーく",e:"Stardust Re-Spark",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"「星墜つる地に立つ閃珖」は１ターンに１枚しか発動できない。\n①：特殊召喚された相手モンスターの直接攻撃宣言時、そのモンスターの攻撃力が自分のLP以上の場合に発動できる。\nその攻撃を無効にし、自分はデッキから１枚ドローする。\nその後、自分のエクストラデッキ・墓地から「スターダスト」モンスター１体を選んで特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"星屑の願い",h:"すたーだすとうぃっしゅ",e:"Stardust Wish",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：１ターンに１度、自分フィールドの「スターダスト」Sモンスターが自身の効果を発動するためにリリースされた場合、そのモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果の発動に対して、相手は魔法・罠・モンスターの効果を発動できない。\n②：このカードの①の効果で特殊召喚したモンスターは攻撃表示の場合、戦闘では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"星屑の残光",h:"すたーだすとふらっしゅ",e:"Stardust Flash",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分の墓地の「スターダスト」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スターライト・ロード",h:"すたーらいと・ろーど",e:"Starlight Road",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドのカードを２枚以上破壊する魔法・罠・モンスターの効果が発動した時に発動できる。\nその効果を無効にし破壊する。\nその後、「スターダスト・ドラゴン」１体をEXデッキから特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"透破抜き",h:"すっぱぬき",e:"Debunk",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：手札・墓地でモンスターの効果が発動した時に発動できる。\nその発動を無効にし除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"捨て身の宝札",h:"すてみのほうさつ",e:"Card of Sacrifice",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側攻撃表示で存在するモンスター２体以上の攻撃力の合計が、相手フィールド上に表側表示で存在する攻撃力が一番低いモンスターよりも低い場合、自分のデッキからカードを２枚ドローする。\nこのカードを発動するターン、自分はモンスターを召喚・反転召喚・特殊召喚する事ができず、表示形式を変更する事もできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ストーンヘンジ・メソッド",h:"すとーんへんじ・めそっど",e:"Stonehenge Methods",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の「先史遺産」と名のついたモンスターが戦闘またはカードの効果によって破壊され墓地へ送られた時に発動できる。\nデッキからレベル４以下の「先史遺産」と名のついたモンスター１体を表側守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターは表示形式を変更できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ストレートフラッシュ",h:"すとれーとふらっしゅ",e:"Straight Flush",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手の魔法＆罠カードゾーン全てにカードが存在する場合に発動できる。\n相手の魔法＆罠カードゾーンのカードを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スネーク・チョーク",h:"すねーく・ちょーく",e:"Serpent Suppression",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"相手フィールド上に表側攻撃表示で存在する攻撃力が０のモンスターは、「レプティレス」と名のついたモンスターとの戦闘では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スネーク・ホイッスル",h:"すねーく・ほいっする",e:"Snake Whistle",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在する爬虫類族モンスターが破壊された時に発動する事ができる。\n自分のデッキからレベル４以下の爬虫類族モンスター１体を自分フィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スパイダー・エッグ",h:"すぱいだー・えっぐ",e:"Spider Egg",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手が直接攻撃を宣言した時、自分の墓地に昆虫族モンスターが３体以上存在する場合に発動する事ができる。\nそのモンスターの攻撃を無効にし、自分フィールド上に「スパイダー・トークン」（昆虫族・地・星１・攻／守１００）３体を攻撃表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"素早きは三文の徳",h:"すばやきはさんもんのとく",e:"Subsurface Stage Divers",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドのモンスターがトークン以外の同名モンスター３体のみの場合に発動できる。\nデッキから３体の同名モンスターを手札に加える。\nこのカードの発動後、ターン終了時まで自分はこの効果で手札に加えたモンスター及びその同名モンスターを通常召喚・特殊召喚できず、そのモンスター効果は発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スピリット・フォース",h:"すぴりっと・ふぉーす",e:"Spirit Force",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手ターンの戦闘ダメージ計算時に発動する事ができる。\nその戦闘によって発生する自分への戦闘ダメージは０になる。\nその後、自分の墓地に存在する守備力１５００以下の戦士族チューナー１体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スピリットの誘い",h:"すぴりっとのいざない",e:"Spirit's Invitation",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードのコントローラーは自分のスタンバイフェイズ毎に５００ライフポイントを払う。\nまたは、５００ライフポイント払わずにこのカードを破壊する。\nフィールド上に表側表示で存在するスピリットモンスターが自分の手札に戻った時、相手フィールド上に存在するモンスター１体を相手が選択して持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スピリットバリア",h:"すぴりっとばりあ",e:"Spirit Barrier",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上にモンスターが存在する限り、このカードのコントローラーへの戦闘ダメージは０になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スリーカード",h:"すりーかーど",e:"Three of a Kind",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上にトークン以外の同名モンスターが３体以上存在する場合に発動できる。\n相手フィールド上のカード３枚を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"スリップ・サモン",h:"すりっぷ・さもん",e:"Slip Summon",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手がモンスターの召喚・反転召喚・特殊召喚に成功した時に発動する事ができる。\n手札からレベル４以下のモンスター１体を表側守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターはエンドフェイズ時に持ち主の手札に戻る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"星遺物に蠢く罠",h:"せいいぶつにうごめくわな",e:"World Legacy Trap Globe",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の手札・墓地のカード、自分フィールドの表側表示のカード、除外されている自分のカードの中から、「星遺物に蠢く罠」以外の「星遺物」カード５種類を１枚ずつ選び、持ち主のデッキに加えてシャッフルする。\nその後、自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"星遺物の傀儡",h:"せいいぶつのかいらい",e:"World Legacy Pawns",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドの裏側表示モンスター１体を対象として発動できる。\nそのモンスターを表側攻撃表示または表側守備表示にする。\n②：自分の墓地の「クローラー」モンスター１体をデッキに戻し、自分フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターを裏側守備表示にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"星遺物の導き",h:"せいいぶつのみちびき",e:"World Legacy Landmark",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：手札及び自分フィールドの表側表示モンスターの中から、「星遺物」モンスター１体を除外し、自分の墓地のモンスター２体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターはこのターン攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"セイクリッドの流星",h:"せいくりっどのりゅうせい",e:"Constellar Meteor",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカードを発動したターン、「セイクリッド」と名のついたモンスターとの戦闘で破壊されなかった相手モンスターは、ダメージステップ終了時に持ち主のデッキに戻る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"聖剣の導く未来",h:"せいけんのみちびくみらい",e:"Until Noble Arms are Needed Once Again",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドの「聖剣」装備魔法カードの数だけ自分のデッキの上からカードをめくる。\nその中から１枚を選んで手札に加え、残りのカードは好きな順番でデッキの上に戻す。\n②：墓地のこのカードを除外して発動できる。\n同名カードが自分のフィールド・墓地に存在しない「聖騎士」モンスター１体をデッキから特殊召喚する。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"成功確率0％",h:"せいこうかくりつ０％",e:"Success Probability 0%",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手の融合デッキから融合モンスター２体をランダムに墓地に送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"星蝕－レベル・クライム－",h:"せいしょく－れべる・くらいむ－",e:"Star Siphon",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"シンクロモンスターが特殊召喚された時、そのモンスター１体を選択して発動する。\n自分フィールド上に「星蝕トークン」（魔法使い族・闇・星１・攻／守０）１体を特殊召喚する。\nこのトークンのレベルは、選択したモンスターと同じレベルになり、選択したモンスターのレベルは１になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"正々堂々",h:"せいせいどうどう",e:"Respect Play",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"お互いのプレイヤーは、それぞれ自分のターンには手札を全て公開し続けなければならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"生存境界",h:"せいぞんきょうかい",e:"Survival's End",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：フィールドの通常モンスターを全て破壊し、破壊した数までデッキからレベル４以下の恐竜族モンスターを自分フィールドに特殊召喚する。\nこの効果で特殊召喚したモンスターはエンドフェイズに破壊される。\n②：墓地のこのカードを除外し、自分フィールドの恐竜族モンスター１体と相手フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"生存競争",h:"せいぞんきょうそう",e:"Survival of the Fittest",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの恐竜族モンスター１体を対象としてこのカードを発動できる。\nこのカードを攻撃力１０００アップの装備カード扱いとして、その自分の恐竜族モンスターに装備する。\n②：このカードの効果でこのカードを装備したモンスターが攻撃で相手モンスターを破壊し墓地へ送った時に発動できる。\n装備モンスターは相手モンスターにもう１度だけ続けて攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"生存本能",h:"せいぞんほんのう",e:"Survival Instinct",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の墓地に存在する恐竜族モンスターを任意の枚数選択しゲームから除外する。\n除外した恐竜族モンスター１体につき、自分は４００ライフポイント回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"正統なる血統",h:"せいとうなるけっとう",e:"Birthright",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：自分の墓地の通常モンスター１体を対象としてこのカードを発動できる。\nそのモンスターを攻撃表示で特殊召喚する。\nこのカードがフィールドから離れた時にそのモンスターは破壊される。\nそのモンスターがフィールドから離れた時にこのカードは破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"聖なる鎧 －ミラーメール－",h:"せいなるよろい　－みらーめーる－",e:"Mirror Mail",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在するモンスターが攻撃対象に選択された時に発動する事ができる。\n攻撃対象モンスターの攻撃力は、攻撃モンスターの攻撃力と同じになる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"セイバー・ホール",h:"せいばー・ほーる",e:"Saber Hole",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：自分フィールドに「X－セイバー」モンスターが存在し、自分または相手がモンスターを召喚・反転召喚・特殊召喚する際に発動できる。\nそれを無効にし、そのモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"生命吸収装置",h:"せいめいきゅうしゅうそうち",e:"Life Absorbing Machine",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分のスタンバイフェイズ毎に、直前の自分のターンに払ったライフポイントの半分が回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"生命力吸収魔術",h:"せいめいりょくきゅうしゅうまじゅつ",e:"The Spell Absorbing Life",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"フィールド上の全ての裏側守備表示モンスターを表側表示にする。\nこの時、リバース効果モンスターの効果は発動しない。\n自分はフィールド上の効果モンスター１体につき４００ライフポイント回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"世界の平定",h:"せかいのへいてい",e:"World Suppression",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"フィールドカードが発動された時に発動する事ができる。\nターン終了時までフィールドカードの効果を無効にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"セキュリティー・ボール",h:"せきゅりてぃー・ぼーる",e:"Security Orb",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手モンスターの攻撃宣言時に発動する事ができる。\nその攻撃モンスター１体の表示形式を変更する。\n相手の魔法・罠カードの効果によって、セットされたこのカードが破壊され墓地へ送られた時、フィールド上に存在するモンスター１体を選択し破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゼクト・コンバージョン",h:"ぜくと・こんばーじょん",e:"Zekt Conversion",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の「甲虫装機」と名のついたモンスターが攻撃対象に選択された時に発動できる。\n攻撃対象モンスターを装備カード扱いとして攻撃モンスター１体に装備する。\nこの効果で「甲虫装機」と名のついたモンスターが装備されている限り、自分はその装備モンスターのコントロールを得る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"絶体絶命",h:"ぜったいぜつめい",e:"Absolute End",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手ターンのみ発動する事ができる。\nこのターン相手モンスターの攻撃は、全てプレイヤーへの直接攻撃になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"絶滅の定め",h:"ぜつめつのさだめ",e:"Extinction on Schedule",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分・相手のメインフェイズに２０００LPを払って発動できる。\nこのカードの発動後３回目のバトルフェイズ終了時に、お互いのプレイヤーはそれぞれのフィールドのカードを全て墓地へ送らなければならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"セメタリー・ボム",h:"せめたりー・ぼむ",e:"Cemetary Bomb",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手の墓地のカードの数×１００ポイントダメージを相手に与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ゼロ・フォース",h:"ぜろ・ふぉーす",e:"Zero Force",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在するモンスターがゲームから除外された時に発動する事ができる。\nフィールド上に表側表示で存在する全てのモンスターの攻撃力を０にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"零式魔導粉砕機",h:"ぜろしきまどうふんさいき",e:"Type Zero Magic Crusher",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"手札の魔法カード１枚を捨てる事で、相手ライフに５００ポイントダメージを与える。",li:1,lk:[],ta:[],al:[],ocg:true},
  {n:"閃光弾",h:"せんこうだん",e:"Flashbang",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手モンスターの直接攻撃によって自分が戦闘ダメージを受けた時に発動できる。\nこのターンのエンドフェイズになる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"閃光のイリュージョン",h:"せんこうのいりゅーじょん",e:"Glorious Illusion",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分の墓地から「ライトロード」と名のついたモンスター１体を選択して表側攻撃表示で特殊召喚する。\nこのカードがフィールド上から離れた時、そのモンスターを破壊する。\nそのモンスターがフィールド上から離れた時、このカードを破壊する。\nまた、自分のエンドフェイズ毎に発動する。\n自分のデッキの上からカードを２枚墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"閃光のバリア －シャイニング・フォース－",h:"せんこうのばりあ　－しゃいにんぐ・ふぉーす－",e:"Radiant Mirror Force",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手フィールド上に攻撃表示モンスターが３体以上存在する場合、相手の攻撃宣言時に発動する事ができる。\n相手フィールド上の攻撃表示モンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"閃光を吸い込むマジック・ミラー",h:"せんこうをすいこむまじっく・みらー",e:"Light-Imprisoning Mirror",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードがフィールド上に存在する限り、フィールド上・墓地で発動する光属性モンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"洗脳解除",h:"せんのうかいじょ",e:"Remove Brainwashing",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードがフィールド上に存在する限り、自分と相手のフィールド上に存在する全てのモンスターのコントロールは、元々の持ち主に戻る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"洗脳光線",h:"せんのうこうせん",e:"Brainwashing Beam",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"相手フィールド上のAカウンターが乗ったモンスター１体を選択してコントロールを得る。\n自分のエンドフェイズ毎に、選択したモンスターのAカウンターを１つ取り除く。\nそのモンスターのAカウンターが全て取り除かれるかそのモンスターが破壊された場合、このカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"発条の巻き戻し",h:"ぜんまいのまきもどし",e:"Zenmairch",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在するレベル４以下の「ゼンマイ」と名のついたモンスター１体を選択して手札に戻し、戻したモンスターと同じレベルの「ゼンマイ」と名のついたモンスター１体を手札から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"相視相殺",h:"そうしそうさい",e:"Mutually Affured Destruction",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：このターン、お互いのプレイヤーは手札を公開し続ける。\nこの効果で手札を公開し続けている間、お互いの手札に同名カードが存在する場合、お互いにそのカード及びその同名カードの効果の発動ができない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ソウル・オブ・スタチュー",h:"そうる・おぶ・すたちゅー",e:"Tiki Soul",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードは発動後モンスターカード（岩石族・光・星４・攻１０００／守１８００）となり、自分のモンスターカードゾーンに特殊召喚する。\nこのカードがフィールド上にモンスター扱いとして存在する限り、このカード以外のモンスター扱いとした罠カードが相手によって破壊され自分の墓地へ送られる場合、墓地へ送らず魔法＆罠カードゾーンにセットする事ができる。\nこのカードは罠カードとしても扱う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魂粉砕",h:"そうる・くらっしゅ",e:"Soul Demolition",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードがフィールド上に存在する限り、５００ライフポイントを払う事で、お互いに相手の墓地に存在するモンスター１体を選択してゲームから除外する。\nこの効果は自分フィールド上に悪魔族モンスターが表側表示で存在する場合のみ発動する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ソーラーレイ",h:"そーらーれい",e:"Solar Ray",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在する光属性モンスターの数×６００ポイントダメージを相手に与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"棘の壁",h:"そーん・うぉーる",e:"Wall of Thorns",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の植物族モンスターが攻撃対象に選択された時に発動できる。\n相手フィールド上に攻撃表示で存在するモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"底なし落とし穴",h:"そこなしおとしあな",e:"Floodgate Trap Hole",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手がモンスターの召喚・反転召喚・特殊召喚に成功した時に発動できる。\nそのモンスターを裏側守備表示にする。\nこの効果で裏側守備表示になったモンスターは表示形式を変更できない。",li:3,lk:[],ta:[],al:[],ocg:true},
  {n:"底なし流砂",h:"そこなしりゅうさ",e:"Bottomless Shifting Sand",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"相手のエンドフェイズ時に１度だけ、フィールド上に表側表示で存在する攻撃力が一番高いモンスターを破壊する。\n 自分のスタンバイフェイズ時に自分の手札が４枚以下の場合、このカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"超音速波",h:"そにっく・ぶーむ",e:"Sonic Boom",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分のターンにフィールド上の「幻獣機」と名のついたモンスター１体を選択して発動できる。\n選択したモンスターはこのターン、攻撃力が元々の攻撃力の倍になり、このカード以外の魔法・罠カードの効果を受けず、守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。\nその後、このターンのエンドフェイズ時に自分フィールド上の機械族モンスターを全て破壊する。\nこのカードを発動するターン、選択したモンスター以外のモンスターは攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ソロモンの律法書",h:"そろもんのりっぽうしょ",e:"Solomon's Lawbook",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"次の自分のスタンバイフェイズをスキップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダーク・アドバンス",h:"だーく・あどばんす",e:"Dark Advance",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"「ダーク・アドバンス」は１ターンに１枚しか発動できない。\n①：自分・相手のメインフェイズ及びバトルフェイズに、自分の墓地の攻撃力２４００以上で守備力１０００のモンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\nその後、手札から攻撃力２４００以上で守備力１０００のモンスター１体を表側攻撃表示でアドバンス召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダーク・キュア",h:"だーく・きゅあ",e:"Dark Cure",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"相手がモンスターの召喚・反転召喚・特殊召喚に成功した時、そのモンスターの攻撃力の半分の数値分だけ相手のライフポイントを回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダーク・ホライズン",h:"だーく・ほらいずん",e:"Dark Horizon",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分が戦闘・効果でダメージを受けた時に発動できる。\n受けたダメージの数値以下の攻撃力を持つ魔法使い族・闇属性モンスター１体をデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"大革命",h:"だいかくめい",e:"Huge Revolution",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分のメインフェイズで自分フィールド上に「逃げまどう民」「弾圧される民」「団結するレジスタンス」が表側表示で存在する時のみ発動する事ができる。\n相手の手札を全て墓地に送り、フィールド上の相手がコントロールするカードを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"大革命返し",h:"だいかくめいがえし",e:"The Huge Revolution is Over",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：フィールドのカードを２枚以上破壊するモンスターの効果・魔法・罠カードが発動した時に発動できる。\nその発動を無効にし除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"大火葬",h:"だいかそう",e:"Big Burn",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手が墓地のモンスターを対象にするカードを発動した時に発動する事ができる。\nお互いの墓地のモンスターを全てゲームから除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"大気圏外射撃",h:"たいきけんがいしゃげき",e:"Orbital Bombardment",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の「エーリアン」と名のついたモンスター１体を墓地に送って発動する。\nフィールド上の魔法または罠カード１枚を破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"大金星！？",h:"だいきんぼし！？",e:"BIG Win!?",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"１から１２までの任意のレベルを宣言して発動できる。\nお互いのプレイヤーはそれぞれコイントスを１回行い、どちらも表が出た場合、自分フィールド上に表側表示で存在する全てのモンスターのレベルは宣言したレベルになる。\nどちらも裏が出た場合、自分は宣言したレベルの数×５００ライフポイントを失う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"対抗魔術",h:"たいこうまじゅつ",e:"Anti-Spell",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"自分フィールド上に存在する魔力カウンターを２つ取り除いて発動する。\n魔法カードの発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"大地震",h:"だいじしん",e:"Seismic Shockwave",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上に存在する恐竜族モンスターが破壊され墓地へ送られた時に発動する事ができる。\n相手フィールド上の魔法＆罠カードゾーンから３カ所を指定する。\n指定した魔法＆罠カードゾーンは使用できない。\nこの時カードが存在している場所は指定する事はできない。\nこのカードは発動後３回目の自分のスタンバイフェイズ時に破壊される。\nこの効果で破壊された場合、自分の墓地の恐竜族モンスター１体を手札に戻す事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"大成仏",h:"だいじょうぶつ",e:"Really Eternal Rest",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"装備カードが装備されているモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダイスインパクト",h:"だいすいんぱくと",e:"Dice Try!",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"相手が発動したサイコロを振る効果の発動を無効にし、そのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダイスエット",h:"だいすえっと",e:"Dice It",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：サイコロを１回振る。\n発動ターンによって以下の効果を適用する。\n\n●自分ターン：出た目の数だけ自分の墓地からカードを選んで除外する。\n出た目が１の場合、さらに自分のデッキの上からカードを６枚墓地へ送る。\n\n●相手ターン：出た目の数だけ自分のデッキの上からカードを墓地へ送る。\n出た目が６の場合、さらに自分の墓地のカード１枚を選んで除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダイスロール・バトル",h:"だいすろーる・ばとる",e:"Dice Roll Battle",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手モンスターの攻撃宣言時に自分の墓地の「スピードロイド」モンスター１体を対象として発動できる。\nそのモンスターと手札の「スピードロイド」チューナー１体を除外し、その２体の元々のレベルの合計と同じレベルを持つSモンスター１体をエクストラデッキから特殊召喚する。\n②：相手バトルステップに墓地のこのカードを除外し、自分及び相手フィールドの表側攻撃表示のSモンスターを１体ずつ対象として発動できる。\nその相手の表側攻撃表示モンスターはその自分のモンスターへ攻撃し、ダメージ計算を行う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダイナミスト・エラプション",h:"だいなみすと・えらぷしょん",e:"Dinomist Eruption",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの「ダイナミスト」モンスターが戦闘・効果で破壊された場合、相手フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダイナミスト・ラッシュ",h:"だいなみすと・らっしゅ",e:"Dinomist Rush",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"「ダイナミスト・ラッシュ」は１ターンに１枚しか発動できない。\n①：デッキから「ダイナミスト」モンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターは他のカードの効果を受けず、エンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"タイフーン",h:"たいふーん",e:"Typhoon",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手フィールドに魔法・罠カードが２枚以上存在し、自分フィールドに魔法・罠カードが存在しない場合、このカードの発動は手札からもできる。\n①：フィールドの表側表示の魔法・罠カード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"大噴火",h:"だいふんか",e:"Volcanic Eruption",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に「ジュラシックワールド」が存在している場合、自分のエンドフェイズ時に発動する事ができる。\nフィールド上のカードを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"大暴落",h:"だいぼうらく",e:"Heavy Slump",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手の手札が８枚以上ある時に発動する事ができる。\n相手は手札を全てデッキに加えてシャッフルした後、カードを２枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"タイラント・ウィング",h:"たいらんと・うぃんぐ",e:"Tyrant Wing",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：フィールドのドラゴン族モンスター１体を対象としてこのカードを発動できる。\nこのカードを攻撃力・守備力４００アップの装備カード扱いとして、そのモンスターに装備する。\n②：このカードの効果でこのカードを装備したモンスターは、１度のバトルフェイズ中に２回までモンスターに攻撃できる。\n③：このカードの効果でこのカードを装備したモンスターが相手モンスターを攻撃したターンのエンドフェイズに発動する。\nこのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"体力増強剤スーパーZ",h:"たいりょくぞうきょうざいすーぱーＺ",e:"Nutrient Z",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分が２０００以上の戦闘ダメージを受ける場合、そのダメージ計算時に発動できる。\n自分は４０００LP回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"畳返し",h:"たたみがえし",e:"Pulling the Rug",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"召喚成功時に発動する効果モンスターの効果の発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"立ちはだかる強敵",h:"たちはだかるきょうてき",e:"Staunch Defender",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手の攻撃宣言時に発動する事ができる。\n自分フィールド上の表側表示モンスター１体を選択する。\n発動ターン相手は選択したモンスターしか攻撃対象にできず、全ての表側攻撃表示モンスターで選択したモンスターを攻撃しなければならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"堕天使降臨",h:"だてんしこうりん",e:"Darklord Descent",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：LPを半分払って発動できる。\n相手フィールドの表側表示モンスター１体を選び、そのモンスターと同じレベルの「堕天使」モンスターを２体まで自分の墓地から選んで守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"旅人の到彼岸",h:"たびびとのとうひがん",e:"The Traveler and the Burning Abyss",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"「旅人の到彼岸」は１ターンに１枚しか発動できない。\n①：自分の墓地の、このターン墓地へ送られた「彼岸」モンスターを任意の数だけ対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"W星雲隕石",h:"だぶるせいうんいんせき",e:"W Nebula Meteorite",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"フィールド上に裏側表示で存在するモンスターを全て表側守備表示にする。\nこのターンのエンドフェイズ時に自分フィールド上に表側表示で存在する爬虫類族・光属性モンスターを全て裏側守備表示にし、その枚数分だけ自分はデッキからカードをドローする。\nその後、自分のデッキからレベル７以上の爬虫類族・光属性モンスター１体を特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダブルマジックアームバインド",h:"だぶるまじっくあーむばいんど",e:"Double Magical Arm Bind",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドのモンスター２体をリリースし、相手フィールドの表側表示モンスター２体を対象として発動できる。\nその表側表示モンスター２体のコントロールを自分エンドフェイズまで得る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"廃石融合",h:"だぶれっと・ふゅーじょん",e:"Fragment Fusion",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分の墓地から、「ジェムナイト」融合モンスターカードによって決められた融合素材モンスターを除外し、その融合モンスター１体をエクストラデッキから融合召喚する。\nこの効果で特殊召喚したモンスターはエンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魂の綱",h:"たましいのつな",e:"Soul Rope",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドのモンスターが効果で破壊され墓地へ送られた時、１０００LPを払って発動できる。\nデッキからレベル４モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魂の転身",h:"たましいのてんしん",e:"Soul Transition",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"「魂の転身」は１ターンに１枚しか発動できず、このカードを発動するターン、自分はモンスターを特殊召喚できない。\n①：自分フィールドに特殊召喚されたモンスターが存在しない場合、自分フィールドの通常召喚された表側表示のレベル４モンスター１体をリリースして発動できる。\n自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダメージ・ゲート",h:"だめーじ・げーと",e:"Damage Gate",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分が戦闘ダメージを受けた時に発動できる。\nその時に受けたダメージの数値以下の攻撃力を持つ、自分の墓地のモンスター１体を選択して特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダメージ・ダイエット",h:"だめーじ・だいえっと",e:"Damage Diet",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このターン自分が受ける全てのダメージは半分になる。\nまた、墓地に存在するこのカードをゲームから除外する事で、そのターン自分が受ける効果ダメージは半分になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダメージ・トランスレーション",h:"だめーじ・とらんすれーしょん",e:"Damage Translation",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このターン自分が受ける効果ダメージは半分になる。\nこのターンのエンドフェイズ時、受けた効果ダメージの回数と同じ数だけ自分フィールド上に「ゴースト・トークン」（悪魔族・闇・星１・攻／守０）を守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダメージ・ポラリライザー",h:"だめーじ・ぽらりらいざー",e:"Damage Polarizer",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"ダメージを与える効果が発動した時に発動する事ができる。\nその発動と効果を無効にし、お互いのプレイヤーはカードを１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダメージ・ワクチンΩMAX",h:"だめーじ・わくちんおめがまっくす",e:"Damage Vaccine Ω MAX",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分が戦闘またはカードの効果によってダメージを受けた時に発動する事ができる。\n自分が受けたそのダメージの数値分だけ自分のライフポイントを回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ダメージ＝レプトル",h:"だめーじいこーるれぷとる",e:"Damage = Reptile",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"１ターンに１度、爬虫類族モンスターの戦闘によって自分が戦闘ダメージを受けた時に発動できる。\nその時に受けたダメージの数値以下の攻撃力を持つ爬虫類族モンスター１体をデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"出目出し",h:"だめだし",e:"Dice-nied",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"相手がモンスターの特殊召喚に成功した時、サイコロを１回振る。\n出た目がその特殊召喚されたモンスターのレベルと同じだった場合、そのモンスターを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"断頭台の惨劇",h:"だんとうだいのさんげき",e:"Tragedy",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手フィールド上に表側攻撃表示で存在するモンスターの表示形式が表側守備表示に変更された時に発動できる。\n相手フィールド上に守備表示で存在するモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"連鎖誘爆",h:"ちぇーん・いぐにっしょん",e:"Chain Ignition",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードがフィールド上に存在し、相手フィールド上のモンスターが相手のカードの効果によって破壊された場合、フィールド上のカード１枚を選択して破壊できる。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"連鎖解呪",h:"ちぇーん・でぃすぺる",e:"Chain Dispel",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：魔法・罠カードが発動した時に発動できる。\n発動したその魔法・罠カードのコントローラーのデッキから同名カードを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"連鎖破壊",h:"ちぇーん・ですとらくしょん",e:"Chain Destruction",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：攻撃力２０００以下のモンスターが召喚・反転召喚・特殊召喚に成功した時、その表側表示モンスター１体を対象として発動できる。\nその表側表示モンスターのコントローラーの手札・デッキから対象のモンスターの同名カードを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"連鎖炸薬",h:"ちぇーん・ばーすと",e:"Chain Burst",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"罠カードを発動したプレイヤーに１０００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"チェーン・ヒーリング",h:"ちぇーん・ひーりんぐ",e:"Chain Healing",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分は５００ライフポイント回復する。\nこのカードがチェーン２またはチェーン３で発動した場合、このカードをデッキに加えてシャッフルする。\nこのカードがチェーン４以降に発動した場合、このカードを手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"チェーン・ブラスト",h:"ちぇーん・ぶらすと",e:"Chain Detonation",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手ライフに５００ポイントダメージを与える。\nこのカードをチェーン２またはチェーン３で発動した場合、発動後このカードは墓地へ送らず、持ち主のデッキに戻す。\nチェーン４以降に発動した場合、持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"連鎖空穴",h:"ちぇーん・ほーる",e:"Chain Hole",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：魔法・罠・モンスターの効果の発動にチェーンして相手がモンスターの効果を発動した時に発動できる。\nその効果を無効にする。\nその後、相手はこの効果で無効にされたカードと元々のカード名が同じカード１枚を手札・デッキから選んで除外できる。\n除外しなかった場合、自分は相手の手札をランダムに１枚選んで除外できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"連鎖除外",h:"ちぇーん・ろすと",e:"Chain Disappearance",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"攻撃力１０００以下のモンスターが召喚・反転召喚・特殊召喚に成功した時に発動できる。\nその攻撃力１０００以下のモンスターをゲームから除外し、さらに除外したカードと同名カードを相手の手札・デッキから全て除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"チェンジ・デステニー",h:"ちぇんじ・ですてにー",e:"Changing Destiny",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手モンスターの攻撃宣言時に発動する事ができる。\n相手モンスター１体の攻撃を無効にし、そのモンスターを守備表示にする。\nそのモンスターはフィールド上に表側表示で存在する限り、表示形式を変更する事ができなくなる。\nその後、相手は以下の効果から１つを選択して適用する。\n\n●このカードの効果で攻撃を無効にされたモンスターの攻撃力の半分だけ自分のライフポイントを回復する。\n\n●このカードの効果で攻撃を無効にされたモンスターの攻撃力の半分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"紋章変換",h:"ちぇんじ・めだりおん",e:"Heraldry Change",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手モンスターの攻撃宣言時に発動できる。\n手札から「紋章獣」と名のついたモンスター１体を特殊召喚し、バトルフェイズを終了する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"地殻変動",h:"ちかくへんどう",e:"Earthshaker",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分は属性を２つ選択する。\n相手はその中から１つを選択する。\nフィールド上に表側表示で存在する選択された属性のモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"力の集約",h:"ちからのしゅうやく",e:"Collected Power",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"フィールド上に表側表示で存在するモンスター１体を選択して発動する。\nフィールド上に存在する全ての装備カードを選択したモンスターに装備する。\n対象が正しくない装備カードは破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"竹頭木屑",h:"ちくとうぼくせつ",e:"Bamboo Scrap",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在する植物族モンスター１体をリリースして発動する。\n相手フィールド上に「プラントークン」（植物族・地・星１・攻８００／守５００）２体を守備表示で特殊召喚する。",li:1,lk:[],ta:[],al:[],ocg:true},
  {n:"血の沼地",h:"ちのぬまち",e:"Sanguine Swamp",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上にこのカード以外の魔法・罠カードが存在している場合、このカードを破壊する。\nこのカードがフィールド上に存在する限り、魔法＆罠カードゾーンにセットされたカードは発動できない。\n２回目の自分のスタンバイフェイズ時にこのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"中央突破",h:"ちゅうおうとっぱ",e:"Breakthrough!",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「六武衆」と名のついたモンスター１体を選択して発動する。\nこのターンのバトルフェイズ中、選択したモンスターが相手モンスターを戦闘によって破壊した場合、自分フィールド上に存在する「大将軍 紫炎」または「六武衆」と名のついたモンスターは相手プレイヤーに直接攻撃する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"チューナー・キャプチャー",h:"ちゅーなー・きゃぷちゃー",e:"Tuner Capture",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手がシンクロモンスターのシンクロ召喚に成功した時に発動する事ができる。\nそのシンクロ素材としたチューナー１体を相手の墓地から自分フィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"チューナーズ・バリア",h:"ちゅーなーず・ばりあ",e:"Tuner's Barrier",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在するチューナー１体を選択して発動する。\n次のターンのエンドフェイズ時まで、選択したチューナー１体は戦闘またはカードの効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"超戦士の盾",h:"ちょうせんしのたて",e:"Super Soldier Shield",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：自分フィールドに「カオス・ソルジャー」モンスターが存在し、フィールドのモンスターを対象とするモンスターの効果・魔法・罠カードが発動した時に発動できる。\nその発動を無効にし破壊する。\n②：このカードが墓地に存在する場合、自分フィールドの魔力カウンターを１つ取り除いて発動できる。\n墓地のこのカードを自分フィールドにセットする。\nこの効果でセットしたこのカードはフィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"挑発",h:"ちょうはつ",e:"Taunt",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手ターンのメインフェイズ１に自分フィールド上モンスター１体を選択して発動する。\n選択したモンスターがフィールド上に存在する限り、このターン相手がモンスターで攻撃を行う場合、選択したモンスターを攻撃対象に選択しなければならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"徴兵令",h:"ちょうへいれい",e:"Conscription",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手のデッキの一番上のカードを１枚めくる。\nめくったカードが通常召喚可能なモンスターだった場合、自分フィールド上に特殊召喚する。\nそれ以外のカードだった場合、そのカードを相手の手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"地霊術－「鉄」",h:"ちれいじゅつ－「くろがね」",e:"Spiritual Earth Art - Kurogane",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの地属性モンスター１体をリリースし、そのモンスター以外の自分の墓地のレベル４以下の地属性モンスター１体を対象として発動できる。\nその地属性モンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"沈黙の邪悪霊",h:"ちんもくのだーく・すぴりっと",e:"Dark Spirit of the Silent",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカードは相手のバトルステップ時にのみ発動する事ができる。\n攻撃モンスター１体の攻撃を無効にし、相手フィールド上に表側表示で存在する他のモンスター１体を選択して代わりに攻撃させる。\n（選択したモンスターが守備表示の場合は攻撃表示にする。\n）",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"痛恨の訴え",h:"つうこんのうったえ",e:"Heartfelt Appeal",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手モンスターの直接攻撃によって自分が戦闘ダメージを受けた時に発動できる。\n相手フィールド上に表側表示で存在する守備力が一番高いモンスター１体のコントロールを次の自分のエンドフェイズ時まで得る。\nこの効果でコントロールを得たモンスターの効果は無効化され、攻撃宣言できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ツタン仮面",h:"つたんかめん",e:"Tutan Mask",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"フィールド上に表側表示で存在するアンデット族モンスター１体を対象にする魔法・罠カードの発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"角笛砕き",h:"つのぶえくだき",e:"Smashing Horn",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"モンスターの召喚・特殊召喚を無効にするモンスターの効果・罠カードが発動した時に発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ツバメ返し",h:"つばめがえし",e:"Swallow Flip",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"特殊召喚成功時に発動される効果モンスターの効果の発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"積み上げる幸福",h:"つみあげるこうふく",e:"Accumulated Fortune",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"チェーン４以降に発動できる。\nデッキからカードを２枚ドローする。\n同一チェーン上に複数回同名カードの効果が発動している場合、このカードは発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"つり天井",h:"つりてんじょう",e:"Needle Ceiling",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"フィールド上にモンスターが４体以上存在する場合に発動する事ができる。\nフィールド上に表側表示で存在するモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"剣の采配",h:"つるぎのさいはい",e:"Commander of Swords",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手のドローフェイズ時に、相手が通常のドローをした時に発動できる。\nドローしたカードをお互いに確認し、確認したカードが魔法・罠カードだった場合、以下の効果から１つを選択して適用する。\n\n●ドローしたカードを捨てる。\n\n●相手フィールド上の魔法・罠カード１枚を選んで破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"D－カウンター",h:"でぃー－かうんたー",e:"D - Counter",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「D-HERO」と名のついたモンスターが攻撃対象に選択された時に発動する事ができる。\n攻撃モンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"TG－SX1",h:"ＴＧ－ＳＸ１",e:"TG-SX1",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在する「TG」と名のついたモンスターが戦闘によって相手モンスターを破壊し墓地へ送った時に発動する事ができる。\n自分の墓地に存在する「TG」と名のついたシンクロモンスター１体を選択して特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"D－タイム",h:"でぃー－たいむ",e:"D - Time",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在する「E・HERO」と名のついたモンスターがフィールド上から離れた時に発動する事ができる。\nそのモンスターのレベルと同じレベル以下の「D-HERO」と名のついたモンスター１体をデッキから手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"D－チェーン",h:"でぃー－ちぇーん",e:"D - Chain",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの「D-HERO」モンスター１体を対象としてこのカードを発動できる。\nこのカードを攻撃力５００アップの装備カード扱いとして、その自分のモンスターに装備する。\n②：装備モンスターが戦闘で相手モンスターを破壊し墓地へ送った場合に発動する。\n相手に５００ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"D－フォーチュン",h:"でぃー－ふぉーちゅん",e:"D - Fortune",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手が直接攻撃を宣言した時に発動する事ができる。\n自分の墓地に存在する「D-HERO」と名のついたモンスター１体をゲームから除外する事で、バトルフェイズを終了する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"D－フュージョン",h:"でぃー－ふゅーじょん",e:"D-Fusion",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカードの効果で融合召喚する場合、「D-HERO」モンスターしか融合素材にできない。\n①：自分フィールドから、融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をエクストラデッキから融合召喚する。\nこの効果で特殊召喚したモンスターはこのターン、戦闘・効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"D.D.ダイナマイト",h:"でぃー．でぃー．だいなまいと",e:"D.D. Dynamite",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手が除外しているカードの数×３００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"DDDの契約変更",h:"でぃーでぃーでぃーのけいやくへんこう",e:"D/D/D Contract Change",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手モンスターの攻撃宣言時に以下の効果から１つを選択して発動できる。\n\n●自分の墓地の「DDD」モンスター１体を選んで除外する。\n攻撃モンスターの攻撃力は、この効果で除外したモンスターの攻撃力分ダウンする。\n\n●デッキからレベル４以下の「DD」Pモンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"DDDの人事権",h:"でぃーでぃーでぃーのじんじけん",e:"D/D/D Human Resources",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分の手札・フィールド・墓地の「DD」モンスター及び自分のPゾーンの「DD」カードの中から合計３枚選んで持ち主のデッキに戻す。\nその後、デッキから「DD」モンスター２体を手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"DDリクルート",h:"でぃーでぃーりくるーと",e:"D/D Recruits",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"「DDリクルート」は１ターンに１枚しか発動できない。\n①：相手フィールドのモンスターの数が自分フィールドのモンスターより多い場合、その差の数まで、自分の墓地の「DD」モンスターまたは「契約書」カードを対象として発動できる。\nそのカードを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"DDリビルド",h:"でぃーでぃーりびるど",e:"D/D Reroll",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在する限り、自分フィールドの「契約書」カードは相手の効果では破壊されない。\n②：墓地のこのカードを除外し、このカード以外の除外されている自分の「DD」カードを３枚まで対象として発動できる。\nそのカードをデッキに戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"帝王の凍志",h:"ていおうのとうし",e:"The Monarchs Awaken",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分のエクストラデッキにカードが存在しない場合、自分フィールドのアドバンス召喚した表側表示モンスター１体を対象として発動できる。\nそのモンスターは効果が無効になり、このカード以外の効果を受けない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ディザーム",h:"でぃざーむ",e:"Disarm",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"手札から「剣闘獣」と名のついたカード１枚をデッキに戻す。\n魔法カードの発動を無効にし、それを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ディフェンダーズ・クロス",h:"でぃふぇんだーず・くろす",e:"Defenders Intersect",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"バトルフェイズ中のみ発動する事ができる。\n相手フィールド上に守備表示で存在するモンスターは表側攻撃表示になり、そのモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ディフェンド・スライム",h:"でぃふぇんど・すらいむ",e:"Jam Defender",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"相手モンスターが自分のモンスターに攻撃した時、自分のフィールド上に「リバイバルスライム」が表側表示で存在している場合、攻撃対象を「リバイバルスライム」に移し替える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"D・ゲイザー",h:"でぃふぉーまー・げいざー",e:"Morphtronic Monitron",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"「D」と名のついたモンスターが召喚・反転召喚・特殊召喚に成功した時、そのモンスターを表側守備表示にする事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"D・スクランブル",h:"でぃふぉーまー・すくらんぶる",e:"Morphtronics, Scramble!",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手が直接攻撃を宣言した時、自分フィールド上にモンスターが存在しない場合に発動する事ができる。\nその攻撃を無効にし、手札から「D」と名のついたモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"D・バリア",h:"でぃふぉーまー・ばりあ",e:"Morphtronic Forcefield",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「D」と名のついたモンスターを破壊する魔法・罠カードの発動を無効にし破壊する。\nさらに自分のデッキから「D」と名のついたカード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ディフォーム",h:"でぃふぉーむ",e:"Morphtransition",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「D」と名のついたモンスターが攻撃対象に選択された時に発動する事ができる。\n攻撃モンスター１体の攻撃を無効にし、攻撃対象に選択された「D」と名のついたモンスター１体の表示形式を変更する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ディメンション・ゲート",h:"でぃめんしょん・げーと",e:"Dimension Gate",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードの発動時に、自分フィールド上のモンスター１体を選択し、表側表示でゲームから除外する。\nまた、相手モンスターの直接攻撃宣言時、フィールド上に表側表示で存在するこのカードを墓地へ送る事ができる。\nこのカードが墓地へ送られた場合、このカードの効果で除外したモンスターを特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ディメンション・ミラージュ",h:"でぃめんしょん・みらーじゅ",e:"Dimension Mirage",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"相手フィールドの表側攻撃表示モンスター１体を対象としてこのカードを発動できる。\n①：対象のモンスターの攻撃で攻撃対象モンスターが破壊されなかったダメージステップ終了時、自分の墓地のモンスター１体を除外してこの効果を発動できる。\n対象のモンスターはもう１度攻撃可能になり、続けて攻撃しなければならない。\n②：対象のモンスターがフィールドから離れた場合にこのカードは破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デーモンの雄叫び",h:"でーもんのおたけび",e:"Archfiend's Roar",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"５００ライフポイントを払い発動する。\n自分の墓地から「デーモン」という名のついたモンスターカード１枚を自分のフィールド上に特殊召喚する。\nこのモンスターは、いかなる場合にも生け贄にする事はできず、このターンのエンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デーモンの呼び声",h:"でーもんのよびごえ",e:"Call of the Archfiend",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分の墓地のレベル５以上の悪魔族モンスター１体を対象として発動できる。\n手札から悪魔族モンスター１体を捨て、対象のモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"死の演算盤",h:"です・かりきゅれーたー",e:"Fatal Abacus",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"フィールド上に存在するモンスターが墓地へ送られる度に、そのモンスターの持ち主は１枚につき５００ポイントダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デスカウンター",h:"ですかうんたー",e:"Des Counterblow",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"直接攻撃によってプレイヤーに戦闘ダメージを与えたモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デステニー・シグナル",h:"ですてにー・しぐなる",e:"Destiny Signal",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上のモンスターが戦闘によって破壊され墓地へ送られた時に発動する事ができる。\n自分の手札またはデッキから「D-HERO」と名のついたレベル４以下のモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デステニー・デストロイ",h:"ですてにー・ですとろい",e:"Destruction of Destiny",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分のデッキのカードを上から３枚墓地へ送る。\nこの効果で墓地へ送った魔法・罠カード１枚につき、自分は１０００ポイントダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デステニー・ミラージュ",h:"ですてにー・みらーじゅ",e:"Destiny Mirage",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の「D-HERO」と名のついたモンスターが相手のカードの効果によって破壊され墓地へ送られた時に発動する事ができる。\nこのターンに破壊され墓地へ送られた「D-HERO」と名のついたモンスターを、全て自分フィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デストラクション・ジャマー",h:"ですとらくしょん・じゃまー",e:"Destruction Jammer",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"手札を１枚捨てて発動できる。\n「フィールド上のモンスターを破壊する効果」を持つ効果モンスターの効果・魔法・罠カードの発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デストラクト・サークル－A",h:"ですとらくと・さーくる－えー",e:"Detonator Circle \"A\"",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"フィールド上に表側表示で存在するAカウンターが乗ったモンスター１体を破壊し、お互いに１０００ポイントダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デストラクト・ポーション",h:"ですとらくと・ぽーしょん",e:"Destruct Potion",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在するモンスター１体を選択して発動する。\n選択したモンスターを破壊し、破壊したモンスターの攻撃力分だけ自分のライフポイントを回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"出たら目",h:"でたらめ",e:"That Six",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在する限り、自分または相手がサイコロを振った場合、その内１つの目を以下の目として適用できる。\n\n●１・３・５が出た場合：６として扱う。\n\n●２・４・６が出た場合：１として扱う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"撤収命令",h:"てっしゅうめいれい",e:"Sound the Retreat!",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在するモンスターを全て持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"手のひら返し",h:"てのひらがえし",e:"Turnabout",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"元々のレベルと異なるレベルのモンスターが、フィールド上に表側表示で存在する場合に発動できる。\nフィールド上のモンスターを全て裏側守備表示にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デビリアン・ソング",h:"でびりあん・そんぐ",e:"Stygian Dirge",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードがフィールド上に存在する限り、相手フィールド上の全てのモンスターのレベルは１つ下がる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デビル・コメディアン",h:"でびる・こめでぃあん",e:"Fiend Comedian",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：コイントスを１回行い、コインの裏表を当てる。\n当たった場合、相手の墓地のカードを全て除外する。\nハズレの場合、相手の墓地のカードの枚数分、自分のデッキの上からカードを墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デモンズ・チェーン",h:"でもんず・ちぇーん",e:"Fiendish Chain",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"フィールドの効果モンスター１体を対象としてこのカードを発動できる。\n①：このカードが魔法＆罠ゾーンに存在する限り、その表側表示モンスターは攻撃できず、効果は無効化される。\nそのモンスターが破壊された時にこのカードは破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デモンバルサム・シード",h:"でもんばるさむ・しーど",e:"Sinister Seeds",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側攻撃表示で存在するモンスターが戦闘によって破壊された時に発動する事ができる。\nその戦闘によって自分が受けた戦闘ダメージ５００ポイントにつき、自分フィールド上に「デモンバルサムトークン」（植物族・闇・星１・攻／守１００）を１体特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デュアル・ブースター",h:"でゅある・ぶーすたー",e:"Gemini Booster",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドのデュアルモンスター１体を対象としてこのカードを発動できる。\nこのカードを攻撃力７００アップの装備カード扱いとしてその自分のモンスターに装備する。\n②：装備カード扱いのこのカードが破壊され墓地へ送られた場合、フィールドのデュアルモンスター１体を対象として発動する。\nそのデュアルモンスターはもう１度召喚された状態になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"二重の落とし穴",h:"でゅある・ほーる",e:"Gemini Trap Hole",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：もう１度召喚された状態のデュアルモンスターが戦闘で破壊された時に発動できる。\n相手フィールドのモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"デルタ・クロウ－アンチ・リバース",h:"でるた・くろう－あんち・りばーす",e:"Delta Crow - Anti Reverse",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールドの「BF」モンスターが３体のみの場合、このカードの発動は手札からもできる。\n①：自分フィールドに「BF」モンスターが存在する場合に発動できる。\n相手フィールドにセットされた魔法・罠カードを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"天狗のうちわ",h:"てんぐのうちわ",e:"Goblin Fan",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在する限り、レベル２以下のモンスターは反転召喚に成功した時に破壊される。\nこの時、そのモンスターはフィールドで発動する効果を発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"天使の手鏡",h:"てんしのてかがみ",e:"Fairy's Hand Mirror",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"フィールド上のモンスター１体を対象に発動した相手の魔法を、別の正しい対象に移し替える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"天使の涙",h:"てんしのなみだ",e:"Graceful Tear",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分の手札を１枚選んで相手の手札に加える。\nその後、自分は２０００LP回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"転生断絶",h:"てんせいだんぜつ",e:"Transmigration Break",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードがフィールド上に存在する限り、墓地からデッキに戻るカードはデッキに戻らずゲームから除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"転生の超戦士",h:"てんせいのちょうせんし",e:"Super Soldier Rebirth",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"「転生の超戦士」は１ターンに１枚しか発動できない。\n①：自分フィールドの「カオス・ソルジャー」モンスター１体を対象として発動できる。\nそのモンスターを墓地へ送り、そのモンスターとカード名が異なる「カオス・ソルジャー」モンスター１体を、召喚条件を無視して手札から特殊召喚する。\n②：自分メインフェイズに墓地のこのカードを除外し、自分の墓地の「カオス・ソルジャー」モンスター１体を対象として発動できる。\nそのカードを手札に加える。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"転生の予言",h:"てんせいのよげん",e:"The Transmigration Prophecy",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：お互いの墓地のカードを合計２枚対象として発動できる。\nそのカードを持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"天地開闢",h:"てんちかいびゃく",e:"Beginning of Heaven and Earth",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：デッキから「カオス・ソルジャー」モンスターまたは「暗黒騎士ガイア」モンスター１体以上を含む戦士族モンスター３体を相手に見せ、相手はその中からランダムに１体選ぶ。\nそれが「カオス・ソルジャー」モンスターまたは「暗黒騎士ガイア」モンスターだった場合、そのモンスターを自分の手札に加え、残りのカードは全て墓地へ送る。\n違った場合、相手に見せたカードを全て墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"天罰",h:"てんばつ",e:"Divine Wrath",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"手札を１枚捨てて発動する。\n効果モンスターの効果の発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"電網の落とし穴",h:"でんもうのおとしあな",e:"Network Trap Hole",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手がデッキ・墓地からモンスターを特殊召喚した時に発動できる。\nそのモンスターを裏側表示で除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トゥーン・ディフェンス",h:"とぅーん・でぃふぇんす",e:"Toon Defense",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：相手モンスターが自分のレベル４以下のトゥーンモンスターに攻撃宣言した時にこの効果を発動できる。\nその相手モンスターの攻撃を自分への直接攻撃にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"同姓同名同盟",h:"どうせいどうめいどうめい",e:"The League of Uniform Nomenclature",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在する、レベル２以下の通常モンスター１体を選択して発動する。\n自分のデッキから、選択したカードと同名のカードを可能な限り自分フィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"同姓同名同盟条約",h:"どうせいどうめいどうめいじょうやく",e:"Treaty on Uniform Nomenclature",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上にトークン以外の同名モンスターが表側表示で２体以上存在する場合に発動する事ができる。\nその同名モンスターの数によって以下の効果を適用する。\n\n●２体：相手フィールド上に存在する魔法・罠カード１枚を破壊する。\n\n●３体：相手フィールド上に存在する魔法・罠カードを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"闘争本能",h:"とうそうほんのう",e:"Battle Instinct",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手が直接攻撃を宣言した時、自分フィールド上にモンスターが存在しない場合に発動する事ができる。\n手札からレベル４以下の獣族モンスター１体を表側攻撃表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"盗賊の七つ道具",h:"とうぞくのななつどうぐ",e:"Seven Tools of the Bandit",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：罠カードが発動した時、１０００LPを払って発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トークン謝肉祭",h:"とーくんしゃにくさい",e:"Token Feastevil",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"トークンが特殊召喚された時に発動する事ができる。\nフィールド上に存在する全てのトークンを破壊し、相手ライフに破壊したトークンの数×３００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トークン生誕祭",h:"とーくんせいたんさい",e:"Oh Tokenbaum!",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の同じレベルのトークンを２体以上リリースして発動できる。\nこのカードを発動するためにリリースしたトークンと同じレベルのモンスターを、リリースしたトークンの数まで自分の墓地から選択して特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化され、エンドフェイズ時に破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"時の機械－タイム・マシーン",h:"ときのきかい－たいむ・ましーん",e:"Time Machine",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分または相手のモンスター１体が戦闘で破壊され墓地へ送られた時に発動できる。\nそのモンスターが破壊された時のコントローラーのフィールドに同じ表示形式でそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"弩弓部隊",h:"どきゅうぶたい",e:"Ballista Squad",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドのモンスター１体をリリースし、相手フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"毒蛇の牙",h:"どくへびのきば",e:"Snake Fang",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"フィールド上に表側表示で存在するモンスター１体を選択して発動する。\n選択したモンスターの守備力はエンドフェイズ時まで５００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"心太砲式",h:"ところてんほうしき",e:"Jelly Cannon",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分または相手のモンスターの攻撃宣言時、フィールドのモンスター１体を対象として発動できる。\nそのモンスターを持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"妬絶の呪眼",h:"とぜつのじゅがん",e:"Evil Eye Defeat",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドに「呪眼」モンスターが存在する場合、フィールドのモンスター１体を対象として発動できる。\nそのモンスターを持ち主の手札に戻す。\n自分の魔法＆罠ゾーンに「セレンの呪眼」が存在する場合、この効果の対象を２体にできる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドタキャン",h:"どたきゃん",e:"Last Minute Cancel",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手モンスターの攻撃宣言時に発動できる。\n自分フィールドのモンスターを全て守備表示にする。\nこのカードの発動後、ターン終了時まで戦闘・効果で破壊された自分フィールドの表側表示の「EM」モンスターは墓地へは行かず持ち主の手札に戻る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"突然進化",h:"とつぜんしんか",e:"Evo-Instant",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の爬虫類族モンスター１体をリリースして発動できる。\nデッキから「エヴォルダー」と名のついたモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"突風",h:"とっぷう",e:"Gust",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手のカードの効果によって自分フィールド上の魔法カードが破壊され墓地へ送られた時に発動できる。\nフィールド上の魔法・罠カード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドッペル・ゲイナー",h:"どっぺる・げいなー",e:"Doppelganger",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"相手フィールド上に存在するモンスターの効果によってダメージを受けた時、受けたダメージと同じダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"好敵手の記憶",h:"とものきおく",e:"Memory of an Adversary",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手モンスターの攻撃宣言時に発動できる。\n自分は攻撃モンスターの攻撃力分のダメージを受け、そのモンスターをゲームから除外する。\n次の相手ターンのエンドフェイズ時、この効果で除外したモンスターを自分フィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"戦友の誓い",h:"とものちかい",e:"Oath of Companionship",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"エクストラデッキから特殊召喚されたモンスターが自分フィールド上に存在しない場合、相手フィールド上に表側表示で存在する、エクストラデッキから特殊召喚されたモンスター１体を選択して発動できる。\n選択したモンスターのコントロールをエンドフェイズ時まで得る。\nこのカードを発動するターン、自分はモンスターを特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドラグニティ・ドライブ",h:"どらぐにてぃ・どらいぶ",e:"Dragunity Legion",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：以下の効果から１つを選択して発動できる。\nこのターン、自分は「ドラグニティ」モンスターしか特殊召喚できない。\n\n●自分の魔法＆罠ゾーンの「ドラグニティ」モンスターカード１枚を対象として発動できる。\nそのカードを守備表示で特殊召喚する。\n\n●自分フィールドの「ドラグニティ」モンスター１体を対象として発動できる。\n自分の墓地から「ドラグニティ」モンスター１体を選び、装備カード扱いとして対象の自分のモンスターに装備する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドラゴンの宝珠",h:"どらごんのほうじゅ",e:"The Dragon's Bead",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"手札を１枚捨てる。\n表側表示のドラゴン族モンスター１体を対象とする罠カードの効果を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トラップ・キャプチャー",h:"とらっぷ・きゃぷちゃー",e:"Trap Reclamation",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分が罠カードを発動した時、手札を１枚捨ててチェーン発動する。\nチェーン発動した罠カードが墓地へ送られた時、そのカードを手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トラップ・ジャマー",h:"とらっぷ・じゃまー",e:"Trap Jammer",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"バトルフェイズ中のみ発動する事ができる。\n相手が発動した罠カードの発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トラップ・スタン",h:"とらっぷ・すたん",e:"Trap Stun",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：このターン、このカード以外のフィールドの罠カードの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"古代遺跡の目覚め",h:"とらみっど・ぱるす",e:"Triamid Pulse",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：１ターンに１度、自分の墓地から、岩石族モンスターまたはフィールド魔法カードを合計２枚除外し、以下の効果から１つを選択して発動できる。\n\n●このカード以外のフィールドの表側表示のカード１枚を対象として発動できる。\nそのカードを破壊する。\n\n●自分の墓地の岩石族モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\n\n●自分の墓地のフィールド魔法カードを３枚まで対象として発動できる。\nそのカードをデッキに加えてシャッフルする。\nその後、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ドレインシールド",h:"どれいんしーるど",e:"Draining Shield",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手モンスターの攻撃宣言時、攻撃モンスター１体を対象として発動できる。\nその攻撃モンスターの攻撃を無効にし、そのモンスターの攻撃力分だけ自分はLPを回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トロイの剣闘獣",h:"とろいのぐらでぃあるびーすと",e:"Trojan Gladiator Beast",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の手札から「剣闘獣」と名のついたモンスター１体を相手フィールド上に特殊召喚する。\nその後、自分のデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トロイボム",h:"とろいぼむ",e:"Trojan Blast",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上のモンスターのコントロールが相手のカードの効果によって相手に移った時に発動できる。\nそのモンスター１体を破壊し、破壊したモンスターの攻撃力分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"トワイライト・イレイザー",h:"とわいらいと・いれいざー",e:"Twilight Eraser",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドに同じ種族でカード名が異なる「ライトロード」モンスターが２体以上存在する場合、自分の墓地の「ライトロード」モンスター２体を除外し、フィールドのカード２枚を対象として発動できる。\nそのカードを除外する。\n②：このカードが「ライトロード」モンスターの効果でデッキから墓地へ送られた場合に発動できる。\n手札から「ライトロード」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"黄昏の交衣",h:"とわいらいと・くろす",e:"Twilight Cloth",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの表側表示モンスター１体を対象として発動できる。\n自分の墓地の「ライトロード」モンスターを任意の数だけ選んで除外し、対象のモンスターの攻撃力・守備力はターン終了時まで、この効果で除外したモンスターの数×２００アップする。\n②：このカードが「ライトロード」モンスターの効果でデッキから墓地へ送られた場合に発動できる。\nこのターン、自分フィールドの「ライトロード」モンスターは戦闘・効果では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"貪欲な瓶",h:"どんよくなかめ",e:"Jar of Avarice",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"「貪欲な瓶」は１ターンに１枚しか発動できない。\n①：「貪欲な瓶」以外の自分の墓地のカード５枚を対象として発動できる。\nそのカード５枚をデッキに加えてシャッフルする。\nその後、自分はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ナチュラル・ディザスター",h:"なちゅらる・でぃざすたー",e:"Natural Disaster",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"「雲魔物」と名のついたモンスターの効果によって、相手のコントロールするカードが破壊され墓地へ送られる度に、相手ライフに５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ナチュルの神星樹",h:"なちゅるのしんせいじゅ",e:"Naturia Sacred Tree",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"「ナチュルの神星樹」の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドの昆虫族・地属性モンスター１体をリリースして発動できる。\nデッキからレベル４以下の植物族・地属性モンスター１体を特殊召喚する。\n②：自分フィールドの植物族・地属性モンスター１体をリリースして発動できる。\nデッキからレベル４以下の昆虫族・地属性モンスター１体を特殊召喚する。\n③：このカードが墓地へ送られた場合に発動する。\nデッキから「ナチュルの神星樹」以外の「ナチュル」カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ナンバーズ・オーバーレイ・ブースト",h:"なんばーず・おーばーれい・ぶーすと",e:"Numbers Overlay Boost",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上のエクシーズ素材の無い「No.」と名のついたエクシーズモンスター１体を選択して発動できる。\n自分の手札のモンスター２体を、選択したエクシーズモンスターの下に重ねてエクシーズ素材とする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"虹の引力",h:"にじのいんりょく",e:"Rainbow Gravity",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分のフィールド上及び墓地に「宝玉獣」と名のついたカードが合計７種類存在する場合のみ発動する事ができる。\n自分のデッキまたは墓地に存在する「究極宝玉神」と名のついたモンスター１体を召喚条件を無視して特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"虹の行方",h:"にじのゆくえ",e:"Rainbow Path",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手モンスターの攻撃宣言時に、自分の魔法＆罠カードゾーンに存在する「宝玉獣」と名のついたカード１枚を選択して墓地へ送り発動する。\n相手モンスター１体の攻撃を無効にし、自分のデッキから「究極宝玉神」と名のついたカード１枚を選択して手札に加える事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"二者一両損",h:"にしゃいちりょうぞん",e:"Simultaneous Loss",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"お互いに自分のデッキの一番上のカード１枚を墓地に送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"偽物のわな",h:"にせもののわな",e:"Fake Trap",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在する罠カードを破壊する魔法・罠・効果モンスターの効果を相手が発動した時に発動する事ができる。\nこのカードを代わりに破壊し、他の自分の罠カードは破壊されない。\nセットされたカードが破壊される場合、そのカードを全てめくって確認する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"忍法 空蝉の術",h:"にんぽう　うつせみのじゅつ",e:"Ninjitsu Art of Decoy",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上の「忍者」という名のついたモンスター１体を選択して発動する。\nこのカードがフィールド上に存在する限り、選択したモンスターは戦闘によっては破壊されない。\n（ダメージ計算は適用する）",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"忍法 影縫いの術",h:"にんぽう　かげぬいのじゅつ",e:"Ninjitsu Art of Shadow Sealing",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上の「忍者」と名のついたモンスター１体をリリースして発動できる。\n相手フィールド上のモンスター１体を選択してゲームから除外する。\nそのモンスターがゲームから除外されている限り、そのモンスターカードゾーンは使用できない。\nこのカードがフィールド上から離れた時、この効果で除外したモンスターを同じ表示形式で元のモンスターカードゾーンに戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"忍法 超変化の術",h:"にんぽう　ちょうへんげのじゅつ",e:"Ninjitsu Art of Super-Transformation",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上の「忍者」と名のついたモンスター１体と相手フィールド上に表側表示で存在するモンスター１体を選択して発動する。\n選択したモンスターを墓地へ送り、そのレベルの合計以下のドラゴン族・恐竜族・海竜族モンスター１体を自分のデッキから自分フィールド上に特殊召喚する。\nこのカードがフィールド上から離れた時、そのモンスターをゲームから除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"忍法 分身の術",h:"にんぽう　ぶんしんのじゅつ",e:"Ninjitsu Art of Duplication",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：自分フィールドの「忍者」モンスター１体をリリースしてこのカードを発動できる。\nレベルの合計がリリースしたモンスターのレベル以下になるように、デッキから「忍者」モンスターを任意の数だけ表側攻撃表示または裏側守備表示で特殊召喚する。\nこのカードがフィールドから離れた時にそのモンスターは全て破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"忍法 変化の術",h:"にんぽう　へんげのじゅつ",e:"Ninjitsu Art of Transformation",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：自分フィールドの表側表示の「忍者」モンスター１体をリリースしてこのカードを発動できる。\nリリースしたモンスターのレベル＋３以下のレベルを持つ獣族・鳥獣族・昆虫族モンスター１体を、手札・デッキから特殊召喚する。\nこのカードがフィールドから離れた時にそのモンスターは破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"忍法 妖変化の術",h:"にんぽう　ようへんげのじゅつ",e:"Ninjitsu Art of Mirage-Transformation",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドの「忍者」モンスター１体をリリースし、相手の墓地のモンスター１体を対象としてこのカードを発動できる。\nそのモンスターを自分フィールドに特殊召喚する。\nこの効果で特殊召喚したモンスターはこのカードが表側表示で存在する間、「忍者」モンスターとしても扱う。\nこのカードがフィールドから離れた時にそのモンスターは墓地へ送られる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"NEXT",h:"ねおすぺーすえくすてんしょん",e:"NEXT",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n自分フィールドにカードが存在しない場合、このカードの発動は手札からもできる。\n①：自分の手札・墓地から、「N」モンスター及び「E・HERO ネオス」を任意の数だけ選んで守備表示で特殊召喚する（同名カードは１枚まで）。\nこの効果で特殊召喚したモンスターの効果は無効化される。\nこの効果で特殊召喚したモンスターが自分フィールドに表側表示で存在する限り、自分は融合モンスターしかEXデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"死魂融合",h:"ねくろ・ふゅーじょん",e:"Necro Fusion",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分の墓地から、融合モンスターカードによって決められた融合素材モンスターを裏側表示で除外し、その融合モンスター１体をEXデッキから融合召喚する。\nこの効果で特殊召喚したモンスターはこのターン攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ネクロバレーの神殿",h:"ねくろばれーのしんでん",e:"Necrovalley Temple",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：「墓守」モンスター及び「王家の眠る谷－ネクロバレー」がフィールドに存在する限り、相手フィールドのモンスターの攻撃力・守備力は５００ダウンする。\n②：自分のフィールドゾーンにカードが存在しない場合、１ターンに１度、自分・相手のメインフェイズに発動できる。\n自分の手札・墓地から「王家の眠る谷－ネクロバレー」１枚を選んで発動する。\n③：このカードが相手の効果で破壊され墓地へ送られた場合に発動できる。\nデッキから「ネクロバレーの神殿」以外の「ネクロバレー」魔法・罠カード１枚を選んで自分フィールドにセットする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ねずみ取り",h:"ねずみとり",e:"Eatgaboon",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手が召喚・反転召喚したモンスターの攻撃力が５００以下だった場合、そのモンスター１体を破壊する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ネフティスの覚醒",h:"ねふてぃすのかくせい",e:"Awakening of Nephthys",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが魔法＆罠ゾーンに存在する限り、自分フィールドの「ネフティス」モンスターの攻撃力は３００アップする。\n②：魔法＆罠ゾーンの表側表示のこのカードが効果で破壊され墓地へ送られた場合に発動できる。\n自分の手札・デッキ・墓地から「ネフティス」モンスター１体を選んで特殊召喚する。\nこの効果で特殊召喚したモンスターはエンドフェイズに破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"粘着落とし穴",h:"ねんちゃくおとしあな",e:"Adhesion Trap Hole",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手がモンスターの召喚・反転召喚・特殊召喚に成功した時に発動する事ができる。\nそのモンスターはフィールド上に表側表示で存在する限り、元々の攻撃力が半分になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"粘着テープの家",h:"ねんちゃくてーぷのいえ",e:"House of Adhesive Tape",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手が守備力５００以下のモンスターを召喚・反転召喚した時に発動する事ができる。\nそのモンスター１体を破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"念導力",h:"ねんどうりょく",e:"Telepathic Power",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在するサイキック族モンスターが、相手モンスターの攻撃によって破壊された場合に発動する事ができる。\nその時に攻撃を行った相手モンスター１体を破壊し、その攻撃力分だけ自分のライフポイントを回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"能力吸収コア",h:"のうりょくきゅうしゅうこあ",e:"Nega-Ton Corepanel",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"自分フィールド上に「コアキメイル」と名のついたモンスターが表側表示で存在し、自分の墓地に「コアキメイルの鋼核」が存在する場合に発動する事ができる。\n効果モンスターの効果の発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"能力吸収石",h:"のうりょくきゅうしゅうせき",e:"Powersink Stone",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"モンスターの効果が発動する度に、このカードに魔石カウンターを１つ置く（最大２つまで）。\nこのカードに魔石カウンターが２つ乗っている場合、フィールド上に表側表示で存在するモンスターは、効果を発動する事ができず無効化される。\nエンドフェイズ時、このカードに乗っている魔石カウンターを全て取り除く。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"呪われた棺",h:"のろわれたひつぎ",e:"Dark Coffin",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"セットされたこのカードが破壊され墓地へ送られた時、相手は以下の効果から１つを選択して適用する。\n\n●自分の手札をランダムに１枚捨てる。\n\n●自分フィールド上に存在するモンスター１体を破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バーサーキング",h:"ばーさーきんぐ",e:"Berserking",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"１ターンに１度、フィールド上に表側表示で存在する獣族モンスター２体を選択して発動する事ができる。\nそのターンのエンドフェイズ時まで、選択したモンスター１体の攻撃力を半分にし、その数値分もう１体のモンスターの攻撃力をアップする。\nこの効果は自分のメインフェイズ時及び相手のバトルフェイズ時にのみ発動する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"パージ・レイ",h:"ぱーじ・れい",e:"Purge Ray",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上のエクシーズモンスター１体をリリースして発動できる。\nこのターンのエンドフェイズ時、リリースしたモンスターと同じ種族でランクが１つ低い「No.」と名のついたモンスター１体をエクストラデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バージェストマ・エルドニア",h:"ばーじぇすとま・えるどにあ",e:"Paleozoic Eldonia",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力・守備力はターン終了時まで５００アップする。\n②：罠カードが発動した時、その発動にチェーンしてこの効果を墓地で発動できる。\nこのカードは通常モンスター（水族・水・星２・攻１２００／守０）となり、モンスターゾーンに特殊召喚する（罠カードとしては扱わない）。\nこの効果で特殊召喚したこのカードはモンスターの効果を受けず、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バージェストマ・カナディア",h:"ばーじぇすとま・かなでぃあ",e:"Paleozoic Canadia",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターを裏側守備表示にする。\n②：罠カードが発動した時、その発動にチェーンしてこの効果を墓地で発動できる。\nこのカードは通常モンスター（水族・水・星２・攻１２００／守０）となり、モンスターゾーンに特殊召喚する（罠カードとしては扱わない）。\nこの効果で特殊召喚したこのカードはモンスターの効果を受けず、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バージェストマ・ハルキゲニア",h:"ばーじぇすとま・はるきげにあ",e:"Paleozoic Hallucigenia",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力・守備力はターン終了時まで半分になる。\n②：罠カードが発動した時、その発動にチェーンしてこの効果を墓地で発動できる。\nこのカードは通常モンスター（水族・水・星２・攻１２００／守０）となり、モンスターゾーンに特殊召喚する（罠カードとしては扱わない）。\nこの効果で特殊召喚したこのカードはモンスターの効果を受けず、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バージェストマ・ピカイア",h:"ばーじぇすとま・ぴかいあ",e:"Paleozoic Pikaia",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：手札から「バージェストマ」カード１枚を捨てる。\nその後、自分はデッキから２枚ドローする。\n②：罠カードが発動した時、その発動にチェーンしてこの効果を墓地で発動できる。\nこのカードは通常モンスター（水族・水・星２・攻１２００／守０）となり、モンスターゾーンに特殊召喚する（罠カードとしては扱わない）。\nこの効果で特殊召喚したこのカードはモンスターの効果を受けず、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バージェストマ・マーレラ",h:"ばーじぇすとま・まーれら",e:"Paleozoic Marrella",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：デッキから罠カード１枚を墓地へ送る。\n②：罠カードが発動した時、その発動にチェーンしてこの効果を墓地で発動できる。\nこのカードは通常モンスター（水族・水・星２・攻１２００／守０）となり、モンスターゾーンに特殊召喚する（罠カードとしては扱わない）。\nこの効果で特殊召喚したこのカードはモンスターの効果を受けず、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バージェストマ・レアンコイリア",h:"ばーじぇすとま・れあんこいりあ",e:"Paleozoic Leanchoilia",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：除外されている自分または相手のカード１枚を対象として発動できる。\nそのカードを墓地に戻す。\n②：罠カードが発動した時、その発動にチェーンしてこの効果を墓地で発動できる。\nこのカードは通常モンスター（水族・水・星２・攻１２００／守０）となり、モンスターゾーンに特殊召喚する（罠カードとしては扱わない）。\nこの効果で特殊召喚したこのカードはモンスターの効果を受けず、フィールドから離れた場合に除外される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バースト・リバース",h:"ばーすと・りばーす",e:"Burst Rebirth",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：２０００LPを払い、自分の墓地のモンスター１体を対象として発動できる。\nそのモンスターを裏側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バーストブレス",h:"ばーすとぶれす",e:"Burst Breath",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドのドラゴン族モンスター１体をリリースして発動できる。\nリリースしたモンスターの攻撃力以下の守備力を持つフィールドのモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バーバリアン・レイジ",h:"ばーばりあん・れいじ",e:"Battleguard Rage",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：自分フィールドの戦士族モンスター１体を対象としてこのカードを発動できる。\nそのモンスターの攻撃力は１０００アップし、そのモンスターが戦闘で破壊したモンスターは墓地へ送らず持ち主の手札に戻す。\n対象のモンスターがフィールドから離れた時にこのカードは破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ハーフ・カウンター",h:"はーふ・かうんたー",e:"Half Counter",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在するモンスターが攻撃された場合、そのダメージ計算時に発動する事ができる。\n攻撃対象となった自分のモンスター１体の攻撃力は、エンドフェイズ時まで攻撃モンスターの元々の攻撃力の半分の数値分アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"完全燃焼",h:"ばーんあうと",e:"Burnout",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"「完全燃焼」は１ターンに１枚しか発動できない。\n①：自分フィールドの表側表示の「化合獣」モンスター１体を除外して発動できる。\nデッキから「化合獣」モンスター２体を特殊召喚する（同名カードは１枚まで）。\n②：相手モンスターの直接攻撃宣言時に、墓地のこのカードを除外し、除外されている自分のデュアルモンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターはもう１度召喚された状態として扱う。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"倍返し",h:"ばいがえし",e:"Double Payback",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"相手のカードの効果によって自分が１０００ポイント以上のダメージを受けた時に発動できる。\nその時に受けたダメージ１０００ポイントにつき、このカードに倍々カウンターを１つ置く。\n次の相手ターンのエンドフェイズ時、このカードを破壊してこのカードに乗っていた倍々カウンターの数×２０００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"パイナップル爆弾",h:"ぱいなっぷるぼむ",e:"Pineapple Blast",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分がモンスターの召喚に成功した時に発動する事ができる。\n相手フィールド上のモンスターの数が自分のコントロールするモンスターの数より多い場合、同じ数になるように相手のカードを破壊する。\n破壊するカードは相手が選択する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バイロード・サクリファイス",h:"ばいろーど・さくりふぁいす",e:"Byroad Sacrifice",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上のモンスターが戦闘によって破壊された場合に発動する事ができる。\n手札から「サイバー・オーガ」１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バウンサー・ガード",h:"ばうんさー・がーど",e:"Bounzer Guard",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の「バウンサー」と名のついたモンスター１体を選択して発動できる。\nこのターン、選択したモンスターはカードの効果の対象にならず、戦闘では破壊されない。\nこのターン、相手モンスターが攻撃する場合、選択したモンスターを攻撃対象にしなければならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"墓荒らし",h:"はかあらし",e:"Graverobber",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手の墓地にある魔法カード１枚を選択し、ターン終了時まで自分の手札として使用する事ができる。\nその魔法カードを使用した場合、２０００ポイントのダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"墓荒らしの報い",h:"はかあらしのむくい",e:"Graverobber's Retribution",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分のスタンバイフェイズ毎に、ゲームから除外されている相手モンスターの数×１００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"破壊神の系譜",h:"はかいしんのけいふ",e:"Lineage of Destruction",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手フィールド上に守備表示で存在するモンスターを破壊したターン、自分フィールド上に表側表示で存在するレベル８のモンスター１体を選択して発動する。\nこのターン、選択したモンスターは１度のバトルフェイズ中に２回攻撃する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"破壊指輪",h:"はかいりんぐ",e:"Destruction Ring",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の表側表示モンスター１体を破壊し、お互いに１０００ポイントダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"墓場からの誘い",h:"はかばからのいざない",e:"Grave Lure",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手のデッキの一番上のカードを表向きにしてシャッフルする。\n相手がそのカードをドローした時、そのカードをそのまま墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"墓場からの呼び声",h:"はかばからのよびごえ",e:"Call of the Grave",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手が「死者蘇生」を発動した時に発動する事ができる。\nその「死者蘇生」の効果を無効にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"光虫異変",h:"ばぐ・えまーじぇんしー",e:"Bug Emergency",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"「光虫異変」は１ターンに１枚しか発動できない。\n①：自分の墓地の昆虫族・レベル３モンスター２体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。\n②：自分の墓地からこのカードとXモンスター１体を除外して発動できる。\n自分フィールドの全ての昆虫族・レベル３モンスターのレベルはターン終了時まで、除外したXモンスターのランクと同じ数値のレベルになる。\nこの効果の発動後、ターン終了時まで自分は昆虫族モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"白衣の天使",h:"はくいのてんし",e:"Numinous Healer",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分が戦闘またはカードの効果によってダメージを受けた時に発動する事ができる。\n自分は１０００ライフポイント回復する。\n自分の墓地に「白衣の天使」が存在する場合、さらにその枚数分だけ５００ライフポイント回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"白銀のバリア－シルバーフォース－",h:"はくぎんのばりあ－しるばーふぉーす－",e:"Shining Silver Force",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"相手がダメージを与える罠カードを発動した時に発動する事ができる。\nその発動を無効にし、そのカードと相手フィールド上に表側表示で存在する魔法・罠カードを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"爆弾ウニ－ボム・アーチン－",h:"ばくだんうに－ぼむ・あーちん－",e:"Explosive Urchin",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"相手が罠カードを発動した時に発動する事ができる。\n自分のスタンバイフェイズ時、相手フィールド上に罠カードが表側表示で存在する場合、相手ライフに１０００ポイントダメージを与える。\n発動後３回目の自分のエンドフェイズ時にこのカードを墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"白兵戦",h:"はくへいせん",e:"Attack and Receive",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分が戦闘またはカードの効果によってダメージを受けた時に発動する事ができる。\n相手ライフに７００ポイントダメージを与える。\n自分の墓地に「白兵戦」が存在する場合、さらにその枚数分だけ相手ライフに３００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"はさみ撃ち",h:"はさみうち",e:"Two-Pronged Attack",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在するモンスター２体と相手フィールド上に存在するモンスター１体を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"破邪の刻印",h:"はじゃのこくいん",e:"Seal of Wickedness",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"相手のスタンバイフェイズ時に１度だけ、フィールド上に表側表示で存在するカード１枚を選択する。\n選択されたカードの効果はそのターン中無効になる。\nこのカードのコントローラーは自分のスタンバイフェイズ毎に５００ライフポイントを払う。\nまたは、５００ライフポイント払わずにこのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バスター・カウンター",h:"ばすたー・かうんたー",e:"Assault Counter",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"自分フィールド上に「／バスター」と名のついたモンスターが存在する場合に発動できる。\n効果モンスターの効果・魔法・罠カードの発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バスター・スラッシュ",h:"ばすたー・すらっしゅ",e:"Assault Slash",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に「／バスター」と名のついたモンスターが表側表示で存在する場合のみ発動する事ができる。\nフィールド上に表側表示で存在するモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バスター・モード",h:"ばすたー・もーど",e:"Assault Mode Activate",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドのSモンスター１体をリリースして発動できる。\nそのモンスターのカード名が含まれる「／バスター」モンスター１体をデッキから攻撃表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"パスト・イメージ",h:"ぱすと・いめーじ",e:"Past Image",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上にサイキック族モンスターが表側表示で存在する場合、相手フィールド上に存在するモンスター１体を選択して発動する事ができる。\n選択したモンスターをゲームから除外する。\nこの効果で除外したモンスターは、次のスタンバイフェイズ時に同じ表示形式で相手フィールド上に戻る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"裸の王様",h:"はだかのおうさま",e:"The Emperor's Holiday",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"フィールド上の全ての装備カードの効果は無効になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"八式対魔法多重結界",h:"はちしきたいまほうたじゅうけっかい",e:"Spell Shield Type-8",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"次の効果から１つを選択して発動する。\n\n●フィールド上のモンスター１体を対象にした魔法の発動と効果を無効にし、そのカードを破壊する。\n\n●手札から魔法カード１枚を墓地に送る事で魔法の発動と効果を無効にし、そのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バックファイア",h:"ばっくふぁいあ",e:"Backfire",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：自分フィールドの表側表示の炎属性モンスターが破壊され墓地へ送られる度に発動する。\n相手に５００ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"罰則金",h:"ばっそくきん",e:"Fine",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分は手札を２枚選んで捨てる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"波動再生",h:"はどうさいせい",e:"Reanimation Wave",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手モンスターの直接攻撃宣言時、その攻撃モンスターのレベル以下のレベルを持つシンクロモンスター１体を自分の墓地から選択して発動する。\nその時の攻撃によって発生する自分への戦闘ダメージは半分になる。\nそのダメージステップ終了時、選択したシンクロモンスターを自分の墓地から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バトル・テレポーテーション",h:"ばとる・てれぽーてーしょん",e:"Battle Teleportation",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在するサイキック族モンスターが１体のみの場合、そのモンスター１体を選択して発動する。\nこのターン選択したモンスターは相手プレイヤーに直接攻撃する事ができる。\nこのターンのバトルフェイズ終了時、選択したモンスターのコントロールを相手に移す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バトルマニア",h:"ばとるまにあ",e:"Battle Mania",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手ターンのスタンバイフェイズ時に発動する事ができる。\n相手フィールド上に表側表示で存在するモンスターは全て攻撃表示になり、このターン表示形式を変更する事はできない。\nまた、このターン攻撃可能な相手モンスターは攻撃しなければならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バブル・クラッシュ",h:"ばぶる・くらっしゅ",e:"Bubble Crash",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"手札・フィールド上のカードの合計が６枚以上あるプレイヤーは、その合計が５枚になるようにカードを墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"バベル・タワー",h:"ばべる・たわー",e:"Tower of Babel",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードがフィールド上に存在する限り、自分または相手が魔法カードを発動する度に、このカードに魔力カウンターを１つ置く。\nこのカードに４つ目の魔力カウンターが乗った時にこのカードを破壊し、その時に魔法カードを発動したプレイヤーは３０００ポイントダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"破滅へのクイック・ドロー",h:"はめつへのくいっく・どろー",e:"Destructive Draw",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"お互いのプレイヤーはドローフェイズ開始時に手札が０枚だった場合、通常のドローに加えてもう１枚ドローする事ができる。\nこのカードのコントローラーは自分のターンのエンドフェイズ毎に７００ライフポイントを払う。\nこの時にライフポイントが７００未満だった場合、ライフポイントは０になる。\n自分フィールド上に表側表示で存在するこのカードがフィールド上から離れた時、自分は３０００ポイントダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"波紋のバリア －ウェーブ・フォース－",h:"はもんのばりあ　－うぇーぶ・ふぉーす－",e:"Drowning Mirror Force",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手モンスターの直接攻撃宣言時に発動できる。\n相手フィールドの攻撃表示モンスターを全て持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"早すぎた帰還",h:"はやすぎたきかん",e:"Premature Return",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：手札を１枚除外し、除外されている自分のモンスター１体を対象として発動できる。\nそのモンスターを裏側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"パラドックス・フュージョン",h:"ぱらどっくす・ふゅーじょん",e:"Paradox Fusion",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：自分フィールドの表側表示の融合モンスター１体を除外して以下の効果を発動できる。\n発動後２回目の自分エンドフェイズに、除外したそのモンスターは表側攻撃表示で自分フィールドに戻る。\n\n●魔法・罠カードが発動した時に発動できる。\nその発動を無効にし破壊する。\n \n●自分または相手がモンスターを特殊召喚する際に発動できる。\nその特殊召喚を無効にし、そのモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"パラレル・セレクト",h:"ぱられる・せれくと",e:"Parallel Selection",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在するシンクロモンスターが相手によって破壊され墓地へ送られた時、ゲームから除外されている自分の魔法カード１枚を選択して発動する。\n選択した魔法カードを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"パリィ",h:"ぱりぃ",e:"Parry",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"手札から「剣闘獣」と名のついたカード１枚をデッキに戻す。\n罠カードの発動を無効にし、それを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"針虫の巣窟",h:"はりむしのそうくつ",e:"Needlebug Nest",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分のデッキの上からカードを５枚墓地へ送る。",li:1,lk:[],ta:[],al:[],ocg:true},
  {n:"パルス・ボム",h:"ぱるす・ぼむ",e:"Pulse Mines",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドに機械族モンスターが存在する場合に発動できる。\n相手フィールドに攻撃表示モンスターが存在する場合、そのモンスターを全て守備表示にする。\nターン終了時まで、相手フィールドにモンスターが召喚・特殊召喚された場合、そのモンスターは守備表示になる。",li:2,lk:[],ta:[],al:[],ocg:true},
  {n:"弾幕回避",h:"ばれる・ろーる",e:"Do a Barrel Roll",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"自分フィールド上の「幻獣機トークン」を全てリリースして発動できる。\n効果モンスターの効果・魔法・罠カードの発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"パワー・ブレイク",h:"ぱわー・ぶれいく",e:"Power Break",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に「パワー・ツール・ドラゴン」が表側表示で存在する場合に発動する事ができる。\n自分のフィールド上・墓地に存在する装備カードを３枚まで選択してデッキに戻し、戻した数×５００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"パワー・フレーム",h:"ぱわー・ふれーむ",e:"Power Frame",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在するモンスターが、その攻撃力よりも高い攻撃力を持つモンスターの攻撃対象に選択された時に発動する事ができる。\nその攻撃を無効にし、このカードを攻撃対象モンスター１体に装備する。\n装備モンスターの攻撃力は、その時の攻撃モンスターと攻撃対象モンスターの攻撃力の差の数値分アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"パワーアップ・コネクター",h:"ぱわーあっぷ・こねくたー",e:"Power-Up Adapter",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"発動後このカードは装備カードとなり、自分フィールド上に表側表示で存在する「D」と名のついたモンスター１体に装備する。\n装備モンスターは攻撃をする事ができない。\nこの効果で装備された時、装備モンスター以外のフィールド上に表側表示で存在するモンスター１体を選択する。\n選択したモンスターの攻撃力は装備モンスターの攻撃力分アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"反撃準備",h:"はんげきじゅんび",e:"Prepare to Strike Back",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"相手プレイヤーが表側守備表示モンスターに対して攻撃宣言をする度に、コイントスで裏表を当てる。\n当たった場合、攻撃対象となった表側守備表示モンスターを攻撃表示にする。\nハズレの場合、攻撃モンスターの攻撃力が攻撃対象のモンスターの守備力を越えた数値分だけこのカードのコントローラーはダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"反射の聖刻印",h:"はんしゃのせいこくいん",e:"Hieratic Seal of Reflection",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"自分フィールド上の「聖刻」と名のついたモンスター１体をリリースして発動できる。\n効果モンスターの効果・魔法・罠カードの発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ハンディキャップマッチ！",h:"はんでぃきゃっぷまっち！",e:"Double Tag Team",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分が「剣闘獣」と名のついたモンスターの特殊召喚に成功した時に発動する事ができる。\n自分の手札またはデッキから「剣闘獣」と名のついたレベル４以下のモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ハンドレス・フェイク",h:"はんどれす・ふぇいく",e:"Phantom Hand",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上に「インフェルニティ」と名のついたモンスターが表側表示で存在する場合、１ターンに１度だけ、次の自分のスタンバイフェイズ時まで自分の手札を全て裏側表示でゲームから除外する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"万能地雷グレイモヤ",h:"ばんのうじらいぐれいもや",e:"Widespread Ruin",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手モンスターの攻撃宣言時に発動できる。\n相手フィールドの表側攻撃表示モンスターの内、攻撃力が一番高いモンスター１体を破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"反発力",h:"はんぱつりょく",e:"Counterforce",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"表側攻撃表示で存在するモンスターを攻撃対象としたモンスターの攻撃が無効になった時に発動できる。\nその２体のモンスターの攻撃力の差分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"反魔鏡",h:"はんまきょう",e:"Anti-Magic Prism",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手が速攻魔法カードを発動した時に発動する事ができる。\nフィールド上に存在するカード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ビーストライザー",h:"びーすとらいざー",e:"Beast Rising",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：１ターンに１度、自分フィールドの表側表示の獣族・獣戦士族モンスター１体を除外し、自分フィールドの獣族・獣戦士族モンスター１体を対象として発動できる。\nその自分の獣族・獣戦士族モンスターの攻撃力は、この効果を発動するために除外したモンスターの元々の攻撃力分アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヒーロー・シグナル",h:"ひーろー・しぐなる",e:"Hero Signal",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドのモンスターが戦闘で破壊され墓地へ送られた時に発動できる。\n手札・デッキからレベル４以下の「E・HERO」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヒーロー・ブラスト",h:"ひーろー・ぶらすと",e:"Hero Blast",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分の墓地の「E・HERO」通常モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。\nその後、手札に加えたモンスターの攻撃力以下の攻撃力を持つ相手フィールドのモンスター１体を選んで破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヒーロー・ヘイロー",h:"ひーろー・へいろー",e:"Hero Ring",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"発動後このカードは装備カードとなり、攻撃力１５００以下の戦士族モンスター１体に装備する。\n相手の攻撃力１９００以上のモンスターは、装備モンスターを攻撃する事ができない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヒーロー・メダル",h:"ひーろー・めだる",e:"Hero Medal",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手がコントロールするカードの効果によってセットされたこのカードが破壊され墓地へ送られた時、このカードをデッキに加えてシャッフルする。\nその後、自分のデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヒーロー逆襲",h:"ひーろーぎゃくしゅう",e:"Hero Counterattack",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在する「E・HERO」と名のついたモンスターが戦闘によって破壊された時に発動する事ができる。\n自分の手札から相手はカード１枚をランダムに選択する。\nそれが「E・HERO」と名のついたモンスターカードだった場合、相手フィールド上のモンスター１体を破壊し、選択したカードを自分フィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヒーロー見参",h:"ひーろーけんざん",e:"A Hero Emerges",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手モンスターの攻撃宣言時に発動できる。\n自分の手札１枚を相手がランダムに選ぶ。\nそれがモンスターだった場合、自分フィールドに特殊召喚し、違った場合は墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヒーロースピリッツ",h:"ひーろーすぴりっつ",e:"Hero Spirit",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の「E・HERO」と名のついたモンスターが戦闘によって破壊された場合、そのターンのバトルフェイズ中に発動する事ができる。\n相手モンスター１体からの戦闘ダメージを０にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヒーローズルール2",h:"ひーろーずるーる２",e:"Hero's Rule 2",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"墓地のカードを対象とする効果モンスターの効果・魔法・罠カードの発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヒーローバリア",h:"ひーろーばりあ",e:"Hero Barrier",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に「E・HERO」と名のついたモンスターが表側表示で存在する場合、相手モンスターの攻撃を１度だけ無効にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"光の召集",h:"ひかりのしょうしゅう",e:"Beckoning Light",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分の手札を全て捨てる。\nその後、この効果で墓地へ捨てられたカードの数だけ、自分の墓地から光属性モンスターを選んで手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"光のピラミッド",h:"ひかりのぴらみっど",e:"Pyramid of Light",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在するこのカードがフィールド上から離れた場合、自分フィールド上に存在する「アンドロ・スフィンクス」、「スフィンクス・テーレイア」を破壊しゲームから除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"光の封札剣",h:"ひかりのふうさつけん",e:"Lightforce Sword",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手の手札をランダムに１枚選んで裏側表示で除外する。\nこのカードの発動後、相手ターンで数えて４ターン目の相手スタンバイフェイズに、そのカードを相手の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"悲劇の引き金",h:"ひげきのひきがね",e:"Remote Revenge",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在するモンスターが、「フィールド上のモンスター１体を破壊する効果」を持つ魔法・罠・効果モンスターの効果の対象になった時に発動する事ができる。\nその効果の対象を相手フィールド上に存在する正しい対象となるモンスター１体に移し替える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ピケルの読心術",h:"ぴけるのどくしんじゅつ",e:"Pikeru's Second Sight",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手の２回目のエンドフェイズまで、相手はドローしたカードを公開してから手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ピケルの魔法陣",h:"ぴけるのまほうじん",e:"Pikeru's Circle of Enchantment",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このターンのエンドフェイズまで、このカードのコントローラーへのカードの効果によるダメージは０になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヒステリック・パーティー",h:"ひすてりっく・ぱーてぃー",e:"Hysteric Party",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：手札を１枚捨ててこのカードを発動できる。\n自分の墓地から「ハーピィ・レディ」を可能な限り特殊召喚する。\nこのカードがフィールドから離れた時にこの効果で特殊召喚したモンスターは全て破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"肥大化",h:"ひだいか",e:"Massivemorph",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力・守備力は倍になり、直接攻撃できない。",li:1,lk:[],ta:[],al:[],ocg:true},
  {n:"罅割れゆく斧",h:"ひびわれゆくおの",e:"Shattered Axe",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"フィールドの表側表示モンスター１体を対象としてこのカードを発動できる。\n①：このカードが魔法＆罠ゾーンに存在する限り、対象のモンスターの攻撃力は自分スタンバイフェイズ毎に５００ダウンする。\nそのモンスターが破壊された時にこのカードは破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"百雷のサンダー・ドラゴン",h:"ひゃくらいのさんだー・どらごん",e:"Thunder Dragons' Hundred Thunders",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分の墓地の雷族モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nその後、その同名モンスターを自分の墓地から可能な限り特殊召喚できる。\nこの効果で特殊召喚したモンスターは、フィールドから離れた場合に除外される。\nこの効果で特殊召喚したモンスターがモンスターゾーンに存在する限り、自分は雷族モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"避雷針",h:"ひらいしん",e:"Anti Raigeki",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手が「サンダー・ボルト」を使用した時、自分モンスターの代わりに相手モンスターを全て破壊する。\n発動後このカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヒロイック・アドバンス",h:"ひろいっく・あどばんす",e:"Heroic Advance",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の「ヒロイック」と名のついたモンスターが攻撃対象に選択された時、自分フィールド上の他のレベル４以下の「ヒロイック」と名のついたモンスター１体を選択して発動できる。\n選択したモンスターの攻撃力はバトルフェイズ終了時まで倍になり、選択したモンスターに攻撃対象を移し替えてダメージ計算を行う。\nお互いのモンスターはこの戦闘では破壊されない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヒロイック・ギフト",h:"ひろいっく・ぎふと",e:"Heroic Gift",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手のライフポイントが２０００以下の場合に発動できる。\n相手のライフポイントを８０００にして自分のデッキからカードを２枚ドローする。\n「ヒロイック・ギフト」は１ターンに１枚しか発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヒロイック・リベンジ・ソード",h:"ひろいっく・りべんじ・そーど",e:"Heroic Retribution Sword",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"発動後このカードは装備カードとなり、自分フィールド上の「ヒロイック」と名のついたモンスター１体に装備する。\n装備モンスターの戦闘によって発生する自分への戦闘ダメージは相手も受ける。\nまた、装備モンスターと戦闘を行った相手モンスターをダメージ計算後に破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ファイアーダーツ",h:"ふぁいあーだーつ",e:"Fire Darts",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の手札が０枚の時に発動する事ができる。\nサイコロを３回振り、その合計の数×１００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ファイナル・ギアス",h:"ふぁいなる・ぎあす",e:"Final Geas",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：元々のレベルが７以上のモンスターが、自分及び相手のフィールドからそれぞれ１体以上、墓地へ送られたターンに発動できる。\nお互いの墓地のモンスターを全て除外する。\nその後、この効果で除外したモンスターの内、レベルが一番高い魔法使い族モンスター１体を自分フィールドに特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フィッシャーチャージ",h:"ふぃっしゃーちゃーじ",e:"Fish Depth Charge",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の魚族モンスター１体をリリースし、フィールド上のカード１枚を選択して発動できる。\n選択したカードを破壊し、デッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フィッシュ・レイン",h:"ふぃっしゅ・れいん",e:"Fish Rain",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"フィールド上に表側表示で存在する魚族・海竜族・水族モンスターがゲームから除外された時に発動する事ができる。\n自分の手札からレベル３以下の魚族・海竜族・水族モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブービートラップE",h:"ぶーびーとらっぷいー",e:"Booby Trap E",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：手札を１枚捨てて発動できる。\n自分の手札・墓地の永続罠カード１枚を選んで自分フィールドにセットする。\nこの効果でセットしたカードはセットしたターンでも発動できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"封魔の呪印",h:"ふうまのじゅいん",e:"Cursed Seal of the Forbidden Spell",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"手札から魔法カードを１枚捨てる。\n魔法カードの発動と効果を無効にし、それを破壊する。\n相手はこのデュエル中、この効果で破壊された魔法カード及び同名カードを発動する事ができない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"風魔の波動",h:"ふうまのはどう",e:"Fuhma Wave",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターとはカード名が異なり、同じ属性を持つフィールドのモンスター１体を選んで破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"風林火山",h:"ふうりんかざん",e:"Fuh-Rin-Ka-Zan",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：風・水・炎・地属性モンスターがフィールドに全て存在する場合に発動できる。\n以下の効果から１つを選んで適用する。\n\n●相手フィールドのモンスターを全て破壊する。\n\n●相手フィールドの魔法・罠カードを全て破壊する。\n\n●相手の手札をランダムに２枚選んで捨てる。\n\n●自分はデッキから２枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"風霊術－「雅」",h:"ふうれいじゅつ－「みやび」",e:"Spiritual Wind Art - Miyabi",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの風属性モンスター１体をリリースし、相手フィールドのカード１枚を対象として発動できる。\nその相手のカードを持ち主のデッキの一番下に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"不運の爆弾",h:"ふうんのばくだん",e:"Bad Luck Blast",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"「不運の爆弾」は１ターンに１枚しか発動できない。\n①：相手フィールドの表側表示モンスター１体を対象として発動できる。\n自分はその表側表示モンスターの攻撃力の半分のダメージを受ける。\nその後、自分が受けたダメージと同じ数値分のダメージを相手に与える。\n②：フィールドのこのカードが相手によって破壊され墓地へ送られた場合に発動する。\n相手に１０００ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フェイク・エクスプロージョン・ペンタ",h:"ふぇいく・えくすぷろーじょん・ぺんた",e:"Fake Explosion",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手モンスターの攻撃宣言時に発動できる。\nモンスターはその戦闘では破壊されず、ダメージ計算後、自分の手札・墓地から「サモン・リアクター・AI」１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フェイク・フェザー",h:"ふぇいく・ふぇざー",e:"Fake Feather",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"手札から「BF」と名のついたモンスター１体を墓地へ送り、相手の墓地の通常罠カード１枚を選択して発動できる。\nこのカードの効果は、選択した通常罠カードの効果と同じになる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フェザー・ウィンド",h:"ふぇざー・うぃんど",e:"Feather Wind",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"自分フィールド上に「E・HERO フェザーマン」が表側表示で存在する場合に発動する事ができる。\n魔法・罠の発動を無効にし、そのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フォースフィールド",h:"ふぉーすふぃーるど",e:"Riryoku Field",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"フィールド上のモンスター１体を対象にした魔法カードの発動を無効にし、そのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フォーチュン・インハーリット",h:"ふぉーちゅん・いんはーりっと",e:"Inherited Fortune",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「フォーチュンレディ」と名のついたモンスターが破壊されたターンに発動する事ができる。\n次の自分のスタンバイフェイズ時に手札から「フォーチュンレディ」と名のついたモンスターを２体まで特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フォーチュン・スリップ",h:"ふぉーちゅん・すりっぷ",e:"Slip of Fortune",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手モンスターの攻撃宣言時に発動する事ができる。\n相手モンスター１体の攻撃を無効にし、攻撃対象モンスター１体を次のスタンバイフェイズ時までゲームから除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フォーチュンレディ・リワインド",h:"ふぉーちゅんれでぃ・りわいんど",e:"Fortune Lady Rewind",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：除外されている自分の「フォーチュンレディ」モンスターを任意の数だけ対象として発動できる（同名カードは１枚まで）。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターはエンドフェイズに持ち主のデッキに戻る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"F.A.オーバー・ヒート",h:"ふぉーみゅらあすりーとおーばー・ひーと",e:"F.A. Overheat",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：相手フィールドにのみモンスターが存在する場合に発動できる。\n手札から「F.A.」モンスター１体を特殊召喚する。\nこの効果で特殊召喚したモンスターのレベルはターン終了時まで３つ上がる。\n②：自分のフィールドゾーンにカードが存在しない場合、墓地のこのカードを除外して発動できる。\n自分の手札・墓地から「F.A.」フィールド魔法カード１枚を選んで発動する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フォーメーション・ユニオン",h:"ふぉーめーしょん・ゆにおん",e:"Formation Union",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"次の効果から１つを選択して発動する。\n●自分フィールド上に表側表示で存在するユニオンモンスター１体を選択し、自分フィールド上に表側表示で存在する装備可能なモンスターに装備する。\n●自分フィールド上に存在する装備カード扱いのユニオンモンスター１体の装備を解除して、自分フィールド上に表側攻撃表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"光子化",h:"ふぉとないず",e:"Lumenize",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手モンスターの攻撃宣言時に発動する事ができる。\n相手モンスター１体の攻撃を無効にし、その相手モンスターの攻撃力分だけ、自分フィールド上に表側表示で存在する光属性モンスター１体の攻撃力を、次の自分のエンドフェイズ時までアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"深すぎた墓穴",h:"ふかすぎたはかあな",e:"The Deep Grave",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分または相手の墓地のモンスター１体を対象として発動できる。\n次の自分スタンバイフェイズにそのモンスターを墓地から自分フィールドに特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"武器庫荒らし",h:"ぶきこあらし",e:"Arsenal Robber",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手はデッキから装備魔法カードを１枚選択して墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"不吉な占い",h:"ふきつなうらない",e:"Ominous Fortunetelling",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分のスタンバイフェイズ時、相手の手札からカードを１枚ランダムに選択する。\nそのカードの種類（モンスター・魔法・罠）を当てた場合、相手プレイヤーに７００ポイントダメージを与える。\nこの効果は１ターンに１度しか使用できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"不屈の闘志",h:"ふくつのとうし",e:"Unbreakable Spirit",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの表側表示モンスターが１体のみの場合、そのモンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで、相手フィールドの攻撃力が一番低いモンスターの攻撃力分アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"武神逐",h:"ぶじんやらい",e:"Bujinfidel",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の「武神」と名のついた獣戦士族モンスター１体をリリースして発動できる。\nそのモンスターのカード名と異なる「武神」と名のついたモンスター１体を自分の墓地から選択して特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"武装解除",h:"ぶそうかいじょ",e:"Disarmament",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"フィールド上の装備カードを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"復活の聖刻印",h:"ふっかつのせいこくいん",e:"Hieratic Seal From the Ashes",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"相手のターンに１度、デッキから「聖刻」と名のついたモンスター１体を墓地へ送る事ができる。\nまた、自分のターンに１度、ゲームから除外されている自分の「聖刻」と名のついたモンスター１体を選択して墓地に戻す事ができる。\nフィールド上に表側表示で存在するこのカードが墓地へ送られた時、自分の墓地の「聖刻」と名のついたモンスター１体を選択して特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"復活の墓穴",h:"ふっかつのはかあな",e:"The Grave of Enkindling",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在するモンスターが戦闘によって破壊され墓地へ送られた時に発動する事ができる。\n自分と相手はそれぞれの墓地からモンスター１体を選択し、守備表示でフィールド上に特殊召喚する。\nこの効果で特殊召喚したモンスターは、フィールド上に表側表示で存在する限り表示形式を変更できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"物理分身",h:"ぶつりぶんしん",e:"Physical Double",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手のターンでのみ発動する事ができる。\n指定した相手モンスターと同じレベル・種族・属性・攻撃力・守備力を持つ「ミラージュトークン」を１体特殊召喚する。\nターン終了時にこのトークンを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フュージョン・ガード",h:"ふゅーじょん・がーど",e:"Fusion Guard",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"ダメージを与える効果が発動した時に発動する事ができる。\nその発動と効果を無効にし、自分の融合デッキからランダムに融合モンスター１体を墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"融合準備",h:"ふゅーじょん・りざーぶ",e:"Fusion Reserve",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：EXデッキの融合モンスター１体を相手に見せ、そのモンスターにカード名が記されている融合素材モンスター１体をデッキから手札に加える。\nその後、自分の墓地から「融合」１枚を選んで手札に加える事ができる。",li:3,lk:[],ta:[],al:[],ocg:true},
  {n:"フライアのリンゴ",h:"ふらいあのりんご",e:"Apple of Enlightenment",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの「ワルキューレ」モンスターの数まで相手の墓地のカードを対象として発動できる。\nそのカードを除外する。\n②：セットされているこのカードが相手の効果でフィールドから離れ、墓地へ送られた場合または除外された場合に発動できる。\n自分は自分フィールドの「ワルキューレ」モンスターの数＋１枚をデッキからドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブライト・フューチャー",h:"ぶらいと・ふゅーちゃー",e:"Bright Future",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"ゲームから除外されている自分のサイキック族モンスター２体を選択して発動する。\n選択したモンスターを墓地に戻し、自分のデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"プライドの咆哮",h:"ぷらいどのほうこう",e:"Prideful Roar",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"戦闘ダメージ計算時、自分のモンスターの攻撃力が相手モンスターより低い場合、その攻撃力の差分のライフポイントを払って発動する。\nダメージ計算時のみ、自分のモンスターの攻撃力は相手モンスターとの攻撃力の差の数値＋３００ポイントアップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブラック・アロー",h:"ぶらっく・あろー",e:"Ebon Arrow",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在するモンスター１体を選択して発動する。\nエンドフェイズ時まで、そのモンスターの攻撃力は５００ポイントダウンし、守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。\n選択したモンスターが戦闘によってモンスターを破壊し墓地へ送った時、破壊したモンスターの元々の守備力分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブラック・イリュージョン",h:"ぶらっく・いりゅーじょん",e:"Black Illusion",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの攻撃力２０００以上の魔法使い族・闇属性モンスターは、ターン終了時まで、戦闘では破壊されず、効果は無効化され、相手の効果を受けない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブラック・サンダー",h:"ぶらっく・さんだー",e:"Black Thunder",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在する「BF」と名のついたモンスターが戦闘によって破壊され墓地へ送られた時に発動する事ができる。\n相手フィールド上に存在するカード１枚につき、相手ライフに４００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブラック・バード・クローズ",h:"ぶらっく・ばーど・くろーず",e:"Blackbird Close",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"自分フィールドに「BF」Sモンスターまたは「ブラックフェザー・ドラゴン」が存在する場合、このカードの発動は手札からもできる。\n①：相手フィールドのモンスターが効果を発動した時、自分フィールドの表側表示の「BF」モンスター１体を墓地へ送って発動できる。\nその発動を無効にし破壊する。\nその後、EXデッキから「ブラックフェザー・ドラゴン」１体を特殊召喚できる。",li:2,lk:[],ta:[],al:[],ocg:true},
  {n:"ブラック・バック",h:"ぶらっく・ばっく",e:"Blackback",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分のターンのみ発動する事ができる。\n自分の墓地に存在する攻撃力２０００以下の「BF」と名のついたモンスター１体を選択して特殊召喚する。\nこのカードを発動するターン、自分はモンスターを通常召喚する事はできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブラック・ブースト",h:"ぶらっく・ぶーすと",e:"Blackboost",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「BF」と名のついたチューナー２体をゲームから除外して発動する。\n自分のデッキからカードを２枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブラック・リターン",h:"ぶらっく・りたーん",e:"Black Return",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"「BF」と名のついたモンスター１体が特殊召喚に成功した時、相手フィールド上に表側表示で存在するモンスター１体を選択して発動する。\n選択した相手モンスターの攻撃力分だけ自分のライフを回復し、そのモンスターを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブラック・リベンジ",h:"ぶらっく・りべんじ",e:"Black Wing Revenge",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの鳥獣族モンスターが戦闘で破壊され墓地へ送られた時に発動できる。\n自分フィールドに「BF－ブラック・クレスト・トークン」（鳥獣族・闇・星２・攻０／守８００）２体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"BF－アンカー",h:"ぶらっくふぇざー－あんかー",e:"Blackwing - Bombardment",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"「BF」と名のついたモンスター１体をリリースし、自分フィールド上に表側表示で存在するシンクロモンスター１体を選択して発動する。\n選択したモンスターの攻撃力はエンドフェイズ時まで、このカードを発動するためにリリースしたモンスターの攻撃力分アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"BF－バックフラッシュ",h:"ぶらっくふぇざー－ばっくふらっしゅ",e:"Blackwing - Backlash",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の墓地に「BF」と名のついたモンスターが５体以上存在する場合、相手モンスターの直接攻撃宣言時に発動する事ができる。\n相手フィールド上に存在するモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"BF－マイン",h:"ぶらっくふぇざー－まいん",e:"Blackwing - Boobytrap",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"セットされたこのカードが相手の効果によって破壊された時、自分フィールド上に「BF」と名のついたモンスターが表側表示で存在する場合、相手ライフに１０００ポイントダメージを与え、自分はデッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フリッグのリンゴ",h:"ふりっぐのりんご",e:"The Golden Apples",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドにモンスターが存在せず、自分が戦闘ダメージを受けた時に発動できる。\n受けたダメージの数値分だけ自分のLPを回復し、自分フィールドに「邪精トークン」（悪魔族・闇・星１・攻／守？）１体を特殊召喚する。\nこのトークンの攻撃力・守備力は、この効果で自分が回復した数値と同じになる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ふるい落とし",h:"ふるいおとし",e:"Weed Out",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"５００ライフポイントを払う。\nフィールド上に表側攻撃表示で存在する、全てのレベル３モンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"全弾発射",h:"ふるばーすと",e:"Full Salvo",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカードの発動後、手札を全て墓地へ送る。\n墓地に送ったカードの枚数×２００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フルハウス",h:"ふるはうす",e:"Full House",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード以外のフィールド上に表側表示で存在する魔法・罠カード２枚と、セットされた魔法・罠カード３枚を選択して発動できる。\n選択したカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブレイクスルー・スキル",h:"ぶれいくするー・すきる",e:"Breakthrough Skill",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手フィールドの効果モンスター１体を対象として発動できる。\nその相手モンスターの効果をターン終了時まで無効にする。\n②：自分ターンに墓地のこのカードを除外し、相手フィールドの効果モンスター１体を対象として発動できる。\nその相手の効果モンスターの効果をターン終了時まで無効にする。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブレインハザード",h:"ぶれいんはざーど",e:"Brain Hazard",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：除外されている自分のサイキック族モンスター１体を対象としてこのカードを発動できる。\nそのモンスターを特殊召喚する。\nこのカードがフィールドから離れた時にそのモンスターは破壊される。\nそのモンスターが破壊された時にこのカードは破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"捕食惑星",h:"ぷれでたー・ぷらねっと",e:"Predaplanet",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"「捕食惑星」の①の効果は１ターンに１度しか使用できない。\n①：このカードが魔法＆罠ゾーンに存在し、捕食カウンターが置かれているモンスターがフィールドから離れた場合に発動する。\nデッキから「プレデター」カード１枚を手札に加える。\n②：墓地のこのカードを除外して発動できる。\n自分の手札・フィールドから、融合モンスターカードによって決められた融合素材モンスターを墓地へ送り、その融合モンスター１体をエクストラデッキから融合召喚する。\nこの効果で融合召喚する場合、「捕食植物」モンスターしか融合素材にできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フレムベルカウンター",h:"ふれむべるかうんたー",e:"Flamvell Counter",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"自分の墓地の守備力２００の炎属性モンスター１体をゲームから除外して発動できる。\n魔法・罠カードの発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブレンD",h:"ぶれんでぃふぉーまー",e:"Morphtronic Mix-up",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に「D」と名のついたモンスターが２体以上表側表示で存在する場合、相手フィールド上に存在するカードを２枚選択して発動する。\n相手はその中から１枚を選択し、相手が選択したカード１枚を破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"フレンドリーファイア",h:"ふれんどりーふぁいあ",e:"Bad Aim",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手の魔法・罠・モンスターの効果が発動した時、そのカード以外のフィールドのカード１枚を対象として発動できる。\n対象のカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブロークン・ブロッカー",h:"ぶろーくん・ぶろっかー",e:"Broken Blocker",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在する攻撃力よりも守備力の高い守備表示モンスターが、戦闘によって破壊された場合に発動する事ができる。\nそのモンスターと同名モンスターを２体まで自分のデッキから表側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブロッサム・ボンバー",h:"ぶろっさむ・ぼんばー",e:"Blossom Bombardment",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在する植物族モンスターが戦闘によって相手モンスターを破壊し墓地へ送った時に発動する事ができる。\nその戦闘で破壊したモンスターの攻撃力分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"分断の壁",h:"ぶんだんのかべ",e:"Wall of Disruption",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手モンスターの攻撃宣言時に発動できる。\n相手フィールドの全ての攻撃表示モンスターの攻撃力は、相手フィールドのモンスターの数×８００ダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"陽炎光輪",h:"へいずぐろーりー",e:"Hazy Glory",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードがフィールド上に存在する限り、自分は「陽炎獣」と名のついたモンスターを召喚する場合に必要なリリースを１体少なくする事ができる。\nまた、フィールド上に表側表示で存在するこのカードを墓地へ送る事で、自分の墓地から「陽炎光輪」以外の「ヘイズ」と名のついたカード１枚を選択して手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヘッド・ジャッジング",h:"へっど・じゃっじんぐ",e:"Head Judging",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分または相手がフィールドのモンスターの効果を発動した時に発動できる。\n発動したそのプレイヤーはコイントスを１回行い、裏表を当てる。\n当たった場合、このカードは墓地へ送られる。\nハズレの場合、その発動を無効にし、そのモンスターのコントロールをそのプレイヤーから見て相手に移す。",li:2,lk:[],ta:[],al:[],ocg:true},
  {n:"ヘル・ポリマー",h:"へる・ぽりまー",e:"Chthonian Polymer",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手が融合モンスターを融合召喚した時に発動する事ができる。\n自分フィールド上のモンスター１体を生け贄に捧げる事で、その融合モンスター１体のコントロールを得る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"方界降世",h:"ほうかいごうぜ",e:"Cubic Ascension",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：相手モンスターの攻撃宣言時に発動できる。\nデッキから「方界胤ヴィジャム」１体を特殊召喚し、攻撃対象をそのモンスターに移し替えてダメージ計算を行う。\n②：自分のLPが相手より２０００以上少ない場合、墓地のこのカードを除外して発動できる。\n自分の手札・デッキ・墓地から「方界胤ヴィジャム」１体を選んで特殊召喚する。\n相手フィールドにのみモンスターが存在する状態で発動した場合、さらに「方界胤ヴィジャム」を２体まで選んで特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"方界曼荼羅",h:"ほうかいまんだら",e:"Cubic Mandala",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：自分フィールドに「方界」モンスターが存在する場合、このターン破壊され相手の墓地へ送られたモンスターを任意の数だけ対象としてこのカードを発動できる。\nそのモンスターの攻撃力を０にして相手フィールドに特殊召喚し、そのモンスターに方界カウンターを１つずつ置く。\n方界カウンターが置かれたモンスターは攻撃できず、効果は無効化される。\n②：対象のモンスターが相手フィールドに存在する限り、相手が発動したモンスターの効果は無効化される。\n③：対象のモンスターが全てフィールドから離れた場合にこのカードは破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"忘却の海底神殿",h:"ぼうきゃくのかいていしんでん",e:"Forgotten Temple of the Deep",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードがフィールド上に存在する限り、このカードのカード名は「海」として扱う。\n１ターンに１度、自分フィールド上のレベル４以下の魚族・海竜族・水族モンスター１体を選択してゲームから除外できる。\n自分のエンドフェイズ時、この効果で除外したモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"宝玉の祈り",h:"ほうぎょくのいのり",e:"Crystal Raigeki",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の魔法＆罠カードゾーンの「宝玉獣」と名のついたカード１枚を墓地へ送って発動できる。\n相手フィールド上のカード１枚を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"宝玉の集結",h:"ほうぎょくのしゅうけつ",e:"Crystal Conclave",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードの①②の効果は同一チェーン上では発動できない。\n①：１ターンに１度、自分フィールドの表側表示の「宝玉獣」モンスターが戦闘・効果で破壊された場合にこの効果を発動できる。\nデッキから「宝玉獣」モンスター１体を特殊召喚する。\n②：魔法＆罠ゾーンの表側表示のこのカードを墓地へ送り、自分フィールドの「宝玉獣」カード１枚とフィールドのカード１枚を対象としてこの効果を発動できる。\n対象のカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"宝玉の双璧",h:"ほうぎょくのそうへき",e:"Crystal Pair",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在する「宝玉獣」と名のついたモンスターが戦闘によって破壊され墓地へ送られた時に発動する事ができる。\nデッキから「宝玉獣」と名のついたモンスター１体を永続魔法カード扱いとして自分の魔法＆罠カードゾーンに表側表示で置く。\nこのターン、自分が受ける戦闘ダメージは０になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"暴君の威圧",h:"ぼうくんのいあつ",e:"Tyrant's Temper",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上に存在するモンスター１体をリリースして発動する。\nこのカードがフィールド上に存在する限り、フィールド上に表側表示で存在する元々の持ち主が自分となるモンスターは、このカード以外の罠カードの効果を受けない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"暴君の暴言",h:"ぼうくんのぼうげん",e:"Tyrant's Tirade",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上に存在するモンスター２体をリリースして発動する。\nこのカードがフィールド上に存在する限り、お互いに手札及びフィールド上で発動する効果モンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"暴君の暴力",h:"ぼうくんのぼうりょく",e:"Tyrant's Tantrum",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上に存在するモンスター２体をリリースして発動する。\nこのカードがフィールド上に存在する限り、相手はデッキから魔法カード１枚を墓地へ送らなければ魔法カードを発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"吠え猛る大地",h:"ほえたけるだいち",e:"Roaring Earth",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上に存在する獣族モンスターが守備表示モンスターを攻撃した時、その守備力を攻撃力が超えていれば、その数値だけ相手ライフに戦闘ダメージを与える。\nこの効果が適用された事によって相手ライフに戦闘ダメージを与えた時、相手フィールド上に表側表示で存在するモンスター１体の攻撃力・守備力は５００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ホーリー・エルフの祝福",h:"ほーりー・えるふのしゅくふく",e:"Gift of The Mystical Elf",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分はフィールド上に存在するモンスターの数×３００ライフポイント回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ホーリージャベリン",h:"ほーりーじゃべりん",e:"Enchanted Javelin",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手モンスターの攻撃宣言時に発動する事ができる。\nその攻撃モンスター１体の攻撃力分だけ自分のライフポイントを回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ホーリーライフバリアー",h:"ほーりーらいふばりあー",e:"Hallowed Life Barrier",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"手札を１枚捨てる。\nこのカードを発動したターン、相手から受ける全てのダメージを０にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ボーン・テンプル・ブロック",h:"ぼーん・てんぷる・ぶろっく",e:"Bone Temple Block",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"手札を１枚捨てる。\nお互いに相手の墓地からレベル４以下のモンスター１体を選択し自分フィールド上に特殊召喚する。\nこの効果で特殊召喚したモンスターは次のターンのエンドフェイズ時に破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"補充要員",h:"ほじゅうよういん",e:"Backup Soldier",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の墓地にモンスターが５体以上存在する場合に発動する事ができる。\n自分の墓地に存在する効果モンスター以外の攻撃力１５００以下のモンスターを３体まで選択して手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ポセイドン・ウェーブ",h:"ぽせいどん・うぇーぶ",e:"Poseidon Wave",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手モンスターの攻撃宣言時に発動する事ができる。\n相手モンスター１体の攻撃を無効にする。\n自分フィールド上に魚族・海竜族・水族モンスターが表側表示で存在する場合、その数×８００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"墓地墓地の恨み",h:"ぼちぼちのうらみ",e:"Ghost of a Grudge",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手の墓地のカードが８枚以上の場合に発動できる。\n相手フィールドの全てのモンスターの攻撃力を０にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ボム・ガード",h:"ぼむ・がーど",e:"Guard Mines",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"「自分フィールド上に存在するモンスター１体を破壊する効果」を持つカードが発動した時に発動する事ができる。\nその発動を無効にし破壊する。\nさらに相手ライフに５００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"白の咆哮",h:"ほわいと・はうりんぐ",e:"White Howling",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドに水属性モンスターが存在する場合、相手の墓地の魔法カード１枚を対象として発動できる。\nそのカードを除外し、このターン、相手フィールドの魔法カードの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"本陣強襲",h:"ほんじんきょうしゅう",e:"Assault on GHQ",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在するモンスター１体を選択して発動する。\n選択したカードを破壊した後、相手のデッキのカードを上から２枚墓地に送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"凡人の施し",h:"ぼんじんのほどこし",e:"Common Charity",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"デッキからカードを２枚ドローし、その後手札の通常モンスター１体をゲームから除外する。\n手札に通常モンスターが無い場合、手札を全て墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ボンディング－DHO",h:"ぼんでぃんぐ－でぃーえいちおー",e:"Bonding - DHO",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分の手札・墓地から「デューテリオン」「ハイドロゲドン」「オキシゲドン」を１体ずつデッキに戻して発動できる。\n自分の手札・墓地から「ウォーター・ドラゴン－クラスター」１体を選んで特殊召喚する。\n②：墓地のこのカードを除外して発動できる。\n自分のデッキ・墓地から「ウォーター・ドラゴン」または「ウォーター・ドラゴン－クラスター」１体を選んで手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"埋蔵金の地図",h:"まいぞうきんのちず",e:"Treasure Map",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"セットされたこのカードを手札に戻す効果が発動した時に発動する事ができる。\n自分のデッキからカードを２枚ドローし、その後手札を１枚捨てる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マインド・ハック",h:"まいんど・はっく",e:"Mind Haxorz",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"５００ライフポイントを払う。\n相手の手札と相手フィールド上のセットされたカードを全て確認する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔宮の賄賂",h:"まきゅうのわいろ",e:"Dark Bribe",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：相手が魔法・罠カードを発動した時に発動できる。\nその発動を無効にし破壊する。\n相手はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マグネット・コンバージョン",h:"まぐねっと・こんばーじょん",e:"Magnet Conversion",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分の墓地のレベル４以下の「マグネット・ウォリアー」モンスターを３体まで対象として発動できる。\nそのモンスターを手札に加える。\n②：墓地のこのカードを除外し、除外されている自分のレベル４以下の「マグネット・ウォリアー」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マグネット・フォース",h:"まぐねっと・ふぉーす",e:"Magnet Force",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：このターン、元々の種族が機械族または岩石族のフィールドのモンスターは、自身以外の相手モンスターの効果を受けない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マジカルシルクハット",h:"まじかるしるくはっと",e:"Magical Hats",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手バトルフェイズに発動できる。\nデッキから魔法・罠カード２枚を選び、そのカード２枚を通常モンスターカード扱い（攻／守０）として、自分のメインモンスターゾーンのモンスター１体と合わせてシャッフルして裏側守備表示でセットする。\nこの効果でデッキから特殊召喚したカードはバトルフェイズの間しか存在できず、バトルフェイズ終了時に破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マジシャンズ・サークル",h:"まじしゃんず・さーくる",e:"Magician's Circle",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分または相手の魔法使い族モンスターの攻撃宣言時に発動できる。\nお互いのプレイヤーは、それぞれ自分のデッキから攻撃力２０００以下の魔法使い族モンスター１体を攻撃表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マジシャンズ・ナビゲート",h:"まじしゃんず・なびげーと",e:"Magician Navigation",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：手札から「ブラック・マジシャン」１体を特殊召喚する。\nその後、デッキからレベル７以下の魔法使い族・闇属性モンスター１体を特殊召喚する。\n②：自分フィールドに「ブラック・マジシャン」が存在する場合、墓地のこのカードを除外し、相手フィールドの表側表示の魔法・罠カード１枚を対象として発動できる。\nそのカードの効果をターン終了時まで無効にする。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:3,lk:[],ta:[],al:[],ocg:true},
  {n:"マジシャンズ・プロテクション",h:"まじしゃんず・ぷろてくしょん",e:"Magicians' Defense",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：自分フィールドに魔法使い族モンスターが存在する限り、自分が受ける全てのダメージは半分になる。\n②：このカードがフィールドから墓地へ送られた場合、自分の墓地の魔法使い族モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マジック・キャプチャー",h:"まじっく・きゃぷちゃー",e:"Spell Reclamation",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分が魔法カードを発動した時、手札を１枚捨ててチェーン発動する。\nチェーン発動した魔法カードが墓地へ送られた時、そのカードを手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マジック・ジャマー",h:"まじっく・じゃまー",e:"Magic Jammer",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：魔法カードが発動した時、手札を１枚捨てて発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マジック・ディフレクター",h:"まじっく・でぃふれくたー",e:"Magic Deflector",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このターン、フィールド上の装備・フィールド・永続・速攻魔法カードの効果を無効にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マジック・ドレイン",h:"まじっく・どれいん",e:"Magic Drain",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"相手が魔法カードを発動した時に発動する事ができる。\n相手は手札から魔法カード１枚を捨ててこのカードの効果を無効にする事ができる。\n捨てなかった場合、相手の魔法カードの発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔導変換",h:"まじっく・ばーすと",e:"Mythical Bestiamorph",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：このカードが魔法＆罠ゾーンに存在する限り、相手モンスターの効果が発動する度に、このカードに魔力カウンターを１つ置く。\n②：魔力カウンターが６つ以上置かれているこのカードを墓地へ送って発動できる。\nデッキから魔法カード１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マジックアーム・シールド",h:"まじっくあーむ・しーるど",e:"Magical Arm Shield",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手フィールドに表側表示モンスターが２体以上存在し、自分フィールドにモンスターが存在する場合、相手モンスターの攻撃宣言時に攻撃モンスター以外の相手フィールドの表側表示モンスター１体を対象として発動できる。\nその表側表示モンスターのコントロールをバトルフェイズ終了時まで得て、攻撃対象をそのモンスターに移し替えてダメージ計算を行う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マスター・ピース",h:"ますたー・ぴーす",e:"Halfway to Forever",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分の墓地のモンスター２体を対象として発動できる。\nそのモンスター２体を効果を無効にして特殊召喚し、その２体のみを素材として光属性の「ホープ」Xモンスター１体をX召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔製産卵床",h:"ませいさんらんしょう",e:"Underworld Egg Clutch",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在する魚族・海竜族・水族モンスターがゲームから除外された時に発動する事ができる。\n自分のデッキからレベル４以下の魚族・海竜族・水族モンスター１体を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"抹殺の聖刻印",h:"まっさつのせいこくいん",e:"Hieratic Seal of Banishment",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の「聖刻」と名のついたモンスター１体をリリースして発動できる。\n相手フィールド上のカード１枚を選択してゲームから除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マドルチェ・ティーブレイク",h:"まどるちぇ・てぃーぶれいく",e:"Madolche Tea Break",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"自分の墓地にモンスターが存在しない場合に発動できる。\n魔法・罠カードの発動を無効にし、そのカードを持ち主の手札に戻す。\n自分フィールド上に「マドルチェ・プディンセス」が存在する場合、さらに相手フィールド上のカード１枚を選んで破壊できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔導人形の夜",h:"まどるちぇ・ないつ",e:"Madolche Nights",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"自分の墓地にモンスターが存在しない場合に発動できる。\n効果モンスターの効果の発動を無効にする。\n自分フィールド上に「マドルチェ・プディンセス」が存在する場合、さらに相手の手札をランダムに１枚デッキに戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マドルチェ・ハッピーフェスタ",h:"まどるちぇ・はっぴーふぇすた",e:"Madolchepalooza",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"手札から「マドルチェ」と名のついたモンスターを任意の数だけ特殊召喚する。\nこの効果で特殊召喚したモンスターはエンドフェイズ時に持ち主のデッキに戻る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マドルチェ・マナー",h:"まどるちぇ・まなー",e:"Madolche Lesson",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の墓地の「マドルチェ」と名のついたモンスター１体を選択してデッキに戻し、自分フィールド上に存在する全ての「マドルチェ」と名のついたモンスターの攻撃力・守備力は８００ポイントアップする。\nその後、自分の墓地のモンスター１体を選んでデッキに戻す事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マドルチェ・ワルツ",h:"まどるちぇ・わるつ",e:"Madolche Waltz",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上の「マドルチェ」と名のついたモンスターが戦闘を行ったダメージ計算後、相手ライフに３００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔の取引",h:"まのとりひき",e:"Terrible Deal",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手が魔法カードを発動した時、１０００ライフポイントを払って発動できる。\n相手の手札をランダムに１枚捨てる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔法除去細菌兵器",h:"まほうじょきょさいきんへいき",e:"Virus Cannon",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：トークン以外の自分フィールドのモンスターを任意の数だけリリースして発動できる。\nリリースしたモンスターの数だけ、相手はデッキから魔法カードを選んで墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔法探査の石版",h:"まほうたんさのせきばん",e:"Mystic Probe",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"永続魔法カードが発動された時に発動する事ができる。\nターン終了時までフィールド上の全ての永続魔法カードの効果を無効にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔法封印の呪符",h:"まほうふういんのじゅふ",e:"Talisman of Spell Sealing",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"「封印師 メイセイ」が自分フィールド上に存在している時のみ発動する事ができる。\nこのカードがフィールド上に存在する限り魔法カードは発動できず、全てのフィールド上魔法カードの効果は無効になる。\n「封印師 メイセイ」が自分フィールド上に存在しなくなった時、このカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔法名－「大いなる獣」",h:"まほうめい－「と・めが・せりおん」",e:"Omega Summon",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：除外されている自分の「召喚獣」モンスターを任意の数だけ対象として発動できる（同名カードは１枚まで）。\nそのモンスターを守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔妖変生",h:"まやかしへんじょう",e:"Mayakashi Metamorphosis",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できず、このカードを発動するターン、自分は「魔妖」モンスターしかEXデッキから特殊召喚できない。\n①：手札を１枚捨て、自分の墓地のモンスター及び除外されている自分のモンスターの中から、「魔妖」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターは、このターン相手の効果の対象にならない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔力枯渇",h:"まりょくこかつ",e:"Exhausting Spell",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分と相手フィールド上に存在する魔力カウンターを全て取り除く。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔力終了宣告",h:"まりょくしゅうりょうせんこく",e:"Spell-Stopping Statute",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"相手が永続魔法カードを発動した時に発動する事ができる。\nそのカードの発動と効果を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔力浄化",h:"まりょくじょうか",e:"Spell Purification",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"手札を１枚捨てる。\nフィールド上に表側表示で存在する永続魔法カードを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔力の棘",h:"まりょくのとげ",e:"Magical Thorn",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"相手の手札が墓地へ捨てられた時、捨てたカードの数×５００ポイントダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魔力誘爆",h:"まりょくゆうばく",e:"That Wacky Alchemy!",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手の魔法＆罠カードゾーンに表側表示で存在する魔法カードが墓地へ送られた場合に発動できる。\nフィールド上に表側表示で存在するモンスター１体を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"誤作動",h:"まるふぁんくしょん",e:"Malfunction",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"５００ライフポイントを払う。\n罠カードの発動を無効にし、そのカードを元に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"身代わりの闇",h:"みがわりのやみ",e:"Dark Sacrifice",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：フィールドのカードを破壊する相手の魔法・罠・モンスターの効果が発動した時に発動できる。\nその効果を無効にする。\nその後、デッキからレベル３以下の闇属性モンスター１体を墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"見切りの極意",h:"みきりのごくい",e:"Showdown of the Secret Sense Scroll Techniques",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：相手の墓地のカードと同名のモンスターの効果・魔法・罠カードを相手が発動した時に発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ミクロ光線",h:"みくろこうせん",e:"Micro Ray",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"フィールド上に表側表示で存在するモンスター１体の守備力は、エンドフェイズ終了時まで０になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ミス・リバイブ",h:"みす・りばいぶ",e:"Foolish Revival",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手の墓地のモンスター１体を選択して発動できる。\n選択したモンスターを相手フィールド上に表側守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ミステリーサークル",h:"みすてりーさーくる",e:"Crop Circles",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上のモンスターを任意の数だけ墓地へ送って発動できる。\n墓地へ送ったモンスターのレベルの合計と同じレベルの「エーリアン」と名のついたモンスター１体をデッキから特殊召喚する。\n特殊召喚できなかった場合、自分は２０００ポイントダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"道連れ",h:"みちづれ",e:"Michizure",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：モンスターが戦闘で破壊され自分の墓地へ送られた時、またはフィールドのモンスターが自分の墓地へ送られた時、フィールドのモンスター１体を対象として発動できる。\nそのモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ミニチュアライズ",h:"みにちゅあらいず",e:"Miniaturize",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"フィールド上に表側表示で存在する元々の攻撃力が１０００より上のモンスター１体を選択して発動する。\n選択したモンスターのレベルを１つ下げ、攻撃力は１０００ポイントダウンする。\nそのモンスターがフィールド上に存在しなくなった時、このカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ミョルニルの魔槌",h:"みょるにるのまづち",e:"Divine Relic Mjollnir",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「極神」と名のついたモンスター１体を選択して発動する。\nこのターン、選択したモンスターは１度のバトルフェイズ中に２回攻撃する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"未来王の予言",h:"みらいおうのよげん",e:"Enlightenment",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在する魔法使い族モンスターの攻撃によって相手モンスターを破壊した場合、そのダメージステップ終了時に発動する事ができる。\nそのモンスターはもう１度だけ続けて攻撃する事ができる。\nこのカードを発動するターン、自分は召喚・反転召喚・特殊召喚する事はできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ミラクル・キッズ",h:"みらくる・きっず",e:"Miracle Kids",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"エンドフェイズ時まで相手モンスター１体の攻撃力は、自分の墓地に存在する「ヒーロー・キッズ」の枚数×４００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"月光輪廻舞踊",h:"むーんらいと・りんかねーしょん・だんす",e:"Lunalight Reincarnation Dance",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"「月光輪廻舞踊」は１ターンに１枚しか発動できない。\n①：自分フィールドのモンスターが戦闘・効果で破壊された場合に発動できる。\nデッキから「ムーンライト」モンスターを２体まで手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"無効",h:"むこう",e:"Muko",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"カードをドローする効果を発動した時に発動する事ができる。\nそのカードの効果によってドローするカードをお互いに確認し、全て墓地に捨てる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"六尺瓊勾玉",h:"むさかにのまがたま",e:"Musakani Magatama",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：自分フィールドに「六武衆」モンスターが存在し、カードを破壊するモンスターの効果・魔法・罠カードを相手が発動した時に発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"無差別破壊",h:"むさべつはかい",e:"Blind Destruction",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分のスタンバイフェイズ毎にサイコロを１回振る。\n１～５の目が出た場合、フィールド上に表側表示で存在する出た目と同じレベルのモンスターを全て破壊する。\n６の目が出た場合、フィールド上に表側表示で存在するレベル６以上のモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"無情なはたき落とし",h:"むじょうなはたきおとし",e:"Heartless Drop Off",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：フィールドの表側表示のカードまたは墓地のカードが、効果で相手の手札に加わった時に発動できる。\n相手の手札を確認し、その中から手札に加えたカード及びその同名カードを全て除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"無抵抗の真相",h:"むていこうのしんそう",e:"Wolf in Sheep's Clothing",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手モンスターの直接攻撃によって自分が戦闘ダメージを受けた時、手札のレベル１モンスター１体を相手に見せて発動する。\n相手に見せたモンスター１体と、自分のデッキに存在する同名モンスター１体を自分フィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"女神の加護",h:"めがみのかご",e:"Aegis of Gaia",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分は３０００ライフポイント回復する。\n自分フィールド上に表側表示で存在するこのカードがフィールド上から離れた時、自分は３０００ポイントダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"メガリス・エマージョン",h:"めがりす・えまーじょん",e:"Megalith Emergence",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分の墓地の「メガリス」モンスター１体を対象として発動できる。\nそのモンスターを守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターは、フィールドから離れた場合に持ち主のデッキの一番下に戻る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"メガリス・プロモーション",h:"めがりす・ぷろもーしょん",e:"Megalith Promotion",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：自分フィールドのレベル４以下のモンスター１体を対象として発動できる。\nそのモンスターのレベルをターン終了時まで元々のレベルの倍にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"恵みの風",h:"めぐみのかぜ",e:"Blessed Winds",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①の効果は１ターンに１度しか使用できない。\n①：以下の効果から１つを選択して発動できる。\n\n●手札及び自分フィールドの表側表示モンスターの中から、植物族モンスター１体を墓地へ送って発動できる。\n自分は５００LP回復する。\n\n●自分の墓地の植物族モンスター１体を対象として発動できる。\nそのモンスターをデッキに戻す。\nその後、自分は５００LP回復する。\n\n●１０００LPを払って発動できる。\n自分の墓地から「アロマ」モンスター１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"メサイアの蟻地獄",h:"めさいあのありじごく",e:"Infinite Dismissal",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"フィールド上に表側表示で存在する全てのレベル３以下のモンスターは、召喚・反転召喚したターンのエンドフェイズ時に破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"メタファイズ・ディメンション",h:"めたふぁいず・でぃめんしょん",e:"Metaphys Dimension",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカード名の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：相手がモンスターの特殊召喚に成功した場合、除外されている自分の「メタファイズ」モンスター１体を対象としてこの効果を発動できる。\nそのモンスターを特殊召喚する。\nこの効果で特殊召喚したモンスターは次のターンのエンドフェイズに除外される。\n②：このカードが既に魔法＆罠ゾーンに存在する状態で、このカード以外の自分の「メタファイズ」カードが除外された場合、相手フィールドのカード１枚を対象として発動できる。\nそのカードを除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"メタモル・クレイ・フォートレス",h:"めたもる・くれい・ふぉーとれす",e:"Metamorphortress",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：自分フィールドのレベル４以上のモンスター１体を対象として発動できる。\nこのカードは発動後、効果モンスター（岩石族・地・星４・攻／守１０００）となり、モンスターゾーンに特殊召喚する。\nその後、対象の表側表示モンスターを装備カード扱いとしてこのカードに装備する。\nこのカードは罠カードとしても扱う。\n②：このカードの効果で特殊召喚されたこのカードの攻撃力・守備力は、このカードの効果で装備したモンスターの攻撃力分アップし、このカードは攻撃したダメージステップ終了時に守備表示になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"紋章の記録",h:"めだりおん・れこーど",e:"Heraldry Record",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"相手フィールド上のエクシーズモンスターがそのエクシーズ素材を取り除いて効果を発動した時に発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"メタル化・魔法反射装甲",h:"めたるか・まほうはんしゃそうこう",e:"Metalmorph",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：フィールドの表側表示モンスター１体を対象としてこのカードを発動できる。\nこのカードを攻撃力・守備力３００アップの装備カード扱いとして、そのモンスターに装備する。\n②：このカードの効果でこのカードを装備したモンスターの攻撃力は、モンスターに攻撃するダメージ計算時のみ、その攻撃対象モンスターの攻撃力の半分アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"メテオ・レイン",h:"めてお・れいん",e:"Meteorain",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このターン自分のモンスターが守備表示モンスターを攻撃した時にその守備力を攻撃力が越えていれば、その数値だけ相手に戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"メンタルドレイン",h:"めんたるどれいん",e:"Mind Drain",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"１０００LPを払ってこのカードを発動できる。\n①：このカードが魔法＆罠ゾーンに存在する限り、お互いに手札のモンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"猛進する剣闘獣",h:"もうしんするぐらでぃあるびーすと",e:"Gladiator Beast Charge",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの「剣闘獣」モンスターの種類の数まで、フィールドの表側表示のカードを対象として発動できる。\nそのカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"猛突進",h:"もうとっしん",e:"Super Rush Recklessly",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在する獣族モンスター１体を選択して破壊し、相手フィールド上に存在するモンスター１体を選択してデッキに戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"猛吹雪",h:"もうふぶき",e:"Driving Snow",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手のコントロールするカードの効果によって自分の罠カードが破壊され、フィールドから墓地に送られた時に発動する事ができる。\nフィールド上の魔法または罠カード１枚を破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魍魎跋扈",h:"もうりょうばっこ",e:"Free-Range Monsters",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分・相手のメインフェイズに発動できる。\nモンスター１体を通常召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"燃え上がる大海",h:"もえあがるたいかい",e:"High Tide on Fire Island",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上にレベル７以上の水属性または炎属性のモンスターが存在する場合に発動できる。\n自分フィールド上のモンスターの属性によって、以下の効果を適用する。\n\n●水属性：このターン効果モンスターの効果を発動するために自分の墓地へ送られた水属性モンスターを可能な限り特殊召喚する。\nその後、自分フィールド上のモンスター１体を選んで破壊する。\n\n●炎属性：フィールド上のモンスター１体を選んで破壊する。\nその後、自分の手札が１枚以上ある場合、１枚を選んで墓地へ捨てる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"燃える闘志",h:"もえるとうし",e:"Fiery Fervor",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"発動後このカードは装備カードとなり、自分フィールド上に表側表示で存在するモンスター１体に装備する。\n元々の攻撃力よりも攻撃力が高いモンスターが相手フィールド上に存在する場合、装備モンスターの攻撃力はダメージステップの間、元々の攻撃力の倍になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"木遁封印式",h:"もくとんふういんしき",e:"Sealing Ceremony of Mokuton",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"１ターンに１度、自分フィールド上に表側表示で存在する地属性モンスター１体をリリースする事で、相手の墓地に存在するカードを２枚まで選択してゲームから除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"もの忘れ",h:"ものわすれ",e:"Memory Loss",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手フィールド上に表側攻撃表示で存在するモンスターの効果が発動した時に発動できる。\nその発動した効果を無効にし、そのモンスターを表側守備表示にする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"諸刃の活人剣術",h:"もろはのかつじんけんじゅつ",e:"Double-Edged Sword Technique",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分の墓地の「六武衆」モンスター２体を対象として発動できる。\nそのモンスターを攻撃表示で特殊召喚する。\nこの効果で特殊召喚したモンスターはこのターンのエンドフェイズに破壊され、自分は破壊されたモンスターの攻撃力の合計分のダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"モンスターレリーフ",h:"もんすたーれりーふ",e:"Relieve Monster",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手モンスターの攻撃宣言時に発動する事ができる。\n自分フィールド上に存在するモンスター１体を手札に戻し、その後手札からレベル４モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"約束の地 －アヴァロン－",h:"やくそくのち　－あう゛ぁろん－",e:"Avalon",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：「アルトリウス」モンスター及び「ランスロット」モンスター１体ずつを含む自分の墓地の「聖騎士」モンスター５体を対象として発動できる。\nそのモンスターを除外し、フィールドのカードを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"野生の咆哮",h:"やせいのほうこう",e:"Howl of the Wild",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分フィールド上に存在するモンスターが戦闘によって相手モンスターを破壊し墓地へ送った時、自分フィールド上に表側表示で存在する獣族モンスター１体につき相手ライフに３００ポイントダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"八汰烏の骸",h:"やたがらすのむくろ",e:"Legacy of Yata-Garasu",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"次の効果から１つを選択して発動する。\n\n●自分のデッキからカードを１枚ドローする。\n\n●相手フィールド上にスピリットモンスターが表側表示で存在する場合に発動する事ができる。\n自分のデッキからカードを２枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"闇からの呼び声",h:"やみからのよびごえ",e:"Call of Darkness",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"「死者蘇生」の効果によって特殊召喚したモンスターを全て墓地へ送る。\nこのカードがフィールド上に存在する限り、お互いに「死者蘇生」を使用する事はできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"闇次元の解放",h:"やみじげんのかいほう",e:"Escape from the Dark Dimension",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：除外されている自分の闇属性モンスター１体を対象としてこのカードを発動できる。\nそのモンスターを特殊召喚する。\nこのカードがフィールドから離れた時にそのモンスターは破壊され除外される。\nそのモンスターが破壊された時にこのカードは破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"闇の幻影",h:"やみのげんえい",e:"Dark Illusion",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"フィールド上に表側表示で存在する闇属性モンスターを対象にする効果モンスターの効果・魔法・罠カードの発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"闇の呪縛",h:"やみのじゅばく",e:"Shadow Spell",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"相手フィールドの表側表示モンスター１体を対象としてこのカードを発動できる。\n①：対象のモンスターの攻撃力は７００ダウンし、攻撃できず、表示形式の変更もできない。\nそのモンスターがフィールドから離れた時にこのカードは破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"闇の閃光",h:"やみのせんこう",e:"Darklight",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドの攻撃力１５００以上の闇属性モンスター１体をリリースして発動できる。\nこのターンに特殊召喚されたモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"闇の取引",h:"やみのとりひき",e:"Dark Deal",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手の通常魔法発動時に１０００ライフポイントを払って発動する事ができる。\nその時相手が発動した通常魔法の効果は、「相手はランダムに手札を１枚捨てる」となる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"闇霊術－「欲」",h:"やみれいじゅつ－「よく」",e:"Dark Spirit Art - Greed",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の闇属性モンスター１体をリリースして発動できる。\n相手は手札から魔法カード１枚を見せてこのカードの効果を無効にできる。\n見せなかった場合、自分はデッキからカードを２枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"融合禁止エリア",h:"ゆうごうきんしえりあ",e:"Non-Fusion Area",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"お互いのプレイヤーは融合召喚をする事ができない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"融合失敗",h:"ゆうごうしっぱい",e:"Mispolymerization",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"融合モンスターが特殊召喚された時に発動する事ができる。\nフィールド上に存在する全ての融合モンスターを融合デッキに戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"融合体駆除装置",h:"ゆうごうたいくじょそうち",e:"Anti-Fusion Device",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"フィールド上に表側表示で存在する融合モンスター１体を破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"融爆",h:"ゆうばく",e:"Induced Explosion",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドのカードが魔法カードの効果で破壊された時、相手フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\n②：自分フィールドのカードが魔法カードの効果で破壊された時、墓地のこのカードを除外し、相手フィールドのカード１枚を対象として発動できる。\nそのカードを破壊する。\nこの効果はこのカードが墓地へ送られたターンには発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"誘発召喚",h:"ゆうはつしょうかん",e:"Triggered Summon",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手フィールド上にモンスターが特殊召喚された時に発動する事ができる。\nお互いは手札からレベル４以下のモンスター１体をフィールド上に特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"誘惑のシャドウ",h:"ゆうわくのしゃどう",e:"Shadow of Eyes",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手フィールド上にモンスターがセットされた時に発動する事ができる。\nそのセットされたモンスター１体を表側攻撃表示にする。\n（この時、リバース効果モンスターの効果は発動しない。\n）",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"油断大敵",h:"ゆだんたいてき",e:"Asleep at the Switch",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手のライフポイントが回復した時に発動する事ができる。\n相手フィールド上に存在するモンスター１体を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"妖仙獣の居太刀風",h:"ようせんじゅうのいたちかぜ",e:"Yosenjus' Sword Sting",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドにモンスターが存在しない場合、手札からカード名が異なる「妖仙獣」モンスターを２体まで相手に見せ、見せた数だけ相手フィールドの表側表示のカードを対象として発動できる。\nそのカードを持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"妖仙獣の秘技",h:"ようせんじゅうのひぎ",e:"Yosenjus' Secret Move",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：自分フィールドに「妖仙獣」カードが存在し、自分のモンスターゾーンに「妖仙獣」モンスター以外の表側表示モンスターが存在しない場合、モンスターの効果・魔法・罠カードが発動した時に発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"陽動作戦",h:"ようどうさくせん",e:"A Feint Plan",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このターン、お互いは裏側表示モンスターを攻撃できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"横取りボーン",h:"よこどりぼーん",e:"Monster Rebone",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：相手がモンスターを特殊召喚したターン、相手の墓地のモンスター１体を対象としてこのカードを発動できる。\nそのモンスターを自分フィールドに守備表示で特殊召喚する。\nこのカードがフィールドから離れた時にそのモンスターは除外される。\nそのモンスターがフィールドから離れた時にこのカードは破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"蘇りし魂",h:"よみがえりしたましい",e:"Soul Resurrection",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：自分の墓地の通常モンスター１体を対象としてこのカードを発動できる。\nそのモンスターを守備表示で特殊召喚する。\nこのカードがフィールドから離れた時にそのモンスターは破壊される。\nそのモンスターが破壊された時にこのカードは破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ライジング・エナジー",h:"らいじんぐ・えなじー",e:"Rising Energy",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：手札を１枚捨て、フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで１５００アップする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ライト・オブ・デストラクション",h:"らいと・おぶ・ですとらくしょん",e:"Light of Destruction",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"相手の効果によって相手のデッキからカードが墓地へ送られた時、相手のデッキの上からカードを３枚墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ライト・リサイレンス",h:"らいと・りさいれんす",e:"Light Spiral",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"「ライトロード」と名のついたモンスターの効果で自分のデッキからカードが墓地に送られる度に、相手のデッキの上からカードを１枚ゲームから除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"雷遁封印式",h:"らいとんふういんしき",e:"Sealing Ceremony of Raiton",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"１ターンに１度、自分フィールド上に表側表示で存在する風属性モンスター１体をデッキの一番下に戻す事で、相手の墓地のカードを２枚まで選択してゲームから除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ライバル登場！",h:"らいばるとうじょう！",e:"A Rival Appears!",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手フィールド上に表側表示で存在するモンスター１体を選択する。\n選択したモンスターと同じレベルのモンスター１体を自分の手札から特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ライヤー・ワイヤー",h:"らいやー・わいやー",e:"Lair Wire",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の墓地の昆虫族モンスター１体をゲームから除外し、相手フィールド上のモンスター１体を選択して発動できる。\n選択したモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"螺旋式発条",h:"らせんしきぜんまい",e:"Zenmailstrom",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の攻撃力１５００以上の「ゼンマイ」と名のついたモンスター１体をリリースして発動する。\n手札から「ゼンマイ」と名のついたモンスター１体を特殊召喚する。\nその後、この効果で特殊召喚したモンスターと同じ攻撃力を持つ「ゼンマイ」と名のついたモンスター１体をデッキから特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ラッキー・チャンス！",h:"らっきー・ちゃんす！",e:"Lucky Chance",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"コイントスを１回行う効果モンスターの効果が発動した時、コインの裏表を当てる。\n当たった場合、デッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ラッキーパンチ",h:"らっきーぱんち",e:"Lucky Punch",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"１ターンに１度、相手モンスターの攻撃宣言時に発動できる。\nコイントスを３回行い、３回とも表だった場合、自分はデッキからカードを３枚ドローする。\n３回とも裏だった場合、このカードを破壊する。\nまた、フィールド上に表側表示で存在するこのカードが破壊された場合、自分は６０００ライフポイントを失う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"リ・バウンド",h:"り・ばうんど",e:"Rebound",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"フィールド上のカードを手札に戻す効果を相手が発動した時に発動できる。\nその効果を無効にし、相手の手札・フィールド上からカードを１枚選んで墓地へ送る。\nまた、セットされたこのカードが相手によって破壊され墓地へ送られた時、デッキからカードを１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"炸裂装甲",h:"りあくてぃぶあーまー",e:"Sakuretsu Armor",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手モンスターの攻撃宣言時、攻撃モンスター１体を対象として発動できる。\nその攻撃モンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"契約洗浄",h:"りーす・ろんだりんぐ",e:"Contract Laundering",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分の魔法＆罠ゾーンの「契約書」カードを全て破壊する。\n破壊した数だけ自分はデッキからドローする。\nその後、自分はドローした数×１０００LP回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"リグレット・リボーン",h:"りぐれっと・りぼーん",e:"Regretful Rebirth",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上のモンスターが戦闘によって破壊され墓地へ送られた時に発動できる。\nそのモンスター１体を選択して自分フィールド上に表側守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターは自分のエンドフェイズ時に破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"リジェクト・リボーン",h:"りじぇくと・りぼーん",e:"Reject Reborn",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手モンスターの直接攻撃宣言時に発動できる。\nバトルフェイズを終了する。\nその後、自分の墓地からチューナーとSモンスターを１体ずつ選んで特殊召喚できる。\nこの効果で特殊召喚したモンスターの効果は無効化される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"真紅眼の鎧旋",h:"りたーん・おぶ・れっどあいず",e:"Return of the Red-Eyes",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"「真紅眼の鎧旋」の①②の効果はそれぞれ１ターンに１度しか使用できない。\n①：自分フィールドに「レッドアイズ」モンスターが存在する場合、自分の墓地の通常モンスター１体を対象としてこの効果を発動できる。\nそのモンスターを特殊召喚する。\n②：このカードが相手の効果で破壊され墓地へ送られた場合、自分の墓地の「レッドアイズ」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"リターンソウル",h:"りたーんそうる",e:"Return Soul",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"エンドフェイズ時に発動する事ができる。\nこのターンに破壊され墓地に存在するモンスターを３体まで持ち主のデッキに戻す事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"リチュアルバスター",h:"りちゅあるばすたー",e:"Ritual Buster",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分または相手が儀式召喚に成功した時に発動できる。\n次の自分ターンのスタンバイフェイズまで、相手は魔法・罠カードの発動及びその効果の発動ができない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"六花深々",h:"りっかしんしん",e:"Rikka Tranquility",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\nこのカードは自分フィールドの植物族モンスター１体をリリースして発動する事もできる。\n①：自分の墓地から「六花」モンスター１体を選んで守備表示で特殊召喚する。\nモンスターをリリースしてこのカードを発動した場合、さらに自分の墓地から植物族モンスター１体を選んで守備表示で特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"反転世界",h:"りばーさる・わーるど",e:"Inverse Universe",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"フィールド上の全ての効果モンスターの攻撃力・守備力を入れ替える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"リバース・リユース",h:"りばーす・りゆーす",e:"Reverse Reuse",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分の墓地のリバースモンスターを２体まで対象として発動できる。\nそのモンスターを表側守備表示または裏側守備表示で相手フィールドに特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"リバースダイス",h:"りばーすだいす",e:"Dice Re-Roll",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：このターン、サイコロを振る効果を適用する際に１度だけ、サイコロを振り直す事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"リバースディメンション",h:"りばーすでぃめんしょん",e:"Dimensional Inversion",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"元々の持ち主が自分であるモンスターが相手の効果によってゲームから除外された時に発動する事ができる。\nそのモンスター１体を自分フィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"リバイバル・ギフト",h:"りばいばる・ぎふと",e:"Revival Gift",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の墓地に存在するチューナー１体を選択し特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化される。\n相手フィールド上に「ギフト・デモン・トークン」（悪魔族・闇・星３・攻／守１５００）２体を特殊召喚する。",li:1,lk:[],ta:[],al:[],ocg:true},
  {n:"リフレクト・ネイチャー",h:"りふれくと・ねいちゃー",e:"Nature's Reflection",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このターン、相手が発動したライフポイントにダメージを与える効果は、相手ライフにダメージを与える効果になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"リボーン・パズル",h:"りぼーん・ぱずる",e:"Puzzle Reborn",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上のモンスター１体のみがカードの効果によって破壊された場合、その１体を選択して発動できる。\n選択したモンスターを自分フィールド上に特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"リミッター・ブレイク",h:"りみったー・ぶれいく",e:"Limiter Overload",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：このカードが墓地へ送られた場合に発動する。\n自分の手札・デッキ・墓地から「スピード・ウォリアー」１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"リミット・リバース",h:"りみっと・りばーす",e:"Limit Reverse",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分の墓地の攻撃力１０００以下のモンスター１体を選択し、表側攻撃表示で特殊召喚する。\nそのモンスターが守備表示になった時、そのモンスターとこのカードを破壊する。\nこのカードがフィールド上から離れた時、そのモンスターを破壊する。\nそのモンスターが破壊された時、このカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"竜魂の幻泉",h:"りゅうこんのげんせん",e:"Oasis of Dragon Souls",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：自分の墓地のモンスター１体を対象としてこのカードを発動できる。\nそのモンスターを守備表示で特殊召喚する。\nこのカードが魔法＆罠ゾーンに存在する限り、特殊召喚したそのモンスターの種族は幻竜族になる。\nこのカードがフィールドから離れた時にそのモンスターは破壊される。\nそのモンスターがフィールドから離れた時にこのカードは破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"竜魂の城",h:"りゅうこんのしろ",e:"Castle of Dragon Souls",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：「竜魂の城」は自分フィールドに１枚しか表側表示で存在できない。\n②：１ターンに１度、自分の墓地からドラゴン族モンスター１体を除外し、自分フィールドの表側表示モンスター１体を対象として発動できる。\nそのモンスターの攻撃力はターン終了時まで７００アップする。\n③：表側表示のこのカードがフィールドから墓地へ送られた時、除外されている自分のドラゴン族モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"硫酸のたまった落とし穴",h:"りゅうさんのたまったおとしあな",e:"Acid Trap Hole",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：フィールドの裏側守備表示モンスター１体を対象として発動できる。\nそのモンスターを表側守備表示にし、守備力が２０００以下の場合は破壊する。\n守備力が２０００より高い場合は裏側守備表示に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"竜の逆鱗",h:"りゅうのげきりん",e:"Dragon's Rage",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在する限り、自分フィールドのドラゴン族モンスターが守備表示モンスターを攻撃した場合、その守備力を攻撃力が超えた分だけ相手に戦闘ダメージを与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"竜の血族",h:"りゅうのけつぞく",e:"D. Tribe",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の全てのモンスターは、エンドフェイズ時までドラゴン族になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"竜の転生",h:"りゅうのてんせい",e:"Dragon's Rebirth",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分フィールドのドラゴン族モンスター１体を対象として発動できる。\nその自分のドラゴン族モンスターを除外し、自分の手札・墓地からドラゴン族モンスター１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"量子猫",h:"りょうしねこ",e:"Quantum Cat",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：種族と属性を１つずつ宣言してこのカードを発動できる。\nこのカードは発動後、宣言した種族・属性を持つ通常モンスター（星４・攻０／守２２００）となり、モンスターゾーンに特殊召喚する。\nこのカードは罠カードとしても扱う。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"竜嵐還帰",h:"りょうらんかんき",e:"Storm Dragon's Return",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：除外されている自分または相手のモンスター１体を対象として発動できる。\nそのモンスターを自分フィールドに特殊召喚する。\nこの効果で特殊召喚したモンスターはエンドフェイズに持ち主の手札に戻る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"リリース・リバース・バースト",h:"りりーす・りばーす・ばーすと",e:"Release, Reverse, Burst",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の「希望皇ホープ」と名のついたモンスター１体をリリースして発動できる。\n相手フィールド上にセットされた魔法・罠カードを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"輪廻のパーシアス",h:"りんねのぱーしあす",e:"Rebirth of Parshath",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"①：モンスターの効果・魔法・罠カードが発動した時、手札のカウンター罠カード１枚を相手に見せ、手札を１枚捨て、１０００LPを払って発動できる。\nその発動を無効にし、そのカードを持ち主のデッキに戻す。\nその後、デッキ・EXデッキから「パーシアス」モンスター１体を選んで特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レアメタル化・魔法反射装甲",h:"れあめたるか・まほうはんしゃそうこう",e:"Rare Metalmorph",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"フィールド上の機械族モンスター１体を選択して発動できる。\nこのカードがフィールド上に存在する限り、その機械族モンスターの攻撃力は５００ポイントアップし、そのモンスターを対象にする魔法カードの効果を１度だけ無効にする。\n選択したモンスターがフィールド上から離れた時、このカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"霊獣の騎襲",h:"れいじゅうのきしゅう",e:"Ritual Beast Ambush",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"「霊獣の騎襲」は１ターンに１枚しか発動できない。\n①：自分の墓地のモンスター及び除外されている自分のモンスターの中から、「霊獣使い」モンスターと「精霊獣」モンスターをそれぞれ１体ずつ対象として発動できる。\n対象のモンスターを守備表示で特殊召喚する。\nこのカードの発動後、ターン終了時まで自分は「霊獣」モンスターしか特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"隷属の鱗粉",h:"れいぞくのりんぷん",e:"Butterflyoke",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手モンスターの攻撃宣言時に発動できる。\n攻撃モンスターの表示形式を守備表示にし、そのモンスター１体にこのカードを装備する。\nまた、１ターンに１度、メインフェイズ及びバトルフェイズ中に発動できる。\n装備モンスターの表示形式を変更する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"暴風雨",h:"れいんすとーむ",e:"Rain Storm",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在する「雲魔物」と名のついたモンスター１体を選択して発動する。\n選択したモンスターの攻撃力を下げる事で、以下の効果を適用する。\n\n●１０００ポイント下げる事で、相手フィールド上の魔法または罠カード１枚を破壊する。\n\n●２０００ポイント下げる事で、相手フィールド上のカード２枚を破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レインボー・ライフ",h:"れいんぼー・らいふ",e:"Rainbow Life",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"手札を１枚捨てて発動できる。\nこのターンのエンドフェイズ時まで、自分は戦闘及びカードの効果によってダメージを受ける代わりに、その数値分だけライフポイントを回復する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レクリスパワー",h:"れくりすぱわー",e:"Reckoned Power",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"手札にある「コアキメイルの鋼核」１枚を相手に見せて発動する。\n相手フィールド上にセットされた魔法・罠カードを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レッド・スプレマシー",h:"れっど・すぷれましー",e:"Red Supremacy",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分の墓地の「レッド・デーモン」Sモンスター１体を除外し、自分フィールドの「レッド・デーモン」Sモンスター１体を対象として発動できる。\nそのモンスターは、このカードを発動するために除外した「レッド・デーモン」Sモンスターと同名カードとして扱い、同じ効果になる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レッド・ロイド・コール",h:"れっど・ろいど・こーる",e:"Emergeroid Call",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"このカード名の②の効果は１ターンに１度しか使用できない。\n①：自分フィールドに「ロイド」融合モンスターが存在し、モンスターの効果・魔法・罠カードが発動した時に発動できる。\nその発動を無効にする。\nその後、発動したそのプレイヤーのデッキ・EXデッキから同名カードを全て墓地へ送る。\n②：墓地のこのカードを除外し、自分の墓地の「ロイド」モンスター１体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レッドアイズ・スピリッツ",h:"れっどあいず・すぴりっつ",e:"Red-Eyes Spirit",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分の墓地の「レッドアイズ」モンスター１体を対象として発動できる。\nそのモンスターを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レベル・リチューナー",h:"れべる・りちゅーなー",e:"Level Retuner",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在するモンスター１体のレベルを２つまで下げる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"レベル変換実験室",h:"れべるへんかんじっけんしつ",e:"Level Conversion Lab",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の手札のモンスターカードを１枚選択して相手に見せ、サイコロを１回振る。\n１の目が出た場合、選択したモンスターを墓地へ送る。\n２～６の目が出た場合、このターンのエンドフェイズ時までこのモンスターは出た目のレベルになる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"連撃の帝王",h:"れんげきのていおう",e:"Escalation of the Monarchs",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：１ターンに１度、相手のメインフェイズ及びバトルフェイズにこの効果を発動できる。\nモンスター１体をアドバンス召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"煉獄の落とし穴",h:"れんごくのおとしあな",e:"Void Trap Hole",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手が攻撃力２０００以上のモンスターを特殊召喚した時に発動できる。\nその攻撃力２０００以上のモンスター１体を選び、効果を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"連鎖旋風",h:"れんさせんぷう",e:"Chain Whirlwind",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"魔法・罠・効果モンスターの効果によってフィールド上に存在するカードが破壊された時、フィールド上に存在する魔法・罠カード２枚を選択して発動する事ができる。\n選択したカードを破壊する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"老化の呪い",h:"ろうかののろい",e:"Curse of Aging",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"手札からカードを１枚捨てる。\nターン終了時まで相手フィールド上の全モンスターの攻撃力・守備力は５００ポイントダウンする。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ローゲの焔",h:"ろーげのほのお",e:"Loge's Flame",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：このカードが魔法＆罠ゾーンに存在し、自分フィールドに「ワルキューレ」モンスターが存在する限り、攻撃力２０００以下の相手モンスターは攻撃できない。\n②：このカードが相手の効果で破壊された場合に発動できる。\n手札・デッキからレベル５以上の「ワルキューレ」モンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"六武式風雷斬",h:"ろくぶしきふうらいざん",e:"Six Strike - Thunder Blast",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在する武士道カウンターを１つ取り除き、以下の効果から１つを選択して発動する。\n\n●相手フィールド上に存在するモンスター１体を選択して破壊する。\n\n●相手フィールド上に存在するカード１枚を選択して持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"六武衆推参！",h:"ろくぶしゅうすいさん！",e:"Return of the Six Samurai",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の墓地の「六武衆」と名のついたモンスター１体を選択して発動できる。\n選択したモンスターを墓地から特殊召喚する。\nこの効果で特殊召喚したモンスターはこのターンのエンドフェイズ時に破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"六武派二刀流",h:"ろくぶはにとうりゅう",e:"Six Style - Dual Wield",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在するモンスターが、表側攻撃表示で存在する「六武衆」と名のついたモンスター１体のみの場合に発動する事ができる。\n相手フィールド上に存在するカード２枚を選択して持ち主の手札に戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ロケットハンド",h:"ろけっとはんど",e:"Rocket Hand",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名の①②の効果は１ターンに１度、いずれか１つしか使用できない。\n①：自分フィールドの攻撃力８００以上の攻撃表示モンスター１体を対象としてこのカードを発動できる。\nこのカードを攻撃力８００アップの装備カード扱いとしてそのモンスターに装備する。\n②：装備されているこのカードを墓地へ送り、フィールドの表側表示のカード１枚を対象として発動できる。\nそのカードを破壊する。\nその後、このカードを装備していたモンスターは攻撃力が０になり、表示形式を変更できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ロスト",h:"ろすと",e:"Disappear",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手の墓地のカード１枚をゲームから除外する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ロスト・スター・ディセント",h:"ろすと・すたー・でぃせんと",e:"Descending Lost Star",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分の墓地のSモンスター１体を対象として発動できる。\nそのモンスターのレベルを１つ下げ、守備力を０にして守備表示で特殊召喚する。\nこの効果で特殊召喚したモンスターの効果は無効化され、表示形式を変更できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ロスト・ネクスト",h:"ろすと・ねくすと",e:"Next to be Lost",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在するモンスター１体を選択する。\n自分のデッキから選択したカードと同名カード１枚を墓地へ送る。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ワンダーガレージ",h:"わんだーがれーじ",e:"Wonder Garage",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"セットされたこのカードが破壊され墓地へ送られた時、手札からレベル４以下の「ロイド」と名のついた機械族モンスター１体を特殊召喚する事ができる。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ザ・スピリチアル・ロード",h:"ざ・すぴりちある・ろーど",e:"The Spiritual Lord",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分の墓地にレベル５・６の「アルカナフォース」と名のついたモンスターが存在する場合にこのカードを発動できる。\n手札からレベル７以上の「アルカナフォース」と名のついたモンスター１体を墓地へ送って発動できる。\nデッキから「ザ・ヘブンズ・ロード」１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"ザ・マテリアル・ロード",h:"ざ・まてりある・ろーど",e:"The Material Lord",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分の墓地にレベル４以下の「アルカナフォース」と名のついたモンスターが存在する場合にこのカードを発動できる。\n手札からレベル５・６の「アルカナフォース」と名のついたモンスター１体を墓地へ送って発動できる。\nデッキから「ザ・スピリチアル・ロード」１枚を手札に加える。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"シンクロ・スピリッツ",h:"しんくろ・すぴりっつ",e:"Synchro Spirits",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の墓地のシンクロモンスター１体を選択してゲームから除外する。\nその後、除外したモンスターのシンクロ召喚に使用したシンクロ素材モンスター一組が自分の墓地に揃っていれば、その一組を自分フィールド上に特殊召喚できる。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"縛られし神への祭壇",h:"しばられしかみへのさいだん",e:"Alter of the Bound Deity",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分のスタンバイフェイズ毎に発動する。\nフィールド上の表側守備表示モンスターの数だけ、このカードに地縛神カウンターを置く。\n地縛神カウンターが４つ以上置かれているこのカードを墓地へ送って発動できる。\nデッキから「地縛神」と名のついたモンスター１体を特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"機動要塞 メタル・ホールド",h:"きどうようさい　めたる・ほーるど",e:"Metalhold the Moving Blockade",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：自分フィールドの機械族・レベル４モンスターを任意の数だけ対象として発動できる。\nこのカードは発動後、効果モンスター（機械族・地・星４・攻／守０）となり、モンスターゾーンに特殊召喚する。\nその後、対象の表側表示モンスターを装備カード扱いとしてこのカードに装備する。\nこのカードは罠カードとしても扱う。\n②：このカードの効果で特殊召喚されたこのカードの攻撃力は、このカードの効果で装備したモンスターの攻撃力の合計分アップし、相手は他の自分フィールドのモンスターを攻撃対象にできず、効果の対象にもできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ディフェンス・メイデン",h:"でぃふぇんす・めいでん",e:"Defense Maiden",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"相手モンスターが自分のモンスターに攻撃宣言した時、自分フィールド上の「恋する乙女」１体を選択して発動できる。\n攻撃対象を選択した「恋する乙女」に移し替える。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"ハイ・アンド・ロー",h:"はい・あんど・ろー",e:"High and Low",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の表側表示モンスターが攻撃対象に選択された時に発動できる。\nその表側表示の攻撃対象モンスターが存在する限り、以下の効果を３回まで適用できる。\n●自分のデッキの一番上のカードを墓地へと送る。\nそれがモンスターだった場合、ターン終了時まで攻撃対象モンスターの攻撃力を墓地へ送られたモンスターの攻撃力分アップし、攻撃対象モンスターの攻撃力が攻撃モンスターの攻撃力より高い場合、攻撃対象モンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"次元均衡",h:"じげんきんこう",e:"Dimension Equilibrium",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の獣族モンスターが相手モンスターの攻撃によって破壊され墓地へ送られた時、そのモンスター１体を選択して発動できる。\nそのモンスターを特殊召喚し、攻撃モンスターをゲームから除外する。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"ギャラルホルン",h:"ぎゃらるほるん",e:"Gjallarhorn",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードは墓地でしか発動できない。\nこのカードが墓地に存在する場合、自分フィールド上の「極神」と名のついたモンスター１体を選択して発動できる。\nこのターン、その表側表示モンスターはこのカード以外の魔法・罠カードの効果を受けない。\n発動後このカードを自分の魔法＆罠ゾーンに表側表示で置く。\nまた、３回目の自分のエンドフェイズ時に自分フィールドの全てのモンスターの攻撃力の合計分のダメージを相手ライフに与え、このカードと自分フィールド上のモンスターを全てゲームから除外する。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"宝玉割断",h:"ほうぎょくかつだん",e:"Crystal Slicer",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"デッキから「宝玉獣」と名のついたモンスター１体を墓地へ送り、相手フィールド上の表側表示モンスター１体を選択して発動できる。\n選択した相手モンスターの元々の攻撃力を半分にする。\n相手はデッキから１枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"シンクロ・バック",h:"しんくろ・ばっく",e:"Synchro Back",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上のシンクロモンスター１体を選択して持ち主のエクストラデッキに戻す。\nエクストラデッキに戻したモンスターと同名のシンクロモンスター１体を、次の自分のターンのスタンバイフェイズ時にエクストラデッキから特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"バイ＝マーセの癇癪",h:"ばいまーせのかんしゃく",e:"Passion of Baimasse",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"カウンター罠",at:"",de:"",tx:"自分フィールド上に「地縛神」と名のついたモンスターが存在し、相手がモンスターの効果を発動した時に発動できる。\nその発動を無効にし破壊する。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"チェーン・クローズ",h:"ちぇーん・くろーず",e:"Chain Close",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"フィールド上のカードが戦闘またはカードの効果によって破壊され墓地へ送られた時に発動できる。\nこのターン相手は魔法・罠カードを発動できない。\nこのカードの発動に対して魔法・罠・モンスターの効果を発動できない。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"穢された大地",h:"けがされただいち",e:"Contaminated Earth",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上のレベル５以上の表側表示モンスターがカードの効果によって破壊されたターンに発動できる。\n自分の手札・デッキ・墓地から「地縛神」と名のついたモンスター１体を選んで特殊召喚する。\nこの効果で特殊召喚したモンスターは、このターン攻撃できず効果の発動もできない。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"プライド・シャウト",h:"ぷらいど・しゃうと",e:"Pride Shout",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"このカードがフィールド上に存在し、攻撃力０の自分のモンスターが直接攻撃を行ったダメージステップ終了時に発動する。\nその自分のモンスターの守備力分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"ゼロ・スプライト",h:"ぜろ・すぷらいと",e:"Zero Sprite",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の表側表示モンスター１体を選択してこのカードを発動できる。\nこのカードを装備カード扱いとして、選択した自分のモンスターに装備する。\n装備モンスターの元々の攻撃力は０になる。\n装備モンスターは１度のバトルフェイズ中に２回攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"分岐－ディヴァジェンス",h:"ぶんき－でぃう゛ぁじぇんす",e:"Divergence",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"自分の機械族モンスターを攻撃対象とした相手モンスターの攻撃宣言時、自分フィールド上の他の機械族モンスター１体を選択してこの効果を発動できる。\n攻撃対象をその機械族モンスターに移し替える。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"スパーク・ブレイカー",h:"すぱーく・ぶれいかー",e:"Spark Breaker",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"１ターンに１度、自分フィールド上のモンスター１体を選択して発動できる。\n選択した自分のモンスターを破壊する。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"クラインの迷宮",h:"くらいんのめいきゅう",e:"Labyrinth of Kline",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手モンスターの攻撃宣言時、そのモンスター１体を選択して発動できる。\nその攻撃を無効にし、次のターン終了時まで、そのモンスターの元々の攻撃力・守備力を入れ替える。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"ダブル・タイプ・レスキュー",h:"だぶる・たいぷ・れすきゅー",e:"Double Type Rescue",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上のモンスターの種族が２種類以上で、相手フィールド上のモンスターの数が自分フィールド上のモンスターより多い場合に発動できる。\n自分の墓地からモンスター１体を選択して特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"Sin Force",h:"しんふぉーす",e:"Malefic Force",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の「Sin」と名のついたモンスター１体を選択して発動できる。\n選択したモンスターはフィールド上に表側表示で存在する限り、相手の魔法カードの効果を受けない。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"神事の獣葬",h:"しんじのじゅうそう",e:"Beast Burial Ritual",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の獣族モンスター１体を選択して破壊し、次のスタンバイフェイズ時に自分はデッキからカードを２枚ドローする。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"ツイン・ボルテックス",h:"ついん・ぼるてっくす",e:"Twin Vortex",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上の機械族モンスター１体と相手フィールド上のモンスター１体を選択して破壊する。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"猫に小判",h:"ねこにこばん",e:"Gold Coins for Cats",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分の獣族モンスターが相手モンスターと戦闘を行うダメージ計算時に発動できる。\nその自分のモンスターの攻撃力はターン終了時まで戦闘を行う相手モンスターの攻撃力の半分だけアップする。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"バトル・リターン",h:"ばとる・りたーん",e:"Battle Return",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分のバトルフェイズ中、自分のフィールド上の「機皇帝」と名のついたモンスター１体を選択して発動できる。\n選択したモンスターの攻撃力はターン終了時まで半分になり、このターン１度のバトルフェイズ中に２回攻撃できる。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"インフィニティ・フォース",h:"いんふぃにてぃー・ふぉーす",e:"Infinity Force",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に「機皇帝」と名のついたモンスターが存在し、自分が戦闘・効果でダメージを受けた時に発動できる。\n相手フィールド上のモンスターを全て破壊する。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"アームズ・コール",h:"あーむず・こーる",e:"Armory Call",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：デッキから装備魔法カード１枚を手札に加える。\nその後、そのカードを装備可能な自分フィールドのモンスター１体に装備できる。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"竜星の凶暴化",h:"りゅうせいのきょうぼうか",e:"Yang Zing Brutality",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分の「竜星」モンスターが相手モンスターと戦闘を行うダメージ計算時に発動できる。\nその自分のモンスターの攻撃力・守備力はそのダメージ計算時のみ元々の数値の倍になり、ダメージステップ終了時に破壊される。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"マジェスペクター・ガスト",h:"まじぇすぺくたー・がすと",e:"Majespecter Gust",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分のPゾーンの「マジェスペクター」カード１枚を対象として発動できる。\nそのカードを特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ペンデュラム・バック",h:"ぺんでゅらむ・ばっく",e:"Pendulum Back",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分のPゾーンにカードが２枚存在する場合、そのPスケールでP召喚可能なレベルを持つ、自分の墓地のモンスター２体を対象として発動できる。\nそのモンスターを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヘル・ブラスト",h:"へる・ぶらすと",e:"Chthonian Blast",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に表側表示で存在するモンスターが破壊され墓地へ送られた時に発動する事ができる。\nフィールド上の攻撃力が一番低い表側表示モンスター１体を破壊し、お互いにその攻撃力の半分のダメージを受ける。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヘイト・クレバス",h:"へいと・くればす",e:"Icy Crevasse",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分フィールド上に存在するモンスター１体が相手のカードの効果によって破壊され墓地へ送られた時、相手フィールド上に存在するモンスター１体を選択して墓地へ送り、その元々の攻撃力分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ブリリアント・スパーク",h:"ぶりりあんと・すぱーく",e:"Brilliant Spark",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"「ブリリアント・スパーク」は１ターンに１枚しか発動できない。\n①：自分フィールドの「ジェムナイト」モンスターが相手モンスターの攻撃または相手の効果で破壊された場合、破壊されたそのモンスター１体を対象として発動できる。\nそのモンスターの元々の攻撃力分のダメージを相手に与える。\n②：このカードが墓地に存在する場合、手札の「ジェムナイト」カード１枚を墓地へ送って発動できる。\nこのカードを手札に加える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"波動障壁",h:"はどうしょうへき",e:"Barrier Wave",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"相手モンスターの攻撃宣言時、自分フィールド上に存在するシンクロモンスター１体をリリースして発動する。\n相手フィールド上に攻撃表示で存在するモンスターを全て守備表示にする。\nその後、攻撃宣言をしたモンスターの守備力分のダメージを相手ライフに与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"エクシーズ・ディメンション・スプラッシュ",h:"えくしーず・でぃめんしょん・すぷらっしゅ",e:"Xyz Dimension Splash",ra:"N",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"セットされたこのカードがゲームから除外された場合、デッキから水属性・レベル８モンスター２体を特殊召喚できる。\nこの効果で特殊召喚したモンスターは攻撃宣言できず、効果は無効化され、リリースする事もできない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ヘイト・バスター",h:"へいと・ばすたー",e:"Hate Buster",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分の悪魔族モンスターが攻撃対象に選択された時、攻撃モンスター１体とその攻撃対象モンスター１体を対象として発動できる。\nその攻撃モンスターとその攻撃対象モンスターの２体を破壊し、破壊した攻撃モンスターの元々の攻撃力分のダメージを相手に与える。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"魂の一撃",h:"たましいのいちげき",e:"Soul Strike",ra:"R",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"自分のライフポイントが４０００以下の場合、自分フィールド上のモンスターが相手モンスターと戦闘を行う攻撃宣言時にライフポイントを半分払い、自分フィールド上のモンスター１体を選択して発動できる。\n選択したモンスターの攻撃力は相手のエンドフェイズ時まで、自分のライフポイントが４０００より下回っている数値分アップする。\n「魂の一撃」は１ターンに１枚しか発動できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"竜星の具象化",h:"りゅうせいのぐしょうか",e:"Yang Zing Creation",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"永続罠",at:"",de:"",tx:"①：１ターンに１度、自分フィールドのモンスターが戦闘・効果で破壊された場合にこの効果を発動できる。\nデッキから「竜星」モンスター１体を特殊召喚する。\n②：このカードが魔法＆罠ゾーンに存在する限り、自分はSモンスター以外のモンスターをエクストラデッキから特殊召喚できない。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"破壊剣の追憶",h:"はかいけんのついおく",e:"Destruction Sword Memories",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：手札から「破壊剣」カード１枚を捨てて発動できる。\nデッキから「バスター・ブレイダー」モンスター１体を特殊召喚する。\n②：墓地のこのカードを除外して発動できる。\n「竜破壊の剣士－バスター・ブレイダー」によって決められた融合素材モンスターを自分の墓地から除外し、その融合モンスター１体をエクストラデッキから融合召喚する。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"強制退出装置",h:"きょうせいたいしゅつそうち",e:"Compulsory Escape Device",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"お互いはそれぞれ自分フィールド上のモンスター１体を選び、そのモンスターを持ち主のデッキに戻す。",li:"",lk:[],ta:[],al:[],ocg:true},
  {n:"ペンデュラム・リボーン",h:"ぺんでゅらむ・りぼーん",e:"Pendulum Reborn",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：自分のエクストラデッキの表側表示のペンデュラムモンスターまたは自分の墓地のペンデュラムモンスター１体を選んで特殊召喚する。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"対峙する宿命",h:"たいじするしゅくめい",e:"Destined Rivals",ra:"SR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"このカード名のカードは１ターンに１枚しか発動できない。\n①：自分フィールドに「青眼の白龍」または「ブラック・マジシャン」が存在する場合に発動できる。\n相手フィールドの全ての表側表示モンスターの効果はターン終了時まで無効化される。",li:"",lk:[],ta:[],al:[],ocg:false},
  {n:"奈落の落とし穴",h:"ならくのおとしあな",e:"Bottomless Trap Hole",ra:"UR",t:"trap",a:"",l:"",ps:"",pe:"",tr:"",mo:[],ma:"",tp:"通常罠",at:"",de:"",tx:"①：相手が攻撃力１５００以上のモンスターを召喚・反転召喚・特殊召喚した時に発動できる。\nその攻撃力１５００以上のモンスターを破壊し除外する。",li:"",lk:[],ta:[],al:[],ocg:false},
];

export default datas;