import { useEffect, useState } from 'react';
import {
  Accordion,
  Badge,
  Button,
  Card,
  Col,
  Container,
  Form,
  FormControl,
  InputGroup,
  Row,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBolt,
  faBomb,
  faDragon,
  faExclamationTriangle,
  faFilter,
  faGem,
  faGhost,
  faSearch,
  faSpinner,
  faStar,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';

import './App.css';
import BorderLayout from './components/BorderLayout';
import LinkTriangles from './components/LinkTriangles';
import datas from './datas';

const Bottom = () => (
  <div className="pt-3 d-flex align-items-center justify-content-center" style={{ color: 'gray', fontSize: 12, backgroundColor: '#1d6a9620' }}>
    <div className="d-flex flex-column align-items-center justify-content-center me-3 pe-3 border-secondary border-end">
      <a href="https://neko-note.org/">「それなら猫の手で」</a>
      <div className="mb-1">Copyright © 2021-2022</div>
      <small>
        <div className="text-center mb-1">
          <a href="https://neko-note.org/privacy-policy">免責事項</a>
        </div>
      </small>
    </div>
    <div>
      最終更新日：2022/01/02
      <div>
        <a href="https://yugiohdb-cards.neko-note.org/">遊戯王OCG カード検索</a>
      </div>
    </div>
  </div>
);

const Search = (props) => {
  const {
    keyword,
    setKeyword,
  } = props;

  const [text, setText] = useState(keyword);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setLoading(true);
  };

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setKeyword(text);
        setLoading(false);
      }, 500)
    }
  }, [loading])

  return (
    <>
      <Form noValidate onSubmit={handleSubmit}>
        <InputGroup className="mb-3">
          <FormControl
            placeholder="検索 : キーワード検索"
            aria-label="keyword"
            aria-describedby="keyword-addon"
            style={{
              borderTopLeftRadius: '50rem',
              borderBottomLeftRadius: '50rem',
            }}
            defaultValue={keyword}
            onChange={(x) => setText(x.target.value)}
          />
          <Button
            type="submit"
            className="pe-3"
            variant="secondary"
            id="keyword-addon"
            style={{
              borderTopRightRadius: '50rem',
              borderBottomRightRadius: '50rem',
            }}
          >
            <FontAwesomeIcon icon={loading ? faSpinner : faSearch} className={loading ? 'fa-spin' : ''} />
          </Button>
        </InputGroup>
      </Form>
    </>
  );
}

const rareToBackground = {
  "N": 'rgb(160,180,185)',
  "R": 'linear-gradient(120deg, rgb(59, 170, 251), rgb(24, 54, 186))',
  "SR": 'linear-gradient(120deg, rgb(243, 234, 95), rgb(236, 185, 78))',
  "UR": 'linear-gradient(120deg, rgb(200, 150, 255), rgb(65, 150, 127))',
};

const attrToColorCode = {
  "光": "#827717",
  "闇": "#1A237E",
  "炎": "#E53935",
  "水": "#2979FF",
  "風": "#00838F",
  "地": "#4E342E",
  "神": "#212121",
  "魔": "#1D2048",
  "罠": "#212529",
}
const attrToName = {
  "光": "光",
  "闇": "闇",
  "炎": "炎",
  "水": "水",
  "風": "風",
  "地": "地",
  "神": "神",
  "魔": "魔",
  "罠": "罠",
}

const Attr = (props) => {
  const {
    card,
  } = props;

  const typeTokey = {
    "monster": card.a,
    "magic": "魔",
    "trap": "罠",
  };

  const key = typeTokey[card.t];

  return (
    <div className="d-flex align-items-center justify-content-center">
      <div
        className="position-relative text-light ps-4 pe-2 "
        style={{ fontSize: 16, backgroundColor: `${attrToColorCode[key]}40` }}
      >
        <div style={{
          position: "absolute",
          top: 0,
          left: 0,
          borderLeft: `24px solid #ffffff`,
          borderBottom: "24px solid transparent",
        }}></div>
        <div className="ps-2 pe-1">
          {attrToName[key]}
        </div>
        <div style={{
          position: "absolute",
          top: 0,
          right: -24,
          borderLeft: `24px solid ${attrToColorCode[key]}40`,
          borderBottom: "24px solid transparent",
        }}></div>
      </div>
    </div>
  );
}

const Filter = (props) => {
  const {
    items,
    state,
    setState,
  } = props;

  return (
    <div className="d-flex flex-wrap" style={{ fontSize: 12 }}>
      {
        items.map((x, i) => (
          <div key={x.name} className="p-1">
            <Button
              size="sm"
              variant={(state && state[i]) ? "primary" : "outline-primary"}
              style={{
                opacity: 0.96,
                color: (state && state[i]) ? "white" : "rgb(29, 32, 72)",
                backgroundColor: (state && state[i]) ? "rgb(29, 32, 72)" : "transparent",
              }}
              onClick={() => {
                const a = [...state];
                a[i] = !state[i];
                setState(a);
              }} >
              {x.name}
            </Button>
          </div>
        ))
      }
    </div>
  );
};

const tagEquals = [
  (x) => x.ta && x.ta.some(xx => xx === 'サーチ'),
  (x) => x.ta && x.ta.some(xx => xx === 'リクルート'),
];

const typeEquals = [
  (x) => x.t === 'monster',
  (x) => x.t === 'magic',
  (x) => x.t === 'trap',
];

const attrEquals = [
  (x) => x.a === '炎',
  (x) => x.a === '水',
  (x) => x.a === '風',
  (x) => x.a === '地',
  (x) => x.a === '光',
  (x) => x.a === '闇',
  (x) => x.a === '神',
];

const monsterEquals = [
  (x) => x.mo.some(xx => xx === '通常'),
  (x) => x.mo.some(xx => xx === '効果'),
  (x) => x.mo.some(xx => xx === '儀式'),
  (x) => x.mo.some(xx => xx === '融合'),
  (x) => x.mo.some(xx => xx === 'シンクロ'),
  (x) => x.mo.some(xx => xx === 'エクシーズ'),
  (x) => x.mo.some(xx => xx === 'トゥーン'),
  (x) => x.mo.some(xx => xx === 'スピリット'),
  (x) => x.mo.some(xx => xx === 'ユニオン'),
  (x) => x.mo.some(xx => xx === 'デュアル'),
  (x) => x.mo.some(xx => xx === 'チューナー'),
  (x) => x.mo.some(xx => xx === 'リバース'),
  (x) => x.mo.some(xx => xx === 'ペンデュラム'),
  (x) => x.mo.some(xx => xx === '特殊召喚'),
  (x) => x.mo.some(xx => xx === 'リンク'),
];

const magicEquals = [
  (x) => x.ma === '通常魔法',
  (x) => x.ma === 'フィールド魔法',
  (x) => x.ma === '装備魔法',
  (x) => x.ma === '永続魔法',
  (x) => x.ma === '速攻魔法',
  (x) => x.ma === '儀式魔法',
];

const trapEquals = [
  (x) => x.tp === '通常罠',
  (x) => x.tp === 'カウンター罠',
  (x) => x.tp === '永続罠',
];

const monsterTypeEquals = [
  (x) => x.tr === '魔法使い族',
  (x) => x.tr === 'ドラゴン族',
  (x) => x.tr === 'アンデット族',
  (x) => x.tr === '戦士族',
  (x) => x.tr === '獣戦士族',
  (x) => x.tr === '獣族',
  (x) => x.tr === '鳥獣族',
  (x) => x.tr === '悪魔族',
  (x) => x.tr === '天使族',
  (x) => x.tr === '昆虫族',
  (x) => x.tr === '恐竜族',
  (x) => x.tr === '爬虫類族',
  (x) => x.tr === '魚族',
  (x) => x.tr === '海竜族',
  (x) => x.tr === '水族',
  (x) => x.tr === '炎族',
  (x) => x.tr === '雷族',
  (x) => x.tr === '岩石族',
  (x) => x.tr === '植物族',
  (x) => x.tr === '機械族',
  (x) => x.tr === 'サイキック族',
  (x) => x.tr === '幻神獣族',
  // (x) => x.tr === '創造神族',
  (x) => x.tr === '幻竜族',
  (x) => x.tr === 'サイバース族',
];

const levelEquals = [
  (x) => x.l === 1,
  (x) => x.l === 2,
  (x) => x.l === 3,
  (x) => x.l === 4,
  (x) => x.l === 5,
  (x) => x.l === 6,
  (x) => x.l === 7,
  (x) => x.l === 8,
  (x) => x.l === 9,
  (x) => x.l === 10,
  (x) => x.l === 11,
  (x) => x.l === 12,
];

const pendulumScaleEquals = [
  (x) => x.ps === 1,
  (x) => x.ps === 2,
  (x) => x.ps === 3,
  (x) => x.ps === 4,
  (x) => x.ps === 5,
  (x) => x.ps === 6,
  (x) => x.ps === 7,
  (x) => x.ps === 8,
  (x) => x.ps === 9,
  (x) => x.ps === 10,
  (x) => x.ps === 11,
  (x) => x.ps === 12,
];

const rareEquals = [
  (x) => x.ra === "UR",
  (x) => x.ra === "SR",
  (x) => x.ra === "R",
  (x) => x.ra === "N",
];

const limitEquals = [
  (x) => x.li === 0,
  (x) => x.li === 1,
  (x) => x.li === 2,
  (x) => x.li === 3,
  (x) => x.li === "",
];

const items = datas;

function App() {

  const [keyword, setKeyword] = useState('');

  const [filterTagsState, setFilterTagsState] = useState([...Array(2)].map(() => false));
  const [isAndFilterTags, setIsAndFilterTags] = useState(true);

  const [filterTypesState, setFilterTypesState] = useState([...Array(3)].map(() => false));
  const [filterAttrsState, setFilterAttrsState] = useState([...Array(7)].map(() => false));
  const [filterMonstersState, setFilterMonstersState] = useState([...Array(15)].map(() => false));
  const [isAndFilterMonsters, setIsAndFilterMonsters] = useState(true);

  const [filterMagicsState, setFilterMagicsState] = useState([...Array(6)].map(() => false));
  const [filterTrapsState, setFilterTrapsState] = useState([...Array(3)].map(() => false));
  const [filterLevelsState, setFilterLevelsState] = useState([...Array(12)].map(() => false));
  const [filterPendulumScalesState, setFilterPendulumScalesState] = useState([...Array(12)].map(() => false));
  const [filterMonsterTypesState, setFilterMonsterTypesState] = useState([...Array(25)].map(() => false));
  const [filterRaresState, setFilterRaresState] = useState([...Array(4)].map(() => false));
  const [filterLimitsState, setFilterLimitsState] = useState([...Array(4)].map(() => false));
  // const [filterEffectsState, setFilterEffectsState] = useState([...Array(24)].map(() => false));

  const keywords = keyword.split(/\s/).filter(x => x !== '');
  const displayItem = items
    .filter(x => keywords.every(k => Object.values(x).some(e => ("" + e).indexOf(k) !== -1)))
    .filter(x => {
      if (filterTypesState.every(k => k === false)) {
        return true;
      }
      return typeEquals.filter((fx, i) => filterTypesState[i]).some(fx => fx(x) === true);
    })
    .filter(x => {
      if (filterAttrsState.every(k => k === false)) {
        return true;
      }
      return attrEquals.filter((fx, i) => filterAttrsState[i]).some(fx => fx(x) === true);
    })
    .filter(x => {
      if (filterMonstersState.every(k => k === false)) {
        return true;
      }
      return monsterEquals.filter((fx, i) => filterMonstersState[i])[isAndFilterMonsters ? "every" : "some"](fx => fx(x) === true);
    })
    .filter(x => {
      if (filterMagicsState.every(k => k === false)) {
        return true;
      }
      return magicEquals.filter((fx, i) => filterMagicsState[i]).some(fx => fx(x) === true);
    })
    .filter(x => {
      if (filterTrapsState.every(k => k === false)) {
        return true;
      }
      return trapEquals.filter((fx, i) => filterTrapsState[i]).some(fx => fx(x) === true);
    })
    .filter(x => {
      if (filterMonsterTypesState.every(k => k === false)) {
        return true;
      }
      return monsterTypeEquals.filter((fx, i) => filterMonsterTypesState[i]).some(fx => fx(x) === true);
    })
    .filter(x => {
      if (filterLevelsState.every(k => k === false)) {
        return true;
      }
      return levelEquals.filter((fx, i) => filterLevelsState[i]).some(fx => fx(x) === true);
    })
    .filter(x => {
      if (filterPendulumScalesState.every(k => k === false)) {
        return true;
      }
      return pendulumScaleEquals.filter((fx, i) => filterPendulumScalesState[i]).some(fx => fx(x) === true);
    })
    .filter(x => {
      if (filterRaresState.every(k => k === false)) {
        return true;
      }
      return rareEquals.filter((fx, i) => filterRaresState[i]).some(fx => fx(x) === true);
    })
    .filter(x => {
      if (filterLimitsState.every(k => k === false)) {
        return true;
      }
      return limitEquals.filter((fx, i) => filterLimitsState[i]).some(fx => fx(x) === true);
    })
    .filter(x => {
      if (filterTagsState.every(k => k === false)) {
        return true;
      }

      return tagEquals.filter((fx, i) => filterTagsState[i])[isAndFilterTags ? "every" : "some"](fx => fx(x) === true);
    })
    .sort((x1, x2) => {
      if (x1.h > x2.h) {
        return 1;
      }
      if (x1.h < x2.h) {
        return -1;
      }
      return 0
    });

  const filterTags = [
    { id: 'tag1', name: 'サーチ' },
    { id: 'tag2', name: 'リクルート' },
  ];

  const filterTypes = [
    { id: 'monster', name: 'モンスター' },
    { id: 'magic', name: '魔法' },
    { id: 'trap', name: '罠' },
  ];

  const filterAttrs = [
    { id: 'fire', name: '炎' },
    { id: 'water', name: '水' },
    { id: 'wind', name: '風' },
    { id: 'earth', name: '地' },
    { id: 'light', name: '光' },
    { id: 'dark', name: '闇' },
    { id: 'devine', name: '神' },
  ];

  const filterMonsters = [
    { id: 'monster0', name: '通常' },
    { id: 'monster1', name: '効果' },
    { id: 'monster2', name: '儀式' },
    { id: 'monster3', name: '融合' },
    { id: 'monster4', name: 'シンクロ' },
    { id: 'monster5', name: 'エクシーズ' },
    { id: 'monster6', name: 'トゥーン' },
    { id: 'monster7', name: 'スピリット' },
    { id: 'monster8', name: 'ユニオン' },
    { id: 'monster9', name: 'デュアル' },
    { id: 'monster10', name: 'チューナー' },
    { id: 'monster11', name: 'リバース' },
    { id: 'monster12', name: 'ペンデュラム' },
    { id: 'monster13', name: '特殊召喚' },
    // { id: 'monster14', name: 'リンク' },
  ];

  const filterMagics = [
    { id: 'magic0', name: '通常魔法' },
    { id: 'magic1', name: 'フィールド魔法' },
    { id: 'magic2', name: '装備魔法' },
    { id: 'magic3', name: '永続魔法' },
    { id: 'magic4', name: '速攻魔法' },
    { id: 'magic5', name: '儀式魔法' },
  ];

  const filterTraps = [
    { id: 'trap0', name: '通常罠' },
    { id: 'trap1', name: 'カウンター罠' },
    { id: 'trap2', name: '永続罠' },
  ];

  const filterLevels = [
    { id: 'lv1', name: '1' },
    { id: 'lv2', name: '2' },
    { id: 'lv3', name: '3' },
    { id: 'lv4', name: '4' },
    { id: 'lv5', name: '5' },
    { id: 'lv6', name: '6' },
    { id: 'lv7', name: '7' },
    { id: 'lv8', name: '8' },
    { id: 'lv9', name: '9' },
    { id: 'lv10', name: '10' },
    { id: 'lv11', name: '11' },
    { id: 'lv12', name: '12' },
  ];

  const filterPendulumScales = [
    { id: 'ps1', name: '1' },
    { id: 'ps2', name: '2' },
    { id: 'ps3', name: '3' },
    { id: 'ps4', name: '4' },
    { id: 'ps5', name: '5' },
    { id: 'ps6', name: '6' },
    { id: 'ps7', name: '7' },
    { id: 'ps8', name: '8' },
    { id: 'ps9', name: '9' },
    { id: 'ps10', name: '10' },
    { id: 'ps11', name: '11' },
    { id: 'ps12', name: '12' },
  ];

  const filterMonsterTypes = [
    { id: 'type1', name: '魔法使い族' },
    { id: 'type2', name: 'ドラゴン族' },
    { id: 'type3', name: 'アンデット族' },
    { id: 'type4', name: '戦士族' },
    { id: 'type5', name: '獣戦士族' },
    { id: 'type6', name: '獣族' },
    { id: 'type7', name: '鳥獣族' },
    { id: 'type8', name: '悪魔族' },
    { id: 'type9', name: '天使族' },
    { id: 'type10', name: '昆虫族' },
    { id: 'type11', name: '恐竜族' },
    { id: 'type12', name: '爬虫類族' },
    { id: 'type13', name: '魚族' },
    { id: 'type14', name: '海竜族' },
    { id: 'type15', name: '水族' },
    { id: 'type16', name: '炎族' },
    { id: 'type17', name: '雷族' },
    { id: 'type18', name: '岩石族' },
    { id: 'type19', name: '植物族' },
    { id: 'type20', name: '機械族' },
    { id: 'type21', name: 'サイキック族' },
    { id: 'type22', name: '幻神獣族' },
    // { id: 'type23', name: '創造神族' },
    { id: 'type24', name: '幻竜族' },
    { id: 'type25', name: 'サイバース族' },
  ];

  const filterRares = [
    { id: 'rare0', name: 'UR' },
    { id: 'rare1', name: 'SR' },
    { id: 'rare2', name: 'R' },
    { id: 'rare3', name: 'N' },
  ];

  const filterLimits = [
    { id: 'limit0', name: '禁止' },
    { id: 'limit1', name: 'リミット1' },
    { id: 'limit2', name: 'リミット2' },
    { id: 'limit3', name: 'リミット3' },
    { id: 'limit4', name: '制限なし' },
  ];

  // const filterEffects = [
  //   { id: 'effect0', name: 'LP回復' },
  //   { id: 'effect1', name: '効果ダメージ' },
  //   { id: 'effect2', name: 'ドロー' },
  //   { id: 'effect3', name: '攻守増減' },
  //   { id: 'effect4', name: 'デッキ破壊' },
  //   { id: 'effect5', name: '魔法・罠破壊' },
  //   { id: 'effect6', name: 'モンスター関連' },
  //   { id: 'effect7', name: '表示形式変更' },
  //   { id: 'effect8', name: '墓地回収/蘇生' },
  //   { id: 'effect9', name: 'ギャンブル' },
  //   { id: 'effect10', name: '除外' },
  //   { id: 'effect11', name: '特殊召喚' },
  //   { id: 'effect12', name: 'トークン' },
  //   { id: 'effect13', name: '儀式関連' },
  //   { id: 'effect14', name: '融合関連' },
  //   { id: 'effect15', name: 'トゥーン' },
  //   { id: 'effect16', name: 'デュアル' },
  //   { id: 'effect17', name: 'ユニオン' },
  //   { id: 'effect18', name: 'スピリット' },
  //   { id: 'effect19', name: '通常モンスター関連' },
  //   { id: 'effect20', name: 'チューナー' },
  //   { id: 'effect21', name: 'シンクロ関連' },
  //   { id: 'effect22', name: 'エクシーズ関連' },
  //   { id: 'effect23', name: 'ペンデュラム関連' },
  // ];

  const center = (
    <div className="main d-flex flex-column">
      <Container fluid className="px-0">
        <Row className="mx-0">
          <Col xs={12} className="px-0">
            <div className="d-flex flex-wrap">
              {
                displayItem.slice(0, 100).map(x => {
                  const {
                    t: type,
                    tr: monsterType,
                    n,
                    h,
                    e,
                    ra,
                    a: attr,
                    l: lv,
                    mo: kinds,
                    at: atk,
                    de: def,
                    tx: text,
                    ps: pscale,
                    pe: peffect,
                    li: limit,
                    lk: links,
                  } = x;

                  const typeTokey = {
                    "monster": attr,
                    "magic": "魔",
                    "trap": "罠",
                  };

                  return (
                    <div key={n} className="border-bottom border-2 border-primary" style={{ width: '100%' }}>
                      <Card className="rounded-0 border-0" style={{ backgroundColor: `${attrToColorCode[typeTokey[type]]}60` }}>
                        <Card.Header className="p-0 pb-2 border-0">
                          <div className="d-flex">
                            <div className="px-4 py-1 d-flex align-items-baseline" style={{ backgroundColor: '#1d2a48', borderBottomRightRadius: 56 }}>
                              {
                                type === "monster" && (
                                  <FontAwesomeIcon className="me-2 align-self-center" title="モンスター" icon={faDragon} style={{ width: 18, height: 18, fontSize: 12, color: "#0dcaf0" }} />
                                )
                              }
                              {
                                type === "magic" && (
                                  <FontAwesomeIcon className="me-2 align-self-center" title="魔法" icon={faBolt} style={{ width: 18, height: 18, fontSize: 12, color: "#ffc107" }} />
                                )
                              }
                              {
                                type === "trap" && (
                                  <FontAwesomeIcon className="me-2 align-self-center" title="罠" icon={faBomb} style={{ width: 18, height: 18, fontSize: 12, color: "#6c757d" }} />
                                )
                              }
                              <div className="badge fw-bold align-self-center me-2" style={{ paddingTop: 2, paddingBottom: 2, fontSize: 12, width: 32, background: rareToBackground[ra] }}>{ra}</div>
                              <span className="pe-2" style={{ fontSize: 16, opacity: 0.9 }}>
                                <a className="text-light" target="blank" href={`https://duel-links.neko-note.org/c/${e.replace(/[^0-9A-Za-z\n]/g, "_").replace(/^([0-9])/, "n$1")}/`}>{n}</a>
                              </span>
                              <span className="text-light pe-4" style={{ fontSize: 8, opacity: 0.8 }}>
                                {h} / {e}
                              </span>
                            </div>
                          </div>
                        </Card.Header>
                        <Card.Body className="ms-2 p-0" style={{ fontSize: 14, backgroundColor: '#1d2a4880' }}>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex">
                              <Attr card={x} />
                              {
                                x.ocg && (
                                  <div>
                                    <Badge className="mt-1 ms-2" bg="info" title="遊戯王OCG の情報が表示されています">OCG</Badge>
                                  </div>
                                )
                              }
                            </div>
                            <div className="me-4 text-light">
                              {
                                monsterType && (
                                  <b>{[monsterType].concat(kinds).join(" ／ ")}</b>
                                )
                              }
                            </div>
                          </div>
                        </Card.Body>
                        <Card.Footer className="ms-2 pt-2 pb-0 px-0" style={{ backgroundColor: 'rgba(255, 255, 255, 0.97)' }}>
                          <div className={`rounded ${lv ? "pb-0" : "pb-2"}`} style={{ fontSize: 14 }}>
                            {
                              (type === "monster") && (peffect !== "") && (
                                <>
                                  <pre className="px-4 my-0" style={{ whiteSpace: 'pre-wrap' }}>{peffect}</pre>
                                  <hr className="my-2" />
                                </>
                              )
                            }
                            <pre className="px-4 my-0" style={{ whiteSpace: 'pre-wrap' }}>{text}</pre>
                          </div>
                          {
                            (lv || (limit !== "")) && (
                              <div className="d-flex justify-content-end text-light fw-bold" style={{ fontSize: 14 }}>
                                <div className="d-flex align-items-center px-4 pt-1" style={{ borderTopLeftRadius: 28, backgroundColor: '#1d2a48' }}>
                                  {
                                    limit === 0 && (
                                      <>
                                        <FontAwesomeIcon title="禁止カード" icon={faTimesCircle} style={{ marginBottom: 1, width: 16, height: 16, fontSize: 12, color: "#dc3545" }} />
                                        &nbsp;
                                        <div className="me-3">{limit}</div>
                                      </>
                                    )
                                  }
                                  {
                                    limit === 1 && (
                                      <>
                                        <FontAwesomeIcon title="リミット１" icon={faExclamationTriangle} style={{ marginBottom: 1, width: 16, height: 16, fontSize: 12, color: "#ffc107" }} />
                                        &nbsp;
                                        <div className="me-3">{limit}</div>
                                      </>
                                    )
                                  }
                                  {
                                    limit === 2 && (
                                      <>
                                        <FontAwesomeIcon title="リミット２" icon={faExclamationTriangle} style={{ marginBottom: 1, width: 16, height: 16, fontSize: 12, color: "#ffc107" }} />
                                        &nbsp;
                                        <div className="me-3">{limit}</div>
                                      </>
                                    )
                                  }
                                  {
                                    limit === 3 && (
                                      <>
                                        <FontAwesomeIcon title="リミット３" icon={faExclamationTriangle} style={{ marginBottom: 1, width: 16, height: 16, fontSize: 12, color: "#ffc107" }} />
                                        &nbsp;
                                        <div className="me-3">{limit}</div>
                                      </>
                                    )
                                  }
                                  {
                                    links && (links.length > 0) && (
                                      <LinkTriangles links={links} />
                                    )
                                  }
                                  {
                                    lv && (
                                      <>
                                        {
                                          pscale !== "" && (
                                            <>
                                              <FontAwesomeIcon title="ペンデュラムスケール" icon={faGem} style={{ marginBottom: 1, width: 16, height: 16, fontSize: 12, color: "#0dcaf0" }} />
                                              &nbsp;
                                              <div className="me-3">{pscale}</div>
                                              <FontAwesomeIcon title="ペンデュラムスケール" icon={faGem} style={{ marginBottom: 1, width: 16, height: 16, fontSize: 12, color: "#dc3545" }} />
                                              &nbsp;
                                              <div className="me-3">{pscale}</div>
                                            </>
                                          )
                                        }
                                        <FontAwesomeIcon className="rounded-pill" icon={faStar} style={{ marginBottom: 1, padding: 2, width: 16, height: 16, fontSize: 12, color: "#ffc107", backgroundColor: "#dc3545" }} />
                                        &nbsp;
                                        <div className="me-3">{lv}</div>
                                        &nbsp;
                                        <div>ATK/</div>
                                        <div className="text-end me-3" style={{ width: 40 }}>{atk}</div>
                                        <div>DEF/</div>
                                        <div className="text-end" style={{ width: 40 }}>{def}</div>
                                      </>
                                    )
                                  }
                                </div>
                              </div>
                            )
                          }
                        </Card.Footer>
                      </Card>
                    </div>
                  );
                })
              }
            </div>
          </Col>
        </Row>
      </Container>
    </div >
  );

  const filterItems = (
    <div className="px-3 py-3">
      <div className="pb-2" style={{ fontSize: 14 }}><b>タイプ</b></div>
      <Filter items={filterTypes} state={filterTypesState} setState={setFilterTypesState} />
      <hr />
      <div className="pb-2" style={{ fontSize: 14 }}><b>属性</b></div>
      <Filter items={filterAttrs} state={filterAttrsState} setState={setFilterAttrsState} />
      <hr />
      <div className="pb-2" style={{ fontSize: 14 }}><b>モンスター</b></div>
      <Form.Check
        type="switch"
        id="monsters-switch"
        label={<small>and検索</small>}
        checked={isAndFilterMonsters}
        onChange={() => setIsAndFilterMonsters(!isAndFilterMonsters)}
      />
      <Filter items={filterMonsters} state={filterMonstersState} setState={setFilterMonstersState} />
      <hr />
      <div className="pb-2" style={{ fontSize: 14 }}><b>魔法</b></div>
      <Filter items={filterMagics} state={filterMagicsState} setState={setFilterMagicsState} />
      <hr />
      <div className="pb-2" style={{ fontSize: 14 }}><b>罠</b></div>
      <Filter items={filterTraps} state={filterTrapsState} setState={setFilterTrapsState} />
      <hr />
      <div className="pb-2" style={{ fontSize: 14 }}><b>種族</b></div>
      <Filter items={filterMonsterTypes} state={filterMonsterTypesState} setState={setFilterMonsterTypesState} />
      <hr />
      <div className="pb-2" style={{ fontSize: 14 }}><b>レベル/ランク</b></div>
      <Filter items={filterLevels} state={filterLevelsState} setState={setFilterLevelsState} />
      <hr />
      <div className="pb-2" style={{ fontSize: 14 }}><b>ペンデュラムスケール</b></div>
      <Filter items={filterPendulumScales} state={filterPendulumScalesState} setState={setFilterPendulumScalesState} />
      <hr />
      {/* <div className="py-2" style={{ fontSize: 14 }}><b>カード効果</b></div>
    <Filter items={filterEffects} state={filterEffectsState} setState={setFilterEffectsState} /> */}
      <div className="py-2" style={{ fontSize: 14 }}><b>レアリティ</b></div>
      <Filter items={filterRares} state={filterRaresState} setState={setFilterRaresState} />
      <hr />
      <div className="pb-2" style={{ fontSize: 14 }}><b>リミットレギュレーション</b></div>
      <Filter items={filterLimits} state={filterLimitsState} setState={setFilterLimitsState} />
      {/* <hr /> */}
      {/* <div className="pb-2" style={{ fontSize: 14 }}><b>✨非公式用語✨</b></div>
      <Form.Check 
        type="switch"
        id="tags-switch"
        label={<small>and検索</small>}
        checked={isAndFilterTags}
        onChange={() => setIsAndFilterTags(!isAndFilterTags)}
      />
      <Filter items={filterTags} state={filterTagsState} setState={setFilterTagsState} />
      <hr /> */}

      {/* <div className="py-2"><b>フィルタ</b></div>
    <div className="py-2">☑ 永続効果</div>
    <div className="py-2">☑ 起動効果</div>
    <div className="py-2">☑ 誘発効果</div>
    <div className="py-2">☑ 誘発即時効果</div> */}
    </div>
  );

  const right = (
    <div className="main">
      <div className="d-block d-sm-none">
        <Accordion defaultActiveKey="-1" flush style={{ borderRadius: 0 }}>
          <Accordion.Item eventKey="0" className="border-0">
            <Accordion.Header>
              <div className="d-flex align-items-center" style={{ width: '100%' }}>
                <FontAwesomeIcon icon={faFilter} style={{ fontSize: 16 }} />
                <b className="ms-2" style={{ fontSize: 14 }}>絞り込み</b>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              {filterItems}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <div className="d-none d-sm-block">
        <div className="ps-3 py-3 text-white d-flex align-items-center" style={{ backgroundColor: 'rgba(29, 32, 72, 0.96)' }}>
          <FontAwesomeIcon icon={faFilter} style={{ fontSize: 16 }} />
          <b className="ms-2" style={{ fontSize: 14 }}>絞り込み</b>
        </div>
        {filterItems}
      </div>
    </div>
  );

  return (
    <div>
      <BorderLayout
        top={
          <div className="text-light bg-primary pt-4 pb-3">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <h1>遊戯王DL カード検索 β版</h1>
            </div>
            <div className="mt-3 mx-4">
              <Search keyword={keyword} setKeyword={setKeyword} />
              <div className="d-flex justify-content-center align-items-baseline">
                <small>絞り込み状況：</small><b>{displayItem.length} / {items.length}</b>&nbsp;<small>件</small>
                {
                  displayItem.length >= 100 && (
                    <sub className="ms-4">
                      （先頭の 100件 が表示されます）
                    </sub>
                  )
                }
              </div>
            </div>
          </div>
        }
        right={right}
        center={center}
        bottom={<Bottom />}
      />
    </div>
  );
}

export default App;
